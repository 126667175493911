import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import moment = require('moment');
import { NgxSpinnerService } from 'ngx-spinner';
import { catchError, Subscription, throwError } from 'rxjs';
import { PDFDocument } from 'pdf-lib';
import { MatDialog } from '@angular/material/dialog';
import { NewCategoryDialogComponent } from '../new-category-dialog/new-category-dialog.component';
import { DeleteFileConfirmationDialogComponent } from './delete-file-confirmation-dialog/delete-file-confirmation-dialog.component';
import { CreateNewTruckFileDialogComponent } from './create-new-truck-file-dialog/create-new-truck-file-dialog.component';
import { WarningFileDialogComponent } from './warning-file-dialog/warning-file-dialog.component';
import { Sort } from '@angular/material/sort';
import { EditCategoryDialogComponent } from './edit-category-dialog/edit-category-dialog.component';
import { HttpEventType } from '@angular/common/http';
import { UploadFilesDialogComponent } from './upload-files-dialog/upload-files-dialog.component';
import { Router } from '@angular/router';
import { TransformService } from '@app/modules/shared/services/transform.service';
import { SafetyService } from '@app/modules/safety/services/safety.service';
import { SharedService } from '@app/modules/shared/services/shared.service';

@Component({
  selector: 'app-truck-file',
  templateUrl: './truck-file.component.html',
  styleUrls: ['./truck-file.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})

export class TruckFileComponent implements OnInit, OnDestroy {
  @ViewChild('expanded') expanded: ElementRef;  
  @ViewChild('fileUpload') fileUpload: ElementRef;

  //Search
  searchText: string = '';

  //Companies filter
  companyArray: any[] = [];
  companyMenuOpen: boolean = false;
  companySelected: string = 'All divisions';

  //Almost expired
  almostExpired: any[] = [
    {viewValue: 'Almost expired'}
  ];

  isOpen: boolean = false;
  value: string = 'Almost expired';

  isSaved: boolean = true;

  dataSource: any[] = [];
  sortedData: any[] = [];

  expandedRow: number = -1;
  expandedFieldIndex: number = 0;
  categoryID: number = -1;

  columnsToDisplay = ['position', 'unit_no', 'driver_name', 'VIN', 'division_name', 'lastUpdate', 'hidden-columns'];
  displayedColumns = [
    { key: 'position', title: ''},
    { key: 'unit_no', title: 'TRUCK #'},
    { key: 'driver_name', title: 'DRIVER'},
    { key: 'VIN', title: 'VIN #'},
    { key: 'division_name', title: 'COMPANY'},
    { key: 'lastUpdate', title: 'LAST UPDATE'},
    { key: 'hidden-columns', title: ''},
  ];
  columnsToDisplayWithExpand = [...this.columnsToDisplay];
  expandedElement: any;

  categories: any[] = [];

  uploadedDate: string = '';

  //Progress
  progressDone: number = 0;
  showProgress: boolean = false;

  //Pdf loading
  pdfLoading: boolean = false;

  loaded: boolean = false;

  error: boolean = false;

  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";

  spinnerBgColor: string = this.transformService.spinnerBgColor;
  spinnerType: string = this.transformService.spinnerType;

  subscription1: Subscription | any;
  subscription2: Subscription | any;
  subscription3: Subscription | any;
  subscription4: Subscription | any;

  constructor(private transformService: TransformService,
              private safetyService: SafetyService,
              private dialog: MatDialog,
              private renderer: Renderer2,
              private spinner: NgxSpinnerService,
              private sharedService: SharedService,
              private router: Router) {
                this.renderer.listen('document', 'click',(e: any)=>{
                  if(e.target !== this.expanded?.nativeElement && !e.target.classList.contains('mat-calendar-body-cell-content')
                       && !this.isSaved && this.dialog.openDialogs.length==0) {
                    this.warningDialog();
                  }
                });
              }

  ngOnInit(): void {
    this.spinner.show('trucks-files');
    this.getTableData();
    this.getCompanyData();
    this.refreshData();
  }

  //Get table data
  getTableData() {
    this.subscription1 = this.safetyService.getTrucksTableData()
    .pipe(catchError((err: any) => {
      this.spinner.hide('trucks-files');
      this.loaded = true;
      this.error = true;
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      console.log(response)
      this.categories = response[1];

      let data: any[] = JSON.parse(JSON.stringify(response[0]));
      
      for(let key in data) {
        let expandedData: any[] = JSON.parse(JSON.stringify(response[1])); 
        for(let key2 in data[key].categories) {
      
          for(let key3 in response[1]) {

            if(expandedData[key3].ID == data[key].categories[key2].categoryID && data[key].categories[key2].files.length > 0) {
              expandedData[key3].uploaded = data[key].categories[key2].uploaded;  
            } 
        }

      }  
        data[key].expandedData = expandedData;
      }
      this.categoryID = response[1][0].ID;
      this.dataSource = JSON.parse(JSON.stringify(data));
      this.sortedData = data;
      this.spinner.hide('trucks-files');
      this.loaded = true;
      console.log(data);
    })
  };

  getCompanyData() {
    this.subscription4 = this.sharedService.getCompanyData().subscribe((response: any) => {
      this.companyArray = response.divisions;
      this.companyArray.push({id: -1, name: 'All divisions'});
    });
  };

  selectValue(value: string, isCompany: boolean) {
    if(isCompany) {
      this.companySelected = value;
      this.companyMenuOpen = false;
      let data: any = JSON.parse(JSON.stringify(this.dataSource));
      let arrayToDisplay: any[] = [];

      if(value === 'All divisions' || value === 'Select an option') {
        arrayToDisplay = data;
      } 
      else {
        for(let key in data) {
          if(data[key].division_name.toLowerCase() === value.toLowerCase()) {
            arrayToDisplay.push(data[key]);
          }
        };
      }
      this.sortedData = arrayToDisplay;
    } 
    else {
      this.value = value;
      this.isOpen = false;
    }
  }

  openUploadFilesDialog() {
    let dialogRef: any = this.dialog.open(UploadFilesDialogComponent, {
      autoFocus: false,
      panelClass: 'upload-files-dialog-container'
    })
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
        this.dataSource = [];
        this.sortedData = [];
        this.error = false;
        this.loaded = false;
        this.spinner.show('trucks-files');
        this.getTableData();
      }
    });
  };

  onFileSelected(event: any, obj: any) {
    this.isSaved = false;
    let length: any = Object.keys(event).length;
    for(let i = 0; i < length; i++) {
      this.addMultipleFiles(event, i, obj);
    }
  }

  addMultipleFiles(file: any, index: number, data: any) {
    let fileName = file[index].name;
    let reader: any = new FileReader();
    reader.onload = () => {
    let obj: any = {
      fileName: fileName,
      fileData: reader.result.split(',')[1],
      truckID: data.unit_no,
      categoryID: this.categoryID,
      uploaded: moment(new Date()).format('MMM DD. YYYY.'),
      uploadedBy: 'User',
      hubCategory: -1,
      expiration: null,
      isOpen: false,
      newFile: true
    };
      this.uploadedDate = obj.uploaded;

      let categoryExist: boolean = false;
      console.log(obj);
      for(let key in data.categories) {
        if(data.categories[key].categoryID === this.categoryID) {
          obj.hubCategory = data.categories[key].ID ? data.categories[key].ID : -1;
          data.categories[key].files.push(obj);
          this.dataSource[this.expandedRow].categories[key].files.push(obj)
          categoryExist = true;
        }
      }

      if(data.categories.length === 0 || !categoryExist) {
        data.categories.push({categoryID: this.categoryID, files: [obj]});
        this.dataSource[this.expandedRow].categories.push({ID: undefined, categoryID: this.categoryID, files: [obj]});
      }
    }
    reader.readAsDataURL(file[index]);
  };

  selectSection(obj: any, index: number) {
    if(this.isSaved) {
      this.categoryID = obj.ID;
      this.expandedFieldIndex = index; 
    } 
  };

  //Upload files
  uploadFiles(obj: any) {
    for(let key in obj.categories) {

      if(obj.categories[key].categoryID === this.categoryID) {
        let filesToSave: any[] = [];
        for(let key2 in obj.categories[key].files) {
          if(obj.categories[key].files[key2]?.newFile) {
            filesToSave.push(obj.categories[key].files[key2])
          }
        }
        console.log(filesToSave);
        this.safetyService.uploadFile(filesToSave).subscribe((httpResponse: any) => {
          this.showProgress = true;
          if (httpResponse.type === HttpEventType.Response) {
            this.showProgress = false;
            this.progressDone = 0;
          }
          if (httpResponse.type === HttpEventType.UploadProgress) {
            this.progressDone = Math.round(100 * httpResponse.loaded / httpResponse.total);
          } 

          let response: any = httpResponse.body;
          if(response) {
            this.isSaved = true;
            console.log(response);
            let i: number = 0;

            if(!obj.categories[key]?.ID) {
               obj.categories[key].ID = response[0].hubCategory;
             }

            for(let key3 in obj.categories[key].files) {
              if(obj.categories[key].files[key3]?.newFile) {
                obj.categories[key].files[key3].ID = response[i].truckID;
                obj.categories[key].files[key3].newFile = false;
                i++;
              }

            }
            obj.expandedData[this.expandedFieldIndex].uploaded = this.uploadedDate;
           // obj.lastUpdate = this.transformService.convertDateToTimestamp(moment(this.uploadedDate).format('ddd, DD/MM YYYY'), '00:00:00')

          }
        })
      }
    } 
  }

  refreshData() {
    this.subscription3 = this.safetyService.truckTableSubject.subscribe((response: any) => {
      this.dataSource = [];
      this.sortedData = [];
      this.error = false;
      this.loaded = false;
      this.spinner.show('trucks-files');
      this.getTableData();
    })
  }

  //Create truck file (merge pdfs)
  createNewTruckFile(data: any) {
    let dialogRef: any = this.dialog.open(CreateNewTruckFileDialogComponent, {
      width: '454px',
      height: '482px',
      autoFocus: false,
      panelClass: 'create-file-confirmation-dialog-container',
      data: {obj: data, categories: this.categories}
    })
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
        this.showProgress = true;
        this.safetyService.getAllFilesByTruck(data.unit_no).subscribe((response: any) => {
          const mergePdfs = async () => {
            const pdfDoc = await PDFDocument.create();
            console.log(response)
            for(let key in response) {
              let doc: any = await PDFDocument.load(response[key]);
              let docPage: any = await pdfDoc.copyPages(doc, doc.getPageIndices());
              docPage.forEach((page: any) => pdfDoc.addPage(page));
            }
    
            const base64 = await pdfDoc.saveAsBase64();
            let obj: any = {
              fileData: base64,
              truckID: data.unit_no,
            };
            this.safetyService.uploadMergedFile(obj).subscribe((httpResponse: any) => {
              if (httpResponse.type === HttpEventType.Response) {
                this.showProgress = false;
                this.progressDone = 0;
              }
              if (httpResponse.type === HttpEventType.UploadProgress) {
                this.progressDone = Math.round(100 * httpResponse.loaded / httpResponse.total);
              } 
            })
          }
          mergePdfs();
        });
      }
    });
  };

  //Expand row
  expandRow(element: any, index: number) {
    this.expandedElement = this.expandedElement === element ? null : element;
    this.expandedRow = index;
  }

  //Open file in new tab
  openPdfInNewTab(fileId: number) {
    this.pdfLoading = true;
    if(fileId) {
      this.safetyService.downloadFile(fileId).subscribe((response: any) => {
        console.log(response);
        this.pdfLoading = false;
        const anchor = document.createElement('a');
        document.body.appendChild(anchor);
        anchor.style.display = 'none';
        const blob = new Blob([response], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        anchor.href = url; 
        anchor.target = '_blank';
        anchor.click();
        window.URL.revokeObjectURL(url);
      })
    }
  }

  deleteFile(categoryObj: any, obj: any, i: number, array: any[]) {
    let dialogRef: any = this.dialog.open(DeleteFileConfirmationDialogComponent, {
      width: '454px',
      height: '482px',
      autoFocus: false,
      panelClass: 'delete-file-confirmation-dialog-container',
      data: {obj: obj, categories: this.categories, categoryId: this.categoryID, truckClicked: categoryObj.unit_no}
    })
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
        this.fileUpload.nativeElement.value = '';
        this.isSaved = true;
        if(obj.ID) {
          this.safetyService.deleteFile(result).subscribe((response: any) => {
            console.log(response);
            if(response === 'OK') {
           //  array.splice(i, 1);
           //   delete categoryObj.expandedData[this.expandedFieldIndex].uploaded;
            this.dataSource = [];
            this.sortedData = [];
            this.error = false;
            this.loaded = false;
            this.spinner.show('trucks-files');
            this.getTableData();
            }
          })
        } 
        else {
          array.splice(i, 1);
        }
        console.log(result);
      }
    });
  }

  warningDialog() {
    let obj: any = {};
    for(let i = 0; i < this.expandedElement?.categories.length; i++) {
      if(this.expandedElement.categories[i].categoryID === this.categoryID) {
        obj = this.expandedElement.categories[i];
        break;
      }
    }
    let dialogRef: any = this.dialog.open(WarningFileDialogComponent, {
      width: '454px',
      height: '482px',
      autoFocus: false,
      panelClass: 'warning-file-confirmation-dialog-container',
      data: {obj: obj, categories: this.categories, 
      unit_no: this.expandedElement.unit_no }
    })
    dialogRef.afterClosed().subscribe((result: any) => {
      this.isSaved = true
      if(result) {
        this.uploadFiles(this.expandedElement);
      }
      else {
        this.fileUpload.nativeElement.value = '';
        this.expandedElement.categories.forEach(obj => {
          let files: any[] = [];
          obj.files.forEach((obj2: any) => {

            if(!obj2?.newFile) {
              files.push(obj2);
            }

          })
          obj.files = files;
        });
      }
    });
  };
  
  //Create category
  addNewCategory() {
    let dialogRef: any = this.dialog.open(NewCategoryDialogComponent, {
      width: '300px',
      autoFocus: false,
      panelClass: 'new-category-dialog-container'
    })
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
        this.getTableData();
      }
    });
  }

  editCategory() {
    this.dialog.open(EditCategoryDialogComponent, {
      width: '630px',
      height: '685px',
      autoFocus: false,
      panelClass: 'edit-category-dialog-container'
    })
  };

  //Sort data
  sortData(sort: Sort) {
    const data = this.dataSource.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }
    this.sortedData = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'unit_no':
          return compare(a.unit_no, b.unit_no, isAsc);
        case 'driver_name':
          return compare(a.driver_name, b.driver_name, isAsc);
        case 'VIN':
          return compare(a.VIN, b.VIN, isAsc);
        case 'division_name':
          return compare(a.division_name, b.division_name, isAsc);
        case 'lastUpdate':
          return compare(a.lastUpdate, b.lastUpdate, isAsc);
        default:
          return 0;
      }
    });
  }

  //Date
  dateClicked(event: any, file: any) {
    file.expiration = moment(event._d).format('YYYY-MM-DDTHH:mm:ss');
    file.isOpenCalendar = !file.isOpenCalendar;
  }

  //Open in new tab
  openInNewTab(route: string) {
    const url = this.router.serializeUrl(this.router.createUrlTree([route]));
    window.open(url, '_blank');
  }

  ngOnDestroy(): void {
    this.subscription1?.unsubscribe();
    this.subscription2?.unsubscribe();
    this.subscription3?.unsubscribe();
    this.subscription4?.unsubscribe();
  }

}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SharedService } from '@app/modules/shared/services/shared.service';
import { TransformService } from '@app/modules/shared/services/transform.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription, catchError, throwError } from 'rxjs';

@Component({
  selector: 'app-note-history-dialog',
  templateUrl: './note-history-dialog.component.html',
  styleUrls: ['./note-history-dialog.component.scss']
})
export class NoteHistoryDialogComponent implements OnInit, OnDestroy {
  noteHistory: any[] = [];

  //Spinner
  loaded: boolean = false;

  error: boolean = false;

  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";

  spinnerBgColor: string = this.transformService.spinnerBgColor;
  spinnerType: string = this.transformService.spinnerType;

  subscription: Subscription | any;

  constructor(@Inject(MAT_DIALOG_DATA) public obj: any,
              private transformService: TransformService,
              private spinner: NgxSpinnerService,
              private sharedService: SharedService) { }

  ngOnInit(): void {
    this.spinner.show('note-history');
    this.subscription = this.sharedService.getNoteHistory(this.obj.statusId, this.obj.driverId)
    .pipe(catchError((err: any) => {
      this.spinner.hide('note-history');
      this.loaded = true;
      this.error = true;
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      console.log(response);
      this.noteHistory = response;
      this.spinner.hide('note-history');
      this.loaded = true;
    })
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  };

}

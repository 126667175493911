import { Injectable } from '@angular/core';
import * as _G from '../../../_config/globals';
import { Subject, forkJoin } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';

@Injectable()
export class DispatchersPerformancesService {
  headers = new HttpHeaders({
		'Content-Type': 'application/json',
		'Authorization': 'Basic SVZTb2x1dGlvbjoxUzBsdXQxMG4yQDIy'
	});
	requestOptions = { headers: this.headers };

  dateChanged = new Subject<any>();
  driverTableLineChartSubject = new Subject<any>();
  dispatchCardsLineChartSubject = new Subject<any>();
  dispatchTableLineChartSubject = new Subject<any>();

  constructor(private http: HttpClient) { }

  getDispatchPerformanceCards(startDate: string, endDate: string, startDate2: string, endDate2: string, filterParams: string) {
    let response1 = this.http.get(`${_G.extendApiUrl}dispatcher/dispatcherperfirmance?start=${startDate}&end=${endDate}${filterParams}`);
    let response2 = this.http.get(`${_G.extendApiUrl}dispatcher/dispatcherperfirmance?start=${startDate2}&end=${endDate2}${filterParams}`);
    return forkJoin([response1, response2]);
  };

  getDispatchTableData(startDate: string, endDate: string, filterParams: string) {
    return this.http.get(`${_G.extendApiUrl}overview/dispoverview?start=${startDate}&end=${endDate}${filterParams}`);
  };

  getDriversCards(filterParams: string) {
    return this.http.get(`${_G.extendApiUrl}dispatcher/drivercard?${filterParams}`);
  };

  getDriversTableData(startDate: string, endDate: string, filterParams: string) {
    return this.http.get(`${_G.extendApiUrl}dispatcher/driversoverview?start=${startDate}&end=${endDate}${filterParams}`);
  };

}

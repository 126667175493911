import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SettingsService } from '@app/modules/settings/services/settings.service';
import { SharedService } from '@app/modules/shared/services/shared.service';
import { TransformService } from '@app/modules/shared/services/transform.service';
import moment = require('moment');
import { Subscription, catchError, throwError } from 'rxjs';

@Component({
  selector: 'app-create-edit-users-dialog',
  templateUrl: './create-edit-users-dialog.component.html',
  styleUrls: ['./create-edit-users-dialog.component.scss']
})
export class CreateEditUsersDialogComponent implements OnInit, OnDestroy {
  //User form group
  userFormGroup: FormGroup | any;

  //Department
  departmentArray: any[] = [];

  departmentInitialValue: string = 'Select an option';

  //Position
  positionArrayDispatchers: any[] = [];
  positionArray: any[] = [];

  dispatcherSearch: string = '';

  positionValue: string = 'Select an option';
  isOpen: boolean = false;

  //Permission
  permissionArray: any[] = [];

  permissionInitialValue: string = 'Select an option';

  //Hiring date
  hiringDateShowValue: string = 'Choose a date';
  hiringCalendar: boolean = false;

  //Birth date
  birthShowValue: string = 'Choose a date';
  birthCalendar: boolean = false;

  //Office
  officeArray: any[] = [
    {viewValue: 'Kragujevac'},
    {viewValue: 'Kraljevo'}
  ];

  officeInitialValue: string = 'Select an option';
  officeValue: string = '';

  //Termination date
  terminationShowValue: string = 'Choose a date';
  terminationCalendar: boolean = false;

  //Password
  showPassword: boolean = false;

  //Confirm password
  showConfirmPassword: boolean = false;

  //Show loading process
  loading: boolean = false;

  //Response
  success: boolean | undefined = undefined;

  //Valid form
  formSubmitAttempt: boolean = false;

  //Reset select inputs
  resetSelect: boolean = false;

  subscription: Subscription | any;
  subscription1: Subscription | any;
  subscription2: Subscription | any;
  subscription3: Subscription | any;

  constructor(@Inject(MAT_DIALOG_DATA) public obj: any,
              private transformService: TransformService,
              private settingsService: SettingsService,
              private sharedService: SharedService) {
                this.userFormGroup = new FormGroup({
                  'id': new FormControl(0),
                  'firstName': new FormControl(''),
                  'lastName': new FormControl(''),
                  'extension': new FormControl(''),
                  'department': new FormControl(''),
                  'position': new FormControl(''),
                  'protransportId': new FormControl(''),
                  'permission': new FormControl(''),
                  'hiringDate': new FormControl(''),
                  'phoneNumber': new FormControl(''),
                  'emergencyPhoneNumber': new FormControl(''),
                  'birthDate': new FormControl(''),
                  'office': new FormControl(''),
                  'terminationDate': new FormControl(''),
                  'email': new FormControl(''),
                  'passwordGroup': new FormGroup({
                    'password': new FormControl(''),
                    'confirmPassword': new FormControl('')
                  }, {validators: this.samePassword()})
                })
              }

  ngOnInit(): void {
    this.subscription = this.settingsService.getAllRoles().subscribe((response: any) => {
      response.forEach(obj => {
        this.permissionArray.push({viewValue: obj.role_name});
      });
    this.subscription1 = this.sharedService.getAllUsersDepartments().subscribe((response: any) => {
      response.forEach(obj => {
        this.departmentArray.push({viewValue: obj.name});
      });
    })
    this.subscription2 = this.settingsService.getAllDispatchers().subscribe((response: any) => {
      let dispArray: any[] = [];
      for(let key in response) {
        dispArray.push({pt_id: key, name: response[key]});
      }
      this.positionArrayDispatchers = dispArray;
      console.log(this.positionArrayDispatchers);
    });

    this.subscription3 = this.sharedService.getAllEmployeePositions().subscribe((response: any) => {
      console.log(response);
      this.positionArray = response;
    });

    });

    console.log(this.obj)
    if(!this.obj.newUser) {
      this.userFormGroup.patchValue({
        'id': this.obj.data.id,
        'firstName': this.obj.data.first_name,
        'lastName': this.obj.data.last_name,
        'extension': this.obj.data.extension,
        'department': this.obj.data.departement,
        'position': this.obj.data.position,
        'protransportId': this.obj.data.pt_id !== 0 ? this.obj.data.pt_id : '',
        'permission': this.obj.data.permission_set,
        'hiringDate': this.obj.data.hire_date,
        'phoneNumber': this.obj.data.cell_phone,
        'emergencyPhoneNumber': this.obj.data.emergency_phone_number,
        'birthDate': this.obj.data.birthday,
        'office': this.obj.data.office,
        'terminationDate': this.obj.data.terminated_date,
        'email': this.obj.data.username
      });

      this.departmentInitialValue = this.obj.data.departement;
      this.positionValue = this.obj.data.position;
      this.permissionInitialValue = this.obj.data.permission_set;
      this.hiringDateShowValue = this.obj.data.hire_date ? moment(this.obj.data.hire_date).format('MMM DD, yyy') : 'Choose a date';
      this.birthShowValue = this.obj.data.birthday ? moment(this.obj.data.birthday).format('MMM DD, yyy') : 'Choose a date';
      this.officeInitialValue = this.obj.data.office;
      this.terminationShowValue = this.obj.data.terminated_date ? moment(this.obj.data.terminated_date).format('MMM DD, yyy') : 'Choose a date';
    }
  }

  //Calendar method
  dateClicked(event: any, calendarNo: number) {

    if(calendarNo === 1) {
      this.hiringDateShowValue = moment(event._d).format('MMM DD, yyy');
      this.userFormGroup.patchValue({
        hiringDate: this.transformService.convertDateToTimestamp(moment(event._d).format('ddd, DD/MM YYYY'), '00:00:00')
      });
      this.hiringCalendar = false;
    };

    if(calendarNo === 2) {
      this.birthShowValue = moment(event._d).format('MMM DD, yyy');
      this.userFormGroup.patchValue({
        birthDate: this.transformService.convertDateToTimestamp(moment(event._d).format('ddd, DD/MM YYYY'), '00:00:00')
      });
      this.birthCalendar = false;
    };

    if(calendarNo === 3) {
      this.terminationShowValue = moment(event._d).format('MMM DD, yyy');
      this.userFormGroup.patchValue({
        terminationDate: this.transformService.convertDateToTimestamp(moment(event._d).format('ddd, DD/MM YYYY'), '00:00:00')
      });
      this.terminationCalendar = false;
    };

  }

  setValue(key: string, value: string, pt_id?: any) {
    if(key === 'position') {
      this.positionValue = pt_id ? value + ' PT ID: ' + pt_id : value;
      this.isOpen = !this.isOpen;
    }

    if(pt_id) {
      this.userFormGroup.patchValue({
        'protransportId': pt_id
      });
    }

    this.userFormGroup.patchValue({
      [key]: value
    });
  };

  //Create user
  createUser() {
    let loggedUser: any = JSON.parse(localStorage.getItem('currentUser'));
    let formValue: any = this.userFormGroup.value;

    let obj: any = {
      id: formValue.id,
      first_name: formValue.firstName,
      last_name: formValue.lastName,
      birthday: formValue.birthDate,
      hire_date: formValue.hiringDate,
      terminated_date: formValue.terminationDate,
      cell_phone: formValue.phoneNumber,
      emergency_phone_number: formValue.emergencyPhoneNumber,
      departement: formValue.department,
      position: formValue.position,
      office: formValue.office,
      extension: formValue.extension,
      pt_id: formValue.protransportId === '' ? 0 : formValue.protransportId,
      permission_set: formValue.permission,
      username: formValue.email,
      password: formValue.passwordGroup.password,
      is_active: false
    }

    if(this.obj.newUser) {
      this.formSubmitAttempt = true;
      obj.date_created = this.transformService.convertDateToTimestamp(moment(new Date()).format('ddd, DD/MM YYYY'),  moment(new Date()).format('HH:mm:ss'));
      obj.created_by = loggedUser.username;
    } 
    else {
      obj.is_active = this.obj.data.is_active;
      obj.edit_by = loggedUser.username;
      obj.date_edited = this.transformService.convertDateToTimestamp(moment(new Date()).format('ddd, DD/MM YYYY'),  moment(new Date()).format('HH:mm:ss'));
    }

    this.addNewOrUpdateUser(obj);
}

addNewOrUpdateUser(obj: any) {
  console.log(obj);
  this.loading = true;
  this.settingsService.createUpdateUser(obj)
  .pipe(catchError((err: any) => {
    this.loading = false;
    this.success = false;
    return throwError(() => err);
  }))
  .subscribe((response: any) => {
    console.log(response);
    this.loading = false;
    if(response.is_ok && this.obj.newUser) {
      this.userFormGroup.reset();
      this.departmentInitialValue = 'Select an option';
      this.positionValue = 'Select an option';
      this.permissionInitialValue = 'Select an option';
      this.hiringDateShowValue = 'Choose a date';
      this.birthShowValue = 'Choose a date';
      this.officeInitialValue = 'Select an option';
      this.terminationShowValue = 'Choose a date';
      this.resetSelect = true;
    }

    if(response.is_ok) {
      this.settingsService.dataChanged.next(true);
      this.success = true;
      this.formSubmitAttempt = false;
    } 
    else {
      this.success = false;
    }
    this.hideIcon()
  })
}

hideIcon() {
  setInterval(() => {
    this.success = undefined;
  }, 4000);
}

  samePassword(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const password = control.get('password');
        const confirmPassword = control.get('confirmPassword');
        let isValid: boolean = true;

        if(this.obj.newUser && password.value?.length < 2) {
          isValid = false;
        }

        return password && confirmPassword && isValid
        && password.value === confirmPassword.value ? null : { isNotSame: true };
   }
  }

  get passwordGroup() {
    return !this.userFormGroup.controls['passwordGroup'].valid && this.formSubmitAttempt;
  }

  get permissionValid() {
    return this.userFormGroup.controls['permission'].valid;
  }

  get positionValid() {
    return this.userFormGroup.controls['position'].valid;
  }

  get departmentValid() {
    return this.userFormGroup.controls['department'].valid;
  }

  get officeValid() {
    return this.userFormGroup.controls['office'].valid;
  }

  get hireValid() {
    return this.userFormGroup.controls['hiringDate'].valid;
  }

  get birthValid() {
    return this.userFormGroup.controls['birthDate'].valid;
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
    this.subscription1?.unsubscribe();
    this.subscription2?.unsubscribe();
    this.subscription3?.unsubscribe();
  }
}

import { ConnectionPositionPair } from '@angular/cdk/overlay';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-current-load',
  templateUrl: './current-load.component.html',
  styleUrls: ['./current-load.component.scss']
})
export class CurrentLoadComponent {
  @Input() loadsArray: any;
  index: number = 0;

  //Positions
  public positions = [
    new ConnectionPositionPair(
      {originX: 'center', originY: 'top'},
      {overlayX: 'start', overlayY: 'bottom'})
  ];

  nextHovered: boolean = false;

  prevRoute() {
    if(this.loadsArray.length > 0) {
      this.index = 0;
    }
  }

  nextRoute() {
    if(this.loadsArray.length > 1) {
      this.index = 1;
    }
  }

}

import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as _G from '../../../_config/globals';
import { timeout } from 'rxjs';

@Injectable()
export class IftaService {
  headers = new HttpHeaders({
		'Content-Type': 'application/json',
		'Authorization': 'Basic SVZTb2x1dGlvbjoxUzBsdXQxMG4yQDIy'
	});
	requestOptions = { headers: this.headers };

  httpOptions: any = {
    headers: this.headers,
    responseType: 'blob' as 'json',
    reportProgress: true,
    observe: 'events'
  };

  constructor(private http: HttpClient) { }

  getTableData(trucksIds: string, startDate: string, endDate: string, isIfta: number) {
    return this.http.get(`${_G.extendApiUrl}routingapp/getIFTAIRPTableForTruck?truckID=${trucksIds}&periodFrom=${startDate}&periodTo=${endDate}&isIFTA=${isIfta}`, this.requestOptions);
  };

  getZipData(trucksIds: string, startDate: string, endDate: string, type: number) {
    return this.http.get(`${_G.extendApiUrl}routingapp/getIFTAIRPReports?truckIDs=${trucksIds}&periodFrom=${startDate}&periodTo=${endDate}&type=${type}`, this.httpOptions).pipe(timeout(300000));
  };

  getAllTrucksData() {
    return this.http.get(`${_G.extendApiUrl}trucks/pttrucks?active=true`, this.requestOptions);
  };

}

import { Component, Input, OnChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { StatusDialogComponent } from '@app/modules/shared/components/status-dialog/status-dialog.component';
import { TransformService } from '@app/modules/shared/services/transform.service';
import moment = require('moment');
import { DispatchersLiveBoardService } from '../../services/dispatchers-live-board.service';

@Component({
  selector: 'app-dispatch-activity-table',
  templateUrl: './dispatch-activity-table.component.html',
  styleUrls: ['./dispatch-activity-table.component.scss']
})
export class DispatchActivityTableComponent implements OnChanges {
  @Input() tableData: any;
  @Input() date: string = '';
  @ViewChild(MatSort) sort: MatSort;

  cardsStObj: any = {
    searching: 0,
    covered: 0,
    hometime: 0,
    notCovered: 0,
    repair: 0,
    loadIssue: 0,
    driverIssue: 0,
    noStatus: 0,
    utilization: 0
  };
  filterArray: string[] = [];

  mergedArrays: any;

  dateLastTwoWeeks: any = {
    startDate: moment().subtract(2, 'week').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD')
  }

  isOpened: boolean = false;

  constructor(public transformService: TransformService,
              private dispatchersLiveBoardService: DispatchersLiveBoardService,
              private dialog: MatDialog,
              private router: Router) { }

  ngOnChanges() {
    if(this.tableData) {
      this.countStatistics(this.tableData);
      this.mergedArrays = new MatTableDataSource(this.tableData.flat(1));
      console.log(this.tableData)
    }
  };

  countStatistics(arrayOfArrays: any) {
    let searching: number = 0;
    let covered: number = 0;
    let hometime: number = 0;
    let notCovered: number = 0;
    let repair: number = 0;
    let loadIssue: number = 0;
    let driverIssue: number = 0;
    let noStatus: number = 0;
    let utilization: number = 0;

    //Utilization calculation
    let utilizationConditions: string[] = ['truck.svg', 'dispatch-swap.png', 'in-transit.png', 'truck-yellow.svg'];
    let numOfDispatchers: number = arrayOfArrays.length;
  
    for(let key in arrayOfArrays) {
      let numOfTrucks: number = arrayOfArrays[key].length;
      let num: number = 0;
      for(let key2 in arrayOfArrays[key]) {

        if(arrayOfArrays[key][key2].icon_name === 'search.svg') {
          searching++;
        }

        if(arrayOfArrays[key][key2].status_group_name === 'OTR') {
          covered++;
        }

        if(arrayOfArrays[key][key2].status_group_name === 'Hometime') {
          hometime++;
        }
        
        if(arrayOfArrays[key][key2].status_group_name === 'Not covered') {
          notCovered++;
        }

        if(arrayOfArrays[key][key2].status_group_name === 'Repair') {
          repair++;
        }

        if(arrayOfArrays[key][key2].status_group_name === 'Load issue') {
          loadIssue++;
        }
        
        if(arrayOfArrays[key][key2].status_group_name === '') {
          noStatus++;
        }

        if(utilizationConditions.includes(arrayOfArrays[key][key2].icon_name)) {
          num++;
        }

      }
      
      utilization += num * 100 / numOfTrucks;
    }

    this.cardsStObj.searching = searching;
    this.cardsStObj.covered = covered;
    this.cardsStObj.hometime = hometime;
    this.cardsStObj.notCovered = notCovered;
    this.cardsStObj.repair = repair;
    this.cardsStObj.loadIssue = loadIssue;
    this.cardsStObj.driverIssue = driverIssue;
    this.cardsStObj.noStatus = noStatus;
    this.cardsStObj.utilization = this.transformService.addCommasDots(utilization / numOfDispatchers, 'round');
  };

  countUtilization(data: any[]) {
    let numOfTrucks: number = data.length;
    let num: number = 0;
    let utilizationConditions: string[] = ['truck.svg', 'dispatch-swap.png', 'in-transit.png', 'truck-yellow.svg'];
    for(let key in data) {
      if(utilizationConditions.includes(data[key].icon_name)) {
        num++;
      }
    };
    return this.transformService.addCommasDots(num * 100 / numOfTrucks, 'round');
  }

  addStatus(obj: any) {
    this.dispatchersLiveBoardService.getStatusesByTruck(obj.unit_no, this.dateLastTwoWeeks.startDate, this.dateLastTwoWeeks.endDate)
    .subscribe((response: any) => {
      if(!obj.terminated) {
        let data: any = {
          driverInfo: {info: {id: obj.driver_id, name: obj.driver_name, truck_no: obj.unit_no, trailer_no: obj.trailer_no }},
          date: new Date(), 
          allStatusArray: response,
          startDate: this.dateLastTwoWeeks.startDate,
          endDate: this.dateLastTwoWeeks.endDate,
        };

      this.dialog.open(StatusDialogComponent, {
        autoFocus: false,
        panelClass: 'status-dialog-container',
        data: data
      });
    }
    })
  };

//Open in new tab
openInNewTab(route: string) {
  const url = this.router.serializeUrl(this.router.createUrlTree([route]));
  window.open(url, '_blank');
}

//Search
applyFilter(event: Event) {
  const filterValue = (event.target as HTMLInputElement).value;
  this.mergedArrays.filter = filterValue.trim().toLowerCase();
  this.tableData = this.groupByProperty(this.mergedArrays.filteredData);
}

groupByProperty(array: any[]) {
  const groups: any = array.reduce((acc: any, o: any) => {
    if (!acc[o.dispatcher_name]) {
      acc[o.dispatcher_name] = [];
    }
        
    acc[o.dispatcher_name].push(o);

    return acc;

  }, {});

  return Object.values(groups);
};
 
//Check uncheck filter
checkUncheckValue(status: string) {
  let index = this.filterArray.indexOf(status);
  if (index === -1) {
    this.filterArray.push(status);
    this.filterArray = [...this.filterArray];
  } 
  else {
    this.filterArray.splice(index, 1);
    this.filterArray = [...this.filterArray];
  }
};

identify(index, item){
  return index;
};

}

import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { PermissionDeniedDialogComponent } from '@app/modules/shared/components/permission-denied-dialog/permission-denied-dialog.component';

@Injectable({
	providedIn: 'root'
})
export class AuthGuard implements CanActivate {

	constructor(private router: Router, private dialog: MatDialog) { }

	canActivate( route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

		let currentUser = JSON.parse(localStorage.getItem('currentUser'));

		if (currentUser) {
			// logged in so return true
			if(currentUser?.value == '') {
				localStorage.clear();
				this.router.navigate(['/login'])
				return false;
			}

			let rulesArray: any[] = JSON.parse(currentUser.value)?.rules;
			
			if(route.data.roles 
			   && rulesArray[route.data.roles]?.component === 'Dispatch profile' 
			   && currentUser.pt_id !== Number(route.params.id) 
			   && !rulesArray[route.data.roles]?.data[0]?.allowedAll) {
				this.dialog.open(PermissionDeniedDialogComponent, {
					width: '300px',
					autoFocus: false,
					panelClass: 'permission-denied-dialog-container'
				})
				return false;
			}

			if (route.data.roles && rulesArray[route.data.roles].forbidden) {
				// role not authorised so redirect to home page
				this.dialog.open(PermissionDeniedDialogComponent, {
					width: '300px',
					autoFocus: false,
					panelClass: 'permission-denied-dialog-container'
				})
				return false;
			}
	
			return true;
		}
		// not logged in so redirect to login page with the return url
        this.router.navigate(['/login']);
        return false;
	}
}

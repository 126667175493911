import { Injectable } from '@angular/core';
import * as _G from '../../../_config/globals';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Subject, forkJoin } from 'rxjs';

@Injectable()
export class OverviewService {
  headers = new HttpHeaders({
		'Content-Type': 'application/json',
		'Authorization': 'Basic SVZTb2x1dGlvbjoxUzBsdXQxMG4yQDIy'
	});
	requestOptions = { headers: this.headers };

  dateChanged = new Subject<any>();
  lineChartSubject = new Subject<any>();
  dispatchLineChartSubject = new Subject<any>();

  top20StatisticsSubject = new Subject<any>();

  constructor(private http: HttpClient) { }

  getCardsData(startDate: string, endDate: string, startDate2: string, endDate2: string, filterParams: string, office: string) {
    let city: string = office === 'All Offices' ? '' : `&city=${office}`;
    let response1 = this.http.get(`${_G.extendApiUrl}overview?start=${startDate}&end=${endDate}${filterParams}${city}`, this.requestOptions);
    let response2 = this.http.get(`${_G.extendApiUrl}overview?start=${startDate2}&end=${endDate2}${filterParams}${city}`, this.requestOptions);
    return forkJoin([response1, response2]);
  };

  getDriverTurnoverData(startDate: string, endDate: string, filterParams: string) {
    return this.http.get(`${_G.extendApiUrl}recruiting/turnover?start=${startDate}&end=${endDate}${filterParams}`, this.requestOptions);
  };

  getHiredTerminated(startDate: string, endDate: string, filterParams: string) {
    return this.http.get(`${_G.extendApiUrl}drivers/HireTermDriverOverview?start=${startDate}&end=${endDate}${filterParams}`, this.requestOptions);
  };

  getOrientationBreakdown(startDate: string, endDate: string) {
    return this.http.get(`${_G.extendApiUrl}recruiting/orientoverview?startDate=${startDate}&endDate=${endDate}`, this.requestOptions);
  };

  getDispatchTable(startDate: string, endDate: string, filterParams: string, office: string) {
    let city: string = office === 'All Offices' ? '' : `&city=${office}`;
    return this.http.get(`${_G.extendApiUrl}overview/dispoverview?start=${startDate}&end=${endDate}${filterParams}${city}`, this.requestOptions);
  };

}

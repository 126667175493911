import { Component, Input, OnInit } from '@angular/core';
import { PhotosOfInspectionDialogComponent } from '../photos-of-inspection-dialog/photos-of-inspection-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { InspectionItemNoteComponent } from './inspection-item-note/inspection-item-note.component';

@Component({
  selector: 'inspection-items-tab',
  templateUrl: './inspection-items-tab.component.html',
  styleUrls: ['./inspection-items-tab.component.scss']
})
export class InspectionItemsTabComponent implements OnInit {
  @Input() data: any;
  activeItemId: number = 0;

  constructor(private dialog: MatDialog) { }

  ngOnInit(): void {
    if(this.data.DVIRInspectionItems.length > 0) {
      this.activeItemId = this.data.DVIRInspectionItems[0].ID;
    }
  }

  showLatestPhotos() {
    this.dialog.open(PhotosOfInspectionDialogComponent, {
      autoFocus: false,
      panelClass: 'component-dialog-container',
      data: {title: `Inspection Items`, ID: this.activeItemId, inspectionArray: this.data.DVIRInspectionItemsPhotos}
    });
  }

  getStatusValue(status: number | null): string {
    if(status === 0) {
      return 'Good';
    };
    if(status === 1) {
      return 'Follow Up';
    }
    if(status === 2) {
      return 'Bad';
    }
    return 'No status';
  };

  showNoteDialog(element: any) {
    if(element.note !== '') {
      this.dialog.open(InspectionItemNoteComponent, {
        autoFocus: false,
        panelClass: 'component-dialog-container',
        data: element.note
      });
    }
  };

}

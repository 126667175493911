import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ApexAxisChartSeries, ApexPlotOptions, ApexChart, ApexLegend, ApexResponsive, ChartComponent } from 'ng-apexcharts';

export type ChartOptions = {
  series: ApexAxisChartSeries | any;
  colors: string | any;
  plotOptions: ApexPlotOptions;
  chart: ApexChart | any;
  labels: string | any;
  legend: ApexLegend | any;
  responsive: ApexResponsive | any;
};

@Component({
  selector: 'truck-mileage-pie-chart',
  templateUrl: './truck-mileage-pie-chart.component.html',
  styleUrls: ['./truck-mileage-pie-chart.component.scss']
})
export class TruckMileagePieChartComponent implements OnInit {
  @ViewChild("chart") chart: ChartComponent | any;
  public chartOptions: Partial<ChartOptions> | any;
  @Input() data: any;

  constructor() {}

  ngOnInit(): void {
    this.chartOptions = this.pieChart(this.data);
  }

  pieChart(data: any) {
    return {
      colors: ['#ff1515', '#fffc15', '#00be62', '#1c6f46', '#0030FF', '#FF00FF', '#800000'],
      series: [data.total_trucks, data.inactive_trucks, data._2000, data._2000_2500, data._2500_3000, data._3000_3900, data._3900],
      plotOptions: {
        pie: {
          dataLabels: {
            offset: -33,
          }, 
        }
      },
      chart: {
        width: 400,
        type: "pie",
        fontFamily: 'Poppins'
      },
      labels: ['TOTAL TRUCKS', 'INACTIVE TRUCKS','0 - 2000 MILES', '2000 - 2500 MILES', '2500 - 3000 MILES', '3000 - 3900 MILES', '3900+ MILES'],
      legend: {
        position: 'bottom'
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: "bottom"
            }
          }
        }
      ]
    };
    
  };

}



import { environment } from '../../environments/environment'


export const DS = '/';

export const apiUrl = environment.services.webapi;
export const extendApiUrl = environment.services.extend.api;
export const phpBridge = environment.services.bridge.php;
export const apiBridge = environment.services.bridge.api;

export const employee = environment.services.employee;

export const pipeDrive = {
	apiUrl: environment.services.pipedrive.url,
	apiToken: environment.services.pipedrive.token
};


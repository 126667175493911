import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { DocumentsService } from '@app/modules/documents/services/documents.service';

import { MsgForbbidenAccessComponent } from '@app/modules/shared/components/msg-forbbiden-access/msg-forbbiden-access.component';
import { SharedService } from '@app/modules/shared/services/shared.service';


@Component({
  selector: 'app-documents-list-dialog',
  templateUrl: './documents-list-dialog.component.html',
  styleUrls: ['./documents-list-dialog.component.scss']
})
export class DocumentsListDialogComponent implements OnInit {
  filesArray: any[] = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private dialog: MatDialog,
              private sharedService: SharedService,
              private documentsService: DocumentsService) { }

  ngOnInit(): void {
    this.filesArray = this.data.files;
  }

  //Preview file
  previewFile(obj: any) {
    let permissionObj: any = this.isAllowed(this.data.users);
    if(permissionObj.allowedView) {
      obj.pdfLoading = true;
      this.documentsService.getDocument(this.data.id, obj.filename).subscribe((base64: string) => {
        this.sharedService.downloadPreviewFile(obj.filename, base64, permissionObj.allowedDownload);
        obj.pdfLoading = false;
      })
    }
    else {
      this.dialog.open(MsgForbbidenAccessComponent, {
        autoFocus: false,
        panelClass: 'forbidden-msg-dialog-container'
      })
    }
  }

  isAllowed(data: any) {
    let userDepartment: any = JSON.parse(localStorage.getItem('currentUser')).department;
    let jsObj: any = JSON.parse(data);
    let allowedView: boolean = false;
    let allowedDownload: boolean = false;
    if(jsObj.selectedDepartmentsViewArray.includes(userDepartment.trim())) {
      allowedView = true;
    };
    if(jsObj.selectedDepartmentsDownloadArray.includes(userDepartment.trim())) {
      allowedDownload = true;
    };
    return {allowedView: allowedView, allowedDownload: allowedDownload}; 
  };

}
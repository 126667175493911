import { Component, OnInit } from '@angular/core';
import { FleetHealthService } from '@app/modules/fleet-health/services/fleet-health.service';
import { TransformService } from '@app/modules/shared/services/transform.service';
import moment = require('moment');
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-truck-profile-schedule',
  templateUrl: './truck-profile-schedule.component.html',
  styleUrls: ['./truck-profile-schedule.component.scss']
})
export class TruckProfileScheduleComponent implements OnInit {
//Date 
dateObj: any = {
  startDate: this.transformService.convertDateToTimestamp(moment().startOf('month').format('ddd, DD/MM YYYY'), 
  '00:00:00'),
  endDate: this.transformService.convertDateToTimestamp(moment().endOf('month').format('ddd, DD/MM YYYY'),  '23:59:59')
};

//Calendar picker
isOpenFrom: boolean = false;
fromDate: string = 'Choose a date';
dateFromServer: any = '';

isOpenTo: boolean = false;
toDate: string = 'Choose a date';
dateToServer: any = '';

isOpenByDay: boolean = false;
oneDate: string = 'Choose a date';
oneDateServer: any = '';

//Calendar
currentMonth: Array<Date> | any = [];

days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
prevDate: any = new Date();
currentDate: any = new Date();

//Available spots
availableSpotsByRange: string = '';
availableSpotsByDay: string = '';
//Spots array
spotsArray: any[] = [];

//Reservation date
reservationDate: Date = new Date();

//Selected day
selectedDay: any;

//By range
range: boolean = true;

//Schedule
schedule: boolean = true;

//Event menu
eventsArray: any[] = [
  {name: 'PM Service'},
  {name: 'PM Service'},
  {name: 'PM Service'},
  {name: 'PM Service'},
];
isOpenEventMenu: boolean = false;
eventValue: string = '';

//Place menu
placeArray: any[] = [
  {name: 'Yard'},
  {name: 'Yard'},
  {name: 'Yard'},
  {name: 'Yard'},
];
isOpenPlaceMenu: boolean = false;
placeValue: string = 'Yard';

//Schedule by
scheduleBy: boolean = true;

//Subscription
subscription: Subscription | any;

constructor(private transformService: TransformService, private fleetHealthService: FleetHealthService) { }

ngOnInit(): void {
  this.getCalendarData();
}

getCalendarData() {
  this.setDate(this.currentDate);
}

setMonth(prev: boolean) {
  let month: any =  '';
  if(prev) {
    month = this.currentDate.getMonth() - 1;
    this.dateObj.startDate = this.transformService.convertDateToTimestamp(moment(this.dateObj.startDate).subtract(1, "month").startOf('month').format('ddd, DD/MM YYYY'), 
    '00:00:00');
    this.dateObj.endDate = this.transformService.convertDateToTimestamp(moment(this.dateObj.endDate).subtract(1, "month").endOf('month').format('ddd, DD/MM YYYY'),  '23:59:59');
  }
  else {
    month = this.currentDate.getMonth() + 1;
    this.dateObj.startDate = this.transformService.convertDateToTimestamp(moment(this.dateObj.startDate).add(1, "month").startOf('month').format('ddd, DD/MM YYYY'), 
    '00:00:00');
    this.dateObj.endDate = this.transformService.convertDateToTimestamp(moment(this.dateObj.endDate).add(1, "month").endOf('month').format('ddd, DD/MM YYYY'),  '23:59:59');
  }
  let d = this.currentDate.setMonth(month);
  this.currentDate = new Date(d);
  this.getCalendarData();
}

setDate(date: any) {
  let dateObj: any = this.getDaysInMonth(date);
  if (dateObj.firstDay === 1) {
    this.currentMonth = dateObj.dateArray;
  } 
  else {
  this.currentMonth = dateObj.dateArray;
  for (let i = 1; i < dateObj.firstDay; i++) {
    this.currentMonth.unshift({date: '-', id: 0, available: null, created_by: null, created_date: null, edited_by: null, edited_date: null});
  }
  }
}

getDaysInMonth(date: any) {
  let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  return { firstDay: this.getLocalDay(firstDay.getDay()), lastDay: lastDay.getDate(), dateArray: this.getDatesInRange(firstDay, lastDay)}
}

getDatesInRange(startDate: any, endDate: any) {
  const date = new Date(startDate.getTime());
  const dates = [];
  while (date <= endDate) {
    let d1 = this.transformService.convertDateToTimestamp(moment(date).format('ddd, DD/MM YYYY'), '00:00:00');
    let obj: any;
    let result = this.spotsArray.some((currObj, arrIndex) => { 
      obj = currObj
    return currObj.date == d1;
    })
    if(result) {
      obj.date = d1;
      console.log(obj)
      dates.push(obj);
    } else {
      dates.push({date: d1, id: 0, available: null, created_by: null, created_date: null, edited_by: null, edited_date: null});
    }
    date.setDate(date.getDate() + 1);
  }
  return dates;
}

getLocalDay(day: number) {
  return day === 0 ? day = 7 : day;
}

makeReservation(obj: any) {
  console.log(obj.date);
  this.selectedDay = obj.date;
  this.fleetHealthService.dateSubject.next(obj.date);
};

getToday(obj: any) {
  if(moment(obj.date).format('MM-DD-yyyy') === moment().format('MM-DD-yyyy')) {
    return true;
  }
  else {
    return false;
  }
};

setClass(obj: any) {
  if(moment(obj.date).format('MM-DD-yyyy') === moment().format('MM-DD-yyyy')) {
    return 'blue-border';
  }
  else if(obj.available === 0) {
    return 'restricted-color';
  }
  else {
    return '';
  }
};

getColorForPast(date: string) {
  if(new Date(date).getTime() < new Date().getTime()) {
    return 'grey-color';
  }
  else {
    return 'blue-color'
  }
}

dateClicked(event: any, calendarNum: number) {
  if(calendarNum === 1) {
    this.fromDate = moment(event._d).format('MMM DD, yyyy.');
    this.dateFromServer = this.transformService.convertDateToTimestamp(moment(event._d).format('ddd, DD/MM YYYY'), '00:00:00');
    this.isOpenFrom = false;
  } 
  else if(calendarNum === 2) {
    this.toDate = moment(event._d).format('MMM DD, yyyy.');
    this.dateToServer = this.transformService.convertDateToTimestamp(moment(event._d).format('ddd, DD/MM YYYY'),  moment().format('00:00:00'));
    this.isOpenTo = false;
  }
  else {
    this.oneDate = moment(event._d).format('MMM DD, yyyy.');
    this.oneDateServer = this.transformService.convertDateToTimestamp(moment(event._d).format('ddd, DD/MM YYYY'),  moment().format('00:00:00'));
    this.isOpenByDay = false;
  }
}

//Save changes
saveChange() {
  let loggedUser: any = JSON.parse(localStorage.getItem('currentUser'));
  let creator: string = loggedUser.first_name + ' ' + loggedUser.last_name;
  let createdDate: string = this.transformService.convertDateToTimestamp(moment(new Date()).format('ddd, DD/MM YYYY'),  moment(new Date()).format('HH:mm:ss'));
  let fromDate: any = this.range ? this.dateFromServer : this.oneDateServer;
  let toDate: any = this.range ? this.dateToServer : this.oneDateServer;
  let availableSpots: any = this.range ? this.availableSpotsByRange : this.availableSpotsByDay;

  let arrayServer: any[] = [];
  let monthArray: any[] = [...this.currentMonth];

  monthArray.forEach(element => {

    if(new Date(element.date).getTime() >= new Date(fromDate).getTime() 
    && new Date(element.date).getTime() <= new Date(toDate).getTime()) {
      element.available = Number(availableSpots);

      if(!element.id) {
        element.created_by = creator;
        element.created_date = createdDate;
      }
      else {
        element.edited_by = creator;
        element.edited_date = createdDate;
      }
      
      arrayServer.push(element);
    }

  });

  this.fleetHealthService.setUpdateSpots(arrayServer).subscribe((response: any) => {
    console.log(response);
    if(response) {
      this.currentMonth = monthArray;
    }
  })
}

//Selected
selectPeriod(obj: any, menu: boolean) {

};

ngOnDestroy(): void {
  this.subscription?.unsubscribe();
}

}

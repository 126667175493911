import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { NotCoveredTrucksRoutingModule } from './not-covered-trucks.routing';
import { LostLoadPerDispatcherTableComponent } from './components/lost-load-per-dispatcher-table/lost-load-per-dispatcher-table.component';
import { LostLoadPerDriverTableComponent } from './components/lost-load-per-driver-table/lost-load-per-driver-table.component';
import { NotCoveredCalendarDialogComponent } from './components/not-covered-calendar-dialog/not-covered-calendar-dialog.component';
import { NotCoveredCardsLineChartComponent } from './components/not-covered-cards-line-chart/not-covered-cards-line-chart.component';
import { NotCoveredDispatchersLineChartComponent } from './components/not-covered-dispatchers-line-chart/not-covered-dispatchers-line-chart.component';
import { NotCoveredDriversLineChartComponent } from './components/not-covered-drivers-line-chart/not-covered-drivers-line-chart.component';
import { NotCoveredPieChartComponent } from './components/not-covered-pie-chart/not-covered-pie-chart.component';
import { NotCoveredTrucksCardsComponent } from './components/not-covered-trucks-cards/not-covered-trucks-cards.component';
import { NotCoveredTrucksPageComponent } from './components/not-covered-trucks-page.component';
import { NotCoveredTrucksService } from './services/not-covered-trucks.service';



@NgModule({
  declarations: [
    NotCoveredTrucksPageComponent,
    LostLoadPerDispatcherTableComponent,
    LostLoadPerDriverTableComponent,
    NotCoveredCalendarDialogComponent,
    NotCoveredCardsLineChartComponent,
    NotCoveredDispatchersLineChartComponent,
    NotCoveredDriversLineChartComponent,
    NotCoveredPieChartComponent,
    NotCoveredTrucksCardsComponent
  ],
  imports: [
    CommonModule,
    NotCoveredTrucksRoutingModule,
    SharedModule
  ],
  providers: [NotCoveredTrucksService]
})
export class NotCoveredTrucksModule { }

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SharedService } from '@app/modules/shared/services/shared.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { catchError, Subscription, throwError } from 'rxjs';
import { DriversProfileService } from '../services/drivers-profile.service';
import { TransformService } from '@app/modules/shared/services/transform.service';

@Component({
  selector: 'app-dashboard-driver-profile-page',
  templateUrl: './dashboard-driver-profile-page.component.html',
  styleUrls: ['./dashboard-driver-profile-page.component.scss']
})
export class DashboardDriverProfilePageComponent implements OnInit, OnDestroy {
  driverInfo: any; 

  driverFirstLastName: any;

  loaded: boolean = false;

  error: boolean = false;

  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";

  spinnerBgColor: string = this.transformService.spinnerBgColor;
  spinnerType: string = this.transformService.spinnerType;

  subscription: Subscription | any;

  constructor(private driversProfileService: DriversProfileService, 
              private route: ActivatedRoute,
              private transformService: TransformService,
              private spinner: NgxSpinnerService) {}

  ngOnInit(): void {
    this.getProfileData();
  }

  getProfileData() {
    this.spinner.show('driver-profile');
    let id: string = this.route.snapshot.paramMap.get('id');
    this.subscription = this.driversProfileService.getDriverProfileData(+id)
    .pipe(catchError((err: any) => {
      this.spinner.hide('driver-profile');
      this.loaded = true;
      this.error = true;
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      this.driverInfo = response;
      this.driverFirstLastName = response.info.name;
      this.spinner.hide('driver-profile');
      this.loaded = true;
      console.log(this.driverInfo)
    });
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  };

}

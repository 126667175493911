import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { Subscription } from 'rxjs';
import { TemplateComponent } from '../../shared/components/template/template.component';
import { FullAppService } from '@app/modules/full-app/services/full-app.service';
import { IDeal } from '@app/modules/full-app/models/deal-model';
import { IFields } from '@app/modules/full-app/models/template-fields.model';
import { ITemplate } from '@app/modules/full-app/models/template-model';
import { SuccessDialogComponent } from '@app/modules/shared/components/success-dialog/success-dialog.component';

@Component({
  selector: 'full-application-deal',
  templateUrl: './full-application-deal.component.html',
  styleUrls: ['./full-application-deal.component.scss']
})
export class FullApplicationDealComponent implements OnChanges, OnDestroy {
  @Input() id: any;

  //Deal
  dealArray: IFields[] = [];
  dealObj: IDeal;

  //Template list
  dropdownValue: string = '';
  dropdownArray: ITemplate[] = [];
  isOpenDropdownMenu: boolean = false;
  selectedIndex: number = -1;

  //Subscription
  subscription: Subscription = new Subscription();

  constructor(private dialog: MatDialog, private fullAppService: FullAppService) { }

  ngOnChanges(): void {
    if(this.id) {
      this.getDealAndTemplates();
    };
  }

  getDealAndTemplates() {
    this.subscription = this.fullAppService.getDealAndTemplates(this.id).subscribe((response: any[]) => {
      this.dropdownValue = '';
      this.dealObj = response[0];
      this.dealArray = response[0].fileds;
      this.dropdownArray = response[1];
      for(let i = 0; i < response[1].length; i++) {
        if(response[0].template_id === response[1][i].id) {
          this.dropdownValue = response[1][i].template_name;
          this.selectedIndex = i;
          break;
        }
      }
    });
  }

  createTemplate() {
    let dialogRef = this.dialog.open(TemplateComponent, {
      autoFocus: false,
      panelClass: 'template-dialog-container',
      data: this.selectedIndex
    });
    dialogRef.afterClosed().subscribe((response: boolean) => {
      if(response) {
        this.getDealAndTemplates();
      }
    });
  };

  //Choose template
  chooseTemplate(obj: ITemplate, index: number) {
    console.log(obj);
    this.dropdownValue = obj.template_name; 
    this.isOpenDropdownMenu = false;
    let copyObj = {...obj};
    this.dealObj = {
      id: 0,
      driver_id: this.id,
      template_id: copyObj.id,
      fileds: copyObj.fileds
    }
    this.dealArray = copyObj.fileds;
    this.selectedIndex = index;
  };

  saveDeal() {
    this.fullAppService.createUpdateDeal(this.dealObj).subscribe((response: IDeal) => {
      this.dealObj = response;
      this.dealArray = response.fileds;
      if(response) {
        this.dialog.open(SuccessDialogComponent, {
          autoFocus: false,
          panelClass: 'success-dialog-container'
        });  
      }
      console.log(response);
    })
  };

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  };

}

import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FullAppService } from '@app/modules/full-app/services/full-app.service';
import moment = require('moment');
import { IFile } from '@app/modules/full-app/models/file-group.model';
import { CreateUpdateGroupComponent } from './create-update-group/create-update-group.component';
import { Subscription } from 'rxjs';
import { IPaperwork } from '@app/modules/full-app/models/paperwork.model';
import { DeleteConfirmationDialogComponent } from '@app/modules/shared/components/delete-confirmation-dialog/delete-confirmation-dialog.component';

@Component({
  selector: 'app-full-app-paperwork-dialog',
  templateUrl: './full-app-paperwork-dialog.component.html',
  styleUrls: ['./full-app-paperwork-dialog.component.scss']
})
export class FullAppPaperworkDialogComponent implements OnInit, OnDestroy {
  formGroup: FormGroup;
  userData: any = JSON.parse(localStorage.getItem('currentUser'));

  //Documents dropdown
  documentsDropdownArray: IFile[] = [];
  isOpenDocumentsMenu: boolean = false;

  //Files
  icon: string = 'insert_drive_file';

  //Subscription
  subscription: Subscription = new Subscription();

  constructor(@Inject(MAT_DIALOG_DATA) public driverId: number,
              private dialog: MatDialog,
              private formBuilder: FormBuilder, 
              private fullAppService: FullAppService,
              public dialogRef: MatDialogRef<FullAppPaperworkDialogComponent>) {
              this.formGroup = this.formBuilder.group({
                'id': [0],
                'driver_id': [this.driverId],
                'document_group': [''],
                'document_name': [''],
                'upload_date': [moment().format('MMM DD, yyyy.')],
                'upload_by': this.userData.first_name + ' ' + this.userData.last_name,
                'is_ok': false,
                'file': this.formBuilder.array([])
              })
  }

  ngOnInit(): void {
    this.getPredefiniedDocumentsGroup();
  }

  getPredefiniedDocumentsGroup() {
    this.subscription = this.fullAppService.getAllFilesGroup().subscribe((response: IFile[]) => {
      console.log(response);
      this.documentsDropdownArray = response;
    });
  }

  //Select files
  onFileSelected(event: any) {  
    let length: any = Object.keys(event.target.files).length;
    for(let i = 0; i < length; i++) {
      this.addMultipleFiles(event, i);
    }
  }

  addMultipleFiles(event: any, index: number) {
    let filename = event.target.files[index].name;
    let reader = new FileReader();
    reader.onload = (e: any) => {
      let base64 = e.target.result.split(',')[1];
      this.filesArray.push(
        this.formBuilder.group({
          'category': '',
          'name': filename,
          'data': base64,
          'updated': false,
          'deleted': false
      }))
    };
    reader.readAsDataURL(event.target.files[index]);
  };

  removeFile(index: number) {
    this.filesArray.removeAt(index);
  };

  createUpdatePredefiniedDocumentGroup(obj: IFile) {
    this.isOpenDocumentsMenu = !this.isOpenDocumentsMenu;
    let dialogRef = this.dialog.open(CreateUpdateGroupComponent, {
      autoFocus: false,
      panelClass: 'component-dialog-container',
      data: obj
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
        this.getPredefiniedDocumentsGroup();
      }
    });
  };

  deletePredefiniedDocumentGroup(id: number, index: number) {
    let dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
      autoFocus: false,
      panelClass: 'delete-dialog-container',
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
        this.fullAppService.deleteFileGroup(id).subscribe((response: boolean) => {
          if(response) {
            this.documentsDropdownArray.splice(index, 1);
            this.isOpenDocumentsMenu = !this.isOpenDocumentsMenu;
          }
        });
      }
    });
  };


  createPaperwork() {
    let formValue: any = this.formGroup.value;
    let obj: IPaperwork = {
      id: formValue.id,
      driver_id: formValue.driver_id,
      document_group: formValue.document_group,
      document_name: formValue.document_name,
      upload_date: moment().format('YYYY-MM-DDT00:00:00'),
      upload_by: formValue.upload_by,
      is_ok: formValue.is_ok,
      file: null
    }

    for(let i = 0; i < formValue.file.length; i++) {
      obj.document_name = formValue.file[i].name
      obj.file = formValue.file[i];
      obj.is_ok = true;
      console.log(obj);
      this.fullAppService.createUpdatePaperwork(obj).subscribe((response: any) => {
        console.log(response);
      });
    };
  };
  
  get documentGroup(): string {
    return this.formGroup.controls['document_group'].value;
  };

  get filesArray() : FormArray {
    return this.formGroup.controls['file'] as FormArray;
  };

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  };

}

import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Sort } from '@angular/material/sort';
import moment = require('moment');
import { Subscription, catchError, throwError } from 'rxjs';
import * as XLSX from 'xlsx';
import { CalendarDialogComponent } from '../calendar-dialog/calendar-dialog.component';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { TransformService } from '../../services/transform.service';
import { SharedService } from '../../services/shared.service';
import { RulesService } from '../../services/rules.service';
import { TitleCasePipe } from '@angular/common';
import { MsgForbbidenAccessComponent } from '../msg-forbbiden-access/msg-forbbiden-access.component';

@Component({
  selector: 'app-company-benefit-miles-dialog',
  templateUrl: './company-benefit-miles-dialog.component.html',
  styleUrls: ['./company-benefit-miles-dialog.component.scss']
})
export class CompanyBenefitMilesDialogComponent implements OnInit, OnDestroy {
  //Date
  dateObj: any = {
    startDate: this.transformService.convertDateToTimestamp(moment().startOf('month').format('ddd, DD/MM YYYY'), 
    '00:00:00'),
    endDate: this.transformService.convertDateToTimestamp(moment().format('ddd, DD/MM YYYY'), moment().format('HH:mm:ss')),
  };

  //Table data
  displayedColumns: string[] = ['position', 'driver', 'dispatcher', 'unit_no', 'type', 'hire_date', 'deal_billable', 'deal_paid',
  'billable_mileage', 'paid_mileage', 'company_benefit_mileage', 'company_benefit_amount'];

  dataSource: any[] = [];
  sortedData: any[] = [];

  //Search inpt
  searchText: string = '';
  isOpen: boolean = false;
  selectedUsers: any[] = new Array<any>();
  dataTypingArray: any[] = [];

  //Column name
  className: string = '';

  columnName: string = '';

  //Calculation obj
  calculationObj: any = {
    numOfDrivers: 0,
    dealBillable: 0,
    dealPaid: 0,
    billableMileage: 0,
    paidMiles: 0,
    companuBenefitMiles: 0,
    companyBenefitAmount: 0
  };

  //Filter table
  statusArray: any[] = [
    {name: 'All', filterNum: 0},
    {name: 'Active', filterNum: 1},
    {name: 'Inactive', filterNum: 2}
  ];
  filterValue: string = 'Active';
  isOpenFilterMenu: boolean = false;
  activeNum: number = 1;

  //Export to excell
  fileName = 'drivers-table.xlsx';

  //Section
  sectionArray: any[] = [
    {type: undefined, title: 'Company drivers', typeArray: ['All', 'Solo Company', 'Team Company']},
    {type: true, title: 'Rent to Run drivers', typeArray: ['All', 'Solo Rent', 'Team Rent']},
    {type: false, title: 'Owner drivers', typeArray: ['All', 'Owner Operator']},
  ];

  //Type array
  typeValue: string = this.sectionArray[this.typeIndex].typeArray[1];
  isOpenTypeMenu: boolean = false;

  //Excel config
  xlsxConfig: any = [
    {columnName: 'No.', selected: true},
    {columnName: 'Driver', selected: true},
    {columnName: 'Dispatcher', selected: true},
    {columnName: 'Truck Number', selected: true},
    {columnName: 'Type', selected: true},
    {columnName: 'Hiring Date', selected: true},
    {columnName: 'Deal Billable', selected: true},
    {columnName: 'Deal Paid', selected: true},
    {columnName: 'Billable Mileage', selected: true},
    {columnName: 'Paid Miles', selected: true},
    {columnName: 'Company Benefit Miles', selected: true},
    {columnName: 'Company Benefit Amount', selected: true}
  ];

  //Interval
  interval: any;

  //Spinner
  loaded: boolean = false;

  error: boolean = false;

  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";

  spinnerBgColor: string = this.transformService.spinnerBgColor;
  spinnerType: string = this.transformService.spinnerType;

  //Search type
  searchTypeArray: any[] = [
    {type: 'Drivers'},
    {type: 'Dispatchers'},
    {type: 'Trucks'},
  ];
  searchType: number = 0;
  typeKey: string = 'driver';

  //Subscription
  subscription: Subscription | any;

  constructor(@Inject(MAT_DIALOG_DATA) public typeIndex: number,
              public transformService: TransformService,
              private dialog: MatDialog, 
              private sharedService: SharedService,
              private spinner: NgxSpinnerService,
              private rulesService: RulesService,
              private titleCase: TitleCasePipe) { }

  ngOnInit(): void {
    this.spinner.show('cbm-spinner');
    this.getTableData();
    if(this.rulesService.liveUpdate) {
      this.interval = setInterval(() => {
        this.getTableData();
      }, this.rulesService.miliseconds);
    }
  }

  getTableData() {
    this.subscription = this.sharedService.getCompanyBenefitMiles(this.dateObj.startDate, this.dateObj.endDate, this.activeNum, this.sectionArray[this.typeIndex].type, this.transformService.filterParams)
    .pipe(catchError((err: any) => {
      this.spinner.hide('cbm-spinner');
      this.loaded = true;
      this.error = true;
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      console.log(response);
      this.dataSource = JSON.parse(JSON.stringify(response));
      this.filterTable(this.typeValue);
      this.spinner.hide('cbm-spinner');
      this.loaded = true;
    })

  };

  //Show driver by status
  showDriverByStatus(status: any) {
    this.filterValue = status.name;
    this.activeNum = status.filterNum;
    this.isOpenFilterMenu = false;
    this.dataSource = [];
    this.sortedData = [];
    this.dataTypingArray = [];

    this.error = false;
    this.loaded = false;

    this.spinner.show('cbm-spinner');
    this.getTableData();
  } 

  //Filter table data
  filterTable(type: string) {
    let allDrivers: any[] = JSON.parse(JSON.stringify(this.dataSource));

    let filteredArray: any[] = [];

    if(type === 'All') {
      filteredArray = allDrivers;
    }
    else {

      for(let key in allDrivers) {

        if(type !== 'All' && allDrivers[key].type.toLowerCase() === type.toLowerCase()) {
          filteredArray.push(allDrivers[key]);
        }

      }

    }
    this.getTotal(filteredArray);
    this.sortedData = filteredArray;
    this.dataTypingArray = JSON.parse(JSON.stringify(filteredArray));
    this.typeValue = type;
    this.isOpenTypeMenu = false;
  };

  //Search drivers
  toggleSelection(user: any) {
    user.selected = !user.selected;
    if (user.selected) {
      this.selectedUsers.push(user);
      this.sortedData = JSON.parse(JSON.stringify(this.selectedUsers));
    } else {
      const i = this.selectedUsers.findIndex(value => value[this.typeKey] === user[this.typeKey]);
      this.selectedUsers.splice(i, 1);
      this.sortedData = JSON.parse(JSON.stringify(this.selectedUsers));
    }
    this.getTotal(this.sortedData);
    this.sortedData.length === 0 ? this.filterTable(this.typeValue): this.sortedData
  }

  resetCheckedValue() {
    this.filterTable(this.typeValue);
    this.searchText = '';
    this.selectedUsers = [];
    this.getTotal(this.sortedData);
  }

  //Hover effect
  onMouseOver(columnName: string) {
    this.columnName = columnName;
    this.className = 'hovered-column';
  }

  onMouseOut(columnName: string) {
    this.columnName = columnName;
    this.className = '';
  }

  //Get sign
  getSign(value: any) {
    let showValue: string = '';
    if(this.typeIndex === 0) {
      showValue = '$' + value;
    }
    else {
      showValue = value + '%'
    }
    return showValue;
  }

  //Sort table
  sortData(sort: Sort) {
    const data = this.selectedUsers.length > 0 ? this.selectedUsers.slice() : this.sortedData.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }
    this.sortedData = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'driver':
          return compare(a.driver, b.driver, isAsc);
        case 'dispatcher':
          return compare(a.dispatcher, b.dispatcher, isAsc);
        case 'unit_no':
          return compare(a.unit_no, b.unit_no, isAsc);
        case 'type':
          return compare(a.type, b.type, isAsc);
        case 'hire_date':
          return compare(a.hire_date, b.hire_date, isAsc);
        case 'deal_billable':
          return compare(a.deal_billable, b.deal_billable, isAsc);
        case 'deal_paid':
          return compare(a.deal_paid, b.deal_paid, isAsc);
        case 'billable_mileage':
          return compare(a.billable_mileage, b.billable_mileage, isAsc);
        case 'paid_mileage':
          return compare(a.paid_mileage, b.paid_mileage, isAsc);
        case 'company_benefit_mileage':
          return compare(a.company_benefit_mileage, b.company_benefit_mileage, isAsc);
        case 'company_benefit_amount':
          return compare(a.company_benefit_amount, b.company_benefit_amount, isAsc);
        default:
          return 0;
      }
    });
  }

  //Get total data
  getTotal(data: any[]) {
    let totalDrivers: number = data.length;
    let avgDealPaidDrivers: number = 0;
    let avgBillableDrivers: number = 0;

    let sumDealBillable: number = 0;
    let sumDealPaid: number = 0;
    let sumBillableMileage: number = 0;
    let sumPaidMiles: number = 0;
    let sumCompanuBenefitMiles: number = 0;
    let sumCompanyBenefitAmount: number = 0;

    for(let key in data) {
      sumDealBillable += data[key].deal_billable;
      sumDealPaid += data[key].deal_paid;
      sumBillableMileage += data[key].billable_mileage;
      sumPaidMiles += data[key].paid_mileage;
      sumCompanuBenefitMiles += data[key].company_benefit_mileage;
      sumCompanyBenefitAmount += data[key].company_benefit_amount;
      
      if(data[key].deal_paid !== 0) {
        avgDealPaidDrivers++;
      };

      if(data[key].deal_billable !== 0) {
        avgBillableDrivers++;
      };

    };

    this.calculationObj.numOfDrivers = this.transformService.addCommasDots(totalDrivers, 'round');
    this.calculationObj.dealBillable = this.transformService.addCommasDots(sumDealBillable / avgBillableDrivers);
    this.calculationObj.dealPaid = this.transformService.addCommasDots(sumDealPaid / avgDealPaidDrivers);
    this.calculationObj.billableMileage = this.transformService.addCommasDots(sumBillableMileage, 'round');
    this.calculationObj.paidMiles = this.transformService.addCommasDots(sumPaidMiles, 'round');
    this.calculationObj.companuBenefitMiles = this.transformService.addCommasDots(sumCompanuBenefitMiles, 'round');
    this.calculationObj.companyBenefitAmount = this.transformService.addCommasDots(sumCompanyBenefitAmount, 'round');

  };

  //Export table to excell
  exportToExcel(columnsConfig: any[]) {
    if(this.rulesService.UserData[56].data[0].sectionArray[43].allowed) {
      const excelTable: any[] = [];
      for(let i = 0; i < this.sortedData.length; i++) {
        let obj: any = {};
        this.transformService.selectedColumn(obj, columnsConfig[0].columnName, i + 1, columnsConfig[0].selected);
        this.transformService.selectedColumn(obj, columnsConfig[1].columnName, this.titleCase.transform(this.sortedData[i].driver), columnsConfig[1].selected);
        this.transformService.selectedColumn(obj, columnsConfig[2].columnName, this.titleCase.transform(this.sortedData[i].dispatcher), columnsConfig[2].selected);
        this.transformService.selectedColumn(obj, columnsConfig[3].columnName, this.sortedData[i].unit_no, columnsConfig[3].selected);
        this.transformService.selectedColumn(obj, columnsConfig[4].columnName, this.titleCase.transform(this.sortedData[i].type), columnsConfig[4].selected);
        this.transformService.selectedColumn(obj, columnsConfig[5].columnName, this.transformService.transformDateFormat(this.sortedData[i].hire_date, 'MMM DD, YYYY'), columnsConfig[5].selected);
        this.transformService.selectedColumn(obj, columnsConfig[6].columnName, this.getSign(this.transformService.addCommasDots(this.sortedData[i].deal_billable)), columnsConfig[6].selected);
        this.transformService.selectedColumn(obj, columnsConfig[7].columnName, this.getSign(this.transformService.addCommasDots(this.sortedData[i].deal_paid)), columnsConfig[7].selected);        
        this.transformService.selectedColumn(obj, columnsConfig[8].columnName, this.transformService.addCommasDots(this.sortedData[i].billable_mileage, 'round'), columnsConfig[8].selected);
        this.transformService.selectedColumn(obj, columnsConfig[9].columnName, this.transformService.addCommasDots(this.sortedData[i].paid_mileage, 'round'), columnsConfig[9].selected);
        this.transformService.selectedColumn(obj, columnsConfig[10].columnName, this.transformService.addCommasDots(this.sortedData[i].company_benefit_mileage, 'round'), columnsConfig[10].selected);
        this.transformService.selectedColumn(obj, columnsConfig[11].columnName, this.getSign(this.transformService.addCommasDots(this.sortedData[i].company_benefit_amount, 'round')), columnsConfig[11].selected);
        excelTable.push(obj);
      };
      let footerObj: any = {};
      this.transformService.selectedColumn(footerObj, columnsConfig[0].columnName, this.calculationObj.numOfDriver, columnsConfig[0].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[1].columnName, '', columnsConfig[1].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[2].columnName, '', columnsConfig[2].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[3].columnName, '', columnsConfig[3].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[4].columnName, '', columnsConfig[4].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[5].columnName, '', columnsConfig[5].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[6].columnName, this.getSign(this.calculationObj.dealBillable === 'NaN' ? 0 : this.calculationObj.dealBillable), columnsConfig[6].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[7].columnName, this.getSign(this.calculationObj.dealPaid === 'NaN' ? 0 : this.calculationObj.dealPaid), columnsConfig[7].selected);        
      this.transformService.selectedColumn(footerObj, columnsConfig[8].columnName, this.calculationObj.billableMileage, columnsConfig[8].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[9].columnName, this.calculationObj.paidMiles, columnsConfig[9].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[10].columnName, this.calculationObj.companuBenefitMiles, columnsConfig[10].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[11].columnName, this.getSign(this.calculationObj.companyBenefitAmount), columnsConfig[11].selected);
      excelTable.push(footerObj);
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelTable);
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, 'company-benefit-miles-table.xlsx');
    }
    else {
      this.msgForbbidenAccess();
    }
  };

  msgForbbidenAccess() {
    this.dialog.open(MsgForbbidenAccessComponent, {
      autoFocus: false,
      panelClass: 'forbidden-msg-dialog-container'
    })
  };

  transformDateFormat(date: any, format: string) {
    if(date) {
      return moment(date).format(format);
    }
    return '';
  };

  //Open calendar
  openDialog() {
    let dialogRef: any = this.dialog.open(CalendarDialogComponent, {
      width: '769px',
      height: '476px',
      autoFocus: false,
      data: {oneRange: true},
      panelClass: 'calendar-dialog-container'
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.dateObj.startDate = result.startDate;
        this.dateObj.endDate = result.endDate;
        this.dataSource = [];
        this.sortedData = [];
        this.dataTypingArray = [];

        this.error = false;
        this.loaded = false;
  
        this.spinner.show('cbm-spinner');
        this.getTableData();
      }
    });
  }

  //Change search type 
  changeType(i: number) {
    this.searchType = i;
    if(i === 0) {
      this.typeKey = 'driver';
    };

    if(i === 1) {
      this.typeKey = 'dispatcher';
    };

    if(i === 2) {
      this.typeKey = 'unit_no';
    }

    this.resetCheckedValue();
    
  };

  ngOnDestroy(): void {
    clearInterval(this.interval);
    this.subscription?.unsubscribe();
  };

}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

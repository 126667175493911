import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sort'
})
export class SortPipe implements PipeTransform {

  transform(array: any[]): any {
    if (!array) {
      return [];
    }

    return array.sort((a: any, b: any) => {
      return a.supervisor - b.supervisor;
    });
  }

}

import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-create-truck-thread-dialog',
  templateUrl: './create-truck-thread-dialog.component.html',
  styleUrls: ['./create-truck-thread-dialog.component.scss']
})
export class CreateTruckThreadDialogComponent implements OnInit {
  subject: string = '';

  constructor(public dialogRef: MatDialogRef<CreateTruckThreadDialogComponent>) { }

  ngOnInit(): void {
    
  }

  create() {
    this.dialogRef.close({subject: this.subject});
  }

}

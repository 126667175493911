import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SafetyService } from '@app/modules/safety/services/safety.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-truck-devices-add-edit-dialog',
  templateUrl: './truck-devices-add-edit-dialog.component.html',
  styleUrls: ['./truck-devices-add-edit-dialog.component.scss']
})
export class TruckDevicesAddEditDialogComponent implements OnInit, OnDestroy {
  //Search trucks
  trucksSearch: string = '';

  //Search drives
  driversSearch: string = '';

  //Truck no
  trucksNoArray: string[] = [];
  truckNo: string = 'Select an option';
  truckValue: string = '';
  isOpen: boolean = false

  //Driver
  driversArray: string[] = [];
  driverName: string = 'Select an option';
  driverValue: string = '';
  driverId: number | undefined;
  isOpen1: boolean = false

  //Device id
  deviceId: string = '';

  //Status
  status: boolean = false;

  subscription: Subscription | any;

  constructor(@Inject(MAT_DIALOG_DATA) public obj: any,
              private safetyService: SafetyService) { }

  ngOnInit() {
    this.subscription = this.safetyService.getDropDownMenuData().subscribe((response: any) => {
      this.trucksNoArray = response[0];
      response[1].forEach(element => {
          element.fullName = element.first_name + ' ' + element.last_name;
      });
      this.driversArray = response[1];
      console.log(response);
    })

    if(!this.obj.newTruck) {
      this.truckNo = this.obj.data.truckID;
      this.truckValue = this.obj.data.truckID;
      this.driverName = this.obj.data.driverName;
      this.driverValue = this.obj.data.driverName;
      this.deviceId = this.obj.data.deviceID;
      this.status = this.obj.data.status;
    }
  };

  setValue(value: any, truckNo: boolean) {
    if(truckNo) {
      this.truckNo = value;
      this.truckValue = value;
      this.isOpen = false;
    }
    else {
      this.driverName = value.fullName;
      this.driverValue = value.fullName;
      this.driverId = value.id;
      this.isOpen1 = false;
    }
  }

  //Create row in table
  create() {
    let obj: any = {
      ID: null,
      truckID: this.truckValue,
      deviceID: this.deviceId,
      status: this.status ? 1 : 0,
      driverName: this.driverValue,
      driverID: this.driverValue === '' ? '' : this.driverId
    }
    console.log(obj);
    if(this.obj.newTruck) {
      this.safetyService.addTruckDevice(obj).subscribe((response: any) => {
        if(response) {
          this.safetyService.newTruckDeviceAdded.next(true);
        }
        console.log(response);
      });
    } 
    else {
      obj.ID = this.obj.data.ID;
      this.safetyService.editTruckDevice(obj).subscribe((response: any) => {
        if(response) {
          this.safetyService.newTruckDeviceAdded.next(true);
        }
        console.log(response);
      });
    }
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

}

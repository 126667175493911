import { Injectable } from '@angular/core';
import * as _G from '../../../_config/globals';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Subject, forkJoin } from 'rxjs';

@Injectable()
export class FleetHealthService {
  headers = new HttpHeaders({
		'Content-Type': 'application/json',
		'Authorization': 'Basic SVZTb2x1dGlvbjoxUzBsdXQxMG4yQDIy'
	});
	requestOptions = { headers: this.headers };

  dateSubject: any = new Subject<any>();
  refreshDataSubject: any = new Subject<any>();
  dataReturnedSubject: any = new Subject<any>();

  constructor(private http: HttpClient) { }

  //Schedule page
  getScheduleCalendarData(startDate: string, endDate: string) {
    return this.http.get(`${_G.extendApiUrl}mitf/getScheduledbyrande?start=${startDate}&end=${endDate}`, this.requestOptions);
  };

  //Set update spots
  setUpdateSpots(array: any[]) {
    let json: any = JSON.stringify(array);
    return this.http.post(`${_G.extendApiUrl}mitf/updateavailability`, json, this.requestOptions);
  };

  deleteSpot(id: number) {
    return this.http.get(`${_G.extendApiUrl}mitf/deleteailability?id=${id}`, this.requestOptions);
  };

  //Get trucks data
  getTrucks() {
    return this.http.get(`${_G.extendApiUrl}mitf/mitfttruckdata`, this.requestOptions);
  };

  getAllCategories() {
    return this.http.get(`${_G.extendApiUrl}mitf/reasons`, this.requestOptions);
  };

  addUpdateReasons(data: any) {
    let obj: any = {
      id: data.id,
      category: data.category,
      subcategory: data.subcategory,
      is_active: data.is_active,
      created_by: data.created_by,
      created_date: data.created_date,
      edited_by: data.edited_by,
      edited_date: data.edited_date
    }
    let json: any = JSON.stringify(obj);
    return this.http.post(`${_G.extendApiUrl}mitf/reasons`, json, this.requestOptions);
  };

  deleteReason(ids: number, id: number, user: string, date: string) {
    return this.http.get(`${_G.extendApiUrl}mitf/deletereasons?ids=${ids}&id=${id}&user=${user}&date=${date}`, this.requestOptions);
  };

  reserveSpot(obj: any) {
    let json: any = JSON.stringify(obj);
    return this.http.post(`${_G.extendApiUrl}mitf/reserve`, json, this.requestOptions);
  };

  deleteReservedSpot(id: number, deletedBy: string, deleteDate: string) {
    return this.http.get(`${_G.extendApiUrl}mitf/deletereserve?id=${id}&deleted_by=${deletedBy}&delete_date=${deleteDate}`, this.requestOptions);
  };

  askForApproval(obj: any) {
    let json: any = JSON.stringify(obj);
    return this.http.post(`${_G.extendApiUrl}mitf/askforreserve`, json, this.requestOptions);
  };

  getSpotsByDay(date: string) {
    return this.http.get(`${_G.extendApiUrl}mitf/reserve?date=${date}`, this.requestOptions);
  };

  changeTruckStatus(obj: any) {
    return this.http.get(`${_G.extendApiUrl}mitf/changereservestatus?id=${obj.id}&status=${obj.status}&created_by=${obj.createdBy}&created_date=${obj.createdDate}`, this.requestOptions);
  };

  askForTruck(obj: any) {
    let json: any = JSON.stringify(obj);
    return this.http.post(`${_G.extendApiUrl}mitf/demand`, json, this.requestOptions);
  };

  rescheduleArriveDateReserveSpot(obj: any) {
    return this.http.get(`${_G.extendApiUrl}mitf/changereservetime?id=${obj.id}&date=${obj.date}&created_by=${obj.createdBy}&created_date=${obj.createdDate}`, this.requestOptions);
  };

  rescheduleArriveDateAskForTruck(obj: any) {
    return this.http.get(`${_G.extendApiUrl}mitf/changedemandtime?id=${obj.id}&date=${obj.date}&created_by=${obj.createdBy}&created_date=${obj.createdDate}`, this.requestOptions);
  };

  askForTruckTableData() {
    let response1 = this.http.get(`${_G.extendApiUrl}mitf/scheduled?arrived=false`, this.requestOptions);
    let response2 = this.http.get(`${_G.extendApiUrl}mitf/demand?history=false`, this.requestOptions);
    return forkJoin([response1, response2]);
  };

  askForTruckHistory() {
    return this.http.get(`${_G.extendApiUrl}mitf/scheduled?arrived=true`, this.requestOptions);
  };

  changeDemandStatus(id: number, user: string, date: string) {
    return this.http.get(`${_G.extendApiUrl}mitf/demandtoscheduled?id=${id}&user=${user}&date=${date}`, this.requestOptions);
  };

  deleteAskForTruck(id: number, deletedBy: string, deletedDate: string) {
    return this.http.get(`${_G.extendApiUrl}mitf/deletedemand?id=${id}&deleted_by=${deletedBy}&deleted_date=${deletedDate}`, this.requestOptions);
  };
  
  getTruckLocation(unitNo: number, divisionId: number) {
    return this.http.get(`${_G.extendApiUrl}mitf/truckslocation?trucks=${unitNo}&division_id=${divisionId}`, this.requestOptions);
  };

  getNumOfScheduledAskForTruck() {
    return this.http.get(`${_G.extendApiUrl}mitf/getscheduled`, this.requestOptions);
  };

  updateReasonsReserveSpot(id: number, reasons: string) {
    return this.http.get(`${_G.extendApiUrl}mitf/addreservesreason?id=${id}${reasons}`, this.requestOptions)
  };

  deleteReasonReserveSpot(ids: number, rids: any[]) {
    return this.http.get(`${_G.extendApiUrl}mitf/deletereservesreason?id=${ids}&rids=${rids}`, this.requestOptions);
  };

  sendReport(date: string) {
    return this.http.get(`${_G.extendApiUrl}mitf/sceduledtrucksreport?date=${date}`, this.requestOptions);
  };

  //Create Work Order
  partsSubject: any = new Subject<any>();
  statusesSubject: any = new Subject<any>();

  getTrailerData() {
    return this.http.get(`${_G.extendApiUrl}mitf/mitftrailerkdata`, this.requestOptions);
  };

  getContactDetails(type: number) {
    return this.http.get(`${_G.extendApiUrl}mitf/partners?type=${type}`, this.requestOptions);
  };

  createUpdatePart(obj: any) {
    let json: any = JSON.stringify(obj)
    console.log(json)
    return this.http.post(`${_G.extendApiUrl}mitf/workreasons`, json, this.requestOptions);
  };
  
  getAllParts() {
    let response1 = this.http.get(`${_G.extendApiUrl}mitf/workreasons?is_truck=${true}`, this.requestOptions);
    let response2 = this.http.get(`${_G.extendApiUrl}mitf/workreasons?is_truck=${false}`, this.requestOptions);
    return forkJoin([response1, response2]);
  };

  deletePart(id: number) {
    return this.http.get(`${_G.extendApiUrl}mitf/deleteeorkreason?id=${id}`, this.requestOptions);
  };

  deleteCategory(category: string) {
    return this.http.get(`${_G.extendApiUrl}mitf/deletereasoncategory?category=${category}`, this.requestOptions);
  };

  //Status
  getAllStatus() {
    return this.http.get(`${_G.extendApiUrl}mitf/status`, this.requestOptions);
  };

  createUpdateStatus(obj: any) {
    let json: any = JSON.stringify(obj)
    console.log(json)
    return this.http.post(`${_G.extendApiUrl}mitf/status`, json, this.requestOptions);
  };

  deleteStatus(id: number, deletedBy: string, deleteDate: string) {
    return this.http.get(`${_G.extendApiUrl}mitf/deletestatus?id=${id}&deleted_by=${deletedBy}&deleted_date=${deleteDate}`, this.requestOptions);
  };

  saveWorkOrder(obj: any) {
    let json: any = JSON.stringify(obj)
    console.log(json)
    return this.http.post(`${_G.extendApiUrl}mitf`, json, this.requestOptions);
  };

  //Dealer & Shop
  getDataBasedOnType(type: number) {
    return this.http.get(`${_G.extendApiUrl}mitf?type=${type}`, this.requestOptions);
  };

  setIntervalReview(obj: any) {
    let json: any = JSON.stringify(obj);
    console.log(json)
    return this.http.post(`${_G.extendApiUrl}mitf/bpreview`, json, this.requestOptions);
  };

  //Delete payment
  deletePayment(id: number) {
    return this.http.get(`${_G.extendApiUrl}mitf/deletemitfpayment?id=${id}`, this.requestOptions);
  };

  deleteWorkOrder(id: number) {
    return this.http.get(`${_G.extendApiUrl}mitf/deletemitfwo?id=${id}`, this.requestOptions);
  };

  //Notes
  truckNoteAddedSubject: any = new Subject<any>();

  createTruckNoteThread(obj: any) {
    let json: any = JSON.stringify(obj);
    return this.http.post(`${_G.extendApiUrl}mitf/getddealersnotes`, json, this.requestOptions);
  };

  addEditNote(obj: any) {
    let json: any = JSON.stringify(obj);
    console.log(json)
    return this.http.post(`${_G.extendApiUrl}mitf/editDealersNotes`, json, this.requestOptions);
  };

  closeTruckThread(obj: any) {
    let json: any = JSON.stringify(obj);
    return this.http.post(`${_G.extendApiUrl}mitf/closedealernotes`, json, this.requestOptions);
  };

  getAllTruckNotes(id: number) {
    return this.http.get(`${_G.extendApiUrl}mitf/getddealersnotes?id=${id}`, this.requestOptions);
  };

  deleteThread(id: number) {
    return this.http.get(`${_G.extendApiUrl}mitf/deletedealersnotes?id=${id}`, this.requestOptions);
  };

  deleteTruckNote(id: number) {
    return this.http.get(`${_G.extendApiUrl}mitf/deletedealersnotesmessages?id=${id}`, this.requestOptions);
  };

  deleteAllTruckNote(id: number) {
    return this.http.get(`${_G.extendApiUrl}mitf/deletedealersnotes?id=${id}`, this.requestOptions);
  };

  //Get truck status
  getTruckData(unitNo: string, startDate: string, endDate: string) {
    return this.http.get(`${_G.extendApiUrl}mitf/reservebytruck?unit_no=${unitNo}&start=${startDate}&end=${endDate}`, this.requestOptions);
  };
 
}

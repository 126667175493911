import { Component } from '@angular/core';

@Component({
  selector: 'app-accidents-page',
  templateUrl: './accidents-page.component.html',
  styleUrls: ['./accidents-page.component.scss']
})
export class AccidentsPageComponent {

}

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TransformService } from '@app/modules/shared/services/transform.service';

@Component({
  selector: 'app-linechart-dialog',
  templateUrl: './linechart-dialog.component.html',
  styleUrls: ['./linechart-dialog.component.scss']
})
export class LinechartDialogComponent implements OnInit {
  dataSource: any[] = [];
  stObj: any = {
    sumGross: 0,
    sumMileage: 0,
    totalGross: 0,
    totalRpm: 0,
    totalMileage: 0,
    avgGross: 0,
    avgRpm: 0,
    avgMileage: 0
  };

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
              public transformService: TransformService) { }

  ngOnInit(): void {
    this.initData();
  }

  initData() {
    for(let i = 0; i < this.data.length; i++) {
      this.stObj.sumGross += this.data[i].gross;
      this.stObj.sumMileage += this.data[i].mileage;
    }
    if(this.data.length > 0) {
      this.stObj.totalGross = this.transformService.addCommasDots(this.stObj.sumGross, 'round');
      this.stObj.totalRpm = this.transformService.addCommasDots(this.stObj.sumGross / this.stObj.sumMileage);
      this.stObj.totalMileage = this.transformService.addCommasDots(this.stObj.sumMileage, 'round');

      this.stObj.avgGross = this.transformService.addCommasDots(this.stObj.sumGross / this.data.length, 'round');
      this.stObj.avgRpm = this.transformService.addCommasDots((this.stObj.sumGross / this.stObj.sumMileage) / this.data.length);
      this.stObj.avgMileage = this.transformService.addCommasDots(this.stObj.sumMileage / this.data.length, 'round');
    }
    this.dataSource = this.data;
  };

}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ChatService } from '@app/modules/chat/services/chat.service';
import { SharedService } from '@app/modules/shared/services/shared.service';
import { TransformService } from '@app/modules/shared/services/transform.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription, catchError, throwError } from 'rxjs';

@Component({
  selector: 'app-chat-contact-list-dialog',
  templateUrl: './chat-contact-list-dialog.component.html',
  styleUrls: ['./chat-contact-list-dialog.component.scss']
})
export class ChatContactListDialogComponent implements OnInit, OnDestroy {
  driversList: any[] = [];
  searchText: string = '';

  loaded: boolean = false;
  error: boolean = false;
  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";
  spinnerBgColor: string = this.transformService.spinnerBgColor;
  spinnerType: string = this.transformService.spinnerType;

  //Subscription
  subscription: Subscription = new Subscription();

  constructor(private chatService: ChatService,
  public dialogRef: MatDialogRef<ChatContactListDialogComponent>,
  private transformService: TransformService,
  private sharedService: SharedService,
  private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.spinner.show('driver-list');
    this.subscription = this.chatService.getAllDriversContact()
    .pipe(catchError((err: any) => {
      this.spinner.hide('driver-list');
      this.loaded = true;
      this.error = true;
      let errorCode: string = err.status + ' ' + err.statusText;
      let service: string = err.url;
      this.sharedService.sendErrorNotification(errorCode, service);
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      if(response) {
       this.driversList = response.users;
      }
      this.spinner.hide('driver-list');
      this.loaded = true;
      console.log(response);
    });
  };

  selectDriver(obj: any) {
    this.dialogRef.close(obj);
  };

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();  
  };

}

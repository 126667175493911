import { Component, Input, OnChanges } from '@angular/core';

import { Sort } from '@angular/material/sort';
import {  Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { TransformService } from '@app/modules/shared/services/transform.service';
import { NotCoveredTrucksService } from '../../services/not-covered-trucks.service';
import * as XLSX from 'xlsx'; 
import { MatDialog } from '@angular/material/dialog';
import { RulesService } from '@app/modules/shared/services/rules.service';
import { MsgForbbidenAccessComponent } from '@app/modules/shared/components/msg-forbbiden-access/msg-forbbiden-access.component';
import { SlicePipe } from '@angular/common';

@Component({
  selector: 'app-lost-load-per-driver-table',
  templateUrl: './lost-load-per-driver-table.component.html',
  styleUrls: ['./lost-load-per-driver-table.component.scss']
})
export class LostLoadPerDriverTableComponent implements OnChanges {

  @Input() data: any;
  @Input() prevPeriod: any;

  compare: boolean = false;

  clickedColumn: string | undefined;

  selectedIndexArray: number[] = [];

  displayedColumns: string[] = ['driver', 'truck', 'dispatcher', 'lost_days', 'estimated_lost'];

  lengthArray: number = 5;

  dataSource: any;

  sortedData: any;

  dateObj: any;

  oneRowClickedColumns: boolean[] = [false, false, false, false, false, false, false, false, false, false];

  userControl = new FormControl();

  selectedUsers: any[] = new Array<any>();

  filteredUsers: Observable<any[]> | any;

  lastFilter: string = '';

  selectedRow: any;

  graphData: any;

  avgObj: any;
  prevAvgObj: any;

  //Excel config
  xlsxConfig: any = [
    {columnName: 'DRIVER', selected: true},
    {columnName: 'TRUCK', selected: true},
    {columnName: 'DISPATCHER', selected: true},
    {columnName: 'LOST LOADS', selected: true},
    {columnName: 'ESTIMATED LOSS', selected: true}
  ];

  constructor(public transformService: TransformService, 
              private dataService: NotCoveredTrucksService,
              private dialog: MatDialog,
              private rulesService: RulesService,
              private slicePipe: SlicePipe) { }

  ngOnChanges(): void {
    if (this.data) {
      this.reset();
      this.dateObj = this.data.dateObj;
      this.dataSource = JSON.parse(JSON.stringify(this.data.driverList));
      this.sortedData = this.data.driverList;
      this.graphData = JSON.parse(JSON.stringify(this.data.driver_list_graph));
      this.avgObj = this.transformService.countAvgLossEstimatedLoadsNotCoveredTrucks(this.data.driverList, 'lost_days', 'estimated_lost');
      this.prevAvgObj = this.transformService.countAvgLossEstimatedLoadsNotCoveredTrucks(this.prevPeriod.driverList, 'lost_days', 'estimated_lost');
      this.filteredUsers = this.userControl.valueChanges.pipe(
        startWith<string | any[]>(''),
        map(value => typeof value === 'string' ? value : this.lastFilter),
        map(filter => this.filter(filter))
      );
    }
  }

  select(obj: any, key: string, index: number, title: string, oneRowColumn?: any) {
    if (!this.compare) {
      this.selectedRow !== index && this.selectedRow !== undefined ? this.reset() : this.selectedRow = index;
      this.clickedColumn = key;
      this.oneRowClickedColumns[oneRowColumn] = !this.oneRowClickedColumns[oneRowColumn];
      this.selectedRow = index;
      let array: any[] = [];
      for (let key in this.graphData) {
        if (obj.driver == this.graphData[key].driver) {
          array.push(this.graphData[key]);
        }
      }
      let objForGraph = { key: this.clickedColumn, data: array, title: title, driverName: obj.driver, reset: false };
      this.dataService.driverLineChartSubject.next(objForGraph);
    } else {

      if (this.selectedIndexArray.length === 0) {
        this.clickedColumn = key;
      }

      if (this.clickedColumn === key) {

        let res = this.selectedIndexArray.findIndex((num: number) => num === index);

        if (res === -1) {
          this.selectedIndexArray.push(index);
        } else {
          this.selectedIndexArray.splice(res, 1);
        }

        let array: any[] = [];
        for (let key in this.graphData) {
          if (obj.driver == this.graphData[key].driver) {
            array.push(this.graphData[key]);
          }
        }

        let objForGraph = {
          key: this.clickedColumn, data: array, title: title, driverName: obj.dispatcher,
          reset: false
        }
        this.dataService.driverLineChartSubject.next(objForGraph);
      }
    }
  }

  setClass(index: number, clickedColumn: string, oneRowIndex?: any) {
    let className: string = '';

    if (!this.compare && this.oneRowClickedColumns[oneRowIndex] && this.selectedRow === index) {
      return className = 'active';
    }

    if (this.selectedIndexArray.includes(index) && clickedColumn === this.clickedColumn) {
      className = 'active';
    }

    return className;
  }

  seeAll() {
    this.lengthArray = this.dataSource.length;
  };

  seeLess() {
    this.lengthArray = 5;
  };

  sortData(sort: Sort) {
    const data = this.dataSource.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'driver':
          return compare(a.driver, b.driver, isAsc);
        case 'truck':
          return compare(a.truck, b.truck, isAsc);
        case 'dispatcher':
          return compare(a.dispatcher, b.dispatcher, isAsc);
        case 'lost_days':
          return compare(a.lost_days, b.lost_days, isAsc);
        case 'estimated_lost':
          return compare(a.estimated_lost, b.estimated_lost, isAsc);
        default:
          return 0;
      }
    });
  }

  //Search

  filter(filter: string): any[] {
    this.lastFilter = filter;
    if (filter) {
      return this.sortedData.filter((option: any) => {
        return option.driver.toLowerCase().indexOf(filter.toLowerCase()) >= 0;
      })
    } else {
      return this.sortedData.slice();
    }
  }

  displayFn(value: any[] | string): string | any {
    let displayValue: any;
    if (Array.isArray(value)) {
      value.forEach((user, index) => {
        if (index === 0) {
          displayValue = user.driver;
        } else {
          displayValue += ', ' + user.driver;
        }
      });
    } else {
      displayValue = value;
    }
    return displayValue;
  }

  optionClicked(event: Event, user: any) {
    event.stopPropagation();
    this.toggleSelection(user);
  }

  toggleSelection(user: any) {
    user.selected = !user.selected;
    if (user.selected) {
      this.reset();
      this.selectedUsers.push(user);
      this.sortedData = this.selectedUsers;
    } else {
      const i = this.selectedUsers.findIndex(value => value.driver === user.driver);
      this.selectedUsers.splice(i, 1);
      this.sortedData = this.selectedUsers;
      this.reset();
    }
    this.sortedData.length === 0 ? this.sortedData = JSON.parse(JSON.stringify(this.dataSource)) : this.sortedData
  }

  onChange() {
    this.compare = !this.compare;
    this.reset();
  }

  reset() {
    let objForGraph = { key: undefined, data: undefined, title: undefined, driverName: undefined, reset: true };
    this.selectedIndexArray = [];
    this.oneRowClickedColumns = [false, false, false, false, false, false, false, false, false, false];
    this.clickedColumn = undefined;
    this.dataService.driverLineChartSubject.next(objForGraph);
  }

  resetCheckedValue() {
    this.sortedData = JSON.parse(JSON.stringify(this.dataSource))
    this.userControl.reset('');
    this.selectedUsers = [];
  }

  //Count previous period
  setDifference(prev: number, current: number, avg?: any) {
    let checkGrowth: any;
    let className: any;
    if (current > prev) {
      checkGrowth = 'more';
      className = 'up';
      avg === 'avg_weight' ? className = 'down' : className;
    } else if (current < prev) {
      checkGrowth = 'less';
      className = 'down';
      avg === 'avg_weight' ? className = 'up' : className;
    } else {
      checkGrowth = '';
      className = 'none'
    }
    let percentage: any;
    if (prev == 0 && current == 0) {
      percentage = 0;
    }

    if (prev == 0 && current !== 0) {
      percentage = 100 - (prev * 100 / current);
    }

    if (prev !== 0 && current == 0) {
      percentage = 100 - (current * 100 / prev);
    }

    if (prev !== 0 && current !== 0) {
      percentage = (prev / current) * 100;
    }

    let oneRangeMsg: any;
    this.prevPeriod.days === 0 ? oneRangeMsg = `${this.prevPeriod.dayName}` : oneRangeMsg = `${this.prevPeriod.days} days`;
    let twoRangeMsg: string = 'chosen period';

    let alert: string = ` ${checkGrowth} than the previous ${this.prevPeriod.twoRange ? twoRangeMsg : oneRangeMsg}`;
    let alertObj: any = { percent: Math.round(percentage), message: alert, className: className };
    return alertObj;
  }

  //Export to excel
  exportToExcel(columnsConfig: any[]) {
    if(this.rulesService.UserData[56].data[0].sectionArray[34].allowed) {
      const tableData: any[] = this.slicePipe.transform(this.sortedData, 0, this.lengthArray);
      const excelTable: any[] = [];
      for(let i = 0; i < tableData.length; i++) {
        let obj: any = {};
        this.transformService.selectedColumn(obj, columnsConfig[0].columnName, tableData[i].driver, columnsConfig[0].selected);
        this.transformService.selectedColumn(obj, columnsConfig[1].columnName, tableData[i].truck, columnsConfig[1].selected);
        this.transformService.selectedColumn(obj, columnsConfig[2].columnName, tableData[i].dispatcher, columnsConfig[2].selected);
        this.transformService.selectedColumn(obj, columnsConfig[3].columnName, this.transformService.addCommasDots(tableData[i].lost_days), columnsConfig[3].selected);
        this.transformService.selectedColumn(obj, columnsConfig[4].columnName, `$${this.transformService.addCommasDots(tableData[i].estimated_lost, 'round')}`, columnsConfig[4].selected);
        excelTable.push(obj);
      };
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelTable);
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, 'lost-load-per-driver.xlsx');
    }
    else {
      this.msgForbbidenAccess();
    }
  };

  msgForbbidenAccess() {
    this.dialog.open(MsgForbbidenAccessComponent, {
      autoFocus: false,
      panelClass: 'forbidden-msg-dialog-container'
    })
  };

}
function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'filter-boolean',
  templateUrl: './filter-boolean.component.html',
  styleUrls: ['./filter-boolean.component.scss']
})
export class FilterBooleanComponent {
  @Input() config: any = {
    radioBtns: [
      {name: 'Checked'},
      {name: 'Unchecked'},
      {name: 'Both'},
    ],
    defaultValue: 'Both'
  }
  @Input() styleConfig: any = {
    'width': '200px',
    'background-color': '#fff',
    'color': '#1F1F1F',
    'box-shadow': '0px 1px 6.65px 0.35px rgba(13, 39, 80, 0.16)',
    'border-radius': '25px'
  };
  @Output() triggerEvent = new EventEmitter<any>();
  isOpenFilterMenu: boolean = false;
}

import { Component } from '@angular/core';

@Component({
  selector: 'app-truck-estimation-card',
  templateUrl: './truck-estimation-card.component.html',
  styleUrls: ['./truck-estimation-card.component.scss']
})
export class TruckEstimationCardComponent {
  activeTrucksPercent: string = '0';
  activeTrucksNum: number = 0;

  weeklyMileagePercent: string = '64%';
  ratePerMilePercent: string = '27%';

  onInputChange() {
    console.log(this.activeTrucksPercent);
  }

}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Subscription } from 'rxjs';

import { FullAppService } from '@app/modules/full-app/services/full-app.service';
import { IExtend } from '@app/modules/full-app/models/extend-model';
import { MatDialog } from '@angular/material/dialog';
import { ConnectionPositionPair } from '@angular/cdk/overlay';
import { ScheduleOrientationDateDialogComponent } from '../../shared/components/schedule-orientation-date-dialog/schedule-orientation-date-dialog.component';
import { FullAppRejectionDialogComponent } from '../../shared/components/full-app-rejection-dialog/full-app-rejection-dialog.component';
import { DeleteConfirmationDialogComponent } from '@app/modules/shared/components/delete-confirmation-dialog/delete-confirmation-dialog.component';

@Component({
  selector: 'filled-applications-cards',
  templateUrl: './filled-applications-cards.component.html',
  styleUrls: ['./filled-applications-cards.component.scss']
})

export class FilledApplicationsCardsComponent {
  @Input() dataObj: any = {
    filledApplicationArray: [],
    recruiterArray: [],
    statusArray: []
  };
  @Output() selectedDriverChanged = new EventEmitter();

  //Search drivers
  searchDrivers: string = '';

  //Select driver
  selectedDriverIndex: number = 0;

  //Positions
  public positions = [
    new ConnectionPositionPair(
      {originX: 'end', originY: 'bottom'},
      {overlayX: 'end', overlayY: 'top'},
      0, 5
    )
  ];

  //Subscription
  subscription: Subscription = new Subscription();

  constructor(private fullAppService: FullAppService,
              private dialog: MatDialog) { }

  //Change driver
  changeDriver(i: number) {
    this.selectedDriverIndex = i;
    this.selectedDriverChanged.emit({index: this.selectedDriverIndex, obj: this.dataObj.filledApplicationArray[this.selectedDriverIndex]});
  };
  
  //Percent
  percentOfFilledApp(page: number) {
    let percentArray: number[] = [0, 4, 8, 15, 20, 22, 26, 28, 29, 35, 38, 40, 45, 50, 52, 55, 57, 60, 65, 70, 75, 80, 84, 86, 88, 90, 92, 94, 100]
    return `${percentArray[page]}%` ;
  };

  //Select method
  changeRecruiterOrStatus(isStatus: boolean, value: string, element: any) {
    let obj: IExtend = {
      extend_id: element.Id,
      recruiter: element.recruiter,
      truck_no: element.truck_no,
      status: element.status,
      orientation_date: element.orientation_date
    };
    if(isStatus) {
      obj.status = value;
      element.isOpenStatusMenu = !element.isOpenStatusMenu;
      if(obj.status === 'Schedule for Orientation') {
        this.scheduleOrientationDate(obj, element);
      }
      else if(obj.status === 'Not approved') {
        this.openRejectionDialog(element);
      }
      else {
        this.saveChanges(obj, element, 'status', value);
      }
    }
    else {
      obj.recruiter = value;
      element.isOpenRecruiterMenu = !element.isOpenRecruiterMenu;
      this.saveChanges(obj, element, 'recruiter', value);
    }

  };

  saveChanges(obj: IExtend, element: any, key: string, value: string) {
    this.fullAppService.changeStatusOrRecruiter(obj).subscribe((response: any) => {
      if(response) {
        element[key] = value;
      } 
    });
  };

  openRejectionDialog(data: any) {
    this.dialog.open(FullAppRejectionDialogComponent, {
      autoFocus: false,
      panelClass: 'rejection-dialog-container',
      disableClose: true,
      data: data
    });
  };

  getPositionAndDriverType(isDivision: boolean, obj: any) {
    let jsObj: any = JSON.parse(obj.DataJson);
    let value: string = '';
    if(isDivision) {
      if(jsObj.positionGroup.firstCheckbox) {
        value = 'Rent to run';
      }
      if(jsObj.positionGroup.secondCheckbox) {
        value = 'Company';
      }
      if(jsObj.positionGroup.thirdCheckbox) {
        value = 'Owner';
      }
    }
    else {
      if(jsObj.operatingArea.firstCheckbox) {
        value = 'Solo';
      }
      if(jsObj.operatingArea.secondCheckbox) {
        value = 'Team';
      }
    }
    return value;
  };

  deleteFullApplication(obj: any, index: number) {
    console.log(obj);
    obj.isOpenMenu = !obj.isOpenMenu;
    let dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
      autoFocus: false,
      panelClass: 'delete-dialog-container',
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
        this.fullAppService.deleteFullApplication(obj.Id).subscribe((response: boolean) => {
          if(response) {
            this.dataObj.filledApplicationArray.splice(index, 1);
          }
          console.log(response);
        });
      }
    });
  };

  scheduleOrientationDate(obj: IExtend, element: any) {
    let dialogRef = this.dialog.open(ScheduleOrientationDateDialogComponent, {
      autoFocus: false,
      panelClass: 'component-dialog-container',
      data: {title: 'Schedule for Orientation', obj: obj}
    });
    dialogRef.afterClosed().subscribe((result: IExtend) => {
      if(result) {
        element.status = result.status;
        element.orientation_date = result.orientation_date;
      }
    });
  };

}
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-assigned-confirmation-dialog',
  templateUrl: './assigned-confirmation-dialog.component.html',
  styleUrls: ['./assigned-confirmation-dialog.component.scss']
})
export class AssignedConfirmationDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public isTruck: boolean) { }
}

import { AfterViewInit, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as L from 'leaflet';

@Component({
  selector: 'app-truck-trailer-map-dialog',
  templateUrl: './truck-trailer-map-dialog.component.html',
  styleUrls: ['./truck-trailer-map-dialog.component.scss']
})
export class TruckTrailerMapDialogComponent implements AfterViewInit {
  private map: any;
  calculationObj: any = {distance: null, duration: null};
  currentlyLocation: string = '';

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

  //Maps method
  private initMap(obj: any): void {
		this.map = L.map('map', {
		  center: [obj.trucck_latitude, obj.trucck_longitude ],
		  zoom: 14
		});
	
		const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
		  maxZoom: 18,
		  minZoom: 3,
		  attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
		});
		tiles.addTo(this.map);

    let markers: any[] = [
      {name: obj.unit_no, latitude: obj.trucck_latitude, longitude: obj.trucck_longitude, markerUrl: './assets/img/location-blue.svg'},
      {name: obj.trailer_no, latitude: obj.trailer_latitude, longitude: obj.trailer_longitude, markerUrl: './assets/img/location-blue.svg'}
    ];

    for (let i = 0; i < markers.length; i++) {
      const marker = L.marker([markers[i].latitude, markers[i].longitude],
        {
          icon: new L.DivIcon({
            className: 'my-div-icon',
            html: `<div style="background-color: #003fa4; color: #fff; text-align: center; padding: 3px; font-size: 16px; font-weight: bolder; border-radius: 10px; font-family: 'Poppins';">${ markers[i].name }</div>
            <img style="width: 70px; height: 70px;" src="${markers[i].markerUrl}"/>`,
            iconSize: [70, 70],
            iconAnchor: [35, 70]
        })
        }
      );
			marker.addTo(this.map);
		}
    this.map.fitBounds([[markers[0].latitude, markers[0].longitude],[markers[1].latitude, markers[1].longitude]])
	};

  ngAfterViewInit(): void {
    this.initMap(this.data);
  };
  
}

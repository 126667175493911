import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';

import { DocumentsRoutingModule } from './documents.routing';
import { AddEditDocumentDialogComponent } from './components/documents-card/add-edit-document-dialog/add-edit-document-dialog.component';
import { CompanyInfoDialogComponent } from './components/documents-card/company-info-dialog/company-info-dialog.component';
import { DocumentExplanationDialogComponent } from './components/documents-card/document-explanation-dialog/document-explanation-dialog.component';
import { DocumentsCardComponent } from './components/documents-card/documents-card.component';
import { DocumentsListDialogComponent } from './components/documents-card/documents-list-dialog/documents-list-dialog.component';
import { DocumentsComponent } from './components/documents.component';
import { DocumentsService } from './services/documents.service';

@NgModule({
  declarations: [
    DocumentsComponent,
    DocumentsCardComponent,
    AddEditDocumentDialogComponent,
    CompanyInfoDialogComponent,
    DocumentExplanationDialogComponent,
    DocumentsListDialogComponent
  ],
  imports: [
    CommonModule,
    DocumentsRoutingModule,
    SharedModule
  ],
  providers: [DocumentsService]
})
export class DocumentsModule { }

import { CdkDragEnter, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { RulesService } from '@app/modules/shared/services/rules.service';

@Component({
  selector: 'app-pre-drag-drop-cards',
  templateUrl: './pre-drag-drop-cards.component.html',
  styleUrls: ['./pre-drag-drop-cards.component.scss']
})
export class PreDragDropCardsComponent implements OnInit  {
  permissions: any = this.rulesService.UserData[6].data[0].sectionArray;
  @ViewChild('dropListContainer') dropListContainer?: ElementRef;

  @Input() cards: any;

  public items: Array<number> = [];
  selectedIndex: number = 0;

  constructor(private rulesService: RulesService) { }

  ngOnInit(): void {
    this.permissions.forEach((obj: any, index: number) => {
        if(obj.allowed) {
          this.items.push(index+1);
        }
    });
  }

  menuItem: any[] = ['Gross', 'Miles', '$/ Mile', 'Weight', 'Prebooked number of loads', 'Margin amount', 'Margin %',
    'Percentage prebooked', 'Prebooked trucks', 'Active trucks'];

  titlesArray: string[] = ['GROSS', 'MILES', '$/ MILE', 'WEIGHT', 'PREBOOKED NUMBER OF LOADS', 'MARGIN AMOUNT', 'MARGIN %',
    'PERCENTAGE PREBOOKED', 'PREBOOKED TRUCKS', 'ACTIVE TRUCKS'];

  keyArray: string[] = ['gross', 'miles', 'mile', 'weight', 'prebooked_number_of_loads', 'margin_amount', 'margin',
    'percentage_prebooked', 'total_prebooked', 'active_trucks'];

  keyArrayCopy: string[] = ['gross', 'miles', 'mile', 'weight', 'prebooked_number_of_loads', 'margin_amount', 'margin',
    'percentage_prebooked', 'total_prebooked', 'active_trucks'];

  activeIndex(i: number) {
    this.selectedIndex = i;
  };

  changeCard(title: string, keyIndex: number, menuItemIndex: any) {
    this.titlesArray[keyIndex] = title.toUpperCase();
    this.keyArray[keyIndex] = this.keyArrayCopy[menuItemIndex];
  };

  //Set value

  setValue(key: any) {
    switch (key) {
      case 'gross':
        let value1: string = `${this.cards.grossStatistics.total}% of total gross`;
        return { className: 'black', value: value1 }
      case 'miles':
        let value2: string = `${this.cards.milesStatistics.total}% of total miles`
        return { className: 'black', value: value2 }
      case 'mile':
        let value3: string = `$${this.cards.mileStatistics.difference} ${this.cards.mileStatistics.below ? 'below' : 'above'} On Spot`;
        let className1 = this.cards.mileStatistics.below ? 'red' : 'green';
        return { className: className1, value: value3 };
      case 'weight':
        let value4: string = `${this.cards.weightStatistics.difference} ${this.cards.weightStatistics.below ? 'lower' : 'better'} than avg`;
        let className2 = this.cards.weightStatistics.below ? 'red' : 'green';
        return { className: className2, value: value4 };
      case 'prebooked_number_of_loads':
        let value5: string = `${this.cards.prebookedNumberOfLoads.total}% of all loads`
        return { className: 'black', value: value5 }
      case 'margin':
        let value6: string = `${this.cards.marginStatistics.difference}${this.cards.marginStatistics.below ? '% below' : '% above'} On Spot margin %`;
        let className3 = this.cards.marginStatistics.below ? 'red' : 'green';
        return { className: className3, value: value6 };
      default:
        return { className: '', value: '' };
    }
  }

  //Drag and drop methods

  dropListReceiverElement?: HTMLElement;
  dragDropInfo?: {
    dragIndex: number;
    dropIndex: number;
  };

  dragEntered(event: CdkDragEnter<number>) {
    const drag = event.item;
    const dropList = event.container;
    const dragIndex = drag.data;
    const dropIndex = dropList.data;

    this.dragDropInfo = { dragIndex, dropIndex };

    const phContainer = dropList.element.nativeElement;
    const phElement = phContainer.querySelector('.cdk-drag-placeholder');

    if (phElement) {
      phContainer.removeChild(phElement);
      phContainer.parentElement?.insertBefore(phElement, phContainer);

      moveItemInArray(this.items, dragIndex, dropIndex);

    }
  };

  dragMoved(index: number) {
    let placeholderElement: any;
    if (!this.dropListContainer || !this.dragDropInfo) return;
    placeholderElement =
      this.dropListContainer.nativeElement.querySelector(
        '.cdk-drag-placeholder'
      );
    this.selectedIndex = index;

    const receiverElement =
      this.dragDropInfo.dragIndex > this.dragDropInfo.dropIndex
        ? placeholderElement?.nextElementSibling
        : placeholderElement?.previousElementSibling;

    if (!receiverElement) {
      return;
    }

    receiverElement.style.display = 'none';
    this.dropListReceiverElement = receiverElement;
  }

  dragDropped() {
    if (!this.dropListReceiverElement) {
      return;
    }
    this.dropListReceiverElement.style.removeProperty('display');
    this.dropListReceiverElement = undefined;
    this.dragDropInfo = undefined;
  }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import moment = require('moment');
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription, catchError, throwError } from 'rxjs';
import { TransformService } from '../../services/transform.service';
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'app-active-trucks-rent-to-run-dialog',
  templateUrl: './active-trucks-rent-to-run-dialog.component.html',
  styleUrls: ['./active-trucks-rent-to-run-dialog.component.scss']
})

export class ActiveTrucksRentToRunDialogComponent implements OnInit, OnDestroy {
  dateObj: any = {
    startDate: moment().startOf('month').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD')
  };
  
  displayedColumns: string[] = ['position', 'driver', 'unit_no', 'deal', 'hire_date', 'termination_date', 'dispatcher', 'gross', 
  'margin', 'margin_percent', 'billable', 'paid', 'rate', 'weight', 'num_of_loads'];
  sortedData: any = new MatTableDataSource();

  stObj: any = {
    numOfDrivers: 0,
    gross: 0,
    margin: 0,
    marginPercent: 0,
    billableMiles: 0,
    paidMiles: 0,
    rate: 0,
    weight: 0,
    loads: 0
  };

  //Filter menu
  isOpenFilterMenu: boolean = false;
  filterValue: string = 'Active';
  filterArray: any[] = [
    {name: 'All', filter: ''},
    {name: 'Active', filter: 'true'},
    {name: 'Inactive', filter: 'false'}
  ];
  isActive: string = 'true';

  loaded: boolean = false;

  error: boolean = false;

  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";

  spinnerBgColor: string = this.transformService.spinnerBgColor;
  spinnerType: string = this.transformService.spinnerType;

  //Subscription
  subscription: Subscription | any;

  constructor(private router: Router,
              public transformService: TransformService, 
              private sharedService: SharedService,
              private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.spinner.show('active-drivers-rent-to-run');
    this.getTableData();
  }

  getDate(result: any) {
    this.dateObj.startDate = result.startDate.split('T')[0];
    this.dateObj.endDate = result.endDate.split('T')[0];
    this.sortedData.data = [];
    this.loaded = false;
    this.error = false;
    this.spinner.show('active-drivers-rent-to-run');
    this.getTableData();
  };

  getTableData() {
    this.subscription = this.sharedService.getRentToRunDrivers(this.dateObj.startDate, this.dateObj.endDate, this.transformService.filterParams)
    .pipe(catchError((err: any) => {
      this.spinner.hide('active-drivers-rent-to-run');
      this.loaded = true;
      this.error = true;
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      this.sortedData.data = response;
      this.sortedData.filter = this.isActive;
      this.countStatistics(this.sortedData.filteredData);
      this.spinner.hide('active-drivers-rent-to-run');
      this.loaded = true;
      console.log(response);
    })
  };

  sortData(sort: Sort) {
    const data = JSON.parse(JSON.stringify(this.sortedData.data));
    if (!sort.active || sort.direction === '') {
      this.sortedData.data = data;
      return;
    }
    this.sortedData.data = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'driver':
          return compare(a.driver, b.driver, isAsc);
        case 'unit_no':
          return compare(a.unit_no, b.unit_no, isAsc);
        case 'deal':
          return compare(a.deal, b.deal, isAsc);
        case 'hire_date':
          return compare(new Date(a.hire_date), new Date(b.hire_date), isAsc);
        case 'termination_date':
          return compare(new Date(a.termination_date), new Date(b.termination_date), isAsc);
        case 'dispatcher':
          return compare(a.dispatcher, b.dispatcher, isAsc);
        case 'gross':
          return compare(a.gross, b.gross, isAsc);
        case 'margin':
          return compare(a.margin, b.margin, isAsc);
        case 'margin_percent':
          return compare(a.margin_percent, b.margin_percent, isAsc);
        case 'billable':
          return compare(a.billable, b.billable, isAsc);
        case 'paid':
          return compare(a.paid, b.paid, isAsc);
        case 'rate':
          return compare(a.rate, b.rate, isAsc);
        case 'weight':
          return compare(a.weight, b.weight, isAsc);
        case 'num_of_loads':
          return compare(a.num_of_loads, b.num_of_loads, isAsc);
        default:
          return 0;
      }
    });
  }

  countStatistics(array: any[]) {
    let numOfDrivers: number = array.length;
    let sumGross: number = 0;
    let sumMargin: number = 0;
    let sumMarginPercent: number = 0;
    let sumBillableMiles: number = 0;
    let sumPaidMiles: number = 0;
    let sumWeight: number = 0;
    let sumLoads: number = 0;

    for(let key in array) {
      sumGross += array[key].gross;
      sumMargin += array[key].margin;
      sumMarginPercent += array[key].margin_percent;
      sumBillableMiles += array[key].billable;
      sumPaidMiles += array[key].paid;
      sumWeight += array[key].weight;
      sumLoads += array[key].num_of_loads;
    };

    this.stObj.numOfDrivers = numOfDrivers;
    this.stObj.gross = sumGross;
    this.stObj.margin = sumMargin;
    this.stObj.marginPercent = sumMarginPercent / numOfDrivers;
    this.stObj.billableMiles = sumBillableMiles;
    this.stObj.rate = sumGross / sumBillableMiles;
    this.stObj.paidMiles = sumPaidMiles;
    this.stObj.weight = sumWeight / numOfDrivers;
    this.stObj.loads = sumLoads;
  };

  //Open in new tab
  openInNewTab(route: string) {
    const url = this.router.serializeUrl(this.router.createUrlTree([route]));
    window.open(url, '_blank');
  };

  //Search
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.sortedData.filter = filterValue.trim().toLowerCase();
    this.countStatistics(this.sortedData.filteredData);
  }

  //Filter
  filterDrivers(obj: any) {
    this.sortedData.filter = obj.filter;
    this.countStatistics(this.sortedData.filteredData);
    this.filterValue = obj.name;
    this.isActive = obj.filter;
    this.isOpenFilterMenu = false;
  };

  //Reset
  resetInpt(input: any) {
    input.value = '';
    this.sortedData.filter = '';
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  };

}

function compare(a: number | string | any, b: number | string | any, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
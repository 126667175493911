import { Component, OnDestroy, OnInit } from '@angular/core';
import { SettingsService } from '@app/modules/settings/services/settings.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-role-history',
  templateUrl: './role-history.component.html',
  styleUrls: ['./role-history.component.scss']
})
export class RoleHistoryComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = ['position', 'role_name', 'date', 'username', 'type'];
  dataSource: any = [];

  subscription: Subscription | any;

  constructor(private settingsService: SettingsService) { }

  ngOnInit(): void {
    this.subscription = this.settingsService.getRoleHistory().subscribe((response: any) => {
      console.log(response);
      this.dataSource = response;
    })
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

}

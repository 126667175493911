import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { NgxSpinnerService } from 'ngx-spinner';
import { catchError, Subscription, throwError } from 'rxjs';
import { CreateEditUsersDialogComponent } from './create-edit-users-dialog/create-edit-users-dialog.component';
import { DeleteUserConfirmationDialogComponent } from './delete-user-confirmation-dialog/delete-user-confirmation-dialog.component';
import { LoginHistoryDialogComponent } from './login-history-dialog/login-history-dialog.component';
import { RolesDialogComponent } from './roles-dialog/roles-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DepartmentDialogComponent } from './department-dialog/department-dialog.component';
import moment = require('moment');
import { SettingsService } from '../../services/settings.service';
import { TransformService } from '@app/modules/shared/services/transform.service';
import { SharedService } from '@app/modules/shared/services/shared.service';

@Component({
  selector: 'app-users-departments',
  templateUrl: './users-departments.component.html',
  styleUrls: ['./users-departments.component.scss']
})
export class UsersDepartmentsComponent implements OnInit, OnDestroy {
  //Search
  isOpen: boolean = false;
  searchText: string  = '';

  //Btns sections
  btnsArray: any[] = [
    {name: 'ALL USERS', data: []}, {name: 'DISPATCH', data: []}, {name: 'SAFETY', data: []}, 
    {name: 'RECRUITING', data: []}, {name: 'ACCOUNTING', data: []}, {name: 'AFTERHOURS', data: []}, 
    {name: 'HOS', data: []}, {name: 'MAINTENANCE', data: []}, {name: 'MANAGEMENT', data: []}
  ];
  btnSectionIndex: number = 0;

  //Users table data
  displayedColumns: string[] = ['no', 'img', 'first_name', 'permission_set', 'departement', 'position', 'is_active', 
  'login_history', 'online', 'log_out', 'actions'];
  dataSource: any[] = [];
  sortedData: any[] = [];
  searchArray: any[] = [];

  //Show data
  dataForOtherFilters: any[] = [];

  dispatchData: any[] = [];
  safetyData: any[] = [];
  recruitingData: any[] = [];
  accountingData: any[] = [];
  afterhoursData: any[] = [];
  hosData: any[] = [];
  maintenanceData: any[] = [];
  managementData: any[] = [];

  subscription1: Subscription | any;
  subscription2: Subscription | any;

  loaded: boolean = false;

  error: boolean = false;

  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";

  spinnerBgColor: string = this.transformService.spinnerBgColor;
  spinnerType: string = this.transformService.spinnerType;

  constructor(private dialog: MatDialog,
              private transformService: TransformService,
              private settingsService: SettingsService,
              private sharedService: SharedService,
              private _snackBar: MatSnackBar,
              private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.getAllUsers();
    this.dataChanged();
  }

  getAllUsers() {
    this.sortedData = [];
    this.error = false;
    this.loaded = false;
    this.spinner.show('settings-spinner');
    this.subscription1 = this.settingsService.getAllUsers()
    .pipe(catchError((err: any) => {
      this.spinner.hide('settings-spinner');
      this.loaded = true;
      this.error = true;
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      console.log(response);
      this.dataSource = JSON.parse(JSON.stringify(response));
      this.dataForOtherFilters = JSON.parse(JSON.stringify(response));
      this.searchArray = JSON.parse(JSON.stringify(response));

      for(let key in this.dataForOtherFilters) {
        if(this.dataForOtherFilters[key].departement === 'Dispatch') {
          this.dispatchData.push(this.dataForOtherFilters[key])
        };
        if(this.dataForOtherFilters[key].departement === 'Safety') {
          this.safetyData.push(this.dataForOtherFilters[key])
        }
        if(this.dataForOtherFilters[key].departement === 'Recruiting') {
          this.recruitingData.push(this.dataForOtherFilters[key])
        }
        if(this.dataForOtherFilters[key].departement === 'Accounting') {
          this.accountingData.push(this.dataForOtherFilters[key])
        }
        if(this.dataForOtherFilters[key].departement === 'Afterhours') {
          this.afterhoursData.push(this.dataForOtherFilters[key])
        }
        if(this.dataForOtherFilters[key].departement === 'Hos') {
          this.hosData.push(this.dataForOtherFilters[key])
        }
        if(this.dataForOtherFilters[key].departement === 'Maintenance') {
          this.maintenanceData.push(this.dataForOtherFilters[key])
        }
        if(this.dataForOtherFilters[key].departement === 'Management') {
          this.managementData.push(this.dataForOtherFilters[key])
        }
        if(this.dataForOtherFilters[key].departement === 'Safety & Maintenance') {
          this.safetyData.push(this.dataForOtherFilters[key])
          this.maintenanceData.push(this.dataForOtherFilters[key])
        }
      }
      this.btnsArray[0].data = this.dataForOtherFilters;
      this.btnsArray[1].data = this.dispatchData;
      this.btnsArray[2].data = this.safetyData;
      this.btnsArray[3].data = this.recruitingData;
      this.btnsArray[4].data = this.accountingData;
      this.btnsArray[5].data = this.afterhoursData;
      this.btnsArray[6].data = this.hosData;
      this.btnsArray[7].data = this.maintenanceData;
      this.btnsArray[8].data = this.managementData;

      this.sortedData = this.btnsArray[this.btnSectionIndex].data;
      this.spinner.hide('settings-spinner');
      this.loaded = true;
    })
  }

  dataChanged() {
    this.subscription2 = this.settingsService.dataChanged.subscribe((response: any) => {
      if(response) {
        this.getAllUsers();
      }
    })
  }

  showUsers(obj: any, index: number) {
    this.btnSectionIndex = index;
    this.sortedData = obj.data;
  }

  openUserDialog(newUser: boolean, obj?: any) {
    this.dialog.open(CreateEditUsersDialogComponent, {
      width: '946px',
      height: '900px',
      autoFocus: false,
      panelClass: 'user-dialog-container',
      data: {newUser: newUser, data: obj}
    });
  }

  deleteUser(obj: any) {
      let dialogRef: any = this.dialog.open(DeleteUserConfirmationDialogComponent, {
        width: '300px',
        height: '300px',
        autoFocus: false,
        panelClass: 'delete-user-confirmation-dialog-container'
      })
      dialogRef.afterClosed().subscribe((result: any) => {
        if(result) {
          this.settingsService.deleteUser(obj.id, obj.first_name).subscribe((response: any) => {
            console.log(response);
            if(response.is_ok) {
              this.getAllUsers();
            }
          })
        }
      });
  }

  //Sort data
  sortData(sort: Sort) {
    const data = this.btnsArray[this.btnSectionIndex].data.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }
    this.sortedData = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'first_name':
          return compare(a.first_name, b.first_name, isAsc);
        case 'permission_set':
          return compare(a.permission_set, b.permission_set, isAsc);
        case 'departement':
          return compare(a.departement, b.departement, isAsc);
        case 'is_active':
            return compare(a.is_active, b.is_active, isAsc);
        case 'online':
            return compare(a.online, b.online, isAsc);
        default:
          return 0;
      }
    });
  }

  changeStatus(data: any, index: number) {
    let loggedUser: any = JSON.parse(localStorage.getItem('currentUser'));
    this.settingsService.changeStatus(data.id, !data.is_active, loggedUser.username).subscribe((response: any) => {
      if(response.is_ok) {  
        this.dataForOtherFilters[index].is_active = data.is_active;
        this.dataSource[index].is_active  = data.is_active;
      }
    })
  }

  openLoginHistoryDialog(obj: any) {
    this.settingsService.getLoginHistory(obj.id).subscribe((response: any) => {
      console.log(response);
      this.dialog.open(LoginHistoryDialogComponent, {
        width: '707px',
        maxHeight: '380px',
        autoFocus: false,
        panelClass: 'login-history-dialog-container',
        data: {user: obj.first_name + ' ' + obj.last_name, dataSource: response}
      })
    })
  };

  findUser(obj: any) {
    this.searchText = obj.full_name;
    this.sortedData = [obj];
    this.isOpen = !this.isOpen;
  }

  createRoles() {
    this.dialog.open(RolesDialogComponent, {
      maxWidth: '1000px',
      autoFocus: false,
      panelClass: 'roles-dialog-container'
    })
  };

  openDepartmentDialog() {
    this.dialog.open(DepartmentDialogComponent, {
      autoFocus: false,
      panelClass: 'departments-main-container'
    })
  }

  //Upload photo
  onFileSelected(event: any, data: any) {
    console.log(data);
    let allowedTypes: string[] = ['image/jpeg', 'image/png'];
    if (allowedTypes.includes(event.target.files[0].type)) {
      let reader = new FileReader();
      reader.onload = (e: any) => {
        var uint8 = new Uint8Array(e.target.result);
        var result = [];
        for (var i = 0; i < uint8.length; i++) {
          result.push(uint8[i]);
        }
        let obj: any = {
          id: data.photo.id,
          name: data.full_name,
          user_id: data.id,
          photo: result
        }
        console.log(obj);
        this.settingsService.setPicture(this.transformService.userData.id, obj)
        .pipe(catchError((err: any) => {
          return throwError(() => err);
        }))
        .subscribe((response: any) => {
          console.log(response);
          if (response) {
            this.getAllUsers();
          } else {
            this._snackBar.open('A server error occurred while uploading the file', 'Close', { duration: 3000 });
          }
        });
      };
      reader.readAsArrayBuffer(event.target.files[0])
    } else {
      this._snackBar.open('Uploaded file is not a valid image. Only JPG and PNG files are allowed.', 'Close', { duration: 3000 });
    }
  };
  
  //Delete photo
  deletePhoto(id: number) {
    this.settingsService.deletePicture(id).subscribe((response: any) => {
      console.log(response)
      if(response) {
        this.getAllUsers();
      }
    });
  };

  logoutUser(id: number ) {
    this.settingsService.sendStatus(id, false, this.transformService.convertDateToTimestamp(moment(new Date()).format('ddd, DD/MM YYYY'),  moment(new Date()).format('HH:mm:ss')), true);
    this.sortedData = [];
    this.error = false;
    this.loaded = false;
    this.spinner.show('settings-spinner');
    this.getAllUsers();
  };

  logoutAll() {
    this.settingsService.logoutAllUsers().subscribe((response: any) => {
      console.log(response);
    });
  };

  ngOnDestroy(): void {
    this.subscription1?.unsubscribe();
    this.subscription2?.unsubscribe();
  }

}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

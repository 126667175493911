import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PhotosOfInspectionDialogComponent } from '../photos-of-inspection-dialog/photos-of-inspection-dialog.component';

@Component({
  selector: 'brakes-inspection-tab',
  templateUrl: './brakes-inspection-tab.component.html',
  styleUrls: ['./brakes-inspection-tab.component.scss']
})
export class BrakesInspectionTabComponent implements OnInit {
  @Input() data: any;
  tiresObj: any = {
    'LF': {ID: 0, dvirID: 0, tireID: 'LF', psiValue: '-', psiStatus: null, depthValue: '-', depthStatus: null, notes: ''},
    'RF': {ID: 0, dvirID: 0, tireID: 'RF', psiValue: '-', psiStatus: null, depthValue: '-', depthStatus: null, notes: ''},
    'LFO': {ID: 0, dvirID: 0, tireID: 'LFO', psiValue: '-', psiStatus: null, depthValue: '-', depthStatus: null, notes: ''},
    'LFI': {ID: 0, dvirID: 0, tireID: 'LFI', psiValue: '-', psiStatus: null, depthValue: '-', depthStatus: null, notes: ''},
    'RFI': {ID: 0, dvirID: 0, tireID: 'RFI', psiValue: '-', psiStatus: null, depthValue: '-', depthStatus: null, notes: ''},
    'RFO': {ID: 0, dvirID: 0, tireID: 'RFO', psiValue: '-', psiStatus: null, depthValue: '-', depthStatus: null, notes: ''},
    'LRO': {ID: 0, dvirID: 0, tireID: 'LRO', psiValue: '-', psiStatus: null, depthValue: '-', depthStatus: null, notes: ''},
    'LRI': {ID: 0, dvirID: 0, tireID: 'LRI', psiValue: '-', psiStatus: null, depthValue: '-', depthStatus: null, notes: ''},
    'RRI': {ID: 0, dvirID: 0, tireID: 'RRI', psiValue: '-', psiStatus: null, depthValue: '-', depthStatus: null, notes: ''},
    'RRO': {ID: 0, dvirID: 0, tireID: 'RRO', psiValue: '-', psiStatus: null, depthValue: '-', depthStatus: null, notes: ''}
  };
  activeWheelName: string = 'LF';
  activeWheelId: number = 0;

  constructor(private dialog: MatDialog) { }

  ngOnInit(): void {
    for(let i = 0; i < this.data.DVIRBrakesInspection.length; i++) {
      this.tiresObj[this.data.DVIRBrakesInspection[i].tireID] = this.data.DVIRBrakesInspection[i];

      if(this.activeWheelName === this.data.DVIRBrakesInspection[i].tireID) {
        this.activeWheelId = this.data.DVIRBrakesInspection[i].ID;
      }
    };
    console.log(this.tiresObj)
  }

  selectWheel(value: string) {
    this.activeWheelName = value;
  };

  showLatestPhotos() {
    this.dialog.open(PhotosOfInspectionDialogComponent, {
      autoFocus: false,
      panelClass: 'component-dialog-container',
      data: {title: `Brakes Inspection - ${this.activeWheelName}`, ID: this.activeWheelId, inspectionArray: this.data.DVIRBrakesInspectionPhotos}
    });
  }

  getStatusValue(status: number | null): string {
    if(status === 0) {
      return 'Good';
    };
    if(status === 1) {
      return 'Follow Up';
    }
    if(status === 2) {
      return 'Bad';
    }
    return 'No status';
  };

}

import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DriversProfileService } from '@app/modules/drivers-profile-page/services/drivers-profile.service';
import moment = require('moment');

@Component({
  selector: 'app-create-update-thread-dialog',
  templateUrl: './create-update-thread-dialog.component.html',
  styleUrls: ['./create-update-thread-dialog.component.scss']
})
export class CreateUpdateThreadDialogComponent implements OnInit {
  formGroup: FormGroup;
  loggedUser: any = JSON.parse(localStorage.getItem('currentUser'));
  fullName: string = `${this.loggedUser.first_name} ${this.loggedUser.last_name}`;
  nickname: string = `${this.loggedUser.nickname}`;
  creator: string = this.nickname.length === 0 ? this.fullName : this.nickname?.trim().split(' ')[0];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private formBuilder: FormBuilder,
              public dialogRef: MatDialogRef<CreateUpdateThreadDialogComponent>,
              private driversProfileService: DriversProfileService) {
                this.formGroup = this.formBuilder.group({
                  'id': [0],
                  'driver_id': [null],
                  'subject': ['', [Validators.required, Validators.minLength(2)]],
                  'create_date': [''],
                  'creator': [this.creator],
                  'department': [this.loggedUser.department],
                  'editor': [null],
                  'editor_department': [null],
                  'edit_date': [null],
                  'closed': [null],
                  'closed_by': [null],
                  'closed_by_department': [null],
                  'close_date': [null],
                  'notes': [null],
                  'files': null,
                  'emails': [[]],
                  'creator_id': [this.loggedUser.id]
                })
              }

  ngOnInit(): void {
    console.log(this.data.obj);
    if(this.data.editMode) {
      this.formGroup.setValue({
        'id': this.data.obj.id,
        'driver_id': this.data.obj.driver_id,
        'subject': this.data.obj.subject,
        'create_date': this.data.obj.create_date,
        'creator': this.data.obj.creator,
        'department': this.data.obj.department,
        'editor': this.data.obj.editor,
        'editor_department': this.data.obj.editor_department,
        'edit_date': this.data.obj.edit_date,
        'closed': this.data.obj.closed,
        'closed_by': this.data.obj.closed_by,
        'closed_by_department': this.data.obj.closed_by_department,
        'close_date': this.data.obj.close_date,
        'notes': this.data.obj.notes,
        'files': this.data.obj.files,
        'emails': this.data.obj.emails,
        'creator_id': this.data.obj.creator_id
      });
    }
  }

  save() {
    let formData: any = this.formGroup.value;
    if(this.data.editMode) {
      formData.editor = this.creator;
      formData.editor_department = this.loggedUser.department;
      formData.edit_date = moment().format('YYYY-MM-DDTHH:mm:ss');
    }
    else {
      formData.driver_id = this.data.driverId;
      formData.create_date = moment().format('YYYY-MM-DDTHH:mm:ss');
    }
    console.log(formData)
    this.driversProfileService.createThreadNoteExhchange(formData).subscribe((response: any) => {
      if(response?.id) {
        this.dialogRef.close(response);
      }
    });
  };
  
}

import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import moment = require('moment');
import { Subscription, catchError, throwError } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { EmployeeDataDialogComponent } from '../employee-data-dialog/employee-data-dialog.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { Sort } from '@angular/material/sort';
import { TransformService } from '@app/modules/shared/services/transform.service';
import { EmployeeService } from '../../services/employee.service';
import { SharedService } from '@app/modules/shared/services/shared.service';
import { RulesService } from '@app/modules/shared/services/rules.service';
import { EmployeeStatusDialogComponent } from '@app/modules/shared/components/employee-status-dialog/employee-status-dialog.component';

@Component({
  selector: 'app-employee-activity-table',
  templateUrl: './employee-activity-table.component.html',
  styleUrls: ['./employee-activity-table.component.scss']
})

export class EmployeeActivityTableComponent implements OnInit, OnDestroy {
  permissions: any = this.rulesService.UserData[24].data;
  //Calendars
  isOpenFromCalendar: boolean = false;
  isOpenToCalendar: boolean = false;

  dateObj: any = {
    startDate: moment().startOf('isoWeek').format('YYYY-MM-DDT00:00:00'),
    endDate: moment().endOf('isoWeek').format('YYYY-MM-DDT23:59:59'),
  };
  currentDate: string = moment().format('YYYY-MM-DDT00:00:00');
  tableData: any[] = [];

  //Department
  isOpenDepartmentMenu: boolean = false;
  departmentsArray: any[] = [];
  dempartmentValue: string = 'All Departments';

  //Active/Inactive
  isOpenActivityMenu: boolean = false;
  employeeStatusArray: any[] = [
    {name: 'All Employees'},
    {name: 'Active'},
    {name: 'Inactive'}
  ];
  emloyeeStatusValue: string = 'Active';

  //Offices
  isOpenOfficeMenu: boolean = false;
  officeArray: any[] = [];
  officeValue: string = 'All Offices';

  //Search value
  searchValue: string = '';

  //Show status table
  statusTable: boolean = true;

  //Is opened popup
  isOpened: boolean = false;

  //Statistic percent data
  filterMetaData: any = {
    stArray: [],
    countData: true,
    numToDivide: 0,
    allStatusForAllEmployees: []
  };

  //Statistic percent data
  filterMetaDataTableTwo: any = {
    stArray: [],
    countData: false,
    numToDivide: 0,
    allStatusForAllEmployees: []
  };

  //Subscription
  subscription1: Subscription | any;
  subscription2: Subscription | any;
  subscription3: Subscription | any;
  subscription4: Subscription | any;

  loaded: boolean = false;

  error: boolean = false;

  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";

  spinnerBgColor: string = this.transformService.spinnerBgColor;
  spinnerType: string = this.transformService.spinnerType;

  constructor(private dialog: MatDialog,
              public transformService: TransformService,
              private employeeService: EmployeeService,
              private spinner: NgxSpinnerService,
              private sharedService: SharedService,
              private rulesService: RulesService,
              public cd: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.subscription1 = this.employeeService.getEmployeeDepartmentsAndOffices().subscribe((responseObj: any) => {
      this.departmentsArray = responseObj.response1;
      this.departmentsArray.unshift({name: 'All Departments'});

      this.officeArray = responseObj.response2;
      this.officeArray.unshift({name: 'All Offices'});
      console.log(responseObj);
    })
    this.getTableData();
    this.refreshData();
  }

  refreshData() {
    this.subscription2 = this.employeeService.refreshDataSubject.subscribe((response: boolean) => {
      if(response) {
        this.getTableData();
      }
    })
  };

  getTableData() {
    this.error = false;
    this.loaded = false;
    this.tableData = [];
    this.spinner.show('employee-activity');

    if(this.statusTable) {
      this.subscription3 = this.employeeService.getEmployeeTableData(this.dateObj.startDate, this.dateObj.endDate)
      .pipe(catchError((err: any) => {
        this.spinner.hide('employee-activity');
        this.loaded = true;
        this.error = true;
        return throwError(() => err);
      }))
      .subscribe((response: any) => {
        this.filterMetaData.allStatusForAllEmployees = response[1];
        for(let key in response[0]) {
          let obj: any = this.getDatesInRange(response[0][key], response[1]);
          response[0][key].statusArray = obj.array;
          response[0][key].percentOfAllDays = this.transformService.addCommasDots(isNaN(obj.percentOfDays) ? 0 : obj.percentOfDays, 'round') + '%';
        }
        this.tableData = response[0];
        console.log(this.tableData);
        this.spinner.hide('employee-activity');
        this.loaded = true;
      });
    }
    else {
    this.subscription4 = this.employeeService.getActivityStatsForAll(this.dateObj.startDate, this.dateObj.endDate)
    .pipe(catchError((err: any) => {
      this.spinner.hide('employee-activity');
      this.loaded = true;
      this.error = true;
      return throwError(() => err);
    }))
    .subscribe((response: any) => {

      let employeeTempArray: string[] = [];

      let finalArray: any[] = [];

      for(let key in response) {
        let index: any = employeeTempArray.indexOf(response[key].employee_id);

        if(index === -1) {
          employeeTempArray.push(response[key].employee_id);
          let obj: any = {
            employee: '', nickname: '', percentOfWorkingDays: 0, percentOfOutOfWorkDays: 0, percentOfDays: 0, extraShift: 0, 
            regularShift: 0, earlyLeave: 0, lateArrival: 0, workingWeekend: 0, nightShift: 0, morningShift: 0, healthyIssue: 0, 
            familyTradition: 0, nationalHoliday: 0, vacation: 0, restDay: 0, dayOff: 0, overtimeHours: 0, shortenedShifts: 0, 
            summarry: 0
          }
          finalArray.push(obj);
        }

      }

      for(let key in response) {
        let index: any = employeeTempArray.indexOf(response[key].employee_id);

        if(index !== -1) {
          finalArray[index].employee = response[key].employee;
          finalArray[index].nickname = response[key].nickname;
          finalArray[index].percentOfWorkingDays += response[key].status_group_name === 'Working' ? 1 : 0;
          finalArray[index].percentOfOutOfWorkDays += response[key].status_group_name === 'Out of work' ? 1 : 0;
          finalArray[index].percentOfDays = (finalArray[index].percentOfWorkingDays * 100) / (finalArray[index].percentOfWorkingDays + finalArray[index].percentOfOutOfWorkDays);
          finalArray[index].is_active = response[key].is_active;
          finalArray[index].city = response[key].city;
          finalArray[index].department = response[key].departments;
          finalArray[index].extraShift += response[key].status === 'Extra shift' ? 1 : 0;
          finalArray[index].regularShift += response[key].status === 'Regular shift' ? 1 : 0;
          finalArray[index].earlyLeave += response[key].status === 'Early leave' ? 1 : 0;
          finalArray[index].lateArrival += response[key].status === 'Late arrival' ? 1 : 0;
          finalArray[index].workingWeekend += response[key].status === 'Working weekend' ? 1 : 0;
          finalArray[index].nightShift += response[key].status === 'Night shift' ? 1 : 0;
          finalArray[index].morningShift += response[key].status === 'Morning shift' ? 1 : 0;
          finalArray[index].healthyIssue += response[key].status === 'Health issue' ? 1 : 0;
          finalArray[index].familyTradition += response[key].status === 'Family tradition' ? 1 : 0;
          finalArray[index].nationalHoliday += response[key].status === 'National holiday' ? 1 : 0;
          finalArray[index].vacation += response[key].status === 'Vacation' ? 1 : 0;
          finalArray[index].restDay += response[key].status === 'Regular rest day' ? 1 : 0;
          finalArray[index].dayOff += response[key].status === 'Day off' ? 1 : 0;
          finalArray[index].overtimeHours += response[key].status === 'Extra shift' ? response[key].hours : 0;
          finalArray[index].shortenedShifts += response[key].status === 'Late arrival' || response[key].status === 'Early leave' ? response[key].hours : 0;
        }

      }
      
      this.tableData = finalArray;
      this.spinner.hide('employee-activity');
      this.loaded = true;
    })
    }
  }

  //Calendar method
  dateClicked(event: any, fromCalendar: boolean) {
    if(fromCalendar) {
      this.dateObj.startDate = moment(event._d).format('YYYY-MM-DDT00:00:00');
      this.isOpenFromCalendar = false;
    }
    else {
      this.dateObj.endDate = moment(event._d).format('YYYY-MM-DDT23:59:59');
      this.isOpenToCalendar = false;
    }
  };

  //Get data
  run() {
    this.getTableData();
  };

  //Status
  createEditStatus(dayObj: any, employeeData: any, index: number, inactiveDate: string) {
    if(moment(inactiveDate).format('YYYY-MM-DD') > moment(dayObj.date).format('YYYY-MM-DD')) {
      let obj: any = JSON.parse(JSON.stringify(dayObj));
      let dialogRef = this.dialog.open(EmployeeStatusDialogComponent, {
        autoFocus: false,
        panelClass: 'employee-status-main-container',
        data: obj
      });
      dialogRef.afterClosed().subscribe((result: any) => {
        if(result) {
          if(result === 'Refresh') {
            this.getTableData();
          }
          else {
            console.log(employeeData)
            let retrunedObj: any = result;
            retrunedObj.date = obj.date;
            employeeData[index] = retrunedObj;
            console.log(retrunedObj)
          }
        }
      });
    } 
  };

  //Add days
  getDatesInRange(objData: any, array: any[]) {
    let startDate = new Date(this.dateObj.startDate);
    let endDate = new Date(this.dateObj.endDate);
    const date = new Date(startDate.getTime());
    const dates = [];
    let numOfDaysWithAllStatuses: number = 0;
    let numOfDaysWithWorkingStatus: number = 0;
    while (date <= endDate) {
      let d1 = moment(date).format('YYYY-MM-DDT00:00:00');
      let obj: any;

      let result = array.some((currObj: any) => { 
        obj = currObj
        return currObj.employee_id == objData.id && currObj.status_date === d1;
      })
      if(result) {
        obj.date = d1;
        numOfDaysWithWorkingStatus += obj.status_group_name === 'Working' ? 1 : 0;
        numOfDaysWithAllStatuses++;
        dates.push(obj);
      } else {
        dates.push(
        {id: 0, date: d1, employee_id: objData.id, employee: objData.name, status_group_name: null, status: null, icon_id: null, 
        icon: null, color: null, craetor: null, creator_id: null, creator_department: null, status_date: null, datecreated: null,
        editor: null, edit_date: null, terminated: null, className: null, no_className: null, dispatcher_className: null, total_className: null,
        hours: null, note: null, approved: null, firstday: null, backtowork: null, files: []
        });
      }

      date.setDate(date.getDate() + 1);
    }
    return {array: dates, percentOfDays: (numOfDaysWithWorkingStatus * 100) / numOfDaysWithAllStatuses};
  }

  showDataByPeriod(period: string) {
    if(period === 'weekly') {
      this.dateObj.startDate = moment().startOf('isoWeek').format('YYYY-MM-DDT00:00:00');
      this.dateObj.endDate = moment().endOf('isoWeek').format('YYYY-MM-DDT23:59:59');
    }
    else {
      this.dateObj.startDate = moment().startOf('month').format('YYYY-MM-DDT00:00:00');
      this.dateObj.endDate = moment().endOf('month').format('YYYY-MM-DDT23:59:59');
    }
    this.getTableData();
  }

  //Set class
  setClassByDay(obj: any, element: any) {
    if(obj.status === 'Extra shift') {
      return 'extra-shift';
    }
    else if(obj.status === 'Working weekend') {
      return 'working-weekend day-bg';
    }
    else if((obj.status === null && moment(element.inactive_date).format('YYYY-MM-DD') > moment(obj.date).format('YYYY-MM-DD')
    && (moment(element.hire_date).format('YYYY-MM-DD') <= moment(obj.date).format('YYYY-MM-DD') || !element.hire_date)) 
      || (obj.status === 'Early leave' && obj.approved === true && moment(element.inactive_date).format('YYYY-MM-DD') > moment(obj.date).format('YYYY-MM-DD'))) {
      return 'border-red day-bg'
    }
    else {
      return 'day-bg';
    }

  };

  //Is weekend
  setThDayClass(obj: any) {
    let day: any = new Date(obj.date);
    if(this.currentDate === obj.date) {
      return 'current-date';
    }
    else if((day.getDay() == 6 || day.getDay() == 0)) {
      return 'day-bg weekend-border';
    }
    else {
      return 'day-bg';
    }
  };

  toggleTable() {
    this.statusTable = !this.statusTable;
    this.getTableData();
  };

  openEmployeeDataDialog(obj: any, tableIndex: number) {
    if(this.permissions[1].allowedAll) {
      let dialogRef = this.dialog.open(EmployeeDataDialogComponent, {
        autoFocus: false,
        panelClass: 'employee-data-main-container',
        data: {editMode: true, obj: obj, dateObj: this.dateObj, tableIndex: tableIndex}
      });
      dialogRef.afterClosed().subscribe((result: any) => {
        if(result) {
          this.getTableData();
        };
      });
    }
  }

  sortStatusArray(sort: Sort) {
    const data = this.tableData.slice();
    if (!sort.active || sort.direction === '') {
      this.tableData = data;
      return;
    }
    this.tableData = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'employee':
          return compare(a.employee, b.employee, isAsc);
        case 'nickname':
          return compare(a.nickname, b.nickname, isAsc);
        case 'percentOfDays':
          return compare(a.percentOfDays, b.percentOfDays, isAsc);
        case 'extraShift':
          return compare(a.extraShift, b.extraShift, isAsc);
        case 'regularShift':
          return compare(a.regularShift, b.regularShift, isAsc);
        case 'earlyLeave':
          return compare(a.earlyLeave, b.earlyLeave, isAsc);
        case 'lateArrival':
          return compare(a.lateArrival, b.lateArrival, isAsc);
        case 'workingWeekend':
          return compare(a.workingWeekend, b.workingWeekend, isAsc);
        case 'nightShift':
          return compare(a.nightShift, b.nightShift, isAsc);
        case 'morningShift':
          return compare(a.morningShift, b.morningShift, isAsc);
        case 'healthyIssue':
          return compare(a.healthyIssue, b.healthyIssue, isAsc);
        case 'familyTradition':
          return compare(a.familyTradition, b.familyTradition, isAsc);
        case 'nationalHoliday':
          return compare(a.nationalHoliday, b.nationalHoliday, isAsc);
        case 'vacation':
          return compare(a.vacation, b.vacation, isAsc);
        case 'restDay':
          return compare(a.restDay, b.restDay, isAsc);
        case 'dayOff':
          return compare(a.dayOff, b.dayOff, isAsc);
        case 'overtimeHours':
          return compare(a.overtimeHours, b.overtimeHours, isAsc);
        case 'shortenedShifts':
          return compare(a.shortenedShifts, b.shortenedShifts, isAsc);
        case 'summarry':
          return compare(a.summarry, b.summarry, isAsc);
        default:
          return 0;
      }
    });
  };

  getSummarry(obj: any) {
    let miliseconds: number = obj.overtimeHours - obj.shortenedShifts;
    let mathAbs: any = Math.abs(miliseconds);
    obj.summarry = miliseconds;
    return `${miliseconds < 0 ? '-' : ''}${String(Math.floor(mathAbs / 3600000)).padStart(2, '0')}:${String(Math.floor((mathAbs % 3600000) / 60000)).padStart(2, '0')}`;
  }

  getHoursMinutes(miliseconds: number) {
    return `${String(Math.floor(miliseconds / 3600000)).padStart(2, '0')}:${String(Math.floor((miliseconds % 3600000) / 60000)).padStart(2, '0')}`;
  };

  getPrimaryAddress(array: any[]) {
    let companyArray: any[] = [];
    for(let i = 0; i < array.length; i++) {
      if(array[i].is_primary) {
        companyArray.push(array[i]);
        break;
      }
    }
    return companyArray;
  };

  getColor(status: string) {
    let obj: any = {
      'Extra shift': 'green-b',
      'Regular shift': 'green-b',
      'Early leave': 'green-b',
      'Late arrival': 'green-b',
      'Working weekend': 'orange-b',
      'Night shift': 'black-b',
      'Morning shift': 'orange-b',
      'Health issue': 'blue-b',
      'Family tradition': 'blue-b',
      'National holiday': 'blue-b',
      'Vacation': 'blue-b',
      'Regular rest day': 'grey-b',
      'Day off': 'orange-b'
    }
    return obj[status];
  };

  isBeforeTerminated(obj: any, date: string) {
    return moment(obj.inactive_date).format('YYYY-MM-DD') > moment(date).format('YYYY-MM-DD') &&
    (moment(obj.hire_date).format('YYYY-MM-DD') <= moment(date).format('YYYY-MM-DD') || !obj.hire_date);
  };

  getTerminatedDate(date1: string, date2: string) {
    return moment(date1).format('YYYY-MM-DD') === moment(date2).format('YYYY-MM-DD');
  };

  trackTask(index: number, item: any): string {
    return `${item.id}`;
  };

  showPrevNextDays(prev: boolean) {
    let startRes = moment(this.dateObj.startDate, "YYYY-MM-DD");
    let endRes = moment(this.dateObj.endDate, "YYYY-MM-DD")
    let days = endRes.diff(startRes, 'days') + 1;
    console.log(days)
    if(prev) {
      this.dateObj.startDate = moment(this.dateObj.startDate).subtract(days, "days").format('YYYY-MM-DDT00:00:00');
      this.dateObj.endDate = moment(this.dateObj.endDate).subtract(days, "days").format('YYYY-MM-DDT23:59:59');
    }
    else {
      this.dateObj.startDate = moment(this.dateObj.startDate).add(days, "days").format('YYYY-MM-DDT00:00:00');
      this.dateObj.endDate = moment(this.dateObj.endDate).add(days, "days").format('YYYY-MM-DDT23:59:59');
    }
    this.getTableData();
  };

  ngOnDestroy(): void {
    this.subscription1?.unsubscribe();
    this.subscription2?.unsubscribe();
    this.subscription3?.unsubscribe();
    this.subscription4?.unsubscribe();
  };

}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
import { Component, Input } from '@angular/core';
import { TransformService } from '@app/modules/shared/services/transform.service';

@Component({
  selector: 'truck-profitability-cards',
  templateUrl: './truck-profitability-cards.component.html',
  styleUrls: ['./truck-profitability-cards.component.scss']
})
export class TruckProfitabilityCardsComponent {
  @Input() cards: any;
  @Input() prevPeriod: any;
  @Input() date: any;

  constructor(public transformService: TransformService) { }

  setDifference(prev: number, current: number, avg?: any) {
    let checkGrowth: any;
    let className: any;
    if (current > prev) {
      checkGrowth = 'more';
      className = 'up';
    } 
    else if (current < prev) {
      checkGrowth = 'less';
      className = 'down';
    } 
    else {
      checkGrowth = '';
      className = 'none'
    }
    let percentage: any;
    if (prev == 0 && current == 0) {
      percentage = 0;
    }

    if (prev == 0 && current !== 0) {
      percentage = Math.abs(100 - (prev * 100 / current));
    }

    if (prev !== 0 && current == 0) {
      percentage = Math.abs(100 - (current * 100 / prev));
    }

    if (prev !== 0 && current !== 0) {
      percentage = Math.abs(100 - (prev / current) * 100);
    }

    let oneRangeMsg: any;
    this.prevPeriod.days === 0 ? oneRangeMsg = `${this.prevPeriod.dayName}` : oneRangeMsg = `${this.prevPeriod.days} days`;
    let twoRangeMsg: string = 'chosen period';

    let alert: string = ` ${checkGrowth} than the previous ${this.prevPeriod.twoRange ? twoRangeMsg : oneRangeMsg}`;
    let alertObj: any = { percent: Math.round(percentage), message: alert, className: className };
    return alertObj;
  }

}

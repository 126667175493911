import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TrucksMapPageComponent } from './components/trucks-map-page/trucks-map-page.component';
import { TrucksMapRoutingModule } from './trucks-map.routing';
import { SharedModule } from '../shared/shared.module';
import { TruckInfoDialogComponent } from './components/trucks-map-page/truck-info-dialog/truck-info-dialog.component';
import { TruckTrailerFilterPipe } from './pipes/truck-trailer-filter.pipe';
import { AssignTrailerComponent } from './components/trucks-map-page/truck-info-dialog/assign-trailer/assign-trailer.component';

@NgModule({
  declarations: [
    TrucksMapPageComponent,
    TruckInfoDialogComponent,
    TruckTrailerFilterPipe,
    AssignTrailerComponent
  ],
  imports: [
    CommonModule,
    TrucksMapRoutingModule,
    SharedModule
  ]
})
export class TrucksMapModule { }

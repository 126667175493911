import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../core/services/auth/auth.guard';
import { DashboardUnderperformingDriversPageComponent } from './components/dashboard-underperforming-drivers-page.component';

const routes: Routes = [
	{
		path: 'drivers',
		children: [
		  {
			path: 'underperforming-drivers-page',
			canActivate: [AuthGuard],
			component: DashboardUnderperformingDriversPageComponent,
			data: { roles: 45 }
		  },
		],
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class DriversUnderperformingRoutingModule { }

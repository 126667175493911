import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import moment = require('moment');

@Component({
  selector: 'app-truck-arrive-dialog',
  templateUrl: './truck-arrive-dialog.component.html',
  styleUrls: ['./truck-arrive-dialog.component.scss']
})
export class TruckArriveDialogComponent {
  //Date
  date: string = '';
  isOpenCalendar: boolean = false;

  //Time
  isOpenTimeMenu: boolean = false;
  time: string = '';
  hour: any;
  minutes: any;

  constructor(public dialogRef: MatDialogRef<any>) { }

  change() {
    this.dialogRef.close(moment(this.date).format(`YYYY-MM-DDT${this.time}:00`));
  };

  //Date
  dateClicked(event: any) {
    this.date = moment(event._d).format('YYYY-MM-DDT00:00:00');
    this.isOpenCalendar = false;
  }

  //Time methods
  onKeyUp(event: any, hour: boolean) {
    let enteredNum: any = +event.target.value;
    if(hour && (enteredNum > 23 || enteredNum < 0 || event.target.value.length > 2)) {
      this.hour = '00';
    }

    if(!hour && (enteredNum > 59 || enteredNum < 0 || event.target.value.length > 2)) {
      this.minutes = '00';
    }
  }

  leadingZero(hour: boolean) {
    if(hour && this.hour) {
      let hourString: string = this.hour.toString();
      this.hour =  (hourString.length === 1) ? ("0" + this.hour) : this.hour;
    }

    if(!hour && this.minutes) {
      let minutesString: string = this.minutes.toString();
      this.minutes = (minutesString.length === 1) ? ("0" + this.minutes) : this.minutes; 
    }
  }

  applyTime() {
    this.time = `${this.hour}:${this.minutes}`;
    this.isOpenTimeMenu = false;
  };

}

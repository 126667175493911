import { ConnectionPositionPair } from '@angular/cdk/overlay';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import moment = require('moment');

@Component({
  selector: 'planner-form',
  templateUrl: './planner-form.component.html',
  styleUrls: ['./planner-form.component.scss']
})
export class PlannerFormComponent {
  @Input() dataObj: any = {
    title: '',
    icon: '',
    fromFirstInputTitle : '',
    fromSecondInputTitle : '',
    fromThirdInputTitle : '',
    fromFourthInputTitle : '',
    toFirstInputTitle : '',
    toSecondInputTitle : '',
    toThirdInputTitle : '',
    toFourthInputTitle : '',
    fromFirstSelectArray: [],
    fromSecondSelectArray: [],
    toFirstSelectArray: [],
    toSecondSelectArray: [],
    id: 0,
    driver_id: 0,
    trip_type: '',
    trip_from_method: '',
    trip_from_status: '',
    trip_from: '',
    trip_from_name: '',
    trip_from_datetime: '',
    trip_to_method: '',
    trip_to_status: '',
    trip_to: '',
    trip_to_name: '',
    trip_to_datetime: '',
    created_by: '',
    created_date: '',
    edited_by: '',
    edited_date: ''
  }
  @Output() emitEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  //Menu
  public positions = [
    new ConnectionPositionPair(
      {originX: 'end', originY: 'bottom'},
      {overlayX: 'end', overlayY: 'top'},
      0, 5
    )
  ];
  isOpenMenu: boolean = false;

  //Select From
  isOpenFromFirstMenu: boolean = false;
  isOpenFromSecondMenu: boolean = false;

  //Select To
  isOpenToFirstMenu: boolean = false;
  isOpenToSecondMenu: boolean = false;

  //Calendars
  isOpenFromCalendar: boolean = false;
  isOpenToCalendar: boolean = false;

  //Timepicker
  isOpenTimepickerFrom: boolean = false; 
  hourFrom: any;
  minutesFrom: any;
  timeFrom: any;

  isOpenTimepickerTo: boolean = false; 
  hourTo: any;
  minutesTo: any;
  timeTo: any;

  deleteForm() {
    this.emitEvent.emit(true);
  };

  dateClicked(event: any, fromCalendar: boolean) {
    if(fromCalendar) {
      this.dataObj.trip_from_datetime = moment(event._d).format('YYYY-MM-DDT00:00:00');
      this.isOpenFromCalendar = false;
    }
    else {
      this.dataObj.trip_to_datetime = moment(event._d).format('YYYY-MM-DDT00:00:00');
      this.isOpenToCalendar = false;
    }
  };

  //Timepicker method
  leadingZero(hour: boolean, fromTimepicker: boolean) {
    if(fromTimepicker) {
      if(hour) {
        this.hourFrom = String(this.hourFrom).padStart(2, '0');
      }
      else {
        this.minutesFrom = String(this.minutesFrom).padStart(2, '0');
      }
    }
    else {
      if(hour) {
        this.hourTo = String(this.hourTo).padStart(2, '0');
      }
      else {
        this.minutesTo = String(this.minutesTo).padStart(2, '0');
      }
    }
  }
  //    let date: any = moment(this.date).format(`YYYY-MM-DDT${time}:00`);
  applyTime(fromTimepicker: boolean) {
    if(fromTimepicker) {
      if(isNaN(this.hourFrom) || this.hourFrom === '') {
        this.hourFrom = '00';
      }
      
      if(isNaN(this.minutesFrom) || this.minutesFrom === '') {
        this.minutesFrom = '00';
      }
      this.timeFrom = `${this.hourFrom}:${this.minutesFrom}`;
      this.dataObj.trip_from_datetime = moment(this.dataObj.trip_from_datetime).format(`YYYY-MM-DDT${this.timeFrom}:00`);
      this.isOpenTimepickerFrom = false;
    }
    else {
      if(isNaN(this.hourTo) || this.hourTo === '') {
        this.hourTo = '00';
      }
      
      if(isNaN(this.minutesTo) || this.minutesTo === '') {
        this.minutesTo = '00';
      }
      this.timeTo = `${this.hourTo}:${this.minutesTo}`;
      this.dataObj.trip_to_datetime = moment(this.dataObj.trip_to_datetime).format(`YYYY-MM-DDT${this.timeTo}:00`);
      this.isOpenTimepickerTo = false;
    }
  };

}

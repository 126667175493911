import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { catchError, Subscription, throwError } from 'rxjs';
import { DispatchersPerformancesService } from '../../services/dispatchers-performances.service';
import { SharedService } from '@app/modules/shared/services/shared.service';
import { TransformService } from '@app/modules/shared/services/transform.service';
import { RulesService } from '@app/modules/shared/services/rules.service';
import * as XLSX from 'xlsx';
import { MatDialog } from '@angular/material/dialog';
import { MsgForbbidenAccessComponent } from '@app/modules/shared/components/msg-forbbiden-access/msg-forbbiden-access.component';
import { TitleCasePipe } from '@angular/common';

@Component({
  selector: 'app-top-worst-tables',
  templateUrl: './top-worst-tables.component.html',
  styleUrls: ['./top-worst-tables.component.scss']
})
export class TopWorstTablesComponent implements OnInit, OnDestroy {
  @ViewChild('scrollContainer', { read: ElementRef }) public scrollContainer: any;
  @Input() date: any;

  topWorst: boolean = true;
  value: string = 'Top';
  percent: number = 3;
  dispatchStatus: number = 1;

  //Menu top
  isOpen: boolean = false;
  selectValue: string = 'Top 3';
  selectArray: any[] = [
    {name: 'Top 3', percent: 3},
    {name: 'Top 4', percent: 4},
    {name: 'Top 5', percent: 5},
    {name: 'Top 6', percent: 6},
    {name: 'Top 7', percent: 7},
    {name: 'Top 8', percent: 8},
    {name: 'Top 9', percent: 9},
    {name: 'Top 10', percent: 10}
  ];

  topArray: any[] = [
    {name: 'Top 3', percent: 3},
    {name: 'Top 4', percent: 4},
    {name: 'Top 5', percent: 5},
    {name: 'Top 6', percent: 6},
    {name: 'Top 7', percent: 7},
    {name: 'Top 8', percent: 8},
    {name: 'Top 9', percent: 9},
    {name: 'Top 10', percent: 10}
  ]

  worstArray: any[] = [
    {name: 'Worst 3', percent: 3},
    {name: 'Worst 4', percent: 4},
    {name: 'Worst 5', percent: 5},
    {name: 'Worst 6', percent: 6},
    {name: 'Worst 7', percent: 7},
    {name: 'Worst 8', percent: 8},
    {name: 'Worst 9', percent: 9},
    {name: 'Worst 10', percent: 10}
  ];

  //Menu filter
  isOpenStatusSelect: boolean = false;
  statusValue: string = 'Active';
  statusArray: any[] = [
    {name: 'Active', status: 1},
    {name: 'Inactive', status: 2},
    {name: 'All', status: 0},
  ];

  data: any;

  displayedColumns: string[] = ['index', 'name', 'value', 'rate', 'avg'];
  displayedRateColumns: string[] = ['index', 'name', 'value', 'avg'];

  avgObj: any = {
    avgGross: 0,
    avgNoOfTrucks: 0,
    avgGrossWeekly: 0,
    avgNoOfTrucksWeekly: 0,
    avgMileageWeekly: 0,
    avgNoOfTrucksMileageWeekly: 0,
    avgRate: 0,
    avgNoOfTrucksRate: 0
  };

  //Excel config
  xlsxConfig: any[] = [
    {columnName: 'NO.', selected: true},
    {columnName: 'DISPATCHER', selected: true},
    {columnName: 'GROSS', selected: true},
    {columnName: 'RATE', selected: true},
    {columnName: 'AVG NUMBER OF TRUCKS', selected: true},
    {columnName: 'NO.', selected: true},
    {columnName: 'DISPATCHER', selected: true},
    {columnName: 'GROSS PER TRUCK WEEKLY', selected: true},
    {columnName: 'RATE', selected: true},
    {columnName: 'AVG NUMBER OF TRUCKS', selected: true},
    {columnName: 'NO.', selected: true},
    {columnName: 'DISPATCHER', selected: true},
    {columnName: 'MILEAGE PER TRUCK WEEKLY', selected: true},
    {columnName: 'RATE', selected: true},
    {columnName: 'AVG NUMBER OF TRUCKS', selected: true},
    {columnName: 'NO.', selected: true},
    {columnName: 'DISPATCHER', selected: true},
    {columnName: 'RATE', selected: true},
    {columnName: 'AVG NUMBER OF TRUCKS', selected: true}
  ];

  //Interval
  interval: any;

  loaded: boolean = false;

  error: boolean = false;

  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";

  spinnerBgColor: string = this.transformService.spinnerBgColor;
  spinnerType: string = this.transformService.spinnerType;

  subscription1: Subscription | any;
  subscription2: Subscription | any;

  constructor(
    private dispatchersPerformancesService: DispatchersPerformancesService,
    private sharedService: SharedService,
    public transformService: TransformService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private rulesService: RulesService,
    private dialog: MatDialog,
    private titleCase: TitleCasePipe) { }

  ngOnInit(): void {
    this.spinner.show('top-worst');
    this.getCarouselData();
    this.dateChanged();
    if(this.rulesService.liveUpdate) {
      this.interval = setInterval(() => {
        this.getCarouselData();
      }, this.rulesService.miliseconds);
    }
  }

  getCarouselData() {
    this.subscription1 = this.sharedService.getTopWorstDispatchers(this.date.startDate, this.date.endDate, this.percent, this.dispatchStatus, this.transformService.filterParams)
    .pipe(catchError((err: any) => {
      this.spinner.hide('top-worst');
      this.loaded = true;
      this.error = true;
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      this.spinner.hide('top-worst');
      this.loaded = true;
      this.data = response;
      console.log(response);
    })
  }

  dateChanged() {
    this.subscription2 = this.dispatchersPerformancesService.dateChanged.subscribe((response: any) => {
      this.date.startDate = response.startDate;
      this.date.endDate = response.endDate;
      this.data = undefined;

      this.error = false;
      this.loaded = false;

      this.spinner.show('top-worst');
      this.getCarouselData();
    })
  }

  //Change top worst
  topWorstChange(isTrue: boolean) {
    this.topWorst = isTrue;
    this.topWorst ? (this.value = 'Top', this.selectArray = [...this.topArray]) : (this.value = 'Worst', this.selectArray = [...this.worstArray]);
    this.selectValue = `${this.value} ${this.percent}`;
  }

  countAvg(array: any, keyInAvgObj: string, keyInArray: string) {
    let value: number = 0;
    for(let key in array) {
      value += array[key][keyInArray];
    }
    this.avgObj[keyInAvgObj] = this.addOneDecimalPlace(value / array.length);
    return this.avgObj[keyInAvgObj];
  };

  //Select option
  selectOption(obj: any, percent: boolean) {
    if(percent) {
      this.selectValue = obj.name;
      this.percent = obj.percent;
      this.isOpen = false;
    }
    else {
      this.statusValue = obj.name;
      this.dispatchStatus = obj.status;
      this.isOpenStatusSelect = false;
    }

    this.data = undefined;
    this.error = false;
    this.loaded = false;
    this.spinner.show('top-worst');
    this.getCarouselData();
  };

  //Prev next methods
  public next(): void {
    let scrollValue: any = this.scrollContainer.nativeElement.scrollLeft;
    this.scrollContainer.nativeElement.scrollTo({ left: (scrollValue + 530), behavior: 'smooth' });
  }

  public prev(): void {
    let scrollValue: any = this.scrollContainer.nativeElement.scrollLeft;
    this.scrollContainer.nativeElement.scrollTo({ left: (scrollValue - 530), behavior: 'smooth' });
  }

  //Open in new tab
  openInNewTab(route: string) {
    const url = this.router.serializeUrl(this.router.createUrlTree([route]));
    window.open(url, '_blank');
  }

  setIcon(prev: boolean) {
    if(prev) {
      return '../../../../assets/img/arrow-prev-blue.svg'
    }
  }

  addOneDecimalPlace(num: number) {
    let number = Number(num);
    if (number === Math.floor(number)) {
       return num;
    } else {
       return num.toFixed(1);
    }
  }

  exportToExcel(columnsConfig: any[]) {
    if(this.rulesService.UserData[56].data[0].sectionArray[10].allowed) {
    //By Gross
    let byGrossKey: string = this.topWorst ? 'top_gross' : 'worst_gross';
    let rateKey: string = this.topWorst ? 'top_gross_avg_rate' : 'worst_gross_avg_rate';
    const excelTable1: any[] = [];
    for(let i = 0; i < this.data[byGrossKey].length; i++) {
      let obj: any = {};
      this.transformService.selectedColumn(obj, columnsConfig[0].columnName, i + 1, columnsConfig[0].selected);
      this.transformService.selectedColumn(obj, columnsConfig[1].columnName, this.titleCase.transform(this.data[byGrossKey][i].name), columnsConfig[1].selected);
      this.transformService.selectedColumn(obj, columnsConfig[2].columnName, `$${this.transformService.addCommasDots(this.data[byGrossKey][i].value, 'round') }`, columnsConfig[2].selected);
      this.transformService.selectedColumn(obj, columnsConfig[3].columnName, `$${this.transformService.addCommasDots(this.data[byGrossKey][i].rate)}`, columnsConfig[3].selected);
      this.transformService.selectedColumn(obj, columnsConfig[4].columnName, this.addOneDecimalPlace(this.data[byGrossKey][i].avg), columnsConfig[4].selected);
      excelTable1.push(obj);
    };
    let byGrossFooterObj: any = {};
    this.transformService.selectedColumn(byGrossFooterObj, columnsConfig[0].columnName, '', columnsConfig[0].selected);
    this.transformService.selectedColumn(byGrossFooterObj, columnsConfig[1].columnName, '', columnsConfig[1].selected);
    this.transformService.selectedColumn(byGrossFooterObj, columnsConfig[2].columnName, `$${this.countAvg(this.data[byGrossKey], 'avgGross', 'value')}`, columnsConfig[2].selected);
    this.transformService.selectedColumn(byGrossFooterObj, columnsConfig[3].columnName, `$${this.transformService.addCommasDots(this.data[rateKey])}`, columnsConfig[3].selected);
    this.transformService.selectedColumn(byGrossFooterObj, columnsConfig[4].columnName, this.countAvg(this.data[byGrossKey], 'avgNoOfTrucks', 'avg'), columnsConfig[4].selected);
    excelTable1.push(byGrossFooterObj);
    const ws1: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelTable1);
    const wb1: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb1, ws1, 'Sheet1');
    XLSX.writeFile(wb1, 'by-gross-table.xlsx');

    //By Gross Per Truck
    let byGrossPerTruckKey: string = this.topWorst ? 'top_gross_per_truck' : 'worst_gross_per_truck';
    let ratePerTruckKey: string = this.topWorst ? 'top_gross_per_truck_avg_rate' : 'worst_gross_per_truck_avg_rate';
    const excelTable2: any[] = [];
    for(let i = 0; i < this.data[byGrossKey].length; i++) {
      let obj: any = {};
      this.transformService.selectedColumn(obj, columnsConfig[5].columnName, i + 1, columnsConfig[5].selected);
      this.transformService.selectedColumn(obj, columnsConfig[6].columnName, this.titleCase.transform(this.data[byGrossPerTruckKey][i].name), columnsConfig[6].selected);
      this.transformService.selectedColumn(obj, columnsConfig[7].columnName, `$${this.transformService.addCommasDots(this.data[byGrossPerTruckKey][i].value, 'round') }`, columnsConfig[7].selected);
      this.transformService.selectedColumn(obj, columnsConfig[8].columnName, `$${this.transformService.addCommasDots(this.data[byGrossPerTruckKey][i].rate)}`, columnsConfig[8].selected);
      this.transformService.selectedColumn(obj, columnsConfig[9].columnName, this.addOneDecimalPlace(this.data[byGrossPerTruckKey][i].avg), columnsConfig[9].selected);
      excelTable2.push(obj);
    };
    let byGrossPerTruckFooterObj: any = {};
    this.transformService.selectedColumn(byGrossPerTruckFooterObj, columnsConfig[5].columnName, '', columnsConfig[5].selected);
    this.transformService.selectedColumn(byGrossPerTruckFooterObj, columnsConfig[6].columnName, '', columnsConfig[6].selected);
    this.transformService.selectedColumn(byGrossPerTruckFooterObj, columnsConfig[7].columnName, `$${this.countAvg(this.data[byGrossPerTruckKey], 'avgGrossWeekly', 'value')}`, columnsConfig[7].selected);
    this.transformService.selectedColumn(byGrossPerTruckFooterObj, columnsConfig[8].columnName, `$${this.transformService.addCommasDots(this.data[ratePerTruckKey])}`, columnsConfig[8].selected);
    this.transformService.selectedColumn(byGrossPerTruckFooterObj, columnsConfig[9].columnName, this.countAvg(this.data[byGrossPerTruckKey], 'avgNoOfTrucksWeekly', 'avg'), columnsConfig[9].selected);
    excelTable2.push(byGrossPerTruckFooterObj);
    const ws2: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelTable2);
    const wb2: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb2, ws2, 'Sheet1');
    XLSX.writeFile(wb2, 'by-gross-per-truck-table.xlsx');
    //By Mileage
    let byMileageKey: string = this.topWorst ? 'top_mileage_per_truck' : 'worst_mileage_per_truck';
    let rateMileageKey: string = this.topWorst ? 'top_mileage_per_truck_avg_rate' : 'worst_mileage_per_truck_avg_rate';
    const excelTable3: any[] = [];
    for(let i = 0; i < this.data[byGrossKey].length; i++) {
      let obj: any = {};
      this.transformService.selectedColumn(obj, columnsConfig[10].columnName, i + 1, columnsConfig[10].selected);
      this.transformService.selectedColumn(obj, columnsConfig[11].columnName, this.titleCase.transform(this.data[byMileageKey][i].name), columnsConfig[11].selected);
      this.transformService.selectedColumn(obj, columnsConfig[12].columnName, `${this.transformService.addCommasDots(this.data[byMileageKey][i].value, 'round') }`, columnsConfig[12].selected);
      this.transformService.selectedColumn(obj, columnsConfig[13].columnName, `$${this.transformService.addCommasDots(this.data[byMileageKey][i].rate)}`, columnsConfig[13].selected);
      this.transformService.selectedColumn(obj, columnsConfig[14].columnName, this.addOneDecimalPlace(this.data[byMileageKey][i].avg), columnsConfig[14].selected);
      excelTable3.push(obj);
    };
    let byMileageFooterObj: any = {};
    this.transformService.selectedColumn(byMileageFooterObj, columnsConfig[10].columnName, '', columnsConfig[10].selected);
    this.transformService.selectedColumn(byMileageFooterObj, columnsConfig[11].columnName, '', columnsConfig[11].selected);
    this.transformService.selectedColumn(byMileageFooterObj, columnsConfig[12].columnName, `${this.countAvg(this.data[byMileageKey], 'avgMileageWeekly', 'value')}`, columnsConfig[12].selected);
    this.transformService.selectedColumn(byMileageFooterObj, columnsConfig[13].columnName, `$${this.transformService.addCommasDots(this.data[rateMileageKey])}`, columnsConfig[13].selected);
    this.transformService.selectedColumn(byMileageFooterObj, columnsConfig[14].columnName, this.countAvg(this.data[byMileageKey], 'avgNoOfTrucksMileageWeekly', 'avg'), columnsConfig[14].selected);
    excelTable3.push(byMileageFooterObj);
    const ws3: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelTable3);
    const wb3: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb3, ws3, 'Sheet1');
    XLSX.writeFile(wb3, 'by-mileage-table.xlsx');
    //By Rate
    let byRateKey: string = this.topWorst ? 'top_rate' : 'worst_rate';
    let rateRKey: string = this.topWorst ? 'top_rate_avg_rate' : 'worst_rate_avg_rate';
    const excelTable4: any[] = [];
    for(let i = 0; i < this.data[byGrossKey].length; i++) {
      let obj: any = {};
      this.transformService.selectedColumn(obj, columnsConfig[15].columnName, i + 1, columnsConfig[15].selected);
      this.transformService.selectedColumn(obj, columnsConfig[16].columnName, this.titleCase.transform(this.data[byRateKey][i].name), columnsConfig[16].selected);
      this.transformService.selectedColumn(obj, columnsConfig[17].columnName, `$${this.transformService.addCommasDots(this.data[byRateKey][i].value) }`, columnsConfig[17].selected);
      this.transformService.selectedColumn(obj, columnsConfig[18].columnName, `${this.addOneDecimalPlace(this.data[byRateKey][i].avg)}`, columnsConfig[18].selected);
      excelTable4.push(obj);
    };
    let byRateFooterObj: any = {};
    this.transformService.selectedColumn(byRateFooterObj, columnsConfig[15].columnName, '', columnsConfig[15].selected);
    this.transformService.selectedColumn(byRateFooterObj, columnsConfig[16].columnName, '', columnsConfig[16].selected);
    this.transformService.selectedColumn(byRateFooterObj, columnsConfig[17].columnName, `$${this.transformService.addCommasDots(this.data[rateRKey])}`, columnsConfig[17].selected);
    this.transformService.selectedColumn(byRateFooterObj, columnsConfig[18].columnName, `${this.countAvg(this.data[byRateKey], 'avgNoOfTrucksRate', 'avg')}`, columnsConfig[18].selected);
    excelTable4.push(byRateFooterObj);
    const ws4: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelTable4);
    const wb4: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb4, ws4, 'Sheet1');
    XLSX.writeFile(wb4, 'by-rate-table.xlsx');
    }
    else {
      this.msgForbbidenAccess();
    }
  };

  msgForbbidenAccess() {
    this.dialog.open(MsgForbbidenAccessComponent, {
      autoFocus: false,
      panelClass: 'forbidden-msg-dialog-container'
    })
  };

  ngOnDestroy(): void {
    clearInterval(this.interval);
    this.subscription1?.unsubscribe();
    this.subscription2?.unsubscribe();
  }
}

import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input } from '@angular/core'
import { catchError, throwError } from 'rxjs';
import { AccountingService } from '@app/modules/accounting/services/accounting.service';
import { TransformService } from '@app/modules/shared/services/transform.service';
import { IAccountingInvoicesDetails } from '@app/modules/accounting/models/accounting-invoices-details.model';
import { IAccountingInvoices } from '@app/modules/accounting/models/accounting-invoices.model';
import { MatDialog } from '@angular/material/dialog';
import { RulesService } from '@app/modules/shared/services/rules.service';
import { MsgForbbidenAccessComponent } from '@app/modules/shared/components/msg-forbbiden-access/msg-forbbiden-access.component';

@Component({
  selector: 'invoices-table',
  templateUrl: './invoices-table.component.html',
  styleUrls: ['./invoices-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed,void', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class InvoicesTableComponent {
  permissions: any = this.rulesService.UserData[34].data;

  @Input() dataSource: IAccountingInvoices[] = [];

  columnsToDisplayWithExpand = ['expand', 'id', 'zip', 'invoice_date_from', 'invoice_date_to', 'crated_date', 'crated_by'];
  expandedElement: IAccountingInvoices | null;

  //Nested table
  displayedColumns: string[] = ['position', 'customer', 'load_no', 'broker_load_no', 'freight_amount', 'invoice_date'];
  dataSourceNested : IAccountingInvoicesDetails[] = [];
  dataSourceNestedSkeletion: IAccountingInvoicesDetails[] = [
    {id: 1, invoice_id: 1, customer: 'loader', load_no: 'loader', broker_load_no: 'loader', freight_amount: 1, invoice_date: 'loader'},
    {id: 1, invoice_id: 1, customer: 'loader', load_no: 'loader', broker_load_no: 'loader', freight_amount: 1, invoice_date: 'loader'},
    {id: 1, invoice_id: 1, customer: 'loader', load_no: 'loader', broker_load_no: 'loader', freight_amount: 1, invoice_date: 'loader'},
    {id: 1, invoice_id: 1, customer: 'loader', load_no: 'loader', broker_load_no: 'loader', freight_amount: 1, invoice_date: 'loader'},
    {id: 1, invoice_id: 1, customer: 'loader', load_no: 'loader', broker_load_no: 'loader', freight_amount: 1, invoice_date: 'loader'},
    {id: 1, invoice_id: 1, customer: 'loader', load_no: 'loader', broker_load_no: 'loader', freight_amount: 1, invoice_date: 'loader'},
  ];

  //Status
  loaded: boolean = false;
  error: boolean = false;
  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";

  spinnerBgColor: string = this.transformService.spinnerBgColor;
  spinnerType: string = this.transformService.spinnerType;

  constructor(private accountingService: AccountingService, 
              public transformService: TransformService,
              private dialog: MatDialog,
              private rulesService: RulesService) { }

  getNestedTableData(element: IAccountingInvoices | any) {
    (this.expandedElement = this.expandedElement === element ? null : element);
    if(this.expandedElement) {
      this.loaded = false;
      this.error = false;
      this.accountingService.getInvoicesDetails(element.id)
      .pipe(catchError((err: any) => {
        this.loaded = true;
        this.error = true;
        return throwError(() => err);
      }))
      .subscribe((response: IAccountingInvoicesDetails[]) => {
        console.log(response);
        this.loaded = true;
        this.dataSourceNested = response;
      });
    }
    else {
      this.dataSourceNested = [];
    }
  }

  downloadInvoiceZipFile(element: IAccountingInvoices | any) {
    if(this.permissions[0].sectionArray[1].allowed) {
      element.zipLoading = true;
      this.accountingService.getInvoiceFile(element.id).subscribe((response: any) => {
        console.log(response);
        element.zipLoading = false;
        const anchor = document.createElement('a')
        const objectUrl = URL.createObjectURL(response)
        anchor.href = objectUrl
        anchor.download = 'archive.zip';
        anchor.click();
        URL.revokeObjectURL(objectUrl);
      })
    }
    else {
      this.dialog.open(MsgForbbidenAccessComponent, {
        autoFocus: false,
        panelClass: 'forbidden-msg-dialog-container'
      })
    }
  }

}
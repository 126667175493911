import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as _G from '../../../_config/globals';

@Injectable()

export class LoadBoardService {
  headers = new HttpHeaders({
		'Content-Type': 'application/json',
		'Authorization': 'Basic SVZTb2x1dGlvbjoxUzBsdXQxMG4yQDIy'
	});
	requestOptions = { headers: this.headers };

  loggedUser: any = JSON.parse(localStorage.getItem('currentUser'));
  creatorId: number = 176;

  constructor(private http: HttpClient) { }

  getTrucksByDispatcher(dispId: number) {
	  return this.http.get(`${_G.extendApiUrl}dispatcher/currenttrucksperdisp?disp_id=${dispId}`, this.requestOptions);
  };

  getAllCompanies(dispId: number) {
	  return this.http.get(`${_G.extendApiUrl}loadboard/getloadboard?dispatcherId=${dispId}`, this.requestOptions);
  };

  createProfile(obj: any) {
    let json: string = JSON.stringify(obj);
    console.log(json);
    return this.http.post(`${_G.extendApiUrl}loadboard/profile`, json, this.requestOptions);
  };

  updateProfile(profileId: number, obj: any) {
    let json: string = JSON.stringify(obj);
    return this.http.put(`${_G.extendApiUrl}loadboard/profile?id=${profileId}`, json, this.requestOptions);
  };

  deleteProfile(profileId: number) {
    return this.http.delete(`${_G.extendApiUrl}loadboard/profile?id=${profileId}`, this.requestOptions);
  };

  setProfileActivity(profileId: number, startDate: string, endDate: string, dispId: number) {
    let unsetDate: string = endDate === '' ? '1970-01-01' : endDate;
    return this.http.get(`${_G.extendApiUrl}loadboard/activitytruck?id=${profileId}&from=${startDate}&to=${unsetDate}&dispatcherId=${dispId}`, this.requestOptions);
  };

  deleteProfileActivity(profileId: number, startDate: string, endDate: string) {
    return this.http.delete(`${_G.extendApiUrl}loadboard/activitytruckdelete?id=${profileId}&from=${startDate}&to=${endDate}`, this.requestOptions);
  };
  
}

import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SharedService } from '@app/modules/shared/services/shared.service';
import { TransformService } from '@app/modules/shared/services/transform.service';
import moment = require('moment');
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription, catchError, throwError } from 'rxjs';

@Component({
  selector: 'app-truck-status-history-dialog',
  templateUrl: './truck-status-history-dialog.component.html',
  styleUrls: ['./truck-status-history-dialog.component.scss']
})
export class TruckStatusHistoryDialogComponent implements OnInit, OnDestroy {
  dateObj: any = {
    startDate: this.transformService.convertDateToTimestamp(moment().startOf('year').format('ddd, DD/MM YYYY'), 
    '00:00:00'),
    endDate: this.transformService.convertDateToTimestamp(moment().format('ddd, DD/MM YYYY'),  moment().format('HH:mm:ss')),
  };

  displayedColumns: string[] = ['position', 'status', 'change_by', 'change_date', 'time'];
  dataSource: any[] = [];

  loaded: boolean = false;

  error: boolean = false;

  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";

  spinnerBgColor: string = this.transformService.spinnerBgColor;
  spinnerType: string = this.transformService.spinnerType;


  subscription: Subscription | any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private transformService: TransformService, 
              private sharedService: SharedService,
              private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.spinner.show('status-history');
    this.getTableData();
  }

  getTableData() {
    this.subscription = this.sharedService.getStatusHistory(this.data.truck_id, this.dateObj.startDate, this.dateObj.endDate)
    .pipe(catchError((err: any) => {
      this.spinner.hide('status-history');
      this.loaded = true;
      this.error = true;
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      this.dataSource = response;
      this.spinner.hide('status-history');
      this.loaded = true;
      console.log(response);
    });
  }

  getDate(result: any) {
    this.dateObj.startDate = result.startDate;
    this.dateObj.endDate = result.endDate;
    this.getTableData();
  };

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}


import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fullAppTable'
})
export class FullAppTablePipe implements PipeTransform {

  transform(array: any[], status: string, recruitersArray: string[],  metaData: any): any[] {
    if (!array) {
      return [];
    }

    let countObj: any = {
      allApplications: 0,
      inProgress: 0,
      declined: 0,
      lost: 0,
      scheduled: 0,
      hired: 0
    };
    
    let transformedArray: any[] = array.filter((obj: any) => {
      let condition1: boolean = status === 'All apps' ? true : status.toLocaleLowerCase() === obj.status.toLocaleLowerCase();
      let condition2: boolean = recruitersArray.length === 0 ? true : recruitersArray.includes(obj.recruiter);
      obj.position = '-';
      obj.experience = '-';
      if(condition2) {
        if(obj.status === 'In progress') {
          countObj.inProgress++;
        }
        if(obj.status === 'Declined') {
          countObj.declined++;
        }
        if(obj.status === 'Lost') {
          countObj.lost++;
        }
        if(obj.status === 'Scheduled') {
          countObj.scheduled++;
        }
        if(obj.status === 'Hired') {
          countObj.hired++
        }
      }

      if(condition1 && condition2) {
        if(!(typeof obj.DataJson === 'object')) {
          obj.DataJson = JSON.parse(obj.DataJson);
        }

        if(obj.DataJson.positionGroup.firstCheckbox) {
          obj.position = 'Rent to run';
        }
        if(obj.DataJson.positionGroup.secondCheckbox) {
          obj.position = 'Company';
        }
        if(obj.DataJson.positionGroup.thirdCheckbox) {
          obj.position = 'Owner';
        }
        if(obj.DataJson.cdlGroup.experience) {
          obj.experience = obj.DataJson.cdlGroup.experience;
        }
        return obj;
      }
    });
    countObj.allApplications = transformedArray.length;
    metaData.countObj = countObj;
    return transformedArray;
  }

}

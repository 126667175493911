import { Component, Input } from '@angular/core';
import { TransformService } from '@app/modules/shared/services/transform.service';

@Component({
  selector: 'report-profitability',
  templateUrl: './report-profitability.component.html',
  styleUrls: ['./report-profitability.component.scss']
})
export class ReportProfitabilityComponent {
  @Input() data: any;
  @Input() date: any;
  
  constructor(public transformService: TransformService) { }

  countAverage() {
    let value: number = 
    (this.data['fixed_cost_per_unit'] / this.data['total_trucks_all']) + 
    (this.data['drivers_pay'] / this.data['total_trucks_all'])+ 
    (this.data['fuel'] / this.data['total_trucks_all']) + 
    (this.data['maintenance'] / this.data['total_trucks_all']) + 
    (this.data['tolls'] / this.data['total_trucks_all']);
    return this.transformService.addCommasDots(value, 'round');
  };

  countTotal() {
    let value: number = this.data['fixed_cost_per_unit'] + this.data['drivers_pay'] + this.data['fuel'] + this.data['maintenance'] + this.data['tolls'];
    return this.transformService.addCommasDots(value, 'round');
  };

}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as _G from '../../../_config/globals';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()

export class MapsService {
	headers = new HttpHeaders({
		'Content-Type': 'application/json',
		'Authorization': 'Basic SVZTb2x1dGlvbjoxUzBsdXQxMG4yQDIy'
	});
	requestOptions = { headers: this.headers };

  constructor ( private http: HttpClient ) {}

	getAvailableTrucks() : Observable<any[]> {
		var url = _G.apiUrl + 'Trucks' + _G.DS;
	
		var params = {
			avlMin:   '0',
			avlMax: '2'
		};
		console.log(url)
		return this.http.get<any[]>(url, { params } );
	}
  
}

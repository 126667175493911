import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BrokersAvoidReasonsDialogComponent } from './brokers-avoid-reasons-dialog/brokers-avoid-reasons-dialog.component';
import { DeleteConfirmationDialogComponent } from '@app/modules/shared/components/delete-confirmation-dialog/delete-confirmation-dialog.component';
import { SharedService } from '@app/modules/shared/services/shared.service';
import { Subscription } from 'rxjs';
import moment = require('moment');
import { BrokersToAvoidService } from '@app/modules/brokers-to-avoid/services/brokers-to-avoid.service';
import { IBrokersReasons } from '@app/modules/brokers-to-avoid/models/brokers-reasons.model';
import { IBrokersToAvoid } from '@app/modules/brokers-to-avoid/models/brokers-to-avoid.model';

@Component({
  selector: 'app-add-edit-brokers-to-avoid-dialog',
  templateUrl: './add-edit-brokers-to-avoid-dialog.component.html',
  styleUrls: ['./add-edit-brokers-to-avoid-dialog.component.scss']
})
export class AddEditBrokersToAvoidDialogComponent implements OnInit, OnDestroy {
  userData: any = JSON.parse(localStorage.getItem('currentUser'));
  formGroup: FormGroup;

  //Business partners menu
  businessPartnersArray: any[] = [];
  isOpenBusinessPartnerMenu: boolean = false;
  isOpenMcMenu: boolean = false;

  //Reason menu
  reasonsArray: IBrokersReasons[] = [];
  isOpenReasonMenu: boolean = false;

  //Status menu
  statusArray: any[] = [
    {name: 'Temporary'},
    {name: 'Permanent'}
  ];
  isOpenStatusMenu: boolean = false;

  //Broker search
  brokerSearch: string = '';

  //MC search
  mcSearch: string = '';

  //Subscription
  subscription1: Subscription = new Subscription();
  subscription2: Subscription = new Subscription();

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private dialog: MatDialog,
              private formBuilder: FormBuilder,
              private brokersToAvoidService: BrokersToAvoidService,
              public dialogRef: MatDialogRef<AddEditBrokersToAvoidDialogComponent>,
              private sharedService: SharedService) {
    this.formGroup = this.formBuilder.group({
      'id': [0],
      'bp_id': [null, Validators.required],
      'bp_name': [null, Validators.required],
      'mc': [null, Validators.required],
      'reason': [null, Validators.required],
      'in_current_status': [null],
      'last_check': [null],
      'created_by': [`${this.userData.first_name} ${this.userData.last_name}`],
      'created_by_id': [this.userData.id],
      'checked_by': [''],
      'status': ['', Validators.required],
      'note': [''],
      'deleted': [false],
      'edited_by': [''],
      'edited_date': [null]
    })
  }

  ngOnInit(): void {
    if(this.data.editMode) {
      let obj: any = {...this.data.obj};
      delete obj.isOpenMenu;
      this.formGroup.patchValue(obj);
    };
    this.subscription1 = this.sharedService.getAllBrokers().subscribe((response: any) => {
      this.businessPartnersArray = response.brokers;
      console.log(this.businessPartnersArray)
    });
    this.subscription2 = this.brokersToAvoidService.getAllReasons().subscribe((response: IBrokersReasons[]) => {
      this.reasonsArray = response;
      console.log(response);
    });
  }

  chooseBrokerOrMc(obj: any, brookerMenu: boolean) {
    this.formGroup.patchValue({'bp_id': obj.id}); 
    this.formGroup.patchValue({'bp_name': obj.broker_company}); 
    this.formGroup.patchValue({'mc': obj.mc}); 
    if(brookerMenu) {
      this.isOpenBusinessPartnerMenu = false;
    }
    else {
      this.isOpenMcMenu = false;
    }
  };

  saveData() {
    if(this.data.editMode) {
      this.formGroup.patchValue({
        edited_by: `${this.userData.first_name} ${this.userData.last_name}`,
        edited_date: moment().format('YYYY-MM-DDTHH:mm:ss')
      });
    }
    else {
      this.formGroup.patchValue({
        in_current_status: moment().format('YYYY-MM-DDTHH:mm:ss'),
      });
    }
    this.brokersToAvoidService.createUpdateBrokerToAvoid(this.formGroup.value).subscribe((response: IBrokersToAvoid) => {
      if(response?.id) {
        this.dialogRef.close(response);
      };
    });
  };

  addEditReasonDialog(obj: IBrokersReasons, editMode: boolean) {
    this.isOpenReasonMenu = !this.isOpenReasonMenu;
    let dialogRef = this.dialog.open(BrokersAvoidReasonsDialogComponent, {
      autoFocus: false,
      panelClass: 'component-dialog-container',
      data: {obj: obj, editMode: editMode}
    });
    dialogRef.afterClosed().subscribe((reason: IBrokersReasons) => {
      if(reason) {
        if(obj.id) {
          obj.reason = reason.reason;
        }
        else {
          this.reasonsArray.push(reason);
        }
      }
    });
  };

  deleteReason(id: number, index: number) {
    let dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
      autoFocus: false,
      panelClass: 'delete-dialog-container',
    });
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if(result) {
        this.brokersToAvoidService.deleteReason(id).subscribe((response: boolean) => {
          if(response) {
            this.reasonsArray.splice(index, 1);
          }
          console.log(response);
        })
      }
    });
  };

  //Forms select values
  get businessPartners(): string {
    return this.formGroup.controls['bp_name'].value;
  };

  get mc(): string {
    return this.formGroup.controls['mc'].value;
  };

  get reason(): string {
    return this.formGroup.controls['reason'].value;
  };

  get status(): string {
    return this.formGroup.controls['status'].value;
  };

  ngOnDestroy(): void {
    this.subscription1?.unsubscribe();  
  };

}

import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add authorization header with jwt token if available
		let currentUser = JSON.parse(localStorage.getItem('currentUser'));

      if (currentUser && request.url.search('extend') > 0) {
        request = request.clone({
          setHeaders: {
            Authorization: 'Basic SVZTb2x1dGlvbjoxUzBsdXQxMG4yQDIy'
          }
        });
      }
      else  if (currentUser && currentUser.token && request.url.search('bridge') < 0) {
        request = request.clone({
            setHeaders: { 
                Authorization: `Bearer ${currentUser.token}`
            }
        });
      }
       
      return next.handle(request);
    }
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import * as moment from 'moment';
import { catchError, Subscription, throwError } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { CalendarDialogComponent } from '@app/modules/shared/components/calendar-dialog/calendar-dialog.component';
import { RulesService } from '@app/modules/shared/services/rules.service';
import { SharedService } from '@app/modules/shared/services/shared.service';
import { TransformService } from '@app/modules/shared/services/transform.service';
import { OverviewService } from '../services/overview.service';

@Component({
  selector: 'app-overview-page',
  templateUrl: './overview-page.component.html',
  styleUrls: ['./overview-page.component.scss']
})
export class OverviewPageComponent implements OnInit, OnDestroy {
  permissions: any = this.rulesService.UserData[1].data;
  data: any;
  dateObj: any = {
    startDate: this.transformService.convertDateToTimestamp(moment().startOf('month').format('ddd, DD/MM YYYY'), 
    '00:00:00'),
    endDate: this.transformService.convertDateToTimestamp(moment().format('ddd, DD/MM YYYY'),  moment().format('HH:mm:ss')),
    dispatcherOffice: 'All Offices'
  };

  subscription1: Subscription = new Subscription();
  subscription2: Subscription = new Subscription();

  days: any;
  previousPeriod: any = {};

  secondRange: any;

  //Open
  isOpenMenu: boolean = false;
  dispatcherOfficeArray: string[] = [];

  //Interval
  interval: any;

  loaded: boolean = false;

  error: boolean = false;
  errorMsg: string = '';

  spinnerBgColor: string = this.transformService.spinnerBgColor;
  spinnerType: string = this.transformService.spinnerType;

  constructor(private overviewService: OverviewService, 
              private dialog: MatDialog, 
              private transformService: TransformService,
              private rulesService: RulesService,
              private sharedService: SharedService,
              private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.spinner.show('sp1');
    this.getCards();
    this.getEmployeeOffice();
    if(this.rulesService.liveUpdate) {
      this.interval = setInterval(() => {
        this.getCards();
      }, this.rulesService.miliseconds);
    }
  }

  getCards() {
    let filterParams: string = '';
    let idsArray: any = JSON.parse(localStorage.getItem('selectedCompanies'));
    for(let key in idsArray) {
      filterParams +=	'&filters=' + idsArray[key];
    }

    let start: any;
    let end: any;
    if(this.secondRange?.twoRange) {
      start = this.secondRange.startDate2;
      end = this.secondRange.endDate2;

    } else {
      start = this.dateObj.startDate;
      end = this.dateObj.endDate;
    }

    var startRes = moment(start, "YYYY-MM-DD");
    var endRes = moment(end, "YYYY-MM-DD")
    let difference = Math.abs(moment.duration(startRes.diff(endRes)).asDays());
    this.days = Math.round(difference);
    let obj: any;
    if(this.days === 0) { 
      obj = {startDate: this.transformService.convertDateToTimestamp(moment().subtract(1, 'week').format('ddd, DD/MM YYYY'), 
      '00:00:00'), endDate: this.transformService.convertDateToTimestamp(moment().subtract(1, 'week').format('ddd, DD/MM YYYY'),
      '23:59:59')};
    } else {

      obj = {startDate: this.transformService.convertDateToTimestamp(moment().subtract(difference*2, 'days').format('ddd, DD/MM YYYY'), 
    '00:00:00'), endDate: this.transformService.convertDateToTimestamp(moment().subtract(difference, 'days').format('ddd, DD/MM YYYY'),
    '23:59:59')};
    }

    this.subscription1 = this.overviewService.getCardsData(this.dateObj.startDate, this.dateObj.endDate, obj.startDate, obj.endDate, filterParams, this.dateObj.dispatcherOffice)
    .pipe(catchError((err: any) => {
      this.spinner.hide('sp1');
      this.loaded = true;
      this.error = true;
      this.errorMsg = "Sorry, we're having some temporary server issues. Please contact support";
      return throwError(() => err);
    }))
    .subscribe((response: any[]) => {
      this.data = response[0];
      this.previousPeriod.data = response[1];
      this.previousPeriod.days = this.days;
      this.previousPeriod.twoRange = this.secondRange?.twoRange;
      this.previousPeriod.dayName = moment(this.dateObj.startDate).format('dddd');
      this.spinner.hide('sp1');
      this.loaded = true;
      console.log(response)
    })
  }

  selectDispatcherOffice(office: string) {
    this.dateObj.dispatcherOffice = office; 
    this.isOpenMenu = !this.isOpenMenu;
    this.changeDate(this.dateObj);
  };

  getEmployeeOffice() {
    this.subscription2 = this.sharedService.getAllEmployeeOffice().subscribe((response: any) => {
      console.log(response);
      this.dispatcherOfficeArray = response;
    })
  };

  getDate(result) {
    this.changeDate(result);
  };

  openFloatingDialog() {
    let dialogRef: any = this.dialog.open(CalendarDialogComponent, {
      width: '769px',
      height: '476px',
      autoFocus: false,
      data: {oneRange: false},
      panelClass: 'calendar-dialog-container'
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.changeDate(result);
      }
    });
  }

  changeDate(result: any) {
    this.secondRange = result;
    this.dateObj.startDate = result.startDate;
    this.dateObj.endDate = result.endDate;
    this.overviewService.dateChanged.next(this.dateObj);
    this.data = undefined;
    this.previousPeriod = {};
    this.error = false;
    this.loaded = false;
    this.spinner.show('sp1');
    let data = {
      cards: undefined, key: undefined, title: undefined,
      type: undefined, downCard: undefined, reset: true
    };
    this.overviewService.lineChartSubject.next(data);
    this.getCards();
  }

  refresh() {
    this.changeDate(this.dateObj);
  };

  ngOnDestroy(): void {
    clearInterval(this.interval);
    this.subscription1?.unsubscribe();
    this.subscription2?.unsubscribe();
  };

}

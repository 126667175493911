import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'inspectionTablePipe'
})
export class InspectionTablePipePipe implements PipeTransform {

  transform(array: any[], inspectionType: string, metaData: any): any[] {
    if (!array) {
      return [];
    }

    //Count obj
    let countObj: any = {
      totalPoints: 0,
      totalCharge: 0,
      totalRewards: 0,
      avgPoints: 0,
      avgCharge: 0,
      avgRewards: 0,
      arrayLength: 0
    }
 
    let transformedArray: any[] = array.filter((obj: any) => {
    
      if(inspectionType.toLocaleLowerCase() === obj.inspection_result.toLocaleLowerCase()) { 
        //Count statistics
        countObj.totalPoints += obj.points;
        countObj.totalCharge += obj.amount;
        countObj.totalRewards += obj.rewards;
        return obj;
      }

    });

    //Set statistics data
    countObj.arrayLength = transformedArray.length;
    if(countObj.arrayLength > 0) {
      countObj.avgPoints = countObj.totalPoints / countObj.arrayLength;
      countObj.avgCharge = countObj.totalCharge / countObj.arrayLength;
      countObj.avgRewards = countObj.totalRewards / countObj.arrayLength;
    }

    metaData.countObj = countObj;  

    return transformedArray;

  }

}

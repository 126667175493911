import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { CreateUpdateFormDialogComponent } from './create-update-form-dialog/create-update-form-dialog.component';
import { DeleteConfirmationDialogComponent } from '@app/modules/shared/components/delete-confirmation-dialog/delete-confirmation-dialog.component';
import { FleetHealthService } from '@app/modules/fleet-health/services/fleet-health.service';

@Component({
  selector: 'app-create-update-parts',
  templateUrl: './create-update-parts.component.html',
  styleUrls: ['./create-update-parts.component.scss']
})
export class CreateUpdatePartsComponent implements OnInit {
  displayedColumns: string[] = ['position', 'category', 'subcategory', 'name', 'code', 'created_by', 'created_date', 'edited_by',
  'edited_date', 'actions'];
  dataSource: any = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private dialog: MatDialog,
              private fleetService: FleetHealthService) { }

  ngOnInit(): void {
    this.dataSource = this.data.array;
    console.log(this.data)
  }

  getData() {
    this.fleetService.getAllParts().subscribe((response: any) => {
      let index: number = this.data.isTruck ? 0 : 1;
      this.dataSource = response[index];
      this.fleetService.partsSubject.next(response[index]);
    });
  }

  addUpdateCategory(isNewCategory: boolean, element: any) {
    let dialogRef: any = this.dialog.open(CreateUpdateFormDialogComponent, {
      autoFocus: false,
      panelClass: 'create-update-parts-dialog-container',
      data: {isNew: isNewCategory, obj: element, array: this.data.array, isTruck: this.data.isTruck}
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
        this.getData();
      }
    });
  };

  deletePart(obj: any) {
    let dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
      autoFocus: false,
      panelClass: 'delete-dialog-container',
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
        this.fleetService.deletePart(obj.id)
        .subscribe((response: any) => {
          if(response) {
            this.getData();
          }
        })
      }
    });
  };

}


import { HttpEventType } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import moment = require('moment');
import { TransformService } from '@app/modules/shared/services/transform.service';
import { SharedService } from '@app/modules/shared/services/shared.service';

@Component({
  selector: 'app-hometime-note-dialog',
  templateUrl: './hometime-note-dialog.component.html',
  styleUrls: ['./hometime-note-dialog.component.scss']
})
export class HometimeNoteDialogComponent {
  note: string = '';
  files: any[] = [];
  fileNames: any[] = [];
  showProgress: boolean = false;
  progressDone: number = 0;

  constructor(@Inject(MAT_DIALOG_DATA) public data,
              private transformService: TransformService,
              private sharedService: SharedService) { }

  onFileSelected(event: any) {  
    let length: any = Object.keys(event.target.files).length;
    for(let i = 0; i < length; i++) {
      this.addMultipleFiles(event, i);
    }
  }

  addMultipleFiles(event: any, index: number) {
    let fileName = event.target.files[index].name;
    this.fileNames.push({filename: fileName});
    let reader = new FileReader();
    reader.onload = (e: any) => {
    var uint8 = new Uint8Array(e.target.result);
    var result = [];
    for (var i = 0; i < uint8.length; i++) {
      result.push(uint8[i]);
    }
    let obj: any = {
      driver_id: this.data.driver_id,
      filename: fileName,
      data: result
    };
    this.files.push(obj);
  }

  reader.readAsArrayBuffer(event.target.files[index])
  };

  //Discard
  discard(i : number) {
    this.fileNames.splice(i, 1);
    this.files.splice(i, 1);
  };

  addNote() {
    let loggedUser: any = JSON.parse(localStorage.getItem('currentUser'));
    let obj: any = {
      id: 0,
      driver_id: this.data.driver_id,
      department: loggedUser.department,
      status_id: this.data.status_id,
      note: this.note, 
      craetor: loggedUser.first_name + ' ' + loggedUser.last_name,
      creator_id: loggedUser.id,
      note_date: this.transformService.convertDateToTimestamp(moment(new Date()).format('ddd, DD/MM YYYY'), moment(new Date()).format('HH:mm:ss')),
      files: this.files
    }
    console.log(obj)
    this.sharedService.createEditNote(obj).subscribe((httpResponse: any) => {
      console.log(httpResponse);
      if(obj.files.length > 0) {
        this.showProgress = true;
      }

      if (httpResponse.type === HttpEventType.Response) {
        this.showProgress = false;
        this.progressDone = 0;
      }
      if (httpResponse.type === HttpEventType.UploadProgress) {
        this.progressDone = Math.round(100 * httpResponse.loaded / httpResponse.total);
      } 
      let response: any = httpResponse.body;

      if(response) {
        this.note = '';
        this.data.notes.unshift(response);
        this.fileNames = [];
        this.files = [];
      }
    })
  };

}

import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Subscription } from 'rxjs';
import { TemplateDriverInputsComponent } from '../template-driver-inputs.component';
import { FullAppService } from '@app/modules/full-app/services/full-app.service';
import { IFullAppFields } from '@app/modules/full-app/models/full-app-fields';
import { IFields } from '@app/modules/full-app/models/template-fields.model';
import { DeleteConfirmationDialogComponent } from '@app/modules/shared/components/delete-confirmation-dialog/delete-confirmation-dialog.component';

@Component({
  selector: 'app-driver-inputs-settings',
  templateUrl: './driver-inputs-settings.component.html',
  styleUrls: ['./driver-inputs-settings.component.scss']
})
export class DriverInputsSettingsComponent implements OnInit, OnDestroy {
  //Input Type menu
  isOpenTypeMenu: boolean = false;
  typeValue: string = '';
  typeArray: any[] = [
    {name: 'Input', option: 1},
    {name: 'Select', option: 2},
    {name: 'Checkbox select', option: 3},
    {name: 'Calendar', option: 4},
    {name: 'Timepicker', option: 5},
    {name: 'Calendar and Timepicker', option: 6}
  ];

  //Inputs
  inputsArray: IFullAppFields[] = [];
  inputObj: IFullAppFields = { 
    id: 0,
    full_app_option: 0,
    title: '',
    value: '',
    selectArray: '',
    calendarTitle: '',
    date: null,
    timepickerTitle: '',
    time: null,
    checkboxSelectedValues: '',
    radioBtnSelected: false,
    users: 0
  }
  refresh: boolean = false;

  //Subscription
  subscription: Subscription = new Subscription();

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private dialog: MatDialog,
              public dialogRef: MatDialogRef<TemplateDriverInputsComponent>,
              private fullAppService: FullAppService) { }

  ngOnInit(): void {
    this.inputsArray = [...this.data];
  }

  addInput() {
    this.saveUpdateExtraField(this.inputObj);
    this.resetInputObj();
  }

  saveUpdateExtraField(obj: IFullAppFields) {
    this.fullAppService.saveUpdateeFullAppExtraField(obj)
    .subscribe((response: IFullAppFields) => {
      if(response.id) {
        this.inputsArray.push(response);
      }
      console.log(response);
    });
  }

  deleteInput(obj: IFields, index: number) {
    let dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
      autoFocus: false,
      panelClass: 'delete-dialog-container',
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
        this.fullAppService.deleteFullAppExtraField(obj.id).subscribe((response: any) => {
          if(response) {
            this.refresh = true;
            this.inputsArray.splice(index, 1);
          }
        })
      }
    });
  };

  resetInputObj() {
    this.inputObj = { 
      id: 0,
      full_app_option: 0,
      title: '',
      value: '',
      selectArray: '',
      calendarTitle: '',
      date: null,
      timepickerTitle: '',
      time: null,
      checkboxSelectedValues: '',
      radioBtnSelected: false,
      users: 0
    }
  };

  closeDialog() {
    this.dialogRef.close(this.inputsArray);
   };

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  };

}

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import moment = require('moment');
import { Subscription, catchError, throwError } from 'rxjs';
import { EmployeeDepartmentsComponent } from './employee-departments/employee-departments.component';
import { EmployeeCitiesComponent } from './employee-cities/employee-cities.component';
import { EmployeePositionsComponent } from './employee-positions/employee-positions.component';
import { EmployeeService } from '@app/modules/employee/services/employee.service';

@Component({
  selector: 'app-basic-info',
  templateUrl: './basic-info.component.html',
  styleUrls: ['./basic-info.component.scss']
})
export class BasicInfoComponent implements OnInit, OnDestroy {
  @Input() data: any;

  //Department
  isOpenDepartmentMenu: boolean = false;
  departmentValue: string = '';
  departmentsArray: any[] = [];
  departmentId: number | undefined = undefined;

  //Position
  isOpenPositionMenu: boolean = false;
  positionValue: string = '';
  positionArray: any[] = [];

  //Hiring date
  isOpenHiringCalendar: boolean = false;
  hiringDate: string = '';

  //Contract date
  isOpenContractCalendar: boolean = false;
  contractDate: string = '';

  //Name
  fullName: string = '';

  //Also known as
  alias: string = '';

  //Personal phone number
  personalPhoneNumber: string = '';

  //Emergency phone number
  emergencyPhoneNumber: string = '';

  //Birthday date
  isOpenBirthdayCalendar: boolean = false;
  birthdayDate: string = '';

  //Family holiday date
  isOpenHolidayCalendar: boolean = false;
  holidayDate: string = '';

  //City
  isOpenCityMenu: boolean = false;
  citiesArray: any[] = [];
  city: string = '';

  //Pt id match
  ptIdsArray: any[] = [];
  ptId: number = 0;

  //Company
  companiesArray: any[] = [];

  selectedCompanyArray: any[] = [
    {id: 0, employee_id: 0, division_id: null, company_name: '', email: '', ext: '', is_primary: true, isOpenCompanyMenu: false}
  ];

  //Data for department dialog
  dataDepartment: any[] = [];

  //Employee status
  isActive: boolean = false;

  //Loading - Error
  loading: boolean = false;
  error: boolean = false;

  //Subscription
  subscription1: Subscription | any;
  subscription2: Subscription | any;
  subscription3: Subscription | any;
  subscription4: Subscription | any;

  constructor(private employeeService: EmployeeService,
              public dialogRef: MatDialogRef<any>,
              private dialog: MatDialog) { }

  ngOnInit(): void {
    this.subscription1 = this.employeeService.getEmployeeDepartmentsAndCompanies()
    .subscribe((response: any) => {
      this.departmentsArray = response[0];
      this.companiesArray = response[1].divisions;
      this.dataDepartment = response;
      console.log(response);
    });

    this.subscription2 = this.employeeService.getAllEmployeeCities()
    .subscribe((response: any) => {
      this.citiesArray = response;
      console.log(response);
    })

    this.subscription3 = this.employeeService.getAllEmployeePositions()
    .subscribe((response: any) => {
      this.positionArray = response;
      console.log(response);
    })

    this.subscription4 = this.employeeService.getUsersFromPt()
    .subscribe((response: any) => {
      this.ptIdsArray = response;
      console.log(response);
    })

    console.log(this.data.obj);
    if(this.data.editMode) {
      this.departmentValue = this.data.obj.department;
      this.departmentId = this.data.obj.deparment_id;
      this.positionValue = this.data.obj.position;
      this.hiringDate = this.data.obj.hire_date;
      this.contractDate = this.data.obj.contract_valid_to;
      this.fullName = this.data.obj.name;
      this.alias = this.data.obj.nickname;
      this.personalPhoneNumber = this.data.obj.personal_phone_number;
      this.emergencyPhoneNumber = this.data.obj.emergency_phone_number;
      this.birthdayDate = this.data.obj.birthday;
      this.holidayDate = this.data.obj.family_holiday;
      this.city = this.data.obj.city;
      this.ptId = this.data.obj.pt_id;
      this.selectedCompanyArray = this.data.obj.companies;
      this.isActive = this.data.obj.is_active;
    }
  }

  //Add inputs
  addInputs() {
    this.selectedCompanyArray.push({id: 0, employee_id: 0, division_id: null, company_name: '', email: '', ext: '', is_primary: false,
    isOpenCompanyMenu: false});
  };

  selectCompany(selectedObj: any, obj: any) {
    selectedObj.division_id = obj.id; 
    selectedObj.company_name = obj.name; 
    selectedObj.isOpenCompanyMenu = false;
  }

  deselectOther(index: number) {
    for(let i = 0; i < this.selectedCompanyArray.length; i++) {
      if(i !== index) {
        this.selectedCompanyArray[i].is_primary = false;
      }
    };
    console.log(this.selectedCompanyArray);
  }

  //Get date
  getDate(event: any) {
    return moment(event._d).format('YYYY-MM-DDT00:00:00');
  };

  createEditEmployee() {
    this.loading = true;
    if(this.ptId === -1) {
      for(let i = 0; i < this.selectedCompanyArray.length; i++) {
        for(let j = 0; j < this.ptIdsArray.length; j++) {
          if(this.ptIdsArray[j].email.toLowerCase() === this.selectedCompanyArray[i].email.toLowerCase()) {
            this.ptId = this.ptIdsArray[j].pt_id;
            break;
          }
        }
      }
    }
    let loggedUser: any = JSON.parse(localStorage.getItem('currentUser'));
    let obj: any = {
      id: 0,
      name: this.fullName,
      nickname: this.alias,
      pt_id: this.ptId === -1 ? 0 : this.ptId,
      deparment_id: this.departmentId,
      department: this.departmentValue,
      position: this.positionValue,
      hire_date: this.hiringDate,
      contract_valid_to: this.contractDate,
      personal_phone_number: this.personalPhoneNumber,
      emergency_phone_number: this.emergencyPhoneNumber,
      birthday: this.birthdayDate,
      family_holiday: this.holidayDate,
      city: this.city,
      is_active: this.isActive,
      termination_date: '',
      created_by: loggedUser.first_name + ' ' + loggedUser.last_name,
      created_date: moment().format('YYYY-MM-DDTHH:mm:ss'),
      edited_by: '',
      edited_date: '',
      accounts: [],
      companies: this.selectedCompanyArray,
      documents: [],
      notes: []
    }

    if(this.data.editMode) {
      obj.id = this.data.obj.id;
      obj.created_by = this.data.obj.created_by;
      obj.created_date = this.data.obj.created_date;
      obj.edited_date = moment().format('YYYY-MM-DDTHH:mm:ss');
      obj.edited_by = loggedUser.first_name + ' ' + loggedUser.last_name;
    }
    console.log(obj)
    this.employeeService.createEmployee(obj)
    .pipe(catchError((err: any) => {
      this.loading = false;
      this.error = true;
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      console.log(response);
      if(response) {
        this.dialogRef.close(true);
        this.employeeService.refreshDataSubject.next(true);
      }
      this.loading = false;
    })
  };

  setBtnName() {
    if(this.loading) {
      return 'Loading...' 
    }
    else if(this.data.editMode) {
      return 'Update';
    }
    else {
      return 'Create';
    }
  }

  //Departments settings
  openDepartmentsDialog() {
    this.isOpenDepartmentMenu = false;
    let dialogRef: any = this.dialog.open(EmployeeDepartmentsComponent, {
      autoFocus: false,
      panelClass: 'employee-departments-main-container',
      data: this.dataDepartment
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {  
        console.log(result);
        this.departmentsArray = result[0];
        this.companiesArray = result[1].divisions;
        this.dataDepartment = result;
      }
    });
  }

  //Positions settings
  openPositionsDialog() {
    this.isOpenPositionMenu = false;
    let dialogRef: any = this.dialog.open(EmployeePositionsComponent, {
      autoFocus: false,
      panelClass: 'employee-positions-main-container',
      data: this.positionArray
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {  
        console.log(result);
        this.positionArray = result;
      }
    });
  };

  //Cities settings
  openCitiesDialog() {
    this.isOpenCityMenu = false;
    let dialogRef: any = this.dialog.open(EmployeeCitiesComponent, {
      autoFocus: false,
      panelClass: 'employee-cities-main-container',
      data: this.citiesArray
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {  
        console.log(result);
        this.citiesArray = result;
      }
    });
  };

  //Set month and date
  setMonthAndDay(normalizedMonthAndYear: moment.Moment | any) {
    this.holidayDate = moment(normalizedMonthAndYear._d).format('YYYY-MM-DDT00:00:00');
    this.isOpenHolidayCalendar = false;
  };

  ngOnDestroy(): void {
    this.subscription1?.unsubscribe();
    this.subscription2?.unsubscribe();
    this.subscription3?.unsubscribe();
    this.subscription4?.unsubscribe();
  };

}

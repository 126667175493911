import { CdkDragEnter, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component,  ElementRef, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'app-drag-drop-cards',
  templateUrl: './drag-drop-cards.component.html',
  styleUrls: ['./drag-drop-cards.component.scss']
})
export class DragDropCardsComponent  {
  @ViewChild('dropListContainerFirst') dropListContainerFirst?: ElementRef;
  @ViewChild('dropListContainerSecond') dropListContainerSecond?: ElementRef;

  @Input() firstFourCard: any;
  @Input() secondFourCard: any;

  constructor() { }

  //Drag and drop

  public items1: Array<number> = [1, 2, 3];
  //public items1: Array<number> = [1, 2, 3, 4];
  public items2: Array<number> = [1, 2, 3, 4, 5, 6, 7];

  selectedIndex1: number = 0;

  selectedIndex2: number = -1;

  cardsWithIcon: boolean = false;
  /*
  menuItem1: any[] = ['Active recruiters', 'Ex recruiters', 'Avg recruiter retention',
    'Below 6 month of experience'];
    */
  menuItem1: any[] = ['Active recruiters', 'Avg recruiter\' experience',
    'Below 6 month of experience'];


  menuItem2: any[] = ['Active drivers', 'Less than 37 days number of drivers', 'More than 37 days number of drivers', 'More than 90 days number of drivers',
    'More than a year number of drivers', 'Total trucks whithout drivers','Total trucks whithout drivers %'];

  titlesArray1: string[] = ['ACTIVE RECRUITERS',  'AVG RECRUITER\'S EXPERIENCE',
    'BELOW 6 MONTH OF EXPERIENCE'];
  /*
  titlesArray1: string[] = ['ACTIVE RECRUITERS', 'EX RECRUITERS', 'AVG RECRUITER RETENTION',
    'BELOW 6 MONTH OF EXPERIENCE'];
    */

  titlesArray2: string[] = ['ACTIVE DRIVERS', 'LESS THAN 37 DAYS NUMBER OF DRIVERS',  'MORE THAN 37 DAYS NUMBER OF DRIVERS', 'MORE THAN 90 DAYS NUMBER OF DRIVERS',
    'MORE THAN A YEAR NUMBER OF DRIVERS', 'TOTAL TRUCKS WHITHOUT DRIVERS','TOTAL TRUCKS WHITHOUT DRIVERS %'];

  //keyArray1: string[] = ['active', 'ex', 'experiance', 'belove'];
  keyArray1: string[] = ['active',  'experiance', 'belove'];

  //keyArray1Copy: string[] = ['active', 'ex', 'experiance', 'belove'];
  keyArray1Copy: string[] = ['active',  'experiance', 'belove'];

  keyArray2: string[] = ['active', 'manje_od_37', 'trideset_sedam', 'devedeset', 'godina', 'empty_trucks', 'empty_trucks_percent', 'active_drivers'];
  keyArray2Copy: string[] = ['active', 'manje_od_37', 'trideset_sedam', 'devedeset', 'godina', 'empty_trucks', 'empty_trucks_percent', 'active_drivers'];
  /*
  icon: string[] = ['', '', '', ''];
  */
  icon: string[] = ['', '', ''];
  /*
  iconsArr: string[] = [
    '../../assets/icon-1.png', '../../assets/icon-2.png', '../../assets/icon-3.png', '../../assets/icon-4.png'
  ];
  */
  iconsArr: string[] = [
    '../../assets/icon-1.png', '../../assets/icon-3.png', '../../assets/icon-4.png'
  ];

  activeIndex(i: number, firstGroup: boolean) {
    if (firstGroup) {
      this.selectedIndex1 = i;
    } else {
      this.selectedIndex2 = i;
    }
  };

  changeCard(title: string, keyIndex: number, menuItemIndex: any, firstGroup: boolean) {
    if (firstGroup) {
      this.icon[keyIndex] = this.iconsArr[menuItemIndex];
      this.titlesArray1[keyIndex] = title.toUpperCase();
      this.keyArray1[keyIndex] = this.keyArray1[menuItemIndex];
    } else {
      this.titlesArray2[keyIndex] = title.toUpperCase();
      this.keyArray2[keyIndex] = this.keyArray2Copy[menuItemIndex];
    } 
  };

  //Drag and drop methods

  dropListReceiverElement?: HTMLElement;
  dragDropInfo?: {
    dragIndex: number;
    dropIndex: number;
  };

  dragEntered(event: CdkDragEnter<number>, firstGroup: boolean) {
    const drag = event.item;
    const dropList = event.container;
    const dragIndex = drag.data;
    const dropIndex = dropList.data;

    this.dragDropInfo = { dragIndex, dropIndex };

    const phContainer = dropList.element.nativeElement;
    const phElement = phContainer.querySelector('.cdk-drag-placeholder');

    if (phElement) {
      phContainer.removeChild(phElement);
      phContainer.parentElement?.insertBefore(phElement, phContainer);

      if (firstGroup) {
        moveItemInArray(this.items1, dragIndex, dropIndex);
      } else {
        moveItemInArray(this.items2, dragIndex, dropIndex);
      }
    }
  };

  dragMoved(index: number, firstGroup: boolean) {
    let placeholderElement: any;
    if (firstGroup) {
      if (!this.dropListContainerFirst || !this.dragDropInfo) return;
      placeholderElement =
        this.dropListContainerFirst.nativeElement.querySelector(
          '.cdk-drag-placeholder'
        );
      this.selectedIndex1 = index;
    } else {
      if (!this.dropListContainerSecond || !this.dragDropInfo) return;
      placeholderElement =
        this.dropListContainerSecond.nativeElement.querySelector(
          '.cdk-drag-placeholder'
        );
      this.selectedIndex2 = index;
    }
    const receiverElement =
      this.dragDropInfo.dragIndex > this.dragDropInfo.dropIndex
        ? placeholderElement?.nextElementSibling
        : placeholderElement?.previousElementSibling;

    if (!receiverElement) {
      return;
    }

    receiverElement.style.display = 'none';
    this.dropListReceiverElement = receiverElement;
  }

  dragDropped() {
    if (!this.dropListReceiverElement) {
      return;
    }
    this.dropListReceiverElement.style.removeProperty('display');
    this.dropListReceiverElement = undefined;
    this.dragDropInfo = undefined;
  }

}

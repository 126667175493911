import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { RecruitingService } from '../../services/recruiting.service';

@Component({
  selector: 'app-recruiters',
  templateUrl: './recruiters.component.html',
  styleUrls: ['./recruiters.component.scss']
})
export class RecruitersComponent implements OnInit, OnDestroy {
  @Input() date: any;
  subscription1: Subscription | any;
  subscription2: Subscription | any;
  tableData: any;
  statistics: any[] = [];
  panelOpenStateArr: boolean[] = [];

  tableDataCopy: any;
  arrayLenght: any[] = [];
  displayedColumns1: string[] = [];
  displayedColumns2: string[] = [];

  constructor(private dataService: RecruitingService) { }

  ngOnInit(): void {
    this.getData(this.date);
    this.dateChange();
  }
  getData(dateObj: any) {
    this.subscription1 = this.dataService.getRectuitersAll(dateObj.startDate, dateObj.endDate).subscribe(
      (response: any) => {
        console.log(response);
        this.tableDataCopy = response.data;
        this.initTable(response.data);
      });
  };

  dateChange() {
    this.subscription2 = this.dataService.dateSubject.subscribe((dateObj: any) => {
      this.getData(dateObj);
    });
  };

  //Search by name

  applyFilter(event: Event) {
    const filterValue: any = (event.target as HTMLInputElement).value.toLowerCase();
    let tableData: any = JSON.parse(JSON.stringify(this.tableDataCopy));

    let objReturned: any = {};

    for (let key in tableData) {
      const filtered = tableData[key]
        .filter((key: any) => key.recruiter.toString().toLocaleLowerCase().includes(filterValue));
      objReturned[key] = filtered;
    }
    console.log(tableData)
    console.log(objReturned);
    this.initTable(objReturned);
  }

  initTable(data: any) {
    this.statistics = [];
    this.displayedColumns1 = [];
    this.displayedColumns2 = [];
    this.arrayLenght = [];
    this.tableData = null;

    if (Object.keys(data).length > 0) {
      this.statistics = [];

      for (let key in data) {

        let num = 0;
        let lengthNo: number = 0;
        for (let key2 in data[key]) {
          if (data[key][key2].number !== -1) {
            let number = +data[key][key2].number;
            lengthNo++;
            num += number;
          }

        }
        console.log(this.statistics);
        if (num === 0 && lengthNo === 0) {
          this.statistics.push({ avg: num, sum: num })
        } else {
          this.statistics.push({ avg: (num / lengthNo).toFixed(2), sum: num })
        }
      }

      this.statistics.unshift({}, {});
      this.panelOpenStateArr = new Array(this.statistics.length).fill(false);


      let key: any = Object.keys(data)[0];
      console.log(key);
      data['#ID'] = data[key];
      data['NAME'] = data[key];
      console.log(data);

      let index: number = 1;
      let num: any;

      for (let key in data) {
        if (key !== '#ID' && key !== 'NAME') {
          this.displayedColumns2.push(key);
        }
        num = data[key].length;
        this.displayedColumns1.push('Col-' + index);
        index++;
      }

      this.displayedColumns2.unshift('#ID', 'NAME');
      this.arrayLenght = Array.from(Array(num).keys());
      this.tableData = data;
    }
  }

  //Sort data
  sortData(sort: any) {
    let sortCritery: any[] = [];
    console.log(sort);

    if (!sort.active || sort.direction === '') {
      this.tableData = this.tableDataCopy;
      return;
    }

    this.tableData[sort.active].sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case sort.active:
          if (sort.active === '#ID') {

            return compare(a.recruiter, b.recruiter, isAsc);

          } else if (sort.active === 'NAME') {

            return compare(a.recruiter, b.recruiter, isAsc);

          } else {

            return compare(a.number === -1 ? 0 : +a.number, b.number === -1 ? 0 : +b.number, isAsc);
          }

        default:
          return 0;
      }
    });

    for (let key in this.tableData[sort.active]) {
      sortCritery.push(this.tableData[sort.active][key].driver);
    }

    for (let key in this.tableData) {

      if (key !== sort.active) {

        this.tableData[key].sort((a: any, b: any) => { return sortCritery.indexOf(a.driver) - sortCritery.indexOf(b.driver) })

      }

    }


    console.log(this.tableData)
    console.log(sortCritery);
  }


  ngOnDestroy(): void {
    this.subscription1.unsubscribe();
    this.subscription2.unsubscribe();
  }
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

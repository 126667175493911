import { Component, EventEmitter, Input, Output } from '@angular/core';

interface Config {
  firstValue: string,
  secondValue: string,
  uniqueId: string,
  minWidth: string
}

@Component({
  selector: 'custom-slider',
  templateUrl: './custom-slider.component.html',
  styleUrls: ['./custom-slider.component.scss']
})
export class CustomSliderComponent {
  @Input() config: Config = {
    firstValue: '',
    secondValue: '',
    uniqueId: '',
    minWidth: ''
  }
  @Output() sliderChange = new EventEmitter<boolean>();
  isTrue: boolean = true;

  changeCheckboxValue() {
    this.isTrue = !this.isTrue;
    this.sliderChange.emit(this.isTrue);
  };

} 

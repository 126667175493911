import { Component, OnDestroy, OnInit } from '@angular/core';
import { AccountingService } from '../../services/accounting.service';
import { Subscription, catchError, throwError } from 'rxjs';
import moment = require('moment');
import { TransformService } from '@app/modules/shared/services/transform.service';
import { SharedService } from '@app/modules/shared/services/shared.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { IAccountingBoard } from '../../models/accounting-board.model';

@Component({
  selector: 'app-dashboard-accounting-board-page',
  templateUrl: './dashboard-accounting-board-page.component.html',
  styleUrls: ['./dashboard-accounting-board-page.component.scss']
})
export class DashboardAccountingBoardPageComponent implements OnInit, OnDestroy {
  //Date
  dateObj: any = {
    startDate: moment().subtract(6, 'month').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD')
  };

  dataArray: IAccountingBoard[] = [];

  //Subscription
  subscription: Subscription = new Subscription();

  loaded: boolean = false;
  error: boolean = false;
  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";

  spinnerBgColor: string = this.transformService.spinnerBgColor;
  spinnerType: string = this.transformService.spinnerType;

  constructor(private transformService: TransformService,
              private accountingService: AccountingService,
              private spinner: NgxSpinnerService,
              private sharedService: SharedService) { }

  ngOnInit() {
    this.getTableData();
  };

  getTableData() {
    this.dataArray = [];
    this.error = false;
    this.loaded = false;
    this.spinner.show('accounting-board');
    this.subscription = this.accountingService.getAccountingLoads(this.dateObj.startDate, this.dateObj.endDate)
    .pipe(catchError((err: any) => {
      this.spinner.hide('accounting-board');
      this.loaded = true;
      this.error = true;
      return throwError(() => err);
    }))
    .subscribe((response: IAccountingBoard[]) => {
      this.dataArray = response;
      this.spinner.hide('accounting-board');
      this.loaded = true;
      console.log(response);
    });
  };

  getDate(result: any) {
    this.dateObj.startDate = result.startDate;
    this.dateObj.endDate = result.endDate;
    this.getTableData();
  };

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  };

}

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FleetHealthService } from '@app/modules/fleet-health/services/fleet-health.service';
import moment = require('moment');
import { Subscription, catchError, throwError } from 'rxjs';

@Component({
  selector: 'app-schedule-calendar',
  templateUrl: './schedule-calendar.component.html',
  styleUrls: ['./schedule-calendar.component.scss']
})
export class ScheduleCalendarComponent implements OnInit, OnDestroy {
  //Selected day
  @Input() selectedDay: string | any;
  @Output() changeDate = new EventEmitter<any>();

  //Date 
  dateObj: any = {
    startDate: moment().startOf('month').format('YYYY-MM-DDT00:00:00'),
    endDate:  moment().endOf('month').format('YYYY-MM-DDT23:59:59')
  };

  //Calendar popup
  isOpen: boolean = false;
  date: any = new Date();

  //Calendar
  currentMonth: Array<Date> | any = [];

  days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

  //Spots array
  spotsArray: any[] = [];

  //Reservation date
  reservationDate: Date = new Date();

  //Is change allowed
  changeDayAllowed: boolean = true;

  error: boolean = false;
  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";

  //Show spinner
  showSpinner: boolean = true;

  //Subscription
  subscription1: Subscription | any;
  subscription2: Subscription | any;
  subscription3: Subscription | any;

  constructor(private fleetHealthService: FleetHealthService) { }

  ngOnInit(): void {
    this.dateObj.startDate = moment(new Date(this.selectedDay)).startOf('month').format('YYYY-MM-DDT00:00:00');
    this.dateObj.endDate = moment(new Date(this.selectedDay)).endOf('month').format('YYYY-MM-DDT23:59:59');
    this.getCalendarData();
    this.refreshData();
    this.changeDay();
  }

  getCalendarData() {
    console.log(this.dateObj)
    this.subscription1 = this.fleetHealthService.getScheduleCalendarData(this.dateObj.startDate, this.dateObj.endDate)
    .pipe(catchError((err: any) => {
      this.error = true;
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      this.error = false;
      console.log(response);
      this.spotsArray = response;
      this.setMonths();
    });
  }

  refreshData() {
    this.subscription2 = this.fleetHealthService.refreshDataSubject.subscribe((response: any) => {
      this.showSpinner = response;
      this.getCalendarData();
    })
  };

  changeDay() {
    this.subscription3 = this.fleetHealthService.dataReturnedSubject.subscribe((response: any) => {
      this.changeDayAllowed = response;
    });
  }

  setMonths() {
    this.setDate(new Date(this.dateObj.endDate));
  }

  setMonth(prev: boolean) {
    let startDate: Date = new Date(this.dateObj.startDate);
    let endDate: Date = new Date(this.dateObj.endDate);
    if(prev) {
      this.dateObj.startDate = moment(startDate).subtract(1, "month").startOf('month').format('YYYY-MM-DDT00:00:00'),
      this.dateObj.endDate = moment(endDate).subtract(1, "month").endOf('month').format('YYYY-MM-DDT23:59:59');
    }
    else {
      this.dateObj.startDate = moment(startDate).add(1, "month").startOf('month').format('YYYY-MM-DDT00:00:00'),
      this.dateObj.endDate = moment(endDate).add(1, "month").endOf('month').format('YYYY-MM-DDT23:59:59');
    }
    this.getCalendarData();
  }

  setDate(date: any) {
    let dateObj: any = this.getDaysInMonth(date);
    if (dateObj.firstDay === 1) {
      this.currentMonth = dateObj.dateArray;
    } 
    else {
      this.currentMonth = dateObj.dateArray;
      for (let i = 1; i < dateObj.firstDay; i++) {
        this.currentMonth.unshift({date: '-', id: null, available: -1, created_by: null, created_date: null, edited_by: null, edited_date: null, slots: -1});
      }
    }
  }

  getDaysInMonth(date: any) {
    let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    return { firstDay: this.getLocalDay(firstDay.getDay()), lastDay: lastDay.getDate(), dateArray: this.getDatesInRange(firstDay, lastDay)}
  }

  getDatesInRange(startDate: any, endDate: any) {
    const date = new Date(startDate.getTime());
    const dates = [];
    while (date <= endDate) {
      let d1 = moment(date).format('YYYY-MM-DDT00:00:00');
      let obj: any = {date: d1, id: null, available: -1, created_by: null, created_date: null, edited_by: null, edited_date: null, slots: -1};
      this.spotsArray.some((currObj) => { 
        obj = currObj
        return currObj.date == d1;
      })

      if(this.selectedDay === d1) {
        this.fleetHealthService.dateSubject.next({obj: obj, showSpinner: this.showSpinner});
      }

      dates.push(obj);
      date.setDate(date.getDate() + 1);
    }
    return dates;
  }

  getLocalDay(day: number) {
    return day === 0 ? day = 7 : day;
  }

  //Calendar popup prover
  setMonthAndYear(normalizedMonthAndYear: moment.Moment | any) {
    this.date = normalizedMonthAndYear._d;
    this.dateObj.startDate = moment(normalizedMonthAndYear._d).subtract(1, "month").startOf('month').format('YYYY-MM-DDT00:00:00');
    this.dateObj.endDate = moment(normalizedMonthAndYear._d).endOf('month').format('YYYY-MM-DDT23:59:59');
    this.getCalendarData();
    this.isOpen = false;
  };
  
  makeReservation(obj: any) {
    if(this.changeDayAllowed) {
      this.changeDate.emit(obj.date);
      this.fleetHealthService.dateSubject.next({obj: obj, showSpinner: true});
      this.changeDayAllowed = false;
    }
  };

  setClass(obj: any) {
    let date: Date = new Date(obj.date);
    if(moment(date).format('MM-DD-yyyy') === moment().format('MM-DD-yyyy')) {
      return 'blue-border';
    }
    else if(obj.slots === 0) {
      return 'restricted-color';
    }
    else {
      return '';
    }
  };

  getColorForPast(obj: any) {
    let date: Date = new Date(obj.date);
    if(moment(date).isBefore(moment().format('MM-DD-yyyy'))) {
      return 'grey-color';
    }
    else {
      return 'blue-color';
    }
  }

  ngOnDestroy(): void {
    this.subscription1?.unsubscribe();
    this.subscription2?.unsubscribe();
    this.subscription3?.unsubscribe();
  }

}
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ImagePreviewComponent } from '@app/modules/shared/components/image-preview/image-preview.component';

@Component({
  selector: 'app-photos-of-inspection-dialog',
  templateUrl: './photos-of-inspection-dialog.component.html',
  styleUrls: ['./photos-of-inspection-dialog.component.scss']
})
export class PhotosOfInspectionDialogComponent implements OnInit {
  photosArray: any[] = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialog: MatDialog) { }

  ngOnInit(): void {
    console.log(this.data);
    if(this.data.inspectionArray.length > 0) {
      for(let i = 0; i < this.data.inspectionArray.length; i++) {
        if(this.data.inspectionArray[i].itemID === this.data.ID) {
          this.photosArray.push(this.data.inspectionArray[i])
        }
      }
    };
    console.log(this.photosArray); 
  };

  previewImage(image: string) {
    this.dialog.open(ImagePreviewComponent, {
      autoFocus: false,
      panelClass: 'component-dialog-container',
      data: image
    });
  };
}

import { Injectable } from '@angular/core';
import * as _G from '../../../_config/globals';
import { HttpClient } from '@angular/common/http';
import { Subject, forkJoin } from 'rxjs';

@Injectable()

export class RecruitingService {
  dateSubject = new Subject<any>();
  driversOverchecksType = new Subject<any>();
  averagePaychecksData = new Subject<any>();

  constructor(private http: HttpClient) { }

  getCards() {
    let response1 = this.http.get(`${_G.apiUrl}recruiting/Overview`);
    let response2 = this.http.get(`${_G.apiUrl}recruiting/DriverOverview`);

    return forkJoin([response1, response2]);
  };

  getEmptyTrucks(startDate: string, endDate: string) {
    return this.http.get(`${_G.apiUrl}recruiting/Emptytrucks?startDate=${startDate}&endDate=${endDate}`);
  };

  getPieChartData(groupBy: string, startDate: string, endDate: string) {
    return this.http.get(`${_G.apiUrl}Drivers/TypeDriverOverview?groupBy=${groupBy}&startDate=${startDate}&endDate=${endDate}`);
  };

  getHiredTerminatedDrivers(groupBy: string, startDate: string, endDate: string) {
    return this.http.get(`${_G.apiUrl}Drivers/HireTermDriverOverview?groupBy=${groupBy}&startDate=${startDate}&endDate=${endDate}`);
  };

  getDriversPaycheks(startDate: string, endDate: string, type: string) {
    return this.http.get(`${_G.apiUrl}Drivers/Statements/overview?startDate=${startDate}&endDate=${endDate}&tip=${type}`);
  };

  getRectuiters(startDate: string, endDate: string) {
    return this.http.get(`${_G.apiUrl}recruiting/RecruiterOverview?startDate=${startDate}&endDate=${endDate}`);
  };

  getExRectuiters(startDate: string, endDate: string) {
    return this.http.get(`${_G.apiUrl}recruiting/ExRecruiterOverview?startDate=${startDate}&endDate=${endDate}`);
  };

  getAllRectuiters(startDate: string, endDate: string) {
    let response1 = this.http.get(`${_G.apiUrl}recruiting/RecruiterOverview?startDate=${startDate}&endDate=${endDate}`);
    let response2 = this.http.get(`${_G.apiUrl}recruiting/ExRecruiterOverview?startDate=${startDate}&endDate=${endDate}`);

    return forkJoin([response1, response2]);
  }

  getRectuitersAll(startDate: string, endDate: string) {
    return this.http.get(`${_G.apiUrl}recruiting/AllRecruiterOverview?startDate=${startDate}&endDate=${endDate}`);
  };

}

import { Component, Inject } from '@angular/core';
import { Notification } from '../notifications-dialog/notification';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import moment = require('moment');

@Component({
  selector: 'app-notifications-snackbar',
  templateUrl: './notifications-snackbar.component.html',
  styleUrls: ['./notifications-snackbar.component.scss'],

})
export class NotificationsSnackbarComponent {
  notifications: Notification[] = [];

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any, public snackBarRef: MatSnackBarRef<any>) {
    if(this.data) {
      this.notifications = this.data
    }
   }

   getText() {
    return this.notifications.map((e: any) => e.type).join(", ");
   }

   calculateTimeDifference(date: any) {
    let dateToConvert: string = date.split('.')[0];

    let timeZone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let dateToDisplay: any = '';
    if(timeZone.startsWith('America')) {
      let date: any = new Date(dateToConvert);
      date.setHours(date.getHours() - 7)
      dateToDisplay = moment(date).format('YYYY-MM-DDTHH:mm:ss');
    }
    else if(timeZone.startsWith('Europe')) {
      let date: any = new Date(dateToConvert);
      date.setHours(date.getHours() - 1)
      dateToDisplay = moment(date).format('YYYY-MM-DDTHH:mm:ss');
    }
    else {
      dateToDisplay = dateToConvert;
    }
    return dateToDisplay;
  };

  closeSnackBar() {
    this.data.preClose();
  }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../core/services/auth/auth.guard';
import { LoadsDriverAppComponent } from './components/safety-page/loads-driver-app/loads-driver-app.component';
import { SafetyPageComponent } from './components/safety-page/safety-page.component';
import { AccidentsPageComponent } from './components/accidents-page/accidents-page.component';

const routes: Routes = [
	{
		path: 'analytics',
		children: [
		  {
			path: 'safety-page',
			component: SafetyPageComponent,
			canActivate: [AuthGuard],
			data: { roles: 10 }
		  },
		  {
			path: 'loads',
			component: LoadsDriverAppComponent,
			canActivate: [AuthGuard],
			data: { roles: 0 }
		  },
		  {
			path: 'accidents',
			component: AccidentsPageComponent,
			canActivate: [AuthGuard],
			data: { roles: 43 }
		  }
		],
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class SafetyRoutingModule { }

import { Component, Inject } from '@angular/core';
import { SectionType } from '../section';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DeleteConfirmationDialogComponent } from '@app/modules/shared/components/delete-confirmation-dialog/delete-confirmation-dialog.component';
import { RulesService } from '@app/modules/shared/services/rules.service';
import { EmployeeService } from '../../services/employee.service';

@Component({
  selector: 'app-employee-data-dialog',
  templateUrl: './employee-data-dialog.component.html',
  styleUrls: ['./employee-data-dialog.component.scss']
})
export class EmployeeDataDialogComponent {
  permissions: any = this.rulesService.UserData[24].data;
  sectionArray: string[] = ['Basic Info', 'Notes', 'Documentation', 'Account', 'Activity stats'];
  activeSection: string = 'Basic Info';
  get SectionType() {
    return SectionType;
  }

  constructor(@Inject(MAT_DIALOG_DATA) public obj: any,
              private employeeService: EmployeeService,
              private rulesService: RulesService,
              private dialog: MatDialog,
              public dialogRef: MatDialogRef<any>) { }

  changeSection(section: string, index: number) {
    if(this.obj.editMode && this.permissions[1].sectionArray[index].allowed) {
      this.activeSection = section;
    }
  }


  deleteEmployee() {
    let dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
      autoFocus: false,
      panelClass: 'delete-dialog-container',
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
        console.log(this.obj.obj.id)
        this.employeeService.deleteEmployee(this.obj.obj.id)
        .subscribe((response: any) => {
          console.log(response);
          if(response) {
            this.dialogRef.close(true);
          }
        });
      }
    })
  };
  
}

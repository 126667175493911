import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DashboardDriversPerformancePageComponent } from './components/dashboard-drivers-performance-page.component';
import { AuthGuard } from '../core/services/auth/auth.guard';

const routes: Routes = [
	{
		path: 'drivers',
		//canActivate: [AuthGuard],
		children: [
		  {
			path: 'drivers-performance',
			canActivate: [AuthGuard],
			component: DashboardDriversPerformancePageComponent,
			data: { roles: 16 }
		  },
		]
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class DriversPerformancePageRoutingModule { }

import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TransformService } from '@app/modules/shared/services/transform.service';

import { ApexAxisChartSeries, ApexChart, ApexDataLabels, ApexGrid, ApexStroke, ApexXAxis, ApexYAxis, ChartComponent } from 'ng-apexcharts';
import { Subscription } from 'rxjs';
import { DispatchersPerformancesService } from '../../services/dispatchers-performances.service';

export type ChartOptions = {
  series: ApexAxisChartSeries | any;
  chart: ApexChart | any;
  xaxis: ApexXAxis | any;
  yaxis: ApexYAxis | any;
  dataLabels: ApexDataLabels | any;
  grid: ApexGrid | any;
  stroke: ApexStroke | any;
  markers: any;
  colors: any;
};

@Component({
  selector: 'app-dispatch-cards-line-chart',
  templateUrl: './dispatch-cards-line-chart.component.html',
  styleUrls: ['./dispatch-cards-line-chart.component.scss']
})
export class DispatchCardsLineChartComponent implements OnInit,  OnDestroy {
  @Input() date: any;
  @ViewChild("chart") chart: ChartComponent | any;
  public chartOptions: Partial<ChartOptions> | any;

  subscription: Subscription | any;

  selectPeriod: FormControl | any;

  graphData: any[] = [];

  addedNames: string[] = [];

  obj: any = { downCard: false };

  //Period
  isOpenPeriodMenu: boolean = false;
  menuValue: string = 'By day';
  selectPeriodArray: any[] = [
    {name: 'By year', value: 'year'},
    {name: 'By quarter', value: 'quarter'},
    {name: 'By month', value: 'month'},
    {name: 'By week', value: 'week'},
    {name: 'By day', value: 'day'},
  ];
  

  constructor(private dispatchersPerformancesService: DispatchersPerformancesService, private transformService: TransformService) {
    this.selectPeriod = new FormControl(['day']);
  }

  ngOnInit(): void {
    this.dataChanged();
  }

  dataChanged() {
    this.subscription = this.dispatchersPerformancesService.dispatchCardsLineChartSubject.subscribe((data: any) => {
      if (data.reset) {
        this.graphData = [];
        this.addedNames = [];
        return;
      }

      if (this.obj.downCard !== data.downCard) {
        this.graphData = [];
        this.addedNames = [];
      }
      if (this.addedNames.includes(data.title) && this.addedNames.length > 0) {
        this.graphData = this.graphData.filter(object => {
          return object.name[0] !== data.title
        });
        this.addedNames = this.addedNames.filter(name => {
          return name !== data.title
        });
      } else {
        let condition1: boolean = data?.key === 'avg_weight';
        let condition2: boolean = data?.key === 'active_drivers';
        let condition3: boolean = data?.key === 'rate_per_mile';
        let condition4: boolean = data?.key === 'lengt_per_load';
        let condition5: boolean = data?.key === 'empty_trucks'; 
        let condition6: boolean = data?.key === 'active_trailers'; 

        let array = [condition1, condition2, condition3, condition4, condition5, condition6];
        let graphData: any = array.includes(true) && data.downCard === false ? data?.cards.total_list : data?.cards.list;
  
        this.addedNames.push(data.title);

        let type = data?.type;

        if (data?.type === 'YARD' || data?.type === 'BILLABLE') {
          type = 'COMPANY';
        };
        if (data?.type === 'RECOVERY' || data?.type === 'PAID') {
          type = 'RENT TO RUN';
        };
        if (data?.type === 'DEALERSHIP' || data?.type === 'SAVED') {
          type = 'OWNER';
        } 
        
        console.log(type)
        this.graphData.push(this.transformService.countDataByPeriodOverview(graphData, this.selectPeriod.value[0], data?.key,
          type, data?.title, condition1 || condition2 || condition3 || condition4 || condition5 || condition6));
      }
      
      if(this.graphData.length > 0) {
        this.initGraph(this.graphData);
      }
      this.obj.downCard = data.downCard;
    })
  }

  initGraph(data: any) {
    let allDatesArray: any[] = this.transformService.getDatesInRange(new Date(this.date.startDate), new Date(this.date.endDate), this.selectPeriod.value[0]);
    let arrayData: any[] = JSON.parse(JSON.stringify(data));
    let seriesData: any[] = [];
    console.log(allDatesArray);
    for (let key in arrayData) {

      let obj: any = {
        name: arrayData[key].name,
        data: arrayData[key].total
      }
      
      for(let i = 0; i < allDatesArray.length; i++) {
          if(arrayData[key].categories[i] !== allDatesArray[i]) {
            arrayData[key].categories.splice(i, 0, allDatesArray[i])
            arrayData[key].total.splice(i, 0, 0);
          }
      }
      seriesData.push(obj)
    }
    this.chartOptions = {
      series: seriesData,
      chart: {
        toolbar: {
          show: true,
          tools: {
            download: false
          },
          animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 800,
            animateGradually: {
                enabled: true,
                delay: 150
            },
            dynamicAnimation: {
                enabled: true,
                speed: 350
            }
          }
        },
        height: 350,
        type: "line",
        zoom: {
          enabled: false
        },
        fontFamily: 'Poppins'
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: "straight",
        width: 1,
      },
      grid: {
        row: {
          colors: ["transparent", "transparent"],
          opacity: 0.5
        }
      },
      xaxis: {
        categories: arrayData[0].categories
      },
      yaxis: this.addYAxis(seriesData),
      markers: {
        size: 5
      },
      colors: ['#0030FF', '#FA9120', '#FA0000', '#008000', '#FF00FF', '#800000', '#FE7777', '#0303BB', '#008FA9', '#7550CB', '#6A6D6E'],
      tooltip: {
        enabled: true,
        inverseOrder: false,
        style: {
          fontSize: '12px'
        },
      }
    };
  }

  addYAxis(array: any) {
    let yAxisArray: any[] = [];
    let colors: string[] = ['#0030FF', '#FA9120', '#FA0000', '#008000', '#FF00FF', '#800000', '#FE7777', '#0303BB', '#008FA9', '#7550CB', '#6A6D6E'];
    for(let i = 0; i < array.length; i++) {
      let obj: any;
      if(i === 0) {
        obj = {
        seriesName: array[i].name[0], axisTicks: {show: true}, axisBorder: {show: false, color: colors[i]},
        labels: {style: {colors: colors[i]}, formatter: (num: number) => { return num.toLocaleString("en-US").toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}
        }
      } 
      else {
        obj = {
          seriesName: array[i].name[0], opposite: true, axisTicks: {show: true}, axisBorder: {show: false, color: colors[i]},
          labels: {style: {colors: colors[i]}, formatter: (num: number) => { return num.toLocaleString("en-US").toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}
        }
      }

      yAxisArray.push(obj);
    }

    return yAxisArray;
  }

  onlyOneSelect(value: string, obj: any) {
    this.menuValue = obj.name;
    this.selectPeriod = new FormControl([value]);
    let lineChartData: any = JSON.parse(JSON.stringify(this.graphData));
    this.graphData = [];
    for (let key in lineChartData) {
      let condition1: boolean = lineChartData[key].key === 'avg_weight';
      let condition2: boolean = lineChartData[key].key === 'active_drivers';
      let condition3: boolean = lineChartData[key].key === 'rate_per_mile';
      let condition4: boolean = lineChartData[key].key === 'lengt_per_load';
      let condition5: boolean = lineChartData[key].key === 'empty_trucks'; 
      
      this.graphData.push(this.transformService.countDataByPeriodOverview(lineChartData[key].data, this.selectPeriod.value[0],
        lineChartData[key].key, lineChartData[key].type, lineChartData[key].title,
        condition1 || condition2 || condition3 || condition4 || condition5));
    }

    this.initGraph(this.graphData);
    this.isOpenPeriodMenu = false;
  };

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

}

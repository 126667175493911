import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'driverFilter'
})
export class DriverFilterPipe implements PipeTransform {

  transform(array: any[], searchValuesArray: any[], objNumValue: any[], metaData: any): any[] {
    if (!array) {
      return [];
    }

    //Statistics data
    let numOfAllDrivers: number = 0;
    let sumGross: number = 0;
    let sumRpm: number = 0;
    let sumMileage: number = 0;
    let sumMarginPercent: number = 0;
    let sumMargin: number = 0;
    let sumLengthPerLoad: number = 0;
    let sumGrossPerLoad: number = 0;
    let sumLoads: number = 0;
    let sumLastPaycheck: number = 0;
    let sumLeaseAmount: number = 0;
    let sumTimeInCompany: number = 0;

    //Obj to return
    let countObj: any = {
      numOfAllDrivers: 0,
      gross: 0,
      rate_per_mile: 0,
      mileage: 0,
      margin_percent: 0,
      margin: 0,
      length_per_load: 0,
      gross_per_load: 0,
      loads: 0,
      last_paycheck: 0,
      time_in_company: 0
    };
    
    let transformedArray: any[] = array.filter((obj: any) => {
      let key1: boolean = searchValuesArray[0].length === 0 ? true : searchValuesArray[0].includes(obj.name);
      let key2: boolean = searchValuesArray[1].length === 0 ? true : searchValuesArray[1].includes(obj.unit_no);
      let key3: boolean = searchValuesArray[2].length === 0 ? true : searchValuesArray[2].includes(obj.trailer_no);
      let key4: boolean = searchValuesArray[3].length === 0 ? true : searchValuesArray[3].includes(obj.is_active ? 'Active' : 'Inactive');
      let key5: boolean = searchValuesArray[4].length === 0 ? true : searchValuesArray[4].includes(obj.dispatcher);
      let key6: boolean = searchValuesArray[5].length === 0 ? true : searchValuesArray[5].includes(obj.recruiter);
      let key7: boolean = searchValuesArray[6].length === 0 ? true : searchValuesArray[6].includes(obj.type);
      let key8: boolean = searchValuesArray[7].length === 0 ? true : searchValuesArray[7].includes(obj.deal);
      let key9: boolean = true;
      let key10: boolean = true;
      let key11: boolean = true;
      let key12: boolean = true;
      let key13: boolean = true;
      let key14: boolean = true;
      let key15: boolean = true;
      let key16: boolean = true;
      let key17: boolean = true;
      let key18: boolean = true;
      let key19: boolean = true;

      key9 = checkRadioBtns(0);
      key10 = checkRadioBtns(1);
      key11 = checkRadioBtns(2);
      key12 = checkRadioBtns(3);
      key13 = checkRadioBtns(4);
      key14 = checkRadioBtns(5);
      key15 = checkRadioBtns(6);
      key16 = checkRadioBtns(7);
      key17 = checkRadioBtns(8);
      key18 = checkRadioBtns(9);
      key19 = checkRadioBtns(10);

      function checkRadioBtns(index: number) {
        if(objNumValue[index].searchValue) {
          let key: boolean | undefined = undefined;
          if(objNumValue[index].operator === 'More than') {
            key = Number(obj[objNumValue[index].key]) > Number(objNumValue[index].searchValue);
          }
          if(objNumValue[index].operator === 'Less than') {
            key = Number(obj[objNumValue[index].key]) < Number(objNumValue[index].searchValue);
          }
          if(objNumValue[index].operator === 'Equal') {
            key = Number(obj[objNumValue[index].key]) == Number(objNumValue[index].searchValue);
          }
          return key;
        }
        else {
          return true;
        }
      };

      if(key1 && key2 && key3 && key4 && key5 && key6 && key7 && key8 && key9 && key10 && key11 && key12 
        && key13 && key14 && key15 && key16 && key17 && key18 && key19) { 
        //Count statistics 
        sumGross += obj.gross;
        sumRpm += obj.rate_per_mile;
        sumMileage += obj.mileage;
        sumMarginPercent += obj.margin_percent;
        sumMargin += obj.margin;
        sumLengthPerLoad += obj.lengt_per_load;
        sumGrossPerLoad += obj.gross_per_load;
        sumLoads += obj.loads;
        sumLastPaycheck += obj.paycheck;
        sumLeaseAmount += obj.lease_amount;
        sumTimeInCompany += obj.time_in_company;
      
        return obj;
      }

    });

    //Set statistics data
    numOfAllDrivers = transformedArray.length;
    countObj.numOfAllDrivers = numOfAllDrivers;
    countObj.gross = sumGross,
    countObj.rate_per_mile = sumGross / sumMileage,
    countObj.mileage = sumMileage,
    countObj.margin_percent = (sumMargin / sumLeaseAmount) * 100,
    countObj.margin = sumMargin,
    countObj.length_per_load = sumMileage / sumLoads,
    countObj.gross_per_load = sumGross / sumLoads,
    countObj.loads = sumLoads,
    countObj.last_paycheck = sumLastPaycheck / numOfAllDrivers,
    countObj.time_in_company = sumTimeInCompany / numOfAllDrivers
    countObj.array = transformedArray;

    metaData.countObj = countObj;
    return transformedArray;

  }

}

import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SharedService } from '@app/modules/shared/services/shared.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-assign-truck-trailer-dialog',
  templateUrl: './assign-truck-trailer-dialog.component.html',
  styleUrls: ['./assign-truck-trailer-dialog.component.scss']
})
export class AssignTruckTrailerDialogComponent implements OnInit, OnDestroy {
  //Assign truck data
  assignTruckTrailerArray: any[] = [];
  isOpenAssignTruckTrailerMenu: boolean = false;
  selectedValue: string = '';
  truckIdTrailerId: number | undefined = undefined;

  //Filter
  truckTrailerNoSearch: string = '';

  //Subscription
  subscription: Subscription = new Subscription();

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<AssignTruckTrailerDialogComponent>, 
  private sharedService: SharedService) { }

  ngOnInit(): void {
    console.log(this.data);
    this.selectedValue = this.data.selectedValue;
    if(this.data.assignTruck) {
      this.subscription = this.sharedService.getAllTrucks().subscribe((response: any) => {
        console.log(response);
        this.assignTruckTrailerArray = response;
      });
    }
    else {
      this.subscription = this.sharedService.getAllTrailers().subscribe((response: any) => {
        console.log(response);
        this.assignTruckTrailerArray = response;
      });
    }
  };

  selectOption(obj: any) {
    this.selectedValue = obj.unit_no;
    this.truckIdTrailerId = obj.id;
    this.isOpenAssignTruckTrailerMenu = !this.isOpenAssignTruckTrailerMenu;
  }

  save() {
    this.dialogRef.close({truckTrailerId: this.truckIdTrailerId, truckTrailerValue: this.selectedValue});
  };

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  };
  
}



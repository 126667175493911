import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TransformService } from '@app/modules/shared/services/transform.service';
import moment = require('moment');
import { Subscription, catchError, throwError } from 'rxjs';
import { DispatcherProfileService } from '../../services/dispatcher-profile.service';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { EmployeeStatusDialogComponent } from '@app/modules/shared/components/employee-status-dialog/employee-status-dialog.component';
import { Sort } from '@angular/material/sort';
import { DateRange } from '@angular/material/datepicker';
import { ConnectionPositionPair } from '@angular/cdk/overlay';
import { RulesService } from '@app/modules/shared/services/rules.service';
import { MsgForbbidenAccessComponent } from '@app/modules/shared/components/msg-forbbiden-access/msg-forbbiden-access.component';

@Component({
  selector: 'dispatch-calendar-activity',
  templateUrl: './dispatch-calendar-activity.component.html',
  styleUrls: ['./dispatch-calendar-activity.component.scss']
})
export class DispatchCalendarActivityComponent implements OnInit, OnDestroy {
  @Input() dispatcherInfoObj: any;
  permissions: any = this.rulesService.UserData[15].data;

  //Date
  dateObj: any = {
    startDate: moment().startOf('month').format('YYYY-MM-DD'),
    endDate: moment().endOf('month').format('YYYY-MM-DD')
  };

  //Date
  dateObjSt: any = {
    startDate: moment().startOf('month').format('YYYY-MM-DD'),
    endDate: moment().endOf('month').format('YYYY-MM-DD')
  };

  //Calendar
  dates: Array<Date> | any = [];

  days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

  currentDate: any = new Date();

  showCalendar: boolean = true;

  statusArray: any[] = [];

  //Statistics table
  statisticsObj: any = {
    overtimeHours: 0,
    shortenedShift: 0,
    summary: 0
  };
  stTableData: any[] = [];

  //Calendar range
  public datePositions = [
    new ConnectionPositionPair(
      {originX: 'center', originY: 'bottom'},
      {overlayX: 'center', overlayY: 'top'},
      0, 10
    )
  ];
  @Input() selectedRangeValue: DateRange<Date> | any;
  @Output() selectedRangeValueChange = new EventEmitter<DateRange<Date>>();
  isOpenCalendar: boolean = false;

  loaded: boolean = false;
  error: boolean = false;
  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";

  spinnerBgColor: string = this.transformService.spinnerBgColor;
  spinnerType: string = this.transformService.spinnerType;

  //Subscription
  subscription: Subscription = new Subscription();

  constructor(private transformService: TransformService, 
              private route: ActivatedRoute, 
              private dispatchProfileService: DispatcherProfileService,
              private dialog: MatDialog,
              private rulesService: RulesService,
              private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.getCalendarData();
  }

  getCalendarData() {
    this.error = false;
    this.loaded = false;
    this.spinner.show('dispatch-calendar-activity');
    let id: number = +this.route.snapshot.paramMap.get('id');
    this.subscription = this.dispatchProfileService.getStatusesByDispatcher(id, this.dateObj.startDate, this.dateObj.endDate)
    .pipe(catchError((err: any) => {
      this.spinner.hide('dispatch-calendar-activity');
      this.loaded = true;
      this.error = true;
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      console.log(response);
      let data: any = JSON.parse(JSON.stringify(response));
      for(let key in data) {
        data[key].date = new Date(data[key].status_date);
      }
      console.log(data);
      this.statusArray = data;
      this.setDate(this.currentDate);
      this.spinner.hide('dispatch-calendar-activity');
      this.loaded = true;
    });
  };

  getStTableData() {
    if(this.statusArray.length > 0) {
      this.error = false;
      this.loaded = false;
      this.spinner.show('dispatch-calendar-activity');
      this.subscription = this.dispatchProfileService.getActivityStats(this.statusArray[0].employee_id, this.dateObjSt.startDate, this.dateObjSt.endDate)
      .pipe(catchError((err: any) => {
        this.spinner.hide('dispatch-calendar-activity');
        this.loaded = true;
        this.error = true;
        return throwError(() => err);
      }))
      .subscribe((response: any) => {
        this.stTableData = response;        
        //Statistics
        let sumOvertimeHours: number = response[0].overtime;
        let sumShortenedShift: number = response[0].shortned;
        let difference: number = sumOvertimeHours - sumShortenedShift;
        let mathAbs: any = Math.abs(difference);
        this.statisticsObj.overtimeHours = `${String(Math.floor(sumOvertimeHours / 3600000)).padStart(2, '0')}:${String(Math.floor((sumOvertimeHours % 3600000) / 60000)).padStart(2, '0')}`;
        this.statisticsObj.shortenedShift = `${String(Math.floor(sumShortenedShift / 3600000)).padStart(2, '0')}:${String(Math.floor((sumShortenedShift % 3600000) / 60000)).padStart(2, '0')}`;
        this.statisticsObj.summary = `${difference < 0 ? '-' : ''}${String(Math.floor(mathAbs / 3600000)).padStart(2, '0')}:${String(Math.floor((mathAbs % 3600000) / 60000)).padStart(2, '0')}`;
        this.spinner.hide('dispatch-calendar-activity');
        this.loaded = true;
      })
    }
  };

  changeSection() {
    this.showCalendar = !this.showCalendar;
    if(this.showCalendar) {
      this.getCalendarData();
    }
    else {
      this.getStTableData();
    }
  }

  //Calendar methods
  setMonth(prev: boolean, isCalendar: boolean) {
    let month: any = prev ? this.currentDate.getMonth() - 1 : this.currentDate.getMonth() + 1;
    let d = this.currentDate.setMonth(month);
    this.currentDate = new Date(d);
    this.setDate(this.currentDate);
    if(prev) {
      this.dateObj.startDate = moment(this.dateObj.startDate).subtract(1,'months').startOf('month').format('YYYY-MM-DD');
      this.dateObj.endDate = moment(this.dateObj.endDate).subtract(1,'months').endOf('month').format('YYYY-MM-DD');

      this.dateObjSt.startDate = moment(this.dateObjSt.startDate).subtract(1,'months').startOf('month').format('YYYY-MM-DD');
      this.dateObjSt.endDate = moment(this.dateObjSt.endDate).subtract(1,'months').endOf('month').format('YYYY-MM-DD');
    }
    else {
      this.dateObj.startDate = moment(this.dateObj.startDate).add(1,'months').startOf('month').format('YYYY-MM-DD');
      this.dateObj.endDate = moment(this.dateObj.endDate).add(1,'months').endOf('month').format('YYYY-MM-DD');

      this.dateObjSt.startDate = moment(this.dateObjSt.startDate).add(1,'months').startOf('month').format('YYYY-MM-DD');
      this.dateObjSt.endDate = moment(this.dateObjSt.endDate).add(1,'months').endOf('month').format('YYYY-MM-DD');
    }

    if(isCalendar) {
      this.getCalendarData();
    }
    else {
      this.getStTableData();
    }
  }

  setDate(date: any) {
    let dateObj: any = this.getDaysInMonth(date);
    if (dateObj.firstDay === 1) {
      this.dates = dateObj.dateArray;
    } 
    else {
      this.dates = dateObj.dateArray;
      for (let i = 1; i < dateObj.firstDay; i++) {
        this.dates.unshift({date: '-', icon: null, status: null, note: ''});
      }
    }
  }

  getDaysInMonth(date: any) {
    let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    return { firstDay: this.getLocalDay(firstDay.getDay()), lastDay: lastDay.getDate(), dateArray: this.getDatesInRange(firstDay, lastDay)}
  }

  getDatesInRange(startDate: any, endDate: any) {
    const date = new Date(startDate.getTime());
    const dates = [];
    while (date <= endDate) {
      let d1 = this.transformService.convertDateToTimestamp(moment(date).format('ddd, DD/MM YYYY'), '00:00:00');
      let obj: any;
      let statusData: any = JSON.parse(JSON.stringify(this.statusArray));
      let result = statusData.some((currObj, arrIndex) => { 
        obj = currObj
      return currObj.status_date == d1;
      })
      if(result) {
        obj.date = new Date(date);
        dates.push(obj);
      } else {
        dates.push({date: new Date(date), icon: null, status: null, note: ''});
      }
      date.setDate(date.getDate() + 1);
    }
    return dates;
  }

  getLocalDay(day: number) {
    return day === 0 ? day = 7 : day;
  }

  //Status
  createEditStatus(dayObj: any, index: number) {
    if(this.permissions[1].sectionArray[1].allowed) {
      if(!this.dispatcherInfoObj.terminated_date || moment(this.dispatcherInfoObj.terminated_date).format('YYYY-MM-DD') > moment(dayObj.date).format('YYYY-MM-DD')) {
        let obj: any = JSON.parse(JSON.stringify(dayObj));
        let dialogRef = this.dialog.open(EmployeeStatusDialogComponent, {
          autoFocus: false,
          panelClass: 'employee-status-main-container',
          data: obj
        });
        dialogRef.afterClosed().subscribe((result: any) => {
          if(result) {
            if(result === 'Refresh') {
              this.getCalendarData();
            }
            else {
              let retrunedObj: any = result;
              retrunedObj.date = obj.date;
              this.dates[index] = retrunedObj;
              console.log(retrunedObj)
            }
          }
        });
      } 
    }
    else {
      this.showForbidenMessage();
    }
  };

  setBgColor(obj: any) {
    if(obj.status === 'Working weekend') {
      return 'working-weekend';
    }
    else if(obj.status === 'Working on holiday') {
      return 'yellow';
    }
    else if(obj.status === 'Extra shift') {
      return 'green';
    }
    else {
      return 'white';
    }
  }

  sortData(sort: Sort) {
    const data = this.stTableData.slice();
    if (!sort.active || sort.direction === '') {
      this.stTableData = data;
      return;
    }
    this.stTableData = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'status':
          return compare(a.status, b.status, isAsc);
        case 'days':
          return compare(a.days, b.days, isAsc);
        case 'days_percent':
          return compare(a.days_percent, b.days_percent, isAsc);
        default:
          return 0;
      }
    });
  };

  selectedChange(m: any) {
    if (!this.selectedRangeValue?.start || this.selectedRangeValue?.end) {
      this.selectedRangeValue = new DateRange<Date>(m, null);
    } 
    else {
      const start = this.selectedRangeValue.start;
      const end = m;
      if (end < start) {
          this.selectedRangeValue = new DateRange<Date>(end, start);
      } else {
          this.selectedRangeValue = new DateRange<Date>(start, end);
      }
    }

    this.selectedRangeValueChange.emit(this.selectedRangeValue);
    if(this.selectedRangeValue.start && this.selectedRangeValue.end) {
      this.currentDate = new Date();
      this.dateObj.startDate = moment().startOf('month').format('YYYY-MM-DD');
      this.dateObj.endDate = moment().endOf('month').format('YYYY-MM-DD');
      this.setDate(this.currentDate);

      this.dateObjSt.startDate = moment(this.selectedRangeValue.start._d).format('YYYY-MM-DD');
      this.dateObjSt.endDate = moment(this.selectedRangeValue.end._d).format('YYYY-MM-DD');
      this.getStTableData();
      this.isOpenCalendar = false;
    }
  }

  showForbidenMessage() {
    this.dialog.open(MsgForbbidenAccessComponent, {
      autoFocus: false,
      panelClass: 'forbidden-msg-dialog-container'
    })
  };

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
 
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as _G from '../../../_config/globals';
import { Observable } from 'rxjs';
import { LoadCalculationResult, LoadCalculationTimePoint } from '../models/load.model';

@Injectable()
export class EstimationsService {
  headers = new HttpHeaders({
		'Content-Type': 'application/json',
		'Authorization': 'Basic SVZTb2x1dGlvbjoxUzBsdXQxMG4yQDIy'
	});
	requestOptions = { headers: this.headers };


  constructor(private http: HttpClient) { }

  getLoadsCalculationByRadius(data: any, includeLoads: boolean) : Observable<LoadCalculationResult> {
		console.log('Estimation Data: ', data);
		let url = _G.apiUrl + 'Dispatch/LoadEstimations?includeLoads=' + includeLoads.toString();
  		return this.http.post<LoadCalculationResult>(url, data);
	}

  
	getLoadsCalculationByRadiusAndTimeSeries(data: any, frame) : Observable<LoadCalculationTimePoint[]> {
		console.log('Estimation Time Series: ', data);
		let url = _G.apiUrl + 'Dispatch/LoadEstimations/' + `${frame}`;
  		return this.http.post<LoadCalculationTimePoint[]>(url, data);
	}

  getZipCodes() {
		return this.http.get('/assets/static-data/zips.json');
	}

}

import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';

import { ApexAxisChartSeries, ApexChart, ApexDataLabels, ApexGrid, ApexStroke, ApexXAxis, ApexYAxis, ChartComponent } from 'ng-apexcharts';
import { Subscription } from 'rxjs';
import { NotCoveredTrucksService } from '../../services/not-covered-trucks.service';
import { TransformService } from '@app/modules/shared/services/transform.service';

export type ChartOptions = {
  series: ApexAxisChartSeries | any;
  chart: ApexChart | any;
  xaxis: ApexXAxis | any;
  yaxis: ApexYAxis | any;
  dataLabels: ApexDataLabels | any;
  grid: ApexGrid | any;
  stroke: ApexStroke | any;
  markers: any;
  colors: any;
};

@Component({
  selector: 'app-not-covered-drivers-line-chart',
  templateUrl: './not-covered-drivers-line-chart.component.html',
  styleUrls: ['./not-covered-drivers-line-chart.component.scss']
})
export class NotCoveredDriversLineChartComponent implements OnInit, OnDestroy {
  @Input() dateObj: any;
  @ViewChild("chart") chart: ChartComponent | any;
  public chartOptions: Partial<ChartOptions> | any;

  subscription: Subscription | any;

  selectPeriod: FormControl | any;

  addedNames: string[] = [];

  graphData: any[] = [];

  //Period
  isOpenPeriodMenu: boolean = false;
  menuValue: string = 'By day';
  selectPeriodArray: any[] = [
    {name: 'By year', value: 'year'},
    {name: 'By quarter', value: 'quarter'},
    {name: 'By month', value: 'month'},
    {name: 'By week', value: 'week'},
    {name: 'By day', value: 'day'},
  ];

  constructor(private dataService: NotCoveredTrucksService, private transformService: TransformService) {
    this.selectPeriod = new FormControl(['day']);
  }

  ngOnInit(): void {
    this.showGraph();
  }

  showGraph() {
    this.subscription = this.dataService.driverLineChartSubject.subscribe((response: any) => {
      console.log(response);
      if (response.reset) {
        this.graphData = [];
        this.addedNames = [];
      } else {

        let dispName: string = response.driverName + ' (' + response.title + ')';
        if (this.addedNames.includes(dispName) && this.addedNames.length > 0) {
          this.graphData = this.graphData.filter(object => {
            return object.name[0] !== dispName
          });
          this.addedNames = this.addedNames.filter(name => {
            return name !== dispName
          });
        } else {
          this.addedNames.push(dispName);
          this.graphData.push(this.transformService.countDataByPeriodLoadTableNotCoveredTrucks(response.data, this.selectPeriod.value[0], response?.key,
            response?.title, response.driverName));
        }
        this.initGraph(this.graphData);
      }
    })
  }


  initGraph(data: any) {
    let arrayData: any[] = [];
    let categoriesArray: any[] = [];
    for (let key in data) {
      let obj = {
        name: data[key].name,
        data: data[key].total
      }
      if (categoriesArray.length < data[key].categories.length) {
        categoriesArray = data[key].categories;
      }
      arrayData.push(obj)
    }

    for (let key in arrayData) {
      if (arrayData[key].data.length < categoriesArray.length) {
        let difference = categoriesArray.length - arrayData[key].data.length;
        arrayData[key].data = arrayData[key].data.concat(Array(difference).fill(0));
      }
    }
    this.chartOptions = {
      series: arrayData,
      chart: {
        toolbar: {
          show: true,
          tools: {
            download: false
          },
          animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 800,
            animateGradually: {
                enabled: true,
                delay: 150
            },
            dynamicAnimation: {
                enabled: true,
                speed: 350
            }
          }
        },
        height: 350,
        type: "line",
        zoom: {
          enabled: false
        },
        fontFamily: 'Poppins'
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: "straight",
        width: 1,
      },
      grid: {
        row: {
          colors: ["transparent", "transparent"],
          opacity: 0.5
        }
      },
      xaxis: {
        categories: categoriesArray
      },
      yaxis: {
        logarithmic: true,
        logBase: 10,
        min: 0.1,
        forceNiceScale: true,
        tooltip: {
          enabled: true
        },
        labels: {
          formatter: (num: number) => {
            let result = (num - Math.floor(num)) !== 0;
            let number;
            if (result) {
              number = Math.round(num);
            } else {
              number = num;
            }
            return number.toLocaleString("en-US").toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          },
        },
      },
      markers: {
        size: 5
      },
      colors: ['#0030FF', '#FA9120', '#FA0000', '#008000', '#FF00FF', '#800000', '#FE7777', '#0303BB', '#008FA9', '#7550CB', '#6A6D6E'],
      tooltip: {
        enabled: true,
        inverseOrder: false,
        style: {
          fontSize: '12px'
        },
      }
    };
  }

  onlyOneSelect(value: string, obj: any) {
    this.menuValue = obj.name;
    this.selectPeriod = new FormControl([value]);
    let lineChartData: any = JSON.parse(JSON.stringify(this.graphData));
    this.graphData = [];

    for (let key in lineChartData) {
      this.graphData.push(this.transformService.countDataByPeriodLoadTableNotCoveredTrucks(lineChartData[key].data, this.selectPeriod.value[0],
        lineChartData[key].key, lineChartData[key].title, lineChartData[key].dName));
    }
    this.initGraph(this.graphData);
    this.isOpenPeriodMenu = false;
  };

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

}

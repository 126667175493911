import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'driversInputsFilter',
  pure: false
})
export class DriversInputsFilterPipe implements PipeTransform {

  transform(array: any[], id: number, metaObj: any): any[] {
    if (!array) {
      return [];
    }

    let transformedArray = array.filter((obj: any) => {
      if(obj.users === id) { 
        return obj;
      }
    });

    metaObj.countObj.selectedInputsLength = transformedArray.length;

    return transformedArray;

  }

}

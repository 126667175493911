import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SharedService } from '@app/modules/shared/services/shared.service';
import { DeleteConfirmationDialogComponent } from '../../delete-confirmation-dialog/delete-confirmation-dialog.component';

@Component({
  selector: 'app-create-update-location',
  templateUrl: './create-update-location.component.html',
  styleUrls: ['./create-update-location.component.scss']
})
export class CreateUpdateLocationComponent implements OnInit {
  locationArray: any[] = [];
  locationObj: any = {
    id: 0,
    location_name: ''
  }

  objIndex: number | undefined = undefined;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private dialog: MatDialog,
              public dialogRef: MatDialogRef<CreateUpdateLocationComponent>,
              private sharedService: SharedService) { }

  ngOnInit(): void {
    this.locationArray = this.data.array;
    this.dialogRef.backdropClick().subscribe(result => {
      this.dialogRef.close(this.locationArray);
    });
  }

  editDiscardLocation(obj: any, index: number) {
    if(this.locationObj.id === obj.id) {
      this.locationObj.id = 0;
      this.locationObj.location_name = '';
      this.objIndex = undefined;
    }
    else {
      this.locationObj.id = obj.id;
      this.locationObj.location_name = obj.location_name;
      this.objIndex = index;
    }
  };

  deleteLocation(id: number, index: number) {
    let dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
      autoFocus: false,
      panelClass: 'delete-dialog-container',
    });
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if(result) {
        this.sharedService.deleteTrailerLocation(id).subscribe((response: boolean) => {
          if(response) {
            this.locationArray.splice(index, 1);
          }
        })
      }
    });
  };

  save() {
    this.sharedService.createUpdateTrailerLocation(this.locationObj).subscribe((response: any) => {
      console.log(response);
      if(response.id) {
        if(response.id === this.locationObj.id) {
          this.locationArray[this.objIndex].location_name = response.location_name;
        }
        else {
          this.locationArray.push(response);
        }
        this.locationObj.id = 0;
        this.locationObj.location_name = '';
      }
    })
  };
  

}

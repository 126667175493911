import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'filter-dropdown-multiple-select',
  templateUrl: './filter-dropdown-multiple-select.component.html',
  styleUrls: ['./filter-dropdown-multiple-select.component.scss']
})
export class FilterDropdownMultipleSelectComponent {
  @Input() config: any = {
    array: [],
    selectedValuesArray:[],
    defaultValue: '',
    key: '',
    search: false
  };
  @Output() triggerEvent = new EventEmitter<string>();

  isOpenFilterDropdown: boolean = false;
  dropdownSearch: string = '';

  //Select all
  selectUnselectAll() {
    if(this.config.selectedValuesArray.length > 0) {
      this.config.selectedValuesArray = [];
    }
    else {
      for(let i = 0; i < this.config.array.length; i++) {
        this.config.selectedValuesArray.push(this.config.array[i][this.config.key]);
      };
    }
    this.triggerEvent.emit(this.config.selectedValuesArray);
  };

  //Check uncheck value
  checkUncheckValue(value: string) {
    let index = this.config.selectedValuesArray.indexOf(value);
    if (index === -1) {
      this.config.selectedValuesArray.push(value);
    } 
    else {
      this.config.selectedValuesArray.splice(index, 1);
    }
    this.triggerEvent.emit(this.config.selectedValuesArray);
  };
}

import { Component, OnInit, Input, SimpleChange } from '@angular/core';
import { ChartData, GCConfig } from '../../models/gcharts.model';
import { LoadCalculationTimePoint, LoadCalculation } from '../../models/load.model';


@Component({
	selector: 'loads-estimation-analysis',
	templateUrl: './estimation-analysis.component.html',
	styleUrls: ['./estimation-analysis.component.scss']
})
export class EstimationAnalysisComponent implements OnInit {

	@Input('data') timeSeries: LoadCalculationTimePoint[];

	ranges = ['0-300', '301-500', '500+'];
	

	
	constructor() { }


	ngOnChanges(changes: SimpleChange) {
		console.log(changes);
		if(('timeSeries' in changes)) {
			console.log(
				'Time series on change: ', this.timeSeries
			);
			if(this.timeSeries) {
				this.setChartsData();
			} 
		}
	}

	ngOnInit() {
		console.log(
			'Time series on init: ', this.timeSeries
		);

		//this.setChartsData();

	}

	gross = {
		max: [],
		min: [],
		avg: []
	}

	setChartsData() {

		var rows = {
			gross: {
				max: [],
				min: [],
				avg: []
			},
			dollar_per_mile: {
				max: [],
				min: [],
				avg: []
			},
			mileage: {
				max: [],
				min: [],
				avg: []
			}
		}

		this.timeSeries.forEach( (s) => {
			
			let pointLabel = s.title;
			var pointGroups: Map<string, LoadCalculation> = new Map<string, LoadCalculation>();

			s.data.grouped.forEach( (el, index) => {
					pointGroups.set(el.mileageRange, el.value);
				}
			);


			console.log(pointLabel,  pointGroups);

			var param = "gross";
			var attr = 'max';
			//var attrs = ['max', 'avg', 'min'];
			var attrs = ['max', 'avg'];
			//var params = ['gross', 'dollar_per_mile', 'mileage'];
			var params = ['gross', 'dollar_per_mile'];




			

			// this.ranges.forEach( (r, i) => {
			// 	attrs.forEach( (attr) => {
			// 		let val = (!pointGroups.has(r)) ? 0 : pointGroups.get(r).gross[attr];
			// 		values[attr].push(val);
			// 	});
			// });

			params.forEach( (param) => {

				var values = {
					min: [pointLabel],
					max: [pointLabel],
					avg: [pointLabel] 
				}

				this.ranges.forEach( (r, i) => {
					attrs.forEach( (attr) => {
						let val = (!pointGroups.has(r)) ? 0 : pointGroups.get(r)[param][attr];

						values[attr].push(val);
					});
				});

				rows[param].max.push(values.max);
//				rows[param].min.push(values.min);
				rows[param].avg.push(values.avg);
			});


			// rows.gross.max.push(values.max);
			// rows.gross.min.push(values.min);
			// rows.gross.avg.push(values.avg);
		});

		console.log('ROWS:', rows);

		this.setCharts(rows.gross.max, 'Max Gross per Year');
		this.setCharts(rows.gross.avg, 'Avg Gross per Year');
//		this.setCharts(rows.gross.min, 'Min Gross per Year');

		this.setCharts(rows.dollar_per_mile.max, 'Max $/mile per Year');
		this.setCharts(rows.dollar_per_mile.avg, 'Avg $/mile per Year');
//		this.setCharts(rows.dollar_per_mile.min, 'Min $/mile per Year');

		// this.setCharts(rows.mileage.max, 'Max Mileage per Year');
		// this.setCharts(rows.mileage.avg, 'Avg Mileage per Year');
		// this.setCharts(rows.mileage.min, 'Min Mileage per Year');
	}

	

	charts: ChartData[] = [];


	setCharts(data, title) {
		console.log('Gross data: ', this.gross);

		let chartData: ChartData = new ChartData(); 

		let chartConfigMax = new GCConfig("ColumnChart", 
			data, 
			this.loadsMileage.columnNames, 
			this.loadsMileage.options
		);
		
		chartData.attribute = title;
		chartData.chart = chartConfigMax;

		this.charts.push(chartData);
	}

	loadsMileage = {
		columnNames: ['Mileage range', '0-300', '301-500', '500+'],
		table: [],
		options: { 'title': 'Loads Analysis', height: 440 }
	};

}

import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-loads-info-dialog',
  templateUrl: './loads-info-dialog.component.html',
  styleUrls: ['./loads-info-dialog.component.scss']
})
export class LoadsInfoDialogComponent {
  tabsArray: string[] = ['Load info', 'Files' , 'Request'];
  activeTab: number = 0;

  subscription3: Subscription = new Subscription();

  constructor(@Inject(MAT_DIALOG_DATA) public obj: any) { }

}

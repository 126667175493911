import { Injectable } from '@angular/core';
import * as _G from '../../../_config/globals';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Subject, forkJoin } from 'rxjs';

@Injectable()

export class MaintenanceService {

  headers = new HttpHeaders({
		'Content-Type': 'application/json',
		'Authorization': 'Basic SVZTb2x1dGlvbjoxUzBsdXQxMG4yQDIy'
	});
	requestOptions = { headers: this.headers };

	constructor ( private http: HttpClient ) {}

  dateChanged = new Subject<any>();

	maintenanceCardsLineChartSubject = new Subject<any>();

	breakDownByGroupLineChart = new Subject<any>();
	truckPartsLineChart = new Subject<any>();

	breakDownByGroupLineChart2 = new Subject<any>();
	trailerPartsLineChart = new Subject<any>();

	getMaintenanceCards(startDate: string, endDate: string, startDate2: string, endDate2: string, filterParams: string) {
		let response1 = this.http.get(`${_G.extendApiUrl}Maintenance/getmaintenanceoverview?start=${startDate}&end=${endDate}${filterParams}`);
		let response2 = this.http.get(`${_G.extendApiUrl}Maintenance/getmaintenanceoverview?start=${startDate2}&end=${endDate2}${filterParams}`);
		return forkJoin([response1, response2]);
	};

  //Trucks

	getBreakDownByMileage(startDate: string, endDate: string, filterParams: string) {
		return this.http.get(`${_G.extendApiUrl}Maintenance/breackdownmaintbertrucks?startDate=${startDate}&endDate=${endDate}${filterParams}`, this.requestOptions);
	};

	getBreakDownByGroup(startDate: string, endDate: string, startDate2: string, endDate2: string, filterParams: string) {
		let response1 = this.http.get(`${_G.extendApiUrl}Maintenance/truckmaintpercategory?startDate=${startDate}&endDate=${endDate}${filterParams}`, this.requestOptions);
		let response2 = this.http.get(`${_G.extendApiUrl}Maintenance/truckmaintpercategory?startDate=${startDate2}&endDate=${endDate2}${filterParams}`, this.requestOptions);
		return forkJoin([response1, response2]);
	};

	getTrucksMaintenanceTableData(startDate: string, endDate: string, filterParams: string) {
		return this.http.get(`${_G.extendApiUrl}Maintenance/maintenancepertrucks?start=${startDate}&end=${endDate}${filterParams}`)
	};

	//Trailers

	getBreakDownByYear(startDate: string, endDate: string, filterParams: string) {
		return this.http.get(`${_G.extendApiUrl}Maintenance/breackdownmaintpertrailers?startDate=${startDate}&endDate=${endDate}${filterParams}`, this.requestOptions);
	}

	getBreakDownByGroup2(startDate: string, endDate: string, startDate2: string, endDate2: string, filterParams: string) {
		let response1 = this.http.get(`${_G.extendApiUrl}Maintenance/trailersmaintpercategory?startDate=${startDate}&endDate=${endDate}${filterParams}`, this.requestOptions);
		let response2 = this.http.get(`${_G.extendApiUrl}Maintenance/trailersmaintpercategory?startDate=${startDate2}&endDate=${endDate2}${filterParams}`, this.requestOptions);
		return forkJoin([response1, response2]);
	};

	getTrailersMaintenanceTableData(startDate: string, endDate: string, filterParams: string) {
		return this.http.get(`${_G.extendApiUrl}Maintenance/maintenancepertrailer?startDate=${startDate}&endDate=${endDate}${filterParams}`, this.requestOptions);
	};

}

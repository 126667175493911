import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AddContactDialogComponent } from './add-contact-dialog/add-contact-dialog.component';
import { ConnectionPositionPair } from '@angular/cdk/overlay';
import { LoadBoardService } from '@app/modules/load-board/services/load-board.service';
import { SharedService } from '@app/modules/shared/services/shared.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { DeleteConfirmationDialogComponent } from '@app/modules/shared/components/delete-confirmation-dialog/delete-confirmation-dialog.component';

@Component({
  selector: 'create-update-profil-dialog',
  templateUrl: './create-update-profil-dialog.component.html',
  styleUrls: ['./create-update-profil-dialog.component.scss']
})
export class CreateUpdateProfilDialogComponent implements OnInit, OnDestroy {
  loggedUser: any = JSON.parse(localStorage.getItem('currentUser'));
  formGroup: FormGroup;

  //Positions
  public positions = [
    new ConnectionPositionPair(
      {originX: 'start', originY: 'top'},
      {overlayX: 'start', overlayY: 'bottom'},
      5, -5
    )
  ];

  //Filters
  radioDriverActivityMenu: any[] = [
    {name: 'Full', checked: true},
    {name: 'Partial', checked: false}
  ];
  isOpenDriverActivity: boolean = false;

  //Company
  companyArray: any[] = [];
  isOpenCompanyMenu: boolean = false;
  companyValue: string = '';

  //Type
  typesArray: any[] = [
    {name: 'Division'},
    {name: 'Rent to run'},
    {name: 'Owner'}
  ];
  isOpenTypeMenu: boolean = false;

  //Truck
  trucksArray: any[] = [];
  takenTrucks: number[] = [];
  isOpenTruckMenu: boolean = false;

  //Equipment type
  equipmentTypeArray: any[] = [];
  isOpenEquipmentTypeMenu: boolean = false;

  //Load broker company
  brokersArray: any[] = [
    {name: 'DAT'}
  ];
  isOpenBrokerMenu: boolean = false;

  //Calendar
  isOpenCalendar: boolean = false;
  date: string = '';

  //Destination
  destinationArray: any[] = [];
  isOpenValidZonesMenu: boolean = false;
  isOpenDestinationMenu: boolean = false;

  //Contacts array
  contactsArray: any[] = [];

  //Subscription
  subscription1: Subscription = new Subscription();
  subscription2: Subscription = new Subscription();
  subscription3: Subscription = new Subscription();

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private dialog: MatDialog,
              private formBuilder: FormBuilder,  
              private loadBoardService: LoadBoardService,
              private sharedService: SharedService,
              public dialogRef: MatDialogRef<CreateUpdateProfilDialogComponent>) {
              this.formGroup = this.formBuilder.group({
                'id': [null],
                'dispatcher_id': [this.loadBoardService.creatorId],
                'full_partial': ['Full'],
                'profile_name': ['', [Validators.required, Validators.minLength(2)]],
                'company': ['', [Validators.required, Validators.minLength(2)]],
                'truck_groups': ['', [Validators.required, Validators.minLength(2)]],
                'trucks': ['', [Validators.required, Validators.minLength(2)]],
                'equipment_type': [null, Validators.required],
                'destination': ['', [Validators.required, Validators.minLength(2)]],
                'min_rate': [null],
                'reference': [''],
                'broker_companies': ['', [Validators.required, Validators.minLength(2)]],
                'general_comment': [''],
                'profile_comment': [''],
                'contacts': [''],
                'length': [null],
                'weight': [null],
                'date_created': [null]
              })
  }

  ngOnInit(): void {
    this.getCompanies();
    this.getTrucksAssignedToDispatcher();
    this.getMenuData();
    if(this.data.editMode) {
      this.formGroup.setValue({
        'id': this.data.obj.id,
        'dispatcher_id': this.data.obj.dispatcher_id,
        'full_partial': this.data.obj.full_partial,
        'profile_name': this.data.obj.profile_name,
        'company': this.data.obj.company,
        'truck_groups': this.data.obj.truck_groups,
        'trucks': this.data.obj.trucks,
        'equipment_type': this.data.obj.equipment_type,
        'destination': this.data.obj.destination,
        'min_rate': this.data.obj.min_rate,
        'reference': this.data.obj.reference,
        'broker_companies': this.data.obj.broker_companies,
        'general_comment': this.data.obj.general_comment,
        'profile_comment': this.data.obj.profile_comment,
        'contacts': this.data.obj.contacts,
        'length': this.data.obj.length,
        'weight': this.data.obj.weight,
        'date_created': this.data.obj.date_created
      });
      this.contactsArray = this.data.obj?.contacts?.length > 0 ? this.data.obj.contacts.split(', ') : [];
    };
    console.log(this.data);
  };

  getCompanies() {
    this.subscription1 = this.sharedService.getCompanyData().subscribe((response: any) => {
      this.companyArray = response.divisions;
    });
  }

  getTrucksAssignedToDispatcher() {
    this.subscription2 = this.loadBoardService.getTrucksByDispatcher(this.loadBoardService.creatorId).subscribe((response: any) => {
      this.trucksArray = response;
      console.log(response);
    });
  };

  getMenuData() {
    this.subscription3 = this.loadBoardService.getAllCompanies(this.loadBoardService.creatorId).subscribe((response: any) => {
      this.equipmentTypeArray = response.EquipmentTypes ? response.EquipmentTypes : [];
      if(response.ValidZones) {
        for(let i = 0; i < response.ValidZones.length; i++) {
          this.destinationArray.push({name: `Z${i}`, states: response.ValidZones[i]});
        }
      }
      this.takenTrucks = response.TakenTrucks;
      console.log(response);
    });
  }

  //Check uncheck value
  checkUncheckValue(obj: any, formValue: string, key: string) {
    let newValue: string = formValue;
    if(formValue.includes(obj[key])) {
      let convertedToArray: any[] = formValue.split(', ');
      let index: number = convertedToArray.indexOf(obj[key]);
      convertedToArray.splice(index, 1);
      newValue = convertedToArray.join(', ');
    }
    else {
      newValue += newValue.length === 0 ? `${obj[key]}` :  `, ${obj[key]}`;
    }
    return newValue;
  };

  addContactDialog() {
    let dialogRef: any = this.dialog.open(AddContactDialogComponent, {
      autoFocus: false,
      panelClass: 'component-dialog-container'
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
        this.contactsArray.push(result);
      }
    })
  };

  saveUpdate() {
    this.formGroup.patchValue({'contacts': this.contactsArray.join(', ')})
    let obj: any = this.formGroup.value;
    obj.weight = +obj.weight;
    obj.length = +obj.length;
    obj.min_rate = +obj.min_rate;
    console.log(this.formGroup.value);
    if(this.data.editMode) {
      this.loadBoardService.updateProfile(this.data.obj.id, obj)
      .subscribe((response: any) => {
        if(response) {
          this.dialogRef.close(true);
        }
        console.log(response);
      });
    }
    else {
      this.loadBoardService.createProfile(obj).subscribe((response: any) => {
        console.log(response);
        if(response) {
          this.dialogRef.close(true);
        }
      });
    }
  };

  deleteProfile() {
    let dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
      autoFocus: false,
      panelClass: 'delete-dialog-container',
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
        this.loadBoardService.deleteProfile(this.data.obj.id).subscribe((response: any) => {
          console.log(response);
          if(response) {
            this.dialogRef.close(true);
          }
        });
      }
    })
  };

  ngOnDestroy(): void {
    this.subscription1?.unsubscribe();
    this.subscription2?.unsubscribe();
    this.subscription3?.unsubscribe();
  };
  
  //Form values
  get companiesValue(): string {
    return this.formGroup.controls['company'].value;
  }

  get truckGroupValue(): string {
    return this.formGroup.controls['truck_groups'].value;
  }

  get trucksValue(): string {
    return this.formGroup.controls['trucks'].value;
  }
  
  get equipmentTypeValue(): string {
    return this.formGroup.controls['equipment_type'].value;
  }

  get brokersValue(): string {
    return this.formGroup.controls['broker_companies'].value;
  }

  get destionationValue(): string {
    return this.formGroup.controls['destination'].value;
  }

  get contactsValue(): string {
    return this.formGroup.controls['contacts'].value;
  }

} 

import { ConnectionPositionPair } from '@angular/cdk/overlay';
import { Component } from '@angular/core';

@Component({
  selector: 'dots-dropdown',
  templateUrl: './dots-dropdown.component.html',
  styleUrls: ['./dots-dropdown.component.scss']
})
export class DotsDropdownComponent {
  optionsArray: any[] = ['Option 1', 'Option 2', 'Option 3', 'Option 4'];

  //Positions
  public positions = [
    new ConnectionPositionPair(
      {originX: 'end', originY: 'bottom'},
      {overlayX: 'end', overlayY: 'top'},
      -10, 5
    )
  ];
  isOpenDotsMenu: boolean = false;
}

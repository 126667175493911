import { Component } from '@angular/core';

@Component({
  selector: 'app-completed-task-dialog',
  templateUrl: './completed-task-dialog.component.html',
  styleUrls: ['./completed-task-dialog.component.scss']
})
export class CompletedTaskDialogComponent {

}

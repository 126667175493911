import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { AveragePaycheckComponent } from './components/average-paycheck/average-paycheck.component';
import { DragDropCardsComponent } from './components/drag-drop-cards/drag-drop-cards.component';
import { DriversPaychecksComponent } from './components/drivers-paychecks/drivers-paychecks.component';
import { EmptyTruckCardsComponent } from './components/empty-truck-cards/empty-truck-cards.component';
import { HiredTerminatedChartsComponent } from './components/hired-terminated-charts/hired-terminated-charts.component';
import { HiredTerminatedDialogComponent } from './components/hired-terminated-charts/hired-terminated-dialog/hired-terminated-dialog.component';
import { PieChartsComponent } from './components/pie-charts/pie-charts.component';
import { QuickDataComponent } from './components/quick-data/quick-data.component';
import { RecruitersGraphComponent } from './components/recruiters-graph/recruiters-graph.component';
import { RecruitersComponent } from './components/recruiters/recruiters.component';
import { RecruitingPageComponent } from './components/recruiting-page/recruiting-page.component';
import { RecrutingRoutingModule } from './recruiting.routing';
import { TypePipe } from './pipes/type.pipe';
import { RecruitingService } from './services/recruiting.service';

@NgModule({
  declarations: [
    AveragePaycheckComponent,
    DragDropCardsComponent,
    DriversPaychecksComponent,
    EmptyTruckCardsComponent,
    HiredTerminatedChartsComponent,
    HiredTerminatedDialogComponent,
    PieChartsComponent,
    TypePipe,
    QuickDataComponent,
    RecruitersComponent,
    RecruitersGraphComponent,
    RecruitingPageComponent
  ],
  imports: [
    CommonModule,
    RecrutingRoutingModule,
    SharedModule
  ],
  providers: [RecruitingService]
})
export class RecruitingModule { }

import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import moment = require('moment');
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription, catchError, throwError } from 'rxjs';
import { TruckStatusHistoryDialogComponent } from '../active-trucks-dialog/truck-status-history-dialog/truck-status-history-dialog.component';
import { Sort } from '@angular/material/sort';
import { SharedService } from '../../services/shared.service';
import { TransformService } from '../../services/transform.service';

@Component({
  selector: 'app-empty-trucks-dialog',
  templateUrl: './empty-trucks-dialog.component.html',
  styleUrls: ['./empty-trucks-dialog.component.scss']
})
export class EmptyTrucksDialogComponent implements OnInit, OnDestroy {
  dateObj: any = {
    startDate: moment().startOf('month').format('YYYY-MM-DDTHH:mm:ss'),
    endDate: moment().format('YYYY-MM-DDTHH:mm:ss'),
  };

  //Table
  displayedColumns: string[] = ['position', 'unit_no', 'status', 'in_current_status', 'dispatcher', 'last_piclup', 'history'];
  dataSource: any[] = [];
  searchText: string = '';
  filterMetaData: any = {
    numOfTrucks: 0
  }

  //Spinner
  loaded: boolean = false;

  error: boolean = false;

  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";

  spinnerBgColor: string = this.transformService.spinnerBgColor;
  spinnerType: string = this.transformService.spinnerType;

  subscription: Subscription | any;

  constructor(@Inject(MAT_DIALOG_DATA) public type: any,
              private dialog: MatDialog,
              private sharedService: SharedService,
              public transformService: TransformService,
              private router: Router,
              private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.tableData();
  }

  getDate(result: any) {
    this.dateObj.startDate = result.startDate;
    this.dateObj.endDate = result.endDate;
    this.tableData();
  };

  tableData() {
    this.dataSource = [];
    this.error = false;
    this.loaded = false;
    this.spinner.show('empty-trucks');
    this.subscription = this.sharedService.getActiveTrucksTableData()
    .pipe(catchError((err: any) => {
      this.spinner.hide('empty-trucks');
      this.loaded = true;
      this.error = true;
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      this.dataSource = response;
      this.spinner.hide('empty-trucks');
      this.loaded = true;
      console.log(response)
    });
  }

  sortData(sort: Sort) {
    const data = JSON.parse(JSON.stringify(this.dataSource));
    if (!sort.active || sort.direction === '') {
      this.dataSource = data;
      return;
    }
    this.dataSource = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'unit_no':
          return compare(a.unit_no, b.unit_no, isAsc);
        case 'in_current_status':
          return compare(a.in_current_status, b.in_current_status, isAsc);
        case 'dispatcher':
          return compare(a.dispatcher, b.dispatcher, isAsc);
        case 'status':
          return compare(a.status, b.status, isAsc);
        case 'last_piclup':
          return compare(a.last_piclup, b.last_piclup, isAsc);
        default:
          return 0;
      }
    });
  }

  //Open in new tab
  openInNewTab(route: string) {
    const url = this.router.serializeUrl(this.router.createUrlTree([route]));
    window.open(url, '_blank');
  }

  //Truck status history
  openStatusHistoryDialog(obj: any) {
    this.dialog.open(TruckStatusHistoryDialogComponent, {
      autoFocus: false,
      panelClass: 'status-history-dialog-container',
      data: obj
    });
  };

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { ApexAxisChartSeries, ApexChart, ApexDataLabels, ApexGrid, ApexStroke, ApexXAxis, ApexYAxis, ChartComponent } from 'ng-apexcharts';

export type ChartOptions = {
  series: ApexAxisChartSeries | any;
  chart: ApexChart | any;
  xaxis: ApexXAxis | any;
  yaxis: ApexYAxis | any;
  dataLabels: ApexDataLabels | any;
  grid: ApexGrid | any;
  stroke: ApexStroke | any;
  colors: any;
};

export interface TruckTires {
  tire_position: string,
  driver: string, 
  brand: string, 
  action: string, 
  mileage: number,
  price: number,
  payment_method: string,
  employee: string,
  date: string
}

const ELEMENT_DATA: TruckTires[] = [
  {tire_position: 'LFI', driver: 'Frederick Robinson', brand: 'Michelin', action: 'Added', mileage: 5230, price: 345,
  payment_method: 'EFS', employee: 'Kevin K.', date: '04.15.2023'},
  {tire_position: 'LFI', driver: 'Frederick Robinson', brand: 'Michelin', action: 'Added', mileage: 5230, price: 345,
  payment_method: 'EFS', employee: 'Kevin K.', date: '04.15.2023'},
  {tire_position: 'LFI', driver: 'Frederick Robinson', brand: 'Michelin', action: 'Added', mileage: 5230, price: 345,
  payment_method: 'EFS', employee: 'Kevin K.', date: '04.15.2023'},
  {tire_position: 'LFI', driver: 'Frederick Robinson', brand: 'Michelin', action: 'Added', mileage: 5230, price: 345,
  payment_method: 'EFS', employee: 'Kevin K.', date: '04.15.2023'},
  {tire_position: 'LFI', driver: 'Frederick Robinson', brand: 'Michelin', action: 'Added', mileage: 5230, price: 345,
  payment_method: 'EFS', employee: 'Kevin K.', date: '04.15.2023'},
  {tire_position: 'LFI', driver: 'Frederick Robinson', brand: 'Michelin', action: 'Added', mileage: 5230, price: 345,
  payment_method: 'EFS', employee: 'Kevin K.', date: '04.15.2023'},
];

@Component({
  selector: 'app-truck-profile-tires',
  templateUrl: './truck-profile-tires.component.html',
  styleUrls: ['./truck-profile-tires.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})

export class TruckProfileTiresComponent implements OnInit {
  //Graph
  @ViewChild("chart") chart: ChartComponent | any;
  public chartOptions: Partial<ChartOptions> | any;

  //Table data
  dataSource = ELEMENT_DATA;
  columnsToDisplay: string[] = ['position', 'tire_position', 'driver', 'brand', 'action', 'mileage', 'price', 'payment_method',
  'employee', 'date'];
  displayedColumns = [
    { key: 'tire_position', title: 'Tire position'},
    { key: 'driver', title: 'Driver'},
    { key: 'brand', title: 'Brand'},
    { key: 'action', title: 'Action'},
    { key: 'mileage', title: 'Mileage'},
    { key: 'price', title: 'Price'},
    { key: 'payment_method', title: 'Payment method'},
    { key: 'employee', title: 'Employee'},
    { key: 'date', title: 'Date'}
  ];
  columnsToDisplayWithExpand = [...this.columnsToDisplay];
  expandedElement: TruckTires | null;

  //Select tires
  isOpenTiresMenu: boolean = false;
  tireValue: string = 'Drive tires';

  selectTiresArray: any[] = [
    {name: 'Drive tires'},
    {name: 'Drive tires'},
    {name: 'Drive tires'},
    {name: 'Drive tires'},
    {name: 'Drive tires'},
  ];

  //Select brands
  isOpenBrandsMenu: boolean = false;
  brandValue: string = 'All Brands';

  selectBrandsArray: any[] = [
    {name: 'All Brands'},
    {name: 'All Brands'},
    {name: 'All Brands'},
    {name: 'All Brands'},
    {name: 'All Brands'},
  ];

  //Select drivers
  isOpenDriverMenu: boolean = false;
  driverValue: string = 'All drivers';

  selectDriversArray: any[] = [
    {name: 'All drivers'},
    {name: 'All drivers'},
    {name: 'All drivers'},
    {name: 'All drivers'},
    {name: 'All drivers'},
  ];

  //Select actions
  isOpenActionsMenu: boolean = false;
  actionValue: string = 'All actions';

  selectActionsArray: any[] = [
    {name: 'All actions'},
    {name: 'All actions'},
    {name: 'All actions'},
    {name: 'All actions'},
    {name: 'All actions'},
  ];


  constructor() { }

  ngOnInit(): void {
    this.initGraph('');
  }

  //Graph method
  initGraph(data: any) {
    let seriesArray: any[] = [
      {name: 'Maintenance cost', data: [10, 20, 100, 30, 46, 70]},
    ];
    this.chartOptions = {
      series: seriesArray,
      chart: {
        toolbar: {
          show: true,
          tools: {
            download: false
          }
        },
        height: 220,
        type: "line",
        zoom: {
          enabled: false
        },
        fontFamily: 'Poppins'
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: "straight",
        width: 1,
      },
      grid: {
        row: {
          colors: ["transparent", "transparent"], 
          opacity: 0.5
        }
      },
      xaxis: {
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun']
      },
      yaxis: {
        tooltip: {
          enabled: true
        },
        labels: {
          formatter: (num: number) => {
            let roundedNumber: any = Math.round(num);
            return roundedNumber.toLocaleString("en-US").toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          },
        },
      },
      markers: {
        size: 5
      },
      colors: ['#005beb', '#FA9120', '#FA0000', '#008000', '#FF00FF', '#800000', '#FE7777', '#0303BB', '#008FA9', '#7550CB', '#6A6D6E'],
    };
  }  

  //Menu select
  selectPeriod(obj: any, selectNum: number) {
    if(selectNum === 1) {
      this.tireValue = obj.name;
      this.isOpenTiresMenu = false;
    }
    else if(selectNum === 2) {
      this.brandValue = obj.name;
      this.isOpenBrandsMenu = false;
    }
    else if(selectNum === 3) {
      this.driverValue = obj.name;
      this.isOpenDriverMenu = false;
    }
    else {
      this.actionValue = obj.name;
      this.isOpenDriverMenu = false;
    }
  };

  //Sort data
  sortData(sort: Sort) {
    /*
    const data = this.dataSource.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }
    this.sortedData = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'unit_no':
          return compare(a.unit_no, b.unit_no, isAsc);
        case 'driver_name':
          return compare(a.driver_name, b.driver_name, isAsc);
        case 'VIN':
          return compare(a.VIN, b.VIN, isAsc);
        case 'division_name':
          return compare(a.division_name, b.division_name, isAsc);
        case 'lastUpdate':
          return compare(a.lastUpdate, b.lastUpdate, isAsc);
        default:
          return 0;
      }
    });
    */
  }

}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { DispatcherProfileService } from '../services/dispatcher-profile.service';
import { Subscription, catchError, throwError } from 'rxjs';
import moment = require('moment');
import { TransformService } from '@app/modules/shared/services/transform.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Sort } from '@angular/material/sort';

@Component({
  selector: 'app-score-page',
  templateUrl: './score-page.component.html',
  styleUrls: ['./score-page.component.scss']
})
export class ScorePageComponent implements OnInit, OnDestroy {
  //Date
  dateObj: any = {
    startDate: moment().startOf('month').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD')
  };
  displayedColumns: string[] = ['position', 'name', 'gross_rank', 'gross_score', 'rate_rank', 'rate_score', 'mileage_rank', 'mileage_score'];
  dataSource: any[] = [];

  //Spinner
  loaded: boolean = false;
  error: boolean = false;

  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";

  spinnerBgColor: string = this.transformService.spinnerBgColor;
  spinnerType: string = this.transformService.spinnerType;

  //Subscription
  subscription: Subscription = new Subscription();

  constructor(public transformService: TransformService,
              private dispatcherProfileService: DispatcherProfileService,
              private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.getTableData();
  }

  getTableData() {
    this.dataSource = [];
    this.error = false;
    this.loaded = false;
    this.spinner.show('score-table');
    this.subscription = this.dispatcherProfileService.getScoreForAllDispatchers(this.dateObj.startDate, this.dateObj.endDate)
    .pipe(catchError((err: any) => {
      this.spinner.hide('score-table');
      this.loaded = true;
      this.error = true;
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      console.log(response)
      this.dataSource = response;
      this.spinner.hide('score-table');
      this.loaded = true;
    });
  };

  
  getDate(result: any) {
    this.dateObj.startDate = moment(result.startDate).format('YYYY-MM-DD');
    this.dateObj.endDate = moment(result.endDate).format('YYYY-MM-DD');
    this.getTableData();
  };

    //Sort data
    sortData(sort: Sort) {
      const data = JSON.parse(JSON.stringify(this.dataSource));
      if (!sort.active || sort.direction === '') {
        this.dataSource = data;
        return;
      }
      this.dataSource = data.sort((a: any, b: any) => {
        const isAsc = sort.direction === 'asc';
        switch (sort.active) {
          case 'name':
            return compare(a.name, b.name, isAsc);
          case 'gross_rank':
            return compare(a.gross_rank, b.gross_rank, isAsc);
          case 'gross_score':
            return compare(a.gross_score, b.gross_score, isAsc);
          case 'rate_rank':
            return compare(a.rate_rank, b.rate_rank, isAsc);
          case 'rate_score':
            return compare(a.rate_score, b.rate_score, isAsc);
          case 'mileage_rank':
            return compare(a.mileage_rank, b.mileage_rank, isAsc);  
          case 'mileage_score':
            return compare(a.mileage_score, b.mileage_score, isAsc);
          default:
            return 0;
        }
      });
    }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  };

}

function compare(a: number | string | any, b: number | string | any, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}


import { TitleCasePipe } from '@angular/common';
import { Component, Input, OnChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { MsgForbbidenAccessComponent } from '@app/modules/shared/components/msg-forbbiden-access/msg-forbbiden-access.component';
import { RulesService } from '@app/modules/shared/services/rules.service';
import { TransformService } from '@app/modules/shared/services/transform.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-dispatch-live-board-cards',
  templateUrl: './dispatch-live-board-cards.component.html',
  styleUrls: ['./dispatch-live-board-cards.component.scss']
})
export class DispatchLiveBoardCardsComponent implements OnChanges {
  permissions: any = this.rulesService.UserData[2].data[0].sectionArray;
  @Input() cardsData: any;
  @Input() utilizationArray: any[] = [];
  displayedColumns: string[] = ['position', 'dispatcer', 'gross', 'rate', 'billable_miles', 'paid_miles', 'empty', 'loaded', 'utilization'];

  //Excel config
  xlsxConfig: any = [
    {columnName: 'No.', selected: true},
    {columnName: 'Dispatcher', selected: true},
    {columnName: 'Gross', selected: true},
    {columnName: 'Rate', selected: true},
    {columnName: 'Billable Miles', selected: true},
    {columnName: 'Paid Miles', selected: true},
    {columnName: 'Empty', selected: true},
    {columnName: 'Loaded', selected: true},
    {columnName: 'Utilization', selected: true}
  ];

  constructor(public transformService: TransformService, 
              private rulesService: RulesService, 
              private router: Router,
              private dialog: MatDialog,
              private titleCase: TitleCasePipe) { }

  ngOnChanges(): void {
    if(this.cardsData && this.utilizationArray) {
      for(let i = 0; i < this.utilizationArray.length; i++) {
        this.countUtilization(this.utilizationArray[i]);
      };
    }
  };

  //Count prev period
  setDifference(prev: number, current: number, weight?: any) {
    let checkGrowth: any;
    let className: any;
    if (current > prev) {
      checkGrowth = 'more than the';
      className = 'up';
      weight === 'weight' ? className = 'down' : className;
    } else if (current < prev) {
      checkGrowth = 'less than the';
      className = 'down';
      weight === 'weight' ? className = 'up' : className;
    } else {
      checkGrowth = '';
      className = 'none'
    }
    let percentage: any;
    if (prev == 0 && current == 0) {
      percentage = 0;
    }

    if (prev == 0 && current !== 0) {
      percentage = Math.abs(100 - (prev * 100 / current));
    }

    if (prev !== 0 && current == 0) {
      percentage = Math.abs(100 - (current * 100 / prev));
    }

    if (prev !== 0 && current !== 0) {
      percentage = Math.abs(100 - (prev / current) * 100);
    }

    let alert: string = ` ${checkGrowth} previous ${this.cardsData[1].data.dayName}`;
    let alertObj: any = { percent: Math.round(percentage), message: alert, className: className };
    return alertObj;
  };

  //Open in new tab
  openInNewTab(route: string) {
    const url = this.router.serializeUrl(this.router.createUrlTree([route]));
    window.open(url, '_blank');
  };

  //Count utilization
  countUtilization(data: any[]) {
    let numOfTrucks: number = data.length;
    let num: number = 0;
    let utilizationConditions: string[] = ['truck.svg', 'dispatch-swap.png', 'in-transit.png', 'truck-yellow.svg'];
    for(let key in data) {
      if(utilizationConditions.includes(data[key].icon_name)) {
        num++;
      }
    };
    for(let i = 0 ; i < this.cardsData[2].data.length; i++) {
      if(this.cardsData[2].data[i].id === data[0].dispatcher_id) {
        this.cardsData[2].data[i].utilization = +this.transformService.addCommasDots(num * 100 / numOfTrucks, 'round');
      }
    };
  };

  //Sort data
  sortData(sort: Sort) {
    const data = JSON.parse(JSON.stringify(this.cardsData[2].data));
    if (!sort.active || sort.direction === '') {
      this.cardsData[2].data = data;
      return;
    }
    this.cardsData[2].data = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'dispatcer':
          return compare(a.dispatcer, b.dispatcer, isAsc);
        case 'gross':
          return compare(a.gross, b.gross, isAsc);
        case 'rate':
        return compare(a.rate, b.rate, isAsc);
        case 'billable_miles':
          return compare(a.billable_miles, b.billable_miles, isAsc);
        case 'paid_miles':
          return compare(a.paid_miles, b.paid_miles, isAsc);
        case 'empty':
          return compare(a.empty, b.empty, isAsc);
        case 'loaded':
          return compare(a.loaded, b.loaded, isAsc);
        case 'utilization':
          return compare(a.utilization, b.utilization, isAsc);
        default:
          return 0;
      }
    });
  };

  exportToExcel(columnsConfig: any[]) {
    if(this.rulesService.UserData[56].data[0].sectionArray[5].allowed) {
      const excelTable: any[] = [];
      for(let i = 0; i < this.cardsData[2].data.length; i++) {
        let obj: any = {};
        this.transformService.selectedColumn(obj, columnsConfig[0].columnName, i + 1, columnsConfig[0].selected);
        this.transformService.selectedColumn(obj, columnsConfig[1].columnName, this.titleCase.transform(this.cardsData[2].data[i].dispatcer), columnsConfig[1].selected);
        this.transformService.selectedColumn(obj, columnsConfig[2].columnName, `$${this.transformService.addCommasDots(this.cardsData[2].data[i].gross, 'round')}`, columnsConfig[2].selected);
        this.transformService.selectedColumn(obj, columnsConfig[3].columnName, `$${this.transformService.addCommasDots(this.cardsData[2].data[i].rate)}`, columnsConfig[3].selected);
        this.transformService.selectedColumn(obj, columnsConfig[4].columnName, this.transformService.addCommasDots(this.cardsData[2].data[i].billable_miles, 'round'), columnsConfig[4].selected);
        this.transformService.selectedColumn(obj, columnsConfig[5].columnName, this.transformService.addCommasDots(this.cardsData[2].data[i].paid_miles, 'round'), columnsConfig[5].selected);
        this.transformService.selectedColumn(obj, columnsConfig[6].columnName, this.transformService.addCommasDots(this.cardsData[2].data[i].empty, 'round'), columnsConfig[6].selected);
        this.transformService.selectedColumn(obj, columnsConfig[7].columnName, this.transformService.addCommasDots(this.cardsData[2].data[i].loaded, 'round'), columnsConfig[7].selected);
        this.transformService.selectedColumn(obj, columnsConfig[8].columnName, `${this.cardsData[2].data[i].utilization}%`, columnsConfig[8].selected);
        excelTable.push(obj);
      };
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelTable);
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, 'dispatchers-rank.xlsx');
    }
    else {
      this.msgForbbidenAccess();
    }
  };

  msgForbbidenAccess() {
    this.dialog.open(MsgForbbidenAccessComponent, {
      autoFocus: false,
      panelClass: 'forbidden-msg-dialog-container'
    })
  };

}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

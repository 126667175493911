import { AfterContentChecked, ChangeDetectorRef, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import moment = require('moment');
import * as XLSX from 'xlsx';
import { NgxSpinnerService } from 'ngx-spinner';
import { catchError, Subscription, throwError } from 'rxjs';
import { SharedService } from '@app/modules/shared/services/shared.service';
import { TransformService } from '@app/modules/shared/services/transform.service';
import { RulesService } from '@app/modules/shared/services/rules.service';
import { TrucksService } from '@app/modules/trucks/services/trucks.service';

@Component({
  selector: 'app-truck-performance-profitability-table',
  templateUrl: './truck-performance-profitability-table.component.html',
  styleUrls: ['./truck-performance-profitability-table.component.scss']
})
export class TruckPerformanceProfitabilityTableComponent implements OnInit, OnChanges, AfterContentChecked, OnDestroy {
  @ViewChild('scrollContainer', { read: ElementRef }) public scrollContainer: any;
  @Input() date: any;

  fileName = 'trucks-table.xlsx';

  tableData: any;

  objStCards: any = {
    profitWeeks: 0,
    profit: 0,
    profitPercent: 0,
    profitCpm: 0,
    lossWeeks: 0
  }

  objHorizontalTotal: any = {
    gross: 0,
    equipment: 0,
    fuel: 0,
    maintenance: 0,
    tolls: 0,
    eld: 0,
    insurance: 0,
    ifta: 0,
    driverPay: 0,
    total: 0,
    className: 'grey-gradient'
  }

  netProfitGraphData: any[] = [];

  unitNo: string = '';

  //Interval
  interval: any;

  loaded: boolean = true;

  error: boolean = false;

  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";

  spinnerBgColor: string = this.transformService.spinnerBgColor;
  spinnerType: string = this.transformService.spinnerType;

  subscription1: Subscription | any;
  subscription2: Subscription | any;

  constructor(private cdref: ChangeDetectorRef,
              public transformService: TransformService,
              private trucksService: TrucksService, 
              private sharedService: SharedService,
              private spinner: NgxSpinnerService,
              private rulesService: RulesService) { }

  ngOnInit(): void {
    this.spinner.show('truck-profitability');
    this.dateChanged();
    if(this.rulesService.liveUpdate) {
      this.interval = setInterval(() => {
        this.getTableData();
      }, this.rulesService.miliseconds);
    }
  }

  ngOnChanges() {
    if(this.date) {
      this.getTableData();
    }
  }

  getTableData() {
    this.trucksService.getTruckProfitabilityTableData(this.date.startDate, this.date.endDate, this.transformService.filterParams)
    .pipe(catchError((err: any) => {
      this.spinner.hide('truck-profitability');
      this.loaded = true;
      this.error = true;
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      console.log(response);
      this.tableData = response;
      this.countHorizontalTotal(response);
      for(let key in response) {
        let expense: number = response[key].gross_exp + response[key].equipment_exp + 
        response[key].fuel_exp + response[key].maintenance_exp + response[key].tolls_exp
        + response[key].eld_exp + response[key].insurance_exp + response[key].ifta_exp + response[key].driver_pay_exp;

        let income: number = response[key].gross_in + response[key].equipment_in + response[key].fuel_in 
        + response[key].maintenance_in + response[key].tolls_in
        + response[key].eld_in + response[key].insurance_in +  response[key].ifta_in + response[key].driver_pay_in;

        if((income - expense) < 1000) {
          this.objStCards.lossWeeks++;
        } 
        else {
          this.objStCards.profit += income - expense;
          this.objStCards.profitPercent += ((income - expense) / (response[key].gross_exp + response[key].gross_in)) * 100;
          this.objStCards.profitCpm += ((income - expense) / (response[key].info.mileage));
          this.objStCards.profitWeeks++;
        } 
      }

      this.spinner.hide('truck-profitability');
      this.loaded = true;
    })
  }

  dateChanged() {
    this.subscription2 = this.trucksService.truckPerformanceDateChanged.subscribe((response: any) => {
      this.date.startDate = response.startDate;
      this.date.endDate = response.endDate;
      this.tableData = undefined;
      this.error = false;
      this.loaded = false;

      this.spinner.show('truck-profitability');
      this.getTableData()
    })
  }

  //Scroll
  public scrollRight(): void {
    let scrollInitialValue: number = Math.ceil(this.scrollContainer.nativeElement.scrollLeft);
    this.scrollContainer.nativeElement.scrollTo({ left: (scrollInitialValue + 275), behavior: 'smooth' });
  }

  public scrollLeft(): void {
    let scrollInitialValue: number = Math.ceil(this.scrollContainer.nativeElement.scrollLeft);
    this.scrollContainer.nativeElement.scrollTo({ left: (scrollInitialValue - 275), behavior: 'smooth' });
  }

  formatDate(startDate: string, endDate:string) {
    return moment(startDate).format('MMM DD') + ' - ' + moment(endDate).format('DD, yyyy');
  }

  countTotal(data: any) {
    let expense: number = data.gross_exp + data.equipment_exp + data.fuel_exp + data.maintenance_exp + data.tolls_exp
    + data.eld_exp + data.insurance_exp + data.ifta_exp + data.driver_pay_exp;
    let income: number = data.gross_in + data.equipment_in + data.fuel_in + data.maintenance_in + data.tolls_in
    + data.eld_in + data.insurance_in +  data.ifta_in + data.driver_pay_in;
    let total: string = this.transformService.addCommasDots((income - expense), 'round');
    let className: string = '';

    if((income - expense) < 1000) {
      className = 'red-gradient';
    } 
    else {
      className = 'green-gradient'
    } 
    return {total: total, className: className}
  }

  countHorizontalTotal(data: any[]) {
    let grossExpTotal: number = 0;
    let grossInTotal: number = 0;

    let equipmentExpTotal: number = 0;
    let equipmentInTotal: number = 0;

    let fuelExpTotal: number = 0;
    let fuelInTotal: number = 0;

    let maintenanceExpTotal: number = 0;
    let maintenanceInTotal: number = 0;

    let tollsExpTotal: number = 0;
    let tollsInTotal: number = 0;

    let eldExpTotal: number = 0;
    let eldInTotal: number = 0;

    let insuranceExpTotal: number = 0;
    let insuranceInTotal: number = 0;

    let iftaExpTotal: number = 0;
    let iftaInTotal: number = 0;

    let driverPayExpTotal: number = 0;
    let driverPayInTotal: number = 0;

    for(let key in data) {
      grossExpTotal += data[key].gross_exp;
      grossInTotal += data[key].gross_in;

      equipmentExpTotal += data[key].equipment_exp;
      equipmentInTotal += data[key].equipment_in;

      fuelExpTotal += data[key].fuel_exp;
      fuelInTotal += data[key].fuel_in;

      maintenanceExpTotal += data[key].maintenance_exp;
      maintenanceInTotal += data[key].maintenance_in;

      tollsExpTotal += data[key].tolls_exp;
      tollsInTotal += data[key].tolls_in;

      eldExpTotal += data[key].eld_exp;
      eldInTotal += data[key].eld_in;

      insuranceExpTotal += data[key].insurance_exp;
      insuranceInTotal += data[key].insurance_in;

      iftaExpTotal += data[key].ifta_exp;
      iftaInTotal += data[key].ifta_in;

      driverPayExpTotal += data[key].driver_pay_exp;
      driverPayInTotal += data[key].driver_pay_in;
    }

    this.objHorizontalTotal.gross = grossInTotal - grossExpTotal;
    this.objHorizontalTotal.equipment = equipmentInTotal - equipmentExpTotal;
    this.objHorizontalTotal.fuel = fuelInTotal - fuelExpTotal;
    this.objHorizontalTotal.maintenance = maintenanceInTotal - maintenanceExpTotal;
    this.objHorizontalTotal.tolls = tollsInTotal - tollsExpTotal;
    this.objHorizontalTotal.eld = eldInTotal - eldExpTotal;
    this.objHorizontalTotal.insurance = insuranceInTotal - insuranceExpTotal;
    this.objHorizontalTotal.ifta = iftaInTotal - iftaExpTotal;
    this.objHorizontalTotal.driverPay = driverPayInTotal - driverPayExpTotal;
    this.objHorizontalTotal.total = this.objHorizontalTotal.gross + this.objHorizontalTotal.equipment + this.objHorizontalTotal.fuel +
    this.objHorizontalTotal.maintenance + this.objHorizontalTotal.tolls + this.objHorizontalTotal.eld + this.objHorizontalTotal.insurance +
    this.objHorizontalTotal.ifta + this.objHorizontalTotal.driverPay;
    this.trucksService.netProfitSubject.next(this.objHorizontalTotal.total);
    if(this.objHorizontalTotal.total < 1000) {
      this.objHorizontalTotal.className = 'red-gradient';
    } 
    else {
      this.objHorizontalTotal.className = 'green-gradient'
    } 

  }

  exportToExcel() {
    let element = document.getElementById('trucks-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    XLSX.writeFile(wb, this.fileName);
  };


  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
    this.subscription1?.unsubscribe();
    this.subscription2?.unsubscribe();
  }

}

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DriversProfileService } from '@app/modules/drivers-profile-page/services/drivers-profile.service';
import { DeleteConfirmationDialogComponent } from '@app/modules/shared/components/delete-confirmation-dialog/delete-confirmation-dialog.component';

@Component({
  selector: 'app-driver-info-reasons-create-update-dialog',
  templateUrl: './driver-info-reasons-create-update-dialog.component.html',
  styleUrls: ['./driver-info-reasons-create-update-dialog.component.scss']
})
export class DriverInfoReasonsCreateUpdateDialogComponent implements OnInit {
  reasonsArray: any[] = [];
  reasonObj: any = {
    id: 0,
    reason: ''
  }

  objIndex: number | undefined = undefined;

  constructor(@Inject(MAT_DIALOG_DATA) public obj: any, public dialogRef: MatDialogRef<DriverInfoReasonsCreateUpdateDialogComponent>,
  private dialog: MatDialog, private driversProfileService: DriversProfileService) { }

  ngOnInit(): void {
    this.reasonsArray = this.obj.data;
    this.dialogRef.backdropClick().subscribe(result => {
      this.dialogRef.close(this.reasonsArray);
    });
  }

  editDiscardReason(obj: any, index: number) {
    if(this.reasonObj.id === obj.id) {
      this.reasonObj.id = 0;
      this.reasonObj.reason = '';
      this.objIndex = undefined;
    }
    else {
      this.reasonObj.id = obj.id;
      this.reasonObj.reason = obj.reason;
      this.objIndex = index;
    }
  };

  deleteReason(id: number, index: number) {
    let dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
      autoFocus: false,
      panelClass: 'delete-dialog-container',
    });
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if(result) {
        this.driversProfileService.deleteDriverReason(id).subscribe((response: boolean) => {
          if(response) {
            this.reasonsArray.splice(index, 1);
          }
          console.log(response);
        })
      }
    });
  };

  save() {
    this.driversProfileService.createUpdateDriverReason(this.reasonObj).subscribe((response: any) => {
      console.log(response);
      if(response.id) {
        if(response.id === this.reasonObj.id) {
          this.reasonsArray[this.objIndex].reason = response.reason;
        }
        else {
          this.reasonsArray.push(response);
        }
        this.reasonObj.id = 0;
        this.reasonObj.reason = '';
      }
    })
  };
  
}
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FullAppService } from '@app/modules/full-app/services/full-app.service';
import moment = require('moment');

@Component({
  selector: 'app-schedule-orientation-date-dialog',
  templateUrl: './schedule-orientation-date-dialog.component.html',
  styleUrls: ['./schedule-orientation-date-dialog.component.scss']
})
export class ScheduleOrientationDateDialogComponent {
  isOpenCalendar: boolean = false;
  orientationDate: string = '';

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private fullAppService: FullAppService, 
              public dialogRef: MatDialogRef<ScheduleOrientationDateDialogComponent>) { }

  //Calendar method
  dateClicked(event: any) {
    this.orientationDate = moment(event._d).format('YYYY-MM-DDT00:00:00');
    this.isOpenCalendar = false;
  };

  save() {
    this.data.obj.orientation_date = this.orientationDate;
    this.fullAppService.changeStatusOrRecruiter(this.data.obj).subscribe((response: boolean) => {
      if(response) {
        this.dialogRef.close(this.data.obj);
      }
    });
  };

}

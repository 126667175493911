import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IBrokersToAvoid } from '@app/modules/brokers-to-avoid/models/brokers-to-avoid.model';
import { ApexAxisChartSeries, ApexChart, ApexDataLabels, ApexPlotOptions, ApexYAxis, ChartComponent, 
ApexGrid, ApexLegend } from 'ng-apexcharts';

type ApexXAxis = {
  type?: "category" | "datetime" | "numeric";
  categories?: any;
  labels?: {
    rotate?: number,
    style?: {
      colors?: string | string[];
      fontSize?: string;
    }
  }
};

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  xaxis: ApexXAxis;
  grid: ApexGrid;
  colors: string[];
  legend: ApexLegend;
};

@Component({
  selector: 'brokers-to-avoid-column-chart',
  templateUrl: './brokers-to-avoid-column-chart.component.html',
  styleUrls: ['./brokers-to-avoid-column-chart.component.scss']
})
export class BrokersToAvoidColumnChartComponent implements OnInit {
  @Input() dataArray: IBrokersToAvoid[] = [];
  @ViewChild("chart") chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;

  constructor() { }

  ngOnInit(): void {
    this.initChart();
  }

  initChart() {
    let dataObj: any = this.groupByReason(this.dataArray);
    console.log(dataObj);
    this.chartOptions = {
      series: dataObj.seriesArray,
      chart: {
        height: 400,
        type: "bar",
        fontFamily: 'Poppins',
        toolbar: {
          show: true,
          tools: {
            download: false
          }
        },
      },
      colors: ['#b7b7b7'],
      plotOptions: {
        bar: {
          columnWidth: "45%",
          distributed: true,
          dataLabels: {
            position: "top"
          }
        }
      },
      dataLabels: {
        enabled: true,
        offsetY: -30,
        style: {
          fontSize: "14px",
          colors: ["#6b6b6b"]
        }
      },
      legend: {
        show: false
      },
      grid: {
        show: true
      },
      xaxis: {
        categories: dataObj.categoriesArray,
        labels: {
          rotate: -45,
          style: {
            colors: ['#6b6b6b'],
            fontSize: "14px"
          }
        }
      }
    };
  };

  //Group by reason
  groupByReason(array: any) {
    let categoriesArray: string[] = [];
    let seriesArray: any[] = [{name: "Total", data: []}];
    const groups: any = array.reduce((acc: any, obj: any) => {

    if (!acc[obj.reason]) {
      acc[obj.reason] = [];
    }
        
    acc[obj.reason].push(obj);
      
    return acc;
      
    }, {});

    for(let groupName in groups) {
      
      let tempArray: string[] = [];
      categoriesArray.push(groupName);
      for(let i = 0; i < groups[groupName].length; i++) {
        if(!tempArray.includes(groups[groupName][i].mc)) {
          tempArray.push(groups[groupName][i].mc)
        }
      };  

      seriesArray[0].data.push(tempArray.length);
    };

    if(array.length === 0) {
      seriesArray = [];
    };

    return {categoriesArray: categoriesArray, seriesArray: seriesArray};
  };

}

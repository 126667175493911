import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DashboardDriverProfilePageComponent } from './components/dashboard-driver-profile-page.component';
import { AuthGuard } from '../core/services/auth/auth.guard';

const routes: Routes = [
	{
		path: 'drivers',
		//canActivate: [AuthGuard],
		children: [
		  {
			path: 'filter-drivers',
			children:[
				{
				  path: 'driver-profile/:id',
				  canActivate: [AuthGuard],
				  component: DashboardDriverProfilePageComponent,
				  data: { roles: 14 }
				}
			]
		  }
		]
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class DriversProfilePageRoutingModule { }

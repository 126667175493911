import { Component, OnInit, Input, ViewChild, NgZone } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';

import { ApexAxisChartSeries, ApexChart, ApexDataLabels, ApexFill, ApexGrid, ApexLegend, ApexResponsive, ApexStroke,
ApexXAxis, ChartComponent } from 'ng-apexcharts';
import { Subscription } from 'rxjs';
import { HiredTerminatedDialogComponent } from './hired-terminated-dialog/hired-terminated-dialog.component';
import { RecruitingService } from '../../services/recruiting.service';
import { TransformService } from '@app/modules/shared/services/transform.service';

export type ChartOptions = {
  series: ApexAxisChartSeries | any;
  chart: ApexChart | any;
  xaxis: ApexXAxis | any;
  dataLabels: ApexDataLabels | any;
  grid: ApexGrid | any;
  stroke: ApexStroke | any;
};

export type ChartOptions2 = {
  series: ApexAxisChartSeries | any;
  chart: ApexChart | any;
  responsive: ApexResponsive | any;
  labels: string | any;
  colors: string | any;
  legend: ApexLegend | any;
  fill: ApexFill | any;
};
@Component({
  selector: 'app-hired-terminated-charts',
  templateUrl: './hired-terminated-charts.component.html',
  styleUrls: ['./hired-terminated-charts.component.scss']
})
export class HiredTerminatedChartsComponent implements OnInit {
  @ViewChild("chart") chart: ChartComponent | any;
  public chartOptions: Partial<ChartOptions> | any;
  public chartOptions2: Partial<ChartOptions> | any;
  @Input() date: any;
  subscription1: Subscription | any;
  subscription2: Subscription | any;
  show: string = 'bar';

  objOfArrays: any;

  graphSelected: FormControl;

  barChartData: any;

  barChartOptions: any;

  dataForDialog: any;

  data: any;
  elements: any;

  constructor(private dataService: RecruitingService,
    private transformService: TransformService,
    private ngZone: NgZone,
    public dialog: MatDialog) {
    this.graphSelected = new FormControl(['bar']);
    this.barChartOptions = {
      responsive: true,
      scales: {
        x: {},
        y: {}
      },
      plugins: {
        legend: {
          display: true,
        },
        datalabels: {
          anchor: 'end',
          align: 'end'
        }
      },
      onClick: (
        event: any,
        elements: any[],
        chart: 'bar'
      ) => {
        this.openDialog(elements);
      }
    }
}

  ngOnInit(): void {
    this.getBarChartData(this.date);
    this.dateChange();
  }
  getBarChartData(dateObj: any) {
    this.subscription1 = this.dataService.getHiredTerminatedDrivers(dateObj.groupBy, dateObj.startDate, dateObj.endDate).subscribe(
      (response: any) => {
        console.log(response);
        this.dataForDialog = this.transformService.countDataForDialogRecruiting(response.data, dateObj.period);
        this.objOfArrays = this.transformService.countDataByPeriodRecruiting(response.data, dateObj.period);
        this.initCertainGraph(this.show, dateObj.period);
        console.log(this.dataForDialog)
        console.log(this.objOfArrays)
      });
  }

  dateChange() {
    this.subscription2 = this.dataService.dateSubject.subscribe((dateObj: any) => {
      this.getBarChartData(dateObj);
    });
  };

  //Open dialog

  openDialog(elements: any) {
    this.ngZone.run(() => {
      this.dialog.open(HiredTerminatedDialogComponent, {
        maxWidth: '600px',
        data: {
          elements: elements,
          data: this.dataForDialog.group
        }
      });
    });

  }

  //Init certain graph

  initCertainGraph(type: string, period?: string) {
    if (type === 'bar') {
      let periodArr: any[] = ['today', 'isoWeek', 'month', 'days']
      let data: any;

      if (periodArr.includes(period)) {
        let obj: any = { labels: [], hired: [], terminated: [] };

        for (let key in this.dataForDialog.group2) {
          obj.labels.push(key);
          obj.hired.push(this.dataForDialog.group2[key][0].hired);
          obj.terminated.push(this.dataForDialog.group2[key][0].terminated);
        }

        data = obj;
      } else {
        data = this.objOfArrays;
      };

      this.initBarChartData(data);
    };

    if (type === 'area' || type === 'line') {
      this.initGraphAreaLine(this.objOfArrays, type);
    };

    if (type === 'pie') {
      this.initPieChart(this.objOfArrays);
    };
  }

  //Graph bar

  initBarChartData(objOfArrays: any) {
    this.barChartData = {
      labels: objOfArrays.labels,
      datasets: [
        { data: objOfArrays.hired, label: 'HIRED', backgroundColor: '#100ca1', borderColor: '#0250cc' },
        { data: objOfArrays.terminated, label: 'TERMINATED', backgroundColor: '#f4661c', borderColor: '#FFA500' }
      ]
    };
  };

  //Graph line area

  initGraphAreaLine(objOfArrays: any, type: string) {
    this.chartOptions = {
      series: [
        {
          name: 'HIRED',
          data: objOfArrays.hired
        },
        {
          name: 'TERMINATED',
          data: objOfArrays.terminated
        },
      ],
      chart: {
        height: 350,
        type: type,
        zoom: {
          enabled: false
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: "smooth"
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5
        }
      },
      xaxis: {
        categories: objOfArrays.labels
      }
    }
  };

  initPieChart(objOfArrays: any) {
    let sumHired = 0;
    let sumTerminated = 0;

    objOfArrays.hired.forEach((hired: number) => {
      sumHired += hired;
    });
    objOfArrays.terminated.forEach((terminated: number) => {
      sumTerminated += terminated;
    });

    this.chartOptions2 = {
      fill: {
        type: 'gradient'
      },
      colors: ['#100ca1', '#868686', '#f4661c'],
      series: [sumHired, sumTerminated],
      chart: {
        width: 360,
        type: "pie",
      },
      labels: ['HIRED', 'TERMINATE'],
      legend: {
        position: 'bottom'
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: "bottom"
            }
          }
        }
      ]
    };
  }

  onlyOneSelect(value: string) {
    this.graphSelected = new FormControl([value]);
  };

  onSubmit(matSelect: MatSelect) {
    matSelect.close();
    this.show = matSelect.value[0];
    this.initCertainGraph(matSelect.value[0])
  };

  ngOnDestroy(): void {
    this.subscription1.unsubscribe();
    this.subscription2.unsubscribe();
  }
}

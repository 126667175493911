import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SharedService } from '@app/modules/shared/services/shared.service';
import { TransformService } from '@app/modules/shared/services/transform.service';
import moment = require('moment');
import { Subscription } from 'rxjs';

@Component({
  selector: 'driver-table-popup',
  templateUrl: './driver-table-popup.component.html',
  styleUrls: ['./driver-table-popup.component.scss']
})
export class DriverTablePopupComponent implements OnInit, OnDestroy {
  @Input() statusObj: any;
  dateObj: any = {
    startDate: this.transformService.convertDateToTimestamp(moment().subtract(4, "week").startOf('isoWeek').format('ddd, DD/MM YYYY'),'00:00:00'),
    endDate: this.transformService.convertDateToTimestamp(moment().format('ddd, DD/MM YYYY'),  moment().format('HH:mm:ss'))
  };

  displayedColumns: string[] = ['mondey', 'gross', 'driver_gross', 'mileage', 'paid_mileage', 'rate', 'weight', 'num_of_loads', 'unitlization', 'home_time'];
  dataSource: any[] = [];

  avgObj: any = {
    grossAvg: 0,
    driverGrossAvg:0,
    mileageAvg: 0,
    paidMilesAvg: 0,
    rateAvg: 0,
    weightAvg: 0,
    loadsAvg: 0,
    utilizationAvg: 0,
    hometimeAvg: 0,
    dealAvg: 0,
    paycheckAvg: 0
  };

  avgFleet: any;

  subscription: Subscription | any;

  constructor(public transformService: TransformService,
              private sharedService: SharedService) { }

  ngOnInit(): void {
    this.subscription = this.sharedService.getDriverWeeklyOveview(this.dateObj.startDate, this.dateObj.endDate, this.statusObj.id)
    .subscribe((response: any) => {
      this.avgFleet = response;
      let array: any[] = JSON.parse(JSON.stringify(response.lista));
      if(response.lista.length > 0) {

        let d1: any = moment().startOf('isoWeek').format('yyyy-MM-DD');
        let d2: any = moment(response.lista[0].mondey).format('yyyy-MM-DD');

        if(d1 !== d2) {
          array.unshift({ deal: 0, gross: 0, home_time: 0, mileage: 0, 
          mondey: this.transformService.convertDateToTimestamp(moment().startOf('isoWeek').format('ddd, DD/MM YYYY'),'00:00:00'), 
          num_of_loads: 0, rate: 0, statement: 0, statement_no: 0, unitlization: 0, week: 0, weight: 0, year: null});
        }
        
      }
      this.dataSource  = array;
      this.countStData(array);
      console.log(response);
    })
  }

  countStData(array: any) {
    let weekNum: number = this.dataSource.length - 1;
    let weekNumSubstract2: number = this.dataSource.length - 3;
    let sumGross: number = 0;
    let sumDriverGross: number = 0;
    let sumMileage: number = 0;
    let sumPaidMiles: number = 0;
    let sumWeight: number = 0;
    let sumLoads: number = 0;
    let sumUtilization: number = 0;
    let sumHometime: number = 0;
    let sumDeal: number = 0;
    let sumPaycheck: number = 0;
    console.log(array);

    for(let i = 1; i < array.length; i++) {
      sumGross += array[i].gross;
      sumDriverGross += array[i].driver_gross;
      sumMileage += array[i].mileage;
      sumPaidMiles += array[i].paid_mileage;
      sumWeight += array[i].weight;
      sumLoads += array[i].num_of_loads;
      sumUtilization += array[i].unitlization;
      sumHometime += array[i].home_time;
      sumDeal += array[i].deal;
      sumPaycheck += array[i].statement;
    }

    this.avgObj.grossAvg = sumGross / weekNum;
    this.avgObj.driverGrossAvg = sumDriverGross / weekNum;
    this.avgObj.mileageAvg = sumMileage / weekNum;
    this.avgObj.paidMilesAvg = sumPaidMiles / weekNum;
    this.avgObj.rateAvg = sumGross / sumMileage;
    this.avgObj.weightAvg = sumWeight / weekNum;
    this.avgObj.loadsAvg = sumLoads / weekNum;
    this.avgObj.utilizationAvg = sumUtilization / weekNum;
    this.avgObj.hometimeAvg = sumHometime / weekNum;
    this.avgObj.dealAvg = sumDeal / weekNum;
    this.avgObj.paycheckAvg = sumPaycheck / weekNumSubstract2;
  };

  getStartEndWeek(date: string) {
    let value: string = '';
    let d1: any = moment().startOf('isoWeek').format('yyyy-MM-DD');
    let d2: any = moment(date).format('yyyy-MM-DD');
    if(d1 === d2) {
      value = 'This week in progress'
    }
    else {
      value = `${moment(date).startOf('isoWeek').format('MMM DD')}-${moment(date).endOf('isoWeek').format('MMM DD, yyyy')}`;
    }
    return value;
  };

  //Num of weeks
  numOfWeeks() {
    let value: string = '0 weeks';

    if(this.dataSource.length === 1) {
      value = this.dataSource.length - 1 + ' week';
    };

    if(this.dataSource.length > 1) {
      value = this.dataSource.length - 1 + ' weeks';
    };

    return value;
  }

  getClass(averageWeek: number, averageFleet: number) {
    if(averageWeek < averageFleet) {
      return 'red-color';
    }
    else if(averageWeek > averageFleet) {
      return 'green-color';
    }
    else {
      return '';
    }
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

}

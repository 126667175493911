import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, TemplateRef, Output, EventEmitter, ViewChildren, QueryList } from '@angular/core';
import { Subject } from 'rxjs';
import { Misc } from 'app/_config/misc';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { EstimationsService } from '../../services/estimations.service';
import { LoadCalculationTimePoint, Load, LoadCalculationGrouped, LoadCalculationResult, MapCircle } from '../../models/load.model';
import { SharedService } from '@app/modules/shared/services/shared.service';
import * as L from 'leaflet';
import {MarkerClusterGroup} from "leaflet.markercluster";

declare const google: any;

declare const MarkerClusterer: any;
declare const $: any;

interface Marker {
    lat: number;
    lng: number;
    label?: string;
    draggable?: boolean;
}

@Component({
	selector: 'loads-estimation-board',
	templateUrl: './estimation-board.component.html',
	styleUrls: ['./estimation-board.component.scss']
  })
  export class EstimationBoardComponent implements OnInit {

	@Output () timeSeries = new EventEmitter<LoadCalculationTimePoint[]>();
	@Output ('loads') resultLoads = new EventEmitter<Load[]>();
	@Output ('grouped') resultGrouped = new EventEmitter< Map<string, Load[]> >();

	public errorMessage;
	public loading = false;

    @ViewChild('startInput') startInput: ElementRef;
    @ViewChild('pickupInput') pickupInput: ElementRef;
    @ViewChild('deliveryInput') deliveryInput: ElementRef;
	@ViewChild('hdrTpl') hdrTpl: TemplateRef<any>;

	private autocompleteOptions; 

	private startLocationAutocomplete: any;
	private pickupLocationAutocomplete: any;
	private deliveryLocationAutocomplete;

    public amazonPickupCode: string;
    public amazonDeliveryCode: string;

    private pickupLocation;
    private deliveryLocation;
    private startLocation;

    public individualLocationsView = true;

    private stageTitle: string[];

    private waypoints = [];
    private origin;
    private destination;

    private locations: Load[] = [];
    private amazonLocations;

    private zipCodes;
    public amazonCodes;

    private map = null;
    private geocoder = null;
    private markersOnMap;
    private markerCluster;
    private markerIcon;
    private directionsDisplay;
    private directionsService;

    public circles = [];

    private infowindow;

    // Private variables
    private _circle = null; // tmp variable for google maps Circle graphics

    // Holedrs for filtered drivers locations by getCloseLocations() function
    private dataSetIndividual = []
    private dataSetOrigin = [];
    private dataSetDestination = [];

    public individualDataTable;
    public originDataTable;
    public destinationDataTable;

    public selectedRows = [];

    public columns = [
        { prop: 'full_name', name: "Name" },
        { prop: "phone", name: 'Phone' },
        { prop: "email", name: 'Email' },
        { prop: "active", name: 'Status' }
    ];


    private driver: Driver;
    private drivers: Driver[] = [];

    private driversEmailList = [];

    private route = {
        origin: '',
        destination: ''
    };

    public constructor(private fb: FormBuilder, private estimationsService: EstimationsService, private sharedService: SharedService, 
    public ref: ChangeDetectorRef, public m: Misc) { }

    public states: string[];

    routeDetailsArray: any[] = [];

    locationArray: any[] = [];
    isOpenIndividualLocationMenu: boolean = false;
    individualLocation: string = '';
  
    isOpenStartLocationMenu: boolean = false;
    startPickupLocation: string = '';
    isOpenEndLocationMenu: boolean = false;
    endDeliveryLocation: string = '';

    ngOnInit() { 
        this.getLocations();
		this.getZipCodes();

		this.stopsFormGroup = this.fb.group({
			stops: this.fb.array([
				//this.createPreviousEmployer()
			]),
	
		});
    }

    dtOptions: DataTables.Settings = {};
    dtTrigger: Subject<any> = new Subject();
	dtInstance: DataTables.Api;
	
	startLocValue = '';
	pickupLocValue = '';
	deliveryLocValue = '';

    formatLabel(value: number): number {
        return value;
    }

	//calc: Map<string, LoadCalculationGrouped>;
	calc: LoadCalculationGrouped

	loads: Load[];
	grouped: LoadCalculationGrouped[];

	title = {
		'dollar_per_mile': '$/ mile',
		'dollar_per_mile_loaded': '$/ mile(L) ',
		'gross': 'Load price',
		'mileage': 'Miles',
		'mileage_loaded': 'Miles (L)',
		'weight': 'Weight'
	}

	switchLocations() {
		var tmpPickup = '';
		tmpPickup = this.startPickupLocation;
		this.startPickupLocation= this.endDeliveryLocation;
		this.endDeliveryLocation= tmpPickup;

		this.clearStops();
	}

    getLocations() {
        this.sharedService.getAllLocationInfo().subscribe((response: any) => {
            console.log(response);
            this.locationArray = response;
        });
    };


	public getLoadsCalculation() {
		this.calc = null;
		this.setStatus(true); // TODO: rename function or create usefull one

        this.sharedService.getCoordinates(this.startPickupLocation, this.endDeliveryLocation)
        .subscribe((response: any) => {
            if(response.origin.length > 0 && response.destination.length > 0) {
                let reoute = {
                    pickup: {
                        longitude: Number(response.origin[0].lon),
                        latitude: Number(response.origin[0].lat),
                        miles: this.radius
                    },
                    delivery: {
                        longitude: Number(response.destination[0].lon),
                        latitude: Number(response.destination[0].lat),
                        miles: this.radius
                    },
                    mileage: this.totalRouteMiles 
                }

                this.estimationsService.getLoadsCalculationByRadius(reoute, true).subscribe(
                    response => {
                        console.log('Response: ', response);
                        this.setResult(response);
                        //this.addMarkersToMap();
                        this.setStatus(false);
                        //this.getTimeSeries();
                    },
                    error => {
                        this.errorMessage = <any>error;
                        console.log('getLoadsCalculation > ', this.errorMessage);
                        this.setStatus(false);
                        alert('Connection issue, contact support.')
                    }
                );
            }
        })
	}

	setStatus (status: boolean) {
		this.loading = status;
	}

	setResult(result: LoadCalculationResult) {
		//this.calc = new Map<string, LoadCalculationGrouped>();
		this.calc = new LoadCalculationGrouped;

		this.grouped = result.grouped;
		this.loads = result.loads;

		this.resultLoads.emit(this.loads);


		var groupedLoads: Map<string, Load[]> = new Map<string, Load[]>();

		this.grouped.forEach(
			(el, index) => {
				//this.calc.set(el.googleMileage, el);

				groupedLoads.set(el.googleMileage, el.loads)
			}
		);


		if(this.grouped[0]) {
			this.calc = this.grouped[0];
		}


		this.resultGrouped.emit(groupedLoads);

		console.log('Calc: ', this.calc);
	}

	public getTimeSeries() {
        this.sharedService.getCoordinates(this.startPickupLocation, this.endDeliveryLocation)
        .subscribe((response: any) => {
            if(response.origin.length > 0 && response.destination.length > 0) {
                let reoute = {
                    pickup: {
                        longitude: Number(response.origin[0].lon),
                        latitude: Number(response.origin[0].lat),
                        miles: this.radius
                    },
                    delivery: {
                        longitude: Number(response.destination[0].lon),
                        latitude: Number(response.destination[0].lat),
                        miles: this.radius
                    },
                    mileage: this.totalRouteMiles 
                }
                this.estimationsService.getLoadsCalculationByRadiusAndTimeSeries(reoute, 'months').subscribe(
                    response => {
                        console.log('Time series service response: ', response); 
                        this.timeSeries.emit(response);
                    },
                    error => {
                        this.errorMessage = <any>error;
                    }
                );
            }
        })
	}

    getZipCodes() {
        this.estimationsService.getZipCodes().subscribe(
            response => {
				this.zipCodes = response;
				this.initMap();
            },
            error => this.errorMessage = <any>error
        );
    }
	
	clearStartLocation() {
		this.startInput.nativeElement.value = '';
	}

    ngAfterViewInit() {

    }


    initMap() {
        const tiles = L.tileLayer(
            "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
            {
              maxZoom: 18,
              attribution:
                '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Points &copy 2012 LINZ'
            }
          ),
          center = L.latLng(41.85, -87.65);
    
        this.map = L.map("map", { 
            center: center, 
            zoom: 5, 
            layers: [tiles] 
        });
	}


	pickupZip = '';
	deliveryZip = '';


	addMarkersToMap() {
		this.markersOnMap = [];

        // locations is array created from json (it's declared in php file)
        for (let i = 0; i < this.locations.length; i++) {

            if (this.zipCodes[this.locations[i].pickup_zip] !== undefined) {

                var lat = +this.zipCodes[this.locations[i].pickup_zip].lat;
				var lng = +this.zipCodes[this.locations[i].pickup_zip].lng;

				this._addMarker(lat, lng, i);
			}		

		 	if (this.zipCodes[this.locations[i].delivery_zip] !== undefined) {
				
                var lat = +this.zipCodes[this.locations[i].delivery_zip].lat;
				var lng = +this.zipCodes[this.locations[i].delivery_zip].lng;
				
				this._addMarker(lat, lng, i);
			}

		}

		// Add a marker clusterer to manage the markers.
		this.markerCluster = new MarkerClusterer(this.map, this.markersOnMap,
			{ imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m' });
		
		google.maps.event.addListener(this.markerCluster, 'clusterclick', function (cluster) {
            var markers = cluster.getMarkers();
        });
	}
	
	_addMarker(lat, lng, index) {
		var marker = new google.maps.Marker({
			position: new google.maps.LatLng(lat, lng),
			map: this.map,
			icon: this.markerIcon,
		});

		google.maps.event.addListener(marker, 'click', ((marker, i) => {
			return () => {
				this._locationClickHandler(this.locations[i], marker);
			}
		})(marker, index)
		);

		
		this.markersOnMap.push(marker);
	}

    _removeAllcircles() {
        for (var i in this.circles) {
            this.circles[i].setMap(null);
        }
        this.circles = []; // this is if you really want to remove them, so you reset the variable.
    }

    _locationClickHandler(info, marker) {
        var html = this._infoWindowRender(info);
        this.map.setZoom(12);
        this.map.setCenter(marker.getPosition());
        this.infowindow.setContent(html);

        this.infowindow.open(this.map, marker);

        this.directionsDisplay.setDirections({ routes: [] });

        if (this._circle) {
            this._circle.setMap(null);
            this._circle = null;
        }
    }

    _infoWindowRender(info) {
        var html = '';
        html += '<h6><b>' + info.load_no + '</b></h6>';
        html += '<p><b>Gross:&nbsp;</b> <span class="">' + info.total_broker + '</span></p>';
        html += '<p><b>Mileage:&nbsp;</b> <span class="">' + info.mileage_loaded + '</span></p>';
        //html += '<button class="infowindow-button-' + info.zip_code + '" (click)="updatePerson()">Click me</button>';

        //html += '<div class="form-group"><label for="comment">Message or Comment:</label><textarea class="form-control" rows="5" id="comment"></textarea></div>';
        //html += '<a href="#" class="btn btn-warning" onclick="locationClick(' + info.email + ');"><i class="fa fa-envelope"></i>' + 'Send Email' + '</a>';
        //html += '<a href="#" class="btn btn-primary pull-right" onclick="locationClick(' + info.email + ');"><i class="fa fa-save"></i>' + 'Save' + '</a>';
        return html;
    }

    _locationListItemRender(index, info) {
        var html = '\n\
            <td align="center">' + info.full_name + '</td>\n\
            <td><a href="tel:' + info.phone + '">' + info.phone + '</a></td>\n\
            <td><a href="mail:' + info.email + '">' + info.email + '</a></td>\n\
            <td align="right">' + '<a href="#" class="w3-button w3-small w3-green w3-disabled" onclick="driverClick(' + index + ');">' + 'Details' + '</a>' + '</td>';
        return html;
    }

    _locationData(index, info) {
        var row = [
            '<b>' + info.full_name + '</b>',
            '<a href="tel:' + info.phone + '">' + info.phone + '</a>',
            '<a href="mail:' + info.email + '">' + info.email + '</a>',
            '<a href="#" class="w3-button w3-small w3-green w3-disabled" onclick="locationClick(' + index + ');">' + 'Email' + '</a>'
        ];
        return row;
    }

    getMiles(m) {
        return (m * 0.000621371192).toFixed(2).toString();
    }

    getHours(mi) {
        return (mi / 63).toFixed(1).toString() + ' hours';
    }

    openList(evt, listName) {
        var i, x, tablinks;
        x = document.getElementsByClassName("list");
        for (i = 0; i < x.length; i++) {
            x[i].style.display = "none";
        }
        tablinks = document.getElementsByClassName("tablink");
        for (i = 0; i < x.length; i++) {
            tablinks[i].className = tablinks[i].className.replace(" w3-deep-orange", "");
        }
        document.getElementById(listName).style.display = "block";
        evt.currentTarget.className += " w3-deep-orange";
    }


    /**
     * Filter locations on map based on selected location and radius
     */

    public radius = 70;
	public slider;

    public setRadius(value) {
		this.radius = value;
		console.info(value);
	}


	loadPickupCircle: MapCircle = new MapCircle();
	loadDeliveryCircle: MapCircle = new MapCircle();
	
	getCloseLocations() {
        var location = this.startInput.nativeElement.value;
		
        this.dataSetIndividual = [];
        var circle;

        var i = 0;
		console.info('radius', this.radius);
		
        if (location == "") {
            this.initMap();
            return false;
        }

        //remove all radii and markers from map before displaying new ones
        if (this.circles.length > 0) {
            this._removeAllcircles();
        }

        if (this.geocoder) {
            this.geocoder.geocode({ 'address': location }, (results, status) => {
                if (status == google.maps.GeocoderStatus.OK) {
                    if (status != google.maps.GeocoderStatus.ZERO_RESULTS) {

                        var address_lat_lng = results[0].geometry.location;
						console.info('address_lat_lng', address_lat_lng.lat());


						this.loadPickupCircle.longitude = address_lat_lng.lng();
						this.loadPickupCircle.latitude = address_lat_lng.lat();

                        circle = new google.maps.Circle({
                            center: address_lat_lng,
                            radius: this.radius * 1609.34,
                            strokeColor: '#000',
                            strokeOpacity: 0.5,
                            strokeWeight: 1,
                            fillColor: '#000',
                            fillOpacity: 0.2,
                            clickable: false,
                            map: this.map
                        });

                        if (circle) {
                            this.map.fitBounds(circle.getBounds(), { padding: [200, 10] });
                        }

                        this.circles.push(circle);

                    } else {
                        alert("No results found while geocoding!");
                    }
                } else {
                    alert("Your imput is incorect, please try again " + status);
                    this.initMap();
                    return false;
                }
			});

        }
	}
	


    getCloseLocationsForRoute(origin: any, destination: any) {
        console.log(origin);
        const circleOrigin = L.circle(origin, {
            color: 'steelblue',
            radius: this.radius * 1609.344,
            fillColor: 'steelblue',
            opacity: 0.5
        }).addTo(this.map);
        
        const circleDestination = L.circle(destination, {
            color: 'steelblue',
            radius: this.radius * 1609.344,
            fillColor: 'steelblue',
            opacity: 0.5
        }).addTo(this.map);
        this.map.fitBounds([circleOrigin.getBounds(),circleDestination.getBounds()]);
	}

	_drawCircleOnMap(address, radius) {
		var circle;

		this.geocoder.geocode({ 'address': address }, (results, status) => {
			if (status == google.maps.GeocoderStatus.OK) {
				if (status != google.maps.GeocoderStatus.ZERO_RESULTS) {

					var address_lat_lng = results[0].geometry.location;
					console.info('address_lat_lng', address_lat_lng.lat());

					circle = new google.maps.Circle({
						center: address_lat_lng,
						radius: radius * 1609.34,
						strokeColor: '#000',
						strokeOpacity: 0.5,
						strokeWeight: 1,
						fillColor: '#000',
						fillOpacity: 0.2,
						clickable: false,
						map: this.map
					});

					if (circle) {
						this.map.fitBounds(circle.getBounds(), { padding: [200, 10] });

						this.circles.push(circle);
					}
				} else {
					alert("No results found while geocoding!");
					return false;
				}
			} else {
				alert("Your imput is incorect, please try again " + status);
				this.initMap();
				return false;
			}
		});
		return
	}
	

	createAmazonAddress(code: any) {
		var obj = this.amazonLocations[code];
		return obj.fc_city + ', ' + obj.fc_state + ', ' + obj.zip_code
	}

    setAddressesHolders() {
		this.pickupLocation = this.pickupInput.nativeElement.value;
		this.deliveryLocation = this.deliveryInput.nativeElement.value;
		
		this.route.origin = this.pickupLocation;


        this.startLocation = this.startInput.nativeElement.value;

        this.setWaypoints();
	}


	stopsFormGroup: FormGroup;

	/**
	 * -------------------------------------------------------------------------
	 * Route stops
	 */	

	@ViewChildren('allTheseThings') things: QueryList<any>;

	get stops() {
		return this.stopsFormGroup.get('stops') as FormArray;
	}

	addStop() {
		let stop = new FormControl('');

		this.stops.push(stop);

		console.log('stop elements: ', this.things);
	}

	removeStop(i: number) {
		this.stops.removeAt(i);
	}

	clearStops() {
		while (this.stops.length !== 0) {
			this.stops.removeAt(0)
		}
	}


	stopWaypoints: string[] = [];

	setStopWaypoints() {
		this.stopWaypoints = [];

		this.things.forEach(
			t => {
				this.stopWaypoints.push(t.nativeElement.value);
			}
		);

		console.log('STOPS ELEMENTS', this.things)
	}
	
    setWaypoints() {
        this.waypoints = [];
        if (this.startLocation !== '') {
            this.origin = this.startLocation;
            var waypoint = this.pickupLocation;
			this.waypoints.push(waypoint);
			this.route.origin = this.origin; // private variable route
        } else {
            this.waypoints = [];
			this.origin = this.pickupLocation;
        }
        this.destination = this.deliveryLocation;
	}
	
    public calculateAndDisplayRoute() {
        console.log(this.stopsFormGroup.value.stops);
		this.setStatus(true);
        let routingPlanArray: any[] = [];
        let addressArray: any[] = JSON.parse(JSON.stringify(this.stopsFormGroup.value.stops));

        if(this.individualLocation) {
            addressArray.unshift(this.individualLocation, this.startPickupLocation);
            addressArray.push(this.endDeliveryLocation);
        }
        else {
            addressArray.unshift(this.startPickupLocation);
            addressArray.push(this.endDeliveryLocation);
        }

        this.sharedService.getMultipleCoordinates(addressArray)
        .subscribe((response: any) => {
            console.log(response);
            for(let key in response) {
                if(response[key].length > 0) {
                    routingPlanArray.push(L.latLng(Number(response[key][0].lat), Number(response[key][0].lon)));
                };
            }
            this.map.remove();
            this.initMap();
            let routeControl: any = L.Routing.control({
            containerClassName: "leaflet-instructions",
            plan: L.Routing.plan(routingPlanArray, {
                createMarker: (i, wp, nWps) => {
                console.log(routingPlanArray);
                if(routingPlanArray.length === 2) {
                    if (i === 0 ) {
                        let myStartIcon = L.icon({
                            iconUrl: './assets/img/location_start.png',
                            iconSize: [34, 34],
                            iconAnchor: [17, 34]
                    });
                        return L.marker(wp.latLng, {icon: myStartIcon });
                    }
                    else {                        
                        let myEndIcon = L.icon({
                            iconUrl: './assets/img/location_end.png',
                            iconSize: [34, 34],
                            iconAnchor: [17, 34]
                        });
                        return L.marker(wp.latLng, {icon: myEndIcon });
                    }
                }
                if(routingPlanArray.length > 2) {
                    if (i === 0) {
                        let myStartIcon = L.icon({
                            iconUrl: './assets/img/location_start.png',
                            iconSize: [34, 34],
                            iconAnchor: [17, 34]
                    });
                        return L.marker(wp.latLng, {icon: myStartIcon });
                    }
                    else if(i === (routingPlanArray.length - 1)) {                        
                        let myEndIcon = L.icon({
                            iconUrl: './assets/img/location_end.png',
                            iconSize: [34, 34],
                            iconAnchor: [17, 34]
                        });
                        return L.marker(wp.latLng, {icon: myEndIcon });
                    }
                    else {                        
                        let middleIcon = L.icon({
                            iconUrl: './assets/img/location.png',
                            iconSize: [34, 34],
                            iconAnchor: [17, 34]
                        });
                        return L.marker(wp.latLng, {icon: middleIcon });
                    }
                }
         
                }
            }),
            routeWhileDragging: true,
            addWaypoints: true,
            showAlternatives: true,
            lineOptions: {
                styles: [{color: '#022bb1', opacity: 0.6, weight: 10}], extendToWaypoints: false, missingRouteTolerance: 0
            },
            altLineOptions: {
            styles: [{color: '#022bb1', opacity: 0.3, weight: 10}], extendToWaypoints: false, missingRouteTolerance: 0,

            },
            }).addTo(this.map);

            L.circle(routingPlanArray[0], {
                color: 'steelblue',
                radius: this.radius * 1609.344,
                fillColor: 'steelblue',
                opacity: 0.5
            }).addTo(this.map);
            
            L.circle(routingPlanArray[routingPlanArray.length -1], {
                color: 'steelblue',
                radius: this.radius * 1609.344,
                fillColor: 'steelblue',
                opacity: 0.5
            }).addTo(this.map);
            
            routeControl.on('routesfound', (e) => {
                let routes = e.routes;
                console.log(routes);
                this.setRouteDetailsTable(routes);
                this.getLoadsCalculation();
            });
            this.map.fitBounds(this.map.getBounds());
        });
		console.log(routingPlanArray);

        //$('#info-container').show();
	}
	
	totalRouteMiles = 0;

    setRouteDetailsTable(route: any) {
		//$('#directions-details-table').show();
        document.getElementById('directions-details-table').style.display = 'block';
        let summaryHtml: any = '';
        let total = 0;
        console.log(route);
        let stageTitle: string = 'Empty';

        let multipleWaypointsTotal: number = 0;
        for (let i = 0; i < route[0].instructions.length; i++) {
            total += route[0].instructions[i].distance;
            multipleWaypointsTotal += route[0].instructions[i].distance;
            if(route[0].instructions[i].type === 'WaypointReached') {  
                summaryHtml += '<tr>';
                summaryHtml += '<td>' + stageTitle + '</td>';
                const miles = this.getMiles(multipleWaypointsTotal);
                summaryHtml += '<td class="right">' + miles + ' mi' + '</td>';
                summaryHtml += '<td class="right">' + this.getHours(miles) + '</td>';
                summaryHtml += '</tr>';
                this.routeDetailsArray.push({stageTitle: stageTitle, miles: miles + ' mi', hours: this.getHours(miles)});
                stageTitle = 'Loaded';
                multipleWaypointsTotal = 0;  
            }
            if(route[0].instructions[i].type === 'DestinationReached') {
                summaryHtml += '<tr>';
                summaryHtml += '<td>' + stageTitle + '</td>';
                const miles = this.getMiles(multipleWaypointsTotal);
                summaryHtml += '<td class="right">' + miles + ' mi' + '</td>';
                summaryHtml += '<td class="right">' + this.getHours(miles) + '</td>';
                summaryHtml += '</tr>';
                this.routeDetailsArray.push({stageTitle: stageTitle, miles: miles + ' mi', hours: this.getHours(miles)});
                multipleWaypointsTotal = 0;  
            }
 
        }

		this.totalRouteMiles = +this.getMiles(total); // TODO: Skloniti ovo van ove view funkcije

		summaryHtml += '<tr>';
        summaryHtml += '<td><b>Total:</b></td>';
        summaryHtml += '<td class=""><b>' + this.getMiles(total) + ' mi' + '</b></td>';
        summaryHtml += '<td class=""><b>' + this.getHours(this.getMiles(total)) + '</b></td>';
        summaryHtml += '</tr>';
        this.routeDetailsArray.push({stageTitle: 'Total:', miles: this.getMiles(total) + ' mi', hours: this.getHours(this.getMiles(total))});            
        console.log(summaryHtml)
		//$('#directions-details-table > tbody').html(summaryHtml);
    }

	public dataTable = [];

    
	
}


class Driver {
    email: string;
    name: string;
}

interface Route {
    origin: string;
    destination: string;
}

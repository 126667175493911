import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { ApexAxisChartSeries, ApexChart, ApexDataLabels, ApexGrid, ApexStroke, ApexXAxis, ApexYAxis, ChartComponent } from 'ng-apexcharts';
import { catchError, Subscription, throwError } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { SharedService } from '@app/modules/shared/services/shared.service';
import { TrucksService } from '@app/modules/trucks/services/trucks.service';
import { TransformService } from '@app/modules/shared/services/transform.service';

export type ChartOptions = {
  series: ApexAxisChartSeries | any;
  chart: ApexChart | any;
  xaxis: ApexXAxis | any;
  yaxis: ApexYAxis | any;
  dataLabels: ApexDataLabels | any;
  grid: ApexGrid | any;
  stroke: ApexStroke | any;
  markers: any;
  colors: any;
};
@Component({
  selector: 'app-empty-trucks-line-chart',
  templateUrl: './empty-trucks-line-chart.component.html',
  styleUrls: ['./empty-trucks-line-chart.component.scss']
})
export class EmptyTrucksLineChartComponent implements OnInit, OnDestroy {

  @ViewChild("chart") chart: ChartComponent | any;
  public chartOptions: Partial<ChartOptions> | any;

  subscription1: Subscription | any;
  subscription2: Subscription | any;

  dateObj: any = {
    startDate: this.transformService.convertDateToTimestamp(moment().startOf('month').format('ddd, DD/MM YYYY'), 
    '00:00:00'),
    endDate: this.transformService.convertDateToTimestamp(moment().format('ddd, DD/MM YYYY'),  moment().format('HH:mm:ss')),
  };

  selectPeriod: FormControl | any;

  graphData: any;

  //Period
  isOpenPeriodMenu: boolean = false;
  menuValue: string = 'By day';
  selectPeriodArray: any[] = [
    {name: 'By year', value: 'year'},
    {name: 'By quarter', value: 'quarter'},
    {name: 'By month', value: 'month'},
    {name: 'By week', value: 'week'},
    {name: 'By day', value: 'day'},
  ];

  selectedCardsArray: any[] = [
    { selected: true },
    { selected: false },
    { selected: false },
    { selected: false },
    { selected: false },
    { selected: false },
    { selected: false }
  ];

  loaded: boolean = false;

  error: boolean = false;

  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";

  spinnerBgColor: string = this.transformService.spinnerBgColor;
  spinnerType: string = this.transformService.spinnerType;

  constructor(private truckService: TrucksService,
    private transformService: TransformService,
    private spinner: NgxSpinnerService) {
    this.selectPeriod = new FormControl(['day']);
}

  ngOnInit(): void {
    this.spinner.show('no-driver-line-chart')
    this.getGraph(false);
    this.activeCardsChanged();
  }

  getGraph(reset: boolean) {
    this.subscription1 = this.truckService.getLineChartData(this.dateObj.startDate, this.dateObj.endDate, this.transformService.filterParams)
    .pipe(catchError((err: any) => {
      this.spinner.hide('no-driver-line-chart');
      this.loaded = true;
      this.error = true;
      return throwError(() => err);
    }))
    .subscribe(
      (response: any) => {
        reset ? this.graphData = [] : this.graphData;
        this.graphData = this.transformService.countDataByPeriodTrucks(response, this.selectPeriod.value[0], this.selectedCardsArray);
        this.selectedCards();
        this.spinner.hide('no-driver-line-chart');
        this.loaded = true;
        console.log(response)
        console.log(this.graphData)
      })
  }

  activeCardsChanged() {
    this.subscription2 = this.truckService.selectedCardSubject.subscribe((response: any) => {
      this.selectedCardsArray = response.activeCards;
      this.selectedCards(response);
    })
  };

  selectedCards(newSelectionArray?: any) {
    let categories: any = this.graphData.categories;
    let arrayData: any[] = [];

    let obj: any = { categories: categories, arrayData: arrayData };

    for (let key in this.graphData.graphArray) {
      if (newSelectionArray) {
        this.graphData.graphArray[key].selected = newSelectionArray.activeCards[key].selected;
      }
      if (this.graphData.graphArray[key].selected) {
        arrayData.push(this.graphData.graphArray[key]);
      }
    }
    this.initGraph(obj);
  };

  initGraph(obj: any) {
    this.chartOptions = {
      series: obj.arrayData,
      chart: {
        toolbar: {
          show: true,
          tools: {
            download: false
          },
          animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 800,
            animateGradually: {
                enabled: true,
                delay: 150
            },
            dynamicAnimation: {
                enabled: true,
                speed: 350
            }
          }
        },
        height: 350,
        type: "line",
        zoom: {
          enabled: false
        },
        fontFamily: 'Poppins'
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: "straight",
        width: 1,
      },
      grid: {
        row: {
          colors: ["transparent", "transparent"],
          opacity: 0.5
        }
      },
      xaxis: {
        categories: obj.categories
      },
      yaxis: {
        logarithmic: true,
        logBase: 10,
        min: 0.1,
        forceNiceScale: true,
        tooltip: {
          enabled: true
        },
        labels: {
          formatter: (num: number) => {
            let result = (num - Math.floor(num)) !== 0;
            let number;
            if (result) {
              number = Math.round(num);
            } else {
              number = num;
            }
            return number.toLocaleString("en-US").toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          },
        },
      },
      markers: {
        size: 5
      },
      colors: ['#0030FF', '#FA9120', '#FA0000', '#008000', '#FF00FF', '#800000', '#FE7777', '#0303BB', '#008FA9', '#7550CB', '#6A6D6E'],
      tooltip: {
        enabled: true,
        inverseOrder: false,
        style: {
          fontSize: '12px'
        },
      }
    };
  }

  //Calendar dialog
  getDate(result: any) {
    this.dateObj.startDate = result.startDate;
    this.dateObj.endDate = result.endDate;
    this.graphData = undefined;
    this.error = false;
    this.loaded = false;
    this.spinner.show('no-driver-line-chart');
    this.getGraph(true);
  }

  onlyOneSelect(value: string, obj: any) {
    this.menuValue = obj.name;
    this.selectPeriod = new FormControl([value]);
    let lineChartData: any = JSON.parse(JSON.stringify(this.graphData));
    this.graphData = this.transformService.countDataByPeriodTrucks(lineChartData.data, this.selectPeriod.value[0], this.selectedCardsArray);
    this.selectedCards();
    this.isOpenPeriodMenu = false;
  };

  ngOnDestroy(): void {
    this.subscription1?.unsubscribe();
    this.subscription2?.unsubscribe();
  }
}

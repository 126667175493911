import { Component, OnInit, Input  } from '@angular/core';
import { RecruitingService } from '../../services/recruiting.service';

@Component({
  selector: 'app-empty-truck-cards',
  templateUrl: './empty-truck-cards.component.html',
  styleUrls: ['./empty-truck-cards.component.scss']
})
export class EmptyTruckCardsComponent implements OnInit {
  @Input() noOfTrucks: any;
  trucksReadyLength: any;
  trucksReadySoonLength: any;
  trucksRepairLength: any;

  @Input() date: any;

  readyArr: any[] = [];
  readySoonArr: any[] = [];
  repairsArr: any[] = [];

  constructor(private dataService: RecruitingService) { }

  ngOnInit(): void {
    this.getEmptyTrucksData(this.date);
  }

  getEmptyTrucksData(dateObj: any) {
    this.dataService.getEmptyTrucks(dateObj.startDate, dateObj.endDate).subscribe(
      (response: any) => {
        this.readyArr = response.data.ready;
        this.trucksReadyLength = response.data.ready.length;

        this.readySoonArr = response.data.ready_soon;
        this.trucksReadySoonLength = response.data.ready_soon.length;

        this.repairsArr = response.data.repair;
        this.trucksRepairLength = response.data.repair.length;
      })
  }
}

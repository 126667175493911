import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { DriversUnderperformingRoutingModule } from './drivers-underperforming.routing';
import { DashboardUnderperformingDriversPageComponent } from './components/dashboard-underperforming-drivers-page.component';
import { ActionMenuComponent } from './components/underperforming-drivers-table/action-menu/action-menu.component';
import { UnderperformingDriversTableComponent } from './components/underperforming-drivers-table/underperforming-drivers-table.component';
import { UnderperformingWeekDialogComponent } from './components/underperforming-drivers-table/underperforming-week-dialog/underperforming-week-dialog.component';
import { DriversUnderperformingService } from './services/drivers-underperforming.service';

@NgModule({
  declarations: [
    DashboardUnderperformingDriversPageComponent,
    UnderperformingDriversTableComponent,
    ActionMenuComponent,
    UnderperformingWeekDialogComponent
  ],
  imports: [
    CommonModule,
    DriversUnderperformingRoutingModule,
    SharedModule
  ],
  providers: [DriversUnderperformingService]
})
export class DriversUnderperformingModule { }

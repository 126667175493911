import { Component } from '@angular/core';

const ELEMENT_DATA: any[] = [
  {driverName: 'Frederick Robinson', duration: '01:06:21', from: '01.12.2022', to: 'Currently assigned', gross: 23000, rpm: 2.21,
  fuelConsumption: 6.54, idling: '12:17h', maintenanceCost: 16320, loadMileage: 21260, loaded: 78, netProfit: 16655},
  {driverName: 'James Joplin', duration: '01:06:21', from: '01.12.2022', to: '21.11.2022', gross: 23000, rpm: 2.21,
  fuelConsumption: 6.54, idling: '12:17h', maintenanceCost: 16320, loadMileage: 21260, loaded: 78, netProfit: 16655},
  {driverName: 'James Joplin', duration: '01:06:21', from: '01.12.2022', to: '21.11.2022', gross: 23000, rpm: 2.21,
  fuelConsumption: 6.54, idling: '12:17h', maintenanceCost: 16320, loadMileage: 21260, loaded: 78, netProfit: 16655},
  {driverName: 'James Joplin', duration: '01:06:21', from: '01.12.2022', to: '21.11.2022', gross: 23000, rpm: 2.21,
  fuelConsumption: 6.54, idling: '12:17h', maintenanceCost: 16320, loadMileage: 21260, loaded: 78, netProfit: 16655},
  {driverName: 'James Joplin', duration: '01:06:21', from: '01.12.2022', to: '21.11.2022', gross: 23000, rpm: 2.21,
  fuelConsumption: 6.54, idling: '12:17h', maintenanceCost: 16320, loadMileage: 21260, loaded: 78, netProfit: 16655},
];

@Component({
  selector: 'app-truck-profile-drivers-dispatchers-table',
  templateUrl: './truck-profile-drivers-dispatchers-table.component.html',
  styleUrls: ['./truck-profile-drivers-dispatchers-table.component.scss']
})
export class TruckProfileDriversDispatchersTableComponent {
  drivers: boolean = false;
  displayedColumns: string[] = ['position', 'driverName', 'duration', 'from', 'to', 'gross', 'rpm', 'fuelConsumption',
  'idling', 'maintenanceCost', 'loadMileage', 'loaded', 'netProfit'];
  dataSource = ELEMENT_DATA;

  //Popup
  isOpened: boolean = false;

}

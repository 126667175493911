import { Component, OnDestroy, OnInit } from '@angular/core';
import { IftaService } from '../../services/ifta.service';
import { Subscription, catchError, throwError } from 'rxjs';
import { Sort } from '@angular/material/sort';
import moment = require('moment');
import { TransformService } from '@app/modules/shared/services/transform.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { HttpEventType } from '@angular/common/http';
import { WarningMsgDialogComponent } from '@app/modules/shared/components/warning-msg-dialog/warning-msg-dialog.component';
import { RulesService } from '@app/modules/shared/services/rules.service';
import { MsgForbbidenAccessComponent } from '@app/modules/shared/components/msg-forbbiden-access/msg-forbbiden-access.component';

@Component({
  selector: 'app-dashboard-ifta-irp-page',
  templateUrl: './dashboard-ifta-irp-page.component.html',
  styleUrls: ['./dashboard-ifta-irp-page.component.scss']
})
export class DashboardIftaIrpPageComponent implements OnInit, OnDestroy {
  permissions: any = this.rulesService.UserData[33].data;

  //Data source
  dataSource: any[] = [];

  //Trucks
  trucksArray: any[] = [];
  isOpenTruckMenu: boolean = false;
  truckSearch: string = '';

  //Report
  reportArray: any[] = [{name: 'IFTA & IRP PING Report'}, {name: 'IFTA Ping Report'}, {name: 'IRP Ping Report'}];
  isOpenReportMenu: boolean = false;

  //Date
  isOpenFromCalendar: boolean = false;
  isOpenToCalendar: boolean = false;

  //Filter obj
  filterObj: any = {
    trucks: [],
    reportValue: '',
    reportIndex: null,
    fromDate: '',
    toDate: ''
  };

  //Progress
  progressDone: number = 0;
  showProgress: boolean = false;

  //Subscription
  subscription1: Subscription = new Subscription();
  subscription2: Subscription = new Subscription();

  loaded: boolean = true;
  error: boolean = false;
  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";

  spinnerBgColor: string = this.transformService.spinnerBgColor;
  spinnerType: string = this.transformService.spinnerType;

  constructor(public transformService: TransformService,
              private dialog: MatDialog, 
              private iftaService: IftaService,
              private spinner: NgxSpinnerService,
              private rulesService: RulesService) { }

  ngOnInit(): void {
    this.getAllTrucks();
  };

  getAllTrucks() {
    this.subscription1 = this.iftaService.getAllTrucksData().subscribe((response: any) => {
      this.trucksArray = response;
      console.log(response);
    });
  };

  getTableData() {
    this.error = false;
    this.loaded = false;
    this.dataSource = [];
    this.spinner.show('ifta-irp-table');
    this.subscription2 = this.iftaService.getTableData(this.filterObj.trucks.toString(), this.filterObj.fromDate, this.filterObj.toDate, this.filterObj.reportIndex)
    .pipe(catchError((err: any) => {
      this.spinner.hide('ifta-irp-table');
      this.loaded = true;
      this.error = true;
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      console.log(response);
      this.dataSource = response;
      this.spinner.hide('ifta-irp-table');
      this.loaded = true;
    });
  };

  //Date
  dateClicked(event: any, isFrom: boolean) {
    if(isFrom) {
      this.filterObj.fromDate = moment(event._d).format('YYYY-MM-DD');
      this.isOpenFromCalendar = false;
    }  
    else {
      this.filterObj.toDate = moment(event._d).format('YYYY-MM-DD');
      this.isOpenToCalendar = false;
    };
  }

  //Select all trucks
  selectUnselectAllTrucks() {
    if(this.filterObj.trucks.length > 0) {
      this.filterObj.trucks = [];
    }
    else {
      for(let i = 0; i < this.trucksArray.length; i++) {
        this.filterObj.trucks.push(this.trucksArray[i].unit_no);
      };
    }
  };

  //Check uncheck value
  checkUncheckValue(unitNo: string) {
    let index = this.filterObj.trucks.indexOf(unitNo);
    if (index === -1) {
      this.filterObj.trucks.push(unitNo);
    } 
    else {
      this.filterObj.trucks.splice(index, 1);
    }
  };

  sortData(sort: Sort) {
    const data = JSON.parse(JSON.stringify(this.dataSource));
    if (!sort.active || sort.direction === '') {
      this.dataSource = data;
      return;
    }
    this.dataSource = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'Name':
          return compare(a.Name, b.Name, isAsc);
        case 'odometer':
          return compare(a.odometer, b.odometer, isAsc);
        case 'Latitude':
          return compare(a.Latitude, b.Latitude, isAsc);
        case 'Longitude':
          return compare(a.Longitude, b.Longitude, isAsc);
        case 'UTCTimestamp':
          return compare(new Date(a.UTCTimestamp), new Date(b.UTCTimestamp), isAsc);
        default:
          return 0;
      }
    });
  };

  run() {
    this.getTableData();
  };

  exportData() {
    if(this.permissions[0].sectionArray[0].allowed) {
      this.iftaService.getZipData(this.filterObj.trucks.toString(), this.filterObj.fromDate, this.filterObj.toDate, this.filterObj.reportIndex)
      .pipe(catchError((err: any) => {
        this.showProgress = false;
        this.progressDone = 0;
        this.dialog.open(WarningMsgDialogComponent, {
          autoFocus: false,
          panelClass: 'warning-msg-dialog-container'
        });
        return throwError(() => err);
      }))
      .subscribe((httpResponse: any) => {
        this.showProgress = true;
        if (httpResponse.type === HttpEventType.Response) {
          this.showProgress = false;
          this.progressDone = 0;
          const anchor = document.createElement('a')
          const objectUrl = URL.createObjectURL(httpResponse.body)
          anchor.href = objectUrl
          anchor.download =  this.filterObj.trucks.length === 1 ? `${this.filterObj.trucks[0]}.xlsx` : 'archive.zip';
          anchor.click();
          URL.revokeObjectURL(objectUrl);
        }
        if (httpResponse.type === HttpEventType.DownloadProgress) {
          this.progressDone = Math.round(100 * httpResponse.loaded / httpResponse.total);
        } 
      });
    }
    else {
      this.dialog.open(MsgForbbidenAccessComponent, {
        autoFocus: false,
        panelClass: 'forbidden-msg-dialog-container'
      })
    }
  };

  //Reset filters
  resetFilters() {
    this.dataSource = [];
    this.truckSearch = '';
    this.filterObj.trucks = [];
    this.filterObj.reportValue = '';
    this.filterObj.reportIndex = null;
    this.filterObj.fromDate = '';
    this.filterObj.toDate = '';
  };

  ngOnDestroy(): void {
    this.subscription1?.unsubscribe();
    this.subscription2?.unsubscribe();
  };

}

function compare(a: any, b: any, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ticketStatus'
})
export class TicketStatusPipe implements PipeTransform {

  transform(array: any[], value: string): any[] {
    if (!array) {
      return [];
    }
 
    return array.filter((obj: any) => {
      let key1: boolean = false;

      if(obj.status === value || value === 'All') {
        key1 = true;
      }   

      if(key1) { 
        return obj;
      }

    });

  }

}

import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ApexAxisChartSeries, ApexPlotOptions, ApexChart, ApexLegend, ApexResponsive, ChartComponent } from 'ng-apexcharts';

export type ChartOptions = {
  series: ApexAxisChartSeries | any;
  colors: string | any;
  plotOptions: ApexPlotOptions;
  chart: ApexChart | any;
  labels: string | any;
  legend: ApexLegend | any;
  responsive: ApexResponsive | any;
};

@Component({
  selector: 'truck-activity-pie-chart',
  templateUrl: './truck-activity-pie-chart.component.html',
  styleUrls: ['./truck-activity-pie-chart.component.scss']
})
export class TruckActivityPieChartComponent implements OnInit {
  @ViewChild("chart") chart: ChartComponent | any;
  public chartOptions: Partial<ChartOptions> | any;
  @Input() data: any;

  constructor() { }

  ngOnInit(): void {
    this.chartOptions = this.pieChart(this.data);
  }

  pieChart(data: any) {
    return {
      colors: ['#ff1515', '#fffc15', '#00be62', '#1c6f46', '#0030FF', '#FF00FF', '#800000', '#6A6D6E', '#7550CB'],
      series: [data.total_trucks, data.total_trucks_with_driver, data.total_trucks_no_driver, 
      (data.dealer_trucks + data.repair_trucks + data.new_trucks), data.total_spare_trucks, data.total_recoveries_trucks,
      data.total_actve_trucks, data.hometime_trucks, data.loads_per_week],
      plotOptions: {
        pie: {
          dataLabels: {
            offset: -33,
          }, 
        }
      },
      chart: {
        width: 400,
        type: "pie",
        fontFamily: 'Poppins'
      },
      labels: ['TOTAL TRUCKS', 'TRUCKS WITH DRIVERS', 'NO DRIVER', 'DEALER / REPAIR / NEW', 'SPARE TRUCKS', 'RECOVERIES', 'ACTIVE TRUCKS', 'HOME TIME', 'LOADS PER WEEK'],
      legend: {
        position: 'bottom'
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: "bottom"
            }
          }
        }
      ]
    };
    
  };

}
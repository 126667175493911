import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { InspectionDetailsComponent } from '../inspection-details/inspection-details.component';
import { TransformService } from '@app/modules/shared/services/transform.service';
import { Status, Inspection } from '../../models/inspection.model';
import { InspectionsService } from '../../services/inspections.service';
import { Subscription } from 'rxjs';

interface InspectionSummary {
  points: {
    total: number;
    avg: any;
  };
  amount: {
    total: number;
    avg: number;
  };
  rewards: {
    total: number;
    avg: number;
  };
  all: {
    total: number;
  };
}

@Component({
  selector: 'app-inspection-list',
  templateUrl: './inspection-list.component.html',
  styleUrls: ['./inspection-list.component.scss', '../inspections-main-page/inspections-main-page.component.scss']
})
export class InspectionListComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = ['position', 'driver', 'truck_no', 'inspection_result',
    'inspection_level', 'amount', 'rewards', 'points', 'date', 'out_of_service', 'city', 'state', 'original', 'sent', 'related', 
    'button'];
  @Input() obj: any;
  statuses = Status;

  sortColumn: keyof Inspection | undefined;
  sortDirection: number = 1;
  searchInput = '';
  filteredData: Inspection[] = [];
  originalData: Inspection[] = [];

  inspectionSummary: InspectionSummary = {
    points: { total: 0, avg: 0 },
    amount: { total: 0, avg: 0 },
    rewards: { total: 0, avg: 0 },
    all: { total: 0 }
  };

  subscription: Subscription = new Subscription();

  constructor(
    public service: InspectionsService,
    public transformService: TransformService,
    private dialog: MatDialog)
    { }

  ngOnInit() {
    this.getData();
  };

  getData() {
    this.subscription = this.service.data$.subscribe((response: any) => {
      let data = [...response];
      data.sort((a: any, b: any) => {
        return <any>new Date(b.date) - <any>new Date(a.date);
      });
      this.filteredData = data;
      this.originalData = data;
      this.generateInspectionSummary();
    });
  };

  private generateInspectionSummary() {
    this.inspectionSummary = {
      points: { total: 0, avg: 0 },
      amount: { total: 0, avg: 0 },
      rewards: { total: 0, avg: 0 },
      all: { total: 0 }
    };

    if (this.filteredData) {
      this.filteredData.forEach(item => {
        this.inspectionSummary.points.total += item.points;
        this.inspectionSummary.amount.total += item.amount;
        this.inspectionSummary.rewards.total += item.rewards;
      });
    }

    const totalCount = this.filteredData.length;
    this.inspectionSummary.points.avg = (this.inspectionSummary.points.total / totalCount).toFixed(1)
    this.inspectionSummary.amount.avg = this.inspectionSummary.amount.total / totalCount;
    this.inspectionSummary.rewards.avg = this.inspectionSummary.rewards.total / totalCount;
    this.inspectionSummary.all.total = totalCount;
  }

  roundToOneDecimalPlace(num: number): number {
    return Math.round(num * 10) / 10;
  }

  openDialog(data: any, editMode: boolean) {
    this.dialog.open(InspectionDetailsComponent, {
      autoFocus: false,
      panelClass: 'inspections',
      data: { inspectionData: data, editMode: editMode },
    });

  }

  applySearch() {
    const filteredData = this.originalData.filter((item: any) => {
      const dateFormatted = this.formatDateFromString(item.date);

      return (
        dateFormatted.toLowerCase().includes(this.searchInput.toLowerCase()) ||
        Object.keys(item).some((key) => {
          const value = item[key];
          if (typeof value === "string" && value.toLowerCase().includes(this.searchInput.toLowerCase())) {
            return true;
          } else if (typeof value === "boolean") {
            const outOfServiceStatus = value ? "OOS" : "Driving";
            return outOfServiceStatus.toLowerCase().includes(this.searchInput.toLowerCase());
          }
          return false;
        })
      );
    });

    this.filteredData = filteredData;
    this.generateInspectionSummary();
  }

  formatDateFromString(dateString: string) {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
  }

  getRelatedTooltipText(element: any): string {
    return element.related.map(item => item.related).join(', ');
  }

  //Hover
  className: string = '';
  columnName: string = '';

  //Hover effect
  onMouseOver(columnName: string) {
    this.columnName = columnName;
    this.className = 'hovered-column';
  }

  onMouseOut(columnName: string) {
    this.columnName = columnName;
    this.className = '';
  }

  sortData(sort: Sort) {
    const data = this.filteredData.slice();
    if (!sort.active || sort.direction === '') {
      this.filteredData = data;
      return;
    }
    this.filteredData = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'driver':
          return compare(a.driver_name, b.driver_name, isAsc);
        case 'dispatcher':
          return compare(a.dispatcher, b.dispatcher, isAsc);
        case 'truck_no':
          return compare(a.truck_no, b.truck_no, isAsc);
        case 'inspection_result':
          return compare(a.inspection_result, b.inspection_result, isAsc);
        case 'inspection_level':
          return compare(a.inspection_level, b.inspection_level, isAsc);
        case 'amount':
          return compare(a.amount, b.amount, isAsc);
        case 'rewards':
          return compare(a.rewards, b.rewards, isAsc);
        case 'points':
          return compare(a.points, b.points, isAsc);
        case 'date':
          return compare(a.date, b.date, isAsc);
        case 'out_of_service':
          return compare(a.out_of_service, b.out_of_service, isAsc);
        case 'state':
          return compare(a.state, b.state, isAsc);
        case 'original':
          return compare(a.original, b.original, isAsc);
        case 'sent':
          return compare(a.sent, b.sent, isAsc);
        case 'related':
          const aValue = a.related && a.related.length > 0 ? a.related[0].related : null;
          const bValue = b.related && b.related.length > 0 ? b.related[0].related : null;
          return compare(aValue, bValue, isAsc);
        default:
          return 0;
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  };

}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}



import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'accountingBoardFilter'
})
export class AccountingBoardFilterPipe implements PipeTransform {

  transform(array: any[], searchValuesArray: any[], keyArray: any[], objNumValue: any[], dateObj: any): any[] {
    if (!array) {
      return [];
    }

    return array.filter((obj: any) => {
      let key1: boolean = obj[keyArray[0]].toLocaleLowerCase().startsWith(searchValuesArray[0].toLocaleLowerCase());
      let key2: boolean = obj[keyArray[1]].toLocaleLowerCase().startsWith(searchValuesArray[1].toLocaleLowerCase());
      let key3: boolean = obj[keyArray[2]].toLocaleLowerCase().startsWith(searchValuesArray[2].toLocaleLowerCase());

      let key4: boolean =  obj[keyArray[3]].toLocaleLowerCase().startsWith(searchValuesArray[3].toLocaleLowerCase());
      let key5: boolean =  obj[keyArray[4]].toLocaleLowerCase().startsWith(searchValuesArray[4].toLocaleLowerCase());
      let key6: boolean =  obj[keyArray[5]].toLocaleLowerCase().startsWith(searchValuesArray[5].toLocaleLowerCase());
      let key7: boolean =  checkDate('puObj', 'pickup_time');
      let key8: boolean =  checkDate('delObj', 'delivery_time');
      let key9: boolean =  checkDate('invoiceObj', 'invoice_date');
      let key10: boolean =  obj[keyArray[6]].toLocaleLowerCase().includes(searchValuesArray[6].toLocaleLowerCase());
      let key11: boolean =  obj[keyArray[7]].toLocaleLowerCase().startsWith(searchValuesArray[7].toLocaleLowerCase());
      let key12: boolean =  obj[keyArray[8]].toLocaleLowerCase().startsWith(searchValuesArray[8].toLocaleLowerCase());
      let key13: boolean = obj[keyArray[9]].toLocaleLowerCase().startsWith(searchValuesArray[9].toLocaleLowerCase());
      let key14: boolean = obj[keyArray[10]].toLocaleLowerCase().startsWith(searchValuesArray[10].toLocaleLowerCase());
      let key15: boolean = checkCheckbox('Both', 'Checked', obj.info._checked, 11);
      let key16: boolean = checkCheckbox('Both', 'Yes', obj.tonu, 12);
      let key17: boolean = checkNote('Both', 'With note', obj.info.note, 13);
      let key18: boolean = checkRadioBtns(0);
      let key19: boolean = checkRadioBtns(1);

      //Date filter
      function checkDate(key: string, key2: string) {
        if(dateObj[key]) {
          const start = Date.parse(dateObj[key].start._d);
          const end = Date.parse(dateObj[key].end._d);
          const d = Date.parse(obj[key2]);
          return d.valueOf() >= start.valueOf() && d.valueOf() <= end.valueOf();
        }
        return true;
      }

      //Checkbox filter
      function checkCheckbox(filterValue: string, filterValue2: string, property: boolean, index: number) {
        let key: boolean = true;
        if(searchValuesArray[index] === filterValue) {
          key = true;
        }
        else if(searchValuesArray[index] === filterValue2) {
          key = property === true;
        }
        else {
          key = property === false;
        }
        return key;
      }

      function checkNote(filterValue: string, filterValue2: string, property: any, index: number) {
        let key: boolean = true;
        if(searchValuesArray[index] === filterValue) {
          key = true;
        }
        else if(searchValuesArray[index] === filterValue2) {
          key = property ? property.length > 0 : false;
        }
        else {
          key = property ? property.length === 0 : true;
        }
        return key;
      }

      //Check radion btn
      function checkRadioBtns(index: number) {
        if(objNumValue[index].searchValue) {
          let key: boolean | undefined = undefined;
          if(objNumValue[index].operator === 'More than') {
            key = Number(obj[objNumValue[index].key]) > Number(objNumValue[index].searchValue);
          }
          if(objNumValue[index].operator === 'Less than') {
            key = Number(obj[objNumValue[index].key]) < Number(objNumValue[index].searchValue);
          }
          if(objNumValue[index].operator === 'Equal') {
            key = Number(obj[objNumValue[index].key]) == Number(objNumValue[index].searchValue);
          }
          return key;
        }
        else {
          return true;
        }
      };

      if(key1 && key2 && key3 && key4 && key5 && key6 && key7 && key8 && key9 && key10 
      && key11 && key12 && key13 && key14 && key15 && key16 && key17 && key18 && key19) { 
        return obj;
      };

    });

  }

}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardDispatchLiveBoardPageComponent } from './components/dashboard-dispatch-live-board-page.component';
import { AuthGuard } from '../core/services/auth/auth.guard';

const routes: Routes = [
	{
		path: 'dispatchers',
		//canActivate: [AuthGuard],
        children: [
          {
            path: 'dashboard-dispatch-live-board',
            canActivate: [AuthGuard],
            component: DashboardDispatchLiveBoardPageComponent,
            data: { roles: 2 }
          }
		]
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class DispatcherLiveBoardRoutingModule { }

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-create-update-note-dialog',
  templateUrl: './create-update-note-dialog.component.html',
  styleUrls: ['./create-update-note-dialog.component.scss']
})
export class CreateUpdateNoteDialogComponent implements OnInit {
  note: string = '';

  constructor(@Inject(MAT_DIALOG_DATA) public infoNote: string,  public dialogRef: MatDialogRef<CreateUpdateNoteDialogComponent>) { }

  ngOnInit(): void {
    this.note = this.infoNote;
  }

  saveData() {
    this.dialogRef.close(this.note);
  };

}

import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-warning-contract-dialog',
  templateUrl: './warning-contract-dialog.component.html',
  styleUrls: ['./warning-contract-dialog.component.scss']
})
export class WarningContractDialogComponent implements OnInit {
  @ViewChild('scrollMe') private myScrollContainer: ElementRef;
  categoryName: string = '';

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<WarningContractDialogComponent>) { }

  ngOnInit(): void {
    this.data.categories.forEach(element => {
      if(element.ID === this.data.obj.categoryID) {
        this.categoryName = element.categoryName;
      }
    });
  }

  //Scroll
  public scrollBottom(): void {
    this.myScrollContainer.nativeElement.scrollTo(
    { top: (this.myScrollContainer.nativeElement.scrollTop + 120 ), behavior: 'smooth' });
  }

  public scrollTop(): void {
    this.myScrollContainer.nativeElement.scrollTo(
      { top: (this.myScrollContainer.nativeElement.scrollTop - 120), behavior: 'smooth' });
  }

  discard() {
    this.dialogRef.close(false);
  }

  confirm() {
    this.dialogRef.close(true);
  };
  
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription, catchError, throwError } from 'rxjs';
import * as L from 'leaflet';
import { MatDialog } from '@angular/material/dialog';
import { TruckInfoDialogComponent } from './truck-info-dialog/truck-info-dialog.component';
import { TrucksMapService } from '../../services/trucks-map.service';
import { TransformService } from '@app/modules/shared/services/transform.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-trucks-map-page',
  templateUrl: './trucks-map-page.component.html',
  styleUrls: ['./trucks-map-page.component.scss']
})
export class TrucksMapPageComponent implements OnInit, OnDestroy {
	//Asset search
	assetSearch: string = '';

	//Filter
	filterArray: any[] = [
		{name: 'Trucks'},
		{name: 'Trailers'},
		{name: 'Both'},
	];
	isOpenFilterMenu: boolean = false;
	filterValue: string = 'Both';

	//Trucks by dispatchers
	trucksByDispatchers: string[] = [];
	
	//Map
	private map: any;
	
	//Data array
	dataArray: any[] = [];

	//Trucks trailers
	trucksTrailersLatLngObj: any = {};

	//Dispatchers
	dispatchersArray: any[] = [];
	dispatchersSearch: string = '';
	isOpenDispatcherMenu: boolean = false;
	
	//Subscription
	subscription1: Subscription = new Subscription();
	subscription2: Subscription = new Subscription();

	loaded: boolean = false;
	error: boolean = false;
	errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";

	spinnerBgColor: string = this.transformService.spinnerBgColor;
	spinnerType: string = this.transformService.spinnerType;
	
	constructor(private trucksMapService: TrucksMapService,
				public transformService: TransformService,
				private dialog: MatDialog,
				private spinner: NgxSpinnerService) { }
	
	ngOnInit(): void {
		this.getMapData();
		this.getTrucksByDispatchers();
	};
	
	getMapData() {
		this.spinner.show('trucks-map-page');
		this.subscription1 = this.trucksMapService.getMapData()
		.pipe(catchError((err: any) => {
		this.spinner.hide('trucks-map-page');
		this.loaded = true;
		this.error = true;
		return throwError(() => err);
		}))
		.subscribe((response: any) => {
	
		let numOfIterations: number = response.truckData.length >= response.trailerData.length ? response.truckData.length : response.trailerData.length;
		const trucksArray: any[] = [];
		const trailersArray: any[] = [];
		for(let i = 0; i < numOfIterations; i++) {
			if(i < response.truckData.length) {
				response.truckData[i].isTruck = true;
				response.truckData[i].firstKey = response.truckData[i].truck_name;
				response.truckData[i].secondKey = `${response.truckData[i].fuel}%`;
				response.truckData[i].thirdKey = response.truckData[i].address_display_name;
				response.truckData[i].fourthKey = `${this.transformService.addCommasDots(response.truckData[i].odometer * 0.6213711922, 'round')} mi`;

				response.truckData[i].firstIcon = '../../../../../assets/img/truck-front-blue.png';
				response.truckData[i].secondIcon = 'local_gas_station';
				response.truckData[i].thirdIcon = 'my_location';
				response.truckData[i].fourthIcon = 'speed';

				trucksArray.push(response.truckData[i]);
			}
			if(i < response.trailerData.length) {
				response.trailerData[i].isTruck = false;
				response.trailerData[i].firstKey = response.trailerData[i].trailer_name;
				response.trailerData[i].secondKey = `${this.transformService.addCommasDots(response.trailerData[i].batteryVoltage, 'round')}%`;
				response.trailerData[i].thirdKey = response.trailerData[i].address_display_name;
				response.trailerData[i].fourthKey = `${response.trailerData[i].make} ${response.trailerData[i].model}`

				response.trailerData[i].firstIcon = '../../../../../assets/img/trailer-driver-blue.png';
				response.trailerData[i].secondIcon = 'battery_charging_full';
				response.trailerData[i].thirdIcon = 'my_location';
				response.trailerData[i].fourthIcon = 'edit';
				trailersArray.push(response.trailerData[i]);
			}
		};
		this.dataArray = trucksArray.concat(trailersArray);
		this.initMap();
		this.setMarkersOnMap(this.dataArray);
		this.spinner.hide('trucks-map-page');
		this.loaded = true;
		console.log(this.dataArray);
		});
	}
	private initMap(): void {
			this.map = L.map('map', {
			center: [37.920746, -91.020687],
			zoom: 5,
			});
		
			const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
			maxZoom: 18,
			minZoom: 3,
			attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
			});
			tiles.addTo(this.map);
	};
	
	setMarkersOnMap(array: any[]) {
		for (let i = 0; i < array.length; i++) {
			this.trucksTrailersLatLngObj[array[i].firstKey] = {lat: array[i].latitude, lng: array[i].longitude}
			let markerIcon: any;
			if(array[i].isTruck) {
				markerIcon = {
					icon: new L.DivIcon({
						className: 'my-div-icon',
						html: `<div style="background-color: #2196F3; color: #fff; text-align: center; padding: 3px; font-size: 15px; font-weight: bolder; border-radius: 10px; font-family: 'Poppins';">${ array[i].firstKey }</div>
						<img style="width: 48px; height: 48px;" src="./assets/img/location-pin-truck.png"/>`,
						iconSize: [48, 48],
						iconAnchor: [24, 48]
					})
				}
			}
			else {
				markerIcon = {
					icon: new L.DivIcon({
						className: 'my-div-icon',
						html: `<div style="width: 70px; background-color: #1F1F1F; color: #fff; text-align: center; padding: 3px; font-size: 15px; font-weight: bolder; border-radius: 10px; font-family: 'Poppins';">${ array[i].firstKey }</div>
						<img style="width: 36px; height: 36px; margin-left: 17px; margin-top: 3px;" src="./assets/img/square-location-pin.png"/>`,
						iconSize: [36, 36],
						iconAnchor: [18, 36]
					})
				}
			}

			const marker = L.marker([+array[i].latitude, +array[i].longitude], markerIcon);
			marker.addTo(this.map).on('click', (e) => {
				this.dialog.open(TruckInfoDialogComponent, {
					autoFocus: false,
					panelClass: 'truck-info-dialog-container',
					data: {isTruck: array[i].isTruck ? 1 : 0, assetName: array[i].firstKey}
				})
			});;
		}
	};

	
	getTrucksByDispatchers() {
		this.subscription2 = this.trucksMapService.getTrucksByDispatchers().subscribe((response: any) => {
			for(let i = 0; i < response.length; i++) {
				response[i].displayName = response[i]?.lastName?.split('/')[1].trim();
			}
			this.dispatchersArray = response;
			console.log(response);
		});
	};

	filterTrucksByDispatcher(trucksArray: string[]) {
		this.trucksByDispatchers = trucksArray;
		this.assetSearch = '';
		this.filterValue = 'Trucks';
		this.isOpenDispatcherMenu = false;
		this.filterDataOnMap();
	};

	filterDataOnMap() {
		let filteredArray: any[] = [];
		for(let i = 0; i < this.dataArray.length; i++) {
			let dispatcherTrucks: boolean = this.trucksByDispatchers.includes(this.dataArray[i].firstKey) || this.trucksByDispatchers.length === 0;

			if(this.filterValue === 'Trucks' && this.dataArray[i].isTruck && dispatcherTrucks) {
				filteredArray.push(this.dataArray[i]);
			};
			if(this.filterValue === 'Trailers' && !this.dataArray[i].isTruck) {
				filteredArray.push(this.dataArray[i]);
			}
			if(this.filterValue === 'Both') {
				filteredArray.push(this.dataArray[i]);
			}
		};
		if(this.map){
			this.map.remove();
		};
		this.initMap();
		this.setMarkersOnMap(filteredArray);
	};

	setView() {
		if(this.trucksTrailersLatLngObj[this.assetSearch]) {
			this.map.setView([this.trucksTrailersLatLngObj[this.assetSearch].lat, this.trucksTrailersLatLngObj[this.assetSearch].lng], 18);
		}
	};

	reset() {
		this.assetSearch = '';
		this.filterValue = 'Both';
		this.trucksByDispatchers = [];
		this.filterDataOnMap();
	};
	
	ngOnDestroy(): void {
		this.subscription1?.unsubscribe();      
		this.subscription2?.unsubscribe();      
	};

	/*Old design
	//Asset search
	assetSearch: string = '';

	//Tags array
	tagsConfig: any = {
	  title: 'Tags',
	  array: [
		{groupTitle: 'Tags', groupData: []}
	  ],
	  search: true,
	  icon: 'local_offer'
	}

	//Filter
	filterArray: any[] = [
		{name: 'Trucks'},
		{name: 'Trailers'},
		{name: 'Both'},
	  ];
	
	isOpenFilterMenu: boolean = false;
	filterValue: string = 'Both';
  
	//Map
	private map: any;
  
	//Data array
	dataArray: any[] = [];
  
	sortAscending: boolean = true; 
  
	//Subscription
	subscription1: Subscription = new Subscription();
	subscription2: Subscription = new Subscription();

	loaded: boolean = false;
	error: boolean = false;
	errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";

	spinnerBgColor: string = this.transformService.spinnerBgColor;
	spinnerType: string = this.transformService.spinnerType;
  
	constructor(private trucksMapService: TrucksMapService,
				public transformService: TransformService,
				private dialog: MatDialog,
				private spinner: NgxSpinnerService) { }
  
	ngOnInit(): void {
	  this.getMapData();

	  //Test
	  this.trucksMapService.getTrucksByDispatchers().subscribe((response: any) => {
		console.log(response);
	  });
	}
  
	getMapData() {
	  this.spinner.show('trucks-map-page');
	  this.subscription1 = this.trucksMapService.getMapData()
	  .pipe(catchError((err: any) => {
		this.spinner.hide('trucks-map-page');
		this.loaded = true;
		this.error = true;
		return throwError(() => err);
	  }))
	  .subscribe((response: any) => {
	
		let numOfIterations: number = response.truckData.length >= response.trailerData.length ? response.truckData.length : response.trailerData.length;
		const trucksArray: any[] = [];
		const trailersArray: any[] = [];
		for(let i = 0; i < numOfIterations; i++) {
			if(i < response.truckData.length) {
				response.truckData[i].isTruck = true;
				response.truckData[i].firstKey = response.truckData[i].truck_name;
				response.truckData[i].secondKey = `${response.truckData[i].fuel}%`;
				response.truckData[i].thirdKey = response.truckData[i].address_display_name;
				response.truckData[i].fourthKey = `${this.transformService.addCommasDots(response.truckData[i].odometer * 0.6213711922, 'round')} mi`;

				response.truckData[i].firstIcon = '../../../../../assets/img/truck-front-blue.png';
				response.truckData[i].secondIcon = 'local_gas_station';
				response.truckData[i].thirdIcon = 'my_location';
				response.truckData[i].fourthIcon = 'speed';

				trucksArray.push(response.truckData[i]);
			}
			if(i < response.trailerData.length) {
				response.trailerData[i].isTruck = false;
				response.trailerData[i].firstKey = response.trailerData[i].trailer_name;
				response.trailerData[i].secondKey = `${this.transformService.addCommasDots(response.trailerData[i].batteryVoltage, 'round')}%`;
				response.trailerData[i].thirdKey = response.trailerData[i].address_display_name;
				response.trailerData[i].fourthKey = `${response.trailerData[i].make} ${response.trailerData[i].model}`

				response.trailerData[i].firstIcon = '../../../../../assets/img/trailer-driver-blue.png';
				response.trailerData[i].secondIcon = 'battery_charging_full';
				response.trailerData[i].thirdIcon = 'my_location';
				response.trailerData[i].fourthIcon = 'edit';
				trailersArray.push(response.trailerData[i]);
			}
		};
		this.dataArray = trucksArray.concat(trailersArray);
		this.initMap();
		this.setMarkersOnMap(this.dataArray);
		this.spinner.hide('trucks-map-page');
		this.loaded = true;
		console.log(this.dataArray);
	  });
	}
  
	private initMap(): void {
		  this.map = L.map('map', {
			center: [37.920746, -91.020687],
			zoom: 5,
		  });
	  
		  const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
			maxZoom: 18,
			minZoom: 3,
			attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
		  });
		  tiles.addTo(this.map);
	  };
  
	  setMarkersOnMap(array: any[]) {
		for (let i = 0; i < array.length; i++) {
			let markerIcon: any;
			if(array[i].isTruck) {
				markerIcon = {
					icon: new L.DivIcon({
						className: 'my-div-icon',
						html: `<div style="background-color: #2196F3; color: #fff; text-align: center; padding: 3px; font-size: 15px; font-weight: bolder; border-radius: 10px; font-family: 'Poppins';">${ array[i].firstKey }</div>
						<img style="width: 48px; height: 48px;" src="./assets/img/location-pin-truck.png"/>`,
						iconSize: [48, 48],
						iconAnchor: [24, 48]
					})
				}
			}
			else {
				markerIcon = {
					icon: new L.DivIcon({
						className: 'my-div-icon',
						html: `<div style="width: 70px; background-color: #1F1F1F; color: #fff; text-align: center; padding: 3px; font-size: 15px; font-weight: bolder; border-radius: 10px; font-family: 'Poppins';">${ array[i].firstKey }</div>
						<img style="width: 36px; height: 36px; margin-left: 17px; margin-top: 3px;" src="./assets/img/square-location-pin.png"/>`,
						iconSize: [36, 36],
						iconAnchor: [18, 36]
					})
				}
			}

			const marker = L.marker([+array[i].latitude, +array[i].longitude], markerIcon);
			marker.addTo(this.map).on('click', (e) => {
				this.dialog.open(TruckInfoDialogComponent, {
					autoFocus: false,
					panelClass: 'truck-info-dialog-container',
					data: {isTruck: array[i].isTruck ? 1 : 0, assetName: array[i].firstKey}
				})
			});;
		}
	  };
  
	setView(lat: number, lng: number) {
	  this.map.setView([lat, lng], 12);
	};

	filterDataOnMap() {
		let filteredArray: any[] = [];
		for(let i = 0; i < this.dataArray.length; i++) {
			if(this.filterValue === 'Trucks' && this.dataArray[i].isTruck) {
				filteredArray.push(this.dataArray[i]);
			};
			if(this.filterValue === 'Trailers' && !this.dataArray[i].isTruck) {
				filteredArray.push(this.dataArray[i]);
			}
			if(this.filterValue === 'Both') {
				filteredArray.push(this.dataArray[i]);
			}
		};
		if(this.map){
		  this.map.remove();
		};
		this.initMap();
		this.setMarkersOnMap(filteredArray);
	};
  
	sortData() {
	  this.sortAscending = !this.sortAscending;
	  let sortedArray: any[] = this.dataArray.sort((a: any,b: any) => {
		if(this.sortAscending) {
		  return a.firstKey - b.firstKey;
		}
		else {
		  return b.firstKey - a.firstKey;
		}
	  });
	  this.dataArray = [...sortedArray];
	};
  
	ngOnDestroy(): void {
	  this.subscription1?.unsubscribe();      
	  this.subscription2?.unsubscribe();      
	}
	*/
}	

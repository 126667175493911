import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'drivers'
})
export class DriversPipe implements PipeTransform {

  transform(array: any[], valueArray: boolean[]): any[] {
    if (!array) {
      return [];
    }
 
    let arr = array.filter((obj: any) => {
      let key: boolean = valueArray[0] === valueArray[1];

      if(valueArray[0] && !valueArray[1]) {
        key = obj.is_active === valueArray[0];
      }

      if(!valueArray[0] && valueArray[1]) {
        key = obj.is_active === valueArray[0];
      }


      if(key) { 
        return obj;
      }

    });
    return arr
  }

}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../core/services/auth/auth.guard';
import { TasksPageComponent } from './components/tasks-page/tasks-page.component';

const routes: Routes = [
    {
		path: '',
		canActivate: [AuthGuard],
		component: TasksPageComponent,
		data: { roles: 31 }
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class TasksRoutingModule { }

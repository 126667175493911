import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { DispatcherLiveBoardRoutingModule } from './dispatchers-live-board.routing';
import { DashboardDispatchLiveBoardPageComponent } from './components/dashboard-dispatch-live-board-page.component';
import { DispatchActivityTableComponent } from './components/dispatch-activity-table/dispatch-activity-table.component';
import { DispatchLiveBoardCardsComponent } from './components/dispatch-live-board-cards/dispatch-live-board-cards.component';
import { DispatchLoadsTableComponent } from './components/dispatch-loads-table/dispatch-loads-table.component';
import { MapLoadDialogComponent } from './components/dispatch-loads-table/map-load-dialog/map-load-dialog.component';
import { PickupDeliveryConfirmationDialogComponent } from './components/dispatch-loads-table/pickup-delivery-confirmation-dialog/pickup-delivery-confirmation-dialog.component';
import { DispatchersLiveBoardService } from './services/dispatchers-live-board.service';
import { FilterStatusesPipe } from './pipes/filter-statuses.pipe';

@NgModule({
  declarations: [
    DashboardDispatchLiveBoardPageComponent,
    DispatchActivityTableComponent,
    DispatchLiveBoardCardsComponent,
    DispatchLoadsTableComponent,
    MapLoadDialogComponent,
    PickupDeliveryConfirmationDialogComponent,
    FilterStatusesPipe
  ],
  imports: [
    CommonModule,
    SharedModule,
    DispatcherLiveBoardRoutingModule
  ],
  providers: [DispatchersLiveBoardService]
})
export class DispatchersLiveBoardModule { }

import { Component } from '@angular/core';

@Component({
  selector: 'app-load-board',
  templateUrl: './load-board.component.html',
  styleUrls: ['./load-board.component.scss']
})
export class LoadBoardComponent {
  //Filter array
  filterArray: string[] = ['Load Board', 'Search Loads'];
  activeFilter: string = 'Load Board';
  
}

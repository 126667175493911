import { Component } from '@angular/core';

@Component({
  selector: 'app-permission-denied-dialog',
  templateUrl: './permission-denied-dialog.component.html',
  styleUrls: ['./permission-denied-dialog.component.scss']
})
export class PermissionDeniedDialogComponent {
  name: any = JSON.parse(localStorage.getItem('currentUser')).first_name;

}

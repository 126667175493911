import { Component, OnDestroy, OnInit } from '@angular/core';
import moment = require('moment');
import { Subscription } from 'rxjs';
import { CreateUpdatePartsComponent } from './create-update-parts/create-update-parts.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CreateUpdateStatusComponent } from './create-update-status/create-update-status.component';
import { SuccessDialogComponent } from '@app/modules/shared/components/success-dialog/success-dialog.component';
import { FleetHealthService } from '../../services/fleet-health.service';
import { TransformService } from '@app/modules/shared/services/transform.service';


@Component({
  selector: 'app-create-work-order-dialog',
  templateUrl: './create-work-order-dialog.component.html',
  styleUrls: ['./create-work-order-dialog.component.scss']
})
export class CreateWorkOrderDialogComponent implements OnInit, OnDestroy {
  truck: boolean = true;
  selectedRowIndex: number = 10;

  //Truck data
  truckData: any[] = [];
  trailerData: any[] = [];
  workOrderData: any[] = [];

  //Basic info
  isOpenTruckAutocomplete: boolean = false;
  searchValue: string = '';
  truckId: number | undefined = undefined;
  autoCompleteData: any[] = [];
  unitNo: string = '';
  brand: string = '';
  trailer: string = '';
  trailerId: number | undefined = undefined;
  driver: string = '';
  driverId: number | undefined = undefined;
  dispatcher: string = '';
  dispatcherId: number | undefined = undefined;
  loadStatus: string = '';

  //Work order
  workOrderNoteActive: boolean = false;
  workOrderNoteValue: string = '';

  isOpenOrderMenu: boolean = false;
  orderCategoryValue: string = 'Category';
  orderSubategoryValue: string = 'Subcategory';
  orderName: string = 'Name';
  orderNameServer: string = '';

  orderCategoryValueServer: string = '';
  orderSubcategoryValueServer: string = '';

  ordersArray: any[] = [];
  ordersMenuArrayCopy: any[] = [];
  ordersMenuArray: any[] = [];

  imageName: string = '';
  byteArrayWorkOrder: any[] = [];
  selectedPartsArray: any[] = [];

  searchOrdersArray: any[] = [];

  //Contact details
  isOpenContactMenu: boolean = false;
  contactDetailsValue: string = 'Dealership';
  contactDetailsArray: any[] = [
    {name: 'Dealership', type: 0},
    {name: 'Shop', type: 1},
    {name: 'Road Service', type: 2}
  ];
  type: number = 0;

  isOpenPartnersAutocomplete: boolean = false;
  partnersArray: any[] = [];
  partnersSearch: string = '';
  contactPhone: string = '';
  contactEmail: string = '';
  state: string = '';
  city: string = '';
  zipCode: string = '';
  address: string = '';
  contact: string = '';
  bussinessPartnerId: number | undefined;

  //Payment details
  paymentNoteActive: boolean = false;
  paymentNoteValue: string = '';

  isOpenPaymentMenu: boolean = false;
  paymentArray: any[] = [
    {name: 'EFS', type: 0},
    {name: 'Credit Card', type: 1},
    {name: 'Warranty', type: 2},
    {name: 'Driver - To be reimbursed', type: 3},
  ];
  paymentType: number = 0;

  paymentTypeValue: string = 'EFS';
  moneyCode: string = '';
  amount: string = '$';
  issuedTo: string = '';
  paidBy: string = '';

  isOpenPaymentCalendar: boolean = false;
  date: string = 'Choose a date';
  dateForServer: string = ';'

  isOpenPaidByMenu: boolean = false;
  paidByArray: any[] = [
    {name: 'Tempo Freight Systems'},
    {name: 'JDM Expedite'},
    {name: 'Five Star Transport'},
  ];
  paidByValue: string = '';
  issuedByArray: any[] = [];
  isOpenIssuedMenu: boolean = false;

  selectedPaymentArray: any[] = [];

  documentName: string = '';
  byteArrayPayment: any[] = [];

  //Start/End
  isOpenStatusMenu: boolean = false;
  statusValue: string = 'In progress';
  statusArray: any[] = [];

  isOpenStartCalendar: boolean = false;
  startDate: string = 'Choose a date';
  startDateForServer: string = '';

  isOpenStartTime: boolean = false;
  startTime: string = '';
  startHour: any;
  startMinutes: any;

  isOpenEndTime: boolean = false;
  endTime: string = '';
  endHour: any;
  endMinutes: any;

  isOpenEndCalendar: boolean = false;
  endDate: string = 'Choose a date';
  endDateForServer: string = '';

  isOpenArrivedMenu: boolean = false;
  arrivedValue: string = 'Driving';
  arrivedArray: any[] = [
    {name: 'Driving'},
    {name: 'Towing'}
  ];

  //New data added
  isAddedNewData: boolean = false;

  //Subscription
  subscription1: Subscription | any;
  subscription2: Subscription | any;
  subscription3: Subscription | any;
  subscription4: Subscription | any;
  subscription5: Subscription | any;

  constructor(private fleetService: FleetHealthService, 
              private transformService: TransformService,
              private dialog: MatDialog,
              public dialogRef: MatDialogRef<any>) { }

  ngOnInit(): void {
    this.subscription1 = this.fleetService.getTrucks().subscribe((response: any) => {
      console.log(response);
      this.autoCompleteData = response;
      this.truckData = response;
    });

    this.subscription2 = this.fleetService.getAllParts().subscribe((response: any) => {
      this.workOrderData = response;
      this.transformWorkOrderData(response[0]);
      console.log(response);
    });

    this.getContactDetails(this.type);

    this.subscription4 = this.fleetService.getTrailerData().subscribe((response: any) => {
      console.log(response);
      this.trailerData = response;
    });

    this.subscription5 = this.fleetService.getAllStatus().subscribe((response: any) => {
      console.log(response);
      this.statusArray = response;
    });

    this.updatePartsData();
    this.updateStatuses();
  };

  updatePartsData() {
    this.fleetService.partsSubject.subscribe((response: any) => {
      this.transformWorkOrderData(response);
    });
  }

  transformWorkOrderData(array: any) {
    this.ordersArray = array;
    this.searchOrdersArray = array;
    this.ordersMenuArray = this.groupCategories(array);
    this.ordersMenuArrayCopy = {...this.ordersMenuArray};
  }

  updateStatuses() {
    this.fleetService.statusesSubject.subscribe((response: any) => {
      this.statusArray = response;
    });
  }

  groupCategories(array: any[]) {
    const groups: any = array.reduce((acc: any, o: any) => {

      o['category'] = o['category'];
    
      if (!acc[o['category']]) {
        acc[o['category']] = [];
      }
          
      acc[o['category']].push(o);
  
      return acc;
  
    }, {});

    return groups;
  }

  getContactDetails(type: number) {
    this.type = type;
    this.subscription3 = this.fleetService.getContactDetails(type).subscribe((response: any) => {
      console.log(response);
      this.partnersArray = response;
      this.issuedByArray = response;
    });
    this.isOpenContactMenu = false;
  };

  //Basic info autocomplete
  selectOption(obj: any, value: string) {
    this.searchValue = value;
    this.unitNo = obj.unit_no;
    this.truckId = obj.truck_id;
    this.driverId = obj.driver_id;
    this.brand = obj.model;
    this.trailer = obj.trailer;
    this.trailerId = obj.trailer_id;
    this.driver = obj.driver;
    this.dispatcher = obj.dispatcher;
    this.dispatcherId = obj.dispatcher_id;
    this.loadStatus = obj.loaded ? 'Loaded' : 'Pickup';
    this.isOpenTruckAutocomplete = false;
  }

  //Contact details
  selectContactOption(obj: any) {
    this.partnersSearch = obj.name;
    this.contactPhone = obj.phone;
    this.contactEmail = obj.email;
    this.state = obj.state;
    this.city = obj.city;
    this.zipCode = obj.zip_code;
    this.address = obj.address;
    this.contact = obj.contact;
    this.bussinessPartnerId = obj.id;
    this.isOpenPartnersAutocomplete = false;
  };

  resetContactDetails() {
    this.contactDetailsValue = 'Dealership';
    this.partnersSearch = '';
    this.contactPhone = '';
    this.state = '';
    this.city = '';
    this.zipCode = '';
    this.address = '';
    this.contact = '';
    this.bussinessPartnerId = undefined;
  }

  //Payment
  selectPaymentOption(obj: any) {
    this.paymentTypeValue = obj.name; 
    this.paymentType = obj.type; 
    this.isOpenPaymentMenu = false; 
    this.resetPaymentSelectedValues();
  };

  addPayment() {
    let files: any = [];
    if(this.byteArrayPayment.length > 0) {
      files.push({filename: this.documentName, data: this.byteArrayPayment});
    }

    let loggedUser: any = JSON.parse(localStorage.getItem('currentUser'));
    this.selectedPaymentArray.push({id: 0, type_id: 0, method: this.paymentTypeValue, type: this.paymentType, money_code: this.moneyCode,
    issued_to: this.issuedTo, paid_by: this.paidByValue, date: this.dateForServer, amount: Number(this.amount.split('$')[1]) ? Number(this.amount.split('$')[1]) : 0,
    created_by: loggedUser.first_name + ' ' + loggedUser.last_name, 
    created_date: this.transformService.convertDateToTimestamp(moment(new Date()).format('ddd, DD/MM YYYY'),  moment(new Date()).format('HH:mm:ss')),
    edited_by: null, edited_date: null, files: files
    })

    this.paymentTypeValue = 'EFS';
    this.paymentType = 0;
    this.moneyCode = '';
    this.amount = '$';
    this.issuedTo = '';
    this.paidByValue = '';
    this.date = 'Choose a date';
    this.documentName = '';
    this.byteArrayPayment = [];
  };

  resetPaymentSelectedValues() {
    this.moneyCode = '';
    this.amount = '$';
    this.issuedTo = '';
    this.paidByValue = '';
    this.date = 'Choose a date';
  }

  //Transform output
  transformOutput() {
    this.amount = this.amount.includes('$') ? this.amount : '$' + this.amount;
  };

  //Start end
  dateClicked(event: any, num: number) {
    if(num === 1) {
      this.date = moment(event._d).format('MMM DD, yyy');
      this.dateForServer = this.transformService.convertDateToTimestamp(moment(event._d).format('ddd, DD/MM YYYY'), '00:00:00');
      this.isOpenPaymentCalendar = false;
    }
    else if(num === 2) {
      this.startDate = moment(event._d).format('MMM DD, yyy');
      this.startDateForServer = this.transformService.convertDateToTimestamp(moment(event._d).format('ddd, DD/MM YYYY'), '00:00:00');
      this.isOpenStartCalendar = false;
    }
    else {
      this.endDate = moment(event._d).format('MMM DD, yyy');
      this.endDateForServer = this.transformService.convertDateToTimestamp(moment(event._d).format('ddd, DD/MM YYYY'), '00:00:00');
      this.isOpenEndCalendar = false;
    }
  }

  //Time methods
  onKeyUp(event: any, hour: boolean, isStartTime: boolean) {
    let enteredNum: any = +event.target.value;

    if(isStartTime) {

      if(hour && (enteredNum > 23 || enteredNum < 0 || event.target.value.length > 2)) {
        this.startHour = '00';
      }

      if(!hour && (enteredNum > 59 || enteredNum < 0 || event.target.value.length > 2)) {
        this.startMinutes = '00';
      }

    }
    else {
      if(hour && (enteredNum > 23 || enteredNum < 0 || event.target.value.length > 2)) {
        this.endHour = '00';
      }

      if(!hour && (enteredNum > 59 || enteredNum < 0 || event.target.value.length > 2)) {
        this.endMinutes = '00';
      }
    }
  }

  leadingZero(hour: boolean, isStartTime: boolean) {
    if(isStartTime) {

      if(hour && this.startHour) {
        let hourString: string = this.startHour.toString();
        this.startHour =  (hourString.length === 1) ? ("0" + this.startHour) : this.startHour;
      }

      if(!hour && this.startMinutes) {
        let minutesString: string = this.startMinutes.toString();
        this.startMinutes = (minutesString.length === 1) ? ("0" + this.startMinutes) : this.startMinutes; 
      }

    }

    else {
      if(hour && this.endHour) {
        let hourString: string = this.endHour.toString();
        this.endHour =  (hourString.length === 1) ? ("0" + this.endHour) : this.endHour;
      }

      if(!hour && this.endMinutes) {
        let minutesString: string = this.endMinutes.toString();
        this.endMinutes = (minutesString.length === 1) ? ("0" + this.endMinutes) : this.endMinutes; 
      }
    }
  }

  applyTime(isStartTime: boolean) { 
    if(isStartTime) {
      this.startTime = `${this.startHour}:${this.startMinutes}`;
      this.isOpenStartTime = false;
    }
    else {
      this.endTime = `${this.endHour}:${this.endMinutes}`;
      this.isOpenEndTime = false;
    }
  };

  //Open parts dialog
  openPartsDialog() {
    this.dialog.open(CreateUpdatePartsComponent, {
      autoFocus: false,
      panelClass: 'create-update-parts-dialog-container',
      data: {array: this.ordersArray, isTruck: this.truck}
    })
    this.isOpenOrderMenu = !this.isOpenOrderMenu;
  }

  //File selected
  onFileSelected(event: any, workOrder: boolean) {
    let fileName = event.target.files[0]?.name;
    let reader = new FileReader();
    reader.onload = (e: any) => {
      let uint8: any = new Uint8Array(e.target.result);
      let result: any[] = [];
      for (var i = 0; i < uint8.length; i++) {
        result.push(uint8[i]);
      }
      if(workOrder) {
        this.imageName = fileName;
        this.byteArrayWorkOrder = result;
      }
      else {
        this.documentName = fileName;
        this.byteArrayPayment = result;
      }
    }
    reader.readAsArrayBuffer(event.target.files[0]);
  };

  //Parts
  addPart() {
    let loggedUser: any = JSON.parse(localStorage.getItem('currentUser'));
    if(this.orderName !== '') {
      this.selectedPartsArray.push({id: 0, type_id: 0, type: 0, category: this.orderCategoryValueServer, subcategory:
      this.orderSubcategoryValueServer, name: this.orderNameServer, labor_cost: 0, park_cost: 0, 
      created_by: loggedUser.first_name + ' ' + loggedUser.last_name, note: '',
      created_date: this.transformService.convertDateToTimestamp(moment(new Date()).format('ddd, DD/MM YYYY'),  moment(new Date()).format('HH:mm:ss')),
      edited_by: null, edited_date: null, image_data: this.byteArrayWorkOrder, image_name: this.imageName});

      this.orderCategoryValue = 'Category';
      this.orderSubategoryValue = 'Subcategory';
      this.orderName = 'Name';

      this.orderCategoryValueServer = '';
      this.orderSubcategoryValueServer = '';
      this.orderNameServer = '';
      this.imageName = '';
      this.byteArrayWorkOrder = [];
      this.ordersMenuArray = {...this.ordersMenuArrayCopy};
      console.log(this.ordersMenuArray)
    }
  };

  discard(i: number, array: any[]) {
    array.splice(i, 1);
  };

  selectCategory(obj: any) {
    this.orderCategoryValue = obj.category;
    this.orderCategoryValueServer = obj.category;
    this.orderSubategoryValue = obj.subcategory; 
    this.orderSubcategoryValueServer = obj.subcategory;
    this.orderName = obj.name;
    this.orderNameServer = obj.name;
    this.isOpenOrderMenu = !this.isOpenOrderMenu;
  }

  //Start/end
  openStatusSettings() {
    this.dialog.open(CreateUpdateStatusComponent, {
      autoFocus: false,
      panelClass: 'create-update-statuses-dialog-container',
      data: this.statusArray
    })
    this.isOpenStatusMenu = !this.isOpenStatusMenu;
  };

  //Save data
  save() {
    let loggedUser: any = JSON.parse(localStorage.getItem('currentUser'));
    let startDate: string = this.transformService.convertDateToTimestamp(moment(this.startDateForServer).format('ddd, DD/MM YYYY'), this.startTime + ':00');
    let endDate: string = this.transformService.convertDateToTimestamp(moment(this.endDateForServer).format('ddd, DD/MM YYYY'), this.endTime + ':00');
    
    let obj: any = {
      id: 0,
      type: this.type,
      unit_no: this.unitNo,
      truck_id: this.truckId,
      brand: this.truck ? this.brand : '',
      driver: this.driver,
      driver_id: this.driverId,
      dispatcher: this.dispatcher,
      dispatcher_id: this.dispatcherId,
      bussiness_paretner_id: this.bussinessPartnerId,
      trailer_no: this.trailer,
      trailer_id: this.trailerId,
      trailer_brand: !this.truck ? this.brand : '',
      load_status: this.loadStatus,
      arrived_by: this.arrivedValue,
      work_order_note: this.workOrderNoteValue,
      type_name: this.partnersSearch,
      type_phone: this.contactPhone,
      type_email: this.contactEmail,
      type_address: this.address,
      type_city: this.city,
      type_zip: this.zipCode,
      type_state: this.state,
      type_contact: this.contact,
      payment_note: this.paymentNoteValue,
      start_type: startDate,
      end_type: endDate,
      note: '',
      last_checked: this.transformService.convertDateToTimestamp(moment(new Date()).format('ddd, DD/MM YYYY'),  moment(new Date()).format('HH:mm:ss')),
      is_truck: this.truck,
      payments: this.selectedPaymentArray,
      workorders: this.selectedPartsArray,
      created_by: loggedUser.first_name + ' ' + loggedUser.last_name,
      departments: loggedUser.department,
      created_date: this.transformService.convertDateToTimestamp(moment(new Date()).format('ddd, DD/MM YYYY'),  moment(new Date()).format('HH:mm:ss')),
      checkedstatus: [
        {
          id: 0,
          type_id: 0,
          last_check: this.transformService.convertDateToTimestamp(moment(new Date()).format('ddd, DD/MM YYYY'),  moment(new Date()).format('HH:mm:ss')),
          status: this.statusValue,
          expected_end: endDate,
          created_by: loggedUser.first_name + ' ' + loggedUser.last_name,
          created_date: this.transformService.convertDateToTimestamp(moment(new Date()).format('ddd, DD/MM YYYY'),  moment(new Date()).format('HH:mm:ss'))
        }
      ]
    }
    console.log(obj);
    this.fleetService.saveWorkOrder(obj).subscribe((response: any) => {
      console.log(response);
      if(response.result) {
        this.resetAllFields();
        this.dialog.open(SuccessDialogComponent, {
          autoFocus: false,
          panelClass: 'success-dialog-container'
        });    
        this.isAddedNewData = true;
      };
    })
  }

  //Close dialog
  closeDialog() {
    this.dialogRef.close(this.isAddedNewData);
  };

  //Filter data
  filterOrder(event: any) {
    console.log(event.target.value);
    let searchText: string = event.target.value;
    let array: any[] = [...this.searchOrdersArray];
    array = array.filter((obj: any) => {
      return obj.name?.toLocaleLowerCase().includes(searchText);
    });
    console.log(array);
    this.ordersMenuArray = this.groupCategories(array);
  }

  changeForm() {
    this.truck = !this.truck;
    this.resetAllFields();

    if(this.truck) {
      this.autoCompleteData = this.truckData;
      this.transformWorkOrderData(this.workOrderData[0]);
    }
    else {
      this.autoCompleteData = this.trailerData;
      this.transformWorkOrderData(this.workOrderData[1]);
    }

  };

  resetAllFields() {
    this.selectedRowIndex = 10;
    this.searchValue = '';
    this.truckId = undefined;
    this.autoCompleteData = [];
    this.unitNo = '';
    this.brand = '';
    this.trailer = '';
    this.trailerId = undefined;
    this.driver = '';
    this.driverId = undefined;
    this.dispatcher = '';
    this.dispatcherId = undefined;
    this.loadStatus = '';
    this.workOrderNoteActive = false;
    this.workOrderNoteValue = '';
    this.orderCategoryValue = 'Category';
    this.orderSubategoryValue = 'Subcategory';
    this.orderName = 'Name';
    this.orderCategoryValueServer = '';
    this.orderSubcategoryValueServer = '';
    this.orderNameServer = '';
    this.ordersArray = [];
    this.ordersMenuArray = [];
    this.imageName = '';
    this.byteArrayWorkOrder = [];
    this.selectedPartsArray = [];
    this.searchOrdersArray = [];
    this.contactDetailsValue = 'Dealership';
    this.type = 0;
    this.partnersSearch = '';
    this.contactPhone = '';
    this.contactEmail = '';
    this.state = '';
    this.city = '';
    this.zipCode = '';
    this.address = '';
    this.contact = '';
    this.bussinessPartnerId = undefined;
    this.paymentNoteActive = false;
    this.paymentNoteValue = '';
    this.paymentType = 0;
    this.paymentTypeValue = 'EFS';
    this.moneyCode = '';
    this.amount = '$';
    this.issuedTo = '';
    this.paidBy = '';
    this.date = 'Choose a date';
    this.documentName = '';
    this.byteArrayPayment = [];
    this.dateForServer = '';
    this.paidByValue = '';
    this.selectedPaymentArray = [];
    this.statusValue = 'In progress';
    this.startDate = 'Choose a date';
    this.startDateForServer = '';
    this.startTime = '';
    this.startHour = undefined;
    this.startMinutes = undefined;
    this.endTime = '';
    this.endHour = undefined;
    this.endMinutes = undefined;
    this.endDate = 'Choose a date';
    this.endDateForServer = '';
    this.arrivedValue = 'Driving';
  };

  ngOnDestroy(): void {
    this.subscription1?.unsubscribe();
    this.subscription2?.unsubscribe();
    this.subscription3?.unsubscribe();
    this.subscription4?.unsubscribe();
  };

}

import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ApexAxisChartSeries, ApexChart, ApexFill, ApexLegend, ApexResponsive, ChartComponent } from 'ng-apexcharts';
import { Subscription } from 'rxjs';
import { RecruitingService } from '../../services/recruiting.service';
import { TransformService } from '@app/modules/shared/services/transform.service';

export type ChartOptions = {
  series: ApexAxisChartSeries | any;
  chart: ApexChart | any;
  responsive: ApexResponsive | any;
  labels: string | any;
  colors: string | any;
  legend: ApexLegend | any;
  fill: ApexFill | any;
};

@Component({
  selector: 'app-pie-charts',
  templateUrl: './pie-charts.component.html',
  styleUrls: ['./pie-charts.component.scss']
})
export class PieChartsComponent implements OnInit, OnDestroy {
  @ViewChild("chart") chart: ChartComponent | any;
  subscription: Subscription | any;
  public chartOptions1: Partial<ChartOptions> | any;
  public chartOptions2: Partial<ChartOptions> | any;
  public chartOptions3: Partial<ChartOptions> | any;

  @Input() date: any;
  @Input() pieChartSecondData: any;

  constructor(private dataService: RecruitingService, private transformService: TransformService) { }

  ngOnInit(): void {
    this.getData(this.date);
    this.dateChange();
    this.chartOptions2 = this.pieChart([['MORE THAN 3 MONTHS', 'LESS THAN 3 MONTHS'], []]);
  }
  getData(dateObj: any) {
    this.dataService.getPieChartData(dateObj.groupBy, dateObj.startDate, dateObj.endDate).subscribe(
      (response: any) => {
        console.log(response);
        this.chartOptions1 = this.pieChart(this.transformService.countPieStatisticsRecruiting(response.data));
      });

    this.dataService.getHiredTerminatedDrivers(dateObj.groupBy, dateObj.startDate, dateObj.endDate).subscribe(
      (response: any) => {
        console.log(response);
        let pieChartThirdData = this.transformService.countHiredTerminatedRecruiting(response.data);
        this.chartOptions3 = this.pieChart([['HIRED', 'TERMINATED'], [pieChartThirdData.sumHired, pieChartThirdData.sumTerminated]])
      });
  };

  dateChange() {
    this.subscription = this.dataService.dateSubject.subscribe((dateObj: any) => {
      this.getData(dateObj);
    });
  };

  pieChart(array: any) {
    return {
      fill: {
        type: 'gradient'
      },
      colors: ['#100ca1', '#868686', '#f4661c'],
      series: array[1],
      chart: {
        width: 360,
        type: "pie",
      },
      labels: array[0],
      legend: {
        position: 'bottom'
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: "bottom"
            }
          }
        }
      ]
    };
  };

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}

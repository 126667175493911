import { Component, OnInit, Input, SimpleChange } from '@angular/core';
import { Load } from '../../models/load.model';

@Component({
	selector: 'loads-estimation-time-series',
	templateUrl: './estimation-time-series.component.html',
	styleUrls: ['./estimation-time-series.component.scss']
})
export class EstimationTimeSeriesComponent implements OnInit {

	@Input() loads: Load[];
	@Input() grouped: Map<string, Load[]>;

	data: any;
	elementId2: string;
	options: any;

	ngOnChanges(changes: SimpleChange) {
		console.log(changes);
		if (('loads' in changes)) {
			console.log(
				'Time series on change: ', this.loads
			);
			if (this.loads) {
				this.setAnnotationChart();
			}
		}

		if (('grouped' in changes)) {
			console.log(
				'Time series on change: ', this.grouped
			);
			// if(this.grouped) {
			// 	this.setAnnotationChart();
			// } 
		}
	}

	ngOnInit(): void {

	}

	totalColumns = [
		['date', 'Date'],

		['number', 'Load price'],
		['string', 'Load Total'],
		['string', 'Description']
	];


	totalPerMileColumns = [
		['date', 'Date'],

		['number', '$/ mile'],
		['string', 'Load per mile'],
		['string', 'Description']
	];


	totalRows = [];
	totalPerMileRows = [];

	setAnnotationChart() {

		this.totalRows = [];
		this.totalPerMileRows = [];

		this.loads.forEach(
			(load) => {
				
				let totalRow = [new Date(load.date), load.total_broker, 'Load # ' + load.load_no, this._getLoadInfo(load)];

				let totalPerMileRow = [new Date(load.date), load.dollar_per_mile, 'Load # ' + load.load_no, this._getLoadInfo(load)];

				this.totalRows.push(totalRow);
				this.totalPerMileRows.push(totalPerMileRow);
			}
		);

		this.options = {
			tooltip: {isHtml: true},
			displayAnnotations: true,
			displayAnnotationsFilter: true,
			annotationsWidth: 15,
			allowHtml: true,
			curveType: 'function',
			fill: 10,
			thicknes: 3,
			textStyle: {
				fontName: 'Times-Roman',
				fontSize: 40,
			},
			table: {
				textStyle: {
					fontName: 'Times-Roman',
					fontSize: 40,
					// bold: true,
					// italic: true,
					// color: '#871b47',     // The color of the text.
					// auraColor: '#d799ae', // The color of the text outline.
					// opacity: 0.8          // The transparency of the text.
				}
			},
			annotations: {
				textStyle: {
					fontName: 'Times-Roman',
					fontSize: 118,
					bold: true,
					italic: true,
					color: '#871b47',     // The color of the text.
					auraColor: '#d799ae', // The color of the text outline.
					opacity: 0.8          // The transparency of the text.
				}
			}
		};

		this.elementId2 = 'testAnnotatedTimeLine'; 1

		this.setTotalData()
	}

	setTotalData() {
		this.data = {
			rows: this.totalRows,
			columns: this.totalColumns
		}

		console.log('Data: ', this.data);
	}

	setTotalPerMileData() {
		this.data = {
			rows: this.totalPerMileRows,
			columns: this.totalPerMileColumns
		}

		console.log('Data: ', this.data);
	}

	_getLoadInfo(load: Load) {

		var table = `<table style="width:95%; font-size: 12px;" class="load-info-table" border="0">
						<tr>
							<th style="font-size: 11px;">$/ mile</th>
							<td align="right">${load.dollar_per_mile}</td> 
						</tr>
						<tr>
							<th style="font-size: 11px;">Total</th>
							<td align="right">${load.total_broker}</td>
						</tr>
						<tr>
							<th style="font-size: 11px;">Mileage</th>
							<td align="right">${load.mileage}</td>
						</tr>
					</table>`;
		return table;
	}

}

import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort'
import { LoadsInfoDialogComponent } from '../loads-info-dialog/loads-info-dialog.component';

@Component({
  selector: 'loads-table',
  templateUrl: './loads-table.component.html',
  styleUrls: ['./loads-table.component.scss']
})
export class LoadsTableComponent {
  @Input() dataSource: any[] = [];

  statusObj: any = {
    1: 'Load Booked',
    2: 'In Transit On Time',
    3: 'In Transit Delayed',
    4: 'Delivered Waiting For Bol',
    5: 'Waiting For Payment',
    6: 'Completed',
    null: ''
  };

  constructor(private dialog: MatDialog) { }

  sortData(sort: Sort) {
    const data = JSON.parse(JSON.stringify(this.dataSource));
    if (!sort.active || sort.direction === '') {
      this.dataSource = data;
      return;
    }
    this.dataSource = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'route':
          return compare(a.is_pickuped, b.is_pickuped, isAsc);
        case 'load_no':
          return compare(a.load_no, b.load_no, isAsc);
        case 'driver':
          return compare(a.driver, b.driver, isAsc);
        case 'unit_no':
          return compare(a.unit_no, b.unit_no, isAsc);
        case 'dispatcher':
          return compare(a.dispatcher, b.dispatcher, isAsc);
        case 'status':
          return compare(a.status, b.status, isAsc);
        default:
          return 0;
      }
    });
  }

  loadInfoDialog(element: any) {
    this.dialog.open(LoadsInfoDialogComponent, {
      autoFocus: false,
      panelClass: 'loads-info-dialog-container',
      data: element
    })
  };

}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

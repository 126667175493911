import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { PrebookedUpcomingRoutingModule } from './prebooked-upcoming.routing';
import { DragDropUpcomingCardsComponent } from './components/drag-drop-upcoming-cards/drag-drop-upcoming-cards.component';
import { PrebookedUpcomingTableComponent } from './components/prebooked-upcoming-table/prebooked-upcoming-table.component';
import { PrebookedUpcomingComponent } from './components/prebooked-upcoming.component';
import { PrebookedUpcomingService } from './services/prebooked-upcoming.service';

@NgModule({
  declarations: [
    PrebookedUpcomingComponent,
    DragDropUpcomingCardsComponent,
    PrebookedUpcomingTableComponent
  ],
  imports: [
    CommonModule,
    PrebookedUpcomingRoutingModule,
    SharedModule
  ],
  providers: [PrebookedUpcomingService]
})
export class PrebookedUpcomingModule { }

import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { CalendarDialogComponent } from '@app/modules/shared/components/calendar-dialog/calendar-dialog.component';
import { RulesService } from '@app/modules/shared/services/rules.service';
import { SharedService } from '@app/modules/shared/services/shared.service';
import { TransformService } from '@app/modules/shared/services/transform.service';

import moment = require('moment');
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription, catchError, throwError } from 'rxjs';
import * as XLSX from 'xlsx'; 
import { StatementsService } from '../../services/statements.service';
import { MsgForbbidenAccessComponent } from '@app/modules/shared/components/msg-forbbiden-access/msg-forbbiden-access.component';

@Component({
  selector: 'app-statements-table',
  templateUrl: './statements-table.component.html',
  styleUrls: ['./statements-table.component.scss']
})
export class StatementsTableComponent implements OnInit, OnDestroy {
  permissions: any = this.rulesService.UserData[44].data;

  fileName: string = 'statement-table.xlsx';

  dateObj: any = {
    startDate: this.transformService.convertDateToTimestamp(moment().subtract(1, 'week').startOf('isoWeek').format('ddd, DD/MM YYYY'), 
    '00:00:00'),
    endDate: this.transformService.convertDateToTimestamp(moment().subtract(1, 'week').endOf('isoWeek').format('ddd, DD/MM YYYY'), 
    '23:59:59'),
  };

  startDate: string = moment().subtract(1, 'week').startOf('isoWeek').weekday(2).format('MM.DD.YYYY');
  endDate: string = moment().subtract(1, 'week').endOf('isoWeek').weekday(1).format('MM.DD.YYYY');

  displayedColumns: string[] = ['position', 'driver_name', 'unit_no', 'driver_status', 'company_gross', 'company_miles',
  'drivers_gross', 'paid_miles', 'company_benefint_miles', 'company_benefint_miles_percent', 'margin', 'margin_percent',
  'rate', 'tolls', 'fuel', 'deduction', 'paycheck', 'statement'];

  dataSource: any[] = [];
  sortedData: any[] = [];

  stObj: any = {
    numOfDrivers: 0,
    companyGrossSt: 0,
    companyMileageSt: 0,
    driversGrossSt: 0,
    paidMileageSt: 0,
    companyBenefitMilesSt: 0,
    companyBenefitMilesPercentSt: 0,
    marginSt: 0,
    marginPercentSt: 0,
    rateSt: 0,
    tollCostSt: 0,
    fuelCostSt: 0,
    deductionCostSt: 0,
    paycheckSt: 0
  };

  //Select
  dataTypingArray: any[] = [];
  selectedUsers: any[] = new Array<any>();

  searchText: string = '';

  isOpen: boolean = false;

  loaded: boolean = false;

  error: boolean = false;

  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";

  spinnerBgColor: string = this.transformService.spinnerBgColor;
  spinnerType: string = this.transformService.spinnerType;

  subscription: Subscription | any;

  constructor(public transformService: TransformService,
              private statementService: StatementsService,
              private sharedService: SharedService,
              private dialog: MatDialog,
              private rulesService: RulesService,
              private router: Router,
              private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.spinner.show('statements-table');
    this.getTableData();
  }

  getTableData() {
    this.subscription = this.statementService.getStatementsTableData(this.dateObj.startDate, this.dateObj.endDate)
    .pipe(catchError((err: any) => {
      this.spinner.hide('statements-table');
      this.loaded = true;
      this.error = true;
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      console.log(response);
      this.dataSource = JSON.parse(JSON.stringify(response));
      this.sortedData = response;
      this.dataTypingArray = response;
      this.countStData(response);
      this.spinner.hide('statements-table');
      this.loaded = true;
    });
  };

  openDialog() {
    let dialogRef: any = this.dialog.open(CalendarDialogComponent, {
      width: '769px',
      height: '476px',
      autoFocus: false,
      data: {oneRange: true},
      panelClass: 'calendar-dialog-container'
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.dateObj.startDate = result.startDate;
        this.dateObj.endDate = result.endDate;
        this.startDate = moment(result.startDate).format('MM.DD.YYYY');
        this.endDate = moment(result.endDate).format('MM.DD.YYYY');
        this.dataSource = [];
        this.error = false;
        this.loaded = false;
        this.spinner.show('statements-table');
        this.getTableData();
      }
    });
  }

  setDay(isTrue: boolean) {
    if(isTrue) {
      this.dateObj.startDate = moment(this.dateObj.startDate).add(1, 'week').format('YYYY-MM-DD');
      this.dateObj.endDate = moment(this.dateObj.endDate).add(1, 'week').format('YYYY-MM-DD');
    } else {
      this.dateObj.startDate = moment(this.dateObj.startDate).subtract(1, 'week').format('YYYY-MM-DD');
      this.dateObj.endDate = moment(this.dateObj.endDate).subtract(1, 'week').format('YYYY-MM-DD');
    }

    this.startDate = moment(this.dateObj.startDate).weekday(2).format('MM.DD.YYYY');
    this.endDate = moment(this.dateObj.endDate).weekday(1).format('MM.DD.YYYY');

    this.sortedData = [];
    this.error = false;
    this.loaded = false;
    this.spinner.show('statements-table');
    this.getTableData();
  }

  countStData(array: any) {
    let numOfDrivers: number = array.length;
    let sumCompanyGross: number = 0;
    let sumCompanyMileage: number = 0;
    let sumDriversGross: number = 0;
    let sumPaidMileage: number = 0;
    let sumCompanyBenefitMiles: number = 0;
    let sumCompanyBenefitMilesPercent: number = 0;
    let sumMargin: number = 0;
    let sumMarginPercent: number = 0;
    let sumRate: number = 0;
    let sumTollCost: number = 0;
    let sumFuelCost: number = 0;
    let sumDeductionCost: number = 0;
    let sumPaycheck: number = 0;

    for(let i = 1; i < array.length; i++) {
      sumCompanyGross += this.isNumber(array[i].company_gross);
      sumCompanyMileage += this.isNumber(array[i].company_miles);
      sumDriversGross += this.isNumber(array[i].drivers_gross);
      sumPaidMileage += this.isNumber(array[i].paid_miles);
      sumCompanyBenefitMiles += this.isNumber(array[i].company_benefint_miles);
      sumCompanyBenefitMilesPercent += this.isNumber(array[i].company_benefint_miles_percent);
      sumMargin += this.isNumber(array[i].margin);
      sumMarginPercent += this.isNumber(array[i].margin_percent);

      sumRate += this.isNumber(array[i].rate);
      sumTollCost += this.isNumber(array[i].tolls);
      sumFuelCost += this.isNumber(array[i].fuel);
      sumDeductionCost += this.isNumber(array[i].deduction);
      sumPaycheck += this.isNumber(array[i].paycheck);
    }

    this.stObj.numOfDrivers = numOfDrivers;
    this.stObj.companyGrossSt = sumCompanyGross / numOfDrivers;
    this.stObj.companyMileageSt = sumCompanyMileage / numOfDrivers;
    this.stObj.driversGrossSt = sumDriversGross / numOfDrivers;
    this.stObj.paidMileageSt = sumPaidMileage / numOfDrivers;
    this.stObj.companyBenefitMilesSt = sumCompanyBenefitMiles / numOfDrivers;
    this.stObj.companyBenefitMilesPercentSt = sumCompanyBenefitMilesPercent / numOfDrivers;
    this.stObj.marginSt = sumMargin / numOfDrivers;
    this.stObj.marginPercentSt = sumMarginPercent / numOfDrivers;

    this.stObj.rateSt = sumRate / numOfDrivers;
    this.stObj.tollCostSt = sumTollCost / numOfDrivers;
    this.stObj.fuelCostSt = sumFuelCost / numOfDrivers;
    this.stObj.deductionCostSt = sumDeductionCost / numOfDrivers;
    this.stObj.paycheckSt = sumPaycheck / numOfDrivers;

  };

  isNumber(value: number | string) {
    if(typeof value === 'number') {
      return value;
    }
    else {
      return 0;
    }
  }

  sortData(sort: Sort) {
    const data = this.selectedUsers.length > 0 ? this.selectedUsers.slice() : this.dataSource.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }
    this.sortedData = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'driver_name':
          return compare(a.driver_name, b.driver_name, isAsc);
        case 'unit_no':
          return compare(a.unit_no, b.unit_no, isAsc);
        case 'driver_status':
            return compare(a.driver_status, b.driver_status, isAsc);
        case 'company_gross':
          return compare(a.company_gross, b.company_gross, isAsc);
        case 'company_miles':
          return compare(a.company_miles, b.company_miles, isAsc);
        case 'drivers_gross':
          return compare(a.drivers_gross, b.drivers_gross, isAsc);
        case 'paid_miles':
          return compare(a.paid_miles, b.paid_miles, isAsc);
        case 'company_benefint_miles':
          return compare(a.company_benefint_miles, b.company_benefint_miles, isAsc);
        case 'company_benefint_miles_percent':
          return compare(a.company_benefint_miles_percent, b.company_benefint_miles_percent, isAsc);
        case 'margin':
          return compare(a.margin, b.margin, isAsc);
        case 'margin_percent':
          return compare(a.margin_percent, b.margin_percent, isAsc);
        case 'rate':
          return compare(a.rate, b.rate, isAsc);
        case 'tolls':
          return compare(a.tolls, b.tolls, isAsc);
        case 'fuel':
          return compare(a.fuel, b.fuel, isAsc);
        case 'deduction':
          return compare(a.deduction, b.deduction, isAsc);
        case 'paycheck':
          return compare(a.paycheck, b.paycheck, isAsc);
        default:
          return 0;
      }
    });
  }

  downloadStatement(obj: any) {
    obj.pdfLoading = true;
    this.sharedService.downloadStatementFile(obj.driver_id, obj.id)
    .pipe(catchError((err: any) => {
      obj.pdfLoading = false;
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      obj.pdfLoading = false;
      let fileURL = window.URL.createObjectURL(response);
      let tab = window.open();
      if(this.permissions[0].sectionArray[0].allowed) {
        tab.location.href = fileURL;
      }
      else {
        tab.location.href = fileURL + '#toolbar=0';
      }
    }) 
  }

  //Open in new tab
  openInNewTab(route: string) {
    const url = this.router.serializeUrl(this.router.createUrlTree([route]));
    window.open(url, '_blank');
  }

  //Search 
  toggleSelection(user: any) {
    user.selected = !user.selected;
    if (user.selected) {
      this.selectedUsers.push(user);
      this.sortedData = JSON.parse(JSON.stringify(this.selectedUsers));
    } else {
      const i = this.selectedUsers.findIndex(value => value.name === user.name);
      this.selectedUsers.splice(i, 1);
      this.sortedData = JSON.parse(JSON.stringify(this.selectedUsers));
    }
    this.sortedData.length === 0 ? this.sortedData = JSON.parse(JSON.stringify(this.dataSource)) : this.sortedData;
    this.countStData(this.sortedData);
  }

  resetCheckedValue() {
    let data: any = JSON.parse(JSON.stringify(this.dataSource));
    this.sortedData = data;
    this.dataTypingArray = data;
    this.searchText = '';
    this.selectedUsers = [];
    this.countStData(this.sortedData);
  }

  //Export to excel
  exportToExcel() {
    let element = document.getElementById('statements-table'); 
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    XLSX.writeFile(wb, this.fileName);
  };
  

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

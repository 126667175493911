import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import moment = require('moment');
import { PDFDocument } from 'pdf-lib';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription, catchError, throwError } from 'rxjs';
import { AddContractCategoryDialogComponent } from './add-contract-category-dialog/add-contract-category-dialog.component';
import { EditContractCategoryDialogComponent } from './edit-contract-category-dialog/edit-contract-category-dialog.component';
import { DeleteCategoryFileDialogComponent } from './delete-category-file-dialog/delete-category-file-dialog.component';
import { WarningContractDialogComponent } from './warning-contract-dialog/warning-contract-dialog.component';
import { TemplatesListDialogComponent } from './templates-list-dialog/templates-list-dialog.component';
import { DeleteTemplateDialogComponent } from './templates-list-dialog/delete-template-dialog/delete-template-dialog.component';

import * as pdfmake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';

import * as htmlToPdf from 'html-to-pdfmake';
import { TransformService } from '@app/modules/shared/services/transform.service';
import { SafetyService } from '@app/modules/safety/services/safety.service';
import { PolicyDialogComponent } from './policy-dialog/policy-dialog.component';
import { SuccessActionComponent } from './policy-dialog/success-action/success-action.component';
import * as XLSX from 'xlsx';
import { TitleCasePipe } from '@angular/common';
import { RulesService } from '@app/modules/shared/services/rules.service';
import { MsgForbbidenAccessComponent } from '@app/modules/shared/components/msg-forbbiden-access/msg-forbbiden-access.component';
import { SearchPipe } from '@app/modules/shared/pipes/search.pipe';

@Component({
  selector: 'app-contract',
  templateUrl: './contract.component.html',
  styleUrls: ['./contract.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})

export class ContractComponent implements OnInit, OnDestroy {
  @ViewChild('expanded') expanded: ElementRef;  
  @ViewChild('fileUpload') fileUpload: ElementRef;

  //Search
  searchText: string = '';

  isSaved: boolean = true;

  dataSource: any[] = [];
  sortedData: any[] = [];

  expandedRow: number = -1;
  expandedFieldIndex: number = 0;
  categoryID: number = -1;
  categoryName: string = '';

  columnsToDisplay = ['position', 'driverID', 'driver_name', 'truckID', 'lastUpdate', 'hidden-columns'];
  displayedColumns = [
    { key: 'position', title: ''},
    { key: 'driverID', title: 'DRIVER ID'},
    { key: 'driver_name', title: 'DRIVER'},
    { key: 'truckID', title: 'TRUCK #'},
    { key: 'lastUpdate', title: 'LAST UPDATE'},
    { key: 'hidden-columns', title: ''},
  ];
  columnsToDisplayWithExpand = [...this.columnsToDisplay];
  expandedElement: any;

  categories: any[] = [];

  uploadedDate: string = '';

  //Excel config
  xlsxConfig: any[] = [
    {columnName: 'No.', selected: true},
    {columnName: 'DRIVER ID', selected: true},
    {columnName: 'DRIVER', selected: true},
    {columnName: 'TRUCK #', selected: true},
    {columnName: 'LAST UPDATE', selected: true}
  ];

  //Date
  date: any = moment().format('YYYY-MM-DD');

  loaded: boolean = false;

  error: boolean = false;

  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";

  spinnerBgColor: string = this.transformService.spinnerBgColor;
  spinnerType: string = this.transformService.spinnerType;

  subscription1: Subscription | any;
  subscription2: Subscription | any;
  subscription3: Subscription | any;

  constructor(private transformService: TransformService,
              private safetyService: SafetyService,
              private titleCase: TitleCasePipe,
              private dialog: MatDialog,
              private renderer: Renderer2,
              private spinner: NgxSpinnerService,
              private rulesService: RulesService,
              private searchPipe: SearchPipe) {
                this.renderer.listen('document', 'click',(e: any)=>{
                  if(e.target !== this.expanded?.nativeElement && !e.target.classList.contains('mat-calendar-body-cell-content')
                       && !this.isSaved && this.dialog.openDialogs.length==0) {
                    this.warningDialog();
                  }
                });
              }

  ngOnInit(): void {
    (pdfmake as any).vfs = pdfFonts.pdfMake.vfs;
    this.spinner.show('contract');
    this.getTableData();
    this.refreshData();
  }

  //Get table data
  getTableData() {
    this.subscription1 = this.safetyService.getContractTableData()
    .pipe(catchError((err: any) => {
      this.spinner.hide('contract');
      this.loaded = true;
      this.error = true;
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      console.log(response);
      this.categories = response[1];

      let data: any[] = JSON.parse(JSON.stringify(response[0]));
      
      for(let key in data) {
        let expandedData: any[] = JSON.parse(JSON.stringify(response[1])); 
        for(let key2 in data[key].categories) {
      
          for(let key3 in response[1]) {

            if(expandedData[key3].ID == data[key].categories[key2].categoryID && data[key].categories[key2].files.length > 0) {
              expandedData[key3].uploaded = data[key].categories[key2].uploaded;  
            } 
        }

      }  
        data[key].expandedData = expandedData;
      }
      this.categoryID = response[1][0].ID;
      this.dataSource = JSON.parse(JSON.stringify(data));
      this.sortedData = data;
      this.spinner.hide('contract');
      this.loaded = true;
      console.log(this.dataSource);
    })
  };

  onFileSelected(event: any, obj: any) {
    this.isSaved = false;
    let length: any = Object.keys(event).length;
    for(let i = 0; i < length; i++) {
      this.addMultipleFiles(event, i, obj);
    }
  }

  addMultipleFiles(file: any, index: number, data: any) {
    let fileName = file[index].name;
    let reader: any = new FileReader();
    reader.onload = () => {
    let obj: any = {
      fileName: fileName,
      fileData: reader.result.split(',')[1],
      driverID: data.driverID,
      categoryID: this.categoryID,
      uploaded: moment(new Date()).format('MMM DD. YYYY.'),
      uploadedBy: JSON.parse(localStorage.getItem('currentUser')).first_name,
      hubCategory: -1,
      expiration: null,
      isOpen: false,
      newFile: true
    };
      this.uploadedDate = obj.uploaded;

      let categoryExist: boolean = false;
      console.log(obj);
      for(let key in data.categories) {
        if(data.categories[key].categoryID === this.categoryID) {
          obj.hubCategory = data.categories[key].ID ? data.categories[key].ID : -1;
          data.categories[key].files.push(obj);
          this.dataSource[this.expandedRow].categories[key].files.push(obj)
          categoryExist = true;
        }
      }

      if(data.categories.length === 0 || !categoryExist) {
        data.categories.push({categoryID: this.categoryID, files: [obj]});
        this.dataSource[this.expandedRow].categories.push({ID: undefined, categoryID: this.categoryID, files: [obj]});
      }
    }
    reader.readAsDataURL(file[index]);
  };

  selectSection(obj: any, index: number) {
    if(this.isSaved) {
      this.categoryID = obj.ID;
      this.expandedFieldIndex = index; 
      this.categoryName = obj.categoryName;
    } 
  };

  //Upload files
  uploadFiles(obj: any) {
    for(let key in obj.categories) {

      if(obj.categories[key].categoryID === this.categoryID) {
        let filesToSave: any[] = [];
        for(let key2 in obj.categories[key].files) {
          if(obj.categories[key].files[key2]?.newFile) {
            filesToSave.push(obj.categories[key].files[key2])
          }
        }
        console.log(filesToSave)
        this.safetyService.uploadContractFiles(filesToSave).subscribe((httpResponse: any) => {

          let response: any = httpResponse.body;
          console.log(response);
          if(response) {
            this.isSaved = true;
            let i: number = 0;

            if(!obj.categories[key]?.ID) {
               obj.categories[key].ID = response[0].hubCategory;
             }

            for(let key3 in obj.categories[key].files) {
              if(obj.categories[key].files[key3]?.newFile) {
                obj.categories[key].files[key3].ID = response[i].truckID;
                obj.categories[key].files[key3].newFile = false;
                i++;
              }

            }
            obj.expandedData[this.expandedFieldIndex].uploaded = this.uploadedDate;
          }
        })
      }
    } 
  }

  refreshData() {
    this.subscription3 = this.safetyService.contractTableSubject.subscribe((response: any) => {
      this.dataSource = [];
      this.sortedData = [];
      this.error = false;
      this.loaded = false;
      this.spinner.show('trucks-files');
      this.getTableData();
    })
  }

  //Create truck file 
  createNewContractFile() {
    this.safetyService.templateSubject.next({header: '', template: '', footer: '', show_page_number: false, newTemplate: true});
  };

  //Expand row
  expandRow(element: any, index: number) {
    this.expandedElement = this.expandedElement === element ? null : element;
    this.expandedRow = index;
    this.categoryName = element.expandedData[0].categoryName;
    this.safetyService.getTemplateByDriverId(element.driverID).subscribe((response: any) => {
      element.categories = response;
      console.log(element);
    })
  }

  //Open file in new tab
  openPdfInNewTab(driverId: number, obj: any, date: string) {
    obj.pdfLoading = true;
    this.safetyService.getTemplatePdf(driverId, obj.id, date)
    .pipe(catchError((err: any) => {
      obj.pdfLoading = false;
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      obj.pdfLoading = false;
      console.log(response);
      const html = `<div">${response.body}</div>`;
      const pdfMakeContent = htmlToPdf(html); 
   
      var docDefinition: any = {
       pageSize: 'LETTER',
       pageMargins: [ 25, 80, 25, 80 ],
       footer: (currentPage: any, pageCount: any) => { 
         const pdfFooter = response.footer.replace('Page 1 of N', `Page ${currentPage.toString()} of ${pageCount}`);
         let finalOutput = `<div style="max-height: 90px;">${pdfFooter}</div>`;
         const pdfMakeFooter = htmlToPdf(finalOutput); 
         pdfMakeFooter[0].margin = [ 25, 10, 25, 10 ];
         return [
           pdfMakeFooter
         ]
       },
       header: (currentPage: any, pageCount, pageSize) => {
         const pdfHeader = `<div style="max-height: 90px;">${response.header}</div>`;
         const pdfMakeHeader = htmlToPdf(pdfHeader); 
         pdfMakeHeader[0].margin = [ 25, 10, 25, 10 ];
         return [
           pdfMakeHeader
         ]
       },
         content: pdfMakeContent
       };
   
      pdfmake.createPdf(docDefinition).open();
    })
  }

  openAllPdfsInNewTab(obj: any, date: string) {
    obj.finalPdfLoading = true;
    this.safetyService.getAllTemplatesByDriver(obj.driverID, date)
    .pipe(catchError((err: any) => {
      obj.finalPdfLoading = false;
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      console.log(response)
      let base64Array: string[] = [];
      for(let key in response) {
        const html = `<div">${response[key].body}</div>`;
        const pdfMakeContent = htmlToPdf(html); 
     
        var docDefinition: any = {
         pageSize: 'LETTER',
         pageMargins: [ 25, 80, 25, 80 ],
         footer: (currentPage: any, pageCount: any) => { 
           const pdfFooter = response[key].footer.replace('Page 1 of N', `Page ${currentPage.toString()} of ${pageCount}`);
           let finalOutput = `<div style="max-height: 90px;">${pdfFooter}</div>`;
           const pdfMakeFooter = htmlToPdf(finalOutput); 
           pdfMakeFooter[0].margin = [ 25, 10, 25, 10 ];
           return [
             pdfMakeFooter
           ]
         },
         header: (currentPage: any, pageCount, pageSize) => {
           const pdfHeader = `<div style="max-height: 90px;">${response[key].header}</div>`;
           const pdfMakeHeader = htmlToPdf(pdfHeader); 
           pdfMakeHeader[0].margin = [ 25, 10, 25, 10 ];
           return [
             pdfMakeHeader
           ]
         },
           content: pdfMakeContent
         };
     
         const pdfDocGenerator = pdfmake.createPdf(docDefinition);
         pdfDocGenerator.getBase64((data) => {
          base64Array.push(data)
          if(base64Array.length === response.length) {
            this.mergePdfs(base64Array);
          }
        })
      };
      obj.finalPdfLoading = false;
    });
  };

   async mergePdfs(base64Array: any[]) {
    const pdfDoc = await PDFDocument.create();

    for(let key in base64Array) {
      let doc: any = await PDFDocument.load(base64Array[key]);
      let docPage: any = await pdfDoc.copyPages(doc, doc.getPageIndices());
      docPage.forEach((page: any) => pdfDoc.addPage(page));
    }

    const base64 = await pdfDoc.saveAsBase64();
    const anchor = document.createElement('a');
    document.body.appendChild(anchor);
    anchor.style.display = 'none';
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);
    anchor.href = url; 
    anchor.target = '_blank';
    anchor.click();
    window.URL.revokeObjectURL(url);
  }

  //Action methods
  editTemplate(obj: any) {
    this.safetyService.templateSubject.next(obj);
  };

  deleteTemplate(templateId: number, element: any) {
    let dialogRef = this.dialog.open(DeleteTemplateDialogComponent, {
      autoFocus: false,
      panelClass: 'delete-dialog-container',
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
        this.safetyService.deleteTemplate(templateId).subscribe((response: any) => {
          if(response) {
            this.safetyService.getTemplateByDriverId(element.driverID).subscribe((response: any) => {
              element.categories = response;  
              console.log(response);
            })
          }
        });
      }
    });
  };

  deleteFile(categoryObj: any, obj: any, i: number, array: any[]) {
    let dialogRef: any = this.dialog.open(DeleteCategoryFileDialogComponent, {
      width: '454px',
      height: '482px',
      autoFocus: false,
      panelClass: 'delete-file-confirmation-dialog-container',
      data: {obj: obj, categories: this.categories, categoryId: this.categoryID}
    })
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
        this.fileUpload.nativeElement.value = '';
        this.isSaved = true;
        if(obj.ID) {
          this.safetyService.deleteContractFile(obj.ID).subscribe((response: any) => {
            console.log(response);
            if(response === 'OK') {
              array.splice(i, 1);
              delete categoryObj.expandedData[this.expandedFieldIndex].uploaded;
            }
          })
        } 
        else {
          array.splice(i, 1);
        }
      }
    });
  }

  warningDialog() {
    let obj: any = {};
    for(let i = 0; i < this.expandedElement?.categories.length; i++) {
      if(this.expandedElement.categories[i].categoryID === this.categoryID) {
        obj = this.expandedElement.categories[i];
        break;
      }
    }
    let dialogRef: any = this.dialog.open(WarningContractDialogComponent, {
      width: '454px',
      height: '482px',
      autoFocus: false,
      panelClass: 'warning-file-confirmation-dialog-container',
      data: {obj: obj, categories: this.categories, 
      unit_no: this.expandedElement.truckID }
    })
    dialogRef.afterClosed().subscribe((result: any) => {
      this.isSaved = true
      if(result) {
        this.uploadFiles(this.expandedElement);
      }
      else {
        this.fileUpload.nativeElement.value = '';
        this.expandedElement.categories.forEach(obj => {
          let files: any[] = [];
          obj.files.forEach((obj2: any) => {

            if(!obj2?.newFile) {
              files.push(obj2);
            }

          })
          obj.files = files;
        });
      }
    });
  };

  //Create category
  addNewCategory() {
    let dialogRef: any = this.dialog.open(AddContractCategoryDialogComponent, {
      width: '300px',
      autoFocus: false,
      panelClass: 'new-category-dialog-container'
    })
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
        this.getTableData();
      }
    });
  };

  editCategory() {
    this.dialog.open(EditContractCategoryDialogComponent, {
      width: '630px',
      maxHeight: '685px',
      autoFocus: false,
      panelClass: 'edit-category-dialog-container'
    })
  };

  //Sort data
  sortData(sort: Sort) {
    const data = this.dataSource.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }
    this.sortedData = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) { 
        case 'driverID':
          return compare(a.driverID, b.driverID, isAsc);
        case 'driver_name':
          return compare(a.driver_name, b.driver_name, isAsc);
        case 'truckID':
          return compare(a.truckID, b.truckID, isAsc);
        case 'lastUpdate':
          return compare(a.lastUpdate, b.lastUpdate, isAsc);
        default:
          return 0;
      }
    });
  };

  exportToExcel(columnsConfig: any[]) {
    if(this.rulesService.UserData[56].data[0].sectionArray[19].allowed) {
      const tableData: any[] = this.searchPipe.transform(this.sortedData, this.searchText, 'truckID');
      const excelTable: any[] = [];
      for(let i = 0; i < tableData.length; i++) {
        let obj: any = {};
        this.transformService.selectedColumn(obj, columnsConfig[0].columnName, i + 1, columnsConfig[0].selected);
        this.transformService.selectedColumn(obj, columnsConfig[1].columnName, tableData[i].driverID, columnsConfig[1].selected);
        this.transformService.selectedColumn(obj, columnsConfig[2].columnName, this.titleCase.transform(tableData[i].driver_name), columnsConfig[2].selected);
        this.transformService.selectedColumn(obj, columnsConfig[3].columnName, tableData[i].truckID, columnsConfig[3].selected);
        this.transformService.selectedColumn(obj, columnsConfig[4].columnName, this.transformDateFormat(tableData[i].lastUpdate), columnsConfig[4].selected);
        excelTable.push(obj);
      };
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelTable);
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, 'contract.xlsx');
    }
    else {
      this.msgForbbidenAccess();
    }
  };

  transformDateFormat(date: any) {
    if(date && (date.split('T')[0].split('-')[0] > 1900)) {
      return moment(date).format('MM DD. YYYY');
    }
    return '';
  };

  msgForbbidenAccess() {
    this.dialog.open(MsgForbbidenAccessComponent, {
      autoFocus: false,
      panelClass: 'forbidden-msg-dialog-container'
    })
  };

  //Templates dialog
  openTemplatesDialog() {
    this.dialog.open(TemplatesListDialogComponent, {
      autoFocus: false,
      panelClass: 'templates-list-dialog-container'
    })
  };

  //Policy dialog
  openPolicyDialog() {
    let dialogRef: any = this.dialog.open(PolicyDialogComponent, {
      autoFocus: false,
      panelClass: 'policy-dialog-main-container'
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result === 'Ok') {
        this.dialog.open(SuccessActionComponent, {
          autoFocus: false,
          panelClass: 'success-dialog-container'
        })
      }
    });
  };

  //Date clicked
  dateClicked(event: any, element: any) {
    element.date = moment(event._d).format('YYYY-MM-DD');
    element.isOpenCalendar = false;
  }

  ngOnDestroy(): void {
    this.subscription1?.unsubscribe();
    this.subscription2?.unsubscribe();
    this.subscription3?.unsubscribe();
  };

}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

import { Component, Input, ViewChild } from '@angular/core';
import { RulesService } from '@app/modules/shared/services/rules.service';
import { MsgForbbidenAccessComponent } from '@app/modules/shared/components/msg-forbbiden-access/msg-forbbiden-access.component';
import * as XLSX from 'xlsx';
import { MatDialog } from '@angular/material/dialog';
import { TransformService } from '@app/modules/shared/services/transform.service';
import { FilterStatementsPipe } from '../../pipes/filter-statements.pipe';
import { DriversStatementsTableComponent } from './drivers-statements-table/drivers-statements-table.component';

@Component({
  selector: 'drivers-statements-activity-table',
  templateUrl: './drivers-statements-activity-table.component.html',
  styleUrls: ['./drivers-statements-activity-table.component.scss']
})
export class DriversStatementsActivityTableComponent {
  @Input() profileData: any;
  @ViewChild(DriversStatementsTableComponent) childComponent: any;
  monthlyView: boolean = false;

  //Excel config
  xlsxConfig: any = [
    {columnName: 'Gross', selected: true},
    {columnName: 'Driver gross', selected: true},
    {columnName: 'Billable miles', selected: true},
    {columnName: 'System miles', selected: true},
    {columnName: 'Rate', selected: true},
    {columnName: 'Loads', selected: true},
    {columnName: 'Hometime', selected: true},
    {columnName: 'Deal', selected: true},
    {columnName: 'Paycheck', selected: true}
  ];

  constructor(private dialog: MatDialog,
              private transformService: TransformService,
              private rulesService: RulesService,
              private filterStatementsPipe: FilterStatementsPipe) { }

  changePreview(event: any) {
    this.monthlyView = !this.monthlyView;
    event.preventDefault();
  };

  exportToExcel(columnsConfig: any[]) {
    if(this.rulesService.UserData[56].data[0].sectionArray[45].allowed) {
      const tableData: any[] = this.filterStatementsPipe.transform(this.childComponent.tableData, this.childComponent.avgFleet, this.childComponent.showNumOfWeeks, this.childComponent.metaObj);
      const excelTable: any[] = [];
      for(let i = 0; i < tableData.length; i++) {
        let obj: any = {};
        this.transformService.selectedColumn(obj, columnsConfig[0].columnName, `$${this.transformService.addCommasDots(tableData[i].gross, 'round')}`, columnsConfig[0].selected);
        this.transformService.selectedColumn(obj, columnsConfig[1].columnName, `$${this.transformService.addCommasDots(tableData[i].driver_gross, 'round')}`, columnsConfig[1].selected);
        this.transformService.selectedColumn(obj, columnsConfig[2].columnName, this.transformService.addCommasDots(tableData[i].mileage, 'round'), columnsConfig[2].selected);
        this.transformService.selectedColumn(obj, columnsConfig[3].columnName, this.transformService.addCommasDots(tableData[i].paid_mileage, 'round'), columnsConfig[3].selected);
        this.transformService.selectedColumn(obj, columnsConfig[4].columnName, `$${this.transformService.addCommasDots(tableData[i].rate)}`, columnsConfig[4].selected);
        this.transformService.selectedColumn(obj, columnsConfig[5].columnName, this.transformService.addCommasDots(tableData[i].num_of_loads, 'round') , columnsConfig[5].selected);
        this.transformService.selectedColumn(obj, columnsConfig[6].columnName, tableData[i].home_time, columnsConfig[6].selected);
        this.transformService.selectedColumn(obj, columnsConfig[7].columnName, this.childComponent.paySign.includes(this.childComponent.avgFleet.driver_status) ? ('$' + this.transformService.addCommasDots(tableData[i].deal)) : (this.transformService.addCommasDots((tableData[i].deal * 100), 'round') +'%') , columnsConfig[7].selected);
        this.transformService.selectedColumn(obj, columnsConfig[8].columnName, `$${this.transformService.addCommasDots(tableData[i].statement, 'round')}`, columnsConfig[8].selected);
        excelTable.push(obj);
      };
      let footerObj: any = {};
      this.transformService.selectedColumn(footerObj, columnsConfig[0].columnName, this.childComponent.metaObj.countObj.grossAvg, columnsConfig[0].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[1].columnName, this.childComponent.metaObj.countObj.driverGrossAvg, columnsConfig[1].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[2].columnName, this.childComponent.metaObj.countObj.mileageAvg, columnsConfig[2].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[3].columnName, this.childComponent.metaObj.countObj.paidMilesAvg, columnsConfig[3].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[4].columnName, this.childComponent.metaObj.countObj.rateAvg, columnsConfig[4].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[5].columnName, this.childComponent.metaObj.countObj.loadsAvg, columnsConfig[5].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[6].columnName, this.childComponent.metaObj.countObj.hometimeAvg, columnsConfig[6].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[7].columnName, this.childComponent.metaObj.countObj.dealAvg, columnsConfig[7].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[8].columnName, this.childComponent.metaObj.countObj.paycheckAvg, columnsConfig[8].selected);
      excelTable.push(footerObj);
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelTable);
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, 'statements-table.xlsx');
    }
    else {
      this.msgForbbidenAccess();
    }
  };

  msgForbbidenAccess() {
    this.dialog.open(MsgForbbidenAccessComponent, {
      autoFocus: false,
      panelClass: 'forbidden-msg-dialog-container'
    })
  };

}
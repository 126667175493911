import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

import { FullAppExpensesDialogComponent } from '../../shared/components/full-app-expenses-dialog/full-app-expenses-dialog.component';
import { FullAppService } from '@app/modules/full-app/services/full-app.service';
import { SharedService } from '@app/modules/shared/services/shared.service';
import { IExpenses, IExpensesFiles } from '@app/modules/full-app/models/expenses-model';
import { BadDocumentation, IBadDocumentation } from '@app/modules/full-app/models/bad-documentation.model';
import { DeleteConfirmationDialogComponent } from '@app/modules/shared/components/delete-confirmation-dialog/delete-confirmation-dialog.component';

@Component({
  selector: 'full-application-expenses',
  templateUrl: './full-application-expenses.component.html',
  styleUrls: ['./full-application-expenses.component.scss']
})
export class FullApplicationExpensesComponent implements OnInit, OnDestroy {
  @Input() id: number;

  //Table data
  dataSource: IExpenses[] = [];
  files: IExpensesFiles[]= [];

  //Status dropdown array
  statusDropdownArray: string[] = ['Completed', 'Pending', 'Not Completed'];

  //Subscription
  subscription: Subscription = new Subscription();

  constructor(private dialog: MatDialog,
              private fullAppService: FullAppService,
              private sharedService: SharedService) { }

  ngOnInit(): void {
    this.getTableData();
  };

  getTableData() {
    this.subscription = this.fullAppService.getAllExpenses(this.id).subscribe((response: IExpenses[]) => {
      console.log(response);
      this.dataSource = response;
    });
  };

  changeStatus(element: IExpenses | any, value: string, index: number) {
    element.isOpenStatusMenu = !element.isOpenStatusMenu;
    let array: IExpenses[] = JSON.parse(JSON.stringify(this.dataSource));
    array[index].status = value;
    this.saveExpense(array, index, false);
  };

  createUpdateExpense() {
    let dialogRef = this.dialog.open(FullAppExpensesDialogComponent, {
      autoFocus: false,
      panelClass: 'expenses-dialog-container',
      disableClose: true,
      data: {data: this.dataSource, driverId: this.id}
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
        this.dataSource = result;
      }
    });
  };

  missingDocumentation(element: IExpenses | any, fileObj: IExpensesFiles) {
    let obj: IBadDocumentation = new BadDocumentation(0, element.id, fileObj.name);
    this.fullAppService.markBadDocumentation(obj).subscribe((response: IBadDocumentation) => {
      if(response?.id) {
        element.error = element.error ? 0 : 1;
      }
    });
  };

  //Select files
  onFileSelected(event: any, index: number) {  
    let length: any = Object.keys(event.target.files).length;
    for(let i = 0; i < length; i++) {
      this.addMultipleFiles(event, i, length, index);
    }
  }

  addMultipleFiles(event: any, i: number, length: number, index: number) {
    let filename = event.target.files[i].name;
    let reader = new FileReader();
    reader.onload = (e: any) => {
      let base64 = e.target.result.split(',')[1];
      let obj: IExpensesFiles = {
        'category': '',
        'name': filename,
        'data': base64,
        'updated': false,
        'deleted': false
      }
      this.files.push(obj);
      if(this.files.length === length) {
        let array: IExpenses[] = JSON.parse(JSON.stringify(this.dataSource));
        array[index].files = array[index].files.concat(this.files);
        this.saveExpense(array, index, true)
      }
    };
    reader.readAsDataURL(event.target.files[i]);
  };

  saveExpense(array: any[], index: number, expandRow: boolean) {
    this.fullAppService.createUpdateExpense(array).subscribe((response: IExpenses[] | any) => {
      console.log(response);
      if(response.length > 0) {
        if(expandRow) {
          response[index].expandRow = true;
        }
        this.dataSource = response;
      }
    });
  };
  
  deleteFile(elementObj: IExpenses, fileObj: IExpensesFiles, index: number) {
    let dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
      autoFocus: false,
      panelClass: 'delete-dialog-container',
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
        this.fullAppService.deleteFileFromExpense(elementObj.employee_id, elementObj.id, fileObj.name).subscribe((response: boolean) => {
          if(response) {
            elementObj.files.splice(index, 1);
          };
        });
      }
    });
  };

  deleteExpense(obj: IExpenses, index: number) {
    let dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
      autoFocus: false,
      panelClass: 'delete-dialog-container',
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
        this.fullAppService.deleteExpense(obj.employee_id, obj.id).subscribe((response: boolean) => {
          if(response) {
            this.dataSource.splice(index, 1);
          };
        });
      }
    });
  };

  openFileInNewTab(file: IExpensesFiles) {
    this.sharedService.downloadPreviewFile(file.name, file.data, true);
  };

  downloadFile(obj: IExpensesFiles) {
    const extension = obj.name.split('.').pop().toLowerCase();
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp', 'svg', 'tiff', 'ico'];
    let base64: string = '';
    if(extension === 'pdf') {
      base64 = `data:application/pdf;base64,${obj.data}`
    }
    if(imageExtensions.includes(extension)) {
      base64 =  `data:image/${extension === 'jpg' ? 'jpeg' : extension};base64,${obj.data}`;
    }
    if(extension === 'docx') {
      base64 = `data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,${obj.data}`
    }
    if(extension === 'doc') {
      base64 = `data:application/msword;base64,${obj.data}`
    }
    let anchor = document.createElement('a');
    anchor.download = obj.name;
    anchor.href = base64;
    anchor.click();
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();  
  };

}

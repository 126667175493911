import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { DateRange } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import moment = require('moment');
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription, catchError, throwError } from 'rxjs';
import { InfoMapDialogComponent } from './info-map-dialog/info-map-dialog.component';

import { LoadsDocumentationDialogComponent } from '@app/modules/shared/components/loads-documentation-dialog/loads-documentation-dialog.component';
import { LoadAnalysisService } from '../../services/load-analysis.service';
import { TransformService } from '@app/modules/shared/services/transform.service';
import { SharedService } from '@app/modules/shared/services/shared.service';
import * as XLSX from 'xlsx';
import { TitleCasePipe } from '@angular/common';
import { RulesService } from '@app/modules/shared/services/rules.service';
import { MsgForbbidenAccessComponent } from '@app/modules/shared/components/msg-forbbiden-access/msg-forbbiden-access.component';

@Component({
  selector: 'app-brokers-search-table',
  templateUrl: './brokers-search-table.component.html',
  styleUrls: ['./brokers-search-table.component.scss']
})
export class BrokersSearchTableComponent implements OnInit, OnDestroy {
  dataSource: any[] = [];

  sortedData: any[] = [];

  displayedColumns: string[] = ['position', 'broker_company', 'from_city', 'to_city', 'pu_date', 'del_date', 'lenght',
  'amount', 'rate', 'weight', 'detention_time', 'comodity', 'rank', 'info'];

  className: string = '';
  columnName: string = '';

  //Filters

  brokersArray: any[] = [];
  brokersArrayCopy: any[] = [];

  isOpenBroker: boolean = false;
  brokersSearch: string = '';
  allBrokers: any = {selected: false};

  //Rank
  rankMin: number | undefined;
  rankMax: number | undefined;

  //Calendar
  isOpenCalendar: boolean = false;
  period: string = 'Choose a date';
  @Input() selectedRangeValue: DateRange<Date> | any;
  @Output() selectedRangeValueChange = new EventEmitter<DateRange<Date>>();
  dateRange: any = {
    startDate: moment('01/01/2010').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD')
  };

  //Location autocomplete
  @Input() adressType: string = '(cities)';
  @Output() setAddress: EventEmitter<any> = new EventEmitter();
  @ViewChild('from') from: any;
  @ViewChild('to') to: any;


  fromInput: string = '';
  cityFrom: string | undefined;
  stateFromShort: | undefined;

  toInput: string;
  stateToShort: string | undefined;
  cityTo: string | undefined;

  //Zip code
  zipCodesFromArray: any[] = [];
  isOpenFromZip: boolean = false;
  zipCodeFrom: string | undefined;

  zipCodesToArray: any[] = [];
  isOpenToZip: boolean = false;
  zipCodeTo: string | undefined;
  
  //Radius 
  radiusFromMiles: number | undefined;
  radiusToMiles: number | undefined;

  //Load length
  loadMin: number | undefined;
  loadMax: number | undefined;

  //Amount
  amountMax: number | undefined;
  amountMin: number | undefined;

  //Rate per mile
  rpmMin: number | undefined;
  rpmMax: number | undefined;

  //Weight
  weightMin: number | undefined;
  weightMax: number | undefined;

  //Commodity
  isOpenCommodity: boolean = false;
  commoditySearch: string = '';
  allCommodity: any = {selected: false};

  //Location
  locationArray: any[] = [];
  isOpenLocationFromMenu: boolean = false;
  isOpenLocationToMenu: boolean = false;

  commodityArray: any[] = [];
  commodityArrayCopy: any[] = [];

  //Num of loads
  numOfLoads: number | undefined;

  //Brokers table total, avg obj
  calculationObj: any = {
    totalLoads: 0,
    avgRpm: 0,
    totalMileage: 0,
    totalPaidMiles: 0,
    totalLoadedMiles: 0,
    totalEmptyMiles: 0,
    avgCompanyBenefitMilesPercent: 0,
    totalFreightAmount: 0,
    totalPuStopAmount: 0,
    avgMarginPercent: 0,
    avgWeight: 0,
    rpm: 0,
    detentionTime: 0
  }

  //Search
  isOpenSearch: boolean = false;
  searchText: string = '';
  selectedUsers: any[] = new Array<any>();
  dataTypingArray: any[] = [];

  //Badge broker
  numOfSelectedBrokers: number = 0;

  //Badge commodity
  numOfSelectedCommodities: number = 0;

  //Excel config
  xlsxConfig: any = [
    {columnName: 'No.', selected: true},
    {columnName: 'BROKER', selected: true},
    {columnName: 'BROKER LOAD NUMBER', selected: true},
    {columnName: 'LOAD#', selected: true},
    {columnName: 'DISPATCHER', selected: true},
    {columnName: 'DRIVER', selected: true},
    {columnName: 'TRUCK#', selected: true},
    {columnName: 'TYPE', selected: true},
    {columnName: 'PU DATE', selected: true},
    {columnName: 'DEL DATE', selected: true},
    {columnName: 'FROM', selected: true},
    {columnName: 'TO', selected: true},
    {columnName: 'RATE', selected: true},
    {columnName: 'MILEAGE', selected: true},
    {columnName: 'PAID MILES', selected: true},
    {columnName: 'LOADED MILES', selected: true},
    {columnName: 'EMPTY MILES', selected: true},
    {columnName: 'COMPANY BENEFIT MILES %', selected: true},
    {columnName: 'FREIGHT AMOUNT', selected: true},
    {columnName: 'PU STOP AMOUNT', selected: true},
    {columnName: 'MARGIN %', selected: true},
    {columnName: 'WEIGHT', selected: true},
    {columnName: 'STATUS', selected: true},
    {columnName: 'DETENTION TIME', selected: true},
    {columnName: 'COMMODITY', selected: true},
    {columnName: 'RANK', selected: true},
  ]

  loaded: boolean = true;

  error: boolean = false;

  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";

  spinnerBgColor: string = this.transformService.spinnerBgColor;
  spinnerType: string = this.transformService.spinnerType;

  subscription1: Subscription | any;
  subscription2: Subscription | any;

  constructor(public transformService: TransformService,
              private loadAnalysisService: LoadAnalysisService,
              private sharedService: SharedService,
              private dialog: MatDialog,
              private spinner: NgxSpinnerService,
              private titleCase: TitleCasePipe,
              private rulesService: RulesService) { }

  ngOnInit(): void {
    this.subscription1 = this.sharedService.getAllBrokers().subscribe((response: any) => {
      this.brokersArray = response.brokers;
      this.commodityArray = response.compmodities;
      this.zipCodesFromArray = JSON.parse(JSON.stringify(response.zip_codes));
      this.zipCodesToArray = JSON.parse(JSON.stringify(response.zip_codes));
      this.brokersArrayCopy = JSON.parse(JSON.stringify(response.brokers));
      this.commodityArrayCopy = JSON.parse(JSON.stringify(response.compmodities));
      console.log(response);
    });

    this.subscription2 = this.sharedService.getAllLocationInfo().subscribe((response: any) => {
      console.log(response);
      this.locationArray = response;
    });
  }

  sortData(sort: Sort) {
    const data = this.dataSource.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'broker_company':
          return compare(a.broker_company, b.broker_company, isAsc);
        case 'broker_load_nunmber':
          return compare(a.broker_load_nunmber, b.broker_load_nunmber, isAsc);
        case 'load_no':
          return compare(a.load_no, b.load_no, isAsc);
        case 'dispatcher':
          return compare(a.dispatcher, b.dispatcher, isAsc);
        case 'driver':
          return compare(a.driver, b.driver, isAsc);
        case 'type':
          return compare(a.type, b.type, isAsc);
        case 'pu_date':
          return compare(a.pu_date, b.pu_date, isAsc);
        case 'del_date':
          return compare(a.del_date, b.del_date, isAsc);
        case 'from_city':
          return compare(a.from_city, b.from_city, isAsc);
        case 'to_city':
          return compare(a.to_city, b.to_city, isAsc);
        case 'rate':
          return compare(a.rate, b.rate, isAsc);
        case 'mileage':
          return compare(a.mileage, b.mileage, isAsc);
        case 'paid_miles':
          return compare(a.paid_miles, b.paid_miles, isAsc);
        case 'loaded_miles':
          return compare(a.loaded_miles, b.loaded_miles, isAsc);
        case 'empty_miles':
          return compare(a.empty_miles, b.empty_miles, isAsc);
        case 'company_benefit_miles_percent':
          return compare(a.company_benefit_miles_percent, b.company_benefit_miles_percent, isAsc);
        case 'freight_amount':
          return compare(a.freight_amount, b.freight_amount, isAsc);
        case 'stop_amont':
          return compare(a.stop_amont, b.stop_amont, isAsc);
        case 'margint_percent':
          return compare(a.margint_percent, b.margint_percent, isAsc);
        case 'weight':
          return compare(a.weight, b.weight, isAsc);
        case 'status':
          return compare(a.status, b.status, isAsc);
        case 'detention_time':
          return compare(a.detention_time, b.detention_time, isAsc);
        case 'comodity':
          return compare(a.comodity, b.comodity, isAsc);
        case 'rank':
          return compare(a.rank, b.rank, isAsc);
        default:
          return 0;
      }
    });
  }

  onMouseOver(columnName: string) {
    this.columnName = columnName;
    this.className = 'hovered-column';
  }

  onMouseOut(columnName: string) {
    this.columnName = columnName;
    this.className = '';
  }

  //Filters
  selectAllBrokers() {
    let complete: boolean = JSON.parse(JSON.stringify(this.allBrokers.selected = !this.allBrokers.selected));
    this.brokersArray.forEach(t => {(t.selected = complete)});
    
    if(complete) {
      this.brokersSearch = 'All brokers';
      this.numOfSelectedBrokers = this.brokersArray.length;
    } else {
      this.brokersSearch = '';
      this.numOfSelectedBrokers = 0;
    }
  }

  selectBrokers(obj: any) {
    obj.selected = !obj.selected
    if(obj.selected) {
      this.numOfSelectedBrokers++;
      this.brokersArray = [...this.brokersArray.sort((x, y) => {
        return (x.selected === y.selected) ? 0 : x.selected ? -1 : 1;
     })];
    } else {
      this.numOfSelectedBrokers--;
    }
    console.log(this.brokersArray)
  }

  //Commodities
  selectAllCommodities(array: any[], isSelected: any) {
    let complete: boolean = JSON.parse(JSON.stringify(isSelected.selected = !isSelected.selected));
    array.forEach(t => {(t.selected = complete)});
    if(complete) {
      this.commoditySearch = 'All commodities';
      this.numOfSelectedCommodities = array.length;
    } else {
      this.commoditySearch = '';
      this.numOfSelectedCommodities = 0;
    }
  }

  selectCommodities(obj: any) {
    obj.selected = !obj.selected;
    if(obj.selected) {
      this.numOfSelectedCommodities++;
      this.commodityArray = [...this.commodityArray.sort((x, y) => {
        return (x.selected === y.selected) ? 0 : x.selected ? -1 : 1;
     })];
    } else {
      this.numOfSelectedCommodities--;
    }
  }

  //Calendar
  selectedChange(m: any) {
    if (!this.selectedRangeValue?.start || this.selectedRangeValue?.end) {
      this.selectedRangeValue = new DateRange<Date>(m, null);
    } else {
      const start = this.selectedRangeValue.start;
      const end = m;
      if (end < start) {
          this.selectedRangeValue = new DateRange<Date>(end, start);
      } else {
          this.selectedRangeValue = new DateRange<Date>(start, end);
      }
    }
  this.selectedRangeValueChange.emit(this.selectedRangeValue);
    if(this.selectedRangeValue.start && this.selectedRangeValue.end) {
      this.dateRange.startDate = moment(this.selectedRangeValue.start._d).format('YYYY-MM-DD');
      this.dateRange.endDate = moment(this.selectedRangeValue.end._d).format('YYYY-MM-DD');
      this.isOpenCalendar = false;
      this.period = moment(this.selectedRangeValue.start._d).format('MM/DD/yyyy') + ' - ' +  moment(this.selectedRangeValue.end._d).format('MM/DD/yyyy');
    }
  }

  //Select zip
  selectZipCode(value: any, from: boolean) {
    if(from) {
      this.zipCodeFrom = value;
      this.isOpenFromZip = false;
    } else {
      this.zipCodeTo = value;
      this.isOpenToZip = false;
    }
  }

  countAvg(data: any) {
    let totalLoads: number = data.length;
    let mileage: number = 0;
    let paidMiles: number = 0;
    let loadedMiles: number = 0;
    let emptyMiles: number = 0;
    let freightAmount: number = 0;
    let puStopAmount: number = 0;
    let weight: number = 0;
    let sumDetentionTime: number = 0;

    let marginPercentConditions: string[] = ['SOLO COMPANY', 'TEAM COMPANY'];
    let sumFreightAmountOnlyRentAndOwner: number = 0;

    for(let key in data) {
      mileage += data[key].mileage;
      paidMiles += data[key].paid_miles;
      loadedMiles += data[key].loaded_miles;
      emptyMiles += data[key].empty_miles;
      freightAmount += data[key].freight_amount;
      puStopAmount += data[key].stop_amont;
      weight += data[key].weight;
      sumDetentionTime += data[key].seconds;

      if(!marginPercentConditions.includes(data[key].type)) {
        sumFreightAmountOnlyRentAndOwner += data[key].freight_amount;
      }
    };

    this.calculationObj.totalLoads = totalLoads;
    this.calculationObj.avgRpm = freightAmount / mileage;
    this.calculationObj.totalMileage = mileage / totalLoads;
    this.calculationObj.totalPaidMiles = paidMiles;
    this.calculationObj.totalLoadedMiles = loadedMiles;
    this.calculationObj.totalEmptyMiles = emptyMiles;
    this.calculationObj.avgCompanyBenefitMilesPercent = (mileage - paidMiles) / mileage * 100;
    this.calculationObj.totalFreightAmount = freightAmount;
    this.calculationObj.totalPuStopAmount = puStopAmount;
    this.calculationObj.avgMarginPercent = ((freightAmount - puStopAmount) / (sumFreightAmountOnlyRentAndOwner) * 100);
    this.calculationObj.avgWeight = weight / totalLoads;
    this.calculationObj.rpm = freightAmount / mileage;
    this.calculationObj.detentionTime = this.secondsToDhms(sumDetentionTime / totalLoads);
  };

  secondsToDhms(seconds: number) {
    seconds = Number(seconds)
    let d = Math.floor(seconds / (3600 * 24));
    let h = Math.floor((seconds % (3600 * 24)) / 3600);
    let m = Math.floor((seconds % 3600) / 60);

    let time: string = '';

    if(d > 0 && h > 0 && m > 0) {
      time = d+'d ' + h + ':' + m + 'h';
    } 
    else if(h > 0 && m > 0) {
      time = h + ':' + m + 'h';
    }
    else {
      time = m + 'h';
    }
    return time;
  }


  setLocation(obj: any, isFrom: boolean) {
    if(isFrom) {
      this.cityFrom = obj.city;
      this.stateFromShort = obj.state;
      this.fromInput = `${obj.city}, ${obj.state}, USA`;
      this.isOpenLocationFromMenu = false;
    }
    else {
      this.cityTo = obj.city;
      this.stateToShort = obj.state;
      this.toInput = `${obj.city}, ${obj.state}, USA`;
      this.isOpenLocationToMenu = false;
    }
  }

  //Search 
  search() {
    if(this.fromInput === '') {
      this.stateFromShort = undefined;
      this.cityFrom = undefined;
    }

    if(this.toInput === '') {
      this.stateToShort = undefined;
      this.cityTo = undefined;
    }

    this.error = false;
    this.loaded = false;
    this.sortedData = [];
    this.spinner.show('brokers-search-table');
    let obj: any = {
      startDate: this.dateRange.startDate,
      endDate: this.dateRange.endDate,
      f_state: this.stateFromShort,
      f_city: this.cityFrom,
      f_zip: this.zipCodeFrom,
      f_radius: this.radiusFromMiles,
      t_state: this.stateToShort,
      t_city: this.cityTo,
      t_zip: this.zipCodeTo,
      t_radius: this.radiusToMiles,
      min_length: this.loadMin,
      max_length: this.loadMax,
      min_amount: this.amountMin,
      max_amount: this.amountMax,
      min_rate: this.rpmMin,
      max_rate: this.rpmMax,
      min_weight: this.weightMin,
      max_weight: this.weightMax,
      min_rank: this.rankMin,
      max_rank: this.rankMax,
      comodity: this.returnSelectedValues(this.commodityArray, false),
      broker: this.returnSelectedValues(this.brokersArray, true)
    }
    console.log(obj)
    this.subscription1 = this.loadAnalysisService.getBrokersTableWithFilter(obj, this.transformService.filterParams)
      .pipe(catchError((err: any) => {
        this.spinner.hide('brokers-search-table');
        this.loaded = true;
        this.error = true;
        return throwError(() => err);
      }))
      .subscribe((response: any) => {
        this.dataSource = JSON.parse(JSON.stringify(response));
        this.dataTypingArray = JSON.parse(JSON.stringify(response));
        this.sortedData = response;
        this.numOfLoads = response.length;
        this.spinner.hide('brokers-search-table');
        this.loaded = true;
        this.countAvg(response);
        console.log(response)
      });
  };

  returnSelectedValues(array: any[], broker: boolean) {
    let dataForServer: any[] = [];

    for(let key in array) {
      if(array[key].selected && broker) {
        dataForServer.push({id: array[key].id})
      }
      if(array[key].selected && !broker) {
        dataForServer.push(array[key].name);
      }
    }
    return dataForServer;
  }

  reset() {
    this.period = 'Choose a date';
    this.dateRange.startDate = this.transformService.convertDateToTimestamp(moment('01/01/2000').format('ddd, DD/MM YYYY'),
    '00:00:00');
    this.dateRange.endDate = this.transformService.convertDateToTimestamp(moment().format('ddd, DD/MM YYYY'), moment().format('HH:mm:ss'));
    this.stateFromShort = undefined;
    this.fromInput = '';
    this.cityFrom = undefined;
    this.zipCodeFrom = undefined;
    this.radiusFromMiles = undefined;
    this.toInput = '';
    this.stateToShort = undefined;
    this.cityTo = undefined;
    this.zipCodeTo = undefined;
    this.radiusToMiles = undefined;
    this.loadMin = undefined;
    this.loadMax = undefined;
    this.amountMin = undefined;
    this.amountMax = undefined;
    this.rpmMin = undefined;
    this.rpmMax = undefined;
    this.weightMin = undefined;
    this.weightMax = undefined;
    this.rankMin = undefined;
    this.rankMax = undefined;
    this.commodityArray = this.commodityArrayCopy;
    this.commoditySearch = ''; 
    this.brokersArray = this.brokersArrayCopy;
    this.brokersSearch = '';
    this.numOfSelectedBrokers = 0;
    this.commoditySearch = '';
    this.numOfSelectedBrokers = 0;
  }

  //Search methods
  toggleSelection(user: any) {
    user.selected = !user.selected;
    if (user.selected) {
      this.selectedUsers.push(user);
      this.sortedData = JSON.parse(JSON.stringify(this.selectedUsers));
    } else {
      const i = this.selectedUsers.findIndex(value => value.broker_company === user.broker_company);
      this.selectedUsers.splice(i, 1);
      this.sortedData = JSON.parse(JSON.stringify(this.selectedUsers));
    }
    this.sortedData.length === 0 ? this.sortedData = JSON.parse(JSON.stringify(this.dataSource)) : this.sortedData
  }

  resetCheckedValue() {
    let data: any = JSON.parse(JSON.stringify(this.dataSource));
    this.sortedData = data;
    this.dataTypingArray = data;
    this.searchText = '';
    this.selectedUsers = [];
  }

  //Open dialog
  openDialog(obj: any) {
    this.dialog.open(InfoMapDialogComponent, {
      width: '915px',
      height: '730px',
      autoFocus: false,
      panelClass: 'info-map-dialog-container',
      data: obj
    });
  }

  openFilesForDownload(loadNo: any) {
    this.sharedService.getAllFilesLoads(loadNo).subscribe((response) => {
      console.log(response);
      this.dialog.open(LoadsDocumentationDialogComponent, {
        width: '500px',
        height: '500px',
        panelClass: 'download-files-dialog',
        autoFocus: false,
        data: {fileNames: response, loadNo: loadNo}
      });
    });
  };

  exportToExcel(columnsConfig: any[]) {
    if(this.rulesService.UserData[56].data[0].sectionArray[38].allowed) {
      const excelTable: any[] = [];
      for(let i = 0; i < this.sortedData.length; i++) {
        let obj: any = {};
        this.transformService.selectedColumn(obj, columnsConfig[0].columnName, i + 1, columnsConfig[0].selected);
        this.transformService.selectedColumn(obj, columnsConfig[1].columnName, this.titleCase.transform(this.sortedData[i].broker_company), columnsConfig[1].selected);
        this.transformService.selectedColumn(obj, columnsConfig[2].columnName, this.sortedData[i].broker_load_nunmber, columnsConfig[2].selected);
        this.transformService.selectedColumn(obj, columnsConfig[3].columnName, this.sortedData[i].load_no, columnsConfig[3].selected);
        this.transformService.selectedColumn(obj, columnsConfig[4].columnName, this.titleCase.transform(this.sortedData[i].dispatcher), columnsConfig[4].selected);
        this.transformService.selectedColumn(obj, columnsConfig[5].columnName,this.titleCase.transform( this.sortedData[i].driver), columnsConfig[5].selected);
        this.transformService.selectedColumn(obj, columnsConfig[6].columnName, this.sortedData[i].unit_no, columnsConfig[6].selected);
        this.transformService.selectedColumn(obj, columnsConfig[7].columnName, this.sortedData[i].type, columnsConfig[7].selected);
        this.transformService.selectedColumn(obj, columnsConfig[8].columnName, this.transformService.transformDateFormat(this.sortedData[i].pu_date, 'MM/DD/YYYY'), columnsConfig[8].selected);
        this.transformService.selectedColumn(obj, columnsConfig[9].columnName, this.transformService.transformDateFormat(this.sortedData[i].del_date, 'MM/DD/YYYY'), columnsConfig[9].selected);
        this.transformService.selectedColumn(obj, columnsConfig[10].columnName, this.sortedData[i].from_city, columnsConfig[10].selected);
        this.transformService.selectedColumn(obj, columnsConfig[11].columnName, this.sortedData[i].to_city, columnsConfig[11].selected);
        this.transformService.selectedColumn(obj, columnsConfig[12].columnName, `$${this.transformService.addCommasDots(this.sortedData[i].rate)}`, columnsConfig[12].selected);
        this.transformService.selectedColumn(obj, columnsConfig[13].columnName, this.transformService.addCommasDots(this.sortedData[i].mileage, 'round'), columnsConfig[13].selected);
        this.transformService.selectedColumn(obj, columnsConfig[14].columnName, this.transformService.addCommasDots(this.sortedData[i].paid_miles, 'round'), columnsConfig[14].selected);
        this.transformService.selectedColumn(obj, columnsConfig[15].columnName, this.transformService.addCommasDots(this.sortedData[i].loaded_miles, 'round'), columnsConfig[15].selected);
        this.transformService.selectedColumn(obj, columnsConfig[16].columnName, this.transformService.addCommasDots(this.sortedData[i].empty_miles, 'round'), columnsConfig[16].selected);
        this.transformService.selectedColumn(obj, columnsConfig[17].columnName, `${this.transformService.addCommasDots(this.sortedData[i].company_benefit_miles_percent, 'round')}%`, columnsConfig[17].selected);
        this.transformService.selectedColumn(obj, columnsConfig[18].columnName, `$${this.transformService.addCommasDots(this.sortedData[i].freight_amount, 'round')}`, columnsConfig[18].selected);
        this.transformService.selectedColumn(obj, columnsConfig[19].columnName, `$${this.transformService.addCommasDots(this.sortedData[i].stop_amont, 'round')}`, columnsConfig[19].selected);
        this.transformService.selectedColumn(obj, columnsConfig[20].columnName, `${this.transformService.addCommasDots(this.sortedData[i].margint_percent, 'round')}%`, columnsConfig[20].selected);
        this.transformService.selectedColumn(obj, columnsConfig[21].columnName, this.transformService.addCommasDots(this.sortedData[i].weight, 'round'), columnsConfig[21].selected);
        this.transformService.selectedColumn(obj, columnsConfig[22].columnName, this.titleCase.transform(this.sortedData[i].status), columnsConfig[22].selected);
        this.transformService.selectedColumn(obj, columnsConfig[23].columnName, this.sortedData[i].detention_time, columnsConfig[23].selected);
        this.transformService.selectedColumn(obj, columnsConfig[24].columnName, this.titleCase.transform(this.sortedData[i].comodity), columnsConfig[24].selected);
        this.transformService.selectedColumn(obj, columnsConfig[25].columnName, this.sortedData[i].rank, columnsConfig[25].selected);
        excelTable.push(obj);
      };
      let footerObj: any = {};
      this.transformService.selectedColumn(footerObj, columnsConfig[0].columnName, this.transformService.addCommasDots(this.calculationObj.totalLoads, 'round'), columnsConfig[0].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[1].columnName, '', columnsConfig[1].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[2].columnName, '', columnsConfig[2].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[3].columnName, '', columnsConfig[3].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[4].columnName, '', columnsConfig[4].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[5].columnName, '', columnsConfig[5].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[6].columnName, '', columnsConfig[6].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[7].columnName, '', columnsConfig[7].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[8].columnName, '', columnsConfig[8].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[9].columnName, '', columnsConfig[9].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[10].columnName, '', columnsConfig[10].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[11].columnName, '', columnsConfig[11].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[12].columnName, `$${this.transformService.addCommasDots(this.calculationObj.rpm)}`, columnsConfig[12].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[13].columnName, this.transformService.addCommasDots(this.calculationObj.totalMileage, 'round'), columnsConfig[13].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[14].columnName, this.transformService.addCommasDots(this.calculationObj.totalPaidMiles, 'round'), columnsConfig[14].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[15].columnName, this.transformService.addCommasDots(this.calculationObj.totalLoadedMiles, 'round'), columnsConfig[15].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[16].columnName, this.transformService.addCommasDots(this.calculationObj.totalEmptyMiles, 'round'), columnsConfig[16].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[17].columnName, `${this.transformService.addCommasDots(this.calculationObj.avgCompanyBenefitMilesPercent, 'round')}%`, columnsConfig[17].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[18].columnName, this.transformService.addCommasDots(this.calculationObj.totalFreightAmount, 'round'), columnsConfig[18].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[19].columnName, this.transformService.addCommasDots(this.calculationObj.totalPuStopAmount, 'round'), columnsConfig[19].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[20].columnName, `${this.transformService.addCommasDots(this.calculationObj.avgMarginPercent, 'round') == 'NaN' ? 0 : this.transformService.addCommasDots(this.calculationObj.avgMarginPercent, 'round')}%`, columnsConfig[20].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[21].columnName, this.transformService.addCommasDots(this.calculationObj.avgWeight, 'round'), columnsConfig[21].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[22].columnName, '', columnsConfig[22].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[23].columnName, this.calculationObj.detentionTime, columnsConfig[23].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[24].columnName, '', columnsConfig[24].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[25].columnName, '', columnsConfig[25].selected);
      excelTable.push(footerObj);
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelTable);
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, 'brokers.xlsx')
    }
    else {
      this.msgForbbidenAccess();
    }
  };

  msgForbbidenAccess() {
    this.dialog.open(MsgForbbidenAccessComponent, {
      autoFocus: false,
      panelClass: 'forbidden-msg-dialog-container'
    })
  };

  transformDateFormat(date: any, format: string) {
    if(date) {
      return moment(date).format(format);
    }
    return '';
  };

  ngOnDestroy(): void {
    this.subscription1?.unsubscribe();
    this.subscription2?.unsubscribe();
  }
  
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

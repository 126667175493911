import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddNewViolationDialogComponent } from './components/add-new-violation-dialog/add-new-violation-dialog.component';
import { InspectionColumnChartComponent } from './components/inspection-column-chart/inspection-column-chart.component';
import { InspectionDetailsComponent } from './components/inspection-details/inspection-details.component';
import { InspectionLineChartComponent } from './components/inspection-line-chart/inspection-line-chart.component';
import { InspectionListComponent } from './components/inspection-list/inspection-list.component';
import { InspectionPieChartComponent } from './components/inspection-pie-chart/inspection-pie-chart.component';
import { InspectionsMainPageComponent } from './components/inspections-main-page/inspections-main-page.component';
import { InspectionsComponent } from './components/inspections.component';
import { SharedModule } from '../shared/shared.module';
import { InspectionsRoutingModule } from './inspections.routing';
import { InspectionsService } from './services/inspections.service';

@NgModule({
  declarations: [
    AddNewViolationDialogComponent,
    InspectionColumnChartComponent,
    InspectionDetailsComponent,
    InspectionLineChartComponent,
    InspectionListComponent,
    InspectionPieChartComponent,
    InspectionsMainPageComponent,
    InspectionsComponent
  ],
  imports: [
    CommonModule,
    InspectionsRoutingModule,
    SharedModule,
  ],
  providers: [InspectionsService]
})
export class InspectionsModule { }

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterSearch'
})
export class FilterSearchPipe implements PipeTransform {

  transform(array: any[], keyValueArray: any[]): any[] {
    if (!array) {
      return [];
    }

    return array.filter((obj: any) => {
      let key1: boolean = true;
      if(keyValueArray[0].value === 'Active') {
        key1 = obj[keyValueArray[0].key] === true;
      }

      if(keyValueArray[0].value === 'Inactive') {
        key1 = obj[keyValueArray[0].key] === false;
      }


      let key2: boolean = obj[keyValueArray[1].key].toLocaleLowerCase().startsWith(keyValueArray[1].value.toLocaleLowerCase());
      let key3: boolean = obj[keyValueArray[2].key].toLocaleLowerCase().startsWith(keyValueArray[2].value.toLocaleLowerCase());
      let key4: boolean = obj[keyValueArray[3].key].toLocaleLowerCase().startsWith(keyValueArray[3].value.toLocaleLowerCase());

      let key5: boolean = true;

      if(keyValueArray[4].value === 'Approved') {
        key5 = obj[keyValueArray[4].key].toLocaleLowerCase().startsWith('regular');
      }

      if(keyValueArray[4].value === 'Not approved') {
        key5 = obj[keyValueArray[4].key].toLocaleLowerCase().startsWith('bad practice');
      }

      let key6: boolean = keyValueArray[5].value.includes(obj[keyValueArray[5].key]);
      let key7: boolean = true;

      if(keyValueArray[6].value) {
        const start = Date.parse(keyValueArray[6].value.start._d);
        const end = Date.parse(keyValueArray[6].value.end._d);
        const d = Date.parse(obj[keyValueArray[6].key]);

        key7 = d.valueOf() >= start.valueOf() && d.valueOf() <= end.valueOf();
      }

      let key8: boolean = false;
    
      if(keyValueArray[7].value === 'With note' && obj[keyValueArray[7].key].length > 0) {
        key8 = true;
      }
      else if(keyValueArray[7].value === 'Without note' && obj[keyValueArray[7].key].length === 0) {
        key8 = true;
      }
      else if(keyValueArray[7].value === 'All') {
        key8 = true;
      }
      else {
        key8 = false;
      }

      let key9: boolean = false;

      if(keyValueArray[8].value === 'All') {
       key9 = true;
      }
      else {
       key9 = obj[keyValueArray[8].key].toLocaleLowerCase().startsWith(keyValueArray[8].value.toLocaleLowerCase());
      }

      if(key1 && key2 && key3 && key4 && key5 && key6 && key7 && key8 && key9) { 
        return obj;
      }

    });

  }

}

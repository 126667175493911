import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HometimeComponent } from './components/hometime.component';
import { AuthGuard } from '../core/services/auth/auth.guard';

const routes: Routes = [
	{
		path: 'drivers',
		//canActivate: [AuthGuard],
		children: [
		  {
			path: 'hometime',
			canActivate: [AuthGuard],
			component: HometimeComponent,
			data: { roles: 25 }
		  }
		],
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class HometimeRoutingModule { }

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import * as _G from '../../../_config/globals';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class DriversSearchService {
  private readonly _drivers = new BehaviorSubject<any[]>(null);
	readonly drivers$ = this._drivers.asObservable();

	constructor(private http: HttpClient ) {	
		this.fechAll();
	}

	public fechAll() {
		this.getDrivers().subscribe(
			response => this.drivers = response
		);
	}

  getDrivers(): Observable<any[]> {
		return this.http.get<any[]>(_G.apiBridge + 'dashboard/drivers/search/all');
	}

  
	getZipCodes() {
		return this.http.get('/assets/static-data/zips.json');
	}

	getAmazonLocations(): Observable<any[]> {
		return this.http.get<any[]>(_G.apiBridge + 'dashboard/drivers/search/amazonLocations');
	}

  sendEmails(email: any) {
		var url = _G.phpBridge + '/drivers/drivers-search.php';

		var body = JSON.stringify(email);

		return this.http.post(url, body);
	}

	get drivers(): any[] { return this._drivers.getValue(); }
	set drivers(val: any[]) { this._drivers.next(val); }
}

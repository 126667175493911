import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject, timeout } from 'rxjs';
import * as _G from '../../../_config/globals';

@Injectable()
export class BusinessPartnersService {
  headers = new HttpHeaders({
		'Content-Type': 'application/json',
		'Authorization': 'Basic SVZTb2x1dGlvbjoxUzBsdXQxMG4yQDIy'
	});
	requestOptions = { headers: this.headers };
  
  dateChanged: any = new Subject<any>();

  constructor(private http: HttpClient) { }

  //Brokers
  getBrokersTableData(startDate: string, endDate: string, filterParams: string) {
    return this.http.get(`${_G.extendApiUrl}dispatcher/brokerperfmance?start=${startDate}&end=${endDate}${filterParams}`);
  };

  getBrokersTableWithFilter(obj: any, filterParams: string) {
    let url: string = `${_G.extendApiUrl}dispatcher/brokersloads?startDate=${obj.startDate}&endDate=${obj.endDate}&f_state=${obj.f_state}&f_city=${obj.f_city}&f_zip=${obj.f_zip}&f_radius=${obj.f_radius}&t_state=${obj.t_state}&t_city=${obj.t_city}&t_zip=${obj.t_zip}&t_radius=${obj.t_radius}&min_length=${obj.min_length}&max_length=${obj.max_length}&min_amount=${obj.min_amount}&max_amount=${obj.max_amount}&min_rate=${obj.min_rate}&max_rate=${obj.max_rate}&min_weight=${obj.min_weight}&max_weight=${obj.max_weight}&min_rank=${obj.min_rank}&max_rank=${obj.max_rank}${filterParams}`;
    
    if(obj.broker.length > 0) {
      let filters: string = '';
      for(let i = 0; i < obj.broker.length; i++) {
        filters += `&broker=${obj.broker[i].id}`;
      }
      url = `${url}${filters}`
    }
    if(obj.comodity.length > 0) {
      let filters: string = '';
      for(let i = 0; i < obj.comodity.length; i++) {
        filters += `&comodity=${obj.comodity[i].trim()}`;
      }
      url = `${url}${filters}`
    }
    return this.http.get(url).pipe(timeout(300000));
  };

}

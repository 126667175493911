import { Component, OnDestroy, OnInit } from '@angular/core';

import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { NotCoveredCalendarDialogComponent } from './not-covered-calendar-dialog/not-covered-calendar-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { NotCoveredTrucksService } from '../services/not-covered-trucks.service';
import { RulesService } from '@app/modules/shared/services/rules.service';
import { TransformService } from '@app/modules/shared/services/transform.service';

@Component({
  selector: 'app-not-covered-trucks-page',
  templateUrl: './not-covered-trucks-page.component.html',
  styleUrls: ['./not-covered-trucks-page.component.scss']
})
export class NotCoveredTrucksPageComponent implements  OnInit, OnDestroy {
  permissions: any = this.rulesService.UserData[5].data;
  currentPeriod: any;
  prevPeriod: any = {};
  graphData: any;
  currentPeriodCards: any;
  dateObj: any = {
    startDate: this.transformService.convertDateToTimestamp(moment().startOf('days').format('ddd, DD/MM YYYY'),
      '00:00:00'),
    endDate: this.transformService.convertDateToTimestamp(moment().format('ddd, DD/MM YYYY'), moment().format('HH:mm:ss')),
  };

  subscription1: Subscription | any;
  subscription2: Subscription | any;
  subscription3: Subscription | any;

  days: any;

  secondRange: any;

  constructor(private dataService: NotCoveredTrucksService,
    private transformService: TransformService,
    private rulesService: RulesService,
    private dialog: MatDialog) { }

  ngOnInit(): void {
    this.getCards();
  }

  getCards() {
    let start: any;
    let end: any;
    if (this.secondRange?.twoRange) {
      start = this.secondRange.startDate2;
      end = this.secondRange.endDate2;

    } else {
      start = this.dateObj.startDate;
      end = this.dateObj.endDate;
    }

    var startRes = moment(start, "YYYY-MM-DD");
    var endRes = moment(end, "YYYY-MM-DD")
    let difference = Math.abs(moment.duration(startRes.diff(endRes)).asDays());
    this.days = Math.round(difference);
    let obj: any;
    if (this.days === 0) {
      obj = {
        startDate: this.transformService.convertDateToTimestamp(moment().subtract(1, 'week').format('ddd, DD/MM YYYY'),
          '00:00:00'), endDate: this.transformService.convertDateToTimestamp(moment().subtract(1, 'week').format('ddd, DD/MM YYYY'),
            '23:59:59')
      };
    } else {

      obj = {
        startDate: this.transformService.convertDateToTimestamp(moment().subtract(difference * 2, 'days').format('ddd, DD/MM YYYY'),
          '00:00:00'), endDate: this.transformService.convertDateToTimestamp(moment().subtract(difference, 'days').format('ddd, DD/MM YYYY'),
            '23:59:59')
      };
    }

    this.subscription1 = this.dataService.getNotCoveredTrucksCardsData(this.dateObj.startDate, this.dateObj.endDate).subscribe((res: any) => {
      this.currentPeriod = res.data;
      this.currentPeriod.dateObj = this.dateObj;
      this.currentPeriodCards = res;
      this.currentPeriodCards.dateObj = this.dateObj;
      console.log(res.data);
    })

    this.subscription2 = this.dataService.getNotCoveredTrucksCardsData(obj.startDate, obj.endDate).subscribe((res: any) => {
      console.log(res.data);
      this.prevPeriod.data = res.data;
      this.prevPeriod.days = this.days;
      this.prevPeriod.twoRange = this.secondRange?.twoRange;
      this.prevPeriod.dayName = moment(obj.start).format('ddd');
    })

    this.subscription3 = this.dataService.getNotCoveredTrucksLineChart(this.dateObj.startDate, this.dateObj.endDate).subscribe((response) => {
      console.log(response);
      this.graphData = response;
    })
  }

  getDate(result) {
    this.secondRange = result;
    this.dateObj.startDate = result.startDate;
    this.dateObj.endDate = result.endDate;
    this.getCards();
  };

  openFloatingDialog() {
    let dialogRef: any = this.dialog.open(NotCoveredCalendarDialogComponent, {
      minWidth: '769px',
      height: '476px',
      autoFocus: false,
      data: {oneRange: true},
      panelClass: 'not-covered-dialog-container'
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.secondRange = result;
        this.dateObj.startDate = result.startDate;
        this.dateObj.endDate = result.endDate;
        this.getCards();
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription1?.unsubscribe();
    this.subscription2?.unsubscribe();
  }

}

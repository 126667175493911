import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ReasonsDialogComponent } from '../../reasons-dialog/reasons-dialog.component';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { FleetHealthService } from '@app/modules/fleet-health/services/fleet-health.service';

@Component({
  selector: 'app-edit-reasons-dialog',
  templateUrl: './edit-reasons-dialog.component.html',
  styleUrls: ['./edit-reasons-dialog.component.scss']
})
export class EditReasonsDialogComponent implements OnInit, OnDestroy {
  reasonsArray: any[] = [];
  
  reasonsObj: any;
  isOpenReasonMenu: boolean = false;
  reasonValue: string = 'Category';

  reasonsDataWithoutGroup: any[] = [];

  //Subscription
  subscription: Subscription | any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private dialog: MatDialog,
              public dialogRef: MatDialogRef<any>,
              private fleetService: FleetHealthService) { }

  ngOnInit(): void {
    this.getAllCategories();
    for(let key in this.data.reasons) {
      this.reasonsArray.push({reasonValue: this.data.reasons[key], isOpenReasonMenu: false, value: this.data.reasons[key]})
    }

    if(this.data.reasons.length === 0) {
      this.reasonsArray.push({reasonValue: 'Category', isOpenReasonMenu: false, value: ''});
    }
  }

  getAllCategories() {
    this.subscription = this.fleetService.getAllCategories().subscribe((response: any) => {
      this.reasonsDataWithoutGroup = response;
      this.reasonsObj = this.groupCategories(response)
      console.log(this.groupCategories(response));
      console.log(response);
    })
  };

  groupCategories(array: any[]) {
    const groups: any = array.reduce((acc: any, o: any) => {

      o['category'] = o['category'];
    
      if (!acc[o['category']]) {
        acc[o['category']] = [];
      }
          
      acc[o['category']].push(o);
  
      return acc;
  
    }, {});

    return groups;
  }

  //Add select
  addSelect() {
    this.reasonsArray.push({reasonValue: 'Category', isOpenReasonMenu: false, value: ''});
  };

  //Remove select
  removeSelect(index: number) {
    this.reasonsArray.splice(index, 1);
    
  }

  //Open reasons dialog
  openReasonsDialog() {
    let dialogRef: any = this.dialog.open(ReasonsDialogComponent, {
      autoFocus: false,
      panelClass: 'reasons-dialog-main-container',
      data: this.reasonsDataWithoutGroup
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      this.getAllCategories();
    });
  }

  update() {
    let reasonsParams: string = '';

    this.reasonsArray.forEach(element => {
      
        if(element.value !== '') {
          reasonsParams += '&reason=' + element.value
        }
    })

    this.fleetService.updateReasonsReserveSpot(this.data.id, reasonsParams).subscribe((response: any) => {
      console.log(response);
      if(response) {
        this.dialogRef.close(true);
      }
    })
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

}

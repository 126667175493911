import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DocumentsService } from '@app/modules/documents/services/documents.service';

import moment = require('moment');
import { catchError, throwError } from 'rxjs';

@Component({
  selector: 'app-company-info-dialog',
  templateUrl: './company-info-dialog.component.html',
  styleUrls: ['./company-info-dialog.component.scss']
})
export class CompanyInfoDialogComponent implements OnInit {

  companyInfoArray: any[] = [];

  //Data saved
  loading: boolean = false;
  error: boolean = false;

  //Multiple clicks
  multipleClicks: boolean = true;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
              private documentsService: DocumentsService,
              public dialogRef: MatDialogRef<any>) { }

  ngOnInit(): void {
    this.companyInfoArray = this.data.data;
  }

  //Add new box
  addNewBox() {
    this.companyInfoArray.push({boxName: '', info: ''});
  };

  //Remove box
  removeBox(index: number) {
    this.companyInfoArray.splice(index, 1);
  };

  saveCompanyData() {
    if(this.multipleClicks) {
      this.loading = true;
      this.error = false;

      let loggedUser: any = JSON.parse(localStorage.getItem('currentUser'));
      this.data.data = JSON.stringify(this.companyInfoArray);
      this.data.edited_by = loggedUser.first_name + ' ' + loggedUser.last_name;
      this.data.edited_date = moment().format('YYYY-MM-DDTHH:mm:ss');
      this.documentsService.saveCompanyInfo(this.data)
      .pipe(catchError((err: any) => {
        this.loading = false;
        this.error = true;
        return throwError(() => err);
      }))
      .subscribe((response: any) => {
        if(response) {
          this.multipleClicks = false;
          this.dialogRef.close(this.companyInfoArray);
        }
        else {
          this.error = true;
        }
        this.loading = false;
      })
    }
  };
  
}

import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import moment = require('moment');
import { ApexAxisChartSeries, ApexChart, ApexXAxis, ApexYAxis, ApexDataLabels, ApexGrid, ApexStroke, ChartComponent } from 'ng-apexcharts';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription, catchError, throwError } from 'rxjs';
import { SharedService } from '../../services/shared.service';
import { TransformService } from '../../services/transform.service';
import { CalendarDialogComponent } from '../calendar-dialog/calendar-dialog.component';
import { TruckTrailerMapDialogComponent } from './truck-trailer-map-dialog/truck-trailer-map-dialog.component';
import { TrailerNoteDialogComponent } from './trailer-note-dialog/trailer-note-dialog.component';
import { CreateUpdateLocationComponent } from './create-update-location/create-update-location.component';
import * as XLSX from 'xlsx';
import { RulesService } from '../../services/rules.service';
import { MsgForbbidenAccessComponent } from '../msg-forbbiden-access/msg-forbbiden-access.component';
import { TitleCasePipe } from '@angular/common';
import { DateAsAgoPipe } from '../../pipes/date-as-ago.pipe';

export type ChartOptions = {
  series: ApexAxisChartSeries | any;
  chart: ApexChart | any;
  xaxis: ApexXAxis | any;
  yaxis: ApexYAxis | any;
  dataLabels: ApexDataLabels | any;
  grid: ApexGrid | any;
  stroke: ApexStroke | any;
  colors: any;
};

@Component({
  selector: 'app-active-trailers-dialog',
  templateUrl: './active-trailers-dialog.component.html',
  styleUrls: ['./active-trailers-dialog.component.scss']
})
export class ActiveTrailersDialogComponent implements OnInit, OnDestroy {
  dateObj: any = {
    startDate: this.transformService.convertDateToTimestamp(moment().startOf('month').format('ddd, DD/MM YYYY'), 
    '00:00:00'),
    endDate: this.transformService.convertDateToTimestamp(moment().format('ddd, DD/MM YYYY'),  moment().format('HH:mm:ss')),
  };

  displayedColumns: string[] = ['position', 'trailer_no', 'unit_no', 'distance', 'status', 'in_current_status', 'location_name', 
  'driver', 'dispatcher', 'last_piclup', 'last_delivery', 'map', 'note'];

  sortedData: any = new MatTableDataSource();
  dataSource: any = [];

  //Filters
  selectedFilter: number = 0;
  
  //Filter table and statistics
  filterArray: any[] = [
    {cardTitle: 'All Trailers', filter: 'All', array: []},
    {cardTitle: 'OTR', filter: 'OTR', array: []},
    {cardTitle: 'Dealer - Ready', filter: 'DEALER - READY', array: []},
    {cardTitle: 'Dealer - Not Ready', filter: 'DEALER - NOT READY', array: []},
    {cardTitle: 'Recovery', filter: 'RECOVERY', array: []},
    {cardTitle: 'Yard', filter: 'YARD', array: []},
    {cardTitle: 'Dropped', filter: 'Dropped', array: []},
    {cardTitle: 'GPS Not Working', filter: 'GPS Not Working', array: []}
  ];

  //Graph
  @ViewChild("chart") chart: ChartComponent | any;
  public chartOptions: Partial<ChartOptions> | any;
  selectPeriod: FormControl | any;
  graph: boolean = false;
  graphData: any[] = [];

  isOpenPeriodMenu: boolean = false;
  selectPeriodArray: any[] = [
    {name: 'By year', period: 'year'},
    {name: 'By quarter', period: 'quarter'},
    {name: 'By month', period: 'month'},
    {name: 'By week', period: 'week'},
    {name: 'By day', period: 'day'},
  ];
  menuValue: string = 'By day';

  //Show graph
  graphArray: any[] = [
    {cardTitle: 'Active Trailers', selected: false},
    {cardTitle: 'OTR', selected: true},
    {cardTitle: 'Dealer - Ready', selected: false},
    {cardTitle: 'Dealer - Not Ready', selected: false},
    {cardTitle: 'Recovery', selected: false},
    {cardTitle: 'Yard', selected: false}
  ];
  byPercentage: boolean = false;
  avgPercent: number = 0;
  avgNumber: number = 0;

  statisticsObj: any = {
    activeTrailers: 0,
    otr: 0,
    dealerReady: 0,
    dealerNotReady: 0,
    recovery: 0,
    yard: 0
  }

  //Edit mode
  editMode: boolean = false;
  statusArray: any[] = [
    {name: 'OTR', tableValue: 'OTR'},
    {name: 'DEALER - READY', tableValue: 'Dealer - Ready'},
    {name: 'DEALER - NOT READY', tableValue: 'Dealer - Not Ready'},
    {name: 'RECOVERY', tableValue: 'Recovery'},
    {name: 'YARD', tableValue: 'Yard'},
    {name: 'Dropped', tableValue: 'Dropped'},
    {name: 'GPS Not Working', tableValue: 'GPS Not Working'}
  ];

  //Excel config
  xlsxConfig: any[] = [
    {columnName: 'No.', selected: true},
    {columnName: '#Trailer', selected: true},
    {columnName: '#Truck', selected: true},
    {columnName: 'Distance between', selected: true},
    {columnName: 'Status', selected: true},
    {columnName: 'In Current Status', selected: true},
    {columnName: 'Location', selected: true},
    {columnName: 'Driver', selected: true},
    {columnName: 'Dispatcher', selected: true},
    {columnName: 'Last Pick Up', selected: true},
    {columnName: 'Last Delivery', selected: true},
    {columnName: 'Note', selected: true}
  ];

  //Location
  locationArray: any[] = [];

  loaded: boolean = false;
  error: boolean = false;
  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";

  spinnerBgColor: string = this.transformService.spinnerBgColor;
  spinnerType: string = this.transformService.spinnerType;

  subscription1: Subscription | any;
  subscription2: Subscription | any;

  constructor(public transformService: TransformService,
              private dialog: MatDialog,
              private sharedService: SharedService,
              private router: Router,
              private spinner: NgxSpinnerService,
              private rulesService: RulesService,
              private titleCase: TitleCasePipe,
              private dateAsAgo: DateAsAgoPipe) { 
                this.selectPeriod = new FormControl(['day']);
              }

  ngOnInit(): void {
    this.spinner.show('active-trailers');
    this.getActiveTrailersTableData();
  }

  getActiveTrailersTableData() {
    this.subscription1 = this.sharedService.getActiveTrailersTableData(this.transformService.filterParams)
    .pipe(catchError((err: any) => {
      this.spinner.hide('active-trailers');
      this.loaded = true;
      this.error = true;
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      this.dataSource = [...response];
      this.filterData(response);
      this.spinner.hide('active-trailers');
      this.loaded = true;
      console.log(response)
    });

    this.subscription2 = this.sharedService.getAllTrailerLocations().subscribe((response: any) => {
      this.locationArray = response;
    });
  }

  refresh() {
    this.filterArray[0].array = [];
    this.filterArray[1].array = [];
    this.filterArray[2].array = [];
    this.filterArray[3].array = [];
    this.filterArray[4].array = [];
    this.filterArray[5].array = [];
    this.filterArray[6].array = [];
    this.filterArray[7].array = [];
    this.sortedData.data = [];
    this.error = false;
    this.loaded = false;
    this.spinner.show('active-trailers');
    this.getActiveTrailersTableData();
  };

  getGraphData() {
    this.sharedService.getActiveTrailersGraphData(this.dateObj.startDate, this.dateObj.endDate, this.transformService.filterParams)
    .subscribe((response: any) => {
      console.log(response);
      this.graphData = response;
      this.initGraph(this.transformService.countDataByPeriodActiveTrailers(response, this.selectPeriod.value[0]));
    });
  };

  //Filter data
  filterData(data: any[]) {
    this.filterArray[0].array = data;

    data.forEach(element => {

      if(element.status.toLowerCase() === 'otr') {
        this.filterArray[1].array.push(element);
      }
  
      if(element.status.toLowerCase() === 'dealer - ready') {
        this.filterArray[2].array.push(element);
      }
      
      if(element.status.toLowerCase() === 'dealer - not ready') {
        this.filterArray[3].array.push(element);
      }

      if(element.status.toLowerCase() === 'recovery') {
        this.filterArray[4].array.push(element);
      }
      
      if(element.status.toLowerCase() === 'yard') {
        this.filterArray[5].array.push(element);
      }

      if(element.status.toLowerCase() === 'dropped') {
        this.filterArray[6].array.push(element);
      }

      if(element.status.toLowerCase() === 'gps not working') {
        this.filterArray[7].array.push(element);
      }

    });

    this.sortedData.data = this.filterArray[0].array;
  };

  selectArray(i: number) {
    this.selectedFilter = i;
    this.sortedData.data = this.filterArray[i].array;
  };

  //Show graph by different counting
  showGraphByDifferentCounting() {
    this.byPercentage = !this.byPercentage;
    this.initGraph(this.transformService.countDataByPeriodActiveTrucks(this.graphData, this.selectPeriod.value[0]));
  };

  //Open in new tab
  openInNewTab(route: string) {
    const url = this.router.serializeUrl(this.router.createUrlTree([route]));
    window.open(url, '_blank');
  }

  selectGraphArray(obj: any) {
    obj.selected = !obj.selected;
    this.initGraph(this.transformService.countDataByPeriodActiveTrailers(this.graphData, this.selectPeriod.value[0]));
  };

  //Change status
  changeStatus(obj: any, element: any) {
    element.isOpenMenu = false;
    this.sharedService.changeTrailerStatus(element.trailer_id, obj.name).subscribe((response: any) => {
      if(response) {
        element.status = obj.name;
        this.setTrailerLocation(element.trailer_no, '');
      }
      console.log(response);
    })
  };

  initGraph(data: any) {
    let seriesArray: any[] = [];
    let sumActive: number = 0;
    let sumOtr: number = 0;
    let sumDealerReady: number = 0;
    let sumDealerNotReady: number = 0;
    let sumRecovery: number = 0;
    let sumYard: number = 0;
    let divideNumber: number = 0;
  

    if(this.byPercentage) {
      seriesArray = [
        {name: 'Active Trailers', data: data.totalActivePercent},
        {name: 'OTR %', data: data.totalOtrPercent},
        {name: 'Dealer - Ready %', data: data.totalDealerReadyPercent},
        {name: 'Dealer - Not Ready %', data: data.totalDealerNotReadyPercent},
        {name: 'Recovery %', data: data.totalRecoveryPercent},
        {name: 'Yard %', data: data.totalYardPercent},
      ]

      for(let i = 0; i < data.totalActivePercent.length; i++) {

        sumActive += data.totalActivePercent[i];
        sumOtr += data.totalOtrPercent[i];
        sumDealerReady += data.totalDealerReadyPercent[i];
        sumDealerNotReady += data.totalDealerNotReadyPercent[i];
        sumRecovery += data.totalRecoveryPercent[i];
        sumYard += data.totalYardPercent[i];
      }
      divideNumber = data.totalActivePercent.length;
    }
    else {
      seriesArray = [
        {name: 'Active Trailers', data: data.totalActive},
        {name: 'OTR', data: data.totalOtr},
        {name: 'Dealer - Ready', data: data.totalDealerReady},
        {name: 'Dealer - Not Ready', data: data.totalDealerNotReady},
        {name: 'Recovery', data: data.totalRecovery},
        {name: 'Yard', data: data.totalYard},
      ]
      
      for(let i = 0; i < data.totalActive.length; i++) {

        sumActive += data.totalActive[i];
        sumOtr += data.totalOtr[i];
        sumDealerReady += data.totalDealerReady[i];
        sumDealerNotReady += data.totalDealerNotReady[i];
        sumRecovery += data.totalRecovery[i];
        sumYard += data.totalYard[i];
      }
      divideNumber = data.totalActive.length;
    }

    this.statisticsObj.activeTrailers = sumActive / divideNumber;
    this.statisticsObj.otr = sumOtr / divideNumber;
    this.statisticsObj.dealerReady = sumDealerReady / divideNumber;
    this.statisticsObj.dealerNotReady = sumDealerNotReady / divideNumber;
    this.statisticsObj.recovery = sumRecovery / divideNumber;
    this.statisticsObj.yard = sumYard / divideNumber;

    let filteredArray: any[] = [];
    
    for(let i = 0; i < this.graphArray.length; i++) {

      if(this.graphArray[i].selected) {
        filteredArray.push(seriesArray[i]);
      }

    }

    this.chartOptions = {
      series: filteredArray,
      chart: {
        toolbar: {
          show: true,
          tools: {
            download: false
          }
        },
        height: 350,
        type: "line",
        zoom: {
          enabled: false
        },
        fontFamily: 'Poppins'
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: "straight",
        width: 1,
      },
      grid: {
        row: {
          colors: ["transparent", "transparent"], 
          opacity: 0.5
        }
      },
      xaxis: {
        categories: data.categories
      },
      yaxis: this.addYAxis(filteredArray),
      markers: {
        size: 5
      },
      colors: ['#0030FF', '#FA9120', '#FA0000', '#008000', '#FF00FF', '#800000', '#FE7777', '#0303BB', '#008FA9', '#7550CB', '#6A6D6E'],
    };
  }

  addYAxis(array: any) {
    let yAxisArray: any[] = [];
    let colors: string[] = ['#0030FF', '#FA9120', '#FA0000', '#008000', '#FF00FF', '#800000', '#FE7777', '#0303BB', '#008FA9', '#7550CB', '#6A6D6E'];
    for(let i = 0; i < array.length; i++) {
      let obj: any;
      if(i === 0) {
        obj = {
        seriesName: array[i].name, axisTicks: {show: true}, axisBorder: {show: false, color: colors[i]},
        labels: {style: {colors: colors[i]}, formatter: (num: number) => { 
          let roundedNumber: any = Math.round(num) + (this.byPercentage ? '%' : '');
          return roundedNumber.toLocaleString("en-US").toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}
        }
      } 
      else {
        obj = {
          seriesName: array[i].name, opposite: true, axisTicks: {show: true}, axisBorder: {show: false, color: colors[i]},
          labels: {style: {colors: colors[i]}, formatter: (num: number) => {
            let roundedNumber: any = Math.round(num) + (this.byPercentage ? '%' : '');
            return roundedNumber.toLocaleString("en-US").toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}
        }
      }

      yAxisArray.push(obj);
    }

    return yAxisArray;
  }

  sortData(sort: Sort) {
    const data = JSON.parse(JSON.stringify(this.sortedData.data));
    if (!sort.active || sort.direction === '') {
      this.sortedData.data = data;
      return;
    }
    this.sortedData.data = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'trailer_no':
          return compare(a.trailer_no, b.trailer_no, isAsc);
        case 'unit_no':
          return compare(a.unit_no, b.unit_no, isAsc);
        case 'distance':
          return compare(a.distance, b.distance, isAsc);
        case 'in_current_status':
          return compare(a.in_current_status, b.in_current_status, isAsc);
        case 'location_name':
          return compare(a.location_name, b.location_name, isAsc);
        case 'driver':
          return compare(a.driver, b.driver, isAsc);
        case 'dispatcher':
          return compare(a.dispatcher, b.dispatcher, isAsc);
        case 'status':
          return compare(a.status, b.status, isAsc);
        case 'last_piclup':
          return compare(a.last_piclup, b.last_piclup, isAsc);
        case 'last_delivery':
          return compare(a.last_delivery, b.last_delivery, isAsc);
        case 'hasNotes':
          return compare(a.hasNotes, b.hasNotes, isAsc);
        default:
          return 0;
      }
    });
  }

  //Open dialog
  openDialog() {
    let dialogRef: any = this.dialog.open(CalendarDialogComponent, {
      width: '769px',
      height: '476px',
      autoFocus: false,
      data: {oneRange: true},
      panelClass: 'calendar-dialog-container'
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.dateObj.startDate = result.startDate;
        this.dateObj.endDate = result.endDate;
        this.getGraphData();
      }
    });
  }

  //Open map dialog
  openMapDialog(obj: any) {
    this.dialog.open(TruckTrailerMapDialogComponent, {
      panelClass: 'map-dialog',
      autoFocus: false,
      data: obj
    });
  };

  //Note dialog
  openNoteDialog(element: any) {
    this.dialog.open(TrailerNoteDialogComponent, {
      autoFocus: false,
      panelClass: 'trailer-note-dialog-container',
      data: element
    })
  }

  //Select period
  selectPeriodMenu(obj: any) {
    this.menuValue = obj.name;
    this.selectPeriod = new FormControl([obj.period]);
    this.initGraph(this.transformService.countDataByPeriodActiveTrailers(this.graphData, this.selectPeriod.value[0]));
    this.isOpenPeriodMenu = false;
  }

  //Set trailer location
  setTrailerLocation( obj: any, location: string) {
    this.sharedService.setTrailerLocation(obj.trailer_no, location).subscribe((response: any) => {
      if(response) {
        obj.isOpenLocationMenu = !obj.isOpenLocationMenu;
        obj.location_name = location;
      };
    })
  };

  //Add edit location
  addEditLocation(obj: any) {
    obj.isOpenLocationMenu = !obj.isOpenLocationMenu;
    let dialogRef = this.dialog.open(CreateUpdateLocationComponent, {
      autoFocus: false,
      panelClass: 'component-dialog-container',
      data: {array: JSON.parse(JSON.stringify(this.locationArray))}
    });
    dialogRef.afterClosed().subscribe((response: any[]) => {
      if(response) {
        this.locationArray = response
      }
    });
  };

  //Search
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.sortedData.filter = filterValue.trim().toLowerCase();
  }

  //Reset
  resetInpt(input: any) {
    input.value = '';
    this.sortedData.filter = '';
  }

  exportToExcel(columnsConfig: any[]) {
    if(this.rulesService.UserData[56].data[0].sectionArray[24].allowed) {
      const excelTable: any[] = [];
      for(let i = 0; i < this.sortedData.data.length; i++) {
        let obj: any = {};
        this.transformService.selectedColumn(obj, columnsConfig[0].columnName, i + 1, columnsConfig[0].selected);
        this.transformService.selectedColumn(obj, columnsConfig[1].columnName, this.sortedData.data[i].trailer_no, columnsConfig[1].selected);
        this.transformService.selectedColumn(obj, columnsConfig[2].columnName, this.sortedData.data[i].unit_no, columnsConfig[2].selected);
        this.transformService.selectedColumn(obj, columnsConfig[3].columnName, `${this.sortedData.data[i].distance}mi`, columnsConfig[3].selected);
        this.transformService.selectedColumn(obj, columnsConfig[4].columnName, this.sortedData.data[i].status, columnsConfig[4].selected);
        this.transformService.selectedColumn(obj, columnsConfig[5].columnName, `${this.sortedData.data[i].in_current_status} days`, columnsConfig[5].selected);
        this.transformService.selectedColumn(obj, columnsConfig[6].columnName, this.sortedData.data[i].location_name, columnsConfig[6].selected);
        this.transformService.selectedColumn(obj, columnsConfig[7].columnName, this.titleCase.transform(this.sortedData.data[i].driver), columnsConfig[7].selected);
        this.transformService.selectedColumn(obj, columnsConfig[8].columnName, this.titleCase.transform(this.sortedData.data[i].dispatcher), columnsConfig[8].selected);
        this.transformService.selectedColumn(obj, columnsConfig[9].columnName, this.dateAsAgo.transform(this.sortedData.data[i].last_piclup), columnsConfig[9].selected);
        this.transformService.selectedColumn(obj, columnsConfig[10].columnName,this.dateAsAgo.transform(this.sortedData.data[i].last_delivery), columnsConfig[10].selected);
        this.transformService.selectedColumn(obj, columnsConfig[11].columnName, this.sortedData.data[i].hasNotes, columnsConfig[11].selected);
        excelTable.push(obj);
      };
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelTable);
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, 'active-trailers.xlsx');
    }
    else {
      this.msgForbbidenAccess();
    }
  };

  msgForbbidenAccess() {
    this.dialog.open(MsgForbbidenAccessComponent, {
      autoFocus: false,
      panelClass: 'forbidden-msg-dialog-container'
    })
  };

  ngOnDestroy(): void {
    this.subscription1?.unsubscribe();
    this.subscription2?.unsubscribe();
  }

}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from '../services/authentication.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if (err.status === 401) {
                //Set new token if 401 response returned from api
                this.authenticationService.getToken().subscribe((token: string) => {
                    let userData: any = JSON.parse(localStorage.getItem('currentUser'));
                    userData.token = token;
                    localStorage.setItem('currentUser', JSON.stringify(userData));
                });
            }
            let service: string = err.url.replace(/&/g, '%26')
            let obj: any = {
                status: err.status,
                statusText: err.statusText,
                url: service
            }
            return throwError(() => obj);
        }))
    }
}
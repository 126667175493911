import { Component, Input, OnInit } from '@angular/core';
import * as Ckeditor from '../../../../../../custom-ckeditor5/build/ckeditor';
import { MatDialog } from '@angular/material/dialog';
import { VariablesDialogComponent } from './variables-dialog/variables-dialog.component';
import { AddEditTemplateDialogComponent } from './add-edit-template-dialog/add-edit-template-dialog.component';


import * as pdfmake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';

import * as htmlToPdf from 'html-to-pdfmake';
import { SuccessDialogComponent } from '@app/modules/shared/components/success-dialog/success-dialog.component';

@Component({
  selector: 'app-document-editor',
  templateUrl: './document-editor.component.html',
  styleUrls: ['./document-editor.component.scss']
})
export class DocumentEditorComponent implements OnInit {
  @Input() data: any;
  public Editor: any = Ckeditor;
  config = {
    image: {
			resizeUnit: 'px',
		},
    fontSize: {
      options: [
        6, 7, 8, 9, 10, 11, 12, 13, 14, 
        15, 16, 17, 18, 19,20, 21, 22, 23, 
        24, 25, 26, 27, 28, 29,
        30, 31, 32, 33, 34, 35, 36, 37, 38, 39,
        40, 41, 42, 43, 44, 45, 46, 47, 48, 49,
        50, 51, 52, 53, 54, 55, 56, 57, 58, 59,
        60, 61, 62, 63, 64, 65, 66, 67, 68, 69,
        70],
    },
  };

  constructor(private dialog: MatDialog) { }

  ngOnInit(): void {
    console.log(this.data);
    (pdfmake as any).vfs = pdfFonts.pdfMake.vfs;
  }

  openKeywordDialog() {
    this.dialog.open(VariablesDialogComponent, {
      autoFocus: false,
      panelClass: 'variables-main-dialog-container',
    });
  };
  
  contentChanged(evt: any, key: string) {
    if(this.data[key].includes('img')) {
      this.data[key] = this.data[key].replace(/&lt;/g, '<');
      this.data[key] = this.data[key].replace(/&gt;/g, '>');
    }
  };

  clearEditor() {
    this.data.header = ''
    this.data.template = '';
    this.data.footer = '';
    this.data.show_page_number = false;
  };

  //Save document
  saveDocument() {
    let dialogRef: any = this.dialog.open(AddEditTemplateDialogComponent, {
      autoFocus: false,
      panelClass: 'add-edit-template-dialog-container',
      data: this.data
    });
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if(result) {
        this.dialog.open(SuccessDialogComponent, {
          autoFocus: false,
          panelClass: 'success-dialog-container'
        });    
        this.clearEditor();
      }
      console.log(result)
    });
  };

  addPageNumbering() {
    console.log(this.data.show_page_number)
    if(this.data.show_page_number) {
      this.data.footer += 'Page 1 of N';
    }
    else {
      this.data.footer = this.data.footer.replace('Page 1 of N', '');
    }
  }


  exportToPdf() {
   const html = `<div>${this.data.template}</div>`;
   const pdfMakeContent = htmlToPdf(html, {tableAutoSize: true}); 
   console.log(this.data)

   var docDefinition: any = {
    pageSize: 'LETTER',
    pageMargins: [ 25, 80, 25, 80 ],
    footer: (currentPage: any, pageCount: any) => { 
      const pdfFooter = this.data.footer.replace('Page 1 of N', `Page ${currentPage.toString()} of ${pageCount}`);
      let finalOutput = `<div style="max-height: 90px;">${pdfFooter}</div>`;
      const pdfMakeFooter = htmlToPdf(finalOutput, {tableAutoSize: true}); 
      pdfMakeFooter[0].margin = [ 25, 10, 25, 10 ];
      return [
        pdfMakeFooter
      ]
    },
    header: (currentPage: any, pageCount, pageSize) => {
      const pdfHeader = `<div style="max-height: 90px;">${this.data.header}</div>`;
      const pdfMakeHeader = htmlToPdf(pdfHeader, {tableAutoSize: true}); 
      pdfMakeHeader[0].margin = [ 25, 10, 25, 10 ];
      return [
        pdfMakeHeader
      ]
    },
      content: pdfMakeContent
    };

   pdfmake.createPdf(docDefinition).open();
    
  }

}

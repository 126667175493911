import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { INote } from '@app/modules/shared/models/note-model';
import { SharedService } from '@app/modules/shared/services/shared.service';
import moment = require('moment');
import { Subscription } from 'rxjs';
import { DeleteConfirmationDialogComponent } from '../../delete-confirmation-dialog/delete-confirmation-dialog.component';

@Component({
  selector: 'app-trailer-note-dialog',
  templateUrl: './trailer-note-dialog.component.html',
  styleUrls: ['./trailer-note-dialog.component.scss']
})
export class TrailerNoteDialogComponent implements OnInit, OnDestroy {
  loggedUser: any = JSON.parse(localStorage.getItem('currentUser'));
  fullName: string = `${this.loggedUser.first_name} ${this.loggedUser.last_name}`;
  noteArray: INote[] | any = [];
  selectedNoteIndex: number | undefined = undefined;

  formGroup: FormGroup;

  subscription: Subscription = new Subscription();

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialog: MatDialog,
  private sharedService: SharedService, private formBuilder: FormBuilder) {
    this.formGroup = this.formBuilder.nonNullable.group({
      'id': [0],
      'trailer': [this.data.trailer_no],
      'note': ['', [Validators.required, Validators.minLength(1)]],
      'created_by': [this.fullName],
      'creator_department':[this.loggedUser.department],
      'created_date': [''],
      'edited_by': [''],
      'editor_department': [''],
      'edited_date': [null]
    })
  }

  ngOnInit(): void {
    this.subscription = this.sharedService.getNotesByTrailer(this.data.trailer_no).subscribe((response: INote[]) => {
      this.noteArray = response;
      console.log(response);
    });
  };

  editNote(obj: any, index: number) {
    obj.isEdit = !obj.isEdit;
    this.formGroup.setValue({
        id: obj.id,
        trailer: obj.trailer,
        note: obj.note,
        created_by: obj.created_by,
        creator_department: obj.creator_department,
        created_date: obj.created_date,
        edited_by: this.fullName,
        editor_department: this.loggedUser.department,
        edited_date: null
    });
    this.selectedNoteIndex = index;
  };

  discard(obj: any) {
    obj.isEdit = !obj.isEdit;
    this.selectedNoteIndex = undefined;
    this.formGroup.reset();
  };

  addNote() {
    let form: INote = this.formGroup.value;
    if(form.id) {
      form.edited_date = moment().format('YYYY-MM-DDTHH:mm:ss');
    }
    else {
      form.created_date = moment().format('YYYY-MM-DDTHH:mm:ss');
    }
    this.sharedService.createUpdateTrailerNote(form).subscribe((response: any) => {
      console.log(response);
      if(response?.id) {
        if(form.id === 0) {
          this.noteArray.unshift(response);
        }
        else {
          this.noteArray[this.selectedNoteIndex].isEdit = false;
          this.noteArray[this.selectedNoteIndex].note = response.note;
          this.noteArray[this.selectedNoteIndex].edited_by = response.edited_by;
          this.noteArray[this.selectedNoteIndex].editor_department = response.editor_department;
          this.noteArray[this.selectedNoteIndex].edited_date = response.edited_date;
        }
        this.formGroup.reset();
      }
    });
  };

  deleteNote(noteId: number, index: number) {
    let dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
      autoFocus: false,
      panelClass: 'delete-dialog-container',
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
        this.sharedService.deleteTrailerNote(noteId).subscribe((response: boolean) => {
          console.log(response);
          if(response) {
            this.noteArray.splice(index, 1);
          }
        });
      }
    })
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  };

}

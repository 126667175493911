import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';

import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { RecruitingService } from '../../services/recruiting.service';
import { TransformService } from '@app/modules/shared/services/transform.service';


@Component({
  selector: 'app-drivers-paychecks',
  templateUrl: './drivers-paychecks.component.html',
  styleUrls: ['./drivers-paychecks.component.scss']
})
export class DriversPaychecksComponent implements OnInit, OnDestroy {
  @ViewChild('scrollContainer', { read: ElementRef }) public scrollContainer: any;
  subscription1: Subscription | any;
  subscription2: Subscription | any;
  @Input() date: any;
 
  currentDate = this.transformService.convertDateToTimestamp(moment().format('ddd, MM/DD/YYYY'), moment().format('HH:mm:ss'));
  tableData: any;
  statistics: any[] = [];
  panelOpenStateArr: boolean[] = [];

  selectedValue: FormControl;

  tableDataCopy: any;
  arrayLenght: any[] = [];
  displayedColumns1: string[] = [];
  displayedColumns2: string[] = [];

  dateObj: any = {
    startDate: this.transformService.convertDateToTimestamp(moment().subtract(4, 'weeks').startOf('isoWeek').format('ddd, MM/DD/YYYY'),
      '00:00:00'),
    endDate: this.transformService.convertDateToTimestamp(moment().format('ddd, MM/DD/YYYY'), moment().format('HH:mm:ss'))
  }

  constructor(private dataService: RecruitingService, private transformService: TransformService) {
    this.selectedValue = new FormControl(['active']);
}

  ngOnInit(): void {
    this.getData();
  }

  getData() {
    this.subscription1 = this.dataService.getDriversPaycheks(this.dateObj.startDate, this.dateObj.endDate,
      this.selectedValue.value[0])
      .subscribe(
        (response: any) => {
          console.log(response.data)
          this.tableDataCopy = response.data;
          this.initTable(response.data);
          let copyData = JSON.parse(JSON.stringify(response));
          delete copyData.data['#ID'];
          delete copyData.data['NAME'];
          this.dataService.averagePaychecksData.next(copyData);
        });

  };

  isNegative(num: number) {
    return Math.sign(num) === -1;
  };

  //Filter method

  onlyOneSelect(value: string) {
    this.selectedValue = new FormControl([value]);
  };

  onSubmit(matSelect: MatSelect) {
    matSelect.close();
    let obj = {
      selectedValue: this.selectedValue.value[0]
    }
    this.dataService.driversOverchecksType.next(obj);
  };

  //Search by name

  applyFilter(event: Event) {
    const filterValue: any = (event.target as HTMLInputElement).value.toLowerCase();
    let tableData: any = JSON.parse(JSON.stringify(this.tableDataCopy));

    let objReturned: any = {};

    for (let key in tableData) {
      const filtered = tableData[key]
        .filter((key: any) => key.driver.toString().toLocaleLowerCase().includes(filterValue));
      objReturned[key] = filtered;
    }

    console.log(objReturned);
    this.initTable(objReturned);
  }

  initTable(data: any) {
    this.statistics = [];
    this.displayedColumns1 = [];
    this.displayedColumns2 = [];
    this.arrayLenght = [];
    this.tableData = null;
    if (Object.keys(data).length > 0) {

      for (let key in data) {

        let num = 0;
        let negative: number = 0;
        let lessThan: number = 0;
        let lengthNo: number = 0;
        for (let key2 in data[key]) {
          if (data[key][key2].total !== 'X') {
            let number = +data[key][key2].total;
            lengthNo++;
            num += number;
            if (this.isNegative(number)) {
              negative++;
            }

            if (number < 1000) {
              lessThan++;
            }

          }

        }
        console.log(this.statistics);
        this.statistics.push({ avg: (num / lengthNo).toFixed(2), neg: negative, less: lessThan })
      }

      this.statistics.unshift({}, {});
      this.panelOpenStateArr = new Array(this.statistics.length).fill(false);


      let key: any = Object.keys(data)[0];
      console.log(key);
      data['#ID'] = data[key];
      data['NAME'] = data[key];
      console.log(data);

      let num: any;

      let index: number = 1;

      for (let key in data) {
        if (key !== '#ID' && key !== 'NAME') {
          this.displayedColumns2.push(key);
        }
        num = data[key].length;
        this.displayedColumns1.push('Col-' + index);
        index++;
      }

      this.displayedColumns2.unshift('#ID', 'NAME');
      this.tableData = data;
      this.arrayLenght = Array.from(Array(num).keys());
    }
  }

  public scrollRight(): void {
    this.dateObj.startDate = this.transformService.convertDateToTimestamp(moment(this.dateObj.startDate).subtract(4, 'weeks')
      .startOf('isoWeek').format('ddd, MM/DD/YYYY'), '00:00:00')
    this.dateObj.endDate = this.transformService.convertDateToTimestamp(moment(this.dateObj.endDate).subtract(4, 'weeks')
      .endOf('isoWeek').format('ddd, MM/DD/YYYY'), '23:59:59')
    console.log(this.dateObj);
    this.getData();
  }

  public scrollLeft(): void {
    
    this.dateObj.startDate = this.transformService.convertDateToTimestamp(moment(this.dateObj.startDate).add(4, 'weeks')
      .startOf('isoWeek').format('ddd, MM/DD/YYYY'), '00:00:00')
    this.dateObj.endDate = this.transformService.convertDateToTimestamp(moment(this.dateObj.endDate).add(4, 'weeks')
      .endOf('isoWeek').format('ddd, MM/DD/YYYY'), '23:59:59')
    console.log(this.dateObj);
    this.getData();
  }

  //Sort data

  sortData(sort: any) {
    let sortCritery: any[] = [];
    console.log(sort);

    if (!sort.active || sort.direction === '') {
      this.tableData = this.tableDataCopy;
      return;
    }

    this.tableData[sort.active].sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case sort.active:
          if (sort.active === '#ID') {

            return compare(a.driver, b.driver, isAsc);

          } else if (sort.active === 'NAME') {

            return compare(a.driver, b.driver, isAsc);

          } else {

            return compare(a.total === 'X' ? 0 : +a.total, b.total === 'X' ? 0 : +b.total, isAsc);
          }

        default:
          return 0;
      }
    });

    for (let key in this.tableData[sort.active]) {
      sortCritery.push(this.tableData[sort.active][key].driver);
    }

    for (let key in this.tableData) {

      if (key !== sort.active) {

        this.tableData[key].sort((a: any, b: any) => { return sortCritery.indexOf(a.driver) - sortCritery.indexOf(b.driver) })

      }

    }

  }


  ngOnDestroy(): void {
    this.subscription1?.unsubscribe();
    this.subscription2?.unsubscribe();
  }
}
function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { DeleteContractConfirmationDialogComponent } from './delete-contract-confirmation-dialog/delete-contract-confirmation-dialog.component';
import { SafetyService } from '@app/modules/safety/services/safety.service';

@Component({
  selector: 'app-edit-contract-category-dialog',
  templateUrl: './edit-contract-category-dialog.component.html',
  styleUrls: ['./edit-contract-category-dialog.component.scss']
})
export class EditContractCategoryDialogComponent implements OnInit, OnDestroy {
  categoryArray: any[] = [];

  subscription: Subscription | any;

  constructor(private safetyService: SafetyService,
              private dialog: MatDialog,
              private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.getAllCategories();
  }

  getAllCategories() {
    this.subscription = this.safetyService.getContractCategories().subscribe((response: any) => {
      this.categoryArray = response;
      console.log(response);
    })
  };

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.categoryArray, event.previousIndex, event.currentIndex);
  }

  saveData() {
    this.safetyService.changeContractCategoryPosition(this.categoryArray).subscribe((response: any) => {
      if(response) {
        this._snackBar.open('Successfully saved!', 'Close', {
          duration: 3000
        });
        this.getAllCategories();
        this.safetyService.contractTableSubject.next(true);
      }
      else {
        this._snackBar.open('An error occurred. Please try again later.', 'Close', {
          duration: 3000
        });
      }
    })
  };

  removeCategory(obj: any, index: number) {
    let dialogRef: any = this.dialog.open(DeleteContractConfirmationDialogComponent, {
      width: '454px',
      height: '482px',
      autoFocus: false,
      panelClass: 'delete-category-confirmation-dialog-container',
      data: obj
    })
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
        this.safetyService.deleteContractCategory(obj.ID).subscribe((response: any) => {
    
          if(response) {
            this.categoryArray.splice(index, 1);
            this._snackBar.open('Successfully deleted!', 'Close', {
              duration: 3000
            });
            this.safetyService.contractTableSubject.next(true);
          }
        })
      }
    });
  };

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  };
  
}


import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EmptyTrucksStatsPageComponent } from './components/empty-trucks-stats-page/empty-trucks-stats-page.component';
import { TruckPerformancePageComponent } from './components/truck-performance-page/truck-performance-page.component';
import { TruckProfilePageComponent } from './components/truck-profile-page/truck-profile-page.component';
import { AuthGuard } from '../core/services/auth/auth.guard';
import { TrucksInspectionsPageComponent } from './components/trucks-inspections-page/trucks-inspections-page.component';


const routes: Routes = [
	{
		path: 'trucks',
		children: [
			{
			path: 'truck-nodriver',
			canActivate: [AuthGuard],
			component: EmptyTrucksStatsPageComponent,
			data: { roles: 4 }
			},
			{
			path: 'truck-profile/:id',
			canActivate: [AuthGuard],
			component: TruckProfilePageComponent,
			data: { roles: 12 }
			},
			{
			path: 'trucks-performance',
			canActivate: [AuthGuard],
			component: TruckPerformancePageComponent,
			data: { roles: 13 }
			},
			{
				path: 'trucks-inspections',
				canActivate: [AuthGuard],
				component: TrucksInspectionsPageComponent,
				data: { roles: 0 }
			}
    	],
  	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class TrucksRoutingModule { }

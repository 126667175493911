export class GCData {

	public constructor() { }

	gross: GCType = new GCType();
	loads: GCType = new GCType();
	weight?: GCType = new GCType();
	mileage?: GCType = new GCType();
	mileage_empty?: GCType = new GCType();
	mileage_loaded?: GCType = new GCType();
	deducted?: GCType = new GCType();
	avgGrossPerLoad?: GCType = new GCType();
	avgGrossPerMile?: GCType = new GCType();
	avgMilesPerLoad?: GCType = new GCType();
}

export class ChartData {
	attribute: string;
	chart: GCConfig;
}

export interface GCDataTable {
	gross: Item;
	loads: Item;
	mileage?: Item;
	mileage_empty?: Item;
	mileage_loaded?: Item;
	weight?: Item;
	deducted?: Item;
	avgGrossPerLoad?: Item;
	avgGrossPerMile?: Item;
	avgMilesPerLoad?: Item;
}

export interface GCDataTableTimeline {
  gross: Item;
  margin: Item;
  mileage?: Item;
  mileage_empty?: Item;
  rate?: Item;
  no_truck?: Item;
  no_loads?: Item;
  avg_veight?: Item;
  days?: Item;
}

export interface Item {
	columnNames: string[];
	table?: any; // values in table
	options?: {};
	total?: any; // values in total row
	average?: any; // values in average row
}

export enum ChartType {
	PieChart, LineChart, BarChart, ColumnChart, Table
}

export class GCType {

	public constructor() { }

	PieChart?: GCConfig;
	BarChart?: GCConfig;
	ColumnChart: GCConfig;
	Table: GCConfig;
	LineChart: GCConfig;
	AreaChart: GCConfig;
}

export class GCConfig {

	public constructor(type: string, data, columnNames, options) {
		this.chartType = type;
		this.dataTable = data;
		this.columnNames = columnNames;
		this.options = options
	}

	chartType: string;
	dataTable: any[];
	columnNames: string[];
	options: {};
}





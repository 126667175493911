import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import moment = require('moment');
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription, catchError, throwError } from 'rxjs';
import { AccountingService } from '../../services/accounting.service';
import { TransformService } from '@app/modules/shared/services/transform.service';
import { RulesService } from '@app/modules/shared/services/rules.service';
import { SharedService } from '@app/modules/shared/services/shared.service';
import { ConnectionPositionPair } from '@angular/cdk/overlay';
import { DateRange } from '@angular/material/datepicker';

@Component({
  selector: 'app-dashboard-accounting-page',
  templateUrl: './dashboard-accounting-page.component.html',
  styleUrls: ['./dashboard-accounting-page.component.scss']
})
export class DashboardAccountingPageComponent implements OnInit, OnDestroy {
  permissions: any = this.rulesService.UserData[9].data[0].allowedAll;
  data: any;

  date: any = {
    startDate: moment().isoWeekday(2).subtract(1, 'week').format('YYYY-MM-DD'),
    endDate: moment().isoWeekday(1).format('YYYY-MM-DD')
  };

  startDate: string = moment().isoWeekday(2).subtract(1, 'week').format('MM.DD.YYYY');
  endDate: string = moment().isoWeekday(1).format('MM.DD.YYYY');

  //Date
  @Input() selectedRangeValue: DateRange<Date> | any;
  @Output() selectedRangeValueChange = new EventEmitter<DateRange<Date>>();
  isOpen: boolean = false;
  //Positions
  public positions = [
    new ConnectionPositionPair(
      {originX: 'center', originY: 'bottom'},
      {overlayX: 'center', overlayY: 'top'},
      0, 5
    )
  ];

  loaded: boolean = false;
  error: boolean = false;
  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";

  spinnerBgColor: string = this.transformService.spinnerBgColor;
  spinnerType: string = this.transformService.spinnerType;

  subscription: Subscription | any;


  constructor(public transformService: TransformService, 
              private rulesService: RulesService,
              private accountingService: AccountingService,
              private spinner: NgxSpinnerService,
              private sharedService: SharedService) { }

  ngOnInit(): void {
    this.spinner.show('accounting');
    this.getAccountingTableData();
  }

  selectedChange(m: any) {
    if (!this.selectedRangeValue?.start || this.selectedRangeValue?.end) {
      this.selectedRangeValue = new DateRange<Date>(m, null);
    } else {
      const start = this.selectedRangeValue.start;
      const end = m;
      if (end < start) {
          this.selectedRangeValue = new DateRange<Date>(end, start);
      } else {
          this.selectedRangeValue = new DateRange<Date>(start, end);
      }
    }
    this.selectedRangeValueChange.emit(this.selectedRangeValue);
    if(this.selectedRangeValue.start && this.selectedRangeValue.end) {
      this.date.startDate = moment(this.selectedRangeValue.start._d).format('YYYY-MM-DD');
      this.date.endDate = moment(this.selectedRangeValue.end._d).format('YYYY-MM-DD');

      this.startDate = moment(this.date.startDate).format('MM.DD.YYYY');
      this.endDate = moment(this.date.endDate).format('MM.DD.YYYY');

      this.data = [];
      this.error = false;
      this.loaded = false;
      this.spinner.show('accounting');
      this.getAccountingTableData();
      this.isOpen = false;
    }
  }

  getAccountingTableData() {
    let filterParams: string = '';
    let idsArray: any = JSON.parse(localStorage.getItem('selectedCompanies'));
    for(let key in idsArray) {
      filterParams +=	'&filters=' + idsArray[key];
    }
    console.log(this.date);
    this.subscription = this.accountingService.getAccountingTable(this.date.startDate, this.date.endDate, filterParams)
    .pipe(catchError((err: any) => {
      this.spinner.hide('accounting');
      this.loaded = true;
      this.error = true;
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      this.data = response;
      this.spinner.hide('accounting');
      this.loaded = true;
      console.log(response)
    })
  };

  setDay(isTrue: boolean) {
    if(isTrue) {
      this.date.startDate = moment(this.date.startDate).add(1, 'week').format('YYYY-MM-DD');
      this.date.endDate = moment(this.date.endDate).add(1, 'week').format('YYYY-MM-DD');

      this.startDate = moment(this.date.startDate).format('MM.DD.YYYY');
      this.endDate = moment(this.date.endDate).format('MM.DD.YYYY');

    } else {
      this.date.startDate = moment(this.date.startDate).subtract(1, 'week').format('YYYY-MM-DD');
      this.date.endDate = moment(this.date.endDate).subtract(1, 'week').format('YYYY-MM-DD');

      this.startDate = moment(this.date.startDate).format('MM.DD.YYYY');
      this.endDate = moment(this.date.endDate).format('MM.DD.YYYY');
    }
    this.data = [];

    this.error = false;
    this.loaded = false;
    this.spinner.show('accounting');
    this.getAccountingTableData();
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
  
}

import { ConnectionPositionPair } from '@angular/cdk/overlay';
import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DriversProfileService } from '@app/modules/drivers-profile-page/services/drivers-profile.service';
import { TransformService } from '@app/modules/shared/services/transform.service';
import { AssignTruckTrailerDialogComponent } from './assign-truck-trailer-dialog/assign-truck-trailer-dialog.component';
import { SuccessDialogComponent } from '@app/modules/shared/components/success-dialog/success-dialog.component';
import { WarningMsgDialogComponent } from '@app/modules/shared/components/warning-msg-dialog/warning-msg-dialog.component';
import { AdditionalInfoDialogComponent } from './additional-info-dialog/additional-info-dialog.component';
import { WarningLettersDialogComponent } from './warning-letters-dialog/warning-letters-dialog.component';
import { DotInspectionDialogComponent } from './dot-inspection-dialog/dot-inspection-dialog.component';
import { ActivatedRoute } from '@angular/router';
import { RulesService } from '@app/modules/shared/services/rules.service';
import { MsgForbbidenAccessComponent } from '@app/modules/shared/components/msg-forbbiden-access/msg-forbbiden-access.component';
import { catchError, throwError } from 'rxjs';
import { SharedService } from '@app/modules/shared/services/shared.service';
import { AssignedConfirmationDialogComponent } from '@app/modules/shared/components/assigned-confirmation-dialog/assigned-confirmation-dialog.component';

@Component({
  selector: 'additional-info',
  templateUrl: './additional-info.component.html',
  styleUrls: ['./additional-info.component.scss']
})
export class AdditionalInfoComponent {
  permissions: any = this.rulesService.UserData[14].data;
  @Input() data: any;

  //Positions
  public positions = [
    new ConnectionPositionPair(
      {originX: 'end', originY: 'bottom'},
      {overlayX: 'end', overlayY: 'top'},
      0, 5
    )
  ];
  isOpenEquipmentMenu: boolean = false;

  //Truck, Trailer info
  isOpenTruckMenu: boolean = false;
  isOpenTrailerMenu: boolean = false;

  constructor(public transformService: TransformService, 
              private dialog: MatDialog,
              private driversProfileService: DriversProfileService,
              private route: ActivatedRoute,
              private rulesService: RulesService,
              private sharedService: SharedService) { }

  assignTruckTrailer(assignTruck: boolean, key: string) {
    this.isOpenEquipmentMenu = !this.isOpenEquipmentMenu;
    if((this.permissions[2].allowedAll && assignTruck) || (this.permissions[3].allowedAll && !assignTruck)) {
      let dialogRef = this.dialog.open(AssignTruckTrailerDialogComponent, {
        autoFocus: false,
        panelClass: 'component-dialog-container',
        data: {assignTruck: assignTruck, selectedValue: this.data.info[key]}
      });
      dialogRef.afterClosed().subscribe((obj: any) => {
        if(obj) {
          if(assignTruck) {
            this.alreadyAssigned(this.data.info.id, obj, true);
          }
          else {
            this.alreadyAssigned(obj.truckTrailerId, obj, false);
          }
        }
      });
    }
    else {
      this.showForbidenMessage();
    }
  };

  assignTruck(driverId: number, truckId: number, truckTrailerValue: string) {
    this.sharedService.assignTruck(driverId, truckId)
    .pipe(catchError((err: any) => {
      this.showErrorMessage();
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      if(response === 'OK') {
        this.data.info.truck_no = truckTrailerValue;
        this.showSuccessMessage(); 
      }
      else {
        this.showErrorMessage();
      }
    });
  };

  assignTrailer(trailerId: number, truckId: number, truckTrailerValue: string) {
    this.sharedService.assignTrailer(trailerId, truckId)
    .pipe(catchError((err: any) => {
      this.showErrorMessage();
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      if(response === 'OK') {
        this.data.info.trailer_no = truckTrailerValue;
        this.showSuccessMessage(); 
      }
      else {
        this.showErrorMessage();
      }
    });
  };

  alreadyAssigned(id: number, data: any, checkForTruck: boolean) {
    this.sharedService.isTruckTrailerAssigned(id, checkForTruck)
    .pipe(catchError((err: any) => {
      this.showErrorMessage();
      return throwError(() => err);
    }))
    .subscribe((assigned: boolean) => {
      if(assigned) {
        let dialogRef: any = this.dialog.open(AssignedConfirmationDialogComponent, {
          autoFocus: false,
          panelClass: 'assigned-msg-dialog-container',
          data: checkForTruck
        });
        dialogRef.afterClosed().subscribe((response: boolean) => {
          if(response) {
            if(checkForTruck) {
              this.assignTruck(this.data.info.id, data.truckTrailerId, data.truckTrailerValue);
            }
            else {
              this.assignTrailer(data.truckTrailerId, this.data.info.truck_id, data.truckTrailerValue);
            }
          } 
        });
      }
      else {
        if(checkForTruck) {
          this.assignTruck(this.data.info.id, data.truckTrailerId, data.truckTrailerValue);
        }
        else {
          this.assignTrailer(data.truckTrailerId, this.data.info.truck_id, data.truckTrailerValue);
        }
      }
    });
  };


  changeTwicInfo(event: any, value: string) {
    event.preventDefault();
    if(this.permissions[4].allowedAll) {
      if(value === 'Yes') {
        let dialogRef = this.dialog.open(AdditionalInfoDialogComponent, {
          autoFocus: false,
          panelClass: 'component-dialog-container',
          data: {data: this.data.info}
        });  
        dialogRef.afterClosed().subscribe((expDate: string) => {
          if(expDate) {
            this.data.additionalInfo.twic_expiration_date = expDate;
            this.data.additionalInfo.twic_card = true;
          }
        });
      }
      else {
        this.driversProfileService.changeTwicCardStatus(this.data.info.id, null)
        .subscribe((response: any) => {
          if(response) {
            this.data.additionalInfo.twic_expiration_date = null;
            this.data.additionalInfo.twic_card = false;
          }
        });
      };
    }
    else {
      this.showForbidenMessage();
    }
  };

  changeAdditionalInfo(event: any, type: string, key: string) {
    event.preventDefault();
    if(this.permissions[4].allowedAll) {
      this.driversProfileService.changeOtherAdditionalInfoDataStatus(this.data.info.id, !this.data.additionalInfo[key], type)
      .subscribe((response: any) => {
        console.log(response);
        if(response) {
          this.data.additionalInfo[key] = !this.data.additionalInfo[key];
        }
      });
    }
    else {
      this.showForbidenMessage();
    }
  }

  changeChildSuppot(event: any) {
    event.preventDefault();
    if(this.permissions[4].allowedAll) {
      this.driversProfileService.changeChildSupportStatus(this.data.info.id, !this.data.info.child_support)
      .subscribe((saved: boolean) => {
        if(saved) {
          this.data.info.child_support = !this.data.info.child_support;
        }
        console.log(saved);
      });
    }
    else {
      this.showForbidenMessage();
    }
  };

  openWarningLettersDialog() {
    this.dialog.open(WarningLettersDialogComponent, {
      autoFocus: false,
      panelClass: 'component-dialog-container',
      data: this.data.info.id
    });
  };

  openDotInspectionDialog(inspectionType: string) {
    this.dialog.open(DotInspectionDialogComponent, {
      autoFocus: false,
      panelClass: 'component-dialog-container',
      data: {driverId: this.data.info.id, inspectionType: inspectionType}
    });
  };

  showSuccessMessage() {
    this.dialog.open(SuccessDialogComponent, {
      autoFocus: false,
      panelClass: 'success-dialog-container'
    });  
  };

  showErrorMessage() {
    this.dialog.open(WarningMsgDialogComponent, {
      autoFocus: false,
      panelClass: 'warning-msg-dialog-container'
    });
  };

  showForbidenMessage() {
    this.dialog.open(MsgForbbidenAccessComponent, {
      autoFocus: false,
      panelClass: 'forbidden-msg-dialog-container'
    })
  };

  getProfileData() {
    let id: string = this.route.snapshot.paramMap.get('id');
    this.driversProfileService.getDriverProfileData(+id)
    .subscribe((response: any) => {
      let data: any = JSON.parse(JSON.stringify(this.data.info));
      let obj: any = {driver_id: data.id, truck_no: data.truck_no, truck_id: data.truck_id,
      trailer_no: data.trailer_no, trailer_id: data.trailer_id, old_disp_id: data.dispatcher_id,
      new_disp: response.info.dispatcher, new_disp_id: response.info.dispatcher_id};
      this.driversProfileService.sendNotification(obj).subscribe((response: any) => {});
      this.data.info = response.info;
    });
  }

  get getCurrentDate(): string {
    let date = new Date();
    let europeDateTime: any = date.toLocaleString("de-DE", {hour12: false, timeZone: "Europe/Berlin"}).split(',');
    let format: string = europeDateTime[0].split('.')
    let day: string = +format[0] < 10 ? `0${format[0]}` : format[0];
    let month: string = +format[1] < 10 ? `0${format[1]}` : format[1];
    let year: string = format[2];
    return `${year}-${month}-${day}T${europeDateTime[1].trim()}`;
  }

}

import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import moment = require('moment');
import { LoadDetailsDialogComponent } from './load-details-dialog/load-details-dialog.component';

@Component({
  selector: 'search-loads-card',
  templateUrl: './search-loads-card.component.html',
  styleUrls: ['./search-loads-card.component.scss']
})
export class SearchLoadsCardComponent {

  //Filters

  //Origin
  originArray: any[] = [];
  isOpenOriginMenu: boolean = false;
  originValue: string = '';

  //Destination
  destinationArray: any[] = [];
  isOpenDestinationMenu: boolean = false;
  destinationValue: string = '';

  //Equipment type
  equipmentTypeArray: any[] = [];
  isOpenEquipmentTypeMenu: boolean = false;
  equipmentTypeValue: string = '';

  //Load type
  loadTypeArray: any[] = [];
  isOpenLoadTypeMenu: boolean = false;
  loadTypeValue: string = '';

  //Calendar
  isOpenCalendar: boolean = false;
  rangeDate: string = moment().format('YYYY-MM-DD');

  //Load requirement
  isOpenLoadRequirement: boolean = false;
  loadRequirementsArray: any[] = [
    {name: 'Tracking required'}, {name: 'Tracking not required'}, {name: 'Both'}
  ];

  //Company
  isOpenCompanyMenu: boolean = false;
  radioArray: any[] = [
    {name: 'Show only', checked: true},
    {name: 'Exclude', checked: false}
  ];

  //Private loads
  isOpenPrivateLoadsMenu: boolean = false;
  privateLoadChecked: boolean = false;

  //Private loads
  isOpenBookableLoadsMenu: boolean = false;
  bookableLoadChecked: boolean = false;

  //Time
  isOpenTimeMenu: boolean = false;
  time: string = '';
  hour: any = '';
  minutes: any = '';

  dataSource: any[] = [
    {age: '0m', rate: '$550', rpm: '$2.14/mi', origin: 'Dallas, TX', destination: 'Austing, TX', dhO: 0, pick_up: '2024-05-15', equipment: 'Flatbed', company: 'Online Freight Service Inc', phone: '(651) 435 6454', status: ''},
    {age: '0m', rate: '$1550', rpm: '$2.14/mi', origin: 'Dallas, TX', destination: 'Austing, TX', dhO: 0, pick_up: '2024-05-15', equipment: 'Flatbed', company: 'Online Freight Service Inc', phone: '(651) 435 6454', status: ''},
    {age: '5m', rate: '$550', rpm: '$2.14/mi', origin: 'Dallas, TX', destination: 'Austing, TX', dhO: 0, pick_up: '2024-05-15', equipment: 'Flatbed', company: 'Online Freight Service Inc', phone: '(651) 435 6454', status: ''},
    {age: '10m', rate: '$550', rpm: '$2.14/mi', origin: 'Dallas, TX', destination: 'Austing, TX', dhO: 0, pick_up: '2024-05-15', equipment: 'Flatbed', company: 'Online Freight Service Inc', phone: '(651) 435 6454', status: ''},
    {age: '2h', rate: '$550', rpm: '$2.14/mi', origin: 'Dallas, TX', destination: 'Austing, TX', dhO: 0, pick_up: '2024-05-15', equipment: 'Flatbed', company: 'Online Freight Service Inc', phone: '(651) 435 6454', status: ''},
    {age: '3h', rate: '$550', rpm: '$2.14/mi', origin: 'Dallas, TX', destination: 'Austing, TX', dhO: 0, pick_up: '2024-05-15', equipment: 'Flatbed', company: 'Online Freight Service Inc', phone: '(651) 435 6454', status: ''},
    {age: '4h', rate: '$550', rpm: '$2.14/mi', origin: 'Dallas, TX', destination: 'Austing, TX', dhO: 0, pick_up: '2024-05-15', equipment: 'Flatbed', company: 'Online Freight Service Inc', phone: '(651) 435 6454', status: ''}
  ];

  constructor(private dialog: MatDialog) { }

  sortData(sort: Sort) {
    const data = JSON.parse(JSON.stringify(this.dataSource));
    if (!sort.active || sort.direction === '') {
      this.dataSource = data;
      return;
    }
    this.dataSource = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'bp_name':
          return compare(a.bp_name, b.bp_name, isAsc);
        case 'mc':
          return compare(a.mc, b.mc, isAsc);
        case 'reason':
          return compare(a.reason, b.reason, isAsc);
        case 'from':
          return compare(a.in_current_status, b.in_current_status, isAsc);
        case 'in_current_status':
          return compare(a.in_current_status, b.in_current_status, isAsc);
        case 'last_check':
          return compare(a.last_check, b.last_check, isAsc);
        case 'created_by':
          return compare(a.created_by, b.created_by, isAsc);
        case 'status':
          return compare(a.status, b.status, isAsc);
        case 'checked_by':
          return compare(a.checked_by, b.checked_by, isAsc);
        case 'note':
          return compare(a.note, b.note, isAsc);
        default:
          return 0;
      }
    });
  }

  openLoadDetailsDialog(element: any) {
    this.dialog.open(LoadDetailsDialogComponent, {
      autoFocus: false,
      panelClass: 'load-details-dialog-container',
      data: element
    });
  };

  //Time methods
  leadingZero(hour: boolean) {
    if(hour) {
      this.hour = String(this.hour).padStart(2, '0');
    }
    else {
      this.minutes = String(this.minutes).padStart(2, '0');
    }
  }

  applyTime() {
    if(isNaN(this.hour) || this.hour === '') {
      this.hour = '00';
    }
    
    if(isNaN(this.minutes) || this.minutes === '') {
      this.minutes = '00';
    }
    this.time = `${this.hour}:${this.minutes}`;
    this.isOpenTimeMenu = false;
  };

}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

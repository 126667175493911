import { Component, Input } from '@angular/core';
import { IFullAppFields } from '@app/modules/full-app/models/full-app-fields';
import moment = require('moment');

@Component({
  selector: 'custom-input-drivers',
  templateUrl: './custom-input-drivers.component.html',
  styleUrls: ['./custom-input-drivers.component.scss']
})
export class CustomInputDriversComponent {
  @Input() config: IFullAppFields = { 
    id: 0,
    full_app_option: 0,
    title: '',
    value: '',
    selectArray: '',
    calendarTitle: '',
    date: '',
    timepickerTitle: '',
    time: '',
    checkboxSelectedValues: '',
    radioBtnSelected: false,
    users: 0
  }

  //Select menu
  isOpenSelectMenu: boolean = false;

  //Calendar
  isOpenCalendar: boolean = false;

  //Timepicker
  isOpenTimepicker: boolean = false; 
  hour: any;
  minutes: any;

  //Calendar method
  dateClicked(event: any) {
    this.config.date = moment(event._d).format('YYYY-MM-DDT00:00:00');
    this.isOpenCalendar = false;
  };

  //Timepicker method
  leadingZero(hour: boolean) {
    if(hour) {
      this.hour = String(this.hour).padStart(2, '0');
    }
    else {
      this.minutes = String(this.minutes).padStart(2, '0');
    }
  }
  
  applyTime() {
    if(isNaN(this.hour) || this.hour === '') {
      this.hour = '00';
    }
    
    if(isNaN(this.minutes) || this.minutes === '') {
      this.minutes = '00';
    }
    this.config.time = `${this.hour}:${this.minutes}`;
    this.isOpenTimepicker = false;
  };

  //Checkbox method
  checkUncheckValue(name: string) {
    let array: string[] = this.config.checkboxSelectedValues.split(',');
    let index: number = array.indexOf(name);
    if(index === -1) {
      array.push(name);
    }
    else {
      array.splice(index, 1);
    }
    this.config.checkboxSelectedValues = array.toString();
  }

}

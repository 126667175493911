import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { RecruitingPageComponent } from './components/recruiting-page/recruiting-page.component';
import { AuthGuard } from '../core/services/auth/auth.guard';

const routes: Routes = [
    {
        path: 'recruiting',
        canActivate: [AuthGuard],
        children: [
          {
            path: 'recruiting-page',
            component: RecruitingPageComponent
          },
        ],
        data: { roles: 8 }
    },
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class RecrutingRoutingModule { }

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'driverStatusFilter',
  pure: false
})
export class DriverStatusFilterPipe implements PipeTransform {

  transform(array: any[], filter: string): any[] {
    if (!array) {
      return [];
    }

    return array.filter((obj: any) => {
      let key: boolean = false;

      if(filter === 'All') {
        key = true;
      }
      
      if(filter === 'Violation' && (obj.status === 'Bad practice' || obj.status === "Driver can't be reached" 
      || obj.status === 'Dispatch fault' || obj.status === 'Load Damaged')) {
        key = true;
      };

      if(filter === 'Repair' && obj.status === 'Repair') {
        key = true;
      };

      if(filter === 'Hometime' && obj.status_group_name === filter && (obj.status === 'Regular' || obj.status === 'Bad practice')) {
        key = true;
      };

      if(filter === 'No status' && obj.status === 'No status') {
        key = true;
      };

      if(key) {
        return obj;
      };
 
    })
  }

}

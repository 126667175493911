import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'filter-numeric',
  templateUrl: './filter-numeric.component.html',
  styleUrls: ['./filter-numeric.component.scss']
})
export class FilterNumericComponent {
  @Input() config: any = {
    filterSign: 'More than',
    filterValue: null
  };
  @Input() styleConfig: any = {
    'width': '200px',
    'background-color': '#fff',
    'color': '#1F1F1F',
    'box-shadow': '0px 1px 6.65px 0.35px rgba(13, 39, 80, 0.16)',
    'border-radius': '25px'
  };
  @Output() triggerEvent = new EventEmitter<any>();

  //Num values
  radioBtns: any[] = [
    {name: 'More than'},
    {name: 'Less than'},
    {name: 'Equal'},
  ];
  isOpenFilterMenu: boolean = false;

}

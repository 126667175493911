import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DriverInfoReasonsCreateUpdateDialogComponent } from './driver-info-reasons-create-update-dialog/driver-info-reasons-create-update-dialog.component';
import { DeleteConfirmationDialogComponent } from '@app/modules/shared/components/delete-confirmation-dialog/delete-confirmation-dialog.component';
import { DriversProfileService } from '@app/modules/drivers-profile-page/services/drivers-profile.service';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import moment = require('moment');
import { SharedService } from '@app/modules/shared/services/shared.service';
import { ConnectionPositionPair } from '@angular/cdk/overlay';

@Component({
  selector: 'app-driver-info-dialog',
  templateUrl: './driver-info-dialog.component.html',
  styleUrls: ['./driver-info-dialog.component.scss']
})
export class DriverInfoDialogComponent implements OnInit, OnDestroy {
  formGroup: FormGroup;

  //Positions
  public positions = [
    new ConnectionPositionPair(
      {originX: 'end', originY: 'bottom'},
      {overlayX: 'end', overlayY: 'top'},
      0, 5
    )
  ];

  //Outcome menu
  outcomeArray: any[] = [
    {name: 'Terminated'},
    {name: 'Quit'}
  ];
  isOpenOutcomeMenu: boolean = false;

  //Reason
  reasonsArray: any[] = [];
  isOpenReasonMenu: boolean = false;

  //Eligible for rehiring
  eligibleForRehiringArray: any[] = [
    {name: 'Yes'},
    {name: 'No'},
  ];
  isOpeneligibleForRehiringMenu: boolean = false;

  //Eligible for rehiring
  eligibleForSecurityDepositArray: any[] = [
    {name: 'Yes', booleanValue: true},
    {name: 'No', booleanValue: false},
  ];
  isOpeneligibleForSecurityDepositMenu: boolean = false;

  //Files
  files: any[] = [];

  //Email for notification
  emailArray: any[] = [];

  //Subscription
  subscription1: Subscription = new Subscription();
  subscription2: Subscription = new Subscription();

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialog: MatDialog,
  public dialogRef: MatDialogRef<DriverInfoDialogComponent>, private driversProfileService: DriversProfileService,
  private formBuilder: FormBuilder, private sharedService: SharedService) {
    this.formGroup = this.formBuilder.group({
      'driver_id': [null],
      'is_active':[null],
      'hire_date': [null],
      'termination_date': [null],
      'outcome': [null],
      'reason': [null],
      'eligible_for_rehiring': [null],
      'eligible_for_security_deposit': [null],
      'note': [null]
    })
  }

  ngOnInit(): void {
    this.formGroup.patchValue({
      'driver_id': this.data.id,
      'is_active': this.data.is_active,
      'hire_date': this.data.hire_date,
      'termination_date': this.data.termination_date
    });
    this.setValidation();
    this.subscription1 = this.driversProfileService.getAllDriverReasons().subscribe((response: any) => {
      this.reasonsArray = response;
    });
    this.getAllEmails();
  }

  getAllEmails() {
    let departments: string[] = ['Accounting', 'Safety', 'Recruiting', 'Afterhours', 'Dispatch', 'Dispatch supervisor', 'Hours Of Service'];
    this.subscription2 = this.sharedService.getAllEmails().subscribe((response: any) => {
      let filteredArray: any[] = [];

      for(let i = 0; i < response.length; i++) {

        for(let j = 0; j < response[i].companies.length; j++) {
          if(response[i].companies[j].is_primary && departments.includes(response[i].department)) {
            filteredArray.push({id: response[i].settings_id, email: response[i].companies[j].email, name: `${response[i].name} / ${response[i].nickname} ${response[i].companies[j].ext}`});
            break;
          }
        }
      }
      this.emailArray = filteredArray;
    })
  }

  //Change status
  changeStatus() {
    this.formGroup.patchValue({'is_active': !this.getFormData('is_active')});
    this.setValidation();
  };

  setValidation() {
    if(this.getFormData('is_active')) {
      this.clearValidators('outcome');
      this.clearValidators('reason');
      this.clearValidators('eligible_for_rehiring');
      this.clearValidators('eligible_for_security_deposit');
      this.clearValidators('note');
    }
    else {
      this.setValidators('outcome', [Validators.required, Validators.minLength(1)]);
      this.setValidators('reason', [Validators.required, Validators.minLength(1)]);
      this.setValidators('eligible_for_rehiring', [Validators.required, Validators.minLength(1)]);
      this.setValidators('eligible_for_security_deposit', [Validators.required]);
      this.setValidators('note', [Validators.required, Validators.minLength(2)]);
    };
  };

  setValidators(key: string, validators: any[]) {
    this.formGroup.controls[key].setValidators(validators);
    this.formGroup.controls[key].updateValueAndValidity();
  };

  clearValidators(key: string) {
    this.formGroup.controls[key].clearValidators();
    this.formGroup.controls[key].updateValueAndValidity();
  };

  addEditReasonDialog(editMode: boolean) {
    this.isOpenReasonMenu = !this.isOpenReasonMenu;
    let dialogRef = this.dialog.open(DriverInfoReasonsCreateUpdateDialogComponent, {
      autoFocus: false,
      panelClass: 'component-dialog-container',
      data: {data: this.reasonsArray, editMode: editMode, }
    });
    dialogRef.afterClosed().subscribe((reasonsArray: any[]) => {
      if(reasonsArray) {
        this.reasonsArray = reasonsArray;
      }
    });
  };

  //Add files
  onFileSelected(event: any) {
    let fileNames: any[] = [];  
    let listOfFiles: any = event.target.files;  
    const formData: any = new FormData();  
    const reader: any = new FileReader();

    for  (let i =  0; i <  listOfFiles.length; i++)  {  
      formData.append("file[]", listOfFiles[i], listOfFiles[i].name);

      reader.onload = () => {
        fileNames.push({filename: listOfFiles[i].name, data: reader.result.split(',')[1]});
      };
      reader.readAsDataURL(listOfFiles[i]);

    } 
    this.driversProfileService.uploadFiles(this.data.id, formData).subscribe((response: any) => {
      if(response) {
        this.files = fileNames;
      };
    });
  };

  openFileInNewTab(obj: any) {
    this.sharedService.downloadPreviewFile(obj.filename, obj.data);
  };

  downloadFile(obj: any) {
    obj.isOpenFileMenu = !obj.isOpenFileMenu;
    let source: string = `data:application/octet-stream;base64,${obj.data}`;
    const downloadLink = document.createElement('a');
    const fileName = obj.filename;
    downloadLink.href = source;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  deleteFile(obj: any, index: number) {
    obj.isOpenFileMenu = !obj.isOpenFileMenu;
    let dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
      autoFocus: false,
      panelClass: 'delete-dialog-container',
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
        this.driversProfileService.deleteDriverFile(obj.filename, this.data.id)
        .subscribe((response: any) => {
          console.log(response);
          if(response) {
            this.files.splice(index, 1);
          } 
        });
      }
    })
  };

  saveData() {
    let formData: any = this.formGroup.value;
    if(this.getFormData('is_active')) {
      formData.termination_date = null;
    }
    else {
      this.formGroup.patchValue({'termination_date': moment().format('YYYY-MM-DDT00:00:00')});
    }
    this.driversProfileService.changeStatus(formData)
    .subscribe((response: any) => {
      if(response) {
        if(!this.getFormData('is_active')) {
          this.createThread();
        }
        else {
          this.dialogRef.close({data: [], terminationDate: null});
        }
      }
    });
  }

  createThread() {
    let loggedUser: any = JSON.parse(localStorage.getItem('currentUser'));
    let dataObj: any = {
      id: 0,
      driver_id: this.data.id,
      subject: `${this.getFormData('outcome')} - Reason (${this.getFormData('reason')})`,
      create_date:  moment().format('YYYY-MM-DDTHH:mm:ss'),
      creator: `${loggedUser.first_name} ${loggedUser.last_name}`,
      department: loggedUser.department,
      editor: null,
      editor_department: null,
      edit_date: null,
      closed: null,
      closed_by: null,
      closed_by_department: null,
      close_date: null,
      notes: null,
      files: null,
      emails: [JSON.stringify(this.emailArray)]
    }
    console.log(dataObj);
    this.driversProfileService.createThreadNoteExhchange(dataObj).subscribe((response: any) => {
      if(response?.id) {
        let data: any = response;
        //Send note
        let obj: any = {
          id: 0,
          note_id: response.id,
          driver_id: this.data.id,
          note: this.getFormData('note'),
          note_date: moment().format('YYYY-MM-DDTHH:mm:ss'),
          creator: `${loggedUser.first_name} ${loggedUser.last_name}`,
          creator_department: loggedUser.department,
          editor: null,
          editor_department: null,
          edit_date: null,
          files: []
        }

        this.driversProfileService.createEditNoteExchange(obj).subscribe((response: any) => {
          if(response) {
            console.log(response);
            data.notes = [response];
          }
        });

        let notificationsArray: any[] = JSON.parse(JSON.stringify(dataObj.emails));
        notificationsArray.push({id: 27, email: 'nikolab@jdmexpedite.com', name: 'Nikola Beloica'});

        //Send notification
        for(let key in notificationsArray) {
        
          if(notificationsArray[key].id !== loggedUser.id && notificationsArray[key].id !== 0) {
  
            let obj: any = {
              id: null,
              type: 'NOTE',
              data: JSON.stringify(dataObj),
              date: moment().add(1, 'hour').format('YYYY-MM-DDTHH:mm:ss'),
              from_user: loggedUser.id,
              for_user: notificationsArray[key].id,
            }
            
            this.sharedService.sendNotification(obj).subscribe((response: any) => {
   
            });
          }
        } 
        this.dialogRef.close({data: data, terminationDate: this.getFormData('termination_date')});
      }
    });
  }

  getFormData(key: string): string {
    return this.formGroup.controls[key].value; 
  };

  get eligibleForSecurityDeposit(): string {
    let value: any = this.formGroup.controls['eligible_for_security_deposit'].value;
    if(value) {
      return 'Yes';
    }
    else if(value === false) {
      return 'No';
    }
    else {
      return '';
    }
  }

  ngOnDestroy(): void {
    this.subscription1?.unsubscribe(); 
    this.subscription2?.unsubscribe();  
  };

}

import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TransformService } from '@app/modules/shared/services/transform.service';
import { TrucksService } from '@app/modules/trucks/services/trucks.service';
import { ApexAxisChartSeries, ApexChart, ApexDataLabels, ApexGrid, ApexStroke, ApexXAxis, ApexYAxis, ChartComponent } from 'ng-apexcharts';
import { Subscription } from 'rxjs';

export type ChartOptions = {
  series: ApexAxisChartSeries | any;
  chart: ApexChart | any;
  xaxis: ApexXAxis | any;
  yaxis: ApexYAxis | any;
  dataLabels: ApexDataLabels | any;
  grid: ApexGrid | any;
  stroke: ApexStroke | any;
  colors: any;
};

@Component({
  selector: 'app-truck-performance-analysis-linechart',
  templateUrl: './truck-performance-analysis-linechart.component.html',
  styleUrls: ['./truck-performance-analysis-linechart.component.scss']
})
export class TruckPerformanceAnalysisLinechartComponent implements OnInit, OnDestroy {
  @Input() date: any;
  @ViewChild("chart") chart: ChartComponent | any;

  public chartOptions: Partial<ChartOptions> | any;
  subscription: Subscription | any;

  selectPeriod: FormControl | any;

  addedUnits: string[] = [];

  graphData: any[] = [];

  //Period
  isOpenPeriodMenu: boolean = false;
  menuValue: string = 'By day';
  selectPeriodArray: any[] = [
    {name: 'By year', value: 'year'},
    {name: 'By quarter', value: 'quarter'},
    {name: 'By month', value: 'month'},
    {name: 'By week', value: 'week'},
    {name: 'By day', value: 'day'},
  ];
  
  constructor(private trucksService: TrucksService, private transformService: TransformService) {
    this.selectPeriod = new FormControl(['day']);
}

  ngOnInit(): void {
    this.dataChanged();
  }

  dataChanged() {
    this.subscription = this.trucksService.truckAnalysisLineChart.subscribe((response: any) => {
      if (response.reset) {
        this.graphData = [];
        this.addedUnits = [];
      } else {
        console.log(this.graphData);
        let unitNo: string = response.unitNo + ' (' + response.title + ')';
        if (this.addedUnits.includes(unitNo) && this.addedUnits.length > 0) {
          this.graphData = this.graphData.filter(object => {
            return object.name[0] !== unitNo
          });
          this.addedUnits = this.addedUnits.filter(unit_no => {
            return unit_no !== unitNo
          });

        } else {
          this.addedUnits.push(unitNo);
          this.graphData.push(this.transformService.countDataByPeriodDown(response.data, this.selectPeriod.value[0], response?.key,
            response?.title));
        }

        if(this.graphData.length > 0) {
          this.initGraph(this.graphData);
        }
      }
    })
  }

  initGraph(data: any) {
    let allDatesArray: any[] = this.transformService.getDatesInRange(new Date(this.date.startDate), new Date(this.date.endDate), this.selectPeriod.value[0]);
    let arrayData: any[] = JSON.parse(JSON.stringify(data));
    let seriesData: any[] = [];
    for (let key in arrayData) {

      let obj: any = {
        name: arrayData[key].name,
        data: arrayData[key].total
      }
      
      for(let i = 0; i < allDatesArray.length; i++) {
          if(arrayData[key].categories[i] !== allDatesArray[i]) {
            arrayData[key].categories.splice(i, 0, allDatesArray[i])
            arrayData[key].total.splice(i, 0, 0);
          }
      }
      seriesData.push(obj)
    }

    this.chartOptions = {
      series: seriesData,
      chart: {
        toolbar: {
          show: true,
          tools: {
            download: false
          },
          animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 800,
            animateGradually: {
                enabled: true,
                delay: 150
            },
            dynamicAnimation: {
                enabled: true,
                speed: 350
            }
          }
        },
        height: 350,
        type: "line",
        zoom: {
          enabled: false
        },
        fontFamily: 'Poppins'
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: "straight",
        width: 1,
      },
      grid: {
        row: {
          colors: ["transparent", "transparent"],
          opacity: 0.5
        }
      },
      xaxis: {
        categories: arrayData[0].categories
      },
      yaxis: this.addYAxis(seriesData),
      markers: {
        size: 5
      },
      colors: ['#0030FF', '#FA9120', '#FA0000', '#008000', '#FF00FF', '#800000', '#FE7777', '#0303BB', '#008FA9', '#7550CB', '#6A6D6E'],
      tooltip: {
        enabled: true,
        inverseOrder: false,
        style: {
          fontSize: '12px'
        },
      }
    };
  }

  addYAxis(array: any) {
    let yAxisArray: any[] = [];
    let colors: string[] = ['#0030FF', '#FA9120', '#FA0000', '#008000', '#FF00FF', '#800000', '#FE7777', '#0303BB', '#008FA9', '#7550CB', '#6A6D6E'];
    for(let i = 0; i < array.length; i++) {
      let obj: any;
      if(i === 0) {
        obj = {
        seriesName: array[i].name[0], axisTicks: {show: true}, axisBorder: {show: false, color: colors[i]},
        labels: {style: {colors: colors[i]}, formatter: (num: number) => { return num.toLocaleString("en-US").toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}
        }
      } 
      else {
        obj = {
          seriesName: array[i].name[0], opposite: true, axisTicks: {show: true}, axisBorder: {show: false, color: colors[i]},
          labels: {style: {colors: colors[i]}, formatter: (num: number) => { return num.toLocaleString("en-US").toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}
        }
      }

      yAxisArray.push(obj);
    }

    return yAxisArray;
  }


  onlyOneSelect(value: string, obj: any) {
    this.menuValue = obj.name;
    this.selectPeriod = new FormControl([value]);
    let lineChartData: any = JSON.parse(JSON.stringify(this.graphData));
    this.graphData = [];
    for (let key in lineChartData) {
      this.graphData.push(this.transformService.countDataByPeriodDown(lineChartData[key].data, this.selectPeriod.value[0],
        lineChartData[key].key, lineChartData[key].title));
    }
    this.initGraph(this.graphData);
    this.isOpenPeriodMenu = false;
  };

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}


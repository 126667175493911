import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadBoardComponent } from './components/load-board/load-board.component';
import { LoadBoardRoutingModule } from './load-board.routing';
import { SharedModule } from '../shared/shared.module';
import { LoadBoardService } from './services/load-board.service';
import { LoadBoardCardComponent } from './components/load-board/load-board-card/load-board-card.component';
import { CreateUpdateProfilDialogComponent } from './components/load-board/load-board-card/create-update-profil-dialog/create-update-profil-dialog.component';
import { AddContactDialogComponent } from './components/load-board/load-board-card/create-update-profil-dialog/add-contact-dialog/add-contact-dialog.component';
import { ProfileActivityDialogComponent } from './components/load-board/load-board-card/profile-activity-dialog/profile-activity-dialog.component';
import { LoadBoardTablePipe } from './pipes/load-board-table.pipe';
import { SearchLoadsCardComponent } from './components/search-loads-card/search-loads-card.component';
import { LoadDetailsDialogComponent } from './components/search-loads-card/load-details-dialog/load-details-dialog.component';

@NgModule({
  declarations: [
    LoadBoardComponent,
    LoadBoardCardComponent,
    CreateUpdateProfilDialogComponent,
    AddContactDialogComponent,
    ProfileActivityDialogComponent,
    LoadBoardTablePipe,
    SearchLoadsCardComponent,
    LoadDetailsDialogComponent
  ],
  imports: [
    CommonModule,
    LoadBoardRoutingModule,
    SharedModule
  ],
  providers: [LoadBoardService]
})
export class LoadBoardModule { }

import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-remove-from-list-confirmation-dialog',
  templateUrl: './remove-from-list-confirmation-dialog.component.html',
  styleUrls: ['./remove-from-list-confirmation-dialog.component.scss']
})
export class RemoveFromListConfirmationDialogComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public title: string, public dialogRef: MatDialogRef<RemoveFromListConfirmationDialogComponent>) { }

  confirmAction() {
    this.dialogRef.close(true);
  };
  
}

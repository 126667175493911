import { Component, OnDestroy, OnInit } from '@angular/core';
import moment = require('moment');
import { Subscription, catchError, throwError } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { MsgForbbidenAccessComponent } from '@app/modules/shared/components/msg-forbbiden-access/msg-forbbiden-access.component';
import { CreateUpdateTicketDialogComponent } from './create-update-ticket-dialog/create-update-ticket-dialog.component';
import { TransformService } from '@app/modules/shared/services/transform.service';
import { TicketsService } from '../services/tickets.service';
import { SharedService } from '@app/modules/shared/services/shared.service';
import { RulesService } from '@app/modules/shared/services/rules.service';

@Component({
  selector: 'app-tickets',
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.scss']
})
export class TicketsComponent implements OnInit, OnDestroy {
  permissions: any = this.rulesService.UserData[28].data;

  //Date
  dateObj: any = {
    startDate: this.transformService.convertDateToTimestamp(moment().startOf('month').format('ddd, DD/MM YYYY'), 
    '00:00:00'),
    endDate: this.transformService.convertDateToTimestamp(moment().format('ddd, DD/MM YYYY'),  moment().format('HH:mm:ss')),
  };

  dataSource: any[] = [];

  //Select
  driversDataOriginalArray: any[] = [];
  drivers: any[] = [];
  selectedDrivers: any[] = new Array<any>();
  searchText: string = '';
  isOpen: boolean = false;

  //Active inactive menu
  driverStatusArray: any[] = [
    {status: 'All drivers'},
    {status: 'Active drivers'},
    {status: 'Inactive drivers'}
  ];
  isOpenDriverMenu: boolean= false;
  driverStatus: string = 'Active drivers';

  //Statistics obj
  stObj: any = {
    open: 0,
    inProgress: 0,
    closed: 0
  }
  
  ticketStatus: string = 'All';

  subscription: Subscription = new Subscription();

  loaded: boolean = false;

  error: boolean = false;

  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";

  spinnerBgColor: string = this.transformService.spinnerBgColor;
  spinnerType: string = this.transformService.spinnerType;

  constructor(private transformService: TransformService,
              private dialog: MatDialog,
              private ticketsService: TicketsService,
              private sharedService: SharedService,
              private rulesService: RulesService,
              private spinner: NgxSpinnerService,
              private router: Router) { }
  
  ngOnInit() {
    this.getTableData();
  }

  getTableData() {
    this.spinner.show('tickets-section');
    this.stObj = {
      open: 0,
      inProgress: 0,
      closed: 0
    }
    this.subscription = this.ticketsService.getTicketsData(this.dateObj.startDate, this.dateObj.endDate)
    .pipe(catchError((err: any) => {
      this.spinner.hide('tickets-section');
      this.loaded = true;
      this.error = true;
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      console.log(response)
      let data = response.reduce((acc: any, val: any, ind: any) => {

        if(val.status === 'Open') {
          this.stObj.open++;
        }
        if(val.status === 'In progress') {
          this.stObj.inProgress++;
        }
        if(val.status === 'Closed') {
          this.stObj.closed++;
        }
    
        const index = acc.findIndex((el: any) => el.driver_id === val.driver_id);
        if(index !== -1){
          acc[index].ticketsArray.push(val);
        } 
        else {
          let obj: any = {...val};
          val.ticketsArray = [obj];
          acc.push(val);
        };

        return acc;
      }, []);
      this.driversDataOriginalArray = JSON.parse(JSON.stringify(data));
      this.drivers = data;
      this.dataSource = data;
      this.spinner.hide('tickets-section');
      this.loaded = true;
      console.log(data);
    })
  };

  getDate(result: any) {
    this.dateObj.startDate = result.startDate;
    this.dateObj.endDate = result.endDate;
    this.dataSource = [];
    this.error = false;
    this.loaded = false;
    this.getTableData();
  };

  // Open Tickets Dialog
  openTicketDialog(editMode: boolean, obj: any) {
    if(this.permissions[0].allowedAll) {
      let dialogRef = this.dialog.open(CreateUpdateTicketDialogComponent, {
        autoFocus: false,
        panelClass: 'create-update-ticket-main-container',
        disableClose: true,
        data: {editMode: editMode, obj: obj}
      });
      dialogRef.afterClosed().subscribe((result: any) => {
        if(result) {
          this.dataSource = [];
          this.error = false;
          this.loaded = false;
          this.getTableData();
        }
      });
    }
    else {
      this.dialog.open(MsgForbbidenAccessComponent, {
        autoFocus: false,
        panelClass: 'forbidden-msg-dialog-container'
      })
    }
  }

  // Search
  toggleSelection(user: any) {
    user.selected = !user.selected;
    if (user.selected) {
      this.selectedDrivers.push(user);
      this.dataSource = JSON.parse(JSON.stringify(this.selectedDrivers));
    } else {
      const i = this.selectedDrivers.findIndex(value => value.name === user.name);
      this.selectedDrivers.splice(i, 1);
      this.dataSource = JSON.parse(JSON.stringify(this.selectedDrivers));
    }
    this.dataSource.length === 0 ? this.dataSource = JSON.parse(JSON.stringify(this.driversDataOriginalArray)) : this.dataSource;
  }

  resetCheckedValue() {
    let data: any = JSON.parse(JSON.stringify(this.driversDataOriginalArray));
    this.dataSource = data;
    this.drivers = data;
    this.searchText = '';
    this.selectedDrivers = [];
  }

  // Sort data
  sortData(sort: Sort) {
    const data = this.dataSource.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSource = data;
      return;
    }
    this.dataSource = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'driver':
          return compare(a.driver, b.driver, isAsc);
        case 'ticketsArray':
          return compare(a.ticketsArray.length, b.ticketsArray.length, isAsc);
        default:
          return 0;
      }
    });
  }

  getClass(number: number) {
    if(number >= 3) {
      return 'danger-bg';
    }
    else if(number === 2) {
      return 'warning-bg';
    }
    else {
      return '';
    }
  };

  getBgColor(status: string) {
    if(status === 'Open') {
      return 'green-status-bg ';
    }
    else if(status === 'In progress') {
      return 'blue-status-bg';
    }
    else if(status === 'Closed') {
      return 'red-status-bg'
    }
    else {
      return '';
    }
  };

  downloadPreviewFile(obj: any) {
    this.sharedService.downloadPreviewFile(obj.filename, obj.data);
  };

  //Open in new tab
  openInNewTab(route: string) {
    const url = this.router.serializeUrl(this.router.createUrlTree([route]));
    window.open(url, '_blank');
  };

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  };

}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}


import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { RulesService } from '@app/modules/shared/services/rules.service';
import { SharedService } from '@app/modules/shared/services/shared.service';
import { TransformService } from '@app/modules/shared/services/transform.service';
import { TrucksService } from '@app/modules/trucks/services/trucks.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { catchError, Subscription, throwError } from 'rxjs';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-truck-performance-analysis-table',
  templateUrl: './truck-performance-analysis-table.component.html',
  styleUrls: ['./truck-performance-analysis-table.component.scss']
})
export class TruckPerformanceAnalysisTableComponent implements OnInit, OnChanges, OnDestroy {
  @Input() date: any;
  
  dataSource: any[] = [];

  sortedData: any[] = [];

  displayedColumns: string[] = ['position', 'unit_no', 'status', 'makeANDmodel', 'year', 'company', 'dispatcher', 'driver',
  'type', 'odometer', 'fuel_consumption', 'maintenance_cpm', 'maintenance_cost', 'gross', 'rate_per_mile', 'net_profit'];

  className: string = '';
  columnName: string = '';

  isOpen: boolean = false;
  isOpen1: boolean = false;
  isOpen2: boolean = false;
  calendarDate1: string = '';
  calendarDate2: string = '';

  //Graph
  fileName = 'truck-analysis-table.xlsx';

  compare: boolean = false;

  clickedColumn: string | undefined;
  selectedIndexArray: number[] = [];

  oneRowClickedColumns: boolean[] = [false, false, false, false, false, false, false];

  listByDays: any;

  graphData: any[] = [];

  title: any;

  selectedUsers: any[] = new Array<any>();

  searchText: string = '';

  selectedRow: any;

  dataTypingArray: any[] = [];

  countingObj: any = {
    numOfTrucks: 0,
    totalOdometer: 0,
    avgFuelConsumption: 0,
    avgMaintenanceCpm: 0,
    totalMaintenanceCost: 0,
    totalGross: 0,
    avgRpm: 0,
    avgNetprofit: 0
  };

  //Filter table
  statusArray: string[] = [
    'All', 'Active', 'Inactive'
  ];
  filterValue: string = 'Active';
  isOpenFilterMenu = false;

  
  //Interval
  interval: any;

  loaded: boolean = false;

  error: boolean = false;

  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";

  spinnerBgColor: string = this.transformService.spinnerBgColor;
  spinnerType: string = this.transformService.spinnerType;

  subscription1: Subscription | any;
  subscription2: Subscription | any;

  constructor(public transformService: TransformService,
              private trucksService: TrucksService,
              private spinner: NgxSpinnerService,
              private sharedService: SharedService,
              private rulesService: RulesService,
              private router: Router) { }

  ngOnInit(): void {
    this.spinner.show('truck-analysis');
    this.dateChanged();
    if(this.rulesService.liveUpdate) {
      this.interval = setInterval(() => {
        this.getTableData();
      }, this.rulesService.miliseconds);
    }
  }

  ngOnChanges(): void {
    if(this.date) {
      this.getTableData();
    }
  }

  getTableData() {
    this.subscription1 = this.trucksService.getTruckAnalysisTableData(this.date.startDate, this.date.endDate, this.transformService.filterParams)
    .pipe(catchError((err: any) => {
      this.spinner.hide('truck-analysis');
      this.loaded = true;
      this.error = true;
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      console.log(response);
      this.reset();
      this.listByDays = response.list;
      this.dataSource = JSON.parse(JSON.stringify(response.total));
      this.filterTable('Active');
      this.dataTypingArray = response.total;
      this.spinner.hide('truck-analysis');
      this.loaded = true;
    })
  }

  //Filter table data
  filterTable(value: string) {
    let allDrivers: any[] = JSON.parse(JSON.stringify(this.dataSource));

    let filteredArray: any[] = [];

    if(value === 'All') {
      filteredArray = allDrivers;
    }
    else {

      for(let key in allDrivers) {

        if(allDrivers[key].status === value) {
          filteredArray.push(allDrivers[key]);
        }

      }

    }
    this.calculateData(filteredArray);
    this.sortedData = filteredArray;
    this.filterValue = value;
    this.isOpenFilterMenu = false;
  };

  dateChanged() {
    this.subscription2 = this.trucksService.truckPerformanceDateChanged.subscribe((response: any) => {
      this.date.startDate = response.startDate;
      this.date.endDate = response.endDate;
      this.sortedData = [];

      this.error = false;
      this.loaded = false;

      this.spinner.show('truck-analysis');
      this.getTableData();
    })
  }

  sortData(sort: Sort) {
    const data = this.dataSource.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }
    this.sortedData = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'unit_no':
          return compare(a.unit_no, b.unit_no, isAsc);
        case 'status':
          return compare(a.status, b.status, isAsc);
        case 'makeANDmodel':
          return compare(a.makeANDmodel, b.makeANDmodel, isAsc);
        case 'year':
          return compare(a.year, b.year, isAsc);
        case 'company':
          return compare(a.company, b.company, isAsc);
        case 'dispatcher':
          return compare(a.dispatcher, b.dispatcher, isAsc);
        case 'driver':
          return compare(a.driver, b.driver, isAsc);
        case 'type':
          return compare(a.type, b.type, isAsc);
        case 'odometer':
          return compare(a.odometer, b.odometer, isAsc);
        case 'fuel_consumption':
          return compare(a.fuel_consumption, b.fuel_consumption, isAsc);
        case 'maintenance_cpm':
          return compare(a.maintenance_cpm, b.maintenance_cpm, isAsc);
        case 'maintenance_cost':
          return compare(a.maintenance_cost, b.maintenance_cost, isAsc);
        case 'gross':
          return compare(a.gross, b.gross, isAsc);
        case 'rate_per_mile':
          return compare(a.rate_per_mile, b.rate_per_mile, isAsc);
        case 'net_profit':
          return compare(a.net_profit, b.net_profit, isAsc);
        default:
          return 0;
      }
    });
  }

  onMouseOver(columnName: string) {
    this.columnName = columnName;
    this.className = 'hovered-column';
  }

  onMouseOut(columnName: string) {
    this.columnName = columnName;
    this.className = '';
  }

  exportToExcel() {
    let element = document.getElementById('trucks-analysis-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    XLSX.writeFile(wb, this.fileName);
  };

  select(obj: any, key: string, index: number, title: string, oneRowColumn?: any) {
    if (!this.compare) {
      this.selectedRow !== index && this.selectedRow !== undefined ? this.reset() : this.selectedRow = index;
      this.clickedColumn = key;
      this.oneRowClickedColumns[oneRowColumn] = !this.oneRowClickedColumns[oneRowColumn];
      let array: any[] = [];
      this.selectedRow = index;

      for (let key in this.listByDays) {
        let unit_no = this.listByDays[key].unit_no;
        if (obj.unit_no === unit_no) {
          array.push(this.listByDays[key]);
        }
      }

      let objForGraph = { key: this.clickedColumn, data: array, title: title, unitNo: obj.unit_no, reset: false }
      this.trucksService.truckAnalysisLineChart.next(objForGraph);
    } else {

      if (this.selectedIndexArray.length === 0) {
        this.clickedColumn = key;
      }

      if (this.clickedColumn === key) {

        let res = this.selectedIndexArray.findIndex((num: number) => num === index);

        if (res === -1) {
          this.selectedIndexArray.push(index);
        } else {
          this.selectedIndexArray.splice(res, 1);
        }
        let array: any[] = [];

        for (let key in this.listByDays) {
          let unit_no = this.listByDays[key].unit_no;
          if (obj.unit_no === unit_no) {
            array.push(this.listByDays[key]);
          }
        }
  
        let objForGraph = { key: this.clickedColumn, data: array, title: title, unitNo: obj.unit_no, reset: false }
        this.trucksService.truckAnalysisLineChart.next(objForGraph);
      }
    }
  }

  setClass(index: number, clickedColumn: string, oneRowIndex?: any) {
    let className: string = '';

    if (!this.compare && this.oneRowClickedColumns[oneRowIndex] && this.selectedRow === index) {
      return className = 'active';
    }

    if (this.selectedIndexArray.includes(index) && clickedColumn === this.clickedColumn) {
      className = 'active';
    }

    return className;
  }

  //Calculation
  calculateData(data: any[]) {
    let numOfTrucks: number = data.length;
    let sumOdometer: number = 0;
    let sumFuelConsumption: number = 0;
    let sumMaintenanceCost: number = 0;
    let sumGross: number = 0;
    let sumMileage: number = 0;
    let sumNetprofit: number = 0;

    for(let key in data) {
        sumOdometer += data[key].odometer;
        sumFuelConsumption += data[key].fuel_consumption;
        sumGross += data[key].gross;
        sumMileage += data[key].mileage;
        sumMaintenanceCost += data[key].maintenance_cost;
    }

    this.countingObj.numOfTrucks = this.transformService.addCommasDots(numOfTrucks, 'round');
    this.countingObj.totalOdometer = this.transformService.addCommasDots(sumOdometer, 'round');
    this.countingObj.avgFuelConsumption = this.transformService.addCommasDots(sumFuelConsumption / numOfTrucks);
    this.countingObj.avgMaintenanceCpm = this.transformService.addCommasDots(sumMaintenanceCost / sumOdometer);
    this.countingObj.totalMaintenanceCost = this.transformService.addCommasDots(sumMaintenanceCost, 'round');
    this.countingObj.totalGross = this.transformService.addCommasDots(sumGross, 'round');
    this.countingObj.avgRpm = this.transformService.addCommasDots(sumGross / sumMileage);
    this.countingObj.avgNetprofit = this.transformService.addCommasDots(sumNetprofit, 'round');
  }

  //Search

  toggleSelection(user: any) {
    user.selected = !user.selected;
    if (user.selected) {
      this.reset();
      this.selectedUsers.push(user);
      this.sortedData = JSON.parse(JSON.stringify(this.selectedUsers));
    } else {
      const i = this.selectedUsers.findIndex(value => value.unit_no === user.unit_no);
      this.selectedUsers.splice(i, 1);
      this.sortedData = JSON.parse(JSON.stringify(this.selectedUsers));
      this.reset();
    }
    this.sortedData.length === 0 ? this.sortedData = JSON.parse(JSON.stringify(this.dataSource)) : this.sortedData
  }

  onChange() {
    this.compare = !this.compare;
    this.reset();
  }

  reset() {
    let objForGraph = { key: undefined, data: undefined, title: undefined, dispatcherName: undefined, reset: true };
    this.selectedIndexArray = [];
    this.oneRowClickedColumns = [false, false, false, false, false, false, false, false, false, false];
    this.graphData = [];
    this.clickedColumn = undefined;
    this.trucksService.truckAnalysisLineChart.next(objForGraph);
  }

  resetCheckedValue() {
    let data: any = JSON.parse(JSON.stringify(this.dataSource));
    this.sortedData = data;
    this.dataTypingArray = data;
    this.searchText = '';
    this.selectedUsers = [];
  }

  //Open in new tab
  openInNewTab(route: string) {
    const url = this.router.serializeUrl(this.router.createUrlTree([route]));
    window.open(url, '_blank');
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
    this.subscription1?.unsubscribe();
    this.subscription2?.unsubscribe();
  }
  
}
  
  function compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { ForgotPasswordPageComponent } from './components/forgot-password-page/forgot-password-page.component';
import { AlreadyLoggedGuard } from './services/auth/already-logged.guard';



const routes: Routes = [
	{
		path: 'login',
		component: LoginComponent,
		canActivate: [AlreadyLoggedGuard],
		data: { title: 'JDM SOFTWARE' }
	},
	{
		path: 'reset-password',
		component: ForgotPasswordPageComponent,
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class CoreRoutingModule { }

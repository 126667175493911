import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SafetyService } from '@app/modules/safety/services/safety.service';
import { TransformService } from '@app/modules/shared/services/transform.service';
import moment = require('moment');
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-add-edit-template-dialog',
  templateUrl: './add-edit-template-dialog.component.html',
  styleUrls: ['./add-edit-template-dialog.component.scss']
})
export class AddEditTemplateDialogComponent implements OnInit, OnDestroy {
  //Divisions
  isOpenDivisionMenu: boolean = false;
  divisionsValue: string = 'All divisions';
  divisionArray: any[] = [
    {name: 'JDM Expedite INC', id: 1, selected: true},
    {name: 'JDM Logistix Inc.', id: 2, selected: true},
    {name: 'Tempo Freight Systems LLC', id: 3, selected: true},
    {name: 'Five Star Transport', id: 0, selected: true}
  ];

  //Driver type
  isOpenDriverTypeMenu: boolean = false;
  typeValue: string = 'All types';
  typesArray: any[] = [
    {name: 'Company', selected: true, filter: ['SOLO COMPANY', 'TEAM COMPANY']},
    {name: 'Rent to run', selected: true, filter: ['SOLO RENT', 'TEAM RENT']},
    {name: 'Owner', selected: true, filter: ['OWNER', 'OWNER OPERATOR']},
  ];

  //Drivers
  isOpenDriverMenu: boolean = false;
  driversValue: string = 'Select drivers';
  driversArray: any[] = [];
  allDrivers: boolean = false;

  driversDataArray: any[] = [];

  //Template name
  templateName: string = '';

  //Category
  categoryArray: any[] = [];
  isOpenCategoryMenu: boolean = false;
  categoryValue: string = 'All Categories';
  allCategories: boolean = false;

  //Subscription
  subscription1: Subscription | any;
  subscription2: Subscription | any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  public dialogRef: MatDialogRef<any>,
  private transformService: TransformService,
  private safetyService: SafetyService) { }

  ngOnInit(): void {
    this.subscription1 = this.safetyService.getContractCategories().subscribe((response: any) => {
      this.categoryArray = response;
      if(!this.data.newTemplate) {
        let selectedCategories: any = this.data.category.split('|');
        for(let key in this.categoryArray) {

          for(let key2 in selectedCategories) {
            if(selectedCategories[key2] === this.categoryArray[key].categoryName) {
              this.categoryArray[key].selected = true;
            }
          }
        }
      }
      else {
      this.categoryArray.forEach(element => element.selected = true);
      }
    })

    this.subscription2 = this.safetyService.getDivisionsContract().subscribe((response: any) => {
      this.driversDataArray = [...response];
      this.driversArray = response;

      this.driversArray.forEach(element => element.selected = true);
      
      if(!this.data.newTemplate) {
        this.templateName = this.data.name;

        this.divisionArray[0].selected = this.data.jdm;
        this.divisionArray[1].selected = this.data.logistic;
        this.divisionArray[2].selected = this.data.tfs;
        this.divisionArray[3].selected = this.data.fst;

        this.typesArray[0].selected = this.data.company;
        this.typesArray[1].selected = this.data.rent;
        this.typesArray[2].selected = this.data.owner;

        let selectedDrivers: any = this.data.drivers.split(',');
        console.log(selectedDrivers);
        for(let key in this.driversArray) {

          for(let key2 in selectedDrivers) {
            if(Number(selectedDrivers[key2]) === this.driversArray[key].driverID) {
              this.driversArray[key].selected = true;
            }
          }
        }

      }
    })
  }

  //Select division
  selectDivision(obj: any) {
    obj.selected = !obj.selected;
    this.divisionsValue = '';
    if(this.divisionArray[0].selected && this.divisionArray[1].selected && this.divisionArray[2].selected && this.divisionArray[3].selected) {
        this.divisionsValue = 'All divisions';
    }
    else {
      this.divisionArray.forEach((element: any) => {
        if(element.selected) {
          this.divisionsValue += element.name;
        }
      });
    }

    this.filterData();
  };

  //Select types
  selectTypes(obj: any) {
    obj.selected = !obj.selected;
    this.typeValue = '';
    if(this.typesArray[0].selected && this.typesArray[1].selected && this.typesArray[2].selected) {
        this.typeValue = 'All types';
    }
    else {
      this.typesArray.forEach((element: any) => {

        if(this.typeValue.length > 0 && !this.typeValue.includes(',')) {
          this.typeValue += ', ';
        }

        if(element.selected) {
          this.typeValue += element.name;
        }

      });
    }

    this.filterData();
  }

  //Select all drivers
  selectAllDrivers() {
    this.allDrivers = !this.allDrivers;
    this.driversArray.forEach((element: any) => {
      element.selected = this.allDrivers;
    });
  };

  //Select all categories
  selectAllCategories() {
    this.allCategories = !this.allCategories;
    this.categoryArray.forEach((element: any) => {
      element.selected = this.allCategories;
    });
  };

  //Filter data
  filterData() {
    let array: any = [...this.driversDataArray];
    let filteredArray: any[] = [];
    let conditions: any[] = [];
    
    for(let i = 0; i < this.divisionArray.length; i++) {
      if(this.divisionArray[i].selected) {
        conditions.push(this.divisionArray[i].id)
      }
    }

    for(let j = 0; j < this.typesArray.length; j++) {
      if(this.typesArray[j].selected) {
       conditions = conditions.concat(this.typesArray[j].filter);
      };
    }

    array.forEach((element: any) => {

      if(conditions.includes(element.division_id) && conditions.includes(element.status)) {
        filteredArray.push(element);
      };

    });
    this.driversArray = filteredArray;
  };

  //Create edit template
  createEditTemplate() {
    let loggedUser: any = JSON.parse(localStorage.getItem('currentUser'));
    console.log(this.data.template)
    let obj: any = {
      id: 0, name: this.templateName, header: this.data.header, template: this.data.template, footer: this.data.footer, 
      show_page_number: this.data.show_page_number, jdm: this.divisionArray[0].selected, 
      tfs: this.divisionArray[2].selected, fst: this.divisionArray[3].selected, logistic: this.divisionArray[1].selected,
      company: this.typesArray[0].selected, rent: this.typesArray[1].selected, owner: this.typesArray[2].selected, 
      drivers: '', category: '', created_by: loggedUser.first_name + ' ' + loggedUser.last_name, 
      created_date: this.transformService.convertDateToTimestamp(moment(new Date()).format('ddd, DD/MM YYYY'),  moment(new Date()).format('HH:mm:ss')), 
      edited_by: null, edited_date: null
    }

    for(let key in this.driversArray) {
      if(this.driversArray[key].selected) {
        obj.drivers += this.driversArray[key].driverID + ', ';
      }
    }

    for(let key in this.categoryArray) {
      if(this.categoryArray[key].selected) {
        obj.category += this.categoryArray[key].categoryName + '| ';
      }
    }

     if(!this.data.newTemplate) {
      obj.id = this.data.id;
      obj.created_by = this.data.created_by;
      obj.craeted_date = this.data.craeted_date;
      obj.edited_by = loggedUser.first_name + ' ' + loggedUser.last_name; 
      obj.edited_date = this.transformService.convertDateToTimestamp(moment(new Date()).format('ddd, DD/MM YYYY'),  moment(new Date()).format('HH:mm:ss'));
     }
     console.log(obj);
     
     this.safetyService.createEditTemplate(obj).subscribe((response: any) => {
      if(response) {
        this.dialogRef.close(true);
      }
     })
  };

  ngOnDestroy(): void {
    this.subscription1?.unsubscribe();
    this.subscription2?.unsubscribe();
  };

}

import { Component } from '@angular/core';

@Component({
  selector: 'app-truck-profile-files',
  templateUrl: './truck-profile-files.component.html',
  styleUrls: ['./truck-profile-files.component.scss']
})
export class TruckProfileFilesComponent {

}

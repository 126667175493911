import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DealerShopPageComponent } from './components/dealer-shop-page/dealer-shop-page.component';
import { FleetHealthOverviewComponent } from './components/fleet-health-overview/fleet-health-overview.component';
import { FleetHealthPageComponent } from './components/fleet-health-page.component';
import { SchedulePageComponent } from './components/schedule-page/schedule-page.component';
import { TruckProfilePageMaintenanceComponent } from './components/truck-profile-page-maintenance/truck-profile-page-maintenance.component';
import { AuthGuard } from '../core/services/auth/auth.guard';


const routes: Routes = [
	{
		path: 'fleet-health',
		canActivate: [AuthGuard],
		children: [
		  {
			path: 'overview',
			component: FleetHealthOverviewComponent,
			canActivate: [AuthGuard]
		  },
		  {
			path: 'dealer-shop',
			component: DealerShopPageComponent,
			canActivate: [AuthGuard]
		  },
		  {
			path: 'schedule',
			component: SchedulePageComponent,
			canActivate: [AuthGuard]
		  },
		  {
			path: 'truck-maintenance',
			component: TruckProfilePageMaintenanceComponent,
			canActivate: [AuthGuard]
		  }
		],
		component: FleetHealthPageComponent,
		data: { roles: 22 }
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class FleetHealthRoutingModule { }

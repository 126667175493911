import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SharedService } from '@app/modules/shared/services/shared.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-assign-trailer',
  templateUrl: './assign-trailer.component.html',
  styleUrls: ['./assign-trailer.component.scss']
})
export class AssignTrailerComponent  implements OnInit, OnDestroy {
  //Assign trailer data
  trailersArray: any[] = [];
  isOpenTrailerMenu: boolean = false;
  selectedValue: string = '';
  trailerId: number | undefined = undefined;

  //Filter
  trailerSearch: string = '';

  //Subscription
  subscription: Subscription = new Subscription();

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<AssignTrailerComponent>, 
  private sharedService: SharedService) { }

  ngOnInit(): void {
    this.selectedValue = this.data.selectedValue;
    this.subscription = this.sharedService.getAllTrailers().subscribe((response: any) => {
      console.log(response);
      this.trailersArray = response;
    });
  };

  selectOption(obj: any) {
    this.selectedValue = obj.unit_no;
    this.trailerId = obj.id;
    this.isOpenTrailerMenu = !this.isOpenTrailerMenu;
  }

  save() {
    this.dialogRef.close({trailerId: this.trailerId, trailerValue: this.selectedValue});
  };

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  };
  
}
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as _G from '../../../_config/globals';
import { forkJoin } from 'rxjs';

@Injectable()

export class ReportsService {
  headers = new HttpHeaders({
		'Content-Type': 'application/json',
		'Authorization': 'Basic SVZTb2x1dGlvbjoxUzBsdXQxMG4yQDIy'
	});
	requestOptions = { headers: this.headers };

  constructor(private http: HttpClient) { }

  getMileagePerTruckData(startDate: string, endDate: string, startDate2: string, endDate2: string, filterParams: string) {
	  let response1 = this.http.get(`${_G.extendApiUrl}reports/truckmileage?start=${startDate}&end=${endDate}${filterParams}`, this.requestOptions);
    let response2 = this.http.get(`${_G.extendApiUrl}reports/truckmileage?start=${startDate2}&end=${endDate2}${filterParams}`, this.requestOptions);
    return forkJoin([response1, response2]);
  };

  getTrucksActivationData(startDate: string, endDate: string, startDate2: string, endDate2: string, filterParams: string) {
    let response1 = this.http.get(`${_G.extendApiUrl}reports/truckactivations?start=${startDate}&end=${endDate}${filterParams}`, this.requestOptions);
    let response2 = this.http.get(`${_G.extendApiUrl}reports/truckactivations?start=${startDate2}&end=${endDate2}${filterParams}`, this.requestOptions);
    return forkJoin([response1, response2]);
  };

  getTrucksActivityData(startDate: string, endDate: string, startDate2: string, endDate2: string, filterParams: string) {
    let response1 = this.http.get(`${_G.extendApiUrl}reports/truckactivity?start=${startDate}&end=${endDate}${filterParams}`, this.requestOptions);
    let response2 = this.http.get(`${_G.extendApiUrl}reports/truckactivity?start=${startDate2}&end=${endDate2}${filterParams}`, this.requestOptions);
    return forkJoin([response1, response2]);
  };

  getTrucksProfitabilityData(startDate: string, endDate: string, startDate2: string, endDate2: string, filterParams: string) {
    let response1 = this.http.get(`${_G.extendApiUrl}reports/truckprofitabillity?start=${startDate}&end=${endDate}${filterParams}`, this.requestOptions);
    let response2 = this.http.get(`${_G.extendApiUrl}reports/truckprofitabillity?start=${startDate2}&end=${endDate2}${filterParams}`, this.requestOptions);
    return forkJoin([response1, response2]);
  };
   
}

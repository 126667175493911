import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import * as G from '../../../_config/globals';

@Injectable()

export class AuthenticationService {
	headers = new HttpHeaders({
		'Content-Type': 'application/json',
		'Authorization': 'Basic SVZTb2x1dGlvbjoxUzBsdXQxMG4yQDIy'
	  });
	requestOptions = { headers: this.headers };;

    constructor(private http: HttpClient) { }

	login(username: string, password: string) {
		
		let data: any = {
			username: username,
			password: password
		}
		
		let jsonData = JSON.stringify(data);

			return this.http.post(`${G.extendApiUrl}login/login`, jsonData, this.requestOptions)
				.pipe(
					map(
						(user: any) => {
							// login successful if there's a jwt token in the response
							if (user?.id) {
								// store user details and jwt token in local storage to keep user logged in between page refreshes
								localStorage.setItem('currentUser', JSON.stringify(user));
							}
							return user;
						}
					)
				);
		}

    logout() { 
        // remove user from local storage to log user out
		localStorage.clear(); 
	}

	resetLink(email: string) {
		return this.http.get(`${G.extendApiUrl}login/sendresetmail?email=${email}`, this.requestOptions);
	}

	resetPassword(obj: any) {
		console.log(obj);
		let json: any = JSON.parse(JSON.stringify(obj));
		return this.http.post(`${G.extendApiUrl}login/resetpassword`, json, this.requestOptions);
	}

	getToken() {
		return this.http.get(`${G.extendApiUrl}login/token`, this.requestOptions);
	};

}

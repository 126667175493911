import { Component, OnDestroy, OnInit } from '@angular/core';
import { RulesService } from '@app/modules/shared/services/rules.service';
import * as L from 'leaflet';
import { MapsService } from '../services/maps.service';
import { Subscription } from 'rxjs';

const iconRetinaUrl = 'assets/marker-icon-2x.png';
const iconUrl = 'assets/marker-icon.png';
const shadowUrl = 'assets/marker-shadow.png';

const iconDefault = L.icon({
	iconRetinaUrl,
	iconUrl,
	shadowUrl,
	iconSize: [25, 41],
	iconAnchor: [12, 41],
	popupAnchor: [1, -34],
	tooltipAnchor: [16, -28],
	shadowSize: [41, 41]
  });
L.Marker.prototype.options.icon = iconDefault;
  
@Component({
	selector: 'app-maps',
	templateUrl: './maps.component.html',
	styleUrls: ['./maps.component.scss'],
})
export class MapsComponent implements OnInit, OnDestroy {	
	permissions: any = this.rulesService.UserData[18].data[0].allowedAll;
	
	public trucks: any[];
	private map: any;

	//Subscription
	subscription: Subscription = new Subscription();

	constructor(private mapsService: MapsService, private rulesService: RulesService) { }

	ngOnInit(): void {
		this.getTrucks();
	};

	getTrucks() {
		this.subscription =	this.mapsService.getAvailableTrucks().subscribe((response: any) => {
			console.log(response)
			this.trucks = response;
			this.initMap();
			this.setMarkersOnMap();
		});
	};

	private initMap(): void {
		this.map = L.map('map', {
		  center: [37.920746, -91.020687],
		  zoom: 5,
		  scrollWheelZoom: false,
		});
	
		const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
		  maxZoom: 18,
		  minZoom: 3,
		  attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
		});
		tiles.addTo(this.map);
	};

	setMarkersOnMap() {
		for (let i = 0; i < this.trucks.length; i++) {
			let truck: any = this.trucks[i];
			let lat: number = +truck.latitude;
			let lng: number = +truck.longitude;
			const marker = L.marker([lat, lng]);
			marker.bindPopup(this.setPopups(this.trucks[i]));
			marker.addTo(this.map);
		}
	};

	setPopups(data: any): string {
		return `` +
		  `<div><b>Truck:</b> ${ data.unit_no }</div>` +
		  `<div><b>Location:</b> ${ data.available_city }, ${data.available_state}, ${data.available_zip_code}</div>` +
		  `<div><b>Available date:</b> ${ this.reformatDate(data.available_datetime) }</div>`
	};

	reformatDate(dateStr) {
		var justDate = dateStr.split("T")[0];
		var dArr = justDate.split("-");  // ex input "2010-01-18"
		//return dArr[1]+ "/" +dArr[2]+ "/" +dArr[0].substring(2); //ex out: "18/01/10"
		return dArr[1] + "/" + dArr[2] + "/" + dArr[0]; //ex out: "18/01/10"
	};

	ngOnDestroy(): void {
		this.subscription?.unsubscribe();
	};

}
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CalendarDialogComponent } from '@app/modules/shared/components/calendar-dialog/calendar-dialog.component';
import { RulesService } from '@app/modules/shared/services/rules.service';
import { SharedService } from '@app/modules/shared/services/shared.service';
import { TransformService } from '@app/modules/shared/services/transform.service';

import moment = require('moment');
import { NgxSpinnerService } from 'ngx-spinner';
import { catchError, Subscription, throwError } from 'rxjs';
import { TrucksService } from '../../services/trucks.service';

@Component({
  selector: 'app-truck-performance-page',
  templateUrl: './truck-performance-page.component.html',
  styleUrls: ['./truck-performance-page.component.scss']
})
export class TruckPerformancePageComponent implements OnInit, OnDestroy {
  permissions: any = this.rulesService.UserData[13].data;

  data: any;

  dateObj: any = {
    startDate: moment().startOf('month').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD')
  };

  //Interval
  interval: any;

  loaded: boolean = false;

  error: boolean = false;
  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";

  spinnerBgColor: string = this.transformService.spinnerBgColor;
  spinnerType: string = this.transformService.spinnerType;

  subscription: Subscription | any;

  constructor(private dialog: MatDialog,
              private transformService: TransformService,
              private rulesService: RulesService,
              private trucksService: TrucksService,
              private sharedService: SharedService,
              private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.spinner.show('truck-performance-cards');
    this.getTruckPerformanceCards();
    if(this.rulesService.liveUpdate) {
      this.interval = setInterval(() => {
        this.getTruckPerformanceCards();
      }, this.rulesService.miliseconds);
    }
  }

  getTruckPerformanceCards() {
    this.subscription = this.trucksService.getTruckPerformanceCards(this.dateObj.startDate, 
    this.dateObj.endDate, this.transformService.filterParams)
    .pipe(catchError((err: any) => {
      this.spinner.hide('truck-performance-cards');
      this.loaded = true;
      this.error = true;
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      console.log(response)
      this.data = response;
      this.spinner.hide('truck-performance-cards');
      this.loaded = true;
    })
  }

  getDate(result: any) {
    this.newData(result);
  };

  openFloatingDialog() {
    let dialogRef: any = this.dialog.open(CalendarDialogComponent, {
      width: '769px',
      height: '476px',
      autoFocus: false,
      data: {oneRange: false},
      panelClass: 'calendar-dialog-container'
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.newData(result);
      }
    });
  }

  newData(result: any) {
    this.dateObj.startDate = moment(result.startDate).format('YYYY-MM-DD');
    this.dateObj.endDate = moment(result.endDate).format('YYYY-MM-DD');
    this.trucksService.truckPerformanceDateChanged.next(this.dateObj);
    this.data = undefined;
    this.error = false;
    this.loaded = false;
    this.spinner.show('truck-performance-cards');
    this.getTruckPerformanceCards();
  }

  refresh() {
    this.newData(this.dateObj)
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
    this.subscription?.unsubscribe();
  }

}

import { Component, EventEmitter, Input, Output } from '@angular/core';


@Component({
  selector: 'app-custom-select',
  templateUrl: './custom-select.component.html',
  styleUrls: ['./custom-select.component.scss']
})
export class CustomSelectComponent {
  @Input() valuesArray: any[];
  @Input() value: string = '';
  @Input() valid: boolean = false;
  @Input() reset: boolean = false;
  @Input() selectWidth: string = 'w247';
  @Input() selectHeight: string = 'h70';
  @Output() triggerEvent = new EventEmitter<any>();

  isOpen = false;

  constructor() { } 

  openCloseMenu() {
    this.isOpen = !this.isOpen;
  };

  selectValue(value: string) {
    this.value = value;
    this.isOpen = false;
    if(value !== 'Select an option') {
      this.triggerEvent.emit(value);
    }
  };

}

import { AfterContentChecked, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { SharedService } from '@app/modules/shared/services/shared.service';
import { catchError, Subscription, throwError } from 'rxjs';
import { FullAppService } from '../../services/full-app.service';
import moment = require('moment');
import { DateRange } from '@angular/material/datepicker';
import { TransformService } from '@app/modules/shared/services/transform.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { IExtend } from '../../models/extend-model';
import { MatDialog } from '@angular/material/dialog';
import { SuccessDialogComponent } from '@app/modules/shared/components/success-dialog/success-dialog.component';
import { WarningMsgDialogComponent } from '@app/modules/shared/components/warning-msg-dialog/warning-msg-dialog.component';

@Component({
  selector: 'full-application-table',
  templateUrl: './full-application-table.component.html',
  styleUrls: ['./full-application-table.component.scss']
})
export class FullApplicationTableComponent implements OnInit, AfterContentChecked, OnDestroy {
  //Date
  dateObj: any = {
    startDate: moment().startOf('year').format('YYYY-MM-DDT00:00:00'),
    endDate: moment().format('YYYY-MM-DDT23:59:59')
  };

  //Calendar
  @Input() selectedRangeValue: DateRange<Date> | any;
  @Output() selectedRangeValueChange = new EventEmitter<DateRange<Date>>();
  isOpenCalendar: boolean = false;

  //Filters array
  filtersArray: any[] = [
    {name: 'All apps', key: 'allApplications'},
    {name: 'In progress', key: 'inProgress'},
    {name: 'Declined', key: 'declined'},
    {name: 'Lost', key: 'lost'},
    {name: 'Scheduled', key: 'scheduled'},
    {name: 'Hired', key: 'hired'}
  ];
  activeStatus: string = 'All apps';

  filtersKeys: any = {
    'In progress': 'inProgress', 
    'Declined': 'declined', 
    'Lost': 'lost', 
    'Scheduled': 'scheduled', 
    'Hired': 'hired'
  };

  //Status menu
  statusArray: any[] = [
    'In progress', 
    'Declined', 
    'Lost', 
    'Scheduled', 
    'Hired'
  ];

  //Recruiter menu
  recruiterArray: any[] = [];
  isOpenRecruiterMenu: boolean = false;
  recruiterSearch: string = '';
  selectedRecruitersArray: string[] = [];

  //Search
  driverSearch: string = '';

  //Data source
  dataSource: any[] = [];

    //Metadata
  filterMetaData: any = {
    countObj: {
      allApplications: 0,
      inProgress: 0,
      declined: 0,
      lost: 0,
      scheduled: 0,
      hired: 0
    }
  };

  loaded: boolean = false;
  error: boolean = false;
  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";

  spinnerBgColor: string = this.transformService.spinnerBgColor;
  spinnerType: string = this.transformService.spinnerType;

  //Subscription
  subscription1: Subscription = new Subscription();
  subscription2: Subscription = new Subscription();

  constructor(private dialog: MatDialog,
              private transformService: TransformService,
              private sharedService: SharedService, 
              private fullAppService: FullAppService,
              private spinner: NgxSpinnerService,
              private cdref: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.getApplications();
    this.getAllRecruiters();
  };

  dateRangeClicked(m: any) {
    if (!this.selectedRangeValue?.start || this.selectedRangeValue?.end) {
      this.selectedRangeValue = new DateRange<Date>(m, null);
    } 
    else {
      const start = this.selectedRangeValue.start;
      const end = m;
      if (end < start) {
        this.selectedRangeValue = new DateRange<Date>(end, start);
      } 
      else {
        this.selectedRangeValue = new DateRange<Date>(start, end);
      }
    }
    this.selectedRangeValueChange.emit(this.selectedRangeValue);
    if(this.selectedRangeValue.start && this.selectedRangeValue.end) {
      this.dateObj.startDate = moment(this.selectedRangeValue.start._d).format('YYYY-MM-DD');
      this.dateObj.endDate = moment(this.selectedRangeValue.end._d).format('YYYY-MM-DD');
      this.isOpenCalendar = false;
      this.getApplications();
    }
  }

  getApplications() {
    this.error = false;
    this.loaded = false;
    this.dataSource = [];
    this.spinner.show('full-app-table');
    this.subscription1 = this.fullAppService.getFilledApplications(this.dateObj.startDate, this.dateObj.endDate)
    .pipe(catchError((err: any) => {
      this.spinner.hide('full-app-table');
      this.loaded = true;
      this.error = true;
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      console.log(response);
      this.dataSource = response;
      this.spinner.hide('full-app-table');
      this.loaded = true;
    });
  }

  getAllRecruiters() {
    this.subscription2 = this.sharedService.getAllEmployees().subscribe((response: any) => {
      for(let i = 0; i < response.length; i++) {
        if(response[i].department === 'Recruiting') {
          this.recruiterArray.push(response[i].nickname)
        }
      }
    });
  };

  //Select all trucks
  selectUnselectAllRecruiters() {
    if(this.selectedRecruitersArray.length > 0) {
      this.selectedRecruitersArray = [];
    }
    else {
      for(let i = 0; i < this.recruiterArray.length; i++) {
        this.selectedRecruitersArray.push(this.recruiterArray[i]);
      };
    }
    this.selectedRecruitersArray = [...this.selectedRecruitersArray];
  };

  //Check uncheck value
  checkUncheckValue(recruiter: string) {
    let index = this.selectedRecruitersArray.indexOf(recruiter);
    if (index === -1) {
      this.selectedRecruitersArray.push(recruiter);
    } 
    else {
      this.selectedRecruitersArray.splice(index, 1);
    }
    this.selectedRecruitersArray = [...this.selectedRecruitersArray];
  };

  sortData(sort: Sort) {
    const data = JSON.parse(JSON.stringify(this.dataSource));
    if (!sort.active || sort.direction === '') {
      this.dataSource = data;
      return;
    }
    this.dataSource = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'Name':
          return compare(a.Name, b.Name, isAsc);
        case 'Created':
          return compare(new Date(a.Created), new Date(b.Created), isAsc);
        case 'page':
          return compare(a.page, b.page, isAsc);
        case 'position':
          return compare(a.position, b.position, isAsc);
        case 'experience':
          return compare(a.experience, b.experience, isAsc);
        case 'status':
          return compare(a.status, b.status, isAsc);
        case 'recruiter':
          return compare(a.recruiter, b.recruiter, isAsc);
        default:
          return 0;
      }
    });
  }

   //Select method
   changeRecruiterOrStatus(isStatus: boolean, value: string, element: any) {
    let obj: IExtend = {
      extend_id: element.Id,
      recruiter: element.recruiter,
      truck_no: element.truck_no,
      status: element.status,
      orientation_date: element.orientation_date
    };
    if(isStatus) {
      obj.status = value;
      this.saveChanges(obj, element, 'status', value);
    }
    else {
      obj.recruiter = value;
      this.saveChanges(obj, element, 'recruiter', value);
    }

  };

  saveChanges(obj: IExtend, element: any, key: string, value: string) {
    this.fullAppService.changeStatusOrRecruiter(obj)
    .pipe(catchError((err: any) => {
      this.showErrorMessage();
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      if(response) {
        if(key === 'status') {
          this.filterMetaData.countObj[this.filtersKeys[value]]++;
          this.filterMetaData.countObj[this.filtersKeys[element[key]]]--;
        }
        element[key] = value;
        this.showSuccessMessage();
      } 
      else {
        this.showErrorMessage();
      }
    });
  };

  showSuccessMessage() {
    this.dialog.open(SuccessDialogComponent, {
      autoFocus: false,
      panelClass: 'success-dialog-container'
    });  
  };

  showErrorMessage() {
    this.dialog.open(WarningMsgDialogComponent, {
      autoFocus: false,
      panelClass: 'warning-msg-dialog-container'
    });
  };

  //Percent
  percentOfFilledApp(page: number) {
    let percentArray: number[] = [0, 4, 8, 15, 20, 22, 26, 28, 29, 35, 38, 40, 45, 50, 52, 55, 57, 60, 65, 70, 75, 80, 84, 86, 88, 90, 92, 94, 100]
    return `${percentArray[page]}%` ;
  };

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  };

  ngOnDestroy(): void {
    this.subscription1?.unsubscribe();
    this.subscription2?.unsubscribe();
  };

}

function compare(a: any, b: any, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
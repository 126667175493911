import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DispatchRewardSystemService } from '@app/modules/dispatch-reward-system/services/dispatch-reward-system.service';
import { DeleteConfirmationDialogComponent } from '@app/modules/shared/components/delete-confirmation-dialog/delete-confirmation-dialog.component';
import { MsgForbbidenAccessComponent } from '@app/modules/shared/components/msg-forbbiden-access/msg-forbbiden-access.component';
import { WarningMsgDialogComponent } from '@app/modules/shared/components/warning-msg-dialog/warning-msg-dialog.component';
import { RulesService } from '@app/modules/shared/services/rules.service';
import moment = require('moment');
import { catchError, throwError } from 'rxjs';

@Component({
  selector: 'app-set-target-dialog',
  templateUrl: './set-target-dialog.component.html',
  styleUrls: ['./set-target-dialog.component.scss']
})
export class SetTargetDialogComponent implements OnInit {
  permissions: any = this.rulesService.UserData[32].data;

  //Conditions array
  conditionsArray: any = this._formBuilder.array([]);

  //Refresh data
  refreshData: boolean = false;

  //Edit mode
  isEditMode: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private dialog: MatDialog, 
              public dialogRef: MatDialogRef<SetTargetDialogComponent>,
              private rulesService: RulesService,
              private _formBuilder: FormBuilder, 
              private dispatchRewardSystemService: DispatchRewardSystemService) { }

  ngOnInit(): void {
    if(this.data.selectedObj.targetDataSolo.length > 0) {
      this.addConditionBasedOnNumber(this.data.selectedObj.targetDataSolo.length, 0);
      this.conditionsArray.controls[0].patchValue(this.data.selectedObj.targetDataSolo);
      this.isEditMode = true;
    };
    if(this.data.selectedObj.targetDataTeam.length > 0) {
      this.addConditionBasedOnNumber(this.data.selectedObj.targetDataTeam.length, 1);
      this.conditionsArray.controls[1].patchValue(this.data.selectedObj.targetDataTeam);
      this.isEditMode = true;
    };
    if(this.data.selectedObj.targetDataSolo.length === 0 && this.data.selectedObj.targetDataTeam.length === 0) {
      this.addConditionBasedOnNumber(1, 0);
      this.addConditionBasedOnNumber(1, 1);
    };
  };

  //Add condition
  addCondition(index: number) {
    this.conditionsArray.controls[index].push(
      this._formBuilder.group({
        id: [0],
        target_from: [this.data.selectedObj.date],
        target_to: [this.getLastDayOfWeek(this.data.selectedObj.date)],
        condition: ['', [Validators.required, Validators.minLength(4)]],
        based_on: ['', [Validators.required, Validators.minLength(4)]],
        metrics: [''],
        period: [''],
        metrix_baased_on_from: [null],
        metrix_based_on_to: [null],
        target: [0],
        calculation_points_after: [0],
        reword: [0],
        type: index
      })
    );
  };

  getTargetByPercentAndDate(firstIndex: number, secondIndex: number, form: any) {
    let fromDate: string | null = this.getFormData('metrix_baased_on_from', firstIndex, secondIndex);
    let toDate: string | null = this.getFormData('metrix_based_on_to', firstIndex, secondIndex);
    let top: string = this.getFormData('metrics', firstIndex, secondIndex);
    let condition: string = this.getFormData('condition', firstIndex, secondIndex);
    let based_on: string = this.getFormData('based_on', firstIndex, secondIndex);
    let driverType: number = this.getFormData('type', firstIndex, secondIndex);
    if(fromDate && toDate && top && condition && based_on === 'Drivers performance' && driverType !== null) {
      this.dispatchRewardSystemService.getTargetByDateAndPercent(fromDate, toDate, top.split(' ')[1].split('%')[0], driverType).subscribe((response: any) => {
        console.log(response);
        if(condition === 'Gross') {
          form.patchValue({'target': Math.round(response?.Item1)});
        }
        else {
          form.patchValue({'target': response?.Item2.toFixed(2)})
        }
      });
    }
  };

  //Save data
  saveData() {
    if((this.permissions[0].sectionArray[0].allowed && !this.isEditMode) || 
    (this.permissions[0].sectionArray[1].allowed && this.isEditMode)) {
      this.conditionsArray.updateValueAndValidity();
      console.log(this.conditionsArray.value);
      this.dispatchRewardSystemService.setTarget(this.conditionsArray.value)
      .pipe(catchError((err: any) => {
        this.showErrorMessage();
        return throwError(() => err);
      }))
      .subscribe((response: any) => {
        if(response?.length > 0) {
          this.dialogRef.close(true);
        };
        console.log(response);
      });
    }
    else {
      this.showForbiddenMessage();
    }
  };

  //Delete condition
  deleteCondition(id: number, i: number, j: number) {
    if(this.permissions[0].sectionArray[2].allowed) {
      let dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
        autoFocus: false,
        panelClass: 'delete-dialog-container',
      });
      dialogRef.afterClosed().subscribe((result: any) => {
        if(result) {
          if(id) {
            this.dispatchRewardSystemService.deleteTarget(id).subscribe((response: any) => {
              if(response) {
                this.conditionsArray.controls[i].removeAt(j);
                this.refreshData = true;
              }
              console.log(response);
            });
          }
          else {
            this.conditionsArray.controls[i].removeAt(j);
          }
        }
      })
    }
    else {
      this.showForbiddenMessage();
    }
  }

  //Delete target
  deleteTarget(conditionArray: any, index: number) {
    if(this.permissions[0].sectionArray[2].allowed) {
      let dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
        autoFocus: false,
        panelClass: 'delete-dialog-container',
      });
      dialogRef.afterClosed().subscribe((result: any) => {
        if(result) {
          const resetObj: any = {
            id: [0],
            target_from: this.data.selectedObj.date,
            target_to: this.getLastDayOfWeek(this.data.selectedObj.date),
            condition: '',
            based_on: '',
            metrics: '',
            period: '',
            metrix_baased_on_from: null,
            metrix_based_on_to: null,
            target: 0,
            calculation_points_after: 0,
            reword: 0,
            type: null
          };

          for(let i = 0; i < conditionArray.value.length; i++) {
            if(conditionArray.value[i].id) {
              this.dispatchRewardSystemService.deleteTarget(conditionArray.value[i].id).subscribe((response: any) => {
                if(response) {
                  this.conditionsArray.controls[index].controls[i].patchValue(resetObj);
                  this.refreshData = true;
                }
                console.log(response);
              });
            }
            else {
              this.conditionsArray.controls[index].controls[i].patchValue(resetObj);
            }
          }
        }
      })
    }
    else {
      this.showForbiddenMessage();
    }
  };

  //Show error message
  showErrorMessage() {
    this.dialog.open(WarningMsgDialogComponent, {
      autoFocus: false,
      panelClass: 'warning-msg-dialog-container'
    });
  };

  //Show forbidden message
  showForbiddenMessage() {
    this.dialog.open(MsgForbbidenAccessComponent, {
      autoFocus: false,
      panelClass: 'forbidden-msg-dialog-container'
    })
  };

  //Get last day of week
  getLastDayOfWeek(date: string): string {
    return moment(date).endOf('isoWeek').format('YYYY-MM-DD');
  };

  //Get form data
  getFormData(formKey: string, firstIndex: number, secondIndex: number) {
    return this.conditionsArray.controls[firstIndex].controls[secondIndex].controls[formKey].value;
  };

  addConditionBasedOnNumber(numOfConditions: number, type: number) {
    let conditionArray: any[] = [];
    for(let i = 0; i < numOfConditions; i++) {
      conditionArray.push(this._formBuilder.group({
        id: [0],
        target_from: [this.data.selectedObj.date],
        target_to: [this.getLastDayOfWeek(this.data.selectedObj.date)],
        condition: ['', [Validators.required, Validators.minLength(4)]],
        based_on: ['', [Validators.required, Validators.minLength(4)]],
        metrics: [''],
        period: [''],
        metrix_baased_on_from: [null],
        metrix_based_on_to: [null],
        target: [0],
        calculation_points_after: [0],
        reword: [0],
        type: [type]
      }));
    }
    this.conditionsArray.controls.push(this._formBuilder.array(conditionArray, [Validators.required, this.validateConditionsArray]));
  };

  //Validator
  validateConditionsArray(control: FormArray): { [key: string]: any } | null {
    let valuesArray: any[] = [];
    const conditions = control.value;
    let duplicates: boolean = false;
    for(let i = 0; i < conditions.length; i++) {
      if(valuesArray.includes(conditions[i].condition)) {
        duplicates = true;
        break;
      }
      valuesArray.push(conditions[i].condition);
    };
    if(duplicates) {
      return { invalidCondition: true };
    }
    return null; 
  };

}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as _G from '../../../_config/globals';
import { BehaviorSubject, Subject, forkJoin } from 'rxjs';

@Injectable()
export class DriversProfileService {
  headers = new HttpHeaders({
		'Content-Type': 'application/json',
		'Authorization': 'Basic SVZTb2x1dGlvbjoxUzBsdXQxMG4yQDIy'
	});
	requestOptions = { headers: this.headers };

  linechartSelectionChange: any = new BehaviorSubject<boolean>(true);
  newNoteSubject: any = new Subject<any>();

  constructor( private http: HttpClient ) {}

  getDriverLoads(id: number){
	  return this.http.get(`${_G.extendApiUrl}Drivers/driverprofileloads?id=${id}`, this.requestOptions);
  };

  getNoteExchange(driverId: number) {
	  return this.http.get(`${_G.extendApiUrl}drivers/getdriversnotes?id=${driverId}`, this.requestOptions);
  };

  createThreadNoteExhchange(obj: any) {
    let json: string = JSON.stringify(obj);
	  return this.http.post(`${_G.extendApiUrl}drivers/getdriversnotes`, json, this.requestOptions);
  };

  createEditNoteExchange(obj: any) {
    let json: string = JSON.stringify(obj);
	  return this.http.post(`${_G.extendApiUrl}drivers/editDriversNotes`, json, this.requestOptions);
  }; 

  closeThread(obj: any) {
	  return this.http.post(`${_G.extendApiUrl}drivers/closedriversnotes`, obj, this.requestOptions);
  };

  deleteNoteExchange(noteId: number) {
	  return this.http.get(`${_G.extendApiUrl}drivers/deletedriversnotesmessages?id=${noteId}`, this.requestOptions);
  };

  deleteAllNoteExchange(noteId: number) {
	  return this.http.get(`${_G.extendApiUrl}drivers/deletealldrivesrnotes?note_id=${noteId}`, this.requestOptions);
  };

  deleteThreadNoteExchange(noteId: number) {
	  return this.http.get(`${_G.extendApiUrl}drivers/deletedriversnotes?id=${noteId}`, this.requestOptions);
  };

  getDriverProfileData(id: number) {
	  return this.http.get(`${_G.extendApiUrl}drivers/driverprofile?id=${id}`, this.requestOptions);
  };

  //Driver activity statements
  getActivityStatementsTableData(driverId: number) {
    let response1 = this.http.get(`${_G.extendApiUrl}drivers/driverweeklyoverview?id=${driverId}`, this.requestOptions);
    let response2 = this.http.get(`${_G.extendApiUrl}drivers/driverestatus?id=${driverId}`, this.requestOptions);
    return forkJoin([response1, response2]);
  };

  //Upload files
  uploadFiles(driverId: number, formData: any) {
	  return this.http.post(`${_G.apiUrl}upload?driverId=${driverId}`, formData);
  };

  //Change driver info
  changeDriverInfo(twoCalls: boolean, notificationObj: any, obj: any) {
    let json: string = JSON.stringify(obj);
    let response1 = this.http.get(`${_G.extendApiUrl}drivers/asgndriertodisp?disp_id=${notificationObj.dispId}&dispatcer=${notificationObj.dispatcherName}&truck_id=${notificationObj.truckId}&old_disp_id=${notificationObj.oldDispId}`, this.requestOptions);
    let response2 = this.http.post(`${_G.extendApiUrl}drivers/driverdata`, json, this.requestOptions);
    if(twoCalls) {
      return forkJoin([response1, response2]);
    }
    else {
      return response2;
    }
  };

  //Change additional info
  changeTwicCardStatus(driverId: number, date: string) {
	  return this.http.get(`${_G.extendApiUrl}drivers/twicdate?driver_id=${driverId}&date=${date}`, this.requestOptions);
  };

  changeChildSupportStatus(driverId: number, childSupport: boolean) {
	  return this.http.get(`${_G.extendApiUrl}drivers/childsupport?driver_id=${driverId}&has_support=${childSupport}`, this.requestOptions);
  };

  changeOtherAdditionalInfoDataStatus(driverId: number, value: boolean, type: string) {
	  return this.http.get(`${_G.extendApiUrl}drivers/setendorsment?driver_id=${driverId}&setvalue=${value}&type=${type}`, this.requestOptions);
  };

  //Statistisc
  getStatisticsCardsData(startDate: string, endDate: string, driverId: number) {
    return this.http.get(`${_G.extendApiUrl}drivers/drivercards?start=${startDate}&end=${endDate}&driver_id=${driverId}`, this.requestOptions);
  };

  //Get all reasons
  getAllDriverReasons() {
    return this.http.get(`${_G.extendApiUrl}drivers/driversreasons`, this.requestOptions);
  };

  //Create update reason
  createUpdateDriverReason(obj: any) {
    let json: string = JSON.stringify(obj);
    return this.http.post(`${_G.extendApiUrl}drivers/driversreasons`, json, this.requestOptions);
  };

  //Delete reason
  deleteDriverReason(reasonId: number) {
    console.log(`${_G.extendApiUrl}drivers/driversreasons/${reasonId}`);
    return this.http.delete(`${_G.extendApiUrl}drivers/driversreasons/${reasonId}`, this.requestOptions);
  };

  changeStatus(obj: any) {
    let json: string = JSON.stringify(obj);
    return this.http.post(`${_G.extendApiUrl}drivers/changestatus`, json, this.requestOptions);
  };

  getAllDriverFiles(driverId: number) {
    return this.http.get(`${_G.apiUrl}Download/driverfiles?id=${driverId}`);
  };

  deleteDriverFileFromPt(driverId: number, filename: string) {
    return this.http.delete(`${_G.extendApiUrl}drivers/drierfile?driver_id=${driverId}&filename=${filename}`, this.requestOptions);
  };

  downloadDriverFile(driverId: number, filename: string) {
    return this.http.get(`${_G.apiUrl}Download/driverfile?id=${driverId}&file_name=${filename}`);
  };

  deleteDriverFile(driverId: number, filename: string) {
    return this.http.delete(`${_G.apiUrl}Download/driverfile?id=${driverId}&file_name=${filename}`);
  };

  //Warning letters
  getWarningLettersByDriver(driverId: number) {
    return this.http.get(`${_G.extendApiUrl}drivers/warnings?driver_id=${driverId}`, this.requestOptions);
  };

  //Truck trailer info
  getTruckInfo(unitNo: string) {
    return this.http.get(`${_G.extendApiUrl}drivers/drivertruck?unit_no=${unitNo}`, this.requestOptions);
  };

  getTrailerInfo(trailerNo: string) {
    return this.http.get(`${_G.extendApiUrl}drivers/drivertrailer?unit_no=${trailerNo}`, this.requestOptions);
  };

  //Dot inspecion
  getDotInspectionByDriver(driverId: number) {
    return this.http.get(`${_G.extendApiUrl}drivers/DotInspectionsbydriver?driver_id=${driverId}`, this.requestOptions);
  };

  sendNotification(obj: any) {
    let json: string = JSON.stringify(obj);
    return this.http.post(`${_G.extendApiUrl}dispatcher/assigntask`, json, this.requestOptions);
  };

  sendDriverAlert(obj: any) {
    let json: string = JSON.stringify(obj);
    return this.http.post(`${_G.extendApiUrl}drivers/driersatusalert`, json, this.requestOptions);
  };

}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as _G from '../../../_config/globals';

@Injectable()
export class PaymentsService {
  headers = new HttpHeaders({
		'Content-Type': 'application/json',
		'Authorization': 'Basic SVZTb2x1dGlvbjoxUzBsdXQxMG4yQDIy'
	});
	requestOptions = { headers: this.headers };

  constructor(private http: HttpClient) { }

  createUpdatePayment(obj: any) {
    let json: string = JSON.stringify(obj);
    return this.http.post(`${_G.extendApiUrl}payments`, json, this.requestOptions);
  };

  deletePayment(paymentId: number) {
    return this.http.delete(`${_G.extendApiUrl}payments/payment/${paymentId}`, this.requestOptions);
  };

  getPaymentsByTypeAndDate(type: number, startDate: string, endDate: string) {
    return this.http.get(`${_G.extendApiUrl}payments?type=${type}&start=${startDate}&end=${endDate}`, this.requestOptions);
  };

  getPaymentFile(paymentId: number, fileName: string) {
    return this.http.get(`${_G.extendApiUrl}payments/paymentfile?id=${paymentId}&fileName=${fileName}`, this.requestOptions);
  };

  deleteFileFromPayment(paymentId: number, fileName: string) {
    return this.http.delete(`${_G.extendApiUrl}payments/paymentfile?id=${paymentId}&fileName=${fileName}`, this.requestOptions);
  };

  createUpdatePaymentReason(obj: any) {
	  let json: string = JSON.stringify(obj);
	  return this.http.post(`${_G.extendApiUrl}payments/categorytasks`, json, this.requestOptions);
  };

  getPaymentReasonsData() {
    return this.http.get(`${_G.extendApiUrl}payments/categorytasks`, this.requestOptions);
  };

  deletePaymentReason(ids: number, id: number, user: string, date: string) {
    return this.http.get(`${_G.extendApiUrl}payments/deletecategorytasks?ids=${ids}&id=${id}&user=${user}&date=${date}`, this.requestOptions);
  };

}

import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-warning-reserve-spot',
  templateUrl: './warning-reserve-spot.component.html',
  styleUrls: ['./warning-reserve-spot.component.scss']
})
export class WarningReserveSpotComponent {

  constructor(public dialogRef: MatDialogRef<any>) { }

  confirmAction() {
    this.dialogRef.close(true);
  };
  
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { ApexAxisChartSeries, ApexChart, ApexDataLabels, ApexGrid, ApexStroke, ApexXAxis, ApexYAxis, ChartComponent } from 'ng-apexcharts';

export type ChartOptions = {
  series: ApexAxisChartSeries | any;
  chart: ApexChart | any;
  xaxis: ApexXAxis | any;
  yaxis: ApexYAxis | any;
  dataLabels: ApexDataLabels | any;
  grid: ApexGrid | any;
  stroke: ApexStroke | any;
  colors: any;
};

const ELEMENT_DATA: any[] = [
  {pm_service: 'PM79961245', status: 'Done', driver_name: 'Frederick Robinson', repair_type: 'Preventive Maintenance',
  repair: 'Oil change', date: '03.05.2023', cost: 352, mileage: 123, shop: 'Shop', shop_name: 'Mc Jones Truck Shop',
  state: 'OH'},
  {pm_service: 'PM79961245', status: 'Done', driver_name: 'Frederick Robinson', repair_type: 'Preventive Maintenance',
  repair: 'Oil change', date: '03.05.2023', cost: 352, mileage: 123, shop: 'Shop', shop_name: 'Mc Jones Truck Shop',
  state: 'OH'},
  {pm_service: 'PM79961245', status: 'Done', driver_name: 'Frederick Robinson', repair_type: 'Preventive Maintenance',
  repair: 'Oil change', date: '03.05.2023', cost: 352, mileage: 123, shop: 'Shop', shop_name: 'Mc Jones Truck Shop',
  state: 'OH'},
  {pm_service: 'PM79961245', status: 'Done', driver_name: 'Frederick Robinson', repair_type: 'Preventive Maintenance',
  repair: 'Oil change', date: '03.05.2023', cost: 352, mileage: 123, shop: 'Shop', shop_name: 'Mc Jones Truck Shop',
  state: 'OH'},
  {pm_service: 'PM79961245', status: 'Done', driver_name: 'Frederick Robinson', repair_type: 'Preventive Maintenance',
  repair: 'Oil change', date: '03.05.2023', cost: 352, mileage: 123, shop: 'Shop', shop_name: 'Mc Jones Truck Shop',
  state: 'OH'},
  {pm_service: 'PM79961245', status: 'Done', driver_name: 'Frederick Robinson', repair_type: 'Preventive Maintenance',
  repair: 'Oil change', date: '03.05.2023', cost: 352, mileage: 123, shop: 'Shop', shop_name: 'Mc Jones Truck Shop',
  state: 'OH'},
];

@Component({
  selector: 'app-truck-profile-repairs',
  templateUrl: './truck-profile-repairs.component.html',
  styleUrls: ['./truck-profile-repairs.component.scss']
})
export class TruckProfileRepairsComponent implements OnInit {
  //Graph
  @ViewChild("chart") chart: ChartComponent | any;
  public chartOptions: Partial<ChartOptions> | any;

  //Table data
  displayedColumns: string[] = ['position', 'pm_service', 'status', 'driver_name', 'repair_type', 'repair', 'date',
  'cost', 'mileage', 'shop', 'shop_name', 'state'];
  dataSource = ELEMENT_DATA;

  //Select repairs
  isOpenRepairMenu: boolean = false;
  repairValue: string = 'All repairs';
  searchText: string = '';

  selectRepairsArray: any[] = [
    {name: 'All repairs'},
    {name: 'Repair 1'},
    {name: 'Repair 2'},
    {name: 'Repair 3'},
    {name: 'Repair 4'},
  ];

  //Select drivers
  isOpenDriverMenu: boolean = false;
  driverValue: string = 'All drivers';

  selectDriversArray: any[] = [
    {name: 'All drivers'},
    {name: 'All drivers'},
    {name: 'All drivers'},
    {name: 'All drivers'},
    {name: 'All drivers'},
  ];

  selectPeriod(obj: any, repair: boolean) {
    if(repair) {
      this.repairValue = obj.name;
      this.isOpenRepairMenu = false;
    }
    else {
      this.driverValue = obj.name;
      this.isOpenDriverMenu = false;
    }
  };

  constructor() {}

  ngOnInit() {
    this.initGraph('');
  }

  
  initGraph(data: any) {
    let seriesArray: any[] = [
      {name: 'Maintenance cost', data: [10, 20, 100, 30, 46, 70]},
    ];
    this.chartOptions = {
      series: seriesArray,
      chart: {
        toolbar: {
          show: true,
          tools: {
            download: false
          }
        },
        height: 220,
        type: "line",
        zoom: {
          enabled: false
        },
        fontFamily: 'Poppins'
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: "straight",
        width: 1,
      },
      grid: {
        row: {
          colors: ["transparent", "transparent"], 
          opacity: 0.5
        }
      },
      xaxis: {
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun']
      },
      yaxis: {
        tooltip: {
          enabled: true
        },
        labels: {
          formatter: (num: number) => {
            let roundedNumber: any = Math.round(num);
            return roundedNumber.toLocaleString("en-US").toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          },
        },
      },
      markers: {
        size: 5
      },
      colors: ['#005beb', '#FA9120', '#FA0000', '#008000', '#FF00FF', '#800000', '#FE7777', '#0303BB', '#008FA9', '#7550CB', '#6A6D6E'],
    };
  }

}


import { Component } from '@angular/core';

@Component({
  selector: 'app-full-app-documentation-dialog',
  templateUrl: './full-app-documentation-dialog.component.html',
  styleUrls: ['./full-app-documentation-dialog.component.scss']
})
export class FullAppDocumentationDialogComponent {
  documentsArray: any[] = [
    {name: 'Full application form', uploadedDocument: true, expanded: false, files: [{documentName: 'Full Application Form.pdf'}]},
    {name: 'PSP', uploadedDocument: false, expanded: false, files: [{documentName: 'Full Application Form.pdf'}]},
    {name: 'Drug Test Result', uploadedDocument: false, expanded: false, files: [{documentName: 'Full Application Form.pdf'}]},
    {name: 'Long Medical Form', uploadedDocument: false, expanded: false, files: [{documentName: 'Full Application Form.pdf'}]},
    {name: 'EIN', uploadedDocument: false, expanded: false, files: [{documentName: 'Full Application Form.pdf'}]},
    {name: 'Banking Info', uploadedDocument: false, expanded: false, files: [{documentName: 'Full Application Form.pdf'}]},
    {name: 'CDL Photo', uploadedDocument: true, expanded: false, files: [{documentName: 'Full Application Form.pdf'}]}
  ];

  //Files
  files: any[] = [];


  //Add files
  onFileSelected(event: any) {  
    console.log(event);
    let length: any = Object.keys(event.target.files).length;
    for(let i = 0; i < length; i++) {
      this.addMultipleFiles(event, i);
    }
  }

  addMultipleFiles(event: any, index: number) {
    let fileName = event.target.files[index].name;
    let reader = new FileReader();
    reader.onload = (e: any) => {
      var uint8 = new Uint8Array(e.target.result);
      var result = [];
      for (var i = 0; i < uint8.length; i++) {
        result.push(uint8[i]);
      }
      let obj: any = {
        filename: fileName,
        data: result
      };
      this.files.push(obj);
    }

    reader.readAsArrayBuffer(event.target.files[index])
  };

}

import { ConnectionPositionPair } from '@angular/cdk/overlay';
import { Component, Input } from '@angular/core';
import { AddEditBrokersToAvoidDialogComponent } from '../add-edit-brokers-to-avoid-dialog/add-edit-brokers-to-avoid-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { RemoveFromListConfirmationDialogComponent } from '../remove-from-list-confirmation-dialog/remove-from-list-confirmation-dialog.component';
import { IBrokersToAvoid } from '@app/modules/brokers-to-avoid/models/brokers-to-avoid.model';
import { BrokersToAvoidService } from '@app/modules/brokers-to-avoid/services/brokers-to-avoid.service';
import { Sort } from '@angular/material/sort';
import { RulesService } from '@app/modules/shared/services/rules.service';
import { MsgForbbidenAccessComponent } from '@app/modules/shared/components/msg-forbbiden-access/msg-forbbiden-access.component';
import moment = require('moment');

@Component({
  selector: 'brokers-to-avoid-table',
  templateUrl: './brokers-to-avoid-table.component.html',
  styleUrls: ['./brokers-to-avoid-table.component.scss']
})
export class BrokersToAvoidTableComponent {
  userData: any = JSON.parse(localStorage.getItem('currentUser'));
  permissions: any = this.rulesService.UserData[30].data;
  searchBrokers: string = '';
  @Input() dataSource: IBrokersToAvoid[] = [];
  @Input() divisionId: number = -1;

  //Positions
  public positions = [
    new ConnectionPositionPair(
      {originX: 'end', originY: 'bottom'},
      {overlayX: 'end', overlayY: 'top'},
      0, 5
    )
  ];

  constructor(private rulesService: RulesService,
              private dialog: MatDialog, 
              private brokersToAvoidService: BrokersToAvoidService) { }

  //Remove from the list
  removeFromListDialog(element: any, index: number) {
    element.isOpenMenu = !element.isOpenMenu;
    if(this.permissions[0].allowedAll) {
      let dialogRef = this.dialog.open(RemoveFromListConfirmationDialogComponent, {
        autoFocus: false,
        panelClass: 'remove-from-the-list-dialog-container',
        data: element.bp_name
      });
      dialogRef.afterClosed().subscribe((result: any) => {
        if(result) {
          let obj: any = {...element};
          obj.edited_by = `${this.userData.first_name} ${this.userData.last_name}`;
          obj.edited_date = moment().format('YYYY-MM-DDTHH:mm:ss');
          obj.deleted = true;
          this.brokersToAvoidService.createUpdateBrokerToAvoid(obj).subscribe((response: IBrokersToAvoid) => {
            if(response?.deleted) {
              this.dataSource.splice(index, 1);
            };
          });
        }
      });
    }
    else {
      this.dialog.open(MsgForbbidenAccessComponent, {
        autoFocus: false,
        panelClass: 'forbidden-msg-dialog-container'
      })
    }
  };

  //Add/Edit Brokers
  openAddEditBrokersToAvoidDialog(element: any, index: number, readOnly: boolean) {
    element.isOpenMenu = false;
    if(this.permissions[0].allowedAll) {
      let dialogRef = this.dialog.open(AddEditBrokersToAvoidDialogComponent, {
        autoFocus: false,
        panelClass: 'brokers-to-avoid-dialog-container',
        data: {obj: element, editMode: true, readOnly: readOnly}
      });
      dialogRef.afterClosed().subscribe((result: any) => {
        if(result) {
          this.dataSource[index] = result;
          this.dataSource = [...this.dataSource];
        }
      });
    }
    else {
      this.dialog.open(MsgForbbidenAccessComponent, {
        autoFocus: false,
        panelClass: 'forbidden-msg-dialog-container'
      })
    }
  };

  //Mark as checked
  markAsChecked(element: any) {
    element.isOpenMenu = !element.isOpenMenu;
    if(this.permissions[0].allowedAll) {
      let obj: any = {...element};
      obj.last_check = moment().format('YYYY-MM-DDTHH:mm:ss');
      obj.checked_by = `${this.userData.first_name} ${this.userData.last_name}`;
      obj.edited_by = `${this.userData.first_name} ${this.userData.last_name}`;
      obj.edited_date = moment().format('YYYY-MM-DDTHH:mm:ss');
      this.brokersToAvoidService.createUpdateBrokerToAvoid(obj).subscribe((response: IBrokersToAvoid) => {
        console.log(response);
        if(response?.id) {
          element.last_check = response.last_check;
          element.checked_by = response.checked_by;
          element.edited_by = response.edited_by;
          element.edited_date = response.edited_date;
        };
      });
    }
    else {
      this.dialog.open(MsgForbbidenAccessComponent, {
        autoFocus: false,
        panelClass: 'forbidden-msg-dialog-container'
      })
    }
  };

  sortData(sort: Sort) {
    const data = JSON.parse(JSON.stringify(this.dataSource));
    if (!sort.active || sort.direction === '') {
      this.dataSource = data;
      return;
    }
    this.dataSource = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'bp_name':
          return compare(a.bp_name, b.bp_name, isAsc);
        case 'mc':
          return compare(a.mc, b.mc, isAsc);
        case 'reason':
          return compare(a.reason, b.reason, isAsc);
        case 'from':
          return compare(a.in_current_status, b.in_current_status, isAsc);
        case 'in_current_status':
          return compare(a.in_current_status, b.in_current_status, isAsc);
        case 'last_check':
          return compare(a.last_check, b.last_check, isAsc);
        case 'created_by':
          return compare(a.created_by, b.created_by, isAsc);
        case 'status':
          return compare(a.status, b.status, isAsc);
        case 'checked_by':
          return compare(a.checked_by, b.checked_by, isAsc);
        case 'note':
          return compare(a.note, b.note, isAsc);
        default:
          return 0;
      }
    });
  }

}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
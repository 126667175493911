// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

export const environment = {
  production: false,
  services: {
	  webapi: 'https://pgapi.jdmexpedite.com/api/',
	  bridge: {
		  php: 'https://bridge.jdm.local', 
		  api: 'https://bridgeapi.jdmexpedite.com/api/',
	  },
	  extend: {
		api: 'https://extend.jdmexpedite.com/api/'
	  },
	  employee: { url: 'http://employeeapi.jdmexpedite.com/api/', token: 'a0f0d5d9-f86f-477b-8603-9b549469b1a8'},
    pipedrive: { url: 'https://api.pipedrive.com/v1/', token: '871b9aa5c5d2cc33c35113207f1033e85e2c94e9' },  // sendbox
    serverUrl: 'http://45.80.135.63:6689/api/settings',
  } 
};
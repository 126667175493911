import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { StatusDialogComponent } from '@app/modules/shared/components/status-dialog/status-dialog.component';
import { TransformService } from '@app/modules/shared/services/transform.service';
import moment = require('moment');
import { NgxSpinnerService } from 'ngx-spinner';
import { catchError, Subscription, throwError } from 'rxjs';
import { SharedService } from '@app/modules/shared/services/shared.service';

@Component({
  selector: 'app-driver-activity-calendar',
  templateUrl: './driver-activity-calendar.component.html',
  styleUrls: ['./driver-activity-calendar.component.scss']
})

export class DriverActivityCalendarComponent implements OnInit, OnDestroy {
  @Input() data: any;
  dateObj: any = {
    startDate: moment().startOf('month').format('YYYY-MM-DD'),
    endDate:  moment().add(1, 'month').endOf('month').format('YYYY-MM-DD')
  }

  currentMonth: any[] = [];
  currentDate: string = moment().format('MMMM, YYYY');

  nextMonth: any[] = [];
  nextDate: string = moment().add(1, 'month').format('MMMM, YYYY');

  days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

  statusArray: any[] = [];

  //Status
  isOpenedCurrentMonth: boolean = false;
  isOpenedNextMonth: boolean = false;

  loaded: boolean = false;
  error: boolean = false;
  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";

  spinnerBgColor: string = this.transformService.spinnerBgColor;
  spinnerType: string = this.transformService.spinnerType;

  //Subscription
  subscription1: Subscription = new Subscription();
  subscription2: Subscription = new Subscription();

  constructor(private dialog: MatDialog,
              private route: ActivatedRoute, 
              private transformService: TransformService,
              private sharedService: SharedService,
              private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.getCalendarData();
    this.dataChanged();
  }

  dataChanged() {
    this.subscription1 = this.sharedService.statusNoteChangedSubject.subscribe((response: boolean) => {
      if(response) {
        this.getCalendarData();
      }
    });
  }

  getCalendarData() {
    this.error = false;
    this.loaded = false;
    this.spinner.show('driver-calendar');
    let id: string = this.route.snapshot.paramMap.get('id');
    this.subscription2 = this.sharedService.getDriverStatusesByDriver(+id, this.dateObj.startDate, this.dateObj.endDate)
    .pipe(catchError((err: any) => {
      this.spinner.hide('driver-calendar');
      this.loaded = true;
      this.error = true;
      let errorCode: string = err.status + ' ' + err.statusText;
      let service: string = err.url;
      this.sharedService.sendErrorNotification(errorCode, service);
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      this.statusArray = response;
      this.currentMonth = this.addMissingDates(response, moment(this.dateObj.startDate).format('YYYY-MM-DDT00:00:00'), moment(this.dateObj.startDate).endOf('month').format('YYYY-MM-DDT00:00:00'));
      this.nextMonth = this.addMissingDates(response, moment(this.dateObj.endDate).startOf('month').format('YYYY-MM-DDT00:00:00'), moment(this.dateObj.endDate).format('YYYY-MM-DDT00:00:00'));
      this.spinner.hide('driver-calendar');
      this.loaded = true;
    });
  }

  addMissingDates(array: any[], firstDayOfMonth: string, lastDayOfMonth: string) {
    let startDate: any = new Date(firstDayOfMonth);
    let endDate: any = new Date(lastDayOfMonth);
    const dates = [];
    if(moment(startDate).day() !== 1) {
      let monday: any = new Date(moment(startDate).startOf('isoWeek').format('YYYY-MM-DDT00:00:00'));
      let currentDay = startDate;
      while (monday < currentDay) {
        dates.push({date: '-', icon_name: null, status: null, notes: []});
        monday.setDate(monday.getDate() + 1);
      }
    };

    while (startDate <= endDate) {
      let element: any;
      let curentDate = new Date(startDate);
      const result = array.find((obj: any) => { 
          element = obj;
          return new Date(obj.status_date).toDateString() === curentDate.toDateString()
      });

      if(!result) {
        dates.push({date: new Date(startDate), icon_name: null, status: null, notes: []});
      }
      else {
        element.date = new Date(startDate);
        dates.push(element);
      }
      startDate.setDate(startDate.getDate() + 1);
    }
    return dates;
  };

  setMonth(prev: boolean) {
    if(prev) {
      this.dateObj.startDate = moment(this.dateObj.startDate).subtract(2, 'month').startOf('month').format('YYYY-MM-DD');
      this.dateObj.endDate =  moment(this.dateObj.endDate).subtract(2, 'month').endOf('month').format('YYYY-MM-DD');
      this.currentDate = moment(this.currentDate).subtract(2, 'month').format('MMMM, YYYY');
      this.nextDate = moment(this.nextDate).subtract(2, 'month').format('MMMM, YYYY');
    } 
    else {
      this.dateObj.startDate = moment(this.dateObj.startDate).add(2, 'month').startOf('month').format('YYYY-MM-DD');
      this.dateObj.endDate =  moment(this.dateObj.endDate).add(2, 'month').endOf('month').format('YYYY-MM-DD');
      this.currentDate = moment(this.currentDate).add(2, 'month').format('MMMM, YYYY');
      this.nextDate = moment(this.nextDate).add(2, 'month').format('MMMM, YYYY');
    }
    this.getCalendarData();
  }

  addStatus(obj: any) {
    obj.driverInfo = this.data;
    obj.allStatusArray = this.statusArray;
    obj.date = obj.date;
    obj.startDate = this.dateObj.startDate;
    obj.endDate = this.statusArray.length > 0 && new Date(this.statusArray[this.statusArray.length-1].status_date).getTime() > new Date(obj.date).getTime() ? 
    this.statusArray[this.statusArray.length-1].status_date.split('T')[0] : moment(obj.date).format('YYYY-MM-DD')
    this.dialog.open(StatusDialogComponent, {
      autoFocus: false,
      panelClass: 'status-dialog-container',
      data: obj
    });
  };
  
  ngOnDestroy(): void {
    this.subscription1?.unsubscribe();
    this.subscription2?.unsubscribe();
  };

}

import { Component, Input, OnInit } from '@angular/core';

import * as moment from 'moment';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PrebookedLoadsService } from '../services/prebooked-loads.service';
import { TransformService } from '@app/modules/shared/services/transform.service';
import { RulesService } from '@app/modules/shared/services/rules.service';

@Component({
  selector: 'app-prebooked-loads',
  templateUrl: './prebooked-loads.component.html',
  styleUrls: ['./prebooked-loads.component.scss']
})
export class PrebookedLoadsComponent implements OnInit {
  permissions: any = this.rulesService.UserData[6].data[0].allowedAll;
  @Input() groupBy: any;

  selected: FormControl;
  dateFormGroup: FormGroup;

  loaded: boolean = false;

  dateObj: any = {
    startDate: this.transformService.convertDateToTimestamp(moment().format('ddd, MM/DD/YYYY'),'00:00:00'),
    endDate: this.transformService.convertDateToTimestamp(moment().format('ddd, MM/DD/YYYY'), moment().format('HH:mm:ss')),
  };

  //Data for cards
  objOfCards: any;

  //Data for table
  tableData: any;

  //Data for graph
  graphData: any;

  //Btn
  btnsData: any[] = [
    { period: 'present today', name: 'Today' },
    { period: 'past days', name: 'Yesterday' },
    { period: 'present isoWeek', name: 'This Week' },
    { period: 'past isoWeek', name: 'Last Week' },
    { period: 'present month', name: 'This Month' },
    { period: 'past month', name: 'Last Month' },
    { period: 'quarter 0-3', name: 'Q1' },
    { period: 'quarter 3-6', name: 'Q2' },
    { period: 'quarter 6-9', name: 'Q3' },
    { period: 'quarter 9-12', name: 'Q4' },   
  ];

  constructor(private prebookedLoadsService: PrebookedLoadsService,
    private transformService: TransformService,
    private rulesService: RulesService,
    private router: Router,
    private activatedRoute: ActivatedRoute) {
    this.selected = new FormControl(['present month']);
    this.dateFormGroup = new FormGroup({
      'start': new FormControl(moment().startOf('month')),
      'end': new FormControl(moment())
    });
}

  ngOnInit(): void {
    let json = JSON.stringify(this.dateObj);
    sessionStorage.setItem('date', json);
    this.getCardsData(this.dateObj);
  }

  getCardsData(dateObj: any) {
    let obj: any = {};
    this.prebookedLoadsService.getCardsData(dateObj.startDate, dateObj.endDate).subscribe((response: any) => {
      console.log(response)
      if (response.data) {
        this.loaded = true;
        let res: any = response.data;
        obj.gross = '$' + this.transformService.addCommasDots(res.prebook_gross );
        obj.miles = this.transformService.addCommasDots(res.prebook_miles);
        obj.mile = '$' + this.transformService.addCommasDots(res.prebook_gross / res.prebook_miles);
        obj.weight = this.transformService.addCommasDots(res.prebook_weight );
        obj.prebooked_number_of_loads = this.transformService.addCommasDots(res.prebook_num_of_loads );
        obj.margin_amount = '$' + this.transformService.addCommasDots(res.prebook_gross - res.prebokk_driver_gross);
        obj.margin = this.transformService.addCommasDots((res.prebook_gross - res.prebokk_driver_gross) * 100 / res.prebook_gross) + '%';
        obj.percentage_prebooked = this.transformService.addCommasDots((res.prebook_trucks * 100) / res.active_trucks) + '%';
        obj.total_prebooked = this.transformService.addCommasDots(res.prebook_trucks);
        obj.active_trucks = this.transformService.addCommasDots(res.active_trucks);

     /*   obj.gross = '$' + this.transformService.addCommasDots(res.prebook_gross / res.total_gross);
        obj.miles = this.transformService.addCommasDots(res.prebook_miles / res.total_miles);
        obj.mile = '$' + this.transformService.addCommasDots(res.prebook_gross / res.prebook_miles);
        obj.weight = this.transformService.addCommasDots(res.prebook_weight / res.total_weight);
        obj.prebooked_number_of_loads = this.transformService.addCommasDots(res.prebook_num_of_loads / res.total_num_of_loads);
        obj.margin_amount = '$' + this.transformService.addCommasDots(res.prebook_gross - res.prebokk_driver_gross);
        obj.margin = this.transformService.addCommasDots((res.prebook_gross - res.prebokk_driver_gross) * 100 / res.prebook_gross) + '%';
        obj.percentage_prebooked = this.transformService.addCommasDots((res.prebook_trucks * 100) / res.active_trucks) + '%';
        obj.total_prebooked = this.transformService.addCommasDots(res.prebook_trucks);
        obj.active_trucks = this.transformService.addCommasDots(res.active_trucks);*/

        //Calculation

       // let gross: any = res.prebook_gross / res.total_gross;
        let gross: any = res.prebook_gross ;
        let resultGross = this.transformService.addCommasDots((gross / res.total_gross) * 100);
        obj.grossStatistics = { total: resultGross };

      //  let miles: any = res.prebook_miles / res.total_miles;
        let miles: any = res.prebook_miles ;
        let resultMiles: any = this.transformService.addCommasDots((miles / res.total_miles) * 100);
        obj.milesStatistics = { total: resultMiles };

        let mile$ = res.prebook_gross / res.prebook_miles;
        let totalMiles$: any = res.total_gross / res.total_miles;
        let resultMiles$ = this.transformService.addCommasDots((mile$) - (totalMiles$));
        obj.mileStatistics = { below: mile$ > totalMiles$, difference: resultMiles$ }

        let weight: any = res.prebook_weight ;
        let resultWeight: any = this.transformService.addCommasDots(weight - (res.total_weight / res.prebook_num_of_loads));
        obj.weightStatistics = { below: weight > resultWeight, difference: resultWeight };

        let prebooked_number_of_loads: any = res.prebook_num_of_loads ;
        let resultPrebookedNumberOfLoads: any = this.transformService.addCommasDots((prebooked_number_of_loads / res.total_num_of_loads) * 100);
        obj.prebookedNumberOfLoads = { total: resultPrebookedNumberOfLoads };

        let margin: any = (res.prebook_gross - res.prebokk_driver_gross) * 100 / res.prebook_gross;
        let totalMargin: any = (res.total_gross - res.total_driver_gross) * 100 / res.total_gross;
        let resultMargin: any = this.transformService.addCommasDots(margin - totalMargin);
        obj.marginStatistics = { below: margin > resultMargin, difference: resultMargin };

        this.objOfCards = obj;
        this.tableData = response.data.preBookedLists;
        this.graphData = response.data.preBookedGraph;
      }
    });
  }

  showDataByCertainDate(clickedValue: string) {
    let startDate: any;
    let endDate: any;
    let value: any = clickedValue.split(' ')[1];

    if (clickedValue.includes('present')) {

      startDate = this.transformService.convertDateToTimestamp(moment().startOf(value).format('ddd, MM/DD/YYYY'), '00:00:00');
      endDate = this.transformService.convertDateToTimestamp(moment().format('ddd, MM/DD/YYYY'), moment().format('HH:mm:ss'));

    } else if (clickedValue.includes('quarter')) {
      let splitValue = value.split('-');
      startDate = this.transformService.convertDateToTimestamp(moment().month(splitValue[0]).date(1)
        .format('ddd, MM/DD/YYYY'), '00:00:00');
      endDate = this.transformService.convertDateToTimestamp(moment().month(splitValue[1]).date(0)
        .format('ddd, MM/DD/YYYY'), '23:59:59');
    }
    else {

      startDate = this.transformService.convertDateToTimestamp(moment().subtract(1, value).startOf(value)
        .format('ddd, MM/DD/YYYY'), '00:00:00');
      endDate = this.transformService.convertDateToTimestamp(moment().subtract(1, value).endOf(value)
        .format('ddd, MM/DD/YYYY'), '23:59:59');

    }
    console.log({ startDate: startDate, endDate: endDate })
    this.getCardsData({ startDate: startDate, endDate: endDate });
  };

  //Show by methods
  selectDate(value: string) {
    this.selected = new FormControl([value]);
  };

  quickDataSubmit(obj: any) {
    this.getCardsData(obj);
    this.loaded = false;
  };

  //Calendar methods
  dateRangeChange(dateObj: any) {
    console.log(dateObj);

    this.dateObj.startDate = this.transformService.convertDateToTimestamp(moment(dateObj.startDate).format('ddd, MM/DD/YYYY'), '00:00:00');
    this.dateObj.endDate = this.transformService.convertDateToTimestamp(moment(dateObj.endDate).format('ddd, MM/DD/YYYY'), moment().format('HH:mm:ss'));
    this.loaded = false;
    this.getCardsData(this.dateObj);
    let json = JSON.stringify(dateObj);
    sessionStorage.setItem('date', json);
  };

  //Upcomming btn
  upComing() {
    this.router.navigate(['/admin/dispatchers/prebooked-upcoming'], { relativeTo: this.activatedRoute })
  }

}

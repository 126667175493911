import { CdkDragEnter, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, ElementRef, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CompanyBenefitMilesDialogComponent } from '@app/modules/shared/components/company-benefit-miles-dialog/company-benefit-miles-dialog.component';
import { DriversPerformanceService } from '../../services/drivers-performance.service';
import { TransformService } from '@app/modules/shared/services/transform.service';
import { RulesService } from '@app/modules/shared/services/rules.service';

@Component({
  selector: 'app-drivers-performance-cards',
  templateUrl: './drivers-performance-cards.component.html',
  styleUrls: ['./drivers-performance-cards.component.scss']
})
export class DriversPerformanceCardsComponent implements OnInit, OnChanges {
  permissions: any = this.rulesService.UserData[16].data[0].sectionArray;
  @ViewChild('dropListContainer') dropListContainer?: ElementRef;

  @Input() cards: any;
  @Input() prevPeriod: any;
  @Input() date: any;

  public items: Array<number> = [];

  clickedCardName: string = '';

  oneSelectedCardName: string | undefined;

  activeCardsArray: any[] = [
    { selected: false }, { selected: false }, { selected: false }, { selected: false }, { selected: false },
    { selected: false }, { selected: false }, { selected: false }, { selected: false }, { selected: false },
    { selected: false },  { selected: false }
  ];

  activeDownCardsArray: any[] = [
    { selected: false, cardName: 'COMPANY', group: 'FIRST' }, { selected: false, cardName: 'RENT TO RUN', group: 'FIRST' }, { selected: false, cardName: 'OWNER', group: 'FIRST' },
    { selected: false, cardName: 'PAID DEAL', group: 'SECOND' }, { selected: false, cardName: 'SAVED AMOUNT', group: 'SECOND' }, { selected: false, cardName: 'BENEFIT MILES', group: 'SECOND' },
    { selected: false, cardName: 'PAID DEAL', group: 'THIRD' }, { selected: false, cardName: 'SAVED AMOUNT', group: 'THIRD' }, { selected: false, cardName: 'BENEFIT MILES', group: 'THIRD' },
    { selected: false, cardName: 'PAID DEAL', group: 'FOURTH' }, { selected: false, cardName: 'SAVED AMOUNT', group: 'FOURTH' }, { selected: false, cardName: 'BENEFIT MILES', group: 'FOURTH' },

    { selected: false, cardName: 'COMPANY', group: 'FIFTH' }, { selected: false, cardName: 'RENT TO RUN', group: 'FIFTH' }, { selected: false, cardName: 'OWNER', group: 'FIFTH' },
    { selected: false, cardName: 'COMPANY', group: 'SIXTH' }, { selected: false, cardName: 'RENT TO RUN', group: 'SIXTH' }, { selected: false, cardName: 'OWNER', group: 'SIXTH' },
    { selected: false, cardName: 'COMPANY', group: 'SEVENTH' }, { selected: false, cardName: 'RENT TO RUN', group: 'SEVENTH' }, { selected: false, cardName: 'OWNER', group: 'SEVENTH' },

    { selected: false, cardName: 'PERCENT', group: 'EIGHTH' }, { selected: false, cardName: 'RENT TO RUN', group: 'EIGHTH' }, { selected: false, cardName: 'MARGIN / LOAD', group: 'EIGHTH' },
    { selected: false, cardName: 'COMPANY', group: 'NINTH' }, { selected: false, cardName: 'RENT TO RUN', group: 'NINTH' }, { selected: false, cardName: 'OWNER', group: 'NINTH'}, 

    { selected: false, cardName: 'COMPANY', group: 'TENTH' }, { selected: false, cardName: 'RENT TO RUN', group: 'TENTH' }, { selected: false, cardName: 'OWNER', group: 'TENTH' },
    { selected: false, cardName: 'PERCENT', group: 'ELEVENTH' }, { selected: false, cardName: 'LOSS PER DAY', group: 'ELEVENTH' }, { selected: false, cardName: 'OWNER', group: 'ELEVENTH' },
    { selected: false, cardName: 'COMPANY', group: 'TWELFTH' }, { selected: false, cardName: 'RENT TO RUN', group: 'TWELFTH' }, { selected: false, cardName: 'OWNER', group: 'TWELFTH' }
  ];

  slideToggleOn: boolean = true;

  constructor(public transformService: TransformService,
              private rulesService: RulesService,
              private dialog: MatDialog,
              private driversPerformanceService: DriversPerformanceService) { }

  ngOnInit(): void {
    let cardShowInGraph: boolean = true;
    this.permissions.forEach((obj: any, index: number) => {
        if(obj.allowed) {

          if(cardShowInGraph) {
            this.activeCardsArray[index].selected = true;
            cardShowInGraph = false;
          }

          this.items.push(index+1);

        }
    });
  }

  ngOnChanges(): void {
    if (this.cards) {
      this.showInGraphWhenDateChanged();
    }
  }

  titlesArray: string[] = ['CURRENT ACTIVE TRUCKS', 'COMPANY DRIVER DEAL', 'RENT TO RUN DEAL', 'OWNER OPERATOR DEAL',
  'GROSS', 'RATE PER MILE', 'MILEAGE - TMS', 'MARGIN', 'NUMBER OF LOADS', 'LENGTH PER LOAD', 'DAILY TRUCKS WITH LOAD',
  'WEEKLY MILEAGE'];

  titlesArrayDownCards: string[] = [
    'COMPANY (CURRENT ACTIVE TRUCKS)', 'RENT TO RUN (CURRENT ACTIVE TRUCKS)', 'OWNER (CURRENT ACTIVE TRUCKS)',
    'PAID DEAL (COMPANY DRIVER DEAL)', 'SAVED AMOUNT (COMPANY DRIVER DEAL)', 'BENEFIT MILES (COMPANY DRIVER DEAL)',
    'PAID DEAL (RENT TO RUN DEAL)', 'SAVED AMOUNT (RENT TO RUN DEAL)', 'BENEFIT MILES (RENT TO RUN DEAL)',
    'PAID DEAL (OWNER OPERATOR DEAL)', 'SAVED AMOUNT (OWNER OPERATOR DEAL)', 'BENEFIT MILES (OWNER OPERATOR DEAL)',
    'COMPANY (GROSS)', 'RENT TO RUN (GROSS)', 'OWNER (GROSS)',
    'COMPANY (RATE PER MILE)', 'RENT TO RUN (RATE PER MILE)', 'OWNER (RATE PER MILE)',
    'COMPANY (MILEAGE - TMS)', 'RENT TO RUN (MILEAGE - TMS)', 'OWNER (MILEAGE - TMS)',
    'PERCENT (MARGIN)', 'RENT TO RUN (MARGIN)', 'MARGIN/LOAD (MARGIN)',
    'COMPANY (NUMBER OF LOADS)', 'RENT TO RUN (NUMBER OF LOADS)', 'OWNER (NUMBER OF LOADS)',
    'COMPANY (LENGTH PER LOAD)', 'RENT TO RUN (LENGTH PER LOAD)', 'OWNER (LENGTH PER LOAD)',
    'PERCENT (DAILY TRUCKS WITH LOAD)', 'LOSS PER DAY (DAILY TRUCKS WITH LOAD)', 'BENEFIT MILES (DAILY TRUCKS WITH LOAD)',
    'COMPANY (WEEKLY MILEAGE)', 'RENT TO RUN (WEEKLY MILEAGE)', 'OWNER (WEEKLY MILEAGE)',
  ];

  keyArray: string[] = ['active_trucks', 'company_driver_deal', 'rent_deal', 'owner_deal', 'gross', 'rate', 'mileage', 'margin',
  'num_of_load', 'length_per_load', 'daily_trucks_with_load', 'weekly_mileage' ];

  keyArrayCardDown: string[] = [
    'active_trucks_company', 'active_trucks_rent', 'active_trucks_owner',
    'company_paid_deal', 'company_saved_amount', 'company_benefit_miles',
    'rent_deal_paid', 'rent_deal_saved', 'rent_deal_benefit',
    'owner_deal_paid', 'owner_deal_saved', 'owner_deal_benefit',
    'gross_company', 'gross_rent', 'gross_owner',
    'rate_company', 'rate_rent', 'rate_owner',
    'mileage_company', 'mileage_rent', 'mileage_owner',
    'margin_percent', 'margin_rent', 'margin_load',
    'num_of_load_company', 'num_of_load_rent', 'num_of_load_owner',
    'length_per_load_company', 'length_per_load_rent', 'length_per_load_owner',
    'daily_trucks_with_load_percent', 'daily_trucks_with_load_lost', 'daily_trucks_with_load_owner',
    'weekly_mileage_company', 'weekly_mileage_rent', 'weekly_mileage_owner',
  ];

  showInGraphWhenDateChanged() {
    let downCard: boolean = true;
    if (this.slideToggleOn) {
      let data = { cards: undefined, date: this.date ,key: undefined, title: undefined, downCard: undefined, reset: true };
      this.driversPerformanceService.driverPerformanceCardsLineChart.next(data);

      for (let i = 0; i < this.activeCardsArray.length; i++) {
        if (this.activeCardsArray[i].selected) {
          let data = {
            cards: this.cards, date: this.date, key: this.keyArray[i], title: this.titlesArray[i], downCard: false, reset: false
          };
          this.driversPerformanceService.driverPerformanceCardsLineChart.next(data);
          downCard = false;
        }
      }

      if (downCard) {
        for (let i = 0; i < this.activeDownCardsArray.length; i++) {
          if (this.activeDownCardsArray[i].selected) {
            let data = {
              cards: this.cards, date: this.date, key: this.keyArrayCardDown[i], title: this.titlesArrayDownCards[i], downCard: true, 
              reset: false
            };
            this.driversPerformanceService.driverPerformanceCardsLineChart.next(data);
          }
        }
      }
    }
  }

  showInGraph(obj: any, topCard: boolean, cardIndex: number) {
    if (this.slideToggleOn) {
      if (topCard) {
        obj.selected = !obj.selected;
        this.resetSelectedCards(0);
        let data = {
          cards: this.cards, date: this.date, key: this.keyArray[cardIndex], title: this.titlesArray[cardIndex], downCard: false, reset: false
        };
        this.driversPerformanceService.driverPerformanceCardsLineChart.next(data);
      }

      if (!topCard) {
        this.resetSelectedCards(1);
        let sendData = () => {
          let data = {
            cards: this.cards, date: this.date, key: this.keyArrayCardDown[cardIndex], title: this.titlesArrayDownCards[cardIndex],
            downCard: true, reset: false
          };
          this.driversPerformanceService.driverPerformanceCardsLineChart.next(data);
        }

        if (this.activeDownCardsArray.every(obj => obj.selected === false)) {
          obj.selected = !obj.selected;
          this.clickedCardName = obj.cardName;
          sendData();
        }
        else if (obj.cardName == this.clickedCardName && obj.cardName == 'COMPANY') {
          this.activeDownCardsArray[cardIndex].selected = !this.activeDownCardsArray[cardIndex].selected;
          sendData();
        }
        else if (obj.cardName == this.clickedCardName && obj.cardName == 'RENT TO RUN') {
          this.activeDownCardsArray[cardIndex].selected = !this.activeDownCardsArray[cardIndex].selected;
          sendData();
        }
        else if (obj.cardName == this.clickedCardName && obj.cardName == 'OWNER') {
          this.activeDownCardsArray[cardIndex].selected = !this.activeDownCardsArray[cardIndex].selected;
          sendData();
        }
        else if (obj.group == 'FIRST' && this.isActiveGroup(0, 3)) {
          this.clickedCardName = '';
          this.activeDownCardsArray[cardIndex].selected = !this.activeDownCardsArray[cardIndex].selected;
          sendData();
        }
        else if (obj.group == 'SECOND' && this.isActiveGroup(3, 3)) {
          this.clickedCardName = '';
          this.activeDownCardsArray[cardIndex].selected = !this.activeDownCardsArray[cardIndex].selected;
          sendData();
        }
        else if (obj.group == 'THIRD' && this.isActiveGroup(6, 3)) {
          this.clickedCardName = '';
          this.activeDownCardsArray[cardIndex].selected = !this.activeDownCardsArray[cardIndex].selected;
          sendData();
        }
        else if (obj.group == 'FOURTH' && this.isActiveGroup(9, 3)) {
          this.clickedCardName = '';
          this.activeDownCardsArray[cardIndex].selected = !this.activeDownCardsArray[cardIndex].selected;
          sendData();
        }
        else if (obj.group == 'FIFTH' && this.isActiveGroup(12, 3)) {
          this.clickedCardName = '';
          this.activeDownCardsArray[cardIndex].selected = !this.activeDownCardsArray[cardIndex].selected;
          sendData();
        }
        else if (obj.group == 'SIXTH' && this.isActiveGroup(15, 3)) {
          this.clickedCardName = '';
          this.activeDownCardsArray[cardIndex].selected = !this.activeDownCardsArray[cardIndex].selected;
          sendData();
        }
        else if (obj.group == 'SEVENTH' && this.isActiveGroup(18, 3)) {
          this.clickedCardName = '';
          this.activeDownCardsArray[cardIndex].selected = !this.activeDownCardsArray[cardIndex].selected;
          sendData();
        }
        else if (obj.group == 'EIGHTH' && this.isActiveGroup(21, 3)) {
          this.clickedCardName = '';
          this.activeDownCardsArray[cardIndex].selected = !this.activeDownCardsArray[cardIndex].selected;
          sendData();
        }
        else if (obj.group == 'NINTH' && this.isActiveGroup(24, 3)) {
          this.clickedCardName = '';
          this.activeDownCardsArray[cardIndex].selected = !this.activeDownCardsArray[cardIndex].selected;
          sendData();
        }
        else if (obj.group == 'TENTH' && this.isActiveGroup(27, 3)) {
          this.clickedCardName = '';
          this.activeDownCardsArray[cardIndex].selected = !this.activeDownCardsArray[cardIndex].selected;
          sendData();
        }
        else if (obj.group == 'ELEVENTH' && this.isActiveGroup(30, 3)) {
          this.clickedCardName = '';
          this.activeDownCardsArray[cardIndex].selected = !this.activeDownCardsArray[cardIndex].selected;
          sendData();
        }
        else if (obj.group == 'TWELFTH' && this.isActiveGroup(33, 3)) {
          this.clickedCardName = '';
          this.activeDownCardsArray[cardIndex].selected = !this.activeDownCardsArray[cardIndex].selected;
          sendData();
        }
        else if (this.onlyOneSelectedCard() && this.oneSelectedCardName == obj.cardName) {
          this.clickedCardName = obj.cardName;
          this.activeDownCardsArray[cardIndex].selected = !this.activeDownCardsArray[cardIndex].selected;
          sendData();
        }
      }
    }
  }

  isActiveGroup(startIndex: number, endIndex: number) {
    let copyArray: any = JSON.parse(JSON.stringify(this.activeDownCardsArray));
    copyArray.splice(startIndex, endIndex);

    return copyArray.every((obj: any) => obj.selected === false)
  }

  onlyOneSelectedCard() {
    let numberOfSelectedCards: number = 0;
    for (let key in this.activeDownCardsArray) {
      if (this.activeDownCardsArray[key].selected) {
        numberOfSelectedCards++;
        this.oneSelectedCardName = this.activeDownCardsArray[key].cardName;
      }
    }
    return numberOfSelectedCards === 1 ? true : false;
  }

  onChange() {
    this.slideToggleOn = !this.slideToggleOn;
    if (!this.slideToggleOn) {
      this.resetSelectedCards(2);
      let data = {
        cards: undefined, key: undefined, title: undefined,
        downCard: undefined, reset: true
      };
      this.driversPerformanceService.driverPerformanceCardsLineChart.next(data);
    }
  }

  resetSelectedCards(topCard: number) {
    if (topCard === 0) {
      this.activeDownCardsArray = [
        { selected: false, cardName: 'COMPANY', group: 'FIRST' }, { selected: false, cardName: 'RENT TO RUN', group: 'FIRST' }, { selected: false, cardName: 'OWNER', group: 'FIRST' },
        { selected: false, cardName: 'PAID DEAL', group: 'SECOND' }, { selected: false, cardName: 'SAVED AMOUNT', group: 'SECOND' }, { selected: false, cardName: 'BENEFIT MILES', group: 'SECOND' },
        { selected: false, cardName: 'PAID DEAL', group: 'THIRD' }, { selected: false, cardName: 'SAVED AMOUNT', group: 'THIRD' }, { selected: false, cardName: 'BENEFIT MILES', group: 'THIRD' },
        { selected: false, cardName: 'PAID DEAL', group: 'FOURTH' }, { selected: false, cardName: 'SAVED AMOUNT', group: 'FOURTH' }, { selected: false, cardName: 'BENEFIT MILES', group: 'FOURTH' },
    
        { selected: false, cardName: 'COMPANY', group: 'FIFTH' }, { selected: false, cardName: 'RENT TO RUN', group: 'FIFTH' }, { selected: false, cardName: 'OWNER', group: 'FIFTH' },
        { selected: false, cardName: 'COMPANY', group: 'SIXTH' }, { selected: false, cardName: 'RENT TO RUN', group: 'SIXTH' }, { selected: false, cardName: 'OWNER', group: 'SIXTH' },
        { selected: false, cardName: 'COMPANY', group: 'SEVENTH' }, { selected: false, cardName: 'RENT TO RUN', group: 'SEVENTH' }, { selected: false, cardName: 'OWNER', group: 'SEVENTH' },
    
        { selected: false, cardName: 'PERCENT', group: 'EIGHTH' }, { selected: false, cardName: 'RENT TO RUN', group: 'EIGHTH' }, { selected: false, cardName: 'MARGIN / LOAD', group: 'EIGHTH' },
        { selected: false, cardName: 'COMPANY', group: 'NINTH' }, { selected: false, cardName: 'RENT TO RUN', group: 'NINTH' }, { selected: false, cardName: 'OWNER', group: 'NINTH'}, 
    
        { selected: false, cardName: 'COMPANY', group: 'TENTH' }, { selected: false, cardName: 'RENT TO RUN', group: 'TENTH' }, { selected: false, cardName: 'OWNER', group: 'TENTH' },
        { selected: false, cardName: 'PERCENT', group: 'ELEVENTH' }, { selected: false, cardName: 'LOSS PER DAY', group: 'ELEVENTH' }, { selected: false, cardName: 'OWNER', group: 'ELEVENTH' },
        { selected: false, cardName: 'COMPANY', group: 'TWELFTH' }, { selected: false, cardName: 'RENT TO RUN', group: 'TWELFTH' }, { selected: false, cardName: 'OWNER', group: 'TWELFTH' }
      ];
    }
    else if (topCard === 1) {
      this.activeCardsArray = [
        { selected: false }, { selected: false }, { selected: false }, { selected: false }, { selected: false },
        { selected: false }, { selected: false }, { selected: false }, { selected: false }, { selected: false },
        { selected: false },  { selected: false }
      ];
    } else {
      this.activeCardsArray = [
        { selected: false }, { selected: false }, { selected: false }, { selected: false }, { selected: false },
        { selected: false }, { selected: false }, { selected: false }, { selected: false }, { selected: false },
        { selected: false },  { selected: false }
      ];
      this.activeDownCardsArray = [
        { selected: false, cardName: 'COMPANY', group: 'FIRST' }, { selected: false, cardName: 'RENT TO RUN', group: 'FIRST' }, { selected: false, cardName: 'OWNER', group: 'FIRST' },
        { selected: false, cardName: 'PAID DEAL', group: 'SECOND' }, { selected: false, cardName: 'SAVED AMOUNT', group: 'SECOND' }, { selected: false, cardName: 'BENEFIT MILES', group: 'SECOND' },
        { selected: false, cardName: 'PAID DEAL', group: 'THIRD' }, { selected: false, cardName: 'SAVED AMOUNT', group: 'THIRD' }, { selected: false, cardName: 'BENEFIT MILES', group: 'THIRD' },
        { selected: false, cardName: 'PAID DEAL', group: 'FOURTH' }, { selected: false, cardName: 'SAVED AMOUNT', group: 'FOURTH' }, { selected: false, cardName: 'BENEFIT MILES', group: 'FOURTH' },
    
        { selected: false, cardName: 'COMPANY', group: 'FIFTH' }, { selected: false, cardName: 'RENT TO RUN', group: 'FIFTH' }, { selected: false, cardName: 'OWNER', group: 'FIFTH' },
        { selected: false, cardName: 'COMPANY', group: 'SIXTH' }, { selected: false, cardName: 'RENT TO RUN', group: 'SIXTH' }, { selected: false, cardName: 'OWNER', group: 'SIXTH' },
        { selected: false, cardName: 'COMPANY', group: 'SEVENTH' }, { selected: false, cardName: 'RENT TO RUN', group: 'SEVENTH' }, { selected: false, cardName: 'OWNER', group: 'SEVENTH' },
    
        { selected: false, cardName: 'PERCENT', group: 'EIGHTH' }, { selected: false, cardName: 'RENT TO RUN', group: 'EIGHTH' }, { selected: false, cardName: 'MARGIN / LOAD', group: 'EIGHTH' },
        { selected: false, cardName: 'COMPANY', group: 'NINTH' }, { selected: false, cardName: 'RENT TO RUN', group: 'NINTH' }, { selected: false, cardName: 'OWNER', group: 'NINTH'}, 
    
        { selected: false, cardName: 'COMPANY', group: 'TENTH' }, { selected: false, cardName: 'RENT TO RUN', group: 'TENTH' }, { selected: false, cardName: 'OWNER', group: 'TENTH' },
        { selected: false, cardName: 'PERCENT', group: 'ELEVENTH' }, { selected: false, cardName: 'LOSS PER DAY', group: 'ELEVENTH' }, { selected: false, cardName: 'OWNER', group: 'ELEVENTH' },
        { selected: false, cardName: 'COMPANY', group: 'TWELFTH' }, { selected: false, cardName: 'RENT TO RUN', group: 'TWELFTH' }, { selected: false, cardName: 'OWNER', group: 'TWELFTH' }
      ];
    }
  }

  //Drag and drop methods

  dropListReceiverElement?: HTMLElement;
  dragDropInfo?: {
    dragIndex: number;
    dropIndex: number;
  };

  dragEntered(event: CdkDragEnter<number>) {
    const drag = event.item;
    const dropList = event.container;
    const dragIndex = drag.data;
    const dropIndex = dropList.data;

    this.dragDropInfo = { dragIndex, dropIndex };

    const phContainer = dropList.element.nativeElement;
    const phElement = phContainer.querySelector('.cdk-drag-placeholder');

    if (phElement) {
      phContainer.removeChild(phElement);
      phContainer.parentElement?.insertBefore(phElement, phContainer);

      moveItemInArray(this.items, dragIndex, dropIndex);

    }
  };

  dragMoved(index: number) {
    let placeholderElement: any;
    if (!this.dropListContainer || !this.dragDropInfo) return;
    placeholderElement =
      this.dropListContainer.nativeElement.querySelector(
        '.cdk-drag-placeholder'
      );

    const receiverElement =
      this.dragDropInfo.dragIndex > this.dragDropInfo.dropIndex
        ? placeholderElement?.nextElementSibling
        : placeholderElement?.previousElementSibling;

    if (!receiverElement) {
      return;
    }

    receiverElement.style.display = 'none';
    this.dropListReceiverElement = receiverElement;
  }

  dragDropped() {
    if (!this.dropListReceiverElement) {
      return;
    }
    this.dropListReceiverElement.style.removeProperty('display');
    this.dropListReceiverElement = undefined;
    this.dragDropInfo = undefined;
  }

  ratePerMilePercent(prev: number, current: number,) {
    let value = Math.abs(100-(prev / current) * 100);
    return value.toFixed(2);
  }

  setDifference(prev: number, current: number, avg?: any) {
    let checkGrowth: any;
    let className: any;
    if (current > prev) {
      checkGrowth = 'more';
      className = 'up';
      avg === 'avg_weight' ? className = 'down' : className;
    } else if (current < prev) {
      checkGrowth = 'less';
      className = 'down';
      avg === 'avg_weight' ? className = 'up' : className;
    } else {
      checkGrowth = '';
      className = 'none'
    }
    let percentage: any;
    if (prev == 0 && current == 0) {
      percentage = 0;
    }

    if (prev == 0 && current !== 0) {
      percentage = Math.abs(100 - (prev * 100 / current));
    }

    if (prev !== 0 && current == 0) {
      percentage = Math.abs(100 - (current * 100 / prev));
    }

    if (prev !== 0 && current !== 0) {
      percentage = Math.abs(100 - (prev / current) * 100);
    }

    let oneRangeMsg: any;
    this.prevPeriod.days === 0 ? oneRangeMsg = `${this.prevPeriod.dayName}` : oneRangeMsg = `${this.prevPeriod.days} days`;
    let twoRangeMsg: string = 'chosen period';

    let alert: string = ` ${checkGrowth} than the previous ${this.prevPeriod.twoRange ? twoRangeMsg : oneRangeMsg}`;
    let alertObj: any = { percent: Math.round(percentage), message: alert, className: className };
    return alertObj;
  }

  countPercentage(current: any, total: any) {
    return Math.round((current / total) * 100)
  }

  countCpm(prev: number, current: number) {
    let className: any;
    if (current < prev) {
      className = 'up-cpm';
    } else if (current > prev) {
      className = 'down-cpm';
    } else {
      className = 'none'
    }
    let alertObj: any = { percent: current.toFixed(2), className: className };
    return alertObj;
  }

  setDifferenceWeight(prev: number, current: number, avg?: any) {
    let checkGrowth: any;
    let className: any;
    if (current > prev) {
      checkGrowth = 'more';
      className = 'downW';
      avg === 'avg_weight' ? className = 'downW' : className;
    } else if (current < prev) {
      checkGrowth = 'less';
      className = 'upW';
      avg === 'avg_weight' ? className = 'upW' : className;
    } else {
      checkGrowth = '';
      className = 'none'
    }
    let percentage: any = Math.abs(100 - Math.round((prev / current) * 100));
    let oneRangeMsg: string = `${this.prevPeriod.days} days`;
    let twoRangeMsg: string = 'chosen period';

    let alert: string = ` ${checkGrowth} than the previous ${this.prevPeriod.twoRange ? twoRangeMsg : oneRangeMsg}`;
    let alertObj: any = { percent: percentage, message: alert, className: className };
    return alertObj;
  }

  setClass() {
    let isGreater: any = this.countPercentage(this.cards?.total[this.keyArray[10]], this.cards?.total?.active_trucks);
    if( isGreater > 10 && this.activeCardsArray[10].selected) {
      return 'active-alert-gradient';
    } 
    else if(isGreater < 10 && this.activeCardsArray[10].selected) {
      return 'active';
    } 
    else {
      return '';
    }
  }

  //Company benefit miles dialog
  openCompanyBenefitMilesDialog() {
    this.dialog.open(CompanyBenefitMilesDialogComponent, {
      minWidth: '1379px',
      autoFocus: false,
      panelClass: 'company-benefit-miles-dialog-container',
      data: 0
    });
  }

  //Company benefit miles dialog
  openCompanyBenefitMilesDialogRentToRun() {
    this.dialog.open(CompanyBenefitMilesDialogComponent, {
      minWidth: '1379px',
      autoFocus: false,
      panelClass: 'company-benefit-miles-dialog-container',
      data: 1
    });
  }

  openCompanyBenefitMilesDialogOwner() {
    this.dialog.open(CompanyBenefitMilesDialogComponent, {
      minWidth: '1379px',
      autoFocus: false,
      panelClass: 'company-benefit-miles-dialog-container',
      data: 2
    });
  }
  
}
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-login-history-dialog',
  templateUrl: './login-history-dialog.component.html',
  styleUrls: ['./login-history-dialog.component.scss']
})
export class LoginHistoryDialogComponent {
  displayedColumns: string[] = ['user', 'date', 'agent', 'ip'];

  constructor(@Inject(MAT_DIALOG_DATA) public obj: any) { }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import moment = require('moment');
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription, catchError, throwError } from 'rxjs';
import { UnderperformingWeekDialogComponent } from './underperforming-week-dialog/underperforming-week-dialog.component';
import { Router } from '@angular/router';
import * as XLSX from 'xlsx';
import { Sort } from '@angular/material/sort';
import { SharedService } from '@app/modules/shared/services/shared.service';
import { DriversUnderperformingService } from '../../services/drivers-underperforming.service';
import { TransformService } from '@app/modules/shared/services/transform.service';

@Component({
  selector: 'app-underperforming-drivers-table',
  templateUrl: './underperforming-drivers-table.component.html',
  styleUrls: ['./underperforming-drivers-table.component.scss']
})
export class UnderperformingDriversTableComponent implements OnInit, OnDestroy {
  dateObj: any = {
    startDate: this.transformService.convertDateToTimestamp(moment().subtract(4, "week").startOf('isoWeek').format('ddd, DD/MM YYYY'), 
    '00:00:00'),
    endDate: this.transformService.convertDateToTimestamp(moment().subtract(1, "week").endOf('isoWeek').format('ddd, DD/MM YYYY'), 
    '23:59:59')
  };

  data: any;

  displayedColumns: string[] = ['position', 'driver', 'dispatcher', 'paid', 'unit_no', 'make', 'truck_year'];
  dynamicColumns: any[] = [];
  
  dataSource: any[] = [];

  //Btns 
  tabsArray: any[] = [
    {tabName: 'Profitability', type: 3, key: 'profit'},
    {tabName: 'Mileage', type: 1, key: 'mileage'},
    {tabName: 'Gross', type: 0, key: 'gross'},
    {tabName: 'Fuel Consumption', type: 2, key: 'fuel'},
  ];

  selectedTab: number = 1;

  //Selection weeks
  periodArray: any[] = [
    {period: 'Last 4 weeks', week: 4},
    {period: 'Last 5 weeks', week: 5},
    {period: 'Last 6 weeks', week: 6},
    {period: 'Last 7 weeks', week: 7},
    {period: 'Last 8 weeks', week: 8},
  ];
  isOpen: boolean = false;
  selectedPeriod: string = 'Last 4 weeks';

  //Key
  key: string = 'mileage';

  //Num of rows in table
  numOfRows: any[] = [
    {value: 'Show 10', num: 10},
    {value: 'Show 20', num: 20},
    {value: 'Show 30', num: 30},
    {value: 'Show 40', num: 40}
  ];
  numOfRowsIsOpen: boolean = false;
  selectedRows: string = 'Show 40';
  numOfDrivers: number = 40;
  type: number = 1;

  //Table filter
  selectedTruckModel: string = 'All trucks';
  isOpenTruckMenu: boolean = false;
  truckModels: any[] = [];

  //Deal sign
  paySign: string[] = ['SOLO COMPANY', 'TEAM COMPANY'];

  //Filename
  fileName = 'underperforming-drivers-table.xlsx';

  loaded: boolean = false;

  error: boolean = false;

  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";

  spinnerBgColor: string = this.transformService.spinnerBgColor;
  spinnerType: string = this.transformService.spinnerType;

  subscription: Subscription | any;

  constructor(public transformService: TransformService,
              private dialog: MatDialog,
              private driversUnderperformingService: DriversUnderperformingService,
              private spinner: NgxSpinnerService,
              private router: Router) { }

  ngOnInit(): void {
   this.spinner.show('underperforming-drivers');
   this.getTableData();
  }


  getTableData() {
    this.subscription = this.driversUnderperformingService.getUnderperformingDriversTableData(this.dateObj.startDate, this.dateObj.endDate, this.numOfDrivers, this.type, this.transformService.filterParams)
    .pipe(catchError((err: any) => {
      this.spinner.hide('underperforming-drivers');
      this.loaded = true;
      this.error = true;
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      console.log(response);
      this.data = JSON.parse(JSON.stringify(response));
      this.truckModels = Object.keys(this.transformService.groupByProperty(response, 'truckModelYear'));
      console.log(this.truckModels)
      let startDate: any = this.transformService.convertDateToTimestamp(moment(this.dateObj.startDate).startOf('isoWeek').format('ddd, DD/MM YYYY'), 
      '00:00:00');
      let endDate: any = this.dateObj.endDate;

      let mondaysArray: string[] = this.transformService.getMondaysBetweenTwoDates(startDate, endDate);
      let obj: any = this.transformService.groupByWeek(response, mondaysArray);
      this.key = this.tabsArray[this.selectedTab].key;

      mondaysArray.sort((a: any, b: any) => {
        return <any>new Date(b) - <any>new Date(a);
      });      
      
      for(let i = 0; i < mondaysArray.length; i++ ) {
        this.displayedColumns.push(`week-${i}`);
        this.dynamicColumns.push({key: `week-${i}`, title: `${moment(mondaysArray[i]).startOf('isoWeek').format('MMM DD')}-${moment(mondaysArray[i]).endOf('isoWeek').format('DD, YYYY')}`});
      }

      this.displayedColumns.push('avg');
      let array: any = Object.values(obj) ? Object.values(obj) : [];
      if(array.length > 0) {
        array.sort((a: any, b: any) => {
          let avgAValue: number = 0;
          let avgBValue: number = 0;

          a.forEach((obj: any) => {
            avgAValue += obj[this.key];
          });

          b.forEach((obj: any) => {
            avgBValue += obj[this.key];
          });

          a[0].avgAValue = avgAValue;
          b[0].avgBValue = avgBValue;

          return a[0].avgAValue - b[0].avgBValue;
        })

      }

      for(let i = 0; i < array.length; i++) {
        let avgValue: number = 0;
        for(let j = 0; j < array[i].length; j++) {
          avgValue += array[i][j][this.key];
        }
        array[i][0].avg = avgValue / array[i].length;
      }

      this.dataSource = array;

      this.spinner.hide('underperforming-drivers');
      this.loaded = true;
      console.log(response);
      console.log(this.dataSource);
    })
  };

  showSection(obj: any, i: number) {
    this.selectedTab = i;
    this.type = obj.type;
    this.changeData();
  }

  //Select period
  selectPeriod(obj: any) {
    this.selectedPeriod = obj.period;
    this.getWeekRange(obj.week);
    this.changeData();
    this.isOpen = false;
  }

  getDate(result: any) {
    this.dateObj.startDate = result.startDate;
    this.dateObj.endDate = result.endDate;
    this.changeData();
  };

  getWeekRange(week: number) {
    this.dateObj.startDate = this.transformService.convertDateToTimestamp(moment().subtract(week, "week")
    .startOf('isoWeek').format('ddd, DD/MM YYYY'), '00:00:00');
    this.dateObj.endDate = this.transformService.convertDateToTimestamp(moment().subtract(1, "week")
    .endOf('isoWeek').format('ddd, DD/MM YYYY'), '23:59:59');
  };

  showRows(obj: any) {
    this.selectedRows = obj.value;
    this.numOfDrivers = obj.num;
    this.numOfRowsIsOpen = false;
    this.changeData();
  };

  refresh() {
    this.changeData();
  }

  changeData() {
    this.displayedColumns = ['position', 'driver', 'dispatcher', 'paid', 'unit_no', 'make', 'truck_year'];
    this.dynamicColumns = [];
    this.dataSource = [];
    this.data = [];
    this.error = false;
    this.loaded = false;
    this.spinner.show('underperforming-drivers');
    this.getTableData();
  };

  countAverage(array: any) {
    let avgValue: number = 0;
    array.forEach((obj: any) => {
        avgValue += obj[this.key];
    });
    return avgValue / array.length;
  };

  underperformingWeek(data: any) {
    this.dialog.open(UnderperformingWeekDialogComponent, {
      autoFocus: false,
      panelClass: 'underperforming-week-dialog-container',
      data: data
    });
  };

  addSign(key: string, value: any) {
    let valueWithSign: any = '';

    switch(key) {
      case 'gross':
        valueWithSign = '$' + this.transformService.addCommasDots(value, 'round');
        break;
      case 'mileage':
        valueWithSign =  this.transformService.addCommasDots(value, 'round');
        break;
      case 'fuel':
        valueWithSign =  this.transformService.addCommasDots(value);
        break;
      default:
        valueWithSign = '$' + this.transformService.addCommasDots(value, 'round');
    }

    return valueWithSign;
  }

  getDealValue(obj: any) {
    let value: string = '';
    if(this.paySign.includes(obj.status)) {
      return value = '$'+ this.transformService.addCommasDots(obj.paid)
    } else {
      return value = this.transformService.addCommasDots(obj.paid * 100, 'round') + '%';
    }
  }

  //Select truck model
  selectTruckModel(truckModel: string) {
    this.selectedTruckModel = truckModel;
    let data: any[] = JSON.parse(JSON.stringify(this.data));
    let trucksToDispay: any[] = [];
    if(truckModel === 'All trucks') {
      trucksToDispay = data;
    } 
    else {
      for(let key in data) {
        if(data[key].truckModelYear === truckModel) {
          trucksToDispay.push(data[key]);
        }
      }
    }

    let mondaysArray: string[] = this.transformService.getMondaysBetweenTwoDates(this.dateObj.startDate, this.dateObj.endDate);
    let obj: any = this.transformService.groupByWeek(trucksToDispay, mondaysArray);
    this.key = this.tabsArray[this.selectedTab].key;

    let array: any = Object.values(obj) ? Object.values(obj) : [];
    if(array.length > 0) {
      array.sort((a: any, b: any) => {
        let avgAValue: number = 0;
        let avgBValue: number = 0;

        a.forEach((obj: any) => {
          avgAValue += obj[this.key];
        });

        b.forEach((obj: any) => {
          avgBValue += obj[this.key];
        });

        a[0].avgAValue = avgAValue;
        b[0].avgBValue = avgBValue;

        return a[0].avgAValue - b[0].avgBValue;
      })

      this.isOpenTruckMenu = false;
    }

    this.dataSource = array;

  }

  //Open in new tab
  openInNewTab(route: string) {
    const url = this.router.serializeUrl(this.router.createUrlTree([route]));
    window.open(url, '_blank');
  }

  //Export to excell
  exportToExcel() {
    let element = document.getElementById('underperforming-drivers-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    XLSX.writeFile(wb, this.fileName);
  };

  //Sort
  sortData(sort: Sort) {
    const data = JSON.parse(JSON.stringify(this.dataSource));
    if (!sort.active || sort.direction === '') {
      this.dataSource = data;
      return;
    }
    this.dataSource = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      if(sort.active.includes('week')) {
        switch (sort.active) {
          case sort.active:
            let index: number = Number(sort.active.split('-')[1]);
            return compare(a[index][this.key], b[index][this.key], isAsc);
          default:
            return 0;
        }
      }
      else {
        switch (sort.active) {
          case sort.active:
            return compare(a[0][sort.active], b[0][sort.active], isAsc);
          default:
            return 0;
        }
      }
    });
    
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  };

}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MaintenanceRoutingModule } from './maintenance.routing';
import { SharedModule } from '../shared/shared.module';
import { BreakDownByGroupTrucksComponent } from './components/break-down-by-group-trucks/break-down-by-group-trucks.component';
import { BreakDownByMileageTrucksComponent } from './components/break-down-by-mileage-trucks/break-down-by-mileage-trucks.component';
import { BreakdownByGroupLineChartComponent } from './components/breakdown-by-group-line-chart/breakdown-by-group-line-chart.component';
import { BreakdownByGroupLineChart2Component } from './components/breakdown-by-group-line-chart2/breakdown-by-group-line-chart2.component';
import { BreakdownByGroupTrailersDialogComponent } from './components/breakdown-by-group-trailers-dialog/breakdown-by-group-trailers-dialog.component';
import { BreakdownByGroupTrailersComponent } from './components/breakdown-by-group-trailers/breakdown-by-group-trailers.component';
import { BreakdownByGroupTrucksDialogComponent } from './components/breakdown-by-group-trucks-dialog/breakdown-by-group-trucks-dialog.component';
import { BreakdownByMileageTrucksDialogComponent } from './components/breakdown-by-mileage-trucks-dialog/breakdown-by-mileage-trucks-dialog.component';
import { BreakdownByYearTrailersDialogComponent } from './components/breakdown-by-year-trailers-dialog/breakdown-by-year-trailers-dialog.component';
import { BreakdownByYearTrailersComponent } from './components/breakdown-by-year-trailers/breakdown-by-year-trailers.component';
import { DashboardMaintenancePageComponent } from './components/dashboard-maintenance-page.component';
import { MaintenanceCardsLineChartComponent } from './components/maintenance-cards-line-chart/maintenance-cards-line-chart.component';
import { MaintenanceDragDropCardsComponent } from './components/maintenance-drag-drop-cards/maintenance-drag-drop-cards.component';
import { TrailersLineChartComponent } from './components/trailers-line-chart/trailers-line-chart.component';
import { TrailersTableComponent } from './components/trailers-table/trailers-table.component';
import { TruckLineChartComponent } from './components/truck-line-chart/truck-line-chart.component';
import { TruckTableComponent } from './components/truck-table/truck-table.component';
import { MaintenanceService } from './services/maintenance.service';



@NgModule({
  declarations: [
    DashboardMaintenancePageComponent,
    BreakDownByGroupTrucksComponent,
    BreakDownByMileageTrucksComponent,
    BreakdownByGroupLineChartComponent,
    BreakdownByGroupLineChart2Component,
    BreakdownByGroupTrailersComponent,
    BreakdownByGroupTrailersDialogComponent,
    BreakdownByGroupTrucksDialogComponent,
    BreakdownByMileageTrucksDialogComponent,
    BreakdownByYearTrailersComponent,
    BreakdownByYearTrailersDialogComponent,
    MaintenanceCardsLineChartComponent,
    MaintenanceDragDropCardsComponent,
    TrailersLineChartComponent,
    TrailersTableComponent,
    TruckLineChartComponent,
    TruckTableComponent
  ],
  imports: [
    CommonModule,
    MaintenanceRoutingModule,
    SharedModule
  ],
  providers: [MaintenanceService]
})
export class MaintenanceModule { }

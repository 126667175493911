import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'paymentType'
})
export class PaymentTypePipe implements PipeTransform {

  transform(array: any[], value: string): any[] {
    return array.filter((obj: any) => {
      if((obj.category === value) || (value === 'All Categories') || (obj.category === '')) {
        return obj;
      }
    });
  }

}

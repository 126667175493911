
import { Routes } from '@angular/router';
import { MainComponentComponent } from './modules/main-component/main-component.component';
import { AuthGuard } from './modules/core/services/auth/auth.guard';
import { PageNotFoundComponent } from './modules/page-not-found/page-not-found.component';

export const AppRoutes: Routes = [
    {
        path: '',
		component: MainComponentComponent,
		canActivate: [AuthGuard],
        children: [
            {
				path: 'admin',
                loadChildren: () => import('./modules/main.module').then(m => m.MainModule)
			},
        ]   
	},
    { path:'**', component: PageNotFoundComponent }
];

import { Component } from '@angular/core';
import moment = require('moment');

@Component({
  selector: 'app-scheduled-trucks-calendar',
  templateUrl: './scheduled-trucks-calendar.component.html',
  styleUrls: ['./scheduled-trucks-calendar.component.scss']
})
export class ScheduledTrucksCalendarComponent {
  //Calendar show
  showCalendar: boolean = true;

  //Selected day
  selectedDay: string = moment().format('YYYY-MM-DDT00:00:00');

  setDate(date: string) {
    this.selectedDay = date;
  };

}

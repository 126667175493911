import { Component, Input, NgZone, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TransformService } from '@app/modules/shared/services/transform.service';
import { ApexAxisChartSeries, ApexChart, ApexXAxis, ApexYAxis, ApexDataLabels, ApexGrid, ApexStroke, ChartComponent } from 'ng-apexcharts';
import { LinechartDialogComponent } from './linechart-dialog/linechart-dialog.component';

export type ChartOptions = {
  series: ApexAxisChartSeries | any;
  chart: ApexChart | any;
  xaxis: ApexXAxis | any;
  yaxis: ApexYAxis | any;
  dataLabels: ApexDataLabels | any;
  grid: ApexGrid | any;
  stroke: ApexStroke | any;
  markers: any;
  colors: any;
  tooltip: any;
};

@Component({
  selector: 'dispatch-statistics-line-chart',
  templateUrl: './dispatch-statistics-line-chart.component.html',
  styleUrls: ['./dispatch-statistics-line-chart.component.scss']
})
export class DispatchStatisticsLineChartComponent {
  @ViewChild("chart") chart: ChartComponent | any;
  public chartOptions: Partial<ChartOptions> | any;
  graphData: any;

  @Input() set objData(value: any) {
    this.graphData = value;
    this.initGraph(value);
  }

  selectPeriod: FormControl | any;
  graphDataLength: number = 0;

  //Period
  isOpenPeriodMenu: boolean = false;
  menuValue: string = 'By day';
  selectPeriodArray: any[] = [
    {name: 'By year', value: 'year'},
    {name: 'By quarter', value: 'quarter'},
    {name: 'By month', value: 'month'},
    {name: 'By week', value: 'week'},
    {name: 'By day', value: 'day'},
  ];

  constructor(private transformService: TransformService,
              private dialog: MatDialog,
              private ngZone: NgZone) {
    this.selectPeriod = new FormControl(['day']);
  }

  initGraph(data: any) {
    let obj: any = this.transformService.groupGraphDataByDateDispatcherProfile({data: data.graphArray, viewBy: this.selectPeriod.value[0], keys: data.keys, dateObj: data.dateObj});
    this.graphDataLength = obj.series.length;
    console.log(obj);
    this.chartOptions = {
      series: obj.series,
      chart: {
        toolbar: {
          show: true,
          tools: {
            download: false
          },
          animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 800,
            animateGradually: {
                enabled: true,
                delay: 150
            },
            dynamicAnimation: {
                enabled: true,
                speed: 350
            }
          }
        },
        height: 350,
        type: "line",
        zoom: {
          enabled: false
        },
        fontFamily: 'Poppins',
        events: {
          click: (event: any, chartContext: any, config: any) => {
            this.ngZone.run(() => {
              console.log(obj.series[0].tableArray[config.dataPointIndex])
              this.dialog.open(LinechartDialogComponent, {
                autoFocus: false,
                panelClass: 'component-dialog-container',
                data: obj.series[0].tableArray[config.dataPointIndex]
              });
            });
          }
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: "straight",
        width: 1,
      },
      grid: {
        row: {
          colors: ["transparent", "transparent"],
          opacity: 0.5
        }
      },
      xaxis: {
        categories: obj.categories
      },
      yaxis: this.addYAxis(obj.series),
      markers: {
        size: 5
      },
      colors: ['#0030FF', '#FA9120', '#FA0000', '#008000', '#FF00FF', '#800000', '#FE7777', '#0303BB', '#008FA9', '#7550CB', '#6A6D6E'],
      tooltip: {
        enabled: true,
        inverseOrder: false,
        style: {
          fontFamily: 'Poppins',
          fontSize: '12px'
        }
      }
    }
  };

  addYAxis(array: any) {
    let yAxisArray: any[] = [];
    let colors: string[] = ['#0030FF', '#FA9120', '#FA0000', '#008000', '#FF00FF', '#800000', '#FE7777', '#0303BB', '#008FA9', '#7550CB', '#6A6D6E'];
    for(let i = 0; i < array.length; i++) {
      let obj: any;
      if(i === 0) {
        obj = {
        seriesName: array[i].name[0], axisTicks: {show: true}, axisBorder: {show: false, color: colors[i]},
        labels: {style: {colors: colors[i]}, formatter: (num: number) => {
          let value: number = num;
          if(array[i].name !== 'Rate') {
            value = Math.round(value);
          }
          return value.toLocaleString("en-US").toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}
        }
      } 
      else {
        obj = {
          seriesName: array[i].name[0], opposite: true, axisTicks: {show: true}, axisBorder: {show: false, color: colors[i]},
          labels: {style: {colors: colors[i]}, formatter: (num: number) => { 
            let value: number = num;
            if(array[i].name !== 'Rate') {
              value = Math.round(value);
            }
            return value.toLocaleString("en-US").toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          }
        }
      }
      yAxisArray.push(obj);
    }

    return yAxisArray;
  }

  onlyOneSelect(value: string, obj: any) {
    this.menuValue = obj.name;
    this.selectPeriod = new FormControl([value]);
    this.initGraph(this.graphData);
    this.isOpenPeriodMenu = false;
  };

}

import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CalendarDialogComponent } from '@app/modules/shared/components/calendar-dialog/calendar-dialog.component';
import moment = require('moment');
import { BusinessPartnersService } from '../services/business-partners.service';

@Component({
  selector: 'app-business-partners-page',
  templateUrl: './business-partners-page.component.html',
  styleUrls: ['./business-partners-page.component.scss']
})
export class BusinessPartnersPageComponent {
  dateObj: any = {
    startDate: moment().startOf('month').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD')
  };

  constructor(private businessPartnersService: BusinessPartnersService, 
              private dialog: MatDialog) { }

  getDate(result) {
    this.dateObj.startDate = result.startDate;
    this.dateObj.endDate = result.endDate;
    this.businessPartnersService.dateChanged.next(this.dateObj);
  };

  openFloatingDialog() {
    let dialogRef: any = this.dialog.open(CalendarDialogComponent, {
      minWidth: '769px',
      height: '476px',
      autoFocus: false,
      data: {oneRange: false}
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.dateObj.startDate = result.startDate;
        this.dateObj.endDate = result.endDate;
        this.businessPartnersService.dateChanged.next(this.dateObj);
      }
    });
  }

   
  refresh() {
    this.businessPartnersService.dateChanged.next(this.dateObj);
  };
  
}

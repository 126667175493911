import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IBrokersReasons } from '@app/modules/brokers-to-avoid/models/brokers-reasons.model';
import { BrokersToAvoidService } from '@app/modules/brokers-to-avoid/services/brokers-to-avoid.service';

@Component({
  selector: 'app-brokers-avoid-reasons-dialog',
  templateUrl: './brokers-avoid-reasons-dialog.component.html',
  styleUrls: ['./brokers-avoid-reasons-dialog.component.scss']
})
export class BrokersAvoidReasonsDialogComponent implements OnInit {
  reasonObj: IBrokersReasons = {
    id: 0,
    reason: ''
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<BrokersAvoidReasonsDialogComponent>,
              private brokersToAvoidService: BrokersToAvoidService) { }

  ngOnInit(): void {
    this.reasonObj = this.data.obj;
  }

  save() {
    this.brokersToAvoidService.createUpdateReasons(this.reasonObj).subscribe((response: IBrokersReasons) => {
      console.log(response);
      if(response.id) {
        this.dialogRef.close(response);
      }
    })
  };
  
}

import { CdkDragEnter, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, ElementRef, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { RulesService } from '@app/modules/shared/services/rules.service';
import { TransformService } from '@app/modules/shared/services/transform.service';
import { MaintenanceService } from '../../services/maintenance.service';

@Component({
  selector: 'app-maintenance-drag-drop-cards',
  templateUrl: './maintenance-drag-drop-cards.component.html',
  styleUrls: ['./maintenance-drag-drop-cards.component.scss']
})
export class MaintenanceDragDropCardsComponent implements OnInit, OnChanges {
  permissions: any = this.rulesService.UserData[11].data[0].sectionArray;
  @ViewChild('dropListContainer') dropListContainer?: ElementRef;

  @Input() cards: any;
  @Input() prevPeriod: any;

  public items: Array<number> = [];

  clickedCardName: string = '';

  oneSelectedCardName: string | undefined;

  activeCardsArray: any[] = [
    { selected: true }, { selected: false }, { selected: false }, { selected: false }, { selected: false },
    { selected: false }, { selected: false }
  ];

  activeDownCardsArray: any[] = [
    { selected: false, cardName: 'TRUCK', group: 'FIRST' }, { selected: false, cardName: 'TRAILER', group: 'FIRST' },
    { selected: false, cardName: 'TRUCK', group: 'SECOND' }, { selected: false, cardName: 'TRAILER', group: 'SECOND' },
    { selected: false, cardName: 'AVG PER TRUCK', group: 'THIRD' }, { selected: false, cardName: 'WEEKLY AVG FOR TRUCK', group: 'THIRD' },

    { selected: false, cardName: 'DEDUCTED', group: 'FOURTH' }, { selected: false, cardName: 'SPENT', group: 'FOURTH' },
    { selected: false, cardName: 'TRUCK', group: 'FIFTH' }, { selected: false, cardName: 'TRAILER', group: 'FIFTH' },
    { selected: false, cardName: 'PER GALLONS', group: 'SIXTH' }, { selected: false, cardName: 'GALLONS', group: 'SIXTH' }, { selected: false, cardName: 'CONSUMPTION', group: 'SIXTH' },

    { selected: false, cardName: 'COMPANY', group: 'SEVENTH' }, { selected: false, cardName: 'RENT TO RUN', group: 'SEVENTH' }, { selected: false, cardName: 'OWNER', group: 'SEVENTH' }
  ];

  slideToggleOn: boolean = true;

  constructor(public transformService: TransformService, private rulesService: RulesService,
    private mService: MaintenanceService) { }

  ngOnInit(): void {
    let cardShowInGraph: boolean = true;
    this.permissions.forEach((obj: any, index: number) => {
        if(obj.allowed) {

          if(cardShowInGraph) {
            this.activeCardsArray[index].selected = true;
            cardShowInGraph = false;
          }

          this.items.push(index+1);

        }
    });
  }

  ngOnChanges(): void {
    if (this.cards) {
      this.showInGraphWhenDateChanged();
    }
  }

  titlesArray: string[] = ['MAINTENANCE COST', 'MAINTENANCE COST PER MILE', 'TOTAL MILEAGE-GPS', 'MAINTENANCE ACCOUNT',
  'UNITS', 'FUEL COST PER MILE', 'IDLING'];

  titlesArrayDownCards: string[] = [
    'TRUCK (COST)', 'TRAILER (COST)',
    'TRUCK (CPM)', 'TRAILER (CPM)',
    'AVG PER TRUCK (TOTAL MILEAGE-GPS)', 'WEEKLY AVG (TOTAL MILEAGE-GPS)',
    'DEDUCTED (ACCOUNT)', 'SPENT (ACCOUNT)',
    'TRUCK (UNITS)', 'TRAILERS (UNITS)',
    'PER GALLON (CPM)', 'GALLONS (CPM)', 'CONSUMPTION (CPM)',
    'COMPANY (IDLING)', 'RENT TO RUN (IDLING)', 'OWNER (IDLING)'
  ];

  keyArray: string[] = ['maintenance_cost', 'maintenance_cost_per_mile', 'total_mileage', 'maintenance_account', 
  'units', 'fuel_cost_per_mile', 'idling'];

  keyArrayCardDown: string[] = ['maintenance_cost_trucks', 'maintenance_cost_trailers', 'maintenance_cost_per_trucks',
  'maintenance_cost_trailers', 'avg_milegage_per_truck', 'weekly_avg_mileage_per_truck', 'maintenance_acount_dedacted', 
  'maintenance_acount_spent', 'trucks', 'trailers', 'fuel_cost_per_galon', 'fuel_gallons', 'fuel_consumption',
  'idling_company_time', 'idling_rent_time', 'idling_owner_time'
  ];

  downKey: string[] = ['maintenance_cost', 'maintenance_cost', 'maintenance_cost_per_mile', 'maintenance_cost_per_mile',
  'total_mileage', 'total_mileage', 'maintenance_account', 'maintenance_account', 'units', 'units', 'fuel_cost_per_mile',
  'fuel_cost_per_mile', 'fuel_cost_per_mile', 'idling_company', 'idling_rent', 'idling_owner'];

  showInGraphWhenDateChanged() {
    let downCard: boolean = true;
    if (this.slideToggleOn) {
      let data = { cards: undefined, key: undefined, title: undefined, type: undefined, downCard: undefined, reset: true };
      this.mService.maintenanceCardsLineChartSubject.next(data);

      for (let i = 0; i < this.activeCardsArray.length; i++) {
        if (this.activeCardsArray[i].selected) {
          let data = {
            cards: this.cards, key: this.keyArray[i], title: this.titlesArray[i], downCard: false, reset: false,
            type: 'all'
          };
          this.mService.maintenanceCardsLineChartSubject.next(data);
          downCard = false;
        }
      }

      if (downCard) {
        for (let i = 0; i < this.activeDownCardsArray.length; i++) {
          if (this.activeDownCardsArray[i].selected) {
            let data = {
              cards: this.cards, key: this.downKey[i], title: this.titlesArrayDownCards[i],
              type: this.activeDownCardsArray[i].cardName, downCard: true, reset: false
            };
            this.mService.maintenanceCardsLineChartSubject.next(data);
          }
        }
      }
    }
  }

  showInGraph(obj: any, topCard: boolean, cardIndex: number) {
    if (this.slideToggleOn) {
      if (topCard) {
        obj.selected = !obj.selected;
        this.resetSelectedCards(0);
        let data = {
          cards: this.cards, key: this.keyArray[cardIndex], title: this.titlesArray[cardIndex], downCard: false, reset: false,
          type: 'all'
        };
        this.mService.maintenanceCardsLineChartSubject.next(data);
      }

      if (!topCard) {
        this.resetSelectedCards(1);
        let sendData = () => {
          let data = {
            cards: this.cards, key: this.downKey[cardIndex], title: this.titlesArrayDownCards[cardIndex],
            type: obj.cardName, downCard: true, reset: false
          };
          this.mService.maintenanceCardsLineChartSubject.next(data);
        }

        if (this.activeDownCardsArray.every(obj => obj.selected === false)) {
          obj.selected = !obj.selected;
          this.clickedCardName = obj.cardName;
          sendData();
        }
        else if (obj.cardName == this.clickedCardName && obj.cardName == 'TRUCK') {
          this.activeDownCardsArray[cardIndex].selected = !this.activeDownCardsArray[cardIndex].selected;
          sendData();
        }
        else if (obj.cardName == this.clickedCardName && obj.cardName == 'TRAILER') {
          this.activeDownCardsArray[cardIndex].selected = !this.activeDownCardsArray[cardIndex].selected;
          sendData();
        }
        else if (obj.group == 'FIRST' && this.isActiveGroup(0, 2)) {
          this.clickedCardName = '';
          this.activeDownCardsArray[cardIndex].selected = !this.activeDownCardsArray[cardIndex].selected;
          sendData();
        }
        else if (obj.group == 'SECOND' && this.isActiveGroup(2, 2)) {
          this.clickedCardName = '';
          this.activeDownCardsArray[cardIndex].selected = !this.activeDownCardsArray[cardIndex].selected;
          sendData();
        }
        else if (obj.group == 'THIRD' && this.isActiveGroup(4, 2)) {
          this.clickedCardName = '';
          this.activeDownCardsArray[cardIndex].selected = !this.activeDownCardsArray[cardIndex].selected;
          sendData();
        }
        else if (obj.group == 'FOURTH' && this.isActiveGroup(6, 2)) {
          this.clickedCardName = '';
          this.activeDownCardsArray[cardIndex].selected = !this.activeDownCardsArray[cardIndex].selected;
          sendData();
        }
        else if (obj.group == 'FIFTH' && this.isActiveGroup(8, 2)) {
          this.clickedCardName = '';
          this.activeDownCardsArray[cardIndex].selected = !this.activeDownCardsArray[cardIndex].selected;
          sendData();
        }
        else if (obj.group == 'SIXTH' && this.isActiveGroup(11, 3)) {
          this.clickedCardName = '';
          this.activeDownCardsArray[cardIndex].selected = !this.activeDownCardsArray[cardIndex].selected;
          sendData();
        }
        else if (obj.group == 'SEVENTH' && this.isActiveGroup(14, 3)) {
          this.clickedCardName = '';
          this.activeDownCardsArray[cardIndex].selected = !this.activeDownCardsArray[cardIndex].selected;
          sendData();
        }
        else if (this.onlyOneSelectedCard() && this.oneSelectedCardName == obj.cardName) {
          this.clickedCardName = obj.cardName;
          this.activeDownCardsArray[cardIndex].selected = !this.activeDownCardsArray[cardIndex].selected;
          sendData();
        }
      }
    }
  }

  isActiveGroup(startIndex: number, endIndex: number) {
    let copyArray: any = JSON.parse(JSON.stringify(this.activeDownCardsArray));
    copyArray.splice(startIndex, endIndex);

    return copyArray.every((obj: any) => obj.selected === false)
  }

  onlyOneSelectedCard() {
    let numberOfSelectedCards: number = 0;
    for (let key in this.activeDownCardsArray) {
      if (this.activeDownCardsArray[key].selected) {
        numberOfSelectedCards++;
        this.oneSelectedCardName = this.activeDownCardsArray[key].cardName;
      }
    }
    return numberOfSelectedCards === 1 ? true : false;
  }

  onChange(isTrue: boolean) {
    this.slideToggleOn = isTrue;
    if (!this.slideToggleOn) {
      this.resetSelectedCards(2);
      let data = {
        cards: undefined, key: undefined, title: undefined,
        type: undefined, downCard: undefined, reset: true
      };
      this.mService.maintenanceCardsLineChartSubject.next(data);
    }
  }

  resetSelectedCards(topCard: number) {
    if (topCard === 0) {
      this.activeDownCardsArray =  [
        { selected: false, cardName: 'TRUCK', group: 'FIRST' }, { selected: false, cardName: 'TRAILER', group: 'FIRST' },
        { selected: false, cardName: 'TRUCK', group: 'SECOND' }, { selected: false, cardName: 'TRAILER', group: 'SECOND' },
        { selected: false, cardName: 'AVG PER TRUCK', group: 'THIRD' }, { selected: false, cardName: 'WEEKLY AVG FOR TRUCK', group: 'THIRD' },
    
        { selected: false, cardName: 'DEDUCTED', group: 'FOURTH' }, { selected: false, cardName: 'SPENT', group: 'FOURTH' },
        { selected: false, cardName: 'TRUCK', group: 'FIFTH' }, { selected: false, cardName: 'TRAILER', group: 'FIFTH' },
        { selected: false, cardName: 'PER GALLONS', group: 'SIXTH' }, { selected: false, cardName: 'GALLONS', group: 'SIXTH' }, { selected: false, cardName: 'CONSUMPTION', group: 'SIXTH' },
    
        { selected: false, cardName: 'COMPANY', group: 'SEVENTH' }, { selected: false, cardName: 'RENT TO RUN', group: 'SEVENTH' }, { selected: false, cardName: 'OWNER', group: 'SEVENTH' }
      ];
    }
    else if (topCard === 1) {
      this.activeCardsArray = [
        { selected: false }, { selected: false }, { selected: false }, { selected: false }, { selected: false },
        { selected: false }, { selected: false }
      ];
    } else {
      this.activeCardsArray = [
        { selected: false }, { selected: false }, { selected: false }, { selected: false }, { selected: false },
        { selected: false }, { selected: false }
      ];
      this.activeDownCardsArray =  [
        { selected: false, cardName: 'TRUCK', group: 'FIRST' }, { selected: false, cardName: 'TRAILER', group: 'FIRST' },
        { selected: false, cardName: 'TRUCK', group: 'SECOND' }, { selected: false, cardName: 'TRAILER', group: 'SECOND' },
        { selected: false, cardName: 'AVG PER TRUCK', group: 'THIRD' }, { selected: false, cardName: 'WEEKLY AVG FOR TRUCK', group: 'THIRD' },
    
        { selected: false, cardName: 'DEDUCTED', group: 'FOURTH' }, { selected: false, cardName: 'SPENT', group: 'FOURTH' },
        { selected: false, cardName: 'TRUCK', group: 'FIFTH' }, { selected: false, cardName: 'TRAILER', group: 'FIFTH' },
        { selected: false, cardName: 'PER GALLONS', group: 'SIXTH' }, { selected: false, cardName: 'GALLONS', group: 'SIXTH' }, { selected: false, cardName: 'CONSUMPTION', group: 'SIXTH' },
    
        { selected: false, cardName: 'COMPANY', group: 'SEVENTH' }, { selected: false, cardName: 'RENT TO RUN', group: 'SEVENTH' }, { selected: false, cardName: 'OWNER', group: 'SEVENTH' }
      ];
    }
  }

  //Drag and drop methods

  dropListReceiverElement?: HTMLElement;
  dragDropInfo?: {
    dragIndex: number;
    dropIndex: number;
  };

  dragEntered(event: CdkDragEnter<number>) {
    const drag = event.item;
    const dropList = event.container;
    const dragIndex = drag.data;
    const dropIndex = dropList.data;

    this.dragDropInfo = { dragIndex, dropIndex };

    const phContainer = dropList.element.nativeElement;
    const phElement = phContainer.querySelector('.cdk-drag-placeholder');

    if (phElement) {
      phContainer.removeChild(phElement);
      phContainer.parentElement?.insertBefore(phElement, phContainer);

      moveItemInArray(this.items, dragIndex, dropIndex);

    }
  };

  dragMoved(index: number) {
    let placeholderElement: any;
    if (!this.dropListContainer || !this.dragDropInfo) return;
    placeholderElement =
      this.dropListContainer.nativeElement.querySelector(
        '.cdk-drag-placeholder'
      );

    const receiverElement =
      this.dragDropInfo.dragIndex > this.dragDropInfo.dropIndex
        ? placeholderElement?.nextElementSibling
        : placeholderElement?.previousElementSibling;

    if (!receiverElement) {
      return;
    }

    receiverElement.style.display = 'none';
    this.dropListReceiverElement = receiverElement;
  }

  dragDropped() {
    if (!this.dropListReceiverElement) {
      return;
    }
    this.dropListReceiverElement.style.removeProperty('display');
    this.dropListReceiverElement = undefined;
    this.dragDropInfo = undefined;
  }

  ratePerMilePercent(prev: number, current: number,) {
    let value = Math.abs(100-(prev / current) * 100);
    return value.toFixed(2);
  }

  setDifference(prev: number, current: number, avg?: any) {
    let checkGrowth: any;
    let className: any;
    if (current > prev) {
      checkGrowth = 'more';
      className = 'up';
      avg === 'avg_weight' ? className = 'down' : className;
    } else if (current < prev) {
      checkGrowth = 'less';
      className = 'down';
      avg === 'avg_weight' ? className = 'up' : className;
    } else {
      checkGrowth = '';
      className = 'none'
    }
    let percentage: any;
    if (prev == 0 && current == 0) {
      percentage = 0;
    }

    if (prev == 0 && current !== 0) {
      percentage = Math.abs(100 - (prev * 100 / current));
    }

    if (prev !== 0 && current == 0) {
      percentage = Math.abs(100 - (current * 100 / prev));
    }

    if (prev !== 0 && current !== 0) {
      percentage = Math.abs(100 - (prev / current) * 100);
    }

    let oneRangeMsg: any;
    this.prevPeriod.days === 0 ? oneRangeMsg = `${this.prevPeriod.dayName}` : oneRangeMsg = `${this.prevPeriod.days} days`;
    let twoRangeMsg: string = 'chosen period';

    let alert: string = ` ${checkGrowth} than the previous ${this.prevPeriod.twoRange ? twoRangeMsg : oneRangeMsg}`;
    let alertObj: any = { percent: Math.round(percentage), message: alert, className: className };
    return alertObj;
  }

  setDifferenceFuelIdling(prev: number, current: number, avg?: any) {
    let checkGrowth: any;
    let className: any;
    if (current > prev) {
      checkGrowth = 'more';
      className = 'down';
      avg === 'avg_weight' ? className = 'down' : className;
    } else if (current < prev) {
      checkGrowth = 'less';
      className = 'up';
      avg === 'avg_weight' ? className = 'up' : className;
    } else {
      checkGrowth = '';
      className = 'none'
    }
    let percentage: any;
    if (prev == 0 && current == 0) {
      percentage = 0;
    }

    if (prev == 0 && current !== 0) {
      percentage = Math.abs(100 - (prev * 100 / current));
    }

    if (prev !== 0 && current == 0) {
      percentage = Math.abs(100 - (current * 100 / prev));
    }

    if (prev !== 0 && current !== 0) {
      percentage = Math.abs(100 - (prev / current) * 100);
    }

    let oneRangeMsg: any;
    this.prevPeriod.days === 0 ? oneRangeMsg = `${this.prevPeriod.dayName}` : oneRangeMsg = `${this.prevPeriod.days} days`;
    let twoRangeMsg: string = 'chosen period';

    let alert: string = ` ${checkGrowth} than the previous ${this.prevPeriod.twoRange ? twoRangeMsg : oneRangeMsg}`;
    let alertObj: any = { percent: Math.round(percentage), message: alert, className: className };
    return alertObj;
  }

  countPercentage(current: any, total: any) {
    return Math.round((current / total) * 100)
  }

  countCpm(prev: number, current: number) {
    let className: any;
    if (current < prev) {
      className = 'up-cpm';
    } else if (current > prev) {
      className = 'down-cpm';
    } else {
      className = 'none'
    }
    let alertObj: any = { percent: current.toFixed(2), className: className };
    return alertObj;
  }

  setDifferenceWeight(prev: number, current: number, avg?: any) {
    let checkGrowth: any;
    let className: any;
    if (current > prev) {
      checkGrowth = 'more';
      className = 'downW';
      avg === 'avg_weight' ? className = 'downW' : className;
    } else if (current < prev) {
      checkGrowth = 'less';
      className = 'upW';
      avg === 'avg_weight' ? className = 'upW' : className;
    } else {
      checkGrowth = '';
      className = 'none'
    }
    let percentage: any = Math.abs(100 - Math.round((prev / current) * 100));
    let oneRangeMsg: string = `${this.prevPeriod.days} days`;
    let twoRangeMsg: string = 'chosen period';

    let alert: string = ` ${checkGrowth} than the previous ${this.prevPeriod.twoRange ? twoRangeMsg : oneRangeMsg}`;
    let alertObj: any = { percent: percentage, message: alert, className: className };
    return alertObj;
  }

}

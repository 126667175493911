import { Component, OnInit } from '@angular/core';

//Map
declare const google: any;
declare global {
  interface Window {
    initMap: () => void;
  }
}

@Component({
  selector: 'app-truck-profile-cards',
  templateUrl: './truck-profile-cards.component.html',
  styleUrls: ['./truck-profile-cards.component.scss']
})

export class TruckProfileCardsComponent implements OnInit {
  isOpen: boolean = false;
  value: string = 'OTR';

  selectArray: any[] = [
    {name: 'OTR'},
    {name: 'OTR'},
    {name: 'OTR'},
    {name: 'OTR'},
    {name: 'OTR'},
  ];

  //Map
  map: any = null;

  ngOnInit(): void {
    this.initMap();
  }

  selectPeriod(obj: any) {
    this.value = obj.name;
    this.isOpen = false;
  };
  

  async initMap() {
    const { Map } = await google.maps.importLibrary("maps");
    this.map = new Map(document.getElementById("map"), {
      center: { lat: -34.397, lng: 150.644 },
      zoom: 8,
      scrollwheel: true,
      mapTypeControl: true,
      draggable: true,
      scaleControl: true,
      navigationControl: true,
      disableDefaultUI: true,
      streetViewControl: false,
    });
  }

}

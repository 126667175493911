import { Injectable } from '@angular/core';
import * as _G from '../../../_config/globals';
import { HttpHeaders, HttpClient } from '@angular/common/http';

@Injectable()

export class TicketsService {

  headers = new HttpHeaders({
		'Content-Type': 'application/json',
		'Authorization': 'Basic SVZTb2x1dGlvbjoxUzBsdXQxMG4yQDIy'
	});
	requestOptions = { headers: this.headers };

  constructor(private http: HttpClient) { }

  getTicketsData(startDate: string, endDate: string) {
    return this.http.get(`${_G.extendApiUrl}warnings/initiate?start=${startDate}&end=${endDate}`, this.requestOptions);
  };

  addUpdateTicket(obj: any) {
    let json: any = JSON.stringify(obj);
    return this.http.post(`${_G.extendApiUrl}warnings/initiate`, json, {
      headers: this.headers,
      reportProgress: true,
      observe: 'events'
    });
  };

  deleteTicket(id: number, deletedBy: string, date: string) {
    return this.http.get(`${_G.extendApiUrl}warnings/deleteinitiate?id=${id}&user=${deletedBy}&date=${date}`, this.requestOptions);
  };

}

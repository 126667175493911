import { Component } from '@angular/core';

@Component({
  selector: 'app-trucks-cards',
  templateUrl: './trucks-cards.component.html',
  styleUrls: ['./trucks-cards.component.scss']
})
export class TrucksCardsComponent {

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WarningsRoutingModule } from './warnings.routing';
import { SharedModule } from '../shared/shared.module';
import { CreateUpdateWarningDialogComponent } from './components/create-update-warning-dialog/create-update-warning-dialog.component';
import { WarningsComponent } from './components/warnings.component';
import { DriversFilterPipe } from './pipes/drivers-filter.pipe';
import { WarningPipe } from './pipes/warning.pipe';
import { WarningsService } from './services/warnings.service';

@NgModule({
  declarations: [
    WarningsComponent,
    CreateUpdateWarningDialogComponent,
    DriversFilterPipe,
    WarningPipe
  ],
  imports: [
    CommonModule,
    WarningsRoutingModule,
    SharedModule
  ],
  providers: [WarningsService,  WarningPipe]
})
export class WarningsModule { }

import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Sort } from '@angular/material/sort';
import { RulesService } from '@app/modules/shared/services/rules.service';
import { TransformService } from '@app/modules/shared/services/transform.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { catchError, map, Observable, startWith, Subscription, throwError } from 'rxjs';

import * as XLSX from 'xlsx'; 
import { MaintenanceService } from '../../services/maintenance.service';
import { SharedService } from '@app/modules/shared/services/shared.service';

@Component({
  selector: 'app-trailers-table',
  templateUrl: './trailers-table.component.html',
  styleUrls: ['./trailers-table.component.scss']
})
export class TrailersTableComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('scrollContainerDriversTable', { read: ElementRef }) public scrollContainerDriversTable: any;
  @Input() date: any;
  subscription1: Subscription | any;
  subscription2: Subscription | any;

  fileName: string = 'trailers-table.xlsx';

  compare: boolean = false;

  clickedColumn: string | undefined;
  selectedIndexArray: number[] = [];

  displayedColumns: string[] = ['unit_no', 'assigned', 'status', 'year', 'maintenance_cost_per_mile', 
  'maintenacne_cost', 'location'];

  dataSource: any[] = [];

  sortedData: any;

  oneRowClickedColumns: boolean[] = [false, false, false, false, false, false, false, false, false, false];

  listByDays: any;

  graphData: any[] = [];

  title: any;

  selectedUsers: any[] = new Array<any>();

  searchText: string = '';

  isOpen: boolean = false;

  selectedRow: any;

  dataTypingArray: any[] = [];

  totalObj: any = {
    numOfTrailers: 0,
    maintenace_cpm: 0,
    maintenance_cost: 0,
  }

  avgObj: any = {
    maintenace_cpm: 0,
    maintenance_cost: 0
  }

  loaded: boolean = false;

  error: boolean = false;

  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";

  spinnerBgColor: string = this.transformService.spinnerBgColor;

  activeFormControl = new FormControl('');

  //Filters
  activeArray: any[] = [
    {name: 'Active', selected: false, group: 'Trailers', all: false},
    {name: 'Inactive', selected: false, group: 'Trailers', all: false},
    {name: 'Rented', selected: false, group: 'Trailers', all: false},
    {name: 'All trailers', selected: false, group: 'Trailers', all: true}
  ];
 
  allBrandsArray: any[] = [
    {name: 'Great Dane', selected: false, group: 'Mark', all: false},
    {name: 'Utility', selected: false, group: 'Mark', all: false},
    {name: 'Wabash', selected: false,  group: 'Mark', all: false},
    {name: 'Hyndai', selected: false,  group: 'Mark', all: false},
    {name: 'All brands', selected: false,  group: 'Mark', all: true},
  ];

  allDevisionsArray: any[] = [
    {name: 'JDM Expedite INC', selected: false, group: 'Devisions', all: false},
    {name: 'Tempo Freight Systems', selected: false, group: 'Devisions', all: false},
    {name: 'Five Star Transport', selected: false, group: 'Devisions', all: false},
    {name: 'All divisions', selected: false, group: 'Devisions', all: false}
  ];
  
  allEquipedArray: any[] = [
    {name: 'Skirts', selected: false, group: 'Equiped', all: false},
    {name: 'Vents', selected: false, group: 'Equiped', all: false},
    {name: 'E-tracks', selected: false, group: 'Equiped', all: false},
    {name: 'Manifest box', selected: false, group: 'Equiped', all: false},
    {name: 'Tire storage', selected: false, group: 'Equiped', all: false},
    {name: 'Disc Brakes', selected: false, group: 'Equiped', all: false},
    {name: 'Drum Brakes', selected: false, group: 'Equiped', all: false},
    {name: 'All types', selected: false, group: 'Equiped', all: true}
  ];

  allYearsArray: any[] = [
    {name: '2015', selected: false, group: 'Years', all: false},
    {name: '2016', selected: false, group: 'Years', all: false},
    {name: '2017', selected: false, group: 'Years', all: false},
    {name: '2018', selected: false, group: 'Years', all: false},
    {name: '2019', selected: false, group: 'Years', all: false},
    {name: '2020', selected: false, group: 'Years', all: false},
    {name: '2021', selected: false, group: 'Years', all: false},
    {name: '2022', selected: false, group: 'Years', all: false},
    {name: 'All years', selected: false, group: 'Years', all: false},
  ];

  //Interval
  interval: any;

  constructor(private maintenanceService: MaintenanceService,
              public transformService: TransformService,
              private _snackBar: MatSnackBar,
              private spinner: NgxSpinnerService,
              private rulesService: RulesService) { }

  ngOnInit() {
    this.spinner.show('trailer-table');
    this.dateChanged();
    if(this.rulesService.liveUpdate) {
      this.interval = setInterval(() => {
        this.getTableData();
      }, this.rulesService.miliseconds);
    }
  }

  ngOnChanges(): void {
    if(this.date) {
      this.getTableData()
    }
  }

  dateChanged() {
    this.subscription1 = this.maintenanceService.dateChanged.subscribe((response: any) => {
      this.date.startDate = response.startDate;
      this.date.endDate = response.endDate;
      this.sortedData = undefined;
      this.spinner.show('trailer-table');
      this.error = false;
      this.loaded = false;
      this.getTableData()
    })
  }

  getTableData() {
    this.subscription2 = this.maintenanceService.getTrailersMaintenanceTableData(this.date.startDate, this.date.endDate, this.transformService.filterParams)
    .pipe(catchError((err: any) => {
      this.spinner.hide('trailer-table');
      this.loaded = true;
      this.error = true;
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      this.reset();
      this.listByDays = response.list;
      this.dataSource = JSON.parse(JSON.stringify(response.total));
      this.dataTypingArray = response.total;
      let dataForTable: any[] = [];
      for(let key in response.total) {
        if(response.total[key].is_active === 'Active' ) {
          dataForTable.push(response.total[key]);
        }
      }
      this.sortedData = dataForTable;
      this.getTotal(dataForTable);
      this.spinner.hide('trailer-table');
      this.loaded = true;
    }
    );
  }

  select(obj: any, key: string, index: number, title: string, oneRowColumn?: any) {
    if(!this.compare) {
      this.selectedRow !== index && this.selectedRow !== undefined ? this.reset() : this.selectedRow = index;
      this.clickedColumn = key;
      this.oneRowClickedColumns[oneRowColumn] = !this.oneRowClickedColumns[oneRowColumn];
      let array: any[] = [];
      this.selectedRow = index;
      for(let key in this.listByDays) {
        let unit_no = this.listByDays[key].unit_no;
        let splitedName = unit_no.split('/')[0].trim();
        if(obj.unit_no == splitedName) {
          array.push(this.listByDays[key]);
        }
      }

      let objForGraph = {key: this.clickedColumn, data: array, title: title, truckName: obj.unit_no, reset: false}
      this.maintenanceService.trailerPartsLineChart.next(objForGraph);
    } else {

      if(this.selectedIndexArray.length === 0) {
        this.clickedColumn = key;
      }

      if(this.clickedColumn === key) {

        let res = this.selectedIndexArray.findIndex((num: number) => num === index);
    
        if(res === -1) {
          this.selectedIndexArray.push(index);
        } else {
          this.selectedIndexArray.splice(res, 1);
        }
        let array: any[] = [];

        for(let key in this.listByDays) {
          let unit_no = this.listByDays[key].unit_no;
          let splitedName = unit_no.split('/')[0].trim();
          if(obj.unit_no == splitedName) {
            array.push(this.listByDays[key]);
          }
        }

        let objForGraph = {key: this.clickedColumn, data: array, title: title, truckName: obj.unit_no, reset: false}
        this.maintenanceService.trailerPartsLineChart.next(objForGraph);
      }
    }
  }

  setClass(index: number, clickedColumn: string, oneRowIndex?: any) {
    let className: string = '';

    if(!this.compare && this.oneRowClickedColumns[oneRowIndex] && this.selectedRow === index) {
      return className = 'active';
    }
    
    if(this.selectedIndexArray.includes(index) && clickedColumn === this.clickedColumn) {
      className = 'active';
    }
    
    return className;
  } 

  sortData(sort: Sort) {
    const data = this.selectedUsers.length > 0 ? this.selectedUsers.slice() : this.dataSource.slice();
    
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }
    this.sortedData = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'unit_no':
          return compare(a.unit_no, b.unit_no, isAsc);
        case 'assigned':
          return compare(a.assigned, b.assigned, isAsc);
        case 'status':
          return compare(a.status, b.status, isAsc);
        case 'year':
          return compare(a.year, b.year, isAsc);
        case 'maintenance_cost_per_mile':
          return compare(a.maintenance_cost_per_mile, b.maintenance_cost_per_mile, isAsc);
        case 'maintenacne_cost':
          return compare(a.maintenacne_cost, b.maintenacne_cost, isAsc);
        case 'location':
          return compare(a.location, b.location, isAsc);
        default:
          return 0;
      }
    });
  }

  //Search

  toggleSelection(user: any) {
    user.selected = !user.selected;
    if (user.selected) {
      this.reset();
      this.selectedUsers.push(user);
      this.sortedData =  JSON.parse(JSON.stringify(this.selectedUsers));
    } else {
      const i = this.selectedUsers.findIndex(value => value.unit_no === user.unit_no);
      this.selectedUsers.splice(i, 1);
      this.sortedData =  JSON.parse(JSON.stringify(this.selectedUsers));
      this.reset();
    }
    this.sortedData.length === 0 ? this.sortedData = JSON.parse(JSON.stringify(this.dataSource)) : this.sortedData
  }

  onChange() {
    this.compare = !this.compare;
    this.reset();
  }

  getTotal(data: any[]) {
    let maintenace_cpm: number = 0;
    let maintenance_cost: number = 0;
    let numOfTrailers: number = data.length;

    for(let key in data) {
      maintenace_cpm += data[key].maintenance_cost_per_mile;
      maintenance_cost += data[key].maintenacne_cost;
    }

    this.totalObj.numOfTrailers = numOfTrailers
    this.totalObj.maintenace_cpm = this.transformService.addCommasDots(maintenace_cpm);
    this.totalObj.maintenance_cost = this.transformService.addCommasDots(maintenance_cost, 'round');

    this.avgObj.maintenace_cpm = this.transformService.addCommasDots(maintenace_cpm / numOfTrailers);
    this.avgObj.maintenance_cost = this.transformService.addCommasDots(maintenance_cost / numOfTrailers, 'round');
  }

  exportToExcel() {
    let element = document.getElementById('trailers-table'); 
    const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);

    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    XLSX.writeFile(wb, this.fileName);
  };

  reset() {
    let objForGraph = {key: undefined, data: undefined, title: undefined, truckName: undefined, reset: true};
    this.selectedIndexArray = [];
    this.oneRowClickedColumns = [false, false, false, false, false, false, false, false, false, false];
    this.graphData = [];
    this.clickedColumn = undefined;
    this.maintenanceService.trailerPartsLineChart.next(objForGraph);
  }

  resetCheckedValue() {
    let data: any = JSON.parse(JSON.stringify(this.dataSource));
    this.sortedData = data;
    this.dataTypingArray = data;
    this.searchText = '';
    this.selectedUsers = [];
  }

  //Scroll
  public scrollRight(): void {
    this.scrollContainerDriversTable.nativeElement.scrollTo({ left: (this.scrollContainerDriversTable.nativeElement.scrollLeft + 140), behavior: 'smooth'});
  }

  public scrollLeft(): void {
    this.scrollContainerDriversTable.nativeElement.scrollTo({ left: (this.scrollContainerDriversTable.nativeElement.scrollLeft - 140), behavior: 'smooth' });
  }

  scrollBottomCheck(e: any) {
    if(e.target.scrollHeight < e.target.scrollTop + e.target.offsetHeight + 1) {
      this._snackBar.open('Bottom of table', void 0, {duration: 500});
    }
  }

  //Get tooltip text
  getTooltipText(element: any) {
    return `Driver: ${element.driver}

    Dispatcher: ${element.dispatcher}`;
}

  //Filter data
  checkBoxChanged(obj: any, array: any[]) {
    let total: string[] = ['All trailers', 'All brands', 'All divisions', 'All types', 'All years'];
    if(total.includes(obj.name)) {
      let complete: boolean = array[array.length-1].selected = !array[array.length-1].selected; 
      array.forEach(t => {(t.selected = complete)});
    } else {
      obj.selected = !obj.selected;
    }
  }

  filterData() {
    let filtersArray: any[] = this.activeArray.concat(this.allBrandsArray).concat(this.allDevisionsArray)
    .concat(this.allEquipedArray).concat(this.allYearsArray);
    this.sortedData = this.transformService.filterTable2(this.dataSource, filtersArray);
    this.dataTypingArray = this.transformService.filterTable2(this.dataSource, filtersArray);
    this.getTotal(this.transformService.filterTable2(this.dataSource, filtersArray))
  }

  resetFilters() {
    this.activeFormControl = new FormControl('');
    this.activeArray = [
      {name: 'Active', selected: false, group: 'Trailers', all: false},
      {name: 'Inactive', selected: false, group: 'Trailers', all: false},
      {name: 'Rented', selected: false, group: 'Trailers', all: false},
      {name: 'All trailers', selected: false, group: 'Trailers', all: true}
    ];
  
    this.allBrandsArray = [
      {name: 'Great Dane', selected: false, group: 'Mark', all: false},
      {name: 'Utility', selected: false, group: 'Mark', all: false},
      {name: 'Wabash', selected: false,  group: 'Mark', all: false},
      {name: 'Hyndai', selected: false,  group: 'Mark', all: false},
      {name: 'All brands', selected: false,  group: 'Mark', all: true},
    ];
  
    this.allDevisionsArray = [
      {name: 'JDM Expedite INC', selected: false, group: 'Devisions'},
      {name: 'Tempo Freight Systems', selected: false, group: 'Devisions'},
      {name: 'Five Star Transport', selected: false, group: 'Devisions'},
      {name: 'All divisions', selected: false, group: 'Devisions'}
    ];
  
    this.allEquipedArray = [
      {name: 'Skirts', selected: false, group: 'Equiped', all: false},
      {name: 'Vents', selected: false, group: 'Equiped', all: false},
      {name: 'E-tracks', selected: false, group: 'Equiped', all: false},
      {name: 'Manifest box', selected: false, group: 'Equiped', all: false},
      {name: 'Tire storage', selected: false, group: 'Equiped', all: false},
      {name: 'Disc Brakes', selected: false, group: 'Equiped', all: false},
      {name: 'Drum Brakes', selected: false, group: 'Equiped', all: false},
      {name: 'All types', selected: false, group: 'Equiped', all: true}
    ];
  
    this.allYearsArray = [
      {name: '2015', selected: false, group: 'Years', all: false},
      {name: '2016', selected: false, group: 'Years', all: false},
      {name: '2017', selected: false, group: 'Years', all: false},
      {name: '2018', selected: false, group: 'Years', all: false},
      {name: '2019', selected: false, group: 'Years', all: false},
      {name: '2020', selected: false, group: 'Years', all: false},
      {name: '2021', selected: false, group: 'Years', all: false},
      {name: '2022', selected: false, group: 'Years', all: false},
      {name: 'All years', selected: false, group: 'Years', all: false},
    ];

  };

  ngOnDestroy(): void {
    clearInterval(this.interval);
    this.subscription1?.unsubscribe();
    this.subscription2?.unsubscribe();
  }

}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
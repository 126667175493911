import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SharedService } from '@app/modules/shared/services/shared.service';
import { TransformService } from '@app/modules/shared/services/transform.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription, catchError, throwError } from 'rxjs';
import { ITruckAssignedTrucks } from '../../models/trucks-assigments.model';
import { AssignTrucksDialogComponent } from './assign-trucks-dialog/assign-trucks-dialog.component';
import { AccountingService } from '../../services/accounting.service';
import { RulesService } from '@app/modules/shared/services/rules.service';
import { MsgForbbidenAccessComponent } from '@app/modules/shared/components/msg-forbbiden-access/msg-forbbiden-access.component';

@Component({
  selector: 'app-dashboard-liabilities-page',
  templateUrl: './dashboard-liabilities-page.component.html',
  styleUrls: ['./dashboard-liabilities-page.component.scss']
})
export class DashboardLiabilitiesPageComponent implements OnInit, OnDestroy {
  permissions: any = this.rulesService.UserData[35].data;

  //Data
  dataObj: any = {
    dataSource: [],
    employeesArray: [],
    accountingNamesById: {0: 'Not Assigned'}
  }

  loaded: boolean = false;
  error: boolean = false;
  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";

  spinnerBgColor: string = this.transformService.spinnerBgColor;
  spinnerType: string = this.transformService.spinnerType;

  //Subscription
  subscription1: Subscription = new Subscription();
  subscription2: Subscription = new Subscription();

  constructor(private dialog: MatDialog,
              private transformService: TransformService,
              private accountingService: AccountingService,
              private spinner: NgxSpinnerService,
              private sharedService: SharedService,
              private rulesService: RulesService) { }

  ngOnInit(): void {
    this.getTableData();
    this.getAccountingEmployees();
  };

  assignMultipleTrucks() {
    if(this.permissions[0].sectionArray[0].allowed) {
      let dialogRef = this.dialog.open(AssignTrucksDialogComponent, {
        autoFocus: false,
        panelClass: 'assign-trucks-dialog-container',
        data: this.dataObj
      });
      dialogRef.afterClosed().subscribe((result: any) => {
        if(result) {
          this.getTableData();
        }
      });
    }
    else {
      this.dialog.open(MsgForbbidenAccessComponent, {
        autoFocus: false,
        panelClass: 'forbidden-msg-dialog-container'
      })
    }
  };

  getTableData() {
    this.dataObj.dataSource = [];
    this.error = false;
    this.loaded = false;
    this.spinner.show('liabilities-page');
    this.subscription1 = this.accountingService.getAssignedTrucks()
    .pipe(catchError((err: any) => {
      this.spinner.hide('liabilities-page');
      this.loaded = true;
      this.error = true;
      return throwError(() => err);
    }))
    .subscribe((response: ITruckAssignedTrucks[]) => {
      this.dataObj.dataSource = response;
      this.spinner.hide('liabilities-page');
      this.loaded = true;
      console.log(response);
    });
  };

  getAccountingEmployees() {
    this.subscription2 = this.sharedService.getAllEmployees().subscribe((response: any) => {
      for(let i = 0; i < response.length; i++) {
        if(response[i].department.toLowerCase().includes('accounting')) {
          this.dataObj.employeesArray.push(response[i]);
          this.dataObj.accountingNamesById[response[i].id] = `${response[i].name} ${response[i].nickname}`;
        }
      }
    });
  };

  ngOnDestroy(): void {
    this.subscription1?.unsubscribe();
    this.subscription2?.unsubscribe();
  };

}


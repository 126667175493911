export interface Inspection {
    id: number;
    driver_name: string;
    driver_id: number;
    dispatcher: string;
    dispatcher_id: number;
    truck_no: string;
    trailer_no: string;
    division: string;
    division_id: number;
    report_no: string;
    inspection_level: string;
    state: string;
    city: string;
    zip_code?: string;
    date?: string;
    note: string;
    inspection_result: string;
    out_of_service: boolean;
    points: number;
    amount: number;
    rewards: number;
    creator: string;
    datecreated?: string;
    editor: string;
    edited_date?: string;
    violation: DotViolation[];
    related: DotRelated[];
    files: NoteFile[];
    status: string,
    original: boolean,
    sent: boolean
  }
  
  export interface DotViolation {
    id: number;
    dot_id: number;
    violation: string;
    creator: string;
    created_date?: string;
    edotor: string;
    edited_date?: string;
  }
  
  export interface DotRelated {
    id: number;
    dot_id: number;
    related: string;
    creator: string;
    created_date: string | null;
    edotor: string;
    edited_date: string | null;
  }
  
  export interface NoteFile {
    driver_id: number;
    status_id: number;
    filename: string;
    ext: string;
    data: Uint8Array;
    created: Date;
  }
  
  export interface InspectionOverview {
    all: InspectionOverviewItem;
    passed: InspectionOverviewItem;
    failed: InspectionOverviewItem;
  }
  
  export interface InspectionOverviewItem {
    value: number;
    percentage: number;
    levels: LevelOverview[];
  }
  
  export interface LevelOverview {
    level: string;
    value: number;
    percentage: number;
  }
  
  export const Status = [
    { name: 'OOS', value: true },
    { name: 'Driving', value: false }
  ];
  
  export enum Outcome {
    PASSED = 'PASSED',
    FAILED = 'FAILED'
  }
  
  export enum Level {
    Level1 = 'Level I',
    Level2 = 'Level II',
    Level3 = 'Level III'
  }
  
  export enum Related {
    UnsafeDriving = 'Unsafe driving',
    NonCSA = 'Non-CSA',
    CrashIndicator = 'Crash Indicator',
    HoursOfServiceCompliance = 'Hours of Service compliance',
    VehicleMaintenance = 'Vehicle maintenance',
    ControlledSubstanceAndAlcohol = 'Controlled substance and alcohol',
    HazardousMaterialsCompliance = 'Hazardous materials compliance',
    DriverFitness = 'Driver fitness'
  }
  
import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Sort } from '@angular/material/sort';
import { RulesService } from '@app/modules/shared/services/rules.service';
import { TransformService } from '@app/modules/shared/services/transform.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { catchError, Subscription, throwError } from 'rxjs';
import * as XLSX from 'xlsx'; 
import { MaintenanceService } from '../../services/maintenance.service';
import { SharedService } from '@app/modules/shared/services/shared.service';

@Component({
  selector: 'app-truck-table',
  templateUrl: './truck-table.component.html',
  styleUrls: ['./truck-table.component.scss']
})
export class TruckTableComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('scrollContainerDriversTable', { read: ElementRef }) public scrollContainerDriversTable: any;
  @Input() date: any;
  subscription1: Subscription | any;
  subscription2: Subscription | any;

  fileName: string = 'trucks-table.xlsx';

  compare: boolean = false;

  clickedColumn: string | undefined;
  selectedIndexArray: number[] = [];

  displayedColumns: string[] = ['unit_no', 'staus', 'mileage', 'maintenance_cost_per_mile', 'maintenacne_cost',
  'idling_time', 'gross', 'margin', 'galons_per_mile', 'utilization', 'spentvsgross'];

  dataSource: any[] = [];

  sortedData: any;

  oneRowClickedColumns: boolean[] = [false, false, false, false, false, false, false, false, false, false];

  listByDays: any;

  graphData: any[] = [];

  title: any;

  selectedUsers: any[] = new Array<any>();

  searchText: string = '';

  isOpen: boolean = false;

  selectedRow: any;

  dataTypingArray: any[] = [];

  totalObj: any = {
    numOfTrucks: 0,
    mileage: 0,
    maintenace_cpm: 0,
    maintenance_cost: 0,
    idling: 0,
    gross: 0,
    margin: 0,
    gallons_per_mile: 0,
    utilization: 0,
    spent_vs_gross: 0
  }

  avgObj: any = {
    mileage: 0,
    maintenace_cpm: 0,
    maintenance_cost: 0,
    idling: 0,
    gross: 0,
    margin: 0,
    gallons_per_mile: 0,
    utilization: 0,
    spent_vs_gross: 0
  }

  loaded: boolean = false;

  error: boolean = false;

  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";

  spinnerBgColor: string = this.transformService.spinnerBgColor;
  spinnerType: string = this.transformService.spinnerType;

  activeFormControl = new FormControl('');

  //Filters
  activeArray: any[] = [
    {name: 'Active', selected: false, group: 'Trucks', all: false},
    {name: 'Inactive', selected: false, group: 'Trucks', all: false}
  ];

  allBrandsArray: any[] = [
    {name: 'Freightliner', selected: false, group: 'Mark', all: false},
    {name: 'Peterbilt', selected: false, group: 'Mark', all: false},
    {name: 'Volvo', selected: false,  group: 'Mark', all: false},
    {name: 'All brands', selected: false,  group: 'Mark', all: false}
  ];

  allDevisionsArray: any[] = [
    {name: 'JDM Expedite INC', selected: false, group: 'Company'},
    {name: 'Tempo Freight Systems', selected: false, group: 'Company'},
    {name: 'Five Star Transport', selected: false, group: 'Company'},
    {name: 'All divisions', selected: false, group: 'Company'}
  ];

  allTypesArray: any[] = [
    {name: 'Company', selected: false, group: 'Type', namesArray: ['SOLO COMPANY', 'TEAM COMPANY']},
    {name: 'Rent to run', selected: false, group: 'Type', namesArray: ['SOLO RENT', 'TEAM RENT']},
    {name: 'Owner', selected: false, group: 'Type', namesArray: ['OWNER']},
    {name: 'All types', selected: false, group: 'Type', namesArray: ['SOLO COMPANY', 'TEAM COMPANY', 'SOLO RENT', 'TEAM RENT', 'OWNER', '']}
  ];

  allMileageArray: any[] = [
    {name: '0-99K', selected: false, group: 'Mileage', from: 0, to: 99000},
    {name: '100-149K', selected: false, group: 'Mileage', from: 100000, to: 149000},
    {name: '150-199K', selected: false, group: 'Mileage', from: 150000, to: 199000},
    {name: '200-249K', selected: false, group: 'Mileage', from: 200000, to: 249000},
    {name: '250-300K', selected: false, group: 'Mileage', from: 250000, to: 300000},
    {name: '300-399K', selected: false, group: 'Mileage', from: 300000, to: 399000},
    {name: '400-499K', selected: false, group: 'Mileage', from: 400000, to: 499000},
    {name: '500K +', selected: false, group: 'Mileage', from: 500000, to: 1000000000000000},
    {name: 'Total mileage', selected: false, group: 'Mileage',from: 0, to: 1000000000000000}
  ];

  totalUtilizationArray: any[] = [
    {name: 'Below 50%', selected: false, condition: '50', group: 'Utilization', from: 0, to: 50},
    {name: 'Above 50%', selected: false, condition: '50-70', group: 'Utilization', from: 50, to: 1000000000000000 },
    {name: '50-70%', selected: false, condition: '50-70', group: 'Utilization', from: 50, to: 70},
    {name: '70-100%', selected: false, condition: '70-100', group: 'Utilization', from: 70, to: 100},
    {name: 'Top 10%', selected: false, condition: 'T10', group: 'Utilization', from: 0, to: 10},
    {name: 'Worst 10%', selected: false, condition: 'L10', group: 'Utilization', from: 0, to: 10},
    {name: 'Total Utilization', selected: false, condition: 'all', group: 'Utilization', from: 0, to: 1000000000000000},
  ]

  //Interval
  interval: any;

  constructor(private maintenanceService: MaintenanceService,
              public transformService: TransformService,
              private _snackBar: MatSnackBar,
              private spinner: NgxSpinnerService,
              private rulesService: RulesService) { }

  ngOnInit() {
    this.spinner.show('truck-table');
    this.dateChanged();
    if(this.rulesService.liveUpdate) {
      this.interval = setInterval(() => {
        this.getTableData();
      }, this.rulesService.miliseconds);
    }
  }

  ngOnChanges(): void {
    if(this.date) {
      this.getTableData()
    }
  }

  dateChanged() {
    this.subscription1 = this.maintenanceService.dateChanged.subscribe((response: any) => {
      this.date.startDate = response.startDate;
      this.date.endDate = response.endDate;
      this.sortedData = undefined;
      this.spinner.show('truck-table');
      this.error = false;
      this.loaded = false;
      this.getTableData()
    })
  }

  getTableData() {
    this.subscription2 = this.maintenanceService.getTrucksMaintenanceTableData(this.date.startDate, this.date.endDate, this.transformService.filterParams)
    .pipe(catchError((err: any) => {
      this.spinner.hide('truck-table');
      this.loaded = true;
      this.error = true;
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      this.reset();
      this.listByDays = response.list;
      this.dataSource = JSON.parse(JSON.stringify(response.total));
      this.dataTypingArray = response.total;
      let dataForTable: any[] = [];
      for(let key in response.total) {
        if(response.total[key].staus === 'Active' ) {
          dataForTable.push(response.total[key]);
        }
      }
      this.sortedData = dataForTable;
      this.getTotal(dataForTable);
      this.spinner.hide('truck-table');
      this.loaded = true;
    }
    );
  }

  select(obj: any, key: string, index: number, title: string, oneRowColumn?: any) {
    if(!this.compare) {
      this.selectedRow !== index && this.selectedRow !== undefined ? this.reset() : this.selectedRow = index;
      this.clickedColumn = key;
      this.oneRowClickedColumns[oneRowColumn] = !this.oneRowClickedColumns[oneRowColumn];
      let array: any[] = [];
      this.selectedRow = index;
      for(let key in this.listByDays) {
        let unit_no = this.listByDays[key].unit_no;
        let splitedName = unit_no.split('/')[0].trim();
        if(obj.unit_no == splitedName) {
          array.push(this.listByDays[key]);
        }
      }

      let objForGraph = {key: this.clickedColumn, data: array, title: title, truckName: obj.unit_no, reset: false}
      this.maintenanceService.truckPartsLineChart.next(objForGraph);
    } else {

      if(this.selectedIndexArray.length === 0) {
        this.clickedColumn = key;
      }

      if(this.clickedColumn === key) {

        let res = this.selectedIndexArray.findIndex((num: number) => num === index);
    
        if(res === -1) {
          this.selectedIndexArray.push(index);
        } else {
          this.selectedIndexArray.splice(res, 1);
        }
        let array: any[] = [];

        for(let key in this.listByDays) {
          let unit_no = this.listByDays[key].unit_no;
          let splitedName = unit_no.split('/')[0].trim();
          if(obj.unit_no == splitedName) {
            array.push(this.listByDays[key]);
          }
        }

        let objForGraph = {key: this.clickedColumn, data: array, title: title, truckName: obj.unit_no, reset: false}
        this.maintenanceService.truckPartsLineChart.next(objForGraph);
      }
    }
  }

  setClass(index: number, clickedColumn: string, oneRowIndex?: any) {
    let className: string = '';

    if(!this.compare && this.oneRowClickedColumns[oneRowIndex] && this.selectedRow === index) {
      return className = 'active';
    }
    
    if(this.selectedIndexArray.includes(index) && clickedColumn === this.clickedColumn) {
      className = 'active';
    }
    
    return className;
  } 

  sortData(sort: Sort) {
    const data = this.selectedUsers.length > 0 ? this.selectedUsers.slice() : this.dataSource.slice();
    
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }
    this.sortedData = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'unit_no':
          return compare(a.unit_no, b.unit_no, isAsc);
        case 'staus':
          return compare(a.staus, b.staus, isAsc);
        case 'mileage':
          return compare(a.mileage, b.mileage, isAsc);
        case 'maintenance_cost_per_mile':
          return compare(a.maintenance_cost_per_mile, b.maintenance_cost_per_mile, isAsc);
        case 'maintenacne_cost':
          return compare(a.maintenacne_cost, b.maintenacne_cost, isAsc);
        case 'idling_time':
          return compare(a.idling_time, b.idling_time, isAsc);
        case 'gross':
          return compare(a.gross, b.gross, isAsc);
        case 'margin':
          return compare(a.margin, b.margin, isAsc);
        case 'galons_per_mile':
          return compare(a.galons_per_mile, b.galons_per_mile, isAsc);
        case 'utilization':
          return compare(a.utilization, b.utilization, isAsc);
        case 'spentvsgross':
          return compare(a.spentvsgross, b.spentvsgross, isAsc);
        default:
          return 0;
      }
    });
  }

  //Search

  toggleSelection(user: any) {
    user.selected = !user.selected;
    if (user.selected) {
      this.reset();
      this.selectedUsers.push(user);
      this.sortedData =  JSON.parse(JSON.stringify(this.selectedUsers));
    } else {
      const i = this.selectedUsers.findIndex(value => value.unit_no === user.unit_no);
      this.selectedUsers.splice(i, 1);
      this.sortedData =  JSON.parse(JSON.stringify(this.selectedUsers));
      this.reset();
    }
    this.sortedData.length === 0 ? this.sortedData = JSON.parse(JSON.stringify(this.dataSource)) : this.sortedData
  }

  onChange() {
    this.compare = !this.compare;
    this.reset();
  }

  getTotal(data: any[]) {
    let mileage: number = 0;
    let maintenace_cpm: number = 0;
    let maintenance_cost: number = 0;
    let idling: number = 0;
    let gross: number = 0;
    let margin: number = 0;
    let gallons_per_mile: number = 0;
    let utilization: number = 0;
    let spent_vs_gross: number = 0;

    let numOfTrucks: number = data.length;

    for(let key in data) {
      mileage += data[key].mileage;
      maintenace_cpm += data[key].maintenance_cost_per_mile;
      maintenance_cost += data[key].maintenacne_cost;
      idling += data[key].idling;
      gross += data[key].gross;
      margin += data[key].margin;
      gallons_per_mile += data[key].galons_per_mile;
      utilization += data[key].utilization;
      spent_vs_gross += data[key].spentvsgross
    }

    this.totalObj.numOfTrucks = numOfTrucks
    this.totalObj.mileage = this.transformService.addCommasDots(mileage, 'round');
    this.totalObj.maintenace_cpm = this.transformService.addCommasDots(maintenace_cpm / numOfTrucks);
    this.totalObj.maintenance_cost = this.transformService.addCommasDots(maintenance_cost, 'round');
    this.totalObj.idling = this.transformService.addCommasDots(idling / numOfTrucks, 'round');
    this.totalObj.gross = this.transformService.addCommasDots(gross, 'round');
    this.totalObj.margin = this.transformService.addCommasDots(margin, 'round');
    this.totalObj.gallons_per_mile = this.transformService.addCommasDots(gallons_per_mile / numOfTrucks, 'round');
    this.totalObj.utilization = this.transformService.addCommasDots(utilization, 'round');
    this.totalObj.spent_vs_gross = this.transformService.addCommasDots(spent_vs_gross  / numOfTrucks, 'round');

    this.avgObj.mileage = this.transformService.addCommasDots(mileage / numOfTrucks, 'round');
    this.avgObj.maintenace_cpm = this.transformService.addCommasDots(maintenace_cpm / numOfTrucks);
    this.avgObj.maintenance_cost = this.transformService.addCommasDots(maintenance_cost / numOfTrucks, 'round');
    this.avgObj.idling = this.transformService.addCommasDots(idling / numOfTrucks, 'round');
    this.avgObj.gross = this.transformService.addCommasDots(gross / numOfTrucks, 'round');
    this.avgObj.margin = this.transformService.addCommasDots(margin / numOfTrucks, 'round');
    this.avgObj.gallons_per_mile = this.transformService.addCommasDots(gallons_per_mile / numOfTrucks, 'round');
    this.avgObj.utilization = this.transformService.addCommasDots(utilization / numOfTrucks, 'round');
    this.avgObj.spent_vs_gross = this.transformService.addCommasDots(spent_vs_gross / numOfTrucks, 'round');
    console.log(this.totalObj)
  }

  exportToExcel() {
    let element = document.getElementById('dispatchers-table'); 
    const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);

    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    XLSX.writeFile(wb, this.fileName);
  };

  reset() {
    let objForGraph = {key: undefined, data: undefined, title: undefined, truckName: undefined, reset: true};
    this.selectedIndexArray = [];
    this.oneRowClickedColumns = [false, false, false, false, false, false, false, false, false, false];
    this.graphData = [];
    this.clickedColumn = undefined;
    this.maintenanceService.truckPartsLineChart.next(objForGraph);
  }

  resetCheckedValue() {
    let data: any = JSON.parse(JSON.stringify(this.dataSource));
    this.sortedData = data;
    this.dataTypingArray = data;
    this.searchText = '';
    this.selectedUsers = [];
  }

  //Scroll
  public scrollRight(): void {
    this.scrollContainerDriversTable.nativeElement.scrollTo({ left: (this.scrollContainerDriversTable.nativeElement.scrollLeft + 140), behavior: 'smooth'});
  }

  public scrollLeft(): void {
    this.scrollContainerDriversTable.nativeElement.scrollTo({ left: (this.scrollContainerDriversTable.nativeElement.scrollLeft - 140), behavior: 'smooth' });
  }

  scrollBottomCheck(e: any) {
    if(e.target.scrollHeight < e.target.scrollTop + e.target.offsetHeight + 1) {
      this._snackBar.open('Bottom of table', void 0, {duration: 500});
    }
  }

  //Get tooltip text
  getTooltipText(element: any) {
    return `Driver: ${element.driver}

    Dispatcher: ${element.dispatcher}`;
}

  //Filter data
  checkBoxChanged(obj: any, array: any[]) {
    let total: string[] = ['All brands', 'All divisions', 'All types', 'Total mileage', 'Total Utilization'];
    if(total.includes(obj.name)) {
      let complete: boolean = array[array.length-1].selected = !array[array.length-1].selected; 
      array.forEach(t => {(t.selected = complete)});
    } else {
      obj.selected = !obj.selected;
    }
  }

  filterData() {
    let filtersArray: any[] = this.activeArray.concat(this.allBrandsArray).concat(this.allDevisionsArray)
    .concat(this.allTypesArray).concat(this.allMileageArray).concat(this.totalUtilizationArray);
    this.sortedData = this.transformService.filterTable(this.dataSource, filtersArray);
    this.dataTypingArray = this.transformService.filterTable(this.dataSource, filtersArray);
    this.getTotal(this.transformService.filterTable(this.dataSource, filtersArray))
    console.log(this.allDevisionsArray)
    console.log(this.transformService.filterTable(this.dataSource, filtersArray));
  }

  resetFilters() {
    this.activeFormControl = new FormControl('');
    this.activeArray = [
      {name: 'Active', selected: false, group: 'Trucks', all: false},
      {name: 'Inactive', selected: false, group: 'Trucks', all: false}
    ];
  
    this.allBrandsArray = [
      {name: 'Freightliner', selected: false, group: 'Mark', all: false},
      {name: 'Peterbilt', selected: false, group: 'Mark', all: false},
      {name: 'Volvo', selected: false,  group: 'Mark', all: false},
      {name: 'All brands', selected: false,  group: 'Mark', all: false}
    ];
  
    this.allDevisionsArray = [
      {name: 'JDM Expedite INC', selected: false, group: 'Company'},
      {name: 'Tempo Freight Systems', selected: false, group: 'Company'},
      {name: 'Five Star Transport', selected: false, group: 'Company'},
      {name: 'All divisions', selected: false, group: 'Company'}
    ];
  
    this.allTypesArray = [
      {name: 'Company', selected: false, group: 'Type', namesArray: ['SOLO COMPANY', 'TEAM COMPANY']},
      {name: 'Rent to run', selected: false, group: 'Type', namesArray: ['SOLO RENT', 'TEAM RENT']},
      {name: 'Owner', selected: false, group: 'Type', namesArray: ['OWNER']},
      {name: 'All types', selected: false, group: 'Type', namesArray: ['SOLO COMPANY', 'TEAM COMPANY', 'SOLO RENT', 'TEAM RENT', 'OWNER', '']}
    ];
  
    this.allMileageArray = [
      {name: '0-99K', selected: false, group: 'Mileage', from: 0, to: 99000},
      {name: '100-149K', selected: false, group: 'Mileage', from: 100000, to: 149000},
      {name: '150-199K', selected: false, group: 'Mileage', from: 150000, to: 199000},
      {name: '200-249K', selected: false, group: 'Mileage', from: 200000, to: 249000},
      {name: '250-300K', selected: false, group: 'Mileage', from: 250000, to: 300000},
      {name: '300-399K', selected: false, group: 'Mileage', from: 300000, to: 399000},
      {name: '400-499K', selected: false, group: 'Mileage', from: 400000, to: 499000},
      {name: '500K +', selected: false, group: 'Mileage', from: 500000, to: 1000000000000000},
      {name: 'Total mileage', selected: false, group: 'Mileage',from: 0, to: 1000000000000000}
    ];
  
    this.totalUtilizationArray = [
      {name: 'Below 50%', selected: false, condition: '50', group: 'Utilization', from: 0, to: 50},
      {name: 'Above 50%', selected: false, condition: '50-70', group: 'Utilization', from: 50, to: 1000000000000000 },
      {name: '50-70%', selected: false, condition: '50-70', group: 'Utilization', from: 50, to: 70},
      {name: '70-100%', selected: false, condition: '70-100', group: 'Utilization', from: 70, to: 100},
      {name: 'Top 10%', selected: false, condition: 'T10', group: 'Utilization', from: 0, to: 10},
      {name: 'Worst 10%', selected: false, condition: 'L10', group: 'Utilization', from: 0, to: 10},
      {name: 'Total Utilization', selected: false, condition: 'all', group: 'Utilization', from: 0, to: 1000000000000000},
    ]
  };

  ngOnDestroy(): void {
    clearInterval(this.interval);
    this.subscription1?.unsubscribe();
    this.subscription2?.unsubscribe();
  }

}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
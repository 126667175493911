import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DriversProfileService } from '@app/modules/drivers-profile-page/services/drivers-profile.service';
import moment = require('moment');

@Component({
  selector: 'app-additional-info-dialog',
  templateUrl: './additional-info-dialog.component.html',
  styleUrls: ['./additional-info-dialog.component.scss']
})
export class AdditionalInfoDialogComponent {

  //Expiration calendar
  isOpenExpirationCalendar: boolean = false;
  expirationDate: string = '';

  //Files
  fileNames: string[] = [];

  constructor(@Inject(MAT_DIALOG_DATA) public obj: any, 
  private driversProfileService: DriversProfileService, public dialogRef: MatDialogRef<AdditionalInfoDialogComponent>) { }

  //Calendar method
  dateClicked(event: any) {
    this.expirationDate = moment(event._d).format('YYYY-MM-DDT00:00:00');
    this.isOpenExpirationCalendar = !this.isOpenExpirationCalendar;
    this.driversProfileService.changeTwicCardStatus(this.obj.data.id, this.expirationDate)
    .subscribe((response: any) => {
      if(response) {
        this.dialogRef.close(this.expirationDate);
      }
    });
  };
  
  //Add files
  onFileSelected(event: any) {
    let fileNames: string[] = [];  
    let listOfFiles: any = event.target.files;  
    const formData: any = new FormData();  
    for  (let i =  0; i <  listOfFiles.length; i++)  {  
      formData.append("file[]", listOfFiles[i], listOfFiles[i].name);
      fileNames.push(listOfFiles[i].name);
    } 
    this.driversProfileService.uploadFiles(this.obj.data.id, formData).subscribe((response: any) => {
      if(response) {
        this.fileNames = fileNames;
      };
    });
  }
  
}

import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';

import { Sort } from '@angular/material/sort';
import { Subscription, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import * as XLSX from 'xlsx';
import { NgxSpinnerService } from 'ngx-spinner';
import { DispatchersPerformancesService } from '../../services/dispatchers-performances.service';
import { SharedService } from '@app/modules/shared/services/shared.service';
import { RulesService } from '@app/modules/shared/services/rules.service';
import { TransformService } from '@app/modules/shared/services/transform.service';
import { TitleCasePipe } from '@angular/common';
import { SortPipe } from '@app/modules/shared/pipes/sort.pipe';
import { MatDialog } from '@angular/material/dialog';
import { MsgForbbidenAccessComponent } from '@app/modules/shared/components/msg-forbbiden-access/msg-forbbiden-access.component';

@Component({
  selector: 'app-dispatch-performance-table',
  templateUrl: './dispatch-performance-table.component.html',
  styleUrls: ['./dispatch-performance-table.component.scss']
})
export class DispatchPerformanceTableComponent implements OnInit, OnChanges, OnDestroy {
  @Input() date: any;
  subscription1: Subscription | any;
  subscription2: Subscription | any;
  subscription3: Subscription | any;
  subscription4: Subscription | any;
  subscription5: Subscription | any;

  compare: boolean = false;

  clickedColumn: string | undefined;
  selectedIndexArray: number[] = [];

  displayedColumns: string[] = ['position', 'name', 'drivers', 'company', 'lease', 'owner', 'gross', 'rate_per_mile', 'mileage', 
  'benefit_mileage_percent', 'benefit_mileage', 'margin_percent', 'margin', 'lengt_per_load', 'gross_per_load', 'avg_weight', 
  'driver_retention'];

  //Excel config
  //Excel config
  xlsxConfig: any[] = [
    {columnName: 'No.', selected: true},
    {columnName: 'Dispatcher', selected: true},
    {columnName: 'Truck', selected: true},
    {columnName: 'Company', selected: true},
    {columnName: 'Lease', selected: true},
    {columnName: 'Owner', selected: true},
    {columnName: 'Gross', selected: true},
    {columnName: 'Rate', selected: true},
    {columnName: 'Mileage', selected: true},
    {columnName: 'Company Benefit Miles %', selected: true},
    {columnName: 'Company Benefit Miles', selected: true},
    {columnName: 'Margin %', selected: true},
    {columnName: 'Margin', selected: true},
    {columnName: 'Length Per Load', selected: true},
    {columnName: 'Gross Per Load', selected: true},
    {columnName: 'Avg Weight', selected: true},
    {columnName: 'Driver Retention', selected: true}
  ];

  dataSourceMainArray: any;
  sortedData: any;

  oneRowClickedColumns: boolean[] = [false, false, false, false, false, false, false, false, false, false];

  listByDays: any;

  graphData: any[] = [];

  title: any;
  //Search
  dataTypingArray: any[] = [];
  activeDispatchers: boolean = true;

  selectedUsers: any[] = new Array<any>();

  searchText: string = '';

  selectedRow: any;


  totalObj: any = {
    drivers: 0,
    company: 0,
    lease: 0,
    owner: 0,
    gross: 0,
    rate_per_mile: 0,
    mileage: 0,
    company_benefit_miles_percent: 0,
    company_benefit_miles: 0,
    margin_percent: 0,
    margin: 0,
    length_per_load: 0,
    gross_per_load: 0,
    avg_weight: 0,
    driver_retention: 0
  }

  className: string = '';

  columnName: string = '';

  topByGrossArray: number[] = [];
  topByRpmArray: number[] = [];
  averageRpm: number = 0;

  //Interval
  interval: any;

  loaded: boolean = false;

  error: boolean = false;

  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";

  spinnerBgColor: string = this.transformService.spinnerBgColor;
  spinnerType: string = this.transformService.spinnerType;

  constructor(private dispatchersPerformancesService: DispatchersPerformancesService,
    public transformService: TransformService,
    private sharedService: SharedService,
    private spinner: NgxSpinnerService,
    private rulesService: RulesService,
    private titleCase: TitleCasePipe,
    private sortPipe: SortPipe,
    private dialog: MatDialog) { }

  ngOnInit() {
    this.spinner.show('st-p-cards');
    this.dateChanged();
    this.getAllDispatchers();
    if(this.rulesService.liveUpdate) {
      this.interval = setInterval(() => {
        this.getTableData();
      }, this.rulesService.miliseconds);
    }
  }

  ngOnChanges(): void {
    if (this.date) {
      this.getTableData();
    }
  }

  getAllDispatchers() {
    this.subscription5 = this.sharedService.getAllDispatchers().subscribe((response: any) => {
      this.dataTypingArray = response;
      console.log(response);
    })
  };

  filterData(array: any[], obj: any, obj1: any, obj2: any) {
    obj.selected = !obj.selected;
    obj1.selected = false;
    obj2.selected = false;
    let filteredData: any[] = [];
    let allDispatchers: any[] = [...this.dataSourceMainArray];

    if(obj.selected) {
      for(let key in allDispatchers) {
        for(let key2 in array) {
          if(array[key2].id === allDispatchers[key].disp_id) {
            filteredData.push(allDispatchers[key]);
          }
        }
      }  
    }
    else {
      filteredData = allDispatchers;
    }
    this.sortedData = filteredData;
    console.log(this.sortedData)
    this.dataTypingArray.forEach(element => {
      element.selected = false;
    });
    this.searchText = '';
    this.selectedUsers = [];
    this.getTotal(this.sortedData);
  }

  dateChangeFilterStatisticsData(array: any) {
    let filteredData: any[] = [];
    let allDispatchers: any[] = [...this.dataSourceMainArray];
    for(let key in allDispatchers) {
      for(let key2 in array) {
        if(array[key2].id === allDispatchers[key].disp_id) {
          filteredData.push(allDispatchers[key]);
        }
      }
    }  
    this.sortedData = filteredData;
  }

  dateChanged() {
    this.subscription1 = this.dispatchersPerformancesService.dateChanged.subscribe((response: any) => {
      this.date.startDate = response.startDate;
      this.date.endDate = response.endDate;
      this.sortedData = undefined;
      this.dataSourceMainArray = undefined;
      this.error = false;
      this.loaded = false;

      this.spinner.show('st-p-cards');
      this.getTableData();
    })
  }

  getTableData() {
    this.subscription2 = this.dispatchersPerformancesService.getDispatchTableData(this.date.startDate, this.date.endDate, this.transformService.filterParams)
    .pipe(catchError((err: any) => {
      this.spinner.hide('st-p-cards');
      this.loaded = true;
      this.error = true;
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      this.reset();
      this.topByGrossArray = [];
      this.topByRpmArray = [];
      this.averageRpm = 0;
      
      this.listByDays = response.list;
      this.dataSourceMainArray =  JSON.parse(JSON.stringify(response.total));
      if(response.total.length > 4) {
        //Top 5 by gross statistics
        let tempGrossArray: any = JSON.parse(JSON.stringify(response.total));
        tempGrossArray.sort((a: any, b: any) => {
          return b.gross - a.gross;
        });
        //Top 5 by rpm statistics
        let tempRpmArray: any = JSON.parse(JSON.stringify(response.total));
        tempRpmArray.sort((a: any, b: any) => {
          return b.rate_per_mile - a.rate_per_mile;
        });

        for(let i = 0; i < response.total.length; i++) {
          if(tempGrossArray[i].gross > 0 && !tempGrossArray[i].supervisor && this.topByGrossArray.length < 5) {
            this.topByGrossArray.unshift(tempGrossArray[i].gross);
          };
          if(tempRpmArray[i].rate_per_mile > 0 && !tempRpmArray[i].supervisor && this.topByRpmArray.length < 5) {
            this.topByRpmArray.unshift(tempRpmArray[i].rate_per_mile);
          }
          if(this.topByGrossArray.length === 5 && this.topByRpmArray.length === 5) {
            break;
          }
        };
      }
      this.sortedData = response.total;
      this.showOnlySelectedDispatchers();
      this.spinner.hide('st-p-cards');
      this.loaded = true;
      console.log(this.sortedData)
    })
  }

  select(obj: any, key: string, index: number, title: string, oneRowColumn?: any) {
    if (!this.compare) {
      this.selectedRow !== index && this.selectedRow !== undefined ? this.reset() : this.selectedRow = index;
      this.clickedColumn = key;
      this.oneRowClickedColumns[oneRowColumn] = !this.oneRowClickedColumns[oneRowColumn];
      let array: any[] = [];
      this.selectedRow = index;

      for (let key in this.listByDays) {
        let name = this.listByDays[key].name;
        if (obj.name === name) {
          array.push(this.listByDays[key]);
        }
      }

      let objForGraph = { key: this.clickedColumn, data: array, title: title, dispatcherName: obj.name, reset: false }
      this.dispatchersPerformancesService.dispatchTableLineChartSubject.next(objForGraph);
    } else {

      if (this.selectedIndexArray.length === 0) {
        this.clickedColumn = key;
      }

      if (this.clickedColumn === key) {

        let res = this.selectedIndexArray.findIndex((num: number) => num === index);

        if (res === -1) {
          this.selectedIndexArray.push(index);
        } else {
          this.selectedIndexArray.splice(res, 1);
        }
        let array: any[] = [];

        for (let key in this.listByDays) {
          let name = this.listByDays[key].name;
          if (obj.name === name) {
            array.push(this.listByDays[key]);
          }
        }

        let objForGraph = { key: this.clickedColumn, data: array, title: title, dispatcherName: obj.name, reset: false }
        this.dispatchersPerformancesService.dispatchTableLineChartSubject.next(objForGraph);
      }
    }
  }

  setClass(index: number, clickedColumn: string, oneRowIndex?: any) {
    let className: string = '';

    if (!this.compare && this.oneRowClickedColumns[oneRowIndex] && this.selectedRow === index) {
      return className = 'active';
    }

    if (this.selectedIndexArray.includes(index) && clickedColumn === this.clickedColumn) {
      className = 'active';
    }

    return className;
  }

  sortData(sort: Sort) {
    const data = JSON.parse(JSON.stringify(this.sortedData));
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }
    this.sortedData = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      if(!a.supervisor && !b.supervisor) {
        switch (sort.active) {
          case 'position':
            return compare(a.position, b.position, isAsc);
          case 'name':
            return compare(a.name, b.name, isAsc);
          case 'drivers':
            return compare(a.drivers, b.drivers, isAsc);
          case 'company':
            return compare(a.company, b.company, isAsc);
          case 'lease':
            return compare(a.lease, b.lease, isAsc);
          case 'owner':
            return compare(a.owner, b.owner, isAsc);
          case 'gross':
            return compare(a.gross, b.gross, isAsc);
          case 'rate_per_mile':
            return compare(a.rate_per_mile, b.rate_per_mile, isAsc);
          case 'mileage':
            return compare(a.mileage, b.mileage, isAsc);
          case 'benefit_mileage_percent':
            return compare(a.benefit_mileage_percent, b.benefit_mileage_percent, isAsc);
          case 'benefit_mileage':
            return compare(a.benefit_mileage, b.benefit_mileage, isAsc);
          case 'margin_percent':
            return compare(a.margin_percent, b.margin_percent, isAsc);
          case 'margin':
            return compare(a.margin, b.margin, isAsc);
          case 'lengt_per_load':
            return compare(a.lengt_per_load, b.lengt_per_load, isAsc);
          case 'gross_per_load':
            return compare(a.gross_per_load, b.gross_per_load, isAsc);
          case 'avg_weight':
            return compare(a.avg_weight, b.avg_weight, isAsc);
          case 'driver_retention':
            return compare(a.driver_retention, b.driver_retention, isAsc);
          default:
            return 0;
        }
      }
    });
  }

  //Search

  toggleSelection(user: any) {
    user.selected = !user.selected;
    var index = this.selectedUsers.indexOf(user.name);

    if (index === -1) {
      this.selectedUsers.push(user.name);
    } else {
      this.selectedUsers.splice(index, 1);
    }
    this.showOnlySelectedDispatchers();
  }

  showOnlySelectedDispatchers() {
    this.sortedData = [...this.dataSourceMainArray].filter( el => this.selectedUsers.includes(el.name));
    this.sortedData.length === 0 ? this.sortedData = JSON.parse(JSON.stringify(this.dataSourceMainArray)) : this.sortedData;
    this.reset();
    this.getTotal(this.sortedData);
  }

  onChange(isTrue: boolean) {
    this.compare = isTrue;
    this.reset();
  }

  getTotal(data: any[]) {
    let drivers: number = 0;
    let company: number = 0;
    let lease: number = 0;
    let owner: number = 0;
    let gross: number = 0;
    let rate_per_mile: number = 0;
    let mileage: number = 0;
    let company_benefit_miles_percent: number = 0;
    let company_benefit_miles: number = 0;
    let margin_percent: number = 0;
    let margin: number = 0;
    let length_per_load: number = 0;
    let gross_per_load: number = 0;
    let avg_weight: number = 0;
    let driver_retention: number = 0;
    let driver_gross: number = 0;
    let weight: number = 0;

    let loads: number = 0;
    let lease_amount: number = 0;

    let numOfDrivers: number = data.length;

    for (let key in data) {
      drivers += data[key].drivers;
      company += data[key].company;
      lease += data[key].lease;
      owner += data[key].owner;
      gross += data[key].gross;
      rate_per_mile += data[key].rate_per_mile;
      this.averageRpm = gross / mileage;
      mileage += data[key].mileage;
      company_benefit_miles_percent += data[key].benefit_mileage_percent;
      company_benefit_miles += data[key].benefit_mileage;
      margin_percent += data[key].margin_percent;
      margin += data[key].margin;
      length_per_load += data[key].lengt_per_load;
      gross_per_load += data[key].gross_per_load;
      loads += data[key].loads;
      avg_weight += data[key].avg_weight;
      driver_retention += data[key].driver_retention;
      lease_amount += data[key].lease_amount;
      driver_gross += data[key].driver_gross;
      weight += data[key].weight;
    }

    this.totalObj.drivers = this.addOneDecimalPlace(drivers);
    this.totalObj.company = this.addOneDecimalPlace(company);
    this.totalObj.lease = this.transformService.addCommasDots(lease, 'round');
    this.totalObj.owner = this.transformService.addCommasDots(owner, 'round');
    this.totalObj.gross = this.transformService.addCommasDots(gross, 'round');
    this.totalObj.rate_per_mile = this.transformService.addCommasDots(gross / mileage);
    this.totalObj.mileage = this.transformService.addCommasDots(mileage, 'round');
    this.totalObj.company_benefit_miles_percent = this.transformService.addCommasDots((company_benefit_miles / mileage) * 100, 'round');
    this.totalObj.company_benefit_miles = this.transformService.addCommasDots(company_benefit_miles, 'round');
    this.totalObj.margin_percent = this.transformService.addCommasDots(((gross - driver_gross) / lease_amount) * 100, 'round');
    this.totalObj.margin = this.transformService.addCommasDots(gross - driver_gross, 'round');
    this.totalObj.length_per_load = this.transformService.addCommasDots(mileage / loads, 'round');
    this.totalObj.gross_per_load = this.transformService.addCommasDots(gross / loads, 'round');
    this.totalObj.avg_weight = this.transformService.addCommasDots(weight / loads, 'round');
    this.totalObj.driver_retention = this.transformService.addCommasDots(driver_retention / numOfDrivers, 'round');
    this.totalObj.loads = this.transformService.addCommasDots(loads, 'round');
  }

  addOneDecimalPlace(num: number) {
    let number = Number(num);
    if (number === Math.floor(number)) {
       return num;
    } else {
       return num.toFixed(1);
    }
  }

  exportToExcel(columnsConfig: any[]) {
    if(this.rulesService.UserData[56].data[0].sectionArray[8].allowed) {
      const tableData: any[] = this.sortPipe.transform(this.sortedData);
      const excelTable: any[] = [];
      for(let i = 0; i < tableData.length; i++) {
        let obj: any = {};
        this.transformService.selectedColumn(obj, columnsConfig[0].columnName, i + 1, columnsConfig[0].selected);
        this.transformService.selectedColumn(obj, columnsConfig[1].columnName, this.titleCase.transform(tableData[i].name), columnsConfig[1].selected);
        this.transformService.selectedColumn(obj, columnsConfig[2].columnName, this.addOneDecimalPlace(tableData[i].drivers), columnsConfig[2].selected);
        this.transformService.selectedColumn(obj, columnsConfig[3].columnName, this.addOneDecimalPlace(tableData[i].company), columnsConfig[3].selected);
        this.transformService.selectedColumn(obj, columnsConfig[4].columnName, this.transformService.addCommasDots(tableData[i].lease), columnsConfig[4].selected);
        this.transformService.selectedColumn(obj, columnsConfig[5].columnName, this.transformService.addCommasDots(tableData[i].owner), columnsConfig[5].selected);
        this.transformService.selectedColumn(obj, columnsConfig[6].columnName, `$${this.transformService.addCommasDots(tableData[i].gross, 'round')}`, columnsConfig[6].selected);
        this.transformService.selectedColumn(obj, columnsConfig[7].columnName, `$${this.transformService.addCommasDots(tableData[i].rate_per_mile)}`, columnsConfig[7].selected);
        this.transformService.selectedColumn(obj, columnsConfig[8].columnName, `${this.transformService.addCommasDots(tableData[i].mileage, 'round')}`, columnsConfig[8].selected);
        this.transformService.selectedColumn(obj, columnsConfig[9].columnName, `${this.transformService.addCommasDots(tableData[i].benefit_mileage_percent, 'round')}`, columnsConfig[9].selected);
        this.transformService.selectedColumn(obj, columnsConfig[10].columnName, `${this.transformService.addCommasDots(tableData[i].benefit_mileage, 'round')}`, columnsConfig[10].selected);
        this.transformService.selectedColumn(obj, columnsConfig[11].columnName, `${this.transformService.addCommasDots(tableData[i].margin_percent)}%`, columnsConfig[11].selected);
        this.transformService.selectedColumn(obj, columnsConfig[12].columnName, `$${this.transformService.addCommasDots(tableData[i].margin)}`, columnsConfig[12].selected);
        this.transformService.selectedColumn(obj, columnsConfig[13].columnName, `${this.transformService.addCommasDots(tableData[i].lengt_per_load, 'round')}`, columnsConfig[13].selected);
        this.transformService.selectedColumn(obj, columnsConfig[14].columnName, `$${this.transformService.addCommasDots(tableData[i].gross_per_load, 'round')}`, columnsConfig[14].selected);
        this.transformService.selectedColumn(obj, columnsConfig[15].columnName, `${this.transformService.addCommasDots(tableData[i].avg_weight, 'round')} lbs`, columnsConfig[15].selected);
        this.transformService.selectedColumn(obj, columnsConfig[16].columnName, `${this.transformService.addCommasDots(tableData[i].driver_retention)} days`, columnsConfig[16].selected);
        excelTable.push(obj);
      };
      let footerObj: any = {};
      this.transformService.selectedColumn(footerObj, columnsConfig[0].columnName, '', columnsConfig[0].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[1].columnName, '', columnsConfig[1].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[2].columnName, this.totalObj.drivers, columnsConfig[2].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[3].columnName, this.totalObj.company, columnsConfig[3].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[4].columnName, this.totalObj.lease, columnsConfig[4].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[5].columnName,this.totalObj.owner, columnsConfig[5].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[6].columnName, `$${this.totalObj.gross}`, columnsConfig[6].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[7].columnName, `$${this.totalObj.rate_per_mile}`, columnsConfig[7].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[8].columnName, this.totalObj.mileage, columnsConfig[8].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[9].columnName, `${this.totalObj.company_benefit_miles_percent}%`, columnsConfig[9].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[10].columnName, this.totalObj.company_benefit_miles, columnsConfig[10].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[11].columnName, `${this.totalObj.margin_percent}%`, columnsConfig[11].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[12].columnName, `$${this.totalObj.margin}`, columnsConfig[12].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[13].columnName, this.totalObj.length_per_load, columnsConfig[13].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[14].columnName, `$${this.totalObj.gross_per_load}`, columnsConfig[14].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[15].columnName, `${this.totalObj.avg_weight} lbs`, columnsConfig[15].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[16].columnName, `${this.totalObj.driver_retention} days`, columnsConfig[16].selected);
      excelTable.push(footerObj);
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelTable);
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, 'dispatchers.xlsx');
    }
    else {
      this.msgForbbidenAccess();
    }
  };

  msgForbbidenAccess() {
    this.dialog.open(MsgForbbidenAccessComponent, {
      autoFocus: false,
      panelClass: 'forbidden-msg-dialog-container'
    })
  };

  reset() {
    let objForGraph = { key: undefined, data: undefined, title: undefined, dispatcherName: undefined, reset: true };
    this.selectedIndexArray = [];
    this.oneRowClickedColumns = [false, false, false, false, false, false, false, false, false, false];
    this.graphData = [];
    this.clickedColumn = undefined;
    this.dispatchersPerformancesService.dispatchTableLineChartSubject.next(objForGraph);
  }

  toggleDispatchers(isTrue: boolean) {
    this.activeDispatchers = isTrue;
    this.resetCheckedValue();
  };

  resetCheckedValue() {
    this.dataTypingArray.forEach(element => {
      element.selected = false;
    });
    this.sortedData = [...this.dataSourceMainArray];
    this.searchText = '';
    this.selectedUsers = [];
    this.getTotal(this.sortedData);
  }

  //Hover effect
  onMouseOver(columnName: string) {
    this.columnName = columnName;
    this.className = 'hovered-column';
  }

  onMouseOut(columnName: string) {
    this.columnName = columnName;
    this.className = '';
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
    this.subscription1?.unsubscribe();
    this.subscription2?.unsubscribe();
    this.subscription3?.unsubscribe();
    this.subscription4?.unsubscribe();
    this.subscription5?.unsubscribe();
  }

}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

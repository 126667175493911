import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fullAppFilter',
  pure: false
})
export class FullAppFilterPipe implements PipeTransform {

  transform(array: any[], filterValue: string, recruiter: string, typeKey: string, selectedDriverIndex: number, metaData: any): any[] {
    if (!array) {
      return [];
    }

    //Obj to return
    let countObj: any = {
      allAplicationsNum: 0,
      notAssignedNum: 0,
      noStatusNum: 0,
      prospectingNum: 0,
      proposalSentNum: 0,
      safetyCheckNum: 0,
      approvedNum: 0,
      scheduledNum: 0,
      notApprovedNum: 0,
      proposalDeclinedNum: 0,
      allAplicationsPercent: 0,
      notAssignedPercent: 0,
      noStatusPercent: 0,
      prospectingPercent: 0,
      proposalSentPercent: 0,
      safetyCheckPercent: 0,
      approvedPercent: 0,
      scheduledPercent: 0,
      notApprovedPercent: 0,
      proposalDeclinedPercent: 0,
      array: [],
      selectedDriverObj: null
    }
 
    let transformedArray: any[] = array.filter((obj: any) => {
      let jsObj: any = JSON.parse(obj.DataJson);
      let condition1: boolean = obj.status.includes(filterValue);
      let condition2: boolean = obj.recruiter === '' && filterValue === 'Not assigned';
      let condition3: boolean = filterValue === 'All applications';
      let condition4: boolean = obj.recruiter === recruiter || recruiter === 'All';
      let condition5: boolean = jsObj.positionGroup[typeKey] || typeKey === 'All';

      countObj.allAplicationsNum++;

      //Count statistics 
      if(obj.recruiter === '') {
        countObj.notAssignedNum++;
      };
      if(obj.status === 'No status') {
        countObj.noStatusNum++;
      };
      if(obj.status === 'Prospecting') {
        countObj.prospectingNum++;
      };
      if(obj.status === 'Proposal sent') {
        countObj.proposalSentNum++;
      };
      if(obj.status === 'Safety check') {
        countObj.safetyCheckNum++;
      }
      if(obj.status === 'Approved') {
        countObj.approvedNum++;
      };
      if(obj.status === 'Scheduled') {
        countObj.scheduledNum++;
      };
      if(obj.status === 'Not approved') {
        countObj.notApprovedNum++;
      };
      if(obj.status === 'Proposal declined') {
        countObj.proposalDeclinedNum++;
      };
      if((condition1 || condition2 || condition3) && condition4 && condition5) { 
        return obj;
      };

    });

    let displayObj: any = {};

    if(transformedArray.length > 0) {
      let selectedObj: any = {...transformedArray[selectedDriverIndex]};
      selectedObj.DataJson = JSON.parse(selectedObj.DataJson);
      displayObj = selectedObj;
    }
  
    //Set statistics data
    countObj.allAplicationsPercent = 100; 
    countObj.notAssignedPercent = (countObj.notAssignedNum * 100) / countObj.allAplicationsNum;
    countObj.noStatusPercent = (countObj.noStatusNum * 100) / countObj.allAplicationsNum;
    countObj.prospectingPercent = (countObj.prospectingNum * 100) / countObj.allAplicationsNum;
    countObj.proposalSentPercent = (countObj.proposalSentNum * 100) / countObj.allAplicationsNum;
    countObj.safetyCheckPercent = (countObj.safetyCheckNum * 100) / countObj.allAplicationsNum;
    countObj.approvedPercent = (countObj.approvedNum * 100) / countObj.allAplicationsNum;
    countObj.scheduledPercent = (countObj.scheduledNum * 100) / countObj.allAplicationsNum;
    countObj.notApprovedPercent = (countObj.notApprovedNum * 100) / countObj.allAplicationsNum;
    countObj.proposalDeclinedPercent = (countObj.proposalDeclinedNum * 100) / countObj.allAplicationsNum;
    countObj.array = transformedArray;
    countObj.selectedDriverObj = displayObj;
    metaData.countObj = countObj;

    return transformedArray;

  }

}
import { SlicePipe } from '@angular/common';
import { Component, Input, OnChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { MsgForbbidenAccessComponent } from '@app/modules/shared/components/msg-forbbiden-access/msg-forbbiden-access.component';
import { RulesService } from '@app/modules/shared/services/rules.service';
import { SharedService } from '@app/modules/shared/services/shared.service';
import { TransformService } from '@app/modules/shared/services/transform.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-empty-trucks-table',
  templateUrl: './empty-trucks-table.component.html',
  styleUrls: ['./empty-trucks-table.component.scss']
})
export class EmptyTrucksTableComponent implements OnChanges {
  @Input() tableData: any;
  btnsArray: any[] = [{ name: 'YARD', selected: false }, { name: 'RECOVERY', selected: false }, { name: 'DEALERSHIP', selected: false }];

  displayedColumns: string[] = ['unit_no', 'status', 'model', 'yeer', 'mileage', 'loss', 'location', 'days_without_driver'];

  lengthArray: number = 5;

  dataSource: any;

  sortedData: any;

  //Excel config
  xlsxConfig: any[] = [
    {columnName: 'TRUCK', selected: true},
    {columnName: 'STATUS', selected: true},
    {columnName: 'MODEL', selected: true},
    {columnName: 'YEAR', selected: true},
    {columnName: 'MILEAGE', selected: true},
    {columnName: 'LOSS', selected: true},
    {columnName: 'LOCATION', selected: true},
    {columnName: 'DAYS WITHOUT DRIVER', selected: true}
  ];

  yardArray: any; 
  recoveryArray: any;
  dealershipArray: any;

  constructor(public transformService: TransformService, 
              private sharedService: SharedService,
              private rulesService: RulesService,
              private dialog: MatDialog,
              private slicePipe: SlicePipe) { }

  ngOnChanges(): void {
    if (this.tableData) {
      this.yardArray = this.tableData.yard_list;
      this.recoveryArray = this.tableData.recovery_list;
      this.dealershipArray = this.tableData.dealership_list;
      this.dataSource = JSON.parse(JSON.stringify(this.tableData.yard_list.concat(this.tableData.recovery_list).concat(this.tableData.dealership_list)));
      this.sortedData = this.tableData.yard_list.concat(this.tableData.recovery_list).concat(this.tableData.dealership_list);
      for(let i = 0; i < this.sortedData.length; i++) {
        this.getTruckLocation(this.sortedData[i]);
      };
    }
  }

  filterData(obj: any) {
    obj.selected = !obj.selected;

    let yard: any[] = [];
    let recovery: any[] = [];
    let dealership: any[] = [];

    for (let key in this.btnsArray) {
      if (this.btnsArray[key].selected && this.btnsArray[key].name === 'YARD') {
        yard = this.yardArray;
      };

      if (this.btnsArray[key].selected && this.btnsArray[key].name === 'RECOVERY') {
        recovery = this.recoveryArray;
      };

      if (this.btnsArray[key].selected && this.btnsArray[key].name === 'DEALERSHIP') {
        dealership = this.dealershipArray;
      };
    }
    this.sortedData = yard.concat(recovery).concat(dealership);

    if (!this.btnsArray[0].selected && !this.btnsArray[1].selected && !this.btnsArray[2].selected) {
      this.sortedData = this.dataSource;
    }
  }

  getTruckLocation(element: any) {
    this.sharedService.getTruckLocation(element.unit_no, -1).subscribe((response: any) => {
      if(response.length > 0) {
        this.sharedService.getCurrentAddress(response[0].latitude, response[0].longitude).subscribe((obj: any) => {
          console.log(obj);
          let response: any = obj.address;  
          element.location = `${response.hasOwnProperty('city') ? response.city : (response.town ? response.town : 'Unknown')}, ${response['ISO3166-2-lvl4']}`;
        })
      }
    })
  };

  seeAll() {
    this.lengthArray = this.dataSource.length;
  };

  seeLess() {
    this.lengthArray = 5;
  };

  sortData(sort: Sort) {
    const data = this.dataSource.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'unit_no':
          return compare(a.unit_no, b.unit_no, isAsc);
        case 'status':
          return compare(a.status, b.status, isAsc);
        case 'model':
          return compare(a.model, b.model, isAsc);
        case 'yeer':
          return compare(a.yeer, b.yeer, isAsc);
        case 'mileage':
          return compare(a.mileage, b.mileage, isAsc);
        case 'loss':
          return compare(a.loss, b.loss, isAsc);
        case 'location':
          return compare(a.location, b.location, isAsc);
        case 'days_without_driver':
          return compare(a.days_without_driver, b.days_without_driver, isAsc);
        default:
          return 0;
      }
    });
  }

  exportToExcel(columnsConfig: any[]) {
    if(this.rulesService.UserData[56].data[0].sectionArray[15].allowed) {
      let tableData: any[] = this.slicePipe.transform(this.sortedData, 0, this.lengthArray);
      const excelTable: any[] = [];
      for(let i = 0; i < tableData.length; i++) {
        let obj: any = {};
        this.transformService.selectedColumn(obj, columnsConfig[0].columnName, tableData[i].unit_no, columnsConfig[0].selected);
        this.transformService.selectedColumn(obj, columnsConfig[1].columnName, tableData[i].status, columnsConfig[1].selected);
        this.transformService.selectedColumn(obj, columnsConfig[2].columnName, tableData[i].model, columnsConfig[2].selected);
        this.transformService.selectedColumn(obj, columnsConfig[3].columnName, tableData[i].yeer, columnsConfig[3].selected);
        this.transformService.selectedColumn(obj, columnsConfig[4].columnName, tableData[i].mileage, columnsConfig[4].selected);
        this.transformService.selectedColumn(obj, columnsConfig[5].columnName, tableData[i].loss, columnsConfig[5].selected);
        this.transformService.selectedColumn(obj, columnsConfig[6].columnName, tableData[i].location, columnsConfig[6].selected);
        this.transformService.selectedColumn(obj, columnsConfig[7].columnName, tableData[i].days_without_driver, columnsConfig[7].selected);
        excelTable.push(obj);
      };
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelTable);
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, 'empty-trucks.xlsx');
    }
    else {
      this.msgForbbidenAccess();
    }
  };

  msgForbbidenAccess() {
    this.dialog.open(MsgForbbidenAccessComponent, {
      autoFocus: false,
      panelClass: 'forbidden-msg-dialog-container'
    })
  };

}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

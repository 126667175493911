import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CreateUpdatePaymentDialogComponent } from './create-update-payment-dialog/create-update-payment-dialog.component';
import { catchError, Subscription, throwError } from 'rxjs';
import { PaymentsService } from '../../services/payments.service';
import moment = require('moment');
import { TransformService } from '@app/modules/shared/services/transform.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { RulesService } from '@app/modules/shared/services/rules.service';
import { MsgForbbidenAccessComponent } from '@app/modules/shared/components/msg-forbbiden-access/msg-forbbiden-access.component';

@Component({
  selector: 'app-dashboard-payments-page',
  templateUrl: './dashboard-payments-page.component.html',
  styleUrls: ['./dashboard-payments-page.component.scss']
})
export class DashboardPaymentsPageComponent implements OnInit, OnDestroy {
  permissions: any = this.rulesService.UserData[37].data;

  //Date 
  dateObj: any = {
    startDate: moment().startOf('month').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD')
  };

  //Type of payments
  typeOfPayments: any[] = [
    {
      name: 'Repair Payments', 
      columns: [
        {columnName: 'Issued by', columnKey: 'IssuedBy', filterNum: 0, width: 'w175', letterTranform: true, prefix: '', formatNumbers: false, numOfDecimals: 0, isValue: true, isDate: false, isCheckbox: false, isFile: false},
        {columnName: 'Driver Name', columnKey: 'DriverName', filterNum: 0, width: 'w192', letterTranform: true, prefix: '', formatNumbers: false, numOfDecimals: 0, isValue: true, isDate: false, isCheckbox: false, isFile: false},
        {columnName: 'Division', columnKey: 'division', filterNum: 0, width: 'w140', letterTranform: true, prefix: '', formatNumbers: false, numOfDecimals: 0, isValue: true, isDate: false, isCheckbox: false, isFile: false},
        {columnName: 'Unit (TRK or TRL)', columnKey: 'unit_no', filterNum: 0, width: 'w125', letterTranform: false, prefix: '', formatNumbers: false, numOfDecimals: 0, isValue: true, isDate: false, isCheckbox: false, isFile: false},
        {columnName: 'Date of Payment', columnKey: 'DateOfPayment', filterNum: 3, width: 'w125', letterTranform: false, prefix: '', formatNumbers: false, numOfDecimals: 0, isValue: true, isDate: true, isCheckbox: false, isFile: false},
        {columnName: 'Payment Method', columnKey: 'PaymentMethod', filterNum: 0, width: 'w125', letterTranform: false, prefix: '', formatNumbers: false, numOfDecimals: 0, isValue: true, isDate: false, isCheckbox: false, isFile: false},
        {columnName: 'Money Code', columnKey: 'MoneyCode', filterNum: 0, width: 'w125', letterTranform: false, prefix: '', formatNumbers: false, numOfDecimals: 0, isValue: true, isDate: false, isCheckbox: false, isFile: false},
        {columnName: 'Amount Issued', columnKey: 'IssuedAmount', filterNum: 1, width: 'w109', letterTranform: false, prefix: '$', formatNumbers: true, numOfDecimals: 2, isValue: true, isDate: false, isCheckbox: false, isFile: false},
        {columnName: 'Payment Amount', columnKey: 'PaymantAmount', filterNum: 1, width: 'w109', letterTranform: false, prefix: '$', formatNumbers: true, numOfDecimals: 2, isValue: true, isDate: false, isCheckbox: false, isFile: false},
        {columnName: 'Payment Issued to', columnKey: 'PaymentIssuedTo', filterNum: 0, width: 'w125', letterTranform: true, prefix: '', formatNumbers: false, numOfDecimals: 0, isValue: true, isDate: false, isCheckbox: false, isFile: false},
        {columnName: 'Type of Repair', columnKey: 'TypeOfRepair', filterNum: 0, width: 'w125', letterTranform: false, prefix: '', formatNumbers: false, numOfDecimals: 0, isValue: true, isDate: false, isCheckbox: false, isFile: false},
        {columnName: 'Reason', columnKey: 'ReasonForMoneyCode', filterNum: 0, width: 'w125', letterTranform: true, prefix: '', formatNumbers: false, numOfDecimals: 0, isValue: true, isDate: false, isCheckbox: false, isFile: false},
        {columnName: 'Invoice Received', columnKey: 'InvoiceReceived', filterNum: 2, width: 'w100', letterTranform: false, prefix: '', formatNumbers: false, numOfDecimals: 0, isValue: false, isDate: false, isCheckbox: true, isFile: false},
        {columnName: 'Invoice Uploaded to PT', columnKey: 'InvoiceUploadedToPT', filterNum: 2, width: 'w125', letterTranform: false, prefix: '', formatNumbers: false, numOfDecimals: 0, isValue: false, isDate: false, isCheckbox: true, isFile: false},
        {columnName: 'Attachment', columnKey: 'fileName', filterNum: 2, width: 'w125', letterTranform: false, prefix: '', formatNumbers: false, numOfDecimals: 0, isValue: false, isDate: false, isCheckbox: false, isFile: true},
        {columnName: 'Reviewed', columnKey: 'reviewed', filterNum: 2, width: 'w100', letterTranform: false, prefix: '', formatNumbers: false, numOfDecimals: 0, isValue: false, isDate: false, isCheckbox: true, isFile: false},
      ],
      type: 0 
    }, 
    {
      name: 'Fuel Payments',
      columns: [
        {columnName: 'Issued by', columnKey: 'IssuedBy', filterNum: 0, width: 'w175', letterTranform: true, prefix: '', formatNumbers: false, numOfDecimals: 0, isValue: true, isDate: false, isCheckbox: false, isFile: false},
        {columnName: 'Driver Name', columnKey: 'DriverName', filterNum: 0, width: 'w192', letterTranform: true, prefix: '', formatNumbers: false, numOfDecimals: 0, isValue: true, isDate: false, isCheckbox: false, isFile: false},
        {columnName: 'Division', columnKey: 'division', filterNum: 0, width: 'w140', letterTranform: true, prefix: '', formatNumbers: false, numOfDecimals: 0, isValue: true, isDate: false, isCheckbox: false, isFile: false},
        {columnName: 'Unit (TRK or TRL)', columnKey: 'unit_no', filterNum: 0, width: 'w125', letterTranform: false, prefix: '', formatNumbers: false, numOfDecimals: 0, isValue: true, isDate: false, isCheckbox: false, isFile: false},
        {columnName: 'Date of Payment', columnKey: 'DateOfPayment', filterNum: 3, width: 'w125', letterTranform: false, prefix: '', formatNumbers: false, numOfDecimals: 0, isValue: true, isDate: true, isCheckbox: false, isFile: false},
        {columnName: 'Issued To', columnKey: 'PaymentIssuedTo', filterNum: 0, width: 'w125', letterTranform: true, prefix: '', formatNumbers: false, numOfDecimals: 0, isValue: true, isDate: false, isCheckbox: false, isFile: false},
        {columnName: 'State', columnKey: 'Stete', filterNum: 0, width: 'w125', letterTranform: false, prefix: '', formatNumbers: false, numOfDecimals: 0, isValue: true, isDate: false, isCheckbox: false, isFile: false},
        {columnName: 'Payment Method', columnKey: 'PaymentMethod', filterNum: 0, width: 'w125', letterTranform: false, prefix: '', formatNumbers: false, numOfDecimals: 0, isValue: true, isDate: false, isCheckbox: false, isFile: false},
        {columnName: 'Money Code', columnKey: 'MoneyCode', filterNum: 0, width: 'w125', letterTranform: false, prefix: '', formatNumbers: false, numOfDecimals: 0, isValue: true, isDate: false, isCheckbox: false, isFile: false},
        {columnName: 'Amount Issued', columnKey: 'IssuedAmount', filterNum: 1, width: 'w109', letterTranform: false, prefix: '$', formatNumbers: true, numOfDecimals: 2, isValue: true, isDate: false, isCheckbox: false, isFile: false},
        {columnName: 'Amount Used', columnKey: 'AmountUsed', filterNum: 1, width: 'w109', letterTranform: false, prefix: '$', formatNumbers: true, numOfDecimals: 2, isValue: true, isDate: false, isCheckbox: false, isFile: false},
        {columnName: 'Gallons', columnKey: 'Quantity', filterNum: 1, width: 'w109', letterTranform: false, prefix: '', formatNumbers: true, numOfDecimals: 0, isValue: true, isDate: false, isCheckbox: false, isFile: false},
        {columnName: 'Reason', columnKey: 'ReasonForMoneyCode', filterNum: 0, width: 'w125', letterTranform: true, prefix: '', formatNumbers: false, numOfDecimals: 0, isValue: true, isDate: false, isCheckbox: false, isFile: false},
        {columnName: 'Receipt Received', columnKey: 'FuelReceiptReceived', filterNum: 2, width: 'w100', letterTranform: false, prefix: '', formatNumbers: false, numOfDecimals: 0, isValue: false, isDate: false, isCheckbox: true, isFile: false},
        {columnName: 'Attachment', columnKey: 'fileName', filterNum: 2, width: 'w125', letterTranform: false, prefix: '', formatNumbers: false, numOfDecimals: 0, isValue: false, isDate: false, isCheckbox: false, isFile: true},
        {columnName: 'Reviewed', columnKey: 'reviewed', filterNum: 2, width: 'w100', letterTranform: false, prefix: '', formatNumbers: false, numOfDecimals: 0, isValue: false, isDate: false, isCheckbox: true, isFile: false},
      ],
      type: 1
    },
    {
      name: 'Miscellaneous Payments',
      columns: [
        {columnName: 'Issued by', columnKey: 'IssuedBy', filterNum: 0, width: 'w175', letterTranform: true, prefix: '', formatNumbers: false, numOfDecimals: 0, isValue: true, isDate: false, isCheckbox: false, isFile: false},
        {columnName: 'Driver Name', columnKey: 'DriverName', filterNum: 0, width: 'w192', letterTranform: true, prefix: '', formatNumbers: false, numOfDecimals: 0, isValue: true, isDate: false, isCheckbox: false, isFile: false},
        {columnName: 'Division', columnKey: 'division', filterNum: 0, width: 'w140', letterTranform: true, prefix: '', formatNumbers: false, numOfDecimals: 0, isValue: true, isDate: false, isCheckbox: false, isFile: false},
        {columnName: 'Unit (TRK or TRL)', columnKey: 'unit_no', filterNum: 0, width: 'w125', letterTranform: false, prefix: '', formatNumbers: false, numOfDecimals: 0, isValue: true, isDate: false, isCheckbox: false, isFile: false},
        {columnName: 'Date of Payment', columnKey: 'DateOfPayment', filterNum: 3, width: 'w125', letterTranform: false, prefix: '', formatNumbers: false, numOfDecimals: 0, isValue: true, isDate: true, isCheckbox: false, isFile: false},
        {columnName: 'Payment Method', columnKey: 'PaymentMethod', filterNum: 0, width: 'w125', letterTranform: false, prefix: '', formatNumbers: false, numOfDecimals: 0, isValue: true, isDate: false, isCheckbox: false, isFile: false},
        {columnName: 'Money Code', columnKey: 'MoneyCode', filterNum: 0, width: 'w125', letterTranform: false, prefix: '', formatNumbers: false, numOfDecimals: 0, isValue: true, isDate: false, isCheckbox: false, isFile: false},
        {columnName: 'Amount Issued', columnKey: 'IssuedAmount', filterNum: 1, width: 'w109', letterTranform: false, prefix: '$', formatNumbers: true, numOfDecimals: 2, isValue: true, isDate: false, isCheckbox: false, isFile: false},
        {columnName: 'Amount Used', columnKey: 'AmountUsed', filterNum: 1, width: 'w109', letterTranform: false, prefix: '$', formatNumbers: true, numOfDecimals: 2, isValue: true, isDate: false, isCheckbox: false, isFile: false},
        {columnName: 'Payment Issued to', columnKey: 'PaymentIssuedTo', filterNum: 0, width: 'w125', letterTranform: true, prefix: '', formatNumbers: false, numOfDecimals: 0, isValue: true, isDate: false, isCheckbox: false, isFile: false},
        {columnName: 'Reason for Payment', columnKey: 'ReasonForPeyment', filterNum: 0, width: 'w125', letterTranform: false, prefix: '', formatNumbers: false, numOfDecimals: 0, isValue: true, isDate: false, isCheckbox: false, isFile: false},
        {columnName: 'Reason', columnKey: 'ReasonForMoneyCode', filterNum: 0, width: 'w125', letterTranform: true, prefix: '', formatNumbers: false, numOfDecimals: 0, isValue: true, isDate: false, isCheckbox: false, isFile: false},
        {columnName: 'Invoice Received', columnKey: 'InvoiceReceived', filterNum: 2, width: 'w100', letterTranform: false, prefix: '', formatNumbers: false, numOfDecimals: 0, isValue: false, isDate: false, isCheckbox: true, isFile: false},
        {columnName: 'Attachment', columnKey: 'fileName', filterNum: 2, width: 'w125', letterTranform: false, prefix: '', formatNumbers: false, numOfDecimals: 0, isValue: false, isDate: false, isCheckbox: false, isFile: true},
        {columnName: 'Reviewed', columnKey: 'reviewed', filterNum: 2, width: 'w100', letterTranform: false, prefix: '', formatNumbers: false, numOfDecimals: 0, isValue: false, isDate: false, isCheckbox: true, isFile: false},
      ],
      type: 2
    }
  ];

  selectedTypeColumns: any[] = [];
  selectedTypeDataSource: any[] = [];
  selectedType: number = 0;

  //Subscription
  subscription: Subscription = new Subscription();

  loaded: boolean = false;
  error: boolean = false;
  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";

  spinnerBgColor: string = this.transformService.spinnerBgColor;
  spinnerType: string = this.transformService.spinnerType;

  constructor(private transformService: TransformService,
              private dialog: MatDialog, 
              private paymentsService: PaymentsService,
              private spinner: NgxSpinnerService,
              private rulesService: RulesService) { };

  ngOnInit(): void {
    this.getPaymentsByType();
  };

  getPaymentsByType() {
    this.selectedTypeColumns = [];
    this.selectedTypeDataSource = [];
    this.error = false;
    this.loaded = false;
    this.spinner.show('payments-table');
    this.subscription = this.paymentsService.getPaymentsByTypeAndDate(this.selectedType, this.dateObj.startDate, this.dateObj.endDate)
    .pipe(catchError((err: any) => {
      this.spinner.hide('payments-table');
      this.loaded = true;
      this.error = true;
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      this.selectedTypeColumns = this.typeOfPayments[this.selectedType].columns;
      this.selectedTypeDataSource = response;
      this.spinner.hide('payments-table');
      this.loaded = true;
      console.log(response);
    });
  };

  run() {
    this.getPaymentsByType();
  };

  createUpdatePaymentDialog() {
    if(this.permissions[0].sectionArray[0].allowed) {
      let dialogRef: any = this.dialog.open(CreateUpdatePaymentDialogComponent, {
        autoFocus: false,
        panelClass: 'component-dialog-container',
        data: {editMode: false, data: {}}
      });
      dialogRef.afterClosed().subscribe((result: any) => {
        if(result) {
          if(result.type === this.selectedType) {
            this.selectedTypeDataSource.push(result);
            this.selectedTypeDataSource = [...this.selectedTypeDataSource];
          }
        }
      })
    }
    else {
      this.dialog.open(MsgForbbidenAccessComponent, {
        autoFocus: false,
        panelClass: 'forbidden-msg-dialog-container'
      })
    }
  };

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  };

}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'day'
})
export class DayPipe implements PipeTransform {

  transform(value: number | string): string {
    return value === 1 ? `${value} day` : `${value} days`;
  }

}

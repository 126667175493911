import { Component, OnInit } from '@angular/core';
import { RulesService } from '@app/modules/shared/services/rules.service';
import { LoadCalculationTimePoint, LoadCalculation, Load } from '../models/load.model';


@Component({
	selector: 'app-estimations',
	templateUrl: './estimations.component.html',
	styleUrls: ['./estimations.component.scss']
  })
  export class EstimationsComponent implements OnInit {
	permissions: any = this.rulesService.UserData[19].data[0].allowedAll;

	selectedTab = 0;
	timeSeries: LoadCalculationTimePoint[];
	loads: LoadCalculation[];

	grouped: Map<string, Load[]>;

	constructor(private rulesService: RulesService) { }

	ngOnInit() {

	}

	openAnalysis() {
		this.selectedTab = 1;
	}

	setAnalysis(timeSeties: LoadCalculationTimePoint[]) {
		this.timeSeries = timeSeties;
	}

	setLoads(loads) {
		this.loads = loads;
	}

	setGrouped(grouped) {
		this.grouped = grouped;
	}
}
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardMaintenancePageComponent } from './components/dashboard-maintenance-page.component';
import { AuthGuard } from '../core/services/auth/auth.guard';


const routes: Routes = [
	{
		path: 'maintenance',
		canActivate: [AuthGuard],
        children: [
           {
             path: 'maintenance-page',
             component: DashboardMaintenancePageComponent
           },
    ],
    data: { roles: 11 }
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class MaintenanceRoutingModule { }
import { ConnectionPositionPair } from '@angular/cdk/overlay';
import { Component, EventEmitter, Input, Output } from '@angular/core';

import moment = require('moment');
import { CalendarDialogComponent } from '../calendar-dialog/calendar-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { TransformService } from '../../services/transform.service';

@Component({
  selector: 'calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent {
  //Calendar
  @Input() dateObj: any = {
    startDate: this.transformService.convertDateToTimestamp(moment().startOf('month').format('ddd, DD/MM YYYY'), 
    '00:00:00'),
    endDate: this.transformService.convertDateToTimestamp(moment().format('ddd, DD/MM YYYY'),  moment().format('HH:mm:ss')),
  };
  @Output() selectedDate = new EventEmitter<any>();
  //Fast option calendar
  isOpenFrom: boolean = false;
  isOpenTo: boolean = false;
  fromDate: Date = new Date(this.dateObj.startDate);
  toDate: Date = new Date(this.dateObj.endDate);

  range: boolean = true;

  public positions = [
    new ConnectionPositionPair(
      {originX: 'end', originY: 'bottom'},
      {overlayX: 'end', overlayY: 'top'})
  ];

  public positions2 = [
    new ConnectionPositionPair(
      {originX: 'start', originY: 'bottom'},
      {overlayX: 'start', overlayY: 'top'})
  ];

    constructor(private transformService: TransformService, private dialog: MatDialog) { }

  //Calendar
  openDialog() {
    let dialogRef: any = this.dialog.open(CalendarDialogComponent, {
      width: '769px',
      height: '476px',
      autoFocus: false,
      data: {oneRange: true},
      panelClass: 'calendar-dialog-container'
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.dateObj.startDate = result.startDate;
        this.dateObj.endDate = result.endDate;
        this.selectedDate.emit(this.dateObj);
      }
    });
  }

  dateClicked(event: any, start: boolean) {
    if(start) {
      this.dateObj.startDate = this.transformService.convertDateToTimestamp(moment(event._d).format('ddd, DD/MM YYYY'), '00:00:00');
      this.fromDate = new Date(this.dateObj.startDate);
      this.isOpenFrom = false;
    }
    else {
      this.dateObj.endDate = this.transformService.convertDateToTimestamp(moment(event._d).format('ddd, DD/MM YYYY'), '23:59:59');
      this.toDate = new Date(this.dateObj.endDate);
      this.isOpenTo = false;
      this.selectedDate.emit(this.dateObj);
    }

    if(!this.range) {
      this.dateObj.startDate = this.transformService.convertDateToTimestamp(moment(event._d).format('ddd, DD/MM YYYY'), '00:00:00');
      this.dateObj.endDate = this.transformService.convertDateToTimestamp(moment(event._d).format('ddd, DD/MM YYYY'), '23:59:59');
      this.selectedDate.emit(this.dateObj);
    }
  }

  changeRange() {
    this.range = !this.range;
  };

}

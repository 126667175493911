import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { RulesService } from '@app/modules/shared/services/rules.service';
import { SharedService } from '@app/modules/shared/services/shared.service';
import { TransformService } from '@app/modules/shared/services/transform.service';
import { TrucksService } from '@app/modules/trucks/services/trucks.service';
import { ApexAxisChartSeries, ApexChart, ApexLegend, ApexResponsive, ApexXAxis, ApexYAxis, ChartComponent } from 'ng-apexcharts';
import { NgxSpinnerService } from 'ngx-spinner';
import { catchError, Subscription, throwError } from 'rxjs';

export type ChartOptions = {
  series: ApexAxisChartSeries | any;
  chart: ApexChart | any;
  xaxis: ApexXAxis | any;
  yaxis: ApexYAxis | any;
  responsive: ApexResponsive | any;
  labels: string | any;
  colors: string | any;
  legend: ApexLegend | any;
  tooltip: any;
};

@Component({
  selector: 'app-truck-cards-pie-chart',
  templateUrl: './truck-cards-pie-chart.component.html',
  styleUrls: ['./truck-cards-pie-chart.component.scss']
})
export class TruckCardsPieChartComponent implements OnInit, OnDestroy {
  permissions: any = this.rulesService.UserData[12].data[0].sectionArray;
  @Input() truckProfileData: any;
  @Input() date: any;
  @Input() location: string = '';

  @ViewChild("chart") chart: ChartComponent | any;
  public chartOptions: Partial<ChartOptions> | any;

  pieData: any;

  netProfit: number = 0;

  show: boolean = false;

  unitNo: string = '';

  selectedCards: any[] = [
    {selected: false}, {selected: false}, {selected: false}, 
    {selected: false}, {selected: false}, {selected: false},
    {selected: false}, {selected: false}
  ];

  netProfitData: any[] = [];

  loaded: boolean = false;

  error: boolean = false;

  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";

  spinnerBgColor: string = this.transformService.spinnerBgColor;
  spinnerType: string = this.transformService.spinnerType;

  subscription1: Subscription | any;
  subscription2: Subscription | any;
  subscription3: Subscription | any;
  subscription4: Subscription | any;

  constructor(private trucksService: TrucksService, 
              public transformService: TransformService,
              private rulesService: RulesService,
              private sharedService: SharedService,
              private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    if(this.date.unitNo) {
      this.getPieChartData();
    }
    this.dateChanged();
    this.netProfitSubject();
    this.netProfitGraphData();
  }

  dateChanged() {
    this.subscription1 = this.trucksService.dateChanged.subscribe((response: any) => {
      this.date.startDate = response.startDate;
      this.date.endDate = response.endDate;
      this.chartOptions = undefined;
      this.pieData = undefined;
      this.error = false;
      this.loaded = false;
      this.getPieChartData();
    })
  }

  netProfitSubject() {
    this.subscription2 = this.trucksService.netProfitSubject.subscribe((response: number) => {
      this.netProfit = response;
    })
  }

  netProfitGraphData() {
    this.subscription3 = this.trucksService.netProfitGraphSubject.subscribe((response: any) => {
      console.log(response);
      this.netProfitData = response;
    })
  }

  getPieChartData() {
    this.spinner.show('truck-pie-chart');
    this.subscription4 = this.trucksService.getChartsData(this.date.unitNo, this.date.startDate, this.date.endDate)
    .pipe(catchError((err: any) => {
      this.spinner.hide('truck-pie-chart');
      this.loaded = true;
      this.error = true;
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      this.pieData = response;
      this.chartOptions = this.pieChart({otr: response.otr, no_driver: response.no_driver, repairs: response.repairs});
      let timelineObj: any = {data: this.pieData.list, reset: false}
      this.trucksService.timelineChartSubject.next(timelineObj);
      this.spinner.hide('truck-pie-chart');
      this.loaded = true;
    });
  }

  pieChart(obj: any) {
    return {
      colors: ['#003fa2', '#8b8b8b', '#ff900c'],
      series: [obj.otr, obj.no_driver, obj.repairs],
      chart: {
        width: 360,
        type: "pie",
        fontFamily: 'Poppins',
        fontSize: '13px'
      },
      labels: ['OTR', 'WITHOUT DRIVER', 'REPAIRS'],
      legend: {
        position: 'bottom'
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: "bottom"
            }
          }
        }
      ],
      yaxis: {
        labels: {
          formatter: (num: number) => {
            typeof num !== 'number' ? num = 0 : num;
            let result = (num - Math.floor(num)) !== 0;
            let number: any;
            if(result) {
              number = Math.round(num);
            } else {
              number = num;
            }
            return number.toLocaleString("en-US").toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          },
        },
      },
    };
  };

  showGraph() {
    this.reset();
    let dataObj: any = {selectedCards: this.selectedCards, data: []};
    this.trucksService.lineChartSubject.next(dataObj);
    let timelineObj: any = {data: this.pieData.list, reset: false}
    this.trucksService.timelineChartSubject.next(timelineObj);
  };

  showLineChart(obj: any) {
    obj.selected = !obj.selected;
    let timelineObj: any = {data: this.pieData.list, reset: true}
    this.trucksService.timelineChartSubject.next(timelineObj);

    let listData: any = JSON.parse(JSON.stringify(this.truckProfileData.list));
    let array: any = listData.concat(this.netProfitData);

    let dataObj: any = {selectedCards: this.selectedCards, data: array};
    this.trucksService.lineChartSubject.next(dataObj);
  };

  reset() {
    this.selectedCards = [
      {selected: false}, {selected: false}, {selected: false}, 
      {selected: false}, {selected: false}, {selected: false},
      {selected: false}, {selected: false}
    ];
  };

  ngOnDestroy(): void {
    this.subscription1?.unsubscribe();
    this.subscription2?.unsubscribe();
    this.subscription3?.unsubscribe();
    this.subscription4?.unsubscribe();
  }

}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DateRange } from '@angular/material/datepicker';
import moment = require('moment');

@Component({
  selector: 'calendar-range-date-select',
  templateUrl: './calendar-range-date-select.component.html',
  styleUrls: ['./calendar-range-date-select.component.scss']
})
export class CalendarRangeDateSelectComponent {
  @Input() config: any = {
    startDate: '',
    endDate: '',
    formatToShow: 'MM/dd/yyyy',
    formatToReturn: 'YYYY-MM-DD'
  };
  @Input() styleConfig: any = {
    'width': '240px',
    'height': '45px',
    'background-color': '#f4f8ff',
    'color': '#1F1F1F',
    'box-shadow': 'none',
    'border-radius': '25px',
    'border-top': 'thin solid #0000001f'
  };
  @Output() selectedRangeValueChange = new EventEmitter<any>();
  selectedRangeValue: any;
  isOpenCalendar: boolean = false;

  dateRangeClicked(m: any) {
    if (!this.selectedRangeValue?.start || this.selectedRangeValue?.end) {
      this.selectedRangeValue = new DateRange<Date>(m, null);
    } 
    else {
      const start = this.selectedRangeValue.start;
      const end = m;
      if (end < start) {
        this.selectedRangeValue = new DateRange<Date>(end, start);
      } 
      else {
        this.selectedRangeValue = new DateRange<Date>(start, end);
      }
      if(this.selectedRangeValue.start && this.selectedRangeValue.end) {
        this.config.startDate = moment(this.selectedRangeValue.start._d).format(this.config.formatToReturn);
        this.config.endDate = moment(this.selectedRangeValue.end._d).format(this.config.formatToReturn);
        this.isOpenCalendar = false;
      }
      this.selectedRangeValueChange.emit({startDate: this.config.startDate, endDate: this.config.endDate});
    }
    
  }

}

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EmployeeService } from '@app/modules/employee/services/employee.service';
import moment = require('moment');
import { catchError, throwError } from 'rxjs';

@Component({
  selector: 'app-employee-note-create-update-dialog',
  templateUrl: './employee-note-create-update-dialog.component.html',
  styleUrls: ['./employee-note-create-update-dialog.component.scss']
})
export class EmployeeNoteCreateUpdateDialogComponent implements OnInit {
  noteTitle: string = '';
  note: string = '';

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<any>,
              private employeeService: EmployeeService) { }

  ngOnInit(): void {
    console.log(this.data);
    if(this.data.editMode) {
      this.noteTitle = this.data.noteObj.title;
      this.note = this.data.noteObj.note;
    }
  }

  addEditNote() {
    let loggedUser: any = JSON.parse(localStorage.getItem('currentUser'));
    let obj: any = JSON.parse(JSON.stringify(this.data.obj));
    obj.edited_date = moment().format('YYYY-MM-DDTHH:mm:ss');
    obj.edited_by = loggedUser.first_name + ' ' + loggedUser.last_name;
    let note : any = {
      id: 0,
      employee_id: obj.id,
      title: this.noteTitle,
      note: this.note,
      note_date: moment().format('YYYY-MM-DDTHH:mm:ss'),
      note_by: loggedUser.first_name + ' ' + loggedUser.last_name,
      edited_by: '',
      edited_date: ''
    }
    if(this.data.editMode) {
      note.id = obj.id;
      note.note_date = this.data.noteObj.note_date;
      note.note_by = this.data.noteObj.note_by;
      note.edited_by = loggedUser.first_name + ' ' + loggedUser.last_name;
      note.edited_date = moment().format('YYYY-MM-DDTHH:mm:ss');
    }
    if(obj.notes.length > 0) {
      obj.notes[this.data.index] = note;
    }
    else {
      obj.notes.push(note);
    }
   
    console.log(JSON.stringify(obj));
    console.log(obj);

    this.employeeService.createEmployee(obj)
    .pipe(catchError((err: any) => {
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      console.log(response);
      if(response) {
        this.dialogRef.close(response);
      }
    })

  };

}

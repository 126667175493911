import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-load-note-dialog',
  templateUrl: './load-note-dialog.component.html',
  styleUrls: ['./load-note-dialog.component.scss']
})
export class LoadNoteDialogComponent implements OnInit {
  note: string = '';

  constructor(@Inject(MAT_DIALOG_DATA) public obj: any) { }

  ngOnInit(): void {
    this.note = this.obj.note;    
  }

}

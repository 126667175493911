import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DriversProfileService } from '@app/modules/drivers-profile-page/services/drivers-profile.service';
import { TransformService } from '@app/modules/shared/services/transform.service';
import { Subscription, catchError, throwError } from 'rxjs';

@Component({
  selector: 'truck-trailer-info',
  templateUrl: './truck-trailer-info.component.html',
  styleUrls: ['./truck-trailer-info.component.scss']
})
export class TruckTrailerInfoComponent implements OnInit, OnDestroy {
  @Input() obj: any = {truckNo: '', trailerNo: '', isTruck: true};

  dataObjTruck: any = {
    def_level: 0,
    division_id: 0,
    fuel_level: 0,
    id: 0,
    make: null,
    model: null,
    odometer: 0,
    plate_no: null,
    status: null,
    unit_no: null,
    vin_no: null,
    year: 0
  }

  dataObjTrailer: any = {
    division_id: 0,
    id: 0,
    make: null,
    model: null,
    odometer: 0,
    plate_no: null,
    serial_no: null,
    type: null,
    unit_no: null,
    year: 0
  }

  loaded: boolean = false;
  error: boolean = false;

  truckInfo: boolean = true;

  //Subscription
  subscription: Subscription = new Subscription();

  constructor(private driversProfileService: DriversProfileService,
  public transformService: TransformService) { }

  ngOnInit(): void {
    this.getData(this.obj.isTruck);
  }

  getData(isTruck: boolean) {
    this.truckInfo = isTruck;
    if(this.truckInfo) {
      this.subscription = this.driversProfileService.getTruckInfo(this.obj.truckNo)
      .pipe(catchError((err: any) => {
        this.loaded = true;
        this.error = true;
        return throwError(() => err);
      }))
      .subscribe((response: any) => {
        this.dataObjTruck = response;
        this.loaded = true;
        console.log(response);
      });
    }
    else {
      this.subscription = this.driversProfileService.getTrailerInfo(this.obj.trailerNo)
      .pipe(catchError((err: any) => {
        this.loaded = true;
        this.error = true;
        return throwError(() => err);
      }))
      .subscribe((response: any) => {
        this.dataObjTrailer = response;
        this.loaded = true;
        console.log(response);
      });
    }
  };

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  };

}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../core/services/auth/auth.guard';
import { EstimationsComponent } from './components/estimations.component';


const routes: Routes = [
	{
		path: 'loads',
		canActivate: [AuthGuard],
        children: [
            {
                path: 'estimations',
                component: EstimationsComponent
			},
    ],
    data: { roles: 19 }
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class EstimationsRoutingModule { }

import { Component, Input, OnChanges, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { TransformService } from '@app/modules/shared/services/transform.service';

import { ApexAxisChartSeries, ApexChart, ApexDataLabels, ApexGrid, ApexStroke, ApexXAxis, ChartComponent } from 'ng-apexcharts';


export type ChartOptions = {
  series: ApexAxisChartSeries | any;
  chart: ApexChart | any;
  xaxis: ApexXAxis | any;
  dataLabels: ApexDataLabels | any;
  grid: ApexGrid | any;
  colors: string | any;
  stroke: ApexStroke | any;
};


@Component({
  selector: 'app-prebooked-line-chart',
  templateUrl: './prebooked-line-chart.component.html',
  styleUrls: ['./prebooked-line-chart.component.scss']
})
export class PrebookedLineChartComponent implements OnChanges {

  @Input() graph: any;
  @ViewChild("chart") chart: ChartComponent | any;
  public chartOptions: Partial<ChartOptions> | any;

  selectPeriod: FormControl | any;

  constructor(private transformService: TransformService) {
    this.selectPeriod = new FormControl(['week']);
  }

  ngOnChanges(): void {
    if (this.graph) {
      let data = this.transformService.countDataByPeriodPrebookedLoads(this.graph, this.selectPeriod.value[0]);
      this.initGraph(data);
    }
  }

  initGraph(data: any) {
    this.chartOptions = {
      series: [
        {
          name: "PREBOOKED",
          data: data.prebooked
        },
        {
          name: "ON SPOT",
          data: data.spot
        }
      ],
      chart: {
        height: 350,
        type: "line",
        zoom: {
          enabled: false
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: "straight"
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5
        }
      },
      colors: ['#0062ff', '#f98b06'],
      xaxis: {
        categories: data.categories
      }
    };
  }

  onlyOneSelect(value: string) {
    this.selectPeriod = new FormControl([value]);
  };

  onSubmit(matSelect: MatSelect) {
    matSelect.close();
    let data = this.transformService.countDataByPeriodPrebookedLoads(this.graph, this.selectPeriod.value[0]);
    this.initGraph(data);
  };

}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotCoveredTrucksPageComponent } from './components/not-covered-trucks-page.component';
import { AuthGuard } from '../core/services/auth/auth.guard';

const routes: Routes = [
	{
		path: 'dispatchers',
		//canActivate: [AuthGuard],
        children: [
          {
            path: 'not-covered-trucks',
            canActivate: [AuthGuard],
            component: NotCoveredTrucksPageComponent,
            data: { roles: 5 }
          }
    	]
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class NotCoveredTrucksRoutingModule { }

import { ConnectionPositionPair } from '@angular/cdk/overlay';
import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'target-dots-menu',
  templateUrl: './target-dots-menu.component.html',
  styleUrls: ['./target-dots-menu.component.scss']
})
export class TargetDotsMenuComponent {
  optionsArray: any[] = ['Delete'];
  @Output() triggerEvent = new EventEmitter<string>();

  //Positions
  public positions = [
    new ConnectionPositionPair(
      {originX: 'end', originY: 'bottom'},
      {overlayX: 'end', overlayY: 'top'},
      -10, 5
    )
  ];
  isOpenDotsMenu: boolean = false;

  selectOption(value: string) {
    this.triggerEvent.emit(value);
    this.isOpenDotsMenu = false;
  };

}

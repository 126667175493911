import { AfterContentChecked, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CreateUpdateProfilDialogComponent } from './create-update-profil-dialog/create-update-profil-dialog.component';
import { Sort } from '@angular/material/sort';
import { ProfileActivityDialogComponent } from './profile-activity-dialog/profile-activity-dialog.component';
import { Subscription, catchError, throwError } from 'rxjs';
import { LoadBoardService } from '@app/modules/load-board/services/load-board.service';
import { TransformService } from '@app/modules/shared/services/transform.service';
import { SharedService } from '@app/modules/shared/services/shared.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'load-board-card',
  templateUrl: './load-board-card.component.html',
  styleUrls: ['./load-board-card.component.scss']
})
export class LoadBoardCardComponent implements OnInit, AfterContentChecked, OnDestroy {
  //Filters
  filterArray: any[] = [
    {name: 'All', key: 'allProfiles', bgColor: 'badge-gray'},
    {name: 'Active', key: 'activeProfiles', bgColor: 'badge-green'},
    {name: 'Inactive', key: 'inactiveProfiles', bgColor: 'badge-red'}
  ]
  activeFilterIndex: number = 0;

  metaData: any = {
    allProfiles: 0,
    activeProfiles: 0,
    inactiveProfiles: 0
  }

  dataSource: any[] = [];
  dataObj: any;
  searchProfile: string = '';

  loaded: boolean = false;
  error: boolean = false;
  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";

  spinnerBgColor: string = this.transformService.spinnerBgColor;
  spinnerType: string = this.transformService.spinnerType;

  //Subscription
  subscription: Subscription = new Subscription();

  constructor(private dialog: MatDialog, 
              private loadBoardService: LoadBoardService,
              private transformService: TransformService,
              private cdref: ChangeDetectorRef,
              private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.getTableData();
  }

  getTableData() {
    this.loaded = false;
    this.error = false;
    this.dataSource = [];
    this.dataObj = undefined;
    this.spinner.show('load-board-table');
    this.subscription = this.loadBoardService.getAllCompanies(this.loadBoardService.creatorId)
    .pipe(catchError((err: any) => {
      this.spinner.hide('load-board-table');
      this.loaded = true;
      this.error = true;
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      if(response.TruckProfiles) {
        this.dataSource = response.TruckProfiles;
      };
      this.dataObj = response;
      this.spinner.hide('load-board-table');
      this.loaded = true;
      console.log(response);
    });
  };

  createUpdateProfileDialog(editMode: boolean, element: any) {
    let dialogRef: any = this.dialog.open(CreateUpdateProfilDialogComponent, {
      disableClose: true,
      autoFocus: false,
      panelClass: 'create-update-profil-dialog-container',
      data: {editMode: editMode, obj: element}
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
        this.getTableData();
      } 
    })
  }

  sortData(sort: Sort) {
    const data = JSON.parse(JSON.stringify(this.dataSource));
    if (!sort.active || sort.direction === '') {
      this.dataSource = data;
      return;
    }
    this.dataSource = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'active':
          return compare(a.active, b.active, isAsc);
        case 'profile_name':
          return compare(a.profile_name, b.profile_name, isAsc);
        case 'profile_status':
          return compare(a.profile_status, b.profile_status, isAsc);
        case 'trucks':
          return compare(a.trucks, b.trucks, isAsc);
        case 'destination':
          return compare(a.destination, b.destination, isAsc);
        case 'weight':
          return compare(a.weight, b.weight, isAsc);
        case 'length':
          return compare(a.length, b.length, isAsc);
        case 'min_rate':
          return compare(a.min_rate, b.min_rate, isAsc);
        case 'profile_comment':
          return compare(a.profile_comment, b.profile_comment, isAsc);
        case 'general_comment':
          return compare(a.general_comment, b.general_comment, isAsc);
        case 'date_created':
          return compare(a.date_created, b.date_created, isAsc);
        default:
          return 0;
      }
    });
  }

  changeProfileStatus(element: any) {
    let dialogRef: any = this.dialog.open(ProfileActivityDialogComponent, {
      disableClose: true,
      autoFocus: false,
      panelClass: 'profile-activity-dialog-container',
      data: {element: element, datesArray: this.dataObj.TruckProfilesActivity}
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
        this.getTableData();
      } 
    })
  };

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  };

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  };

}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {

  transform(array: any[], searchText: string, key: string): any[] {
    if (!array) {
      return [];
    }
    if (!searchText) {
      return array;
    }
    searchText = searchText.toLocaleLowerCase();

    return array.filter((obj: any) => {
      if(key === '') {
        return obj.toLocaleLowerCase().includes(searchText);
      } 
      else {
        return `${obj[key]}`?.toLocaleLowerCase().includes(searchText);
      }
    });
  }

}

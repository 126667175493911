import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import moment = require('moment');

@Component({
  selector: 'app-trigger-double-click-dialog',
  templateUrl: './trigger-double-click-dialog.component.html',
  styleUrls: ['./trigger-double-click-dialog.component.scss']
})
export class TriggerDoubleClickDialogComponent implements OnInit, OnDestroy {
  time: string = '';
  //Interval
  interval: any;

  constructor(@Inject(MAT_DIALOG_DATA) public date: string) { }

  ngOnInit(): void {
    let x: any = new Date(moment(this.date).add(1, 'days').format('YYYY-MM-DDT14:00:00'));
    let y: any = new Date();
    this.time = this.secondsToDhms(new Date(Math.abs(x - y) / 1000));
  
    this.interval = setInterval(() => {
      let z: any = new Date();
      this.time = this.secondsToDhms(new Date(Math.abs(x - z) / 1000));
    }, 1000);
  };

  secondsToDhms(seconds: any) {
    seconds = Number(seconds)
    let h: string = `${Math.floor((seconds % (3600 * 24)) / 3600)}`
    let m: string = `${Math.floor((seconds % 3600) / 60)}`;
    let s: string = `${Math.floor(seconds % 60)}`;
    return `${h.padStart(2, '0')}:${m.padStart(2, '0')}:${s.padStart(2, '0')}`;
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
  };

}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PrebookedUpcomingComponent } from './components/prebooked-upcoming.component';
import { AuthGuard } from '../core/services/auth/auth.guard';

const routes: Routes = [
	{
		path: 'dispatchers',
		//canActivate: [AuthGuard],
        children: [
          {
            path: 'prebooked-upcoming',
            canActivate: [AuthGuard],
            component: PrebookedUpcomingComponent,
            data: { roles: 0 }
          }
    	]
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class PrebookedUpcomingRoutingModule { }

import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PhotosOfInspectionDialogComponent } from '../photos-of-inspection-dialog/photos-of-inspection-dialog.component';

@Component({
  selector: 'rims-inspection-tab',
  templateUrl: './rims-inspection-tab.component.html',
  styleUrls: ['./rims-inspection-tab.component.scss']
})
export class RimsInspectionTabComponent implements OnInit {
  @Input() data: any;
  tiresObj: any = {
    'LF': {ID: 0, dvirID: 0, rimID: 'LF', status: '-', notes: ''},
    'RF': {ID: 0, dvirID: 0, rimID: 'RF', status: '-', notes: ''},
    'LFO': {ID: 0, dvirID: 0, rimID: 'LFO', status: '-', notes: ''},
    'LFI': {ID: 0, dvirID: 0, rimID: 'LFI', status: '-', notes: ''},
    'RFI': {ID: 0, dvirID: 0, rimID: 'RFI', status: '-', notes: ''},
    'RFO': {ID: 0, dvirID: 0, rimID: 'RFO', status: '-', notes: ''},
    'LRO': {ID: 0, dvirID: 0, rimID: 'LRO', status: '-', notes: ''},
    'LRI': {ID: 0, dvirID: 0, rimID: 'LRI', status: '-', notes: ''},
    'RRI': {ID: 0, dvirID: 0, rimID: 'RRI', status: '-', notes: ''},
    'RRO': {ID: 0, dvirID: 0, rimID: 'RRO', status: '-', notes: ''}
  };  
  activeWheelName: string = 'LF';
  activeWheelId: number = 0;

  constructor(private dialog: MatDialog) { }

  ngOnInit(): void {
    console.log(this.data);
    for(let i = 0; i < this.data.DVIRRimsInspection.length; i++) {
      this.tiresObj[this.data.DVIRRimsInspection[i].rimID] = this.data.DVIRRimsInspection[i];

      if(this.activeWheelName === this.data.DVIRRimsInspection[i].rimID) {
        this.activeWheelId = this.data.DVIRRimsInspection[i].ID;
      }
    };
    console.log(this.tiresObj)
  }

  selectWheel(value: string) {
    this.activeWheelName = value;
  };

  showLatestPhotos() {
    this.dialog.open(PhotosOfInspectionDialogComponent, {
      autoFocus: false,
      panelClass: 'component-dialog-container',
      data: {title: `Rims Inspection - ${this.activeWheelName}`, ID: this.activeWheelId, inspectionArray: this.data.DVIRRimsInspectionPhotos}
    });
  }

  getStatusValue(status: number | null): string {
    if(status === 0) {
      return 'Good';
    };
    if(status === 1) {
      return 'Follow Up';
    }
    if(status === 2) {
      return 'Bad';
    }
    return 'No status';
  };

}

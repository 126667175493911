import { HttpEventType } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DeleteConfirmationDialogComponent } from '@app/modules/shared/components/delete-confirmation-dialog/delete-confirmation-dialog.component';
import { EmailDialogComponent } from '@app/modules/shared/components/email-dialog/email-dialog.component';
import { TicketsWarningsReasonsDialogComponent } from '@app/modules/shared/components/tickets-warnings-reasons-dialog/tickets-warnings-reasons-dialog.component';
import { SharedService } from '@app/modules/shared/services/shared.service';
import moment = require('moment');
import { Subscription, catchError, throwError } from 'rxjs';
import { TicketsService } from '../../services/tickets.service';

@Component({
  selector: 'app-create-update-ticket-dialog',
  templateUrl: './create-update-ticket-dialog.component.html',
  styleUrls: ['./create-update-ticket-dialog.component.scss']
})
export class CreateUpdateTicketDialogComponent implements OnInit, OnDestroy {
  loggedUser: any = JSON.parse(localStorage.getItem('currentUser'));

  //Ticket status
  ticketStatusArray: any[] = [
    {name: 'Open', bgColor: 'green-status-bg'},
    {name: 'In progress', bgColor: 'blue-status-bg'},
    {name: 'Closed', bgColor: 'red-status-bg'}
  ];
  isOpenTicketStatusMenu: boolean = false;
  ticketStatus: string = 'Open';

  //Driver
  autoCompleteData: any[] = [];
  isOpenDriverAutocomplete: boolean = false;
  driverSearch: string = '';
  driverId: number | undefined = undefined;
  isActiveDriver: boolean | undefined = undefined;

  filterActive: boolean = true;
  filterInactive: boolean = false;

  //Truck
  truckNo: string = '';
  truckId: number | undefined = undefined;

  //Initiator
  employeeArray: any[] = [];
  isOpenInitiatorMenu: boolean = false;
  initiator: string = '';
  initiatorId: any;
  initiatorDepartment: string = '';
  searchEmployee: string = '';

  //Calendar
  isOpenCalendar: boolean = false;
  initiatingDate: string = '';

  //Assigned to
  assignedToArray: any[] = [];
  isOpenAssignedMenu: boolean = false;
  assignedToId: number | undefined = undefined;
  assignedToEmail: string = '';
  assignedToName: string = '';
  searchUser: string = '';

  //Priority array
  isOpenPriorityMenu: boolean = false;
  priorityArray: any[] = [
    {name: 'High priority'},
    {name: 'Medium priority'},
    {name: 'Low priority'}
  ];
  priorityValue: string = '';

  //Reasons
  reasonsArray: any[] = [
    {reasonValue: 'Category', isOpenReasonMenu: false, value: ''}
  ];
  
  reasonsObj: any;
  isOpenReasonMenu: boolean = false;

  reasonsArraySelectedValues: string[] = [];

  reasonsDataWithoutGroup: any[] = [];

  //Write ticket array
  notesArray: any[] = [
    {
      id: 0,
      initiate_id: undefined,
      note: '',
      created_by: '',
      created_date: ''
    }
  ];

  //Files
  files: any[] = [];

  //Selected employees
  selectedEmployees: any[] = [];

  //Data saved
  loading: boolean = false;
  error: boolean = false;

  //Message delete in progress
  msgDelete: boolean = false;
  msgDeleteError: boolean = false;

  //Multiple clicks
  multipleClicks: boolean = true;

  //Progress
  progressDone: number = 0;
  showProgress: boolean = false;

  //Subscription
  subscription1: Subscription = new Subscription();
  subscription2: Subscription = new Subscription();
  subscription3: Subscription = new Subscription();

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<any>,
              private dialog: MatDialog,
              private ticketService: TicketsService,
              private sharedService: SharedService) { }

  ngOnInit(): void {
    console.log(this.data.obj);
    if(this.data.editMode) {
      this.initData();
    }
    this.getAutocompleteData();
    this.getAllEmployees();
    this.getAllTicketsReasons();
  }

  initData() {
    let initObj: any = JSON.parse(JSON.stringify(this.data.obj));
    this.driverSearch = initObj.driver;
    this.truckNo = initObj.unit_no;
    this.truckId = initObj.trucks_id;
    this.driverId = initObj.driver_id;
    this.isActiveDriver = initObj.is_active;
    this.initiator = initObj.initiator;
    this.initiatorId = initObj.initiator_id;
    this.initiatorDepartment = initObj.department;
    this.initiatingDate = initObj.initiating_date;
    this.priorityValue = initObj.priority;
    this.reasonsArraySelectedValues = initObj.reasons;
    let reasonsArray: any[] = [];
    for(let key in initObj.reasons) {
      reasonsArray.push({reasonValue: initObj.reasons[key], isOpenReasonMenu: false, value: initObj.reasons[key]})
    }
    this.reasonsArray = reasonsArray.length > 0 ? reasonsArray : this.reasonsArray;
    this.notesArray = initObj.notes;
    this.files = initObj.files;
    this.ticketStatus = initObj.status;
    this.selectedEmployees = JSON.parse(initObj.users);

    if(initObj.assignedTo.length > 0) {
      let assignedToObj: any = JSON.parse(initObj.assignedTo)
      this.assignedToId = assignedToObj.id;
      this.assignedToEmail = assignedToObj.email;
      this.assignedToName = assignedToObj.name;
    }
  };

  getAutocompleteData() {
    this.subscription1 = this.sharedService.getAllDrivers()
    .subscribe((response: any) => {
      this.autoCompleteData = response;
      console.log(response);
    });
  };

  getAllEmployees() {
    this.subscription2 = this.sharedService.getAllEmails().subscribe((response: any) => {
      this.employeeArray = response;
      this.assignedToArray = response;
      console.log(response);
    });
  };

  getAllTicketsReasons() {
    this.subscription3 = this.sharedService.getTwReasons().subscribe((response: any) => {
      this.reasonsDataWithoutGroup = response;
      this.reasonsObj = this.groupCategories(response)
    })
  };

  //Select driver autocomplete
  selectOption(obj: any) {
    this.driverSearch = obj.driver;
    this.truckNo = obj.unit_no;
    this.truckId = obj.truck_id;
    this.driverId = obj.driver_id;
    this.isActiveDriver = obj.is_active;
    this.isOpenDriverAutocomplete = false;
    console.log(obj);
  }

  //Select employee
  selectEmployee(obj: any) {
    this.initiator = obj.name + ' ' + obj.nickname; 
    this.initiatorId = obj.settings_id;
    this.initiatorDepartment = obj.department;
    for(let key in this.notesArray) {
      this.notesArray[key].initiate_id = obj.settings_id;
    }
    this.isOpenInitiatorMenu = !this.isOpenInitiatorMenu;
  };

  //Assign to
  assignToUser(obj: any) {
    this.assignedToId = obj.id;
    for(let i = 0; i < obj.companies.length; i++) {
      if(obj.companies[i].is_primary) {
        this.assignedToEmail = obj.companies[i].email;
        break;
      }
    };
    this.assignedToName = obj.name + ' ' + obj.nickname; 
    this.isOpenAssignedMenu = !this.isOpenAssignedMenu;
  };

  //Select date
  dateClicked(event: any) {
    this.initiatingDate = moment(event._d).format('YYYY-MM-DDT00:00:00');
    this.isOpenCalendar = false;
  };

  groupCategories(array: any[]) {
    const groups: any = array.reduce((acc: any, o: any) => {

      o['category'] = o['category'];
    
      if (!acc[o['category']]) {
        acc[o['category']] = [];
      }
          
      acc[o['category']].push(o);
  
      return acc;
  
    }, {});

    return groups;
  };

  //Select reasons
  selectReason(array: any[], index: number, obj: any) {
    obj.isOpenReasonMenu = !obj.isOpenReasonMenu; 
    obj.reasonValue = array[index].subcategory; 
    obj.value = array[index].subcategory;
    this.reasonsArraySelectedValues.push(array[index].subcategory);
  }

  addSelect() {
    this.reasonsArray.push({reasonValue: 'Category', isOpenReasonMenu: false, value: ''});
  };

  //Remove select
  removeSelect(index: number) {
    this.reasonsArray.splice(index, 1);
    this.reasonsArraySelectedValues.splice(index, 1);
  }

  //Open reasons dialog
  openReasonsDialog() {
    let dialogRef: any = this.dialog.open(TicketsWarningsReasonsDialogComponent, {
      autoFocus: false,
      panelClass: 'reasons-dialog-main-container',
      data: this.reasonsDataWithoutGroup
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      this.getAllTicketsReasons();
    });
  }

  //Email dialog
  openEmailDialog() {
    let dialogRef = this.dialog.open(EmailDialogComponent, {
      autoFocus: false,
      panelClass: 'email-dialog-container',
      data: {
        selectedEmails: this.selectedEmployees, 
        deafultAddressArray: []
      }
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
        this.selectedEmployees = result;
      }
    })
  }

  getEmployees(array: any) {
    let names = array.map((a) => a.name);
    return names.join(', ')
  };

  //Add files
  onFileSelected(event: any) {
    let length: any = Object.keys(event.target.files).length;
    for (let i = 0; i < length; i++) {
      this.addMultipleFiles(event, i);
    }
  }

  addMultipleFiles(event: any, index: number) {
    let fileName = event.target.files[index].name;
    let reader = new FileReader();
    reader.onload = (e: any) => {
      let base64 = e.target.result.split(',')[1];
      let obj: any = {
        filename: fileName,
        data: base64
      };
      this.files.push(obj);
    };

    reader.readAsDataURL(event.target.files[index]);
  };

  //Discard files
  discard(i : number) {
    this.files.splice(i, 1);
  };

  //Create update ticket
  createUpdateTicket() {
    if(this.multipleClicks) {
      this.loading = true;
      this.error = false;

      let obj: any = {
        id: 0,
        unit_no: this.truckNo,
        trucks_id: this.truckId,
        driver: this.driverSearch,
        driver_id: this.driverId,
        is_active: this.isActiveDriver,
        initiator: this.initiator,
        initiator_id: this.initiatorId,
        department: this.initiatorDepartment,
        initiating_date: this.initiatingDate,
        notes: this.notesArray,
        priority: this.priorityValue,
        reasons: this.reasonsArraySelectedValues,
        files: this.files,
        created: moment().format('YYYY-MM-DDTHH:mm:ss'),
        status: this.ticketStatus,
        users: JSON.stringify(this.selectedEmployees),
        assignedTo: JSON.stringify({id: this.assignedToId, email: this.assignedToEmail, name: this.assignedToName})
      };

      for(let i = 0; i < this.notesArray.length; i++) {
        if(this.notesArray[i].id) {
          this.notesArray[i].edited_by = this.loggedUser.first_name + ' ' + this.loggedUser.last_name;
          this.notesArray[i].edited_date = moment().format('YYYY-MM-DDTHH:mm:ss');
        }
        else {
          this.notesArray[i].created_by = this.loggedUser.first_name + ' ' + this.loggedUser.last_name;
          this.notesArray[i].created_date = moment().format('YYYY-MM-DDTHH:mm:ss');
        }
        this.notesArray[i].initiate_id = this.initiatorId;
      };

      if(this.data.editMode) {
        obj.id = this.data.obj.id;
        obj.created = this.data.obj.created;
        obj.edited_by = this.loggedUser.first_name + ' ' + this.loggedUser.last_name;
        obj.edited_date = moment().format('YYYY-MM-DDTHH:mm:ss');
      }
    
      this.ticketService.addUpdateTicket(obj)
      .pipe(catchError((err: any) => {
        this.loading = false;
        this.showProgress = false;
        this.error = true;
        return throwError(() => err);
      }))
      .subscribe((httpResponse: any) => {
        this.showProgress = true;
        if (httpResponse.type === HttpEventType.UploadProgress) {
          this.progressDone = Math.round(100 * httpResponse.loaded / httpResponse.total);
        } 

        if (httpResponse.type === HttpEventType.Response) {
          this.showProgress = false;
          this.progressDone = 0;
          if(httpResponse.body) {
            this.multipleClicks = false;
            this.sendNotifications();
            this.dialogRef.close(true);
          }
          else {
            this.error = true;
          }
          this.loading = false;
        }
      });

    }
  };

  sendNotifications() {
    if(this.selectedEmployees.length > 0) {
      let loggedUser: any = JSON.parse(localStorage.getItem('currentUser'));
      for(let key in this.selectedEmployees) {
        
        if(this.selectedEmployees[key].id !== loggedUser.id && this.selectedEmployees[key].id !== 0) {

          let obj: any = {
            id: null,
            type: 'TICKET',
            data: JSON.stringify(this.data.obj),
            date: moment().add(1, 'hour').format('YYYY-MM-DDTHH:mm:ss'),
            from_user: loggedUser.id,
            for_user: this.selectedEmployees[key].id,
          }

          this.sharedService.sendNotification(obj).subscribe((response: any) => {
            console.log(response);
          });

        }
      }
    }
  }

  //Delete ticket
  deleteTicket() {
    let dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
      autoFocus: false,
      panelClass: 'delete-dialog-container',
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
        let loggedUser: any = JSON.parse(localStorage.getItem('currentUser'));
        let fullName: string = loggedUser.first_name + ' ' + loggedUser.last_name;
        this.ticketService.deleteTicket(this.data.obj.id, fullName, moment().format('YYYY-MM-DDTHH:mm:ss'))
        .subscribe((response: any) => {
          if(response) {
            this.dialogRef.close(true);
          };
        });
      }
    });
  };

  getBtnName() {
    if(this.loading) {
      return 'Loading...' 
    }
    else if(this.data.editMode) {
      return 'Update ticket';
    }
    else {
      return 'Create ticket';
    }
  };

  get validateInputs() {
    let disableBtn: boolean = true;
    if(this.driverSearch && this.truckNo && this.initiator && this.initiatingDate && this.priorityValue
    && this.reasonsArraySelectedValues.length > 0) {
      disableBtn = false;
    };
    return disableBtn;
  };

  downloadPreviewFile(obj: any) {
    this.sharedService.downloadPreviewFile(obj.filename, obj.data);
  };

  //Open notes dialog
  addNewNote() {
    this.notesArray.push(
      {
        id: 0,
        initiate_id: undefined,
        note: '',
        created_by: this.loggedUser.first_name + ' ' + this.loggedUser.last_name,
        created_date: moment().format('YYYY-MM-DDTHH:mm:ss')
      }
    );
  };

  get getBgColor() {
    let obj: any = {
      'Open': 'green-status-bg',
      'In progress': 'blue-status-bg',
      'Closed': 'red-status-bg',
    }
    return obj[this.ticketStatus];
  };

  ngOnDestroy(): void {
    this.subscription1?.unsubscribe();
    this.subscription2?.unsubscribe();
    this.subscription3?.unsubscribe();
  };

}

import { Component } from '@angular/core';

@Component({
  selector: 'app-load-analysis-page',
  templateUrl: './load-analysis-page.component.html',
  styleUrls: ['./load-analysis-page.component.scss']
})
export class LoadAnalysisPageComponent {

}

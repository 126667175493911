import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { DriversFilterRoutingModule } from './drivers-filter.routing';
import { DashboardFilterDriversPageComponent } from './components/dashboard-filter-drivers-page.component';
import { FilterDriversTableComponent } from './components/filter-drivers-table/filter-drivers-table.component';
import { FilterSelectMenuComponent } from './components/filters-dialog/filter-select-menu/filter-select-menu.component';
import { FiltersDialogComponent } from './components/filters-dialog/filters-dialog.component';
import { DriversFilterService } from './services/drivers-filter.service';

@NgModule({
  declarations: [
    DashboardFilterDriversPageComponent,
    FilterDriversTableComponent,
    FiltersDialogComponent,
    FilterSelectMenuComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    DriversFilterRoutingModule
  ],
  providers: [DriversFilterService]
})
export class DriversFilterModule { }

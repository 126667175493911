import { Component } from '@angular/core';

@Component({
  selector: 'app-message-info-dialog',
  templateUrl: './message-info-dialog.component.html',
  styleUrls: ['./message-info-dialog.component.scss']
})
export class MessageInfoDialogComponent {

}

import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import L = require('leaflet');
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'app-load-map-dialog',
  templateUrl: './load-map-dialog.component.html',
  styleUrls: ['./load-map-dialog.component.scss']
})
export class LoadMapDialogComponent  implements OnInit, AfterViewInit {
  //Interval
  private map: any;
  routeInterval: any;
  currentlyLocation: string = '';

  constructor(private sharedService: SharedService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<any>) { }

  ngOnInit(): void {
    console.log(this.data);
    this.dialogRef.backdropClick().subscribe(_ => {
      clearInterval(this.routeInterval);
    })
  }

  //Maps methods
  private initMap(obj: any): void {
    let origin: any = {lat: obj.latitude, lng: obj.longitude};
    let destination: any = {lat: this.data.destinationLat, lng: this.data.destinationLng};

    if(this.data.pick_up && this.data.delivery) {
      origin.lat = this.data.pu_lat;
      origin.lng = this.data.pu_lng;

      destination.lat = this.data.del_lat;
      destination.lng = this.data.del_lng;
    }


    this.map = L.map('map', {
		  center: [origin.lat, origin.lat]
		});
	
		const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
		  attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
		});
		tiles.addTo(this.map);

    let control: any  = L.Routing.control({
      containerClassName: "leaflet-instructions",
      plan: L.Routing.plan([
        L.latLng(origin.lat, origin.lng),
        L.latLng(destination.lat, destination.lng)
      ], {
        createMarker: function(i, wp, nWps) {
          if (i === 0  ) {
            let myStartIcon = L.icon({
              iconUrl: './assets/img/location_start.png',
              iconSize: [34, 34],
              iconAnchor: [17, 34]
            });
            return L.marker(wp.latLng, {icon: myStartIcon });
          }
          else {                        
            let myEndIcon = L.icon({
              iconUrl: './assets/img/location_end.png',
              iconSize: [34, 34],
              iconAnchor: [17, 34]
            });
            return L.marker(wp.latLng, {icon: myEndIcon });
          }
        }
      }),
      routeWhileDragging: false,
      lineOptions: {
        styles: [{color: '#022bb1', opacity: 0.6, weight: 10}], extendToWaypoints: false, missingRouteTolerance: 0
     }
    }).addTo(this.map);

    if(!this.data.pick_up || !this.data.delivery) {

      let marker = L.marker([origin.lat, origin.lng],
        {
          icon: new L.DivIcon({
            className: 'my-div-icon',
            html: `<div style="background-color: #003fa4; color: #fff; text-align: center; padding: 3px; font-size: 16px; font-weight: bolder; border-radius: 10px; font-family: 'Poppins';">${ obj.unit_no}</div>
            <img style="width: 90px; height: 90px;" src="./assets/img/truck_pin.svg"/>`,
            iconSize: [90, 90],
            iconAnchor: [45, 90]
        })
        }
      );
      marker.addTo(this.map);

      this.routeInterval = setInterval(() => {
        this.sharedService.getTruckLocation(this.data.unit_no, -1).subscribe((response: any) => {
          console.log(response);
          if(response.length > 0) {
           // control.setWaypoints([new L.LatLng(response[0].latitude, response[0].longitude), new L.LatLng(this.data.destinationLat, this.data.destinationLng)]);
            marker.setLatLng(new L.LatLng(response[0].latitude, response[0].longitude));
            this.getCurrentAddress(response[0].latitude, response[0].longitude);
          }
        })
      }, 1000);

    }

    this.map.fitBounds([[origin.lat, origin.lng], [destination.lat, destination.lng]])
	};

  ngAfterViewInit(): void {
    this.sharedService.getTruckLocation(this.data.unit_no, -1).subscribe((response: any) => {
      if(response.length > 0) {
        this.initMap(response[0]);
        this.getCurrentAddress(response[0].latitude, response[0].longitude);
      }
    })
  };

  getCurrentAddress(lat: number, lng: number) {
    this.sharedService.getCurrentAddress(lat, lng).subscribe((response: any) => {
      this.currentlyLocation = response.address.road;
    })
  }

}

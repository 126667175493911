import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CalendarDialogComponent } from '@app/modules/shared/components/calendar-dialog/calendar-dialog.component';
import moment = require('moment');
import { NgxSpinnerService } from 'ngx-spinner';
import { catchError, Subscription, throwError } from 'rxjs';
import { DriversPerformanceService } from '../services/drivers-performance.service';
import { SharedService } from '@app/modules/shared/services/shared.service';
import { TransformService } from '@app/modules/shared/services/transform.service';
import { RulesService } from '@app/modules/shared/services/rules.service';

@Component({
  selector: 'app-dashboard-drivers-performance-page',
  templateUrl: './dashboard-drivers-performance-page.component.html',
  styleUrls: ['./dashboard-drivers-performance-page.component.scss']
})
export class DashboardDriversPerformancePageComponent  implements OnInit, OnDestroy {
  permissions: any = this.rulesService.UserData[16].data;
  
  dateObj: any = {
    startDate: this.transformService.convertDateToTimestamp(moment().startOf('month').format('ddd, DD/MM YYYY'), 
    '00:00:00'),
    endDate: this.transformService.convertDateToTimestamp(moment().format('ddd, DD/MM YYYY'),  moment().format('HH:mm:ss')),
    unitNo: null
  };

  data: any;
  previousPeriod: any;

  //Interval
  interval: any;

  loaded: boolean = false;

  error: boolean = false;
  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";

  spinnerBgColor: string = this.transformService.spinnerBgColor;
  spinnerType: string = this.transformService.spinnerType;

  subscription: Subscription | any;

  constructor(private dialog: MatDialog,
              private transformService: TransformService,
              private driversPerformanceService: DriversPerformanceService,
              private sharedService: SharedService,
              private rulesService: RulesService,
              private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.spinner.show('drivers-perf-cards');
    this.getCardsData();
    if(this.rulesService.liveUpdate) {
      this.interval = setInterval(() => {
        this.getCardsData();
      }, this.rulesService.miliseconds);
    }
  }

  getCardsData() {
    let start: any = this.dateObj.startDate;
    let end: any = this.dateObj.endDate;

    let startRes = moment(start, "YYYY-MM-DD");
    let endRes = moment(end, "YYYY-MM-DD")
    let difference = Math.abs(moment.duration(startRes.diff(endRes)).asDays());
    let days = Math.round(difference);
    let obj: any;
    if(days === 0) { 
      obj = {startDate: this.transformService.convertDateToTimestamp(moment().subtract(1, 'week').format('ddd, DD/MM YYYY'), 
      '00:00:00'), endDate: this.transformService.convertDateToTimestamp(moment().subtract(1, 'week').format('ddd, DD/MM YYYY'),
      '23:59:59')};
    } else {

      obj = {startDate: this.transformService.convertDateToTimestamp(moment().subtract(difference*2, 'days').format('ddd, DD/MM YYYY'), 
    '00:00:00'), endDate: this.transformService.convertDateToTimestamp(moment().subtract(difference, 'days').format('ddd, DD/MM YYYY'),
    '23:59:59')};

    let idsArray: any = JSON.parse(localStorage.getItem('selectedCompanies'));
    let filterParams: string = '';
    for(let key in idsArray) {
      filterParams +=	'&filters=' + idsArray[key];
    }
    this.subscription = this.driversPerformanceService.getDriverPerformanceCardsData(this.dateObj.startDate, this.dateObj.endDate, obj.startDate, obj.endDate, filterParams)
    .pipe(catchError((err: any) => {
      this.spinner.hide('drivers-perf-cards');
      this.loaded = true;
      this.error = true;
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      console.log(response);
      this.data = response[0];
      this.previousPeriod = response[1];
      this.previousPeriod.days = days;
      this.previousPeriod.twoRange = false;
      this.previousPeriod.dayName = moment(obj.start).format('dddd');
      this.spinner.hide('drivers-perf-cards');
      this.loaded = true;
    })
  }

}


  getDate(result: any) {
    this.changeDate(result);
  };

  openFloatingDialog() {
    let dialogRef: any = this.dialog.open(CalendarDialogComponent, {
      width: '769px',
      height: '476px',
      autoFocus: false,
      data: {oneRange: false},
      panelClass: 'calendar-dialog-container'
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.changeDate(result);
      }
    });
  }

  refresh() {
    this.changeDate(this.dateObj)
  }

  changeDate(result: any) {
    this.dateObj.startDate = result.startDate;
    this.dateObj.endDate = result.endDate;
    this.driversPerformanceService.driverPerformanceDateChanged.next(this.dateObj);
    this.data = undefined;
    this.previousPeriod = {};
    this.error = false;
    this.loaded = false;
    this.spinner.show('drivers-perf-cards');
    let data = {cards: undefined, key: undefined, title: undefined, downCard: undefined, reset: true};
    this.driversPerformanceService.driverPerformanceCardsLineChart.next(data);
    this.getCardsData();
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
    this.subscription?.unsubscribe();  
  }

}

import { HttpEventType } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DocumentsService } from '@app/modules/documents/services/documents.service';
import { DeleteConfirmationDialogComponent } from '@app/modules/shared/components/delete-confirmation-dialog/delete-confirmation-dialog.component';
import { SharedService } from '@app/modules/shared/services/shared.service';

import moment = require('moment');
import { Subscription, catchError, throwError } from 'rxjs';

@Component({
  selector: 'app-add-edit-document-dialog',
  templateUrl: './add-edit-document-dialog.component.html',
  styleUrls: ['./add-edit-document-dialog.component.scss']
})
export class AddEditDocumentDialogComponent implements OnInit, OnDestroy {
  //Document name
  documentName: string = '';

  //Upload by
  uploadBy: string = '';

  //Upload date
  isOpenUploadDateCalendar: boolean = false;
  uploadDate: string = '';

  //Expiration date
  isOpenExpirationDateCalendar: boolean = false;
  expirationDate: string = '';

  //Document description
  documentDescription: string = '';

  //Files
  files: any[] = [];

  filesValidation: number = 0;

  //Who can view and download
  permissionArray: any[] = [];
  isOpenViewDownloadMenu: boolean = false;

  //Search user
  searchUser: string = '';

  //Data saved
  loading: boolean = false;
  error: boolean = false;

  //Multiple clicks
  multipleClicks: boolean = true;

  //Progress
  progressDone: number = 0;
  showProgress: boolean = false;

  //Subscription
  subscription: Subscription | any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private dialog: MatDialog,
              public dialogRef: MatDialogRef<any>,
              private documentsService: DocumentsService,
              private sharedService: SharedService) { }

  ngOnInit(): void {
    let initObj: any = JSON.parse(JSON.stringify(this.data));
    if(initObj.editMode) {
      this.documentName = initObj.obj.name;
      this.uploadBy  = initObj.obj.uploaded_by;
      this.uploadDate = initObj.obj.uplaod_date;
      this.expirationDate = initObj.obj.expiration_date;
      this.documentDescription = initObj.obj.description;
      this.files = initObj.obj.files;
      this.filesValidation = initObj.obj.files.length;
    }
    else {
      let loggedUser: any = JSON.parse(localStorage.getItem('currentUser'));
      this.uploadBy = `${loggedUser.first_name} ${loggedUser.last_name}`;
    }
    this.subscription = this.sharedService.getAllEmployeeDepartments().subscribe((response: any) => {
      if(initObj.editMode) {
        let permissionObj: any = JSON.parse(this.data.obj.users);

        for(let i = 0; i < response.length; i++) {

          if(permissionObj.selectedDepartmentsViewArray.includes(response[i].name.trim())) {
            response[i].viewAllowed = true;
          }

          if(permissionObj.selectedDepartmentsDownloadArray.includes(response[i].name.trim())) {
            response[i].downloadAllowed = true;
          }
        }
      }
      this.permissionArray = response;
    });
    console.log(this.files)
  }

  //Date 
  dateClicked(event: any, uploadDate: boolean) {
    if(uploadDate) {
      this.uploadDate = moment(event._d).format('YYYY-MM-DDT00:00:00');
      this.isOpenUploadDateCalendar = false;
    }
    else {
      this.expirationDate = moment(event._d).format('YYYY-MM-DDT00:00:00');
      this.isOpenExpirationDateCalendar = false;
    }
  }

  //Upload files
  onFileSelected(event: any) {  
    let length: any = Object.keys(event).length;
    for(let i = 0; i < length; i++) {
      this.addMultipleFiles(event, i);
    }
  }

  addMultipleFiles(event: any, index: number) {
    let fileName = event[index].name;
    let reader = new FileReader();
    reader.onload = (e: any) => {
      let uint8 = new Uint8Array(e.target.result);
      let result = [];
      for (let i = 0; i < uint8.length; i++) {
        result.push(uint8[i]);
      }
      let obj: any = {
        filename: fileName,
        data: result,
        isEdit: true,
        deleteFile: false
      };
      this.files.push(obj);
      this.filesValidation++;
    }

    reader.readAsArrayBuffer(event[index])
  };

  //Remove file
  removeFile(obj: any, index: number) {
    if(this.data.editMode) {
      obj.deleteFile = true;
    }
    else {
      this.files.splice(index, 1);
    } 
    this.filesValidation--;
  };

  //Permission settings
  selectPermissions() {
    let allowed: boolean = true;
    if(this.data.editMode) {
      let loggedUser: any = JSON.parse(localStorage.getItem('currentUser')).userGroup;
      if(loggedUser !== 'Safety' && loggedUser !== 'Admin') {
        allowed = false;
      }
    }
    if(allowed) {
      this.isOpenViewDownloadMenu = !this.isOpenViewDownloadMenu;
    }
  }

  getBtnName() {
    if(this.loading) {
      return 'Loading...' 
    }
    else if(this.data.editMode) {
      return 'Update';
    }
    else {
      return 'Create';
    }
  };

  addUpdateDocument() {
    if(this.multipleClicks) {
      this.loading = true;
      this.error = false;

      let viewDepartments: string[] = [];
      let downloadDepartments: string[] = [];

      for(let i = 0; i < this.permissionArray.length; i++) {
        if(this.permissionArray[i].viewAllowed) {
          viewDepartments.push(this.permissionArray[i].name.trim());
        }
        if(this.permissionArray[i].downloadAllowed) {
          downloadDepartments.push(this.permissionArray[i].name.trim());
        }
      };

      let obj: any = {
        id: this.data.obj.id,
        company_id: this.data.obj.company_id,
        name: this.documentName,
        uploaded_by: this.uploadBy,
        uplaod_date: this.uploadDate,
        expiration_date: this.expirationDate,
        description: this.documentDescription,
        users: JSON.stringify({selectedDepartmentsViewArray: viewDepartments, selectedDepartmentsDownloadArray: downloadDepartments}),
        files: this.files
      }
      console.log(obj)
      this.documentsService.addUpdateDocument(obj)
      .pipe(catchError((err: any) => {
        this.loading = false;
        this.error = true;
        return throwError(() => err);
      }))
      .subscribe((httpResponse: any) => {
        this.showProgress = true;
        if (httpResponse.type === HttpEventType.UploadProgress) {
          this.progressDone = Math.round(100 * httpResponse.loaded / httpResponse.total);
        }
        
        if (httpResponse.type === HttpEventType.Response) {
          console.log(httpResponse);
          this.showProgress = false;
          this.progressDone = 0;
          if(httpResponse.body) {
            this.multipleClicks = false;
            this.dialogRef.close(httpResponse.body);
          }
          else {
            this.error = true;
          }
          this.loading = false;
        }

      });
    }

  };

  deleteDocument() {
    let dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
      autoFocus: false,
      panelClass: 'delete-dialog-container',
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
        this.documentsService.deleteDocument(this.data.obj.id)
        .subscribe((response: any) => {
          if(response) {
            this.dialogRef.close(0);
          }
        })
      }
    })
  };

  /*
  downloadPreviewFile(obj: any) {
    let base64: string = this.convertByteArrayToBase64(obj.data);
    this.sharedService.downloadPreviewFile(obj.filename, base64);
  };

  convertByteArrayToBase64(x: any) {
		return btoa(x.map(function(v){return String.fromCharCode(v)}).join(''));
	}*/

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  };

}

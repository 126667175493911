import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truckTrailerFilter'
})
export class TruckTrailerFilterPipe implements PipeTransform {

  transform(array: any[], filter: string): any {
    if (!array) {
      return [];
    }

    return array.filter((obj: any) => {
      let condition1: boolean = filter === 'Trucks' && obj.isTruck;
      let condition2: boolean = filter === 'Trailers' && !obj.isTruck;
      let condition3: boolean = filter === 'Both';

      if(condition1 || condition2 || condition3) {
        return obj;
      }; 
    });
  }

}

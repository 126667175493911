import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-user-confirmation-dialog',
  templateUrl: './delete-user-confirmation-dialog.component.html',
  styleUrls: ['./delete-user-confirmation-dialog.component.scss']
})
export class DeleteUserConfirmationDialogComponent {

  constructor(public dialogRef: MatDialogRef<DeleteUserConfirmationDialogComponent>) { }

  deleteUser() {
    this.dialogRef.close(true);
  };

}

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { CreateUpdateStatusFormComponent } from './create-update-status-form/create-update-status-form.component';

import moment = require('moment');
import { DeleteConfirmationDialogComponent } from '@app/modules/shared/components/delete-confirmation-dialog/delete-confirmation-dialog.component';
import { TransformService } from '@app/modules/shared/services/transform.service';
import { FleetHealthService } from '@app/modules/fleet-health/services/fleet-health.service';

@Component({
  selector: 'app-create-update-status',
  templateUrl: './create-update-status.component.html',
  styleUrls: ['./create-update-status.component.scss']
})
export class CreateUpdateStatusComponent implements OnInit {
  displayedColumns: string[] = ['position', 'status', 'created_by', 'created_date', 'edited_by', 'edited_date', 'actions'];
  dataSource: any = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private dialog: MatDialog,
              private transformService: TransformService,
              private fleetService: FleetHealthService) { }

  ngOnInit(): void {
    this.dataSource = this.data;
  }

  getData() {
    this.fleetService.getAllStatus().subscribe((response: any) => {
      this.dataSource = response;
      this.fleetService.statusesSubject.next(response);
    });
  }

  addUpdateStatus(isNewStatus: boolean, element: any) {
    let dialogRef: any = this.dialog.open(CreateUpdateStatusFormComponent, {
      autoFocus: false,
      panelClass: 'create-update-form-dialog-container',
      data: {isNew: isNewStatus, obj: element}
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
        this.getData();
      }
    });
  };

  deleteStatus(obj: any) {
    let dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
      autoFocus: false,
      panelClass: 'delete-dialog-container',
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
        let loggedUser: any = JSON.parse(localStorage.getItem('currentUser'));
        let createdBy: any = loggedUser.first_name + ' ' + loggedUser.last_name;
        let createdDate: any = this.transformService.convertDateToTimestamp(moment(new Date()).format('ddd, DD/MM YYYY'),  moment(new Date()).format('HH:mm:ss'));

        this.fleetService.deleteStatus(obj.id, createdBy, createdDate)
        .subscribe((response: any) => {
          if(response) {
            this.getData();
          }
        })
      }
    });
  };

}


import { Injectable } from '@angular/core';
import * as _G from '../../../_config/globals';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject, forkJoin } from 'rxjs';

@Injectable()
export class DriversPerformanceService {
  headers = new HttpHeaders({
		'Content-Type': 'application/json',
		'Authorization': 'Basic SVZTb2x1dGlvbjoxUzBsdXQxMG4yQDIy'
	});
	requestOptions = { headers: this.headers };

  driverPerformanceDateChanged = new Subject<any>();
  driverPerformanceCardsLineChart = new Subject<any>();
  driverPerformanceUtilizationLineChart = new Subject<any>();

  constructor( private http: HttpClient ) {}

  getDriverPerformanceCardsData(startDate: string, endDate: string, startDate2: string, endDate2: string, filterParams: string) {
		let response1 = this.http.get(`${_G.extendApiUrl}drivers/driversperformancecards?start=${startDate}&end=${endDate}${filterParams}`, this.requestOptions);
		let response2 = this.http.get(`${_G.extendApiUrl}drivers/driversperformancecards?start=${startDate2}&end=${endDate2}${filterParams}`, this.requestOptions);
		return forkJoin([response1, response2]);
	};

  getAverageCardsData(startDate: string, endDate: string, filterParams: string) {
		return this.http.get(`${_G.extendApiUrl}drivers/driverutil?start=${startDate}&end=${endDate}${filterParams}`, this.requestOptions);
	};

  getUtilizationData(startDate: string, endDate: string, filterParams: string) {
		let response1 = this.http.get(`${_G.extendApiUrl}drivers/unitlbystatus?start=${startDate}&end=${endDate}${filterParams}`, this.requestOptions);
		let response2 = this.http.get(`${_G.extendApiUrl}drivers/utilbydrivers?start=${startDate}&end=${endDate}${filterParams}`, this.requestOptions);
		return forkJoin([response1, response2]);
	};

}

import { Component, OnDestroy, OnInit } from '@angular/core';

import { Subscription, catchError, throwError } from 'rxjs';
import moment = require('moment');
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpEventType } from '@angular/common/http';
import { AccountingService } from '../../services/accounting.service';
import { TransformService } from '@app/modules/shared/services/transform.service';
import { AccountingInvoices, IAccountingInvoices } from '../../models/accounting-invoices.model';
import { SuccessDialogComponent } from '@app/modules/shared/components/success-dialog/success-dialog.component';
import { WarningMsgDialogComponent } from '@app/modules/shared/components/warning-msg-dialog/warning-msg-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { RulesService } from '@app/modules/shared/services/rules.service';
import { MsgForbbidenAccessComponent } from '@app/modules/shared/components/msg-forbbiden-access/msg-forbbiden-access.component';

@Component({
  selector: 'app-dashboard-invoices-page',
  templateUrl: './dashboard-invoices-page.component.html',
  styleUrls: ['./dashboard-invoices-page.component.scss']
})
export class DashboardInvoicesPageComponent implements OnInit, OnDestroy {
  permissions: any = this.rulesService.UserData[34].data;

  //Data
  data: IAccountingInvoices[] = [];

  //Invoice date from
  invoiceDateFrom: string = '';
  isOpenCalendarFrom: boolean = false;

  //Invoice date to
  invoiceDateTo: string = '';
  isOpenCalendarTo: boolean = false;

  //Created by
  loggedUser: any = JSON.parse(localStorage.getItem('currentUser'))
  createdBy: string = `${this.loggedUser.first_name} ${this.loggedUser.last_name}`;

  //Progress
  progressDone: number = 0;
  showProgress: boolean = false;

  //Status
  loaded: boolean = false;
  error: boolean = false;
  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";

  spinnerBgColor: string = this.transformService.spinnerBgColor;
  spinnerType: string = this.transformService.spinnerType;

  subscription: Subscription  = new Subscription();

  constructor(private accountingService: AccountingService, 
              private transformService: TransformService,
              private spinner: NgxSpinnerService,
              private dialog: MatDialog,
              private rulesService: RulesService) { }

  ngOnInit() {
    this.getInvoicesTableData();
  };

  getInvoicesTableData() {
    this.spinner.show('invoices-section');
    this.subscription = this.accountingService.getInvoices()
    .pipe(catchError((err: any) => {
      this.spinner.hide('invoices-section');
      this.loaded = true;
      this.error = true;
      return throwError(() => err);
    }))
    .subscribe((response: IAccountingInvoices[]) => {
      this.spinner.hide('invoices-section');
      this.loaded = true;
      this.data = response;
      console.log(response);
    })
  };

  //Date 
  dateClicked(event: any, fromDate: boolean) {
    if(fromDate) {
      this.invoiceDateFrom = moment(event._d).format('YYYY-MM-DD');
      this.isOpenCalendarFrom = false;
    }
    else {
      this.invoiceDateTo = moment(event._d).format('YYYY-MM-DD');
      this.isOpenCalendarTo = false;
    }
  };

  //Create invoice
  createInvoices() {
    if(this.permissions[0].sectionArray[0].allowed) {
      const invoiceObj: AccountingInvoices = new AccountingInvoices(0, this.invoiceDateFrom, this.invoiceDateTo, 
      moment().format('YYYY-MM-DDTHH:mm:ss'), this.createdBy);
      console.log(invoiceObj);
      this.accountingService.createInvoices(invoiceObj)
      .pipe(catchError((err: any) => {
        this.showErrorMessage();
        this.showProgress = false;
        this.progressDone = 0;
        return throwError(() => err);
      }))
      .subscribe((httpResponse: any) => {
        this.showProgress = true;
        if (httpResponse.type === HttpEventType.Response) {
          this.showProgress = false;
          this.progressDone = 0;
          const anchor = document.createElement('a')
          const objectUrl = URL.createObjectURL(httpResponse.body)
          anchor.href = objectUrl
          anchor.download = 'archive.zip';
          anchor.click();
          URL.revokeObjectURL(objectUrl);
          
          this.invoiceDateFrom = '';
          this.invoiceDateTo = '';
          this.data = [];
          this.getInvoicesTableData();
        }
        if (httpResponse.type === HttpEventType.DownloadProgress) {
          this.progressDone = Math.round(100 * httpResponse.loaded / httpResponse.total);
        } 
      })
    }
    else {
      this.dialog.open(MsgForbbidenAccessComponent, {
        autoFocus: false,
        panelClass: 'forbidden-msg-dialog-container'
      })
    }
  };

  showSuccessMessage() {
    this.dialog.open(SuccessDialogComponent, {
      autoFocus: false,
      panelClass: 'success-dialog-container'
    });  
  };

  showErrorMessage() {
    this.dialog.open(WarningMsgDialogComponent, {
      autoFocus: false,
      panelClass: 'warning-msg-dialog-container'
    });
  };

  ngOnDestroy(): void { 
    this.subscription?.unsubscribe();
  };

}

import { ConnectionPositionPair } from '@angular/cdk/overlay';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import moment = require('moment');
import { Subscription } from 'rxjs';
import { FullAppService } from '@app/modules/full-app/services/full-app.service';
import { IDriverNote } from '@app/modules/full-app/models/driver-note.model';

@Component({
  selector: 'app-full-app-note-dialog',
  templateUrl: './full-app-note-dialog.component.html',
  styleUrls: ['./full-app-note-dialog.component.scss']
})
export class FullAppNoteDialogComponent implements OnInit, OnDestroy {
  formGroup: FormGroup;
  userData: any = JSON.parse(localStorage.getItem('currentUser'));

  //Notes
  noteArray: any[] = [];
  editedNoteIndex: number | undefined = undefined;

  //Menu
  isOpenMenu: boolean = false;

  //Positions
  public positions = [
    new ConnectionPositionPair(
      {originX: 'end', originY: 'bottom'},
      {overlayX: 'end', overlayY: 'top'},
      0, 5
    )
  ];

  //Subscription
  subscription: Subscription = new Subscription();

  constructor(
  @Inject(MAT_DIALOG_DATA) public driverId: number, 
  private fullAppService: FullAppService,
  private formBuilder: FormBuilder) {
    this.formGroup = this.formBuilder.nonNullable.group({
      'id': [0],
      'driver_id': [this.driverId],
      'note': [''],
      'created_by': [this.userData.first_name + ' ' + this.userData.last_name],
      'creator_id': [this.userData.id],
      'department': [this.userData.department],
      'created_date': [''],
      'edited_by': [''],
      'editor_id': [null],
      'editor_department': [''],
      'edited_date': [null]
    })
  }

  ngOnInit(): void {
    this.subscription = this.fullAppService.getNotesByDriverId(this.driverId).subscribe((response: IDriverNote[]) => {
      this.noteArray = response;
    });
  };

  editNote(obj: IDriverNote | any, index: number) {
    obj.isOpenMenu = !obj.isOpenMenu; 
    this.formGroup.setValue({
      id: obj.id,
      driver_id: obj.driver_id,
      note: obj.note,
      created_by: obj.created_by,
      creator_id: obj.creator_id,
      department: obj.department,
      created_date: obj.created_date,
      edited_by: obj.edited_by,
      editor_id: obj.editor_id,
      editor_department: obj.editor_department,
      edited_date: obj.edited_date
    });
    this.editedNoteIndex = index;
  };

  postNote() {
    if(this.noteId) {
      this.formGroup.patchValue({
        'edited_by': this.userData.first_name + ' ' + this.userData.last_name,
        'editor_id': this.userData.id,
        'editor_department': this.userData.department,
        'edited_date': moment().format('YYYY-MM-DDTHH:mm:ss')
      })
    }
    else {
      this.formGroup.patchValue({
        'created_date': moment().format('YYYY-MM-DDTHH:mm:ss')
      })
    }

    this.fullAppService.createUpdateDriverNote(this.formGroup.value).subscribe((response: IDriverNote) => {
      if(response.id && this.noteId === 0) {
        this.noteArray.push(response);
      }
      if(response.id && this.noteId !== 0) {
        this.noteArray[this.editedNoteIndex] = response;
      }
      this.formGroup.reset();
    });
  };

  deleteNote(noteId: number, index: number) {
    this.fullAppService.deleteDriverNote(noteId).subscribe((response: boolean) => {
      console.log(response);
      if(response) {
        this.noteArray.splice(index, 1);
      }
    });
  };

  get noteId(): number {
    return this.formGroup.controls['id'].value;
  };

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  };
    
}

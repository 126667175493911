import { HttpHeaders, HttpClient } from '@angular/common/http';
import * as _G from '../../../_config/globals';
import { Injectable } from '@angular/core';
import { forkJoin } from 'rxjs';

@Injectable()
export class DispatchersLiveBoardService {
  headers = new HttpHeaders({
		'Content-Type': 'application/json',
		'Authorization': 'Basic SVZTb2x1dGlvbjoxUzBsdXQxMG4yQDIy'
	});
	requestOptions = { headers: this.headers };

  constructor(private http: HttpClient) { }

   //Live board
   getLiveboardCards(date: string, prevDate: string, filterParams: string, office: string) {
    let city: string = office === 'All Offices' ? '' : `&city=${office}`;
    let response1 = this.http.get(`${_G.extendApiUrl}dispatcher/livedispcards?date=${date}${filterParams}${city}`, this.requestOptions);
    let response2 = this.http.get(`${_G.extendApiUrl}dispatcher/livedispcards?date=${prevDate}${filterParams}${city}`, this.requestOptions);
    let response3 = this.http.get(`${_G.extendApiUrl}dispatcher/livedisprank?date=${date}${filterParams}${city}`, this.requestOptions);
    return forkJoin([response1, response2, response3]);
  };

  getLiveActivityTable(date: string, filterParams: string, office: string) {
    let city: string = office === 'All Offices' ? '' : `&city=${office}`;
    return this.http.get(`${_G.extendApiUrl}dispatcher/disptruckstatuslive?date=${date}${filterParams}${city}`, this.requestOptions);
  };

  getLiveLoadsData(date: string, filterParams: string, office: string) {
    let city: string = office === 'All Offices' ? '' : `&city=${office}`;
    return this.http.get(`${_G.extendApiUrl}dispatcher/displiveloads?date=${date}${filterParams}${city}`, this.requestOptions);
  };

  changeLoadStatus(id: number, user: string, date: string, value: boolean) {
    return this.http.get(`${_G.extendApiUrl}loads/deliveryok?id=${id}&user=${user}&date=${date}&value=${value}`, this.requestOptions);
  };

  getStatusesByTruck(unitNo: string, startDate: string, endDate: string) {
    return this.http.get(`${_G.extendApiUrl}drivers/driverestatuspertruck?unit_no=${unitNo}&start=${startDate}&end=${endDate}`, this.requestOptions);
  };
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatNum'
})
export class FormatNumPipe implements PipeTransform {

  transform(value: number | any, numOfDecimalPoinst: number): number | string {
    let formattedNumber: number | any = value;

    if((typeof formattedNumber !== 'number') || isNaN(formattedNumber)) {
      return 0;
    }

    if(numOfDecimalPoinst) {
      formattedNumber = value.toFixed(numOfDecimalPoinst);
    }

    if(!numOfDecimalPoinst) {
      formattedNumber = Math.round(formattedNumber);
    }

    return formattedNumber.toLocaleString("en-US").toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

}

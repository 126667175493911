import { Pipe, PipeTransform } from '@angular/core';
import moment = require('moment');

@Pipe({
  name: 'employeeFilter'
})
export class EmployeeFilterPipe implements PipeTransform {

  transform(array: any[], searchValuesArray: any[], keyArray: any[], metaData: any): any[] {
    if (!array) {
      return [];
    }

    let ids: number[] = [];
    let transformedArray: any = array.filter((obj: any) => {
      let key1: boolean =  obj[keyArray[0]].toLocaleLowerCase().startsWith(searchValuesArray[0].toLocaleLowerCase());

      let key2: boolean =  true;

      if(searchValuesArray[1] !== 'All Departments') {
        key2 = obj[keyArray[1]] === searchValuesArray[1];
      }

      let key3: boolean = true;

      if(searchValuesArray[2] !== 'All Employees') {
        let isActiveBoolean = searchValuesArray[2] === 'Active' ? false : true;
        key3 = obj[keyArray[2]] === isActiveBoolean;
      }

      let key4: boolean = true;

      if(searchValuesArray[3] !== 'All Offices') {
        key4 = obj[keyArray[3]] === searchValuesArray[3];
      }

      if(key1 && key2 && key3 && key4) { 
        ids.push(obj.id);
        return obj;
      }

    });

    //Count statistics
    let stArray: any[] = [];
    let data: any[] = [];
    
    if(metaData.countData) {

      let numOfWorkingStatusByDay: number = 0;

      metaData.allStatusForAllEmployees.reduce((acc: any, valObj: any) => {
          const date: string = valObj.date;
          
          const index = data.findIndex((el: any) => el.date === valObj.date);
          if(index !== -1 && valObj.status_group_name === 'Working' && ids.includes(valObj.employee_id)){
            numOfWorkingStatusByDay++;
            data[index] = {date: date, numOfWorkingStatusByDay: numOfWorkingStatusByDay};
          } 

          if(index === -1 && valObj.status_group_name === 'Working' && ids.includes(valObj.employee_id)){
            numOfWorkingStatusByDay = 1;
            data.push({date: date, numOfWorkingStatusByDay: numOfWorkingStatusByDay});
          };

        }, []);
      

      let startDate = new Date(transformedArray[0].statusArray[0].date);
      let endDate = new Date(transformedArray[0].statusArray[transformedArray[0].statusArray.length - 1].date);
      const date = new Date(startDate.getTime());
      while (date <= endDate) {
        let d1: any = moment(date).format('YYYY-MM-DDT00:00:00');
        let newObj: any;

        let isExist: any = data.some((currObj: any) => { 
          newObj = currObj
          return currObj.date === d1;
        })

        if(isExist) {
          stArray.push(newObj);
        }
        else {
          stArray.push({date: d1, numOfWorkingStatusByDay: 0});
        }

        date.setDate(date.getDate() + 1);
      }

    }

    metaData.stArray = stArray;
    metaData.numToDivide = transformedArray.length;
    console.log(metaData);
    return transformedArray;

    }

    

}

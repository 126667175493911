import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardFilterDriversPageComponent } from './components/dashboard-filter-drivers-page.component';
import { AuthGuard } from '../core/services/auth/auth.guard';

const routes: Routes = [
	{
		path: 'drivers',
		//canActivate: [AuthGuard],
		children: [
		  {
			path: 'filter-drivers',
			children:[
				{
				  path: 'filter-drivers-page',
				  component: DashboardFilterDriversPageComponent,
				  canActivate: [AuthGuard],
				  data: { roles: 17 }
				}
			]
		  },
		],
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class DriversFilterRoutingModule { }

import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { CalendarDialogComponent } from '@app/modules/shared/components/calendar-dialog/calendar-dialog.component';
import { SharedService } from '@app/modules/shared/services/shared.service';
import { TransformService } from '@app/modules/shared/services/transform.service';

import moment = require('moment');
import { NgxSpinnerService } from 'ngx-spinner';
import { catchError, Subscription, throwError } from 'rxjs';
import { TrucksService } from '../../services/trucks.service';
import { RulesService } from '@app/modules/shared/services/rules.service';

@Component({
  selector: 'app-truck-profile-page',
  templateUrl: './truck-profile-page.component.html',
  styleUrls: ['./truck-profile-page.component.scss']
})
export class TruckProfilePageComponent implements OnInit, OnDestroy {
  permissions: any = this.rulesService.UserData[12].data[1].allowedAll;
  data: any;

  dateObj: any = {
    startDate: this.transformService.convertDateToTimestamp(moment().startOf('month').format('ddd, DD/MM YYYY'), 
    '00:00:00'),
    endDate: this.transformService.convertDateToTimestamp(moment().format('ddd, DD/MM YYYY'),  moment().format('HH:mm:ss')),
    unitNo: null
  };

  truckLocation: string = '';

  loaded: boolean = false;

  error: boolean = false;

  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";

  spinnerBgColor: string = this.transformService.spinnerBgColor;
  spinnerType: string = this.transformService.spinnerType;

  subscription1: Subscription | any;
  subscription2: Subscription | any;

  constructor(private route: ActivatedRoute,
              private trucksService: TrucksService,
              private transformService: TransformService,
              private rulesService: RulesService,
              private dialog: MatDialog,
              private sharedService: SharedService,
              private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.getTruckProfileData();
    this.getTruckLocation();
  }

  getTruckProfileData() {
    this.spinner.show('truck-profile-data');
    let unitNo: any = this.route.snapshot.paramMap.get('id');
    this.dateObj.unitNo = unitNo;
    this.subscription1 = this.trucksService.getTruckCards(unitNo, this.dateObj.startDate, this.dateObj.endDate)
    .pipe(catchError((err: any) => {
      this.spinner.hide('truck-profile-data');
      this.loaded = true;
      this.error = true;
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      this.data = response;
      this.spinner.hide('truck-profile-data');
      this.loaded = true;
      console.log(response)
    });
  };

  getTruckLocation() {
    let unitNo: any = this.route.snapshot.paramMap.get('id');
    this.sharedService.getTruckLocation(unitNo, -1).subscribe((response: any) => {
      if(response.length > 0) {
        this.sharedService.getCurrentAddress(response[0].latitude, response[0].longitude).subscribe((obj: any) => {
          let response: any = obj.address;  
          this.truckLocation = `${response.city ? response.city : (response.town ? response.town : 'Unknown')}, ${response.state}`;
        })
      }
    })
  };

  getDate(result: any) {
    this.dateObj.startDate = result.startDate;
    this.dateObj.endDate = result.endDate;
    this.data = undefined;
    this.error = false;
    this.loaded = false;
    this.trucksService.dateChanged.next(this.dateObj);
    this.getTruckProfileData();
  };

  openFloatingDialog() {
    let dialogRef: any = this.dialog.open(CalendarDialogComponent, {
      width: '769px',
      height: '476px',
      autoFocus: false,
      data: {oneRange: false},
      panelClass: 'calendar-dialog-container'
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.dateObj.startDate = result.startDate;
        this.dateObj.endDate = result.endDate;
        this.trucksService.dateChanged.next(this.dateObj);
        this.getTruckProfileData();
      }
    });
  }


  ngOnDestroy(): void {
    this.subscription1?.unsubscribe();
    this.subscription2?.unsubscribe();
  }
  
}

import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-cancel-orientation-date-dialog',
  templateUrl: './cancel-orientation-date-dialog.component.html',
  styleUrls: ['./cancel-orientation-date-dialog.component.scss']
})
export class CancelOrientationDateDialogComponent {
  constructor(public dialogRef: MatDialogRef<any>) { }

  confirmAction() {
    this.dialogRef.close(true);
  };
  
}

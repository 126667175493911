import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'accidentTableFilter'
})
export class AccidentTableFilterPipe implements PipeTransform {

  transform(array: any[], searchValuesArray: any[], keyArray: any[], dateObj: any): any[] {
    if (!array) {
      return [];
    }

    return array.filter((obj: any) => {
      let key1: boolean = obj[keyArray[0]].toLocaleLowerCase().startsWith(searchValuesArray[0].toLocaleLowerCase());
      let key2: boolean = `${obj[keyArray[1]]}`.toLocaleLowerCase().startsWith(searchValuesArray[1].toLocaleLowerCase());
      let key3: boolean = obj[keyArray[2]].toLocaleLowerCase().startsWith(searchValuesArray[2].toLocaleLowerCase());
      let key4: boolean = obj[keyArray[3]].toLocaleLowerCase().startsWith(searchValuesArray[3].toLocaleLowerCase());
      let key5: boolean = true;
      let key6: boolean = obj[keyArray[4]].toLocaleLowerCase().includes(searchValuesArray[4].toLocaleLowerCase());
      let key7: boolean = obj[keyArray[5]].toLocaleLowerCase().includes(searchValuesArray[5].toLocaleLowerCase());
      let key8: boolean = obj[keyArray[6]].toLocaleLowerCase().includes(searchValuesArray[6].toLocaleLowerCase());
      let key9: boolean = obj[keyArray[7]].toLocaleLowerCase().includes(searchValuesArray[7].toLocaleLowerCase());
      let key10: boolean = obj[keyArray[8]].toLocaleLowerCase().startsWith(searchValuesArray[8].toLocaleLowerCase());
      let key11: boolean = true;
      let key12: boolean = true;
      let key13: boolean = obj[keyArray[9]].toLocaleLowerCase().startsWith(searchValuesArray[9].toLocaleLowerCase());
      let key14: boolean = true;
      let key15: boolean = true;
      let key16: boolean = true;
      let key17: boolean = true;

      if(dateObj.dateOccuredObj) {
        const start = Date.parse(dateObj.dateOccuredObj.start._d);
        const end = Date.parse(dateObj.dateOccuredObj.end._d);
        const d = Date.parse(obj.date_occured);
        key5 = d.valueOf() >= start.valueOf() && d.valueOf() <= end.valueOf();
      }

      if(dateObj.dateDrugObj) {
        const start = Date.parse(dateObj.dateDrugObj.start._d);
        const end = Date.parse(dateObj.dateDrugObj.end._d);
        const d = Date.parse(obj.drug_test_date);
        key15 = d.valueOf() >= start.valueOf() && d.valueOf() <= end.valueOf();
      }

      if(dateObj.dateAlcoholObj) {
        const start = Date.parse(dateObj.dateAlcoholObj.start._d);
        const end = Date.parse(dateObj.dateAlcoholObj.end._d);
        const d = Date.parse(obj.alcohol_test_date);
        key16 = d.valueOf() >= start.valueOf() && d.valueOf() <= end.valueOf();
      }


      key11 = checkBooleanOption(10);
      key12 = checkBooleanOption(11);
      key14 = checkBooleanOption(12);
      key17 = checkStringOption(13)

      function checkBooleanOption(index: number) {
        let key: boolean | undefined = undefined;
        if(searchValuesArray[index] === 'Both') {
          key = true;
        }
        else if(searchValuesArray[index] === 'Checked') {
          key = obj[keyArray[index]] === true;
        }
        else {
          key = obj[keyArray[index]] === false;
        }
        return key;
      };

      function checkStringOption(index: number) {
        let key: boolean | undefined = undefined;
        if(searchValuesArray[index] === 'Both') {
          key = true;
        }
        else if(searchValuesArray[index] === 'Our driver') {
          key = obj[keyArray[index]] === 'Our driver';
        }
        else {
          key = obj[keyArray[index]] === '3rd Party';
        }
        return key;
      };

      if(key1 && key2 && key3 && key4 && key5 && key6 && key7 && key8 && key9 && key10 && key11 && key12 && key13 && key14 && key15 && key16 && key17) { 
        return obj;
      };

    });

  }

}

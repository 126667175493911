import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import moment = require('moment');
import { Subscription } from 'rxjs';
import { NotificationType, Notification } from './notification';
import { SharedService } from '../../services/shared.service';
import { TransformService } from '../../services/transform.service';

@Component({
  selector: 'app-notifications-dialog',
  templateUrl: './notifications-dialog.component.html',
  styleUrls: ['./notifications-dialog.component.scss']
})
export class NotificationsDialogComponent implements OnDestroy {
  private subscriptions: Subscription = new Subscription();
  loggedUser: any = JSON.parse(localStorage.getItem('currentUser'));

  @Input() notifications: Notification[] = [];
  @Input() idsArray: number[] = [];
  @Output() loadMore = new EventEmitter<any>();
  numOfNotifications: number = 10;

  get NotificationType() {
    return NotificationType;
  }

  seeMoreId: number | undefined = undefined;

  constructor(private sharedService: SharedService, public transformService: TransformService) { }

  // Approve/read notification
  approveNotification(notificationId: number, approve: boolean) {
    let readedDate = this.transformService.convertDateToTimestamp(moment(new Date()).format('ddd, DD/MM YYYY'), moment(new Date()).format('HH:mm:ss'));
    const foundNotification = this.notifications.find(item => item.id === notificationId);
    foundNotification.readded = true;

    let approveNotificationSub = this.sharedService.approveNotification(notificationId, approve, this.loggedUser.id, readedDate)
      .subscribe((response) => {
        console.log(response);
        if (response) {
          this.loadMore.emit(this.numOfNotifications);
        }
      })
    this.subscriptions.add(approveNotificationSub);
  };

  // Extract name
  getName(input: string): string {
    const index = input.lastIndexOf('/');
    if (index !== -1) {
      return input.substring(0, index).trim();
    } else {
      return input.trim();
    }
  }

  calculateTimeDifference(date: string) {
    let dateToConvert: string = date.split('.')[0];

    let timeZone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let dateToDisplay: any = '';
    if(timeZone.startsWith('America')) {
      let date: any = new Date(dateToConvert);
      date.setHours(date.getHours() - 7)
      dateToDisplay = moment(date).format('YYYY-MM-DDTHH:mm:ss');
    }
    else if(timeZone.startsWith('Europe')) {
      let date: any = new Date(dateToConvert);
      date.setHours(date.getHours() - 1)
      dateToDisplay = moment(date).format('YYYY-MM-DDTHH:mm:ss');
    }
    else {
      dateToDisplay = dateToConvert;
    }
    return dateToDisplay;
  };

  //Open in new tab
  returnRoute(obj: any) {
    let route: string = '';
    if(obj?.data?.driver_id) {
      route = `/admin/drivers/filter-drivers/driver-profile/${obj.data.driver_id}`;
    }
    else {
      route = `/admin/dispatchers/dashboard-dispatch-profile-page/${obj.data.dispatcher_id}`;
    }
    return route;
  };

  //Call api
  onScroll(event: any) {
    if(event.target.scrollHeight <= Math.round(event.target.scrollTop + event.target.offsetHeight + 1)) {
      this.numOfNotifications += 10;
      this.loadMore.emit(this.numOfNotifications);
    } 
  }

  countDays(date1: string, date2: string) {
    let date_1 = new Date(date1);
    let date_2 = new Date(date2);

    let difference = date_1.getTime() - date_2.getTime();
    let totalDays = Math.ceil(difference / (1000 * 3600 * 24));
    let value: string = totalDays === 1 ? totalDays + ' day' : totalDays + ' days';
    return value
  }

  checkDueDate(dueDate: string) {
    let date1 = new Date(moment().format('YYYY-MM-DDT00:00:00'));
    let date2 = new Date(dueDate);
    let Difference_In_Time = date2.getTime() - date1.getTime();
    let value: number = Math.round(Difference_In_Time / (1000 * 3600 * 24));
    if(value < 0) {
      return 'Expired'
    }
    else if(value === 1) {
      return `${value} day left`;
    }
    else {
      return `${value} days left`;
    }
  };

  getNote(obj: any) {
    if(obj.hasOwnProperty('notes')) {
      return obj.notes;
    }
    else {
      return obj.note;
    }
  }

  identify(index, item){
    return item.id; 
  };

  ngOnDestroy(): void {
    this.subscriptions?.unsubscribe();
  };

}

import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-truck-inspection-details-dialog',
  templateUrl: './truck-inspection-details-dialog.component.html',
  styleUrls: ['./truck-inspection-details-dialog.component.scss']
})
export class TruckInspectionDetailsDialogComponent {
  tabsArray: any[] = [
    {name: 'Inspection Items'},
    {name: 'Tires Inspection'},
    {name: 'Rims Inspection'},
    {name: 'Brakes Inspection'}
  ];
  activeIndex: number = 0;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }
  
}

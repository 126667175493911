import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { FleetStatusComponent } from '../fleet-status/fleet-status.component';
import { TransformService } from '@app/modules/shared/services/transform.service';

@Component({
  selector: 'app-hired-terminated-dialog-over',
  templateUrl: './hired-terminated-dialog-over.component.html',
  styleUrls: ['./hired-terminated-dialog-over.component.scss']
})
export class HiredTerminatedDialogOverComponent implements OnInit {
  dataSource: any;
  paySign: string[] = ['SOLO COMPANY', 'TEAM COMPANY'];

  constructor(@Inject(MAT_DIALOG_DATA) public obj: FleetStatusComponent,
              private transformService: TransformService) { }

  ngOnInit(): void {
    console.log(this.obj)
    let index: number = this.obj.elements.dataPointIndex;
    let array: any[] = this.obj.data.array[index];
    this.dataSource = array.filter(elements => {
      return elements !== null;
    });
  }

  getPayValue(data: any) {
    let value: string = '';
    if(this.paySign.includes(data.type)) {
      return value = '$'+ this.transformService.addCommasDots(data.deal)
    } else {
      return value = this.transformService.addCommasDots(data.deal * 100, 'round') + '%';
    }
  }

}

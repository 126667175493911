import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../core/services/auth/auth.guard';
import { TrucksMapPageComponent } from './components/trucks-map-page/trucks-map-page.component';

const routes: Routes = [
    {
		path: '',
		canActivate: [AuthGuard],
		component: TrucksMapPageComponent,
		data: { roles: 40 }
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class TrucksMapRoutingModule { }
import { Component } from '@angular/core';

@Component({
  selector: 'app-trigger-triple-click-dialog',
  templateUrl: './trigger-triple-click-dialog.component.html',
  styleUrls: ['./trigger-triple-click-dialog.component.scss']
})
export class TriggerTripleClickDialogComponent {
  alertManager: boolean = true;
}

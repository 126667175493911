import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrokersToAvoidComponent } from './components/brokers-to-avoid/brokers-to-avoid.component';
import { BrokersToAvoidTableComponent } from './components/brokers-to-avoid/brokers-to-avoid-table/brokers-to-avoid-table.component';
import { BrokersToAvoidRoutingModule } from './brokers-to-avoid.routing';
import { BrokersToAvoidService } from './services/brokers-to-avoid.service';
import { SharedModule } from '../shared/shared.module';
import { AddEditBrokersToAvoidDialogComponent } from './components/brokers-to-avoid/add-edit-brokers-to-avoid-dialog/add-edit-brokers-to-avoid-dialog.component';
import { RemoveFromListConfirmationDialogComponent } from './components/brokers-to-avoid/remove-from-list-confirmation-dialog/remove-from-list-confirmation-dialog.component';
import { BrokersAvoidReasonsDialogComponent } from './components/brokers-to-avoid/add-edit-brokers-to-avoid-dialog/brokers-avoid-reasons-dialog/brokers-avoid-reasons-dialog.component';
import { BrokersToAvoidColumnChartComponent } from './components/brokers-to-avoid/brokers-to-avoid-column-chart/brokers-to-avoid-column-chart.component';
import { DivisionPipe } from './pipes/division.pipe';

@NgModule({
  declarations: [
    BrokersToAvoidComponent,
    BrokersToAvoidTableComponent,
    AddEditBrokersToAvoidDialogComponent,
    RemoveFromListConfirmationDialogComponent,
    BrokersAvoidReasonsDialogComponent,
    BrokersToAvoidColumnChartComponent,
    DivisionPipe
  ],
  imports: [
    CommonModule,
    SharedModule,
    BrokersToAvoidRoutingModule
  ],
  providers: [BrokersToAvoidService]
})
export class BrokersToAvoidModule { }

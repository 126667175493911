import { Component } from '@angular/core';

@Component({
  selector: 'app-statement-page',
  templateUrl: './statement-page.component.html',
  styleUrls: ['./statement-page.component.scss']
})
export class StatementPageComponent {

}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TotalLoadsComponent } from './components/total-loads.component';
import { AuthGuard } from '../core/services/auth/auth.guard';

const routes: Routes = [
	{
		path: '',
		canActivate: [AuthGuard],
		component: TotalLoadsComponent,
		data: { roles: 26 }
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class TotalLoadsRoutingModule { }

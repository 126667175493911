import { Injectable } from '@angular/core';
import * as _G from '../../../_config/globals';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';

@Injectable()

export class SettingsService {
  dataChanged: any = new Subject<any>();
  headers = new HttpHeaders({
		'Content-Type': 'application/json',
		'Authorization': 'Basic SVZTb2x1dGlvbjoxUzBsdXQxMG4yQDIy'
	});
	requestOptions = { headers: this.headers };

  constructor(private http: HttpClient) { }

  getAllDispatchers() {
    return this.http.get(`${_G.extendApiUrl}dispatcher/dispetchers`, this.requestOptions);
  };

  getAllUsers() {
    return this.http.get(`${_G.extendApiUrl}users`, this.requestOptions);
  };

  createUpdateUser(obj: any) {
    let json: any = JSON.stringify(obj);
    return this.http.post(`${_G.extendApiUrl}users`, json, this.requestOptions);
  };

  changeStatus(id: number, isActive: boolean, user: string) {
    return this.http.get(`${_G.extendApiUrl}users/status?id=${id}&status=${isActive}&user=${user}`, this.requestOptions);
  };

  deleteUser(id: number, user: string) {
    return this.http.get(`${_G.extendApiUrl}users/removeuser?id=${id}&user=${user}`, this.requestOptions);
  };

  //Status online/offline
  sendStatus(id: number, status: boolean, date: string, history: boolean) {
    this.http.get(`${_G.extendApiUrl}login/online?id=${id}&status=${status}&date=${date}&history=${history}`, this.requestOptions).subscribe((response: any) => {
      console.log(response);
    });
  };

  getLoginHistory(id: number) {
    return this.http.get(`${_G.extendApiUrl}login/loginhistiry?id=${id}`, this.requestOptions);
  };

  //Users
	getUserLogoutStatus(id: number) {
		return this.http.get(`${_G.extendApiUrl}users/islogout?id=${id}`, this.requestOptions)
	};

	logoutAllUsers() {
		return this.http.get(`${_G.extendApiUrl}users/logout`, this.requestOptions);
	};

  //Set profile picture
  setPicture(id: number, obj: any) {
    console.log(obj);
    return this.http.post(`${_G.extendApiUrl}dispatcher/dispphoto?id=${id}`, obj, this.requestOptions);
  };

  deletePicture(id: number) {
    return this.http.get(`${_G.extendApiUrl}dispatcher/removeuserphoto?id=${id}`, this.requestOptions);
  };

  //Create role
  createUpdateRole(data: any) {
    let json: any = JSON.stringify(data);
    return this.http.post(`${_G.extendApiUrl}login/role`, json, this.requestOptions);
  };

  getAllRoles() {
    return this.http.get(`${_G.extendApiUrl}login/role`, this.requestOptions);
  };

  getRoleHistory() {
    return this.http.get(`${_G.extendApiUrl}login/rolehistory`, this.requestOptions);
  };

  deleteRole(id: number, userName: string, date: string) {
    return this.http.get(`${_G.extendApiUrl}login/removerole?id=${id}&user=${userName}&date=${date}`, this.requestOptions);
  };

  //Department dialog
  getAllDepartments() {
    return this.http.get(`${_G.extendApiUrl}users/department`, this.requestOptions);
  };

  createUpdateDepartment(obj: any) {
    let json: any = JSON.stringify(obj);
    return this.http.post(`${_G.extendApiUrl}users/department`, json, this.requestOptions);
  };

  deleteDepartment(departmentId: number) {
    return this.http.get(`${_G.extendApiUrl}users/deletedepartment?id=${departmentId}`, this.requestOptions);
  };

}

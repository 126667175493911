import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TrucksService } from '@app/modules/trucks/services/trucks.service';
import moment = require('moment');
import { ApexAxisChartSeries, ApexChart, ApexDataLabels, ApexFill, ApexGrid, ApexLegend, ApexPlotOptions, ApexXAxis, ApexYAxis, 
ChartComponent } from 'ng-apexcharts';
import { Subscription } from 'rxjs';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  fill: ApexFill;
  legend: ApexLegend;
  xaxis: ApexXAxis;
  plotOptions: ApexPlotOptions;
  dataLabels: ApexDataLabels,
};

@Component({
  selector: 'app-timeline-chart',
  templateUrl: './timeline-chart.component.html',
  styleUrls: ['./timeline-chart.component.scss']
})
export class TimelineChartComponent implements OnInit, OnDestroy {
  @ViewChild("chart") chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;

  subscription: Subscription | any;

  constructor(private trucksService: TrucksService) { }

  ngOnInit() {
    this.showGraph();
  };

  showGraph() {
    this.subscription = this.trucksService.timelineChartSubject.subscribe((response: any) => {
      if(!response.reset) {
        this.transformDataForTimelineChart(response.data);
      } else {
        this.chartOptions = undefined;
      }
    });
  }

  transformDataForTimelineChart(list: any[]) {
    let chartData: any = {data: []};
    
    let countOtr: number = 0;
    let countRepairs: number = 0;
    let countWithoutDriver: number = 0;

    for(let key in list) {
      if(list[key].status === 'OTR') {
        chartData.data.push(
          {
            x: 'OTR',
            y: [
              new Date(list[key].startDate).getTime(),
              new Date(list[key].endDate).getTime()
            ],
            fill: {
              type: "gradient",
              gradient: {
                type: "horizontal",
                gradientToColors: ['#003fa2', '#0062ff'],
                inverseColors: true,
                opacityFrom: 1,
                opacityTo: 1,
                stops: [50, 70, 100]
              }
            },
          },
        )
        countOtr++;
      }
      if(list[key].status === 'REPAIRS') {
        chartData.data.push(
          {
            x: 'REPAIRS',
            y: [
              new Date(list[key].startDate).getTime(),
              new Date(list[key].endDate).getTime()
            ],
            fillColor: '#ff900c'
          },
        )
        countRepairs++;
      }
      if(list[key].status === 'NO DRIVER') {
        chartData.data.push(
          {
            x: 'WITHOUT DRIVER',
            y: [
              new Date(list[key].startDate).getTime(),
              new Date(list[key].endDate).getTime()
            ],
            fillColor: '#8b8b8b'
          },
        )
        countWithoutDriver++
      }
    };

    if(countOtr == 0) {
      chartData.data.push(
        {
          x: 'OTR',
          y: [],
          fill: {
            type: "gradient",
            gradient: {
              type: "horizontal",
              gradientToColors: ['#003fa2', '#0062ff'],
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [50, 70, 100]
            }
          },
        },
      )
    } 
    if(countRepairs == 0) {
      chartData.data.push(
        {
          x: 'REPAIRS',
          y: [],
          fillColor: '#ff900c'
        },
      )
    }
    if(countWithoutDriver == 0) {
      chartData.data.push(
        {
          x: 'WITHOUT DRIVER',
          y: [],
          fillColor: '#8b8b8b'
        }
      )
    }

    this.initTimeLineChart(chartData)
  }

  initTimeLineChart(series: any) {
    this.chartOptions = {
      series: [series],
      chart: {
        height: 350,
        type: "rangeBar",
        zoom: {
          enabled: false
        },
        toolbar: {
          show: true,
          tools: {
            download: false
          }
        }
      },
      plotOptions: {
        bar: {
          horizontal: true
        }
      },
      dataLabels: {
        enabled: true,
        formatter: function(val, opts) {
          var a = moment(val[0]);
          var b = moment(val[1]);
          var diff = b.diff(a, "days");
          return diff + (diff > 1 ? " days" : " day");
        },
        style: {
          colors: ["#f3f4f5", "#fff"]
        }
      },
      xaxis: {
        type: "datetime",
        labels: {
          format: 'ddd MM.dd'
        }
      },
      legend: {
        show: false
      }
    };
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

}

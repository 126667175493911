import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MapsComponent } from './components/maps.component';
import { SharedModule } from '../shared/shared.module';
import { MapsRoutingModule } from './maps.routing';
import { MapsService } from './services/maps.service';

@NgModule({
  declarations: [
    MapsComponent
  ],
  imports: [
    CommonModule,
    MapsRoutingModule,
    SharedModule
  ],
  providers: [MapsService]
})
export class MapsModule { }

import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-truck-device-confirmation-delete-dialog',
  templateUrl: './truck-device-confirmation-delete-dialog.component.html',
  styleUrls: ['./truck-device-confirmation-delete-dialog.component.scss']
})
export class TruckDeviceConfirmationDeleteDialogComponent {

  constructor(public dialogRef: MatDialogRef<TruckDeviceConfirmationDeleteDialogComponent>) { }

  deleteRow() {
    this.dialogRef.close(true);
  };

}

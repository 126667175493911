import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { BusinessPartnersService } from '@app/modules/business-partners/services/business-partners.service';
import { RulesService } from '@app/modules/shared/services/rules.service';
import { SharedService } from '@app/modules/shared/services/shared.service';
import { TransformService } from '@app/modules/shared/services/transform.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription, catchError, throwError } from 'rxjs';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-business-partners-table',
  templateUrl: './business-partners-table.component.html',
  styleUrls: ['./business-partners-table.component.scss']
})
export class BusinessPartnersTableComponent implements OnInit, OnDestroy {
  @Input() date: any;

  dataSource: any;

  sortedData: any;

  displayedColumns: string[] = ['position', 'broker', 'gross', 'income_percent', 'rate', 'avg_load_length', 'avg_weight',
  'delivered_loads', 'delivered_loads_percent', 'cancelled_loads', 'cancelled_loads_percent', 'avg_detention_time', 'rank'];

  fileName = 'brokers-table.xlsx';

  stObj: any = {
    totalLoads: 0,
    totalGross: 0,
    totalIncomePercent: 0,
    avgRpm: 0,
    avgLoadLength: 0,
    avgWeight: 0,
    totalDeliveredLoads: 0,
    avgDeliveredLoadsPercent: 0,
    totalCanceledLoads: 0,
    avgCanceledLoadsPercent: 0,
    avgDetentionTime: 0
  };

  //Search
  isOpenSearch: boolean = false;
  searchText: string = '';
  selectedUsers: any[] = new Array<any>();
  dataTypingArray: any[] = [];

  //Interval
  interval: any;

  loaded: boolean = false;

  error: boolean = false;

  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";

  spinnerBgColor: string = this.transformService.spinnerBgColor;
  spinnerType: string = this.transformService.spinnerType;

  subscription1: Subscription | any;
  subscription2: Subscription | any;

  constructor(public transformService: TransformService, 
              private businessPartnersService: BusinessPartnersService,
              private spinner: NgxSpinnerService,
              private rulesService: RulesService) { }

  ngOnInit(): void {
    this.spinner.show('brokers-table');
    this.getTableData();
    this.dateChanged();
    if(this.rulesService.liveUpdate) {
      this.interval = setInterval(() => {
        this.getTableData();
      }, this.rulesService.miliseconds);
    }
  }

  getTableData() {
    this.subscription1 = this.businessPartnersService.getBrokersTableData(this.date.startDate, this.date.endDate, this.transformService.filterParams)
    .pipe(catchError((err: any) => {
      this.spinner.hide('brokers-table');
      this.loaded = true;
      this.error = true;
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      this.countStatistics(response);
      this.dataSource = JSON.parse(JSON.stringify(response));
      this.dataTypingArray = JSON.parse(JSON.stringify(response));
      this.sortedData = response;
      this.spinner.hide('brokers-table');
      this.loaded = true;
    });
  }

  dateChanged() {
    this.subscription2 = this.businessPartnersService.dateChanged.subscribe((response: any) => {
      this.date.startDate = response.startDate;
      this.date.endDate = response.endDate;
      this.sortedData = undefined;

      this.error = false;
      this.loaded = false;

      this.spinner.show('brokers-table');
      this.getTableData();
    })
  };

  sortData(sort: Sort) {
    const data = this.dataSource.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }
    this.sortedData = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'broker':
          return compare(a.broker, b.broker, isAsc);
        case 'gross':
          return compare(a.gross, b.gross, isAsc);
        case 'income_percent':
          return compare(a.income_percent, b.income_percent, isAsc);
        case 'rate':
          return compare(a.rate, b.rate, isAsc);
        case 'avg_load_length':
          return compare(a.avg_load_length, b.avg_load_length, isAsc);
        case 'avg_weight':
          return compare(a.avg_weight, b.avg_weight, isAsc);
        case 'delivered_loads':
          return compare(a.delivered_loads, b.delivered_loads, isAsc);
        case 'delivered_loads_percent':
          return compare(a.delivered_loads_percent, b.delivered_loads_percent, isAsc);
        case 'cancelled_loads':
          return compare(a.cancelled_loads, b.cancelled_loads, isAsc);
        case 'cancelled_loads_percent':
          return compare(a.cancelled_loads_percent, b.cancelled_loads_percent, isAsc);
        case 'avg_detention_time':
          return compare(a.avg_detention_time, b.avg_detention_time, isAsc);
        case 'rank':
          return compare(a.rank, b.rank, isAsc);
        default:
          return 0;
      }
    });
  }

  countStatistics(data: any) {
    let numOfLoads: number = data.length;
    let sumGross: number = 0;
    let sumIncomePercent: number = 0;
    let sumMileage: number = 0;
    let sumLoadLength: number = 0;
    let sumWeight: number = 0;
    let sumDeliveredLoads: number = 0;
    let sumDeliveredLoadsPercent: number = 0;
    let sumCanceledLoads: number = 0;
    let sumDetentionTime: number = 0;

    for(let key in data) {
      sumGross += data[key].gross; 
      sumIncomePercent += data[key].income_percent;
      sumMileage += data[key].mileage;
      sumLoadLength += data[key].avg_load_length;
      sumWeight += data[key].avg_weight;
      sumDeliveredLoads += data[key].delivered_loads;
      sumCanceledLoads += data[key].cancelled_loads;
      sumDetentionTime += data[key].detention_time;
    }

    this.stObj.totalLoads = this.transformService.addCommasDots(numOfLoads, 'round');
    this.stObj.totalGross = this.transformService.addCommasDots(sumGross, 'round');
    this.stObj.totalIncomePercent = this.transformService.addCommasDots(sumIncomePercent, 'round');
    this.stObj.avgRpm = this.transformService.addCommasDots(sumGross / sumMileage);
    this.stObj.avgLoadLength = this.transformService.addCommasDots(sumLoadLength / numOfLoads, 'round');
    this.stObj.avgWeight = this.transformService.addCommasDots(sumWeight / numOfLoads, 'round');
    this.stObj.totalDeliveredLoads = this.transformService.addCommasDots(sumDeliveredLoads, 'round');
    this.stObj.avgDeliveredLoadsPercent = this.transformService.addCommasDots((sumDeliveredLoadsPercent / (sumDeliveredLoads + sumCanceledLoads)) * 100);
    
    this.stObj.totalCanceledLoads = this.transformService.addCommasDots(sumCanceledLoads, 'round');
    this.stObj.avgCanceledLoadsPercent = this.transformService.addCommasDots((sumCanceledLoads / (sumDeliveredLoads + sumCanceledLoads)) * 100);
    this.stObj.avgDetentionTime = this.secondsToDhms(sumDetentionTime / numOfLoads);
  }

  secondsToDhms(seconds: number) {
    seconds = Number(seconds)
    let d = Math.floor(seconds / (3600 * 24));
    let h = Math.floor((seconds % (3600 * 24)) / 3600);
    let m = Math.floor((seconds % 3600) / 60);

    let time: string = '';

    if(d > 0 && h > 0 && m > 0) {
      time = d+'d ' + h + ':' + m + 'h';
    } 
    else if(h > 0 && m > 0) {
      time = h + ':' + m + 'h';
    }
    else {
      time = m + 'h';
    }
    return time;
  }

  //Search methods
  toggleSelection(user: any) {
    user.selected = !user.selected;
    if (user.selected) {
      this.selectedUsers.push(user);
      this.sortedData = JSON.parse(JSON.stringify(this.selectedUsers));
    } else {
      const i = this.selectedUsers.findIndex(value => value.broker === user.broker);
      this.selectedUsers.splice(i, 1);
      this.sortedData = JSON.parse(JSON.stringify(this.selectedUsers));
    }
    this.sortedData.length === 0 ? this.sortedData = JSON.parse(JSON.stringify(this.dataSource)) : this.sortedData
    this.countStatistics(this.sortedData);
}

  resetCheckedValue() {
    let data: any = JSON.parse(JSON.stringify(this.dataSource));
    this.sortedData = data;
    this.dataTypingArray = data;
    this.searchText = '';
    this.selectedUsers = [];
    this.countStatistics(this.sortedData);
  }

  exportToExcel() {
    let arrayToExport: any[] = [];

    for(let i = 0; i < this.dataSource.length; i++) {
      let obj: any = {Position: i + 1, Broker: this.dataSource[i].brooker, 
      Gross: this.transformService.addCommasDots(this.dataSource[i].gross, 'round'),
      Income_Percent: this.transformService.addCommasDots(this.dataSource[i].income_percent), 
      Rate: '$' + this.transformService.addCommasDots(this.dataSource[i].rate),
      Avg_Load_Length: this. transformService.addCommasDots(this.dataSource[i].avg_load_length, 'round'),
      Avg_Weight: this.transformService.addCommasDots(this.dataSource[i].avg_weight, 'round'),
      Delivered_Loads: this.transformService.addCommasDots(this.dataSource[i].delivered_loads, 'round'),
      Delivered_Loads_Percent: this.transformService.addCommasDots(this.dataSource[i].delivered_loads_percent),
      Canceled_Loads: this.transformService.addCommasDots(this.dataSource[i].cancelled_loads, 'round'),
      Canceled_Loads_Percent: this.transformService.addCommasDots(this.dataSource[i].cancelled_loads_percent),
      Detention_Time: this.dataSource[i].avg_detention_time,
      Rank: this.dataSource[i].rank
      }

      arrayToExport.push(obj);
    }

    var wb = XLSX.utils.book_new();
    var ws = XLSX.utils.json_to_sheet(arrayToExport);
    XLSX.utils.book_append_sheet(wb, ws, 'Brokers');
    XLSX.writeFile(wb, `${'brokers-table'}.xlsx`);
  };

  ngOnDestroy(): void {
    clearInterval(this.interval);
    this.subscription1?.unsubscribe();
    this.subscription2?.unsubscribe();
  }

}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

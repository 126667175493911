import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { TransformService } from '@app/modules/shared/services/transform.service';
import moment = require('moment');
import { ApexAxisChartSeries, ApexChart, ApexDataLabels, ApexPlotOptions, ApexYAxis, ApexXAxis, ApexFill, ApexTooltip, ChartComponent } from 'ng-apexcharts';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  fill: ApexFill,
  colors: any;
  tooltip: ApexTooltip
};

@Component({
  selector: 'truck-mileage-column-chart',
  templateUrl: './truck-mileage-column-chart.component.html',
  styleUrls: ['./truck-mileage-column-chart.component.scss']
})
export class TruckMileageColumnChartComponent implements OnInit {
  @ViewChild("chart") chart: ChartComponent | any;
  @Input() date: any;
  @Input() data: any;
  public chartOptions: Partial<ChartOptions> | any;

  constructor(private transfromService: TransformService) { }

  ngOnInit(): void {
    let dateRange: string = `Date: ${moment(this.date.startDate).format('MM/DD/YYYY')} - ${moment(this.date.endDate).format('MM/DD/YYYY')}`;
    let titlesData: string[] = ['TOTAL TRUCKS', 'INACTIVE TRUCKS', '0 - 2000 MILES', '2000 - 2500 MILES', '2500 - 3000 MILES', '3000 - 3900 MILES', 
    '3900+ MILES'];
    let seriesData: number[] = [this.data.total_trucks, this.data.inactive_trucks, this.data._2000, this.data._2000_2500, this.data._2500_3000, 
    this.data._3000_3900, this.data._3900];
    this.chartOptions = {
      series: [
        {
          name: "TOTAL TRUCKS",
          data: [this.data.total_trucks]
        },
        {
          name: "INACTIVE TRUCKS",
          data: [this.data.inactive_trucks]
        },
        {
          name: "0 - 2000 MILES",
          data: [this.data._2000]
        },
        {
          name: "2000 - 2500 MILES",
          data: [this.data._2000_2500]
        },
        {
          name: "2500 - 3000 MILES",
          data: [this.data._2500_3000]
        },
        {
          name: "3000 - 3900 MILES",
          data: [this.data._3000_3900]
        },
        {
          name: "3900+ MILES",
          data: [this.data._3900]
        }
      ],
      chart: {
        toolbar: {
          show: true,
          tools: {
            download: false
          }
        },
        type: "bar",
        height: 500,
        fontFamily: 'Poppins'
      },
      plotOptions: {
        bar: {
          dataLabels: {
            position: "top",
            columnWidth: "80%",
            endingShape: "rounded"
          }
        }
      },
      dataLabels: {
        enabled: true,
        offsetY: -30,
        style: {
          fontSize: "14px",
          colors: ["#304758"]
        }
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"]
      },
      xaxis: {
        categories: [dateRange]
      },
      colors: ['#ff1515', '#fffc15', '#00be62', '#1c6f46', '#0030FF', '#FF00FF', '#800000'],
      tooltip: {
        style: {
          fontFamily: 'Poppins'
        },
        custom: ({series, seriesIndex, dataPointIndex, w}) => {
          let percent: number = (seriesData[seriesIndex] / this.data.total_trucks) * 100;
          return `<div style="background: linear-gradient(to right, #ffffff 0%, #f3f4f6 100%);">
          <div style="padding: 0px 16px; height: 35px; line-height: 35px; border-bottom: 1px solid #0000001f;">${dateRange}</div>
            <div style="padding: 0px 16px; height: 35px; line-height: 35px; border-bottom: 1px solid #0000001f;">TOTAL TRUCKS: <span style="font-size: 16px; font-weight: 600;">${this.data.total_trucks}</span></div>
            <div style="padding: 0px 16px; height: 35px; line-height: 35px; border-bottom: 1px solid #0000001f;">TRUCKS: <span style="font-size: 16px; font-weight: 600;">${seriesData[seriesIndex]}</span></div>
            <div style="padding: 0px 16px; height: 35px; line-height: 35px;">${titlesData[seriesIndex]}: <span style="font-size: 16px; font-weight: 600;">${this.transfromService.addCommasDots(percent)}%</span></div>
          </div>`;
        }
      }
    }; 
  }

}

import { Injectable } from '@angular/core';
import * as _G from '../../../_config/globals';
import { HttpHeaders, HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TrucksMapService {
  headers = new HttpHeaders({
		'Content-Type': 'application/json',
		'Authorization': 'Basic SVZTb2x1dGlvbjoxUzBsdXQxMG4yQDIy'
	});
	requestOptions = { headers: this.headers };

  constructor ( private http: HttpClient ) {}

  getMapData() {
    return this.http.get(`${_G.extendApiUrl}samsara/getFleetMapData`, this.requestOptions);
  };

  getTruckTrailerInfo(isTruck: number, assetName: string) {
	  return this.http.get(`${_G.extendApiUrl}samsara/getFleetTruckTrailerPopupData?isTruck=${isTruck}&assetName=${assetName}`, this.requestOptions);
  };

  getTrucksByDispatchers() {
    return this.http.get(`${_G.extendApiUrl}routingapp/getDispatcherTrucks`, this.requestOptions);
  };

}

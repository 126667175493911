import { Component, Input } from '@angular/core';
import { RulesService } from '@app/modules/shared/services/rules.service';
import { TransformService } from '@app/modules/shared/services/transform.service';

@Component({
  selector: 'app-truck-performance-cards',
  templateUrl: './truck-performance-cards.component.html',
  styleUrls: ['./truck-performance-cards.component.scss']
})
export class TruckPerformanceCardsComponent {
  permissions: any = this.rulesService.UserData[13].data[0].sectionArray;
  @Input() cardsData: any;

  slideToggleOn: boolean = true;

  expandedCardsArray: any = [
    {expanded: false}, {expanded: false}, {expanded: false}, {expanded: false},
    {expanded: false}, {expanded: false}, {expanded: false}
  ];

  constructor(public transformService: TransformService, private rulesService: RulesService) { }

  expandCard(obj: any) {
    obj.expanded = !obj.expanded;
  };

  onChange() {
    this.slideToggleOn = !this.slideToggleOn;
  }

}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filedsSearch'
})
export class FiledsSearchPipe implements PipeTransform {

  transform(array: any[], value: string): any[] {
    if (!array) {
      return [];
    }
 
    return array.filter((obj: any) => {
      let valueLowerCase: string = value.toLowerCase();
      let condition1: boolean = obj.title.toLocaleLowerCase().startsWith(valueLowerCase);
      let condition2: boolean = obj.calendarTitle.toLocaleLowerCase().startsWith(valueLowerCase);
      let condition3: boolean = obj.timepickerTitle.toLocaleLowerCase().startsWith(valueLowerCase);

      if(condition1 || condition2 || condition3) { 
        return obj;
      }

    });

  }

}

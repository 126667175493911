import { NgModule } from '@angular/core';
import { CommonModule, DatePipe, TitleCasePipe } from '@angular/common';

import { CustomSelectComponent } from './components/custom-select/custom-select.component';

import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SearchPipe } from './pipes/search.pipe';
import { DateAsAgoPipe } from './pipes/date-as-ago.pipe';
import { DndDirective } from './directives/dnd.directive';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { DropdownTriggerForDirective } from './directives/dropdown-trigger-for.directive';
import { LoginMessagesDialogComponent } from './components/login-messages-dialog/login-messages-dialog.component';
import { ShortenPipe } from './pipes/shorten.pipe';
import { CompanyBenefitMilesDialogComponent } from './components/company-benefit-miles-dialog/company-benefit-miles-dialog.component';
import { RouterModule } from '@angular/router';

import { NgApexchartsModule } from 'ng-apexcharts';
import { NotificationsDialogComponent } from './components/notifications-dialog/notifications-dialog.component';
import { PopupComponent } from './components/popup/popup.component';
import { StatusPopupComponent } from './components/status-popup/status-popup.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { DeleteConfirmationDialogComponent } from './components/delete-confirmation-dialog/delete-confirmation-dialog.component';
import { SuccessDialogComponent } from './components/success-dialog/success-dialog.component';
import { SearchMultiplePipe } from './pipes/search-multiple.pipe';
import { NotificationsSnackbarComponent } from './components/notifications-snackbar/notifications-snackbar.component';
import { TicketsWarningsReasonsDialogComponent } from './components/tickets-warnings-reasons-dialog/tickets-warnings-reasons-dialog.component';
import { MsgForbbidenAccessComponent } from './components/msg-forbbiden-access/msg-forbbiden-access.component';
import { CustomSliderComponent } from './components/custom-slider/custom-slider.component';
import { MaterialModule } from '../material/material.module';
import { CalendarDialogComponent } from './components/calendar-dialog/calendar-dialog.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { ActiveTrucksDialogComponent } from './components/active-trucks-dialog/active-trucks-dialog.component';
import { TruckStatusHistoryDialogComponent } from './components/active-trucks-dialog/truck-status-history-dialog/truck-status-history-dialog.component';
import { ActiveTrucksRentToRunDialogComponent } from './components/active-trucks-rent-to-run-dialog/active-trucks-rent-to-run-dialog.component';
import { DispatcherStatusDialogComponent } from './components/dispatcher-status-dialog/dispatcher-status-dialog.component';
import { EmailDialogComponent } from './components/email-dialog/email-dialog.component';
import { EmptyTrucksAnalysisDialogComponent } from './components/empty-trucks-analysis-dialog/empty-trucks-analysis-dialog.component';
import { EmptyTrucksDialogComponent } from './components/empty-trucks-dialog/empty-trucks-dialog.component';
import { LoadsDocumentationDialogComponent } from './components/loads-documentation-dialog/loads-documentation-dialog.component';
import { LoadsWithMarginDialogComponent } from './components/loads-with-margin-dialog/loads-with-margin-dialog.component';
import { PermissionDeniedDialogComponent } from './components/permission-denied-dialog/permission-denied-dialog.component';
import { StatusDeleteDialogComponent } from './components/status-delete-dialog/status-delete-dialog.component';
import { NoteHistoryDialogComponent } from './components/status-dialog/note-history-dialog/note-history-dialog.component';
import { StatusDialogComponent } from './components/status-dialog/status-dialog.component';
import { TwAddUpdateCategoryComponent } from './components/tickets-warnings-reasons-dialog/tw-add-update-category/tw-add-update-category.component';
import { WarningMsgDialogComponent } from './components/warning-msg-dialog/warning-msg-dialog.component';

import { EmptyTrucksFilterPipe } from './pipes/empty-trucks-filter.pipe';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateDifferencePipe } from './pipes/date-difference.pipe';
import { ActiveTrailersDialogComponent } from './components/active-trailers-dialog/active-trailers-dialog.component';
import { MultiPropertySearchPipe } from './pipes/multi-property-search.pipe';
import { TruckTrailerMapDialogComponent } from './components/active-trailers-dialog/truck-trailer-map-dialog/truck-trailer-map-dialog.component';
import { TrailerNoteDialogComponent } from './components/active-trailers-dialog/trailer-note-dialog/trailer-note-dialog.component';
import { CreateUpdateLocationComponent } from './components/active-trailers-dialog/create-update-location/create-update-location.component';
import { DriverStatusFilterPipe } from './pipes/driver-status-filter.pipe';
import { LoadMapDialogComponent } from './components/load-map-dialog/load-map-dialog.component';
import { EmployeeStatusDialogComponent } from './components/employee-status-dialog/employee-status-dialog.component';
import { TaskNoteDialogComponent } from './components/task-note-dialog/task-note-dialog.component';
import { DriverStatusPipe } from './pipes/driver-status.pipe';
import { NotificationsTasksSnackbarComponent } from './components/notifications-tasks-snackbar/notifications-tasks-snackbar.component';
import { ClosingWithoutNoteDialogComponent } from './components/task-note-dialog/closing-without-note-dialog/closing-without-note-dialog.component';
import { ChatInboxComponent } from './components/chat-inbox/chat-inbox.component';
import { ImagePreviewComponent } from './components/image-preview/image-preview.component';
import { RemoveDuplicatesPipe } from './pipes/remove-duplicates.pipe';
import { BooleanPipe } from './pipes/boolean.pipe';
import { MessageInfoDialogComponent } from './components/message-info-dialog/message-info-dialog.component';
import { SortPipe } from './pipes/sort.pipe';
import { CheckboxSelectComponent } from './components/checkbox-select/checkbox-select.component';
import { AssignedConfirmationDialogComponent } from './components/assigned-confirmation-dialog/assigned-confirmation-dialog.component';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { DropdownOneSelectComponent } from './components/dropdown-one-select/dropdown-one-select.component';
import { CalendarOneDateSelectComponent } from './components/calendar-one-date-select/calendar-one-date-select.component';
import { DispNamePipe } from './pipes/disp-name.pipe';
import { FormatNumPipe } from './pipes/format-num.pipe';
import { FilterMultipleOptionsComponent } from './components/filter-multiple-options/filter-multiple-options.component';
import { NoteInfoMsgDialogComponent } from './components/note-info-msg-dialog/note-info-msg-dialog.component';
import { InputWithDropdownOneSelectComponent } from './components/input-with-dropdown-one-select/input-with-dropdown-one-select.component';
import { DropdownMultipleSelectComponent } from './components/dropdown-multiple-select/dropdown-multiple-select.component';
import { ConfirmActionDialogComponent } from './components/confirm-action-dialog/confirm-action-dialog.component';
import { FilterNumericComponent } from './components/filter-numeric/filter-numeric.component';
import { FilterBooleanComponent } from './components/filter-boolean/filter-boolean.component';
import { FilterCalendarComponent } from './components/filter-calendar/filter-calendar.component';

const maskConfig: Partial<IConfig> = {
  validation: false,
};

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'MM/DD/YYYY',
  },
  display: {
    dateInput: 'ddd, MM/DD/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  },
};

@NgModule({
  declarations: [
    //Components
    ActiveTrucksDialogComponent,
    TruckStatusHistoryDialogComponent,
    ActiveTrucksRentToRunDialogComponent,
    CalendarComponent,
    CalendarDialogComponent,
    CompanyBenefitMilesDialogComponent,
    CustomSelectComponent,
    CustomSliderComponent,
    DeleteConfirmationDialogComponent,
    DispatcherStatusDialogComponent,
    DropdownComponent,
    EmailDialogComponent,
    EmptyTrucksAnalysisDialogComponent,
    EmptyTrucksDialogComponent,
    LoadsDocumentationDialogComponent,
    LoadsWithMarginDialogComponent,
    LoginMessagesDialogComponent,
    MsgForbbidenAccessComponent,
    NotificationsDialogComponent,
    NotificationsSnackbarComponent,
    PermissionDeniedDialogComponent,
    PopupComponent,
    StatusDeleteDialogComponent,
    StatusDialogComponent,
    NoteHistoryDialogComponent,
    StatusPopupComponent,
    SuccessDialogComponent,
    TicketsWarningsReasonsDialogComponent,
    TwAddUpdateCategoryComponent,
    WarningMsgDialogComponent,
    EmployeeStatusDialogComponent,
    AssignedConfirmationDialogComponent,
    NoteInfoMsgDialogComponent,
    //Directives
    DndDirective,
    DropdownTriggerForDirective,
    //Pipes
    DateAsAgoPipe,
    EmptyTrucksFilterPipe,
    SearchMultiplePipe,
    SearchPipe,
    ShortenPipe,
    DateDifferencePipe,
    ActiveTrailersDialogComponent,
    MultiPropertySearchPipe,
    TruckTrailerMapDialogComponent,
    TrailerNoteDialogComponent,
    CreateUpdateLocationComponent,
    DriverStatusFilterPipe,
    LoadMapDialogComponent,
    TaskNoteDialogComponent,
    DriverStatusPipe,
    NotificationsTasksSnackbarComponent,
    ClosingWithoutNoteDialogComponent,
    ChatInboxComponent,
    ImagePreviewComponent,
    RemoveDuplicatesPipe,
    BooleanPipe,
    MessageInfoDialogComponent,
    SortPipe,
    CheckboxSelectComponent,
    AssignedConfirmationDialogComponent,
    SafeUrlPipe,
    DropdownOneSelectComponent,
    CalendarOneDateSelectComponent,
    DispNamePipe,
    FormatNumPipe,
    FilterMultipleOptionsComponent,
    NoteInfoMsgDialogComponent,
    InputWithDropdownOneSelectComponent,
    DropdownMultipleSelectComponent,
    ConfirmActionDialogComponent,
    FilterNumericComponent,
    FilterBooleanComponent,
    FilterCalendarComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    RouterModule,  
    FormsModule, 
    ReactiveFormsModule,
    MaterialModule,
    CKEditorModule,
    NgxSpinnerModule,
    NgApexchartsModule,
    NgxMaskModule.forRoot(maskConfig)
  ],
  exports: [
    //Components
    ActiveTrucksDialogComponent,
    TruckStatusHistoryDialogComponent,
    ActiveTrucksRentToRunDialogComponent,
    CalendarComponent,
    CalendarDialogComponent,
    CompanyBenefitMilesDialogComponent,
    CustomSelectComponent,
    CustomSliderComponent,
    DeleteConfirmationDialogComponent,
    DispatcherStatusDialogComponent,
    DropdownComponent,
    EmailDialogComponent,
    EmptyTrucksAnalysisDialogComponent,
    EmptyTrucksDialogComponent,
    LoadsDocumentationDialogComponent,
    LoadsWithMarginDialogComponent,
    LoginMessagesDialogComponent,
    MsgForbbidenAccessComponent,
    NotificationsDialogComponent,
    NotificationsSnackbarComponent,
    PermissionDeniedDialogComponent,
    PopupComponent,
    StatusDeleteDialogComponent,
    StatusDialogComponent,
    NoteHistoryDialogComponent,
    StatusPopupComponent,
    SuccessDialogComponent,
    TicketsWarningsReasonsDialogComponent,
    TwAddUpdateCategoryComponent,
    WarningMsgDialogComponent,
    ActiveTrailersDialogComponent,
    LoadMapDialogComponent,
    EmployeeStatusDialogComponent,
    TaskNoteDialogComponent,
    ImagePreviewComponent,
    CheckboxSelectComponent,
    DropdownOneSelectComponent,
    CalendarOneDateSelectComponent,
    NoteInfoMsgDialogComponent,
    InputWithDropdownOneSelectComponent,
    DropdownMultipleSelectComponent,
    ConfirmActionDialogComponent,
    FilterNumericComponent,
    FilterBooleanComponent,
    FilterCalendarComponent,
    
    //Directives
    DndDirective,
    DropdownTriggerForDirective,
    //Pipes
    DateAsAgoPipe,
    EmptyTrucksFilterPipe,
    SearchMultiplePipe,
    SearchPipe,
    ShortenPipe,
    DateDifferencePipe,
    MultiPropertySearchPipe,
    RemoveDuplicatesPipe,
    BooleanPipe,
    SafeUrlPipe,
    DispNamePipe,
    FormatNumPipe,
    FilterMultipleOptionsComponent,
    //Built in
    HttpClientModule,
    RouterModule,  
    FormsModule, 
    ReactiveFormsModule,
    CKEditorModule,
    MaterialModule,
    NgApexchartsModule,
    NgxSpinnerModule,
    NgxMaskModule,
    SortPipe
  ],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
    DatePipe, TitleCasePipe, DispNamePipe, FormatNumPipe
  ]
})
export class SharedModule { }

export interface ILoad {
	load_no: string,
	date: string;
	total_broker: number
	dollar_per_mile: number;
	mileage: number;
} 

export class Load implements ILoad {
	constructor() {}
	load_no: string;
	date: string;
	total_broker: number;
	dollar_per_mile: number;
	mileage: number;
	pickup_zip: string;
	delivery_zip: string;
	mileageRange: string;
	googleMileage: number;
} 

export class MapCircle {
	constructor() {}

    latitude: string;
	longitude: string;
	miles:  string;
}

export class LoadCalculation {

	constructor() {}
	dollar_per_mile;
	gross;
	mileage;
}

export class LoadCalculationGrouped {
	googleMileage: string;
	mileageRange: string;
	count: number;
	value: LoadCalculation;
	loads: Load[];
}

export class LoadCalculationResult {
	grouped?: LoadCalculationGrouped[];
	loads?: Load[];
}

export class LoadCalculationTimePoint {
	title: string;
	data: LoadCalculationResult
}
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterByGroup'
})
export class FilterByGroupPipe implements PipeTransform {

  transform(array: any[], value: string): any[] {
    return array.filter((obj: any) => {
      if((obj.category === value) || (value === 'All tasks') || (obj.category === '')) {
        return obj;
      }
    });
  }

}

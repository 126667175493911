import { Component, Input, OnInit } from '@angular/core';
import { EmployeeService } from '@app/modules/employee/services/employee.service';
import moment = require('moment');
import { catchError, throwError } from 'rxjs';

@Component({
  selector: 'app-employee-documentation',
  templateUrl: './employee-documentation.component.html',
  styleUrls: ['./employee-documentation.component.scss']
})
export class EmployeeDocumentationComponent implements OnInit {
  @Input() data: any;
  files: any[] = [];

  constructor(private employeeService: EmployeeService) { }

  ngOnInit(): void {
    this.files = this.data.obj.documents;
    console.log(this.files);
  }

  onFileSelected(event: any) {  
    let length: any = Object.keys(event.target.files).length;
    for(let i = 0; i < length; i++) {
      this.addMultipleFiles(event, i, length);
    }
  }

  async addMultipleFiles(event: any, index: number, length: number) {
    let loggedUser: any = JSON.parse(localStorage.getItem('currentUser'));
    let fileName = event.target.files[index].name;
    let reader = new FileReader();
    reader.onload = (e: any) => {
      var uint8 = new Uint8Array(e.target.result);
      var result = [];
      for (var i = 0; i < uint8.length; i++) {
        result.push(uint8[i]);
      }
      let obj: any = {
        id: 0,
        employee_id: this.data.obj.id,
        name: fileName,
        uplaoded_date: moment().format('YYYY-MM-DDTHH:mm:ss'),
        uploaded_by: loggedUser.first_name + ' ' + loggedUser.last_name,
        data: result
      };
      this.files.push(obj);
      if(length === this.files.length) {
        let objForServer: any = this.data.obj;
        objForServer.documents = this.files;
        console.log(objForServer);

        console.log(JSON.stringify(objForServer))
        this.saveDocuments(objForServer);
      }
    }
    reader.readAsArrayBuffer(event.target.files[index])
  };

  saveDocuments(objForServer: any) {
    this.employeeService.createEmployee(objForServer)
    .pipe(catchError((err: any) => {
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      console.log(response);
      if(response) {
        this.data.obj = response;
        this.files = response.documents;
        this.employeeService.refreshDataSubject.next(true);
      }
      else {
        this.data.obj.documents = this.files;
      }
    })
  };

  deleteFile(id: number, filename: string, fileId: number, index: number) {
    this.employeeService.deleteEmployeeDocument(id, filename, fileId)
    .subscribe((response: any) => {
      if(response) {
        this.data.obj.documents.splice(index, 1);
        this.files.splice(index, 1);
        this.employeeService.refreshDataSubject.next(true);
      }
      console.log(response)
    })

  };


  downloadFile(id: number, name: string) {
    this.employeeService.getEmployeeDocument(id, name)
    .subscribe((base64: any) => {
      let extension: string = name.substring(name.length - 3);
      if(extension === 'pdf') {
        const byteCharacters = atob(base64);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        window.open(url, '_blank');
      }
      else {
        let image = document.createElement('img');
        image.src = `data:image/${extension};base64,` + base64;
        image.setAttribute('style', 'width: 100%')
        let w = window.open("");
        w.document.write(image.outerHTML);
      }
    })
  };

}

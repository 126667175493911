import { Component, Input, OnChanges } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { TransformService } from '@app/modules/shared/services/transform.service';

@Component({
  selector: 'app-filter-drivers-table',
  templateUrl: './filter-drivers-table.component.html',
  styleUrls: ['./filter-drivers-table.component.scss']
})
export class FilterDriversTableComponent implements OnChanges {
  displayedColumns: string[] = ['position', 'driver', 'status', 'type', 'hire_date', 'termination_date', 'dealValue',
  'gross', 'mileage', 'avgMileage', 'state', 'paycheck', 'avgPaycheck', 'phone_cell'];
  @Input() dataSource: any;

  //Hover
  className: string = '';
  columnName: string = '';

  //Statistics obj
  stObj: any = {
    avgGross: 0,
    avgTotalMileage: 0,
    avgMileage: 0,
    avgTotalPaycheck: 0,
    avgPaycheck: 0,
  }

  constructor(public transformService: TransformService, private router: Router) { }

  ngOnChanges(): void {
    if(this.dataSource) {
      console.log(this.dataSource)
      let sumGross: number = 0;
      let sumMileage: number = 0;
      let sumPaycheck: number = 0;

      let avgMileage: number = 0;
      let avgPaycheck: number = 0;

      this.dataSource.forEach(element => {
        sumGross += element.gross;
        sumMileage += element.mileage;
        sumPaycheck += element.paycheck;

        avgMileage += element.avgMileage;
        avgPaycheck += element.avgPaycheck;
      });

      this.stObj.avgGross = this.transformService.addCommasDots(sumGross / this.dataSource.length, 'round');
      this.stObj.avgTotalMileage = this.transformService.addCommasDots(sumMileage / this.dataSource.length, 'round');
      this.stObj.avgMileage = this.transformService.addCommasDots(avgMileage / this.dataSource.length, 'round');

      this.stObj.avgTotalPaycheck = this.transformService.addCommasDots(sumPaycheck / this.dataSource.length, 'round');
      this.stObj.avgPaycheck = this.transformService.addCommasDots(avgPaycheck / this.dataSource.length, 'round');

    } 
  }

  //Hover effect
  onMouseOver(columnName: string) {
    this.columnName = columnName;
    this.className = 'hovered-column';
  }

  onMouseOut(columnName: string) {
    this.columnName = columnName;
    this.className = '';
  }

  //Open in new tab
  openInNewTab(route: string) {
    const url = this.router.serializeUrl(this.router.createUrlTree([route]));
    window.open(url, '_blank');
  }

  sortData(sort: Sort) {
    const data = this.dataSource.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSource = data;
      return;
    } 
    this.dataSource = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'driver':
          return compare(a.driver, b.driver, isAsc);
        case 'status':
          return compare(a.status, b.status, isAsc);
        case 'type':
          return compare(a.type, b.type, isAsc);
        case 'hire_date':
          return compare(a.hire_date, b.hire_date, isAsc);
        case 'termination_date':
          return compare(a.termination_date, b.termination_date, isAsc);
        case 'dealValue':
          return compare(a.dealValue, b.dealValue, isAsc);
        case 'gross':
          return compare(a.gross, b.gross, isAsc);
        case 'mileage':
          return compare(a.mileage, b.mileage, isAsc);
        case 'avgMileage':
        return compare(a.avgMileage, b.avgMileage, isAsc);
        case 'state':
          return compare(a.state, b.state, isAsc);
        case 'paycheck':
          return compare(a.paycheck, b.paycheck, isAsc);
        case 'avgPaycheck':
          return compare(a.avgPaycheck, b.avgPaycheck, isAsc);
        case 'phone_cell':
          return compare(a.phone_cell, b.phone_cell, isAsc);
        default:
          return 0;
      }
    });
  }

}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

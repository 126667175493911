import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { FormControl, FormGroup } from '@angular/forms';
import { RulesService } from '@app/modules/shared/services/rules.service';
import { TransformService } from '@app/modules/shared/services/transform.service';
import { RecruitingService } from '../../services/recruiting.service';

@Component({
  selector: 'app-recruiting-page',
  templateUrl: './recruiting-page.component.html',
  styleUrls: ['./recruiting-page.component.scss']
})
export class RecruitingPageComponent implements OnInit {
  permissions: any = this.rulesService.UserData[8].data[0].allowedAll;
  selected: FormControl;
  dateFormGroup: FormGroup;

  cardsDataFirst: any;
  cardsDataSecond: any;

/*  dateObj: any = {
    groupBy: 'hours',
    startDate: this.transformService.convertDateToTimestamp(moment().startOf('month').format('ddd, MM/DD/YYYY'),
      '00:00:00'),
    endDate: this.transformService.convertDateToTimestamp(moment().format('ddd, MM/DD/YYYY'), moment().format('HH:mm:ss')),
    period: 'month'
  };*/
  dateObj: any = {
    groupBy: 'hours',
    startDate: this.transformService.convertDateToTimestamp(moment().format('ddd, MM/DD/YYYY'), moment().format('HH:mm:ss')),
    endDate: this.transformService.convertDateToTimestamp(moment().format('ddd, MM/DD/YYYY'), moment().format('HH:mm:ss')),
    period: 'month'
  };

  constructor(private dataService: RecruitingService, private transformService: TransformService, private rulesService: RulesService) {
    this.selected = new FormControl(['present today']);
    this.dateFormGroup = new FormGroup({
      start: new FormControl(moment()),
      end: new FormControl(moment()),
    });
}

  ngOnInit(): void {
    this.dataService.getCards().subscribe((response: any) => {
      this.cardsDataFirst = response[0].data;
      let copy = JSON.parse(JSON.stringify(response[1].data));
      copy.active_trucks = copy.active;
      copy.active = copy.active_drivers + '/' + copy.active;
      copy.empty_trucks_percent = copy.empty_trucks_percent + ' %';
      
     // this.cardsDataSecond = response[1].data;
      this.cardsDataSecond = copy;
    });
  }

  selectDate(value: string) {
    this.selected = new FormControl([value]);
  };

  quickDataSubmit(obj: any) {
    this.dataService.dateSubject.next(obj);
  };

  //Calendar methods
  dateRangeChange(startDate: any, endDate: any) {
    if (startDate.value && endDate.value) {
      this.dateObj.startDate = this.transformService.convertDateToTimestamp(startDate.value, '00:00:00');
      this.dateObj.endDate = this.transformService.convertDateToTimestamp(endDate.value, moment().format('HH:mm:ss'));

      this.dataService.dateSubject.next(this.dateObj);
    };
  }
  /*
  savePdf() {
    let DATA: any = document.getElementById('htmlData');
    html2canvas(DATA).then((canvas) => {
      const FILEURI = canvas.toDataURL('image/png');
      var imgWidth = 210;
      var pageHeight = 290;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;
      let PDF = new jsPDF('p', 'mm', 'a4', true);
      let position = 0;
      PDF.addImage(FILEURI, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        PDF.addPage();
        PDF.addImage(FILEURI, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
        heightLeft -= pageHeight;
      }

      PDF.save('report.pdf');
      console.log(canvas);
    });
  };
  */
}

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { TransformService } from '@app/modules/shared/services/transform.service';

import * as XLSX from 'xlsx'; 

@Component({
  selector: 'app-breakdown-by-year-trailers-dialog',
  templateUrl: './breakdown-by-year-trailers-dialog.component.html',
  styleUrls: ['./breakdown-by-year-trailers-dialog.component.scss']
})
export class BreakdownByYearTrailersDialogComponent implements OnInit {

  displayedColumns: string[] = ['position', 'unit_no', 'make', 'model', 'year', 'status', 'dot_inspection_exp_date', 
  'maintenance_cost', 'cost_per_mile'];
  dataSource: any[] = [];
  fileName: string = 'trailers-table.xlsx';
  
  avgObj: any = {
    numOfTrailers: 0,
    maintCpm: 0,
    maintCost: 0,
    total_maint_cost: 0,
  }

  yearArray: any[] = [
    {year: '2015', selected: false, key: '_2015_list'},
    {year: '2016', selected: false, key: '_2016_list'},
    {year: '2017', selected: false, key: '_2017_list'},
    {year: '2018', selected: false, key: '_2018_list'},
    {year: '2019', selected: false, key: '_2019_list'},
    {year: '2020', selected: false, key: '_2020_list'},
    {year: '2021', selected: false, key: '_2021_list'},
    {year: '2022 and younger', selected: false, key: '_2022_list'},
  ];

  constructor(@Inject(MAT_DIALOG_DATA) private data: any, public transformService: TransformService) {}

  ngOnInit() {
    let key: string = this.data.card;
    this.yearArray[this.data.index].selected = true;
    this.dataSource = this.data.obj[key];
    this.getTotal(this.data.obj[key])
  };

  getTotal(data: any[]) {
    let numOfTrailers: number = data.length;
    let sumMaintCpm: number = 0;
    let sumMaintCost: number = 0;

    for(let key in data) {
      sumMaintCpm += data[key].cost_per_mile;
      sumMaintCost += data[key].maintenance_cost;
    }

    this.avgObj.numOfTrailers = numOfTrailers;
    this.avgObj.maintCpm = this.transformService.addCommasDots((sumMaintCpm / numOfTrailers));
    this.avgObj.maintCost = this.transformService.addCommasDots((sumMaintCost / numOfTrailers), 'round');
    this.avgObj.total_maint_cost = this.transformService.addCommasDots(sumMaintCost, 'round');
  };

  choseMileage(mileageObj: any) {
    let dataArray: any[] = [];
    mileageObj.selected = !mileageObj.selected;
    for(let key in this.yearArray) {
      if(this.yearArray[key].selected) {
        dataArray = dataArray.concat(this.data.obj[this.yearArray[key].key]);
      }
    }
    this.dataSource = dataArray;
    this.getTotal(dataArray);
  };

  //Export to excel
  exportToExcel() {
    let element = document.getElementById('trailers-table'); 
    const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);

    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    XLSX.writeFile(wb, this.fileName);
  };

  //Sort data
  sortData(sort: Sort) {
    const data =  this.dataSource.slice();
    
    if (!sort.active || sort.direction === '') {
      this.dataSource = data;
      return;
    }
    this.dataSource = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'position':
          return compare(a.position, b.position, isAsc);
        case 'unit_no':
          return compare(a.unit_no, b.unit_no, isAsc);
        case 'make':
          return compare(a.make, b.make, isAsc);
        case 'model':
          return compare(a.model, b.model, isAsc);
        case 'year':
          return compare(a.year, b.year, isAsc);
        case 'status':
          return compare(a.status, b.status, isAsc);
        case 'dot_inspection_exp_date':
          return compare(a.dot_inspection_exp_date, b.dot_inspection_exp_date, isAsc);
        case 'maintenance_cost':
          return compare(a.maintenance_cost, b.maintenance_cost, isAsc);
        case 'cost_per_mile':
          return compare(a.cost_per_mile, b.cost_per_mile, isAsc);
        default:
          return 0;
      }
    });
  }
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
import { Injectable } from '@angular/core';
import { IFullAppFields } from '../models/full-app-fields';

@Injectable({
  providedIn: 'root'
})
export class TransformDataService {

  constructor() { }

  getInputs(obj: any) {
    let data: any = obj.DataJson;
    let array: IFullAppFields[] = [
    // <!--Step basic info-->
    {id: -1, full_app_option: 1, title: 'First name', value: data.basicInfoFormGroup.firstName, 
    selectArray: '', calendarTitle: '', date: null, timepickerTitle: '', time: null, checkboxSelectedValues: '', 
    radioBtnSelected: false, users: 0},
    {id: -1, full_app_option: 1, title: 'Middle name', value: data.basicInfoFormGroup.middleName, 
    selectArray: '', calendarTitle: '', date: null, timepickerTitle: '', time: null, checkboxSelectedValues: '', 
    radioBtnSelected: false, users: 0},
    {id: -1, full_app_option: 1, title: 'Last name', value: data.basicInfoFormGroup.lastName, 
    selectArray: '', calendarTitle: '', date: null, timepickerTitle: '', time: null, checkboxSelectedValues: '', 
    radioBtnSelected: false, users: 0},
    {id: -1, full_app_option: 1, title: 'Phone number', value: data.basicInfoFormGroup.phoneNumber, 
    selectArray: '', calendarTitle: '', date: null, timepickerTitle: '', time: null, checkboxSelectedValues: '', 
    radioBtnSelected: false, users: 0},
    {id: -1, full_app_option: 1, title: 'Email', value: data.basicInfoFormGroup.email, 
    selectArray: '', calendarTitle: '', date: null, timepickerTitle: '', time: null, checkboxSelectedValues: '', 
    radioBtnSelected: false, users: 0},
    {id: -1, full_app_option: 1, title: 'Zip Code', value: data.basicInfoFormGroup.zipCode, 
    selectArray: '', calendarTitle: '', date: null, timepickerTitle: '', time: null, checkboxSelectedValues: '', 
    radioBtnSelected: false, users: 0},
    //<!--Step current address-->
    {id: -1, full_app_option: 1, title: 'Street', value: data.currentAddressGroup.street, 
    selectArray: '', calendarTitle: '', date: null, timepickerTitle: '', time: null, checkboxSelectedValues: '', 
    radioBtnSelected: false, users: 0},
    {id: -1, full_app_option: 1, title: 'Unit #', value: data.currentAddressGroup.unit, 
    selectArray: '', calendarTitle: '', date: null, timepickerTitle: '', time: null, checkboxSelectedValues: '', 
    radioBtnSelected: false, users: 0},
    {id: -1, full_app_option: 1, title: 'Zip Code', value: data.currentAddressGroup.zipCode, 
    selectArray: '', calendarTitle: '', date: null, timepickerTitle: '', time: null, checkboxSelectedValues: '', 
    radioBtnSelected: false, users: 0},
    {id: -1, full_app_option: 1, title: 'City', value: data.currentAddressGroup.city, 
    selectArray: '', calendarTitle: '', date: null, timepickerTitle: '', time: null, checkboxSelectedValues: '', 
    radioBtnSelected: false, users: 0},
    {id: -1, full_app_option: 1, title: 'State', value: data.currentAddressGroup.state, 
    selectArray: '', calendarTitle: '', date: null, timepickerTitle: '', time: null, checkboxSelectedValues: '', 
    radioBtnSelected: false, users: 0},
    //<!--Step previous address-->
    ...data.previousAddressGroup.havePreviousAddress ? 
    [{id: -1, full_app_option: 1, title: 'Street (previous)', value: data.previousAddressGroup.street, 
    selectArray: '', calendarTitle: '', date: null, timepickerTitle: '', time: null, checkboxSelectedValues: '', 
    radioBtnSelected: false, users: 0},
    {id: -1, full_app_option: 1, title: 'Unit # (previous)', value: data.previousAddressGroup.unit, 
    selectArray: '', calendarTitle: '', date: null, timepickerTitle: '', time: null, checkboxSelectedValues: '', 
    radioBtnSelected: false, users: 0},
    {id: -1, full_app_option: 1, title: 'Zip Code (previous)', value: data.previousAddressGroup.zipCode, 
    selectArray: '', calendarTitle: '', date: null, timepickerTitle: '', time: null, checkboxSelectedValues: '', 
    radioBtnSelected: false, users: 0},
    {id: -1, full_app_option: 1, title: 'City (previous)', value: data.previousAddressGroup.city, 
    selectArray: '', calendarTitle: '', date: null, timepickerTitle: '', time: null, checkboxSelectedValues: '', 
    radioBtnSelected: false, users: 0},
    {id: -1, full_app_option: 1, title: 'State (previous)', value: data.previousAddressGroup.state, 
    selectArray: '', calendarTitle: '', date: null, timepickerTitle: '', time: null, checkboxSelectedValues: '', 
    radioBtnSelected: false, users: 0}] : [],
    //<!--Step CDL-->
    ...data.cdlGroup.haveCdl === 0 ? [
      {id: -1, full_app_option: 1, title: 'Issue state', value: data.cdlGroup.issueState, 
      selectArray: '', calendarTitle: '', date: null, timepickerTitle: '', time: null, checkboxSelectedValues: '', 
      radioBtnSelected: false, users: 0},
      {id: -1, full_app_option: 1, title: 'CDL #', value: data.cdlGroup.cdl, 
      selectArray: '', calendarTitle: '', date: null, timepickerTitle: '', time: null, checkboxSelectedValues: '', 
      radioBtnSelected: false, users: 0},
      {id: -1, full_app_option: 4, title: '', value: '', 
      selectArray: '', calendarTitle: 'Expiration date', date: data.cdlGroup.expirationDate, timepickerTitle: '', time: null, checkboxSelectedValues: '', 
      radioBtnSelected: false, users: 0},
      {id: -1, full_app_option: 1, title: 'Experience', value: data.cdlGroup.experience, 
      selectArray: '', calendarTitle: '', date: null, timepickerTitle: '', time: null, checkboxSelectedValues: ''}
    ] : [],
    ...data.cdlGroup.haveCdl === 1 ? [
      {id: -1, full_app_option: 2, title: 'Type of CDL', value: this.getCdlValue(data.cdlTypeGroup), 
      selectArray: "CDL Class A, CDL Class B, CDL Class C, I don't have a CDL", calendarTitle: '', date: null, 
      timepickerTitle: '', time: null, checkboxSelectedValues: '', 
      radioBtnSelected: false, users: 0},
    ] : [],
    //<!--Step Medical certificate-->
    ...data.medicalCertificateGroup.haveCertificate === 0 ? [
      {id: -1, full_app_option: 4, title: '', value: '', 
      selectArray: '', calendarTitle: 'Expiration date', date: data.medicalCertificateGroup.expirationDate, timepickerTitle: '', time: null, 
      checkboxSelectedValues: '', radioBtnSelected: false, users: 0},
    ] : [],
    //<!--Step current employee-->
    {id: -1, full_app_option: 1, title: 'Company name', value: data.currentEmployeeGroup.companyName, 
    selectArray: '', calendarTitle: '', date: null, timepickerTitle: '', time: null, checkboxSelectedValues: '', 
    radioBtnSelected: false, users: 0},
    {id: -1, full_app_option: 4, title: '', value: '', 
    selectArray: '', calendarTitle: 'Hiring date', date: data.currentEmployeeGroup.hiringDate, timepickerTitle: '', time: null, 
    checkboxSelectedValues: '', radioBtnSelected: false, users: 0},
    {id: -1, full_app_option: 4, title: '', value: '', 
    selectArray: '', calendarTitle: 'Termination date', date: data.currentEmployeeGroup.terminationDate, timepickerTitle: '', time: null, 
    checkboxSelectedValues: '', radioBtnSelected: false, users: 0},
    {id: -1, full_app_option: 1, title: 'MC / DOT #', value: data.currentEmployeeGroup.mcDot, 
    selectArray: '', calendarTitle: '', date: null, timepickerTitle: '', time: null, checkboxSelectedValues: '', 
    radioBtnSelected: false, users: 0},
    //<!--Step previous employee-->
    ...data.previousEmployeeGroup.havePreviousEmployees ? this.getPreviousEmployees(data.previousEmployeeGroup) : [],
    //<!--Step accidents-->
    ...data.accidentGroup.haveAccident === 0 ? [
      {id: -1, full_app_option: 1, title: 'Nature of accident', value: data.accidentGroup.natureOfAccident, selectArray: "", 
      calendarTitle: '', date: null, timepickerTitle: '', time: null, checkboxSelectedValues: '',  radioBtnSelected: false, users: 0},
      {id: -1, full_app_option: 4, title: '', value: '', 
      selectArray: '', calendarTitle: 'Year of accident', date: data.accidentGroup.yearOfAccident, timepickerTitle: '', time: null, 
      checkboxSelectedValues: '', radioBtnSelected: false, users: 0},
      {id: -1, full_app_option: 2, title: 'Accident', value: this.getAccidentValue(data.accidentGroup), 
      selectArray: "Preventable, Non preventable", calendarTitle: '', date: null, timepickerTitle: '', time: null, checkboxSelectedValues: '',  
      radioBtnSelected: false, users: 0},
    ] : [],
    //<!--Testing-->
    {id: -1, full_app_option: 2, title: 'Test for drug or alcohol', value: data.testingGroup.haveTest ? 'No' : 'Yes', 
    selectArray: "Yes, No", calendarTitle: '', date: null, timepickerTitle: '', time: null, checkboxSelectedValues: '',  
    radioBtnSelected: false, users: 0},
    {id: -1, full_app_option: 1, title: 'Description', value: data.testingGroup.description, selectArray: "", 
    calendarTitle: '', date: null, timepickerTitle: '', time: null, checkboxSelectedValues: '',  radioBtnSelected: false, users: 0},
    //<!--Step Endorsment-->
    {id: -1, full_app_option: 3, title: 'Endorsments', value: '', selectArray: "Doubles / Triples, Tanker vehicle, Hazardous Materials, Tanker and Hazardous Materials, TWIC Card", 
    calendarTitle: '', date: null, timepickerTitle: '', time: null, checkboxSelectedValues: this.getStepEndorsments(data.endorsmentGroup), 
    radioBtnSelected: false, users: 0},
    //<!--Step Equipment-->
    {id: -1, full_app_option: 3, title: 'Equipment ', value: '', selectArray: "Dry Van 53, Flatbad, Reefer", 
    calendarTitle: '', date: null, timepickerTitle: '', time: null, checkboxSelectedValues: this.getStepEquipments(data.equipmentGroup), 
    radioBtnSelected: false, users: 0},
    //<!--Step OTR Experience-->
    {id: -1, full_app_option: 2, title: 'OTR experience', value: data.otrExperienceGroup.period, 
    selectArray: '5+ years, 2 to 5 years, 1 to 2 years, 6 to 12 months, Less than 6 months', calendarTitle: '', date: null, 
    timepickerTitle: '', time: null, checkboxSelectedValues: '', radioBtnSelected: false, users: 0},
    //<!--Step social security number-->
    {id: -1, full_app_option: 1, title: 'Social Security Number', value: data.socialSecurityNumberGroup.ssn, 
    selectArray: '', calendarTitle: '', date: null, timepickerTitle: '', time: null, checkboxSelectedValues: '', 
    radioBtnSelected: false, users: 0},
    //<!--Step Zip code-->
    {id: -1, full_app_option: 1, title: 'Zip Code ', value: data.zipNumberGroup.zip, 
    selectArray: '', calendarTitle: '', date: null, timepickerTitle: '', time: null, checkboxSelectedValues: '', 
    radioBtnSelected: false, users: 0},
    //<!--Step road in a row-->
    {id: -1, full_app_option: 2, title: 'Road in a row', value: data.roadDurationGroup.period, 
    selectArray: 'more than one month, one month, 3 weeks, 2 weeks, 1 week', calendarTitle: '', date: null, 
    timepickerTitle: '', time: null, checkboxSelectedValues: '', radioBtnSelected: false, users: 0},
    //<!--Step date of birthday-->
    {id: -1, full_app_option: 4, title: '', value: '', 
      selectArray: '', calendarTitle: 'Date of birth', date: data.dateOfBirthdayGroup.dateOfBirthday, timepickerTitle: '', time: null, 
      checkboxSelectedValues: '', radioBtnSelected: false, users: 0},
    //<!--Step Operating area-->
    {id: -1, full_app_option: 2, title: 'Operating area', value: this.getOperatingArea(data.preferOperatingArea),
    selectArray: 'Over the road, Dedicated', calendarTitle: '', date: null, 
    timepickerTitle: '', time: null, checkboxSelectedValues: '', radioBtnSelected: false, users: 0},
    //<!--Step weekly miles-->
    {id: -1, full_app_option: 1, title: 'Miles per week', value: data.weeklyMilesGroup.weeklyMiles, 
    selectArray: '', calendarTitle: '', date: null, timepickerTitle: '', time: null, checkboxSelectedValues: '', 
    radioBtnSelected: false, users: 0},
    //<!--Step position-->
    {id: -1, full_app_option: 2, title: 'Position', value: this.getDivision(data.positionGroup),
    selectArray: 'Company driver, Rent to run driver, Owner operator', calendarTitle: '', date: null, 
    timepickerTitle: '', time: null, checkboxSelectedValues: '', radioBtnSelected: false, users: 0},
    ]
    return [...array, ...obj.extra_fields];
  };

  getCdlValue(data: any) {
    if(data.firstOption) {
      return 'CDL Class A';
    }
    if(data.secondOption) {
      return 'CDL Class B';
    }
    if(data.thirdOption) {
      return 'CDL Class C';
    }
    if(data.fourthOption) {
      return "I don't have a CDL";
    }
  }

  getPreviousEmployees(data: any): any[] {
    let array: any[] = [];
    for(let key in data.previousEmployeeArray) {
      array.push(
      {id: -1, full_app_option: 1, title: 'Company name (previous company)', value: data.previousEmployeeArray[key].companyName, 
      selectArray: '', calendarTitle: '', date: null, timepickerTitle: '', time: null, checkboxSelectedValues: '', 
      radioBtnSelected: false, users: 0},
      {id: -1, full_app_option: 4, title: '', value: '', 
      selectArray: '', calendarTitle: 'Hiring date', date: data.previousEmployeeArray[key].hiringDate, timepickerTitle: '', time: null, 
      checkboxSelectedValues: '', radioBtnSelected: false, users: 0},
      {id: -1, full_app_option: 4, title: '', value: '', 
      selectArray: '', calendarTitle: 'Termination date', date: data.previousEmployeeArray[key].terminationDate, timepickerTitle: '', time: null, 
      checkboxSelectedValues: '', radioBtnSelected: false, users: 0},
      {id: -1, full_app_option: 1, title: 'MC / DOT #', value: data.currentEmployeeGroup?.mcDot, 
      selectArray: '', calendarTitle: '', date: null, timepickerTitle: '', time: null, checkboxSelectedValues: '', 
      radioBtnSelected: false, users: 0})
    }

    return array;
  };

  getAccidentValue(data: any) {
    let value: string = '';
    if(data.isPreventable === 0) {
      value = 'Preventable';
    }
    else if(data.isPreventable === 1) {
      value = 'Non preventable';
    }
    else {
      value = '';
    }
    return value;
  }

  getStepEndorsments(data: any) {
    let value: string = '';
    if(data.firstCheckbox) {
      value+= 'Doubles / Triples, ';
    }
    if(data.secondCheckbox) {
      value+= 'Tanker vehicle, ';
    }
    if(data.thirdCheckbox) {
      value+= ' Hazardous Materials, ';
    }
    if(data.fourthCheckbox) {
      value+= "Tanker and Hazardous Materials, ";
    }
    if(data.fifthCheckbox) {
      value+= "TWIC Card";
    }
    return value.replace(/,*$/, '');
  };

  getStepEquipments(data: any) {
    let value: string = '';
    if(data.firstCheckbox) {
      value+= 'Dry Van 53, ';
    }
    if(data.secondCheckbox) {
      value+= 'Flatbad, ';
    }
    if(data.thirdCheckbox) {
      value+= 'Reefer';
    }
    return value.replace(/,*$/, '');
  };

  getPosition(data: any) {
    let value: string = '';
    if(data.firstCheckbox) {
      return 'Solo driver';
    }
    if(data.secondCheckbox) {
      return 'Team driver';
    }
    return value;
  }

  getOperatingArea(data: any) {
    let value: string = '';
    if(data.firstCheckbox) {
      value+= 'Over the road, ';
    }
    if(data.secondCheckbox) {
      value+= 'Dedicated';
    }
    return value.replace(/,*$/, '');
  };

  getDivision(data: any) {
    let value: string = '';
    if(data.firstCheckbox) {
      return 'Company driver';
    }
    if(data.secondCheckbox) {
      return 'Rent to run driver';
    }
    if(data.thirdCheckbox) {
      return 'Owner operator';
    }
    return value;
  };

  getPaid(data: any) {
    let value: string = '';
    if(data.firstCheckbox) {
      return 'Pay per mile';
    }
    if(data.secondCheckbox) {
      return 'By percentage';
    }
    return value;
  };
  
}

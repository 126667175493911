import { ConnectionPositionPair } from '@angular/cdk/overlay';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TransformService } from '@app/modules/shared/services/transform.service';
import { DriverInfoDialogComponent } from './driver-info-dialog/driver-info-dialog.component';
import { SharedService } from '@app/modules/shared/services/shared.service';
import { Subscription, catchError, throwError } from 'rxjs';
import moment = require('moment');
import { DriversProfileService } from '@app/modules/drivers-profile-page/services/drivers-profile.service';
import { SuccessDialogComponent } from '@app/modules/shared/components/success-dialog/success-dialog.component';
import { WarningMsgDialogComponent } from '@app/modules/shared/components/warning-msg-dialog/warning-msg-dialog.component';
import { DriverFilesDialogComponent } from './driver-files-dialog/driver-files-dialog.component';
import { RulesService } from '@app/modules/shared/services/rules.service';
import { MsgForbbidenAccessComponent } from '@app/modules/shared/components/msg-forbbiden-access/msg-forbbiden-access.component';

@Component({
  selector: 'driver-info-card',
  templateUrl: './driver-info-card.component.html',
  styleUrls: ['./driver-info-card.component.scss']
})
export class DriverInfoCardComponent implements OnInit, OnDestroy {
  permissions: any = this.rulesService.UserData[14].data;
  @Input() data: any;
  formGroup: FormGroup;

  //Driver info menu
  isOpenDriverInfoMenu: boolean = false;
  public positions = [
    new ConnectionPositionPair(
      {originX: 'end', originY: 'bottom'},
      {overlayX: 'end', overlayY: 'top'},
      0, 5
    )
  ];

  public datePositions = [
    new ConnectionPositionPair(
      {originX: 'center', originY: 'bottom'},
      {overlayX: 'center', overlayY: 'top'},
      0, 10
    )
  ];

  onlyRead: boolean = true;
  paySign: string[] = ['SOLO COMPANY', 'TEAM COMPANY'];

  //Driver status
  driverStatusArray: any[] = [
    {name: 'SOLO COMPANY'}, 
    {name: 'TEAM COMPANY'},
    {name: 'SOLO RENT'}, 
    {name: 'TEAM RENT'},
    {name: 'OWNER OPERATOR'}
  ];
  isOpenDriverStatusMenu: boolean = false;

  //Divisions
  divisionArray: any[] = [];
  isOpenDivisionMenu: boolean = false;
  selectedDispatcher: any = {id: 0, name: ''};

  //Dispatchers
  dispatchersArray: any[] = [];
  isOpenDispatcherMenu: boolean = false;

  //Recruiters
  recruitersArray: any[] = [];
  isOpenRecruiterMenu: boolean = false;

  //Driver relations
  driverRelationsArray: any[] = [];
  isOpenDriverRelationsMenu: boolean = false;

  //Dates
  isOpenHiringDate: boolean = false;
  isOpenTerminationDate: boolean = false;
  isOpenCdlDate: boolean = false;
  isOpenMedicalDate: boolean = false;

  //Emails
  emailsArray: any[] = [
    {
      id: 0,
      email: "emily@tempofreightsystems.com",
      name: "Milica Bogdanovic / Emily 502"
    },
    {
      id: 0,
      email: "hailey@tempofreightsystems.com",
      name: "Dina Matic / Hailey 509"
    },
    {
      id: 56,
      email: "hanna@tempofreightsystems.com",
      name: "Ana Tosic / Hanna 508"
    },
    {
      id: 0,
      email: "jenna@tempofreightsystems.com",
      name: "Jelena Nikic / Jenna 501"
    },
    {
      id: 57,
      email: "mary@tempofreightsystems.com",
      name: "Marina Bubanja / Mary 505"
    },
    {
      id: 0,
      email: "stacy@tempofreightsystems.com",
      name: "Sanja Djordjevic / Stacy 504"
    },
    {
      id: 0,
      email: "john@tempofreightsystems.com",
      name: "Nikola Petrovic / John 304"
    },
    {
      id: 5,
      email: "kevin@tempofreightsystems.com",
      name: "Aleksa Korac / Kevin 301"
    },
    {
      id: 155,
      email: "ross@tempofreightsystems.com",
      name: "Uros Kojadinovic / Ross 302"
    },
    {
      id: 13,
      email: "brads@jdmexpedite.com",
      name: "Nikola Savic / Brad 411"
    },
    {
      id: 14,
      email: "hellenp@jdmexpedite.com",
      name: "Jelena Prokic / Hellen 401"
    },
    {
      id: 0,
      email: "jackj@jdmexpedite.com",
      name: "Dusan Jeremic / Jack 409"
    },
    {
      id: 15,
      email: "kristinaj@jdmexpedite.com",
      name: "Kristina Jokanovic / Kristina 402"
    },
    {
      id: 18,
      email: "lexiz@jdmexpedite.com",
      name: "Vanja Zivanovic / Lexi 408"
    },
    {
      id: 0,
      email: "mattj@jdmexpedite.com",
      name: "Darko Jelenic / Matt 403"
    },
    {
      id: 0,
      email: "staceyt@jdmexpedite.com",
      name: "Olga Tucakovic / Stacey  404"
    },
    {
      id: 17,
      email: "tatianam@jdmexpedite.com",
      name: "Tatjana Marjanovic / Tatiana 407"
    },
    {
      id: 0,
      email: "wills@jdmexpedite.com",
      name: "Stefan Stefanovic / Will 405"
    },
    {
      id: 9,
      email: "daniel@tempofreightsystems.com",
      name: "Danijel Ilic / Daniel 202"
    },
    {
      id: 0,
      email: "ivan@tempofreightsystems.com",
      name: "Ivan Jelenic / Ivan 204"
    },
    {
      id: 0,
      email: "frontdesk@tempofreightsystems.com",
      name: "Jasmina Dimitrievska / Jasmine - Front Desk 450"
    },
    {
      id: 2,
      email: "luke@tempofreightsystems.com",
      name: "Aleksandar Nikolic / Luke 220"
    },
    {
      id: 0,
      email: "ned@tempofreightsystems.com",
      name: "Nemanja Zivkovic / Ned 705"
    },
    {
      id: 0,
      email: "tina@tempofreightsystems.com",
      name: "Andjela Andrejevic / Tina 203"
    },
    {
      id: 115,
      email: "tom@tempofreightsystems.com",
      name: "Predrag Kitic / Tom K. 110"
    },
    {
      id: 45,
      email: "neo@tempofreightsystems.com",
      name: "Nemanja Ignjatovic / Neo 101"
    }

  ];

  //Subscription
  subscription1: Subscription = new Subscription();
  subscription2: Subscription = new Subscription();
  subscription3: Subscription = new Subscription();

  constructor(private formBuilder: FormBuilder, 
              private transformService: TransformService,
              private dialog: MatDialog, 
              private sharedService: SharedService, 
              private driversProfileService: DriversProfileService,
              private rulesService: RulesService) {
    this.formGroup = this.formBuilder.group({
      'id': [null],
      'division_id': [-1],
      'division_name': null,
      'city': [null],
      'state': [null],
      'zip': [null],
      'phone_cell': [null],
      'phone_emergency': [null],
      'email': [null],
      'company_name': [null],
      'company_city': [null],
      'company_state': [null],
      'company_street1': [null],
      'company_zip': [null],
      'cdl_no': [null],
      'cdl_state': [null],
      'cdl_expiration': [null],
      'medical_card_expiration_date': [null],
      'hire_date': [null],
      'termination_date': [null],
      'recruiter': [''],
      'driver_relations': [''],
      'status': ['']
    })
  }

  ngOnInit(): void {
    let obj: any = this.data.info;
    this.formGroup.setValue({
      'id': obj.id,
      'division_id': -1,
      'division_name': obj.division,
      'city': obj.city,
      'state': obj.state,
      'zip': null,
      'phone_cell': obj.phone_cell,
      'phone_emergency': obj.phone_emergency,
      'email': obj.email,
      'company_name': obj.company_name,
      'company_city': null,
      'company_state': null,
      'company_street1': null,
      'company_zip': null,
      'cdl_no': obj.cdl_no,
      'cdl_state': obj.cdl_state,
      'cdl_expiration': obj.cdl_exp_date,
      'medical_card_expiration_date': obj.mdeical_exp_date,
      'hire_date': obj.hire_date,
      'termination_date': obj.termination_date,
      'recruiter': obj.recruiter,
      'driver_relations': obj.driver_relations,
      'status': obj.deal
    })
    this.selectedDispatcher.id = obj.dispatcher_id;
    this.selectedDispatcher.name = obj.dispatcher;
    this.getDivisionsData();
    this.getAllDispatchers();
    this.getAllEmployees();
  }

  getDivisionsData() {
    this.subscription1 = this.sharedService.getCompanyData().subscribe((response: any) => {
      console.log(response);
      this.divisionArray = response.divisions;
    });
  };

  getAllDispatchers() {
    this.subscription2 = this.sharedService.getAllDispatchers().subscribe((response: any) => {
      for(let i = 0; i < response.length; i++) {
        if(response[i].is_active) {
          this.dispatchersArray.push(response[i]);
        }
      };
    })
  };

  getAllEmployees() {
    this.subscription3 = this.sharedService.getAllEmployees().subscribe((response: any) => {
      for(let i = 0; i < response.length; i++) {
        if(response[i].department === 'Recruiting') {
          this.recruitersArray.push(response[i]);
        };
        if(response[i].department === 'Driver Relations') {
          this.driverRelationsArray.push(response[i]);
        }
      };
    })
  };

  editInfo() {
    this.isOpenDriverInfoMenu = false;
    if(this.permissions[0].allowedAll) {
      this.onlyRead = false;
    }
    else {
      this.dialog.open(MsgForbbidenAccessComponent, {
        autoFocus: false,
        panelClass: 'forbidden-msg-dialog-container'
      })
    }
  };

  changeStatus() {
    this.isOpenDriverInfoMenu = false;
    if(this.permissions[1].allowedAll) {
      let dialogRef = this.dialog.open(DriverInfoDialogComponent, {
        autoFocus: false,
        panelClass: 'component-dialog-container',
        data: this.data.info,
        disableClose: true
      });
      dialogRef.afterClosed().subscribe((obj: any) => {
        console.log(obj);
        if(obj) {
          this.data.info.termination_date = obj.terminationDate;
          this.formGroup.patchValue({'termination_date': obj.terminationDate});
          if(obj.terminationDate) {
            this.data.notes.unshift(obj.data);
          }
        }
      });
    }
    else {
      this.dialog.open(MsgForbbidenAccessComponent, {
        autoFocus: false,
        panelClass: 'forbidden-msg-dialog-container'
      })
    }
  };

  saveChanges() {
    let callTwoApis: boolean = false;
    let nicknameAndExtension: string = '';
    if(this.selectedDispatcher.id !== 0 && this.selectedDispatcher.id !== this.data.info.dispatcher_id) {
      callTwoApis = true;
      nicknameAndExtension = this.selectedDispatcher.name?.split('/')[1]?.trim();
    }
    let dataObj: any = {
      dispId: this.selectedDispatcher.id, 
      dispatcherName: nicknameAndExtension,
      truckId: this.data.info.truck_id, 
      oldDispId: this.data.info.dispatcher_id 
    };
    this.driversProfileService.changeDriverInfo(callTwoApis, dataObj, this.formGroup.value)
    .pipe(catchError((err: any) => {
      this.showErrorMsg();
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      console.log(response);
      if(!callTwoApis && response) {
        this.onlyRead = !this.onlyRead;
        if(this.data.info.deal !== this.getFormValue('status')) {
          this.sendAlertAndNotification(this.data);
        };
        this.showSuccessMsg();
      }
      else if(callTwoApis && response[0] && response[1]) {
        let data: any = {...this.selectedDispatcher};
        this.data.info.dispatcher = data.name;
        this.data.info.dispatcher_id = data.id;
        this.onlyRead = !this.onlyRead;
        this.showSuccessMsg();
      }
      else {
        this.showErrorMsg();
      }
    });
  };

  cancelEditing() {
    this.onlyRead = !this.onlyRead;
    let obj: any = this.data.info;
    this.formGroup.reset({
      'id': obj.id,
      'division_id': [-1],
      'division_name': obj.division,
      'city': obj.city,
      'state': obj.state,
      'zip': [null],
      'phone_cell': obj.phone_cell,
      'phone_emergency': obj.phone_emergency,
      'email': obj.email,
      'company_name': obj.company_name,
      'company_city': [null],
      'company_state': [null],
      'company_street1': [null],
      'company_zip': [null],
      'cdl_no': obj.cdl_no,
      'cdl_state': obj.cdl_state,
      'cdl_expiration': obj.cdl_exp_date,
      'medical_card_expiration_date': obj.mdeical_exp_date,
      'hire_date': obj.hire_date,
      'termination_date': obj.termination_date,
      'recruiter': obj.recruiter,
      'driver_relations': obj.driver_relations,
      'status': obj.deal
    });
    this.selectedDispatcher.id = obj.dispatcher_id;
    this.selectedDispatcher.name = obj.dispatcher;
  }

  sendAlertAndNotification(data: any) {
    let infoData: any = data.info;
    let alertData: any = {
      "driver": infoData.name,
      "old_status": infoData.deal,
      "date": moment().format('YYYY-MM-DDTHH:mm:ss'),
      "status": this.getFormValue('status')
    }
    this.data.info.deal = this.getFormValue('status');
    let loggedUser: any = JSON.parse(localStorage.getItem('currentUser'));
    let notificationsArray: any[] = JSON.parse(JSON.stringify(this.emailsArray));
    notificationsArray.push({id: 27, email: 'nikolab@jdmexpedite.com', name: 'Nikola Beloica'});

    let notificationData: any = {
      id: 0,
      driver_id: infoData.id,
      subject: `Driver profile note of status change`,
      create_date:  moment().format('YYYY-MM-DDTHH:mm:ss'),
      creator: `${loggedUser.first_name} ${loggedUser.last_name}`,
      department: loggedUser.department,
      editor: null,
      editor_department: null,
      edit_date: null,
      closed: null,
      closed_by: null,
      closed_by_department: null,
      close_date: null,
      notes: null,
      files: null,
      emails: [JSON.stringify(this.emailsArray)]
    };
    this.driversProfileService.createThreadNoteExhchange(notificationData).subscribe((responseThread: any) => {
      if(responseThread?.id) {
        //Send note
        let obj: any = {
          id: 0,
          note_id: responseThread.id,
          driver_id: infoData.id,
          note: `${infoData.name} is switching from a ${alertData.old_status} driver position to a ${alertData.status} driver position as of ${moment(alertData.date).format('MMM DD, YYYY - HH:mm')}. Please update all the systems accordingly.`,
          note_date: moment().format('YYYY-MM-DDTHH:mm:ss'),
          creator: `${loggedUser.first_name} ${loggedUser.last_name}`,
          creator_department: loggedUser.department,
          editor: null,
          editor_department: null,
          edit_date: null,
          files: []
        }
        this.driversProfileService.createEditNoteExchange(obj).subscribe((responseNote: any) => {
          if(responseNote) {
            let notificationObj: any = JSON.parse(JSON.stringify(responseNote));
            notificationObj.subject =  `Driver profile note of status change`;
            notificationObj.driver_name = infoData.name;
            responseThread.notes = [responseNote];
            data.notes.unshift(responseThread);
            let notificationsArray: any[] = JSON.parse(JSON.stringify(this.emailsArray));
            notificationsArray.push({id: 27, email: 'nikolab@jdmexpedite.com', name: 'Nikola Beloica'});
    
            //Send notification
            for(let key in notificationsArray) {
            
              if(notificationsArray[key].id !== loggedUser.id && notificationsArray[key].id !== 0) {
      
                let obj: any = {
                  id: null,
                  type: 'NOTE',
                  data: JSON.stringify(notificationObj),
                  date: moment().add(1, 'hour').format('YYYY-MM-DDTHH:mm:ss'),
                  from_user: loggedUser.id,
                  for_user: notificationsArray[key].id,
                }
                this.sharedService.sendNotification(obj).subscribe((response: any) => { });
              }
            }

            this.driversProfileService.sendDriverAlert(alertData).subscribe((response: any) => { })
          }
        });
      }
    });
  };

  getPayValue(data: any) {
    if(this.paySign.includes(data.info.deal)) {
      return `$${this.transformService.addCommasDots(data.info.pay / 100)}`;
    } 
    else {
      return `${this.transformService.addCommasDots(data.info.pay, 'round')}%`;
    }
  };

  //Set division
  setDivision(obj: any) {
    this.formGroup.patchValue({'division_id': obj.id});
    this.formGroup.patchValue({'division_name': obj.name});
    this.isOpenDivisionMenu = !this.isOpenDivisionMenu;
  }

  //Set value
  changeDriverDescData(obj: any, changeType: number) {
    if(changeType === 0) {
      this.selectedDispatcher = obj;
      this.isOpenDispatcherMenu = !this.isOpenDispatcherMenu;
    };
    if(changeType === 1) {
      this.formGroup.patchValue({'recruiter': obj.nickname});
      this.isOpenRecruiterMenu = !this.isOpenRecruiterMenu;
    };
    if(changeType === 2) {
      this.formGroup.patchValue({'driver_relations': obj.nickname});
      this.isOpenDriverRelationsMenu = !this.isOpenDriverRelationsMenu;
    };
  };

  //Set date
  setDate(event: any, key: string) {
    this.formGroup.patchValue({[key]: moment(event._d).format('YYYY-MM-DDT00:00:00')});
  };

  //Get form values
  getFormValue(formKey: string) {
    return this.formGroup.controls[formKey].value;
  };

  //Count driving days - expirations
  countExpDays(date: string) {
    let date_1 = new Date(date);
    let date_2 = new Date();
    let difference = date_1.getTime() - date_2.getTime();
    let totalDays = Math.ceil(difference / (1000 * 3600 * 24));
    let value: string = '';
    if(totalDays < 0) {
      value = 'Expired';
    } else {
      value = totalDays === 1 ? `in ${totalDays} day` : `in ${totalDays} days`;
    }
    return value
  };

  countDrivingDays() {
    let hireDate: string | null = this.getFormValue('hire_date');
    let terminationDate: string | null = this.getFormValue('termination_date');
    if(hireDate) {
      let date_1 = new Date(hireDate);
      let date_2 = terminationDate ? new Date(terminationDate) : new Date();
      let difference = date_2.getTime() - date_1.getTime();
      let totalDays = Math.ceil(difference / (1000 * 3600 * 24));
      return totalDays === 1 ? `${totalDays} day` : `${totalDays} days`;
    }
    else {
      return '0 days'
    }

  };

  showSuccessMsg() {
    this.dialog.open(SuccessDialogComponent, {
      autoFocus: false,
      panelClass: 'success-dialog-container'
    });  
  };

  showErrorMsg() {
    this.dialog.open(WarningMsgDialogComponent, {
      autoFocus: false,
      panelClass: 'warning-msg-dialog-container'
    });
  };

  openFilesDialog() {
    this.dialog.open(DriverFilesDialogComponent, {
      autoFocus: false,
      panelClass: 'component-dialog-container',
      data: this.data.info.id
    });
  };

  //Driver type
  getDriverType() {
    let companyType: string[] = ['SOLO COMPANY', 'TEAM COMPANY'];
    let rentType: string[] = ['SOLO RENT', 'TEAM RENT'];
    if(companyType.includes(this.data.info.deal)) {
      return 'Company driver';
    }
    else if(rentType.includes(this.data.info.deal)) {
      return 'Rent driver';
    }
    else {
      return 'Owner';
    } 
  };

  ngOnDestroy(): void {
    this.subscription1?.unsubscribe();  
    this.subscription2?.unsubscribe();  
    this.subscription3?.unsubscribe();  
  };

}

import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import moment = require('moment');
import { Subscription } from 'rxjs';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { CostInfluenceDialogComponent } from './cost-influence-dialog/cost-influence-dialog.component';
import { TransformService } from '@app/modules/shared/services/transform.service';
import { TrucksService } from '@app/modules/trucks/services/trucks.service';

@Component({
  selector: 'app-p-l-category-dialog',
  templateUrl: './p-l-category-dialog.component.html',
  styleUrls: ['./p-l-category-dialog.component.scss']
})
export class PLCategoryDialogComponent implements OnInit, OnDestroy {
  trucksSearch: string = '';
  trailersSearch: string = '';

  effectiveFrom: string = 'Choose a date';
  effectiveUntil: string = 'Choose a date';

  array1: any[] = [
    {viewValue: 'Fixed'},
    {viewValue: 'Variable'}
  ];

  typeOfPlValueInitial: string = 'Select an option';

  array2: any[] = [
    {viewValue: 'Daily'},
    {viewValue: 'Weekly'},
    {viewValue: 'Monthly'},
    {viewValue: 'Quarterly'},
    {viewValue: 'Yearly'}
  ];

  paymentCycleInitial: string = 'Select an option';

  array3: any[] = [
    {viewValue: 'Trucks'},
    {viewValue: 'Trailers'},
  ];

 radioBtns: any[] = [
  {viewValue: 'PROFIT', selected: false},
  {viewValue: 'LOSS', selected: false}
 ]

 //Values
 categoryValue: string = '';

 typeOfPl: string = '';
 paymentCycle: string = '';

 amount: string = '';

 fromDate: string = '';
 toDate: string = '';

 //calendar
 isOpen = false;
 isOpen1 = false;
 isOpen2 = false;

 unitNoTrucks: any[] = [];
 unitNoTrailers: any[] = [];

 value: string = 'Select an option';

 trucks: any = {selected: false};
 trailers: any = {selected: false};

 isEdit: boolean = false;
 createdPl: any;

 subscription1: Subscription | any;
 subscription2: Subscription | any;

constructor(@Inject(MAT_DIALOG_DATA) public data: any,
          private dialog: MatDialog,
          private trucksService: TrucksService, 
          private transformService: TransformService) { }

ngOnInit(): void {
  this.subscription2 = this.trucksService.getAllTrucksTrailers().subscribe((response: any) => {
    this.unitNoTrucks = response.trucks;
    this.unitNoTrailers = response.trailers;
    this.getSavedSelection();
    console.log(response);
  })
}

getSavedSelection() {
  this.subscription1 = this.trucksService.getSavedPl(this.data.unitNo).subscribe((response: any) => {
    if(response.date_created) {
      this.createdPl = response;
      this.isEdit = true;
      this.categoryValue = response.category;
      this.radioBtns[0].selected = response.profit;
      this.radioBtns[1].selected = response.loss;
      //For editing
      this.typeOfPl = response.type;
      this.paymentCycle = response.payment_cicle;
      //Set values
      this.typeOfPlValueInitial = response.type;
      this.paymentCycleInitial = response.payment_cicle;
      this.amount = response.amount;

      this.effectiveFrom = moment(response.effective_from).format('MMM DD, yyy');
      this.effectiveUntil = moment(response.effective_unitl).format('MMM DD, yyy');
      this.fromDate = moment(response.effective_from).format('MMM DD, yyy');
      this.toDate = moment(response.effective_unitl).format('MMM DD, yyy');

      let trucksSelected: any[] = response.spread_amount_over.split(';');
      let trailersSelected: any[] =  response.trailer_no.split(';');
      for(let key in trucksSelected) {
        for(let key2 in this.unitNoTrucks) {
          if(this.unitNoTrucks[key2].unit_no == trucksSelected[key]) {
            this.unitNoTrucks[key2].selected = true;
          }
        }
      }

      for(let key in trailersSelected) {
        for(let key2 in this.unitNoTrailers) {
          if(this.unitNoTrailers[key2].unit_no == trailersSelected[key]) {
            this.unitNoTrailers[key2].selected = true;
          }
        }
      }
    }
  })
}

checkedRadioBtn(obj: any) {
  obj.selected = !obj.selected;
}

selectValue1(value: string) {
  this.typeOfPl = value;
}

selectValue2(value: string) {
  this.paymentCycle = value;
}

showCalendar(firstCalendar: boolean) {
  if(firstCalendar) {
    this.isOpen1 = !this.isOpen1;
  } else {
    this.isOpen2 = !this.isOpen2;
  }
}

dateClicked(event: any, calendarFirst: boolean) {
  if(calendarFirst) {
    this.effectiveFrom = moment(event._d).format('MMM DD, yyy');
    this.fromDate = this.transformService.convertDateToTimestamp(moment(event._d).format('ddd, DD/MM YYYY'), '00:00:00');
    this.isOpen1 = false;
  } else {
    this.effectiveUntil = moment(event._d).format('MMM DD, yyy');
    this.toDate = this.transformService.convertDateToTimestamp(moment(event._d).format('ddd, DD/MM YYYY'),  moment().format('HH:mm:ss'));
    this.isOpen2 = false;
  }
}

addPlCategory() {
  let unitSelectedTrucks: string = this.countSeletedUnits(this.unitNoTrucks);
  let unitSelectedTrailers: string = this.countSeletedUnits(this.unitNoTrailers);
  let loggedUser: any = JSON.parse(localStorage.getItem('currentUser'));
  let obj: any = {
    id: 0,
    truck_no: this.data.unitNo,
    category: this.categoryValue,
    profit: this.radioBtns[0].selected,
    loss: this.radioBtns[1].selected,
    type: this.typeOfPl,
    payment_cicle: this.paymentCycle,
    amount: this.amount,
    spread_amount_over: unitSelectedTrucks,
    trailer_no: unitSelectedTrailers,
    effective_from: this.fromDate,
    effective_unitl: this.toDate,
    creator: loggedUser.username,
    date_created: this.transformService.convertDateToTimestamp(moment().format('ddd, DD/MM YYYY'),  moment().format('HH:mm:ss')),
    editor: null,
    date_edited: null
  }
  if(this.isEdit) {
    obj.id = this.createdPl.id;
    obj.date_created = this.createdPl.date_created;
    obj.editor = loggedUser.username;
    obj.date_edited = this.transformService.convertDateToTimestamp(moment().format('ddd, DD/MM YYYY'),  moment().format('HH:mm:ss'))
  }

  let dialogRef: any = this.dialog.open(ConfirmDialogComponent, {
    width: '435px',
    height: '271px',
    autoFocus: false,
    panelClass: 'pl-confirm-dialog-container',
    data: obj
  });
  dialogRef.afterClosed().subscribe((result: any) => {
    if(result) {
      this.trucksService.saveEditPl(obj).subscribe((response: any) => {
          if(response) {
            console.log(response);
          }
      })    
    }
  });
  console.log(obj);
}

countSeletedUnits(array: any[]) {
  let unitArray: string = '';
  for(let key in array) {
    if(array[key].selected) {
      unitArray += array[key].unit_no + ';'
    }
  }
  return unitArray;
}

//Custom select with submenu
openCloseMenu() {
  this.isOpen = !this.isOpen;
};

selectValue(value: string) {
  this.value = value;
  this.isOpen = false;
}

selectAll(unitsArray: any[], isSelected: any) {
  let complete: boolean = JSON.parse(JSON.stringify(isSelected.selected = !isSelected.selected));
  unitsArray.forEach(t => {(t.selected = complete)});
}

openCostInfluenceDialog() {
  this.dialog.open(CostInfluenceDialogComponent, {
    autoFocus: false,
    panelClass: 'cost-influence-dialog-container',
    //data: obj
  });
}

ngOnDestroy(): void {
  this.subscription1?.unsubscribe();
  this.subscription2?.unsubscribe();
}

}

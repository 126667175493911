export interface IAccountingInvoices {
    id: number,
    invoice_date_from: string,
    invoice_date_to: string,
    crated_date: string,
    crated_by: string
}
  
export class AccountingInvoices implements IAccountingInvoices {
    constructor(public id: number, public invoice_date_from: string, public invoice_date_to: string, 
    public crated_date: string, public crated_by: string) {}
}
  
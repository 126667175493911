import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { SafetyService } from '@app/modules/safety/services/safety.service';

@Component({
  selector: 'app-add-contract-category-dialog',
  templateUrl: './add-contract-category-dialog.component.html',
  styleUrls: ['./add-contract-category-dialog.component.scss']
})
export class AddContractCategoryDialogComponent {
  categoryName: string = '';

  constructor(private safetyService: SafetyService, public dialogRef: MatDialogRef<AddContractCategoryDialogComponent>) { }

  create() {
    this.safetyService.createContractCategory(this.categoryName).subscribe((response: any) => {
      if(response) {
        this.dialogRef.close(true);
      }
    })
  }

}

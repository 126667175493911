import { ConnectionPositionPair } from '@angular/cdk/overlay';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CompletedTaskDialogComponent } from '../completed-task-dialog/completed-task-dialog.component';

@Component({
  selector: 'task-status-dropdown',
  templateUrl: './task-status-dropdown.component.html',
  styleUrls: ['./task-status-dropdown.component.scss']
})
export class TaskStatusDropdownComponent {
  @Input() status: string = '';
  @Output() emitEvent = new EventEmitter<any>();

  //Positions
  public positions = [
    new ConnectionPositionPair(
      {originX: 'end', originY: 'bottom'},
      {overlayX: 'end', overlayY: 'top'},
      0, 1
    )
  ];

  statusArray: any[] = [
    {name: 'Pending', icon: 'assignment', color: 'orange'},
    {name: 'Done', icon: 'done', color: 'green fw'}
  ]
  iconsObj: any = {
    'Pending': 'assignment',
    'Overdue': 'update',
    'Done': 'done'
  };
  isOpenMenu: boolean = false;

  constructor(private dialog: MatDialog) { }

  changeStatus(obj: any, index: number) {
    this.isOpenMenu = !this.isOpenMenu;
    if(obj.name === 'Done') {
      this.openCompleteTaskDialog(obj, 2);
    }
    else {
      this.status = obj.name;
      this.emitEvent.emit(index);
    }
  };

  getClass(status: string) : string {
    if(status === 'Done') {
      return 'border-green';
    }
    else if(status === 'Overdue') {
      return 'border-red';
    } 
    else {
      return 'border-orange';
    };
  };

  openCompleteTaskDialog(obj: any, index: number) {
    let dialogRef = this.dialog.open(CompletedTaskDialogComponent, {
      autoFocus: false,
      panelClass: 'completed-task-dialog-container'
    });
    dialogRef.afterClosed().subscribe((response: any[]) => {
      if(response) {
        this.status = obj.name;
        this.emitEvent.emit(index);
      }
    });
  }

}

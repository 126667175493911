import { OnInit, AfterViewChecked, Component, ElementRef, ViewChild, Input, OnDestroy } from '@angular/core';
import { ITripPlanner } from '@app/modules/full-app/models/trip-planner.model';
import { FullAppService } from '@app/modules/full-app/services/full-app.service';
import moment = require('moment');
import { Subscription } from 'rxjs';

const formData: any = {
  by_flight: {
    title: 'By Flight',
    icon: '../../../../../../assets/img/airplane.png',
    fromFirstInputTitle : 'From',
    fromSecondInputTitle : 'Airport',
    fromThirdInputTitle : 'Date',
    fromFourthInputTitle : 'Boarding time',
    toFirstInputTitle : 'To',
    toSecondInputTitle : 'Airport',
    toThirdInputTitle : 'Date',
    toFourthInputTitle : 'Arrival time',
    fromFirstSelectArray: [
      {name: 'Airport'}, {name: 'Airport'}, {name: 'Airport'}, {name: 'Airport'}
    ],
    fromSecondSelectArray: [
      {name: 'Checked in'}, {name: 'Checked in'}, {name: 'Checked in'}, {name: 'Checked in'}
    ],
    toFirstSelectArray: [
      {name: 'Airport'}, {name: 'Airport'}, {name: 'Airport'}, {name: 'Airport'}
    ],
    toSecondSelectArray: [
      {name: 'Arrived'}, {name: 'Arrived'}, {name: 'Arrived'}, {name: 'Arrived'}
    ],
    //Selected values
    id: 0,
    driver_id: 0,
    trip_type: 'by_flight',
    trip_from_method: '',
    trip_from_status: '',
    trip_from: '',
    trip_from_name: '',
    trip_from_datetime: '',
    trip_to_method: '',
    trip_to_status: '',
    trip_to: '',
    trip_to_name: '',
    trip_to_datetime: '',
    created_by: '',
    created_date: '',
    edited_by: '',
    edited_date: ''
  },
  by_uber: {
    title: 'By Uber',
    icon: '../../../../../../assets/img/car.png',
    fromFirstInputTitle : 'From',
    fromSecondInputTitle : 'Airport',
    fromThirdInputTitle : 'Date',
    fromFourthInputTitle : 'Boarding time',
    toFirstInputTitle : 'To',
    toSecondInputTitle : 'Airport',
    toThirdInputTitle : 'Date',
    toFourthInputTitle : 'Arrival time',
    fromFirstSelectArray: [
      {name: 'Airport'}, {name: 'Airport'}, {name: 'Airport'}, {name: 'Airport'}
    ],
    fromSecondSelectArray: [
      {name: 'Checked in'}, {name: 'Checked in'}, {name: 'Checked in'}, {name: 'Checked in'}
    ],
    toFirstSelectArray: [
      {name: 'Airport'}, {name: 'Airport'}, {name: 'Airport'}, {name: 'Airport'}
    ],
    toSecondSelectArray: [
      {name: 'Arrived'}, {name: 'Arrived'}, {name: 'Arrived'}, {name: 'Arrived'}
    ],
    //Selected values
    id: 0,
    driver_id: 0,
    trip_type: 'by_uber',
    trip_from_method: '',
    trip_from_status: '',
    trip_from: '',
    trip_from_name: '',
    trip_from_datetime: '',
    trip_to_method: '',
    trip_to_status: '',
    trip_to: '',
    trip_to_name: '',
    trip_to_datetime: '',
    created_by: '',
    created_date: '',
    edited_by: '',
    edited_date: ''
  },
  by_bus: {
    title: 'By Bus',
    icon: '../../../../../../assets/img/bus.png',
    fromFirstInputTitle : 'From',
    fromSecondInputTitle : 'Airport',
    fromThirdInputTitle : 'Date',
    fromFourthInputTitle : 'Boarding time',
    toFirstInputTitle : 'To',
    toSecondInputTitle : 'Airport',
    toThirdInputTitle : 'Date',
    toFourthInputTitle : 'Arrival time',
    fromFirstSelectArray: [
      {name: 'Airport'}, {name: 'Airport'}, {name: 'Airport'}, {name: 'Airport'}
    ],
    fromSecondSelectArray: [
      {name: 'Checked in'}, {name: 'Checked in'}, {name: 'Checked in'}, {name: 'Checked in'}
    ],
    toFirstSelectArray: [
      {name: 'Airport'}, {name: 'Airport'}, {name: 'Airport'}, {name: 'Airport'}
    ],
    toSecondSelectArray: [
      {name: 'Arrived'}, {name: 'Arrived'}, {name: 'Arrived'}, {name: 'Arrived'}
    ],
    //Selected values
    id: 0,
    driver_id: 0,
    trip_type: 'by_bus',
    trip_from_method: '',
    trip_from_status: '',
    trip_from: '',
    trip_from_name: '',
    trip_from_datetime: '',
    trip_to_method: '',
    trip_to_status: '',
    trip_to: '',
    trip_to_name: '',
    trip_to_datetime: '',
    created_by: '',
    created_date: '',
    edited_by: '',
    edited_date: ''
  },
  by_train: {
    title: 'By Train',
    icon: '../../../../../../assets/img/train.png',
    fromFirstInputTitle : 'From',
    fromSecondInputTitle : 'Airport',
    fromThirdInputTitle : 'Date',
    fromFourthInputTitle : 'Boarding time',
    toFirstInputTitle : 'To',
    toSecondInputTitle : 'Airport',
    toThirdInputTitle : 'Date',
    toFourthInputTitle : 'Arrival time',
    fromFirstSelectArray: [
      {name: 'Airport'}, {name: 'Airport'}, {name: 'Airport'}, {name: 'Airport'}
    ],
    fromSecondSelectArray: [
      {name: 'Checked in'}, {name: 'Checked in'}, {name: 'Checked in'}, {name: 'Checked in'}
    ],
    toFirstSelectArray: [
      {name: 'Airport'}, {name: 'Airport'}, {name: 'Airport'}, {name: 'Airport'}
    ],
    toSecondSelectArray: [
      {name: 'Arrived'}, {name: 'Arrived'}, {name: 'Arrived'}, {name: 'Arrived'}
    ],
    //Selected values
    id: 0,
    driver_id: 0,
    trip_type: 'by_train',
    trip_from_method: '',
    trip_from_status: '',
    trip_from: '',
    trip_from_name: '',
    trip_from_datetime: '',
    trip_to_method: '',
    trip_to_status: '',
    trip_to: '',
    trip_to_name: '',
    trip_to_datetime: '',
    created_by: '',
    created_date: '',
    edited_by: '',
    edited_date: ''
  },
}

@Component({
  selector: 'full-application-trip-planner',
  templateUrl: './full-application-trip-planner.component.html',
  styleUrls: ['./full-application-trip-planner.component.scss']
})
export class FullApplicationTripPlannerComponent implements OnInit, AfterViewChecked, OnDestroy {
  @Input() id: number;
  @ViewChild('scrollMe') private scrollContainer: ElementRef;
  
  cardsArray: any[] = [
    {key: 'by_flight', title: 'By Flight', img: '../../../../../../assets/img/airplane.png'},
    {key: 'by_uber', title: 'By Uber', img: '../../../../../../assets/img/car.png'},
    {key: 'by_bus', title: 'By Bus', img: '../../../../../../assets/img/bus.png'},
    {key: 'by_train', title: 'By Train', img: '../../../../../../assets/img/train.png'}
  ];

  formArray: any[] = [];

  bgColor: string = 'default-bg';
  scrollAllowed: boolean = false;

  //Subscription
  subscription: Subscription = new Subscription();

  constructor(private fullAppService: FullAppService) { }

  ngOnInit() {
    this.getTripPlannerData();
  };

  getTripPlannerData() {
    this.subscription = this.fullAppService.getTripPlannerByDriverId(this.id).subscribe((response: ITripPlanner[]) => {
      console.log(response);
      this.initForm(response);
    })
  };

  allowDrop(ev: any) {
    ev.preventDefault();
    this.bgColor = 'drag-over';
  }

  dragLeave(ev: any) {
    ev.preventDefault();
    this.bgColor = 'default-bg';
  }
  
  drag(ev: any, key: string) {
    ev.dataTransfer.setData("text", key);
  }
  
  drop(ev: any) {
    ev.preventDefault();
    let formKey: string = ev.dataTransfer.getData("text");
    this.formArray.push(formData[formKey]);
    this.bgColor = 'default-bg';
    this.scrollAllowed = true;
  }

  //Create update trip planner
  createUpdateTripPlanner() {
    let userData: any = JSON.parse(localStorage.getItem('currentUser'));
    for(let i = 0; i < this.formArray.length; i++) {
      let obj: ITripPlanner = {
        id: this.formArray[i].id,
        driver_id: this.id,
        trip_type: this.formArray[i].trip_type,
        trip_from_method: this.formArray[i].trip_from_method,
        trip_from_status: this.formArray[i].trip_from_status,
        trip_from: this.formArray[i].trip_from,
        trip_from_name: this.formArray[i].trip_from_name,
        trip_from_datetime: this.formArray[i].trip_from_datetime,
        trip_to_method: this.formArray[i].trip_to_method,
        trip_to_status: this.formArray[i].trip_to_status,
        trip_to: this.formArray[i].trip_to,
        trip_to_name: this.formArray[i].trip_to_name,
        trip_to_datetime: this.formArray[i].trip_to_datetime,
        created_by: `${userData.first_name} ${userData.last_name}`,
        created_date: moment().format('YYYY-MM-DDTHH:mm:ss'),
        edited_by: '',
        edited_date: ''
      }

      if(this.formArray[i].id) {
        obj.driver_id = this.formArray[i].driver_id;
        obj.created_by = this.formArray[i].created_by;
        obj.created_date = this.formArray[i].created_date;
        obj.edited_by = `${userData.first_name} ${userData.last_name}`;
        obj.edited_date = moment().format('YYYY-MM-DDTHH:mm:ss');
      }
      this.fullAppService.createUpdateTripPlanner(obj).subscribe((response: any) => {
        if(!response?.id) {
          this.formArray = [];
          this.getTripPlannerData();
        }
      });
    };
  };

  deleteForm(eventDelete: boolean, obj: any, index: number) {
    if(eventDelete && obj.id === 0) {
      this.formArray.splice(index, 1);
    }
    else {
      this.fullAppService.deleteTripPlanner(obj.id).subscribe((response: boolean) => {
        if(response) {
          this.formArray.splice(index, 1);
        };
      });
    }
  };

  scrollBottom() {
    this.scrollContainer.nativeElement.scroll({top: this.scrollContainer.nativeElement.scrollHeight, left: 0, behavior: 'smooth'});
  };

  initForm(response: ITripPlanner[]) {
    for(let i = 0; i < response.length; i++) {
      let form: any = JSON.parse(JSON.stringify(formData))[response[i].trip_type];
      form.id = response[i].id;
      form.driver_id = response[i].driver_id;
      form.trip_type = response[i].trip_type;
      form.trip_from_method = response[i].trip_from_method;
      form.trip_from_status = response[i].trip_from_status;
      form.trip_from = response[i].trip_from;
      form.trip_from_name = response[i].trip_from_name;
      form.trip_from_datetime = response[i].trip_from_datetime;
      form.trip_to_method = response[i].trip_to_method;
      form.trip_to_status = response[i].trip_to_status;
      form.trip_to = response[i].trip_to;
      form.trip_to_name = response[i].trip_to_name;
      form.trip_to_datetime = response[i].trip_to_datetime;
      form.created_by = response[i].created_by;
      form.created_date = response[i].created_date;
      form.edited_by = response[i].edited_by;
      form.edited_date = response[i].edited_date;
      this.formArray.push(form);
    };
  }

  ngAfterViewChecked() {
    if(this.scrollAllowed) {
      this.scrollBottom();
      this.scrollAllowed = false;
    };
  };

  ngOnDestroy(): void {
      this.subscription?.unsubscribe();
  };

}
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DashboardDriversSearchPageComponent } from './components/dashboard-drivers-search-page.component';
import { AuthGuard } from '../core/services/auth/auth.guard';

const routes: Routes = [
	{
		path: 'drivers',
		//canActivate: [AuthGuard],
        children: [
            {
                path: 'search',
                canActivate: [AuthGuard],
                component: DashboardDriversSearchPageComponent,
                data: { roles: 20 }
			},
    	],
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class DriversSearchRoutingModule { }

import { TitleCasePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { TransformService } from '@app/modules/shared/services/transform.service';
import moment = require('moment');
import { CreateUpdatePaymentDialogComponent } from '../create-update-payment-dialog/create-update-payment-dialog.component';
import { RulesService } from '@app/modules/shared/services/rules.service';
import { MsgForbbidenAccessComponent } from '@app/modules/shared/components/msg-forbbiden-access/msg-forbbiden-access.component';
import { FormatNumPipe } from '@app/modules/shared/pipes/format-num.pipe';
import { ConnectionPositionPair } from '@angular/cdk/overlay';
import { WarningMsgDialogComponent } from '@app/modules/shared/components/warning-msg-dialog/warning-msg-dialog.component';
import { PaymentsService } from '@app/modules/accounting/services/payments.service';
import { SharedService } from '@app/modules/shared/services/shared.service';
import { catchError, throwError } from 'rxjs';
import { ConfirmActionDialogComponent } from '@app/modules/shared/components/confirm-action-dialog/confirm-action-dialog.component';

@Component({
  selector: 'payments-table',
  templateUrl: './payments-table.component.html',
  styleUrls: ['./payments-table.component.scss']
})
export class PaymentsTableComponent {
  permissions: any = this.rulesService.UserData[37].data;
  //Data source
  @Input() config: any = {columns: [], dataSource: []};

  //Filters
  filters: any = {
    IssuedBy: [],
    DriverName: [],
    unit_no: [],
    DateOfPayment: null,
    PaymentMethod: [],
    PaymantAmount: {sign: 'More than', value: null},
    PaymentIssuedTo: [],
    TypeOfRepair: [],
    InvoiceReceived: 'Both',
    InvoiceUploadedToPT: 'Both',
    MoneyCode: [],
    IssuedAmount: {sign: 'More than', value: null},
    AmountUsed: {sign: 'More than', value: null},
    Stete: [],
    Quantity: {sign: 'More than', value: null},
    FuelReceiptReceived: 'Both',
    ReasonForPeyment: [],
    ReasonForMoneyCode: [],
    fileName: 'Both',
    division: [],
    reviewed: 'Both',
    voided: 'Both'
  };

  //Positions
  public positions = [
    new ConnectionPositionPair(
      {originX: 'end', originY: 'bottom'},
      {overlayX: 'end', overlayY: 'top'},
      0, 5
    )
  ];

  constructor(public transformService: TransformService, 
              private paymentsService: PaymentsService,
              private sharedService: SharedService,
              private titleCasePipe: TitleCasePipe,
              private formatNumPipe: FormatNumPipe,
              private dialog: MatDialog,
              private rulesService: RulesService) { }

  getValue(column: any, value: any) {
    if(column.letterTranform && value !== 'N/A') {
      return this.titleCasePipe.transform(value);
    }
    else if(column.formatNumbers) {
      return `${column.prefix}${this.formatNumPipe.transform(value, column.numOfDecimals)}`;
    }
    else if(column.isDate) {
      return moment(value).format('MMM DD, YYYY.')
    }
    else {
      return value;
    }
  };

  sortData(sort: Sort) {
    const data = JSON.parse(JSON.stringify(this.config.dataSource));
    if (!sort.active || sort.direction === '') {
      this.config.dataSource = data;
      return;
    }
    this.config.dataSource = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'IssuedBy':
          return compare(a.IssuedBy, b.IssuedBy, isAsc);
        case 'DriverName':
          return compare(a.DriverName, b.DriverName, isAsc);
        case 'unit_no':
          return compare(a.unit_no, b.unit_no, isAsc);
        case 'DateOfPayment':
          return compare(new Date(a.DateOfPayment), new Date(b.DateOfPayment), isAsc);
        case 'PaymentMethod':
          return compare(a.PaymentMethod, b.PaymentMethod, isAsc);
        case 'PaymantAmount':
          return compare(a.PaymantAmount, b.PaymantAmount, isAsc);
        case 'PaymentIssuedTo':
          return compare(a.PaymentIssuedTo, b.PaymentIssuedTo, isAsc);
        case 'TypeOfRepair':
            return compare(a.TypeOfRepair, b.TypeOfRepair, isAsc);
        case 'InvoiceReceived':
          return compare(a.InvoiceReceived, b.InvoiceReceived, isAsc);
        case 'InvoiceUploadedToPT':
          return compare(a.InvoiceUploadedToPT, b.InvoiceUploadedToPT, isAsc);
        case 'MoneyCode':
          return compare(a.MoneyCode, b.MoneyCode, isAsc);
        case 'IssuedAmount':
          return compare(a.IssuedAmount, b.IssuedAmount, isAsc);
        case 'AmountUsed':
          return compare(a.AmountUsed, b.AmountUsed, isAsc);
        case 'Stete':
          return compare(a.Stete, b.Stete, isAsc);
        case 'Quantity':
          return compare(a.Quantity, b.Quantity, isAsc);
        case 'FuelReceiptReceived':
          return compare(a.FuelReceiptReceived, b.FuelReceiptReceived, isAsc);
        case 'CardUsed':
          return compare(a.CardUsed, b.CardUsed, isAsc);
        case 'RELAYCode':
          return compare(a.RELAYCode, b.RELAYCode, isAsc);
        case 'ReasonForPeyment':
          return compare(a.ReasonForPeyment, b.ReasonForPeyment, isAsc);
        case 'ReasonForMoneyCode':
          return compare(a.ReasonForMoneyCode, b.ReasonForMoneyCode, isAsc);
        case 'fileName':
          return compare(a.fileName, b.fileName, isAsc);
        case 'division':
          return compare(a.division, b.division, isAsc);
        case 'reviewed':
          return compare(a.reviewed, b.reviewed, isAsc);
        case 'voided':
          return compare(a.voided, b.voided, isAsc);
        default:
          return 0;
      }
    });
  }

  changeCheckboxValue(element: any, checkboxKey: string) {
    if((this.permissions[0].sectionArray[4].allowed && checkboxKey === 'voided' ||
        this.permissions[0].sectionArray[2].allowed && checkboxKey === 'reviewed') ||
        this.permissions[0].sectionArray[1].allowed && checkboxKey !== 'reviewed' && checkboxKey !== 'voided') {
      this.confirmationBeforeAction(element, checkboxKey);
    }
    else {
      this.showForbiddenMesage();
    }
  };

  confirmationBeforeAction(element: any, checkboxKey: string) {
    let dialogRef: any = this.dialog.open(ConfirmActionDialogComponent, {
      autoFocus: false,
      panelClass: 'confirm-dialog-container',
      data: 'Are you sure you want to change?'
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
        let obj: any = JSON.parse(JSON.stringify(element));
        obj[checkboxKey] = !obj[checkboxKey];
        this.paymentsService.createUpdatePayment(obj)
        .pipe(catchError((err: any) => {
          this.showErrorMessage();
          return throwError(() => err);
        }))
        .subscribe((response: any) => {
          if(response?.id) {
            element[checkboxKey] = response[checkboxKey];
          }
          else {
            this.showErrorMessage();
          }
          console.log(response);
        });
      }
    })
  };

  createUpdatePaymentDialog(obj: any) {
    if(this.permissions[0].sectionArray[1].allowed) {
      let dialogRef: any = this.dialog.open(CreateUpdatePaymentDialogComponent, {
        autoFocus: false,
        disableClose: true,
        panelClass: 'component-dialog-container',
        data: {editMode: true, data: obj}
      });
      dialogRef.afterClosed().subscribe((result: any) => {
        if(result) {
          if(result === 'DELETE PAYMENT') {
            let index: number = this.getIndex(obj.id);
            this.config.dataSource.splice(index, 1);
            this.config.dataSource = [...this.config.dataSource];
            return;
          };
          if(result.type === obj.type) {
            let index: number = this.getIndex(obj.id);
            this.config.dataSource[index] = result;
            this.config.dataSource = [...this.config.dataSource];
          }
        }
      })
    }
    else {
      this.showForbiddenMesage();
    }
  };

  getIndex(id: number) {
    for(let i = 0; i < this.config.dataSource.length; i++) {
      if(this.config.dataSource[i].id === id) {
        return i;
      };
    };
  };

  openFileInNewTab(element: any) {
    this.getPaymentFile(element, false);
  };

  downloadFile(element: any) {
    this.getPaymentFile(element, true);
  };

  getPaymentFile(element: any, download: boolean) {
    element.isOpenFileMenu = !element.isOpenFileMenu;
    element.pdfLoading = true;
    this.paymentsService.getPaymentFile(element.id, element.fileName)
    .pipe(catchError((err: any) => {
      element.pdfLoading = false;
      return throwError(() => err);
    }))
    .subscribe((base64: string) => {
      console.log(base64)
      element.pdfLoading = false;
      if(!base64) return;
      if(download) {
        let source: string = `data:application/octet-stream;base64,${base64}`;
        const downloadLink = document.createElement('a');
        downloadLink.href = source;
        downloadLink.download = element.fileName;
        downloadLink.click();
        return;
      }
      this.sharedService.downloadPreviewFile(element.fileName, base64);
    });
  };  

  selectFilters(propertyName: string, value: any) {
    this.filters[propertyName] = value;
    this.filters = {...this.filters};
  };

  //Show error message
  showErrorMessage() {
    this.dialog.open(WarningMsgDialogComponent, {
      autoFocus: false,
      panelClass: 'warning-msg-dialog-container'
    });
  };

  //Show forbidden message
  showForbiddenMesage() {
    this.dialog.open(MsgForbbidenAccessComponent, {
      autoFocus: false,
      panelClass: 'forbidden-msg-dialog-container'
    })
  }

  tooltipDisable(value: any) {
    if(typeof value === 'string' && value.length < 25) {
      return true;
    }
    if(typeof value === 'number') {
      return true;
    }
    return false;
  };

  clearFilters() {
    this.filters = {
      IssuedBy: [],
      DriverName: [],
      unit_no: [],
      DateOfPayment: null,
      PaymentMethod: [],
      PaymantAmount: {sign: 'More than', value: null},
      PaymentIssuedTo: [],
      TypeOfRepair: [],
      InvoiceReceived: 'Both',
      InvoiceUploadedToPT: 'Both',
      MoneyCode: [],
      IssuedAmount: {sign: 'More than', value: null},
      AmountUsed: {sign: 'More than', value: null},
      Stete: [],
      Quantity: {sign: 'More than', value: null},
      FuelReceiptReceived: 'Both',
      ReasonForPeyment: [],
      ReasonForMoneyCode: [],
      fileName: 'Both',
      division: [],
      reviewed: 'Both',
      voided: 'Both'
    }
  };

};

function compare(a: any, b: any, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DeleteConfirmationDialogComponent } from '@app/modules/shared/components/delete-confirmation-dialog/delete-confirmation-dialog.component';
import { SharedService } from '@app/modules/shared/services/shared.service';
import { TasksService } from '@app/modules/tasks/services/tasks.service';
import moment = require('moment');
import { Subscription } from 'rxjs';
import { ITask } from '@app/modules/tasks/models/task-model';
import { RulesService } from '@app/modules/shared/services/rules.service';
import { MsgForbbidenAccessComponent } from '@app/modules/shared/components/msg-forbbiden-access/msg-forbbiden-access.component';

@Component({
  selector: 'app-assign-task-dialog',
  templateUrl: './assign-task-dialog.component.html',
  styleUrls: ['./assign-task-dialog.component.scss']
})
export class AssignTaskDialogComponent implements OnInit, OnDestroy {
  permissions: any = this.rulesService.UserData[31].data;
  loggedUser: any = JSON.parse(localStorage.getItem('currentUser'));
  
  //Form group
  formGroup: FormGroup;
  taskTypeArray: any[] = [];
  isOpenGroupMenu: boolean = false;
  isOpenTaskMenu: boolean = false;

  //Due date
  isOpenCalendar: boolean = false;
  //Menu
  loadsArray: any[] = [];
  isOpenLoadMenu: boolean = false;

  trucksArray: any[] = [];
  isOpenTruckMenu: boolean = false;

  trucksObjKeyId: any = {};
  trucksObjKeyUnitNo: any = {};
  trucksObjKeyDispId: any = {};
  trailersObjKeyTruckId: any = {};
  trailersObjKeyTrailerUnitNo: any = {};
  driversObjKeyId: any = {};
  driversObjKeyTruckId: any = {};
  dispatchersObjKeyId: any = {};
  driverFullInfo: any = {};

  trailersArray: any[] = [];
  isOpenTrailerMenu: boolean = false;

  driversArray: any[] = [];
  isOpenDriverMenu: boolean = false;

  //Data to save
  assignedTasksArray: any[] = [];

  //Show form
  showForm: boolean = true;

  //Min date
  minDate = new Date();

  //Subscription 
  subscription1: Subscription = new Subscription();
  subscription2: Subscription = new Subscription();
  subscription3: Subscription = new Subscription();
  subscription4: Subscription = new Subscription();
  subscription5: Subscription = new Subscription();
  subscription6: Subscription = new Subscription();

  constructor(@Inject(MAT_DIALOG_DATA) public obj: any,
              private rulesService: RulesService,
              private formBuilder: FormBuilder, 
              public dialogRef: MatDialogRef<AssignTaskDialogComponent>,
              private tasksService: TasksService,
              private sharedService: SharedService,
              private dialog: MatDialog) {
    this.formGroup = this.formBuilder.nonNullable.group({
      'id': [0],
      'task': [''],
      'category': [''],
      'load_no': [''],
      'broker_load_no': [''],
      'driver': ['', [Validators.required, Validators.minLength(2)]],
      'driver_id': [null],
      'truck_no': ['', [Validators.required, Validators.minLength(2)]],
      'truck_id': [null],
      'trailer_no': [''],
      'trailer_id': [null],
      'dispetcher': [''],
      'dispetcher_id': [null],
      'due_date': ['', [Validators.required, Validators.minLength(2)]],
      'status': [0],
      'creator_id': [this.loggedUser.id],
      'created_by': [''],
      'department': [''],
      'created_date': [''],
      'assigned_to': [''],
      'edited_by': [''],
      'edited_date': [null],
      'triggered': [null],
      'explanation': [''],
      'note': [''],
      'fields': [''],
      'files': [[]],
      'num_of_notes': [null],
      'company': [''],
      'phone': [''],
      'driver_type': ['']
    })
  }

  ngOnInit(): void {
    if(this.obj.editMode) {
      this.formGroup.setValue(this.obj.data);
    }
    this.getTrucks();
    this.getTrailers();
    this.getDrivers();
    this.getDispatchers();
    this.getActiveLoads();
    this.getAllRelatedTo();
    console.log(this.obj.data);
  };

  getTrucks() {
    this.subscription1 = this.tasksService.getTrucks().subscribe((response: any) => {
      for(let i = 0; i < response.length; i++) {
        this.trucksObjKeyId[response[i].id] = response[i].unit_no;
        this.trucksObjKeyUnitNo[response[i].unit_no] = response[i].id;
        this.trucksObjKeyDispId[response[i].id] = response[i].dispatcher_id;
      };
      this.trucksArray = response;
      console.log(response)
    });
  };

  getTrailers() {
    this.subscription2 = this.tasksService.getTrailers().subscribe((response: any) => {
      for(let i = 0; i < response.length; i++) {
        this.trailersObjKeyTruckId[response[i].truck_id] = response[i].unit_no;
        this.trailersObjKeyTrailerUnitNo[response[i].unit_no] = response[i].id;
      };
      this.trailersArray = response;
      console.log(response)
    });
  };

  getDrivers() {
    this.subscription3 = this.tasksService.getDrivers().subscribe((response: any) => {
      for(let i = 0; i < response.length; i++) {
        response[i].full_name = `${response[i].first_name} ${response[i].last_name}`;
        this.driversObjKeyId[response[i].truck_id] = `${response[i].first_name} ${response[i].last_name}`;
        this.driversObjKeyTruckId[response[i].truck_id] = response[i].id;
        this.driverFullInfo[response[i].id] = response[i];
      };
      this.driversArray = response;
      console.log(response)
    });
  };

  getDispatchers() {
    this.subscription4 = this.sharedService.getAllDispatchers().subscribe((response: any) => {
      for(let i = 0; i < response.length; i++) {
        let nicknameAndExtension: string = response[i].name?.split('/')[1]?.trim();
        this.dispatchersObjKeyId[response[i].id] = nicknameAndExtension;
      };
    });
  };

  getActiveLoads() {
    this.subscription5 = this.tasksService.getActiveLoads().subscribe((response: any) => {
      this.loadsArray = response;
      console.log(response)
    })
  };

  autocompleteLoadsMenu(obj: any) {
    console.log(obj);
    let nicknameAndExtension: string = obj.dispatcher?.split('/')[1]?.trim();
    this.formGroup.patchValue({
      'load_no': obj.load_no,
      'broker_load_no': obj.broker_load_no,
      'driver': obj.driver,
      'driver_id': obj.driver_id,
      'truck_no': obj.unit_no,
      'truck_id': this.trucksObjKeyUnitNo[obj.unit_no],
      'trailer_no': obj.trauler_no,
      'trailer_id': this.trailersObjKeyTrailerUnitNo[obj.trauler_no],
      'dispetcher': nicknameAndExtension,
      'dispetcher_id': obj.dispatcher_id,
      'assigned_to': nicknameAndExtension,
      'company': this.driverFullInfo[obj.driver_id]?.status,
      'phone': this.driverFullInfo[obj.driver_id]?.phone_cell,
      'driver_type': this.driverFullInfo[obj.driver_id]?.status
    });
    this.isOpenLoadMenu = !this.isOpenLoadMenu;
  };

  autoCompleteByTruck(obj: any) {
    this.formGroup.patchValue({
      'driver': this.driversObjKeyId[this.trucksObjKeyUnitNo[obj.unit_no]],
      'driver_id': obj.driver1_id,
      'truck_no': obj.unit_no,
      'truck_id': this.trucksObjKeyUnitNo[obj.unit_no],
      'trailer_no': this.trailersObjKeyTruckId[this.trucksObjKeyUnitNo[obj.unit_no]],
      'trailer_id': this.trailersObjKeyTrailerUnitNo[this.trailersObjKeyTruckId[this.trucksObjKeyUnitNo[obj.unit_no]]],
      'dispetcher': this.dispatchersObjKeyId[obj.dispatcher_id],
      'dispetcher_id': obj.dispatcher_id,
      'assigned_to': this.dispatchersObjKeyId[obj.dispatcher_id],
      'company': this.driverFullInfo[obj.driver1_id]?.status,
      'phone': this.driverFullInfo[obj.driver1_id]?.phone_cell,
      'driver_type': this.driverFullInfo[obj.driver1_id]?.status
    });
    this.isOpenTruckMenu = !this.isOpenTruckMenu;
  };

  autoCompleteByTrailer(obj: any) {
    this.formGroup.patchValue({
      'driver': this.driversObjKeyId[obj.truck_id],
      'driver_id': this.driversObjKeyTruckId[obj.truck_id],
      'truck_no': this.trucksObjKeyId[obj.truck_id],
      'truck_id': obj.truck_id,
      'trailer_no': obj.unit_no,
      'trailer_id': obj.id,
      'dispetcher': this.dispatchersObjKeyId[this.trucksObjKeyDispId[obj.truck_id]],
      'dispetcher_id': this.trucksObjKeyDispId[obj.truck_id],
      'assigned_to': this.dispatchersObjKeyId[this.trucksObjKeyDispId[obj.truck_id]],
      'company': this.driverFullInfo[this.driversObjKeyTruckId[obj.truck_id]]?.status,
      'phone': this.driverFullInfo[this.driversObjKeyTruckId[obj.truck_id]]?.phone_cell,
      'driver_type': this.driverFullInfo[this.driversObjKeyTruckId[obj.truck_id]]?.status
    });
    this.isOpenTrailerMenu = !this.isOpenTrailerMenu;
  };

  autoCompleteByDriver(obj: any) {
    console.log(obj);
    this.formGroup.patchValue({
      'driver': `${obj.first_name} ${obj.last_name}`,
      'driver_id': obj.id,
      'truck_no': this.trucksObjKeyId[obj.truck_id],
      'truck_id': obj.truck_id,
      'trailer_no': this.trailersObjKeyTruckId[obj.truck_id],
      'trailer_id': this.trailersObjKeyTrailerUnitNo[this.trailersObjKeyTruckId[obj.truck_id]],
      'dispetcher': this.dispatchersObjKeyId[this.trucksObjKeyDispId[obj.truck_id]],
      'dispetcher_id': this.trucksObjKeyDispId[obj.truck_id],
      'assigned_to': this.dispatchersObjKeyId[this.trucksObjKeyDispId[obj.truck_id]],
      'company': obj.status,
      'phone': obj.phone_cell,
      'driver_type': obj.status
    });
    this.isOpenDriverMenu = !this.isOpenDriverMenu;
  };

  getAllRelatedTo() {
    this.subscription6 = this.tasksService.getRelatedToData().subscribe((response: any) => {
      console.log(response);
      this.taskTypeArray = response;
    })
  };

  dateClicked(event: any) {
    this.formGroup.patchValue({'due_date': moment(event._d).format('YYYY-MM-DDT00:00:00')})
    this.isOpenCalendar = false;
  }

  //Add files
  onFileSelected(event: any) {  
    let length: any = Object.keys(event.target.files).length;
    for(let i = 0; i < length; i++) {
      this.addMultipleFiles(event, i);
    }
  }

  addMultipleFiles(event: any, index: number) {
    let fileName = event.target.files[index].name;
    let reader = new FileReader();
    reader.onload = (e: any) => {
      let base64 = e.target.result.split(',')[1];
      let obj: any = {
        name: fileName,
        data: base64
      };
      this.files.push(obj);
      this.formGroup.patchValue({'files': this.files});
    };

    reader.readAsDataURL(event.target.files[index]);
  };

  openFileInNewTab(obj: any) {
    obj.isOpenFileMenu = !obj.isOpenFileMenu;
    this.sharedService.downloadPreviewFile(obj.name, obj.data);
  };

  downloadFile(obj: any) {
    obj.isOpenFileMenu = !obj.isOpenFileMenu;
    let source: string = `data:application/octet-stream;base64,${obj.data}`;
    const downloadLink = document.createElement('a');
    const fileName = obj.name;
    downloadLink.href = source;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  deleteFile(fileObj: any, index: number) {
    fileObj.isOpenFileMenu = !fileObj.isOpenFileMenu;
    let dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
      autoFocus: false,
      panelClass: 'delete-dialog-container',
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
        this.files.splice(index, 1);
      }
    })
  };

  addTask() {
    if(this.formGroup.valid) {
      this.assignedTasksArray.push(this.formGroup.value);
      this.resetForm();
    }
  };

  editTask(obj: any, i: number) {
    this.formGroup.setValue(obj);
    this.assignedTasksArray.splice(i, 1);
    this.showForm = true;
  };

  cancelTask() {
    if(this.assignedTasksArray.length > 0) {
      this.showForm = !this.showForm;
      this.resetForm()
    }
  }

  resetForm() {
    this.formGroup.reset({
      'category': this.formGroup.value.category,
      'task': this.formGroup.value.task,
      'files': []
    });
  }

  assignTask() {
    let dataArray: any[] = JSON.parse(JSON.stringify(this.assignedTasksArray));
    if(this.permissions[0].sectionArray[0].allowed || 
      (this.permissions[0].sectionArray[1].allowed && this.loggedUser.id === this.obj.data.creator_id) 
      || !this.obj.editMode) {
      const fullName: string = `${this.loggedUser.first_name} ${this.loggedUser.last_name}`;
      const nickname: string = `${this.loggedUser.nickname}`;
      const creator: string = nickname.length === 0 ? fullName : nickname?.trim().split(' ')[0];
      if(this.obj.editMode) {
        if(this.checkDueDate(this.formGroup.value.due_date)) {
          this.formGroup.patchValue({status: 0});
        }
        dataArray.push(this.formGroup.value);
      }
      
      let date: string = moment().format('YYYY-MM-DDTHH:mm:ss');
      for(let i = 0; i < dataArray.length; i++) {
        if(this.obj.editMode) {
          dataArray[i].edited_by = creator;
          dataArray[i].edited_date = date;
        }
        else {
          dataArray[i].created_by = creator;
          dataArray[i].department = this.loggedUser.department;
          dataArray[i].created_date = date;
        }
      }
      console.log(dataArray);

      this.tasksService.createUpdateTask(dataArray)
      .subscribe((response: ITask[]) => {
        console.log(response);
        if(response?.length > 0) {
          this.dialogRef.close(response);
        }
      });
    }
    else {
      this.dialog.open(MsgForbbidenAccessComponent, {
        autoFocus: false,
        panelClass: 'forbidden-msg-dialog-container'
      })
    }
  };

  deleteTask() {
    if(this.permissions[0].sectionArray[2].allowed ||
      (this.permissions[0].sectionArray[3].allowed && this.loggedUser.id === this.obj.data.creator_id)) {
      let dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
        autoFocus: false,
        panelClass: 'delete-dialog-container',
      });
      dialogRef.afterClosed().subscribe((result: any) => {
        if(result) {
          this.tasksService.deleteTask(this.obj.data.id).subscribe((response: any) => {
            if(response) {
              this.dialogRef.close('DELETE');
            };
          });
        }
      })
    }
    else {
      this.dialog.open(MsgForbbidenAccessComponent, {
        autoFocus: false,
        panelClass: 'forbidden-msg-dialog-container'
      })
    }
  };

  get getGridTableRowStyle(): string {
    if(this.categoryValue === 'Load') {
      return `grid-template-areas: 'b c d e a f g h'`;
    }
    else if(this.categoryValue === 'Driver') {
      return `grid-template-areas: 'a f d e g h'`;
    }
    else if(this.categoryValue === 'Truck') {
      return `grid-template-areas: 'd e a f g h'`;
    }
    else if(this.categoryValue === 'Trailer') {
      return `grid-template-areas: 'e d a f g h'`;
    }
    else {
      return `grid-template-areas: 'a e d f g h'`;
    }
  };

  get getGridFormStyle(): string {
    if(this.categoryValue === 'Load') {
      return `grid-template-areas: 'b c d e a f g h' 'i i i i i i i i' 'j j j j j j j j'`;
    }
    else if(this.categoryValue === 'Driver') {
      return `grid-template-areas: 'a f d e g h' 'i i i i i i' 'j j j j j j'`;
    }
    else if(this.categoryValue === 'Truck') {
      return `grid-template-areas: 'd e a f g h' 'i i i i i i' 'j j j j j j'`;
    }
    else if(this.categoryValue === 'Trailer') {
      return `grid-template-areas: 'e d a f g h' 'i i i i i i' 'j j j j j j'`;
    }
    else {
      return `grid-template-areas: 'a e d f g h' 'i i i i i i' 'j j j j j j'`;
    }
  };

  checkDueDate(dueDate: string) {
    let date1 = new Date(moment().format('YYYY-MM-DDT00:00:00'));
    let date2 = new Date(dueDate);
    let Difference_In_Time = date2.getTime() - date1.getTime();
    let Difference_In_Days = Math.round(Difference_In_Time / (1000 * 3600 * 24));
    if(Difference_In_Days > 0) {
      return true;
    }
    return false;
  };


  //Form values
  get categoryValue(): string {
    return this.formGroup.controls['category'].value;
  }

  get taskValue(): string {
    return this.formGroup.controls['task'].value;
  }

  get loadNoValue(): string {
    return this.formGroup.controls['load_no'].value;
  };

  get unitNoValue(): string {
    return this.formGroup.controls['truck_no'].value;
  };

  get trailerNoValue(): string {
    return this.formGroup.controls['trailer_no'].value;
  };

  get driverValue(): string {
    return this.formGroup.controls['driver'].value;
  };

  get dueDateValue(): string {
    return this.formGroup.controls['due_date'].value;
  };

  get files(): any[] {
    return this.formGroup.controls['files'].value;
  };

  ngOnDestroy(): void {
    this.subscription1?.unsubscribe();
    this.subscription2?.unsubscribe();
    this.subscription3?.unsubscribe();
    this.subscription4?.unsubscribe();
    this.subscription5?.unsubscribe();
    this.subscription6?.unsubscribe();
  }

}
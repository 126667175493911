import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterSearchDrivers'
})
export class FilterSearchDriversPipe implements PipeTransform {

  transform(array: any[], keyValueArray: any[], metaData: any): any[] {
    if (!array) {
      return [];
    }

    //Statistics
    let sumNumOfApproved: number = 0;
    let sumNumOfNotApproved: number = 0;
    let sumNumofDays: number = 0;

    let data: any[] =  array.filter((obj: any) => {
      let key1: boolean =  obj[keyValueArray[0].key].toLocaleLowerCase().startsWith(keyValueArray[0].value.toLocaleLowerCase());
      let key2: boolean = checkRadioBtns(1);
      let key3: boolean = checkRadioBtns(2);
      let key4: boolean = checkRadioBtns(3);

      function checkRadioBtns(index: number) {
        if(keyValueArray[index].searchValue) {
          let key: boolean | undefined = undefined;
          if(keyValueArray[index].operator === 'More than') {
            key = Number(obj[keyValueArray[index].key]) > Number(keyValueArray[index].searchValue);
          }
          if(keyValueArray[index].operator === 'Less than') {
            key = Number(obj[keyValueArray[index].key]) < Number(keyValueArray[index].searchValue);
          }
          if(keyValueArray[index].operator === 'Equal') {
            key = Number(obj[keyValueArray[index].key]) == Number(keyValueArray[index].searchValue);
          }
          return key;
        }
        else {
          return true;
        }
      };

      if(key1 && key2 && key3 && key4) { 
        sumNumOfApproved += obj.approved;
        sumNumOfNotApproved += obj.not_approved;
        sumNumofDays += obj.days
        return obj;
      }

    });

    let countObj: any = {
      avgNumOfApproved: sumNumOfApproved / data.length,
      avgNumOfNotApproved: sumNumOfNotApproved / data.length,
      avgNumOfPercent: (sumNumOfApproved + sumNumOfNotApproved) / sumNumofDays * 100
    }

    metaData.countObj = countObj;

    return data;

  }

}

import { Pipe, PipeTransform } from '@angular/core';
import moment = require('moment');

@Pipe({
  name: 'loadBoardTable'
})
export class LoadBoardTablePipe implements PipeTransform {

  transform(array: any[], profilesActivitiesObj: any, activeFilterIndex: number, metaObj: any): any[] {
    let transformedArray: any[] = [];

    let activeProfiles: number = 0;
    let inactiveProfiles: number = 0;

    for(let i = 0; i < array.length; i++) {
      if(profilesActivitiesObj.TruckProfilesActivity[array[i].id]) {

        let isProfileActive: boolean = false;
        let profileStatus: string = 'Inactive profile';

        for(let j = 0; j < profilesActivitiesObj.TruckProfilesActivity[array[i].id].length; j++) {

          let startDate: number = new Date(profilesActivitiesObj.TruckProfilesActivity[array[i].id][j].m_Item1).getTime();
          let endDate: number = new Date(profilesActivitiesObj.TruckProfilesActivity[array[i].id][j].m_Item2).getTime();
          let today: number = new Date().getTime();

          if((startDate <= today && endDate >= today) || (startDate <= today && profilesActivitiesObj.TruckProfilesActivity[array[i].id][j].m_Item2 === '1970-01-01T00:00:00')) {
            let unlimitedDate: string = profilesActivitiesObj.TruckProfilesActivity[array[i].id][j].m_Item2 === '1970-01-01T00:00:00' ? 'Unlimited' : moment(profilesActivitiesObj.TruckProfilesActivity[array[i].id][j].m_Item2).format('MM/DD/yyyy');
            isProfileActive = true;
            profileStatus = `${moment(profilesActivitiesObj.TruckProfilesActivity[array[i].id][j].m_Item1).format('MM/DD/yyyy')} - ${unlimitedDate}`;
            array[i].startDate = profilesActivitiesObj.TruckProfilesActivity[array[i].id][j].m_Item1;
            array[i].endDate = profilesActivitiesObj.TruckProfilesActivity[array[i].id][j].m_Item2;

            break;
          }

        }

        array[i].active = isProfileActive;
        array[i].profile_status = profileStatus;
        array[i].valid_date = isProfileActive;

        if(isProfileActive) {
          activeProfiles++;
        }
        else {
          inactiveProfiles++;
        }
      }
      else {
        array[i].active = false;
        array[i].profile_status = 'The date is not set';
        array[i].valid_date = false;
        inactiveProfiles++;
      }
      transformedArray.push(array[i]);
    };

    metaObj.allProfiles = transformedArray.length;
    metaObj.activeProfiles = activeProfiles;
    metaObj.inactiveProfiles = inactiveProfiles;

    return transformedArray.filter((obj: any) => {
      let condition: boolean = true;

      if(activeFilterIndex === 0) {
        condition = true;
      }
      if(activeFilterIndex === 1) {
        condition = obj.active === true;
      }
      if(activeFilterIndex === 2) {
        condition = obj.active === false;
      }


      if(condition) {
        return obj;
      }

    });

  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoadAnalysisRoutingModule } from './load-analysis.routing';
import { SharedModule } from '../shared/shared.module';
import { BrokersSearchTableComponent } from './components/brokers-search-table/brokers-search-table.component';
import { InfoMapDialogComponent } from './components/brokers-search-table/info-map-dialog/info-map-dialog.component';
import { LoadAnalysisPageComponent } from './components/load-analysis-page.component';
import { LoadAnalysisService } from './services/load-analysis.service';

@NgModule({
  declarations: [
    LoadAnalysisPageComponent,
    BrokersSearchTableComponent,
    InfoMapDialogComponent
  ],
  imports: [
    CommonModule,
    LoadAnalysisRoutingModule,
    SharedModule
  ],
  providers: [LoadAnalysisService]
})
export class LoadAnalysisModule { }

import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-create-new-truck-file-dialog',
  templateUrl: './create-new-truck-file-dialog.component.html',
  styleUrls: ['./create-new-truck-file-dialog.component.scss']
})
export class CreateNewTruckFileDialogComponent implements OnInit {
  @ViewChild('scrollMe') private myScrollContainer: ElementRef;
  categories: any[] = [];
  categoryNames: any[] = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<CreateNewTruckFileDialogComponent>) { console.log(data) }

  ngOnInit(): void {
    this.data.obj.categories.forEach(obj => {
      obj.files.forEach(element => {
        element.categoryID = obj.categoryID
      })
      this.categories = this.categories.concat(obj.files)
    });
    this.categoryNames = this.data.categories;
  }

  returnCategoryName(id: number) {
    let name: string = '';
    this.categoryNames.forEach(element => {
      if(element.ID === id) {
        name = element.categoryName;
      }
    });
    return name;
  };
  
  //Scroll
  public scrollBottom(): void {
    this.myScrollContainer.nativeElement.scrollTo(
    { top: (this.myScrollContainer.nativeElement.scrollTop + 95 ), behavior: 'smooth' });
  }

  public scrollTop(): void {
    this.myScrollContainer.nativeElement.scrollTo(
      { top: (this.myScrollContainer.nativeElement.scrollTop - 95), behavior: 'smooth' });
  }

  confirm() {
    this.dialogRef.close(true);
  };

}

import { Component, Input } from '@angular/core';

@Component({
  selector: 'report-activity',
  templateUrl: './report-activity.component.html',
  styleUrls: ['./report-activity.component.scss']
})
export class ReportActivityComponent {
  @Input() data: any;
  @Input() date: any;

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { DispatcherPerformanceRoutingModule } from './dispatchers.performance.routing';
import { DashboardDispatchPerformancePageComponent } from './components/dashboard-dispatch-performance-page.component';
import { DispatchCardsLineChartComponent } from './components/dispatch-cards-line-chart/dispatch-cards-line-chart.component';
import { DispatchPTableLineChartComponent } from './components/dispatch-p-table-line-chart/dispatch-p-table-line-chart.component';
import { DispatchPerformanceCardsComponent } from './components/dispatch-performance-cards/dispatch-performance-cards.component';
import { DispatchPerformanceTableComponent } from './components/dispatch-performance-table/dispatch-performance-table.component';
import { DriverPTableLineChartComponent } from './components/driver-p-table-line-chart/driver-p-table-line-chart.component';
import { DriverPerformanceTableComponent } from './components/driver-performance-table/driver-performance-table.component';
import { DriverFilterPipe } from './pipes/driver-filter.pipe';
import { TopWorstTablesComponent } from './components/top-worst-tables/top-worst-tables.component';
import { DispatchersPerformancesService } from './services/dispatchers-performances.service';

@NgModule({
  declarations: [
    DashboardDispatchPerformancePageComponent,
    DispatchCardsLineChartComponent,
    DispatchPTableLineChartComponent,
    DispatchPerformanceCardsComponent,
    DispatchPerformanceTableComponent,
    DriverPTableLineChartComponent,
    DriverPerformanceTableComponent,
    DriverFilterPipe,
    TopWorstTablesComponent
  ],
  imports: [
    CommonModule,
    DispatcherPerformanceRoutingModule,
    SharedModule
  ],
  providers: [DispatchersPerformancesService]
})
export class DispatchersPerformancesModule { }

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TicketsRoutingModule } from './tickets.routing';
import { SharedModule } from '../shared/shared.module';
import { CreateUpdateTicketDialogComponent } from './components/create-update-ticket-dialog/create-update-ticket-dialog.component';
import { TicketsNotesDialogComponent } from './components/create-update-ticket-dialog/tickets-notes-dialog/tickets-notes-dialog.component';
import { TicketsComponent } from './components/tickets.component';
import { DriversPipe } from './pipes/drivers.pipe';
import { TicketStatusPipe } from './pipes/ticket-status.pipe';
import { TicketPipe } from './pipes/ticket.pipe';
import { TicketsService } from './services/tickets.service';


@NgModule({
  declarations: [
    TicketsComponent,
    CreateUpdateTicketDialogComponent,
    TicketsNotesDialogComponent,
    DriversPipe,
    TicketStatusPipe,
    TicketPipe
  ],
  imports: [
    CommonModule,
    TicketsRoutingModule,
    SharedModule
  ],
  providers: [TicketsService]
})
export class TicketsModule { }

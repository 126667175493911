import { Component, OnDestroy, OnInit } from '@angular/core';
import { ReportsService } from '@app/modules/reports/services/reports.service';
import { TransformService } from '@app/modules/shared/services/transform.service';
import moment = require('moment');
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription, catchError, throwError } from 'rxjs';

@Component({
  selector: 'report-truck-activation',
  templateUrl: './report-truck-activation.component.html',
  styleUrls: ['./report-truck-activation.component.scss']
})
export class ReportTruckActivationComponent implements OnInit, OnDestroy {
  //Date obj
  dateObj: any = {
    startDate: moment().startOf('month').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
  };

  //Data
  cardsDataPrev: any;
  cardsDataCurrent: any;
  days: number = 0;

  loaded: boolean = false;
  error: boolean = false;
  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";

  spinnerBgColor: string = this.transformService.spinnerBgColor;
  spinnerType: string = this.transformService.spinnerType;

  //Subscription
  subscription: Subscription = new Subscription();

  constructor(private transformService: TransformService,
              private reportService: ReportsService,
              private spinner: NgxSpinnerService,) { }

  ngOnInit(): void {
    this.getReportsData();
  }

  getReportsData() {
    this.cardsDataCurrent = undefined;
    this.cardsDataPrev = {};
    this.days = 0;
    this.error = false;
    this.loaded = false;
    this.spinner.show('reports-truck-activation');
    let difference = Math.abs(moment.duration(moment(this.dateObj.startDate).diff(moment(this.dateObj.endDate))).asDays());
    this.days = Math.round(difference);
    let obj: any;
    if(this.days === 0) { 
      obj = {
        startDate: moment().subtract(1, 'week').format('YYYY-MM-DD'), 
        endDate: moment().subtract(1, 'week').format('YYYY-MM-DD')
      };
    } 
    else {
      obj = {
        startDate: moment().subtract(difference*2, 'days').format('YYYY-MM-DD'), 
        endDate: moment().subtract(difference, 'days').format('YYYY-MM-DD')
      };
    }
    this.subscription = this.reportService.getTrucksActivationData(this.dateObj.startDate, this.dateObj.endDate, obj.startDate, obj.endDate, this.transformService.filterParams)
    .pipe(catchError((err: any) => {
      this.spinner.hide('reports-truck-activation');
      this.loaded = true;
      this.error = true;
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      this.cardsDataCurrent = response[0];
      this.cardsDataPrev = response[1];
      this.cardsDataPrev.days = this.days;
      this.cardsDataPrev.dayName = moment(this.dateObj.startDate).format('dddd');
      this.spinner.hide('reports-truck-activation');
      this.loaded = true;
      console.log(response)
    });
  };

  getDate(result) {
    this.dateObj.startDate = moment(result.startDate).format('YYYY-MM-DD');
    this.dateObj.endDate = moment(result.endDate).format('YYYY-MM-DD');
    this.getReportsData();
  };

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();  
  };

}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { TransformService } from '@app/modules/shared/services/transform.service';
import moment = require('moment');
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription, catchError, throwError } from 'rxjs';
import { TrucksService } from '../../services/trucks.service';
import { SharedService } from '@app/modules/shared/services/shared.service';

import * as pdfmake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';

import * as htmlToPdf from 'html-to-pdfmake';
import { TruckInspectionDetailsDialogComponent } from './truck-inspection-details-dialog/truck-inspection-details-dialog.component';

@Component({
  selector: 'app-trucks-inspections-page',
  templateUrl: './trucks-inspections-page.component.html',
  styleUrls: ['./trucks-inspections-page.component.scss']
})
export class TrucksInspectionsPageComponent implements OnInit, OnDestroy {
  //Date
  isOpenFromCalendar: boolean = false;
  isOpenToCalendar: boolean = false;

  dateObj: any = {
    startDate: moment().startOf('month').format('YYYY-MM-DD'),
    endDate:  moment().format('YYYY-MM-DD')
  };

  dataSource: any[] = [];

  //Trucks
  trucksArray: any[] = [];
  isOpenTruckMenu: boolean = false;
  truckSearch: string = '';

  //Trailers
  trailersArray: any[] = [];
  isOpenTrailerMenu: boolean = false;
  trailerSearch: string = '';

  //Drivers
  driversArray: any[] = [];
  isOpenDriverMenu: boolean = false;
  driverSearch: string = '';  

  //Filter obj
  filterObj: any = {
    trucks: [],
    trailers: [],
    drivers: []
  };

  selectedTrucks: string[] = [];
  selectedTrailers: string[] = [];
  selectedDrivers: string[] = [];
  
  //Pdf data
  tiresBrakesObj: any = {
    'LF': {ID: 0, dvirID: 0, tireID: 'LF', psiValue: '-', psiStatus: null, depthValue: '-', depthStatus: null, notes: ''},
    'RF': {ID: 0, dvirID: 0, tireID: 'RF', psiValue: '-', psiStatus: null, depthValue: '-', depthStatus: null, notes: ''},
    'LFO': {ID: 0, dvirID: 0, tireID: 'LFO', psiValue: '-', psiStatus: null, depthValue: '-', depthStatus: null, notes: ''},
    'LFI': {ID: 0, dvirID: 0, tireID: 'LFI', psiValue: '-', psiStatus: null, depthValue: '-', depthStatus: null, notes: ''},
    'RFI': {ID: 0, dvirID: 0, tireID: 'RFI', psiValue: '-', psiStatus: null, depthValue: '-', depthStatus: null, notes: ''},
    'RFO': {ID: 0, dvirID: 0, tireID: 'RFO', psiValue: '-', psiStatus: null, depthValue: '-', depthStatus: null, notes: ''},
    'LRO': {ID: 0, dvirID: 0, tireID: 'LRO', psiValue: '-', psiStatus: null, depthValue: '-', depthStatus: null, notes: ''},
    'LRI': {ID: 0, dvirID: 0, tireID: 'LRI', psiValue: '-', psiStatus: null, depthValue: '-', depthStatus: null, notes: ''},
    'RRI': {ID: 0, dvirID: 0, tireID: 'RRI', psiValue: '-', psiStatus: null, depthValue: '-', depthStatus: null, notes: ''},
    'RRO': {ID: 0, dvirID: 0, tireID: 'RRO', psiValue: '-', psiStatus: null, depthValue: '-', depthStatus: null, notes: ''}
  }; 

  rimsObj: any = {
    'LF': {ID: 0, dvirID: 0, rimID: 'LF', status: '-', notes: ''},
    'RF': {ID: 0, dvirID: 0, rimID: 'RF', status: '-', notes: ''},
    'LFO': {ID: 0, dvirID: 0, rimID: 'LFO', status: '-', notes: ''},
    'LFI': {ID: 0, dvirID: 0, rimID: 'LFI', status: '-', notes: ''},
    'RFI': {ID: 0, dvirID: 0, rimID: 'RFI', status: '-', notes: ''},
    'RFO': {ID: 0, dvirID: 0, rimID: 'RFO', status: '-', notes: ''},
    'LRO': {ID: 0, dvirID: 0, rimID: 'LRO', status: '-', notes: ''},
    'LRI': {ID: 0, dvirID: 0, rimID: 'LRI', status: '-', notes: ''},
    'RRI': {ID: 0, dvirID: 0, rimID: 'RRI', status: '-', notes: ''},
    'RRO': {ID: 0, dvirID: 0, rimID: 'RRO', status: '-', notes: ''}
  }; 

  loaded: boolean = true;
  error: boolean = false;
  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";

  spinnerBgColor: string = this.transformService.spinnerBgColor;
  spinnerType: string = this.transformService.spinnerType;

  //Subscription
  subscription1: Subscription = new Subscription();
  subscription2: Subscription = new Subscription();
  subscription3: Subscription = new Subscription();

  constructor(public transformService: TransformService,
              private dialog: MatDialog, 
              private sharedService: SharedService,
              private trucksService: TrucksService,
              private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    (pdfmake as any).vfs = pdfFonts.pdfMake.vfs;
    this.getTrucks();
    this.getTrailers();
    this.getAllDrivers();
    this.getTableData();
  };

  getTrucks() {
    this.subscription1 = this.sharedService.getAllTrucksData(true).subscribe((response: any) => {
      this.trucksArray = response;
    });
  };

  getTrailers() {
    this.subscription2 = this.sharedService.getTrailersData(true).subscribe((response: any) => {
      this.trailersArray = response;
    });
  };

  getAllDrivers() {
    this.subscription3 = this.sharedService.getDriversData().subscribe((response: any) => {
      for(let i = 0; i < response.length; i++) {
        response[i].full_name = `${response[i].first_name} ${response[i].last_name}`;
        this.driversArray.push(response[i]);
      };
    });
  };

  getTableData() {
    this.error = false;
    this.loaded = false;
    this.dataSource = [];
    this.spinner.show('trucks-inspections');
    this.subscription2 = this.trucksService.getAllTruckInspections(this.dateObj.startDate, this.dateObj.endDate)
    .pipe(catchError((err: any) => {
      this.spinner.hide('trucks-inspections');
      this.loaded = true;
      this.error = true;
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      console.log(response);
      this.dataSource = response;
      this.spinner.hide('trucks-inspections');
      this.loaded = true;
    });
  };

  //Date
  dateClicked(event: any, isFrom: boolean) {
    if(isFrom) {
      this.dateObj.startDate = moment(event._d).format('YYYY-MM-DD');
      this.isOpenFromCalendar = false;
    }  
    else {
      this.dateObj.endDate = moment(event._d).format('YYYY-MM-DD');
      this.isOpenToCalendar = false;
    };
  }

  //Select unselect all
  selectUnselectAll(filterKey: string, array: any[], key: string) {
    if(this.filterObj[filterKey].length > 0) {
      this.filterObj[filterKey] = [];
    }
    else {
      for(let i = 0; i < array.length; i++) {
        if(!this.filterObj[filterKey].includes(array[i][key])) {
          this.filterObj[filterKey].push(array[i][key]);
        }
      };
    }
  };

  //Check uncheck value
  checkUncheckValue(key: string, value: string) {
    let index = this.filterObj[key].indexOf(value);
    if (index === -1) {
      this.filterObj[key].push(value);
    } 
    else {
      this.filterObj[key].splice(index, 1);
    }
  };

  run() {
    let filterData: any = JSON.parse(JSON.stringify(this.filterObj));
    this.selectedTrucks = filterData.trucks;
    this.selectedTrailers = filterData.trailers;
    this.selectedDrivers = filterData.drivers;
    this.getTableData();
  };

  sortData(sort: Sort) {
    const data = JSON.parse(JSON.stringify(this.dataSource));
    if (!sort.active || sort.direction === '') {
      this.dataSource = data;
      return;
    }
    this.dataSource = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'truckID':
          return compare(a.truckID, b.truckID, isAsc);
        case 'trailerID':
          return compare(a.trailerID, b.trailerID, isAsc);
        case 'driverName':
          return compare(a.driverName, b.driverName, isAsc);
        case 'inspectionStartDate':
          return compare(new Date(a.inspectionStartDate), new Date(b.inspectionStartDate), isAsc);
        case 'inspectionEndDate':
          return compare(new Date(a.inspectionEndDate), new Date(b.inspectionEndDate), isAsc);
        case 'truckInspection':
          return compare(a.isTruck, b.isTruck, isAsc);
        case 'trailerInspection':
          return compare(!a.isTruck, !b.isTruck, isAsc);
        case 'isPreTrip':
          return compare(a.isPreTrip, b.isPreTrip, isAsc);
        case 'isFullInspection':
          return compare(a.isFullInspection, b.isFullInspection, isAsc);
        default:
          return 0;
      }
    });
  };

  openInspectionDetailsDialog(id: number) {
    this.trucksService.getTruckInspectionDetails(id).subscribe((response: any) => {
      console.log(response);
      this.dialog.open(TruckInspectionDetailsDialogComponent, {
        autoFocus: false,
        panelClass: 'component-dialog-container',
        data: response
      });
    });
  }

  createPdfAndOpenInNewTab(id: number) {
    this.trucksService.getTruckInspectionDetails(id).subscribe((objData: any) => {
      console.log(objData);
      //Pdf
      let dataRowsInspectionItems: string = `
        <tr style="height: 40px; border: none;">
          <th colspan="2" style="vertical-align: middle; font-size: 22px; width: 705px; background-color: #e9edf9;">Inspection Items</th>
        </tr>
      `;
      for(let i = 0; i < objData.DVIRInspectionItems.length; i++) {
        dataRowsInspectionItems += `
        <tr style="height: 30px; border: none;">
          <th style="vertical-align: middle; width: 305px; background-color: #fff;">${objData.DVIRInspectionItems[i].itemName}</th>
          <td style="vertical-align: middle; width: 400px;">${this.getStatusValue(objData.DVIRInspectionItems[i].status)}</td>
        </tr>
        <tr style="height: 30px; border: none;">
          <th style="vertical-align: middle; width: 305px; background-color: #fff;">Note</th>
          <td style="vertical-align: middle; width: 400px;">${objData.DVIRInspectionItems[i].note}</td>
        </tr>
        `
        for(let j = 0; j < objData.DVIRInspectionItemsPhotos.length; j++) {
          if(objData.DVIRInspectionItemsPhotos[j].itemID === objData.DVIRInspectionItems[i].ID) {
            dataRowsInspectionItems += `
              <tr style="border: none;">
                <th style="background-color: #fff;">
                  <div style="display: flex; align-items: center;">Photo</div>
                </th>
                <td><img width="200" src="data:image/jpeg;base64,${objData.DVIRInspectionItemsPhotos[j].photoBase64}" alt="image"/></td>
              </tr>
            `;
          }
        };
      };
      let dataRowsTireInspection: string = `
        <tr style="height: 40px; border: none;">
          <th colspan="2" style="vertical-align: middle; font-size: 22px; width: 705px; background-color: #e9edf9;">Tire Inspection</th>
        </tr>
      `;
      for(let i = 0; i < objData.DVIRTireInspection.length; i++) {
        dataRowsTireInspection += `
        <tr style="height: 30px; border: none;">
          <th style="vertical-align: middle; width: 305px; background-color: #fff;">Tire</th>
          <td style="vertical-align: middle; width: 400px;">${objData.DVIRTireInspection[i].tireID}</td>
        </tr>
        <tr style="height: 30px; border: none;">
          <th style="vertical-align: middle; width: 305px; background-color: #fff;">Depth</th>
          <td style="vertical-align: middle; width: 400px;">${objData.DVIRTireInspection[i].depthValue}</td>
        </tr>
        <tr style="height: 30px; border: none;">
          <th style="vertical-align: middle; width: 305px; background-color: #fff;">Depth Status</th>
          <td style="vertical-align: middle; width: 400px;">${this.getStatusValue(objData.DVIRTireInspection[i].depthStatus)}</td>
        </tr>
        <tr style="height: 30px; border: none;">
          <th style="vertical-align: middle; width: 305px; background-color: #fff;">PSI</th>
          <td style="vertical-align: middle; width: 400px;">${objData.DVIRTireInspection[i].psiValue}</td>
        </tr>
        <tr style="height: 30px; border: none;">
          <th style="vertical-align: middle; width: 305px; background-color: #fff;">Depth Status</th>
          <td style="vertical-align: middle; width: 400px;">${this.getStatusValue(objData.DVIRTireInspection[i].psiStatus)}</td>
        </tr>
        <tr style="height: 30px; border: none;">
          <th style="vertical-align: middle; width: 305px; background-color: #fff;">Note</th>
          <td style="vertical-align: middle; width: 400px;">${objData.DVIRTireInspection[i].notes}</td>
        </tr>
        `;
        for(let j = 0; j < objData.DVIRTireInspectionPhotos.length; j++) {
          if(objData.DVIRTireInspectionPhotos[j].itemID === objData.DVIRTireInspectionPhotos[i].ID) {
            dataRowsTireInspection += `
              <tr style="border: none;">
                <th style="background-color: #fff;">
                  <div style="display: flex; align-items: center;">Photo</div>
                </th>
                <td><img width="200" src="data:image/jpeg;base64,${objData.DVIRTireInspectionPhotos[j].photoBase64}" alt="image"/></td>
              </tr>
            `;
          }
        }
      };

      let dataRowsRimsInspection: string = `
        <tr style="height: 40px; border: none;">
          <th colspan="2" style="vertical-align: middle; font-size: 22px; width: 705px; background-color: #e9edf9;">Rims Inspection</th>
        </tr>
      `;
      for(let i = 0; i < objData.DVIRRimsInspection.length; i++) {
        dataRowsRimsInspection += `
        <tr style="height: 30px; border: none;">
          <th style="vertical-align: middle; width: 305px; background-color: #fff;">Rims</th>
          <td style="vertical-align: middle; width: 400px;">${objData.DVIRRimsInspection[i].rimID}</td>
        </tr>
        <tr style="height: 30px; border: none;">
          <th style="vertical-align: middle; width: 305px; background-color: #fff;">Status</th>
          <td style="vertical-align: middle; width: 400px;">${this.getStatusValue(objData.DVIRRimsInspection[i].status)}</td>
        </tr>
        <tr style="height: 30px; border: none;">
          <th style="vertical-align: middle; width: 305px; background-color: #fff;">Note</th>
          <td style="vertical-align: middle; width: 400px;">${objData.DVIRRimsInspection[i].notes}</td>
        </tr>
        `;
        for(let j = 0; j < objData.DVIRRimsInspectionPhotos.length; j++) {
          if(objData.DVIRRimsInspectionPhotos[j].itemID === objData.DVIRRimsInspectionPhotos[i].ID) {
            dataRowsRimsInspection += `
              <tr style="border: none;">
                <th style="background-color: #fff;">
                  <div style="display: flex; align-items: center;">Photo</div>
                </th>
                <td><img width="200" src="data:image/jpeg;base64,${objData.DVIRRimsInspectionPhotos[j].photoBase64}" alt="image"/></td>
              </tr>
            `;
          }
        }
      };
      let dataRowsBrakesInspection: string = `
        <tr style="height: 40px; border: none;">
          <th colspan="2" style="vertical-align: middle; font-size: 22px; width: 705px; background-color: #e9edf9;">Brakes Inspection</th>
        </tr>
      `;
      for(let i = 0; i < objData.DVIRBrakesInspection.length; i++) {
        dataRowsBrakesInspection += `
        <tr style="height: 30px; border: none;">
          <th style="vertical-align: middle; width: 305px; background-color: #fff;">Brake</th>
          <td style="vertical-align: middle; width: 400px;">${objData.DVIRBrakesInspection[i].tireID}</td>
        </tr>
        <tr style="height: 30px; border: none;">
          <th style="vertical-align: middle; width: 305px; background-color: #fff;">Depth</th>
          <td style="vertical-align: middle; width: 400px;">${objData.DVIRBrakesInspection[i].depthValue}</td>
        </tr>
        <tr style="height: 30px; border: none;">
          <th style="vertical-align: middle; width: 305px; background-color: #fff;">Depth Status</th>
          <td style="vertical-align: middle; width: 400px;">${this.getStatusValue(objData.DVIRBrakesInspection[i].depthStatus)}</td>
        </tr>
        <tr style="height: 30px; border: none;">
          <th style="vertical-align: middle; width: 305px; background-color: #fff;">PSI</th>
          <td style="vertical-align: middle; width: 400px;">${objData.DVIRBrakesInspection[i].psiValue}</td>
        </tr>
        <tr style="height: 30px; border: none;">
          <th style="vertical-align: middle; width: 305px; background-color: #fff;">Depth Status</th>
          <td style="vertical-align: middle; width: 400px;">${this.getStatusValue(objData.DVIRBrakesInspection[i].psiStatus)}</td>
        </tr>
        <tr style="height: 30px; border: none;">
          <th style="vertical-align: middle; width: 305px; background-color: #fff;">Note</th>
          <td style="vertical-align: middle; width: 400px;">${objData.DVIRBrakesInspection[i].notes}</td>
        </tr>
        `;
        for(let j = 0; j < objData.DVIRBrakesInspectionPhotos.length; j++) {
          if(objData.DVIRBrakesInspectionPhotos[j].itemID === objData.DVIRBrakesInspectionPhotos[i].ID) {
            dataRowsBrakesInspection += `
              <tr style="border: none;">
                <th style="background-color: #fff;">
                  <div style="display: flex; align-items: center;">Photo</div>
                </th>
                <td><img width="200" src="data:image/jpeg;base64,${objData.DVIRBrakesInspectionPhotos[j].photoBase64}" alt="image"/></td>
              </tr>
            `;
          }
        }
      };

      const html = `
          <table style="text-align: left; color: #1F1F1F; border: none;">
              <tr style="height: 140px; border: none;">
                  <th colspan="2" style="vertical-align: middle; background-color: #e9edf9; font-size: 36px; text-align: center; width: 705px;">
                    <div style="display: flex; align-items: center;">Truck Inspection</div>
                  </th> 
              </tr>
              <tr style="height: 30px; border: none;">
                  <th style="vertical-align: middle; width: 305px; background-color: #fff;">Inspection Start Date:</th>
                  <td style="vertical-align: middle; width: 400px;">${moment(objData.baseData.inspectionStartDate).format('MMM DD, yyyy - HH:mm')}</td>
              </tr>
              <tr style="height: 30px; border: none;">
                  <th style="vertical-align: middle; width: 305px; background-color: #fff;">Inspection End Date:</th>
                  <td style="vertical-align: middle; width: 400px;">${moment(objData.baseData.inspectionEndDate).format('MMM DD, yyyy - HH:mm')}</td>
              </tr>
              <tr style="height: 30px; border: none;">
                  <th style="vertical-align: middle; width: 305px; background-color: #fff;">Inspection Duration:</th>
                  <td style="vertical-align: middle; width: 400px;">${this.getInspectionDuration(objData.baseData.inspectionStartDate, objData.baseData.inspectionEndDate)}</td>
              </tr>
              <tr style="height: 30px; border: none;">
                  <th style="vertical-align: middle; width: 305px; background-color: #fff;">Truck:</th>
                  <td style="vertical-align: middle; width: 400px;">${objData.baseData.truckID}</td>
              </tr>
              <tr style="height: 30px; border: none;">
                  <th style="vertical-align: middle; width: 305px; background-color: #fff;">Driver:</th>
                  <td style="vertical-align: middle; width: 400px;">${objData.baseData.driverName}</td>
              </tr>
              ${objData.DVIRInspectionItems.length > 0 ? dataRowsInspectionItems : ''}
              ${objData.DVIRTireInspection.length > 0 ? dataRowsTireInspection : ''}
              ${objData.DVIRRimsInspection.length > 0 ? dataRowsRimsInspection : ''}
              ${objData.DVIRBrakesInspection.length > 0 ? dataRowsBrakesInspection : ''}
          </table>`;
      const pdfMakeContent = htmlToPdf(html, {
        tableAutoSize:true
      }); 
  
      var docDefinition: any = {
        pageSize: 'A4',
        pageMargins: [5, 5, 5, 5],
        content: pdfMakeContent,
      };
      pdfmake.createPdf(docDefinition).open();
    });
  };

  getStatusValue(status: number | null): string {
    if(status === 0) {
      return 'Good';
    };
    if(status === 1) {
      return 'Follow Up';
    }
    if(status === 2) {
      return 'Bad';
    }
    return '/';
  };

  getInspectionDuration(startDate: string, endDate: string) {
      let d1 = new Date(startDate).getTime();
      let d2 = new Date(endDate).getTime();

      let date1 = moment(d1).format('YYYY-MM-DDTHH:mm:ss');
      let date2 = moment(d2);
      let milliseconds: number = date2.diff(date1, 'milliseconds');

      let seconds = Math.floor(milliseconds / 1000); 
      let minute = Math.floor(seconds / 60); 
      seconds = seconds % 60; 
      let hour = Math.floor(minute / 60); 
      minute = minute % 60; 
      let day = Math.floor(hour / 24);
      hour = hour % 24;

      if(day > 0) {
        return `${day}d ${hour}h ${minute}m ${seconds}s`;
      }
      else if(hour > 0) {
        return `${hour}h ${minute}m ${seconds}s`;
      }
      else if(minute > 0) {
        return `${minute}m ${seconds}s`;
      }
      else {
        return `${seconds}s`;
      }

     
  };

  ngOnDestroy(): void {
    this.subscription1?.unsubscribe();
    this.subscription2?.unsubscribe();
    this.subscription3?.unsubscribe();
  };

}

function compare(a: any, b: any, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { DriversProfilePageRoutingModule } from './drivers-profile-page.routing';
import { DashboardDriverProfilePageComponent } from './components/dashboard-driver-profile-page.component';

import { DriversProfileService } from './services/drivers-profile.service';
import { DriverInfoComponent } from './components/driver-info/driver-info.component';
import { DriverInfoCardComponent } from './components/driver-info/driver-info-card/driver-info-card.component';
import { AdditionalInfoComponent } from './components/driver-info/additional-info/additional-info.component';
import { NotesComponent } from './components/driver-info/notes/notes.component';
import { DriverStatisticsCardsComponent } from './components/driver-statistics-cards/driver-statistics-cards.component';
import { DriversStatementsActivityTableComponent } from './components/drivers-statements-activity-table/drivers-statements-activity-table.component';

import { AssignTruckTrailerDialogComponent } from './components/driver-info/additional-info/assign-truck-trailer-dialog/assign-truck-trailer-dialog.component';
import { AdditionalInfoDialogComponent } from './components/driver-info/additional-info/additional-info-dialog/additional-info-dialog.component';
import { DriverInfoDialogComponent } from './components/driver-info/driver-info-card/driver-info-dialog/driver-info-dialog.component';
import { DriverLoadsComponent } from './components/driver-statistics-cards/driver-loads/driver-loads.component';
import { DriverActivityCalendarComponent } from './components/drivers-statements-activity-table/driver-activity-calendar/driver-activity-calendar.component';
import { DriverStatisticsLinechartComponent } from './components/driver-statistics-cards/driver-statistics-linechart/driver-statistics-linechart.component';
import { DriverInfoReasonsCreateUpdateDialogComponent } from './components/driver-info/driver-info-card/driver-info-dialog/driver-info-reasons-create-update-dialog/driver-info-reasons-create-update-dialog.component';
import { CreateUpdateThreadDialogComponent } from './components/driver-info/notes/create-update-thread-dialog/create-update-thread-dialog.component';
import { DriverFilesDialogComponent } from './components/driver-info/driver-info-card/driver-files-dialog/driver-files-dialog.component';
import { DriversStatementsTableComponent } from './components/drivers-statements-activity-table/drivers-statements-table/drivers-statements-table.component';
import { WarningLettersDialogComponent } from './components/driver-info/additional-info/warning-letters-dialog/warning-letters-dialog.component';
import { TruckTrailerInfoComponent } from './components/driver-info/additional-info/truck-trailer-info/truck-trailer-info.component';
import { DotInspectionDialogComponent } from './components/driver-info/additional-info/dot-inspection-dialog/dot-inspection-dialog.component';
import { InspectionTablePipePipe } from './pipes/inspection-table-pipe.pipe';
import { FilterStatementsPipe } from './pipes/filter-statements.pipe';
import { TextareaAutoresizeDirective } from './directives/textarea-autoresize.directive';

@NgModule({
  declarations: [
    DashboardDriverProfilePageComponent,
    DriverActivityCalendarComponent,
    DriverLoadsComponent,
    DriverInfoComponent,
    DriverInfoCardComponent,
    AdditionalInfoComponent,
    NotesComponent,
    DriverStatisticsCardsComponent,
    DriversStatementsActivityTableComponent,
    AssignTruckTrailerDialogComponent,
    AdditionalInfoDialogComponent,
    DriverInfoDialogComponent,
    DriverStatisticsLinechartComponent,
    DriverInfoReasonsCreateUpdateDialogComponent,
    CreateUpdateThreadDialogComponent,
    DriverFilesDialogComponent,
    DriversStatementsTableComponent,
    WarningLettersDialogComponent,
    TruckTrailerInfoComponent,
    DotInspectionDialogComponent,
    InspectionTablePipePipe,
    FilterStatementsPipe,
    TextareaAutoresizeDirective
  ],
  imports: [
    CommonModule,
    DriversProfilePageRoutingModule,
    SharedModule
  ],
  providers: [DriversProfileService]
})
export class DriversProfilePageModule { }

import { Component, Input, OnInit, ViewChild, SimpleChange } from '@angular/core';

import { GoogleAnnotationChartService } from './../../service/google-annotation-chart.service';

@Component({
  selector: 'annotation-chart',
  templateUrl: './annotation-chart.component.html'
})
export class AnnotationChartComponent implements OnInit {

    @Input() data: any;
    @Input() config: any;
    @Input() elementId: string;
any
	constructor(private _annotationChartService: GoogleAnnotationChartService) {}

	// width2: number;
	// @ViewChild('width1') width1;

	// ngAfterViewInit(){
	// 	this.width2  = this.width1.nativeElement.width - 300;
	// }

	ngOnChanges(changes: SimpleChange) {
		console.log(changes);
		if(('data' in changes)) {
			console.info( 'Ann. chard on change.' );
			if(this.data) {
				this._annotationChartService.BuildAnnotationChart(this.elementId, this.data, this.config); 
			} 
		}
	}


    ngOnInit(): void {
        this._annotationChartService.BuildAnnotationChart(this.elementId, this.data, this.config); 
    }
}
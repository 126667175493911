import { ConnectionPositionPair } from '@angular/cdk/overlay';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import moment = require('moment');

@Component({
  selector: 'calendar-only-months',
  templateUrl: './calendar-only-months.component.html',
  styleUrls: ['./calendar-only-months.component.scss']
})
export class CalendarOnlyMonthsComponent {
  //Date
  @Input() config: any = {
    selectedDate: '',
    startOrEnd: ''
  }
  isOpenCalendar: boolean = false;
  @Output() triggerEvent = new EventEmitter<string | number>();

  //Positions
  public positions = [
    new ConnectionPositionPair(
      {originX: 'center', originY: 'bottom'},
      {overlayX: 'center', overlayY: 'top'},
      0, 5
    )
  ];

  monthClicked(event: any) {
    if(this.config.startOrEnd === 'start') {
      this.config.selectedDate = moment(event._d).format('YYYY-MM-DD');
    }
    else {
      this.config.selectedDate = moment(event._d).endOf('month').format('YYYY-MM-DD');
    }
    console.log(this.config.selectedDate)
    this.triggerEvent.emit(this.config.selectedDate);
    this.isOpenCalendar = false;
  };

}

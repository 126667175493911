import { CdkDragEnter, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, ElementRef, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'app-drag-drop-upcoming-cards',
  templateUrl: './drag-drop-upcoming-cards.component.html',
  styleUrls: ['./drag-drop-upcoming-cards.component.scss']
})
export class DragDropUpcomingCardsComponent {

  @ViewChild('dropListContainer') dropListContainer?: ElementRef;

  @Input() cards: any;

  public items: Array<number> = [1, 2, 3, 4, 5, 6, 7];

  constructor() { }

  selectedIndex: number = 0;

  menuItem: any[] = ['Potential', 'Miles', '$/ Mile', 'Weight', 'Upcoming prebooked loads', 'Potential margin amount',
    'Margin %'];

  titlesArray: string[] = ['POTENTIAL', 'MILES', '$/ MILE', 'WEIGHT', 'UPCOMING PREBOOKED LOADS', 'POTENTIAL MARGIN AMOUNT',
    'MARGIN %'];

  keyArray: string[] = ['potential', 'potential_miles', 'reta_per_mile', 'potential_weight', 'potential_num_of_loads',
    'potential_margin', 'potentional_margin_percent'];

  keyArrayCopy: string[] = ['potential', 'potential_miles', 'reta_per_mile', 'potential_weight', 'potential_num_of_loads',
    'potential_margin', 'potentional_margin_percent'];


  activeIndex(i: number) {
    this.selectedIndex = i;
  };

  changeCard(title: string, keyIndex: number, menuItemIndex: any) {
    this.titlesArray[keyIndex] = title.toUpperCase();
    this.keyArray[keyIndex] = this.keyArrayCopy[menuItemIndex];
  };

  //Set value

  setValue(key: any) {
    switch (key) {
      case 'reta_per_mile':
        let value3: string = `$${this.cards.mileStatistics.difference} ${this.cards.mileStatistics.below ? 'below' : 'above'} avg`;
        let className1 = this.cards.mileStatistics.below ? 'red' : 'green';
        return { className: className1, value: value3 };
      case 'potential_weight':
        let value4: string = `${this.cards.weightStatistics.difference} ${this.cards.weightStatistics.below ? 'lower' : 'better'} than avg`;
        let className2 = this.cards.weightStatistics.below ? 'red' : 'green';
        return { className: className2, value: value4 };
      case 'potentional_margin_percent':
        let value6: string = `${this.cards.marginStatistics.difference}${this.cards.marginStatistics.below ? '% lower' : '% better'} than average`;
        let className3 = this.cards.marginStatistics.below ? 'red' : 'green';
        return { className: className3, value: value6 };
      default:
        return { className: '', value: '' };
    }
  }


  //Drag and drop methods

  dropListReceiverElement?: HTMLElement;
  dragDropInfo?: {
    dragIndex: number;
    dropIndex: number;
  };

  dragEntered(event: CdkDragEnter<number>) {
    const drag = event.item;
    const dropList = event.container;
    const dragIndex = drag.data;
    const dropIndex = dropList.data;

    this.dragDropInfo = { dragIndex, dropIndex };

    const phContainer = dropList.element.nativeElement;
    const phElement = phContainer.querySelector('.cdk-drag-placeholder');

    if (phElement) {
      phContainer.removeChild(phElement);
      phContainer.parentElement?.insertBefore(phElement, phContainer);

      moveItemInArray(this.items, dragIndex, dropIndex);

    }
  };

  dragMoved(index: number) {
    let placeholderElement: any;
    if (!this.dropListContainer || !this.dragDropInfo) return;
    placeholderElement =
      this.dropListContainer.nativeElement.querySelector(
        '.cdk-drag-placeholder'
      );
    this.selectedIndex = index;

    const receiverElement =
      this.dragDropInfo.dragIndex > this.dragDropInfo.dropIndex
        ? placeholderElement?.nextElementSibling
        : placeholderElement?.previousElementSibling;

    if (!receiverElement) {
      return;
    }

    receiverElement.style.display = 'none';
    this.dropListReceiverElement = receiverElement;
  }

  dragDropped() {
    if (!this.dropListReceiverElement) {
      return;
    }
    this.dropListReceiverElement.style.removeProperty('display');
    this.dropListReceiverElement = undefined;
    this.dragDropInfo = undefined;
  }

}

import { Component } from '@angular/core';

@Component({
  selector: 'app-message-spots-dialog',
  templateUrl: './message-spots-dialog.component.html',
  styleUrls: ['./message-spots-dialog.component.scss']
})
export class MessageSpotsDialogComponent {

}

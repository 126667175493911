import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SharedService } from '@app/modules/shared/services/shared.service';
import { Subscription } from 'rxjs';

import * as L from 'leaflet';
import 'leaflet-routing-machine';

@Component({
  selector: 'app-info-map-dialog',
  templateUrl: './info-map-dialog.component.html',
  styleUrls: ['./info-map-dialog.component.scss']
})
export class InfoMapDialogComponent implements OnInit, OnDestroy {
  @ViewChild('scrollNotesContainer', { read: ElementRef }) public scrollNotesContainer: any;
  private map: any;

  //Load note
  loadNotes: any = [];

  //Stars array
  starsArray: any[] = [
    {selected: false},
    {selected: false},
    {selected: false},
    {selected: false},
    {selected: false},
  ];

  subscription1: Subscription | any;
  subscription2: Subscription | any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private sharedService: SharedService) { }

  ngOnInit(): void {  
    console.log(this.data);
    this.subscription1 = this.sharedService.getAllLoadNote(this.data.load_no).subscribe((response: any[]) => {
      this.loadNotes = response;
    });
    for(let i = 1; i < this.data.rank; i++) {
      this.starsArray[i].selected = true;
    }

    let fromAddress: string = `${this.data.from_city}, ${this.data.from_state}`;
    let toAddress: string = `${this.data.to_city}, ${this.data.to_state}`;
    this.subscription2 = this.sharedService.getCoordinates(fromAddress, toAddress).subscribe((response: any) => {
      if(response.origin.length > 0 && response.destination.length > 0) {
        let origin: any = {lat: Number(response.origin[0].lat), lng: Number(response.origin[0].lon)};
        let destination: any = {lat: Number(response.destination[0].lat), lng: Number(response.destination[0].lon)};
        this.initMap(origin, destination);
      }
    })
  }

  initMap(originCoordinates: any, destinationCoordinates: any) {
    this.map = L.map('map', {
		  zoom: 5
		});
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(this.map);
    
    L.Routing.control({
      containerClassName: "leaflet-instructions",
      plan: L.Routing.plan([
        L.latLng(originCoordinates.lat, originCoordinates.lng),
        L.latLng(destinationCoordinates.lat, destinationCoordinates.lng)
      ], {
        createMarker: function(i, wp, nWps) {
          if (i === 0  ) {
            let myStartIcon = L.icon({
              iconUrl: './assets/img/location_start.png',
              iconSize: [34, 34],
              iconAnchor: [17, 34]
            });
            return L.marker(wp.latLng, {icon: myStartIcon });
          }
          else {                        
            let myEndIcon = L.icon({
              iconUrl: './assets/img/location_end.png',
              iconSize: [34, 34],
              iconAnchor: [17, 34]
            });
            return L.marker(wp.latLng, {icon: myEndIcon });
          }
        }
      }),
      routeWhileDragging: false,
      lineOptions: {
        styles: [{color: '#022bb1', opacity: 0.6, weight: 10}], extendToWaypoints: false, missingRouteTolerance: 0
     }
    }).addTo(this.map);

    this.map.fitBounds([[originCoordinates.lat, originCoordinates.lng],[destinationCoordinates.lat, destinationCoordinates.lng]])
  };

  isBottom: boolean = false;
  public scrollBottom(): void {
    this.scrollNotesContainer.nativeElement.scrollTo(
    { top: (this.scrollNotesContainer.nativeElement.scrollTop + 95 ), behavior: 'smooth' });
  }

  public scrollTop(): void {
    this.scrollNotesContainer.nativeElement.scrollTo(
      { top: (this.scrollNotesContainer.nativeElement.scrollTop - 95), behavior: 'smooth' });
  }

  isBottomCheck(e: any) {
    if (e.target.scrollHeight < e.target.scrollTop + e.target.offsetHeight + 1) {
      this.isBottom = true;
    }

    if(e.target.scrollTop === 0) {
      this.isBottom = false;
    }
  }

  ngOnDestroy(): void {
    this.subscription1?.unsubscribe();
    this.subscription2?.unsubscribe();
  }

}


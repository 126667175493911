import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { FullAppComponent } from './components/full-app.component';
import { AuthGuard } from '../core/services/auth/auth.guard';


const routes: Routes = [
	{
		path: '',
		component: FullAppComponent,
		canActivate: [AuthGuard],
		data: { roles: 42 }
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class FullAppRoutingModule { }

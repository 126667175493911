import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { TransformService } from '@app/modules/shared/services/transform.service';
import moment = require('moment');
import { ApexAxisChartSeries, ApexChart, ApexXAxis, ApexYAxis, ApexDataLabels, ApexGrid, ApexStroke, ChartComponent } from 'ng-apexcharts';

export type ChartOptions = {
  series: ApexAxisChartSeries | any;
  chart: ApexChart | any;
  xaxis: ApexXAxis | any;
  yaxis: ApexYAxis | any;
  dataLabels: ApexDataLabels | any;
  grid: ApexGrid | any;
  stroke: ApexStroke | any;
  markers: any;
  colors: any;
  tooltip: any;
};

@Component({
  selector: 'truck-mileage-line-chart',
  templateUrl: './truck-mileage-line-chart.component.html',
  styleUrls: ['./truck-mileage-line-chart.component.scss']
})
export class TruckMileageLineChartComponent implements OnInit {
  @ViewChild("chart") chart: ChartComponent | any;
  @Input() date: any;
  @Input() data: any;
  public chartOptions: Partial<ChartOptions> | any;

  constructor(private transfromService: TransformService) { }

  ngOnInit(): void {
    this.initGraph(this.data);
  }
 
  initGraph(data: any) {
    let titlesData: string[] = ['TOTAL TRUCKS', 'INACTIVE TRUCKS', '0 - 2000 MILES', '2000 - 2500 MILES', '2500 - 3000 MILES', '3000 - 3900 MILES', 
    '3900+ MILES'];
    let seriesData: number[] = [data.total_trucks, data.inactive_trucks, data._2000, data._2000_2500, data._2500_3000, 
    data._3000_3900, this.data._3900];
    let dateRange: string = `Date: ${moment(this.date.startDate).format('MM/DD/YYYY')} - ${moment(this.date.endDate).format('MM/DD/YYYY')}`

    let seriesArray: any[] = [
      {name: 'TOTAL', data: seriesData}
    ]
    console.log(seriesArray);
    this.chartOptions = {
      series: seriesArray,
      chart: {
        toolbar: {
          show: true,
          tools: {
            download: false
          },
          animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 800,
            animateGradually: {
                enabled: true,
                delay: 150
            },
            dynamicAnimation: {
                enabled: true,
                speed: 350
            }
          }
        },
        height: 350,
        type: "line",
        zoom: {
          enabled: false
        },
        fontFamily: 'Poppins'
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: "straight",
        width: 1,
      },
      grid: {
        row: {
          colors: ["transparent", "transparent"],
          opacity: 0.5
        }
      },
      xaxis: {
        categories: ['TOTAL TRUCKS', 'INACTIVE TRUCKS', '0 - 2000 MILES', '2000 - 2500 MILES', '2500 - 3000 MILES', '3000 - 3900 MILES', 
        '3900+ MILES']
      },
      yaxis: this.addYAxis(seriesArray),
      markers: {
        size: 5
      },
      colors: ['#0030FF', '#ff1515', '#fffc15', '#00be62', '#1c6f46', '#FF00FF', '#800000'],
      tooltip: {
        style: {
          fontFamily: 'Poppins'
        },
        custom: ({series, seriesIndex, dataPointIndex, w}) => {
          let percent: number = (seriesData[dataPointIndex] / data.total_trucks) * 100;
          return `<div style="background: linear-gradient(to right, #ffffff 0%, #f3f4f6 100%);">
          <div style="padding: 0px 16px; height: 35px; line-height: 35px; border-bottom: 1px solid #0000001f;">${dateRange}</div>
            <div style="padding: 0px 16px; height: 35px; line-height: 35px; border-bottom: 1px solid #0000001f;">TOTAL TRUCKS: <span style="font-size: 16px; font-weight: 600;">${data.total_trucks}</span></div>
            <div style="padding: 0px 16px; height: 35px; line-height: 35px; border-bottom: 1px solid #0000001f;">TRUCKS: <span style="font-size: 16px; font-weight: 600;">${seriesData[dataPointIndex]}</span></div>
            <div style="padding: 0px 16px; height: 35px; line-height: 35px;">${titlesData[dataPointIndex]}: <span style="font-size: 16px; font-weight: 600;">${this.transfromService.addCommasDots(percent)}%</span></div>
          </div>`;
        }
      }
    };
  }

  addYAxis(array: any) {
    let yAxisArray: any[] = [];
    let colors: string[] = ['#0030FF', '#ff1515', '#fffc15', '#00be62', '#1c6f46', '#FF00FF', '#800000'];
    for(let i = 0; i < array.length; i++) {
      let obj: any;
      if(i === 0) {
        obj = {
        seriesName: array[i].name, axisTicks: {show: true}, axisBorder: {show: false, color: colors[i]},
        labels: {style: {colors: colors[i]}, formatter: (num: number) => { 
          return num.toLocaleString("en-US").toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}
        }
      } 
      else {
        obj = {
          seriesName: array[i].name, opposite: true, axisTicks: {show: true}, axisBorder: {show: false, color: colors[i]},
          labels: {style: {colors: colors[i]}, formatter: (num: number) => {
            return num.toLocaleString("en-US").toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          }}
        }
      }

      yAxisArray.push(obj);
    }

    return yAxisArray;
  }

}



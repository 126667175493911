import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'driversSearch'
})
export class DriversSearchPipe implements PipeTransform {

  transform(array: any[], searchText: string): any[] {
    if (!array) {
      return [];
    }
    if (!searchText) {
      return array;
    }
    searchText = searchText.toLocaleLowerCase();

    return array.filter((obj: any) => {
      return `${obj.first_name} ${obj.last_name}`?.toLocaleLowerCase().includes(searchText);
    });
  }
}

import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FleetHealthService } from '@app/modules/fleet-health/services/fleet-health.service';
import { TransformService } from '@app/modules/shared/services/transform.service';
import moment = require('moment');

@Component({
  selector: 'app-create-update-form-dialog',
  templateUrl: './create-update-form-dialog.component.html',
  styleUrls: ['./create-update-form-dialog.component.scss']
})
export class CreateUpdateFormDialogComponent {
  alreadyCreated: boolean = false;
  categoryName: string = '';
  subCategoryName: string = '';
  name: string = '';
  code: string = '';

  isOpenMenu: boolean = false;
  menuValue: string = '';
  menuArray: any[] = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
              public dialogRef: MatDialogRef<any>,
              private transformService: TransformService,
              private fleetService: FleetHealthService) {

    this.menuArray = this.groupCategories(data.array);
    console.log(this.menuArray)
    if(!this.data.isNew) {
      this.categoryName = this.data.obj.category;
      this.subCategoryName = this.data.obj.subcategory;
      this.name = this.data.obj.name;
      this.code = this.data.obj.code;
    }
  }

  groupCategories(array: any[]) {
    const groups: any = array.reduce((acc: any, o: any) => {

      o['category'] = o['category'];
    
      if (!acc[o['category']]) {
        acc[o['category']] = [];
      }
          
      acc[o['category']].push(o);
  
      return acc;
  
    }, {});

    let data: any[] = [];

    for(let key in groups) {
      data.push({category: key, code: groups[key][0].code})
    }

    return data;
  }


  changeSlider() {
    this.alreadyCreated = !this.alreadyCreated;
    if(this.alreadyCreated) {
      this.menuValue = this.data.array[0].category;
      this.code = this.data.array[0].code;
    }
    else {
      this.categoryName = '';
      this.subCategoryName = '';
      this.name = '';
      this.code = '';
    }
  }

  selectOption(obj: any) {
    this.menuValue = obj.category;
    this.code = obj.code;
    this.isOpenMenu = false;
  };

  createUpdate() {
    let loggedUser: any = JSON.parse(localStorage.getItem('currentUser'));
    let obj: any = {
      id: 0,
      is_truck: this.data.isTruck,
      category: this.alreadyCreated ? this.menuValue : this.categoryName,
      subcategory: this.subCategoryName,
      name: this.name,
      code: this.code,
      created_by: loggedUser.first_name + ' ' + loggedUser.last_name,
      created_date:  this.transformService.convertDateToTimestamp(moment(new Date()).format('ddd, DD/MM YYYY'),  moment(new Date()).format('HH:mm:ss')),
      edited_by: null,
      edited_date: null
    }

    if(!this.data.isNew) {
      obj.id = this.data.obj.id;
      obj.created_by = this.data.obj.created_by;
      obj.created_date = this.data.obj.created_date;
      obj.edited_by = loggedUser.first_name + ' ' + loggedUser.last_name;
      obj.edited_date = this.transformService.convertDateToTimestamp(moment(new Date()).format('ddd, DD/MM YYYY'),  moment(new Date()).format('HH:mm:ss'));
    }

    this.fleetService.createUpdatePart(obj).subscribe((response: any) => {
      console.log(response);
      if(response) {
        this.dialogRef.close(response);
      }
    });
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'table-dropdown',
  templateUrl: './table-dropdown.component.html',
  styleUrls: ['./table-dropdown.component.scss']
})
export class TableDropdownComponent {
  @Input() config: any = {
    array: [],
    defaultValue: '',
    dropdownValue: '',
    background: ''
  };
  @Output() triggerEvent = new EventEmitter<string>();

  isOpenTableDropdown: boolean = false;
  dropdownSearch: string = '';

  selectOption(value: string) {
    this.config.dropdownValue = value; 
    this.triggerEvent.emit(value);
    this.isOpenTableDropdown = false;
  }

}

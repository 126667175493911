import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RulesService {
  miliseconds: number = 60000;

  constructor() { }

  get UserData() {
    return JSON.parse(JSON.parse(localStorage.getItem('currentUser'))?.value).rules;
  };

  get liveUpdate() {
    return JSON.parse(localStorage.getItem('currentUser'))?.username === 'alex@jdmexpedite.com';
  };
  
}

import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service';

@Component({
  selector: 'app-forgot-password-page',
  templateUrl: './forgot-password-page.component.html',
  styleUrls: ['./forgot-password-page.component.scss']
})
export class ForgotPasswordPageComponent implements OnInit {
  passwordForm: FormGroup;
  loading = false;
  submitted = false;
  resetPassword: boolean = false;

  constructor(private authService: AuthenticationService,
              private formBuilder: FormBuilder, 
              private route: ActivatedRoute) { }

  ngOnInit(): void {
    localStorage.clear();
    this.passwordForm = this.formBuilder.group({
      'password': ['', Validators.required],
      'confirmPassword': ['', Validators.required]
    }, {validators: this.samePassword()});
  }

  onSubmit() {
    if (this.passwordForm.invalid) {
      return;
    }

    this.loading = true;
    let obj: any = {
      thfekoij: null,
      osdassd: null,
      kjjdfe: btoa(this.passwordForm.value.password)
    }
    this.route.queryParamMap
    .subscribe((params: any) => {
      let paramsObject = { ...params.keys, ...params }
      obj.thfekoij = paramsObject.params.thfekoij;
      obj.osdassd = paramsObject.params.osdassd;
    });
    console.log(obj);

    this.authService.resetPassword(obj).subscribe((response: any) => {
      console.log(response);
      if(response) {
        this.resetPassword = response;
      }
      this.loading = false;
    })
  }

  get f() { 
    return this.passwordForm.controls; 
  }

  samePassword(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const password = control.get('password');
        const confirmPassword = control.get('confirmPassword');
        let isValid: boolean = true;

        if(password.value?.length < 2) {
          isValid = false;
        }

        return password && confirmPassword && isValid
        && password.value === confirmPassword.value ? null : { isNotSame: true };
   }
  }

}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as _G from '../../../_config/globals';
import { IAccountingInvoices } from '../models/accounting-invoices.model';
import { ITruckAssignedTrucks } from '../models/trucks-assigments.model';
import { timeout } from 'rxjs';
import { IAccountingCheckedLoads } from '../models/accounting-checked-loads';

@Injectable()
export class AccountingService {
  headers = new HttpHeaders({
		'Content-Type': 'application/json',
		'Authorization': 'Basic SVZTb2x1dGlvbjoxUzBsdXQxMG4yQDIy'
	});
	requestOptions = { headers: this.headers };

  httpOptions: any = {
    headers: this.headers,
    responseType: 'blob' as 'json',
    reportProgress: true,
    observe: 'events'
  };

  constructor(private http: HttpClient) { }

  setMaintenanceIftaCpm(obj: any) {
    let json: any = JSON.stringify(obj)
    return this.http.post(`${_G.extendApiUrl}accounting/MaintIfta`, json, this.requestOptions);
  };

  getAccountingTable(startDate: string, endDate: string, filterParams: string) {
    return this.http.get(`${_G.extendApiUrl}statement/accmaintenaceifta?start=${startDate}&end=${endDate}${filterParams}`, this.requestOptions);
  };

  getAccountingGraph(startDate: string, endDate: string) {
    return this.http.get(`${_G.extendApiUrl}accounting/MaintIftaAll?start=${startDate}&end=${endDate}`, this.requestOptions);
  };

  //Invoices
  getInvoices() {
    return this.http.get(`${_G.extendApiUrl}accounting/invoices`, this.requestOptions);
  };

  getInvoicesDetails(id: number) {
    return this.http.get(`${_G.extendApiUrl}accounting/invoices/details/${id}`, this.requestOptions);
  };

  getInvoiceFile(id: number) {
    return this.http.get(`${_G.extendApiUrl}accounting/invoices/${id}`, {
      headers: this.headers,
      responseType: 'blob' as 'json',
    });
  };

  createInvoices(accountingInvoices: IAccountingInvoices) {
    let json: string = JSON.stringify(accountingInvoices);
    return this.http.post(`${_G.extendApiUrl}accounting/invoices`, json, this.httpOptions);
  };

  //Liabilities
  getAssignedTrucks() {
    return this.http.get(`${_G.extendApiUrl}accounting/trucksassignement`, this.requestOptions);
  };

  assignTruck(obj: ITruckAssignedTrucks) {
    let json: string = JSON.stringify(obj);
    return this.http.post(`${_G.extendApiUrl}accounting/trucksassignement`, json, this.requestOptions);
  };

  //Accounting board
  getAccountingLoads(startDate: string, endDate: string) {
    return this.http.get(`${_G.extendApiUrl}accounting/loads?start=${startDate}&end=${endDate}`, this.requestOptions);
  };

  //Accounting pdf
  previewAccountingPdf(loadNo: number) {
    return this.http.get(`${_G.extendApiUrl}accounting/invoicepreview/${loadNo}`, {
      headers: this.headers,
      responseType: 'blob' as 'json',
    }).pipe(timeout(600000));
  };

  checkUncheckLoads(obj: IAccountingCheckedLoads) {
    let json: string = JSON.stringify(obj);
    return this.http.post(`${_G.extendApiUrl}accounting/checkloads`, json, this.requestOptions);
  };
 
}

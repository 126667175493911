import { ConnectionPositionPair } from '@angular/cdk/overlay';
import { Component } from '@angular/core';
import { TransformService } from '@app/modules/shared/services/transform.service';
import moment = require('moment');

@Component({
  selector: 'app-truck-profile-page-maintenance',
  templateUrl: './truck-profile-page-maintenance.component.html',
  styleUrls: ['./truck-profile-page-maintenance.component.scss']
})
export class TruckProfilePageMaintenanceComponent {
  //Calendar
  dateObj: any = {
    startDate: this.transformService.convertDateToTimestamp(moment().startOf('month').format('ddd, DD/MM YYYY'), 
    '00:00:00'),
    endDate: this.transformService.convertDateToTimestamp(moment().format('ddd, DD/MM YYYY'),  moment().format('HH:mm:ss')),
  };
  //Fast option calendar
  isOpenFrom: boolean = false;
  isOpenTo: boolean = false;
  fromDate: Date = new Date(this.dateObj.startDate);
  toDate: Date = new Date(this.dateObj.endDate);

  range: boolean = true;

  public positions = [
    new ConnectionPositionPair(
      {originX: 'end', originY: 'bottom'},
      {overlayX: 'end', overlayY: 'top'})
  ];

  public positions2 = [
    new ConnectionPositionPair(
      {originX: 'start', originY: 'bottom'},
      {overlayX: 'start', overlayY: 'top'})
  ];

  //Tabs 
  tabsArray: any[] = [
    {tabName: 'Overview'},
    {tabName: 'Repairs'},
    {tabName: 'Schedule'},
    {tabName: 'Fuel'},
    {tabName: 'Truck file'},
    {tabName: 'Performance'},
    {tabName: 'Profitability'},
    {tabName: 'Tires'},
  ];

  selectedTab: number = 0;

  constructor(private transformService: TransformService) { }

  showSection(i: number) {
    this.selectedTab = i;
  }

  //Calendar
  getDate(result: any) {

  };

  dateClicked(event: any, start: boolean) {
    if(start) {
      this.dateObj.startDate = this.transformService.convertDateToTimestamp(moment(event._d).format('ddd, DD/MM YYYY'), '00:00:00');
      this.fromDate = new Date(this.dateObj.startDate);
      this.isOpenFrom = false;
    }
    else {
      this.dateObj.endDate = this.transformService.convertDateToTimestamp(moment(event._d).format('ddd, DD/MM YYYY'), '23:59:59');
      this.toDate = new Date(this.dateObj.endDate);
      this.isOpenTo = false;
    }

    if(!this.range) {
      this.dateObj.startDate = this.transformService.convertDateToTimestamp(moment(event._d).format('ddd, DD/MM YYYY'), '00:00:00');
      this.dateObj.endDate = this.transformService.convertDateToTimestamp(moment(event._d).format('ddd, DD/MM YYYY'), '23:59:59');
    }
    
  }


}

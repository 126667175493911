import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DateRange } from '@angular/material/datepicker';

import * as moment from 'moment';;
import { NotCoveredTrucksPageComponent } from '../not-covered-trucks-page.component';
import { MatDialogRef } from '@angular/material/dialog';
import { TransformService } from '@app/modules/shared/services/transform.service';

@Component({
  selector: 'app-not-covered-calendar-dialog',
  templateUrl: './not-covered-calendar-dialog.component.html',
  styleUrls: ['./not-covered-calendar-dialog.component.scss']
})
export class NotCoveredCalendarDialogComponent  {

  @Input() selectedRangeValue: DateRange<Date> | undefined;
  @Input() selectedRangeValue1: DateRange<Date> | undefined;
  @Output() selectedRangeValueChange = new EventEmitter<DateRange<Date>>();
  @Output() selectedRangeValueChange1 = new EventEmitter<DateRange<Date>>();
  selected: Date | any;

  rangeObj: any;
  rangeObj1: any;

  index: number = -1;

  selectedPeriod: any;

  compare: boolean = false;

  constructor(public dialogRef: MatDialogRef<NotCoveredTrucksPageComponent>, private transformService: TransformService) { }

  selectedChange(m: any) {
    this.selectedPeriod = undefined;
    this.index = -1;
    if (!this.selectedRangeValue?.start || this.selectedRangeValue?.end) {
      this.selectedRangeValue = new DateRange<Date>(m, null);
    } else {
      const start = this.selectedRangeValue.start;
      const end = m;
      if (end < start) {
        this.selectedRangeValue = new DateRange<Date>(end, start);
      } else {
        this.selectedRangeValue = new DateRange<Date>(start, end);
      }
    }
    this.selectedRangeValueChange.emit(this.selectedRangeValue);
    this.rangeObj = this.selectedRangeValue;
  }

  selectedChange1(m: any) {
    this.selectedPeriod = undefined;
    this.index = -1;
    if (!this.selectedRangeValue1?.start || this.selectedRangeValue1?.end) {
      this.selectedRangeValue1 = new DateRange<Date>(m, null);
    } else {
      const start = this.selectedRangeValue1.start;
      const end = m;
      if (end < start) {
        this.selectedRangeValue1 = new DateRange<Date>(end, start);
      } else {
        this.selectedRangeValue1 = new DateRange<Date>(start, end);
      }
    }
    this.selectedRangeValueChange1.emit(this.selectedRangeValue1);
    this.rangeObj1 = this.selectedRangeValue1;
  }

  onlyOneSelect(value: string, i: number) {
    this.index = i;
    let obj = this.getDate(value);
    this.selectedPeriod = obj;
  }

  onSubmit() {
    console.log(this.rangeObj);
    console.log(this.rangeObj1);

    if (!this.selectedPeriod) {
      let obj: any = {};

      if (this.compare) {
        obj.startDate = this.transformService.convertDateToTimestamp(moment(this.rangeObj.start).format('ddd, DD/MM YYYY'), '00:00:00');
        obj.endDate = this.transformService.convertDateToTimestamp(moment(this.rangeObj.end).format('ddd, DD/MM YYYY'), moment().format('HH:mm:ss'));
        obj.startDate2 = this.transformService.convertDateToTimestamp(moment(this.rangeObj1.start).format('ddd, DD/MM YYYY'), '00:00:00');
        obj.endDate2 = this.transformService.convertDateToTimestamp(moment(this.rangeObj1.end).format('ddd, DD/MM YYYY'), moment().format('HH:mm:ss'));
        obj.twoRange = true;
      } else {
        this.rangeObj?.end == null ? this.rangeObj.end = this.transformService.convertDateToTimestamp(moment(this.rangeObj.start).format('ddd, DD/MM YYYY'), moment().format('HH:mm:ss')) : this.rangeObj.end;
        obj.startDate = this.transformService.convertDateToTimestamp(moment(this.rangeObj.start).format('ddd, DD/MM YYYY'), '00:00:00');
        obj.endDate = this.transformService.convertDateToTimestamp(moment(this.rangeObj.end).format('ddd, DD/MM YYYY'), moment().format('HH:mm:ss'));
        obj.twoRange = false;
      }

      console.log(obj);
      this.dialogRef.close(obj);
    } else {
      console.log(this.selectedPeriod)
      this.dialogRef.close(this.selectedPeriod);
    }
  }

  getDate(selected: string) {
    let startDate: any;
    let endDate: any;
    let value: any = selected.split(' ')[1];

    if (selected.includes('present')) {
      startDate = this.transformService.convertDateToTimestamp(moment().startOf(value).format('ddd, DD/MM YYYY'), '00:00:00');
      endDate = this.transformService.convertDateToTimestamp(moment().format('ddd, DD/MM YYYY'), moment().format('HH:mm:ss'));
    } else if (selected.includes('quarter')) {
      let splitValue = value.split('-');
      startDate = this.transformService.convertDateToTimestamp(moment().month(splitValue[0]).date(1)
        .format('ddd, DD/MM YYYY'), '00:00:00');
      endDate = this.transformService.convertDateToTimestamp(moment().month(splitValue[1]).date(0)
        .format('ddd, DD/MM YYYY'), '23:59:59');
    } else if (selected.includes('all-times')) {
      const d = new Date('2010/01/01')
      startDate = this.transformService.convertDateToTimestamp(moment(d).format('ddd, DD/MM YYYY'), '00:00:00');
      endDate = this.transformService.convertDateToTimestamp(moment().format('ddd, DD/MM YYYY'), moment().format('HH:mm:ss'));
    } else {
      startDate = this.transformService.convertDateToTimestamp(moment().subtract(1, value).startOf(value)
        .format('ddd, DD/MM YYYY'), '00:00:00');
      endDate = this.transformService.convertDateToTimestamp(moment().subtract(1, value).endOf(value)
        .format('ddd, DD/MM YYYY'), '23:59:59');

    }
    console.log(startDate)
    console.log(endDate)
    return { startDate: startDate, endDate: endDate, period: value, twoRange: false };
  }

  onChange() {
    this.compare = !this.compare;
  }

}

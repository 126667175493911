import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AccidentsService } from '@app/modules/safety/services/accidents.service';

@Component({
  selector: 'app-add-contact-dialog',
  templateUrl: './add-contact-dialog.component.html',
  styleUrls: ['./add-contact-dialog.component.scss']
})
export class AddContactDialogComponent implements OnInit {
  loggedUser: any = JSON.parse(localStorage.getItem('currentUser'));
  formGroup: FormGroup;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private formBuilder: FormBuilder, 
  private accidentsService: AccidentsService, public dialogRef: MatDialogRef<AddContactDialogComponent>) {
    this.formGroup = this.formBuilder.group({
      'id': [0],
      'name': ['', [Validators.required, Validators.minLength(2)]],
      'phone_number': [''],
      'cell_phone_number': [''],
      'extension': [''],
      'business_partners_id': [0],
      'is_accounts_receivable': [false],
      'is_accounts_payable': [false],
      'is_customer_service': [false],
      'is_dispatch': [false],
      'is_other': [false],
      'is_sales': [false],
      'is_primary_contact': [false],
      'email': [''],
      'accident_id': [0],
      'claim_id': [0],
      'comments': [''],
      'role': [''],
      'pt_id': [this.loggedUser.pt_id]
    })
  }

  ngOnInit(): void {
    if(this.data.editMode) {
      this.formGroup.setValue(this.data.obj);
    }
    else {
      this.formGroup.patchValue({
        'accident_id': this.data.accidentId,
      })
    }
  }

  saveData() {
    if(this.data.accidentId) {
      this.accidentsService.addUpdateContact(this.formGroup.value)
      .subscribe((response: any) => {
        if(response?.id) {
          this.dialogRef.close(response);
        }
      });
    }
    else {
      this.dialogRef.close(this.formGroup.value);
    }
  };

}

import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-filter-select-menu',
  templateUrl: './filter-select-menu.component.html',
  styleUrls: ['./filter-select-menu.component.scss']
})
export class FilterSelectMenuComponent {
  @Input() value: string;
  @Input() selectArray: any;
  @Output() triggerEvent = new EventEmitter<any>();
  isOpen: boolean = false;

  //Select period
  selectPeriod(obj: any) {
    this.value = obj.name;
    this.triggerEvent.emit(obj.name);
    this.isOpen = false;
  }

}

import { HttpEventType } from '@angular/common/http';
import { AfterViewChecked, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import moment = require('moment');
import { StatusDeleteDialogComponent } from '../status-delete-dialog/status-delete-dialog.component';
import { SharedService } from '../../services/shared.service';
import { RulesService } from '../../services/rules.service';
import { TransformService } from '../../services/transform.service';

@Component({
  selector: 'app-dispatcher-status-dialog',
  templateUrl: './dispatcher-status-dialog.component.html',
  styleUrls: ['./dispatcher-status-dialog.component.scss']
})
export class DispatcherStatusDialogComponent implements OnInit, AfterViewChecked {
  permissions: any = this.rulesService.UserData[15].data[1].sectionArray[1].allowed;
  @ViewChild('scrollMe') private myScrollContainer: ElementRef;
  
  range = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });
  
  photo: any = null;
  initialValue: string = 'All';

  btnsNames: any[] = [
    {name: 'All'}, {name: 'Day off'}, {name: 'Hometime'}, {name: 'Holiday'}, {name: 'No status'}
  ];

  statusArray: any[] = [
    {icon: 'user-white.png', statusGroup: 'Working', status: 'Extra shift', bgColor: 'green'}, 
    {icon: 'user-green.png', statusGroup: 'Working', status: 'Regular shift', bgColor: 'green'}, 
    {icon: 'leave.png', statusGroup: 'Working', status: 'Early leave', bgColor: 'green'},
    {icon: 'leave.png', statusGroup: 'Working', status: 'Late arrival', bgColor: 'green'}, 
    {icon: 'user-green.png', statusGroup: 'Working', status: 'Working weekend', bgColor: 'green'}, 
    {icon: 'night-shift.png', statusGroup: 'Working', status: 'Night shift', bgColor: 'black'}, 
    {icon: 'morning-shift.png', statusGroup: 'Working', status: 'Morning shift', bgColor: 'orange'}, 

    {icon: 'health-issue.png', statusGroup: 'Out of work', status: 'Health issue', bgColor: 'blue'}, 
    {icon: 'family-tradition.png', statusGroup: 'Out of work', status: 'Family tradition', bgColor: 'blue'}, 
    {icon: 'national-holiday.png', statusGroup: 'Out of work', status: 'National holiday', bgColor: 'blue'},
    {icon: 'vacation.png', statusGroup: 'Out of work', status: 'Vacation', bgColor: 'blue'},
    {icon: 'regular-rest-day.png', statusGroup: 'Out of work', status: 'Regular rest day', bgColor: 'grey'},
    {icon: 'day-off-yellow.svg', statusGroup: 'Out of work', status: 'Day off', bgColor: 'orange'}
  ];

  selectedStatus: any | undefined = undefined;
  note: string = '';

  activeStatusId: number = 0;

  isEdit: boolean = false;
  editedObj: any;

  statusData: any;
  fileNames: any[] = [];
  files: any[] = [];

  finalData: any;

  //Filter data
  dayOff: string[] = ['Justified', 'Bad practice'];
  holiday: string[] = ['National', 'Family'];
  hometime: string[] = ['Regular', 'Bad practice'];

  //Progress
  progressDone: number = 0;
  showProgress: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private rulesService: RulesService,
              private sharedService: SharedService,
              private dialog: MatDialog,
              private _snackBar: MatSnackBar,
              private transformService: TransformService) {}

  ngOnInit(): void {
    console.log(this.data)
    this.statusData = Object.assign({}, this.data);
    this.finalData =  Object.assign({}, this.data);
    let startDate = this.data.date 
    let endDate = this.data.date
    if(this.data.allStatusArray.length > 0) {
      let d1: any = new Date(this.data.date).getTime();
      let d2: any = new Date(this.data.allStatusArray[0].date).getTime();
      let d3: any = new Date(this.data.allStatusArray[this.data.allStatusArray.length -1].date).getTime();

      d1 < d2 ? startDate = this.data.date : startDate = this.data.allStatusArray[0].date;
      d1 < d3 ? endDate = this.data.allStatusArray[this.data.allStatusArray.length -1].date : endDate;
    }
    this.dateRangeChange(startDate, endDate);
    this.scrollToBottom();
  }


  filterData(btnName: any) {
    let copy: any =  Object.assign({}, this.finalData);
    this.initialValue = btnName.name;
    let filteredArray: any[] = [];
    if(btnName.name == 'All') {
      this.statusData.allStatusArray = copy.allStatusArray
    }

    if(btnName.name == 'Day off') {
      for(let key in copy.allStatusArray) {
        if(this.dayOff.includes(copy.allStatusArray[key].status)) {
          filteredArray.push(copy.allStatusArray[key]);
        }
      }
      this.statusData.allStatusArray = filteredArray;
    }

    if(btnName.name == 'Hometime') {
      for(let key in copy.allStatusArray) {
        if(this.hometime.includes(copy.allStatusArray[key].status)) {
          filteredArray.push(copy.allStatusArray[key]);
      }
     }
     this.statusData.allStatusArray = filteredArray;
    }

    if(btnName.name == 'Holiday') {
      for(let key in copy.allStatusArray) {
        if(this.holiday.includes(copy.allStatusArray[key].status)) {
          filteredArray.push(copy.allStatusArray[key]);
        }
      }
      this.statusData.allStatusArray = filteredArray;
    }

    if(btnName.name == 'No status') {
      for(let key in copy.allStatusArray) {
        if(copy.allStatusArray[key].status == 'No status') {
          filteredArray.push(copy.allStatusArray[key]);
      }
     }
     this.statusData.allStatusArray = filteredArray;
    }
  }

onFileSelected(event: any) {  
    let length: any = Object.keys(event.target.files).length;
  for(let i = 0; i < length; i++) {
    this.addMultipleFiles(event, i);
  }
}

addMultipleFiles(event: any, index: number) {
  let fileName = event.target.files[index].name;
  this.fileNames.push({filename: fileName});
  let reader = new FileReader();
  reader.onload = (e: any) => {
  var uint8 = new Uint8Array(e.target.result);
  var result = [];
  for (var i = 0; i < uint8.length; i++) {
    result.push(uint8[i]);
  }
  let obj: any = {
    dispatcher_id: this.data.dispatcherInfo.id,
    filename: fileName,
    data: result
  };
  this.files.push(obj);
}

reader.readAsArrayBuffer(event.target.files[index])
};

setStatus(currentStatusObj: any, index: number) {
  console.log(currentStatusObj);
  let loggedUser: any = JSON.parse(localStorage.getItem('currentUser'));
  let obj: any;
  if(currentStatusObj.status_date == null) {
    obj = {
      id: 0,
      dispatcher_id: this.data.dispatcherInfo.id,
      dispatcher_name: this.data.dispatcherInfo.name,
      status_group_name: this.statusArray[index].statusGroup,
      status: this.statusArray[index].status,
      icon_id: index,
      icon_name: this.statusArray[index].icon,
      color: this.statusArray[index].bgColor,
      craetor: loggedUser.first_name + ' ' + loggedUser.last_name,
      creator_id: loggedUser.id,
      creator_department: loggedUser.department,
      status_date: this.transformService.convertDateToTimestamp(moment(new Date(currentStatusObj.date)).format('ddd, DD/MM YYYY'), '00:00:00'),
      datecreated: this.transformService.convertDateToTimestamp(moment(new Date()).format('ddd, DD/MM YYYY'),  moment(new Date()).format('HH:mm:ss')),
      editor: null,
      edit_date: null,
      terminated: ''
    }
  } else {
    obj = {
      id: currentStatusObj.id,
      dispatcher_id: currentStatusObj.dispatcher_id,
      dispatcher_name: currentStatusObj.dispatcher_name,
      status_group_name: this.statusArray[index].statusGroup,
      status: this.statusArray[index].status,
      icon_id: index,
      icon_name: this.statusArray[index].icon,
      craetor: currentStatusObj.craetor,
      creator_department: currentStatusObj.creator_department,
      status_date: currentStatusObj.status_date,
      editor: loggedUser.first_name + ' ' + loggedUser.last_name,
      color: this.statusArray[index].bgColor,
      edit_date: this.transformService.convertDateToTimestamp(moment(new Date()).format('ddd, DD/MM YYYY'),  moment(new Date()).format('HH:mm:ss')),
      datecreated: currentStatusObj.datecreated,
      terminated: currentStatusObj.terminated,
    }
  }
  console.log(obj);
  if(this.permissions) {
    this.sharedService.setDispatcherStatus(obj).subscribe((response: any) => {
      console.log(response);
      if(response) {
        this.statusData.allStatusArray[this.activeStatusId].id = response.id;
        this.fileNames = [];
        this.files = [];
        currentStatusObj.craetor = response.craetor;
        currentStatusObj.creator_department = response.creator_department;
        currentStatusObj.datecreated = response.datecreated;

        currentStatusObj.dispatcher_id = response.dispatcher_id;
        currentStatusObj.dispatcher_name = response.dispatcher_name;
        currentStatusObj.edit_date = response.edit_date;
        currentStatusObj.files = response.files;

        currentStatusObj.icon_id = response.icon_id;
        currentStatusObj.icon_name = response.icon_name;
        currentStatusObj.id = response.id;
        currentStatusObj.notes = response.notes;

        currentStatusObj.status = response.status;
        currentStatusObj.status_date = response.status_date;
        currentStatusObj.status_group_name = response.status_group_name;
        currentStatusObj.color = this.statusArray[index].bgColor;
        currentStatusObj.editor = response.editor
        this.sharedService.dataChanged.next(true);
      }
    })
  } 
  else {
    this._snackBar.open("You don't have permission for this action!", 'Close', { duration: 3000 });
  }
}

//Date changed
dateRangeChange(startDate: any, end: any) {
  let array: any[] = Object.assign([], this.data.allStatusArray);
  if (startDate && end) {
    let date: any = new Date(startDate)
    let endDate: any = new Date(end)
    const dates = [];
    while (date <= endDate) {
      let curentDate = new Date(date);

      let result = array.some((currObj, arrIndex) => { 
        let d1: any = new Date(curentDate).getTime();
        let d2: any = new Date(currObj.date).getTime();
      return d1 == d2;
      })

      if(!result) {
        let obj ={
          craetor: null,
          creator_department: null,
          creator_id: 0,
          date: curentDate,
          driver_id: null,
          driver_name: null,
          edit_date: null,
          editor: "",
          files: [],
          icon_id: null,
          icon_name: null,
          id: null,
          notes: null,
          status: 'No status',
          status_date: null,
          status_group_name: 'No status',
          color: 'grey'
          }
        array.push(obj);
      }
      dates.push(curentDate);
      date.setDate(date.getDate() + 1);
    }
    array.sort((a: any, b: any) => {
      return <any>new Date(b.date) - <any>new Date(a.date);
    })
    let finalData: any[] =  Object.assign([], array)
    finalData = finalData.filter((obj: any) => {
      return new Date(obj.date).getTime() <= new Date(end).getTime()
    })
    this.activeStatusId = 0;
    for(let i = 0; i < finalData.length; i++) {
      if(new Date(this.data.date).getTime() == new Date(finalData[i].date).getTime()) {
        this.activeStatusId = i;
      }
    }

    this.statusData.allStatusArray = finalData;
    this.finalData.allStatusArray = finalData;
  }
}

formatDate(date: any) {
  let obj: any = {};
  obj.month = `${moment(date).format('MMM')}`;
  obj.day = `${moment(date).format('DD')}`;
  obj.dayName = `${moment(date).format('ddd')}`;
  obj.year = `${moment(date).format('yy')}`;
  return obj;
};

//Create note
addNote() {
  if(this.permissions) {
    let loggedUser: any = JSON.parse(localStorage.getItem('currentUser'));
    let obj: any = {
      id: 0,
      status_id: this.statusData.allStatusArray[this.activeStatusId].id,
      dispatcher_id: this.statusData.dispatcherInfo.id,
      department: loggedUser.department,
      note: this.note, 
      craetor: loggedUser.first_name + ' ' + loggedUser.last_name,
      creator_id: loggedUser.id,
      note_date: this.transformService.convertDateToTimestamp(moment(new Date()).format('ddd, DD/MM YYYY'),  moment(new Date()).format('HH:mm:ss')),
      files: this.files,
      editor: null,
      edit_date: null
    }
    if(!this.isEdit) {
      this.sharedService.createEditDispatcherNote(obj).subscribe((httpResponse: any) => {

        if(obj.files.length > 0) {
          this.showProgress = true;
        }
  
        if (httpResponse.type === HttpEventType.Response) {
          this.showProgress = false;
          this.progressDone = 0;
        }
        if (httpResponse.type === HttpEventType.UploadProgress) {
          this.progressDone = Math.round(100 * httpResponse.loaded / httpResponse.total);
        } 
        let response: any = httpResponse.body;
  
        if(response) {
          this.note = '';
          this.statusData.allStatusArray[this.activeStatusId].notes.push(response);
          this.sharedService.dataChanged.next(true);
          this.fileNames = [];
          this.files = [];
        }

      })
    } else {
      let copyObj: any = JSON.parse(JSON.stringify(this.editedObj));
      copyObj.editor = loggedUser.first_name + ' ' + loggedUser.last_name;
      copyObj.dispatcher_id = this.statusData.dispatcherInfo.id;
      copyObj.edit_date = this.transformService.convertDateToTimestamp(moment(new Date()).format('ddd, DD/MM YYYY'),  moment(new Date()).format('HH:mm:ss'));
      copyObj.note = this.note;
      copyObj.files = JSON.parse(JSON.stringify(this.files))
      this.sharedService.createEditDispatcherNote(copyObj).subscribe((httpResponse: any) => {
        if(copyObj.files.length > 0) {
          this.showProgress = true;
        }
  
        if (httpResponse.type === HttpEventType.Response) {
          this.showProgress = false;
          this.progressDone = 0;
        }
        if (httpResponse.type === HttpEventType.UploadProgress) {
          this.progressDone = Math.round(100 * httpResponse.loaded / httpResponse.total);
        } 

        let response: any = httpResponse.body;

        if(response) {
          this.note = '';
          this.isEdit = false;
          this.fileNames = [];
          this.files = [];
          //Edit
          this.editedObj.editor = response.editor
          this.editedObj.dispatcher_id =  this.statusData.dispatcherInfo.id;
          this.editedObj.edit_date = response.edit_date;
          this.editedObj.note = response.note
          this.editedObj.files = response.files;
          this.editedObj.isEdit = false;
          this.sharedService.dataChanged.next(true);
        }
      })
    }
  } 
  else {
    this._snackBar.open("You don't have permission for this action!", 'Close', { duration: 3000 });
  }
};

//Select status
selectStatus(id: number) {
  this.activeStatusId = id;
};

//Edit note
editNote(obj: any) {
  if(this.permissions) {
    this.note = obj.note;
    this.editedObj = obj;
    this.isEdit = true;
    obj.isEdit = true;
    let files: any = JSON.parse(JSON.stringify(obj.files));
    this.fileNames = files;
    for(let key in files) {
      let data = {
        dispatcher_id: this.statusData.dispatcherInfo.id,
        filename: files[key].filename,
        data: this.convertBase64ToByteArray(files[key].data)
      };
      this.files.push(data);
    }
  }
};  

closeEditing(obj: any) {
  obj.isEdit = false;
  this.isEdit = false;
  this.note = '';
  this.fileNames = [];
  this.files = [];
};

//Delete note
deleteNote(obj: any, i: number) {
  if(this.permissions) {
    let dialogRef: any = this.dialog.open(StatusDeleteDialogComponent, {
      width: '300px',
      height: '300px',
      autoFocus: false,
      panelClass: 'delete-role-confirmation-dialog-container',
      data: 1
    })
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
        this.sharedService.deleteDispatcherNote(obj.id).subscribe((response: any) => {
          if(response) {
            this.statusData.allStatusArray[this.activeStatusId].notes.splice(i, 1);
            this.sharedService.dataChanged.next(true);
          }
        })
      }
    });
  } 
  else {
    this._snackBar.open("You don't have permission for this action!", 'Close', { duration: 3000 });
  }
};

//Delete all note
deleteAllNote() {
  if(this.permissions) {
    if(this.statusData.allStatusArray[this.activeStatusId].notes.length > 0) {
    let dialogRef: any = this.dialog.open(StatusDeleteDialogComponent, {
      width: '300px',
      height: '300px',
      autoFocus: false,
      panelClass: 'delete-role-confirmation-dialog-container',
      data: 2
    })
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
        this.sharedService.deleteAllDispatcherNotes(this.statusData.allStatusArray[this.activeStatusId].id).subscribe((response: any) => {
          if(response) {
            this.statusData.allStatusArray[this.activeStatusId].notes = [];
          }
        })
      }
    });
    }
  }
  else {
    this._snackBar.open("You don't have permission for this action!", 'Close', { duration: 3000 });
  }
}

deleteStatus(obj: any, i: number) {
  if(this.permissions) {
    let dialogRef: any = this.dialog.open(StatusDeleteDialogComponent, {
      width: '300px',
      height: '300px',
      autoFocus: false,
      panelClass: 'delete-role-confirmation-dialog-container',
      data: 0
    })
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
        this.sharedService.deleteDispatcherStatus(obj.id).subscribe((response: any) => {
          if(response) {
            this.statusData.allStatusArray.splice(i, 1);
            this.sharedService.dataChanged.next(true);
          }
        })
      }
    });
  } 
  else {
    this._snackBar.open("You don't have permission for this action!", 'Close', { duration: 3000 });
  }
};

//Discard
discard(i : number) {
  this.fileNames.splice(i, 1);
  this.files.splice(i, 1);
};

convertBase64ToByteArray(base64: string) {
  var raw = window.atob(base64);
  var rawLength = raw.length;
  var array = new Uint8Array(new ArrayBuffer(rawLength));
  let result: number[] = [];
  for(let i = 0; i < rawLength; i++) {
    result.push(array[i]);
  }
  return result;
}

ngAfterViewChecked() {        
  this.scrollToBottom();        
} 

scrollToBottom(): void {
    try {
        this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    } catch(err) { }                 
}

}

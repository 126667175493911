import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'emptyTrucksFilter'
})
export class EmptyTrucksFilterPipe implements PipeTransform {

  transform(array: any[], type: string, metaData: any): any[] {
    if (!array) {
      return [];
    }

    let transformedArray: any[] = array.filter((obj: any) => {

      if(type === 'Dealership') {

        if(obj.driver === '' && (obj.status.toLocaleLowerCase() === 'dealer - not ready' || obj.status.toLocaleLowerCase() === 'dealer - ready')) {
          return obj;
        }

      }
      else {
        if(obj.driver === '' && obj.status.toLocaleLowerCase() === type.toLocaleLowerCase()) {
          return obj;
        }
      }

    })

    metaData.numOfTrucks = transformedArray.length;
    return transformedArray;
  }

}

import { Component, Inject, OnInit } from '@angular/core';
import { TwAddUpdateCategoryComponent } from './tw-add-update-category/tw-add-update-category.component';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import moment = require('moment');
import { TransformService } from '../../services/transform.service';
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'app-tickets-warnings-reasons-dialog',
  templateUrl: './tickets-warnings-reasons-dialog.component.html',
  styleUrls: ['./tickets-warnings-reasons-dialog.component.scss']
})
export class TicketsWarningsReasonsDialogComponent implements OnInit {
  displayedColumns: string[] = ['position', 'category', 'subcategory', 'created_by', 'created_date', 'edited_by',
    'edited_date', 'actions'];
  dataSource: any = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private transformService: TransformService,
    private sharedService: SharedService,
    private dialog: MatDialog) { }

  ngOnInit(): void {
    this.dataSource = this.data;
    this.data.forEach(element => {
      element.categoryCacheValue = element.category;
      element.subCategoryCacheValue = element.subcategory;
    });
  }

  deleteReason(obj: any, i: number) {
    let loggedUser: any = JSON.parse(localStorage.getItem('currentUser'));
    let date: string = this.transformService.convertDateToTimestamp(moment(new Date()).format('ddd, DD/MM YYYY'), moment(new Date()).format('HH:mm:ss'));
    this.sharedService.deleteTwReason(obj.id, loggedUser, date).subscribe((response: any) => {
      if (response) {
        let array: any[] = [...this.dataSource];
        array.splice(i, 1);
        this.dataSource = array;
      }
    });
  };

  addUpdateCategory(isNewCategory: boolean, element: any) {
    let dialogRef: any = this.dialog.open(TwAddUpdateCategoryComponent, {
      autoFocus: false,
      panelClass: 'reasons-update-dialog-container',
      data: {isNew: isNewCategory, obj: element, array: this.data}
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
        this.sharedService.getTwReasons().subscribe(response => this.dataSource = response);
      }
    });
  };

}



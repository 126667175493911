import { Component, OnDestroy, OnInit } from '@angular/core';
import moment = require('moment');
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription, catchError, throwError } from 'rxjs';
import { DispatchersLiveBoardService } from '../services/dispatchers-live-board.service';
import { TransformService } from '@app/modules/shared/services/transform.service';
import { SharedService } from '@app/modules/shared/services/shared.service';
import { RulesService } from '@app/modules/shared/services/rules.service';

@Component({
  selector: 'app-dashboard-dispatch-live-board-page',
  templateUrl: './dashboard-dispatch-live-board-page.component.html',
  styleUrls: ['./dashboard-dispatch-live-board-page.component.scss']
})
export class DashboardDispatchLiveBoardPageComponent implements OnInit, OnDestroy {
  permissions: any = this.rulesService.UserData[2].data;
  day: string = `${moment().startOf('day').format('MMM DD YYYY')}`;

  date: string = moment().startOf('day').format('YYYY-MM-DD');
  prevDate: string = moment().subtract(7, 'days').format('YYYY-MM-DD');

  data: any;
  liveActivityData: any;
  liveLoadsData: any;

  //Calendar
  isOpen: boolean = false;

  //Interval
  interval: any;

  //Spinner live boards cards
  loaded1: boolean = false;
  error1: boolean = false;

  //Spinner live activity table 
  loaded2: boolean = false;
  error2: boolean = false;

  //Open
  isOpenMenu: boolean = false;
  dispatcherOffice: string = 'All Offices';
  dispatcherOfficeArray: string[] = [];

  //Spinner live loads table 
  loaded3: boolean = false;
  error3: boolean = false;

  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";

  spinnerBgColor: string = this.transformService.spinnerBgColor;
  spinnerType: string = this.transformService.spinnerType;

  subscription1: Subscription | any;
  subscription2: Subscription | any;
  subscription3: Subscription | any;
  subscription4: Subscription | any;

  constructor(public transformService: TransformService,
              private rulesService: RulesService,
              private dispatchersLiveBoardService: DispatchersLiveBoardService,
              private sharedService: SharedService,
              private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.spinner.show('live-board-cards');
    this.spinner.show('live-activity-table');
    this.spinner.show('live-loads-table');
    this.getData();
    this.getEmployeeOffice();
    this.interval = setInterval(() => {
      if(this.day === `${moment().startOf('day').format('MMM DD YYYY')}`) {
        this.getData();
      }
    }, 60000);
  }

  getData() {
    this.subscription1 = this.dispatchersLiveBoardService.getLiveboardCards(this.date, this.prevDate, this.transformService.filterParams, this.dispatcherOffice)
    .pipe(catchError((err: any) => {
      this.spinner.hide('live-board-cards');
      this.loaded1 = true;
      this.error1 = true;
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      let dataForCards: any = JSON.parse(JSON.stringify(response));
      dataForCards[0].data = response[0];
      dataForCards[1].data = response[1];
      dataForCards[2].data = response[2];
      dataForCards[1].data.dayName = moment(this.date).format('dddd');
      this.data = dataForCards;
      this.spinner.hide('live-board-cards');
      this.loaded1 = true;
    });

    this.subscription2 = this.dispatchersLiveBoardService.getLiveActivityTable(this.date, this.transformService.filterParams, this.dispatcherOffice)
    .pipe(catchError((err: any) => {
      this.spinner.hide('live-activity-table');
      this.loaded2 = true;
      this.error2 = true;
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      this.liveActivityData = response;
      this.spinner.hide('live-activity-table');
      this.loaded2 = true;
      console.log(response);
    })

    this.subscription3 = this.dispatchersLiveBoardService.getLiveLoadsData(this.date, this.transformService.filterParams, this.dispatcherOffice)
    .pipe(catchError((err: any) => {
      this.spinner.hide('live-loads-table');
      this.loaded3 = true;
      this.error3 = true;
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      console.log(response)
      this.liveLoadsData = response;
      this.spinner.hide('live-loads-table');
      this.loaded3 = true;
    });
  };

  selectDispatcherOffice(office: string) {
    this.error1 = false;
    this.loaded1 = false;
    this.data = undefined;

    this.error2 = false;
    this.loaded2 = false;
    this.liveActivityData = undefined;

    this.error3 = false;
    this.loaded3 = false;
    this.liveLoadsData = undefined

    this.spinner.show('live-board-cards');
    this.spinner.show('live-activity-table');
    this.spinner.show('live-loads-table');
    
    this.dispatcherOffice = office; 
    this.isOpenMenu = !this.isOpenMenu;
    this.getData();
  };

  getEmployeeOffice() {
    this.subscription4 = this.sharedService.getAllEmployeeOffice().subscribe((response: any) => {
      console.log(response);
      this.dispatcherOfficeArray = response;
    })
  };

  setDay(isTrue: boolean) {
    let date: any = new Date(this.day);
    if(isTrue) {
      this.day = moment(date).add(1, 'days').format('MMM DD YYYY');
    } else {
      this.day = moment(date).subtract(1, 'days').format('MMM DD YYYY');
    }

    let selectedDate: any = new Date(this.day);
    this.date = moment(selectedDate).startOf('day').format('YYYY-MM-DD');
    this.prevDate = moment(selectedDate).subtract(7, 'days').format('YYYY-MM-DD');

    this.error1 = false;
    this.loaded1 = false;
    this.data = undefined;

    this.error2 = false;
    this.loaded2 = false;
    this.liveActivityData = undefined;

    this.error3 = false;
    this.loaded3 = false;
    this.liveLoadsData = undefined

    this.spinner.show('live-board-cards');
    this.spinner.show('live-activity-table');
    this.spinner.show('live-loads-table');
    this.getData(); 
  }

  //Calendar
  dateClicked(event: any) {
    this.day = moment(event._d).format('MMM DD YYYY');
    this.date = moment(event._d).startOf('day').format('YYYY-MM-DD');
    this.prevDate = moment(event._d).subtract(7, 'days').format('YYYY-MM-DD');

    this.error1 = false;
    this.loaded1 = false;
    this.data = undefined;

    this.error2 = false;
    this.loaded2 = false;
    this.liveActivityData = undefined;

    this.error3 = false;
    this.loaded3 = false;
    this.liveLoadsData = undefined

    this.spinner.show('live-board-cards');
    this.spinner.show('live-activity-table');
    this.spinner.show('live-loads-table');
    this.getData();
    this.isOpen = false;
  };

  ngOnDestroy(): void {
    clearInterval(this.interval);
    this.subscription1?.unsubscribe();
    this.subscription2?.unsubscribe();
    this.subscription3?.unsubscribe();
    this.subscription4?.unsubscribe();
  }

}

import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

import { DriverInputsSettingsComponent } from './driver-inputs-settings/driver-inputs-settings.component';
import { FullAppService } from '@app/modules/full-app/services/full-app.service';
import { IFullAppFields, IFullAppApplyFields } from '@app/modules/full-app/models/full-app-fields';
import { IFields } from '@app/modules/full-app/models/template-fields.model';
import { DeleteConfirmationDialogComponent } from '@app/modules/shared/components/delete-confirmation-dialog/delete-confirmation-dialog.component';

@Component({
  selector: 'app-template-driver-inputs',
  templateUrl: './template-driver-inputs.component.html',
  styleUrls: ['./template-driver-inputs.component.scss']
})
export class TemplateDriverInputsComponent implements OnInit, OnDestroy {
  //Inputs
  inputsArray: IFullAppFields[] = [];
  isOpenInputMenu: boolean = false;

  //Inputs selected
  selectedInputsArray: IFullAppFields[] | any = [];
  savedInputsArray: IFullAppFields[] | any = [];

  activeTab: string = 'Inputs applied to all drivers';
  driverId: number = 0;
  metaData: any = {
    countObj: {
      selectedInputsLength: 0
    }
  }

  //Subscription
  subscription: Subscription = new Subscription();

  constructor(@Inject(MAT_DIALOG_DATA) public obj: any,
              private dialog: MatDialog,
              public dialogRef: MatDialogRef<TemplateDriverInputsComponent>,
              private fullAppService: FullAppService) { }

  ngOnInit(): void {
    this.subscription = this.fullAppService.getFullAppExtraFields().subscribe((response: any) => {
      console.log(response);
      let array1: any[] = JSON.parse(JSON.stringify(this.obj.extra_fields));
      let array2: any[] = JSON.parse(JSON.stringify(response));

      array1.filter(object1 => {
        array2.some(object2 => {
          if(object1.id === object2.id) {
            object1.value = '';
            object1.date = null;
            object1.time = null;
            object1.checkboxSelectedValues = '';
            this.selectedInputsArray.push(object1);
            this.savedInputsArray.push(object1);
            object2.selected = true;
          }
        });
      });

      this.inputsArray = array2;
    })
  }

  //Settings
  inputsSettings() {
    this.isOpenInputMenu = false;
    let dialogRef: any = this.dialog.open(DriverInputsSettingsComponent, {
      autoFocus: false,
      panelClass: 'template-drivers-inputs-dialog-container',
      disableClose: true,
      data: this.inputsArray
    });
    dialogRef.afterClosed().subscribe((array: IFullAppFields[]) => {
      this.inputsArray = array;
    });
  };

  selectTab(value: string, id: number) {
    this.activeTab = value;
    this.driverId = id;
  };

  getName(element: IFullAppFields) {
    if(element.full_app_option === 4) {
      return element.calendarTitle;
    }
    else if(element.full_app_option === 5) {
      return element.timepickerTitle;
    }
    else if(element.full_app_option === 6) {
      return `${element.calendarTitle} - ${element.timepickerTitle}`;
    }
    else {
      return element.title;
    }
  };

   //Select field
   selectField(element: IFields | any) {
    element.selected = !element.selected;
    let index: number = this.selectedInputsArray.findIndex(obj => obj.id === element.id);
    if(index === -1) {
      element.users = this.driverId ? this.driverId : 0;
      this.selectedInputsArray.push(element);
    }
    else {
      this.selectedInputsArray.splice(index, 1);
    }
  };

  //Apply fields to all drivers
  applyFileds() {
    let fieldsArray: IFullAppFields[] = this.getDifference(this.selectedInputsArray, this.savedInputsArray);
    let obj: IFullAppApplyFields = {
      id: 0,
      employee_id: this.driverId,
      fileds: fieldsArray
    }
    console.log(fieldsArray)
    if(fieldsArray.length === 0) {
      this.dialogRef.close(true);
    }
    else {
      this.fullAppService.applyFieldsToDrivers(obj).subscribe((response: IFullAppApplyFields) => {
        console.log(response)
        if(response) {
          this.dialogRef.close(true);
        }
      });
    }
  };

  getDifference(array1: IFullAppFields[], array2: IFullAppFields[]) {
    return array1.filter(object1 => {
      return !array2.some(object2 => {
        return object1.id === object2.id;
      });
    });
  }

  //Delete field
  deleteField(element: any, index: number) {
    let dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
      autoFocus: false,
      panelClass: 'delete-dialog-container',
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
        this.fullAppService.deleteAppliedFieldsToDrivers(this.driverId, element.id).subscribe((response: any) => {
          if(response) {
            this.selectedInputsArray.splice(index, 1);
          }
        })
      }
    });
  };

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  };

}

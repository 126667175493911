import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { HiredTerminatedChartsComponent } from '../hired-terminated-charts.component';

@Component({
  selector: 'app-hired-terminated-dialog',
  templateUrl: './hired-terminated-dialog.component.html',
  styleUrls: ['./hired-terminated-dialog.component.scss']
})
export class HiredTerminatedDialogComponent implements OnInit {
  dataSource: any;
  hiredTerminated: any;

  constructor(@Inject(MAT_DIALOG_DATA) public obj: HiredTerminatedChartsComponent,
    public dialogRef: MatDialogRef<HiredTerminatedDialogComponent>) { }

  ngOnInit(): void {
    console.log(this.obj);
    this.hiredTerminated = this.obj.elements[0].datasetIndex;
    let index: number = this.obj.elements[0].index;
    this.dataSource = Object.entries(this.obj.data)[index][1];
    console.log(this.dataSource)
  }

}

import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Clipboard } from '@angular/cdk/clipboard';
import { TrucksMapService } from '@app/modules/trucks-map/services/trucks-map.service';
import { catchError, Subscription, throwError } from 'rxjs';
import { TransformService } from '@app/modules/shared/services/transform.service';
import { Router } from '@angular/router';
import { RulesService } from '@app/modules/shared/services/rules.service';
import { AssignTrailerComponent } from './assign-trailer/assign-trailer.component';
import { MsgForbbidenAccessComponent } from '@app/modules/shared/components/msg-forbbiden-access/msg-forbbiden-access.component';
import { SuccessDialogComponent } from '@app/modules/shared/components/success-dialog/success-dialog.component';
import { WarningMsgDialogComponent } from '@app/modules/shared/components/warning-msg-dialog/warning-msg-dialog.component';
import { SharedService } from '@app/modules/shared/services/shared.service';
import { AssignedConfirmationDialogComponent } from '@app/modules/shared/components/assigned-confirmation-dialog/assigned-confirmation-dialog.component';
import moment = require('moment');
import { Moment } from 'moment';

@Component({
  selector: 'app-truck-info-dialog',
  templateUrl: './truck-info-dialog.component.html',
  styleUrls: ['./truck-info-dialog.component.scss']
})
export class TruckInfoDialogComponent implements OnInit, OnDestroy {
  permissions: any = this.rulesService.UserData[14].data;
  infoData: any = {
    address: '',
    addressLink: '',
    driverID: 0,
    driverName: '',
    fuel: 0,
    loads: [],
    minTillBreak: 0,
    minTillCycle: 0,
    minTillDrive: 0,
    minTillShift: 0,
    odometer: 193765.22,
    plateNumber: '',
    speed: 0,
    trailerID: '', 
    truckID: '', 
    vin: ''
  };
  currentLoad: any = {
    load_id: 0, 
    load_no: '', 
    pickup_city: '', 
    pickup_country: '', 
    picked_up: false, 
    delivery_city: '', 
    delivery_country: '', 
    delivered: false,
    delivered_at: null, 
    amount: 0, 
    mileage: 0, 
    rate_per_mile: 0, 
    pickup_date: null, 
    delivery_date: null
  };
  nextLoad: any = {
    load_id: 0, 
    load_no: '', 
    pickup_city: '', 
    pickup_country: '', 
    picked_up: false, 
    delivery_city: '', 
    delivery_country: '', 
    delivered: false,
    delivered_at: null, 
    amount: 0, 
    mileage: 0, 
    rate_per_mile: 0, 
    pickup_date: null, 
    delivery_date: null
  };

  currentDistance: number = 0;
  nextDistance: number = 0;

  //Subscription
  subscription: Subscription = new Subscription();

  constructor(@Inject(MAT_DIALOG_DATA) public obj: any, 
              private rulesService: RulesService,
              private dialog: MatDialog,
              private trucksMapService: TrucksMapService, 
              private transformService: TransformService,
              private sharedService: SharedService,
              private clipboard: Clipboard,
              private router: Router) { }

  ngOnInit(): void {
    this.subscription = this.trucksMapService.getTruckTrailerInfo(this.obj.isTruck, this.obj.assetName)
    .subscribe((response: any) => {
      console.log(response);
      this.infoData = response;
      if(response.loads.length > 0) {
        this.currentLoad = response.loads[0].load;
      };
      if(response.loads.length > 1) {
        this.nextLoad = response.loads[1].load;
      };
      if(response.distances.length > 0) {
        this.currentDistance = response.distances[0];
      };
      if(response.distances.length > 1) {
        this.nextDistance = response.distances[1];
      };
    });  
  };

  convertMetersToMilesPickup(element: any, distance: number) {
    if(!element.picked_up && !element.delivered && element.load_no) {
      return `${this.transformService.addCommasDots(distance * 0.000621371192)} mi from PU`;
    }
    return '';
  };

  convertMetersToMilesDelivery(element: any, distance: number) {
    if(element.picked_up && !element.delivered && element.load_no) {
      return `${this.transformService.addCommasDots(distance * 0.000621371192)} mi from DEL`;
    }
    return '';
  };

  copyAddress() {
    this.clipboard.copy(this.infoData.address);
  };

  openNewTab(url: string) {
    window.open(url, '_blank');
  };

  //Open in new tab
  openInNewTab(route: string) {
    const url = this.router.serializeUrl(this.router.createUrlTree([route]));
    window.open(url, '_blank');
  }  

  changeTrailer() {
    if(this.permissions[3].allowedAll) {
      let dialogRef = this.dialog.open(AssignTrailerComponent, {
        autoFocus: false,
        panelClass: 'component-dialog-container',
        data: {selectedValue: this.infoData.trailerID}
      });
      dialogRef.afterClosed().subscribe((obj: any) => {
        if(obj) {
          this.alreadyAssigned(obj);
        }
      });
    }
    else {
      this.showForbidenMessage();
    }
  };

  alreadyAssigned(data: any) {
    this.sharedService.isTruckTrailerAssigned(data.trailerId, false)
    .pipe(catchError((err: any) => {
      this.showErrorMessage();
      return throwError(() => err);
    }))
    .subscribe((assigned: boolean) => {
      if(assigned) {
        let dialogRef: any = this.dialog.open(AssignedConfirmationDialogComponent, {
          autoFocus: false,
          panelClass: 'assigned-msg-dialog-container',
          data: false
        });
        dialogRef.afterClosed().subscribe((response: boolean) => {
          if(response) {
            this.assignTrailer(data.trailerId, this.infoData.truckID, data.trailerValue);
          } 
        });
      }
      else {
        this.assignTrailer(data.trailerId, this.infoData.truckID, data.trailerValue);
      }
    });
  };

  assignTrailer(trailerId: number, truckId: number, truckTrailerValue: string) {
    this.sharedService.assignTrailer(trailerId, truckId)
    .pipe(catchError((err: any) => {
      this.showErrorMessage();
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      if(response === 'OK') {
        this.infoData.trailerID = truckTrailerValue;
        this.showSuccessMessage(); 
      }
      else {
        this.showErrorMessage();
      }
    });
  };

  getEta(date: string, element: any) {
    if(date && element.load_no) {
      let d1 = new Date().getTime();
      let d2 = new Date(date).getTime();

      let date1 = moment().format('YYYY-MM-DDTHH:mm:ss');
      let date2 = moment(date);
      let diffInMinutes = date2.diff(date1, 'minutes');
      if(d1 <= d2) {
        return this.timeConvert(diffInMinutes);
      }
      return moment(date).format('HH:mm');
    }
    return '';
  };

  timeConvert(n: number) {
    let num = n;
    let hours = (num / 60);
    let rhours = Math.floor(hours);
    let minutes = (hours - rhours) * 60;
    let rminutes = Math.round(minutes);
    return `${rhours < 10 ? '0' + rhours : rhours}:${rminutes < 10 ? '0' + rminutes : rminutes}`;
  }

  get convertKmToMiles(): string {
    return this.transformService.addCommasDots(this.infoData.odometer * 0.6213711922, 'round');
  };

  checkDate(date: string) {
    let d1 = new Date().getTime();
    let d2 = new Date(date).getTime();
    if(d1 <= d2 || !date) {
      return false;
    }
    return true
  }

  showSuccessMessage() {
    this.dialog.open(SuccessDialogComponent, {
      autoFocus: false,
      panelClass: 'success-dialog-container'
    });  
  };

  showErrorMessage() {
    this.dialog.open(WarningMsgDialogComponent, {
      autoFocus: false,
      panelClass: 'warning-msg-dialog-container'
    });
  };

  showForbidenMessage() {
    this.dialog.open(MsgForbbidenAccessComponent, {
      autoFocus: false,
      panelClass: 'forbidden-msg-dialog-container'
    })
  };

  ngOnDestroy(): void {
    this.subscription?.unsubscribe(); 
  };

}

import { Component } from '@angular/core';
import { RulesService } from '@app/modules/shared/services/rules.service';

@Component({
  selector: 'app-inspections-main-page',
  templateUrl: './inspections-main-page.component.html',
  styleUrls: ['./inspections-main-page.component.scss']
})
export class InspectionsMainPageComponent {
  permissions: any = this.rulesService.UserData[23].data;

  constructor(private rulesService: RulesService) { }

}

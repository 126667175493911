import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { catchError, Subscription, throwError } from 'rxjs';
import { RulesService } from '@app/modules/shared/services/rules.service';
import { SharedService } from '@app/modules/shared/services/shared.service';
import { OverviewService } from '../../services/overview.service';
import { TransformService } from '@app/modules/shared/services/transform.service';

@Component({
  selector: 'app-top-worst-dispatchers',
  templateUrl: './top-worst-dispatchers.component.html',
  styleUrls: ['./top-worst-dispatchers.component.scss']
})
export class TopWorstDispatchersComponent implements OnInit, OnDestroy {
  @ViewChild('scrollContainer', { read: ElementRef }) public scrollContainer: any;
  @Input() date: any;

  topWorst: boolean = true;
  value: string = 'Top';
  percent: number = 3;
  dispatchStatus: number = 1;

  //Menu top
  isOpen: boolean = false;
  selectValue: string = 'Top 3';
  selectArray: any[] = [
    {name: 'Top 3', percent: 3},
    {name: 'Top 4', percent: 4},
    {name: 'Top 5', percent: 5},
    {name: 'Top 6', percent: 6},
    {name: 'Top 7', percent: 7},
    {name: 'Top 8', percent: 8},
    {name: 'Top 9', percent: 9},
    {name: 'Top 10', percent: 10}
  ];

  topArray: any[] = [
    {name: 'Top 3', percent: 3},
    {name: 'Top 4', percent: 4},
    {name: 'Top 5', percent: 5},
    {name: 'Top 6', percent: 6},
    {name: 'Top 7', percent: 7},
    {name: 'Top 8', percent: 8},
    {name: 'Top 9', percent: 9},
    {name: 'Top 10', percent: 10}
  ]

  worstArray: any[] = [
    {name: 'Worst 3', percent: 3},
    {name: 'Worst 4', percent: 4},
    {name: 'Worst 5', percent: 5},
    {name: 'Worst 6', percent: 6},
    {name: 'Worst 7', percent: 7},
    {name: 'Worst 8', percent: 8},
    {name: 'Worst 9', percent: 9},
    {name: 'Worst 10', percent: 10}
  ];

  //Menu filter
  isOpenStatusSelect: boolean = false;
  statusValue: string = 'Active';
  statusArray: any[] = [
    {name: 'Active', status: 1},
    {name: 'Inactive', status: 2},
    {name: 'All', status: 0},
  ];

  data: any;

  displayedColumns: string[] = ['index', 'name', 'value', 'rate', 'avg'];
  displayedRateColumns: string[] = ['index', 'name', 'value', 'avg'];

  avgObj: any = {
    avgGross: 0,
    avgNoOfTrucks: 0,
    avgGrossWeekly: 0,
    avgNoOfTrucksWeekly: 0,
    avgMileageWeekly: 0,
    avgNoOfTrucksMileageWeekly: 0,
    avgRate: 0,
    avgNoOfTrucksRate: 0
  };

  //Interval
  interval: any;

  loaded: boolean = false;

  error: boolean = false;

  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";

  spinnerBgColor: string = this.transformService.spinnerBgColor;
  spinnerType: string = this.transformService.spinnerType;

  subscription1: Subscription | any;
  subscription2: Subscription | any;

  constructor(private overviewService: OverviewService,
    private sharedService: SharedService,
    public transformService: TransformService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private rulesService: RulesService) { }

  ngOnInit(): void {
    this.spinner.show('top-worst');
    this.getCarouselData();
    this.dateChanged();
    if(this.rulesService.liveUpdate) {
      this.interval = setInterval(() => {
        this.getCarouselData();
      }, this.rulesService.miliseconds);
    }
  }

  getCarouselData() {
    this.subscription1 = this.sharedService.getTopWorstDispatchers(this.date.startDate, this.date.endDate, this.percent, this.dispatchStatus, this.transformService.filterParams)
    .pipe(catchError((err: any) => {
      this.spinner.hide('top-worst');
      this.loaded = true;
      this.error = true;
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      this.spinner.hide('top-worst');
      this.loaded = true;
      this.data = response;
      console.log(response);
    })
  }

  dateChanged() {
    this.subscription2 = this.overviewService.dateChanged.subscribe((response: any) => {
      this.date.startDate = response.startDate;
      this.date.endDate = response.endDate;
      this.data = undefined;

      this.error = false;
      this.loaded = false;

      this.spinner.show('top-worst');
      this.getCarouselData();
    })
  }

  //Change top worst
  topWorstChange(isTrue: boolean) {
    this.topWorst = isTrue;
    this.topWorst ? (this.value = 'Top', this.selectArray = [...this.topArray]) : (this.value = 'Worst', this.selectArray = [...this.worstArray]);
    this.selectValue = `${this.value} ${this.percent}`;
  }

  countAvg(array: any, keyInAvgObj: string, keyInArray: string) {
    let value: number = 0;
    for(let key in array) {
      value += array[key][keyInArray];
    }
    this.avgObj[keyInAvgObj] = this.addOneDecimalPlace(value / array.length);
    return this.avgObj[keyInAvgObj];
  };

  //Select option
  selectOption(obj: any, percent: boolean) {
    if(percent) {
      this.selectValue = obj.name;
      this.percent = obj.percent;
      this.isOpen = false;
    }
    else {
      this.statusValue = obj.name;
      this.dispatchStatus = obj.status;
      this.isOpenStatusSelect = false;
    }

    this.data = undefined;
    this.error = false;
    this.loaded = false;
    this.spinner.show('top-worst');
    this.getCarouselData();
  };

  //Prev next methods
  public next(): void {
    let scrollValue: any = this.scrollContainer.nativeElement.scrollLeft;
    this.scrollContainer.nativeElement.scrollTo({ left: (scrollValue + 530), behavior: 'smooth' });
  }

  public prev(): void {
    let scrollValue: any = this.scrollContainer.nativeElement.scrollLeft;
    this.scrollContainer.nativeElement.scrollTo({ left: (scrollValue - 530), behavior: 'smooth' });
  }

  //Open in new tab
  openInNewTab(route: string) {
    const url = this.router.serializeUrl(this.router.createUrlTree([route]));
    window.open(url, '_blank');
  }

  setIcon(prev: boolean) {
    if(prev) {
      return '../../../../assets/img/arrow-prev-blue.svg'
    }
  }

  addOneDecimalPlace(num: number) {
    let number = Number(num);
    if (number === Math.floor(number)) {
       return num;
    } else {
       return num.toFixed(1);
    }
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
    this.subscription1?.unsubscribe();
    this.subscription2?.unsubscribe();
  }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DashboardAccountingPageComponent } from './components/dashboard-accounting-page/dashboard-accounting-page.component';
import { DashboardInvoicesPageComponent } from './components/dashboard-invoices-page/dashboard-invoices-page.component';
import { AuthGuard } from '../core/services/auth/auth.guard';
import { DashboardLiabilitiesPageComponent } from './components/dashboard-liabilities-page/dashboard-liabilities-page.component';
import { DashboardAccountingBoardPageComponent } from './components/dashboard-accounting-board-page/dashboard-accounting-board-page.component';
import { DashboardIftaIrpPageComponent } from './components/dashboard-ifta-irp-page/dashboard-ifta-irp-page.component';
import { DashboardPaymentsPageComponent } from './components/dashboard-payments-page/dashboard-payments-page.component';

const routes: Routes = [
  {
    path: 'accounting',
    children: [
      {
        path: 'accounting-page',
        canActivate: [AuthGuard],
        component: DashboardAccountingPageComponent,
        data: { roles: 9 }
      },
      {
        path: 'invoices-page',
        canActivate: [AuthGuard],
        component: DashboardInvoicesPageComponent,
        data: { roles: 34 }
      },
      {
        path: 'liabilities',
        component: DashboardLiabilitiesPageComponent,
        canActivate: [AuthGuard],
        data: { roles: 35 }
      },
      {
        path: 'accounting-board',
        component: DashboardAccountingBoardPageComponent,
        canActivate: [AuthGuard],
        data: { roles: 36 }
      },
      {
        path: 'activity-summary',
        component: DashboardIftaIrpPageComponent,
        canActivate: [AuthGuard],
        data: { roles: 33 }
      },
      {
        path: 'payments',
        component: DashboardPaymentsPageComponent,
        canActivate: [AuthGuard],
        data: { roles: 37 }
      }
    ]
  }
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class AccountingRoutingModule { }


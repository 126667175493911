import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SafetyService } from '@app/modules/safety/services/safety.service';
import { TransformService } from '@app/modules/shared/services/transform.service';
import moment = require('moment');

@Component({
  selector: 'app-add-edit-variable-dialog',
  templateUrl: './add-edit-variable-dialog.component.html',
  styleUrls: ['./add-edit-variable-dialog.component.scss']
})
export class AddEditVariableDialogComponent implements OnInit {
  //Inputs
  variableName: string = '';
  variableTextValue: string = '';
  variableImageValue: string = '';
  base64: string = '';

  //Custom select
  isOpenMenu: boolean = false;
  menuValue: string = 'Text';
  menuArray: any[] = [
    {name: 'Text', isTrue: false},
    {name: 'Image', isTrue: true}
  ];
  isImage: boolean = false;

  //Description
  description: string = '';

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private dialogRef: MatDialogRef<any>,
              private transformService: TransformService,
              private safetyService: SafetyService) { }

  ngOnInit(): void {
    if(this.data) {
      this.variableName = this.data.name.split('--')[1];
      this.variableTextValue = this.data.value;
      this.description = this.data.description;
      this.isImage = this.data.is_image;
      this.menuValue  = this.data.is_image ? 'Image' : 'Text';
    }
  }

  selectOption(obj: any) {
    this.menuValue = obj.name;
    this.isImage = obj.isTrue;
    this.isOpenMenu = false;
  };

  //File selected
  onFileSelected(event: any) {
    console.log(event);
    let fileName = event.target.files[0].name;
    this.variableImageValue = fileName;
    let reader = new FileReader();
    reader.onload = (e: any) => {
      this.base64 = e.target.result;
      console.log(this.base64)
    }

    reader.readAsDataURL(event.target.files[0]);
  };

  clearImg() {
    this.variableImageValue = '';
    this.base64 = '';
  };

  createUpdateVariable() {
    let loggedUser: any = JSON.parse(localStorage.getItem('currentUser'));

    let obj: any = {
      id: 0, name: `--${this.variableName}--`, value: this.isImage ? this.base64 : this.variableTextValue, 
      description: this.description, is_readonly: false, created_by: loggedUser.first_name + ' ' + loggedUser.last_name,
      is_image: this.isImage, craeted_date: this.transformService.convertDateToTimestamp(moment(new Date()).format('ddd, DD/MM YYYY'),  moment(new Date()).format('HH:mm:ss')), 
      edited_by:  null, edited_date: null
    }

     if(this.data) {
      obj.id = this.data.id;
      obj.created_by = this.data.created_by;
      obj.craeted_date = this.data.craeted_date;
      obj.edited_by = loggedUser.first_name + ' ' + loggedUser.last_name; 
      obj.edited_date = this.transformService.convertDateToTimestamp(moment(new Date()).format('ddd, DD/MM YYYY'),  moment(new Date()).format('HH:mm:ss'));
     }
     console.log(obj);
     this.safetyService.createEditVariable([obj]).subscribe((response: any) => {
      console.log(response);
      if(response) {
        this.dialogRef.close(true);
      }
     })

  };

}

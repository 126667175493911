import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'filter-multiple-options',
  templateUrl: './filter-multiple-options.component.html',
  styleUrls: ['./filter-multiple-options.component.scss']
})
export class FilterMultipleOptionsComponent {
  @Input() config: any = {
    array: [],
    selectedValues: [],
    defaultValue: '',
    key: '',
    titleCase: false,
    search: false
  };
  @Input() styleConfig: any = {
    'width': '240px',
    'background-color': '#fff',
    'color': '#1F1F1F',
    'box-shadow': '0px 1px 6.65px 0.35px rgba(13, 39, 80, 0.16)',
    'border-radius': '25px'
  };
  @Output() triggerEvent = new EventEmitter<string | number>();
  isOpenFilterMenu: boolean = false;
  dropdownSearch: string = '';

   //Select unselect all
   selectUnselectAll() {
    if(this.config.selectedValues.length > 0) {
      this.config.selectedValues = [];
    }
    else {
      for(let i = 0; i < this.config.array.length; i++) {
        if(!this.config.selectedValues.includes(this.config.key === '' ? this.config.array[i] : this.config.array[i][this.config.key])) {
          this.config.selectedValues.push(this.config.array[i][this.config.key]);
        }
      };
    }
    this.triggerEvent.emit(this.config.selectedValues);
  };

  //Check uncheck value
  checkUncheckValue(value: string) {
    let index = this.config.selectedValues.indexOf(value);
    if(index === -1) {
      this.config.selectedValues.push(value);
    } 
    else {
      this.config.selectedValues.splice(index, 1);
    }
    this.triggerEvent.emit(this.config.selectedValues);
  };

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OverviewRoutingModule } from './overview.routing';
import { SharedModule } from '../shared/shared.module';
import { DashboardCardsComponent } from './components/dashboard-cards/dashboard-cards.component';
import { DispatchLineChartComponent } from './components/dispatch-line-chart/dispatch-line-chart.component';
import { DispatchTableComponent } from './components/dispatch-table/dispatch-table.component';
import { FleetStatusComponent } from './components/fleet-status/fleet-status.component';
import { HiredTerminatedDialogOverComponent } from './components/hired-terminated-dialog-over/hired-terminated-dialog-over.component';
import { OverLineChartComponent } from './components/over-line-chart/over-line-chart.component';
import { OverviewPageComponent } from './components/overview-page.component';
import { TopWorstDispatchersComponent } from './components/top-worst-dispatchers/top-worst-dispatchers.component';
import { OverviewService } from './services/overview.service';
import { DriverTurnoverComponent } from './components/fleet-status/driver-turnover/driver-turnover.component';
import { CalendarOnlyMonthsComponent } from './components/fleet-status/driver-turnover/calendar-only-months/calendar-only-months.component';

@NgModule({
  declarations: [
    OverviewPageComponent,
    DashboardCardsComponent,
    DispatchLineChartComponent,
    DispatchTableComponent,
    FleetStatusComponent,
    HiredTerminatedDialogOverComponent,
    OverLineChartComponent,
    TopWorstDispatchersComponent,
    CalendarOnlyMonthsComponent,
    DriverTurnoverComponent
  ],
  imports: [
    CommonModule,
    OverviewRoutingModule,
    SharedModule
  ],
  providers: [OverviewService]
})
export class OverviewModule { }

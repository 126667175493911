import { Pipe, PipeTransform } from '@angular/core';
import moment = require('moment');

@Pipe({
  name: 'tasks',
  pure: false
})
export class TasksPipe implements PipeTransform {

  transform(array: any[], statusFilter: number, filterArray: any[], metaData: any): any[] {
    if (!array) {
      return [];
    }

    let countObj: any = {
      pending: 0,
      overdue: 0,
      done: 0
    };
    
    let transformedArray: any[] = array.filter((obj: any) => {
   
      let condition1: boolean = true;
      if(statusFilter === 0 && (obj.status === 0 || obj.status === 1)) {
        condition1 = true;
      }
      else {
        condition1 = statusFilter === obj.status;
      }

      let condition2: boolean = filterArray[0].length === 0 ? true : filterArray[0].includes(obj.department);
      let condition3: boolean = filterArray[1].length === 0 ? true : filterArray[1].includes(obj.created_by);
      let condition4: boolean = filterArray[2].length === 0 ? true : filterArray[2].includes(obj.task);
      let condition5: boolean = filterArray[3].length === 0 ? true : filterArray[3].includes(obj.assigned_to);
      let condition6: boolean = filterArray[4].length === 0 ? true : filterArray[4].includes(obj.driver);
      let condition7: boolean = filterArray[5].length === 0 ? true : filterArray[5].includes(obj.truck_no);
      let condition8: boolean = filterArray[6].length === 0 ? true : filterArray[6].includes(obj.trailer_no);
      let createdDate: string = moment(obj.created_date).format('YYYY-MM-DD');
      let condition9: boolean = (filterArray[7] === '' && filterArray[8] === '') || (createdDate >= filterArray[7] && createdDate <= filterArray[8]);

      //Statistics
      if(condition2 && condition3 && condition4 && condition5 && condition6 && condition7 && condition8 && condition9) {
        if(obj.status === 0 || obj.status === 1) {
          countObj.pending++;
        }
        if(obj.status === 1) {
          countObj.overdue++;
        }
        if(obj.status === 2) {
          countObj.done++;
        }  
      }

      if(condition1 && condition2 && condition3 && condition4 
      && condition5 && condition6 && condition7 && condition8 && condition9) {
        return obj;
      }
   
    });
    metaData.countObj = countObj;
    return transformedArray;
  }

}

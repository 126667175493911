import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { SharedService } from '../../services/shared.service';
import { Subscription } from 'rxjs';
import { ImagePreviewComponent } from '../image-preview/image-preview.component';

@Component({
  selector: 'app-chat-inbox',
  templateUrl: './chat-inbox.component.html',
  styleUrls: ['./chat-inbox.component.scss']
})
export class ChatInboxComponent implements OnInit, OnDestroy {
  loggedUser: any = JSON.parse(localStorage.getItem('currentUser'));
  fullName: string = `${this.loggedUser.first_name} ${this.loggedUser.last_name}`;
  nickname: string = `${this.loggedUser.nickname}`;
  creator: string = this.nickname.length === 0 ? this.fullName : this.nickname?.trim().split(' ')[0];

  formGroup: FormGroup;
  chatGroups: any[] = [];
  chatMessages: any[] = [];

  //Afterhours
  chatGroupsAfterhours: any[] = [
    {ID: 'Accounting', value: 'Accounting'},
    {ID: 'After Hours', value: 'After Hours'},
    {ID: 'Dispatch', value: 'Dispatch'},
    {ID: 'Hours Of Service', value: 'Hours Of Service'},
    {ID: 'Maintenance', value: 'Maintenance'},
    {ID: 'Recruiting', value: 'Recruiting'},
    {ID: 'Safety', value: 'Safety'}
  ];

  activeDepartment: string = '';

  //Key 
  chatGroupKey: string = 'ID';

  //Filter group
  searchGroup: string = '';

  //Sender menu
  senderArray: any[] = [
    {id: -1, name: 'Fleet Wide', value: 'Fleet Wide'}, {id: -1, name: 'RtR Drivers', value: 'RtR Drivers'}, 
    {id: -1, name: 'Company Drivers', value: 'Company Drivers'}
  ];
  senderValue: string = 'Fleet Wide';
  messageToType: string = 'Fleet Wide';
  isOpenSenderMenu: boolean = false;

  //Interval
  interval: any;

  //Dispatch inbox
  tabsArray: any[] = [{name: 'My drivers', driversArray: []}, {name: 'All drivers', driversArray: []}];
  activeTabIndex: number = 0;

  selectedGroupIndex: number | null = null;

  //Subscription
  subscription1: Subscription = new Subscription();
  subscription2: Subscription = new Subscription();
  subscription3: Subscription = new Subscription();
  subscription4: Subscription = new Subscription();
  subscription5: Subscription = new Subscription();

  constructor(private dialog: MatDialog, private formBuilder: FormBuilder,
    private sharedService: SharedService) {
    this.formGroup = this.formBuilder.group({
      'ID': [0],
      'deleteDate': [''],
      'driverID': [null],
      'driverName': [''],
      'editedDate': [null],
      'imagebase64': [''],
      'isAfterHoursReply': [this.loggedUser.department === 'After Hours' ? true : false],
      'isDeleted': [false],
      'isDriverMessage': [false],
      'isEdited': [false],
      'isFleetWideMessage': [false],
      'isRead': [false],
      'message': [''],
      'messageReadDate': [null],
      'messageSentDate': [null],
      'replyMessageID': [-1],
      'userID': [this.loggedUser.id],
      'userName': [this.creator]
    })
  }

  ngOnInit(): void {
    this.getChatMessages(true);
    this.getActiveDrivers();
    this.getUsersData();
    this.interval = setInterval(() => {
    this.getChatMessages(false);
    }, 5000);
  }

  getChatMessages(callOnce: boolean) {
    if(this.loggedUser.department === 'Dispatch') {

      if(callOnce) {
        this.chatGroupKey = 'driverName';
        this.getDispatchDriversData();
      }

      if(this.selectedGroupIndex !== null) {
        this.getDispatchInbox(this.chatGroups[this.selectedGroupIndex].driverID);
      }

    }
    else if(this.loggedUser.department === 'After Hours') {

      if(callOnce) {
        this.chatGroupKey = 'ID';
        this.chatGroups = this.chatGroupsAfterhours;
      }

      if(this.selectedGroupIndex !== null) {
        this.getAfterhoursInbox(this.chatGroups[this.selectedGroupIndex].ID);
      }

    }
    else {
      this.getChatData();
    }
  };

  //Get data
  selectChatGroup(obj: any, i: number) {
    this.selectedGroupIndex = i;
    if(this.loggedUser.department === 'Dispatch') {
      this.getDispatchInbox(obj.driverID);
      this.formGroup.patchValue({
        'driverID': obj.driverID,
        'driverName': obj.driverName
      })
    }
    else {
      this.getAfterhoursInbox(obj.ID);
    }
    this.activeDepartment = obj.value;
    console.log(obj)
  };

  getDispatchDriversData() {
    this.sharedService.getDriversByDispatcher(this.loggedUser.pt_id).subscribe((response: any) => {
      this.tabsArray[0].driversArray = response;
      this.chatGroups = this.tabsArray[0].driversArray
    })
  }

  //Chat for Safety/Maintenance/Recruiting/Hours Of Service/Accounting
  getChatData() {
    this.subscription1 = this.sharedService.getInboxDataByDepartment(this.loggedUser.department)
    .subscribe((response: any) => {
      this.chatMessages = response.chatMessages;
      console.log(response);
    });  
  };

  //Chat for dispatch
  getDispatchInbox(driverId: number) {
    this.subscription2 = this.sharedService.getInboxByDriver(driverId).subscribe((response: any) => {
      this.chatMessages = response.chatMessages;
      console.log(response);
    });
  };

  //Chat for afterhours
  getAfterhoursInbox(groupId: number) {
    this.subscription3 = this.sharedService.getAfterhoursInbox(groupId).subscribe((response: any) => {
      this.chatMessages = response.chatMessages;
      console.log(response);
    });
  };

  getActiveDrivers() {
    this.subscription4 = this.sharedService.getDriversForChat().subscribe((response: any) => {
      for(let i = 0; i < response.length; i++) {
        this.tabsArray[1].driversArray.push(
          {'ID': 0, 
          'date_created': "0001-01-01T00:00:00", 
          'driverID': `${response[i].id}`,
          'driverName': `${response[i].first_name} ${response[i].last_name}`,
          'isDispatcherOnline': false,
          'isDriverOnline': false,
          'lastRead': null,
          'numOfUnreadMessages': 0
        })
        this.senderArray.push({id: response[i].id, name: `${response[i].first_name} ${response[i].last_name}`, value: 'driver'})
      } 
    })
  }

  getUsersData() {
    this.subscription5 = this.sharedService.getAllSettingsUsers().subscribe((response: any) => {
      for(let i = 0; i < response.length; i++) {
        this.senderArray.push({id: response[i].pt_id, name: response[i].full_name, value: 'user'});
      };
    });
  };

  replyMessage(isDriver: boolean, obj: any) {
    if(isDriver) {
      this.senderValue = obj.driverName;
    }
    else {
      this.senderValue = obj.userName;
    }
    this.messageToType = obj.value;
    this.formGroup.patchValue({'driverID': obj.driverID, 'driverName': obj.driverName});
    this.formGroup.patchValue({'replyMessageID': obj.ID});
  };

  selectMessageType(obj: any) {
    this.senderValue = obj.name; 
    this.messageToType = obj.value;
    if(obj.name === 'Fleet Wide') {
      this.formGroup.patchValue({'isFleetWideMessage': true});
    }
    if(this.loggedUser.department === 'After Hours') {
      this.formGroup.patchValue({'isAfterHoursReply': true});
    }
    this.isOpenSenderMenu = false;
  };
 
  sendMessage() {
    if(this.senderValue === 'Fleet Wide') {
      this.formGroup.patchValue({'isFleetWideMessage': true});
    }
    else {
      this.formGroup.patchValue({'isFleetWideMessage': false});
    }
    let formData: any = this.formGroup.value;
    let department: string = this.loggedUser.department;

    if(department === 'After Hours') {
      department = this.activeDepartment;
    };

    console.log(this.formGroup.value)
    this.sharedService.sendMessage(formData, department, this.messageToType, formData.replyMessageID, this.loggedUser.pt_id)
    .subscribe((response: string) => {
      console.log(response);
      if(response === 'OK') {
        this.chatMessages.push(formData);
        this.formGroup.reset({
          'ID': 0,
          'deleteDate': '',
          'driverID': null,
          'driverName': '',
          'editedDate': null,
          'imagebase64': '',
          'isAfterHoursReply': this.loggedUser.department === 'After Hours' ? true : false,
          'isDeleted': false,
          'isDriverMessage': false,
          'isEdited': false,
          'isFleetWideMessage': this.senderValue === 'Fleet Wide' ? true : false,
          'isRead': false,
          'message': '',
          'messageReadDate': null,
          'messageSentDate': null,
          'replyMessageID': formData.replyMessageID,
          'userID': this.loggedUser.id,
          'userName': this.creator
        })
      }
    });
  };

  //Upload photo
  onPhotoSelected(event: any) {
    const picture = event.target.files[0] as File;
    const reader: any = new FileReader();
    reader.onloadend = () => {
      this.formGroup.patchValue({
      'imagebase64': reader.result.split(',')[1]
      })
      this.sendMessage();
    };
    reader.readAsDataURL(picture);
  };

  previewImage(image: string) {
    this.dialog.open(ImagePreviewComponent, {
      autoFocus: false,
      panelClass: 'component-dialog-container',
      data: image
    });
  };

  getMessageTypeClass(obj: any): string {
    if(obj.isAfterHoursReply && obj.isFleetWideMessage) {
      return 'border-left-fleetwide orange-bg';
    }
    else if(obj.isAfterHoursReply) {
      return 'orange-bg';
    }
    else if(obj.isFleetWideMessage) {
      return 'border-left-fleetwide default-bg';
    }
    else {
      return 'border-left-classic default-bg';
    }
  };

  ngOnDestroy(): void {
    clearInterval(this.interval);
    this.subscription1?.unsubscribe(); 
    this.subscription2?.unsubscribe(); 
    this.subscription3?.unsubscribe(); 
    this.subscription4?.unsubscribe(); 
    this.subscription5?.unsubscribe(); 
  };

}

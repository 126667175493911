import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orientationFilter'
})
export class OrientationFilterPipe implements PipeTransform {

  transform(array: any[], filterValue: string, orientationDate: string, recruiter: string, typeKey: string, selectedDriverIndex: number, metaData: any): any[] {
    if (!array) {
      return [];
    }
  
    //Obj to return
    let countObj: any = {
      allNum: 0,
      inTheOfficeNum: 0,
      notHiredNum: 0,
      hiredNum: 0,
      inTransitNum: 0,
      leftNum: 0,
      inAHotelNum: 0,
      noAnswerNum: 0,
      array: [],
      selectedDriverObj: null
    }
 
    let transformedArray: any[] = array.filter((obj: any) => {
      let jsObj: any = JSON.parse(obj.DataJson);
      let condition1: boolean = obj.status.includes(filterValue) || filterValue === 'All';
      let condition2: boolean = obj.orientation_date === orientationDate;
      let condition3: boolean = obj.recruiter === recruiter || recruiter === 'All';
      let condition4: boolean = jsObj.positionGroup[typeKey] || typeKey === 'All';

      //Count statistics 
      if(obj.orientation_date === orientationDate) {
        countObj.allNum++;

        if(obj.status === 'In the office') {
          countObj.inTheOfficeNum++;
        };
        if(obj.status === 'Not hired') {
          countObj.notHiredNum++;
        };
        if(obj.status === 'Hired') {
          countObj.hiredNum++;
        };
        if(obj.status === 'In transit') {
          countObj.inTransitNum++;
        }
        if(obj.status === 'Left') {
          countObj.leftNum++;
        };
        if(obj.status === 'In a hotel') {
          countObj.inAHotelNum++;
        };
        if(obj.status === 'No answer') {
          countObj.noAnswerNum++;
        };

      };

      if(condition1 && condition2 && condition3 && condition4) { 
        return obj;
      };

    });

    let displayObj: any = {};

    if(transformedArray.length > 0) {
      let selectedObj: any = {...transformedArray[selectedDriverIndex]};
      selectedObj.DataJson = JSON.parse(selectedObj.DataJson);
      displayObj = selectedObj;
    }
  
    //Set statistics data
    countObj.array = transformedArray;
    countObj.selectedDriverObj = displayObj;
    metaData.countObj = countObj;

    return transformedArray;

  }

}

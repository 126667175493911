import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { BusinessPartnersPageComponent } from './components/business-partners-page.component';
import { AuthGuard } from '../core/services/auth/auth.guard';


const routes: Routes = [
    {
        path: 'dispatchers',
        children: [
            {
                path: 'business-partners-page',
                canActivate: [AuthGuard],
                component: BusinessPartnersPageComponent,
                data: { roles: 7 }
            }
        ]
    
    }
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class BusinessPartnersRoutingModule { }

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { FullAppService } from '@app/modules/full-app/services/full-app.service';
import { ISpecialRequirements } from '@app/modules/full-app/models/special-requirements.model';
import { DeleteConfirmationDialogComponent } from '@app/modules/shared/components/delete-confirmation-dialog/delete-confirmation-dialog.component';
import { SuccessDialogComponent } from '@app/modules/shared/components/success-dialog/success-dialog.component';

@Component({
  selector: 'full-application-special-requirements',
  templateUrl: './full-application-special-requirements.component.html',
  styleUrls: ['./full-application-special-requirements.component.scss']
})
export class FullApplicationSpecialRequirementsComponent implements OnInit, OnDestroy {
  @Input() id: number;
  requirementsArray: any[] = [
    {name: 'No special requirements', img: '', selected: false},
    {name: 'Fridge', img: '../../../../../assets/img/fridge.png', selected: false},
    {name: 'Inverter', img: '../../../../../assets/img/battery.png', selected: false},
    {name: 'Auxiliary Power Unit', img: '../../../../../assets/img/apu.png', selected: false},
    {name: 'Passenger', img: '../../../../../assets/img/seat_passenger.png', selected: false},
    {name: 'PET', img: '../../../../../assets/img/pet.png', selected: false},
    {name: 'Gps', img: '../../../../../assets/img/route-black.png', selected: false},
    {name: 'New mattress', img: '../../../../../assets/img/bed.png', selected: false},
    {name: 'Other', img: '../../../../../assets/img/question-mark-black.png', selected: false}
  ]

  specialRequirementsObj: ISpecialRequirements = {
    id: 0,
    driver_id: 0,
    note: '',
    recuirements: []
  }

  //Subscription
  subscription: Subscription = new Subscription();

  constructor(private fullAppService: FullAppService,
              private dialog: MatDialog) { }

  ngOnInit(): void {
    this.subscription = this.fullAppService.getAllSpecialRequirements(this.id).subscribe((response: ISpecialRequirements) => {
      console.log(response);
      if(response.id) {
        this.specialRequirementsObj = response;
        for(let i = 0; i < this.requirementsArray.length; i++) {
          if(response.recuirements.includes(this.requirementsArray[i].name)) {
            this.requirementsArray[i].selected = true;
          }
        };
      };
    });
  }

  selectRequirements(obj: any) {
    obj.selected = !obj.selected;
    if(obj.name === 'No special requirements' && obj.selected) {
      for(let i = 1; i < this.requirementsArray.length; i++) {
        this.requirementsArray[i].selected = false;
      };
    }
    else {
      this.requirementsArray[0].selected = false;
    }

    let index: number = this.specialRequirementsObj.recuirements.findIndex(element => element === obj.name);
    if(index === -1) {
      this.specialRequirementsObj.recuirements.push(obj.name);
    }
    else {
      this.specialRequirementsObj.recuirements.splice(index, 1);
    }
  };

  saveRequirements() {
    this.specialRequirementsObj.driver_id = this.id;
    console.log(this.specialRequirementsObj);
    this.fullAppService.createUpdateSpecialRequirements(this.specialRequirementsObj).subscribe((response: ISpecialRequirements) => {
      if(response.id) {
        this.dialog.open(SuccessDialogComponent, {
          autoFocus: false,
          panelClass: 'success-dialog-container'
        });  
      }
    });
  };

  deleteRequirement() {
    let dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
      autoFocus: false,
      panelClass: 'delete-dialog-container',
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
        if(this.specialRequirementsObj.id) {
          this.fullAppService.deleteSpecialRequirements(this.specialRequirementsObj.id).subscribe((response: boolean) => {
            console.log(response);
            if(response) {
              this.specialRequirementsObj.id = 0;
              this.specialRequirementsObj.note = '';
              this.specialRequirementsObj.recuirements = [];
            };
          });
        }
        else {
          this.specialRequirementsObj.id = 0;
          this.specialRequirementsObj.note = '';
          this.specialRequirementsObj.recuirements = [];
        };

        for(let i = 0; i < this.requirementsArray.length; i++) {
          this.requirementsArray[i].selected = false;
        };
      }
    });
  };

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  };

}

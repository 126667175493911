import { ChangeDetectorRef, Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

import { MatPaginator } from '@angular/material/paginator';
import { Subject } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { DriversSearchService } from '../services/drivers-search.service';
import { RulesService } from '@app/modules/shared/services/rules.service';
import { TransformService } from '@app/modules/shared/services/transform.service';

import * as L from 'leaflet';
import {MarkerClusterGroup} from "leaflet.markercluster";
import { SharedService } from '@app/modules/shared/services/shared.service';

@Component({
  selector: 'app-dashboard-drivers-search-page',
  templateUrl: './dashboard-drivers-search-page.component.html',
  styleUrls: ['./dashboard-drivers-search-page.component.scss']
})
export class DashboardDriversSearchPageComponent implements OnInit {
  permissions: any = this.rulesService.UserData[20].data[0].allowedAll;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  public errorMessage;
  public listName = 'origin';

  public amazonPickupCode: string;
  public amazonDeliveryCode: string;

  public individualLocationsView = true;

  private stageTitle: string[];

  private waypoints = [];

  private driversLocations;
  private amazonLocations;

  private zipCodes;
  public amazonCodes;

  private map = null;

  private dataSetIndividual = []
  private dataSetOrigin = [];
  private dataSetDestination = [];


  public originDataTable;
  public destinationDataTable;

  public selectedRows = [];

  private driver: any;
  private drivers: any[] = [];

  private driversEmailList = [];

  private route = {
      origin: '',
      destination: ''
  };

  public displayedColumns = ['position', 'full_name', 'phone', 'email'];
  public driversDataSource: MatTableDataSource<any>;
  public pageSize: number = 10;

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  dtInstance: DataTables.Api;

  loading = false;

  //Route details
  displayColumnsRoute: string[] = ['position', 'status', 'distance', 'duration'];
  dataSourceRouteDetails: any[] = [];
  totalObj: any = {
    totalDistance: 0,
    totalDuration: 0
  }

  public states: string[];

  locationArray: any[] = [];
  isOpenIndividualLocationMenu: boolean = false;
  individualLocation: string = '';

  isOpenStartLocationMenu: boolean = false;
  startPickupLocation: string = '';
  isOpenEndLocationMenu: boolean = false;
  endDeliveryLocation: string = '';

  loaded: boolean = false;
  error: boolean = false;
  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";
  spinnerBgColor: string = this.transformService.spinnerBgColor;
  spinnerType: string = this.transformService.spinnerType;

  public constructor(private transformService: TransformService,
                      private rulesService: RulesService, 
                      private driversSearchService: DriversSearchService, 
                      private sharedService: SharedService,
                      public ref: ChangeDetectorRef,
                      private spinner: NgxSpinnerService) { }

  applyFilter(filterValue: string) {
    console.log('Filter value: ', filterValue);
    this.driversDataSource.filter = filterValue.trim().toLowerCase();
  }

  ngOnInit() {
    this.spinner.show('driver-search-loader');
    this._getLocations();
    this._getZipCodes();
    this._getAmazonLocations();
    this._setDataSource([]);
  }

  _getLocations() {
    this.sharedService.getAllLocationInfo().subscribe((response: any) => {
        console.log(response);
        this.locationArray = response;
    });
  };

  private _getDrivers() {
    this.driversSearchService.drivers$.subscribe(
      response => {
        console.log('Drivers: ', response);
        this.driversLocations = response;
        if(this.driversLocations) {
          this._initMap();
        }
      }
    );
  }


  private _setDataSource(dataSource) {
    console.log('_setDataSource length: ', dataSource.length);
    this.loading = true;
    this.driversDataSource =  new MatTableDataSource(dataSource);
    this.driversDataSource.paginator = this.paginator;
    this.loading = false;
  }

  private _refreshDrivers() {
    this._getDrivers();
  }

  public updatePerson(id: string, status: string) {
      console.info('update person:', id + ' - ' + status);

      // this.driversService.updatePipeDrivePerson(id, status).subscribe(
      //     response => {
      //         console.info(response);
      //     }
      // );
  }

  private _getZipCodes() {
      this.driversSearchService.getZipCodes().subscribe(
          response => {
      this.zipCodes = response;
      console.log('getZipCodes >', response);
      this._getDrivers();
          },
          (error) => { 
            this.errorMessage = <any>error
          }
      );
  }

  private _getAmazonLocations() {
      this.driversSearchService.getAmazonLocations().subscribe(
          response => {
              this.amazonLocations = response;
              this.amazonLocations = this.amazonLocations.reduce(function (map, obj) {
                  map[obj.code] = obj;
                  return map;
              }, {});
              this.amazonCodes = Object.keys(this.amazonLocations);
          },
          error => {
              this.errorMessage = <any>error
              console.info('ERRORR Message', this.errorMessage);
          }
      );
  }

  private _initMap() {
    const tiles = L.tileLayer(
        "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        {
          maxZoom: 18,
          attribution:
            '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Points &copy 2012 LINZ'
        }
      ),
      center = L.latLng(41.85, -87.65);

    this.map = L.map("map", { 
        center: center, 
        zoom: 5, 
        layers: [tiles] 
    });

    const markerIcon = 
        L.icon({
        iconSize: [24, 35],
        iconAnchor: [0, 35],
        popupAnchor: [13, -35],
        iconUrl: "./assets/img/location_icon_2.png"
        })

    const markerCluster = new MarkerClusterGroup(); 
    for (let i = 0; i < this.driversLocations.length; i++) {

        if (this.zipCodes[this.driversLocations[i].zip_code] !== undefined) {

            var lat = +this.zipCodes[this.driversLocations[i].zip_code].lat;
            var lng = +this.zipCodes[this.driversLocations[i].zip_code].lng;

            const marker = L.marker(new L.LatLng(lat, lng),{title: '', icon: markerIcon});
            marker.bindPopup(this.infoWindowRender(this.driversLocations[i]));
            markerCluster.addLayer(marker);  
        }
    }
    this.map.addLayer(markerCluster); 
    this.loaded = true;
    this.spinner.hide('driver-search-loader');   
  };

  infoWindowRender(info) {
      var html = '';
      html += `<p><b>Driver:&nbsp;</b> ${ info.full_name }</p>`;
      html += '<p><b>Phone:&nbsp;</b> <span class=""><a href="tel:' + info.phone + '">' + info.phone + '</a></span></p>';
      html += '<p><b>E-mail:&nbsp;</b> <span class=""><a href="mailto:' + info.email + '">' + info.email + '</a></span></p>';
      //html += '<button class="infowindow-button-' + info.zip_code + '" (click)="updatePerson()">Click me</button>';

      //html += '<div class="form-group"><label for="comment">Message or Comment:</label><textarea class="form-control" rows="5" id="comment"></textarea></div>';
      //html += '<a href="#" class="btn btn-warning" onclick="locationClick(' + info.email + ');"><i class="fa fa-envelope"></i>' + 'Send Email' + '</a>';
      //html += '<a href="#" class="btn btn-primary pull-right" onclick="locationClick(' + info.email + ');"><i class="fa fa-save"></i>' + 'Save' + '</a>';
      return html;
  }

  licationListItemRender(index, info) {
      var html = '\n\
          <td align="center">' + info.full_name + '</td>\n\
          <td><a href="tel:' + info.phone + '">' + info.phone + '</a></td>\n\
          <td><a href="mail:' + info.email + '">' + info.email + '</a></td>\n\
          <td align="right">' + '<a href="#" class="w3-button w3-small w3-green w3-disabled" onclick="driverClick(' + index + ');">' + 'Details' + '</a>' + '</td>';
      return html;
  }

  locationData(index, info) {
      var row = [
          '<b>' + info.full_name + '</b>',
          '<a href="tel:' + info.phone + '">' + info.phone + '</a>',
          '<a href="mail:' + info.email + '">' + info.email + '</a>',
          '<a href="#" class="w3-button w3-small w3-green w3-disabled" onclick="locationClick(' + index + ');">' + 'Email' + '</a>'
      ];
      return row;
  }

  getMiles(m) {
      return (m * 0.000621371192).toFixed(2).toString();
  }

  getHours(mi) {
      return (mi / 63).toFixed(1).toString() + ' hours';
  }

  openList(evt, listName) {
      var i, x, tablinks;
      x = document.getElementsByClassName("list");
      for (i = 0; i < x.length; i++) {
          x[i].style.display = "none";
      }
      tablinks = document.getElementsByClassName("tablink");
      for (i = 0; i < x.length; i++) {
          tablinks[i].className = tablinks[i].className.replace(" w3-deep-orange", "");
      }
      document.getElementById(listName).style.display = "block";
      evt.currentTarget.className += " w3-deep-orange";
  }

  /**
   * Toggle amazon and clacic locations search
   */

  public isAmazonLocations = false;
  public amazonToggleInfo = "OFF";

  /**
   * Filter locations on map based on selected location and radius
   */

  public radius = 50;
  public slider;

  public setRadius(value) {
  this.radius = value;
  }

  public getCloseLocations() {
      this.loading = true;
      let location = this.individualLocation;
      this.dataSetIndividual = [];
      this.map.remove();
      this._initMap();

      this.sharedService.getCoordinates(location, '').subscribe((response: any) => {
        console.log(response);
        if(response.origin.length > 0) {
          let cooridinates: any = {lat: Number(response.origin[0].lat), lng: Number(response.origin[0].lon)};
          const circle = L.circle(cooridinates, {
            color: 'steelblue',
            radius: this.radius * 1609.344,
            fillColor: 'steelblue',
            opacity: 0.5
          }).addTo(this.map);
          this.map.fitBounds(circle.getBounds());

          let locations = this.driversLocations;

          for (let j = 0; j < locations.length; j++) {

              if (this.zipCodes[locations[j].zip_code] !== undefined) {

                  let lat = +this.zipCodes[locations[j].zip_code].lat;
                  let lng = +this.zipCodes[locations[j].zip_code].lng;
                  let marker_lat_lng = {lat: lat, lng: lng};
                  let distance_from_location = this.map.distance(cooridinates, marker_lat_lng)
                  if (distance_from_location <= this.radius * 1609.344) {
                    let info = locations[j];
                    console.log(info);
                    this.dataSetIndividual.push(info);
                  }
              }
          }

          this._setDataSource(this.dataSetIndividual);
        }
        else {
            alert("No results found while geocoding!");
        }
      })
  }

private _getCloseLocationsForRoute(origin: any, destination: any) {
  this.listName = 'origin';
  this.dataSetOrigin = [];
  this.dataSetDestination = [];
  const circleOrigin = L.circle(origin, {
    color: 'steelblue',
    radius: this.radius * 1609.344,
    fillColor: 'steelblue',
    opacity: 0.5
  }).addTo(this.map);

  const circleDestination = L.circle(destination, {
    color: 'steelblue',
    radius: this.radius * 1609.344,
    fillColor: 'steelblue',
    opacity: 0.5
  }).addTo(this.map);
  this.map.fitBounds([circleOrigin.getBounds(),circleDestination.getBounds()]);
  let locations = this.driversLocations;

  for (let j = 0; j < locations.length; j++) {

      if (this.zipCodes[locations[j].zip_code] !== undefined) {

          let lat = +this.zipCodes[locations[j].zip_code].lat;
          let lng = +this.zipCodes[locations[j].zip_code].lng;
          let marker_lat_lng = {lat: lat, lng: lng};
          let distance_from_origin = this.map.distance(origin, marker_lat_lng);
          let distance_from_destination = this.map.distance(destination, marker_lat_lng);

          if (distance_from_origin <= this.radius * 1609.344) {
            let info = locations[j];
            this.dataSetOrigin.push(info);
          }

          if (distance_from_destination <= this.radius * 1609.344) {
            let info = locations[j];
            this.dataSetDestination.push(info);
          }
      }
  }
  this.showRouteTable();
}


createAmazonAddress(code: any) {
  let obj = this.amazonLocations[code];
  return obj.fc_city + ', ' + obj.fc_state + ', ' + obj.zip_code
}

setAddressesHolders() {
    if (this.isAmazonLocations) {
        this.startPickupLocation = this.amazonLocations[this.amazonPickupCode].zip_code;
        this.endDeliveryLocation = this.amazonLocations[this.amazonDeliveryCode].zip_code;

        this.route.origin = this.createAmazonAddress(this.amazonPickupCode);
        this.route.destination = this.createAmazonAddress(this.amazonDeliveryCode);

        this.setAmazonLocationsTable();
    } 
    else {
        this.route.origin = this.startPickupLocation;
        this.route.destination = this.endDeliveryLocation;
    }
    this.setWaypoints();
}


  setWaypoints() {
    this.waypoints = [];
    if (this.individualLocation !== '') {
        let waypoint = this.startPickupLocation;
        this.waypoints.push(waypoint);
        this.route.origin = this.individualLocation; // private variable route
    } else {
        this.waypoints = [];
    }
  }


// Mayor route location action
public calculateAndDisplayRoute() {
    this.loading = true;
    this.setAddressesHolders();
    console.log(this.startPickupLocation)
    console.log(this.endDeliveryLocation);
    console.log(this.individualLocation)

    this.sharedService.getCoordinates(this.startPickupLocation, this.endDeliveryLocation, this.individualLocation).subscribe((response: any) => {
        console.log(response);
        this.map.remove();
        this._initMap();
        if(response.origin.length > 0 && response.destination.length > 0) {
          let origin: any = {lat: Number(response.origin[0].lat), lng: Number(response.origin[0].lon)};
          let destination: any = {lat: Number(response.destination[0].lat), lng: Number(response.destination[0].lon)};
          let routingPlanArray: any[] = [
            L.latLng(origin.lat, origin.lng),
            L.latLng(destination.lat, destination.lng)
          ]
          if(response?.start?.length > 0) {
            routingPlanArray.unshift(L.latLng(Number(response.start[0].lat), Number(response.start[0].lon)));
          }
     
          let routeControl: any = L.Routing.control({
            containerClassName: "leaflet-instructions",
            plan: L.Routing.plan(routingPlanArray, {
              createMarker: (i, wp, nWps) => {
                console.log(routingPlanArray);
                if(routingPlanArray.length === 3) {
                  if (i === 0) {
                    let myStartIcon = L.icon({
                      iconUrl: './assets/img/location_start.png',
                      iconSize: [34, 34],
                      iconAnchor: [17, 34]
                    });
                    return L.marker(wp.latLng, {icon: myStartIcon });
                  }
                  else if(i === 1) {                        
                    let middleIcon = L.icon({
                      iconUrl: './assets/img/location.png',
                      iconSize: [34, 34],
                      iconAnchor: [17, 34]
                    });
                    return L.marker(wp.latLng, {icon: middleIcon });
                  }
                  else {                        
                    let myEndIcon = L.icon({
                      iconUrl: './assets/img/location_end.png',
                      iconSize: [34, 34],
                      iconAnchor: [17, 34]
                    });
                    return L.marker(wp.latLng, {icon: myEndIcon });
                  }
                }
                else {
                  if (i === 0 ) {
                    let myStartIcon = L.icon({
                      iconUrl: './assets/img/location_start.png',
                      iconSize: [34, 34],
                      iconAnchor: [17, 34]
                    });
                    return L.marker(wp.latLng, {icon: myStartIcon });
                  }
                  else {                        
                    let myEndIcon = L.icon({
                      iconUrl: './assets/img/location_end.png',
                      iconSize: [34, 34],
                      iconAnchor: [17, 34]
                    });
                    return L.marker(wp.latLng, {icon: myEndIcon });
                  }
                } 
              }
            }),
            routeWhileDragging: true,
            addWaypoints: true,
            showAlternatives: true,
            lineOptions: {
              styles: [{color: '#022bb1', opacity: 0.6, weight: 10}], extendToWaypoints: false, missingRouteTolerance: 0
           },
           altLineOptions: {
            styles: [{color: '#022bb1', opacity: 0.3, weight: 10}], extendToWaypoints: false, missingRouteTolerance: 0,

         },
          }).addTo(this.map);
          
          routeControl.on('routesfound', (e) => {
            let routes = e.routes;
            this.setRouteDetailsTable(routes, routingPlanArray);
          });
          this._getCloseLocationsForRoute(routingPlanArray[0], routingPlanArray[routingPlanArray.length -1]);
          this.map.fitBounds([[origin.lat, origin.lng],[destination.lat, destination.lng]]);
        }
        else {
            window.alert('Directions request failed. Try again later!');
        }
    })
    //$('#info-container').show();
}

setRouteDetailsTable(route: any, routingPlanArray: any[]) {
    let arrayToDisplay: any[] = [];
    let total = 0;

    if(routingPlanArray.length === 3) {
      let multipleWaypointsTotal: number = 0;
      for (let i = 0; i < route[0].instructions.length; i++) {
        total += route[0].instructions[i].distance;
        multipleWaypointsTotal += route[0].instructions[i].distance;
        if(route[0].instructions[i].type === 'WaypointReached') {
          const miles = this.getMiles(multipleWaypointsTotal);
          arrayToDisplay.push({status: 'Empty' , distance: miles, duration: this.getHours(miles)});
          multipleWaypointsTotal = 0;
        }
        if(route[0].instructions[i].type === 'DestinationReached') {
          const miles = this.getMiles(multipleWaypointsTotal);
          arrayToDisplay.push({status: 'Loaded' , distance: miles, duration: this.getHours(miles)});
          multipleWaypointsTotal = 0;
        }
      }
    }
    else {
      for (let i = 0; i < route.length; i++) {
        const miles = this.getMiles(route[i].summary.totalDistance);
        arrayToDisplay.push({status: 'Empty' , distance: miles, duration: this.getHours(miles)});
        total += route[i].summary.totalDistance;
      }
    }
    console.log(route);
    // For each route, display summary information.
    this.totalObj.totalDistance = this.getMiles(total);
    this.totalObj.totalDuration = this.getHours(this.getMiles(total));
    this.dataSourceRouteDetails = arrayToDisplay;
}

setAmazonLocationsTable() {
    var locations_table = $('#amazon-locations-table > tbody');

    var pickup = this.amazonLocations[this.amazonPickupCode];
    var delivery = this.amazonLocations[this.amazonDeliveryCode];

    var html = '';
    html += '<tr>';
    html += '<td><b>Pickup</b></td>';
    html += '<td>' + pickup.code + '</td>';
    html += '<td>' + pickup.zip_code + '</td>';
    html += '<td>' + pickup.fc_node + '</td>';
    html += '<td>' + pickup.fc_city + '</td>';
    html += '<td>' + pickup.fc_state + '</td>';
    html += '<td>' + pickup.fc_type + '</td>';
    html += '<tr>';

    html += '<tr>';
    html += '<td><b>Delivery</b></td>';
    html += '<td>' + delivery.code + '</td>';
    html += '<td>' + delivery.zip_code + '</td>';
    html += '<td>' + delivery.fc_node + '</td>';
    html += '<td>' + delivery.fc_city + '</td>';
    html += '<td>' + delivery.fc_state + '</td>';
    html += '<td>' + delivery.fc_type + '</td>';
    html += '<tr>';

    //    var html = '';
    //    //code,zip_code,fc_node,fc_city,fc_state,fc_type
    //    html += '<tr><td>' + pickup.code + '</td><td>' + delivery.code + '</td><td><b>Code</b></td><tr>';
    //    html += '<tr><td>' + pickup.zip_code + '</td><td>' + delivery.zip_code + '</td><td><b>ZIP Code</b></td><tr>';
    //    html += '<tr><td>' + pickup.fc_node + '</td><td>' + delivery.fc_node + '</td><td><b>Node</b></td><tr>';
    //    html += '<tr><td>' + pickup.fc_city + '</td><td>' + delivery.fc_city + '</td><td><b>City</b></td><tr>';
    //    html += '<tr><td>' + pickup.fc_state + '</td><td>' + delivery.fc_state + '</td><td><b>State</b></td><tr>';
    //    //html += '<tr><td>' + pickup.site_type + '</td><td>' + delivery.site_type + '</td><td><b>Site Type</b></td><tr>';
    //    html += '<tr><td>' + pickup.fc_type + '</td><td>' + delivery.fc_type + '</td><td><b>Type</b></td><tr>';

    locations_table.html(html);
}

showIndividualTable() {
    this.individualLocationsView = true;
}

showRouteTable() {
    this.individualLocationsView = false;
    this.setDriversList('origin');
    console.info('TEST this.dataTable: ', this.dataSetOrigin);
}

public activeTab = 0;

public tabs = [
    { text: 'Origin', value: 'origin' },
    { text: 'Destination', value: 'destination' },
    { text: 'All', value: 'all' },
    // ...
]


public dataSource = [];

  public setDriversList(list: string) {
  console.log('List: ', list);
  switch (list) {
          case 'origin':
      this.dataSource = this.dataSetOrigin;
              break;
          case 'destination':
              this.dataSource = this.dataSetDestination;
              break;
          default:
      this.dataSource = [];
              this.dataSource = [...this.dataSetOrigin, ...this.dataSetDestination];
              break;
  }
  //this.ref.detectChanges();

  this._setDataSource(this.dataSource);
}

removeSelectedDrivers() {
  this.selectedRows.splice(0, this.selectedRows.length);
}

  onSelect({ selected }) {
      console.log('Select Event', selected, this.selectedRows);

      this.selectedRows.splice(0, this.selectedRows.length);
      this.selectedRows.push(...selected);
  }

  public listAction = '';
  public actionButton = { label: 'Action', value: '' };

  public listActions = {
  refresh: { label: "Refresh Drivers List", title: "Refresh Drivers List" },
      active: { label: "Set status to Active", title: "Set status to Active" },
      inactive: { label: "Set status to Inactive", title: "Set status to Inactive" },
  notify: { label: "Notify for route", title: "Notify for route" },
  notify_ind: { label: "Notify for ind. location", title: "Notify for ind. location" },
  }

  setAction(action: string) {
      this.listAction = action;
      this.actionButton.label = this.listActions[action].title;
  }

  submitListAction() {

      console.info('this.selectedRows', this.selectedRows);

      switch (this.listAction) {
        case 'refresh':
            this._refreshDrivers();
            break;
        case 'active':
            this.seActiveStatus('1');
            break;
        case 'inactive':
            this.seActiveStatus('2');
            break;
        case 'notify':
            this.sendEmails(false);
        break;
      case 'notify_ind':
            this.sendEmails(true);
            break;				
          default:
            break;
      }
  }

  private seActiveStatus(status: string) {
      this.selectedRows.forEach(element => {
          this.updatePerson(element.pipedrive_id, status);
          element.active = (status == "2") ? 'Inactive' : 'Active';
      });
  }

  private emailValidation(ind: boolean) {

    // Check is there any selected drivers
    if(this.selectedRows.length < 1) {
      alert('No drivers selected.');
      return false;
    }
    // Check addresses
    if(ind) {
      if(this.individualLocation == '') {
        alert('Select individual location first.');
        return false;
      }
    } else {
      if(this.route.origin == '' || this.route.destination == '') {
        alert('Select route first.');
        return false;
      }
    }

    return true;
  }

  private emailsContent(ind: boolean) {
    var emailsData = {recipient_list: []};

        this.selectedRows.forEach(element => {
            var driver: any = {}
            driver.email = element.email;
            driver.full_name = element.full_name;
            emailsData['recipient_list'].push(driver);
    });
    
    if(ind) {
      emailsData['location'] = this.individualLocation;
    } else {
      emailsData['route'] =  this.route;
    }

    return emailsData;
  }

  private sendEmails(ind: boolean) {
    if(!this.emailValidation(ind)) {return};
    var emailsData = this.emailsContent(ind);
    console.info('emailsData', emailsData);

    if(confirm("Are you sure you want to send email to " + this.selectedRows.length + " selected drivers? ")) {

      this.driversSearchService.sendEmails(emailsData).subscribe(
        data => {
          console.log('Response:', data);
          this.emailSentMessage(data.toString());

        },
        error => console.log('ERROR:', error)
      );
    } 
  }

  private emailSentMessage(data: string) {
      if (data == 'success') {
          alert('Emails has been sent to the selected drivers successfully');
      } else {
          alert('There ware some problems in email sending, plese try again later.');
      }
  }

}


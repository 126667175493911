import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FullAppPaperworkDialogComponent } from '../../shared/components/full-app-paperwork-dialog/full-app-paperwork-dialog.component';
import { IPaperwork, IPaperworkFile } from '@app/modules/full-app/models/paperwork.model';
import { Subscription } from 'rxjs';
import { FullAppService } from '@app/modules/full-app/services/full-app.service';
import { SharedService } from '@app/modules/shared/services/shared.service';
import moment = require('moment');
import { DeleteConfirmationDialogComponent } from '@app/modules/shared/components/delete-confirmation-dialog/delete-confirmation-dialog.component';

@Component({
  selector: 'full-application-paperwork',
  templateUrl: './full-application-paperwork.component.html',
  styleUrls: ['./full-application-paperwork.component.scss']
})
export class FullApplicationPaperworkComponent implements OnInit, OnDestroy {
  @Input() id: number;
  
  dataSource: IPaperwork[] = [];
  userData: any = JSON.parse(localStorage.getItem('currentUser'));

  //Subscription
  subscription: Subscription = new Subscription();

  constructor(private dialog: MatDialog, 
              private fullAppService: FullAppService,
              private sharedService: SharedService) { }

  ngOnInit(): void {
    this.subscription = this.fullAppService.getAllPaperworks(this.id).subscribe((response: IPaperwork[]) => {
      this.groupByDocumentGroup(response);
      console.log(this.dataSource);
    });
  }

  createUpdatePaperwork() {
    let dialogRef = this.dialog.open(FullAppPaperworkDialogComponent, {
      autoFocus: false,
      panelClass: 'paperwork-dialog-container',
      disableClose: true,
      data: this.id
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
        //
      }
    });
  };

  openFileInNewTab(file: IPaperworkFile) {
    this.sharedService.downloadPreviewFile(file.name, file.data, true);
  };

  deleteFile(obj: IPaperwork, element: IPaperwork, index: number) {
    let dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
      autoFocus: false,
      panelClass: 'delete-dialog-container',
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
        this.fullAppService.deleteFile(obj.id).subscribe((response: boolean) => {
          if(response) {
            element.files.splice(index, 1);
          };
        });
      }
    });
  };

  missingDocumentation(element: IPaperwork, elementObj: IPaperwork) {
    let obj: IPaperwork = JSON.parse(JSON.stringify(element));
    obj.is_ok = !obj.is_ok;
    this.fullAppService.createUpdatePaperwork(obj).subscribe((response: any) => {
      element.is_ok = response.is_ok;
      for(let i = 0; i < elementObj.files.length; i++) {
        if(!elementObj.files[i].is_ok) {
          elementObj.documentation = false;
        }
        else {
          elementObj.documentation = true;
        }
      }
      console.log(obj);
    });
  };

  //Select files
  onFileSelected(event: any, index: number) {  
    let length: any = Object.keys(event.target.files).length;
    for(let i = 0; i < length; i++) {
      this.addMultipleFiles(event, i, length, index);
    }
  }

  addMultipleFiles(event: any, i: number, length: number, index: number) {
    let filename = event.target.files[i].name;
    let reader = new FileReader();
    reader.onload = (e: any) => {
      let files: IPaperworkFile[] = [];
      let base64 = e.target.result.split(',')[1];
      let obj: IPaperworkFile = {
        'category': '',
        'name': filename,
        'data': base64,
        'updated': false,
        'deleted': false
      }
      files.push(obj);

      if(files.length === length) {
        for(let i = 0; i < files.length; i++) {
          let papeworkObj: IPaperwork = {
            id: 0,
            driver_id: this.id,
            document_group: this.dataSource[index].document_group,
            document_name: files[i].name,
            upload_date: moment().format('YYYY-MM-DDT00:00:00'),
            upload_by: this.userData.first_name + ' ' + this.userData.last_name,
            is_ok: true,
            file: files[i]
          }
          this.fullAppService.createUpdatePaperwork(papeworkObj).subscribe((response: IPaperwork) => {
            if(response?.id) {
              this.dataSource[index].files.push(response);
            }
          });
        }
      }
    };
    reader.readAsDataURL(event.target.files[i]);
  };

  downloadFile(obj: IPaperworkFile) {
    const extension = obj.name.split('.').pop().toLowerCase();
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp', 'svg', 'tiff', 'ico'];
    let base64: string = '';
    if(extension === 'pdf') {
      base64 = `data:application/pdf;base64,${obj.data}`
    }
    if(imageExtensions.includes(extension)) {
      base64 =  `data:image/${extension === 'jpg' ? 'jpeg' : extension};base64,${obj.data}`;
    }
    if(extension === 'docx') {
      base64 = `data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,${obj.data}`
    }
    if(extension === 'doc') {
      base64 = `data:application/msword;base64,${obj.data}`
    }
    let anchor = document.createElement('a');
    anchor.download = obj.name;
    anchor.href = base64;
    anchor.click();
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  };

  groupByDocumentGroup(array: IPaperwork[]) {
    const groups: any = array.reduce((acc: any, obj: IPaperwork) => {
    
    if (!acc[obj.document_group]) {
      acc[obj.document_group] = [];
    }
        
    acc[obj.document_group].push(obj);
      
    return acc;
      
    }, {});
    
    for(let key in groups) {
      let lastElement: IPaperwork = JSON.parse(JSON.stringify(groups[key][groups[key].length -1]));
      lastElement.files = [];
      lastElement.documentation = true;
      for(let i = 0; i < groups[key].length; i++) {
        if(groups[key][i].file) {
          lastElement.files.push(groups[key][i]);
        }
        lastElement.documentation = groups[key][i].is_ok;
      };
      this.dataSource.push(lastElement);
    };
  }

}

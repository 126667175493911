import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LoadService } from '@app/modules/safety/services/load.service';
import { Subscription } from 'rxjs';
import { LoadNoteDialogComponent } from './load-note-dialog/load-note-dialog.component';

@Component({
  selector: 'load-info',
  templateUrl: './load-info.component.html',
  styleUrls: ['./load-info.component.scss']
})
export class LoadInfoComponent implements OnInit, OnDestroy {
  @Input() loadNo: number = 0;

  loadDataArray: any[] = [];

  //Subscription
  subscription: Subscription = new Subscription();

  constructor(private loadService: LoadService, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.getLoadInfoData();
  };
 
  getLoadInfoData() {
    this.subscription = this.loadService.getLoadInfoData(this.loadNo).subscribe((response: any) => {
      this.loadDataArray = response;
      console.log(response);
    });
  };

  openNoteDialog(element: any) {
    this.dialog.open(LoadNoteDialogComponent, {
      autoFocus: false,
      panelClass: 'loads-note-dialog-container',
      data: element
    });
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  };

}

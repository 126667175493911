import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'multiPropertySearch'
})
export class MultiPropertySearchPipe implements PipeTransform {

  transform(array: any[], searchText: string, key1: string, key2: string): any[] {
    if (!array) {
      return [];
    }
    if (!searchText) {
      return array;
    }
    searchText = searchText.toLocaleLowerCase();

    return array.filter((obj: any) => {
      if(key1 === '' || key2 === '') {
        return obj.toLocaleLowerCase().includes(searchText);
      } 
      else {
        return obj[key1]?.toLocaleLowerCase().includes(searchText) || obj[key2]?.toLocaleLowerCase().includes(searchText);
      }
    });
  }

}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { LoadService } from '@app/modules/safety/services/load.service';
import { TransformService } from '@app/modules/shared/services/transform.service';
import { Subscription, catchError, throwError } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-loads-driver-app',
  templateUrl: './loads-driver-app.component.html',
  styleUrls: ['./loads-driver-app.component.scss']
})
export class LoadsDriverAppComponent implements OnInit, OnDestroy {
  tableData: any[] = [];

  loaded: boolean = false;
  error: boolean = false;
  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";

  spinnerBgColor: string = this.transformService.spinnerBgColor;
  spinnerType: string = this.transformService.spinnerType;

  //Subscription
  subscription: Subscription = new Subscription();

  constructor(private loadService: LoadService,
              private transformService: TransformService,
              private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.spinner.show('loads-table');
    this.subscription = this.loadService.getAllLoads()
    .pipe(catchError((err: any) => {
      this.spinner.hide('loads-table');
      this.loaded = true;
      this.error = true;
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      console.log(response);
      this.tableData = response;
      this.spinner.hide('loads-table');
      this.loaded = true;
    });
  };

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  };
}

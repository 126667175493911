import { ConnectionPositionPair } from '@angular/cdk/overlay';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { SuccessDialogComponent } from '@app/modules/shared/components/success-dialog/success-dialog.component';
import { WarningMsgDialogComponent } from '@app/modules/shared/components/warning-msg-dialog/warning-msg-dialog.component';
import moment = require('moment');

@Component({
  selector: 'dispatch-info-card',
  templateUrl: './dispatch-info-card.component.html',
  styleUrls: ['./dispatch-info-card.component.scss']
})
export class DispatchInfoCardComponent implements OnInit {
  //Info obj
  @Input() infoObj: any = {
    birthday: '', 
    cell_phone: '',
    city: '',
    departement: '',
    division: '',
    email: '',
    emergency_phone_number: '',
    hire_date: '',
    id: '',
    jdm_id: null,
    name: '',
    phone_number: '',
    photo: null,
    position: '',
    state: null,
    terminated_date: null,
    time_in_company: ''
  };
  formGroup: FormGroup;

  //Dispatch info menu
  isOpenDispatchInfoMenu: boolean = false;
  public positions = [
    new ConnectionPositionPair(
      {originX: 'end', originY: 'bottom'},
      {overlayX: 'end', overlayY: 'top'},
      0, 5
    )
  ];

  public datePositions = [
    new ConnectionPositionPair(
      {originX: 'center', originY: 'bottom'},
      {overlayX: 'center', overlayY: 'top'},
      0, 10
    )
  ];

  onlyRead: boolean = true;

  //Department
  departmentsArray: any[] = [];
  isOpenDepartmentMenu: boolean = false;

  //Position
  positionsArray: any[] = [];
  isOpenPositionMenu: boolean = false;

  //Divisions
  divisionArray: any[] = [];
  isOpenDivisionMenu: boolean = false;

  //Dates
  isOpenHiringDate: boolean = false;
  isOpenTerminationDate: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private dialog: MatDialog) {
    this.formGroup = this.formBuilder.group({
      'id': [null],
      'department': null,
      'position': null,
      'division_name': null,
      'office': [null],
      'ext': [null],
      'email': [null],
      'phone_number': [null],
      'emergency_phone_number': [null],
      'hire_date': [null],
      'termination_date': [null]
    })
  }

  ngOnInit(): void {
    if(this.infoObj.id) {
      this.formGroup.setValue({
        'id': this.infoObj.id,
        'department': this.infoObj.departement,
        'position': this.infoObj.position,
        'division_name': this.infoObj.division,
        'office': this.infoObj.city,
        'ext': this.infoObj.extension,
        'email': this.infoObj.email,
        'phone_number': this.infoObj.cell_phone,
        'emergency_phone_number': this.infoObj.emergency_phone_number,
        'hire_date': this.infoObj.hire_date,
        'termination_date': this.infoObj.terminated_date
      })
    }
  }

  changeInfoStatus(changeInfo: boolean) {
    this.isOpenDispatchInfoMenu = false;
    if(changeInfo) {
      this.onlyRead = false;
    }
  };

  saveChanges() {
 
  };

  cancelEditing() {
    this.onlyRead = !this.onlyRead;
    /*
    let obj: any = this.data.info;
    this.formGroup.reset({
      'id': obj.id,
      'division_id': [-1],
      'division_name': obj.division,
      'city': obj.city,
      'state': obj.state,
      'zip': [null],
      'phone_cell': obj.phone_cell,
      'phone_emergency': obj.phone_emergency,
      'email': obj.email,
      'company_name': obj.company_name,
      'company_city': [null],
      'company_state': [null],
      'company_street1': [null],
      'company_zip': [null],
      'cdl_no': obj.cdl_no,
      'cdl_state': obj.cdl_state,
      'cdl_expiration': obj.cdl_exp_date,
      'medical_card_expiration_date': obj.mdeical_exp_date,
      'hire_date': obj.hire_date,
      'termination_date': obj.termination_date
    });
    */
  }


  //Set date
  setDate(event: any, key: string) {
    this.formGroup.patchValue({[key]: moment(event._d).format('YYYY-MM-DDT00:00:00')});
  };

  //Get form values
  getFormValue(formKey: string) {
    return this.formGroup.controls[formKey].value;
  };

  showSuccessMsg() {
    this.dialog.open(SuccessDialogComponent, {
      autoFocus: false,
      panelClass: 'success-dialog-container'
    });  
  };

  showErrorMsg() {
    this.dialog.open(WarningMsgDialogComponent, {
      autoFocus: false,
      panelClass: 'warning-msg-dialog-container'
    });
  };

  countPeriodInCompany() {
    let hireDate: string | null = this.getFormValue('hire_date');
    let terminationDate: string | null = this.getFormValue('termination_date');
    if(hireDate) {
      let date_1 = new Date(hireDate);
      let date_2 = terminationDate ? new Date(terminationDate) : new Date();
      let difference = date_2.getTime() - date_1.getTime();
      let totalDays = Math.ceil(difference / (1000 * 3600 * 24));
      return totalDays === 1 ? `${totalDays} day` : `${totalDays} days`;
    }
    else {
      return '0 days'
    }

  };

}


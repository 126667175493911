import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ITruckAssignedTrucks } from '@app/modules/accounting/models/trucks-assigments.model';
import { AccountingService } from '@app/modules/accounting/services/accounting.service';

@Component({
  selector: 'app-assign-trucks-dialog',
  templateUrl: './assign-trucks-dialog.component.html',
  styleUrls: ['./assign-trucks-dialog.component.scss']
})
export class AssignTrucksDialogComponent {
  selectedTrucks: string[] = [];
  selectedObjs: any[] = [];

  isOpenEmployeeMenu: boolean = false;
  accountingEmployee: string = '';
  accountingEmployeeId: number = 0;

  isOpenTrucksMenu: boolean = false;

  //Search
  truckSearch: string = '';

  constructor(@Inject(MAT_DIALOG_DATA) public obj: any, 
              public dialogRef: MatDialogRef<AssignTrucksDialogComponent>,
              private accountingService: AccountingService) { }

  selectAccountingEmployee(obj: any) {
    this.isOpenEmployeeMenu = !this.isOpenEmployeeMenu;
    this.accountingEmployee = `${ obj.name } ${ obj.nickname }`;
    this.accountingEmployeeId = obj.id;
  };

  selectTruck(obj: any) {
    obj.selected = !obj.selected;
    let index: number = this.selectedTrucks.indexOf(obj.unit_no)
    if (index !== -1) {
      this.selectedObjs.splice(index, 1);
      this.selectedTrucks.splice(index, 1);
    } 
    else {
      this.selectedObjs.push(obj);
      this.selectedTrucks.push(obj.unit_no);
    }
  };
  
  assignTrucks() {
    for(let i = 0; i < this.selectedTrucks.length; i++) {

      let obj: ITruckAssignedTrucks = {
        id: this.selectedObjs[i].id,
        unit_no: this.selectedObjs[i].unit_no,
        trailer_no: this.selectedObjs[i].trailer_no,
        employee: this.accountingEmployeeId
      }

      this.accountingService.assignTruck(obj).subscribe((response: any) => {
        if(i === this.selectedTrucks.length - 1) {
          this.dialogRef.close(true);
        }
        console.log(response);
      });
      
    }
  };
  
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { NgxSpinnerService } from 'ngx-spinner';
import { catchError, Subscription, throwError } from 'rxjs';
import { TruckDeviceConfirmationDeleteDialogComponent } from './truck-device-confirmation-delete-dialog/truck-device-confirmation-delete-dialog.component';
import { TruckDevicesAddEditDialogComponent } from './truck-devices-add-edit-dialog/truck-devices-add-edit-dialog.component';
import { TransformService } from '@app/modules/shared/services/transform.service';
import { SafetyService } from '@app/modules/safety/services/safety.service';


@Component({
  selector: 'app-truck-devices',
  templateUrl: './truck-devices.component.html',
  styleUrls: ['./truck-devices.component.scss']
})
export class TruckDevicesComponent implements OnInit, OnDestroy {
  searchText: string = '';

  displayedColumns: string[] = ['position', 'truckID', 'driverName', 'deviceID', 'status', 'merged_truck_file', 'merged_contract_file', 'actions'];
  sortedData: any[] = [];
  dataSource: any[] = [];

  loaded: boolean = false;

  error: boolean = false;

  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";

  spinnerBgColor: string = this.transformService.spinnerBgColor;
  spinnerType: string = this.transformService.spinnerType;

  subscription: Subscription | any;

  constructor(private dialog: MatDialog,
              private transformService: TransformService,
              private safetyService: SafetyService,
              private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.getTruckDevicesTableData();
    this.newRowAdded();
  }

  getTruckDevicesTableData() {
    this.spinner.show('truck-devices');
    this.subscription = this.safetyService.getTruckDevicesTable()
    .pipe(catchError((err: any) => {
      this.spinner.hide('truck-devices');
      this.loaded = true;
      this.error = true;
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      this.dataSource = JSON.parse(JSON.stringify(response));
      this.sortedData = response;
      this.spinner.hide('truck-devices');
      this.loaded = true;
      console.log(response);
    })
  }

  newRowAdded() {
    this.safetyService.newTruckDeviceAdded.subscribe((response: any) => {
      this.getTruckDevicesTableData();
    })
  }

  //Sort data
  sortData(sort: Sort) {
    const data = this.dataSource.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }
    this.sortedData = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'truckID':
          return compare(a.truckID, b.truckID, isAsc);
        case 'driverName':
          return compare(a.driverName, b.driverName, isAsc);
        case 'deviceID':
          return compare(a.deviceID, b.deviceID, isAsc);
        case 'status':
          return compare(a.status, b.status, isAsc);
        default:
          return 0;
      }
    });
  }

  openTruckDialog(newTruck: boolean, obj?: any) {
    this.dialog.open(TruckDevicesAddEditDialogComponent, {
      width: '440px',
      height: '566px',
      autoFocus: false,
      panelClass: 'truck-devices-dialog-container',
      data: {newTruck: newTruck, data: obj}
    });
  }

  changeStatus(obj: any) {
    let isActive: boolean = !obj.status;
    let statusNumber: number = isActive ? 1 : 0;
    this.safetyService.changeTruckStatus(obj.ID, statusNumber).subscribe((response: any) => {
      console.log(response);
    })
  }

  openTruckFileInNewTab(obj: any) {
    obj.truckPdfLoading = true;
    this.safetyService.getMergedFile(obj.truckID).subscribe((response: any) => {
      obj.truckPdfLoading = false;
      const anchor = document.createElement('a');
      document.body.appendChild(anchor);
      anchor.style.display = 'none';
      const blob = new Blob([response], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      anchor.href = url; 
      anchor.target = '_blank';
      anchor.click();
      window.URL.revokeObjectURL(url);
    })
  }

  openContractFileInNewTab(obj: any) {
    obj.contractPdfLoading = true;
    this.safetyService.getMergedContractFile(obj.driverID).subscribe((response: any) => {
      obj.contractPdfLoading = false;
      const anchor = document.createElement('a');
      document.body.appendChild(anchor);
      anchor.style.display = 'none';
      const blob = new Blob([response], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      anchor.href = url; 
      anchor.target = '_blank';
      anchor.click();
      window.URL.revokeObjectURL(url);
    })
  }

  deleteTruckRow(obj: any) {
    let dialogRef: any = this.dialog.open(TruckDeviceConfirmationDeleteDialogComponent, {
      width: '300px',
      height: '300px',
      autoFocus: false,
      panelClass: 'delete-truck-confirmation-dialog-container'
    })
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
        this.safetyService.deleteTruckDevice(obj.ID).subscribe((response: any) => {
          if(response) {
            this.getTruckDevicesTableData();
          }
        })
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ReportsService } from '@app/modules/reports/services/reports.service';
import { TransformService } from '@app/modules/shared/services/transform.service';
import moment = require('moment');
import { NgxSpinnerService } from 'ngx-spinner';
import { catchError, Subscription, throwError } from 'rxjs';

@Component({
  selector: 'report-truck-profitability',
  templateUrl: './report-truck-profitability.component.html',
  styleUrls: ['./report-truck-profitability.component.scss']
})
export class ReportTruckProfitabilityComponent implements OnInit, OnDestroy {
  //Date obj
  dateObj: any = {
    startDate: moment().startOf('month').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
  };

  //Data
  cardsDataPrev: any;
  cardsDataCurrent: any;
  days: number = 0;

  loaded: boolean = false;
  error: boolean = false;
  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";

  spinnerBgColor: string = this.transformService.spinnerBgColor;
  spinnerType: string = this.transformService.spinnerType;

  //Subscription
  subscription: Subscription = new Subscription();

  constructor(private transformService: TransformService,
              private reportService: ReportsService,
              private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.getReportsData();
  }

  getReportsData() {
    this.cardsDataCurrent = undefined;
    this.cardsDataPrev = {};
    this.days = 0;
    this.error = false;
    this.loaded = false;
    this.spinner.show('reports-truck-profitability');
    let difference = Math.abs(moment.duration(moment(this.dateObj.startDate).diff(moment(this.dateObj.endDate))).asDays());
    this.days = Math.round(difference);
    let obj: any;
    if(this.days === 0) { 
      obj = {
        startDate: moment().subtract(1, 'week').format('YYYY-MM-DD'), 
        endDate: moment().subtract(1, 'week').format('YYYY-MM-DD')
      };
    } 
    else {
      obj = {
        startDate: moment().subtract(difference*2, 'days').format('YYYY-MM-DD'), 
        endDate: moment().subtract(difference, 'days').format('YYYY-MM-DD')
      };
    }
    this.subscription = this.reportService.getTrucksProfitabilityData(this.dateObj.startDate, this.dateObj.endDate, obj.startDate, obj.endDate, this.transformService.filterParams)
    .pipe(catchError((err: any) => {
      this.spinner.hide('reports-truck-profitability');
      this.loaded = true;
      this.error = true;
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      this.cardsDataCurrent = this.countData(response[0]);
      this.cardsDataPrev = this.countData(response[1]);
      this.cardsDataPrev.days = this.days;
      this.cardsDataPrev.dayName = moment(this.dateObj.startDate).format('dddd');
      this.spinner.hide('reports-truck-profitability');
      this.loaded = true;
      console.log(this.countData(response[0]));
      console.log(response)
    });
  };

  countData(array: any[]) {
    let obj: any = {
      'total_trucks_all': array.length, 'total_trucks_company': 0, 'total_trucks_rent_to_run': 0, 'total_trucks_owner': 0,
      '-5000/0_all': 0, '-5000/0_company': 0, '-5000/0_rent_to_run': 0, '-5000/0_owner': 0,
      '0/1000_all': 0, '0/1000_company': 0, '0/1000_rent_to_run': 0, '0/1000_owner': 0,
      '1000/2000_all': 0, '1000/2000_company': 0, '1000/2000_rent_to_run': 0, '1000/2000_owner': 0,
      '2000/5000_all': 0, '2000/5000_company': 0, '2000/5000_rent_to_run': 0, '2000/5000_owner': 0,
      '+5000_all': 0, '+5000_company': 0, '+5000_rent_to_run': 0, '+5000_owner': 0,
      'fixed_cost_per_unit': 0, 'drivers_pay': 0, 'fuel': 0, 'maintenance': 0, 'tolls': 0
    }

    for(let i = 0; i < array.length; i++) {
      let cost: number = array[i].driver_pay + array[i].eld + array[i].equipment + array[i].fuel + array[i].gross + array[i].ifta
      + array[i].insurance + array[i].maintenance + array[i].tolls + array[i].truck;

      obj['fixed_cost_per_unit'] += cost;
      obj['drivers_pay'] += array[i].driver_pay;
      obj['fuel'] += array[i].fuel;
      obj['maintenance'] += array[i].maintenance;
      obj['tolls'] += array[i].tolls;

      this.increaseValue(cost, obj, {key1: '-5000/0_all', key2: '0/1000_all', key3: '1000/2000_all', key4: '2000/5000_all', key5: '+5000_all'});

      if(array[i].status === 'SOLO COMPANY' || array[i].status === 'TEAM COMPANY') {
        obj['total_trucks_company']++;
        this.increaseValue(cost, obj, {key1: '-5000/0_company', key2: '0/1000_company', key3: '1000/2000_company', key4: '2000/5000_company', key5: '+5000_company'});
      };
      if(array[i].status === 'SOLO RENT' || array[i].status === 'TEAM RENT') {
        obj['total_trucks_rent_to_run']++;
        this.increaseValue(cost, obj, {key1: '-5000/0_rent_to_run', key2: '0/1000_rent_to_run', key3: '1000/2000_rent_to_run', key4: '2000/5000_rent_to_run', key5: '+5000_rent_to_run'});

      };
      if(array[i].status === 'OWNER OPERATOR') {
        obj['total_trucks_owner']++;
        this.increaseValue(cost, obj, {key1: '-5000/0_owner', key2: '0/1000_owner', key3: '1000/2000_owner', key4: '2000/5000_owner', key5: '+5000_owner'});
      };
    };
    return obj;
  };

  increaseValue(cost: number, stObj: any, keysObj: any) {
    if(cost < 0) {
      stObj[keysObj.key1]++;
    };
    if(cost > 0 && cost <= 1000) {
      stObj[keysObj.key2]++;
    };
    if(cost > 1000 && cost <= 2000) {
      stObj[keysObj.key3]++;
    };
    if(cost > 2000 && cost <= 5000) {
      stObj[keysObj.key4]++;
    };
    if(cost > 5000) {
      stObj[keysObj.key5]++;
    };
  };

  getDate(result: any) {
    this.dateObj.startDate = moment(result.startDate).format('YYYY-MM-DD');
    this.dateObj.endDate = moment(result.endDate).format('YYYY-MM-DD');
    this.getReportsData();
  };

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();  
  };

}
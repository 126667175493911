import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../core/services/auth/auth.guard';
import { DispatchRewardSystemPageComponent } from './components/dispatch-reward-system-page/dispatch-reward-system-page.component';

const routes: Routes = [
    {
		path: 'dispatch-reward-system',
		canActivate: [AuthGuard],
		component: DispatchRewardSystemPageComponent,
		data: { roles: 32 }
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class DispatchRewardSystemRoutingModule { }

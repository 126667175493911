import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import moment = require('moment');
import { NgxSpinnerService } from 'ngx-spinner';

import { catchError, Subscription, throwError } from 'rxjs';
import { CalendarDialogComponent } from '@app/modules/shared/components/calendar-dialog/calendar-dialog.component';
import { RulesService } from '@app/modules/shared/services/rules.service';
import { TransformService } from '@app/modules/shared/services/transform.service';
import { MaintenanceService } from '../services/maintenance.service';

@Component({
  selector: 'app-dashboard-maintenance-page',
  templateUrl: './dashboard-maintenance-page.component.html',
  styleUrls: ['./dashboard-maintenance-page.component.scss']
})
export class DashboardMaintenancePageComponent implements OnInit, OnDestroy {
  permissionsTruck: any = this.rulesService.UserData[11].data[1].sectionArray;
  permissionsTrailer: any = this.rulesService.UserData[11].data[2].sectionArray;
  data: any;
  dateObj: any = {
    startDate: this.transformService.convertDateToTimestamp(moment().startOf('month').format('ddd, DD/MM YYYY'), 
    '00:00:00'),
    endDate: this.transformService.convertDateToTimestamp(moment().format('ddd, DD/MM YYYY'),  moment().format('HH:mm:ss')),
  };

  subscription1: Subscription | any;
  subscription2: Subscription | any;

  days: any;
  previousPeriod: any = {};

  secondRange: any;

  btnsArray: any[] = ['Trucks', 'Trailers'];

  selectedIndex: number = 0;

  //Interval
  interval: any;

  loaded: boolean = false;

  error: boolean = false;

  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";

  spinnerBgColor: string = this.transformService.spinnerBgColor;
  spinnerType: string = this.transformService.spinnerType;

  constructor(private transformService: TransformService,
              private rulesService: RulesService, 
              private dialog: MatDialog,
              private maintenanceService: MaintenanceService,
              private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.spinner.show('maintenance-cards');
    this.getCards();
    if(this.rulesService.liveUpdate) {
      this.interval = setInterval(() => {
        this.getCards();
      }, this.rulesService.miliseconds);
    }
  }

  
  getCards() {
    let start: any;
    let end: any;
    if(this.secondRange?.twoRange) {
      start = this.secondRange.startDate2;
      end = this.secondRange.endDate2;

    } else {
      start = this.dateObj.startDate;
      end = this.dateObj.endDate;
    }

    var startRes = moment(start, "YYYY-MM-DD");
    var endRes = moment(end, "YYYY-MM-DD")
    let difference = Math.abs(moment.duration(startRes.diff(endRes)).asDays());
    this.days = Math.round(difference);
    let obj: any;
    if(this.days === 0) { 
      obj = {startDate: this.transformService.convertDateToTimestamp(moment().subtract(1, 'week').format('ddd, DD/MM YYYY'), 
      '00:00:00'), endDate: this.transformService.convertDateToTimestamp(moment().subtract(1, 'week').format('ddd, DD/MM YYYY'),
      '23:59:59')};
    } else {

      obj = {startDate: this.transformService.convertDateToTimestamp(moment().subtract(difference*2, 'days').format('ddd, DD/MM YYYY'), 
    '00:00:00'), endDate: this.transformService.convertDateToTimestamp(moment().subtract(difference, 'days').format('ddd, DD/MM YYYY'),
    '23:59:59')};
    }
  
    this.subscription1 = this.maintenanceService.getMaintenanceCards(this.dateObj.startDate, this.dateObj.endDate, 
    obj.startDate, obj.endDate, this.transformService.filterParams)
    .pipe(catchError((err: any) => {
      this.spinner.hide('maintenance-cards');
      this.loaded = true;
      this.error = true;
      return throwError(() => err);
    }))
    .subscribe((res: any[]) => {
      this.data = res[0];
      this.previousPeriod.data = res[1];
      this.previousPeriod.days = this.days;
      this.previousPeriod.twoRange = this.secondRange?.twoRange;
      this.previousPeriod.dayName = moment(obj.start).format('dddd');
      this.spinner.show('maintenance-cards');
      this.loaded = true;
      console.log(res);
    })
}

getDate(result) {
  this.changeDate(result);
};

openFloatingDialog() {
  let dialogRef: any = this.dialog.open(CalendarDialogComponent, {
    minWidth: '769px',
    height: '476px',
    autoFocus: false,
    data: {oneRange: false}
 });
  dialogRef.afterClosed().subscribe((result: any) => {
    if(result) {
      this.changeDate(result);
    }
  });
}

changeDate(result: any) {
  this.secondRange = result;
  this.dateObj.startDate = result.startDate;
  this.dateObj.endDate = result.endDate;
  this.maintenanceService.dateChanged.next(this.dateObj);
  this.data = undefined;
  this.previousPeriod = {};
  this.error = false;
  this.loaded = false;
  this.spinner.show('maintenance-cards');
  let data = {
    cards: undefined, key: undefined, title: undefined,
    type: undefined, downCard: undefined, reset: true
  };
  this.maintenanceService.maintenanceCardsLineChartSubject.next(data);
  this.getCards();
}

refresh() {
  this.changeDate(this.dateObj)
}

//Change view

changeView(index: number) {
  this.selectedIndex = index;
}

ngOnDestroy(): void {
  clearInterval(this.interval);
  this.subscription1?.unsubscribe();
  this.subscription2?.unsubscribe();
}

}

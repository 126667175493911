export interface Notification {
  id: number;
  type: string;
  data: string | ForbidenState;
  date: Date;
  from_user: number;
  for_user: number;
  approved: boolean;
  readded: boolean;
  approved_by: number;
  readed_date?: Date;
}

export interface ForbidenState {
  load_no: number;
  weight: number;
  driver: string;
  unit_no: string;
  email: string;
  dispatcher: string;
  pl_state: string;
  pl_city: string;
  dl_state: string;
  dl_city: string;
  pl_date: Date;
  dl_date: Date;
  broker: string;
  amount: number;
  mileage: number;
  rate: number;
  pickup: boolean;
}


export enum NotificationType {
  EX_DRIVER = 'EX DRIVER',
  FIX_MILES = 'FIX MILES',
  FORBIDEN_MILEAGE = 'FORBIDEN MILEAGE',
  FORBIDEN_WIGHT = 'FORBIDEN WIGHT',
  FORBIDEN_STATE = 'FORBIDEN STATE',
  FORBIDEN_STATE_DISP = 'FORBIDEN STATE DISP',
  EX_DISPETCHER = 'EX DISPETCHER',
  NEW_DISPETCHER = 'NEW DISPETCHER',
  FORBIDEN_STATE_DENIED = 'FORBIDEN STATE DENIED',
  FORBIDEN_STATE_APPROVED = 'FORBIDEN STATE APPROVED',
  MEDICAL_EXPIRATION = 'MEDICAL EXPIRATION',
  CDL_EXPIRATION = 'CDL EXPIRATION',
  NEW_DRIVER_PHONE = 'NEW DRIVER PHONE',
  NEW_DRIVER_EMAIL = 'NEW DRIVER EMAIL',
  NOTE = 'NOTE',
  TICKET = 'TICKET',
  ADD_BROKER_TO_LIST = 'ADD_BROKER_TO_LIST',
  REMOVE_BROKER_FROM_LIST = 'REMOVE_BROKER_FROM_LIST',
  
  DOT_INSPECTION = 'DOT_INSPECTION',
  CONTACT_ACCOUNTING = 'CONTACT_ACCOUNTING',
  VACATION = 'VACATION',
  URGENT_ASSISTANCE = 'URGENT_ASSISTANCE',
  TT_DISTANCE = 'TT_DISTANCE',

  TASK_ASSIGNED = 'TASK_ASSIGNED',
  TASK_ASSIGNED_FIRST = 'TASK_ASSIGNED_FIRST',
  TASK_ASSIGNED_SECOND = 'TASK_ASSIGNED_SECOND',
  TASK_ASSIGNED_THIRD = 'TASK_ASSIGNED_THIRD',

  CHARGE_THE_DRIVER = 'CHARGE_THE_DRIVER',
  FUEL_RECEIPT_RECEIVED = 'FUEL_RECEIPT_RECEIVED'
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dispTask'
})
export class DispTaskPipe implements PipeTransform {

  transform(array: any[], statusFilter: number, metaData: any): any[] {
    if (!array) {
      return [];
    }

    let countObj: any = {
      pending: 0,
      overdue: 0,
      done: 0
    };

    let transformedArray: any[] = array.filter((obj: any) => {
      if(obj.status === 0 || obj.status === 1) {
        countObj.pending++;
      }
      if(obj.status === 1) {
        countObj.overdue++;
      }
      if(obj.status === 2) {
        countObj.done++;
      }  

      let condition: boolean = true;
      if(statusFilter === 0 && (obj.status === 0 || obj.status === 1)) {
        condition = true;
      }
      else {
        condition = statusFilter === obj.status;
      }

      if(condition) {
        return obj;
      }

    });
   
    metaData.countObj = countObj;
    return transformedArray;

  }

}

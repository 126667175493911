import { Component, Input } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { TransformService } from '@app/modules/shared/services/transform.service';
import * as XLSX from 'xlsx'; 

@Component({
  selector: 'truck-mileage-table',
  templateUrl: './truck-mileage-table.component.html',
  styleUrls: ['./truck-mileage-table.component.scss']
})
export class TruckMileageTableComponent {
  @Input() dataSource: any[] = [];
  @Input() date: any;

  constructor(public transformService: TransformService) { }

  sortData(sort: Sort) {
    const data = JSON.parse(JSON.stringify(this.dataSource));
    if (!sort.active || sort.direction === '') {
      this.dataSource = data;
      return;
    }
    this.dataSource = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'unit_no':
          return compare(a.unit_no, b.unit_no, isAsc);
        case 'driver':
          return compare(a.driver, b.driver, isAsc);
        case 'dispatcher':
          return compare(a.dispatcher, b.dispatcher, isAsc);
        case 'mileage':
          return compare(a.mileage, b.mileage, isAsc);
        case 'gross':
          return compare(a.gross, b.gross, isAsc);
        case 'rate_per_mile':
          return compare(a.rate_per_mile, b.rate_per_mile, isAsc);
        default:
          return 0;
      }
    });
  }

  //Export to excel
  exportToExcel() {
    let dataRows: string = '';
    for(let i = 0; i < this.dataSource.length; i++) {
      dataRows += `
      <tr>
        <td>${i + 1}</td>
        <td>${this.dataSource[i].unit_no}</td>
        <td>${this.dataSource[i].driver}</td>
        <td>${this.dataSource[i].dispatcher}</td>
        <td>${this.dataSource[i].mileage}</td>
        <td>${this.dataSource[i].gross}</td>
        <td>${this.dataSource[i].rate_per_mile}</td>
        <td>${this.dataSource[i].status}</td>
      </tr>`
    }

    let table: string = `
      <table>
        <tr>
          <th>No</th>
          <th>Truck</th>
          <th>Driver</th>
          <th>Dispatcher</th>
          <th>Mileage</th>
          <th>Gross</th>
          <th>Rate per mile</th>
          <th>Type</th>
        </tr>

        ${dataRows}

      </table>
    `;
    let dom = document.createRange().createContextualFragment(table).firstElementChild;
    console.log(dom)
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(dom);

    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    XLSX.writeFile(wb, 'mileage-per-truck.xlsx');
  };

}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { AskForTruckDialogComponent } from '../ask-for-truck-dialog/ask-for-truck-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Subscription, catchError, throwError } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import moment = require('moment');
import { DemandReserveSpotDialogComponent } from '../demand-reserve-spot-dialog/demand-reserve-spot-dialog.component';
import { AskForApprovalDialogComponent } from '../ask-for-approval-dialog/ask-for-approval-dialog.component';
import { WarningMsgDialogComponent } from '@app/modules/shared/components/warning-msg-dialog/warning-msg-dialog.component';
import { SharedService } from '@app/modules/shared/services/shared.service';
import { FleetHealthService } from '@app/modules/fleet-health/services/fleet-health.service';
import { TransformService } from '@app/modules/shared/services/transform.service';

@Component({
  selector: 'app-demand-by-safety-table',
  templateUrl: './demand-by-safety-table.component.html',
  styleUrls: ['./demand-by-safety-table.component.scss']
})

export class DemandBySafetyTableComponent implements OnInit, OnDestroy {
  numOfScheduledTrucks: number = 0;
  numOfToBeScheduledTrucks: number = 0;

  dataSourceAllData: any[] = [];

  dataSource: MatTableDataSource<[]> = new MatTableDataSource();
  displayedColumns = ['unit_no', 'driver', 'dispatcher', 'date', 'reasons', 'demand_by', 'status', 'edit'];
  activeSection: number = 1;

  statusArray: any[] = [
    {status: 'Scheduled'},
    {status: 'To be Scheduled'},
    {status: 'Arrived'}
  ];

  statusArrayBySection: string[] = [
    'Arrived', 'Reserve spot', 'To be Scheduled'
  ];

  //Loader
  loaded: boolean = false;
  error: boolean = false;
  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";

  spinnerType: string = this.transformService.spinnerType;

  //Subscription
  subscription1: Subscription | any;
  subscription2: Subscription | any;

  constructor(private dialog: MatDialog,
              private fleetHealthService: FleetHealthService,
              private transformService: TransformService,
              private spinner: NgxSpinnerService,
              private sharedService: SharedService) { }

  ngOnInit(): void {
    this.getTableData(true);
    this.refreshData();
  }

  getTableData(showSpinner: boolean) {
    if(showSpinner) {
      this.dataSource.data = [];
      this.loaded = false;
      this.spinner.show('demand-by-safety-spinner');
    }
    this.subscription1 = this.fleetHealthService.askForTruckTableData()
    .pipe(catchError((err: any) => {
      this.spinner.hide('demand-by-safety-spinner');
      this.loaded = true;
      this.error = true;
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      this.numOfScheduledTrucks = response[0].length;
      this.numOfToBeScheduledTrucks = response[1].length;
      this.dataSourceAllData = [...response];
      if(this.activeSection < 2) {
        this.dataSource.data = response[this.activeSection];
      }
      if(showSpinner) {
        this.spinner.hide('demand-by-safety-spinner');
        this.loaded = true;
      }
      console.log(response);
    })
  };

  refreshData() {
    this.subscription2 = this.fleetHealthService.refreshDataSubject.subscribe((response: any) => {
      this.getTableData(response);
    })
  }

  scheduleHistory() {
    this.fleetHealthService.askForTruckHistory().subscribe((response: any) => {
      this.dataSource.data = response;
      this.activeSection = 2;
    });
  };

  //Sort data
  sortData(sort: Sort) {
    const data = this.dataSource.data.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSource.data = data;
      return;
    }
    this.dataSource.data = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'unit_no':
          return compare(a.unit_no, b.unit_no, isAsc);
        case 'driver':
          return compare(a.driver, b.driver, isAsc);
        case 'dispatcher':
          return compare(a.dispatcher, b.dispatcher, isAsc);
        case 'date':
          return compare(a.date, b.date, isAsc);
        case 'subcategory':
          return compare(a.reasons[0].subcategory, b.reasons[0].subcategory, isAsc);
        case 'created_by':
          return compare(a.created_by, b.created_by, isAsc);
        default:
          return 0;
      }
    });
  }

  getReasons(obj: any) {
    let array: any = obj.reasons;
    return array.map((el: any) => `${el.subcategory}`).join(", ");
  };

  //Ask for truck dialog
  openAskForTruckDialog(isEdit: boolean, data: any) { 
    let dialogRef = this.dialog.open(AskForTruckDialogComponent, {
      autoFocus: false,
      panelClass: 'ask-for-truck-dialog-container',
      data: {editMode: isEdit, obj: data, activeSection: this.activeSection},
      disableClose: true
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result === 1) {
        this.activeSection = 1;
        this.getTableData(true);
      }
      if(result === 2) {
        this.fleetHealthService.refreshDataSubject.next(true);
      };
    });
  };

  //Search table
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  //Change status
  changeStatus(obj: any, status: string) {
    let loggedUser: any = JSON.parse(localStorage.getItem('currentUser'));
    let fullName: string = loggedUser.first_name + ' ' + loggedUser.last_name;

    if(status === 'Arrived') {
      let dataObj: any = {id: obj.id, status: status, createdBy: fullName, createdDate: moment().format('YYYY-MM-DDTHH:mm:ss')};
      this.fleetHealthService.changeTruckStatus(dataObj).subscribe((response: any) => {
        if(response) {
          this.fleetHealthService.refreshDataSubject.next(true);
        }
        else {
          this.dialog.open(WarningMsgDialogComponent, {
            autoFocus: false,
            panelClass: 'warning-msg-dialog-container'
          });
        }
      });
    }
    else {
      let dialogRef = this.dialog.open(DemandReserveSpotDialogComponent, {
        autoFocus: false,
        panelClass: 'demand-reserve-spot-dialog-container',
        data: {editMode: true, obj: obj},
        disableClose: true
      });
      dialogRef.afterClosed().subscribe((result: any) => {
        if(result === 1) {
          this.fleetHealthService.refreshDataSubject.next(true);
        }
      
        if(result === 2) {
          let dialogRef = this.dialog.open(AskForApprovalDialogComponent, {
            autoFocus: false,
            panelClass: 'ask-for-approval-dialog-container',
            data: {fillForm: true, obj: obj, spotDate: ''},
            disableClose: true
          });
          dialogRef.afterClosed().subscribe((result: any) => {
            if(result) {
              this.fleetHealthService.refreshDataSubject.next(true);
            }
          });
        }
      });
    }
    obj.isOpenMenu = !obj.isOpenMenu;
  }

  ngOnDestroy(): void {
    this.subscription1?.unsubscribe();
    this.subscription2?.unsubscribe();
  };

}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
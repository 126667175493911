import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TasksService } from '@app/modules/tasks/services/tasks.service';
import moment = require('moment');
import { DeleteConfirmationDialogComponent } from '@app/modules/shared/components/delete-confirmation-dialog/delete-confirmation-dialog.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription, catchError, throwError } from 'rxjs';
import { TransformService } from '@app/modules/shared/services/transform.service';
import { SharedService } from '@app/modules/shared/services/shared.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-related-to-dialog',
  templateUrl: './related-to-dialog.component.html',
  styleUrls: ['./related-to-dialog.component.scss']
})
export class RelatedToDialogComponent implements OnInit, OnDestroy {
  //Form group
  formGroup: FormGroup;

  dataSource: any = [];
  searchValue: string = '';

  //Category menu
  isOpenCategoryMenu: boolean = false;
  categoryArray: any[] = [
    {category: 'Driver'}, {category: 'Truck'}, {category: 'Trailer'}, {category: 'Load'}
  ];

  //Filter array
  filterArray: string[] = ['Driver', 'Truck', 'Trailer', 'Load'];
  activeFilter: string = 'Driver';

  //Edit index
  editedRowIndex: number | null = null;

  //Refresh data
  returnArray: boolean = false;

  loaded: boolean = false;
  error: boolean = false;
  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";

  spinnerBgColor: string = this.transformService.spinnerBgColor;
  spinnerType: string = this.transformService.spinnerType;

  //Subscription
  subscription: Subscription = new Subscription();

  constructor(private dialog: MatDialog,
              private transformService: TransformService,
              private tasksService: TasksService,
              private formBuilder: FormBuilder,
              public dialogRef: MatDialogRef<RelatedToDialogComponent>,
              private sharedService: SharedService,
              private spinner: NgxSpinnerService) {
                this.formGroup = this.formBuilder.nonNullable.group({
                  'id': [0],
                  'category': ['', [Validators.required, Validators.minLength(2)]],
                  'task': ['', [Validators.required, Validators.minLength(2)]],
                  'is_active': [true],
                  'created_by': [''],
                  'created_date': [null],
                  'edited_by': [''],
                  'edited_date': [null]
                })
              }

  ngOnInit(): void {
    this.getAllRelatedTo();
  }

  getAllRelatedTo() {
    this.spinner.show('related-to-table');
    this.subscription = this.tasksService.getRelatedToData()
    .pipe(catchError((err: any) => {
      this.spinner.hide('related-to-table');
      this.loaded = true;
      this.error = true;
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      this.dataSource = response;
      this.spinner.hide('related-to-table');
      this.loaded = true;
    })
  };

  addNewTask() {
    this.formGroup.reset();
    this.formGroup.patchValue({'category': this.activeFilter !== 'All tasks' ? this.activeFilter : ''});
    this.dataSource.unshift(this.formGroup.value);
    this.dataSource = [...this.dataSource]
    this.editedRowIndex = 0;
  };

  saveUpdateTask(element: any) {
    const loggedUser: any = JSON.parse(localStorage.getItem('currentUser'));
    const fullName: string = `${loggedUser.first_name} ${loggedUser.last_name}`;
    const nickname: string = `${loggedUser.nickname}`;
    const creator: string = nickname.length === 0 ? fullName : nickname?.trim().split(' ')[0];
    if(element.id) {
      this.formGroup.patchValue({
        'edited_by': creator,
        'edited_date': moment().format('YYYY-MM-DDTHH:mm:ss')
      });
    }
    else {
      this.formGroup.patchValue({
        'created_by': creator,
        'created_date': moment().format('YYYY-MM-DDTHH:mm:ss')
      });
    }
    this.tasksService.createUpdateRelatedTo(this.formGroup.value).subscribe((response: any) => {
      console.log(response);
      if(response?.id) {
        element.id = response.id
        element.category = response.category;
        element.task = response.task;
        element.is_active = response.is_active;
        element.created_by = response.created_by;
        element.created_date = response.created_date;
        element.edited_by = response.edited_by;
        element.edited_date = response.edited_date;
        this.formGroup.reset();
        this.editedRowIndex = null;
        this.returnArray = true;
      }
    });
  };

  editTask(element: any, index: number) {
    this.formGroup.setValue(element);
    this.editedRowIndex = index;
  };

  cancelTask(element: any, index: number) {
    if(element.id === 0) {
      this.dataSource.splice(index, 1);
      this.dataSource = [...this.dataSource];
    };
    this.editedRowIndex = null;
    this.formGroup.reset();
  }

  deleteTask(obj: any, index: number) {
    let dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
      autoFocus: false,
      panelClass: 'delete-dialog-container',
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
        let loggedUser: any = JSON.parse(localStorage.getItem('currentUser'));
        let date: string = moment().format('YYYY-MM-DDTHH:mm:ss');
        const fullName: string = `${loggedUser.first_name} ${loggedUser.last_name}`;
        const nickname: string = `${loggedUser.nickname}`;
        const creator: string = nickname.length === 0 ? fullName : nickname?.trim().split(' ')[0];
        this.tasksService.deleteRelatedTo(obj.id, loggedUser.id, creator, date).subscribe((response: any) => {
          if(response) {
            this.dataSource.splice(index, 1);
            this.dataSource = [...this.dataSource];
            this.tasksService.refreshData.next(this.dataSource);
            this.returnArray = true;
          };
        });
      }
    })
  };

  closeDialog() {
    if(this.returnArray) {
      this.dialogRef.close(this.dataSource);
    }
    else {
      this.dialogRef.close(null);
    }
  };

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();  
  };

}

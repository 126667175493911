import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FleetHealthService } from '@app/modules/fleet-health/services/fleet-health.service';
import moment = require('moment');

@Component({
  selector: 'app-reasons-dialog',
  templateUrl: './reasons-dialog.component.html',
  styleUrls: ['./reasons-dialog.component.scss']
})
export class ReasonsDialogComponent implements OnInit {
  displayedColumns: string[] = ['position', 'category', 'subcategory', 'created_by', 'created_date', 'edited_by',
  'edited_date', 'actions'];
  dataSource: any = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private fleetService: FleetHealthService) { }

  ngOnInit(): void {
    this.dataSource = this.data;
    console.log(this.data)
    this.data.forEach(element => {
      element.categoryCacheValue = element.category;
      element.subCategoryCacheValue = element.subcategory;
    });
  }

  saveUpdate(obj: any) {
    let loggedUser: any = JSON.parse(localStorage.getItem('currentUser'));
    if(!obj.id) {
      obj.isEdit = false;
      obj.category = obj.categoryCacheValue;
      obj.subcategory = obj.subCategoryCacheValue;
      obj.created_by = loggedUser.first_name + ' ' + loggedUser.last_name;
      obj.created_date = moment().format('YYYY-MM-DDTHH:mm:ss');
    }
    else {
      obj.isEdit = false;
      obj.category = obj.categoryCacheValue;
      obj.subcategory = obj.subCategoryCacheValue;
      obj.edited_by = loggedUser.first_name + ' ' + loggedUser.last_name;
      obj.edited_date = moment().format('YYYY-MM-DDTHH:mm:ss');
    }
    console.log(obj);
    this.fleetService.addUpdateReasons(obj).subscribe((response: any) => {
      console.log(response);
    });
  }

  addNewReason() {
    let obj: any = { category: '', created_by: '', created_date: '', edited_by: '', edited_date: '', id: 0, is_active: true, subcategory: '', 
    isEdit: true, categoryCacheValue: '', subCategoryCacheValue: ''};
    let array: any[] = [...this.dataSource];
    array.unshift(obj);
    this.dataSource = array;
    console.log(this.dataSource)
  };

  editRow(obj: any) {
    obj.isEdit = true;
    obj.categoryCacheValue = obj.category;
    obj.subCategoryCacheValue = obj.subcategory;
  }

  discard(obj: any) {
    obj.isEdit = false;
    obj.categoryCacheValue = '';
    obj.subCategoryCacheValue = '';
  }

  deleteReason(obj: any, i: number) {
    let loggedUser: any = JSON.parse(localStorage.getItem('currentUser'));
    let date: string = moment().format('YYYY-MM-DDTHH:mm:ss');
    let fullName: string = loggedUser.first_name + ' ' + loggedUser.last_name;

    if(obj.id) {
      this.fleetService.deleteReason(obj.id, loggedUser.id, fullName, date).subscribe((response: any) => {
        console.log(response);
        if(response) {
          let array: any[] = [...this.dataSource];
          array.splice(i, 1);
          this.dataSource = array;
        }
      });
    }
    else {
      let array: any[] = [...this.dataSource];
      array.splice(i, 1);
      this.dataSource = array;
    }

  };

}


import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterAccountingEmployee'
})
export class FilterAccountingEmployeePipe implements PipeTransform {

  transform(array: any[]): any[] {
    if (!array) {
      return [];
    }

    return array.filter((obj: any) => {
      if(obj.department.toLowerCase().includes('accounting')) {
        return obj;
      }
    })

  }

}

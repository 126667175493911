import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { DispatcherProfilePageRoutingModule } from './dispatcher-profile-page.routing';
import { DispatcherProfileService } from './services/dispatcher-profile.service';
import { ActivityTableComponent } from './components/activity-table/activity-table.component';
import { DropdownMenuComponent } from './components/activity-table/dropdown-menu/dropdown-menu.component';
import { DispatchInfoCardComponent } from './components/dispatch-info-card/dispatch-info-card.component';
import { DispatchProfileComponent } from './components/dispatch-profile.component';
import { DispatchCalendarActivityComponent } from './components/dispatch-calendar-activity/dispatch-calendar-activity.component';
import { TasksCardComponent } from './components/tasks-card/tasks-card.component';
import { DispatchStatisticsCardsComponent } from './components/dispatch-statistics-cards/dispatch-statistics-cards.component';
import { DriverTablePopupComponent } from './components/activity-table/driver-table-popup/driver-table-popup.component';
import { LoadsTableDispatcherComponent } from './components/loads-table-dispatcher/loads-table-dispatcher.component';
import { DispatchStatisticsLineChartComponent } from './components/dispatch-statistics-line-chart/dispatch-statistics-line-chart.component';
import { DispTaskPipe } from './pipes/disp-task.pipe';
import { ScorePageComponent } from './score-page/score-page.component';
import { ActivityTransformPipe } from './pipes/activity-transform.pipe';
import { LinechartDialogComponent } from './components/dispatch-statistics-line-chart/linechart-dialog/linechart-dialog.component';

@NgModule({
  declarations: [
    ActivityTableComponent,
    DropdownMenuComponent,
    DispatchInfoCardComponent,
    DispatchProfileComponent,
    DispatchCalendarActivityComponent,
    TasksCardComponent,
    DispatchStatisticsCardsComponent,
    DriverTablePopupComponent,
    LoadsTableDispatcherComponent,
    DispatchStatisticsLineChartComponent,
    DispTaskPipe,
    ScorePageComponent,
    ActivityTransformPipe,
    LinechartDialogComponent
  ],
  imports: [
    CommonModule,
    DispatcherProfilePageRoutingModule,
    SharedModule
  ],
  providers: [DispatcherProfileService]
})
export class DispatcherProfilePageModule { }

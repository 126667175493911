import { Component, Input } from '@angular/core';
import { RulesService } from '@app/modules/shared/services/rules.service';
import { TransformService } from '@app/modules/shared/services/transform.service';
import { TrucksService } from '@app/modules/trucks/services/trucks.service';

@Component({
  selector: 'app-empty-trucks-cards',
  templateUrl: './empty-trucks-cards.component.html',
  styleUrls: ['./empty-trucks-cards.component.scss']
})
export class EmptyTrucksCardsComponent  {
  permissions: any = this.rulesService.UserData[4].data[0].sectionArray;

  @Input() cardsData: any;

  activeCardsObj: any = [
    { selected: true },
    { selected: false },
    { selected: false },
    { selected: false },
    { selected: false },
    { selected: false },
    { selected: false }
  ];
  constructor(public transformService: TransformService, private trucksService: TrucksService, private rulesService: RulesService) { }

  setClass(emptyTrucks: boolean, selected: boolean) {
    let className: string = '';

    if (emptyTrucks) {
      className = 'alert-gradient'
    }

    if (selected) {
      className = 'active';
    }

    return className;
  }

  showInGraph(obj: any, forbiddenSelection: boolean) {
    obj.selected = !obj.selected;

    if (forbiddenSelection) {
      this.activeCardsObj[0].selected = false;
      this.activeCardsObj[4].selected = false;
      this.activeCardsObj[5].selected = false;
      this.activeCardsObj[6].selected = false;
    } else {
      this.activeCardsObj[1].selected = false;
      this.activeCardsObj[2].selected = false;
      this.activeCardsObj[3].selected = false;
    }

    let graphObj: any = { activeCards: this.activeCardsObj };
    this.trucksService.selectedCardSubject.next(graphObj);
  };


}

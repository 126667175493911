import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TicketsComponent } from './components/tickets.component';
import { AuthGuard } from '../core/services/auth/auth.guard';


const routes: Routes = [
	{
		path: '',
		component: TicketsComponent,
		canActivate: [AuthGuard],
		data: { roles: 28 }
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class TicketsRoutingModule { }

import { AfterContentChecked, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SetTargetDialogComponent } from './set-target-dialog/set-target-dialog.component';
import moment = require('moment');
import { catchError, Subscription, throwError } from 'rxjs';
import { DispatchRewardSystemService } from '@app/modules/dispatch-reward-system/services/dispatch-reward-system.service';
import { TransformService } from '@app/modules/shared/services/transform.service';
import { SharedService } from '@app/modules/shared/services/shared.service';
import { DispNamePipe } from '@app/modules/shared/pipes/disp-name.pipe';
import { NgxSpinnerService } from 'ngx-spinner';
import { Sort } from '@angular/material/sort';
import { ApexAxisChartSeries, ApexChart, ApexXAxis, ApexYAxis, ApexDataLabels, ApexGrid, ApexStroke, ChartComponent } from 'ng-apexcharts';
import { StatusDialogComponent } from '@app/modules/shared/components/status-dialog/status-dialog.component';
import { Router } from '@angular/router';
import { RulesService } from '@app/modules/shared/services/rules.service';
import { MsgForbbidenAccessComponent } from '@app/modules/shared/components/msg-forbbiden-access/msg-forbbiden-access.component';

export type ChartOptions = {
  series: ApexAxisChartSeries | any;
  chart: ApexChart | any;
  xaxis: ApexXAxis | any;
  yaxis: ApexYAxis | any;
  dataLabels: ApexDataLabels | any;
  grid: ApexGrid | any;
  stroke: ApexStroke | any;
  markers: any;
  colors: any;
  tooltip: any;
};

@Component({
  selector: 'dispatch-reward-system-cards',
  templateUrl: './dispatch-reward-system-cards.component.html',
  styleUrls: ['./dispatch-reward-system-cards.component.scss']
})
export class DispatchRewardSystemCardsComponent implements OnInit, AfterContentChecked, OnDestroy {
  permissions: any = this.rulesService.UserData[32].data;

  //Statistics array
  stCardsArray: any[] = [
    {date: moment().startOf('isoWeek').format('YYYY-MM-DD'), dataSource: [], targetDataSolo: [], targetDataTeam: [], targetDataSoloAndTeam: [],
      bonusAchievedSoloAndTeam: 0, bonusAchievedSolo: 0, bonusAchievedTeam: 0, targetSuccessRateSolo: 0, targetSuccessRateTeam: 0, sumGrossTarget: 0, sumRpmTarget: 0},
    {date: moment().subtract(1, 'week').startOf('isoWeek').format('YYYY-MM-DD'), dataSource: [], targetDataSolo: [], targetDataTeam: [], targetDataSoloAndTeam: [], 
      bonusAchievedSoloAndTeam: 0, bonusAchievedSolo: 0, bonusAchievedTeam: 0, targetSuccessRateSolo: 0, targetSuccessRateTeam: 0, sumGrossTarget: 0, sumRpmTarget: 0},
    {date: moment().subtract(2, 'week').startOf('isoWeek').format('YYYY-MM-DD'), dataSource: [], targetDataSolo: [], targetDataTeam: [], targetDataSoloAndTeam: [],
      bonusAchievedSoloAndTeam: 0, bonusAchievedSolo: 0, bonusAchievedTeam: 0, targetSuccessRateSolo: 0, targetSuccessRateTeam: 0, sumGrossTarget: 0, sumRpmTarget: 0},
    {date: moment().subtract(3, 'week').startOf('isoWeek').format('YYYY-MM-DD'), dataSource: [], targetDataSolo: [], targetDataTeam: [], targetDataSoloAndTeam: [],
      bonusAchievedSoloAndTeam: 0, bonusAchievedSolo: 0, bonusAchievedTeam: 0, targetSuccessRateSolo: 0, targetSuccessRateTeam: 0, sumGrossTarget: 0, sumRpmTarget: 0},
    {date: moment().subtract(4, 'week').startOf('isoWeek').format('YYYY-MM-DD'), dataSource: [], targetDataSolo: [], targetDataTeam: [], targetDataSoloAndTeam: [],
      bonusAchievedSoloAndTeam: 0, bonusAchievedSolo: 0, bonusAchievedTeam: 0, targetSuccessRateSolo: 0, targetSuccessRateTeam: 0, sumGrossTarget: 0, sumRpmTarget: 0}
  ];
  activeCard: number = 0;

  //Quick stats
  quickStatsObj: any = {
    countObj: {
      avgGross: 0,
      avgRpm: 0,
      avgMileage: 0
    }
  }
 
  //Drivers trucks data
  filtersViewArray: any[] = [
    {name: 'Drivers'},
    {name: 'Trucks'}
  ];
  activeViewFilter: number = 0;

  //Graph data
  @ViewChild("chart") chart: ChartComponent | any;
  public chartOptions: Partial<ChartOptions> | any;

  //Drivers-trucks datasource
  dataSource: any[] = [];
  loadsDataSource: any[] = [];
  graphData: any[] = [];
  loadsStObj: any = {
    avgGross: 0,
    avgRpm: 0,
    avgMileage: 0,
    showGrossInLinechart: true,
    showRpmInLinechart: false,
    showMileageInLinechart: false
  }
  statusesDataSource: any[] = [];
  isOpened: boolean = false;
  expandRowIndex: number | undefined = undefined;

  daysOfWeek: any[] = [];
  today: string = moment().format('YYYY-MM-DDTHH:mm:ss');

  //Date obj
  loadsDateObj: any = {
    'Last 5 weeks': {startDate: moment().subtract(5, 'week').startOf('isoWeek').format('YYYY-MM-DD'), endDate: moment().subtract(1, 'week').endOf('isoWeek').format('YYYY-MM-DD'), numOfWeeks: 5},
    'Last 4 weeks': {startDate: moment().subtract(4, 'week').startOf('isoWeek').format('YYYY-MM-DD'), endDate: moment().subtract(1, 'week').endOf('isoWeek').format('YYYY-MM-DD'), numOfWeeks: 4},
    'Last 3 weeks': {startDate: moment().subtract(3, 'week').startOf('isoWeek').format('YYYY-MM-DD'), endDate: moment().subtract(1, 'week').endOf('isoWeek').format('YYYY-MM-DD'), numOfWeeks: 3},
    'Last 2 weeks': {startDate: moment().subtract(2, 'week').startOf('isoWeek').format('YYYY-MM-DD'), endDate: moment().subtract(1, 'week').endOf('isoWeek').format('YYYY-MM-DD'), numOfWeeks: 2},
    'Last week': {startDate: moment().subtract(1, 'week').startOf('isoWeek').format('YYYY-MM-DD'), endDate: moment().subtract(1, 'week').endOf('isoWeek').format('YYYY-MM-DD'), numOfWeeks: 1},
    'This week': {startDate: moment().startOf('isoWeek').format('YYYY-MM-DD'), endDate: moment().endOf('isoWeek').format('YYYY-MM-DD'), numOfWeeks: 1}
  };
  periodNameKey: string = 'Last 5 weeks';

  //Filters drivers
  driverSearch: string = '';
  selectedDispatchers: string[] = [];
  selectedPositions: string[] = [];
  selectedTypes: string[] = [];
  selectedRecruiters: string[] = [];

  //Bonus achieved
  bonusAchieved: boolean = false;

  selectedDrivers: string[] = [];

  //Tabs container
  tabsArray: any[] = [
    {name: 'Solo drivers', activeKeys: {
      dataArray: 'targetDataSolo',
      bonusAchieved: 'bonusAchievedSolo',
      targetSuccessRate: 'targetSuccessRateSolo',
      succesRate: 'succesRate'
    }}, 
    {name: 'Team drivers', activeKeys: {
      dataArray: 'targetDataTeam',
      bonusAchieved: 'bonusAchievedTeam',
      targetSuccessRate: 'targetSuccessRateTeam',
      succesRate: 'succesRate',
    }}
  ];
  activeTab: number = 0;

  //Filter dispatch
  filtersArray: any[] = [
    {name: 'All time'},
    {name: 'Selected week'}
  ];
  activeFilter: number = 0;

  //Sort by
  sortByName: string = 'Gross - Highest to lowest';

  //Current week
  dateObj: any = {
    startDate: moment().startOf('isoWeek').format('YYYY-MM-DD'), 
    endDate: moment().endOf('isoWeek').format('YYYY-MM-DD')
  };

  //Dispatchers
  dispatchArray: any[] = [];

  //Dispatch data
  dispatchDataSource: any[] = [];
  dispatchSearch: string = '';

  //Recrutiers data
  recruitersArray: any[] = [];

  driverTypesObj: any = {
    'SOLO COMPANY': 'Company',
    'TEAM COMPANY': 'Company',
    'SOLO RENT': 'Rent to run',
    'TEAM RENT': 'Rent to run',
    'OWNER OPERATOR': 'Owner'
  };

  //Interval
  interval: any;

  loaded1: boolean = false;
  error1: boolean = false;
  loaded2: boolean = false;
  error2: boolean = false;
  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";

  spinnerBgColor: string = this.transformService.spinnerBgColor;
  spinnerType: string = this.transformService.spinnerType;

  //Subscription
  subscription1: Subscription = new Subscription();
  subscription2: Subscription = new Subscription();
  subscription3: Subscription = new Subscription();
  subscription4: Subscription = new Subscription();
  subscription5: Subscription = new Subscription();

  constructor(private dialog: MatDialog, 
              private dispatchRewardSystemService: DispatchRewardSystemService,
              private sharedService: SharedService,
              private dispName: DispNamePipe,
              private router: Router,
              private cdref: ChangeDetectorRef,
              private transformService: TransformService,
              private rulesService: RulesService,
              private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.getDriversRewardsData();
    this.getDispatchersRewardsData();
    this.getAllDispatchers();
    this.getAllRecruiters();
    this.interval = setInterval(() => {
      let checkObj: any = this.someWeekCurrentWeek;
      if(checkObj.condition) {
        this.getDriversRewardsDataLive(checkObj);
      }
    }, 10000);
  };

  //Drivers data live update
  getDriversRewardsDataLive(obj: any) {
    this.subscription1 = this.dispatchRewardSystemService.getRewardsDataForCurrentWeek(obj.condition, this.dateObj.startDate, this.dateObj.endDate)
    .subscribe((response: any) => {
      if(this.stCardsArray[this.activeCard].date === this.dateObj.startDate) {
        this.dataSource = response;
        if(this.sortByName !== 'Gross - Highest to lowest') {
          this.sortDriversData(this.sortByName);
        }
      }
      this.countsData(this.stCardsArray[obj.index].targetDataSoloAndTeam, response, obj.index);
    });
  };

  //Drivers data
  getDriversRewardsData() {
    this.resetStData(0);
    this.resetStData(1);
    this.resetStData(2);
    this.resetStData(3);
    this.resetStData(4);
    this.dataSource = [];
    this.error1 = false;
    this.loaded1 = false;
    this.spinner.show('drivers-rewards-table');
    this.subscription2 = this.dispatchRewardSystemService.getTargetsByDate(this.someWeekCurrentWeek.weekCurrentBoolean, this.stCardsArray)
    .pipe(catchError((err: any) => {
      this.spinner.hide('drivers-rewards-table');
      this.loaded1 = true;
      this.error1 = true;
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      this.dataSource = response[`dataSourceArray`+(this.activeCard + 1)];
      if(this.sortByName !== 'Gross - Highest to lowest') {
        this.sortDriversData(this.sortByName);
      }
      this.countsData(response.week1Array, response.dataSourceArray1, 0);
      this.countsData(response.week2Array, response.dataSourceArray2, 1);
      this.countsData(response.week3Array, response.dataSourceArray3, 2);
      this.countsData(response.week4Array, response.dataSourceArray4, 3);
      this.countsData(response.week5Array, response.dataSourceArray5, 4);
      this.spinner.hide('drivers-rewards-table');
      this.loaded1 = true;
      console.log(this.stCardsArray)
    });
    this.getDaysOfWeek();
  };

  //Dispatchers reward data
  getDispatchersRewardsData() {
    let params: string = '';
    if(this.activeFilter) {
      params = `?current_week=${this.currentWeek}&start=${this.stCardsArray[this.activeCard].date}&end=${moment(this.stCardsArray[this.activeCard].date).endOf('isoWeek').format('YYYY-MM-DD')}`;
    };

    this.dispatchDataSource = [];
    this.error2 = false;
    this.loaded2 = false;
    this.spinner.show('dispatchers-rewards-table');
    this.subscription3 = this.dispatchRewardSystemService.getDispatchRewardsData(params)
    .pipe(catchError((err: any) => {
      this.spinner.hide('dispatchers-rewards-table');
      this.loaded2 = true;
      this.error2 = true;
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      this.dispatchDataSource = response;
      this.spinner.hide('dispatchers-rewards-table');
      this.loaded2 = true;
    });
  };

  //Dispatchers list
  getAllDispatchers() {
    this.subscription4 = this.sharedService.getAllDispatchers().subscribe((response: any) => {
      for(let i = 0; i < response.length; i++) {
        if(response[i].is_active) {
          const nickname: string | undefined = this.dispName.transform(response[i].name);
          response[i].nickname = nickname ? nickname : response[i].name;
          this.dispatchArray.push(response[i])
        }
      };
    })
  };

  //Recruiters list
  getAllRecruiters() {
    this.subscription5 = this.sharedService.getAllEmployees().subscribe((response: any) => {
      for(let i = 0; i < response.length; i++) {
        if(response[i].position === 'Recruiter') {
          this.recruitersArray.push(response[i]);
        };
      };
    })
  };

  //Set target dialog
  openTargetDialog() {
    if(this.permissions[0].allowedAll) {
      let dialogRef: any = this.dialog.open(SetTargetDialogComponent, {
        autoFocus: false,
        disableClose: true,
        panelClass: 'component-dialog-container',
        data: {selectedObj: this.stCardsArray[this.activeCard], allDates: this.stCardsArray}
      });
      dialogRef.afterClosed().subscribe((result: any) => {
        if(result) {
          this.getDriversRewardsData();
        }
      })
    }
    else {
      this.dialog.open(MsgForbbidenAccessComponent, {
        autoFocus: false,
        panelClass: 'forbidden-msg-dialog-container'
      })
    }
  };

  //Sort data
  sortDriversData(value: string) {
    this.sortByName = value;
    if(value === 'Bonus Achieved') {
      this.bonusAchieved = true;
    }
    else {
      this.bonusAchieved = false;
      let key: string = '';
      let isAsc: boolean = value.includes('Lowest to highest');
      if(value.startsWith('Gross')) {
        key = 'gross';
      }
      else if(value.startsWith('Points')) {
        key = 'points';
      }
      else {
        key = 'rpm';
      }
      const data = JSON.parse(JSON.stringify(this.dataSource));
      this.dataSource = data.sort((a: any, b: any) => {
        switch (key) {
          case 'gross':
            return compare(a.gross, b.gross, isAsc);
          case 'rpm':
            return compare(a.rpm, b.rpm, isAsc);
          case 'points':
            return compare((a.gross_points + a.rpm_points), (b.gross_points + b.rpm_points), isAsc);
          default:
            return 0;
        }
      });
    }
  };

  sortDispatchData(sort: Sort) {
    const data = JSON.parse(JSON.stringify(this.dispatchDataSource));
    if (!sort.active || sort.direction === '') {
      this.dispatchDataSource = data;
      return;
    }
    this.dispatchDataSource = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'dispatcher':
          return compare(this.dispName.transform(a.dispatcher), this.dispName.transform(b.dispatcher), isAsc);
        case 'target_achived':
          return compare(a.target_achived, b.target_achived, isAsc);
        case 'earnings':
          return compare(a.earnings, b.earnings, isAsc);
        case 'gross_pts':
          return compare(a.gross_pts, b.gross_pts, isAsc);
        case 'rpm_pts':
          return compare(a.rpm_pts, b.rpm_pts, isAsc);
        case 'total_points':
          return compare(a.total_points, b.total_points, isAsc);
        default:
          return 0;
      }
    });
  }

  countsData(arrayOfArraysTarget: any[], driversArray: any[], index: number) {
    let calculationDataObj: any = {
      'targetGross-0': 0,
      'calculation_points_after-0': 0,
      'targetGrossSuccesRateSolo': 0,
      'targetRpm-0': 0,
      'calculation_points_after-1': 0,
      'targetRpmSuccesRateSolo': 0,
      'numOfSoloDrivers': 0,

      'targetGross-1': 0,
      'targetGrossSuccesRateTeam': 0,
      'targetRpm-1': 0,
      'targetRpmSuccesRateTeam': 0,
      'numOfTeamDrivers': 0,

      'bonusAchievedSolo': 0,
      'bonusAchievedTeam': 0,
      'bonusAchievedSoloAndTeam': 0,

      'grossIndex-0': null,
      'rpmIndex-0': null,
      'grossIndex-1': null,
      'rpmIndex-1': null,

      'sumGross': 0,
      'sumMileage': 0
    };
   
    this.stCardsArray[index].targetDataSoloAndTeam = arrayOfArraysTarget;

    //Target calculation
    for(let i = 0; i < arrayOfArraysTarget.length; i++) {

      if(arrayOfArraysTarget[i][0].type === 0) {
        this.stCardsArray[index].targetDataSolo = arrayOfArraysTarget[i];
      };
      if(arrayOfArraysTarget[i][0].type === 1) {
        this.stCardsArray[index].targetDataTeam = arrayOfArraysTarget[i];
      };

      for(let j = 0; j < arrayOfArraysTarget[i].length; j++) {
        if(arrayOfArraysTarget[i][j].condition === 'Gross') {
          calculationDataObj[`targetGross-${arrayOfArraysTarget[i][j].type}`] = arrayOfArraysTarget[i][j].target;
          calculationDataObj[`grossIndex-${arrayOfArraysTarget[i][j].type}`] = j;
        };
        if(arrayOfArraysTarget[i][j].condition === 'Rate per mile') {
          calculationDataObj[`targetRpm-${arrayOfArraysTarget[i][j].type}`] = arrayOfArraysTarget[i][j].target;
          calculationDataObj[`rpmIndex-${arrayOfArraysTarget[i][j].type}`] = j;
          calculationDataObj[`calculation_points_after-${arrayOfArraysTarget[i][j].type}`] = arrayOfArraysTarget[i][j].calculation_points_after;
        };
        arrayOfArraysTarget[i][j].succesRate = 0;
      };

    };

    //Drivers data calculation
    for(let i = 0; i < driversArray.length; i++) {
      if(driversArray[i].status.includes('SOLO') || driversArray[i].status === 'OWNER OPERATOR') {
        if(driversArray[i].gross >= calculationDataObj['targetGross-0']) {
          calculationDataObj.targetGrossSuccesRateSolo++;
        };
        if(driversArray[i].rpm >= calculationDataObj['targetRpm-0'] && driversArray[i].mileage > calculationDataObj['calculation_points_after-0']) {
          calculationDataObj.targetRpmSuccesRateSolo++;
        };
        calculationDataObj.numOfSoloDrivers++;
      }

      if(driversArray[i].status.includes('TEAM')) {
        if(driversArray[i].gross >= calculationDataObj['targetGross-1']) {
          calculationDataObj.targetGrossSuccesRateTeam++;
        };
        if(driversArray[i].rpm >= calculationDataObj['targetRpm-1'] && driversArray[i].mileage > calculationDataObj['calculation_points_after-1']) {
          calculationDataObj.targetRpmSuccesRateTeam++;
        };
        calculationDataObj.numOfTeamDrivers++;
      }

      if(driversArray[i].gross >= calculationDataObj['targetGross-0'] 
        && driversArray[i].rpm >= calculationDataObj['targetRpm-0'] 
        && (driversArray[i].status.includes('SOLO') || driversArray[i].status === 'OWNER OPERATOR') 
        && this.stCardsArray[index].targetDataSolo.length > 0) {
          calculationDataObj.bonusAchievedSolo++;
          calculationDataObj.bonusAchievedSoloAndTeam++;
      };
      if(driversArray[i].gross >= calculationDataObj['targetGross-1'] 
        && driversArray[i].rpm >= calculationDataObj['targetRpm-1'] 
        && driversArray[i].status.includes('TEAM') && this.stCardsArray[index].targetDataTeam.length > 0) {
          calculationDataObj.bonusAchievedTeam++;
          calculationDataObj.bonusAchievedSoloAndTeam++;
      };

      calculationDataObj.sumGross += driversArray[i].gross;
      calculationDataObj.sumMileage += driversArray[i].mileage;
    };

    if(calculationDataObj['grossIndex-0'] !== null && this.stCardsArray[index].targetDataSolo.length > 0) {
      this.stCardsArray[index].targetDataSolo[calculationDataObj['grossIndex-0']].succesRate = 
      (calculationDataObj['targetGrossSuccesRateSolo'] / calculationDataObj['numOfSoloDrivers']) * 100;
    };

    if(calculationDataObj['grossIndex-1'] !== null && this.stCardsArray[index].targetDataTeam.length > 0) {
      this.stCardsArray[index].targetDataTeam[calculationDataObj['grossIndex-1']].succesRate = 
      (calculationDataObj['targetGrossSuccesRateTeam'] / calculationDataObj['numOfTeamDrivers']) * 100;
    };

    if(calculationDataObj['rpmIndex-0'] !== null && this.stCardsArray[index].targetDataSolo.length > 0) {
      this.stCardsArray[index].targetDataSolo[calculationDataObj['rpmIndex-0']].succesRate = 
      (calculationDataObj['targetRpmSuccesRateSolo'] / calculationDataObj['numOfSoloDrivers']) * 100;
    };

    if(calculationDataObj['rpmIndex-1'] !== null && this.stCardsArray[index].targetDataTeam.length > 0) {
      this.stCardsArray[index].targetDataTeam[calculationDataObj['rpmIndex-1']].succesRate = 
      (calculationDataObj['targetRpmSuccesRateTeam'] / calculationDataObj['numOfTeamDrivers']) * 100;
    };

    this.stCardsArray[index].sumGrossTarget = calculationDataObj.sumGross;
    this.stCardsArray[index].sumRpmTarget = calculationDataObj.sumGross / calculationDataObj.sumMileage;

    this.stCardsArray[index].targetSuccessRateSolo = (calculationDataObj.bonusAchievedSolo / calculationDataObj.numOfSoloDrivers) * 100;
    this.stCardsArray[index].targetSuccessRateTeam = (calculationDataObj.bonusAchievedTeam / calculationDataObj.numOfTeamDrivers) * 100;
    
    this.stCardsArray[index].bonusAchievedSolo = calculationDataObj.bonusAchievedSolo;
    this.stCardsArray[index].bonusAchievedTeam = calculationDataObj.bonusAchievedTeam;
    this.stCardsArray[index].bonusAchievedSoloAndTeam = calculationDataObj.bonusAchievedSoloAndTeam;

    this.stCardsArray[index].dataSource = driversArray;
  };

  //Change period
  changePeriod(addOrSubstract: string) {
    this.stCardsArray[0].date = moment(this.stCardsArray[0].date)[addOrSubstract](1, 'week').startOf('isoWeek').format('YYYY-MM-DD');
    this.stCardsArray[1].date = moment(this.stCardsArray[1].date)[addOrSubstract](1, 'week').startOf('isoWeek').format('YYYY-MM-DD');
    this.stCardsArray[2].date = moment(this.stCardsArray[2].date)[addOrSubstract](1, 'week').startOf('isoWeek').format('YYYY-MM-DD');
    this.stCardsArray[3].date = moment(this.stCardsArray[3].date)[addOrSubstract](1, 'week').startOf('isoWeek').format('YYYY-MM-DD');
    this.stCardsArray[4].date = moment(this.stCardsArray[4].date)[addOrSubstract](1, 'week').startOf('isoWeek').format('YYYY-MM-DD');
    this.getDriversRewardsData();
    this.getDispatchersRewardsData();
  };

  //Select week
  selectWeek(dataSource: any[], i: number) {
    this.activeCard = i; 
    this.expandRowIndex = undefined;
    this.resetRowData(); 
    this.dataSource = dataSource;
    this.sortDriversData(this.sortByName); 
    this.getDispatchersRewardsData();
    this.getDaysOfWeek();
  };

  //Get percent
  getPercent(value: number) {
    if(value > 100) {
      return 100;
    }
    else {
      return value;
    }
  };

  //Filters method
  selectFilters(arrayName: string, selectedValues: string[]) {
    this[arrayName] = selectedValues;
    this[arrayName] = [...this[arrayName]];
  };

  //Expand row
  expandRow(element: any, i: number) {
    this.resetRowData();
    if(this.expandRowIndex === i) {
      this.expandRowIndex = undefined;
    }
    else {
      this.expandRowIndex = i;
      this.getLoadsData(element.driver_id, true, {startDate: this.stCardsArray[this.activeCard].date, endDate: moment(this.stCardsArray[this.activeCard].date).endOf('isoWeek').format('YYYY-MM-DD')});
      this.getLoadsData(element.driver_id, false, this.loadsDateObj[this.periodNameKey]);
      this.getStatusesByDriver(element.driver_id);
    }
  };

  //Loads data
  getLoadsData(driverId: number, tableData: boolean, dateObj: any) {
    this.dispatchRewardSystemService.getDriverLoadsData(driverId, dateObj.startDate, dateObj.endDate)
    .subscribe((response: any) => {
      if(tableData) {
        this.loadsDataSource = response;
      }
      else {
        this.graphData = response;
        this.getLoadsLinechartData(dateObj);
      }
      console.log(response)
    });
  }

  //Loads linechart
  getLoadsLinechartData(dateObj: any) {
    let startDate: string = `${dateObj.startDate}T00:00:00`;
    let endDate: string = `${dateObj.endDate}T00:00:00`; 

    if(this.graphData.length > 0) {
      const hireDate: string | null = this.graphData[0].hire_date;
      const terminationDate: string | null = this.graphData[0].termination_date;
      
      startDate = hireDate <= startDate ? startDate : hireDate;
      endDate = terminationDate <= endDate ? terminationDate : endDate;

      let weekOrDay: string = this.periodNameKey === 'This week' || this.periodNameKey === 'Last week' ? 'day' : 'week';

      let allDatesArray: any[] = this.transformService.getDatesInRange(new Date(startDate), new Date(endDate), weekOrDay);
      let group = this.graphData.reduce((r: any, o: any) => {
        let periodName: any = '';
   
        if(this.periodNameKey === 'This week' || this.periodNameKey === 'Last week') {
          periodName = `${moment(o.pu_date).startOf('day').format('ddd MM.DD')}`;
        }
        else {
          periodName = `${moment(o.pu_date).startOf('isoWeek').format('MMM DD')}-${moment(o.pu_date).endOf('isoWeek').format('DD')}`;
        }

        if(r[periodName]) {
          r[periodName].gross += o.gross;
          r[periodName].mileage += o.mileage;
        } else {
          r[periodName] = {categories: periodName,  gross: o.gross ? o.gross: 0, mileage: o.mileage ? o.mileage: 0};
        };

      return r;
      }, {});

      //Statistics data
      let sumGross: number = 0;
      let sumMileage: number = 0;
      let numOfWeeks: number = this.loadsDateObj[this.periodNameKey].numOfWeeks;

      let result = Object.keys(group).map((key) => group[key]);
      let objOfArrays: any = {categories: [], totalGross: [], totalRpm: [], totalMileage: []};
      let index: number = 0;
      for(let i = 0; i < allDatesArray.length; i++) {
        if(allDatesArray[i] === result[index]?.categories) {
          objOfArrays.categories.push(result[index].categories);
          objOfArrays.totalGross.push(result[index].gross);
          objOfArrays.totalRpm.push((result[index].gross / result[index].mileage).toFixed(2));
          objOfArrays.totalMileage.push(result[index].mileage);
          sumGross += result[index].gross;
          sumMileage += result[index].mileage;
          index += (result.length - 1) === index ? 0 : 1;
        }
        else {
          objOfArrays.categories.push(allDatesArray[i]);
          objOfArrays.totalGross.push(0);
          objOfArrays.totalRpm.push(0);
          objOfArrays.totalMileage.push(0);
        }
      }
      this.loadsStObj.avgGross = sumGross / numOfWeeks;
      this.loadsStObj.avgRpm = sumGross / sumMileage;
      this.loadsStObj.avgMileage = sumMileage / numOfWeeks;
  
      let dataSeries: any[] = [];
      if(this.loadsStObj.showGrossInLinechart) {
        dataSeries.push({name: 'Gross', data: objOfArrays.totalGross});
      }
      if(this.loadsStObj.showRpmInLinechart) {
        dataSeries.push({name: 'Rate', data: objOfArrays.totalRpm});
      }
      if(this.loadsStObj.showMileageInLinechart) {
        dataSeries.push({name: 'Mileage', data: objOfArrays.totalMileage});
      }

      this.initGraph(objOfArrays.categories, dataSeries);
    }
    else {
      this.chartOptions = undefined;
    }
  };

  //Init graph
  initGraph(categories: any[], seriesData: any) {
    this.chartOptions = {
      series: seriesData,
      chart: {
        toolbar: {
          show: true,
          tools: {
            download: false
          },
          animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 800,
            animateGradually: {
                enabled: true,
                delay: 150
            },
            dynamicAnimation: {
                enabled: true,
                speed: 350
            }
          }
        },
        height: 250,
        type: "line",
        zoom: {
          enabled: false
        },
        fontFamily: 'Poppins'
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: "straight",
        width: 1,
      },
      grid: {
        row: {
          colors: ["transparent", "transparent"],
          opacity: 0.5
        }
      },
      xaxis: {
        categories: categories
      },
      yaxis: this.addYAxis(seriesData),
      markers: {
        size: 5
      },
      colors: ['#0030FF', '#FA9120', '#FA0000', '#008000', '#FF00FF', '#800000', '#FE7777', '#0303BB', '#008FA9', '#7550CB', '#6A6D6E'],
      tooltip: {
        enabled: true,
        inverseOrder: false,
        style: {
          fontSize: '12px'
        },
      }
    };
  }

  //Y axis graph
  addYAxis(array: any) {
    let yAxisArray: any[] = [];
    let colors: string[] = ['#0030FF', '#FA9120', '#FA0000', '#008000', '#FF00FF', '#800000', '#FE7777', '#0303BB', '#008FA9', '#7550CB', '#6A6D6E'];
    for(let i = 0; i < array.length; i++) {
      let obj: any;
      if(i === 0) {
        obj = {
        seriesName: array[i].name[0], axisTicks: {show: true}, axisBorder: {show: false, color: colors[i]},
        labels: {style: {colors: colors[i]}, formatter: (num: number) => { 
          let value: number = num;
          if(array[i].name !== 'Rate') {
            value = Math.round(value);
          }
          return value.toLocaleString("en-US").toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}
        }
      } 
      else {
        obj = {
          seriesName: array[i].name[0], opposite: true, axisTicks: {show: true}, axisBorder: {show: false, color: colors[i]},
          labels: {style: {colors: colors[i]}, formatter: (num: number) => {
            let value: number = num;
            if(array[i].name !== 'Rate') {
              value = Math.round(value);
            }
            return value.toLocaleString("en-US").toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          }}
        }
      }

      yAxisArray.push(obj);
    }

    return yAxisArray;
  }

  //Add status
  addStatus(day: any, element: any) {
    if(element.hire_date <= moment(day.status_date).format('YYYY-MM-DDTHH:mm:ss') && !day.terminated) {
      let data: any = {
        driverInfo: {info: {id: element.id, name: `${element.first_name} ${element.last_name}`, truck_no: element.unit_no, trailer_no: element.trailer_no }},
        date: new Date(day.status_date), 
        allStatusArray: this.statusesDataSource,
        startDate: this.stCardsArray[this.activeCard].date,
        endDate: element.status.length > 0 && new Date(element.status[element.status.length-1].status_date).getTime() > new Date(day.status_date).getTime() ? 
        element.status[element.status.length-1].status_date.split('T')[0] : moment(day.status_date).format('YYYY-MM-DD')
      };
      let dialogRef: any = this.dialog.open(StatusDialogComponent, {
        autoFocus: false,
        panelClass: 'status-dialog-container',
        data: data
      });
      dialogRef.afterClosed().subscribe((result: any) => {
        if(result)  {
          this.getStatusesByDriver(element.driver_id)
        }
      })
    }
  };

  //Get statuses by driver
  getStatusesByDriver(driverId: number) {
    this.sharedService.getDriverStatusesByDriver(driverId, this.stCardsArray[this.activeCard].date, moment(this.stCardsArray[this.activeCard].date).endOf('isoWeek').format('YYYY-MM-DD'))
    .subscribe((response: any) => {
      let statusIndex: number = 0;
      const dataSource: any[] = [];
      for(let i = 0; i < 7; i++) {
        if(response[statusIndex]?.status_date === moment(this.stCardsArray[this.activeCard].date).add(i, 'd').format('YYYY-MM-DDT00:00:00')) {
          dataSource.push(response[statusIndex]);
          statusIndex++;
        }
        else {
          dataSource.push({className: null, color: '', craetor: '', creator_department: '', creator_id: null, datecreated: null, dispatcher: null,
            dispatcher_id: 0, driver_className: null, driver_id: null, driver_name: '', edit_date: null, editor: '', files: [],
            icon_id: null, icon_name: 'no-status.png', id: null, no_className: null, notes: [], status: '', status_date: moment(this.stCardsArray[this.activeCard].date).add(i, 'd').format('YYYY-MM-DDT00:00:00'), 
            status_group_name: '', terminated: false, total_className: null, trailer: null, unit_no: null});
        };
      }
      this.statusesDataSource = dataSource;
    });
  }

  //Open in new tab
  openInNewTab(route: string) {
    const url = this.router.serializeUrl(this.router.createUrlTree([route]));
    window.open(url, '_blank');
  };

  //Reset filters
  resetFilters() {
    this.driverSearch = '';
    this.selectedDispatchers = [];
    this.selectedPositions = [];
    this.selectedTypes = [];
    this.selectedRecruiters = [];
    this.selectedDrivers = [];
  };

  //Reset row data
  resetRowData() {
    this.chartOptions = undefined;
    this.loadsDataSource = [];
    this.graphData = [];
    this.statusesDataSource = [];
    this.loadsStObj.avgGross = 0;
    this.loadsStObj.avgRpm = 0;
    this.loadsStObj.avgMileage = 0;
    this.periodNameKey = 'Last 5 weeks';
    this.quickStatsObj.countObj.avgGross = 0;
    this.quickStatsObj.countObj.avgRpm = 0;
    this.quickStatsObj.countObj.avgMileage = 0;
  };

  //Reset st data
  resetStData(index: number) {
    this.stCardsArray[index].dataSource = [];
    this.stCardsArray[index].targetDataSolo = [];
    this.stCardsArray[index].targetDataTeam = [];
    this.stCardsArray[index].bonusAchievedSoloAndTeam = 0;
    this.stCardsArray[index].bonusAchievedSolo = 0;
    this.stCardsArray[index].bonusAchievedTeam = 0;
    this.stCardsArray[index].targetSuccessRateSolo = 0;
    this.stCardsArray[index].targetSuccessRateTeam = 0;
    this.stCardsArray[index].avgGross = 0;
    this.stCardsArray[index].avgRpm = 0;
    this.stCardsArray[index].avgMileage = 0;
    this.stCardsArray[index].avgGrossTarget = 0;
    this.stCardsArray[index].avgRpmTarget = 0;
  }

  //Get days of week
  getDaysOfWeek() {
    this.daysOfWeek = [];
    for(let i = 1; i <= 7; i++) {
      this.daysOfWeek.push({day: moment(this.stCardsArray[this.activeCard].date).day(i).format('ddd'), date: moment(this.stCardsArray[this.activeCard].date).day(i).format('YYYY-MM-DD')})
    }
  };

  //Get bonus
  showHideImage(element: any) {
    const driverTypes: any = {'SOLO COMPANY': 'targetDataSolo', 'TEAM COMPANY': 'targetDataTeam', 'SOLO RENT': 'targetDataSolo', 'TEAM RENT': 'targetDataTeam', 'OWNER OPERATOR': 'targetDataSolo'};
    let targetArray: any[] = this.stCardsArray[this.activeCard][driverTypes[element.status]];
    if(targetArray.length === 2) {
      return element.gross_perc >= 100 && element.rpm_perc >= 100;
    }
    if(targetArray.length === 1) {
      return targetArray[0].condition === 'Gross' ? element.gross_perc >= 100 : element.rpm_perc >= 100;
    }
    return false;
  };

  trackByDriver(index, item){
    return item.driver_id; 
  };

  trackByDisp(index, item){
    return item.id; 
  };

  get currentWeek(): boolean {
    return (moment().isoWeek() === moment(this.stCardsArray[this.activeCard].date).isoWeek());
  }

  get someWeekCurrentWeek(): any {
    let index: number | undefined = undefined;
    let condition: boolean = false;
    let weekCurrentBoolean: boolean[] = [false, false, false, false, false];
    if(this.stCardsArray[0].date === this.dateObj.startDate) {
      index = 0;
      condition = true;
      weekCurrentBoolean[0] = true;
    }
    if(this.stCardsArray[1].date === this.dateObj.startDate) {
      index = 1;
      condition = true;
      weekCurrentBoolean[1] = true;
    }
    if(this.stCardsArray[2].date === this.dateObj.startDate) {
      index = 2;
      condition = true;
      weekCurrentBoolean[2] = true;
    }
    if(this.stCardsArray[3].date === this.dateObj.startDate) {
      index = 3;
      condition = true;
      weekCurrentBoolean[3] = true;
    }
    if(this.stCardsArray[4].date === this.dateObj.startDate) {
      index = 4;
      condition = true;
      weekCurrentBoolean[4] = true;
    }
    return {index: index, condition: condition, weekCurrentBoolean: weekCurrentBoolean};
  }
  
  ngAfterContentChecked() {
    this.cdref.detectChanges();
  };
 
  ngOnDestroy(): void {
    clearInterval(this.interval);
    this.subscription1?.unsubscribe();
    this.subscription2?.unsubscribe();  
    this.subscription3?.unsubscribe();  
    this.subscription4?.unsubscribe();  
    this.subscription5?.unsubscribe();  
  };

}

function compare(a: number | any, b: number | any, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
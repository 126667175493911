import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PaymentsService } from '@app/modules/accounting/services/payments.service';
import { WarningMsgDialogComponent } from '@app/modules/shared/components/warning-msg-dialog/warning-msg-dialog.component';
import { SharedService } from '@app/modules/shared/services/shared.service';
import { catchError, Subscription, throwError } from 'rxjs';
import { InvalidValidationDialogComponent } from './invalid-validation-dialog/invalid-validation-dialog.component';
import { ConnectionPositionPair } from '@angular/cdk/overlay';
import { DeleteConfirmationDialogComponent } from '@app/modules/shared/components/delete-confirmation-dialog/delete-confirmation-dialog.component';
import { RulesService } from '@app/modules/shared/services/rules.service';
import moment = require('moment');
import { MsgForbbidenAccessComponent } from '@app/modules/shared/components/msg-forbbiden-access/msg-forbbiden-access.component';
import { TitleCasePipe } from '@angular/common';

@Component({
  selector: 'app-create-update-payment-dialog',
  templateUrl: './create-update-payment-dialog.component.html',
  styleUrls: ['./create-update-payment-dialog.component.scss']
})
export class CreateUpdatePaymentDialogComponent implements OnInit, OnDestroy {
  permissions: any = this.rulesService.UserData[37].data;
  loggedUser: any = JSON.parse(localStorage.getItem('currentUser'));
  fullName: string = `${this.loggedUser.first_name} ${this.loggedUser.last_name}`;
  nickname: string = `${this.loggedUser.nickname}`;

  //Payment group
  paymentFormGroup: any = this._formBuilder.nonNullable.group({
    id: [0],
    type: [0],
    IssuedBy: [`${this.nickname.length === 0 ? this.fullName : this.nickname?.trim().split(' ')[0]}`, [Validators.required, Validators.minLength(2)]],
    creator_id: [this.loggedUser.id],
    DriverName: [''],
    unit_no: [''],
    DateOfPayment: [moment().format('YYYY-MM-DD'), Validators.required],
    PaymentMethod: ['', [Validators.required, Validators.minLength(2)]],
    PaymantAmount: [null, Validators.required],
    PaymentIssuedTo: [null, Validators.required],
    TypeOfRepair: [null, Validators.required],
    InvoiceReceived: [false],
    InvoiceUploadedToPT: [false],
    MoneyCode: [''],
    IssuedAmount: [0],
    AmountUsed: [0],
    Stete: [''],
    Quantity: [''],
    FuelReceiptReceived: [false],
    CardUsed: [''],
    RELAYCode: [''],
    ReasonForPeyment: [null, Validators.required],
    ReasonForMoneyCode: [''],
    note: [''],
    fileId: [0],
    fileName: [''],
    fileData: [null],
    division: [''],
    reviewed: [false],
    chargeTheDriver: [false],
    driverCharged: [false],
    amountCharged: [0],
    requestTrigger: [null],
    driverId: [0],
    driverMailSend: [null],
    issuedID: [this.loggedUser.id],
    voided: [false]
  })

  //Drivers
  driversArray: any[] = [];

  //Divisions
  divisionsArray: any[] = [];

  //Units
  unitsArray: any[] = [];

  //Reason for payment
  reasonForPayment: any[] = [];
  reasonFilterArray: string[] = ['Repair', 'Fuel', 'Miscellaneous'];

  //States
  statesArray: any[] = [];

  //File
  fileData: string = '';
  isOpenFileMenu: boolean = false;
  fileLoaded: boolean = true;
  
  //Positions
  public positions = [
    new ConnectionPositionPair(
      {originX: 'end', originY: 'bottom'},
      {overlayX: 'end', overlayY: 'top'},
      0, 5
    )
  ];

  //Subscription
  subscription1: Subscription = new Subscription();
  subscription2: Subscription = new Subscription();
  subscription3: Subscription = new Subscription();
  subscription4: Subscription = new Subscription();
  subscription5: Subscription = new Subscription();
  subscription6: Subscription = new Subscription();

  constructor(@Inject(MAT_DIALOG_DATA) public obj: any, 
              private dialog: MatDialog,
              public dialogRef: MatDialogRef<CreateUpdatePaymentDialogComponent>,
              private paymentsService: PaymentsService,
              private _formBuilder: FormBuilder,
              private sharedService: SharedService,
              private rulesService: RulesService,
              private titleCasePipe: TitleCasePipe) { }
  
  ngOnInit(): void {
    if(this.obj.editMode) {
      this.paymentFormGroup.patchValue({
        id: this.obj.data.id,
        type: this.obj.data.type,
        IssuedBy: this.obj.data.IssuedBy,
        creator_id: this.obj.data.creator_id,
        DriverName: this.obj.data.DriverName,
        unit_no: this.obj.data.unit_no,
        DateOfPayment: this.obj.data.DateOfPayment,
        PaymentMethod: this.obj.data.PaymentMethod,
        PaymantAmount: this.obj.data.PaymantAmount,
        PaymentIssuedTo: this.obj.data.PaymentIssuedTo,
        TypeOfRepair: this.obj.data.TypeOfRepair,
        InvoiceReceived: this.obj.data.InvoiceReceived,
        InvoiceUploadedToPT: this.obj.data.InvoiceUploadedToPT,
        MoneyCode: this.obj.data.MoneyCode,
        IssuedAmount: this.obj.data.IssuedAmount,
        AmountUsed: this.obj.data.AmountUsed,
        Stete: this.obj.data.Stete,
        Quantity: this.obj.data.Quantity,
        FuelReceiptReceived: this.obj.data.FuelReceiptReceived,
        CardUsed: this.obj.data.CardUsed,
        RELAYCode: this.obj.data.RELAYCode,
        ReasonForPeyment: this.obj.data.ReasonForPeyment,
        ReasonForMoneyCode: this.obj.data.ReasonForMoneyCode,
        note: this.obj.data.note,
        fileId: this.obj.data.fileId,
        fileName: this.obj.data.fileName,
        fileData: this.obj.data.fileData,
        division: this.obj.data.division,
        reviewed: this.obj.data.reviewed,
        chargeTheDriver: this.obj.data.chargeTheDriver,
        driverCharged: this.obj.data.driverCharged,
        amountCharged: this.obj.data.amountCharged,
        requestTrigger: this.obj.data.requestTrigger,
        driverId: this.obj.data.driverId,
        driverMailSend: this.obj.data.driverMailSend,
        issuedID: this.obj.data.issuedID,
        voided: this.obj.data.voided
      });

      if(this.obj.data.fileName !== '') {
        this.fileLoaded = false;
        this.getPaymentFile();
      }
      this.setOrClearValidatorsPaymentType(this.obj.data.type);
    }
    this.getAllPaymentReasons();
    this.getDrivers();
    this.getDivisions();
    this.getTrucksAndTrailers();
    this.getStates();
  };

  getAllPaymentReasons() {
    this.subscription1 = this.paymentsService.getPaymentReasonsData().subscribe((response: any) => {
      this.reasonForPayment = response;
    });
  };

  getDrivers() {
    this.subscription2 = this.sharedService.getDriversData(true).subscribe((response: any) => {
      for(let i = 0; i < response.length; i++) {
        response[i].full_name = this.titleCasePipe.transform(`${response[i].first_name} ${response[i].last_name}`);
        response[i].full_name_upper_case = `${response[i].first_name} ${response[i].last_name}`;
      }
      this.driversArray = response;
      this.driversArray.unshift({full_name: 'N/A', full_name_upper_case: 'N/A'});
    });
  };

  getDivisions() {
    this.subscription3 = this.sharedService.getCompanyData().subscribe((response: any) => {
      this.divisionsArray = response.divisions;
    });
  };

  getTrucksAndTrailers() {  
    this.subscription4 = this.sharedService.getTrucksAndTrailersData(true).subscribe((response: any) => {
      this.unitsArray = response[0].concat(response[1]);
    });
  };

  getStates() {
    this.subscription5 = this.sharedService.getAllLocationInfo().subscribe((response: any) => {
      this.statesArray = response;
    });
  };

  //Get file
  getPaymentFile() {
    this.subscription6 = this.paymentsService.getPaymentFile(this.obj.data.id, this.obj.data.fileName)
    .pipe(catchError((err: any) => {
      this.fileLoaded = false;
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      this.fileData = response;
      if(response) {
        this.paymentFormGroup.patchValue({'fileName': this.obj.data.fileName, 'fileData': response});
      }
      this.fileLoaded = true;
    });
  };  

  //Files methods
  onFileSelected(event: any) {  
    let length: any = Object.keys(event.target.files).length;
    for(let i = 0; i < length; i++) {
      this.addMultipleFiles(event, i);
    }
  }

  addMultipleFiles(event: any, index: number) {
    let fileName = event.target.files[index].name;
    let reader = new FileReader();
    reader.onload = (e: any) => {
      let base64 = e.target.result.split(',')[1];
      this.paymentFormGroup.patchValue({'fileName': fileName, 'fileData': base64});
      this.fileData = base64;
    };
    reader.readAsDataURL(event.target.files[index]);
  };

  openFileInNewTab() {
    this.isOpenFileMenu = !this.isOpenFileMenu;
    this.sharedService.downloadPreviewFile(this.formData('fileName'), this.fileData);
  };

  downloadFile() {
    this.isOpenFileMenu = !this.isOpenFileMenu;
    let source: string = `data:application/octet-stream;base64,${this.fileData}`;
    const downloadLink = document.createElement('a');
    const fileName: string = this.formData('fileName');
    downloadLink.href = source;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  deleteFile() {
    this.isOpenFileMenu = !this.isOpenFileMenu;
    let dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
      autoFocus: false,
      panelClass: 'delete-dialog-container',
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
        if(this.obj.data?.id && this.obj.data?.fileName) {
          this.paymentsService.deleteFileFromPayment(this.obj.data.id, this.obj.data.fileName)
          .pipe(catchError((err: any) => {
            this.showErrorMessage();
            return throwError(() => err);
          }))
          .subscribe((response: boolean) => {
            if(response) {
              this.paymentFormGroup.patchValue({'fileId': 0, 'fileName': '', 'fileData': null});
              this.fileData = '';
            }
            else {
              this.showErrorMessage();
            }
            console.log(response)
          });
        }
        else {
          this.paymentFormGroup.patchValue({'fileId': 0, 'fileName': '', 'fileData': null});
          this.fileData = '';
        }
      }
    })
  };

  saveData() {
    this.paymentFormGroup.markAllAsTouched();
    if(this.paymentFormGroup.valid) {
      this.paymentsService.createUpdatePayment(this.paymentFormGroup.value)
      .pipe(catchError((err: any) => {
        this.showErrorMessage();
        return throwError(() => err);
      }))
      .subscribe((response: any) => {
        if(response?.id) {
          this.dialogRef.close(response);
        }
        else {
          this.showErrorMessage();
        }
      });
    }
  };

  //Delete payment
  deletePayment() {
    const formData: any = this.paymentFormGroup.value;
    let condition1: boolean = this.permissions[0].sectionArray[5].allowed;
    let condition2: boolean = this.permissions[0].sectionArray[6].allowed && formData.creator_id === this.loggedUser.id;
    if(condition1 || condition2) {
      let dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
        autoFocus: false,
        panelClass: 'delete-dialog-container',
      });
      dialogRef.afterClosed().subscribe((result: any) => {
        if(result) {
          this.paymentsService.deletePayment(formData.id)
          .pipe(catchError((err: any) => {
            this.showErrorMessage();
            return throwError(() => err);
          }))
          .subscribe((response: boolean) => {
            if(response) {
              this.dialogRef.close('DELETE PAYMENT');
            }
            else {
              this.showErrorMessage();
            }
          });
        }
      })
    }
    else {
      this.showForbiddenMessage();
    }
  }

  //Show error message
  showErrorMessage() {
    this.dialog.open(WarningMsgDialogComponent, {
      autoFocus: false,
      panelClass: 'warning-msg-dialog-container'
    });
  };

  //Show forbidden message
  showForbiddenMessage() {
    this.dialog.open(MsgForbbidenAccessComponent, {
      autoFocus: false,
      panelClass: 'forbidden-msg-dialog-container'
    })
  };

  //Get payment type
  getPaymentType(): string {
    const obj: any = {0: 'Repair Payments', 1: 'Fuel Payments', 2: 'Miscellaneous Payments'};
    return obj[this.formData('type')];
  };

  //Get form value
  formData(formKey: string) {
    return this.paymentFormGroup.controls[formKey].value;
  };

  //Autocomplete division
  selectDivisionById(divisionId: number) {
    let division: string = '';
    for(let i = 0; i < this.divisionsArray.length; i++) {
      if(this.divisionsArray[i].id === divisionId) {
        division = this.divisionsArray[i].name;
        break;
      };
    };
    return division;
  };

  //Set or clear validators
  setOrClearValidatorsPaymentType(paymentType: number) {
    this.paymentFormGroup.markAsUntouched();
    this.clearValidators('TypeOfRepair');
    this.clearValidators('Stete');
    this.clearValidators('Quantity');
    if(paymentType === 0) {
      this.setValidators('TypeOfRepair', [Validators.required]);
    }
    if(paymentType === 1) {
      this.setValidators('Stete', [Validators.required]);
      this.setValidators('Quantity', [Validators.required]);
    }
  };

  setOrClearValidatorPaymentMethod(paymentMethod: string) {
    this.paymentFormGroup.markAsUntouched();
    this.clearValidators('CardUsed');
    this.clearValidators('MoneyCode');
    this.clearValidators('ReasonForMoneyCode');
    this.clearValidators('RELAYCode');
    this.clearValidators('IssuedAmount');
    this.clearValidators('AmountUsed');
    this.paymentFormGroup.patchValue({'PaymantAmount': null, 'CardUsed': '', 'PaymentIssuedTo': '', 'TypeOfRepair': '',
      'MoneyCode': '', 'ReasonForMoneyCode': '', 'IssuedAmount': 0, 'AmountUsed': 0, 'RELAYCode': ''
    });
    if(paymentMethod === 'Credit Card') {
      this.setValidators('CardUsed', [Validators.required, Validators.minLength(2)]);
    }
    if(paymentMethod === 'EFS Money Code') {
      this.setValidators('MoneyCode', [Validators.required,  this.minLengthNumberValidator(10)]);
      this.setValidators('ReasonForMoneyCode', [Validators.required, Validators.minLength(2)]);
    }
    if(paymentMethod === 'EFS Money Code' && this.formData('type') === 1) {
      this.paymentFormGroup.patchValue({'IssuedAmount': null, 'AmountUsed': null});
      this.setValidators('IssuedAmount', [Validators.required]);
      this.setValidators('AmountUsed', [Validators.required]);
    } 
    if(paymentMethod === 'RELAY') {
      this.setValidators('RELAYCode', [Validators.required, Validators.minLength(2)]);
    }
  };

  setValidators(key: string, validators: any[]) {
    this.paymentFormGroup.controls[key].setValidators(validators);
    this.paymentFormGroup.controls[key].updateValueAndValidity();
  };

  clearValidators(key: string) {
    this.paymentFormGroup.controls[key].clearValidators();
    this.paymentFormGroup.controls[key].updateValueAndValidity();
  };

  //Validator
  minLengthNumberValidator(digitsLength: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value == null) {
        return null;
      }
  
      const valueAsString = control.value.toString();
      return valueAsString.length === digitsLength ? null : { 'minLengthNumber': { value: control.value }};
    };
  };

  checkValidaty() {
    let moneyCode: string = `${this.paymentFormGroup.value.MoneyCode}`;
    if(moneyCode.length < 10) {
      this.showInvalidDataMessage('You are missing digits, a money code has 10 digits.');
    };
    if(moneyCode.length > 10) {
      this.showInvalidDataMessage('You have entered too many digits; a money code has 10 digits.');
    };
  };

  showInvalidDataMessage(message: string) {
    this.dialog.open(InvalidValidationDialogComponent, {
      autoFocus: false,
      panelClass: 'component-dialog-container',
      data: message
    });
  };

  setDriverData(event: any) {
    if(typeof event === 'object') {
      this.paymentFormGroup.patchValue({'DriverName': event.full_name_upper_case, 'division': this.selectDivisionById(event.division_id),
      'driverId': event.id
      })
    }
    else {
      this.paymentFormGroup.patchValue({'DriverName': event, 'driverId': 0});
    }
  };

  resetForm() {
    this.paymentFormGroup.reset();
  };

  get getSelectedUnits(): any[] {
    const selectedUnits: string = this.formData('unit_no');
    if(selectedUnits?.length > 0) {
      return selectedUnits.split(', ');
    }
    return [];
  };

  checkValidation(key: string): boolean {
    return !this.paymentFormGroup.controls[key].valid && this.paymentFormGroup.controls[key]?.touched; 
  };

  ngOnDestroy(): void {
    this.subscription1?.unsubscribe();  
    this.subscription2?.unsubscribe();
    this.subscription3?.unsubscribe();
    this.subscription4?.unsubscribe();
    this.subscription5?.unsubscribe();    
    this.subscription6?.unsubscribe();    
  };

}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../core/services/auth/auth.guard';
import { WarningsComponent } from './components/warnings.component';

const routes: Routes = [
	{
		path: '',
		component: WarningsComponent,
		canActivate: [AuthGuard],
		data: { roles: 29 }
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class WarningsRoutingModule { }

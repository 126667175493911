export class Misc {
	public capitalize(string) {
		return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
	}

	public round(number: number, precision: number) {
        var factor = Math.pow(10, precision);
		//Math.round(number * factor) / factor;
		return (Math.round(number * factor) / factor).toFixed(precision);
	}
	
	public money(number: number) {
		return this.round(number, 2).toString().replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
	}		

	public  formatDate(d: string) {
      if (d != null) {
        var date = new Date(d);

        var monthNames = [
          "Jan", "Feb", "Mar",
          "Apr", "May", "Jun", "Jul",
          "Aug", "Sep", "Oct",
          "Nov", "Dec"
        ];

        var monthNames = [
          "January", "February", "March",
          "April", "May", "June", "July",
          "August", "September", "October",
          "November", "December"
        ];

        var day = date.getDate();
        var monthIndex = date.getMonth();
        var year = date.getFullYear();

        //var result = monthNames[monthIndex] + ' ' + day + ', ' +  year;
        var result = +(monthIndex + 1) + '/' + day + '/' + year;

        return result;
      }
      else {
        return "";
      }
     }

  public yesno(d: boolean) {
    if (d) return "Yes";
    else return "No";
  }

  public float2int(value) {
    return Math.ceil(value);
  }

  public abs(value) {
    return Math.abs(value);
  }

  public divide(value1, value2) {
    return Math.floor(value1 / value2);
  }
}


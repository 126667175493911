import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as _G from '../../../_config/globals';
import { forkJoin } from 'rxjs';
import moment = require('moment');

@Injectable()
export class DispatchRewardSystemService {
  headers = new HttpHeaders({
		'Content-Type': 'application/json',
		'Authorization': 'Basic SVZTb2x1dGlvbjoxUzBsdXQxMG4yQDIy'
	});
	requestOptions = { headers: this.headers };

  constructor(private http: HttpClient) { }

  setTarget(obj: any) {
    let json: any = JSON.stringify(obj);
    return this.http.post(`${_G.extendApiUrl}dispatcher/target`, json, this.requestOptions);
  };

  deleteTarget(id: number) {
    return this.http.delete(`${_G.extendApiUrl}dispatcher/target/${id}`, this.requestOptions);
  };

  getTargetsByDate(isCurrentWeekArray: boolean[], datesArray: any[]) {
    let response1 = this.http.get(`${_G.extendApiUrl}dispatcher/target?start=${datesArray[0].date}&end=${moment(datesArray[0].date).endOf('isoWeek').format('YYYY-MM-DD')}`, this.requestOptions);
    let response2 = this.http.get(`${_G.extendApiUrl}dispatcher/target?start=${datesArray[1].date}&end=${moment(datesArray[1].date).endOf('isoWeek').format('YYYY-MM-DD')}`, this.requestOptions);
    let response3 = this.http.get(`${_G.extendApiUrl}dispatcher/target?start=${datesArray[2].date}&end=${moment(datesArray[2].date).endOf('isoWeek').format('YYYY-MM-DD')}`, this.requestOptions);
    let response4 = this.http.get(`${_G.extendApiUrl}dispatcher/target?start=${datesArray[3].date}&end=${moment(datesArray[3].date).endOf('isoWeek').format('YYYY-MM-DD')}`, this.requestOptions);
    let response5 = this.http.get(`${_G.extendApiUrl}dispatcher/target?start=${datesArray[4].date}&end=${moment(datesArray[4].date).endOf('isoWeek').format('YYYY-MM-DD')}`, this.requestOptions);
    let response6 = this.http.get(`${_G.extendApiUrl}dispatcher/driverrewords?current_week=${isCurrentWeekArray[0]}&start=${datesArray[0].date}&end=${moment(datesArray[0].date).endOf('isoWeek').format('YYYY-MM-DD')}`, this.requestOptions);
    let response7 = this.http.get(`${_G.extendApiUrl}dispatcher/driverrewords?current_week=${isCurrentWeekArray[1]}&start=${datesArray[1].date}&end=${moment(datesArray[1].date).endOf('isoWeek').format('YYYY-MM-DD')}`, this.requestOptions);
    let response8 = this.http.get(`${_G.extendApiUrl}dispatcher/driverrewords?current_week=${isCurrentWeekArray[2]}&start=${datesArray[2].date}&end=${moment(datesArray[2].date).endOf('isoWeek').format('YYYY-MM-DD')}`, this.requestOptions);
    let response9 = this.http.get(`${_G.extendApiUrl}dispatcher/driverrewords?current_week=${isCurrentWeekArray[3]}&start=${datesArray[3].date}&end=${moment(datesArray[3].date).endOf('isoWeek').format('YYYY-MM-DD')}`, this.requestOptions);
    let response10 = this.http.get(`${_G.extendApiUrl}dispatcher/driverrewords?current_week=${isCurrentWeekArray[4]}&start=${datesArray[4].date}&end=${moment(datesArray[4].date).endOf('isoWeek').format('YYYY-MM-DD')}`, this.requestOptions);
    return forkJoin(
      {
        week1Array: response1, week2Array: response2, week3Array: response3, week4Array: response4, week5Array: response5,
        dataSourceArray1: response6, dataSourceArray2: response7, dataSourceArray3: response8, dataSourceArray4: response9, dataSourceArray5: response10
      });
  };

  getRewardsDataForCurrentWeek(isCurrentWeek: boolean, startDate: string, endDate: string) {
    return this.http.get(`${_G.extendApiUrl}dispatcher/driverrewords?current_week=${isCurrentWeek}&start=${startDate}&end=${endDate}`, this.requestOptions);
  };

  getDispatchRewardsData(params: string) {
    return this.http.get(`${_G.extendApiUrl}dispatcher/disprewords${params}`, this.requestOptions);
  };

  getTargetByDateAndPercent(startDate: string, endDate: string, top: string, type: number) {
    return this.http.get(`${_G.extendApiUrl}dispatcher/avgtarget?start=${startDate}&end=${endDate}&top=${top}&type=${type}`, this.requestOptions);
  };

  getDriverLoadsData(driverId: number, startDate: string, endDate: string) {
    return this.http.get(`${_G.extendApiUrl}dispatcher/targetloads?id=${driverId}&start=${startDate}&end=${endDate}`, this.requestOptions);
  };

}

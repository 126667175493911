import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TransformService } from '@app/modules/shared/services/transform.service';

import moment = require('moment');
import { ApexAxisChartSeries, ApexChart, ApexLegend, ApexResponsive, ApexXAxis, ApexYAxis, ChartComponent } from 'ng-apexcharts';

export type ChartOptions = {
  series: ApexAxisChartSeries | any;
  chart: ApexChart | any;
  xaxis: ApexXAxis | any;
  yaxis: ApexYAxis | any;
  responsive: ApexResponsive | any;
  labels: string | any;
  colors: string | any;
  legend: ApexLegend | any;
  tooltip: any;
};

@Component({
  selector: 'app-underperforming-week-dialog',
  templateUrl: './underperforming-week-dialog.component.html',
  styleUrls: ['./underperforming-week-dialog.component.scss']
})
export class UnderperformingWeekDialogComponent implements OnInit {
  @ViewChild("chart") chart: ChartComponent | any;
  public chartOptions: Partial<ChartOptions> | any; 
  paySign: string[] = ['SOLO COMPANY', 'TEAM COMPANY'];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public transformService: TransformService) { console.log(data) }

  ngOnInit(): void {
    this.pieChart('');
  }

  pieChart(obj: any) {
    this.chartOptions = {
      colors: ['#ff900c', '#003fa2'],
      series: [4, 24],
      chart: {
        width: 345,
        type: "pie",
        fontFamily: 'Poppins'
      },
      labels: ['Week', 'Total'],
      legend: {
        position: 'bottom'
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: "bottom"
            }
          }
        }
      ],
      yaxis: {
        labels: {
          formatter: (num: number) => {
            typeof num !== 'number' ? num = 0 : num;
            let result = (num - Math.floor(num)) !== 0;
            let number: any;
            if(result) {
              number = Math.round(num);
            } else {
              number = num;
            }
            return number.toLocaleString("en-US").toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          },
        },
      },
    };
  };

  getWeek() {
    return `${moment(this.data.monday).startOf('isoWeek').format('MMM DD')} - ${moment(this.data.monday).endOf('isoWeek').format('DD, yyyy')}`;
  };

  getPayValue(data: any) {
    let value: string = '';
    if(this.paySign.includes(data.status)) {
      return value = '$'+ this.transformService.addCommasDots(data.paid / 100)
    } else {
      return value = this.transformService.addCommasDots(data.paid, 'round') + '%';
    }
  };

}

import { TitleCasePipe } from '@angular/common';
import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DateRange } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { ApexAxisChartSeries, ApexChart, ApexDataLabels, ApexFill, ApexPlotOptions, ApexStates, ApexTooltip, ApexXAxis, ApexYAxis, 
ChartComponent } from 'ng-apexcharts';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription, catchError, throwError } from 'rxjs';
import { HometimeNoteDialogComponent } from './hometime-note-dialog/hometime-note-dialog.component';
import { HometimeService } from '../services/hometime.service';
import { TransformService } from '@app/modules/shared/services/transform.service';
import moment = require('moment');
import * as XLSX from 'xlsx';
import { FilterSearchPipe } from '../pipes/filter-search.pipe';
import { FilterSearchDriversPipe } from '../pipes/filter-search-drivers.pipe';
import { FilterSearchDispatchersPipe } from '../pipes/filter-search-dispatchers.pipe';
import { RulesService } from '@app/modules/shared/services/rules.service';
import { MsgForbbidenAccessComponent } from '@app/modules/shared/components/msg-forbbiden-access/msg-forbbiden-access.component';

export type ChartOptionsColumn = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  fill: ApexFill,
  colors: any;
  states: ApexStates,
  tooltip: ApexTooltip
};

@Component({
  selector: 'app-hometime',
  templateUrl: './hometime.component.html',
  styleUrls: ['./hometime.component.scss']
})
export class HometimeComponent implements OnInit, OnDestroy {
  //Calendars
  isOpenFromCalendar: boolean = false;
  isOpenToCalendar: boolean = false;

  dateObj: any = {
    startDate: moment().startOf('month').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
  };

  //Select menu
  isOpenSelectMenu: boolean = false;
  selectMenuArray: any[] = [
    {name: 'Approved', status: 'Regular', key: 'approved'},
    {name: 'Not approved', status: 'Bad practice', key: 'not_approved'},
    {name: 'All', status: 'All', key: 'all'}
  ];
  selectValue: string = 'All';
  selectStatus: string = 'All';
  selectKey: string = 'all';

  //Column chart graph
  @ViewChild("chart") chart: ChartComponent | any;
  public chartOptions1: Partial<ChartOptionsColumn> | any;
  public chartOptions2: Partial<ChartOptionsColumn> | any;
  public chartOptions3: Partial<ChartOptionsColumn> | any;
  public chartOptions4: Partial<ChartOptionsColumn> | any;
  public chartOptions5: Partial<ChartOptionsColumn> | any;

  //Table data by drivers
  allDataObj: any;
  byDriversArray: any[] = [];
  byDriversTitle: string = 'Selected period';
  byDriversDate: any;

  driverList: any;

  //Table data by days
  byDaysArray: any[] = [];

  //Table data by dispatchers
  dispatchersArray: any[] = [];
  activeDispatcherObj: any;

  byDispatcher: string = '';
  radioBtnsNumValues: any[] = [
    {name: 'More than'},
    {name: 'Less than'},
    {name: 'Equal'},
  ];

  isOpenApprovedDays: boolean = false;
  approvedDaysNumSign: string = 'More than';
  approvedDaysNumValue: string = '';

  isOpenNotApprovedDays: boolean = false;
  notApprovedDaysNumSign: string = 'More than';
  notApprovedDaysNumValue: string = '';

  isOpenPercentOfDays: boolean = false;
  percentDaysNumSign: string = 'More than';
  percentDaysNumValue: string = '';

  //Metadata
  filterMetaData: any = {
    countObj: {
      avgNumOfApproved: 0,
      avgNumOfNotApproved: 0,
      avgNumOfPercent: 0,
    }
  };

  //Table data by drivers
  driversOverview: any[] = [];
  activeDriverObj: any;

  byDriver: string = '';

  isOpenByDriverApprovedDays: boolean = false;
  byDriverApprovedDaysNumSign: string = 'More than';
  byDriverApprovedDaysNumValue: string = '';

  isOpenByDriverNotApprovedDays: boolean = false;
  byDriverNotApprovedDaysNumSign: string = 'More than';
  byDriverNotApprovedDaysNumValue: string = '';

  isOpenByDriverPercentOfDays: boolean = false;
  byDriverPercentDaysNumSign: string = 'More than';
  byDriverPercentDaysNumValue: string = '';

  filterMetaDataByDrivers: any = {
    countObj: {
      avgNumOfApproved: 0,
      avgNumOfNotApproved: 0,
      avgNumOfPercent: 0,
    }
  };

  //Filters
  radioDriverActivityMenu: any[] = [
    {name: 'Active'},
    {name: 'Inactive'},
    {name: 'All'}
  ];
  isOpenDriverActivity: boolean = false;
  driverActivityValue: string = 'All';

  driverName: string = '';
  dispatcherName: string = '';
  tempDispatcher: string = '';

  radioStatusMenu: any[] = [
    {name: 'Approved'},
    {name: 'Not approved'},
    {name: 'All'}
  ];
  isOpenStatusMenu: boolean = false;
  statusValue: string = 'All';

  checkboxDaysMenu: any[] = [
    {name: 'Monday', checked: true, key: 'mon'},
    {name: 'Tuesday', checked: true, key: 'tue'},
    {name: 'Wednesday', checked: true, key: 'wed'},
    {name: 'Thursday', checked: true, key: 'thu'},
    {name: 'Friday', checked: true, key: 'fri'},
    {name: 'Saturday', checked: true, key: 'sat'},
    {name: 'Sunday', checked: true, key: 'sun'}
  ]
  isOpenCheckBoxMenu: boolean = false;

  mon: string = 'Monday';
  tue: string = 'Tuesday';
  wed: string = 'Wednesday';
  thu: string = 'Thursday';
  fri: string = 'Friday';
  sat: string = 'Saturday';
  sun: string = 'Sunday';

  //Calendar
  isOpenHometimeCalendar: boolean = false;
  hometimeDateValue: DateRange<Date> | undefined;
  @Output() hometimeDateValueChange = new EventEmitter<DateRange<Date>>();
  hometimeDateObj: any;

  //Note
  isOpenNoteMenu: boolean = false;
  noteMenu: any[] = [
    {name: 'With note'},
    {name: 'Without note'},
    {name: 'All'}
  ];
  noteValue: string = 'All';
  
  //Graph menu
  isOpenPeriodMenu: boolean = false;
  menuValue: string = 'By day';
  selectPeriodArray: any[] = [
    {name: 'By year', value: 'year'},
    {name: 'By quarter', value: 'quarter'},
    {name: 'By month', value: 'month'},
    {name: 'By week', value: 'week'},
    {name: 'By day', value: 'day'},
  ];
  selectPeriod: FormControl | any;
  dataPointIndex: number = -1;

  //Excel config
  xlsxDriversAllTimesConfig: any[] = [
    {columnName: 'Active/Inactive', selected: true},
    {columnName: 'Driver', selected: true},
    {columnName: 'Dispatcher', selected: true},
    {columnName: 'Temporary dispatcher', selected: true},
    {columnName: 'Status', selected: true},
    {columnName: 'Hometime day', selected: true},
    {columnName: 'Hometime date', selected: true},
    {columnName: 'Note', selected: true}
  ];

  //Excel config
  xlsxByDayConfig: any[] = [
    {columnName: 'Day', selected: true},
    {columnName: 'Drivers', selected: true},
    {columnName: 'Approved', selected: true},
    {columnName: 'Not approved', selected: true},
    {columnName: '% of fleet', selected: true}
  ];

  //Excel config
  xlsxByDisaptchersConfig: any[] = [
    {columnName: 'Dispatcher', selected: true},
    {columnName: 'Approved days', selected: true},
    {columnName: 'Not approved days', selected: true},
    {columnName: '%', tdKey: 'percentage', selected: true}
  ];

  //Excel config
  xlsxByDriversConfig: any[] = [
    {columnName: 'Driver', selected: true},
    {columnName: 'Approved days', selected: true},
    {columnName: 'Not approved days', selected: true},
    {columnName: '%', tdKey: 'percentage', selected: true}
  ];

  loaded: boolean = false;

  error: boolean = false;
  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";

  spinnerBgColor: string = this.transformService.spinnerBgColor;
  spinnerType: string = this.transformService.spinnerType;

  //Subscription
  subscription: Subscription | any;

  constructor(public transformService: TransformService,
              private hometimeService: HometimeService,
              private dialog: MatDialog,
              private router: Router,
              private titlecasePipe: TitleCasePipe,
              private filterSearch: FilterSearchPipe,
              private filterSearchDrivers: FilterSearchDriversPipe,
              private filterSearchDispatchers: FilterSearchDispatchersPipe,
              private spinner: NgxSpinnerService,
              private rulesService: RulesService) {
                this.selectPeriod = new FormControl(['day']);
              }

  ngOnInit(): void {
    this.getData();
  } 

  getData() {
    this.byDriversDate = `${moment(this.dateObj.startDate).format('MMM DD, yyyy')} - ${moment(this.dateObj.endDate).format('MMM DD, yyyy')}`;
    this.error = false;
    this.loaded = false;
    this.allDataObj = undefined;
    this.spinner.show('hometime');
    this.subscription = this.hometimeService.getHometimeData(this.dateObj.startDate, this.dateObj.endDate)
    .pipe(catchError((err: any) => {
      this.spinner.hide('hometime');
      this.loaded = true;
      this.error = true;
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      console.log(response);
      this.allDataObj = response;
      this.activeDispatcherObj = this.allDataObj.dispatchers[0];
      this.activeDriverObj  = this.allDataObj.drivers_overview[0];

      this.byDaysArray = this.groupByProperty(this.allDataObj.drivers);
      this.dispatchersArray = this.allDataObj.dispatchers;
      this.byDriversArray = this.allDataObj.drivers;
      this.driversOverview = this.allDataObj.drivers_overview;
      this.chartOptions1 = this.columnChart(this.transformService.countDataByPeriodColumnChart(this.allDataObj.overview, this.selectPeriod.value[0], 'date', this.selectKey), true);
      this.getDispatcherGraph(this.allDataObj.dispatchers[0]);
      this.getDriverGraph(this.allDataObj.drivers_overview[0]);
      this.getDispatcherStatistics();
      this.spinner.hide('hometime');
      this.loaded = true;
    })
  };

  showDataByStatus(obj: any) {
    this.selectValue = obj.name; 
    this.selectStatus = obj.status;
    this.selectKey = obj.key; 
    this.isOpenSelectMenu = !this.isOpenSelectMenu;
    this.dataPointIndex = -1;
    this.byDriversTitle = 'Selected period';
    this.byDriversDate = `${moment(this.dateObj.startDate).format('MMM DD, yyyy')} - ${moment(this.dateObj.endDate).format('MMM DD, yyyy')}`;
    this.byDriversArray = this.allDataObj.drivers;
    this.chartOptions1 = this.columnChart(this.transformService.countDataByPeriodColumnChart(this.allDataObj.overview, this.selectPeriod.value[0], 'date', this.selectKey), true);
  };

  groupByProperty(array: any[]) {
    let date: string = '';
    const groups: any = array.reduce((acc: any, o: any) => {

      let dayName: string = `${moment(o.date).format('dddd')}`;

      if(acc[dayName]) {
        acc[dayName].numOfDrivers++;
        acc[dayName].numOfApproved += o.status === 'Regular' ? 1 : 0;
        acc[dayName].numOfNotApproved += o.status === 'Bad practice' ? 1 : 0;
        acc[dayName].percentOfFleet += date === moment(o.date).format('YYYY-MM-DD') ? 0 : o.weekday_avg;
      } 
      else {
        acc[dayName] = {
          dayName: dayName,
          numOfDrivers: o.numOfDrivers ? o.numOfDrivers: 1,
          numOfApproved: o.numOfApproved ? o.numOfApproved: o.status === 'Regular' ? 1 : 0,
          numOfNotApproved: o.numOfNotApproved ? o.numOfNotApproved: o.status === 'Bad practice' ? 1 : 0,
          percentOfFleet: o.weekday_avg
        };
      };
      date =  `${moment(o.date).format('YYYY-MM-DD')}`;
  
      return acc;
  
    }, {});

    const map = {
      'Monday': 1,'Tuesday': 2,'Wednesday': 3,'Thursday': 4,'Friday': 5,'Saturday': 6,
      'Sunday': 7
   };

    let sortedArray: any = Object.values(groups);
    sortedArray.sort((a: any, b: any) => {
      return map[a.dayName] - map[b.dayName];
   });
   //Statistics
   let sumNumOfDrivers: number = 0;
   let sumNumOfApproved: number = 0;
   let sumNumOfNotApproved: number = 0;
   let sumPercentOfFleet: number = 0;

   let categories: string[] = [];
   let total: number[] = [];
   let percentArray: number[] = [];

   for(let key in sortedArray) {
    sumPercentOfFleet += sortedArray[key].percentOfFleet;
    sortedArray[key].percentOfFleet = (sortedArray[key].numOfDrivers / sortedArray[key].percentOfFleet) * 100;
    sumNumOfDrivers += sortedArray[key].numOfDrivers;
    sumNumOfApproved += sortedArray[key].numOfApproved;
    sumNumOfNotApproved += sortedArray[key].numOfNotApproved;
    categories.push(sortedArray[key].dayName);
    total.push(sortedArray[key].numOfDrivers);
    percentArray.push(Math.round(sortedArray[key].percentOfFleet));
  }

  sortedArray.push(
    {
      dayName: 'Average',
      numOfDrivers: (sumNumOfDrivers / 7),
      numOfApproved: (sumNumOfApproved / 7),
      numOfNotApproved: (sumNumOfNotApproved / 7),
      percentOfFleet: (sumNumOfDrivers / sumPercentOfFleet) * 100,
    }
  );

  this.chartOptions2 = this.columnChart({categories: categories, total: total, percentArray: percentArray}, false);
  return sortedArray;

  };

  //Dispatcher graph
  selectDispatcher(obj: any) {
    this.activeDispatcherObj  = obj;
    this.getDispatcherGraph(obj);
  };

  getDispatcherGraph(obj: any) {
    let categories: any[] = [];
    let total: any[] = [];
    let percentArray: any[] = [];

    let array: any[] = obj.byday;
    const map = {
      'Monday': 1,'Tuesday': 2,'Wednesday': 3,'Thursday': 4,'Friday': 5,'Saturday': 6,
      'Sunday': 7
   };

    array.sort((a: any, b: any) => {
      return map[a.weekday] - map[b.weekday];
   });
    for(let key in array) {
      categories.push(array[key].weekday);
      total.push((array[key].approved + array[key].not_aproved));
      percentArray.push(Math.round((array[key].approved + array[key].not_aproved) / array[key].drivers * 100));
    }

    this.chartOptions3 = this.columnChart({categories: categories, total: total, percentArray: percentArray}, false);
  }

  getDispatcherStatistics() {
    let categories: string[] = [];
    let total: any[] = [];

    for(let key in this.allDataObj.dispatchers) {
      categories.push(this.titlecasePipe.transform(this.allDataObj.dispatchers[key].dispatcher.split('/')[0]));
      total.push((this.allDataObj.dispatchers[key].approver + this.allDataObj.dispatchers[key].not_approved))
    }

    this.chartOptions4 = {
      series: [{name: 'Total', data: total}],
      chart: {
        height: 400,
        type: "bar",
        fontFamily: 'Poppins',
        toolbar: {
          show: true,
          tools: {
            download: false
          }
        },
        events: {
          dataPointSelection: (event: any, chartContext: any, config: any) => { }
        }
      },
      plotOptions: {
        bar: {
          dataLabels: {
            position: "top"
          }
        }
      },
      dataLabels: {
        enabled: true,
        offsetY: -30,
        style: {
          fontSize: "14px",
          colors: ["#304758"]
        }
      },
      tooltip: {
        y: {
          formatter: function (value, {dataPointIndex, w}) {
            return value
          }
        }
      },
      xaxis: {
        categories: categories,
        labels: {
          rotate: -45,
          rotateAlways: true,
          style: {
            colors: ['#6b6b6b']
          }
        },
        position: "bottom",
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        tooltip: {
          enabled: true
        }
      },
      yaxis: {
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        labels: {
          show: false
        }
      },
      colors: ['#b7b7b7'],
      fill: {
        opacity: 1
      },
      states: {
        normal: {
            filter: {
                type: 'none',
                value: 0,
            }
        },
        hover: {
            filter: {
                type: 'lighten',
                value: 0.15,
            }
        },
        active: {
            allowMultipleDataPointsSelection: false,
            filter: {
                type: 'none',
                value: 1
            }
        },
    }
    };
  };

  //Calendar method
  dateClicked(event: any, fromCalendar: boolean) {
    if(fromCalendar) {
      this.dateObj.startDate = moment(event._d).format('YYYY-MM-DD');
      this.isOpenFromCalendar = false;
    }
    else {
      this.dateObj.endDate = moment(event._d).format('YYYY-MM-DD');
      this.isOpenToCalendar = false;
    }
  };

  //Column chart
  columnChart(obj: any, isBigGraph: boolean) {
    let activeType: string = 'none';
    let activeValue: number = 1;

    if(isBigGraph) {
      this.driverList = obj.driversList;
      activeType = 'darken'; 
      activeValue = 0.35;
    }
    return {
      series: [
        {
          name: "Hometime",
          data: obj.total
        }
      ],
      chart: {
        height: isBigGraph ? 350 : 275,
        type: "bar",
        fontFamily: 'Poppins',
        toolbar: {
          show: true,
          tools: {
            download: false
          }
        },
        events: {
          dataPointSelection: (event: any, chartContext: any, config: any) => {
            if(isBigGraph) {
              if(this.dataPointIndex === config.dataPointIndex) {
                this.dataPointIndex = -1;
                this.byDriversArray = this.allDataObj.drivers;
                this.byDriversTitle = 'Selected period';
                this.byDriversDate = `${moment(this.dateObj.startDate).format('MMM DD, yyyy')} - ${moment(this.dateObj.endDate).format('MMM DD, yyyy')}`;
              }
              else {
                this.dataPointIndex = config.dataPointIndex;
                this.byDriversArray = this.driverList[config.dataPointIndex].drivers;
                this.byDriversTitle = this.selectPeriod.value[0] === 'day' ? this.driverList[config.dataPointIndex].drivers[0].hometime_day : 'Selected period';
                this.byDriversDate = this.selectPeriod.value[0] === 'day' ? moment(this.driverList[config.dataPointIndex].drivers[0].date).format('MMM DD, yyyy') : obj.categories[this.dataPointIndex];
              }
            }
          }
        }
      },
      plotOptions: {
        bar: {
          dataLabels: {
            position: "top"
          }
        }
      },
      dataLabels: {
        enabled: true,
        offsetY: -30,
        style: {
          fontSize: "14px",
          colors: ["#304758"]
        }
      },
      tooltip: {
        y: {
          formatter: function (value, {dataPointIndex, w}) {
            return `${w.config.series[0].data[dataPointIndex]} days (${obj.percentArray[dataPointIndex]}%)`
          }
        }
      },
      xaxis: {
        categories: obj.categories,
        labels: {
          rotate: -45,
          rotateAlways: true,
          style: {
            colors: ['#6b6b6b']
          }
        },
        position: "bottom",
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        tooltip: {
          enabled: true
        }
      },
      yaxis: {
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        labels: {
          show: false
        }
      },
      colors: ['#b7b7b7'],
      fill: {
        opacity: 1
      },
      states: {
        normal: {
            filter: {
                type: 'none',
                value: 0,
            }
        },
        hover: {
            filter: {
                type: 'lighten',
                value: 0.15,
            }
        },
        active: {
            allowMultipleDataPointsSelection: false,
            filter: {
                type: activeType,
                value: activeValue
            }
        },
    }
    };
  }

  onlyOneSelect(value: string, obj: any) {
    this.menuValue = obj.name;
    this.selectPeriod = new FormControl([value]);
    this.chartOptions1 = this.columnChart(this.transformService.countDataByPeriodColumnChart(this.allDataObj.overview, this.selectPeriod.value[0], 'date', this.selectKey), true);
    this.isOpenPeriodMenu = false;
  };

  //Sort data
  sortDataByDrivers(sort: Sort) {
    const data = this.byDriversArray.slice();
    if (!sort.active || sort.direction === '') {
      this.byDriversArray = data;
      return;
    }
    this.byDriversArray = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'is_active':
          return compare(a.is_active, b.is_active, isAsc);
        case 'driver':
          return compare(a.driver, b.driver, isAsc);
        case 'dispatcher':
          return compare(a.dispatcher, b.dispatcher, isAsc);
        case 'assigned_to_dispatcher':
          return compare(a.assigned_to_dispatcher, b.assigned_to_dispatcher, isAsc);
        case 'status':
          return compare(a.status, b.status, isAsc);
        case 'hometime_day':
          return compare(a.hometime_day, b.hometime_day, isAsc);
        case 'date':
          return compare(a.date, b.date, isAsc);
        case 'note':
          return compare(a.note, b.note, isAsc);
        default:
          return 0;
      }
    });
  }

  sortDataByDispatchers(sort: Sort) {
    const data = this.dispatchersArray.slice();
    if (!sort.active || sort.direction === '') {
      this.dispatchersArray = data;
      return;
    }
    this.dispatchersArray = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'dispatcher':
          return compare(a.dispatcher, b.dispatcher, isAsc);
        case 'approver':
          return compare(a.approver, b.approver, isAsc);
        case 'not_approved':
          return compare(a.not_approved, b.not_approved, isAsc);
        case 'percentage':
          return compare(a.percentage, b.percentage, isAsc);
        default:
          return 0;
      }
    });
  }

  //Open in new tab
  openInNewTab(route: string) {
    const url = this.router.serializeUrl(this.router.createUrlTree([route]));
    window.open(url, '_blank');
  }

  //Filter
  selectUnselectDay(obj: any) {
    if(obj.checked) {
      this[obj.key] = obj.name;
    }
    else {
      this[obj.key] = '';
    }
  
  }

  selectedChange(m: any) {
    if (!this.hometimeDateValue?.start || this.hometimeDateValue?.end) {
      this.hometimeDateValue = new DateRange<Date>(m, null);
    } 
    else {
      const start = this.hometimeDateValue.start;
      const end = m;
      if (end < start) {
          this.hometimeDateValue = new DateRange<Date>(end, start);
      } else {
          this.hometimeDateValue = new DateRange<Date>(start, end);
      }
      this.hometimeDateObj = this.hometimeDateValue;
    } 
    this.hometimeDateValueChange.emit(this.hometimeDateValue);
  }

  //Get data
  run() {
    this.getData();
  };

  //Clear filters 
  clearFilters(num: number) {
    if(num === 1) {
      this.driverActivityValue = 'All';
      this.driverName = '';
      this.dispatcherName = '';
      this.tempDispatcher = '';
      this.statusValue = 'All';
      this.checkboxDaysMenu = [
        {name: 'Monday', checked: true, key: 'mon'},
        {name: 'Tuesday', checked: true, key: 'tue'},
        {name: 'Wednesday', checked: true, key: 'wed'},
        {name: 'Thursday', checked: true, key: 'thu'},
        {name: 'Friday', checked: true, key: 'fri'},
        {name: 'Saturday', checked: true, key: 'sat'},
        {name: 'Sunday', checked: true, key: 'sun'}
      ]
      this.mon = 'Monday';
      this.tue = 'Tuesday';
      this.wed = 'Wednesday';
      this.thu = 'Thursday';
      this.fri = 'Friday';
      this.sat = 'Saturday';
      this.sun = 'Sunday';
      this.hometimeDateValue = undefined;
      this.hometimeDateObj = undefined;
      this.noteValue = 'All';
    }
    else if(num === 2) {
      this.byDispatcher = '';
      this.approvedDaysNumSign = 'More than';
      this.approvedDaysNumValue = '';
  
      this.notApprovedDaysNumSign = 'More than';
      this.notApprovedDaysNumValue = '';
    
      this.percentDaysNumSign = 'More than';
      this.percentDaysNumValue = '';
    }
    else {
      this.byDriver = '';
    
      this.byDriverApprovedDaysNumSign = 'More than';
      this.byDriverApprovedDaysNumValue = '';

      this.byDriverNotApprovedDaysNumSign = 'More than';
      this.byDriverNotApprovedDaysNumValue = '';
    
      this.byDriverPercentDaysNumSign = 'More than';
      this.byDriverPercentDaysNumValue = '';
    }
  }

  //Select driver
  selectDriver(obj: any) {
    this.activeDriverObj = obj;
    this.getDriverGraph(obj);
  };

  getDriverGraph(obj: any) {
    let categories: any[] = [];
    let total: any[] = [];
    let percentArray: any[] = [];
    
    let array: any[] = obj.byday;
    const map = {
      'Monday': 1,'Tuesday': 2,'Wednesday': 3,'Thursday': 4,'Friday': 5,'Saturday': 6,
      'Sunday': 7
   };

    array.sort((a: any, b: any) => {
      return map[a.weekday] - map[b.weekday];
   });
    for(let key in array) {
      categories.push(array[key].weekday);
      total.push((array[key].approved + array[key].not_aproved));
      percentArray.push(Math.round(array[key].percentage));
    }

    this.chartOptions5 = this.columnChart({categories: categories, total: total, percentArray: percentArray}, false);
  }

  
  sortByDrivers(sort: Sort) {
    const data = this.driversOverview.slice();
    if (!sort.active || sort.direction === '') {
      this.driversOverview = data;
      return;
    }
    this.driversOverview = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'driver':
          return compare(a.driver, b.driver, isAsc);
        case 'approved':
          return compare(a.approved, b.approved, isAsc);
        case 'not_approved':
          return compare(a.not_approved, b.not_approved, isAsc);
        case 'percentage':
          return compare(a.percentage, b.percentage, isAsc);
        default:
          return 0;
      }
    });
  }
  
  //Note dialog
  openNoteDialog(element: any) {
    this.dialog.open(HometimeNoteDialogComponent, {
      autoFocus: false,
      panelClass: 'hometime-note-dialog-container',
      data: element
    })
  }

  exportToExcelDriversAllTime(columnsConfig: any[]) {
    if(this.rulesService.UserData[56].data[0].sectionArray[26].allowed) {
      let tableData: any[] = this.filterSearch.transform(this.byDriversArray, [{key: 'is_active', value: this.driverActivityValue }, 
        {key: 'driver', value: this.driverName }, 
        {key: 'dispatcher', value: this.dispatcherName},
        {key: 'assigned_to_dispatcher', value: this.tempDispatcher }, 
        {key: 'status', value: this.statusValue},
        {key: 'hometime_day', value: [this.mon, this.tue, this.wed, this.thu, this.fri, this.sat, this.sun]},
        {key: 'date', value: this.hometimeDateObj},
        {key: 'notes', value: this.noteValue },
        {key: 'status', value: this.selectStatus}
      ]);
      const excelTable: any[] = [];
      for(let i = 0; i < tableData.length; i++) {
        let obj: any = {};
        this.transformService.selectedColumn(obj, columnsConfig[0].columnName, tableData[i].is_active, columnsConfig[0].selected);
        this.transformService.selectedColumn(obj, columnsConfig[1].columnName, this.titlecasePipe.transform(tableData[i].driver), columnsConfig[1].selected);
        this.transformService.selectedColumn(obj, columnsConfig[2].columnName, this.titlecasePipe.transform(tableData[i].dispatcher), columnsConfig[2].selected);
        this.transformService.selectedColumn(obj, columnsConfig[3].columnName, tableData[i].assigned_to_dispatcher, columnsConfig[3].selected);
        this.transformService.selectedColumn(obj, columnsConfig[4].columnName, tableData[i].status === 'Regular' ? 'Approved' : 'Not approved', columnsConfig[4].selected);
        this.transformService.selectedColumn(obj, columnsConfig[5].columnName, tableData[i].hometime_day, columnsConfig[5].selected);
        this.transformService.selectedColumn(obj, columnsConfig[6].columnName, this.transformService.transformDateFormat(tableData[i].date, 'MMM DD, YYYY.'), columnsConfig[6].selected);
        this.transformService.selectedColumn(obj, columnsConfig[7].columnName, tableData[i].note, columnsConfig[7].selected);
        excelTable.push(obj);
      };
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelTable);
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, 'drivers.xlsx');
    }
    else {
      this.msgForbbidenAccess();
    }
  };


  exportToExcelDriversByDay(columnsConfig: any[]) {
    if(this.rulesService.UserData[56].data[0].sectionArray[27].allowed) {
      const excelTable: any[] = [];
      for(let i = 0; i < this.byDaysArray.length; i++) {
        let obj: any = {};
        this.transformService.selectedColumn(obj, columnsConfig[0].columnName, this.byDaysArray[i].dayName, columnsConfig[0].selected);
        this.transformService.selectedColumn(obj, columnsConfig[1].columnName, this.transformService.addCommasDots(this.byDaysArray[i].numOfDrivers, 'round'), columnsConfig[1].selected);
        this.transformService.selectedColumn(obj, columnsConfig[2].columnName, this.transformService.addCommasDots(this.byDaysArray[i].numOfApproved, 'round'), columnsConfig[2].selected);
        this.transformService.selectedColumn(obj, columnsConfig[3].columnName, this.transformService.addCommasDots(this.byDaysArray[i].numOfNotApproved, 'round'), columnsConfig[3].selected);
        this.transformService.selectedColumn(obj, columnsConfig[4].columnName, `${this.transformService.addCommasDots(this.byDaysArray[i].percentOfFleet, 'round')}%`, columnsConfig[4].selected);
        excelTable.push(obj);
      };
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelTable);
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, 'by-day.xlsx');
    }
    else {
      this.msgForbbidenAccess();
    }
  };

  exportToExcelDispatchersByDay(columnsConfig: any[]) {
    if(this.rulesService.UserData[56].data[0].sectionArray[28].allowed) {
      let tableData: any[] = this.filterSearchDispatchers.transform(this.dispatchersArray, [
        {key: 'dispatcher', value: this.byDispatcher },
        {searchValue: this.approvedDaysNumValue, key: 'approver', operator: this.approvedDaysNumSign},
        {searchValue: this.notApprovedDaysNumValue, key: 'not_approved', operator: this.notApprovedDaysNumSign},
        {searchValue: this.percentDaysNumValue, key: 'percentage', operator: this.percentDaysNumSign} 
      ], this.filterMetaData)
      const excelTable: any[] = [];
      for(let i = 0; i < tableData.length; i++) {
        let obj: any = {};
        this.transformService.selectedColumn(obj, columnsConfig[0].columnName, this.titlecasePipe.transform(tableData[i].dispatcher), columnsConfig[0].selected);
        this.transformService.selectedColumn(obj, columnsConfig[1].columnName, tableData[i].approver, columnsConfig[1].selected);
        this.transformService.selectedColumn(obj, columnsConfig[2].columnName, tableData[i].not_approved, columnsConfig[2].selected);
        this.transformService.selectedColumn(obj, columnsConfig[3].columnName, `${this.transformService.addCommasDots(tableData[i].percentage)}%`, columnsConfig[3].selected);
        excelTable.push(obj);
      };
      let footerObj: any = {};
      this.transformService.selectedColumn(footerObj, columnsConfig[0].columnName, 'Average', columnsConfig[0].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[1].columnName, this.transformService.addCommasDots(this.filterMetaData.countObj.avgNumOfApproved, 'round'), columnsConfig[1].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[2].columnName, this.transformService.addCommasDots(this.filterMetaData.countObj.avgNumOfNotApproved, 'round'), columnsConfig[2].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[3].columnName,  `${this.transformService.addCommasDots(this.filterMetaData.countObj.avgNumOfPercent)}%`, columnsConfig[3].selected);
      excelTable.push(footerObj);
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelTable);
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, 'by-dispatcher.xlsx');
    }
    else {
      this.msgForbbidenAccess();
    }
  };

  exportToExcelDriversApprovedNotApproved(columnsConfig: any[]) {
    if(this.rulesService.UserData[56].data[0].sectionArray[29].allowed) {
      const tableData: any[] = this.filterSearchDrivers.transform(this.driversOverview, [
        {key: 'driver', value: this.byDriver },
        {searchValue: this.byDriverApprovedDaysNumValue, key: 'approved', operator: this.byDriverApprovedDaysNumSign},
        {searchValue: this.byDriverNotApprovedDaysNumValue, key: 'not_approved', operator: this.byDriverNotApprovedDaysNumSign},
        {searchValue: this.byDriverPercentDaysNumValue, key: 'percentage', operator: this.byDriverPercentDaysNumSign} 
      ], this.filterMetaDataByDrivers)
      const excelTable: any[] = [];
      for(let i = 0; i < tableData.length; i++) {
        let obj: any = {};
        this.transformService.selectedColumn(obj, columnsConfig[0].columnName, this.titlecasePipe.transform(tableData[i].driver), columnsConfig[0].selected);
        this.transformService.selectedColumn(obj, columnsConfig[1].columnName, tableData[i].approved, columnsConfig[1].selected);
        this.transformService.selectedColumn(obj, columnsConfig[2].columnName, tableData[i].not_approved, columnsConfig[2].selected);
        this.transformService.selectedColumn(obj, columnsConfig[3].columnName, `${this.transformService.addCommasDots(tableData[i].percentage)}%`, columnsConfig[3].selected);
        excelTable.push(obj);
      };
      let footerObj: any = {};
      this.transformService.selectedColumn(footerObj, columnsConfig[0].columnName, 'Average', columnsConfig[0].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[1].columnName,  this.transformService.addCommasDots(this.filterMetaDataByDrivers.countObj.avgNumOfApproved, 'round'), columnsConfig[1].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[2].columnName,  this.transformService.addCommasDots(this.filterMetaDataByDrivers.countObj.avgNumOfNotApproved, 'round'), columnsConfig[2].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[3].columnName,  `${this.transformService.addCommasDots(this.filterMetaDataByDrivers.countObj.avgNumOfPercent)}%`, columnsConfig[3].selected);
      excelTable.push(footerObj);
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelTable);
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, 'by-drivers.xlsx');
    }
    else {
      this.msgForbbidenAccess();
    }
  };

  msgForbbidenAccess() {
    this.dialog.open(MsgForbbidenAccessComponent, {
      autoFocus: false,
      panelClass: 'forbidden-msg-dialog-container'
    })
  };

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  };

}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TrucksRoutingModule } from './trucks.routing';
import { SharedModule } from '../shared/shared.module';
import { EmptyTrucksCardsComponent } from './components/empty-trucks-stats-page/empty-trucks-cards/empty-trucks-cards.component';
import { EmptyTrucksLineChartComponent } from './components/empty-trucks-stats-page/empty-trucks-line-chart/empty-trucks-line-chart.component';
import { EmptyTrucksStatsPageComponent } from './components/empty-trucks-stats-page/empty-trucks-stats-page.component';
import { EmptyTrucksTableComponent } from './components/empty-trucks-stats-page/empty-trucks-table/empty-trucks-table.component';
import { TruckEstimationCardComponent } from './components/truck-performance-page/truck-estimation-card/truck-estimation-card.component';
import { TruckPerformanceAnalysisLinechartComponent } from './components/truck-performance-page/truck-performance-analysis-linechart/truck-performance-analysis-linechart.component';
import { TruckPerformanceAnalysisTableComponent } from './components/truck-performance-page/truck-performance-analysis-table/truck-performance-analysis-table.component';
import { TruckPerformanceCardsComponent } from './components/truck-performance-page/truck-performance-cards/truck-performance-cards.component';
import { TruckPerformancePageComponent } from './components/truck-performance-page/truck-performance-page.component';
import { TruckPerformanceProfitabilityTableComponent } from './components/truck-performance-page/truck-performance-profitability-table/truck-performance-profitability-table.component';
import { ConfirmDialogComponent } from './components/truck-profile-page/p-l-category-dialog/confirm-dialog/confirm-dialog.component';
import { CostInfluenceDialogComponent } from './components/truck-profile-page/p-l-category-dialog/cost-influence-dialog/cost-influence-dialog.component';
import { PLCategoryDialogComponent } from './components/truck-profile-page/p-l-category-dialog/p-l-category-dialog.component';
import { TimelineChartComponent } from './components/truck-profile-page/timeline-chart/timeline-chart.component';
import { TruckCardsLineChartComponent } from './components/truck-profile-page/truck-cards-line-chart/truck-cards-line-chart.component';
import { TruckCardsPieChartComponent } from './components/truck-profile-page/truck-cards-pie-chart/truck-cards-pie-chart.component';
import { TruckProfilePageComponent } from './components/truck-profile-page/truck-profile-page.component';
import { TruckProfitabilityInfoDialogComponent } from './components/truck-profile-page/truck-profitability-info-dialog/truck-profitability-info-dialog.component';
import { TruckProfitabilityTableComponent } from './components/truck-profile-page/truck-profitability-table/truck-profitability-table.component';
import { TrucksService } from './services/trucks.service';
import { TrucksInspectionsPageComponent } from './components/trucks-inspections-page/trucks-inspections-page.component';
import { TruckInspectionDetailsDialogComponent } from './components/trucks-inspections-page/truck-inspection-details-dialog/truck-inspection-details-dialog.component';
import { InspectionItemsTabComponent } from './components/trucks-inspections-page/truck-inspection-details-dialog/inspection-items-tab/inspection-items-tab.component';
import { TiresInspectionTabComponent } from './components/trucks-inspections-page/truck-inspection-details-dialog/tires-inspection-tab/tires-inspection-tab.component';
import { RimsInspectionTabComponent } from './components/trucks-inspections-page/truck-inspection-details-dialog/rims-inspection-tab/rims-inspection-tab.component';
import { BrakesInspectionTabComponent } from './components/trucks-inspections-page/truck-inspection-details-dialog/brakes-inspection-tab/brakes-inspection-tab.component';
import { PhotosOfInspectionDialogComponent } from './components/trucks-inspections-page/truck-inspection-details-dialog/photos-of-inspection-dialog/photos-of-inspection-dialog.component';
import { TruckInspectionPipe } from './pipes/truck-inspection.pipe';
import { InspectionItemNoteComponent } from './components/trucks-inspections-page/truck-inspection-details-dialog/inspection-items-tab/inspection-item-note/inspection-item-note.component';

@NgModule({
  declarations: [
    EmptyTrucksStatsPageComponent,
    EmptyTrucksCardsComponent,
    EmptyTrucksLineChartComponent,
    EmptyTrucksTableComponent,
    TruckPerformancePageComponent,
    TruckEstimationCardComponent,
    TruckPerformanceAnalysisLinechartComponent,
    TruckPerformanceAnalysisTableComponent,
    TruckPerformanceCardsComponent,
    TruckPerformanceProfitabilityTableComponent,
    TruckProfilePageComponent,
    PLCategoryDialogComponent,
    ConfirmDialogComponent,
    CostInfluenceDialogComponent,
    TimelineChartComponent,
    TruckCardsLineChartComponent,
    TruckCardsPieChartComponent,
    TruckProfitabilityInfoDialogComponent,
    TruckProfitabilityTableComponent,
    TrucksInspectionsPageComponent,
    TruckInspectionDetailsDialogComponent,
    InspectionItemsTabComponent,
    TiresInspectionTabComponent,
    RimsInspectionTabComponent,
    BrakesInspectionTabComponent,
    PhotosOfInspectionDialogComponent,
    TruckInspectionPipe,
    InspectionItemNoteComponent
  ],
  imports: [
    CommonModule,
    TrucksRoutingModule,
    SharedModule
  ],
  providers: [TrucksService]
})
export class TrucksModule { }

import { ConnectionPositionPair } from '@angular/cdk/overlay';
import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'table-dropdown-menu',
  templateUrl: './dropdown-menu.component.html',
  styleUrls: ['./dropdown-menu.component.scss']
})
export class DropdownMenuComponent {
  @Output() onClickEmit = new EventEmitter<boolean>();
  
  //Positions
  public positions = [
    new ConnectionPositionPair(
      {originX: 'end', originY: 'bottom'},
      {overlayX: 'end', overlayY: 'top'},
      0, 5
    )
  ];

  isOpenMenu: boolean = false;

  selectOption(isFileOption: boolean) {
    if(isFileOption) {
      this.onClickEmit.emit(true);
    };
    this.isOpenMenu = !this.isOpenMenu;
  };

}

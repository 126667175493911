import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { DriversPerformancePageRoutingModule } from './drivers-performance-page.routing';
import { DashboardDriversPerformancePageComponent } from './components/dashboard-drivers-performance-page.component';
import { DriversPerformanceAverageCardsComponent } from './components/drivers-performance-average-cards/drivers-performance-average-cards.component';
import { DriversPerformanceCardsLineChartComponent } from './components/drivers-performance-cards-line-chart/drivers-performance-cards-line-chart.component';
import { DriversPerformanceCardsComponent } from './components/drivers-performance-cards/drivers-performance-cards.component';
import { DriversPerformanceUtilizationLinechartComponent } from './components/drivers-performance-utilization-linechart/drivers-performance-utilization-linechart.component';
import { DriversPerformanceUtilizationComponent } from './components/drivers-performance-utilization/drivers-performance-utilization.component';
import { DriversPerformanceService } from './services/drivers-performance.service';

@NgModule({
  declarations: [
    DashboardDriversPerformancePageComponent,
    DriversPerformanceAverageCardsComponent,
    DriversPerformanceCardsComponent,
    DriversPerformanceCardsLineChartComponent,
    DriversPerformanceUtilizationComponent,
    DriversPerformanceUtilizationLinechartComponent
  ],
  imports: [
    CommonModule,
    DriversPerformancePageRoutingModule,
    SharedModule
  ],
  providers: [DriversPerformanceService]
})
export class DriversPerformancePageModule { }

import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SharedService } from '@app/modules/shared/services/shared.service';
import { TransformService } from '@app/modules/shared/services/transform.service';

import moment = require('moment');

@Component({
  selector: 'app-tw-add-update-category',
  templateUrl: './tw-add-update-category.component.html',
  styleUrls: ['./tw-add-update-category.component.scss']
})
export class TwAddUpdateCategoryComponent {
  alreadyCreated: boolean = false;
  categoryName: string = '';
  subCategoryName: string = '';

  isOpenMenu: boolean = false;
  menuValue: string = '';
  menuArray: any[] = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<any>,
    private sharedService: SharedService,
    private transformService: TransformService) {

    this.menuArray = this.groupCategories(data.array);
    if (!this.data.isNew) {
      this.categoryName = this.data.obj.category;
      this.subCategoryName = this.data.obj.subcategory;
    }
  }

  groupCategories(array: any[]) {
    const groups: any = array.reduce((acc: any, o: any) => {

      o['category'] = o['category'];

      if (!acc[o['category']]) {
        acc[o['category']] = [];
      }

      acc[o['category']].push(o);

      return acc;

    }, {});

    let data: any[] = [];

    for (let key in groups) {
      data.push({ category: key, code: groups[key][0].code })
    }

    return data;
  }


  changeSlider() {
    this.alreadyCreated = !this.alreadyCreated;
    if (this.alreadyCreated) {
      this.menuValue = this.data.array[0].category;
    }
    else {
      this.categoryName = '';
      this.subCategoryName = '';
    }
  }

  selectOption(obj: any) {
    this.menuValue = obj.category;
    this.isOpenMenu = false;
  };

  createUpdate() {
    let loggedUser: any = JSON.parse(localStorage.getItem('currentUser'));
    let obj: any = {
      id: 0,
      is_truck: true,
      category: this.alreadyCreated ? this.menuValue : this.categoryName,
      subcategory: this.subCategoryName,
      created_by: loggedUser.first_name + ' ' + loggedUser.last_name,
      created_date: this.transformService.convertDateToTimestamp(moment(new Date()).format('ddd, DD/MM YYYY'), moment(new Date()).format('HH:mm:ss')),
      edited_by: null,
      edited_date: null
    }

    if (!this.data.isNew) {
      obj.id = this.data.obj.id;
      obj.created_by = this.data.obj.created_by;
      obj.created_date = this.data.obj.created_date;
      obj.edited_by = loggedUser.first_name + ' ' + loggedUser.last_name;
      obj.edited_date = this.transformService.convertDateToTimestamp(moment(new Date()).format('ddd, DD/MM YYYY'), moment(new Date()).format('HH:mm:ss'));
    }
    this.sharedService.saveTwReasons(obj).subscribe((response: any) => {
      if (response) {
        this.dialogRef.close(response);
      }
    });
  }
}


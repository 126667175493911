import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

import moment = require('moment');
import { Subscription } from 'rxjs';
import { CreateUpdateExpenseDialogComponent } from './create-update-expense-dialog/create-update-expense-dialog.component';
import { SharedService } from '@app/modules/shared/services/shared.service';
import { FullAppService } from '@app/modules/full-app/services/full-app.service';
import { PredefiniedExpense, IExpenses } from '@app/modules/full-app/models/expenses-model';
import { DeleteConfirmationDialogComponent } from '@app/modules/shared/components/delete-confirmation-dialog/delete-confirmation-dialog.component';

@Component({
  selector: 'app-full-app-expenses-dialog',
  templateUrl: './full-app-expenses-dialog.component.html',
  styleUrls: ['./full-app-expenses-dialog.component.scss']
})
export class FullAppExpensesDialogComponent implements OnInit, OnDestroy {
  formGroup: FormGroup;
  userData: any = JSON.parse(localStorage.getItem('currentUser'));

  //Expense dropdown
  expenseDropdownArray: PredefiniedExpense[] = [];
  isOpenExpenseMenu: boolean = false;
  
  //Status dropdown
  statusDropdownArray: string[] = ['Completed', 'Pending', 'Not Completed'];
  isOpenStatusMenu: boolean = false;

  //Paid dropdown
  paidDropdownArray: any[] = [];
  isOpenPaidMenu: boolean = false;

  //Calendar
  isOpenCalendar: boolean = false;

  //Payment method dropdown
  paymentMethodDropdownArray: string[] = ['Credit card'];
  isOpenPaymentMenu: boolean = false;

  //Files
  icon: string = 'insert_drive_file';

  //Subscription
  subscription1: Subscription = new Subscription();
  subscription2: Subscription = new Subscription();

  constructor(@Inject(MAT_DIALOG_DATA) public obj: any,
              private dialog: MatDialog,
              private formBuilder: FormBuilder, 
              private fullAppService: FullAppService,
              private sharedService: SharedService,
              public dialogRef: MatDialogRef<FullAppExpensesDialogComponent>) {
              this.formGroup = this.formBuilder.group({
                'id': [0],
                'employee_id': [this.obj.driverId],
                'expense': [null],
                'amount': [null],
                'status': [null],
                'pay_date': [null],
                'pay_by': [null],
                'pament_method': [null],
                'date': [null],
                'receipt': [false],
                'files': this.formBuilder.array([]),
                'created_by': this.userData.first_name + ' ' + this.userData.last_name,
                'ceated_by_id': this.userData.id
              })
  }

  ngOnInit(): void {
    this.getPredefiniedExpenseForSelect();
    this.getAllEmployees();
  }

  getPredefiniedExpenseForSelect() {
    this.subscription1 = this.fullAppService.getAllPredefiniedExpense().subscribe((response: PredefiniedExpense[]) => {
      console.log(response);
      this.expenseDropdownArray = response;
    });
  }

  getAllEmployees() {
    this.subscription2 = this.sharedService.getAllEmployees().subscribe((response: any) => {
      console.log(response);
      this.paidDropdownArray = response;
    });
  }

  //Calendar method
  dateClicked(event: any) {
    this.formGroup.patchValue({'pay_date': moment(event._d).format('YYYY-MM-DDT00:00:00')})
    this.isOpenCalendar = false;
  };

  //Select files
  onFileSelected(event: any) {  
    let length: any = Object.keys(event.target.files).length;
    for(let i = 0; i < length; i++) {
      this.addMultipleFiles(event, i);
    }
  }

  addMultipleFiles(event: any, index: number) {
    let filename = event.target.files[index].name;
    let reader = new FileReader();
    reader.onload = (e: any) => {
      let base64 = e.target.result.split(',')[1];
      this.filesArray.push(
        this.formBuilder.group({
          'category': '',
          'name': filename,
          'data': base64,
          'updated': false,
          'deleted': false
      }))
    };
    reader.readAsDataURL(event.target.files[index]);
    console.log(this.filesArray.controls)
  };

  removeFile(index: number) {
    this.filesArray.removeAt(index);
  };

  createUpdatePredefiniedExpense(obj: PredefiniedExpense) {
    this.isOpenExpenseMenu = !this.isOpenExpenseMenu;
    let dialogRef = this.dialog.open(CreateUpdateExpenseDialogComponent, {
      autoFocus: false,
      panelClass: 'component-dialog-container',
      data: obj
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
        this.getPredefiniedExpenseForSelect();
      }
    });
  };

  deletePredefiniedExpense(id: number, index: number) {
    let dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
      autoFocus: false,
      panelClass: 'delete-dialog-container',
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
        this.fullAppService.deletePredefiniedExpense(id).subscribe((response: boolean) => {
          if(response) {
            this.expenseDropdownArray.splice(index, 1);
            this.isOpenExpenseMenu = !this.isOpenExpenseMenu;
          }
        });
      }
    });
  };


  createExpense() {
    let array: any[] = this.obj.data.concat([this.formGroup.value]);
    console.log(array);
    this.fullAppService.createUpdateExpense(array).subscribe((response: IExpenses[]) => {
      console.log(response);
      if(response.length > 0) {
        this.dialogRef.close(response);
      }
    });
  };

  //Forms select values
  get expense(): string {
    return this.formGroup.controls['expense'].value;
  };

  get status(): string {
    return this.formGroup.controls['status'].value;
  };

  get payDate(): string {
    return this.formGroup.controls['pay_date'].value;
  };

  get paidBy(): string {
    return this.formGroup.controls['pay_by'].value;
  };

  get paymentMethod(): string {
    return this.formGroup.controls['pament_method'].value;
  };

  get filesArray() : FormArray {
    return this.formGroup.controls['files'] as FormArray;
  };

  ngOnDestroy(): void {
    this.subscription1?.unsubscribe();
    this.subscription2?.unsubscribe();
  };

}

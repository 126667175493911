import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as _G from '../../../_config/globals';

@Injectable()
export class DriversFilterService {
  headers = new HttpHeaders({
		'Content-Type': 'application/json',
		'Authorization': 'Basic SVZTb2x1dGlvbjoxUzBsdXQxMG4yQDIy'
	});
	requestOptions = { headers: this.headers };
  
  //Fleet status
  activeInactiveArray: any[] = [
    {name: 'Active', selected: false, key: 'status'},
    {name: 'Inactive', selected: false, key: 'status'},
  ];

  //Profitability
  profitabilityArray: any[] = [
    {name: 'Profitable', selected: false},
    {name: 'Not profitable', selected: false},
  ];

  //Driver status
  driverStatusArray: any[] = [
    {name: 'Platinum', selected: false},
    {name: 'Gold', selected: false},
    {name: 'Silver', selected: false},
    {name: 'Entry', selected: false},
  ];

  //Type
  typeArray: any[] = [
    {name: 'Solo Company', selected: false, key: 'type'}, {name: 'Team Company', selected: false, key: 'type'},
    {name: 'Solo Rent', selected: false, key: 'type'}, {name: 'Team Rent', selected: false, key: 'type'},
    {name: 'Owner', selected: false, key: 'type'},
  ];

  //Deal
  dealArray: any[] = [
    {name: 'All'},
    {name: 'Cpm'},
    {name: 'Percentage'}
  ];

  dealValue: string = 'All';
  dealMin: number | undefined = undefined;
  dealMax: number | undefined = undefined;

  //Period in the company
  periodInTheCompanyCalendarArray: any[] = [
    {name: 'Calendar'},
    {name: 'Min Max'},
  ];
  range: string = 'Calendar';

  periodInTheCompanyArray: any[] = [
    {name: 'Weeks'},
    {name: 'Months'},
    {name: 'Years'},
  ];

  periodInTheCompanyValue: string = 'Weeks';
  periodMin: number | undefined = undefined;
  periodMax: number | undefined = undefined;

  fromDate: string = 'From date';
  filterFromDate: string | undefined = undefined;

  toDate: string = 'To date';
  filterToDate: string | undefined = undefined;

  //Mileage
  mileagePeriodArray: any[] = [
    {name: 'Weekly'},
    {name: 'Monthly'},
    {name: 'Yearly'},
  ];

  mileagePeriodValue: string = 'Weekly';
  mileageMin: number | undefined = undefined;
  mileageMax: number | undefined = undefined;

  //Gross
  grossPeriodArray: any[] = [
    {name: 'Weekly'},
    {name: 'Monthly'},
    {name: 'Yearly'},
  ];

  grossPeriodValue: string = 'Weekly';
  grossMin: number | undefined = undefined;
  grossMax: number | undefined = undefined;

  //States
  statesArray: any[] = [
    {name: 'AL', selected: false, key: 'state'}, {name: 'AK', selected: false, key: 'state'}, {name: 'AZ', selected: false, key: 'state'}, {name: 'AR', selected: false, key: 'state'}, 
    {name: 'CA', selected: false, key: 'state'}, {name: 'CO', selected: false, key: 'state'}, {name: 'CT', selected: false, key: 'state'}, {name: 'DE', selected: false, key: 'state'}, 
    {name: 'FL', selected: false, key: 'state'}, {name: 'GA', selected: false, key: 'state'}, {name: 'HI', selected: false, key: 'state'}, {name: 'ID', selected: false, key: 'state'}, 
    {name: 'IL', selected: false, key: 'state'}, {name: 'IN', selected: false, key: 'state'}, {name: 'IA', selected: false, key: 'state'}, {name: 'KS', selected: false, key: 'state'}, 
    {name: 'KY', selected: false, key: 'state'}, {name: 'LA', selected: false, key: 'state'}, {name: 'ME', selected: false, key: 'state'}, {name: 'MD', selected: false, key: 'state'}, 
    {name: 'MA', selected: false, key: 'state'}, {name: 'MI', selected: false, key: 'state'}, {name: 'MN', selected: false, key: 'state'}, {name: 'MS', selected: false, key: 'state'}, 
    {name: 'MO', selected: false, key: 'state'}, {name: 'MT', selected: false, key: 'state'}, {name: 'NE', selected: false, key: 'state'}, {name: 'NV', selected: false, key: 'state'}, 
    {name: 'NH', selected: false, key: 'state'}, {name: 'NJ', selected: false, key: 'state'}, {name: 'NM', selected: false, key: 'state'}, {name: 'NY', selected: false, key: 'state'}, 
    {name: 'NC', selected: false, key: 'state'}, {name: 'ND', selected: false, key: 'state'}, {name: 'OH', selected: false, key: 'state'}, {name: 'OK', selected: false, key: 'state'}, 
    {name: 'OR', selected: false, key: 'state'}, {name: 'PA', selected: false, key: 'state'}, {name: 'RI', selected: false, key: 'state'}, {name: 'SC', selected: false, key: 'state'}, 
    {name: 'SD', selected: false, key: 'state'}, {name: 'TN', selected: false, key: 'state'}, {name: 'TX', selected: false, key: 'state'}, {name: 'UT', selected: false, key: 'state'}, 
    {name: 'VT', selected: false, key: 'state'}, {name: 'VA', selected: false, key: 'state'}, {name: 'WA', selected: false, key: 'state'}, {name: 'WV', selected: false, key: 'state'}, 
    {name: 'WI', selected: false, key: 'state'}, {name: 'WY', selected: false, key: 'state'}
  ];

  //Paycheck
  paycheckPeriodArray: any[] = [
    {name: 'Weekly'},
    {name: 'Monthly'},
    {name: 'Yearly'},
  ];

  paycheckPeriodValue: string = 'Weekly';
  paycheckMin: number | undefined = undefined;
  paycheckMax: number | undefined = undefined;

  constructor( private http: HttpClient ) {}

  get filterConditions() {
    let mergedArrays: any[] = this.activeInactiveArray.concat(this.typeArray).concat(this.statesArray);
    let criteryArray: any[] = [
      {key: 'status', filterData: [], condition: 'Status Type State', min: undefined, max: undefined},
      {key: 'type', filterData: [], condition: 'Status Type State', min: undefined, max: undefined},
      {key: 'state', filterData: [], condition: 'Status Type State', min: undefined, max: undefined},
      {key: 'dealValue', filterData: [], condition: 'Deal', min: this.dealMin, max: this.dealMax, period: this.dealValue},
      {key: 'gross', filterData: [], condition: 'Mileage Paycheck', min: this.grossMin, max: this.grossMax, period: this.grossPeriodValue},
      {key: 'mileage', filterData: [], condition: 'Mileage Paycheck', min: this.mileageMin, max: this.mileageMax, period: this.mileagePeriodValue},
      {key: 'paycheck', filterData: [], condition: 'Mileage Paycheck', min: this.paycheckMin, max: this.paycheckMax, period: this.paycheckPeriodValue},
      {key: 'hire_date', filterData: [], condition: 'Period in the company', range: this.range,
      min: this.periodMin, max: this.periodMax, period: this.periodInTheCompanyValue,
      fromDate: this.filterFromDate, toDate: this.filterToDate},
    ];

    for(let key in mergedArrays) {
      if(mergedArrays[key].selected && mergedArrays[key].key === 'status') {
        criteryArray[0].filterData.push(mergedArrays[key].name.toLowerCase());
      }
      if(mergedArrays[key].selected && mergedArrays[key].key === 'type') {
        criteryArray[1].filterData.push(mergedArrays[key].name.toLowerCase());
      }
      if(mergedArrays[key].selected && mergedArrays[key].key === 'state') {
        criteryArray[2].filterData.push(mergedArrays[key].name.toLowerCase());
      }
    }

    //Deal
    if(this.dealValue === 'Cpm') {
      criteryArray[3].filterData.push('solo company', 'team company');
    }
    if(this.dealValue === 'Percentage') {
      criteryArray[3].filterData.push('solo rent', 'team rent', 'owner');
    }

    return criteryArray;
  };

  resetFilters() {
    this.activeInactiveArray = [
      {name: 'Active', selected: false, key: 'status'},
      {name: 'Inactive', selected: false, key: 'status'},
    ];

    this.profitabilityArray = [
      {name: 'Profitable', selected: false},
      {name: 'Not profitable', selected: false},
    ];

    this.driverStatusArray = [
      {name: 'Platinum', selected: false},
      {name: 'Gold', selected: false},
      {name: 'Silver', selected: false},
      {name: 'Entry', selected: false},
    ];

    this.typeArray = [
      {name: 'Solo Company', selected: false, key: 'type'}, {name: 'Team Company', selected: false, key: 'type'},
      {name: 'Solo Rent', selected: false, key: 'type'}, {name: 'Team Rent', selected: false, key: 'type'},
      {name: 'Owner', selected: false, key: 'type'},
    ];

    this.dealArray = [
      {name: 'All'},
      {name: 'Cpm'},
      {name: 'Percentage'}
    ];
    this.dealValue = 'All';
    this.dealMin = undefined;
    this.dealArray = undefined;

    this.periodInTheCompanyArray = [
      {name: 'Weeks'},
      {name: 'Months'},
      {name: 'Years'},
    ];
    this.periodInTheCompanyValue = 'Weeks';
    this.periodMin = undefined;
    this.periodMax = undefined;

    this.fromDate = 'From date';
    this.filterFromDate = '';

    this.toDate = 'To date';
    this.filterToDate = '';

    this.mileagePeriodArray = [
      {name: 'Weekly'},
      {name: 'Monthly'},
      {name: 'Yearly'},
    ];
    this.mileagePeriodValue = 'Weekly';
    this.mileageMin = undefined;
    this.mileageMax = undefined;

    this.grossPeriodArray = [
      {name: 'Weekly'},
      {name: 'Monthly'},
      {name: 'Yearly'},
    ];
    this.grossPeriodValue = 'Weekly';
    this.grossMin = undefined;
    this.grossMax = undefined;

    this.statesArray = [
      {name: 'AL', selected: false, key: 'state'}, {name: 'AK', selected: false, key: 'state'}, {name: 'AZ', selected: false, key: 'state'}, {name: 'AR', selected: false, key: 'state'}, 
      {name: 'CA', selected: false, key: 'state'}, {name: 'CO', selected: false, key: 'state'}, {name: 'CT', selected: false, key: 'state'}, {name: 'DE', selected: false, key: 'state'}, 
      {name: 'FL', selected: false, key: 'state'}, {name: 'GA', selected: false, key: 'state'}, {name: 'HI', selected: false, key: 'state'}, {name: 'ID', selected: false, key: 'state'}, 
      {name: 'IL', selected: false, key: 'state'}, {name: 'IN', selected: false, key: 'state'}, {name: 'IA', selected: false, key: 'state'}, {name: 'KS', selected: false, key: 'state'}, 
      {name: 'KY', selected: false, key: 'state'}, {name: 'LA', selected: false, key: 'state'}, {name: 'ME', selected: false, key: 'state'}, {name: 'MD', selected: false, key: 'state'}, 
      {name: 'MA', selected: false, key: 'state'}, {name: 'MI', selected: false, key: 'state'}, {name: 'MN', selected: false, key: 'state'}, {name: 'MS', selected: false, key: 'state'}, 
      {name: 'MO', selected: false, key: 'state'}, {name: 'MT', selected: false, key: 'state'}, {name: 'NE', selected: false, key: 'state'}, {name: 'NV', selected: false, key: 'state'}, 
      {name: 'NH', selected: false, key: 'state'}, {name: 'NJ', selected: false, key: 'state'}, {name: 'NM', selected: false, key: 'state'}, {name: 'NY', selected: false, key: 'state'}, 
      {name: 'NC', selected: false, key: 'state'}, {name: 'ND', selected: false, key: 'state'}, {name: 'OH', selected: false, key: 'state'}, {name: 'OK', selected: false, key: 'state'}, 
      {name: 'OR', selected: false, key: 'state'}, {name: 'PA', selected: false, key: 'state'}, {name: 'RI', selected: false, key: 'state'}, {name: 'SC', selected: false, key: 'state'}, 
      {name: 'SD', selected: false, key: 'state'}, {name: 'TN', selected: false, key: 'state'}, {name: 'TX', selected: false, key: 'state'}, {name: 'UT', selected: false, key: 'state'}, 
      {name: 'VT', selected: false, key: 'state'}, {name: 'VA', selected: false, key: 'state'}, {name: 'WA', selected: false, key: 'state'}, {name: 'WV', selected: false, key: 'state'}, 
      {name: 'WI', selected: false, key: 'state'}, {name: 'WY', selected: false, key: 'state'}
    ];

    this.paycheckPeriodArray = [
      {name: 'Weekly'},
      {name: 'Monthly'},
      {name: 'Yearly'},
    ];
    this.paycheckPeriodValue = 'Weekly';
    this.paycheckMin = undefined;
    this.paycheckMax = undefined;
  };

  //Filter drivers
	getFilterDriversTableData(startDate: string, endDate: string, filterParams: string) {
		return this.http.get(`${_G.extendApiUrl}drivers/filterdrivers?start=${startDate}&end=${endDate}${filterParams}`, this.requestOptions);
	};

}

import { Injectable } from '@angular/core';
import * as _G from '../../../_config/globals';
import { HttpHeaders, HttpClient } from '@angular/common/http';

@Injectable()
export class DocumentsService {

  headers = new HttpHeaders({
		'Content-Type': 'application/json',
		'Authorization': 'Basic SVZTb2x1dGlvbjoxUzBsdXQxMG4yQDIy'
	});
	requestOptions = { headers: this.headers };

  constructor(private http: HttpClient) { }

  getAllCompanies() {
    return this.http.get(`${_G.extendApiUrl}documents/companies`, this.requestOptions);
  };

  saveCompanyInfo(obj: any) {
    let json: any = JSON.stringify(obj);
    return this.http.post(`${_G.extendApiUrl}documents/companies`, json, this.requestOptions);
  };

  getDocumentsByCompany(id: number) {
    return this.http.get(`${_G.extendApiUrl}documents/document?id=${id}`, this.requestOptions);
  };

  addUpdateDocument(obj: any) {
    let json: any = JSON.stringify(obj);
    console.log(json);
    return this.http.post(`${_G.extendApiUrl}documents/document`, json, {
      headers: this.headers,
      reportProgress: true,
      observe: 'events'
    });
  };

  getDocument(id: number, filename: string) {
    return this.http.get(`${_G.extendApiUrl}documents/documentfile?id=${id}&filename=${filename}`, this.requestOptions);
  };  

  deleteDocument(id: number) {
    return this.http.get(`${_G.extendApiUrl}documents/deletedocumet?id=${id}`, this.requestOptions);
  };

}

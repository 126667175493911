import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truckInspection'
})
export class TruckInspectionPipe implements PipeTransform {

  transform(array: any[], filterArray: any[]): any[] {
    if (!array) {
      return [];
    }

    
    let transformedArray: any[] = array.filter((obj: any) => {
      let condition1: boolean = filterArray[0].length === 0 ? true : filterArray[0].includes(obj.truckID);
      let condition2: boolean = filterArray[1].length === 0 ? true : filterArray[1].includes(obj.trailerID);
      let condition3: boolean = filterArray[2].length === 0 ? true : filterArray[2].includes(obj.driverName);

      if(condition1 && condition2 && condition3) {
        return obj;
      }
   
    });

    return transformedArray;
  }

}

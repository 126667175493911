import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { catchError, Subscription, throwError } from 'rxjs';
import { TrucksService } from '../../services/trucks.service';
import { RulesService } from '@app/modules/shared/services/rules.service';
import { TransformService } from '@app/modules/shared/services/transform.service';

@Component({
  selector: 'app-empty-trucks-stats-page',
  templateUrl: './empty-trucks-stats-page.component.html',
  styleUrls: ['./empty-trucks-stats-page.component.scss']
})
export class EmptyTrucksStatsPageComponent implements OnInit, OnDestroy {
  permissions: any = this.rulesService.UserData[4].data[1].allowedAll;
  data: any;
  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";

  loaded1: boolean = false;
  error1: boolean = false;

  loaded2: boolean = false;
  error2: boolean = false;

  spinnerBgColor: string = this.transformService.spinnerBgColor;
  spinnerType: string = this.transformService.spinnerType;

  subscription: Subscription | any;

  constructor(private trucksService: TrucksService, 
              private spinner: NgxSpinnerService, 
              private rulesService: RulesService,
              private transformService: TransformService) { }

  ngOnInit(): void {
    this.spinner.show('no-driver');
    this.spinner.show('no-driver-table');
    this.subscription = this.trucksService.getEmptyTrucksData(this.transformService.filterParams)
    .pipe(catchError((err: any) => {
      this.spinner.hide('no-driver');
      this.spinner.hide('no-driver-table');

      this.loaded1 = true;
      this.error1 = true;

      this.loaded2 = true;
      this.error2 = true;
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      this.data = response;
      this.spinner.hide('no-driver');
      this.spinner.hide('no-driver-table');

      this.loaded1 = true;
      this.loaded2 = true;
      console.log(response)
    })
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}

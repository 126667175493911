import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-category-file-dialog',
  templateUrl: './delete-category-file-dialog.component.html',
  styleUrls: ['./delete-category-file-dialog.component.scss']
})
export class DeleteCategoryFileDialogComponent implements OnInit {
  categoryName: string = '';

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  public dialogRef: MatDialogRef<DeleteCategoryFileDialogComponent>) {  }

  ngOnInit(): void {
    this.data.categories.forEach(element => {
      if(element.ID === this.data.categoryId) {
        this.categoryName = element.categoryName;
      }
    });
  }

  confirm() {
    this.dialogRef.close(true);
  };
  
}

import { CdkDragEnter, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, ElementRef, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActiveTrucksDialogComponent } from '@app/modules/shared/components/active-trucks-dialog/active-trucks-dialog.component';
import { ActiveTrucksRentToRunDialogComponent } from '@app/modules/shared/components/active-trucks-rent-to-run-dialog/active-trucks-rent-to-run-dialog.component';
import { CompanyBenefitMilesDialogComponent } from '@app/modules/shared/components/company-benefit-miles-dialog/company-benefit-miles-dialog.component';
import { EmptyTrucksAnalysisDialogComponent } from '@app/modules/shared/components/empty-trucks-analysis-dialog/empty-trucks-analysis-dialog.component';
import { EmptyTrucksDialogComponent } from '@app/modules/shared/components/empty-trucks-dialog/empty-trucks-dialog.component';
import { LoadsWithMarginDialogComponent } from '@app/modules/shared/components/loads-with-margin-dialog/loads-with-margin-dialog.component';
import { RulesService } from '@app/modules/shared/services/rules.service';
import { OverviewService } from '../../services/overview.service';
import { TransformService } from '@app/modules/shared/services/transform.service';
import { ActiveTrailersDialogComponent } from '@app/modules/shared/components/active-trailers-dialog/active-trailers-dialog.component';
import { MsgForbbidenAccessComponent } from '@app/modules/shared/components/msg-forbbiden-access/msg-forbbiden-access.component';


@Component({
  selector: 'app-dashboard-cards',
  templateUrl: './dashboard-cards.component.html',
  styleUrls: ['./dashboard-cards.component.scss']
})
export class DashboardCardsComponent implements OnInit, OnChanges {
  permissions: any = this.rulesService.UserData[1].data[0].sectionArray;
  permissionsActiveTrucks: any = this.rulesService.UserData[41].forbidden;
  @ViewChild('dropListContainer') dropListContainer?: ElementRef;

  @Input() cards: any;
  @Input() prevPeriod: any;
  @Input() date: any;

  public items: Array<number> = [];

  clickedCardName: string = '';

  oneSelectedCardName: string | undefined;

  activeCardsArray: any[] = [
    { selected: false }, { selected: false }, { selected: false }, { selected: false }, { selected: false },
    { selected: false }, { selected: false }, { selected: false }, { selected: false }, { selected: false },
    { selected: false },  { selected: false }, { selected: false }
  ];

  activeDownCardsArray: any[] = [
    { selected: false, cardName: 'COMPANY', group: 'FIRST' }, { selected: false, cardName: 'RENT TO RUN', group: 'FIRST' }, { selected: false, cardName: 'OWNER', group: 'FIRST' },
    { selected: false, cardName: 'COMPANY', group: 'SECOND' }, { selected: false, cardName: 'RENT TO RUN', group: 'SECOND' }, { selected: false, cardName: 'OWNER', group: 'SECOND' },
    { selected: false, cardName: 'COMPANY', group: 'THIRD' }, { selected: false, cardName: 'RENT TO RUN', group: 'THIRD' }, { selected: false, cardName: 'OWNER', group: 'THIRD' },
    { selected: false, cardName: 'BILLABLE', group: 'FOURTH' }, { selected: false, cardName: 'PAID', group: 'FOURTH' }, { selected: false, cardName: 'SAVED', group: 'FOURTH' },

    { selected: false, cardName: 'COMPANY', group: 'FIFTH' }, { selected: false, cardName: 'RENT TO RUN', group: 'FIFTH' }, { selected: false, cardName: 'OWNER', group: 'FIFTH' },
    { selected: false, cardName: 'COMPANY', group: 'SIXTH' }, { selected: false, cardName: 'RENT TO RUN', group: 'SIXTH' }, { selected: false, cardName: 'OWNER', group: 'SIXTH' },
    { selected: false, cardName: 'COMPANY', group: 'SEVENTH' }, { selected: false, cardName: 'RENT TO RUN', group: 'SEVENTH' }, { selected: false, cardName: 'OWNER', group: 'SEVENTH' },

    { selected: false, cardName: 'COMPANY', group: 'EIGHTH' }, { selected: false, cardName: 'RENT TO RUN', group: 'EIGHTH' }, { selected: false, cardName: 'OWNER', group: 'EIGHTH' },
    { selected: false, cardName: 'TRUCK', group: 'NINTH' }, { selected: false, cardName: 'TRAILER', group: 'NINTH' },

    { selected: false, cardName: 'COMPANY', group: 'TENTH' }, { selected: false, cardName: 'RENT TO RUN', group: 'TENTH' }, { selected: false, cardName: 'OWNER', group: 'TENTH' },
    { selected: false, cardName: 'YARD', group: 'ELEVENTH' }, { selected: false, cardName: 'DEALERSHIP', group: 'ELEVENTH' }, { selected: false, cardName: 'RECOVERY', group: 'ELEVENTH' },
    { selected: false, cardName: 'COMPANY', group: 'TWELFTH' }, { selected: false, cardName: 'RENT TO RUN', group: 'TWELFTH' }, { selected: false, cardName: 'OWNER', group: 'TWELFTH' },
    { selected: false, cardName: 'COMPANY', group: 'THIRTEEN' }, { selected: false, cardName: 'RENT TO RUN', group: 'THIRTEEN' }, { selected: false, cardName: 'OWNER', group: 'THIRTEEN' }
  ];

  slideToggleOn: boolean = true;

  constructor(public transformService: TransformService,
              private rulesService: RulesService,
              private overviewService: OverviewService,
              private dialog: MatDialog) { }

  ngOnInit(): void {
    let cardShowInGraph: boolean = true;
    this.permissions.forEach((obj: any, index: number) => {
        if(obj.allowed) {

          if(cardShowInGraph) {
            this.activeCardsArray[index].selected = true;
            cardShowInGraph = false;
          }

          this.items.push(index+1);

        }
    });
  }

  ngOnChanges(): void {
    if (this.cards) {
      this.showInGraphWhenDateChanged();
    }
  }

  titlesArray: string[] = ['GROSS', 'RATE PER MILE', 'MILEAGE-TMS', 'COMPANY BENEFIT MILES', 'MARGIN', 'MARGIN %', 
  'LENGTH PER LOAD', 'AVG WEIGHT', 'MAINTENANCE', 'ACTIVE TRUCKS', 'EMPTY TRUCKS', 'ACTIVE TRAILERS', 'TOTAL LOADS'];

  titlesArrayDownCards: string[] = [
    'COMPANY (GROSS)', 'RENT TO RUN (GROSS)', 'OWNER (GROSS)',
    'COMPANY (RATE PER MILE)', 'RENT TO RUN (RATE PER MILE)', 'OWNER (RATE PER MILE)',
    'COMPANY (MILEAGE-TMS)', 'RENT TO RUN (MILEAGE-TMS)', 'OWNER (MILEAGE-TMS)',
    'BILLABLE (COMPANY BENEFIT MILES)', 'PAID (COMPANY BENEFIT MILES)', 'SAVED (COMPANY BENEFIT MILES)',

    'COMPANY (MARGIN)', 'RENT TO RUN (MARGIN)', 'OWNER (MARGIN)',
    'COMPANY (MARGIN %)', 'RENT TO RUN (MARGIN %)', 'OWNER (MARGIN %)',
    'COMPANY (LENGTH PER LOAD)', 'RENT TO RUN (LENGTH PER LOAD)', 'OWNER (LENGTH PER LOAD)',
    'COMPANY (AVG WEIGHT)', 'RENT TO RUN (AVG WEIGHT)', 'OWNER (AVG WEIGHT)',
    'TRUCK (MAINTENANCE)', 'TRAILER (MAINTENANCE)',
    'COMPANY (ACTIVE TRUCKS)', 'RENT TO RUN (ACTIVE TRUCKS)', 'OWNER (ACTIVE TRUCKS)',
    'YARD (EMPTY TRUCKS)', 'DEALERSHIP (EMPTY TRUCKS)', 'RECOVERY (EMPTY TRUCKS)',
    'COMPANY (ACTIVE TRAILERS)', 'RENT TO RUN (ACTIVE TRAILERS)', 'OWNER (ACTIVE TRAILERS)',
    'COMPANY (TOTAL LOADS)', 'RENT TO RUN (TOTAL LOADS)', 'OWNER (TOTAL LOADS)'
  ];

  keyArray: string[] = ['gross', 'rate_per_mile', 'mileage', 'saved_miles', 'margin', 'margin_percent', 'lengt_per_load', 'avg_weight',
  'maintenance', 'active_drivers', 'empty_trucks', 'active_trailers', 'total_loads'];

  keyArrayCardDown: string[] = ['company_gross', 'rent_gross', 'owner_gross', 'company_rate_per_mile', 'rent_rate_per_mile',
    'owner_rate_per_mile', 'company_mileage', 'rent_mileage', 'owner_mileage', 'billable_mileage', 'paid_mileage', 'saved_percent',
    'company_margin', 'rent_margin', 'owner_margin', 'company_margin_percent', 'rent_margin_percent', 'owner_margin_percent', 
    'company_lengt_per_load', 'rent_lengt_per_load', 'owner_lengt_per_load', 'company_avg_weight', 'rent_avg_weight', 
    'owner_avg_weight', 'trucks', 'trailers', 'company_active_drivers', 'rent_active_drivers', 'owner_active_drivers', 
    'empty_trucs_ready', 'empty_trucks_ready_soon', 'empty_trucs_recovery', 'company_active_trailers', 'rent_active_trailers', 'owner_active_trailers',
    'total_loads_company', 'total_loads_rent', 'total_loads_owner'
  ];

  downKey: string[] = ['gross', 'gross', 'gross', 'rate_per_mile', 'rate_per_mile', 'rate_per_mile', 'mileage', 'mileage', 'mileage',
    'billable_mileage', 'paid_mileage', 'saved_percent', 'margin', 'margin', 'margin', 'margin_percent', 'margin_percent', 
    'margin_percent', 'lengt_per_load', 'lengt_per_load', 'lengt_per_load', 'avg_weight', 'avg_weight', 'avg_weight', 
    'trucks', 'trailers', 'active_drivers', 'active_drivers', 'active_drivers', 'empty_trucks', 'empty_trucks', 'empty_trucks', 
    'active_trailers', 'active_trailers', 'active_trailers', 'total_loads', 'total_loads', 'total_loads'];

  showInGraphWhenDateChanged() {
    let downCard: boolean = true;
    if (this.slideToggleOn) {
      let data = { cards: undefined, date: this.date ,key: undefined, title: undefined, type: undefined, downCard: undefined, reset: true };
      this.overviewService.lineChartSubject.next(data);

      for (let i = 0; i < this.activeCardsArray.length; i++) {
        if (this.activeCardsArray[i].selected) {
          let data = {
            cards: this.cards, date: this.date, key: this.keyArray[i], title: this.titlesArray[i], downCard: false, reset: false,
            type: 'all'
          };
          this.overviewService.lineChartSubject.next(data);
          downCard = false;
        }
      }

      if (downCard) {
        for (let i = 0; i < this.activeDownCardsArray.length; i++) {
          if (this.activeDownCardsArray[i].selected) {
            let data = {
              cards: this.cards, date: this.date, key: this.downKey[i], title: this.titlesArrayDownCards[i],
              type: this.activeDownCardsArray[i].cardName, downCard: true, reset: false
            };
            this.overviewService.lineChartSubject.next(data);
          }
        }
      }
    }
  }

  showInGraph(obj: any, topCard: boolean, cardIndex: number) {
    if (this.slideToggleOn) {
      if (topCard) {
        obj.selected = !obj.selected;
        this.resetSelectedCards(0);
        let data = {
          cards: this.cards, date: this.date, key: this.keyArray[cardIndex], title: this.titlesArray[cardIndex], downCard: false, reset: false,
          type: 'all'
        };
        this.overviewService.lineChartSubject.next(data);
      }

      if (!topCard) {
        this.resetSelectedCards(1);
        let sendData = () => {
          let data = {
            cards: this.cards, date: this.date, key: this.downKey[cardIndex], title: this.titlesArrayDownCards[cardIndex],
            type: obj.cardName, downCard: true, reset: false
          };
          this.overviewService.lineChartSubject.next(data);
        }

        if (this.activeDownCardsArray.every(obj => obj.selected === false)) {
          obj.selected = !obj.selected;
          this.clickedCardName = obj.cardName;
          sendData();
        }
        else if (obj.cardName == this.clickedCardName && obj.cardName == 'COMPANY') {
          this.activeDownCardsArray[cardIndex].selected = !this.activeDownCardsArray[cardIndex].selected;
          sendData();
        }
        else if (obj.cardName == this.clickedCardName && obj.cardName == 'RENT TO RUN') {
          this.activeDownCardsArray[cardIndex].selected = !this.activeDownCardsArray[cardIndex].selected;
          sendData();
        }
        else if (obj.cardName == this.clickedCardName && obj.cardName == 'OWNER') {
          this.activeDownCardsArray[cardIndex].selected = !this.activeDownCardsArray[cardIndex].selected;
          sendData();
        }
        else if (obj.group == 'FIRST' && this.isActiveGroup(0, 3)) {
          this.clickedCardName = '';
          this.activeDownCardsArray[cardIndex].selected = !this.activeDownCardsArray[cardIndex].selected;
          sendData();
        }
        else if (obj.group == 'SECOND' && this.isActiveGroup(3, 3)) {
          this.clickedCardName = '';
          this.activeDownCardsArray[cardIndex].selected = !this.activeDownCardsArray[cardIndex].selected;
          sendData();
        }
        else if (obj.group == 'THIRD' && this.isActiveGroup(6, 3)) {
          this.clickedCardName = '';
          this.activeDownCardsArray[cardIndex].selected = !this.activeDownCardsArray[cardIndex].selected;
          sendData();
        }
        else if (obj.group == 'FOURTH' && this.isActiveGroup(9, 3)) {
          this.clickedCardName = '';
          this.activeDownCardsArray[cardIndex].selected = !this.activeDownCardsArray[cardIndex].selected;
          sendData();
        }
        else if (obj.group == 'FIFTH' && this.isActiveGroup(12, 3)) {
          this.clickedCardName = '';
          this.activeDownCardsArray[cardIndex].selected = !this.activeDownCardsArray[cardIndex].selected;
          sendData();
        }
        else if (obj.group == 'SIXTH' && this.isActiveGroup(15, 3)) {
          this.clickedCardName = '';
          this.activeDownCardsArray[cardIndex].selected = !this.activeDownCardsArray[cardIndex].selected;
          sendData();
        }
        else if (obj.group == 'SEVENTH' && this.isActiveGroup(18, 3)) {
          this.clickedCardName = '';
          this.activeDownCardsArray[cardIndex].selected = !this.activeDownCardsArray[cardIndex].selected;
          sendData();
        }
        else if (obj.group == 'EIGHTH' && this.isActiveGroup(21, 3)) {
          this.clickedCardName = '';
          this.activeDownCardsArray[cardIndex].selected = !this.activeDownCardsArray[cardIndex].selected;
          sendData();
        }
        else if (obj.group == 'NINTH' && this.isActiveGroup(23, 2)) {
          this.clickedCardName = '';
          this.activeDownCardsArray[cardIndex].selected = !this.activeDownCardsArray[cardIndex].selected;
          sendData();
        }
        else if (obj.group == 'TENTH' && this.isActiveGroup(26, 3)) {
          this.clickedCardName = '';
          this.activeDownCardsArray[cardIndex].selected = !this.activeDownCardsArray[cardIndex].selected;
          sendData();
        }
        else if (obj.group == 'ELEVENTH' && this.isActiveGroup(29, 3)) {
          this.clickedCardName = '';
          this.activeDownCardsArray[cardIndex].selected = !this.activeDownCardsArray[cardIndex].selected;
          sendData();
        }
        else if (obj.group == 'TWELFTH' && this.isActiveGroup(32, 3)) {
          this.clickedCardName = '';
          this.activeDownCardsArray[cardIndex].selected = !this.activeDownCardsArray[cardIndex].selected;
          sendData();
        }
        else if (obj.group == 'THIRTEEN' && this.isActiveGroup(35, 3)) {
          this.clickedCardName = '';
          this.activeDownCardsArray[cardIndex].selected = !this.activeDownCardsArray[cardIndex].selected;
          sendData();
        }
        else if (this.onlyOneSelectedCard() && this.oneSelectedCardName == obj.cardName) {
          this.clickedCardName = obj.cardName;
          this.activeDownCardsArray[cardIndex].selected = !this.activeDownCardsArray[cardIndex].selected;
          sendData();
        }
      }
    }
  }

  isActiveGroup(startIndex: number, endIndex: number) {
    let copyArray: any = JSON.parse(JSON.stringify(this.activeDownCardsArray));
    copyArray.splice(startIndex, endIndex);

    return copyArray.every((obj: any) => obj.selected === false)
  }

  onlyOneSelectedCard() {
    let numberOfSelectedCards: number = 0;
    for (let key in this.activeDownCardsArray) {
      if (this.activeDownCardsArray[key].selected) {
        numberOfSelectedCards++;
        this.oneSelectedCardName = this.activeDownCardsArray[key].cardName;
      }
    }
    return numberOfSelectedCards === 1 ? true : false;
  }

  onChange(isTrue: boolean) {
    this.slideToggleOn = isTrue;
    if (!this.slideToggleOn) {
      this.resetSelectedCards(2);
      let data = {
        cards: undefined, key: undefined, title: undefined,
        type: undefined, downCard: undefined, reset: true
      };
      this.overviewService.lineChartSubject.next(data);
    }
  }

  resetSelectedCards(topCard: number) {
    if (topCard === 0) {
      this.activeDownCardsArray = [
        { selected: false, cardName: 'COMPANY', group: 'FIRST' }, { selected: false, cardName: 'RENT TO RUN', group: 'FIRST' }, { selected: false, cardName: 'OWNER', group: 'FIRST' },
        { selected: false, cardName: 'COMPANY', group: 'SECOND' }, { selected: false, cardName: 'RENT TO RUN', group: 'SECOND' }, { selected: false, cardName: 'OWNER', group: 'SECOND' },
        { selected: false, cardName: 'COMPANY', group: 'THIRD' }, { selected: false, cardName: 'RENT TO RUN', group: 'THIRD' }, { selected: false, cardName: 'OWNER', group: 'THIRD' },
        { selected: false, cardName: 'BILLABLE', group: 'FOURTH' }, { selected: false, cardName: 'PAID', group: 'FOURTH' }, { selected: false, cardName: 'SAVED', group: 'FOURTH' },
    
        { selected: false, cardName: 'COMPANY', group: 'FIFTH' }, { selected: false, cardName: 'RENT TO RUN', group: 'FIFTH' }, { selected: false, cardName: 'OWNER', group: 'FIFTH' },
        { selected: false, cardName: 'COMPANY', group: 'SIXTH' }, { selected: false, cardName: 'RENT TO RUN', group: 'SIXTH' }, { selected: false, cardName: 'OWNER', group: 'SIXTH' },
        { selected: false, cardName: 'COMPANY', group: 'SEVENTH' }, { selected: false, cardName: 'RENT TO RUN', group: 'SEVENTH' }, { selected: false, cardName: 'OWNER', group: 'SEVENTH' },
    
        { selected: false, cardName: 'COMPANY', group: 'EIGHTH' }, { selected: false, cardName: 'RENT TO RUN', group: 'EIGHTH' }, { selected: false, cardName: 'OWNER', group: 'EIGHTH' },
        { selected: false, cardName: 'TRUCK', group: 'NINTH' }, { selected: false, cardName: 'TRAILER', group: 'NINTH' },
    
        { selected: false, cardName: 'COMPANY', group: 'TENTH' }, { selected: false, cardName: 'RENT TO RUN', group: 'TENTH' }, { selected: false, cardName: 'OWNER', group: 'TENTH' },
        { selected: false, cardName: 'YARD', group: 'ELEVENTH' }, { selected: false, cardName: 'DEALERSHIP', group: 'ELEVENTH' }, { selected: false, cardName: 'RECOVERY', group: 'ELEVENTH' },
        { selected: false, cardName: 'COMPANY', group: 'TWELFTH' }, { selected: false, cardName: 'RENT TO RUN', group: 'TWELFTH' }, { selected: false, cardName: 'OWNER', group: 'TWELFTH' },
        { selected: false, cardName: 'COMPANY', group: 'THIRTEEN' }, { selected: false, cardName: 'RENT TO RUN', group: 'THIRTEEN' }, { selected: false, cardName: 'OWNER', group: 'THIRTEEN' }
      ];
    }
    else if (topCard === 1) {
      this.activeCardsArray = [
        { selected: false }, { selected: false }, { selected: false }, { selected: false }, { selected: false },
        { selected: false }, { selected: false }, { selected: false }, { selected: false }, { selected: false },
        { selected: false },  { selected: false }, { selected: false }
      ];
    } else {
      this.activeCardsArray = [
        { selected: false }, { selected: false }, { selected: false }, { selected: false }, { selected: false },
        { selected: false }, { selected: false }, { selected: false }, { selected: false }, { selected: false },
        { selected: false },  { selected: false }, { selected: false }
      ];
      this.activeDownCardsArray = [
        { selected: false, cardName: 'COMPANY', group: 'FIRST' }, { selected: false, cardName: 'RENT TO RUN', group: 'FIRST' }, { selected: false, cardName: 'OWNER', group: 'FIRST' },
        { selected: false, cardName: 'COMPANY', group: 'SECOND' }, { selected: false, cardName: 'RENT TO RUN', group: 'SECOND' }, { selected: false, cardName: 'OWNER', group: 'SECOND' },
        { selected: false, cardName: 'COMPANY', group: 'THIRD' }, { selected: false, cardName: 'RENT TO RUN', group: 'THIRD' }, { selected: false, cardName: 'OWNER', group: 'THIRD' },
        { selected: false, cardName: 'BILLABLE', group: 'FOURTH' }, { selected: false, cardName: 'PAID', group: 'FOURTH' }, { selected: false, cardName: 'SAVED', group: 'FOURTH' },
    
        { selected: false, cardName: 'COMPANY', group: 'FIFTH' }, { selected: false, cardName: 'RENT TO RUN', group: 'FIFTH' }, { selected: false, cardName: 'OWNER', group: 'FIFTH' },
        { selected: false, cardName: 'COMPANY', group: 'SIXTH' }, { selected: false, cardName: 'RENT TO RUN', group: 'SIXTH' }, { selected: false, cardName: 'OWNER', group: 'SIXTH' },
        { selected: false, cardName: 'COMPANY', group: 'SEVENTH' }, { selected: false, cardName: 'RENT TO RUN', group: 'SEVENTH' }, { selected: false, cardName: 'OWNER', group: 'SEVENTH' },
    
        { selected: false, cardName: 'COMPANY', group: 'EIGHTH' }, { selected: false, cardName: 'RENT TO RUN', group: 'EIGHTH' }, { selected: false, cardName: 'OWNER', group: 'EIGHTH' },
        { selected: false, cardName: 'TRUCK', group: 'NINTH' }, { selected: false, cardName: 'TRAILER', group: 'NINTH' },
    
        { selected: false, cardName: 'COMPANY', group: 'TENTH' }, { selected: false, cardName: 'RENT TO RUN', group: 'TENTH' }, { selected: false, cardName: 'OWNER', group: 'TENTH' },
        { selected: false, cardName: 'YARD', group: 'ELEVENTH' }, { selected: false, cardName: 'DEALERSHIP', group: 'ELEVENTH' }, { selected: false, cardName: 'RECOVERY', group: 'ELEVENTH' },
        { selected: false, cardName: 'COMPANY', group: 'TWELFTH' }, { selected: false, cardName: 'RENT TO RUN', group: 'TWELFTH' }, { selected: false, cardName: 'OWNER', group: 'TWELFTH' },
        { selected: false, cardName: 'COMPANY', group: 'THIRTEEN' }, { selected: false, cardName: 'RENT TO RUN', group: 'THIRTEEN' }, { selected: false, cardName: 'OWNER', group: 'THIRTEEN' }
      ];
    }
  }

  //Drag and drop methods

  dropListReceiverElement?: HTMLElement;
  dragDropInfo?: {
    dragIndex: number;
    dropIndex: number;
  };

  dragEntered(event: CdkDragEnter<number>) {
    const drag = event.item;
    const dropList = event.container;
    const dragIndex = drag.data;
    const dropIndex = dropList.data;

    this.dragDropInfo = { dragIndex, dropIndex };

    const phContainer = dropList.element.nativeElement;
    const phElement = phContainer.querySelector('.cdk-drag-placeholder');

    if (phElement) {
      phContainer.removeChild(phElement);
      phContainer.parentElement?.insertBefore(phElement, phContainer);

      moveItemInArray(this.items, dragIndex, dropIndex);

    }
  };

  dragMoved(index: number) {
    let placeholderElement: any;
    if (!this.dropListContainer || !this.dragDropInfo) return;
    placeholderElement =
      this.dropListContainer.nativeElement.querySelector(
        '.cdk-drag-placeholder'
      );

    const receiverElement =
      this.dragDropInfo.dragIndex > this.dragDropInfo.dropIndex
        ? placeholderElement?.nextElementSibling
        : placeholderElement?.previousElementSibling;

    if (!receiverElement) {
      return;
    }

    receiverElement.style.display = 'none';
    this.dropListReceiverElement = receiverElement;
  }

  dragDropped() {
    if (!this.dropListReceiverElement) {
      return;
    }
    this.dropListReceiverElement.style.removeProperty('display');
    this.dropListReceiverElement = undefined;
    this.dragDropInfo = undefined;
  }

  ratePerMilePercent(prev: number, current: number,) {
    let value = Math.abs(100-(prev / current) * 100);
    return value.toFixed(2);
  }

  setDifference(prev: number, current: number, avg?: any) {
    let checkGrowth: any;
    let className: any;
    if (current > prev) {
      checkGrowth = 'more';
      className = 'up';
      avg === 'avg_weight' ? className = 'down' : className;
    } else if (current < prev) {
      checkGrowth = 'less';
      className = 'down';
      avg === 'avg_weight' ? className = 'up' : className;
    } else {
      checkGrowth = '';
      className = 'none'
    }
    let percentage: any;
    if (prev == 0 && current == 0) {
      percentage = 0;
    }

    if (prev == 0 && current !== 0) {
      percentage = Math.abs(100 - (prev * 100 / current));
    }

    if (prev !== 0 && current == 0) {
      percentage = Math.abs(100 - (current * 100 / prev));
    }

    if (prev !== 0 && current !== 0) {
      percentage = Math.abs(100 - (prev / current) * 100);
    }

    let oneRangeMsg: any;
    this.prevPeriod.days === 0 ? oneRangeMsg = `${this.prevPeriod.dayName}` : oneRangeMsg = `${this.prevPeriod.days} days`;
    let twoRangeMsg: string = 'chosen period';

    let alert: string = ` ${checkGrowth} than the previous ${this.prevPeriod.twoRange ? twoRangeMsg : oneRangeMsg}`;
    let alertObj: any = { percent: Math.round(percentage), message: alert, className: className };
    return alertObj;
  }

  countPercentage(current: any, total: any) {
    return Math.round((current / total) * 100)
  }

  countCpm(prev: number, current: number) {
    let className: any;
    if (current < prev) {
      className = 'up-cpm';
    } else if (current > prev) {
      className = 'down-cpm';
    } else {
      className = 'none'
    }
    let alertObj: any = { percent: current.toFixed(2), className: className };
    return alertObj;
  }

  setDifferenceWeight(prev: number, current: number, avg?: any) {
    let checkGrowth: any;
    let className: any;
    if (current > prev) {
      checkGrowth = 'more';
      className = 'downW';
      avg === 'avg_weight' ? className = 'downW' : className;
    } else if (current < prev) {
      checkGrowth = 'less';
      className = 'upW';
      avg === 'avg_weight' ? className = 'upW' : className;
    } else {
      checkGrowth = '';
      className = 'none'
    }
    let percentage: any = Math.abs(100 - Math.round((prev / current) * 100));
    let oneRangeMsg: string = `${this.prevPeriod.days} days`;
    let twoRangeMsg: string = 'chosen period';

    let alert: string = ` ${checkGrowth} than the previous ${this.prevPeriod.twoRange ? twoRangeMsg : oneRangeMsg}`;
    let alertObj: any = { percent: percentage, message: alert, className: className };
    return alertObj;
  }

  setClass() {
    let isGreater: any = this.countPercentage(this.cards?.total[this.keyArray[10]], this.cards?.total?.active_trucks);
    if( isGreater > 10 && this.activeCardsArray[10].selected) {
      return 'active-alert-gradient';
    } 
    else if(isGreater < 10 && this.activeCardsArray[10].selected) {
      return 'active';
    } 
    else {
      return '';
    }
  }

  //Quick analysis dialog
  openQuickLossAnalysisDialog() {
    this.dialog.open(EmptyTrucksAnalysisDialogComponent, {
      autoFocus: false,
      panelClass: 'quick-analysis-dialog-container'
    });
  };

  //Company benefit miles dialog
  openCompanyBenefitMilesDialog() {
    this.dialog.open(CompanyBenefitMilesDialogComponent, {
      minWidth: '1379px',
      autoFocus: false,
      panelClass: 'company-benefit-miles-dialog-container',
      data: 0
    });
  }

  //Loads with margin
  openLoadsWithMarginDialog() {
    this.dialog.open(LoadsWithMarginDialogComponent, {
      autoFocus: false,
      panelClass: 'loads-with-margin-dialog-container',
    });
  };

  //Active trucks dialog
  openActiveTrucksDialog() {
    if(!this.permissionsActiveTrucks) {
      this.dialog.open(ActiveTrucksDialogComponent, {
        autoFocus: false,
        panelClass: 'active-trucks-dialog-container',
      });
    }
    else {
      this.dialog.open(MsgForbbidenAccessComponent, {
        autoFocus: false,
        panelClass: 'forbidden-msg-dialog-container'
      })
    }
  }

  //Active trailes dialog
  openActiveTrailersDialog() {
    this.dialog.open(ActiveTrailersDialogComponent, {
      autoFocus: false,
      panelClass: 'active-trailers-dialog-container',
    });
  };

  //Active trucks rent to run dialog
  openActiveTrucksRentToRunDialog() {
    this.dialog.open(ActiveTrucksRentToRunDialogComponent, {
      autoFocus: false,
      panelClass: 'rent-to-run-dialog-container',
    });
  }

  //Empty trucks
  openEmptyTrucksDialog(type: string) {
    this.dialog.open(EmptyTrucksDialogComponent, {
      autoFocus: false,
      panelClass: 'empty-trucks-dialog-container',
      data: type
    });
  };

}
import { Component, OnDestroy, OnInit } from '@angular/core';
import moment = require('moment');
import { ChatService } from '../../services/chat.service';
import { MatDialog } from '@angular/material/dialog';
import { ChatContactListDialogComponent } from './chat-contact-list-dialog/chat-contact-list-dialog.component';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ImagePreviewComponent } from '@app/modules/shared/components/image-preview/image-preview.component';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit, OnDestroy {
  //Userdata
  loggedUser: any = JSON.parse(localStorage.getItem('currentUser'));
  formGroup: FormGroup;

  //Chat data
  chatGroups: any[] = [
    {ID: 'Accounting'},
    {ID: 'After Hours'},
    {ID: 'Dispatch'},
    {ID: 'Hours Of Service'},
    {ID: 'Maintenance'},
    {ID: 'Recruiting'},
    {ID: 'Safety'}
  ];
  chatMessages: any[] = [];
  
  //Selected chat group
  selectedGroupIndex: number | null = null;

  //Filter
  searchGroup: string = '';

  //Interval
  interval: any;

  //Allowed scroll
  scrollAllowed: boolean = true;

  driverId: number | undefined = undefined;

  //Subscription
  subscription: Subscription = new Subscription();

  constructor(private dialog: MatDialog, private chatService: ChatService, private formBuilder: FormBuilder,
   private route: ActivatedRoute) {
    this.formGroup = this.formBuilder.group({
      'ID': [0],
      'isDriver': [false],
      'isDispatcher': [true],
      'chatGroupID': [0],
      'imagebase64': [''],
      'message': [''],
      'message_sent_date': [''],
      'message_read_date': [null],
      'isRead': [false]
    })
  }

  ngOnInit(): void {
    this.driverId = +this.route.snapshot.paramMap.get('id');
    this.getChatGroups();
    this.interval = setInterval(() => {
    this.getChatGroups();
    }, 5000);
  };

  getChatGroups() {
    let groupId: string | null = this.selectedGroupIndex === null ? null : this.chatGroups[this.selectedGroupIndex].ID;
    if(groupId) {
      this.subscription = this.chatService.getAllChatsByDriver(this.driverId, groupId)
      .subscribe((response: any) => {
        this.chatMessages = response.chatMessages;
        console.log(response);
      });
    }
  };

  openChatContactList() {
    let dialogRef = this.dialog.open(ChatContactListDialogComponent, {
      autoFocus: false,
      panelClass: 'component-dialog-container'
    });
    dialogRef.afterClosed().subscribe((selectedContact: any) => {
      if(selectedContact) {
        console.log(selectedContact);
        this.chatService.createChat(selectedContact.driverID, this.loggedUser.id)
        .subscribe((chatGroupId: number) => {
          if(chatGroupId) {
            this.selectedGroupIndex = null;
            this.getChatGroups();
          };
        })
      }
    });
  };

  //Select chat group
  selectChatGroup(obj: any, i: number) {
    this.formGroup.patchValue({
      'chatGroupID': obj.ID,
    })
    this.selectedGroupIndex = i;
    this.getChatGroups();
  };

  sendMessage() {
    let formData: any = this.formGroup.value;
    formData.message_sent_date = moment().format('YYYY-MM-DDTHH:mm:ss');
    console.log(formData)
    this.chatService.sendMessage(formData, this.chatGroups[this.selectedGroupIndex].driverID, this.loggedUser.id)
    .subscribe((response: any) => {
      if(response) {
        formData.ID = response;
        this.chatMessages.push(formData);
        this.formGroup.reset({
          'ID': 0,
          'isDriver': false,
          'isDispatcher': true,
          'chatGroupID': formData.chatGroupID,
          'imagebase64': '',
          'message': '',
          'message_sent_date': '',
          'message_read_date': null,
          'isRead': false
        });
      }
    });
  };

  //Upload photo
  onPhotoSelected(event: any) {
    const picture = event.target.files[0] as File;
    const reader: any = new FileReader();
    reader.onloadend = () => {
      this.formGroup.patchValue({
      'imagebase64': reader.result.split(',')[1]
      })
      this.sendMessage();
    };
    reader.readAsDataURL(picture);
  };

  previewImage(image: string) {
    this.dialog.open(ImagePreviewComponent, {
      autoFocus: false,
      panelClass: 'component-dialog-container',
      data: image
    });
  };

  getMessageTypeClass(obj: any): string {
    if(obj.isAfterHoursReply && obj.isFleetWideMessage) {
      return 'border-left-fleetwide orange-bg';
    }
    else if(obj.isAfterHoursReply) {
      return 'orange-bg';
    }
    else if(obj.isFleetWideMessage) {
      return 'border-left-fleetwide default-bg';
    }
    else {
      return 'border-left-classic default-bg';
    }
  };

  ngOnDestroy(): void {
    clearInterval(this.interval);
    this.subscription?.unsubscribe();  
  };

}

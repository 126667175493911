import { Injectable } from '@angular/core';
import * as _G from '../../../_config/globals';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Subject, forkJoin } from 'rxjs';
import moment = require('moment');

@Injectable()
export class DispatcherProfileService {
  dataChanged = new Subject<any>();
  dateChanged = new Subject<any>();
  driverProfileCardsLineChartSubject = new Subject<any>();
  driverProfileTableLineChartSubject = new Subject<any>();
  driversPaychecksLineChartSubject = new Subject<any>();

  headers = new HttpHeaders({
		'Content-Type': 'application/json',
		'Authorization': 'Basic SVZTb2x1dGlvbjoxUzBsdXQxMG4yQDIy'
	});

  requestOptions = { headers: this.headers };

  constructor(private http: HttpClient) { }

  getCardsData(startDate: string, endDate: string, startDate2: string, endDate2: string, id: number, filterParams: string) {
    let response1 = this.http.get(`${_G.extendApiUrl}dispatcher/dispprofile?start=${startDate}&end=${endDate}&id=${id}${filterParams}`, this.requestOptions);
    let response2 = this.http.get(`${_G.extendApiUrl}dispatcher/dispprofile?start=${startDate2}&end=${endDate2}&id=${id}${filterParams}`, this.requestOptions);
    return forkJoin([response1, response2]);
  };

  getDriversTableData(startDate: string, endDate: string, id: number, filterParams: string) {
    return this.http.get(`${_G.extendApiUrl}dispatcher/driversoverviewbydisp?start=${startDate}&end=${endDate}&id=${id}${filterParams}`, this.requestOptions);
  };

  getDriversPaychecksTableData(id: number) {
    return this.http.get(`${_G.extendApiUrl}statement/statementsbydispatcher?id=${id}`, this.requestOptions);
  };

  //Get driver activity
  getDriversActivity(id: number, startDateMinus7Days: string, startDate: string, endDate: string, filterParams: string) {
    let response1 = this.http.get(`${_G.extendApiUrl}drivers/driverestatusbyrange?disp_id=${id}&start=${startDate}&end=${endDate}`, this.requestOptions);
    let response2 = this.http.get(`${_G.extendApiUrl}drivers/alldriverestatusbyrange?disp_id=${id}&start=${startDateMinus7Days}&end=${endDate}${filterParams}`, this.requestOptions);
    return forkJoin([response1, response2]);
  };

  //Get total utilization st data
  getTotalUtilizationStData(startDate: string, endDate: string, id: number) {
    return this.http.get(`${_G.extendApiUrl}drivers/DriverUtilization?startDate=${startDate}&endDate=${endDate}&id=${id}`, this.requestOptions);
  };

  //Get utilization
  getUtilizationCards(startDate: string, endDate: string, id: number) {
    return this.http.get(`${_G.extendApiUrl}drivers/trucskutilization?startDate=${startDate}&endDate=${endDate}&id=${id}`, this.requestOptions);
  };

  getInfoData(id: number) {
    return this.http.get(`${_G.extendApiUrl}dispatcher/dispinfo?id=${id}`, this.requestOptions);
  };

  //Get loads
  getLoadsTableData(startDate: string, endDate: string, id: number, filterParams: string) {
    return this.http.get(`${_G.extendApiUrl}dispatcher/loadsbydisp?start=${startDate}&end=${endDate}&id=${id}${filterParams}`, this.requestOptions);
  };

  //Drivers activity table pop-ups
  getLoadInfo(driverId: number, date: string, inTransit: boolean) {
    return this.http.get(`${_G.extendApiUrl}dispatcher/loadinfo?id=${driverId}&date=${date}&is_transit=${inTransit}`, this.requestOptions);
  };

  withoutLoadInfo(driverId: number) {
    return this.http.get(`${_G.extendApiUrl}dispatcher/withoutloadinfo?id=${driverId}`, this.requestOptions);
  };

  sendMail(subject: string, body: string) {
    let obj: any = {
      subject: subject,
      body: body
    }
    let json: any = JSON.stringify(obj);
    return this.http.post(`${_G.extendApiUrl}dispatcher/needHelp`, json, this.requestOptions);
  };

  getAllStatus(id: number) {
    return this.http.get(`${_G.extendApiUrl}dispatcher/dispatcherstatus?id=${id}`, this.requestOptions);
  };

  getDispatcherActivity(id: number, startDate: string, endDate: string) {
    return this.http.get(`${_G.extendApiUrl}dispatcher/dispatcherstatus?id=${id}&start=${startDate}&end=${endDate}`, this.requestOptions);
  };

  //Dispatch profile note exchange

  noteAddedSubject: any = new Subject<any>();

  getAllNotes(dispatcherId: number) {
    return this.http.get(`${_G.extendApiUrl}dispatcher/getdispetchersnotes?id=${dispatcherId}`, this.requestOptions);
  };

  createNoteThread(obj: any) {
    return this.http.post(`${_G.extendApiUrl}dispatcher/getdispetchersnotes`, obj, this.requestOptions);
  };

  addEditNote(obj: any) {
    let json: any = JSON.stringify(obj);
    return this.http.post(`${_G.extendApiUrl}dispatcher/editDispatchersNotes`, json, this.requestOptions);
  };

  closeNoteThread(obj: any) {
    return this.http.post(`${_G.extendApiUrl}dispatcher/closedispatchersnotes`, obj, this.requestOptions);
  };

  deleteNote(noteId: number) {
    return this.http.get(`${_G.extendApiUrl}dispatcher/deletedispatchersnotesmessages?id=${noteId}`, this.requestOptions);
  };

  deleteAllNotes(noteId: number) {
    return this.http.get(`${_G.extendApiUrl}dispatcher/deletealldispatchersnotes?note_id=${noteId}`, this.requestOptions);
  };

  deleteNoteThread(noteId: number) {
    return this.http.get(`${_G.extendApiUrl}dispatcher/deletedispathersnotes?id=${noteId}`, this.requestOptions);
  };

  getActivityTableData(dispId: number, startDate: string, endDate: string) {
    return this.http.get(`${_G.extendApiUrl}dispatcher/driveractivity?disp_id=${dispId}&start=${startDate}&end=${endDate}`, this.requestOptions);
  };

  getLoadsByDispatcher(startDate: string, endDate: string, dispatcherId: number, filterParams: string) {
    return this.http.get(`${_G.extendApiUrl}overview/overviewloads?start=${startDate}&end=${endDate}&disp_id=${dispatcherId}${filterParams}`, this.requestOptions);
  };

  getDispatchStatisticsData(startDate: string, endDate: string, id: number, filterParams: string) {
    return this.http.get(`${_G.extendApiUrl}dispatcher/dispprofile?start=${startDate}&end=${endDate}&id=${id}${filterParams}`, this.requestOptions);
  };

  getTasksByDispatcher(dispId: number) {
    return this.http.get(`${_G.extendApiUrl}dispatcher/tasks?disp_id=${dispId}`, this.requestOptions);
  };


  //Employee status
  getStatusesByDispatcher(dispId: number, startDate: string, endDate: string) {
    return this.http.get(`${_G.extendApiUrl}employee/employeedispatcherstatus?id=${dispId}&start=${startDate}&end=${endDate}`, this.requestOptions);
  };

  getActivityStats(employeeId: number, startDate: string, endDate: string) {
    return this.http.get(`${_G.extendApiUrl}employee/activitystatus?employee_id=${employeeId}&start=${startDate}&end=${endDate}`, this.requestOptions);
  };
  
  //Test
  getScoreForAllDispatchers(startDate: string, endDate: string) {
    return this.http.get(`${_G.extendApiUrl}dispatcher/dispprofileall?start=${startDate}&end=${endDate}`, this.requestOptions);
  };
}

import { TitleCasePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { MsgForbbidenAccessComponent } from '@app/modules/shared/components/msg-forbbiden-access/msg-forbbiden-access.component';
import { RulesService } from '@app/modules/shared/services/rules.service';
import { TransformService } from '@app/modules/shared/services/transform.service';
import * as XLSX from 'xlsx'; 

@Component({
  selector: 'truck-mileage-table',
  templateUrl: './truck-mileage-table.component.html',
  styleUrls: ['./truck-mileage-table.component.scss']
})
export class TruckMileageTableComponent {
  @Input() dataSource: any[] = [];
  @Input() date: any;

   //Excel config
   xlsxConfig: any = [
    {columnName: 'No.', selected: true},
    {columnName: 'Truck', selected: true},
    {columnName: 'Driver', selected: true},
    {columnName: 'Dispatcher', selected: true},
    {columnName: 'Mileage', selected: true},
    {columnName: 'Gross', selected: true},
    {columnName: 'Rate per mile', selected: true},
    {columnName: 'Type', selected: true}
  ];

  constructor(public transformService: TransformService,
              private rulesService: RulesService,
              private dialog: MatDialog,
              private titleCase: TitleCasePipe) { }

  sortData(sort: Sort) {
    const data = JSON.parse(JSON.stringify(this.dataSource));
    if (!sort.active || sort.direction === '') {
      this.dataSource = data;
      return;
    }
    this.dataSource = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'unit_no':
          return compare(a.unit_no, b.unit_no, isAsc);
        case 'driver':
          return compare(a.driver, b.driver, isAsc);
        case 'dispatcher':
          return compare(a.dispatcher, b.dispatcher, isAsc);
        case 'mileage':
          return compare(a.mileage, b.mileage, isAsc);
        case 'gross':
          return compare(a.gross, b.gross, isAsc);
        case 'rate_per_mile':
          return compare(a.rate_per_mile, b.rate_per_mile, isAsc);
        default:
          return 0;
      }
    });
  }

  //Export to excel
  exportToExcel(columnsConfig: any[]) {
    if(this.rulesService.UserData[56].data[0].sectionArray[40].allowed) {
      const excelTable: any[] = [];
      for(let i = 0; i < this.dataSource.length; i++) {
        let obj: any = {};
        this.transformService.selectedColumn(obj, columnsConfig[0].columnName, i + 1, columnsConfig[0].selected);
        this.transformService.selectedColumn(obj, columnsConfig[1].columnName, this.dataSource[i].unit_no, columnsConfig[1].selected);
        this.transformService.selectedColumn(obj, columnsConfig[2].columnName, this.titleCase.transform(this.dataSource[i].driver), columnsConfig[2].selected);
        this.transformService.selectedColumn(obj, columnsConfig[3].columnName, this.titleCase.transform(this.dataSource[i].dispatcher), columnsConfig[3].selected);
        this.transformService.selectedColumn(obj, columnsConfig[4].columnName, this.transformService.addCommasDots(this.dataSource[i].mileage, 'round'), columnsConfig[4].selected);
        this.transformService.selectedColumn(obj, columnsConfig[5].columnName, `$${this.transformService.addCommasDots(this.dataSource[i].gross, 'round')}`, columnsConfig[5].selected);
        this.transformService.selectedColumn(obj, columnsConfig[6].columnName, `$${this.transformService.addCommasDots(this.dataSource[i].rate_per_mile)}`, columnsConfig[6].selected);
        this.transformService.selectedColumn(obj, columnsConfig[7].columnName, this.titleCase.transform(this.dataSource[i].status), columnsConfig[7].selected);
        excelTable.push(obj);
      };
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelTable);
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, 'mileage-per-truck.xlsx');
    }
    else {
      this.msgForbbidenAccess();
    }
  };

  msgForbbidenAccess() {
    this.dialog.open(MsgForbbidenAccessComponent, {
      autoFocus: false,
      panelClass: 'forbidden-msg-dialog-container'
    })
  };

}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
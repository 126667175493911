import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Chart, registerables } from 'chart.js';
import { MatSelectChange } from '@angular/material/select';
import { Inspection, Outcome, Level } from '../../models/inspection.model';
import { Subscription } from 'rxjs';
import { InspectionsService } from '../../services/inspections.service';

interface ChartData {
  count: number;
  percentage: number;
}

@Component({
  selector: 'app-inspection-pie-chart',
  templateUrl: './inspection-pie-chart.component.html',
  styleUrls: ['./inspection-pie-chart.component.scss', '../inspections-main-page/inspections-main-page.component.scss']
})
export class InspectionPieChartComponent implements OnInit, OnDestroy {
  @Input() obj: any;
  data?: Inspection[] = [];
  outcomes = Object.values(Outcome);
  levels = Object.values(Level);

  levelPieChart: any;
  outcomePieChart: any;
  statePieChart: any;

  outcomeData: { [key: string]: ChartData } = {};
  levelData: { [key: string]: ChartData } = {};
  stateData: { [key: string]: ChartData } = {};

  colorPalette = ['#0f4bac', '#E91E63', '#9C27B0', '#673AB7', '#3F51B5', '#2196F3', '#03A9F4', '#00BCD4', '#009688', '#4CAF50', '#8BC34A', '#CDDC39', '#FFEB3B', '#FFC107', '#FF9800', '#FF5722'];

  subscription: Subscription = new Subscription();

  constructor(private service: InspectionsService) {
    Chart.register(...registerables)
  }

  ngOnInit(): void {
    this.subscription = this.service.data$.subscribe((response: any) => {
      this.data = response;
      this.updateCharts();
    });
  }

  updateCharts(): void {
    this.createOutcomePieChart();
    this.createLevelPieChart();
    this.createStatePieChart();
  }

  createOutcomePieChart(event?: MatSelectChange) {
    this.updateOutcomePieChart(event);

    if (this.outcomePieChart) {
      this.outcomePieChart.destroy();
    }

    this.outcomePieChart = this.createChart('outcomeChart', this.outcomeData);
  }


  createLevelPieChart(event?: MatSelectChange) {
    this.updateLevelPieChart(event);

    if (this.levelPieChart) {
      this.levelPieChart.destroy();
    }

    this.levelPieChart = this.createChart('levelChart', this.levelData);
  }

  createStatePieChart(event?: MatSelectChange) {
    this.updateStatePieChart(event);

    if (this.statePieChart) {
      this.statePieChart.destroy();
    }

    this.statePieChart = this.createChart('stateChart', this.stateData);
  }

  updateOutcomePieChart(event?: MatSelectChange) {
    this.outcomeData = this.updateChartData('inspection_result', event);
  }

  updateLevelPieChart(event?: MatSelectChange) {
    this.levelData = this.updateChartData('inspection_level', event);
  }

  updateStatePieChart(event?: MatSelectChange) {
    this.stateData = this.updateChartData('state', event);
  }

  createChart(chartId: string, data: { [key: string]: ChartData }) {
    return new Chart(chartId, {
      type: 'pie',
      data: {
        labels: Object.keys(data),
        datasets: [{
          label: 'Total',
          data: Object.values(data).map((chartData) => chartData.count),
          borderWidth: 2,
          backgroundColor: this.colorPalette,
          hoverOffset: 4
        }]
      },
      options: {
        plugins: {
          legend: {
            position: 'bottom',
            labels: {
              boxWidth: 12
            }
          },
          tooltip: {
            bodyFont: {
              size: 14
            },
            callbacks: {
              label: (context) => {
                const label = context.label;
                const datasetIndex = context.datasetIndex;
                const dataEntry = data[label];
                const percentage = dataEntry.percentage;
                return ` Total ${label}: ${context.formattedValue} (${percentage}%)`;
              }
            }
          }
        },
      }
    });
  }

  updateChartData(dataKey: string, event?: MatSelectChange): { [key: string]: ChartData } {
    const data: { [key: string]: ChartData } = {};

    const selectedValue = event ? event.value : 'ALL';

    if (this.data.length > 0) {
      let filteredData: Inspection[];
      if (selectedValue !== 'ALL') {
        if (selectedValue.toLowerCase() === Outcome.PASSED.toLowerCase() || selectedValue.toLowerCase() === Outcome.FAILED.toLowerCase()) {
          filteredData = this.data.filter((element: Inspection) => element.inspection_result?.toLowerCase() === selectedValue?.toLowerCase());
        } else if (selectedValue.toLowerCase() === Level.Level1.toLowerCase() || selectedValue.toLowerCase() === Level.Level2.toLowerCase() || selectedValue.toLowerCase() === Level.Level3.toLowerCase()) {
          filteredData = this.data.filter((element: Inspection) => element.inspection_level?.toLowerCase() === selectedValue?.toLowerCase());
        }
      } else {
        filteredData = this.data.filter(element => {
          return (element.inspection_result?.toLowerCase() === Outcome.PASSED.toLowerCase() || element.inspection_result?.toLowerCase() === Outcome.FAILED.toLowerCase())
            && (element.inspection_level.toLowerCase() === Level.Level1.toLowerCase() || element.inspection_level.toLowerCase() === Level.Level2.toLowerCase() || element.inspection_level.toLowerCase() === Level.Level3.toLowerCase());
        });
      }

      const totalCount = filteredData.length;

      filteredData.forEach((element: Inspection) => {
        const value = element[dataKey as keyof Inspection] as string;
        data[value] = data[value] || { count: 0, percentage: 0 };
        data[value].count++;
      });

      for (const value in data) {
        if (data.hasOwnProperty(value)) {
          data[value].percentage = Math.round((data[value].count / totalCount) * 100);
        }
      }
    }
    return data;
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

}

import { DialogRef } from '@angular/cdk/dialog';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { DeleteTemplateDialogComponent } from './delete-template-dialog/delete-template-dialog.component';
import { SafetyService } from '@app/modules/safety/services/safety.service';


@Component({
  selector: 'app-templates-list-dialog',
  templateUrl: './templates-list-dialog.component.html',
  styleUrls: ['./templates-list-dialog.component.scss']
})
export class TemplatesListDialogComponent implements OnInit, OnDestroy {

  dataSource: any[] = [];

  subscription: Subscription | any;

  constructor(private safetyService: SafetyService,
              private dialog: MatDialog, 
              private dialogRef: DialogRef) { }

  ngOnInit(): void {
    this.getAllTemplates();
  }

  getAllTemplates() {
    this.subscription = this.safetyService.getAllTemplates().subscribe((response: any) => {
      console.log(response);
      this.dataSource = response;
    })
  };

  newTemplate() {
    this.safetyService.templateSubject.next({header: '', template: '', footer: '', show_page_number: false, newTemplate: true});
    this.dialogRef.close();
  };

  editTemplate(obj: any) {
    obj.newTemplate = false;
    this.safetyService.templateSubject.next(obj);
    this.dialogRef.close();
  };

  deleteTemplate(obj: any) {
    let dialogRef = this.dialog.open(DeleteTemplateDialogComponent, {
      autoFocus: false,
      panelClass: 'delete-dialog-container',
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
        this.safetyService.deleteTemplate(obj.id).subscribe((response: any) => {
          if(response) {
            this.getAllTemplates();
          }
        });
      }
    });
  };

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

}

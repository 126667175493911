import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'nested-dropdown',
  templateUrl: './nested-dropdown.component.html',
  styleUrls: ['./nested-dropdown.component.scss']
})
export class NestedDropdownComponent {
  @Input() selectedValue: string = '';
  @Output() triggerEvent = new EventEmitter<string>();

  array: any[] = [
    {name: 'T1 Tires and Wheels', data: [
      {name: 'T1.1 Tire replacement', data: [
        {name: 'T1.1.1 Tire replacement with NEW'}, 
        {name: 'T1.1.2 Tire replacement with RECAP'}, 
        {name: 'T1.1.3 Tire replacement with USED'}]
      },
      {name: 'T1.2 Tire repair', data: [
        {name: 'T1.2.1 Tire patch'},
        {name: 'T1.2.2 Tire valve replacement'},
        {name: 'T1.2.3 Tire Pass'},
        {name: 'T1.2.4 Balancing'},
        {name: 'T1.2.5 3 Axle alignment'}
      ]},
      {name: 'T1.3 Rim replacement', data: [
        {name: 'T1.3.1 Rim replacement'},
        {name: 'T1.3.2 Rim repair'}
      ]},
      {name: 'T1.5 Mudflaps', data: [
        {name: 'T1.5.1 Mud Flap replacement'},
        {name: 'T1.5.2 Mud Flap bracket replacement'},
        {name: 'T1.5.3 Mud Flap bracket repair/hot work'},
        {name: 'T1.5.4 Mud Flap repair'}
      ]}
    ]}, 
    {name: 'T2 Suspension/Steering system', data: [
      {name: 'T2.1 Leaf Springs', data: [
        {name: 'T2.1.1 Leaf Spring replacement'},
        {name: 'T2.1.2 Leaf Spring Main or Helper leafs replacement'},
        {name: 'T2.1.3 Leaf Spring eye replacement'},
        {name: 'T2.1.4 Center Bolt/Clamp replacement'},
        {name: 'T2.1.5 Bushings replacement'},
        {name: 'T2.1.6 Miscellaneous nuts and bolts replacement'}
      ]},
      {name: 'T2.2 Shock Absorbers', data: [
        {name: 'T2.2.1 Shocks replacement'},
        {name: 'T2.2.2 Shocks repair'},
        {name: 'T2.2.3 Shock mount replacement'},
        {name: 'T2.2.4 Miscellaneous nuts and bolts replacement'}
      ]},
      {name: 'T2.3 Air Springs/Air Bags', data: [
        {name: 'T2.2.1 AirBag replacement'},
        {name: 'T2.2.2 AirBag bypass'},
        {name: 'T2.2.3 Miscellaneous fitting'},
        {name: 'T2.2.4 Miscellaneous nuts and bolts replacement'}
      ]},
      {name: 'T2.4 Suspension or Control Arms', data: [
        {name: 'T2.4.1 Control Arm replacement'},
        {name: 'T2.4.1.1 Arm body replacement'},
        {name: 'T2.4.1.2 Ball Joint replacement'},
        {name: 'T2.4.1.3 Bushings replacement'},
        {name: 'T2.4.1.4 Miscellaneous nuts, bolts and washers replacement'},
      ]},
      {name: 'T2.5 Axles', data: [
        {name: 'T2.5.1 Drive axle replacement/repair/reconnect'},
        {name: 'T2.5.2 Wheel hub replacement/repair'},
        {name: 'T2.5.3 Bearing replacement'},
        {name: 'T2.5.4 Differential replacement'},
        {name: 'T2.5.5 Axle housing replacement'},
        {name: 'T2.5.6 U-joint replacement'},
        {name: 'T2.5.7 Differential reseal or gasket replacement'},
        {name: 'T2.5.8 Sensors'},
        {name: 'T2.5.8.1 Diff pressure sensor'},
        {name: 'T2.5.8.2 Diff temp sensor'}
      ]},
      {name: 'T2.6 Hubs', data: [
        {name: 'T2.6.1 Hub replacement'},
        {name: 'T2.6.2 Hub/Wheel seal repair'},
        {name: 'T2.6.3 Bearing replacement'}
      ]},
      {name: 'T2.8 Stabilizer Bars', data: [
        {name: 'T2.8.1 Stabilizer bar replacement'},
        {name: 'T2.8.2 Stabilizer bar repair'}
      ]},
      {name: 'T2.9 Steering Linkages', data: [
        {name: 'T2.9.1 Tie rods'},
        {name: 'T2.9.1.1 Tie rods replacement'},
        {name: 'T2.9.1.2 Inner tie rod replacement'},
        {name: 'T2.9.1.3 Outer Tie rod replacement'},
        {name: 'T2.9.2 Pitman arm replacement'},
        {name: 'T2.9.3 Drag link replacement'},
        {name: 'T2.9.4 Steering Gearbox'},
        {name: 'T2.9.4.1 Steering Gearbox repair'},
        {name: 'T2.9.4.2 Steering Gearbox replacement'},
        {name: 'T2.9.5 Power steering'}
      ]}
    ]}, 
    {name: 'T3 Brake/Air system', data: [
      {name: 'T3.1 Brakes Consumables', data: [
        {name: 'T3.1.1 Brake Shoe replacement'},
        {name: 'T3.1.2 Brake pad replacement'},
        {name: 'T3.1.3 Brake Drum replacement'},
        {name: 'T3.1.4 Brake Rotor replacement'},
        {name: 'T3.1.5 Brake Caliper replacement'},
        {name: 'T3.1.6 Miscellaneous repairs, valves, fittings, air lines etc.'}
      ]},
      {name: 'T3.2 Brake Elements', data: [
          {name: 'T3.2.1 Brake chamber replacement'},
          {name: 'T3.2.2 S-Camshaft replacement'},
          {name: 'T3.2.3 Slack adjuster'},
          {name: 'T3.2.3.1 Slack adjuster replacement'},
          {name: 'T3.2.3.2 Slack adjuster repair/adjustment'},
          {name: 'T3.2.4 ABS'},
          {name: 'T3.2.4.1 ABS sensor'},
          {name: 'T3.2.4.1.1 ABS sensor replacement'},
          {name: 'T3.2.4.1.2 ABS sensor repair/cleaning'},
          {name: 'T3.2.4.1.3 ABS miscellaneous(check, reset)'},
          {name: 'T3.2.5 Air dryer'},
          {name: 'T3.2.5.1 Air dryer replacement'},
          {name: 'T3.2.5.2 Air dryer cartridge replacement'},
          {name: 'T3.2.6 Miscellaneous elements (brake pedal, air gauge, pressure sensor or switch'}
        ]
      }
    ]}, 
    {name: 'T4 Electrical system', data: [
      {name: 'T4.1 Batteries', data: [
        {name: 'T4.1.1 Jumpstart'},
        {name: 'T4.1.2 Battery replacement'}
      ]},
      {name: 'T4.2 Lights', data: [
        {name: 'T4.2.1 Headlight'},
        {name: 'T4.2.2 Tail light'},
        {name: 'T4.2.3 Reverse light'},
        {name: 'T4.2.4 Marker light'}
      ]},
      {name: 'T4.3 Engine electrical components', data: [
        {name: 'T4.3.1 Alternator'},
        {name: 'T4.3.1.1 Alternator repair'},
        {name: 'T4.3.1.2 Alternator replacement'},
        {name: 'T4.3.2 Starter'},
        {name: 'T4.3.2.1 Starter repair'},
        {name: 'T4.3.2.2 Starter replacement'}
      ]},
      {name: 'T4.4 Electrical wiring and Harnesses', data: [
        {name: 'T4.4.1 Wiring repair'}
      ]}
    ]}, 
    {name: 'T5 Engine', data: [
      {name: 'T5.1 Engine block', data: [
        {name: 'T5.1.1 Engine replacement'},
        {name: 'T5.1.2 Head gasket replacement'},
      ]},
      {name: 'T5.2 Cylinders', data: [
        {name: 'T5.2.1 Injector replacement'},
        {name: 'T5.2.2 Injector seal replacement'},
        {name: 'T5.2.3 Valve adjustment'}
      ]},
      {name: 'T5.2 Cylinders', data: [
        {name: 'T5.2.1 Injector replacement'},
        {name: 'T5.2.2 Injector seal replacement'},
        {name: 'T5.2.3 Valve adjustment'}
      ]}
    ]}, 
    {name: 'T6 Fuel System', data: [
      {name: 'T6.1 Fuel tank', data: [
        {name: 'T6.1.1 Fuel tank holders'},
        {name: 'T6.1.2 Fuel tank cap'},
        {name: 'T6.1.3 Fuel Tank replacement'}
      ]},
      {name: 'T6.2 Fuel filter', data: [
        {name: 'T6.2.1 Fuel filter/Davco replacement'},
        {name: 'T6.2.2 Fuel filter housing replacement'},
        {name: 'T6.2.3 Drain water from fuel'}
      ]},
      {name: 'T6.3 Fuel lines', data: [
        {name: 'T6.3.1 Fuel lines replacement'}
      ]}
    ]}, 
    {name: 'T7 Coolant system', data: [
      {name: 'T6.1 Radiator', data: [
        {name: 'T6.1.1 Radiator replacement'},
        {name: 'T6.1.2 Radiator hose repairs'},
        {name: 'T6.1.2.1 Varios bolts and claims'}
      ]},
      {name: 'T6.2 Coolant tank', data: [
        {name: 'T6.2.1 Coolant tank replacement'},
        {name: 'T6.2.2 Coolant temp sensor replacement'},
        {name: 'T6.2.3 Coolant level sensor replacement'},
        {name: 'T6.2.4 Coolant system flush'},
        {name: 'T6.2.5 Coolant refill'},
        {name: 'T6.2.6 Coolant pressure test'},
        {name: 'T6.2.7 Coolant expansion tank'},
        {name: 'T6.2.8 Coolant add Red 50/50'}
      ]},
      {name: 'T6.3 Coolant hoses', data: []},
      {name: 'T6.4 Coolant/Water pump replacement', data: []}
    ]}, 
    {name: 'T8 Transmission', data: [
      {name: 'T7.1 Transmission replacement', data: []},
      {name: 'T7.2 Clutch replacement', data: []},
      {name: 'T7.3 Transmission oil change', data: []}
    ]}, 
    {name: 'T9 Exhaust System', data: [
      {name: 'T8.1 One box/DPF box', data: [
        {name: 'T8.1.1 DPF filter replacement'},
        {name: 'T8.1.2 DPF case replacement'},
        {name: 'T8.1.3 DPF Inlet/outlet NOx sensor replacement/clean'},
        {name: 'T8.1.4 Soot sensor replacement /RECALL'}
      ]},
      {name: 'T8.2 DEF system', data: [
        {name: 'T8.2.1 DEF'},
        {name: 'T8.2.1.1 DEF pump replacement'},
        {name: 'T8.2.1.2 DEF doser replacement'},
        {name: 'T8.2.1.3 DEF tank replacement'}
      ]},
      {name: 'T8.3 EGR', data: [
        {name: 'T8.3.1 EGR'}
      ]},
      {name: 'T8.4 Exhaust pipes', data: [
        {name: 'T8.4.1 Flex pipe replacement'},
        {name: 'T8.4.2 Exhaust bridge'},
        {name: 'T8.4.3 Exhaust valve'},
        {name: 'T8.4.4 Clamps/pipes for exhaust pipes'}
      ]}
    ]}, 
    {name: 'T10 Body and Exterior', data: []}, 
    {name: 'T11 Interior', data: [
      {name: 'T11.1 AC/HVAC system', data: [
        {name: 'T11.1.1 AC system recharge'},
        {name: 'T11.1.2 AC compressor replacement'},
        {name: 'T11.1.3 AC blower motor replacement'}
      ]},
      {name: 'T11.2 Filters', data: [
        {name: 'T11.2.1 Cab filter replacement'},
        {name: 'T11.2.2 Sleeper filter replacement'}
      ]}
    ]}, 
    {name: 'T12 Towing', data: [
      {name: 'T12.1 Towing', data: [
        {name: 'T12.1.1 Winchout'},
        {name: 'T12.1.2 Towing Impound'},
        {name: 'T12.1.3 Towing Dealer'},
        {name: 'T12.1.4 Towing'}
      ]}
    ]}, 
    {name: 'T13 Miscellaneous', data: [
      {name: 'T13.1 Specialized equipment', data: [
        {name: 'T13.1.1 GPS installation'},
        {name: 'T13.1.2 Tablet mount installation'},
        {name: 'T13.1.3 Inverter installation'},
        {name: 'T13.1.4 Fridge installation'}
      ]}
    ]}, 
    {name: 'TPM', data: [
      {name: 'TPM.14', data: [
        {name: 'T14.1.1 PM service'},
        {name: 'T14.1.2 ToIl change, fuel and oil filter replacement'},
        {name: 'T14.1.3 Air filter'}
      ]}
    ]}
  ];
  index: number = 0;

  dataArray: any[] = this.array;
  backArray: any[] = [];

  isOpenNestedMenu: boolean = false;
  dropdownSearch: string = '';

  selectOption(obj: any) {
    if(this.index < 2) {
      this.index++;
      this.backArray = JSON.parse(JSON.stringify(this.dataArray));
      this.dataArray = obj.data;
    }
    else {
      this.selectedValue = obj.name;
      this.triggerEvent.emit(obj.name);
      this.isOpenNestedMenu = false; 
    }
  };

  backOption() {
    if(this.index === 1) {
      this.dataArray = this.array;
    }
    if(this.index > 1) {
      this.dataArray = this.backArray;
    }
    if(this.index) {
      this.index--;
    }
  };

}

import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SharedService } from '@app/modules/shared/services/shared.service';
import { TicketsService } from '@app/modules/tickets/services/tickets.service';
import moment = require('moment');
import { catchError, throwError } from 'rxjs';

@Component({
  selector: 'app-tickets-notes-dialog',
  templateUrl: './tickets-notes-dialog.component.html',
  styleUrls: ['./tickets-notes-dialog.component.scss']
})
export class TicketsNotesDialogComponent {
  note: string = '';

  loading: boolean = false;
  error: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data,
              private ticketService: TicketsService,
              private sharedService: SharedService) { }

  addNote() {
    this.error = false;
    this.loading = true;
    let loggedUser: any = JSON.parse(localStorage.getItem('currentUser'));
    let obj: any = JSON.parse(JSON.stringify(this.data));
    let noteObj: any = {
      id: 0,
      initiate_id: obj.initiatorId,
      note: this.note,
      created_by: loggedUser.first_name + ' ' + loggedUser.last_name,
      created_date: moment().format('YYYY-MM-DDTHH:mm:ss')
    }
    obj.edited_by = loggedUser.first_name + ' ' + loggedUser.last_name;
    obj.edited_date = moment().format('YYYY-MM-DDTHH:mm:ss');
    obj.notes.push(noteObj)

    this.ticketService.addUpdateTicket(obj)
    .pipe(catchError((err: any) => {
      this.loading = false;
      this.error = true;
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      if(response) {
        this.sendNotifications(JSON.parse(this.data.users));
        
        this.data.notes.push(noteObj);
        this.note = '';
      }
      else {
        this.error = true;
      }
      this.loading = false;
    });

  };

  sendNotifications(selectedEmployees: any[]) {
    if(selectedEmployees.length > 0) {
      let loggedUser: any = JSON.parse(localStorage.getItem('currentUser'));
      for(let key in selectedEmployees) {
        
        if(selectedEmployees[key].id !== loggedUser.id && selectedEmployees[key].id !== 0) {

          let obj: any = {
            id: null,
            type: 'TICKET',
            data: JSON.stringify(this.data),
            date: moment().add(1, 'hour').format('YYYY-MM-DDTHH:mm:ss'),
            from_user: loggedUser.id,
            for_user: selectedEmployees[key].id,
          }
          console.log(obj);
          this.sharedService.sendNotification(obj).subscribe((response: any) => {
            console.log(response);
          });

        }
      }
    }
  }

}

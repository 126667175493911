import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { ITruckAssignedTrucks } from '@app/modules/accounting/models/trucks-assigments.model';
import { AccountingService } from '@app/modules/accounting/services/accounting.service';
import { MsgForbbidenAccessComponent } from '@app/modules/shared/components/msg-forbbiden-access/msg-forbbiden-access.component';
import { RulesService } from '@app/modules/shared/services/rules.service';

@Component({
  selector: 'assigned-trucks-table',
  templateUrl: './assigned-trucks-table.component.html',
  styleUrls: ['./assigned-trucks-table.component.scss']
})
export class AssignedTrucksTableComponent {
  permissions: any = this.rulesService.UserData[35].data;
  //Data 
  @Input() obj: any = {
    dataSource: [],
    employeesArray: [],
    accountingNamesById: {0: 'Not Assigned'}
  };

  truckSearch: string = ''; 
  trailerSearch: string = '';
  accountingEmployeeSearch: string = '';

  constructor(private accountingService: AccountingService,
              private rulesService: RulesService,
              private dialog: MatDialog) { }

  assignTruck(element: any, employeeObj: any) {
    if(this.permissions[0].sectionArray[0].allowed) {
      element.isOpenAssignTruckMenu = !element.isOpenAssignTruckMenu;
      let obj: ITruckAssignedTrucks = {
        id: element.id,
        unit_no: element.unit_no,
        trailer_no: element.trailer_no,
        employee: employeeObj.id
      }

      this.accountingService.assignTruck(obj).subscribe((response: any) => {
        if(response.id) {
          element.employee = response.employee;
          element.edit = false;
        }
      });
    }
    else {
      this.dialog.open(MsgForbbidenAccessComponent, {
        autoFocus: false,
        panelClass: 'forbidden-msg-dialog-container'
      })
    }
  };

  sortData(sort: Sort) {
    const data = JSON.parse(JSON.stringify(this.obj.dataSource));
    if (!sort.active || sort.direction === '') {
      this.obj.dataSource = data;
      return;
    }
    this.obj.dataSource = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'unit_no':
          return compare(a.unit_no, b.unit_no, isAsc);
        case 'trailer_no':
          return compare(a.trailer_no, b.trailer_no, isAsc);
        case 'employee':
          return compare(this.obj.accountingNamesById[a.employee], this.obj.accountingNamesById[b.employee], isAsc);
        default:
          return 0;
      }
    });
  };

}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
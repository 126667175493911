import { Component, OnDestroy, OnInit } from '@angular/core';
import moment = require('moment');
import { DriversProfileService } from '../../services/drivers-profile.service';
import { ActivatedRoute } from '@angular/router';
import { RulesService } from '@app/modules/shared/services/rules.service';
import { SharedService } from '@app/modules/shared/services/shared.service';
import { TransformService } from '@app/modules/shared/services/transform.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription, catchError, throwError } from 'rxjs';

@Component({
  selector: 'driver-statistics-cards',
  templateUrl: './driver-statistics-cards.component.html',
  styleUrls: ['./driver-statistics-cards.component.scss']
})
export class DriverStatisticsCardsComponent implements OnInit, OnDestroy {
  data: any;

  //Date
  dateObj: any = {
    startDate: moment().startOf('isoWeek').format('YYYY-MM-DD'),
    endDate: moment().endOf('isoWeek').format('YYYY-MM-DD')
  };

  //Calendar
  isOpenFromCalendar: boolean = false;
  isOpenToCalendar: boolean = false;

  //Active card
  selectedCardsObj: any = {
    grossSelected: false,
    milesSelected: false,
    rateSelected: false,
    loadsSelected: false
  };

  loaded: boolean = false;
  error: boolean = false;
  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";

  spinnerBgColor: string = this.transformService.spinnerBgColor;
  spinnerType: string = this.transformService.spinnerType;

  subscription: Subscription = new Subscription();

  constructor(private route: ActivatedRoute,
              private driversProfileService: DriversProfileService,
              public transformService: TransformService,
              private sharedService: SharedService,
              private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.getData();
  }

  //Calendar method
  dateClicked(event: any, fromCalendar: boolean) {
    if(fromCalendar) {
      this.dateObj.startDate = moment(event._d).format('YYYY-MM-DD');
      this.isOpenFromCalendar = false;
    }
    else {
      this.dateObj.endDate = moment(event._d).format('YYYY-MM-DD');
      this.isOpenToCalendar = false;
    }
  };

  //Get data
  run() {
    this.getData();
  };

  getData() {
    this.data = undefined;
    this.error = false;
    this.loaded = false;
    this.spinner.show('driver-statistics-cards');
    let id: string = this.route.snapshot.paramMap.get('id');
    this.subscription = this.driversProfileService.getStatisticsCardsData(this.dateObj.startDate, this.dateObj.endDate, +id)
    .pipe(catchError((err: any) => {
      this.spinner.hide('driver-statistics-cards');
      this.loaded = true;
      this.error = true;
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      this.data = response;
      this.spinner.hide('driver-statistics-cards');
      this.loaded = true;
    })
  };

  selectCard(key: string, resetLoads: boolean) {
    this.selectedCardsObj[key] = !this.selectedCardsObj[key];
    if(resetLoads) {
      this.selectedCardsObj.loadsSelected = false;
      this.driversProfileService.linechartSelectionChange.next(true);
    }
    else {
      this.selectedCardsObj.grossSelected = false;
      this.selectedCardsObj.milesSelected = false;
      this.selectedCardsObj.rateSelected = false;
    };
  };

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  };
  
}

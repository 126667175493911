import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Chart, registerables } from 'chart.js';
import { ApexAxisChartSeries, ApexChart, ApexLegend, ApexResponsive, ApexXAxis } from 'ng-apexcharts';
import { InspectionsService } from '../../services/inspections.service';
import { Subscription } from 'rxjs';

export type ChartOptions = {
  series: ApexAxisChartSeries | any;
  chart: ApexChart | any;
  xaxis: ApexXAxis | any;
  responsive: ApexResponsive | any;
  labels: string | any;
  colors: string | any;
  legend: ApexLegend | any;
  plotOptions: any;
  tooltip: any;
  dataLabels: any;
  stroke: any;
};

@Component({
  selector: 'app-inspection-column-chart',
  templateUrl: './inspection-column-chart.component.html',
  styleUrls: ['./inspection-column-chart.component.scss']
})
export class InspectionColumnChartComponent implements OnInit, OnDestroy {
  @Input() obj: any;

  data?: any[] = [];

  public chartOptions: Partial<ChartOptions> = {
    series: [],
    chart: {},
    xaxis: {},
    responsive: {},
    labels: {},
    colors: [],
    legend:{},
    plotOptions: {},
    tooltip: {},
    dataLabels: {},
    stroke: {}
  };

  colorPalette = ['#0f4bac', '#E91E63', '#9C27B0', '#673AB7', '#3F51B5', '#2196F3', '#03A9F4', '#00BCD4', '#009688', '#4CAF50', '#8BC34A', '#CDDC39', '#FFEB3B', '#FFC107', '#FF9800', '#FF5722'];

  subscription: Subscription = new Subscription();

  constructor(private service: InspectionsService) {
    Chart.register(...registerables)
  }

  ngOnInit() {
    this.subscription = this.service.data$.subscribe((response: any) => {
      this.data = response;
      this.columnChart(this.data)
    });
  };

  columnChart(obj: any) {
    this.chartOptions = {};
    const relatedCounts = {};

    obj.forEach(obj => {
      obj.related.forEach(related => {
        const violationName = related.related;
        relatedCounts[violationName] = (relatedCounts[violationName] || 0) + 1;
      });
    });

    const labels = Object.keys(relatedCounts);
    const values = labels.map(label => relatedCounts[label]);
    //Old code
    this.chartOptions = this.chartOptions = {
      series: [
        {
          name: "TOTAL",
          data: values.map((value, index) => ({
            x: labels[index],
            y: value,
            fillColor: this.colorPalette[index]
          }))
        }
      ],
      chart: {
        toolbar: {
          show: true,
          tools: {
            download: false
          }
        },
        type: "bar",
        height: 400,
        fontFamily: 'Poppins'
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "40%",
          endingShape: "rounded",
        }
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"]
      },
      xaxis: {
        categories: labels
      },
      colors: this.colorPalette,
      tooltip: {
        y: {
          formatter: (val: number) => {
            return val;
          }
        }
      }
    };

    /*Valid code
    this.chartOptions = {
      series: [
        {
          name: "TOTAL",
          data: values.map((value, index) => ({
            x: labels[index],
            y: value,
            fillColor: this.colorPalette[index]
          }))
        }
      ],
      chart: {
        toolbar: {
          show: true,
          tools: {
            download: false
          }
        },
        type: "bar",
        height: 400,
        fontFamily: 'Poppins'
      },
      plotOptions: {
        bar: {
          columnWidth: "40%",
          dataLabels: {
            position: "top"
          }
        }
      },
      dataLabels: {
        enabled: true,
        offsetY: -30,
        style: {
          fontSize: "14px",
          colors: ["#304758"]
        }
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"]
      },
      xaxis: {
        categories: labels
      },
      colors: this.colorPalette,
      tooltip: {
        y: {
          formatter: (val: number) => {
            return val;
          }
        }
      }
    };
    */
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../core/services/auth/auth.guard';
import { DispatchProfileComponent } from './components/dispatch-profile.component';
import { ScorePageComponent } from './score-page/score-page.component';

const routes: Routes = [
	{
		path: 'dispatchers',
	  canActivate: [AuthGuard],
        children: [
          {
            path: 'dashboard-dispatch-profile-page/:id',
            canActivate: [AuthGuard],
            component: DispatchProfileComponent,
            data: { roles: 15 }
          }
		]
	},
  {
		path: 'dispatchers',
        children: [
          {
            path: 'score',
            component: ScorePageComponent,
            data: { roles: 0 }
          }
		]
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class DispatcherProfilePageRoutingModule { }

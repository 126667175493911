import { TitleCasePipe } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'dropdown-multiple-select',
  templateUrl: './dropdown-multiple-select.component.html',
  styleUrls: ['./dropdown-multiple-select.component.scss']
})
export class DropdownMultipleSelectComponent {
  @Input() config: any = {
    array: [],
    defaultValue: '',
    selectAllValue: '',
    selectedValuesArray: [],
    keyToShow: '',
    keyToReturn: '',
    indexToReturn: false,
    search: false,
    titleCase: false
  };
  @Input() styleConfig: any = {
    'width': '240px',
    'height': '45px',
    'background-color': '#f4f8ff',
    'color': '#1F1F1F',
    'box-shadow': 'none',
    'border-radius': '25px',
    'border-top': 'thin solid #0000001f',
    'arrow-color': '#0856cd'
  };

  @Output() triggerEvent = new EventEmitter<string[] | number[]>();
  isOpenOneSelectDropdown: boolean = false;
  selectedValuesToReturnArray: any = [];
  dropdownSearch: string = '';

  constructor(private titleCase: TitleCasePipe) { }

  //Select all
  selectUnselectAll() {
    if(this.config.selectedValuesArray.length > 0) {
      this.config.selectedValuesArray = [];
      this.selectedValuesToReturnArray = [];
    }
    else {
      for(let i = 0; i < this.config.array.length; i++) {
        this.config.selectedValuesArray.push(this.config.keyToShow === '' ? this.config.array[i] : this.config.array[i][this.config.keyToShow]);
        this.selectedValuesToReturnArray.push(this.config.keyToReturn === '' ? this.config.array[i] : this.config.array[i][this.config.keyToReturn]);
      };
    }
    this.triggerEvent.emit(this.selectedValuesToReturnArray);
  };

  //Check uncheck value
  checkUncheckValue(obj: any) {
    if(this.config.selectedValuesArray.length === 0) {
      this.selectedValuesToReturnArray = [];
    };
    let index = this.config.selectedValuesArray.indexOf(obj[this.config.keyToShow]);
    if (index === -1) {
      this.config.selectedValuesArray.push(obj[this.config.keyToShow]);
      this.selectedValuesToReturnArray.push(obj[this.config.keyToReturn]);
    } 
    else {
      this.config.selectedValuesArray.splice(index, 1);
      this.selectedValuesToReturnArray.splice(index, 1);
    }
    this.triggerEvent.emit(this.selectedValuesToReturnArray);
  };

  get getSelectValue() {
    if(this.config.selectedValuesArray.length === 0) {
      return this.config.defaultValue;
    };
    if(this.config.titleCase) {
      return this.titleCase.transform(this.config.selectedValuesArray.join(', '));
    };
    return this.config.selectedValuesArray.join(', ');
  };

  getDropdownValue(element: any) {
    if(this.config.keyToShow === '') {
      return element;
    };
    return element[this.config.keyToShow];
  };

}

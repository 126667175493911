import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { LoadBoardService } from '@app/modules/load-board/services/load-board.service';
import { DeleteConfirmationDialogComponent } from '@app/modules/shared/components/delete-confirmation-dialog/delete-confirmation-dialog.component';
import moment = require('moment');

@Component({
  selector: 'app-profile-activity-dialog',
  templateUrl: './profile-activity-dialog.component.html',
  styleUrls: ['./profile-activity-dialog.component.scss']
})
export class ProfileActivityDialogComponent implements OnInit {
  loggedUser: any = JSON.parse(localStorage.getItem('currentUser'));

  //Dates
  datesArray: any[] = [];

  //Calendars
  calendarViewFrom: boolean = true;
  calendarViewTo: boolean = true;

  isOpenFromCalendar: boolean = false;
  fromDateValue: string = '';

  isOpenToCalendar: boolean = false;
  toDateValue: string = '';

  //Timepicker
  fromDateHour: any = '';
  fromDateMinutes: any = '';

  toDateHour: any = '';
  toDateMinutes: any = '';

  //Min date
  minDate = new Date();

  refreshData: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private dialog: MatDialog,
              private loadBoardService: LoadBoardService) { }

  ngOnInit(): void {
    if(this.data.datesArray[this.data.element.id]) {
      this.datesArray = this.data.datesArray[this.data.element.id];
    }
  }
  
  dateClicked(event: any, from: boolean) {
    if(from) {
      this.fromDateValue = moment(event._d).format('YYYY-MM-DDT00:00:00');
      this.isOpenFromCalendar = false;
    }
    else {
      this.toDateValue = moment(event._d).format('YYYY-MM-DDT00:00:00');
      this.isOpenToCalendar = false;
    };
  }

  //Forbidden dates
  isSelected = (event: any) => {
    if(this.datesArray.length > 0) {
      for(let i = 0; i < this.datesArray.length; i++) {
        if(this.datesArray[i].m_Item1 <= moment(event._d).format('YYYY-MM-DDT00:00:00')
        && this.datesArray[i].m_Item2 >= moment(event._d).format('YYYY-MM-DDT00:00:00')) {
          return 'selected';
        }
      }
    }
    return null;
  };

  //Time methods
  leadingZero(hour: boolean, fromDate: boolean) {
    if(hour) {
      if(fromDate) {
        this.fromDateHour = String(this.fromDateHour).padStart(2, '0');
      }
      else {
        this.toDateHour = String(this.toDateHour).padStart(2, '0');
      }
    }
    else {
      if(fromDate) {
        this.fromDateMinutes = String(this.fromDateMinutes).padStart(2, '0');
      }
      else {
        this.toDateMinutes = String(this.toDateMinutes).padStart(2, '0');
      }
    }
  }

  applyTime(fromDate: boolean) {
    if(fromDate) {
      if(isNaN(this.fromDateHour) || this.fromDateHour === '') {
        this.fromDateHour = '00';
      }
      
      if(isNaN(this.fromDateMinutes) || this.fromDateMinutes === '') {
        this.fromDateMinutes = '00';
      }
      let date: string = this.fromDateValue.split('T')[0];
      this.fromDateValue = `${date}T${this.fromDateHour}:${this.fromDateMinutes}:00`;
      this.isOpenFromCalendar = false;
    }
    else {
      if(isNaN(this.toDateHour) || this.toDateHour === '') {
        this.toDateHour = '00';
      }
      
      if(isNaN(this.toDateMinutes) || this.toDateMinutes === '') {
        this.toDateMinutes = '00';
      }
      let date: string = this.toDateValue.split('T')[0];
      this.toDateValue = `${date}T${this.toDateHour}:${this.toDateMinutes}:00`;
      this.isOpenToCalendar = false;
    }
  };

  setActiveProfile() {
    this.loadBoardService.setProfileActivity(this.data.element.id, this.fromDateValue, this.toDateValue, this.loadBoardService.creatorId)
    .subscribe((response: any) => {
      console.log(response);
      if(response) {
        let unsetDate: string = this.toDateValue === '' ? '1970-01-01T00:00:00' : this.toDateValue;
        this.datesArray.push({m_Item1: this.fromDateValue, m_Item2: unsetDate});
        this.fromDateValue = '';
        this.toDateValue = '';
        this.refreshData = true;
      }
    })
    
  };

  deleteRangeDate(obj: any, index: number) {
    let dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
      autoFocus: false,
      panelClass: 'delete-dialog-container',
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
        this.loadBoardService.deleteProfileActivity(this.data.element.id, obj.m_Item1, obj.m_Item2)
        .subscribe((response: boolean) => {
          if(response) {
            this.datesArray.splice(index, 1);
            this.refreshData = true;
          };
        });
      } 
    })
  };

  get isValidDate(): boolean {
    if(this.fromDateValue === '') {
      return true;
    }
    else if(this.toDateValue !== '') {
      return this.fromDateValue >= this.toDateValue;
    }
    else {
      return false;
    }
  }

}

import { Component, OnInit, ViewChild } from '@angular/core';
import { ApexAxisChartSeries, ApexChart, ApexDataLabels, ApexGrid, ApexStroke, ApexXAxis, ApexYAxis, ChartComponent } from 'ng-apexcharts';

export type ChartOptions = {
  series: ApexAxisChartSeries | any;
  chart: ApexChart | any;
  xaxis: ApexXAxis | any;
  yaxis: ApexYAxis | any;
  dataLabels: ApexDataLabels | any;
  grid: ApexGrid | any;
  stroke: ApexStroke | any;
  colors: any;
};

const ELEMENT_DATA: any[] = [
  {unit_no: '7999', driver: 'Frederick Robinson', station: 'Pilot Flying J', state: 'IN', city: 'Indianapolis',
  price: 3.95, gallons: 145, cost: 750, date: '04.17.2023'},
  {unit_no: '7999', driver: 'Frederick Robinson', station: 'Pilot Flying J', state: 'IN', city: 'Indianapolis',
  price: 3.95, gallons: 145, cost: 750, date: '04.17.2023'},
  {unit_no: '7999', driver: 'Frederick Robinson', station: 'Pilot Flying J', state: 'IN', city: 'Indianapolis',
  price: 3.95, gallons: 145, cost: 750, date: '04.17.2023'},
  {unit_no: '7999', driver: 'Frederick Robinson', station: 'Pilot Flying J', state: 'IN', city: 'Indianapolis',
  price: 3.95, gallons: 145, cost: 750, date: '04.17.2023'},
  {unit_no: '7999', driver: 'Frederick Robinson', station: 'Pilot Flying J', state: 'IN', city: 'Indianapolis',
  price: 3.95, gallons: 145, cost: 750, date: '04.17.2023'},
  {unit_no: '7999', driver: 'Frederick Robinson', station: 'Pilot Flying J', state: 'IN', city: 'Indianapolis',
  price: 3.95, gallons: 145, cost: 750, date: '04.17.2023'},
  {unit_no: '7999', driver: 'Frederick Robinson', station: 'Pilot Flying J', state: 'IN', city: 'Indianapolis',
  price: 3.95, gallons: 145, cost: 750, date: '04.17.2023'},
  {unit_no: '7999', driver: 'Frederick Robinson', station: 'Pilot Flying J', state: 'IN', city: 'Indianapolis',
  price: 3.95, gallons: 145, cost: 750, date: '04.17.2023'},
  {unit_no: '7999', driver: 'Frederick Robinson', station: 'Pilot Flying J', state: 'IN', city: 'Indianapolis',
  price: 3.95, gallons: 145, cost: 750, date: '04.17.2023'},
  {unit_no: '7999', driver: 'Frederick Robinson', station: 'Pilot Flying J', state: 'IN', city: 'Indianapolis',
  price: 3.95, gallons: 145, cost: 750, date: '04.17.2023'},
  {unit_no: '7999', driver: 'Frederick Robinson', station: 'Pilot Flying J', state: 'IN', city: 'Indianapolis',
  price: 3.95, gallons: 145, cost: 750, date: '04.17.2023'},
  {unit_no: '7999', driver: 'Frederick Robinson', station: 'Pilot Flying J', state: 'IN', city: 'Indianapolis',
  price: 3.95, gallons: 145, cost: 750, date: '04.17.2023'},
];

//Map
declare const google: any;
declare global {
  interface Window {
    initMap: () => void;
  }
}

@Component({
  selector: 'app-truck-profile-fuel',
  templateUrl: './truck-profile-fuel.component.html',
  styleUrls: ['./truck-profile-fuel.component.scss']
})
export class TruckProfileFuelComponent implements OnInit {
  //Graph
  @ViewChild("chart") chart: ChartComponent | any;
  public chartOptions: Partial<ChartOptions> | any;

  //Table data
  displayedColumns: string[] = ['position', 'unit_no', 'driver', 'station', 'state', 'city', 'price', 'gallons',
  'cost', 'date'];
  dataSource: any[] = ELEMENT_DATA;

  //Select graph
  isOpenGraphMenu: boolean = false;
  graphValue: string = 'By period';
  selectGraphArray: any[] = [
    {name: 'By odometer'},
    {name: 'By period'},
  ];

  //Select fueling
  isOpenFuelingMenu: boolean = false;
  fuelingValue: string = 'All fueling';

  selectFuelingArray: any[] = [
    {name: 'All fueling'},
    {name: 'All fueling'},
    {name: 'All fueling'},
    {name: 'All fueling'},
    {name: 'All fueling'},
  ];

  //Select drivers
  isOpenDriverMenu: boolean = false;
  driverValue: string = 'All drivers';

  selectDriversArray: any[] = [
    {name: 'All drivers'},
    {name: 'All drivers'},
    {name: 'All drivers'},
    {name: 'All drivers'},
    {name: 'All drivers'},
  ];

  //Map
  map: any = null;

  constructor() { }

  ngOnInit(): void {
    this.initGraph('');
    this.initMap()
  }

  //Graph method
  initGraph(data: any) {
    let seriesArray: any[] = [
      {name: 'Maintenance cost', data: [10, 20, 100, 30, 46, 70]},
    ];
    this.chartOptions = {
      series: seriesArray,
      chart: {
        toolbar: {
          show: true,
          tools: {
            download: false
          }
        },
        height: 220,
        type: "line",
        zoom: {
          enabled: false
        },
        fontFamily: 'Poppins'
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: "straight",
        width: 1,
      },
      grid: {
        row: {
          colors: ["transparent", "transparent"], 
          opacity: 0.5
        }
      },
      xaxis: {
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun']
      },
      yaxis: {
        tooltip: {
          enabled: true
        },
        labels: {
          formatter: (num: number) => {
            let roundedNumber: any = Math.round(num);
            return roundedNumber.toLocaleString("en-US").toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          },
        },
      },
      markers: {
        size: 5
      },
      colors: ['#005beb', '#FA9120', '#FA0000', '#008000', '#FF00FF', '#800000', '#FE7777', '#0303BB', '#008FA9', '#7550CB', '#6A6D6E'],
    };
  }

  //Map method
  async initMap() {
    const { Map } = await google.maps.importLibrary("maps");
    this.map = new Map(document.getElementById("map"), {
      center: { lat: -34.397, lng: 150.644 },
      zoom: 8,
      scrollwheel: true,
      mapTypeControl: true,
      draggable: true,
      scaleControl: true,
      navigationControl: true,
      disableDefaultUI: true,
      streetViewControl: false,
    });
  }

  //Menu select
  selectPeriod(obj: any, repair: boolean) {
    if(repair) {
      this.fuelingValue = obj.name;
      this.isOpenFuelingMenu = false;
    }
    else {
      this.driverValue = obj.name;
      this.isOpenDriverMenu = false;
    }
  };
  
}

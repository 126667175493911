import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-contract-confirmation-dialog',
  templateUrl: './delete-contract-confirmation-dialog.component.html',
  styleUrls: ['./delete-contract-confirmation-dialog.component.scss']
})
export class DeleteContractConfirmationDialogComponent {
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  public dialogRef: MatDialogRef<DeleteContractConfirmationDialogComponent>) {  }

  confirm() {
    this.dialogRef.close(true);
  }

}

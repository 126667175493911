import { HttpEventType } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DeleteConfirmationDialogComponent } from '@app/modules/shared/components/delete-confirmation-dialog/delete-confirmation-dialog.component';
import { TicketsWarningsReasonsDialogComponent } from '@app/modules/shared/components/tickets-warnings-reasons-dialog/tickets-warnings-reasons-dialog.component';
import { SharedService } from '@app/modules/shared/services/shared.service';
import moment = require('moment');
import { Subscription, catchError, throwError } from 'rxjs';
import { WarningsService } from '../../services/warnings.service';
import { ConnectionPositionPair } from '@angular/cdk/overlay';

@Component({
  selector: 'app-create-update-warning-dialog',
  templateUrl: './create-update-warning-dialog.component.html',
  styleUrls: ['./create-update-warning-dialog.component.scss']
})
export class CreateUpdateWarningDialogComponent implements OnInit, OnDestroy {
  //Driver
  autoCompleteData: any[] = [];
  isOpenDriverAutocomplete: boolean = false;
  driverSearch: string = '';
  driverId: number | undefined = undefined;
  isActiveDriver: boolean | undefined = undefined;

  filterActive: boolean = true;
  filterInactive: boolean = false;

  //Truck
  truckNo: string = '';
  truckId: number | undefined = undefined;

  //Initiator
  employeeArray: any[] = [];
  isOpenInitiatorMenu: boolean = false;
  initiator: string = '';
  initiatorId: any;
  initiatorDepartment: string = '';
  searchEmployee: string = '';

  //Initiate date
  isOpenInitiateCalendar: boolean = false;
  initiatingDate: string = '';

  //Sent by
  sentByArray: any[] = [];
  isOpenSentByMenu: boolean = false;
  sentBy: string = '';
  searchSentBy: string = '';

  //Method
  methodArray: any[] = [
    {name: 'Verbal'},
    {name: 'Written'}
  ];
  isOpenMethodMenu: boolean = false;
  methodValue: string = '';

  //Sent date
  isOpenSentDateCalendar: boolean = false;
  sentDate: string = '';

  //Phone call made
  phoneCallArray: any[] = [
    {name: 'Yes'},
    {name: 'No'}
  ];
  isOpenPhoneMenu: boolean = false;
  phoneCallMade: string = 'No';

  //Reasons
  reasonsArray: any[] = [
    {reasonValue: 'Category', isOpenReasonMenu: false, value: ''}
  ];
  
  reasonsObj: any;
  isOpenReasonMenu: boolean = false;

  reasonsArraySelectedValues: string[] = [];

  reasonsDataWithoutGroup: any[] = [];

  //Charge
  charge: number | undefined = undefined;

  //Write warning
  warningText: string = '';

  //Files
  files: any[] = [];

  //Data saved
  loading: boolean = false;
  error: boolean = false;

  //Message delete in progress
  msgDelete: boolean = false;
  msgDeleteError: boolean = false;

  //Multiple clicks
  multipleClicks: boolean = true;

  //Progress
  progressDone: number = 0;
  showProgress: boolean = false;

  //Positions
  public positions = [
    new ConnectionPositionPair(
      {originX: 'end', originY: 'bottom'},
      {overlayX: 'end', overlayY: 'top'},
      0, 5
    )
  ];

  //Subscription
  subscription1: Subscription = new Subscription();
  subscription2: Subscription = new Subscription();
  subscription3: Subscription = new Subscription();

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<any>,
              private dialog: MatDialog,
              private warningsService: WarningsService,
              private sharedService: SharedService) { }

  ngOnInit(): void {
    console.log(this.data.obj);
    if(this.data.editMode) {
      this.initData();
    }
    this.getAutocompleteData();
    this.getAllEmployees();
    this.getAllTicketsReasons();
  }

  initData() {
    let initObj: any = JSON.parse(JSON.stringify(this.data.obj));
    this.driverSearch = initObj.driver;
    this.truckNo = initObj.unit_no;
    this.truckId = initObj.trucks_id;
    this.driverId = initObj.driver_id;
    this.isActiveDriver = initObj.is_active;
    this.initiator = initObj.initiator;
    this.initiatorId = initObj.initiator_id;
    this.initiatorDepartment = initObj.department;
    this.initiatingDate = initObj.initiating_date;
    this.sentBy = initObj.sent_by;
    this.methodValue = initObj.method;
    this.sentDate = initObj.sent_date;
    this.phoneCallMade = initObj.phone_call_made ? 'Yes' : 'No';

    this.reasonsArraySelectedValues = initObj.reasons;
    let reasonsArray: any[] = [];
    for(let key in initObj.reasons) {
      reasonsArray.push({reasonValue: initObj.reasons[key], isOpenReasonMenu: false, value: initObj.reasons[key]})
    }
    this.reasonsArray = reasonsArray.length > 0 ? reasonsArray : this.reasonsArray;
    this.charge = initObj.charge;
    this.warningText = initObj.note;
    this.files = initObj.files;
  };

  getAutocompleteData() {
    this.subscription1 = this.sharedService.getAllDrivers()
    .subscribe((response: any) => {
      this.autoCompleteData = response;
    });
  };

  getAllEmployees() {
    this.subscription2 = this.sharedService.getAllEmails().subscribe((response: any) => {
      this.employeeArray = response;
      this.sentByArray = response;
    });
  };

  getAllTicketsReasons() {
    this.subscription3 = this.sharedService.getTwReasons().subscribe((response: any) => {
      this.reasonsDataWithoutGroup = response;
      this.reasonsObj = this.groupCategories(response)
    })
  };

  //Select driver autocomplete
  selectOption(obj: any) {
    this.driverSearch = obj.driver;
    this.truckNo = obj.unit_no;
    this.truckId = obj.truck_id;
    this.driverId = obj.driver_id;
    this.isActiveDriver = obj.is_active;
    this.isOpenDriverAutocomplete = false;
  }

  //Select employee
  selectEmployee(obj: any) {
    this.initiator = obj.nickname; 
    this.initiatorId = obj.settings_id;
    this.initiatorDepartment = obj.department;
    this.isOpenInitiatorMenu = !this.isOpenInitiatorMenu;
  };

  //Select date
  dateClicked(event: any, isInitiateDate: boolean) {
    if(isInitiateDate) {
      this.initiatingDate = moment(event._d).format('YYYY-MM-DDT00:00:00');
      this.isOpenInitiateCalendar = false;
    }
    else {
      this.sentDate = moment(event._d).format('YYYY-MM-DDT00:00:00');
      this.isOpenSentDateCalendar = false;
    }
  };

  groupCategories(array: any[]) {
    const groups: any = array.reduce((acc: any, o: any) => {

      o['category'] = o['category'];
    
      if (!acc[o['category']]) {
        acc[o['category']] = [];
      }
          
      acc[o['category']].push(o);
  
      return acc;
  
    }, {});

    return groups;
  };

  //Select reasons
  selectReason(array: any[], index: number, obj: any) {
    obj.isOpenReasonMenu = !obj.isOpenReasonMenu; 
    obj.reasonValue = array[index].subcategory; 
    obj.value = array[index].subcategory;
    this.reasonsArraySelectedValues.push(array[index].subcategory);
  }

  addSelect() {
    this.reasonsArray.push({reasonValue: 'Category', isOpenReasonMenu: false, value: ''});
  };

  //Remove select
  removeSelect(index: number) {
    this.reasonsArray.splice(index, 1);
    this.reasonsArraySelectedValues.splice(index, 1);
  }

  //Open reasons dialog
  openReasonsDialog() {
    let dialogRef: any = this.dialog.open(TicketsWarningsReasonsDialogComponent, {
      autoFocus: false,
      panelClass: 'reasons-dialog-main-container',
      data: this.reasonsDataWithoutGroup
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      this.getAllTicketsReasons();
    });
  }

  //Add files
  onFileSelected(event: any) {
    let length: any = Object.keys(event.target.files).length;
    for (let i = 0; i < length; i++) {
      this.addMultipleFiles(event, i);
    }
  }

  addMultipleFiles(event: any, index: number) {
    let fileName = event.target.files[index].name;
    let reader = new FileReader();
    reader.onload = (e: any) => {
      let base64 = e.target.result.split(',')[1];
      let obj: any = {
        filename: fileName,
        data: base64
      };
      this.files.push(obj);
    };

    reader.readAsDataURL(event.target.files[index]);
  };

  //Discard files
  discard(i : number) {
    this.files.splice(i, 1);
  };

  get validateInputs() {
    let disableBtn: boolean = true;
    if(this.driverSearch && this.truckNo && this.initiator && this.initiatingDate &&  
    this.sentBy && this.methodValue && this.sentDate && this.phoneCallMade && 
    this.reasonsArraySelectedValues.length > 0 && typeof this.charge === 'number' && this.warningText 
    && this.files.length > 0) {
      disableBtn = false;
    };
    return disableBtn;
  };

  //Create update warning
  createUpdateWarning() {
    if(this.multipleClicks) {
      this.loading = true;
      this.error = false;
      let loggedUser: any = JSON.parse(localStorage.getItem('currentUser'));
      let obj: any = {
        id: 0,
        unit_no: this.truckNo,
        trucks_id: this.truckId,
        driver: this.driverSearch,
        driver_id: this.driverId,
        is_active: this.isActiveDriver,
        initiator: this.initiator,
        initiator_id: this.initiatorId,
        department: this.initiatorDepartment,
        initiating_date: this.initiatingDate,
        sent_by: this.sentBy,
        sent_date: this.sentDate,
        method: this.methodValue,
        phone_call_made: this.phoneCallMade === 'Yes' ? true : false,
        note: this.warningText,
        reasons: this.reasonsArraySelectedValues,
        charge: this.charge,
        files: this.files,
        created: moment().format('YYYY-MM-DDTHH:mm:ss')
      };

      if(this.data.editMode) {
        obj.id = this.data.obj.id;
        obj.created = this.data.obj.created;
        obj.edited_by = loggedUser.first_name + ' ' + loggedUser.last_name;
        obj.edited_date = moment().format('YYYY-MM-DDTHH:mm:ss');
      }
      console.log(obj);
      this.warningsService.addUpdateWarning(obj)
      .pipe(catchError((err: any) => {
        this.loading = false;
        this.showProgress = false;
        this.error = true;
        return throwError(() => err);
      }))
      .subscribe((httpResponse: any) => {
        this.showProgress = true;
        if (httpResponse.type === HttpEventType.UploadProgress) {
          this.progressDone = Math.round(100 * httpResponse.loaded / httpResponse.total);
        } 

        if (httpResponse.type === HttpEventType.Response) {
          this.showProgress = false;
          this.progressDone = 0;
          if(httpResponse.body) {
            this.multipleClicks = false;
            this.dialogRef.close(true);
          }
          else {
            this.error = true;
          }
          this.loading = false;
        }
      });
    }
  };

  //Delete warning
  deleteWarning() {
    let dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
      autoFocus: false,
      panelClass: 'delete-dialog-container',
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
        let loggedUser: any = JSON.parse(localStorage.getItem('currentUser'));
        let fullName: string = loggedUser.first_name + ' ' + loggedUser.last_name;
        this.warningsService.deleteWarning(this.data.obj.id, fullName, moment().format('YYYY-MM-DDTHH:mm:ss'))
        .subscribe((response: any) => {
          if(response) {
            this.dialogRef.close(true);
          };
        });
      }
    });
  };

  getBtnName() {
    if(this.loading) {
      return 'Loading...' 
    }
    else if(this.data.editMode) {
      return 'Update warning';
    }
    else {
      return 'Create warning';
    }
  };


  openFileInNewTab(obj: any) {
    this.sharedService.downloadPreviewFile(obj.filename, obj.data);
  };

  downloadFile(obj: any) {
    obj.isOpenFileMenu = !obj.isOpenFileMenu;
    let source: string = `data:application/octet-stream;base64,${obj.data}`;
    const downloadLink = document.createElement('a');
    const fileName = obj.filename;
    downloadLink.href = source;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  deleteFile(obj: any, index: number) {
    obj.isOpenFileMenu = !obj.isOpenFileMenu;
    let dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
      autoFocus: false,
      panelClass: 'delete-dialog-container',
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
        this.files.splice(index, 1);
      }
    })
  };

  ngOnDestroy(): void {
    this.subscription1?.unsubscribe();
    this.subscription2?.unsubscribe();
    this.subscription3?.unsubscribe();
  };

}

import { Injectable } from '@angular/core';
import * as _G from '../../../_config/globals';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { IChat } from '../models/chat.model';

@Injectable()
export class ChatService {
  headers = new HttpHeaders({
		'Content-Type': 'application/json',
		'Authorization': 'Basic SVZTb2x1dGlvbjoxUzBsdXQxMG4yQDIy'
	});
	requestOptions = { headers: this.headers };

  constructor( private http: HttpClient ) {}

  getAllDriversContact() {
    return this.http.get(`${_G.extendApiUrl}routingapp/getAllDriversForChatGroupDropdown`, this.requestOptions);
  };

  createChat(driverId: string, userId: number) {
    return this.http.get(`${_G.extendApiUrl}routingapp/createChatGroupByDispatcher?driverID=${driverId}&userID=${userId}`, this.requestOptions);
  };

  getAllChatsByDispatcher(dispatcherId: number, groupId: number | null) {
    return this.http.get(`${_G.extendApiUrl}routingapp/getChatGroupsUser?dispatcherID=${dispatcherId}${groupId ? `&groupID=${groupId}` : ''}`, this.requestOptions);
  };

  getAllChatsByDriver(driverId: number, groupId: string) {
    return this.http.get(`${_G.extendApiUrl}routingapp/getChatGroups?driverID=${driverId}&groupID=${groupId}`, this.requestOptions);
  };

  sendMessage(obj: IChat, driverId: string, dispatcherId: number) {
    let json: string = JSON.stringify(obj);
    console.log(json)
    return this.http.post(`${_G.extendApiUrl}routingapp/sendChatMessageFromDispatcher?driverID=${driverId}&dispatcherID=${dispatcherId}`, json, this.requestOptions);
  };

}

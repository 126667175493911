import { Injectable } from '@angular/core';
import * as _G from '../../../_config/globals';
import { HttpHeaders, HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LoadService {
  headers = new HttpHeaders({
		'Content-Type': 'application/json',
		'Authorization': 'Basic SVZTb2x1dGlvbjoxUzBsdXQxMG4yQDIy'
	});
	requestOptions = { headers: this.headers };

  constructor(private http: HttpClient) { }

  getAllLoads() {
    return this.http.get(`${_G.extendApiUrl}loads/activeloads`, this.requestOptions);
  };

  getLoadInfoData(loadNo: number) {
    return this.http.get(`${_G.extendApiUrl}routingapp/getLoadsLoadInfo?load_no=${loadNo}`, this.requestOptions);
  };

  getFilesData(loadNo: number) {
    return this.http.get(`${_G.extendApiUrl}routingapp/getLoadsFiles?load_no=${loadNo}`, this.requestOptions);
  };

  getRequestData(loadNo: number) {
    return this.http.get(`${_G.extendApiUrl}routingapp/getLoadsRequests?load_no=${loadNo}`, this.requestOptions);
  };

  notifyDriver(fileId: number, obj: any) {
    let json: any = JSON.stringify(obj);
    return this.http.post(`${_G.extendApiUrl}routingapp/notifyDriverLoadsFiles?fileID=${fileId}`, json, this.requestOptions);
  };

  completeRequest(requestId: number, obj: any) {
    let json: any = JSON.stringify(obj);
    return this.http.post(`${_G.extendApiUrl}routingapp/completeDriverLoadsRequests?requestID=${requestId}`, json, this.requestOptions);
  };

  changeFileOrder(array: number[]) {
    let json: any = JSON.stringify(array);
    return this.http.post(`${_G.extendApiUrl}routingapp/updateFilePageIndexes`, json, this.requestOptions);
  };

}

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { EmployeeService } from '@app/modules/employee/services/employee.service';
import { DeleteConfirmationDialogComponent } from '@app/modules/shared/components/delete-confirmation-dialog/delete-confirmation-dialog.component';

import moment = require('moment');
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-employee-cities',
  templateUrl: './employee-cities.component.html',
  styleUrls: ['./employee-cities.component.scss']
})
export class EmployeeCitiesComponent implements OnInit {
  //Office
  city: string = '';

  //Obj edited
  objEdit: any;

  //Table data
  citiesArray: any[] = [];
  displayedColumns: string[] = ['name', 'created_by', 'created_date', 'edited_by', 'edited_date', 'action'];

  //Show create update menu
  showTable: boolean = true;
  editMode: boolean = false;

  subscription: Subscription | any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<any>,
              private dialog: MatDialog,
              private employeeService: EmployeeService) { }

  ngOnInit(): void {
    this.citiesArray = this.data;
    
    this.dialogRef.backdropClick().subscribe(_ => {
        this.dialogRef.close(this.citiesArray);
    })
  }

  getTableData() {
    this.subscription = this.employeeService.getAllEmployeeCities()
    .subscribe((response: any) => {
      this.citiesArray = response;
      console.log(response);
    });
  }

  createEditCity(editMode: boolean, obj: any) {
    this.resetInputs();
    this.showTable = false;
    this.editMode = editMode;
    if(this.editMode) {
      this.city = obj.name;
      this.objEdit = obj;
    }
  }

  backBtn() {
    this.resetInputs();
    this.showTable = true;
    this.editMode = false;
  }

  saveUpdateData() {
    let loggedUser: any = JSON.parse(localStorage.getItem('currentUser'));
    let obj: any = {
      id: 0,
      name: this.city,
      created_by: loggedUser.first_name + ' ' + loggedUser.last_name,
      created_date: moment().format('YYYY-MM-DDTHH:mm:ss'),
      edited_by: '',
      edited_date: ''
    }
    if(this.editMode) {
      obj.id = this.objEdit.id;
      obj.created_by = this.objEdit.created_by;
      obj.created_date = this.objEdit.created_date;
      obj.edited_by = loggedUser.first_name + ' ' + loggedUser.last_name,
      obj.edited_date = moment().format('YYYY-MM-DDTHH:mm:ss')
    }

    this.employeeService.addUpdateEmployeeCity(obj).subscribe((response: any) => {
      if(response) {
        this.getTableData();
        this.resetInputs();
        this.showTable = true;
        this.editMode = false;
      }
    });
  };

  deleteCity(id: number) {
    let dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
      autoFocus: false,
      panelClass: 'delete-dialog-container',
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
        this.employeeService.deleteEmployeeCity(id).subscribe((response: any) => {
          if(response) {
            this.getTableData();
          }
        });
      }
    })
  }

  sortData(sort: Sort) {
    const data = this.citiesArray.slice();
    if (!sort.active || sort.direction === '') {
      this.citiesArray = data;
      return;
    }
    this.citiesArray = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'name':
          return compare(a.name, b.name, isAsc);
        case 'created_by':
          return compare(a.created_by, b.created_by, isAsc);
        case 'created_date':
          return compare(new Date(a.created_date), new Date(b.created_date), isAsc);
        case 'edited_by':
          return compare(a.edited_by, b.edited_by, isAsc);
        case 'edited_date':
          return compare(new Date(a.edited_date), new Date(b.edited_date), isAsc);
        default:
          return 0;
      }
    });
  }

  resetInputs() {
    this.city = '';
    this.objEdit = undefined;
  }

}

function compare(a: number | any, b: number | any, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}


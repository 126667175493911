import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';

import { FormControl } from '@angular/forms';
import { Observable, Subscription, map, startWith, tap } from 'rxjs';
import moment = require('moment');
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AddNewViolationDialogComponent } from '../add-new-violation-dialog/add-new-violation-dialog.component';
import { DeleteConfirmationDialogComponent } from '@app/modules/shared/components/delete-confirmation-dialog/delete-confirmation-dialog.component';
import { DotRelated, DotViolation, Inspection, Level, Outcome, Related, Status } from '../../models/inspection.model';
import { RulesService } from '@app/modules/shared/services/rules.service';
import { TransformService } from '@app/modules/shared/services/transform.service';
import { InspectionsService } from '../../services/inspections.service';
import { SharedService } from '@app/modules/shared/services/shared.service';

@Component({
  selector: 'app-inspection-details',
  templateUrl: './inspection-details.component.html',
  styleUrls: ['./inspection-details.component.scss']
})
export class InspectionDetailsComponent implements OnInit, OnDestroy {
  permissions: any = this.rulesService.UserData[23].data;
  private subscriptions: Subscription = new Subscription();

  //Location autocomplete
  @Input() adressType: string = '(cities)';
  @Output() setAddress: EventEmitter<any> = new EventEmitter();
  @ViewChild('location') location: any;

  outcomes = Object.values(Outcome);
  levels = Object.values(Level);
  relatedTo = Object.values(Related);
  statuses = Status;

  //Dot violation, Dot related
  violation: any;
  violations: any;
  related: any;

  //Violation search
  violationSearch: string = '';

  //Truck option list
  myControl: FormControl = new FormControl();
  options: any;
  filteredOptions?: Observable<any[]>;

  //Time
  isOpenTimeMenu: boolean = false;
  selectedTime: string = '';
  hour: any;
  minutes: any;

  // Add/edit item
  editItem: Inspection = <Inspection>{};
  editMode = false;
  editButtonActive = false;

  // Location
  locationInput: string = '';
  locationArray: any[] = [];
  isOpenLocationMenu: boolean = false;

  loggedUser: any;

  // Save
  saveSuccess: boolean | null = null;;
  saveError: string = 'An error occurred while saving the data.';

  //Multiple clicks
  multipleClicks: boolean = true;

  constructor(public service: InspectionsService,
    private transformService: TransformService,
    public dialogRef: MatDialogRef<InspectionDetailsComponent>,
    private dialog: MatDialog,
    private rulesService: RulesService,
    private sharedService: SharedService,
    @Inject(MAT_DIALOG_DATA) public data?: any) {
  }

  ngOnInit() {
    console.log(this.data);
    this.loggedUser = this.getCurrentUser();
    this.getTrucks();
    this.getAllLocations();
    this.initiateDotViolation();
    this.initiateDotRelated();
    this.initializeEditItem();
    this.service.getViolations().subscribe(data => {
      this.violations = data;
    });
    this.editMode = this.data.editMode;
    if (this.editItem.id && this.permissions[0].sectionArray[0].allowed) {
      this.editButtonActive = true;
    }
  }
  
  getAllLocations() {
    this.sharedService.getAllLocationInfo().subscribe((response: any) => {
      this.locationArray = response;
    });
  }

  setLocation(obj: any) {
    this.editItem.city = obj.city;
    this.editItem.state = obj.state_long;
    this.locationInput = `${obj.city}, ${obj.state}, USA`;
    this.isOpenLocationMenu = false;
  }

  get Outcome() {
    return Outcome;
  }

  initializeEditItem() {
    if(this.data && this.data.inspectionData) {
      this.editItem = this.data.inspectionData;
      this.violation = this.editItem.violation ? JSON.parse(JSON.stringify(this.editItem.violation)) : this.initiateDotViolation();
      this.related = this.editItem.related ? JSON.parse(JSON.stringify(this.editItem.related)) : this.initiateDotRelated();
      this.locationInput = this.editItem.city && this.editItem.state
        ? `${this.editItem.city}, ${this.editItem.state}`
        : this.editItem.city || this.editItem.state
          ? this.editItem.city || this.editItem.state
          : '';
      this.selectedTime = this.editItem.date ? this.convertDateToTime(this.editItem.date) : '';
    } else {
      this.related = this.initiateDotRelated();
      this.violation = this.initiateDotViolation();
      this.editItem = {
        id: 0,
        driver_name: '',
        driver_id: null,
        dispatcher: '',
        dispatcher_id: null,
        truck_no: '',
        trailer_no: '',
        division: '',
        division_id: null,
        report_no: '',
        inspection_level: 'Unknown',
        state: '',
        city: '',
        zip_code: '',
        note: '',
        inspection_result: 'Unknown',
        out_of_service: false,
        points: null,
        amount: null,
        rewards: null,
        creator: this.loggedUser.username,
        editor: '',
        datecreated: this.transformService.convertDateToTimestamp(moment(new Date()).format('ddd, DD/MM YYYY'), moment(new Date()).format('HH:mm:ss')),
        violation: this.initiateDotViolation(),
        related: this.initiateDotRelated(),
        files: [],
        status: '',
        original: false,
        sent: false
      }
    }
  }

  //Get trucks
  getTrucks() {
    const getTrucksSubscription = this.service.getTrucks().subscribe(data => {
      this.options = data;

      this.options.sort((a, b) => {
        const valueA = a.unit_no;
        const valueB = b.unit_no;

        if (valueA === undefined || valueB === undefined) return 0;
        if (valueA === undefined) return 1;
        if (valueB === undefined) return -1;

        return valueA.localeCompare(valueB);
      })

      if (this.options) {
        if (this.editItem.truck_no) {
          this.myControl.setValue(this.editItem.truck_no);
        }

        this.filteredOptions = this.myControl.valueChanges.pipe(
          startWith(''),
          map((value: any) => {
            const name = typeof value === 'string' ? value : value?.unit_no;
            return name ? this._filter(name as string) : this.options.slice();
          }),
        );
      }

    });

    this.subscriptions.add(getTrucksSubscription);
  }

  // Dot violation methods
  selectViolation(violation: any, i: number) {
    this.violation[i].violation = violation.violation;
    if (this.violation[i].id !== 0) {
      this.violation[i].edited_date = this.transformService.convertDateToTimestamp(moment(new Date()).format('ddd, DD/MM YYYY'), moment(new Date()).format('HH:mm:ss'));
      this.violation[i].edotor = this.loggedUser.username;
    }
  }

  addNewViolation() {
    let violation: DotViolation = {
      id: 0,
      dot_id: 0,
      violation: '',
      creator: this.loggedUser.username,
      created_date: this.transformService.convertDateToTimestamp(moment(new Date()).format('ddd, DD/MM YYYY'), moment(new Date()).format('HH:mm:ss')),
      edotor: '',
      edited_date: null
    }
    this.violation.push(violation)
  }

  removeViolation(index: number, id: number) {
    this.violation.splice(index, 1);
    if (id !== 0) {
      let deleteViolationSubscription = this.service.deleteViolation(id).pipe(
        tap((response: any) => {
          if (response) {
            this.editItem.violation = this.editItem.violation.filter(item => item.id !== id)
          }
        })
      ).subscribe();
      this.subscriptions.add(deleteViolationSubscription);
    }
  }

  initiateDotViolation() {
    return [
      {
        id: 0,
        dot_id: 0,
        violation: '',
        creator: this.loggedUser.username,
        created_date: this.transformService.convertDateToTimestamp(moment(new Date()).format('ddd, DD/MM YYYY'), moment(new Date()).format('HH:mm:ss')),
        edotor: '',
        edited_date: null
      }
    ]
  }

  // Dot related methods
  selectRelated(related: any, i: number) {
    this.related[i].related = related;
    if (this.related[i].id !== 0) {
      this.related[i].edited_date = this.transformService.convertDateToTimestamp(moment(new Date()).format('ddd, DD/MM YYYY'), moment(new Date()).format('HH:mm:ss'));
      this.related[i].editor = this.loggedUser.username;
    }
  }

  addRelated() {
    let related: DotRelated = {
      id: 0,
      dot_id: 0,
      related: '',
      creator: this.loggedUser.username,
      created_date: this.transformService.convertDateToTimestamp(moment(new Date()).format('ddd, DD/MM YYYY'), moment(new Date()).format('HH:mm:ss')),
      edotor: '',
      edited_date: null
    }
    this.related.push(related);
  }

  removeRelated(index: number, id: number) {
    this.related.splice(index, 1);
    if (id !== 0) {
      let deleteRelatedSubscription = this.service.deleteRelated(id).pipe(
        tap((response: any) => {
          if (response) {
            this.editItem.related = this.editItem.related.filter(item => item.id !== id)
          }
        })
      ).subscribe();
      this.subscriptions.add(deleteRelatedSubscription);
    }
  }

  initiateDotRelated(): DotRelated[] {
    return [
      {
        id: 0,
        dot_id: 0,
        related: '',
        creator: this.loggedUser.username,
        created_date: this.transformService.convertDateToTimestamp(moment(new Date()).format('ddd, DD/MM YYYY'), moment(new Date()).format('HH:mm:ss')),
        edotor: '',
        edited_date: null
      }
    ]
  }

  // User/EditMode methods
  allowEditItem() {
    if (this.editItem.id && this.permissions[0].sectionArray[0].allowed) {
      this.editMode = true;
    }
  }

  private getCurrentUser(): any {
    return JSON.parse(localStorage.getItem('currentUser')) || {};
  }

  //Time methods
  applyTime() {
    this.selectedTime = `${this.hour || '00'}:${this.minutes || '00'}`;
    this.isOpenTimeMenu = false;
  };

  private concatenateDateTime(inputDate: any, inputTime?: string) {
    let date = '';

    if (!inputDate) {
      date = this.transformService.convertDateToTimestamp(moment(new Date()).format('ddd, DD/MM YYYY'), moment(new Date()).format('HH:mm:ss'));
    } else {
      date = this.transformService.convertDateToTimestamp(moment(inputDate).format('ddd, DD/MM YYYY'), '00:00:00');
    }

    if (inputDate && inputTime) {
      let dateTime = this.transformService.convertDateToTimestamp(moment(date).format('ddd, DD/MM YYYY'), inputTime + ':00');
      return dateTime;
    }

    return date;
  }

  private convertDateToTime(inputDate: any) {
    let date = new Date(inputDate);
    this.hour = this.addLeadingZero(date.getHours());
    this.minutes = this.addLeadingZero(date.getMinutes());
    return `${this.hour}:${this.minutes}`;
  }

  addLeadingZero(timeUnit: number): string {
    return timeUnit < 10 ? `0${timeUnit}` : String(timeUnit);
  }

  onKeyUp(event: any, hour: boolean) {
    let enteredNum: any = +event.target.value;
    if (hour && (enteredNum > 23 || enteredNum < 0 || event.target.value.length > 2)) {
      this.hour = '00';
    }

    if (!hour && (enteredNum > 59 || enteredNum < 0 || event.target.value.length > 2)) {
      this.minutes = '00';
    }
  }

  leadingZero(hour: boolean) {
    if (hour && this.hour) {
      let hourString: string = this.hour.toString();
      this.hour = (hourString.length === 1) ? ("0" + this.hour) : this.hour;
    }

    if (!hour && this.minutes) {
      let minutesString: string = this.minutes.toString();
      this.minutes = (minutesString.length === 1) ? ("0" + this.minutes) : this.minutes;
    }
  }

  // Upload file methods
  onFileSelected(event: any) {
    let length: any = Object.keys(event.target.files).length;
    for (let i = 0; i < length; i++) {
      this.addMultipleFiles(event, i);
    }
  }

  addMultipleFiles(event: any, index: number) {
    let fileName = event.target.files[index].name;
    let reader = new FileReader();
    reader.onload = (e: any) => {
      let base64 = e.target.result.split(',')[1];
      let obj: any = {
        filename: fileName,
        data: base64
      };
      this.editItem.files.push(obj);
    };

    reader.readAsDataURL(event.target.files[index]);
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
  }

  // File remove method
  removeFile(i: number) {
    this.editItem.files.splice(i, 1);
  }

  // Download file methods
  downloadFile(file: any): void {
    if (!file || !file.data) {
      return;
    }

    const uint8Array = this.convertToUint8Array(file.data);
    const blob = new Blob([uint8Array], { type: `image/${file.ext}` });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = file.filename;
    link.click();
    URL.revokeObjectURL(url);
  }

  downloadAllFiles(fileList: any[]): void {
    if (!fileList || fileList.length === 0) {
      return;
    }

    fileList.forEach((file) => {
      this.downloadFile(file);
    });
  }

  private convertToUint8Array(data: any): Uint8Array {
    const uint8Array = new Uint8Array(Object.values(data));
    return uint8Array;
  }

  // Preview files
  previewFile(obj: any) {
    obj.pdfLoading = true;
    let extension = obj.ext;
    let base64 = obj.data;

    if (extension === 'pdf') {
      const anchor = document.createElement('a');
      document.body.appendChild(anchor);
      anchor.style.display = 'none';
      const byteCharacters = atob(base64);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      anchor.href = url;
      anchor.target = '_blank';
      anchor.click();
      window.URL.revokeObjectURL(url);
    }
    else {
      let image = document.createElement('img');
      image.src = `data:image/${extension};base64,` + base64;
      let w = window.open("");
      w.document.write(image.outerHTML);
    }
    obj.pdfLoading = false;
  }

  // Truck options methods
  displayFn(option: any): string {
    return option && option.unit_no ? option.unit_no : '';
  }

  private _filter(name: string): any[] {
    const filterValue = name.toLowerCase();
    return this.options.filter((option: { unit_no: string; }) => option.unit_no.toLowerCase().includes(filterValue));
  }

  onOptionSelected(option: any) {
    const {
      unit_no,
      driver_id,
      driver,
      trailer,
      dispatcher_id,
      dispatcher,
      division_id
    } = option;
 
    this.myControl.setValue(unit_no);
    this.editItem = {
      ...this.editItem,
      truck_no: unit_no,
      driver_id: driver_id,
      driver_name: driver,
      trailer_no: trailer,
      dispatcher_id: dispatcher_id,
      dispatcher: dispatcher,
      division_id: division_id
    };
  }

  // Save Inspection method
  saveInspection() {
    this.editItem.date = this.concatenateDateTime(this.editItem.date, this.selectedTime);

    if (this.editItem.id !== 0) {
      this.editItem.edited_date = this.transformService.convertDateToTimestamp(moment(new Date()).format('ddd, DD/MM YYYY'), moment(new Date()).format('HH:mm:ss'))
      this.editItem.editor = this.loggedUser.username;
    }

    if(((!this.locationInput.includes(this.editItem.city) && this.editItem.city.length > 0) || 
      !this.locationInput.includes(this.editItem.state) && this.editItem.state.length > 0)) {
      this.editItem.city = '';
      this.editItem.state = '';
    };

    if(this.editItem.city === '' ||  this.editItem.state === '') {
      let splitedValue: string[] = this.locationInput.split(', ');
      this.editItem.city = splitedValue[0];
      if(splitedValue.length > 1) {
        this.editItem.state = splitedValue[1];
      }
    };

    // Violations
    let editItemViolation = JSON.parse(JSON.stringify(this.editItem.violation));
    let filteredViolation = this.violation.filter((item) => item.violation !== '');
    this.editItem.violation = filteredViolation.length ? filteredViolation : [];

    // Related
    let editItemRelated = JSON.parse(JSON.stringify(this.editItem.related));
    let filteredRelated = this.related.filter((item) => item.related !== '');
    this.editItem.related = filteredRelated.length ? filteredRelated : [];
    console.log(this.editItem)
    if(this.multipleClicks) {
      this.multipleClicks = false;
      this.service.saveInspection(this.editItem).subscribe((response: any) => {
        this.saveSuccess = response;
        if (this.saveSuccess) {
          this.service.refresh$.next(true);
          this.selectedTime = '';
          this.dialogRef.close(true);
        } else {
          this.saveSuccess = false;
          this.editItem.related = editItemRelated;
          this.editItem.violation = editItemViolation;
          this.multipleClicks = true;
        }
      });
    }
  }

  createNewViolation(event: any) {
    event.stopPropagation();
    let dialogRef = this.dialog.open(AddNewViolationDialogComponent, {
      autoFocus: false,
      panelClass: 'add-new-violation-dialog'
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
        this.service.getViolations().subscribe(data => {
          this.violations = data;
        });
      }
    });
  }

  deleteViolation(event: any, section: string) {
    event.stopPropagation();
    let dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
      autoFocus: false,
      panelClass: 'delete-dialog-container',
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
        this.service.deleteViolationFromList(section)
        .subscribe((response: any) => {
          if(response) {
            this.service.getViolations().subscribe(data => {
              this.violations = data;
            });
          }
        })
      }
    });
  }

  deleteDotInspection() {
    if(this.permissions[0].sectionArray[1].allowed) {
      let dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
        autoFocus: false,
        panelClass: 'delete-dialog-container',
      });
      dialogRef.afterClosed().subscribe((result: any) => {
        if(result) {
          this.service.deleteDotInspection(this.data.inspectionData.id)
          .subscribe((response: any) => {
            console.log(response);
            if(response) {
              this.service.refresh$.next(true);
              this.dialogRef.close(true);
            }
          })
        }
      })
    }
  };

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}





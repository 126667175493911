import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'division'
})
export class DivisionPipe implements PipeTransform {

  transform(array: any[], division_id: number) {
    if (!array) {
      return [];
    }
    
    return array.filter((obj: any) => {
   
      if((division_id === -1) || obj.division_id === division_id) {
        return obj;
      }
   
    });
  }

}

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { TransformService } from '@app/modules/shared/services/transform.service';
import * as XLSX from 'xlsx'; 

@Component({
  selector: 'app-breakdown-by-group-trucks-dialog',
  templateUrl: './breakdown-by-group-trucks-dialog.component.html',
  styleUrls: ['./breakdown-by-group-trucks-dialog.component.scss']
})
export class BreakdownByGroupTrucksDialogComponent implements OnInit{
  displayedColumns: string[] = ['position', 'unit_no', 'model', 'year', 'odometers', 'status', 'group', 'maintenance_cost',
  'cost_per_mile'];
  dataSource: any[] = [];
  fileName: string = 'trucks-table.xlsx';

  partsGroup: any[] = [
    {group: 'DRIVERTRAIN', selected: false, key: 't1_list'},
    {group: 'ENGINE BAY', selected: false, key: 't2_list'},
    {group: 'ELECTRICAL', selected: false, key: 't3_list'},
    {group: 'BODYWORK', selected: false, key: 't4_list'},
    {group: 'MISCELLANEOUS', selected: false, key: 't5_list'},
    {group: 'PREVENTIVE MAINT.', selected: false, key: 't6_list'}
  ];

  avgObj: any = {
    odometers: 0,
    maint_cost: 0,
    cpm: 0,
    total_maint_cost: 0,
    numOfTrucks: 0
  }

  constructor(@Inject(MAT_DIALOG_DATA) private data: any, public transformService: TransformService) {}

  ngOnInit(): void {
    let key: string = this.data.card;
    this.partsGroup[this.data.index].selected = true;
    this.dataSource = this.data.obj[key];
    this.getTotal(this.data.obj[key])
  }

  getTotal(data: any[]) {
    let sumOdometers: number = 0;
    let sumMaintCost: number = 0;
    let sumCpm: number = 0;
    let numOfTrucks: number = data.length;

    for(let key in data) {
      sumOdometers += data[key].odometers;
      sumMaintCost += data[key].maintenance_cost;
      sumCpm += data[key].cost_per_mile;
    }

    this.avgObj.odometers = this.transformService.addCommasDots((sumOdometers / numOfTrucks), 'round');
    this.avgObj.maint_cost = this.transformService.addCommasDots((sumMaintCost / numOfTrucks), 'round');
    this.avgObj.cpm = this.transformService.addCommasDots((sumCpm / numOfTrucks));
    this.avgObj.total_maint_cost = this.transformService.addCommasDots(sumMaintCost, 'round');
    this.avgObj.numOfTrucks = numOfTrucks;
  };


  choseGroup(groupObj: any) {
    let dataArray: any[] = [];
    groupObj.selected = !groupObj.selected;
    for(let key in this.partsGroup) {
      if(this.partsGroup[key].selected) {
        dataArray = dataArray.concat(this.data.obj[this.partsGroup[key].key]);
      }
    }
    this.dataSource = dataArray;
    this.getTotal(dataArray)
  };

  //Export to excel
  exportToExcel() {
    let element = document.getElementById('trucks-table'); 
    const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);

    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    XLSX.writeFile(wb, this.fileName);
  };

  //Sort data
  sortData(sort: Sort) {
    const data =  this.dataSource.slice();
    
    if (!sort.active || sort.direction === '') {
      this.dataSource = data;
      return;
    }
    this.dataSource = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'position':
          return compare(a.position, b.position, isAsc);
        case 'unit_no':
          return compare(a.unit_no, b.unit_no, isAsc);
        case 'model':
          return compare(a.model, b.model, isAsc);
        case 'year':
          return compare(a.year, b.year, isAsc);
        case 'odometers':
          return compare(a.odometers, b.odometers, isAsc);
        case 'status':
          return compare(a.status, b.status, isAsc);
        case 'group':
          return compare(a.group, b.group, isAsc);
        case 'maintenance_cost':
          return compare(a.maintenance_cost, b.maintenance_cost, isAsc);
        case 'cost_per_mile':
          return compare(a.cost_per_mile, b.cost_per_mile, isAsc);
        default:
          return 0;
      }
    });
  }
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
import { Injectable } from '@angular/core';
import * as _G from '../../../_config/globals';
import { HttpHeaders, HttpClient } from '@angular/common/http';

export const HEADER = {
  'Content-Type': 'application/json',
  'Authorization': `Basic SVZTb2x1dGlvbjoxUzBsdXQxMG4yQDIy`
}

@Injectable()
export class WarningsService {

  headers = new HttpHeaders({
		'Content-Type': 'application/json',
		'Authorization': 'Basic SVZTb2x1dGlvbjoxUzBsdXQxMG4yQDIy'
	});
	requestOptions = { headers: this.headers };

  constructor(private http: HttpClient) { }

  getWarningsData(startDate: string, endDate: string) {
    return this.http.get(`${_G.extendApiUrl}warnings/warnings?start=${startDate}&end=${endDate}`, this.requestOptions);
  };

  addUpdateWarning(obj: any) {
    let json: any = JSON.stringify(obj);
    return this.http.post(`${_G.extendApiUrl}warnings/warnings`, json, 
    {
      headers: this.headers,
      reportProgress: true,
      observe: 'events'
    });
  };

  deleteWarning(id: number, deletedBy: string, date: string) {
    return this.http.get(`${_G.extendApiUrl}warnings/deletewarnings?id=${id}&user=${deletedBy}&date=${date}`, this.requestOptions);
  };

}

import { Component, Input, OnInit } from '@angular/core';
import { FleetHealthService } from '@app/modules/fleet-health/services/fleet-health.service';
import { TransformService } from '@app/modules/shared/services/transform.service';

import moment = require('moment');

@Component({
  selector: 'app-dealership-details',
  templateUrl: './dealership-details.component.html',
  styleUrls: ['./dealership-details.component.scss']
})
export class DealershipDetailsComponent implements OnInit {
  @Input() dealershipObj: any;
  note: string = '';
  rating: number | undefined = undefined;

  avgRate: number = 0;

  constructor(private fleetService: FleetHealthService, private transformService: TransformService) { }

  ngOnInit(): void {
    if(this.dealershipObj.business_partners_review.length > 0) {
      this.calculateAvgScore();
    }
  }

  calculateAvgScore() {
    let sum: number = 0;
    for(let key in this.dealershipObj.business_partners_review) {
      sum += this.dealershipObj.business_partners_review[key].score
    }
    this.avgRate = this.transformService.addCommasDots(sum / this.dealershipObj.business_partners_review.length);
  };

  setIntervalReview() {
    let loggedUser: any = JSON.parse(localStorage.getItem('currentUser'));
    let obj: any = {
      id: 0,
      business_paretner_id: this.dealershipObj.bussiness_paretner_id,
      note: this.note,
      score: this.rating,
      created_by: loggedUser.first_name + ' ' + loggedUser.last_name,
      created_date: this.transformService.convertDateToTimestamp(moment(new Date()).format('ddd, DD/MM YYYY'),  moment(new Date()).format('HH:mm:ss'))
    }
    this.fleetService.setIntervalReview(obj)
    .subscribe((response: any) => {
      if(response) {
        obj.id = response;
        this.dealershipObj.business_partners_review.unshift(obj);
        this.note = '';
        this.rating = undefined;
        this.calculateAvgScore();
      }
    })
  };

}

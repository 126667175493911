import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { HometimeRoutingModule } from './hometime.routing';
import { HometimeNoteDialogComponent } from './components/hometime-note-dialog/hometime-note-dialog.component';
import { HometimeComponent } from './components/hometime.component';
import { FilterSearchDispatchersPipe } from './pipes/filter-search-dispatchers.pipe';
import { FilterSearchDriversPipe } from './pipes/filter-search-drivers.pipe';
import { FilterSearchPipe } from './pipes/filter-search.pipe';
import { HometimeService } from './services/hometime.service';

@NgModule({
  declarations: [
    HometimeComponent,
    HometimeNoteDialogComponent,
    FilterSearchDispatchersPipe,
    FilterSearchDriversPipe,
    FilterSearchPipe
  ],
  imports: [
    CommonModule,
    HometimeRoutingModule,
    SharedModule
  ],
  providers: [HometimeService]
})
export class HometimeModule { }

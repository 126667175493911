import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PredefiniedExpense } from '@app/modules/full-app/models/expenses-model';

import { FullAppService } from '@app/modules/full-app/services/full-app.service';

@Component({
  selector: 'app-create-update-expense-dialog',
  templateUrl: './create-update-expense-dialog.component.html',
  styleUrls: ['./create-update-expense-dialog.component.scss']
})
export class CreateUpdateExpenseDialogComponent implements OnInit {
  expense: string = '';

  constructor(@Inject(MAT_DIALOG_DATA) public obj: PredefiniedExpense,
              private fullAppService: FullAppService,
              public dialogRef: MatDialogRef<CreateUpdateExpenseDialogComponent>) { }

  ngOnInit(): void {
    this.expense = this.obj.expense;
  }

  save() {
    let obj: PredefiniedExpense = {
      id: this.obj.id,
      expense: this.expense
    }
    this.fullAppService.createUpdatePredefiniedExpense(obj).subscribe((response: any) => {
      if(response) {
        this.dialogRef.close(response);
      }
    });
  };

}

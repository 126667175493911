import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FleetHealthService } from '@app/modules/fleet-health/services/fleet-health.service';
import { DeleteConfirmationDialogComponent } from '@app/modules/shared/components/delete-confirmation-dialog/delete-confirmation-dialog.component';
import { SuccessDialogComponent } from '@app/modules/shared/components/success-dialog/success-dialog.component';
import { TransformService } from '@app/modules/shared/services/transform.service';

import moment = require('moment');
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-payment-details',
  templateUrl: './payment-details.component.html',
  styleUrls: ['./payment-details.component.scss']
})
export class PaymentDetailsComponent implements OnInit, OnDestroy {
  @Input() paymentList: any;
  @Input() typeNum: number | undefined = undefined;

  //Payment method
  isOpenPaymentMethod: boolean = false;
  paymentArray: any[] = [
    {name: 'EFS', type: 0},
    {name: 'Credit Card', type: 1},
    {name: 'Warranty', type: 2},
    {name: 'Driver - To be reimbursed', type: 3},
  ];
  paymentMethodValue: string = '';
  methodIndex: number | undefined = undefined;

  //Paid by
  isOpenPaymentBy: boolean = false;
  paidByArray: any[] = [
    {name: 'Tempo Freight Systems'},
    {name: 'JDM Expedite'},
    {name: 'Five Star Transport'},
  ];
  paidByValue: string = '';

  //Money code
  moneyCode: string = '';
  
  //Issued by
  issuedByArray: any[] = [];
  isOpenIssuedMenu: boolean = false;
  issuedTo: string = '';

  //Amount
  amount: string = '';

  //Date
  isOpenCalendar: boolean = false;
  date: string = '';

  //Files
  files: any[] = [];

  //Add mode
  addMode: boolean = false;

  //Edit mode
  editMode: boolean = false;
  paymentObjIndex: number | undefined = undefined;

  //Subscription
  subscription: Subscription | any;

  constructor(private fleetService: FleetHealthService, 
              private transformService: TransformService,
              private dialog: MatDialog) { }

  ngOnInit(): void {
    console.log(this.paymentList)
    this.subscription = this.fleetService.getContactDetails(this.typeNum).subscribe((response: any) => {
      console.log(response);
      this.issuedByArray = response;
    });
  }

  dateClicked(event: any) {
    this.date = this.transformService.convertDateToTimestamp(moment(event._d).format('ddd, DD/MM YYYY'), '00:00:00');
    this.isOpenCalendar = false;
  }

  //Upload file
  onFileSelected(event: any) {
    let fileName = event.target.files[0]?.name;
    let reader = new FileReader();
    reader.onload = (e: any) => {
      let uint8: any = new Uint8Array(e.target.result);
      let result: any[] = [];
      for (var i = 0; i < uint8.length; i++) {
        result.push(uint8[i]);
      }

      this.files[0] = {filename: fileName, data: result};
    }
    reader.readAsArrayBuffer(event.target.files[0]);
  };

  //Add payment
  addNewPayment() {
    this.addMode = true;
    this.resetAllFields();
    this.editMode = true;
  };

  //Edit payment
  editPayment(element: any, i: number) {
    let obj: any = JSON.parse(JSON.stringify(element))
    this.paymentMethodValue = this.getMethodName(obj);
    this.methodIndex = obj.method;
    this.moneyCode = obj.money_code;
    this.amount = obj.amount;
    this.paidByValue = obj.paid_by;
    this.issuedTo = obj.issued_to;
    this.date = obj.date;
    this.paymentObjIndex = i;
    this.files = obj.files ? obj.files : [];
    this.editMode = true;
    console.log(obj);
  }

  //Get method name
  getMethodName(element: any) {
    let methodName: string = '';

    switch(element.method) {
      case 1:
        methodName = 'Credit Card'
        break;
      case 2:
        methodName = 'Warranty'
        break;
      case 3:
        methodName = 'Driver - To be reimbursed'
        break;
      default:
        methodName = 'EFS'
    }

    return methodName;
  }

  back() {
    this.resetAllFields();
    this.editMode = false;
    this.addMode = false;
  };

  //Delete payment
  deletePayment(obj: any, i: number) {
    let dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
      autoFocus: false,
      panelClass: 'delete-dialog-container',
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
        this.fleetService.deletePayment(obj.id).subscribe((response: any) => {
          console.log(response);
          if(response) {
            this.paymentList.payments.splice(i, 1);
          }
        })
      }
    })
  }

  //Reset
  resetAllFields() {
    this.paymentMethodValue = '';
    this.methodIndex = undefined;
    this.paidByValue = '';
    this.moneyCode = '';
    this.issuedTo = '';
    this.amount = '';
    this.date = '';
    this.paymentObjIndex = undefined;
    this.files = [];
  };

  //Save update data
  saveUpdate() {
    let loggedUser: any = JSON.parse(localStorage.getItem('currentUser'));
    let objUpdate: any = JSON.parse(JSON.stringify(this.paymentList));

    if(this.addMode) {
     let obj: any = {
      id: 0, 
      type_id: 0,
      method: this.methodIndex,
      type: 0, 
      money_code: this.moneyCode,
      issued_to: this.issuedTo, 
      paid_by: this.paidByValue, 
      date: this.date, 
      amount: this.amount,
      created_by: loggedUser.first_name + ' ' + loggedUser.last_name, 
      created_date: this.transformService.convertDateToTimestamp(moment(new Date()).format('ddd, DD/MM YYYY'),  moment(new Date()).format('HH:mm:ss')),
      edited_by: null, 
      edited_date: null, 
      files: this.files
      }
      objUpdate.payments.push(obj);
    }
    else {
      objUpdate.payments[this.paymentObjIndex].amount = this.amount;
      objUpdate.payments[this.paymentObjIndex].date = this.date;
      objUpdate.payments[this.paymentObjIndex].issued_to = this.issuedTo;
      objUpdate.payments[this.paymentObjIndex].method = this.methodIndex;
      objUpdate.payments[this.paymentObjIndex].money_code = this.moneyCode;
      objUpdate.payments[this.paymentObjIndex].paid_by = this.paidByValue;
      objUpdate.payments[this.paymentObjIndex].files = this.files;
      objUpdate.payments[this.paymentObjIndex].edited_by = loggedUser.first_name + ' ' + loggedUser.last_name;
      objUpdate.payments[this.paymentObjIndex].edited_date = this.transformService.convertDateToTimestamp(moment(new Date()).format('ddd, DD/MM YYYY'),  moment(new Date()).format('HH:mm:ss'));
      objUpdate.payments.edited_by = loggedUser.first_name + ' ' + loggedUser.last_name;
      objUpdate.payments.edited_date = this.transformService.convertDateToTimestamp(moment(new Date()).format('ddd, DD/MM YYYY'),  moment(new Date()).format('HH:mm:ss'));
    }

    console.log(objUpdate);
    this.fleetService.saveWorkOrder(objUpdate).subscribe((response: any) => {
      if(response.result) {
        this.paymentList.payments = objUpdate.payments;
        this.dialog.open(SuccessDialogComponent, {
          autoFocus: false,
          panelClass: 'success-dialog-container'
        });   
      };

    })
  };

  previewFile(obj: any) {
    let extension: string = obj.filename.substring(obj.filename.length - 3);
    let base64: string = obj.data;

    if(typeof obj.data === 'object') {
      base64 = btoa(String.fromCharCode(...new Uint8Array(obj.data)));
    }

    if(extension === 'pdf') {
      const anchor = document.createElement('a');
      document.body.appendChild(anchor);
      anchor.style.display = 'none';
      const byteCharacters = atob(base64);
      console.log(byteCharacters);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/pdf' });
      console.log(atob(base64));
      const url = window.URL.createObjectURL(blob);
      anchor.href = url; 
      anchor.target = '_blank';
      anchor.click();
      window.URL.revokeObjectURL(url);
    }
    else {
      let image = document.createElement('img');
      image.src = `data:image/${extension};base64,` + base64;
      image.setAttribute('style', 'width: 100%')
      let w = window.open("");
      w.document.write(image.outerHTML);
    }
  
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  };
  
}

import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import moment = require('moment');
import { DispatcherProfileService } from '../../services/dispatcher-profile.service';
import { Subscription, catchError, throwError } from 'rxjs';
import { TransformService } from '@app/modules/shared/services/transform.service';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'dispatch-statistics-cards',
  templateUrl: './dispatch-statistics-cards.component.html',
  styleUrls: ['./dispatch-statistics-cards.component.scss']
})
export class DispatchStatisticsCardsComponent implements OnInit, OnDestroy {
  @Output() emitData = new EventEmitter<any>();

  //Date
  dateObj: any = {
    startDate: moment().startOf('month').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD')
  };

  //Calendar
  isOpenFromCalendar: boolean = false;
  isOpenToCalendar: boolean = false;

  cardsData: any;

  //Active card
  selectedCardsObj: any = {
    grossSelected: false,
    milesSelected: false,
    rateSelected: false
  };

  cardsStObj: any = {
    gross: {class: '', message: ''},
    rpm: {class: '', message: ''},
    miles: {class: '', message: ''},
    hometime: {class: '', message: ''},
    retention: {class: '', message: ''},
  }

  loaded: boolean = false;
  error: boolean = false;
  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";

  spinnerBgColor: string = this.transformService.spinnerBgColor;
  spinnerType: string = this.transformService.spinnerType;

  //Subscription
  subscription: Subscription = new Subscription();

  constructor(private route: ActivatedRoute,
              public transformService: TransformService,
              private dispatchProfileService: DispatcherProfileService,
              private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.getCardsData(true);
  }

  getCardsData(init: boolean) {
    this.cardsData = undefined;
    this.error = false;
    this.loaded = false;
    this.spinner.show('dispatch-statistics');
    let id: number = +this.route.snapshot.paramMap.get('id');
    this.subscription = this.dispatchProfileService.getDispatchStatisticsData(this.dateObj.startDate, this.dateObj.endDate, id, this.transformService.filterParams)
    .pipe(catchError((err: any) => {
      this.spinner.hide('dispatch-statistics');
      this.loaded = true;
      this.error = true;
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      this.cardsData = response;
      this.spinner.hide('dispatch-statistics');
      this.loaded = true;

      this.cardsStObj.gross = this.scale(response.gross_score);
      this.cardsStObj.rpm = this.scale(response.rate_score);
      this.cardsStObj.miles = this.scale(response.mileage_score);
      this.cardsStObj.hometime = this.scale(response.hometime_score);
      this.cardsStObj.retention = this.scale(response.retention_score);
      console.log(response)
      if(!init) {
        this.updateGraphData(response.total_list);
      }
    });
  };

  //Calendar method
  dateClicked(event: any, fromCalendar: boolean) {
    if(fromCalendar) {
      this.dateObj.startDate = moment(event._d).format('YYYY-MM-DD');
      this.isOpenFromCalendar = false;
    }
    else {
      this.dateObj.endDate = moment(event._d).format('YYYY-MM-DD');
      this.isOpenToCalendar = false;
    }
  };

  showInLinechart(graphArray: any[], key: string) {
    this.selectedCardsObj[key] = !this.selectedCardsObj[key];
    this.updateGraphData(graphArray);
  };

  //Get data
  run() {
    this.emitData.emit(undefined);
    this.getCardsData(false);
  };

  updateGraphData(graphArray: any[]) {
    if(!this.selectedCardsObj.grossSelected && !this.selectedCardsObj.milesSelected && !this.selectedCardsObj.rateSelected) {
      this.emitData.emit(undefined);
    }
    else {
      this.emitData.emit({graphArray: graphArray, keys: this.selectedCardsObj, dateObj: this.dateObj});
    }
  };

  scale(percent: number) {
    let obj: any = {class: '', message: ''};
    if(percent < 70) {
      obj.class = 'red-badge';
      obj.message = 'Needs immediate improvement';
    }
    else if(percent > 70 && percent <= 84) {
      obj.class = 'pink-badge';
      obj.message = 'Low performance';
    }
    else if(percent > 84 && percent <= 90) {
      obj.class = 'green-light-badge';
      obj.message = 'Good performance';
    }
    else if(percent > 90 && percent <= 100) {
      obj.class = 'green-dark-badge';
      obj.message = 'High performance';
    }
    else {
      obj.class = 'green-shine-badge';
      obj.message = 'Outstanding performance';
    }
    return obj;
  };

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();  
  };

}

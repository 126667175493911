import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { PrebookedLoadsRoutingModule } from './prebooked-loads.routing';
import { PreDragDropCardsComponent } from './components/pre-drag-drop-cards/pre-drag-drop-cards.component';
import { PrebookedDoneTableComponent } from './components/prebooked-done-table/prebooked-done-table.component';
import { PrebookedLineChartComponent } from './components/prebooked-line-chart/prebooked-line-chart.component';
import { PrebookedLoadsComponent } from './components/prebooked-loads.component';
import { PrebookedLoadsService } from './services/prebooked-loads.service';

@NgModule({
  declarations: [
    PrebookedLoadsComponent,
    PreDragDropCardsComponent,
    PrebookedDoneTableComponent,
    PrebookedLineChartComponent
  ],
  imports: [
    CommonModule,
    PrebookedLoadsRoutingModule,
    SharedModule
  ],
  providers: [PrebookedLoadsService]
})
export class PrebookedLoadsModule { }

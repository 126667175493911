import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DateRange } from '@angular/material/datepicker';
import { Sort } from '@angular/material/sort';
import { FleetHealthService } from '@app/modules/fleet-health/services/fleet-health.service';
import { SharedService } from '@app/modules/shared/services/shared.service';
import { TransformService } from '@app/modules/shared/services/transform.service';
import moment = require('moment');
import { NgxSpinnerService } from 'ngx-spinner';
import { catchError, Subscription, throwError } from 'rxjs';
import * as XLSX from 'xlsx'; 

@Component({
  selector: 'scheduled-trucks-table',
  templateUrl: './scheduled-trucks-table.component.html',
  styleUrls: ['./scheduled-trucks-table.component.scss']
})
export class ScheduledTrucksTableComponent implements OnInit, OnDestroy {
  //Trucks
  trucksArray: any[] = [];
  isOpenTruckMenu: boolean = false;
  truck: string = '';

  //Table data
  dataSource: any[] = [];

  isClicked: boolean = false;

  //Calendar
  @Input() selectedRangeValue: DateRange<Date> | any;
  @Output() selectedRangeValueChange = new EventEmitter<DateRange<Date>>();
  isOpenCalendar: boolean = false;

  dateObj: any = {
    startDate: moment().startOf('month').format('YYYY-MM-DDT00:00:00'),
    endDate: moment().format('YYYY-MM-DDT23:59:59')
  }

  //Loader
  loaded: boolean = true;
  error: boolean = false;
  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";

  spinnerType: string = this.transformService.spinnerType;

  //Subscription
  subscription: Subscription = new Subscription();

  constructor(private fleetHealthService: FleetHealthService,
              private transformService: TransformService,
              private sharedService: SharedService,
              private spinner: NgxSpinnerService) {}

  ngOnInit(): void {
    this.getAllTrucks();
  }

  dateRangeClicked(m: any) {
    if (!this.selectedRangeValue?.start || this.selectedRangeValue?.end) {
      this.selectedRangeValue = new DateRange<Date>(m, null);
    } 
    else {
      const start = this.selectedRangeValue.start;
      const end = m;
      if (end < start) {
        this.selectedRangeValue = new DateRange<Date>(end, start);
      } 
      else {
        this.selectedRangeValue = new DateRange<Date>(start, end);
      }
    }
    this.selectedRangeValueChange.emit(this.selectedRangeValue);
    if(this.selectedRangeValue.start && this.selectedRangeValue.end) {
      this.dateObj.startDate = moment(this.selectedRangeValue.start._d).format('YYYY-MM-DD');
      this.dateObj.endDate = moment(this.selectedRangeValue.end._d).format('YYYY-MM-DD');
      this.isOpenCalendar = false;
    }
  }
  
  getAllTrucks() {
    this.subscription = this.sharedService.getAllTrucksData(true).subscribe((response: any) => {
      this.trucksArray = response;
      console.log(response);
    });
  };

  searchTruckData(element: any) {
    this.truck = element.unit_no; 
    this.isOpenTruckMenu = false;

    this.isClicked = true;
    this.dataSource = [];
    this.loaded = false;
    this.error = false; 
    this.spinner.show('scheduled-trucks-spinner');
    this.fleetHealthService.getTruckData(this.truck, this.dateObj.startDate, this.dateObj.endDate)
    .pipe(catchError((err: any) => {
      this.spinner.hide('scheduled-trucks-spinner');
      this.loaded = true;
      this.error = true;
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      console.log(response);
      this.dataSource = response;
      this.spinner.hide('scheduled-trucks-spinner');
      this.loaded = true;
    });
  };

  sortData(sort: Sort) {
    const data = JSON.parse(JSON.stringify(this.dataSource));
    if (!sort.active || sort.direction === '') {
      this.dataSource = data;
      return;
    }
    this.dataSource = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'unit_no':
          return compare(a.unit_no, b.unit_no, isAsc);
        case 'driver':
          return compare(a.driver, b.driver, isAsc);
        case 'status':
          return compare(a.status, b.status, isAsc);
        case 'date':
          return compare(new Date(a.date), new Date(b.date), isAsc);
        default:
          return 0;
      }
    });
  }

  //Export to excel
  exportToExcel() {
    let dataRows: string = '';
    for(let i = 0; i < this.dataSource.length; i++) {
      dataRows += `
      <tr>
        <td>${i + 1}</td>
        <td>${this.dataSource[i].unit_no}</td>
        <td>${this.dataSource[i].driver}</td>
        <td>${this.dataSource[i].status}</td>
        <td>${moment(this.dataSource[i].date).format('MMM dd, yyyy - HH:mm')}</td>
      </tr>`
    }
    let table: string = `
      <table>
        <tr>
          <th>No</th>
          <th>Truck #</th>
          <th>Driver</th>
          <th>Status</th>
          <th>Date & Time</th>
        </tr>

        ${dataRows}

      </table>
    `;
    let dom = document.createRange().createContextualFragment(table).firstElementChild;
    console.log(dom)
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(dom);

    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'scheduled-trucks.xlsx');
  };

  reset() {
    this.truck = ''; 
    this.dataSource = [];
    this.isClicked = false;
    this.selectedRangeValue = null;
    this.dateObj.startDate = moment().startOf('month').format('YYYY-MM-DDT00:00:00');
    this.dateObj.endDate = moment().format('YYYY-MM-DDT23:59:59');
  };

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  };

}

function compare(a: any, b: any, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
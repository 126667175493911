import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { StatementPageComponent } from './components/statement-page.component';
import { AuthGuard } from '../core/services/auth/auth.guard';


const routes: Routes = [
    {
		path: 'drivers',
		children: [
            {
                path: 'statements-page',
                canActivate: [AuthGuard],
                component: StatementPageComponent,
                data: { roles: 44 }
            }
		]
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class StatementRoutingModule { }

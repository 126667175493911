import { Component } from '@angular/core';

@Component({
  selector: 'app-note-info-msg-dialog',
  templateUrl: './note-info-msg-dialog.component.html',
  styleUrls: ['./note-info-msg-dialog.component.scss']
})
export class NoteInfoMsgDialogComponent {

}

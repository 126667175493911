import { Component } from '@angular/core';

@Component({
  selector: 'app-closing-without-note-dialog',
  templateUrl: './closing-without-note-dialog.component.html',
  styleUrls: ['./closing-without-note-dialog.component.scss']
})
export class ClosingWithoutNoteDialogComponent {

}

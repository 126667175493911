import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';


import * as moment from 'moment';
import { FormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { TransformService } from '@app/modules/shared/services/transform.service';


@Component({
  selector: 'app-quick-data',
  templateUrl: './quick-data.component.html',
  styleUrls: ['./quick-data.component.scss']
})
export class QuickDataComponent implements OnInit {
  @Input() selectedValue: FormControl | any;
  @Output() dateChanged = new EventEmitter<string>();
  @Output() changeData = new EventEmitter<any>();

  constructor(private transformService: TransformService) { }

  ngOnInit(): void {
  }
  onlyOneSelect(value: string) {
    this.dateChanged.emit(value);
  };

  onSubmit(matSelect: MatSelect) {
    matSelect.close();
    this.changeData.emit(this.getDate(matSelect.value[0]));
  };

  getDate(selected: string) {
    let startDate: any;
    let endDate: any;
    let value: any = selected.split(' ')[1];

    if (selected.includes('present')) {

      startDate = this.transformService.convertDateToTimestamp(moment().startOf(value).format('ddd, MM/DD/YYYY'), '00:00:00');
      endDate = this.transformService.convertDateToTimestamp(moment().format('ddd, MM/DD/YYYY'), moment().format('HH:mm:ss'));

    } else if (selected.includes('quarter')) {
      let splitValue = value.split('-');
      startDate = this.transformService.convertDateToTimestamp(moment().month(splitValue[0]).date(1)
        .format('ddd, MM/DD/YYYY'), '00:00:00');
      endDate = this.transformService.convertDateToTimestamp(moment().month(splitValue[1]).date(0)
        .format('ddd, MM/DD/YYYY'), '23:59:59');
    }
    else {

      startDate = this.transformService.convertDateToTimestamp(moment().subtract(1, value).startOf(value)
        .format('ddd, MM/DD/YYYY'), '00:00:00');
      endDate = this.transformService.convertDateToTimestamp(moment().subtract(1, value).endOf(value)
        .format('ddd, MM/DD/YYYY'), '23:59:59');

    }

    return { groupBy: 'hours', startDate: startDate, endDate: endDate, period: value };

  }

}

import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { DriversProfileService } from '@app/modules/drivers-profile-page/services/drivers-profile.service';
import { DeleteConfirmationDialogComponent } from '@app/modules/shared/components/delete-confirmation-dialog/delete-confirmation-dialog.component';
import { WarningMsgDialogComponent } from '@app/modules/shared/components/warning-msg-dialog/warning-msg-dialog.component';
import { SharedService } from '@app/modules/shared/services/shared.service';
import { TransformService } from '@app/modules/shared/services/transform.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription, catchError, throwError } from 'rxjs';

@Component({
  selector: 'app-driver-files-dialog',
  templateUrl: './driver-files-dialog.component.html',
  styleUrls: ['./driver-files-dialog.component.scss']
})
export class DriverFilesDialogComponent implements OnInit, OnDestroy {
  files: any[] = [];

  loaded: boolean = false;
  error: boolean = false;
  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";
  spinnerBgColor: string = this.transformService.spinnerBgColor;
  spinnerType: string = this.transformService.spinnerType;

  //Subscription
  subscription: Subscription = new Subscription();

  constructor(@Inject(MAT_DIALOG_DATA) public driverId: number, 
  private dialog: MatDialog,
  private transformService: TransformService,
  private driversProfileService: DriversProfileService, 
  private sharedService: SharedService,
  private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.spinner.show('driver-files');
    this.subscription = this.driversProfileService.getAllDriverFiles(this.driverId)
    .pipe(catchError((err: any) => {
      this.spinner.hide('driver-files');
      this.loaded = true;
      this.error = true;
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      for(let i = 0; i < response.length; i++) {
        this.files.push({filename: response[i], pdfLoading: false});
      };
      this.spinner.hide('driver-files');
      this.loaded = true;
      console.log(response);
      console.log(this.files);
    });
  };

  downloadDriverFile(obj: any) {
    obj.pdfLoading = true;
    this.driversProfileService.downloadDriverFile(this.driverId, obj.filename)
    .pipe(catchError((err: any) => {
      obj.pdfLoading = false;
      return throwError(() => err);
    }))
    .subscribe((base64: any) => {
      obj.pdfLoading = false;
      let source: string = `data:application/octet-stream;base64,${base64}`;
      const downloadLink = document.createElement('a');
      const fileName = obj.filename;
      downloadLink.href = source;
      downloadLink.download = fileName;
      downloadLink.click();
    });
  };

  previewDriverFile(obj: any) {
    obj.pdfLoading = true;
    this.driversProfileService.downloadDriverFile(this.driverId, obj.filename)
    .pipe(catchError((err: any) => {
      obj.pdfLoading = false;
      return throwError(() => err);
    }))
    .subscribe((base64: any) => {
      obj.pdfLoading = false;
      this.sharedService.downloadPreviewFile(obj.filename, base64);
    });
  };

  //Add files
  onFileSelected(event: any) {
    let filenamesArray: any[] = [];  
    let listOfFiles: any = event.target.files;  
    const formData: any = new FormData();  
    const reader: any = new FileReader();

    for(let i = 0; i < listOfFiles.length; i++)  {  
      formData.append("file[]", listOfFiles[i], listOfFiles[i].name);

      reader.onload = () => {
        filenamesArray.push({filename: listOfFiles[i].name, pdfLoading: false});
      };
      reader.readAsDataURL(listOfFiles[i]);
    } 
    this.driversProfileService.uploadFiles(this.driverId, formData).subscribe((response: any) => {
      if(response) {
        this.files = this.files.concat(filenamesArray);
      };
    });
  };

  deleteFile(file: any, index: number) {
    let dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
      autoFocus: false,
      panelClass: 'delete-dialog-container',
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
        this.driversProfileService.deleteDriverFileFromPt(this.driverId, file.filename)
        .pipe(catchError((err: any) => {
          this.showErrorMessage();
          return throwError(() => err);
        }))
        .subscribe((response: any) => {
          if(response) {
            this.files.splice(index, 1);
          }
          else {
            this.showErrorMessage();
          }
        });
      }
    })
  };

  //Show error message
  showErrorMessage() {
    this.dialog.open(WarningMsgDialogComponent, {
      autoFocus: false,
      panelClass: 'warning-msg-dialog-container'
    });
  };

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  };

}

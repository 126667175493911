import { Component, Input, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { EmployeeService } from '@app/modules/employee/services/employee.service';
import moment = require('moment');
import { catchError, throwError } from 'rxjs';

@Component({
  selector: 'app-employee-account',
  templateUrl: './employee-account.component.html',
  styleUrls: ['./employee-account.component.scss']
})
export class EmployeeAccountComponent implements OnInit {
  @Input() data: any;
  sectionArray: string[] = ['Private', 'Business'];
  activeSection: string = 'Business';

  //Business name
  businessName: string = '';

  //Account number
  accountNumber: string = '';

  //Start date
  isOpenStartCalendar: boolean = false;
  startDate: string = '';

  //Tax amount
  taxAmount: string = '';

  //Rent date
  isOpenRentCalendar: boolean = false;
  rentDate: string = '';

  //Rent cost
  rentCost: string = '';

  //Primary account
  primaryAccount: boolean = false;

  constructor(private employeeService: EmployeeService,
              public dialogRef: MatDialogRef<any>) { }

  ngOnInit(): void {
    console.log(this.data);
    if(this.data.editMode) {
      this.setTypeOfAccount('Business');
    }
  }

  //Calendar method
  dateClicked(event: any, firstCalendar: boolean) {
    if(firstCalendar) {
      this.startDate = moment(event._d).format('YYYY-MM-DDT00:00:00');
      this.isOpenStartCalendar = false;
    }
    else {
      this.rentDate = moment(event._d).format('YYYY-MM-DDT00:00:00');
      this.isOpenRentCalendar = false;
    }
  };

  //Set type of account
  setTypeOfAccount(section: string) {
    this.resetInputs();
    this.activeSection = section;

    for(let key in this.data.obj.accounts) {

      if(this.data.obj.accounts[key].type === 0 && this.activeSection === 'Private') {
        this.businessName = this.data.obj.accounts[key].business_name;
        this.accountNumber = this.data.obj.accounts[key].account_number;
        this.startDate = this.data.obj.accounts[key].start_date;
        this.taxAmount = this.data.obj.accounts[key].tax_amount;
        this.primaryAccount = this.data.obj.accounts[key].primary_account;
      }

      if(this.data.obj.accounts[key].type === 1 && this.activeSection === 'Business') {
        this.businessName = this.data.obj.accounts[key].business_name;
        this.accountNumber = this.data.obj.accounts[key].account_number;
        this.startDate = this.data.obj.accounts[key].start_date;
        this.taxAmount = this.data.obj.accounts[key].tax_amount;
        this.rentDate = this.data.obj.accounts[key].rent_date;
        this.rentCost = this.data.obj.accounts[key].rent_cost;
        this.primaryAccount = this.data.obj.accounts[key].primary_account;
      }

    };
  };

  //Reset inputs
  resetInputs() {
    this.businessName = '';
    this.accountNumber = '';
    this.startDate = '';
    this.taxAmount = '';
    this.rentDate = '';
    this.rentCost = '';
  }

  createEditAccount() {
    let loggedUser: any = JSON.parse(localStorage.getItem('currentUser'));
    let obj: any = JSON.parse(JSON.stringify(this.data.obj));
    obj.edited_date = moment().format('YYYY-MM-DDTHH:mm:ss');
    obj.edited_by = loggedUser.first_name + ' ' + loggedUser.last_name;
    let accountObj: any = {
      id: 0,
      employee_id: obj.id,
      business_name: this.businessName,
      account_number: this.accountNumber,
      start_date: this.startDate,
      tax_amount: this.taxAmount,
      rent_date: this.rentDate,
      rent_cost: this.rentCost,
      primary_account: this.primaryAccount,
      type: this.activeSection === 'Private' ? 0 : 1
    }

    let alreadyExist: boolean = false;

    for(let i = 0; i < obj.accounts.length; i++) {
      if(obj.accounts[i].type === accountObj.type) {
        obj.accounts[i] = accountObj;
        alreadyExist = true;
        break;
      }
    }
    
    if(!alreadyExist) {
      obj.accounts.push(accountObj);
    }
    console.log(obj)
    console.log(JSON.stringify(obj));

    this.employeeService.createEmployee(obj)
    .pipe(catchError((err: any) => {
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      if(response) {
        this.data.obj = response;
        this.employeeService.refreshDataSubject.next(true);
      }
      console.log(response);
    })

  };

}

import { Injectable } from '@angular/core';
import * as _G from '../../../_config/globals';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class DriversUnderperformingService {
  headers = new HttpHeaders({
		'Content-Type': 'application/json',
		'Authorization': 'Basic SVZTb2x1dGlvbjoxUzBsdXQxMG4yQDIy'
  });
  requestOptions = { headers: this.headers };


  constructor(private http: HttpClient) { }

  getUnderperformingDriversTableData(startDate: string, endDate: string, numPerPage: number, type: number, filterParams: string) {
		if(type === 0 || type === 1) {
			return this.http.get(`${_G.extendApiUrl}Drivers/underperform?start=${startDate}&end=${endDate}&broj=${numPerPage}&type=${type}${filterParams}`, this.requestOptions);
		}
		if(type === 2) {
			return this.http.get(`${_G.extendApiUrl}Drivers/underperformance?start=${startDate}&end=${endDate}&broj=${numPerPage}${filterParams}`, this.requestOptions);
		}
	};

}

import { Component, Input, OnChanges } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { TransformService } from '@app/modules/shared/services/transform.service';

@Component({
  selector: 'app-prebooked-done-table',
  templateUrl: './prebooked-done-table.component.html',
  styleUrls: ['./prebooked-done-table.component.scss']
})
export class PrebookedDoneTableComponent implements OnChanges {

  @Input() data: any;

  displayedColumns: string[] = ['dispatcher', 'prebooked_loads', 'prebooked_loads_percent', 'rate_per_mile',
    'margin_amount', 'margin_percent', 'avg_weight'];
  dataSource: any[] = [];
  sortedData: any[] = [];

  lengthArray: number = 4;

  constructor(public transformService: TransformService) { }

  ngOnChanges(): void {
    if (this.data) {
      this.dataSource = this.data;
      this.sortedData = this.data.slice();
    }
  }

  seeAll() {
    this.lengthArray = this.dataSource.length;
  };

  seeLess() {
    this.lengthArray = 4;
  };

  sortData(sort: Sort) {
    const data = this.dataSource.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'dispatcher':
          return compare(a.dispatcher, b.dispatcher, isAsc);
        case 'prebooked_loads':
          return compare(a.prebooked_loads, b.prebooked_loads, isAsc);
        case 'prebooked_loads_percent':
          return compare(a.prebooked_loads_percent, b.prebooked_loads_percent, isAsc);
        case 'rate_per_mile':
          return compare(a.rate_per_mile, b.rate_per_mile, isAsc);
        case 'margin_amount':
          return compare(a.margin_amount, b.margin_amount, isAsc);
        case 'margin_percent':
          return compare(a.margin_percent, b.margin_percent, isAsc);
        case 'avg_weight':
          return compare(a.avg_weight, b.avg_weight, isAsc);
        default:
          return 0;
      }
    });
  };

}
function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

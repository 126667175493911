import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { DropdownPanel } from './dropdown-panel';

@Component({
  selector: "my-dropdown",
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent implements DropdownPanel {
  @ViewChild(TemplateRef) templateRef: TemplateRef<any>;
  @Input() width: string = '200px';
  @Output() closed = new EventEmitter<void>();
}

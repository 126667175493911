import { Component, OnInit, OnDestroy } from '@angular/core';
import moment = require('moment');
import { Subscription, catchError, throwError } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { CreateUpdateWarningDialogComponent } from './create-update-warning-dialog/create-update-warning-dialog.component';
import { Sort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MsgForbbidenAccessComponent } from '@app/modules/shared/components/msg-forbbiden-access/msg-forbbiden-access.component';
import { RulesService } from '@app/modules/shared/services/rules.service';
import { SharedService } from '@app/modules/shared/services/shared.service';
import { TransformService } from '@app/modules/shared/services/transform.service';
import { WarningsService } from '../services/warnings.service';
import * as XLSX from 'xlsx';
import { TitleCasePipe } from '@angular/common';
import { WarningPipe } from '../pipes/warning.pipe';

@Component({
  selector: 'app-warnings',
  templateUrl: './warnings.component.html',
  styleUrls: ['./warnings.component.scss']
})
export class WarningsComponent implements OnInit, OnDestroy {
  permissions: any = this.rulesService.UserData[29].data;

  //Date
  dateObj: any = {
    startDate: this.transformService.convertDateToTimestamp(moment().startOf('month').format('ddd, DD/MM YYYY'), 
    '00:00:00'),
    endDate: this.transformService.convertDateToTimestamp(moment().format('ddd, DD/MM YYYY'),  moment().format('HH:mm:ss')),
  };

  dataSource: any[] = [];

  //Excel config
  xlsxConfig: any[] = [
    {columnName: 'No.', selected: true},
    {columnName: 'Driver', selected: true},
    {columnName: 'No. of warnings', selected: true}
  ];

  //Select
  driversDataOriginalArray: any[] = [];
  drivers: any[] = [];
  selectedDrivers: any[] = new Array<any>();
  searchText: string = '';
  isOpen: boolean = false;

  //Active inactive menu
  driverStatusArray: any[] = [
    {status: 'All drivers'},
    {status: 'Active drivers'},
    {status: 'Inactive drivers'}
  ];
  isOpenDriverMenu: boolean= false;
  driverStatus: string = 'Active drivers';

  //Statistics obj
  stObj: any = {
    oneWarning: 0,
    twoWarnings: 0,
    threeOrMoreWarnings: 0
  }
  numOfWarningsFilter: number | undefined = undefined;
  equalOrGreater: boolean = false;

  subscription: Subscription = new Subscription();

  loaded: boolean = false;

  error: boolean = false;

  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";

  spinnerBgColor: string = this.transformService.spinnerBgColor;
  spinnerType: string = this.transformService.spinnerType;

  constructor(private transformService: TransformService,
              private dialog: MatDialog,
              private warningsService: WarningsService,
              private sharedService: SharedService,
              private rulesService: RulesService,
              private spinner: NgxSpinnerService,
              private router: Router,
              private warningPipe: WarningPipe,
              private titleCase: TitleCasePipe) { }
  
  ngOnInit() {
    this.getTableData();
  }

  getTableData() {
    this.spinner.show('warnings-section');
    this.stObj = {
      oneWarning: 0,
      twoWarnings: 0,
      threeOrMoreWarnings: 0
    }
    this.subscription = this.warningsService.getWarningsData(this.dateObj.startDate, this.dateObj.endDate)
    .pipe(catchError((err: any) => {
      this.spinner.hide('warnings-section');
      this.loaded = true;
      this.error = true;
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      console.log(response)
      let data = response.reduce((acc: any, val: any, ind: any) => {
    
        const index = acc.findIndex((el: any) => el.driver_id === val.driver_id);
        if(index !== -1){
          acc[index].warningsArray.push(val);
        } 
        else {
          let obj: any = {...val};
          val.warningsArray = [obj];
          acc.push(val);
        };

        return acc;
      }, []);
      this.driversDataOriginalArray = JSON.parse(JSON.stringify(data));
      this.drivers = data;
      this.dataSource = data;
      this.countStData(data);
      this.spinner.hide('warnings-section');
      this.loaded = true;
      console.log(data);
    })
  };

  countStData(array: any[]) {
    let oneWarning: number = 0;
    let twoWarnings: number = 0;
    let threeOrMoreWarnings: number = 0;

    for(let i = 0; i < array.length; i++) {
      if(array[i].warningsArray.length === 1) {
        oneWarning++;
      }
      if(array[i].warningsArray.length === 2) {
        twoWarnings++;
      }
      if(array[i].warningsArray.length >= 3) {
        threeOrMoreWarnings++;
      }
    };

    this.stObj.oneWarning = oneWarning;
    this.stObj.twoWarnings = twoWarnings;
    this.stObj.threeOrMoreWarnings = threeOrMoreWarnings;
  };

  getDate(result: any) {
    this.dateObj.startDate = result.startDate;
    this.dateObj.endDate = result.endDate;
    this.dataSource = [];
    this.error = false;
    this.loaded = false;
    this.getTableData();
  };

  // Open Tickets Dialog
  openWarningDialog(editMode: boolean, obj: any) {
    if(this.permissions[0].allowedAll) {
      let dialogRef = this.dialog.open(CreateUpdateWarningDialogComponent, {
        autoFocus: false,
        panelClass: 'create-update-warning-main-container',
        disableClose: true,
        data: {editMode: editMode, obj: obj}
      });
      dialogRef.afterClosed().subscribe((result: any) => {
        if(result) {
          this.dataSource = [];
          this.error = false;
          this.loaded = false;
          this.getTableData();
        }
      });
    }
    else {
      this.msgForbbidenAccess();
    }
  }

  // Search
  toggleSelection(user: any) {
    user.selected = !user.selected;
    if (user.selected) {
      this.selectedDrivers.push(user);
      this.dataSource = JSON.parse(JSON.stringify(this.selectedDrivers));
    } else {
      const i = this.selectedDrivers.findIndex(value => value.name === user.name);
      this.selectedDrivers.splice(i, 1);
      this.dataSource = JSON.parse(JSON.stringify(this.selectedDrivers));
    }
    this.dataSource.length === 0 ? this.dataSource = JSON.parse(JSON.stringify(this.driversDataOriginalArray)) : this.dataSource;
  }

  resetCheckedValue() {
    let data: any = JSON.parse(JSON.stringify(this.driversDataOriginalArray));
    this.dataSource = data;
    this.drivers = data;
    this.searchText = '';
    this.selectedDrivers = [];
  }

  // Sort data
  sortData(sort: Sort) {
    const data = this.dataSource.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSource = data;
      return;
    }
    this.dataSource = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'driver':
          return compare(a.driver, b.driver, isAsc);
        case 'warningsArray':
          return compare(a.warningsArray.length, b.warningsArray.length, isAsc);
        default:
          return 0;
      }
    });
  }

  getClass(number: number) {
    if(number >= 3) {
      return 'danger-bg';
    }
    else if(number === 2) {
      return 'warning-bg';
    }
    else {
      return '';
    }
  };

  filterTable(numOfWarnings: number, greaterSign: boolean) {
    this.equalOrGreater = greaterSign;
    this.numOfWarningsFilter = this.numOfWarningsFilter === numOfWarnings ? undefined : numOfWarnings;
  };

  downloadPreviewFile(obj: any) {
    this.sharedService.downloadPreviewFile(obj.filename, obj.data);
  };

  //Open in new tab
  openInNewTab(route: string) {
    const url = this.router.serializeUrl(this.router.createUrlTree([route]));
    window.open(url, '_blank');
  };

  exportToExcel(columnsConfig: any[]) {
    if(this.rulesService.UserData[56].data[0].sectionArray[21].allowed) {
      const tableData: any[] = this.warningPipe.transform(this.dataSource, this.driverStatus, this.numOfWarningsFilter, this.equalOrGreater)
      const excelTable: any[] = [];
      for(let i = 0; i < tableData.length; i++) {
        let obj: any = {};
        this.transformService.selectedColumn(obj, columnsConfig[0].columnName, i + 1, columnsConfig[0].selected);
        this.transformService.selectedColumn(obj, columnsConfig[1].columnName, this.titleCase.transform(tableData[i].driver), columnsConfig[1].selected);
        this.transformService.selectedColumn(obj, columnsConfig[2].columnName, tableData[i].warningsArray.length, columnsConfig[2].selected);
        excelTable.push(obj);
      };
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelTable);
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, 'warnings.xlsx');
    }
    else {
      this.msgForbbidenAccess();
    }
  };


  msgForbbidenAccess() {
    this.dialog.open(MsgForbbidenAccessComponent, {
      autoFocus: false,
      panelClass: 'forbidden-msg-dialog-container'
    })
  };

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  };

}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}



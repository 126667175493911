import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BrokersToAvoidComponent } from './components/brokers-to-avoid/brokers-to-avoid.component';
import { AuthGuard } from '../core/services/auth/auth.guard';

const routes: Routes = [
    {
        path: 'dispatchers',
        children: [
            {
                path: 'brokers-to-avoid',
                canActivate: [AuthGuard],
                component: BrokersToAvoidComponent,
                data: { roles: 30 }
            }
        ]
    
    }
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class BrokersToAvoidRoutingModule { }

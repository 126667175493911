import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterDrivers'
})
export class FilterDriversPipe implements PipeTransform {

  transform(array: any[], searchValuesArray: any[]): any[] {
    if (!array) {
      return [];
    }
 
    return array.filter((obj: any) => {

      let key1: boolean = searchValuesArray[0].includes(obj.division_id);
      
      let oneString: string = searchValuesArray[1].join(', ');
      let key2: boolean = oneString.includes(obj.status);

      if(key1 && key2) { 
        return obj;
      }

    });

  }

}

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DateRange } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { IAccountingBoard } from '@app/modules/accounting/models/accounting-board.model';
import { IAccountingCheckedLoads } from '@app/modules/accounting/models/accounting-checked-loads';
import { AccountingService } from '@app/modules/accounting/services/accounting.service';
import { SharedService } from '@app/modules/shared/services/shared.service';
import { TransformService } from '@app/modules/shared/services/transform.service';
import moment = require('moment');
import { Subscription, catchError, throwError } from 'rxjs';
import { CreateUpdateNoteDialogComponent } from './create-update-note-dialog/create-update-note-dialog.component';
import { MsgForbbidenAccessComponent } from '@app/modules/shared/components/msg-forbbiden-access/msg-forbbiden-access.component';
import { SuccessDialogComponent } from '@app/modules/shared/components/success-dialog/success-dialog.component';
import { WarningMsgDialogComponent } from '@app/modules/shared/components/warning-msg-dialog/warning-msg-dialog.component';
import { RulesService } from '@app/modules/shared/services/rules.service';
import { LoadsDocumentationDialogComponent } from '@app/modules/shared/components/loads-documentation-dialog/loads-documentation-dialog.component';

@Component({
  selector: 'accounting-board-table',
  templateUrl: './accounting-board-table.component.html',
  styleUrls: ['./accounting-board-table.component.scss']
})
export class AccountingBoardTableComponent implements OnInit, OnDestroy {
  permissions: any = this.rulesService.UserData[26].data;
  @Input() dataSource: IAccountingBoard[] = [];

  //Accounting Employee 
  employeesArray: any[] = [];
  isOpenAccountingEmployeeMenu: boolean = false;
  selectedAccountingEmployee: string = '';

  //Filters
  truckSearch: string = '';
  driverSearch: string = '';
  loadNUmberSearch: string = '';
  brokerSearch: string = '';
  customerLoadSearch: string = '';
  puCityStateSearch: string = '';
  delCityStateSearch: string = '';
  loadStatusSearch: string = '';
  driverStatusSearch: string = '';
  dispatcherSearch: string = '';

  //Num values
  radioBtns: any[] = [
    {name: 'More than'},
    {name: 'Less than'},
    {name: 'Equal'},
  ];

  //Paid miles
  isOpenPaidMenu: boolean = false;
  paidValue: string = '';
  paidSign: string = 'More than';

  //Freight amount
  isOpenFreightMenu: boolean = false;
  freightValue: string = '';
  freightSign: string = 'More than';

  //Tonu
  radioTonuBtns: any[] = [
    {name: 'Yes'},
    {name: 'No'},
    {name: 'Both'},
  ];
  isOpenTonuMenu: boolean = false;
  radioTonuValue: string = 'Both';

  //Confirmation
  radioConfirmationBtns: any[] = [
    {name: 'Checked'},
    {name: 'Unchecked'},
    {name: 'Both'},
  ];
  isOpenConfirmationMenu: boolean = false;
  confirmationValue: string = 'Both';

  //Note
  radioNoteBtns: any[] = [
    {name: 'With note'},
    {name: 'Without note'},
    {name: 'Both'},
  ];
  isOpenNoteMenu: boolean = false;
  noteValue: string = 'Both';

  //Pu calendar
  puRangeValue: DateRange<Date> | undefined;
  @Output() puRangeValueChange = new EventEmitter<DateRange<Date>>();
  isOpenPuCalendar: boolean = false;
  puObj: any;

  //Del calendar
  delRangeValue: DateRange<Date> | undefined;
  @Output() delRangeValueChange = new EventEmitter<DateRange<Date>>();
  isOpenDelCalendar: boolean = false;
  delObj: any;

  //Invoice calendar
  invoiceRangeValue: DateRange<Date> | undefined;
  @Output() invoiceRangeValueChange = new EventEmitter<DateRange<Date>>();
  isOpeninvoiceCalendar: boolean = false;
  invoiceObj: any;

  //Load statuses
  loadStatusArray: any[] = [
    {status: 'Load Booked'},
    {status: 'In Transit On Time'},
    {status: 'In Transit Delayed'},
    {status: 'Delivered Waiting For Bol'},
    {status: 'Waiting For Payment'},
    {status: 'Completed'}
  ];

  //Subscription
  subscription: Subscription = new Subscription();

  constructor(public transformService: TransformService,
              private rulesService: RulesService,
              private dialog: MatDialog,
              private sharedService: SharedService,
              private accountingService: AccountingService) { }

  ngOnInit(): void {
    this.subscription = this.sharedService.getAllEmployees().subscribe((response: any) => {
      this.employeesArray = response;
      console.log(response);
    });
  }

  sortData(sort: Sort) {
    const data = JSON.parse(JSON.stringify(this.dataSource));
    if (!sort.active || sort.direction === '') {
      this.dataSource = data;
      return;
    }
    this.dataSource = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case '_checked':
          return compare(a.info._checked, b.info._checked, isAsc);
        case 'unit_no':
          return compare(a.unit_no, b.unit_no, isAsc);
        case 'driver':
          return compare(a.driver, b.driver, isAsc);
        case 'load_no':
          return compare(a.load_no, b.load_no, isAsc);
        case 'company_name':
          return compare(a.company_name, b.company_name, isAsc);
        case 'customer_load_no':
          return compare(a.customer_load_no, b.customer_load_no, isAsc);
        case 'pickup_time':
          return compare(new Date(a.pickup_time), new Date(b.pickup_time), isAsc);
        case 'delivery_time':
          return compare(new Date(a.delivery_time), new Date(b.delivery_time), isAsc);
        case 'invoice_date':
          return compare(new Date(a.invoice_date), new Date(b.invoice_date), isAsc);
        case 'pickup_location':
          return compare(a.pickup_location, b.pickup_location, isAsc);
        case 'delivery_location':
          return compare(a.delivery_location, b.delivery_location, isAsc);
        case 'load_status':
          return compare(a.load_status, b.load_status, isAsc);
        case 'driver_status':
          return compare(a.driver_status, b.driver_status, isAsc);
        case 'dispatcher':
          return compare(a.dispatcher, b.dispatcher, isAsc);
        case 'tonu':
          return compare(a.tonu, b.tonu, isAsc);
        case 'paid_miles':
          return compare(a.paid_miles, b.paid_miles, isAsc);
        case 'freight_amount':
          return compare(a.freight_amount, b.freight_amount, isAsc);
        case 'note':
          return compare(a.info.note, b.info.note, isAsc);
        default:
          return 0;
      }
    });
  };

  //Filter date
  selectedChange(m: any, option: number) {
    if(option === 1) {
      if (!this.puRangeValue?.start || this.puRangeValue?.end) {
        this.puRangeValue = new DateRange<Date>(m, null);
      } 
      else {
        const start = this.puRangeValue.start;
        const end = m;
        if (end < start) {
            this.puRangeValue = new DateRange<Date>(end, start);
        } else {
            this.puRangeValue = new DateRange<Date>(start, end);
        }
        this.puObj = this.puRangeValue;
      } 
      this.puRangeValueChange.emit(this.puRangeValue);
    }
    else if(option === 2) {
      if (!this.delRangeValue?.start || this.delRangeValue?.end) {
        this.delRangeValue = new DateRange<Date>(m, null);
      } 
      else {
        const start = this.delRangeValue.start;
        const end = m;
        if (end < start) {
            this.delRangeValue = new DateRange<Date>(end, start);
        } else {
            this.delRangeValue = new DateRange<Date>(start, end);
        }
        this.delObj = this.delRangeValue;
      } 
      this.delRangeValueChange.emit(this.delRangeValue);
    }
    else {
      if (!this.invoiceRangeValue?.start || this.invoiceRangeValue?.end) {
        this.invoiceRangeValue = new DateRange<Date>(m, null);
      } 
      else {
        const start = this.invoiceRangeValue.start;
        const end = m;
        if (end < start) {
            this.invoiceRangeValue = new DateRange<Date>(end, start);
        } else {
            this.invoiceRangeValue = new DateRange<Date>(start, end);
        }
        this.invoiceObj = this.invoiceRangeValue;
      } 
      this.invoiceRangeValueChange.emit(this.invoiceRangeValue);
    }
  }

  clearFilters() {
    this.selectedAccountingEmployee = '';
    this.truckSearch = '';
    this.driverSearch = '';
    this.loadNUmberSearch = '';
    this.brokerSearch = '';
    this.customerLoadSearch = '';
    this.puCityStateSearch = '';
    this.delCityStateSearch = '';
    this.loadStatusSearch = '';
    this.driverStatusSearch = '';
    this.dispatcherSearch = '';
    this.paidValue = '';
    this.paidSign = 'More than';
    this.freightValue = '';
    this.freightSign = 'More than';
    this.confirmationValue = 'Both';
    this.radioTonuValue = 'Both';
    this.noteValue = 'Both';
    this.puRangeValue = undefined;
    this.puObj = undefined;
    this.delRangeValue = undefined;
    this.delObj = undefined;
    this.invoiceRangeValue = undefined;
    this.invoiceObj = undefined;
  };

  openPdfInNewTab(obj: any) {
    obj.pdfLoading = true;
    this.accountingService.previewAccountingPdf(obj.load_no)
    .pipe(catchError((err: any) => {
      obj.pdfLoading = false;
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      obj.pdfLoading = false;
      const anchor = document.createElement('a')
      const objectUrl = URL.createObjectURL(response)
      anchor.href = objectUrl;
      anchor.target = '_blank';
      anchor.click();
      URL.revokeObjectURL(objectUrl);
    })
  };

  checkUncheck(event: any, element: any) {
    event.preventDefault();
    let loggedUser: any = JSON.parse(localStorage.getItem('currentUser'));
    let obj: IAccountingCheckedLoads = {
      id: element.info.id,
      load_no: element.load_no,
      _checked: !element.info._checked,
      note: element.info.note ? element.info.note : '',
      checked_by: `${loggedUser.first_name} ${loggedUser.last_name}`,
      checked_date: moment().format('YYYY-MM-DDTHH:mm:ss')
    }
    console.log(obj);
    this.accountingService.checkUncheckLoads(obj)
    .subscribe((response: any) => {
      console.log(response)
      if(response?.id) {
        element.info = response;
      };
    });
  };

  openNoteDialog(element: any) {
    let dialogRef = this.dialog.open(CreateUpdateNoteDialogComponent, {
      autoFocus: false,
      panelClass: 'component-dialog-container',
      data: element.info.note,
      disableClose: true
    });
    dialogRef.afterClosed().subscribe((note: any) => {
      console.log(note);
      if(typeof note === 'string') {
        let loggedUser: any = JSON.parse(localStorage.getItem('currentUser'));
        let obj: IAccountingCheckedLoads = {
          id: element.info.id,
          load_no: element.load_no,
          _checked: element.info._checked,
          note: note,
          checked_by: `${loggedUser.first_name} ${loggedUser.last_name}`,
          checked_date: moment().format('YYYY-MM-DDTHH:mm:ss')
        }
        console.log(obj);
        this.accountingService.checkUncheckLoads(obj)
        .subscribe((response: any) => {
          console.log(response)
          if(response?.id) {
            element.info = response;
          };
        });
      }
    });
  };

  //Open documentation dialog
  openFilesForDownload(loadNo: any, loadId: number) {
    this.sharedService.getAllFilesLoads(loadNo).subscribe((response) => {
      console.log(response);
      this.dialog.open(LoadsDocumentationDialogComponent, {
        width: '500px',
        height: '500px',
        panelClass: 'download-files-dialog',
        autoFocus: false,
        data: {fileNames: response, loadNo: loadNo, loadId: loadId}
      });
    });
  };

  //Change load status
  changeLoadStatus(status: string, element: any) {
    element.isOpenActivityMenu = false;
    if(this.permissions[0].allowedAll) {
      this.sharedService.changeLoadStatus(element.id, status)
      .subscribe((response: any) => {
        console.log(response);
        if(response) {
          element.load_status = status;
          this.dialog.open(SuccessDialogComponent, {
            autoFocus: false,
            panelClass: 'success-dialog-container'
          });  
        }
        else {
          this.dialog.open(WarningMsgDialogComponent, {
            autoFocus: false,
            panelClass: 'warning-msg-dialog-container'
          });
        }
      });
    }
    else {
      this.dialog.open(MsgForbbidenAccessComponent, {
        autoFocus: false,
        panelClass: 'forbidden-msg-dialog-container'
      })
    }
  };
  

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  };

}

function compare(a: number | string | any, b: number | string | any, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
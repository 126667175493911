import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { InspectionsService } from '../../services/inspections.service';

@Component({
  selector: 'app-add-new-violation-dialog',
  templateUrl: './add-new-violation-dialog.component.html',
  styleUrls: ['./add-new-violation-dialog.component.scss']
})
export class AddNewViolationDialogComponent {

  violation: string = '';
  group: string = '';
  section: string = '';
  description: string = '';

  constructor(private inspectionService: InspectionsService,
              public dialogRef: MatDialogRef<any>) { }

  create() {
    let obj: any = {
      section: this.section,
      description: this.description,
      violation: this.violation,
      group: this.group
    };

    this.inspectionService.createViolation(obj).subscribe((response: any) => {
      if(response) {
        this.dialogRef.close(true);
      }
    });
  };

}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeDuplicates'
})
export class RemoveDuplicatesPipe implements PipeTransform {

  transform(array: any[], key: string): any[] {
    return array.reduce((accumulator, current) => {
      if (!accumulator.find((item) => item[key]?.trim() === current[key]?.trim())) {
        if(current[key] && current[key].trim().length !== 0) {
          accumulator.push(current);
        }
      }
      return accumulator;
    }, []);
  }

}

import { Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { OverviewService } from '@app/modules/overview/services/overview.service';
import { RulesService } from '@app/modules/shared/services/rules.service';
import { TransformService } from '@app/modules/shared/services/transform.service';
import moment = require('moment');
import { ApexAxisChartSeries, ApexChart, ApexXAxis, ApexYAxis, ApexDataLabels, ApexGrid, ApexStroke } from 'ng-apexcharts';
import { NgxSpinnerService } from 'ngx-spinner';
import { catchError, Subscription, throwError } from 'rxjs';

export type ChartOptions = {
  series: ApexAxisChartSeries | any;
  chart: ApexChart | any;
  xaxis: ApexXAxis | any;
  yaxis: ApexYAxis | any;
  dataLabels: ApexDataLabels | any;
  grid: ApexGrid | any;
  stroke: ApexStroke | any;
  markers: any;
  colors: any;
  tooltip: any;
};

@Component({
  selector: 'driver-turnover',
  templateUrl: './driver-turnover.component.html',
  styleUrls: ['./driver-turnover.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DriverTurnoverComponent implements OnInit, OnDestroy {
  @ViewChild('chartContainer') chartContainer!: ElementRef;
  public chartOptions: Partial<ChartOptions> | any;
  dateDriverTurnover: any = {
    startDate: moment().subtract(2, "month").startOf('month').format('YYYY-MM-DD'),
    endDate: moment().endOf('month').format('YYYY-MM-DD')
  };

  driverTurnoverData: any[] = [
    {title: 'Company', key: 'company', selected: true, seriesArray: []},
    {title: 'Rent to run', key: 'rent', selected: false, seriesArray: []},
    {title: 'Owner', key: 'owner', selected: false, seriesArray: []},
    {title: 'Total', key: 'total', selected: false, seriesArray: []}
  ];
  categoriesArray: any[] = [];
  currentMonthBlink: number = -1;

  //Data
  cardsData: any = {company: 0, rent: 0, owner: 0, total: 0};

  //Interval
  interval: any;
  
  //Spinner
  loaded: boolean = false;
  error: boolean = false;
  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";

  spinnerBgColor: string = this.transformService.spinnerBgColor;
  spinnerType: string = this.transformService.spinnerType;

  //Subscription
  subscription: Subscription = new Subscription();

  constructor(private transformService: TransformService, 
              private rulesService: RulesService,
              private overviewService: OverviewService,
              private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.spinner.show('driver-turnover');
    this.getTurnoverData();
    if(this.rulesService.liveUpdate) {
      this.interval = setInterval(() => {
        this.getTurnoverData();
      }, this.rulesService.miliseconds);
    }
  };

  changeDateRange(endDate: string) {
    this.error = false;
    this.loaded = false;
    this.spinner.show('driver-turnover');
    this.dateDriverTurnover.endDate = endDate;
    this.getTurnoverData();
  };

  changeGraphData(obj: any, isTotal: boolean) {
    obj.selected = !obj.selected;
    if(isTotal) {
      for(let i = 0; i < this.driverTurnoverData.length; i++) {
        if(i !== 3) {
          this.driverTurnoverData[i].selected = false;
        };
      };
    }
    else {
      this.driverTurnoverData[3].selected = false;
    }
    this.chartOptions.series = this.showSelectedData.seriesArray;
    if(this.showSelectedData.seriesArray.length > 0 && this.currentMonthBlink !== -1) {
      this.blinkMarker(this.currentMonthBlink);
    };
  };

  getTurnoverData() {
    this.subscription = this.overviewService.getDriverTurnoverData(this.dateDriverTurnover.startDate, this.dateDriverTurnover.endDate, this.transformService.filterParams)
    .pipe(catchError((err: any) => {
      this.spinner.hide('driver-turnover');
      this.loaded = true;
      this.error = true;
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      console.log(response)
      this.cardsData = response.cardsData;
      //Calculate series
      this.calculateSeriesData(response.graphData);
      this.spinner.hide('driver-turnover');
      this.loaded = true;
    });
  }

  calculateSeriesData(allSeriesArray: any[]) {
    this.categoriesArray = [];
    this.currentMonthBlink = -1;
    let date: Date = new Date();
    const currentYear = date.getFullYear();
    const currentMonth = date.getMonth() + 1;
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    for(let i = 0; i < allSeriesArray.length; i++) {
      if(allSeriesArray[i].month === currentMonth && allSeriesArray[i].year == currentYear) {
        this.currentMonthBlink = i;
      }
      this.categoriesArray.push(monthNames[allSeriesArray[i].month - 1]);
      this.driverTurnoverData[0].seriesArray.push(allSeriesArray[i].company);
      this.driverTurnoverData[1].seriesArray.push(allSeriesArray[i].rent);
      this.driverTurnoverData[2].seriesArray.push(allSeriesArray[i].owner);
      this.driverTurnoverData[3].seriesArray.push(allSeriesArray[i].total);
    };
    //Init chart
    this.initChart();
  };

  initChart() {
    const data: any = this.showSelectedData;
    this.chartOptions = {
      series: data.seriesArray,
      chart: {
        toolbar: {
          show: true,
          tools: {
            download: false
          },
          animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 800,
            animateGradually: {
                enabled: true,
                delay: 150
            },
            dynamicAnimation: {
                enabled: true,
                speed: 350
            }
          }
        },
        height: 250,
        type: "line",
        zoom: {
          enabled: false
        },
        fontFamily: 'Poppins'
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: "straight",
        width: 1,
      },
      grid: {
        row: {
          colors: ["transparent", "transparent"],
          opacity: 0.5
        }
      },
      xaxis: {
        categories: this.categoriesArray,
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        }
      },
      yaxis: {
        show: true,
        seriesName: data.seriesName, 
        axisTicks: {
          show: true,
        }, 
        axisBorder: {
          show: false, 
          color: '#0d5fe0'
        },
        labels: {
          style: {
            colors: '#0d5fe0',
            fontFamily: 'Poppins'
          }, 
          formatter: (num: number) => { 
          return `${num.toLocaleString("en-US").toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}%`;
        }}
      },
      markers: {
        size: 5
      },
      colors: ['#0030FF', '#FA9120', '#FA0000', '#008000', '#FF00FF', '#800000', '#FE7777', '#0303BB', '#008FA9', '#7550CB', '#6A6D6E'],
      tooltip: {
        enabled: true,
        inverseOrder: false,
        style: {
          fontSize: '12px'
        },
      }
    };
    if(data.seriesArray.length > 0 && this.currentMonthBlink !== -1) {
      this.blinkMarker(this.currentMonthBlink);
    }
  };

  blinkMarker(index: number) {
    setTimeout(() => {
      const element = this.chartContainer.nativeElement;
      const marker = element.querySelectorAll('.apexcharts-marker')?.[index];
      marker?.classList.add('blinking')
      if(this.chartOptions.series.length === 2) {
        const marker2 = element.querySelectorAll('.apexcharts-marker')?.[index + index + 1];
        marker2?.classList.add('blinking');
      };
      if(this.chartOptions.series.length === 3) {
        const marker2 = element.querySelectorAll('.apexcharts-marker')?.[index + index + 1];
        marker2?.classList.add('blinking');
        const marker3 = element.querySelectorAll('.apexcharts-marker')?.[index + index + index + 2];
        marker3?.classList.add('blinking');
      };
    }, 1000);
  };

  get showSelectedData(): any {
    const seriesName: string[] = [];
    const seriesArray: any[] = [];
    if(this.driverTurnoverData[0].selected) {
      seriesName.push(this.driverTurnoverData[0].title);
      seriesArray.push({name: this.driverTurnoverData[0].title, data: this.driverTurnoverData[0].seriesArray});
    };
    if(this.driverTurnoverData[1].selected) {
      seriesName.push(this.driverTurnoverData[1].title);
      seriesArray.push({name: this.driverTurnoverData[1].title, data: this.driverTurnoverData[1].seriesArray});
    };
    if(this.driverTurnoverData[2].selected) {
      seriesName.push(this.driverTurnoverData[2].title);
      seriesArray.push({name: this.driverTurnoverData[2].title, data: this.driverTurnoverData[2].seriesArray});
    };
    if(this.driverTurnoverData[3].selected) {
      seriesName.push(this.driverTurnoverData[3].title);
      seriesArray.push({name: this.driverTurnoverData[3].title, data: this.driverTurnoverData[3].seriesArray});
    };
    return {seriesName: seriesName, seriesArray: seriesArray};
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
    this.subscription?.unsubscribe();
  };

}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dispName'
})
export class DispNamePipe implements PipeTransform {

  transform(dispatcherName: string): string {
    return dispatcherName?.split('/ ')[1]?.split(' ')[0];
  }

}

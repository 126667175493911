import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportsRoutingModule } from './reports.routing';
import { SharedModule } from '../shared/shared.module';
import { ReportsService } from './services/reports.service';
import { ReportTruckProfitabilityComponent } from './components/report-page/report-truck-profitability/report-truck-profitability.component';
import { ReportTruckActivityComponent } from './components/report-page/report-truck-activity/report-truck-activity.component';
import { ReportTruckActivationComponent } from './components/report-page/report-truck-activation/report-truck-activation.component';
import { ReportMileagePerTruckComponent } from './components/report-page/report-mileage-per-truck/report-mileage-per-truck.component';
import { TruckProfitabilityCardsComponent } from './components/report-page/report-truck-profitability/truck-profitability-cards/truck-profitability-cards.component';
import { TruckProfitabilityColumnChartComponent } from './components/report-page/report-truck-profitability/truck-profitability-column-chart/truck-profitability-column-chart.component';
import { TruckProfitabilityPieChartComponent } from './components/report-page/report-truck-profitability/truck-profitability-pie-chart/truck-profitability-pie-chart.component';
import { TruckProfitabilityLineChartComponent } from './components/report-page/report-truck-profitability/truck-profitability-line-chart/truck-profitability-line-chart.component';
import { ReportProfitabilityComponent } from './components/report-page/report-truck-profitability/report-profitability/report-profitability.component';
import { TruckActivationCardsComponent } from './components/report-page/report-truck-activation/truck-activation-cards/truck-activation-cards.component';
import { TruckActivationColumnChartComponent } from './components/report-page/report-truck-activation/truck-activation-column-chart/truck-activation-column-chart.component';
import { TruckActivationLineChartComponent } from './components/report-page/report-truck-activation/truck-activation-line-chart/truck-activation-line-chart.component';
import { TruckActivationPieChartComponent } from './components/report-page/report-truck-activation/truck-activation-pie-chart/truck-activation-pie-chart.component';
import { TruckActivityCardsComponent } from './components/report-page/report-truck-activity/truck-activity-cards/truck-activity-cards.component';
import { ReportActivityComponent } from './components/report-page/report-truck-activity/report-activity/report-activity.component';
import { TruckActivityColumnChartComponent } from './components/report-page/report-truck-activity/truck-activity-column-chart/truck-activity-column-chart.component';
import { TruckActivityPieChartComponent } from './components/report-page/report-truck-activity/truck-activity-pie-chart/truck-activity-pie-chart.component';
import { TruckActivityLineChartComponent } from './components/report-page/report-truck-activity/truck-activity-line-chart/truck-activity-line-chart.component';
import { TruckMileageCardsComponent } from './components/report-page/report-mileage-per-truck/truck-mileage-cards/truck-mileage-cards.component';
import { TruckMileageColumnChartComponent } from './components/report-page/report-mileage-per-truck/truck-mileage-column-chart/truck-mileage-column-chart.component';
import { TruckMileageLineChartComponent } from './components/report-page/report-mileage-per-truck/truck-mileage-line-chart/truck-mileage-line-chart.component';
import { TruckMileagePieChartComponent } from './components/report-page/report-mileage-per-truck/truck-mileage-pie-chart/truck-mileage-pie-chart.component';
import { TruckMileageTableComponent } from './components/report-page/report-mileage-per-truck/truck-mileage-table/truck-mileage-table.component';

@NgModule({
  declarations: [
    ReportTruckProfitabilityComponent,
    ReportTruckActivityComponent,
    ReportTruckActivationComponent,
    ReportMileagePerTruckComponent,
    TruckProfitabilityCardsComponent,
    TruckProfitabilityColumnChartComponent,
    TruckProfitabilityPieChartComponent,
    TruckProfitabilityLineChartComponent,
    ReportProfitabilityComponent,
    TruckActivationCardsComponent,
    TruckActivationColumnChartComponent,
    TruckActivationLineChartComponent,
    TruckActivationPieChartComponent,
    TruckActivityCardsComponent,
    ReportActivityComponent,
    TruckActivityColumnChartComponent,
    TruckActivityPieChartComponent,
    TruckActivityLineChartComponent,
    TruckMileageCardsComponent,
    TruckMileageColumnChartComponent,
    TruckMileageLineChartComponent,
    TruckMileagePieChartComponent,
    TruckMileageTableComponent
  ],
  imports: [
    CommonModule,
    ReportsRoutingModule,
    SharedModule
  ],
  providers: [ReportsService]
})
export class ReportsModule { }

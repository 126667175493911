import { Component, OnDestroy, OnInit } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { Subscription } from 'rxjs';
import { DialogRef } from '@angular/cdk/dialog';
import { MatDialog } from '@angular/material/dialog';
import { AddEditVariableDialogComponent } from './add-edit-variable-dialog/add-edit-variable-dialog.component';
import { DeleteVariableConfirmationDialogComponent } from './delete-variable-confirmation-dialog/delete-variable-confirmation-dialog.component';
import { SafetyService } from '@app/modules/safety/services/safety.service';

@Component({
  selector: 'app-variables-dialog',
  templateUrl: './variables-dialog.component.html',
  styleUrls: ['./variables-dialog.component.scss']
})
export class VariablesDialogComponent implements OnInit, OnDestroy {
  variablesArray: any[] = [];
  
  subscription: Subscription | any;

  constructor(private safetyService: SafetyService,
              private dialog: MatDialog,
              private dialogRef: DialogRef,
              private clipboard: Clipboard) { }

  ngOnInit(): void {
    this.getData();
  }

  getData() {
    this.subscription = this.safetyService.getAllVariables().subscribe((response: any) => {
      console.log(response);
      this.variablesArray = response;
    })
  };
  
  copyOnClick(obj: any) {
    let value: string = obj.name;
    
    if(obj.is_image) {
      value = `<img src="${obj.value}" alt="${obj.name}" />`
    }
    else {
      value = obj.name
    }
    console.log(value)
    this.clipboard.copy(value);
    this.dialogRef.close();
  }

  addEditVariable(obj: any, open: boolean) {
    if(open) {
      let dialogRef = this.dialog.open(AddEditVariableDialogComponent, {
        autoFocus: false,
        panelClass: 'add-edit-dialog-container',
        data: obj
      });
      dialogRef.afterClosed().subscribe((result: any) => {
        if (result) {
          this.getData();
        }
      });
    }
  };

  deleteVariable(obj: any) {
    if(!obj.is_readonly) {

      let dialogRef = this.dialog.open(DeleteVariableConfirmationDialogComponent, {
        autoFocus: false,
        panelClass: 'delete-dialog-container',
      });
      dialogRef.afterClosed().subscribe((result: any) => {
        if(result) {
          this.safetyService.deleteVariable(obj.id).subscribe((response: any) => {
            if(response) {
              this.getData()
            }
            console.log(response);
          });
        }
      });

    }

  };

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TotalLoadsComponent } from './components/total-loads.component';
import { SharedModule } from '../shared/shared.module';
import { TotalLoadsRoutingModule } from './total-loads.routing';

@NgModule({
  declarations: [
    TotalLoadsComponent
  ],
  imports: [
    CommonModule,
    TotalLoadsRoutingModule,
    SharedModule
  ]
})
export class TotalLoadsModule { }

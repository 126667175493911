import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ApexAxisChartSeries, ApexChart, ApexDataLabels, ApexGrid, ApexStroke, ApexXAxis, ApexYAxis, ChartComponent } from 'ng-apexcharts';

export type ChartOptions = {
  series: ApexAxisChartSeries | any;
  chart: ApexChart | any;
  xaxis: ApexXAxis | any;
  yaxis: ApexYAxis | any;
  dataLabels: ApexDataLabels | any;
  grid: ApexGrid | any;
  stroke: ApexStroke | any;
  colors: any;
};

@Component({
  selector: 'app-utilization-cards-graphs',
  templateUrl: './utilization-cards-graphs.component.html',
  styleUrls: ['./utilization-cards-graphs.component.scss']
})
export class UtilizationCardsGraphsComponent implements OnInit {
  //Graph
  @ViewChild("chart") chart: ChartComponent | any;
  public chartOptions: Partial<ChartOptions> | any;
  selectPeriod: FormControl | any;

  //Popup
  isOpened: boolean = false;

  sectionArray: any[] = [
    {img: '../../../../../../assets/img/truck-grey-front.png', active: '../../../../../../assets/img/truck-active-front.png', popupTitle: 'Active trucks'},
    {img: '../../../../../../assets/img/setup-grey.png', active: '../../../../../../assets/img/setup-active.png', popupTitle: 'Trucks in shop'},
    {img: '../../../../../../assets/img/gas-station-gray.png', active: '../../../../../../assets/img/gas-station-active.png', popupTitle: 'Fuel consumption'},
    {img: '../../../../../../assets/img/tire-gray.png', active: '../../../../../../assets/img/tire-active.png', popupTitle: 'Tires'},
    {img: '../../../../../../assets/img/user-gray.png', active: '../../../../../../assets/img/user-active.png', popupTitle: 'Drivers'},
  ];

  activeSection: number = 0;

  //Menu
  isOpenMenu: boolean = false;
  selectArray: any[] = [
    {name: 'By year'},
    {name: 'By quarter'},
    {name: 'By month'},
    {name: 'By week'},
    {name: 'By day'},
  ];
  menuValue: string = 'By month';

  constructor() {
    this.selectPeriod = new FormControl(['day']);
  }

  ngOnInit() {
    this.initGraph('');
  }

  
  initGraph(data: any) {
    let seriesArray: any[] = [
      {name: 'Assigned trucks', data: [10, 20, 100, 30, 46, 70, 242, 321]},
      {name: 'Loaded trucks', data: [20, 50, 200, 20, 86, 30, 321, 453]},
    ];
    this.chartOptions = {
      series: seriesArray,
      chart: {
        toolbar: {
          show: true,
          tools: {
            download: false
          }
        },
        height: 220,
        type: "line",
        zoom: {
          enabled: false
        },
        fontFamily: 'Poppins'
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: "straight",
        width: 1,
      },
      grid: {
        row: {
          colors: ["transparent", "transparent"], 
          opacity: 0.5
        }
      },
      xaxis: {
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Avg']
      },
      yaxis: {
        tooltip: {
          enabled: true
        },
        labels: {
          formatter: (num: number) => {
            let roundedNumber: any = Math.round(num);
            return roundedNumber.toLocaleString("en-US").toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          },
        },
      },
      markers: {
        size: 5
      },
      colors: ['#0030FF', '#FA9120', '#FA0000', '#008000', '#FF00FF', '#800000', '#FE7777', '#0303BB', '#008FA9', '#7550CB', '#6A6D6E'],
    };
  }

  onlyOneSelect(value: string) {
    this.selectPeriod = new FormControl([value]);
   // this.initGraph(this.transformService.countDataByPeriodTruck(this.graphData, this.selectPeriod.value[0]));
  };

  selectPeriodMenu(obj: any) {
    this.menuValue = obj.name;
    this.isOpenMenu = false;
  }

}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SharedService } from '@app/modules/shared/services/shared.service';
import { TransformService } from '@app/modules/shared/services/transform.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-global-search',
  templateUrl: './global-search.component.html',
  styleUrls: ['./global-search.component.scss']
})
export class GlobalSearchComponent implements OnInit, OnDestroy {
  isOpen: boolean = false;
  searchText: string = '';

  data: any[] = [];

  subscription: Subscription | any;

  constructor(private transformService: TransformService,
              private sharedService: SharedService, 
              private router: Router) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
  }

  ngOnInit(): void {
    this.subscription = this.sharedService.globalSearchApi(this.transformService.filterParams).subscribe((users: any) => {
      this.data = users;
      console.log(users);
    });

  }

  searchProfile(obj: any) {
    if (obj.type === 'driver') {
      this.router.navigate(['/admin/drivers/filter-drivers/driver-profile', obj.id]);
    }; 
    if(obj.type === 'dispatcher') {
      this.router.navigate(['/admin/dispatchers/dashboard-dispatch-profile-page', obj.id]);
    };
    if(obj.type === 'truck') {
      this.router.navigate(['/admin/trucks/truck-profile', obj.name]);
    };
    this.isOpen = false;
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
  
}


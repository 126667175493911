import { Component, Input, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { ApexAxisChartSeries, ApexChart, ApexDataLabels, ApexFill, ApexGrid, ApexLegend, ApexResponsive, ApexStroke, ApexXAxis, ChartComponent } from 'ng-apexcharts';
import { Subscription } from 'rxjs';
import { RecruitingService } from '../../services/recruiting.service';

export type ChartOptions = {
  series: ApexAxisChartSeries | any;
  chart: ApexChart | any;
  xaxis: ApexXAxis | any;
  dataLabels: ApexDataLabels | any;
  grid: ApexGrid | any;
  stroke: ApexStroke | any;
};

export type ChartOptions2 = {
  series: ApexAxisChartSeries | any;
  chart: ApexChart | any;
  responsive: ApexResponsive | any;
  labels: string | any;
  colors: string | any;
  legend: ApexLegend | any;
  fill: ApexFill | any;
};

@Component({
  selector: 'app-recruiters-graph',
  templateUrl: './recruiters-graph.component.html',
  styleUrls: ['./recruiters-graph.component.scss']
})
export class RecruitersGraphComponent implements OnInit, OnDestroy {
  @ViewChild("chart") chart: ChartComponent | any;
  public chartOptions: Partial<ChartOptions> | any;
  public chartOptions2: Partial<ChartOptions> | any;
  @Input() date: any;
  subscription1: Subscription | any;
  subscription2: Subscription | any;
  show: string = 'bar';

  objOfArrays: any;

  graphSelected: UntypedFormControl;

  barChartData: any;

  barChartOptions: any;

  constructor(private dataService: RecruitingService) {
    this.graphSelected = new UntypedFormControl(['bar']);
    this.barChartOptions = {
      responsive: true,
      scales: {
        x: {},
        y: {}
      },
      plugins: {
        legend: {
          display: true,
        },
        datalabels: {
          anchor: 'end',
          align: 'end'
        }
      }
    }
}

  ngOnInit(): void {
    this.getData(this.date);
    this.dateChange();
  }

  getData(dateObj: any) {
    let obj1: any = { labels: [], series: [] };
    let obj2: any = { labels: [], series: [] };
    this.subscription1 = this.dataService.getAllRectuiters(dateObj.startDate, dateObj.endDate).subscribe(
      (response: any) => {

        let activeRectuiters: any[] = response[0].data;
        let exRectuiters: any = response[1].data;

        let activeRectuitersByName: any = this.transformDataForGraph(activeRectuiters);
        let exRectuitersByName: any = this.transformDataForGraph(exRectuiters);

        for (let key in activeRectuitersByName) {
          let num = 0;
          obj1.labels.push(key);
          for (let key2 in activeRectuitersByName[key]) {
            num += activeRectuitersByName[key][key2].number;
          }
          obj1.series.push(num);
        }


        for (let key in exRectuitersByName) {
          let num = 0;
          obj2.labels.push(key);
          for (let key2 in exRectuitersByName[key]) {
            num += exRectuitersByName[key][key2].number;
          }
          obj2.series.push(num);
        }

        console.log(obj1)
        console.log(obj2);
        this.objOfArrays = [obj1, obj2];
        this.initCertainGraph(this.show)
      });
  };

  transformDataForGraph(dataArr: any) {
    return dataArr.reduce((acc: any, date: any) => {

      if (!acc[date.recruiter]) {
        acc[date.recruiter] = [];
      }

      acc[date.recruiter].push(date);

      return acc;

    }, {});
  }

  dateChange() {
    this.subscription2 = this.dataService.dateSubject.subscribe((dateObj: any) => {
      this.getData(dateObj);
    });
  };

  //Init certain graph

  initCertainGraph(type: string) {
    if (type === 'bar') {
      this.initBarChartData(this.objOfArrays);
    };

    if (type === 'area' || type === 'line') {
      this.initGraphAreaLine(this.objOfArrays, type);
    };

    if (type === 'pie') {
      this.initPieChart(this.objOfArrays);
    };
  }

  //Graph bar

  initBarChartData(objOfArrays: any) {
    this.barChartData = {
      labels: objOfArrays[0].labels,
      datasets: [
        { data: objOfArrays[0].series, label: 'CURRENT', backgroundColor: '#100ca1', borderColor: '#0250cc' },
        { data: objOfArrays[1].series, label: 'EX', backgroundColor: '#f4661c', borderColor: '#FFA500' }
      ]
    };
  };

  //Graph line area

  initGraphAreaLine(objOfArrays: any, type: string) {
    this.chartOptions = {
      series: [
        {
          name: ['CURRENT'],
          data: objOfArrays[0].series
        },
        {
          name: ['EX'],
          data: objOfArrays[1].series
        },
      ],
      chart: {
        height: 350,
        type: type,
        zoom: {
          enabled: false
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: "smooth"
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5
        }
      },
      xaxis: {
        categories: objOfArrays[0].labels
      }
    }
  };

  initPieChart(objOfArrays: any) {
    let sumCurrent = 0;
    let sumEx = 0;

    objOfArrays[0].series.forEach((current: number) => {
      sumCurrent += current;
    });
    objOfArrays[1].series.forEach((ex: number) => {
      sumEx += ex;
    });

    this.chartOptions2 = {
      fill: {
        type: 'gradient'
      },
      colors: ['#100ca1', '#868686', '#f4661c'],
      series: [sumCurrent, sumEx],
      chart: {
        width: 360,
        type: "pie",
      },
      labels: ['CURRENT', 'EX'],
      legend: {
        position: 'bottom'
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: "bottom"
            }
          }
        }
      ]
    };
  }

  onlyOneSelect(value: string) {
    this.graphSelected = new UntypedFormControl([value]);
  };

  onSubmit(matSelect: MatSelect) {
    matSelect.close();
    this.show = matSelect.value[0];
    this.initCertainGraph(matSelect.value[0])
  };

  ngOnDestroy(): void {
    this.subscription1.unsubscribe();
    this.subscription2.unsubscribe();
  }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { Subscription } from 'rxjs';
import { FullAppService } from '@app/modules/full-app/services/full-app.service';
import { IFields } from '@app/modules/full-app/models/template-fields.model';
import { DeleteConfirmationDialogComponent } from '@app/modules/shared/components/delete-confirmation-dialog/delete-confirmation-dialog.component';

@Component({
  selector: 'app-inputs-settings',
  templateUrl: './inputs-settings.component.html',
  styleUrls: ['./inputs-settings.component.scss']
})
export class InputsSettingsComponent implements OnInit, OnDestroy {
  inputsArray: IFields[] = [];
  inputObj: IFields = {
    id: 0,
    inputTitle: '',
    inputTitle2: '',
    inputValue: '',
    inputValue2: '',
    inputArray: '',
    inputPrefix: '',
    inputSuffix: '',
    inputPrefix2: '',
    inputSuffix2: '',
    option: 0,
    dropdownValue: '',
    dropdownValue2: '',
    dropdownArray: '',
    dropdownArray2: '',
    fixedValue: '',
    calendarTitle: '',
    date: '',
    users: 0
  };

 //Input Type menu
 isOpenTypeMenu: boolean = false;
 typeValue: string = '';
 typeArray: any[] = [
   {name: 'Basic input', option: 1},
   {name: 'Input with dropdown', option: 2},
   {name: 'Select with dropdown', option: 3},
   {name: 'Two inputs with one dropdown', option: 4},
   {name: 'Two inputs with two dropdown', option: 5},
   {name: 'Calendar', option: 6}
 ];

 //Prefix/Suffix
 isOpenPrefixSuffixMenu: boolean = false;
 isOpenPrefixSuffixMenu2: boolean = false;
 prefixSuffixArray: string[] = ['Prefix', 'Suffix'];
 prefixSuffixValue: string = 'Prefix';
 prefixSuffixInputValue: string = '';
 prefixSuffixValue2: string = 'Prefix';
 prefixSuffixInputValue2: string = '';

 //Subscription
 subscription: Subscription = new Subscription();

 constructor(public dialogRef: MatDialogRef<InputsSettingsComponent>,
            private dialog: MatDialog, 
            private fullAppService: FullAppService) { }

 ngOnInit(): void {
    this.subscription = this.fullAppService.getAllInputs().subscribe((response: IFields[]) => {
      console.log(response);
      this.inputsArray = response;
    })
 }

 addInput() {
   if(this.prefixSuffixValue === 'Prefix') {
     this.inputObj.inputPrefix = this.prefixSuffixInputValue;
   }
   else {
     this.inputObj.inputSuffix = this.prefixSuffixInputValue;
   }

   if(this.prefixSuffixValue2 === 'Prefix') {
     this.inputObj.inputPrefix2 = this.prefixSuffixInputValue2;
   }
   else {
     this.inputObj.inputSuffix2 = this.prefixSuffixInputValue2;
   }

   this.fullAppService.saveInput(this.inputObj).subscribe((response: IFields) => {
    this.inputsArray.push({...response});
  });
   this.resetInputObj();
 };

 deleteInput(id: number, index: number) {
  let dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
    autoFocus: false,
    panelClass: 'delete-dialog-container',
  });
  dialogRef.afterClosed().subscribe((result: any) => {
    if(result) {
      this.fullAppService.deleteInput(id).subscribe((response: boolean) => {
        if(response) {
          this.inputsArray.splice(index, 1);
        }
        console.log(response);
      });
    }
  });
 };

 closeDialog() {
  this.dialogRef.close(this.inputsArray);
 };

 resetInputObj() {
  this.typeValue = '';
  this.inputObj = {
    id: 0,
    inputTitle: '',
    inputTitle2: '',
    inputValue: '',
    inputValue2: '',
    inputArray: '',
    inputPrefix: '',
    inputSuffix: '',
    inputPrefix2: '',
    inputSuffix2: '',
    option: 0,
    dropdownValue: '',
    dropdownValue2: '',
    dropdownArray: '',
    dropdownArray2: '',
    fixedValue: '',
    calendarTitle: '',
    date: '',
    users: 0
  };
  this.prefixSuffixInputValue = '';
  this.prefixSuffixInputValue2 = '';
 };

 ngOnDestroy(): void {
    this.subscription?.unsubscribe();
 }

}

import { Component } from '@angular/core';

@Component({
  selector: 'app-load-details-dialog',
  templateUrl: './load-details-dialog.component.html',
  styleUrls: ['./load-details-dialog.component.scss']
})
export class LoadDetailsDialogComponent {

}

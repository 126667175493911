import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import moment = require('moment');
import { SharedService } from '../../services/shared.service';
import { ITask } from '@app/modules/tasks/models/task-model';
import { TaskNoteDialogComponent } from '../task-note-dialog/task-note-dialog.component';

@Component({
  selector: 'app-notifications-tasks-snackbar',
  templateUrl: './notifications-tasks-snackbar.component.html',
  styleUrls: ['./notifications-tasks-snackbar.component.scss']
})

export class NotificationsTasksSnackbarComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public obj: any,
  private sharedService: SharedService,
  public dialogRef: MatDialogRef<NotificationsTasksSnackbarComponent>,
  private dialog: MatDialog) { console.log(this.obj)}

  //Company
  getCompany(type: string) {
    let companyType: string[] = ['SOLO COMPANY', 'TEAM COMPANY'];
    let rentType: string[] = ['SOLO RENT', 'TEAM RENT'];
    let ownerType: string[] = ['OWNER OPERATOR'];
    if(companyType.includes(type)) {
      return 'Company';
    }
    if(rentType.includes(type)) {
      return 'Rent to run';
    }
    if(ownerType.includes(type)) {
      return 'Owner';
    } 
  };

  countDays(date: string) {
    let date_1 = new Date(date);
    let date_2 = new Date();

    let difference = date_1.getTime() - date_2.getTime();
    let totalDays = Math.ceil(difference / (1000 * 3600 * 24));
    if(totalDays < 0) {
      return 'Expired'
    }
    else if(totalDays === 1) {
      return `${totalDays} day left`;
    }
    else {
      return `${totalDays} days left`;
    }
  }

  //Mark as read notification
  markAsRead(showNoteDialog?: boolean) {
    const loggedUser: any = JSON.parse(localStorage.getItem('currentUser'));
    let readedDate = moment().format('YYYY-MM-DDTHH:mm:ss');
    this.sharedService.approveNotification(this.obj.id, false, loggedUser.id, readedDate)
      .subscribe((response) => {
        console.log(response);
        if (response) {
          this.dialogRef.close(true);
          if(showNoteDialog) {
            this.openTaskNoteDialog(this.obj.data);
          }
        }
    })
  };

  openTaskNoteDialog(element: ITask) {
    this.dialog.open(TaskNoteDialogComponent, {
      disableClose: true,
      autoFocus: false,
      panelClass: 'task-note-dialog-container',
      data: {element: element, noteRequired: true}
    });
  };


}

import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'filter-dropdown-one-select',
  templateUrl: './filter-dropdown-one-select.component.html',
  styleUrls: ['./filter-dropdown-one-select.component.scss']
})
export class FilterDropdownOneSelectComponent {
  @Input() config: any = {
    array: [],
    defaultValue: '',
    dropdownValue: '',
    key: '',
    isDate: false,
    width: '',
    disabled: false,
  };
  @Output() triggerEvent = new EventEmitter<string>();
  isOpenOneSelectDropdown: boolean = false;

  selectOption(value: string) {
    this.config.dropdownValue = value;
    this.triggerEvent.emit(value);
    this.isOpenOneSelectDropdown = false;
  }

}

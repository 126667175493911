import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import moment = require('moment');
import { Subscription, catchError, throwError } from 'rxjs';
import * as XLSX from 'xlsx'; 
import { CalendarDialogComponent } from '../calendar-dialog/calendar-dialog.component';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { NgxSpinnerService } from 'ngx-spinner';
import { LoadsDocumentationDialogComponent } from '../loads-documentation-dialog/loads-documentation-dialog.component';
import { RulesService } from '../../services/rules.service';
import { SharedService } from '../../services/shared.service';
import { TransformService } from '../../services/transform.service';

@Component({
  selector: 'app-loads-with-margin-dialog',
  templateUrl: './loads-with-margin-dialog.component.html',
  styleUrls: ['./loads-with-margin-dialog.component.scss']
})
export class LoadsWithMarginDialogComponent implements OnInit, OnDestroy {
  //Date
  dateObj: any = {
    startDate: this.transformService.convertDateToTimestamp(moment().startOf('month').format('ddd, DD/MM YYYY'), 
    '00:00:00'),
    endDate: this.transformService.convertDateToTimestamp(moment().format('ddd, DD/MM YYYY'), moment().format('HH:mm:ss')),
  };

  fileName: string = 'loads-with-margin-table.xlsx';

  displayedColumns: string[] = ['position', 'documentation', 'load_no', 'unit_no', 'driver', 'dispatcher', 'broker', 'driver_status', 
  'pickup_date', 'delivery_date', 'pickup_city', 'delivery_city', 'freight_amount', 'pickup_amount', 'rate', 'margin_percent', 
  'billable_miles', 'shortest_miles', 'company_benefit_miles_percent', 'weight', 'status'];
  sortedData: any = new MatTableDataSource();
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  //Statistics
  avgObj: any = {
    numOfAllLoads: 0,
    amount: 0,
    pickUpStopAmount: 0,
    rate: 0,
    marginPercent: 0,
    billableMiles: 0,
    paidMiles: 0,
    companyBenefitMilesPercent: 0,
    weight: 0,
  };

  //Hover
  className: string = '';
  columnName: string = '';

  //Interval
  interval: any;

  //Spinner
  loaded: boolean = false;
  error: boolean = false;

  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";

  spinnerBgColor: string = this.transformService.spinnerBgColor;
  spinnerType: string = this.transformService.spinnerType;

  subscription: Subscription | any;

  constructor(public transformService: TransformService,
              private sharedService: SharedService,
              private rulesService: RulesService,
              private dialog: MatDialog,
              private router: Router,
              private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.spinner.show('loads-with-margin-table');
    this.getLoadsWithMarginData();
    if(this.rulesService.liveUpdate) {
      this.interval = setInterval(() => {
        this.getLoadsWithMarginData();
      }, this.rulesService.miliseconds);
    }
  }

  getLoadsWithMarginData() {
    this.subscription = this.sharedService.getLoadsWithMargin(this.dateObj.startDate, this.dateObj.endDate, this.transformService.filterParams)
    .pipe(catchError((err: any) => {
      this.spinner.hide('loads-with-margin-table');
      this.loaded = true;
      this.error = true;
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      console.log(response);
      this.sortedData.data = response;
      this.sortedData.sort = this.sort;
      this.countStatistics(response);
      this.spinner.hide('loads-with-margin-table');
      this.loaded = true;
    })
  }

    //Counts statistics
    countStatistics(data: any[]) {

      //Statistics data
      let numOfAllLoads: number = data.length;
  
      //Avg data
      let sumAmount: number = 0;
      let sumPickUpAmount: number = 0;
      let sumMarginPercent: number = 0;
      let sumBillableMiles: number = 0;
      let sumPaidMiles: number = 0;
      let sumWeight: number = 0;

      for(let key in data) {
        sumAmount += data[key].freight_amount;
        sumPickUpAmount += data[key].pickup_amount;
        sumMarginPercent += data[key].margin_percent;
        sumBillableMiles += data[key].billable_miles;
        sumPaidMiles += data[key].shortest_miles;
        sumWeight += data[key].weight;
      }
      
      this.avgObj.numOfAllLoads = numOfAllLoads;
      this.avgObj.amount = '$' + this.transformService.addCommasDots(sumAmount, 'round');
      this.avgObj.pickUpStopAmount = '$' + this.transformService.addCommasDots(sumPickUpAmount, 'round');
      this.avgObj.rate = '$' + this.transformService.addCommasDots(sumAmount / sumBillableMiles); 
      this.avgObj.marginPercent = this.transformService.addCommasDots(sumMarginPercent / numOfAllLoads) + '%';
      this.avgObj.billableMiles = this.transformService.addCommasDots(sumBillableMiles, 'round');
      this.avgObj.paidMiles = this.transformService.addCommasDots(sumPaidMiles, 'round');
      this.avgObj.companyBenefitMilesPercent = this.transformService.addCommasDots((sumBillableMiles - sumPaidMiles) * 100 / sumBillableMiles, 'round');
      this.avgObj.weight = this.transformService.addCommasDots(sumWeight / numOfAllLoads, 'round');
    
    };

  //Calendar
  openDialog() {
    let dialogRef: any = this.dialog.open(CalendarDialogComponent, {
      width: '769px',
      height: '476px',
      autoFocus: false,
      data: {oneRange: true},
      panelClass: 'calendar-dialog-container'
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.dateObj.startDate = result.startDate;
        this.dateObj.endDate = result.endDate;
        this.sortedData.data = [];
        this.error = false;
        this.loaded = false;
  
        this.spinner.show('loads-with-margin-table');
        this.getLoadsWithMarginData();

      }
    });
  }

  //Hover effect
  onMouseOver(columnName: string) {
    this.columnName = columnName;
    this.className = 'hovered-column';
  }

  onMouseOut(columnName: string) {
    this.columnName = columnName;
    this.className = '';
  }

  //Open in new tab
  openInNewTab(route: string) {
    const url = this.router.serializeUrl(this.router.createUrlTree([route]));
    window.open(url, '_blank');
  }

  //Company benefit miles
  getCompanyBenefitMiles(obj: any, value: number) {
    obj.company_benefit_miles_percent = value;
    return this.transformService.addCommasDots(obj.company_benefit_miles_percent, 'round')
  }

  //Open documentation dialog
  openFilesForDownload(loadNo: any) {
    this.sharedService.getAllFilesLoads(loadNo).subscribe((response) => {
      console.log(response);
      this.dialog.open(LoadsDocumentationDialogComponent, {
        width: '500px',
        height: '500px',
        panelClass: 'download-files-dialog',
        autoFocus: false,
        data: {fileNames: response, loadNo: loadNo}
      });
    });
  };

  //Search
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.sortedData.filter = filterValue.trim().toLowerCase();
  }

  //Export to excel
  exportToExcel() {
    let element = document.getElementById('loads-with-margin-table'); 
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    XLSX.writeFile(wb, this.fileName);
  };

  ngOnDestroy(): void {
    clearInterval(this.interval);
    this.subscription?.unsubscribe();
  }

}

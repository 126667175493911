import { Component } from '@angular/core';

@Component({
  selector: 'app-truck-profile-overview',
  templateUrl: './truck-profile-overview.component.html',
  styleUrls: ['./truck-profile-overview.component.scss']
})
export class TruckProfileOverviewComponent {

}

import { HttpEventType } from '@angular/common/http';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { SafetyService } from '@app/modules/safety/services/safety.service';
import moment = require('moment');
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-upload-files-dialog',
  templateUrl: './upload-files-dialog.component.html',
  styleUrls: ['./upload-files-dialog.component.scss']
})
export class UploadFilesDialogComponent implements OnInit, OnDestroy {
  //Divisions
  selectedDivisions: string[] = [];
  isOpenDivision: boolean = false;
  divisionsArray: any[] = [];

  //Truck type
  selectedTypes: string[] = ['Company', 'Rent to run', 'Owner'];
  isOpenTruckType: boolean = false;
  trucksTypeArray: any[] = [
    {type: 'Company', selected: true, filter: ['SOLO COMPANY', 'TEAM COMPANY']},
    {type: 'Rent to run', selected: true, filter: ['SOLO RENT', 'TEAM RENT']},
    {type: 'Owner', selected: true, filter: ['OWNER', 'OWNER OPERATOR']},
  ];
  allTypesObj: any = {selected: true};

  //Truck unit no
  isOpenTruckMenu: boolean = false;
  truckUnitValues: string[] = [];
  allTrucks: any = {selected: false};
  trucksArray: any[] = [];

  allTrucksWithAndWithoutType: any[] = [];

  //Categories
  isOpenCategoryMenu: boolean = false;
  categoryArray: any[] = [];
  categoryValue: string = 'Select a category';
  categoryID: number | undefined = undefined;

  //Upload files
  files: any[] = [];

  //Search
  searchText: string = '';

  //Progress
  pdfLoading: boolean = false;
  showProgress: boolean = false;
  progressDone: number = 0;

  //Subscription
  subscription: Subscription | any;

  constructor(private safetyService: SafetyService,
              public dialogRef: MatDialogRef<any>) { }

  ngOnInit(): void {
    this.subscription = this.safetyService.getTrucksForSearchCategories().subscribe((response: any) => {
      this.trucksArray = response[0];
      this.allTrucksWithAndWithoutType = response[0];
      this.groupByProperty(response[0]);
      this.categoryArray = response[1];
      console.log(response);
    });
  }

  //Select all types
  selectAllTypes() {
    this.allTypesObj.selected = !this.allTypesObj.selected;

    for(let key in this.trucksTypeArray) {
      this.trucksTypeArray[key].selected = this.allTypesObj.selected;
    }

    this.trucksArray = [...this.allTrucksWithAndWithoutType];
  };

  //Select truck type
  selectTruckType(obj: any) {
    obj.selected = !obj.selected;
    let index: number = this.selectedTypes.indexOf(obj.type);

    if(index === -1) {
      this.selectedTypes.push(obj.type);
    }
    else {
      this.selectedTypes.splice(index, 1);
    }
    this.filterData();
  };

  getTypeValues() {
    let value: string = '';

    if(this.selectedTypes.length === 3) {
      value = 'All types';
    }
    else {
      this.selectedTypes.forEach((type: string, index: number) => {
        if(index === 1) {
          value += ', ' + type;
          return
        }
        value += type;
      });
    }

    value = value === '' ? 'Type' : value;

    return value;
  }

   //Date
   dateClicked(event: any, file: any) {
    file.expiration = moment(event._d).format('YYYY-MM-DDTHH:mm:ss');
    file.isOpenCalendar = !file.isOpenCalendar;
  }

  //Select truck unit
  selectAll() {
    this.truckUnitValues = [];
    let complete: boolean = JSON.parse(JSON.stringify(this.allTrucks.selected = !this.allTrucks.selected));
    this.trucksArray.forEach(t => {
      if(complete) {
        this.truckUnitValues.push(t.unit_no);
      }
      (t.selected = complete)
    });
  };

  selectUnitNo(obj: any) {
    obj.selected = !obj.selected;

    let index: number = this.truckUnitValues.indexOf(obj.unit_no);

    if(index === -1) {
      this.truckUnitValues.push(obj.unit_no);
    }
    else {
      this.truckUnitValues.splice(index, 1);
    }
  }

  getTruckValues() {
    let value: string = '';
    this.truckUnitValues.forEach((type: string, index: number) => {
      if(index > 0 && index < 3) {
        value += ', ' + type;
        return
      }

      if(index < 3) { 
        value += type;
      }
    });
    value = value === '' ? '#Truck' : value;
    return value ;
  };

  //Select division
  selectDivision(obj: any) {
    obj.selected = !obj.selected;
    let index: number = this.selectedDivisions.indexOf(obj.division);

    if(index === -1) {
      this.selectedDivisions.push(obj.division);
    }
    else {
      this.selectedDivisions.splice(index, 1);
    }

    this.filterData();
  };

  getDivisionValues() {
    let value: string = '';

    if(this.selectedDivisions.length === 3) {
      value = 'All divisions';
    }
    else {
      this.selectedDivisions.forEach((type: string, index: number) => {
        if(index === 1) {
          value += ', ' + type;
          return
        }
        value += type;
      });
    }

    value = value === '' ? 'Division' : value;
    return value;
  }

  //Upload files
  onFileSelected(event: any) {  
    let length: any = Object.keys(event).length;
    for(let i = 0; i < length; i++) {
      this.addMultipleFiles(event, i);
    }
  }

  addMultipleFiles(event: any, index: number) {
    let fileName = event[index].name;
    let reader: any = new FileReader();
    reader.onload = () => {
    let obj: any = {
      fileName: fileName,
      fileData: reader.result.split(',')[1],
      truckID: -1,
      categoryID: this.categoryID,
      hubCategory: -1,
      uploaded: moment(new Date()).format('MMM DD. YYYY.'),
      uplaodedBy: JSON.parse(localStorage.getItem('currentUser')).first_name,
      expiration: null
    };
    this.files.push(obj);
  }

  reader.readAsDataURL(event[index])
  };
  

  //Select category
  selectCategory(obj: any) {
    this.categoryValue = obj.categoryName;
    this.categoryID = obj.ID;
    this.isOpenCategoryMenu = false;
  };

  //Group by property
  groupByProperty(array: any[]) {
    const groups: any = array.reduce((acc: any, obj: any) => {
      
    if (!acc[obj.companyName]) {
      acc[obj.companyName] = [];
    }
        
    acc[obj.companyName].push(obj);
      
    return acc;
      
    }, {});

    for(let key in groups) {
      this.divisionsArray.push({division: key, selected: true, data: groups[key]});
      this.selectedDivisions.push(key);
    }
  }

  //Filter data
  filterData() {
    let data: any[] = [];

    let filteredData: any[] = [];

    for(let i = 0; i < this.divisionsArray.length; i++) {
      if(this.divisionsArray[i].selected) {
        data = data.concat(this.divisionsArray[i].data);
      }
    };

    for(let key in data) {
      if(this.trucksTypeArray[0].selected && this.trucksTypeArray[0].filter.includes(data[key].driver_status)) {
        filteredData.push(data[key]);
      };

      if(this.trucksTypeArray[1].selected && this.trucksTypeArray[1].filter.includes(data[key].driver_status)) {
        filteredData.push(data[key]);
      };

      if(this.trucksTypeArray[2].selected && this.trucksTypeArray[2].filter.includes(data[key].driver_status)) {
        filteredData.push(data[key]);
      };
    }

    this.trucksArray = filteredData;
  };

  //Delete file
  deleteFile(i: number) {
    this.files.splice(i, 1);
  }

  //Upload files
  uploadFiles() {
    let obj: any = {
      files: this.files,
      truckIDs: [],
      categoryID: this.categoryID
    }
    delete this.files[0].uploaded
    for(let key in this.trucksArray) {
      if(this.trucksArray[key].selected) {
        obj.truckIDs.push(this.trucksArray[key].unit_no);
      }
    }
    this.safetyService.uploadFilesForTrucks(obj).subscribe((httpResponse: any) => {
      this.showProgress = true;
      if (httpResponse.type === HttpEventType.Response) {
        this.showProgress = false;
        this.progressDone = 0;
        this.dialogRef.close(true);
      }
      if (httpResponse.type === HttpEventType.UploadProgress) {
        this.progressDone = Math.round(100 * httpResponse.loaded / httpResponse.total);
      }
      console.log(httpResponse) 
    })
  };

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { DeleteConfirmationDialogComponent } from '@app/modules/shared/components/delete-confirmation-dialog/delete-confirmation-dialog.component';
import { SharedService } from '@app/modules/shared/services/shared.service';

import moment = require('moment');
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-department-dialog',
  templateUrl: './department-dialog.component.html',
  styleUrls: ['./department-dialog.component.scss']
})
export class DepartmentDialogComponent implements OnInit, OnDestroy {
  //Department name
  departmentName: string = '';

  //Department email
  departmentEmail: string = '';

  //Divisions 
  isOpenDivisionMenu: boolean = false;
  divisionsArray: any[] = [];
  divisionValue: string = '';
  divisionId: number | undefined = undefined; 

  //Obj edited
  objEdit: any;

  //Table data
  departmentsArray: any[] = [];
  displayedColumns: string[] = ['name', 'email', 'division', 'created_by', 'created_date', 'edited_by', 'edited_date', 'action'];

  //Show create update menu
  showTable: boolean = true;
  editMode: boolean = false;

  //All data
  dataDepartments: any[] = [];

  subscription: Subscription | any;

  constructor(public dialogRef: MatDialogRef<any>,
              private dialog: MatDialog,
              private sharedService: SharedService) { }

  ngOnInit(): void {
    this.getTableData();
  };

  getTableData() {
    this.subscription = this.sharedService.getEmployeeDepartmentsAndCompanies()
    .subscribe((response: any) => {
      this.departmentsArray = response[0];
      this.divisionsArray = response[1].divisions;
      this.dataDepartments = response;
      console.log(response);
    });
  }

  createEditDepartment(editMode: boolean, obj: any) {
    this.resetInputs();
    this.showTable = false;
    this.editMode = editMode;
    if(this.editMode) {
      this.departmentName = obj.name;
      this.departmentEmail = obj.email;
      this.divisionValue = obj.division;
      this.divisionId = obj.division_id; 
      this.objEdit = obj;
    }
  }

  backBtn() {
    this.resetInputs();
    this.showTable = true;
    this.editMode = false;
  }

  saveUpdateData() {
    let loggedUser: any = JSON.parse(localStorage.getItem('currentUser'));
    let obj: any = {
      id: 0,
      name: this.departmentName,
      email: this.departmentEmail,
      ext: '',
      division: this.divisionValue,
      division_id: this.divisionId,
      created_by: loggedUser.first_name + ' ' + loggedUser.last_name,
      created_date: moment().format('YYYY-MM-DDTHH:mm:ss'),
      edited_by: '',
      edited_date: ''
    }
    if(this.editMode) {
      obj.id = this.objEdit.id;
      obj.created_by = this.objEdit.created_by;
      obj.created_date = this.objEdit.created_date;
      obj.edited_by = loggedUser.first_name + ' ' + loggedUser.last_name,
      obj.edited_date = moment().format('YYYY-MM-DDTHH:mm:ss')
    }

    this.sharedService.createUpdateEmployeeDepartment(obj).subscribe((response: any) => {
      if(response) {
        this.getTableData();
        this.resetInputs();
        this.showTable = true;
        this.editMode = false;
      }
    });
  };

  deleteDepartment(id: number, index: number) {
    let dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
      autoFocus: false,
      panelClass: 'delete-dialog-container',
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
        this.sharedService.deleteEmloyeeDepartment(id).subscribe((response: any) => {
          if(response) {
            this.getTableData();
          }
        });
      }
    })
  }

  sortData(sort: Sort) {
    const data = this.departmentsArray.slice();
    if (!sort.active || sort.direction === '') {
      this.departmentsArray = data;
      return;
    }
    this.departmentsArray = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'name':
          return compare(a.name, b.name, isAsc);
        case 'email':
          return compare(a.email, b.email, isAsc);
        case 'division':
          return compare(a.division, b.division, isAsc);
        case 'created_by':
          return compare(a.created_by, b.created_by, isAsc);
        case 'created_date':
          return compare(new Date(a.created_date), new Date(b.created_date), isAsc);
        case 'edited_by':
          return compare(a.edited_by, b.edited_by, isAsc);
        case 'edited_date':
          return compare(new Date(a.edited_date), new Date(b.edited_date), isAsc);
        default:
          return 0;
      }
    });
  }

  resetInputs() {
    this.departmentName = '';
    this.departmentEmail = '';
    this.divisionValue = '';
    this.divisionId = undefined; 
    this.objEdit = undefined;
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  };

}

function compare(a: number | any, b: number | any, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Sort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { SharedService } from '@app/modules/shared/services/shared.service';
import { ApexAxisChartSeries, ApexChart, ApexDataLabels, ApexGrid, ApexLegend, ApexResponsive, ApexStroke, ApexXAxis, ApexYAxis, ChartComponent } from 'ng-apexcharts';
import { NgxSpinnerService } from 'ngx-spinner';
import { catchError, Subscription, throwError } from 'rxjs';
import { DriversPerformanceService } from '../../services/drivers-performance.service';
import { TransformService } from '@app/modules/shared/services/transform.service';
import { RulesService } from '@app/modules/shared/services/rules.service';

export type ChartOptions = {
  series: ApexAxisChartSeries | any;
  chart: ApexChart | any;
  xaxis: ApexXAxis | any;
  responsive: ApexResponsive | any;
  labels: string | any;
  colors: string | any;
  legend: ApexLegend | any;
  tooltip: any;
};

export type ChartOptions1 = {
  series: ApexAxisChartSeries | any;
  chart: ApexChart | any;
  xaxis: ApexXAxis | any;
  yaxis: ApexYAxis | any;
  dataLabels: ApexDataLabels | any;
  grid: ApexGrid | any;
  stroke: ApexStroke | any;
  colors: any;
};

@Component({
  selector: 'app-drivers-performance-utilization',
  templateUrl: './drivers-performance-utilization.component.html',
  styleUrls: ['./drivers-performance-utilization.component.scss']
})
export class DriversPerformanceUtilizationComponent implements OnInit, OnDestroy {
  @ViewChild("chart") chart: ChartComponent | any;
  public chartOptions: Partial<ChartOptions> | any;
  public chartOptions1: Partial<ChartOptions1> | any;
  
  @Input() date: any;

  dataSource: any = [];

  sortedData: any;

  oneRowClickedColumns: boolean[] = [false, false, false, false];

  listByDays: any;

  graphData: any[] = [];

  compare: boolean = false;

  clickedColumn: string | undefined;

  selectedRow: any;

  selectedIndexArray: number[] = [];

  displayedColumns: string[] = ['position', 'driver', 'dispatcher', 'type', 'mileage', 'driving', 'hometime', 
  'mainenance'];

  //Select menu
  isOpen: boolean = false;
  value: string = 'All';
  valuesArray: any[] = [
    {viewValue: 'Active'},
    {viewValue: 'Inactive'},
    {viewValue: 'All'}
  ]
  //Checkbox menu
  isOpen1: boolean = false;
  value1: string = 'All';
  valuesArray1: any[] = [
    {viewValue: 'Company', selected: false, allowedArray: ['SOLO COMPANY', 'TEAM COMPANY']},
    {viewValue: 'Rent to run', selected: false, allowedArray: ['SOLO RENT', 'TEAM RENT']},
    {viewValue: 'Owner', selected: false, allowedArray: ['OWNER']},
    {viewValue: 'All', selected: false, allowedArray: ['SOLO COMPANY', 'TEAM COMPANY', 'SOLO RENT', 'TEAM RENT', 'OWNER', '']}
  ]

  //Statistics cards
  objStatisticsCards: any = {
    driving: 0,
    hometime: 0,
    maintenance: 0,
    loadIssue: 0,
    hr34Rest: 0,
    other: 0
  };

  //Selected cards
  cardsSelected: any[] = [
    {selected: false}, {selected: false}, {selected: false},
    {selected: false}, {selected: false}, {selected: false}
  ];

  showLineChart: boolean = false;

  //Linechart
  selectPeriod: FormControl | any;
  smallGraphData: any[] = [];

  //Interval
  interval: any;

  loaded: boolean = false;

  error: boolean = false;

  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";

  spinnerBgColor: string = this.transformService.spinnerBgColor;
  spinnerType: string = this.transformService.spinnerType;

  subscription1: Subscription | any;
  subscription2: Subscription | any;

  constructor(public transformService: TransformService, 
              private sharedService: SharedService,
              private driversPerformanceService: DriversPerformanceService,
              private spinner: NgxSpinnerService,
              private rulesService: RulesService,
              private router: Router) {
    this.selectPeriod = new FormControl(['day']);
  }

  ngOnInit(): void {  
    this.spinner.show('driver-utilization');  
    this.getTableDataUtilization();
    this.dateChanged();
    if(this.rulesService.liveUpdate) {
      this.interval = setInterval(() => {
        this.getTableDataUtilization();
      }, this.rulesService.miliseconds);
    }
  }

  getTableDataUtilization() {
    this.subscription1 = this.driversPerformanceService.getUtilizationData(this.date.startDate, this.date.endDate, this.transformService.filterParams)
    .pipe(catchError((err: any) => {
      this.spinner.hide('driver-utilization');
      this.loaded = true;
      this.error = true;
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      console.log(response);
      this.reset();
      this.calculateCardsData(response[0]);
      this.smallGraphData = response[0];
      this.listByDays = response[1].list;
      this.dataSource = JSON.parse(JSON.stringify(response[1].total));
      this.sortedData = response[1].total;
      this.spinner.hide('driver-utilization');
      this.loaded = true;
    })
  }

  dateChanged() {
    this.subscription2 = this.driversPerformanceService.driverPerformanceDateChanged.subscribe((response: any) => {
      this.date.startDate = response.startDate;
      this.date.endDate = response.endDate;
      this.sortedData = undefined;

      this.error = false;
      this.loaded = false;

      this.spinner.show('driver-utilization');
      this.getTableDataUtilization();
    })
  }

  sortData(sort: Sort) {
    const data = this.sortedData.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'position':
          return compare(a.position, b.position, isAsc);
        case 'driver':
          return compare(a.driver, b.driver, isAsc);
        case 'dispatcher':
          return compare(a.dispatcher, b.dispatcher, isAsc);
        case 'type':
          return compare(a.type, b.type, isAsc);
        case 'mileage':
          return compare(a.mileage, b.mileage, isAsc);
        case 'driving':
          return compare(a.driving, b.driving, isAsc);
        case 'hometime':
          return compare(a.hometime, b.hometime, isAsc);
        case 'mainenance':
          return compare(a.mainenance, b.mainenance, isAsc);
        default:
          return 0;
      }
    });
  }

  //Linechart
  initGraph(data: any) {
    console.log(data);
    let array: any[] = [
      {name: 'DRIVING', data: data.totalT1, selected: this.cardsSelected[0].selected},
      {name: 'HOMETIME', data: data.totalT2, selected: this.cardsSelected[1].selected},
      {name: 'MAINTENANCE', data: data.totalT3, selected: this.cardsSelected[2].selected},
      {name: 'LOAD ISSUE', data: data.totalT4, selected: this.cardsSelected[3].selected},
      {name: '34HR RESET', data: data.totalT5, selected: this.cardsSelected[4].selected},
      {name: 'OTHER', data: data.totalT6, selected: this.cardsSelected[5].selected},
    ];
    let seriesArray: any[] = [];
    for(let key in array) {
      if(array[key].selected) {
        seriesArray.push(array[key]);
      }
    }
    console.log(array);
    console.log(seriesArray);
    this.smallGraphData = seriesArray.length === 0 ? this.smallGraphData = [] : this.smallGraphData;
    this.chartOptions1 = {
      series: seriesArray,
      chart: {
        toolbar: {
          show: true,
          tools: {
            download: false
          }
        },
        height: 350,
        type: "line",
        zoom: {
          enabled: false
        },
        fontFamily: 'Poppins'
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: "straight",
        width: 4,
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"], 
          opacity: 0.5
        }
      },
      xaxis: {
        categories: data.categories
      },
      yaxis: {
        tooltip: {
          enabled: true
        },
        labels: {
          formatter: (num: number) => {
            let roundedNumber: any = Math.round(num);
            return roundedNumber.toLocaleString("en-US").toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          },
        },
      },
      markers: {
        size: 5
      },
      colors: ['#0030FF', '#FA9120', '#FA0000', '#008000', '#FF00FF', '#800000', '#FE7777', '#0303BB', '#008FA9', '#7550CB', '#6A6D6E'],
    };
  }

  //Pie chart
  pieChart(obj: any) {
    return {
      colors: ['#003fa2', '#ff900c', '#b90000', '#65a704', '#8b8b8b', '#FE7777'],
      series: [obj.driving, obj.hometime, obj.maintenance, obj.loadIssue, obj.hr34rest, obj.other],
      chart: {
        width: 400,
        type: "pie",
        fontFamily: 'Poppins'
      },
      labels: ['DRIVING', 'HOMETIME', 'MAINTENANCE', 'LOAD ISSUE', '34HR RESET', 'OTHER'],
      legend: {
        position: 'bottom'
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: "bottom"
            }
          }
        }
      ]
    };
  };



  selectValue(value: string) {
    this.value = value;
    this.isOpen = false;
    let array: any = JSON.parse(JSON.stringify(this.dataSource));
    let arrayToDisplay: any[] = [];

    if(value === 'All') {
      arrayToDisplay = array;
    }
    else { 
      for(let key in array) {
        if(array[key].active.toLowerCase() === value.toLowerCase()) {
          arrayToDisplay.push(array[key]);
        }
      }
    }
    this.sortedData = arrayToDisplay;
  }

  selectValue1(obj: any, value: string) {
    this.value1 = value;
    this.isOpen1 = false;
    let array: any = JSON.parse(JSON.stringify(this.dataSource));
    let arrayToDisplay: any[] = [];
    if(value === 'All') {
      this.value1 = 'All';
      arrayToDisplay = array;
    } 
    else {

      for(let key in array) {

        if(obj.allowedArray.includes(array[key].type)) {
          arrayToDisplay.push(array[key]);
        }
      }
    }
    this.sortedData = arrayToDisplay;
  }

  //Calculate percentage
  calculateCardsData(data: any[]) {
    let totalDrivers: number = data.length;
    let sumDriving: number = 0;
    let sumHometime: number = 0;
    let sumMaintenance: number = 0;
    let sumLoadIssue: number = 0;
    let sum34HrRest: number = 0;
    let sumOther: number = 0;

    for(let key in data) {
      sumDriving += data[key].driving;
      sumHometime += data[key].hometime;
      sumMaintenance += data[key].mainenance;
      sumLoadIssue += data[key].load_issue;
      sum34HrRest += data[key].rest;
      sumOther += data[key].other;
    };  

    this.objStatisticsCards.driving = this.transformService.addCommasDots(((sumDriving / totalDrivers) * 100), 'round');
    this.objStatisticsCards.hometime = this.transformService.addCommasDots(((sumHometime / totalDrivers) * 100), 'round');
    this.objStatisticsCards.maintenance = this.transformService.addCommasDots(((sumMaintenance / totalDrivers) * 100), 'round');
    this.objStatisticsCards.loadIssue = this.transformService.addCommasDots(((sumLoadIssue / totalDrivers) * 100), 'round');
    this.objStatisticsCards.hr34Rest = this.transformService.addCommasDots(((sum34HrRest / totalDrivers) * 100), 'round');
    this.objStatisticsCards.other = this.transformService.addCommasDots(((sumOther / totalDrivers) * 100), 'round');

    let pieObj: any = {
      driving: sumDriving, 
      hometime: sumHometime, 
      maintenance: sumMaintenance, 
      loadIssue: sumLoadIssue, 
      hr34rest: sum34HrRest,
      other: sumOther
    }

    this.chartOptions = this.pieChart(pieObj);
  }

  //Show small graph
  showSmallGraph(obj: any) {
    obj.selected = !obj.selected;
    this.initGraph(this.transformService.countUtilization(this.smallGraphData, this.selectPeriod.value[0]));
  }

  onlyOneSelect(value: string) {
    this.selectPeriod = new FormControl([value]);
    this.initGraph(this.transformService.countUtilization(this.smallGraphData, this.selectPeriod.value[0]));
  };

  //Big graph
  select(obj: any, key: string, index: number, title: string, oneRowColumn?: any) {
    if (!this.compare) {
      this.selectedRow !== index && this.selectedRow !== undefined ? this.reset() : this.selectedRow = index;
      this.clickedColumn = key;
      this.oneRowClickedColumns[oneRowColumn] = !this.oneRowClickedColumns[oneRowColumn];
      let array: any[] = [];
      this.selectedRow = index;

      for (let key in this.listByDays) {
        let name = this.listByDays[key].driver;
        if (obj.driver === name) {
          array.push(this.listByDays[key]);
        }
      }

      let objForGraph = { key: this.clickedColumn, data: array, title: title, driverName: obj.driver, reset: false }
      this.driversPerformanceService.driverPerformanceUtilizationLineChart.next(objForGraph);
    } else {

      if (this.selectedIndexArray.length === 0) {
        this.clickedColumn = key;
      }

      if (this.clickedColumn === key) {

        let res = this.selectedIndexArray.findIndex((num: number) => num === index);

        if (res === -1) {
          this.selectedIndexArray.push(index);
        } else {
          this.selectedIndexArray.splice(res, 1);
        }
        let array: any[] = [];

        for (let key in this.listByDays) {
          let name = this.listByDays[key].driver;
          if (obj.driver === name) {
            array.push(this.listByDays[key]);
          }
        }

        let objForGraph = { key: this.clickedColumn, data: array, title: title, driverName: obj.driver, reset: false }
        this.driversPerformanceService.driverPerformanceUtilizationLineChart.next(objForGraph);
      }
    }
  }

  setClass(index: number, clickedColumn: string, oneRowIndex?: any) {
    let className: string = '';

    if (!this.compare && this.oneRowClickedColumns[oneRowIndex] && this.selectedRow === index) {
      return className = 'active';
    }

    if (this.selectedIndexArray.includes(index) && clickedColumn === this.clickedColumn) {
      className = 'active';
    }

    return className;
  }

  reset() {
    let objForGraph = { key: undefined, data: undefined, title: undefined, driverName: undefined, reset: true };
    this.selectedIndexArray = [];
    this.oneRowClickedColumns = [false, false, false, false, false, false, false, false, false, false];
    this.graphData = [];
    this.clickedColumn = undefined;
    this.driversPerformanceService.driverPerformanceUtilizationLineChart.next(objForGraph);
  }

  //Graph btns
  graphBtns(linechart: boolean) {
    this.showLineChart = linechart;
  };

  onChange() {
    this.compare = !this.compare;
    this.reset();
  };

  //Open in new tab
  openInNewTab(route: string) {
    const url = this.router.serializeUrl(this.router.createUrlTree([route]));
    window.open(url, '_blank');
  };

  ngOnDestroy(): void {
    clearInterval(this.interval);
    this.subscription1?.unsubscribe();
    this.subscription2?.unsubscribe();
  }

}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}


import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TransformService } from '@app/modules/shared/services/transform.service';

@Component({
  selector: 'app-truck-profitability-info-dialog',
  templateUrl: './truck-profitability-info-dialog.component.html',
  styleUrls: ['./truck-profitability-info-dialog.component.scss']
})
export class TruckProfitabilityInfoDialogComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public transformService: TransformService) { }

}

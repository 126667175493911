import { Component, Input, OnChanges } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { TransformService } from '@app/modules/shared/services/transform.service';

@Component({
  selector: 'app-prebooked-upcoming-table',
  templateUrl: './prebooked-upcoming-table.component.html',
  styleUrls: ['./prebooked-upcoming-table.component.scss']
})
export class PrebookedUpcomingTableComponent implements OnChanges {

  @Input() data: any;

  displayedColumns: string[] = ['dispatcher', 'driver', 'driver_type', 'truck_no', 'from', 'to', 'picup_date', 'delivery_date',
    'amount', 'miles', 'rate_per_mile', 'weight'];
  dataSource: any[] = [];
  sortedData: any[] = [];

  lengthArray: number = 4;

  constructor(public transformService: TransformService) { }

  ngOnChanges(): void {
    if (this.data) {
      this.dataSource = this.data.upcomingLists;
      this.sortedData = this.data.upcomingLists.slice();
    }
  }

  seeAll() {
    this.lengthArray = this.dataSource.length;
  };

  seeLess() {
    this.lengthArray = 4;
  };

  sortData(sort: Sort) {
    const data = this.dataSource.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'dispatcher':
          return compare(a.dispatcher, b.dispatcher, isAsc);
        case 'driver':
          return compare(a.driver, b.driver, isAsc);
        case 'driver_type':
          return compare(a.driver_type, b.driver_type, isAsc);
        case 'truck_no':
          return compare(a.truck_no, b.truck_no, isAsc);
        case 'from':
          return compare(a.from, b.from, isAsc);
        case 'to':
          return compare(a.to, b.to, isAsc);
        case 'picup_date':
          return compare(a.picup_date, b.picup_date, isAsc);
        case 'delivery_date':
          return compare(a.delivery_date, b.delivery_date, isAsc);
        case 'amount':
          return compare(a.amount, b.amount, isAsc);
        case 'miles':
          return compare(a.miles, b.miles, isAsc);
        case 'rate_per_mile':
          return compare(a.rate_per_mile, b.rate_per_mile, isAsc);
        case 'weight':
          return compare(a.weight, b.weight, isAsc);
        default:
          return 0;
      }
    });
  };

}
function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

import { Pipe, PipeTransform } from '@angular/core';
import moment = require('moment');

@Pipe({
  name: 'currentDate'
})
export class CurrentDatePipe implements PipeTransform {

  transform(date: string): string {
    let currentDate = moment().format('YYYY-MM-DD');
    if(currentDate >= moment(date).startOf('isoWeek').format('YYYY-MM-DD') && currentDate <= moment(date).endOf('isoWeek').format('YYYY-MM-DD')) {
      return 'This week'
    }
    else {
      return `${moment(date).startOf('isoWeek').format('MMM DD')} - ${moment(date).endOf('isoWeek').format('MMM DD')}`;
    }
  }

}

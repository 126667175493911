import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SharedService } from '@app/modules/shared/services/shared.service';
import * as L from 'leaflet';

@Component({
  selector: 'app-map-load-dialog',
  templateUrl: './map-load-dialog.component.html',
  styleUrls: ['./map-load-dialog.component.scss']
})
export class MapLoadDialogComponent implements OnInit, AfterViewInit {
  //Interval
  private map: any;
  routeInterval: any;
  currentlyLocation: string = '';

  constructor(private sharedService: SharedService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<any>) { }

  ngOnInit(): void {
    this.dialogRef.backdropClick().subscribe(_ => {
      clearInterval(this.routeInterval);
    })
  }

  //Maps methods
  private initMap(obj: any): void {
    this.map = L.map('map', {
		  center: [obj.latitude, obj.longitude ]
		});
	
		const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
		  attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
		});
		tiles.addTo(this.map);
    let marker = L.marker([obj.latitude, obj.longitude],
      {
        icon: new L.DivIcon({
          className: 'my-div-icon',
          html: `<div style="background-color: #003fa4; color: #fff; text-align: center; padding: 3px; font-size: 16px; font-weight: bolder; border-radius: 10px; font-family: 'Poppins';">${ obj.unit_no}</div>
          <img style="width: 90px; height: 90px;" src="./assets/img/truck_pin.svg"/>`,
          iconSize: [90, 90],
          iconAnchor: [45, 90]
      })
      }
    );
    marker.addTo(this.map);

    let control: any  = L.Routing.control({
      containerClassName: "leaflet-instructions",
      plan: L.Routing.plan([
        L.latLng(obj.latitude, obj.longitude),
        L.latLng(this.data.destinationLat, this.data.destinationLng)
      ], {
        createMarker: function(i, wp, nWps) {
          if (i === 0  ) {
            let myStartIcon = L.icon({
              iconUrl: './assets/img/location_start.png',
              iconSize: [34, 34],
              iconAnchor: [17, 34]
            });
            return L.marker(wp.latLng, {icon: myStartIcon });
          }
          else {                        
            let myEndIcon = L.icon({
              iconUrl: './assets/img/location_end.png',
              iconSize: [34, 34],
              iconAnchor: [17, 34]
            });
            return L.marker(wp.latLng, {icon: myEndIcon });
          }
        }
      }),
      routeWhileDragging: false,
      lineOptions: {
        styles: [{color: '#022bb1', opacity: 0.6, weight: 10}], extendToWaypoints: false, missingRouteTolerance: 0
     }
    }).addTo(this.map);
    this.map.fitBounds([[obj.latitude, obj.longitude], [this.data.destinationLat, this.data.destinationLng]])

    this.routeInterval = setInterval(() => {
      this.sharedService.getTruckLocation(this.data.unit_no, -1).subscribe((response: any) => {
        console.log(response);
        if(response.length > 0) {
         // control.setWaypoints([new L.LatLng(response[0].latitude, response[0].longitude), new L.LatLng(this.data.destinationLat, this.data.destinationLng)]);
          marker.setLatLng(new L.LatLng(response[0].latitude, response[0].longitude));
          this.getCurrentAddress(response[0].latitude, response[0].longitude);
          //this.map.setView([response[0].latitude, response[0].longitude]);
          //this.map.fitBounds([[response[0].latitude, response[0].longitude], [this.data.destinationLat, this.data.destinationLng]])
        }
      })
    }, 1000);

	};

  ngAfterViewInit(): void {
    this.sharedService.getTruckLocation(this.data.unit_no, -1).subscribe((response: any) => {
      if(response.length > 0) {
        this.initMap(response[0]);
        this.getCurrentAddress(response[0].latitude, response[0].longitude);
      }
    })
  };

  getCurrentAddress(lat: number, lng: number) {
    this.sharedService.getCurrentAddress(lat, lng).subscribe((response: any) => {
      this.currentlyLocation = response.address.road;
    })
  }

}

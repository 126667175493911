import { Injectable } from '@angular/core';
import * as _G from '../../../_config/globals';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject, forkJoin } from 'rxjs';

@Injectable()

export class TrucksService {
	headers = new HttpHeaders({
		'Content-Type': 'application/json',
		'Authorization': 'Basic SVZTb2x1dGlvbjoxUzBsdXQxMG4yQDIy'
	});
	requestOptions = { headers: this.headers };
	
	dateChanged: any = new Subject<any>();
	timelineChartSubject: any = new Subject<any>();
	lineChartSubject: any = new Subject<any>();
	netProfitSubject: any = new Subject<any>();
	netProfitGraphSubject: any = new Subject<any>();

  	constructor ( private http: HttpClient ) {}

	//Trucks service
	getTruckCards(unit_no: string, startDate: string, endDate: string) {
		return this.http.get(`${_G.extendApiUrl}trucks/truckprofileinfo?unit_no=${unit_no}&start=${startDate}&end=${endDate}`, this.requestOptions);
	};

	//Chart service
	getChartsData(unit_no: string, startDate: string, endDate: string) {
		return this.http.get(`${_G.extendApiUrl}trucks/truckperformancegraph?unit_no=${unit_no}&start=${startDate}&end=${endDate}`);
	};

	//Get all trucks, trailers
	getAllTrucksTrailers() {
		return this.http.get(`${_G.extendApiUrl}trucks/getmodelstruckandtrailers`, this.requestOptions);
	};

	//Profitability table
	getTruckProfitabilityTable(unitNo: string, startDate: string, endDate: string) {
		return this.http.get(`${_G.extendApiUrl}trucks/truckprofitability?unit_no=${unitNo}&start=${startDate}&end=${endDate}`, this.requestOptions);
	};

	//Save P & L
	saveEditPl(obj: any) {
		return this.http.post(`${_G.extendApiUrl}trucks/savepandl`, obj);
	};

	//Get saved p & l 
	getSavedPl(unitNo: string) {
		return this.http.get(`${_G.extendApiUrl}trucks/getpandl?truck_no=${unitNo}`);
	};

	//Truck performances
	truckPerformanceDateChanged: any = new Subject<any>();
	truckAnalysisLineChart: any = new Subject<any>();

	getTruckPerformanceCards(startDate: string, endDate: string, filterParams: string) {
		let response1 = this.http.get(`${_G.extendApiUrl}trucks/trucksperformancecards?start=${startDate}&end=${endDate}${filterParams}`, this.requestOptions);
		let response2 = this.http.get(`${_G.extendApiUrl}trucks/trucksperformancecards?start=${startDate}&end=${endDate}${filterParams}`, this.requestOptions);
		return forkJoin([response1, response2]);
	};	

	getTruckAnalysisTableData(startDate: string, endDate: string, filterParams: string) {
		return this.http.get(`${_G.extendApiUrl}trucks/trucksanalysis?start=${startDate}&end=${endDate}${filterParams}`, this.requestOptions);
	};

	getTruckProfitabilityTableData(startDate: string, endDate: string, filterParams: string) {
		return this.http.get(`${_G.extendApiUrl}trucks/alltruckprofitability?start=${startDate}&end=${endDate}${filterParams}`, this.requestOptions);
	}

	//Empty trucks stats
	selectedCardSubject = new Subject<any>();
	
	getEmptyTrucksData(filterParams: string) {
		return this.http.get(`${_G.extendApiUrl}dispatcher/emptytrucks?${filterParams}`, this.requestOptions);
	};
	
	getLineChartData(startDate: string, endDate: string, filterParams: string) {
		return this.http.get(`${_G.extendApiUrl}trucks/emptytrucksgraph?start=${startDate}&end=${endDate}${filterParams}`, this.requestOptions);
	};

	//Trucks inspections
	getAllTruckInspections(startDate: string, endDate: string) {
		return this.http.get(`${_G.extendApiUrl}routingapp/getDVIRInspections?fromDate=${startDate}&toDate=${endDate}`, this.requestOptions);
	};

	getTruckInspectionDetails(inspectionId: number) {
		return this.http.get(`${_G.extendApiUrl}routingapp/getDVIRInspectionsDetails?ID=${inspectionId}`, this.requestOptions);
	};
  
}

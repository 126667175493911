import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FullAppRoutingModule } from './full-app.routing';
import { SharedModule } from '../shared/shared.module';
import { FullAppComponent } from './components/full-app.component';
import { FilledApplicationsCardsComponent } from './components/full-application/filled-applications-cards/filled-applications-cards.component';
import { FullApplicationDealComponent } from './components/full-application/full-application-deal/full-application-deal.component';
import { FullApplicationExpensesComponent } from './components/full-application/full-application-expenses/full-application-expenses.component';
import { FullApplicationPaperworkComponent } from './components/full-application/full-application-paperwork/full-application-paperwork.component';
import { FullApplicationSpecialRequirementsComponent } from './components/full-application/full-application-special-requirements/full-application-special-requirements.component';
import { CustomInputDriversComponent } from './components/shared/components/custom-input-drivers/custom-input-drivers.component';
import { CustomInputComponent } from './components/shared/components/custom-input/custom-input.component';
import { FullAppDocumentationDialogComponent } from './components/shared/components/full-app-documentation-dialog/full-app-documentation-dialog.component';
import { CreateUpdateExpenseDialogComponent } from './components/shared/components/full-app-expenses-dialog/create-update-expense-dialog/create-update-expense-dialog.component';
import { FullAppExpensesDialogComponent } from './components/shared/components/full-app-expenses-dialog/full-app-expenses-dialog.component';
import { FullAppNoteDialogComponent } from './components/shared/components/full-app-note-dialog/full-app-note-dialog.component';
import { FullAppReasonDialogComponent } from './components/shared/components/full-app-rejection-dialog/full-app-reason-dialog/full-app-reason-dialog.component';
import { FullAppRejectionDialogComponent } from './components/shared/components/full-app-rejection-dialog/full-app-rejection-dialog.component';
import { PlannerFormComponent } from './components/shared/components/planner-form/planner-form.component';
import { DriverInputsSettingsComponent } from './components/shared/components/template-driver-inputs/driver-inputs-settings/driver-inputs-settings.component';
import { TemplateDriverInputsComponent } from './components/shared/components/template-driver-inputs/template-driver-inputs.component';
import { InputsSettingsComponent } from './components/shared/components/template/inputs-settings/inputs-settings.component';
import { TemplateComponent } from './components/shared/components/template/template.component';
import { DriversInputsFilterPipe } from './pipes/drivers-inputs-filter.pipe';
import { FiledsSearchPipe } from './pipes/fileds-search.pipe';
import { FullAppFilterPipe } from './pipes/full-app-filter.pipe';
import { FullAppService } from './services/full-app.service';
import { FullAppPaperworkDialogComponent } from './components/shared/components/full-app-paperwork-dialog/full-app-paperwork-dialog.component';
import { CreateUpdateGroupComponent } from './components/shared/components/full-app-paperwork-dialog/create-update-group/create-update-group.component';
import { ScheduleOrientationDateDialogComponent } from './components/shared/components/schedule-orientation-date-dialog/schedule-orientation-date-dialog.component';
import { OrientationApplicationTabComponent } from './components/full-application/orientation-application-tab/orientation-application-tab.component';
import { FullApplicationTabComponent } from './components/full-application/full-application-tab/full-application-tab.component';
import { FullApplicationTripPlannerComponent } from './components/full-application/full-application-trip-planner/full-application-trip-planner.component';
import { OrientationFilterPipe } from './pipes/orientation-filter.pipe';
import { OrientationApplicationsCardsComponent } from './components/full-application/orientation-applications-cards/orientation-applications-cards.component';
import { CancelOrientationDateDialogComponent } from './components/shared/components/cancel-orientation-date-dialog/cancel-orientation-date-dialog.component';
import { FullApplicationTableComponent } from './components/full-application-table/full-application-table.component';
import { FullAppTablePipe } from './pipes/full-app-table.pipe';
import { TableDropdownComponent } from './components/full-application-table/table-dropdown/table-dropdown.component';
import { DotsDropdownComponent } from './components/full-application-table/dots-dropdown/dots-dropdown.component';

@NgModule({
  declarations: [
    //Components
    FullAppComponent,
    OrientationApplicationTabComponent,
    FullApplicationTabComponent,
    FilledApplicationsCardsComponent,
    FullApplicationDealComponent,
    FullApplicationExpensesComponent,
    FullApplicationPaperworkComponent,
    FullApplicationSpecialRequirementsComponent,
    CustomInputComponent,
    CustomInputDriversComponent,
    FullAppDocumentationDialogComponent,
    FullAppExpensesDialogComponent,
    CreateUpdateExpenseDialogComponent,
    FullAppNoteDialogComponent,
    FullAppRejectionDialogComponent,
    FullAppReasonDialogComponent,
    PlannerFormComponent,
    InputsSettingsComponent,
    TemplateComponent,
    DriverInputsSettingsComponent,
    TemplateDriverInputsComponent,
    FullAppPaperworkDialogComponent,
    CreateUpdateGroupComponent,
    ScheduleOrientationDateDialogComponent,
    //Pipes
    DriversInputsFilterPipe,
    FiledsSearchPipe,
    FullAppFilterPipe,
    FullApplicationTripPlannerComponent,
    OrientationFilterPipe,
    OrientationApplicationsCardsComponent,
    CancelOrientationDateDialogComponent,
    FullApplicationTableComponent,
    FullAppTablePipe,
    TableDropdownComponent,
    DotsDropdownComponent
  ],
  imports: [
    CommonModule,
    FullAppRoutingModule,
    SharedModule
  ],
  providers: [FullAppService]
})
export class FullAppModule { }

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SettingsRoutingModule } from './settings.routing';
import { SharedModule } from '../shared/shared.module';
import { SettingsComponent } from './components/settings.component';
import { CreateEditUsersDialogComponent } from './components/users-departments/create-edit-users-dialog/create-edit-users-dialog.component';
import { DeleteUserConfirmationDialogComponent } from './components/users-departments/delete-user-confirmation-dialog/delete-user-confirmation-dialog.component';
import { DepartmentDialogComponent } from './components/users-departments/department-dialog/department-dialog.component';
import { LoginHistoryDialogComponent } from './components/users-departments/login-history-dialog/login-history-dialog.component';
import { DeleteRoleDialogComponent } from './components/users-departments/roles-dialog/delete-role-dialog/delete-role-dialog.component';
import { RoleHistoryComponent } from './components/users-departments/roles-dialog/role-history/role-history.component';
import { RolesDialogComponent } from './components/users-departments/roles-dialog/roles-dialog.component';
import { UsersDepartmentsComponent } from './components/users-departments/users-departments.component';
import { SettingsService } from './services/settings.service';

@NgModule({
  declarations: [
    SettingsComponent,
    UsersDepartmentsComponent,
    CreateEditUsersDialogComponent,
    DeleteUserConfirmationDialogComponent,
    DepartmentDialogComponent,
    LoginHistoryDialogComponent,
    RolesDialogComponent,
    DeleteRoleDialogComponent,
    RoleHistoryComponent
  ],
  imports: [
    CommonModule,
    SettingsRoutingModule,
    SharedModule
  ],
  providers: [SettingsService]
})
export class SettingsModule { }

import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { LoadService } from '@app/modules/safety/services/load.service';
import { SuccessDialogComponent } from '@app/modules/shared/components/success-dialog/success-dialog.component';
import { WarningMsgDialogComponent } from '@app/modules/shared/components/warning-msg-dialog/warning-msg-dialog.component';
import { Subscription, catchError, throwError } from 'rxjs';

@Component({
  selector: 'files',
  templateUrl: './files.component.html',
  styleUrls: ['./files.component.scss']
})
export class FilesComponent implements OnInit, OnDestroy {
  @Input() loadData: any;

  formData: any = this._formBuilder.group({
    ID: [0],
    pdfBase64: [null, Validators.required],
    load_no: [''],
    driverID: [''],
    documentType: [''],
    documentName: [''],
    status: [null, Validators.required],
    reason: [null, Validators.required],
    note: [''],
    pageIndex: [0],
    dateReceived: [null]
  });

  filesArray: any[] = [];
  selectedPdf: string = '';
  activePage: number = 0;

  //Status
  statusArray: any[] = [
    {name: 'Approved'},
    {name: 'Rejected'},
    {name: 'No status'}
  ];
  statusObj: any = {
    1: 'Approved',
    2: 'Rejected',
    3: 'No status',
    null: ''
  };
  isOpenStatusMenu: boolean = false;

  //Reason
  reasonArray: any[] = [
    {name: 'Poor quality'},
    {name: 'Reason 2'},
    {name: 'Reason 3'},
    {name: 'Reason 4'}
  ];
  isOpenReasonMenu: boolean = false;
  reasonObj: any = {
    0: 'Poor quality',
    1: 'Reason 2',
    2: 'Reason 3',
    3: 'Reason 4',
    null: ''
  };

  metaObj: any = {
    badgeColor: {
      color: 'gray-color'
    }
  }

  subscription: Subscription = new Subscription();

  loaded: boolean = false;
	error: boolean = false;

  constructor(private loadService: LoadService,
              private dialog: MatDialog,
              private _formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.getFilesData();
  };
  
  getFilesData() {
    this.subscription = this.loadService.getFilesData(this.loadData.load_no)
    .pipe(catchError((err: any) => {
      this.loaded = true;
      this.error = true;
      return throwError(() => err);
      }))
    .subscribe((response: any) => {
      this.filesArray = response;
      if(response.length > 0) {
        this.getSelectedPdf();

        if(this.getFormDataValue('pageIndex') === -1) {
          this.changeFilesOrders();
        } 
      }
      this.loaded = true;
      console.log(response);
    });
  };

  onFileSelected(event: any) {  
    let fileName = event.target.files[0].name;
    let reader = new FileReader();
    reader.onload = (e: any) => {
      var uint8 = new Uint8Array(e.target.result);
      var result = [];
      for (var i = 0; i < uint8.length; i++) {
        result.push(uint8[i]);
      }
      let obj: any = {
        pageName: fileName,
        badgeName: 'No status',
        badgeColor: 'gray-color',
        photo: result,
        pageFooter: `Page ${this.filesArray.length + 1}`
      };
      this.filesArray.push(obj);
    }
    reader.readAsArrayBuffer(event.target.files[0])
  }

  getBadgeColor(element: any): string {
    let className: string = '';
    if(element.status === 1) {
      className = 'green-color';
    };
    if(element.status === 2) {
      className = 'red-color';
    };
    if(element.status === 3) {
      className = 'gray-color';
    };
    return className;
  };
  getFormDataValue(formKey: string) {
    return this.formData.controls[formKey].value;
  };

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.filesArray, event.previousIndex, event.currentIndex);
    this.changeFilesOrders();
  };

  changeFilesOrders() {
    let idsArray: number[] = [];
    for(let i = 0; i < this.filesArray.length; i++) {
      idsArray.push(this.filesArray[i].ID);
    };
    this.loadService.changeFileOrder(idsArray).subscribe((response: string) => {
      console.log(response);
      if(response === 'OK') {
        this.getFilesData();
      }
    });  
    console.log(idsArray)
  };

  //Notify driver
  notifyDriver() {
    this.loadService.notifyDriver(this.formData.value.ID, this.formData.value)
    .pipe(catchError((err: any) => {
      this.showErrorMessage();
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      if(response === 'OK') {
        this.showSuccessMessage();
      }
      else {
        this.showErrorMessage();
      }
    });
  };

  getSelectedPdf() {
    this.formData.patchValue(this.filesArray[this.activePage]);
    this.selectedPdf = ('data:application/pdf;base64,' + this.getFormDataValue('pdfBase64') + '#view=Fit');
  };

  //Send to billing
  sendToBilling() { 

  };

  showSuccessMessage() {
    this.dialog.open(SuccessDialogComponent, {
      autoFocus: false,
      panelClass: 'success-dialog-container'
    });  
  };

  showErrorMessage() {
    this.dialog.open(WarningMsgDialogComponent, {
      autoFocus: false,
      panelClass: 'warning-msg-dialog-container'
    });
  };

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();  
  };


}

import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SharedService } from '@app/modules/shared/services/shared.service';
import { ApexAxisChartSeries, ApexChart, ApexFill, ApexLegend, ApexResponsive, ApexXAxis, ApexYAxis, ChartComponent } from 'ng-apexcharts';
import { NgxSpinnerService } from 'ngx-spinner';
import { catchError, Subscription, throwError } from 'rxjs';
import { DriversPerformanceService } from '../../services/drivers-performance.service';
import { TransformService } from '@app/modules/shared/services/transform.service';
import { RulesService } from '@app/modules/shared/services/rules.service';

export type ChartOptions = {
  series: ApexAxisChartSeries | any;
  chart: ApexChart | any;
  xaxis: ApexXAxis | any;
  yaxis: ApexYAxis | any;
  responsive: ApexResponsive | any;
  labels: string | any;
  colors: string | any;
  legend: ApexLegend | any;
  fill: ApexFill | any;
  tooltip: any;
};

@Component({
  selector: 'app-drivers-performance-average-cards',
  templateUrl: './drivers-performance-average-cards.component.html',
  styleUrls: ['./drivers-performance-average-cards.component.scss']
})
export class DriversPerformanceAverageCardsComponent implements OnInit, OnDestroy {
  permissions: any = this.rulesService.UserData[16].data[2].sectionArray;
  @Input() date: any;

  @ViewChild("chart") chart: ChartComponent | any;
  public chartOptions1: Partial<ChartOptions> | any;
  public chartOptions2: Partial<ChartOptions> | any;
  public chartOptions3: Partial<ChartOptions> | any;

  averageCardsObj: any;

  //Interval
  interval: any;

  loaded: boolean = false;

  error: boolean = false;

  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";

  spinnerBgColor: string = this.transformService.spinnerBgColor;
  spinnerType: string = this.transformService.spinnerType;

  subscription1: Subscription | any;
  subscription2: Subscription | any;

  constructor(public transformService: TransformService,
              private rulesService: RulesService,
              private sharedService: SharedService,
              private driversPerformanceService: DriversPerformanceService,
              private spinner: NgxSpinnerService,) { }

  ngOnInit(): void {
    this.spinner.show('average-cards');  
    this.getCardsData();
    this.dateChanged();
    if(this.rulesService.liveUpdate) {
      this.interval = setInterval(() => {
        this.getCardsData();
      }, this.rulesService.miliseconds);
    }
  }

  getCardsData() {
    this.subscription1 = this.driversPerformanceService.getAverageCardsData(this.date.startDate, this.date.endDate, this.transformService.filterParams)
    .pipe(catchError((err: any) => {
      this.spinner.hide('average-cards');
      this.loaded = true;
      this.error = true;
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      console.log(response);
      this.averageCardsObj = response;
      this.spinner.hide('average-cards');
      this.loaded = true;

      let pieObj1: any = {
        key1: response.fuel.consumption_company, title1: 'Consumption (Company)',
        key2: response.fuel.consumption_rent, title2: 'Consumption (Rent to run)',
        key3: response.fuel.consumption_owner === 'NaN' ? 0 : response.fuel.consumption_owner, title3: 'Consumption (Owner)'
      };
      let pieObj2: any = {
        key1: response.idle.idling_percnet_company, title1: 'Idling percent (Company)',
        key2: response.idle.idling_percnet_rent, title2: 'Idling percent (Rent to run)',
        key3: response.idle.idling_percnet_owner, title3: 'Idling percent (Owner)'
      };
      let pieObj3: any = {
        key1: response.check.weekly_pay_company, title1: 'Weekly pay (Company)',
        key2: response.check.weekly_pay_rent, title2: 'Weekly pay (Rent to run)',
        key3: response.check.weekly_pay_owner, title3: 'Weekly pay (Owner)',
      };
      this.chartOptions1 = this.pieChart(pieObj1);
      this.chartOptions2 = this.pieChart(pieObj2);
      this.chartOptions3 = this.pieChart(pieObj3);
    })
  }

  dateChanged() {
    this.subscription2 = this.driversPerformanceService.driverPerformanceDateChanged.subscribe((response: any) => {
      this.date.startDate = response.startDate;
      this.date.endDate = response.endDate;
      this.averageCardsObj = undefined;

      this.error = false;
      this.loaded = false;

      this.spinner.show('average-cards');
      this.getCardsData();
    })
  }

  //Pie chart
  pieChart(obj: any) {
    return {
      colors: ['#003fa2', '#ff900c', '#b90000', '#65a704', '#8b8b8b', '#FE7777'],
      series: [obj.key1, obj.key2, obj.key3],
      chart: {
        width: 400,
        type: "pie",
        fontFamily: 'Poppins'
      },
      labels: [obj.title1, obj.title2, obj.title3],
      legend: {
        position: 'bottom'
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: "bottom"
            }
          }
        }
      ]
    };
  };

  showPieChart(title: string, pieNum: number, keyArray: string[]) {
    if(pieNum === 1) {
      let pieObj1: any = {
        key1: this.averageCardsObj.fuel[keyArray[0]] === 'NaN' ? 0 : this.averageCardsObj.fuel[keyArray[0]], title1: title + ' (Company)',
        key2: this.averageCardsObj.fuel[keyArray[1]] === 'NaN' ? 0 : this.averageCardsObj.fuel[keyArray[1]], title2: title + ' (Rent to run)',
        key3: this.averageCardsObj.fuel[keyArray[2]] === 'NaN' ? 0 : this.averageCardsObj.fuel[keyArray[2]], title3: title + ' (Owner)'
      };
      this.chartOptions1 = this.pieChart(pieObj1)
    };

    if(pieNum === 2) {
      let pieObj1: any = {
        key1: this.averageCardsObj.idle[keyArray[0]], title1: title + ' (Company)',
        key2: this.averageCardsObj.idle[keyArray[1]], title2: title + ' (Rent to run)',
        key3: this.averageCardsObj.idle[keyArray[2]], title3: title + ' (Owner)'
      };
      this.chartOptions2 = this.pieChart(pieObj1)
    };

    if(pieNum === 3) {
      let pieObj1: any = {
        key1: this.averageCardsObj.check[keyArray[0]], title1: title + ' (Company)',
        key2: this.averageCardsObj.check[keyArray[1]], title2: title + ' (Rent to run)',
        key3: this.averageCardsObj.check[keyArray[2]], title3: title + ' (Owner)'
      };
      this.chartOptions3 = this.pieChart(pieObj1)
    };

  };

  ngOnDestroy(): void {
    clearInterval(this.interval);
    this.subscription1?.unsubscribe();
    this.subscription2?.unsubscribe();
  }

}

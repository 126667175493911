import { Component } from '@angular/core';

@Component({
  selector: 'app-dispatch-reward-system-page',
  templateUrl: './dispatch-reward-system-page.component.html',
  styleUrls: ['./dispatch-reward-system-page.component.scss']
})
export class DispatchRewardSystemPageComponent {

}

import { TitleCasePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import moment = require('moment');
import { FormatNumPipe } from '../pipes/format-num.pipe';
import { DateAsAgoPipe } from '../pipes/date-as-ago.pipe';

@Injectable({
  providedIn: 'root'
})
export class TransformService {

  userData: any = JSON.parse(localStorage.getItem('currentUser'));

  spinnerBgColor: string = 'rgb(0,63,162)';
  spinnerType: string = 'pacman';

  get filterParams() {
    let filterParams: string = '';
    let idsArray: any = JSON.parse(localStorage.getItem('selectedCompanies'));
    for(let key in idsArray) {
      filterParams +=	'&filters=' + idsArray[key];
    }
    return filterParams
  }

  constructor(private titleCase: TitleCasePipe, 
              private formatNumPipe: FormatNumPipe,
              private dateAsAgo: DateAsAgoPipe) { }

//Convert to timestamp

convertDateToTimestamp(date: any, time: any) {
  let d = date.split(',')[1].split('/')[0].trim();
  let m = date.split(',')[1].split('/')[1].split(' ')[0];
  let y =  date.split(',')[1].split('/')[1].split(' ')[1];
  let t = time;
  let convertedDate = y + '-' + m + '-' + d + 'T' + t;
  return convertedDate;
};

//Add commas dots

addCommasDots(num: any, roundNumber?: any)  {
  typeof num !== 'number' ? num = 0 : num;
  roundNumber === 'round' ? num = Math.round(num) : num;
  let result = (num - Math.floor(num)) !== 0;
  let number: any;
  if(result) {
    number = num.toFixed(2);
  } else {
    number = num;
  }
  return number.toLocaleString("en-US").toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
};

//Drivers performance
countDataByPeriodDrivers(data: any, viewBy: string, key: string, title: string) {
  let sortedData: any = data.sort((a: any, b: any) => {
    return <any>new Date(a.date) - <any>new Date(b.date);
  })
  let group = sortedData.reduce((r: any, o: any) => {

      let periodName: any;

      if(viewBy === 'day') {
        periodName = `${moment(o.date).startOf('day').format('ddd MM.DD')}`;
      };

      if(viewBy === 'week') {
        periodName = `${moment(o.date).startOf('isoWeek').format('MMM DD')}-${moment(o.date).endOf('isoWeek').format('DD')}`;
      };

      if(viewBy === 'month') {
        periodName = moment(o.date).startOf('month').format('MMM yy');
      };

      if(viewBy === 'quarter') {
        periodName = `${moment(o.date).startOf('quarter').format('MM.DD')}-${moment(o.date).endOf('quarter').format('MM.DD')}`;
      };

      if(viewBy === 'year') {
        periodName = `${moment(o.date).startOf('year').format('YYYY')}`;
      };

      if(r[periodName]) {
        r[periodName].gross += o.gross;
        r[periodName].mileage += o.mileage;

        r[periodName].grossCompany += o.gross_company;
        r[periodName].grossRent += o.gross_rent;
        r[periodName].grossOwner += o.gross_owner;

        r[periodName].mileageCompany += o.mileage_company;
        r[periodName].mileageRent += o.mileage_rent;
        r[periodName].mileageOwner += o.mileage_owner;

        r[periodName][key] += o[key];
        r[periodName].numDivider += 1;
      } else {
        r[periodName] = {categories: periodName, timestamp: o.date, [key]: o[key] ? o[key] : 0,
          gross: o.gross ? o.gross : 0, 
          mileage: o.mileage ? o.mileage : 0,
          grossCompany: o.gross_company ? o.gross_company : 0, 
          grossRent: o.gross_rent ? o.gross_rent : 0, 
          grossOwner: o.gross_owner ? o.gross_owner : 0,
          mileageCompany: o.mileage_company ? o.mileage_company : 0,
          mileageRent: o.mileage_rent ? o.mileage_rent : 0,
          mileageOwner: o.mileage_owner ? o.mileage_owner : 0,
          numDivider: o.numDivider ? o.numDivider: 1};
      };
    return r;
    }, {});

    let result = Object.keys(group).map((key) => group[key]);
    let titleArray: any[] = [];
    titleArray.push(title);
    let objOfArrays: any = {name: titleArray, categories: [], timestampArray: [], total: [], data: data, key: key, title: title};
    for(let index in result) {
      let addDecimalsProperty: string[] = ['rate', 'rate_company', 'rate_rent', 'rate_owner', 'company_driver_deal', 'company_paid_deal',
      'rent_deal', 'rent_deal_paid', 'owner_deal', 'owner_deal_paid'];
      let divideArray: string[] =
      [
      'active_trucks', 'active_trucks_company', 'active_trucks_rent', 'active_trucks_owner',
      'daily_trucks_with_load', 'daily_trucks_with_load_percent', 'daily_trucks_with_load_lost', 'daily_trucks_with_load_owner'
      ];

      let keyValue: number | any;

      if(key === 'rate') {
        keyValue = result[index].gross / result[index].mileage ? (result[index].gross / result[index].mileage) : 0;
      }
      else if(key === 'rate_company') {
        keyValue = result[index].grossCompany / result[index].mileageCompany ? (result[index].grossCompany / result[index].mileageCompany) : 0;
      }
      else if(key === 'rate_rent') {
        keyValue = result[index].grossRent / result[index].mileageRent ? (result[index].grossRent / result[index].mileageRent) : 0;
      }
      else if(key === 'rate_owner') {
        keyValue = result[index].grossOwner / result[index].mileageOwner ? (result[index].grossOwner / result[index].mileageOwner) : 0;
      }
      else {
        keyValue = result[index][key]
      }


      let value: any = divideArray.includes(key) ? keyValue / result[index].numDivider : keyValue;
      value = addDecimalsProperty.includes(key) ? value.toFixed(2) : Math.round(value);
      objOfArrays.categories.push(result[index].categories);
      objOfArrays.timestampArray.push(result[index].timestamp)
      objOfArrays.total.push(value);
    }
    console.log(objOfArrays);
    return objOfArrays;
};

//Count by period

countDataByPeriod(data: any, viewBy: string, key: string, type: string, title: string, condition?: boolean) {
  let sortedData: any = data.sort((a: any, b: any) => {
    return <any>new Date(a.date) - <any>new Date(b.date);
  })
  let group = sortedData.reduce((r: any, o: any) => {
    let condition1: boolean = true;
    if(condition1) {

      let periodName: any;

      if(viewBy === 'day') {
        periodName = `${moment(o.date).startOf('day').format('ddd MM.DD')}`;
      };

      if(viewBy === 'week') {
        periodName = `${moment(o.date).startOf('isoWeek').format('MMM DD')}-${moment(o.date).endOf('isoWeek').format('DD')}`;
      };

      if(viewBy === 'month') {
        periodName = moment(o.date).startOf('month').format('MMM yy');
      };

      if(viewBy === 'quarter') {
        periodName = `${moment(o.date).startOf('quarter').format('MM.DD')}-${moment(o.date).endOf('quarter').format('MM.DD')}`;
      };

      if(viewBy === 'year') {
        periodName = `${moment(o.date).startOf('year').format('YYYY')}`;
      };

      if(r[periodName]) {
        r[periodName][key] += o[key];
        r[periodName].numDivider += 1;
      } else {
        r[periodName] = {categories: periodName, timestamp: o.date, [key]: o[key] ? o[key] : 0,  numDivider: o.numDivider ? o.numDivider: 1};
      };
    }
    return r;
    }, {});

    let result = Object.keys(group).map((key) => group[key]);
    let titleArray: any[] = [];
    titleArray.push(title);
    let objOfArrays: any = {name: titleArray, categories: [],  timestampArray: [], total: [], data: data, key: key, title: title, type: type};
    for(let index in result) {
      let value: any = result[index][key];
      value = key === 'rate_per_mile' ? value.toFixed(2) : Math.round(value);
      objOfArrays.categories.push(result[index].categories);
      objOfArrays.timestampArray.push(result[index].timestamp)
      if(condition){
        value = value === 0 ? value : value / result[index].numDivider;
        objOfArrays.total.push(value);
      } else {
        objOfArrays.total.push(value);
      }
    }
    return objOfArrays;
};

//Sort by filter truck table
filterTable(array: any[], filters: any[]) {
  let filteredArray: any[] = [];
  //All
  let activeArrayAll: string[] = ['active', 'inactive'];
  let marksArrayAll: string[] = ['freightliner', 'peterbilt', 'volvo', 'all brands'];
  let devisionsArrayAll: string[] = ['jdm expedite inc', 'tempo freight systems', 'five star transport', 'all divisions'];
  let typesArrayAll: string[] = ['SOLO COMPANY', 'TEAM COMPANY', 'SOLO RENT', 'TEAM RENT', 'OWNER', ''];
  let mileageArrayAll: any[] = [
    {name: '0-99K', selected: false, group: 'Mileage', from: 0, to: 1000000000000000},
    {name: '100-149K', selected: false, group: 'Mileage', from: 0, to: 1000000000000000},
    {name: '150-199K', selected: false, group: 'Mileage', from: 0, to: 1000000000000000},
    {name: '200-249K', selected: false, group: 'Mileage', from: 0, to: 1000000000000000},
    {name: '250-300K', selected: false, group: 'Mileage', from: 0, to: 1000000000000000},
    {name: '300-399K', selected: false, group: 'Mileage', from: 0, to: 1000000000000000},
    {name: '400-499K', selected: false, group: 'Mileage', from: 0, to: 1000000000000000},
    {name: '500K +', selected: false, group: 'Mileage', from: 0, to: 1000000000000000},
    {name: 'Total mileage', selected: false, group: 'Mileage',from: 0, to: 1000000000000000}
  ];
  let utilizationArrayAll: string[] = [];

  //Selected
  let activeArray: string[] = [];
  let marksArray: string[] = [];
  let devisionsArray: string[] = [];
  let typesArray: string[] = [];
  let mileageArray: any[] = [];
  let utilizationArray: string[] = [];

  let chosenFilters: any[] = [
    {filterArray: activeArrayAll},
    {filterArray: marksArrayAll},
    {filterArray: devisionsArrayAll},
    {filterArray: typesArrayAll},
    {filterArray: mileageArrayAll},
    {filterArray: utilizationArrayAll},
  ]

  for(let key in filters) {
    if(filters[key].selected && filters[key].group === 'Trucks') {
      activeArray.push(filters[key].name.toLowerCase());
      chosenFilters[0].filterArray = activeArray;
    };
    if(filters[key].selected && filters[key].group === 'Mark') {
      marksArray.push(filters[key].name.toLowerCase());
      chosenFilters[1].filterArray = marksArray;
    };
    if(filters[key].selected && filters[key].group === 'Company') {
      devisionsArray.push(filters[key].name.toLowerCase());
      chosenFilters[2].filterArray = devisionsArray;
    };
    if(filters[key].selected && filters[key].group === 'Type') {
      typesArray = typesArray.concat(filters[key].namesArray);
      chosenFilters[3].filterArray = typesArray;
    };
    if(filters[key].selected && filters[key].group === 'Mileage') {
      mileageArray.push(filters[key]);
      chosenFilters[4].filterArray = mileageArray;
    };
    if(filters[key].selected && filters[key].group === 'Utilization') {
      utilizationArray.push(filters[key]);
      chosenFilters[5].filterArray = utilizationArray;
    };
  }

  for(let key in array) {

    let condition1: any = chosenFilters[0].filterArray.includes(array[key].staus.toLowerCase());
    let condition2: any = chosenFilters[1].filterArray.includes(array[key].mark.toLowerCase());
    let condition3: any = chosenFilters[2].filterArray.includes(array[key].division.toLowerCase());
    let condition4: any = chosenFilters[3].filterArray.includes(array[key].driver_status);
    let condition5: any = chosenFilters[4].filterArray.every((obj: any) => array[key].mileage >= obj.from && array[key].mileage <= obj.to)
    let condition6: any = chosenFilters[5].filterArray.every((obj: any) => Math.round(array[key].utilization / 100) >= obj.from && Math.round(array[key].utilization / 100) <= obj.to)

    if(condition1 && condition2 && condition3 && condition4 && condition5 && condition6) {
      filteredArray.push(array[key]);
    }
  }
  return filteredArray;
}

//Sort by filter trailers
filterTable2(array: any[], filters: any[]) {
  let filteredArray: any[] = [];
  //All
  let activeArrayAll: string[] = ['active', 'inactive'];
  let marksArrayAll: string[] = ['great dane', 'utility', 'wabash', 'hyundai', 'all brands'];
  let devisionsArrayAll: string[] = ['jdm expedite inc', 'tempo freight systems', 'five star transport', 'all divisions'];
  let allEquipedArray: string[] = [];
  let allYearsArray: any[] = ['2015', '2016', '2017', '2018', '2019', '2020', '2021', '2022', 'all years'];

  //Selected
  let activeArray: string[] = [];
  let marksArray: string[] = [];
  let devisionsArray: string[] = [];
  let equipedArray: string[] = [];
  let yearsArray: any[] = [];

  let chosenFilters: any[] = [
    {filterArray: activeArrayAll},
    {filterArray: marksArrayAll},
    {filterArray: devisionsArrayAll},
    {filterArray: allEquipedArray},
    {filterArray: allYearsArray}
  ]

  for(let key in filters) {
    if(filters[key].selected && filters[key].group === 'Trailers') {
      activeArray.push(filters[key].name.toLowerCase());
      chosenFilters[0].filterArray = activeArray;
    };
    if(filters[key].selected && filters[key].group === 'Mark') {
      marksArray.push(filters[key].name.toLowerCase());
      chosenFilters[1].filterArray = marksArray;
    };
    if(filters[key].selected && filters[key].group === 'Devisions') {
      devisionsArray.push(filters[key].name.toLowerCase());
      chosenFilters[2].filterArray = devisionsArray;
    };
    if(filters[key].selected && filters[key].group === 'Equiped') {
      equipedArray = equipedArray.concat(filters[key].namesArray);
      chosenFilters[3].filterArray = equipedArray;
    };
    if(filters[key].selected && filters[key].group === 'Years') {
      yearsArray.push(filters[key].name.toLowerCase());
      chosenFilters[4].filterArray = yearsArray;
    };
  }

  for(let key in array) {

    let condition1: any = chosenFilters[0].filterArray.includes(array[key].is_active.toLowerCase());
    let condition2: any = chosenFilters[1].filterArray.includes(array[key].mark.toLowerCase());
    let condition3: any = chosenFilters[2].filterArray.includes(array[key].division.toLowerCase());
    //let condition4: any = chosenFilters[3].filterArray.includes(array[key].driver_status);
    let condition5: any = chosenFilters[4].filterArray.includes(array[key].year.toString());


    if(condition1 && condition2 && condition3 && condition5) {
      filteredArray.push(array[key]);
    }
  }
  return filteredArray;
}

//Break down by group trucks
countDataParts(data: any, viewBy: string) {
  let group = data.reduce((r: any, o: any) => {
      let periodName: any;

      if(viewBy === 'day') {
        periodName = `${moment(o.date).startOf('day').format('ddd MM.DD')}`;
      };

      if(viewBy === 'week') {
        periodName = `${moment(o.date).startOf('isoWeek').format('MMM DD')}-${moment(o.date).endOf('isoWeek').format('DD')}`;
      };

      if(viewBy === 'month') {
        periodName = moment(o.date).startOf('month').format('MMM yy');
      };

      if(viewBy === 'quarter') {
        periodName = `${moment(o.date).startOf('quarter').format('MM.DD')}-${moment(o.date).endOf('quarter').format('MM.DD')}`;
      };

      if(viewBy === 'year') {
        periodName = `${moment(o.date).startOf('year').format('YYYY')}`;
      };

      if(r[periodName]) {
        r[periodName].t1_drivetrain += o.t1_drivetrain;
        r[periodName].t2_engine_bay += o.t2_engine_bay;
        r[periodName].t3_electrical += o.t3_electrical;
        r[periodName].t4_bodywork += o.t4_bodywork;
        r[periodName].t5_miscellaneous += o.t5_miscellaneous;
        r[periodName].t6_preventive_maintenance += o.t6_preventive_maintenance;
      } else {
        r[periodName] = {
          categories: periodName,
          t1: o.t1_drivetrain ? o.t1_drivetrain : 0,
          t2: o.t2_engine_bay ? o.t2_engine_bay : 0,
          t3: o.t3_electrical ? o.t3_electrical : 0,
          t4: o.t4_bodywork ? o.t4_bodywork : 0,
          t5: o.t5_miscellaneous ? o.t5_miscellaneous : 0,
          t6: o.t6_preventive_maintenance ? o.t6_preventive_maintenance : 0
        };
      };
    return r;
    }, {});

    let result = Object.keys(group).map((key) => group[key]);

    let objOfArrays: any = {categories: [], totalT1: [], totalT2: [], totalT3: [], totalT4: [], totalT5: [], totalT6: []};
    for(let key in result) {
      objOfArrays.categories.push(result[key].categories);
      objOfArrays.totalT1.push(result[key].t1);
      objOfArrays.totalT2.push(result[key].t2);
      objOfArrays.totalT3.push(result[key].t3);
      objOfArrays.totalT4.push(result[key].t4);
      objOfArrays.totalT5.push(result[key].t5);
      objOfArrays.totalT6.push(result[key].t6);
    }
    return objOfArrays;
};

//Break down by group trailers
countDataParts2(data: any, viewBy: string) {
  let group = data.reduce((r: any, o: any) => {
      let periodName: any;

      if(viewBy === 'day') {
        periodName = `${moment(o.date).startOf('day').format('ddd MM.DD')}`;
      };

      if(viewBy === 'week') {
        periodName = `${moment(o.date).startOf('isoWeek').format('MMM DD')}-${moment(o.date).endOf('isoWeek').format('DD')}`;
      };

      if(viewBy === 'month') {
        periodName = moment(o.date).startOf('month').format('MMM yy');
      };

      if(viewBy === 'quarter') {
        periodName = `${moment(o.date).startOf('quarter').format('MM.DD')}-${moment(o.date).endOf('quarter').format('MM.DD')}`;
      };

      if(viewBy === 'year') {
        periodName = `${moment(o.date).startOf('year').format('YYYY')}`;
      };

      if(r[periodName]) {
        r[periodName].trl1_drivetrain += o.trl1_drivetrain;
        r[periodName].trl2_electrical += o.trl2_electrical;
        r[periodName].trl3_break_systems += o.trl3_break_systems;
        r[periodName].trl4_bodywork += o.trl4_bodywork;
        r[periodName].trl5_miscellaneous += o.trl5_miscellaneous;
        r[periodName].trlpm_preventive_maintenance += o.trlpm_preventive_maintenance;
      } else {
        r[periodName] = {
          categories: periodName,
          t1: o.trl1_drivetrain ? o.trl1_drivetrain : 0,
          t2: o.trl2_electrical ? o.trl2_electrical : 0,
          t3: o.trl3_break_systems ? o.trl3_break_systems : 0,
          t4: o.trl4_bodywork ? o.trl4_bodywork : 0,
          t5: o.trl5_miscellaneous ? o.trl5_miscellaneous : 0,
          t6: o.trlpm_preventive_maintenance ? o.trlpm_preventive_maintenance : 0
        };
      };
    return r;
    }, {});

    let result = Object.keys(group).map((key) => group[key]);

    let objOfArrays: any = {categories: [], totalT1: [], totalT2: [], totalT3: [], totalT4: [], totalT5: [], totalT6: []};
    for(let key in result) {
      objOfArrays.categories.push(result[key].categories);
      objOfArrays.totalT1.push(result[key].t1);
      objOfArrays.totalT2.push(result[key].t2);
      objOfArrays.totalT3.push(result[key].t3);
      objOfArrays.totalT4.push(result[key].t4);
      objOfArrays.totalT5.push(result[key].t5);
      objOfArrays.totalT6.push(result[key].t6);
    }
    return objOfArrays;
};

  //Count data by period truck
  countDataByPeriodDown(data: any, viewBy: string, key: string, title: string) {
    let sortedData: any = data.sort((a: any, b: any) => {
      return <any>new Date(a.date) - <any>new Date(b.date);
    })
    let group = sortedData.reduce((r: any, o: any) => {
    let periodName: any;
    if(viewBy === 'day') {
      periodName = `${moment(o.date).startOf('day').format('ddd MM.DD')}`;
    };

    if(viewBy === 'week') {
      periodName = `${moment(o.date).startOf('isoWeek').format('MMM DD')}-${moment(o.date).endOf('isoWeek').format('DD')}`;;
    };

    if(viewBy === 'month') {
      periodName = moment(o.date).startOf('month').format('MMM yy');
    };

    if(viewBy === 'quarter') {
      periodName = `${moment(o.date).startOf('quarter').format('MM.DD')}-${moment(o.date).endOf('quarter').format('MM.DD')}`;
    };

    if(viewBy === 'year') {
      periodName = `${moment(o.date).startOf('year').format('YYYY')}`;
    };

    if(r[periodName]) {
      r[periodName][key] += o[key];
      r[periodName].numDivider += 1;
    } else {
      r[periodName] = {categories: periodName, timestamp: o.date, [key]: o[key] ? o[key] : 0, numDivider: o.numDivider ? o.numDivider: 1};
    };
      return r;
    }, {});

    let result = Object.keys(group).map((key) => group[key]);
    let titleArray: any[] = [];
    titleArray.push(data[0].unit_no + ' (' + title + ')');


  let objOfArrays: any = {name: titleArray, categories: [], timestampArray: [], total: [], data: data, key: key, title: title};

    for(let index in result) {
      let value: any =  result[index][key];
      objOfArrays.categories.push(result[index].categories);
      objOfArrays.timestampArray.push(result[index].timestamp)
      objOfArrays.total.push(value);
    }
    return objOfArrays;

  };

  //Get dates between

  getDatesInRange(startDate: any, endDate: any, period: string) {
    const date = new Date(startDate.getTime());
    const dates = [];
    while (date <= endDate) {
      let d1 = this.convertToSpecificPeriod(date, period);

      if(!dates.includes(d1)) {
        dates.push(d1);
      }

      date.setDate(date.getDate() + 1);
    }
    return dates;
  }

  //Missing date convert to specific period
  convertToSpecificPeriod(date: any, viewBy: string) {
    let periodName: string = '';
    if (viewBy === 'day') {
      periodName = `${moment(date).startOf('day').format('ddd MM.DD')}`;
    };

    if (viewBy === 'week') {
      periodName = `${moment(date).startOf('isoWeek').format('MMM DD')}-${moment(date).endOf('isoWeek').format('DD')}`;
    };

    if (viewBy === 'month') {
      periodName = moment(date).startOf('month').format('MMM yy');
    };

    if (viewBy === 'quarter') {
      periodName = `${moment(date).startOf('quarter').format('MM.DD')}-${moment(date).endOf('quarter').format('MM.DD')}`;
    };

    if (viewBy === 'year') {
      periodName = `${moment(date).startOf('year').format('YYYY')}`;
    };

    return periodName;
  }

  //Count data by period truck profile
  countDataByPeriodTruck(data: any, viewBy: string) {
    let sortedData: any = data.sort((a: any, b: any) => {
      return <any>new Date(a.date) - <any>new Date(b.date);
    })
    let group = sortedData.reduce((r: any, o: any) => {
        let periodName: any;

        if(viewBy === 'day') {
          periodName = `${moment(o.date).startOf('day').format('ddd MM.DD')}`;
        };

        if(viewBy === 'week') {
          periodName = `${moment(o.date).startOf('isoWeek').format('MMM DD')}-${moment(o.date).endOf('isoWeek').format('DD')}`;
        };

        if(viewBy === 'month') {
          periodName = moment(o.date).startOf('month').format('MMM yy');
        };

        if(viewBy === 'quarter') {
          periodName = `${moment(o.date).startOf('quarter').format('MM.DD')}-${moment(o.date).endOf('quarter').format('MM.DD')}`;
        };

        if(viewBy === 'year') {
          periodName = `${moment(o.date).startOf('year').format('YYYY')}`;
        };

        if(r[periodName]) {
          r[periodName].fuel_consumption += o.fuel_consumption;
          r[periodName].assigned_driver_days += o.assigned_driver_days;
          r[periodName].gross += o.gross;
          r[periodName].rate_per_mile += o.rate_per_mile;
          r[periodName].maintenance_cost += o.maintenance_cost;
          r[periodName].iddling += o.iddling;
          r[periodName].mielage += o.mielage;
          if(viewBy !== 'day') {
            r[periodName].net_profit += o.net_profit;
          } else {
            r[periodName].net_profit += 0;
          }
        } else {
          r[periodName] = {
            categories: periodName,
            fuel_consumption: o.fuel_consumption ? o.fuel_consumption : 0,
            assigned_driver_days: o.assigned_driver_days ? o.assigned_driver_days : 0,
            gross: o.gross ? o.gross : 0,
            rate_per_mile: o.rate_per_mile ? o.rate_per_mile : 0,
            maintenance_cost: o.maintenance_cost ? o.maintenance_cost : 0,
            iddling: o.iddling ? o.iddling : 0,
            net_profit: viewBy !== 'day'? o.net_profit : 0,
            mielage: o.mielage ? o.mielage : 0
          };
        };
      return r;
      }, {});

      let result = Object.keys(group).map((key) => group[key]);

      let objOfArrays: any = {categories: [], total1: [], total2: [], total3: [], total4: [], total5: [], total6: [], total7: [], total8: []};
      for(let key in result) {
        objOfArrays.categories.push(result[key].categories);
        objOfArrays.total1.push(result[key].fuel_consumption);
        objOfArrays.total2.push(result[key].assigned_driver_days);
        objOfArrays.total3.push(result[key].gross);
        objOfArrays.total4.push(result[key].rate_per_mile);
        objOfArrays.total5.push(result[key].maintenance_cost);
        objOfArrays.total6.push(result[key].iddling);
        objOfArrays.total7.push(result[key].net_profit);
        objOfArrays.total8.push(result[key].mielage);
      }
      console.log(objOfArrays)
      return objOfArrays;
  };


  //Drivers performance utilization linechart
  countUtilization(data: any, viewBy: string) {

        let group = data.reduce((r: any, o: any) => {
        let periodName: any;

        if(viewBy === 'day') {
          periodName = `${moment(o.date).startOf('day').format('ddd MM.DD')}`;
        };

        if(viewBy === 'week') {
          periodName = `${moment(o.date).startOf('isoWeek').format('MMM DD')}-${moment(o.date).endOf('isoWeek').format('DD')}`;
        };

        if(viewBy === 'month') {
          periodName = moment(o.date).startOf('month').format('MMM yy');
        };

        if(viewBy === 'quarter') {
          periodName = `${moment(o.date).startOf('quarter').format('MM.DD')}-${moment(o.date).endOf('quarter').format('MM.DD')}`;
        };

        if(viewBy === 'year') {
          periodName = `${moment(o.date).startOf('year').format('YYYY')}`;
        };

        if(r[periodName]) {
          r[periodName].driving += o.driving;
          r[periodName].hometime += o.hometime;
          r[periodName].mainenance += o.mainenance;
          r[periodName].load_issue += o.load_issue;
          r[periodName].rest += o.rest;
          r[periodName].other += o.other;
        } else {
          r[periodName] = {
            categories: periodName,
            s1: o.driving ? o.driving : 0,
            s2: o.hometime ? o.hometime : 0,
            s3: o.mainenance ? o.mainenance : 0,
            s4: o.load_issue ? o.load_issue : 0,
            s5: o.rest ? o.rest : 0,
            s6: o.other ? o.other : 0
          };
        };
      return r;
      }, {});

      let result = Object.keys(group).map((key) => group[key]);

      let objOfArrays: any = {categories: [], totalT1: [], totalT2: [], totalT3: [], totalT4: [], totalT5: [], totalT6: []};
      for(let key in result) {
        objOfArrays.categories.push(result[key].categories);
        objOfArrays.totalT1.push(result[key].s1);
        objOfArrays.totalT2.push(result[key].s2);
        objOfArrays.totalT3.push(result[key].s3);
        objOfArrays.totalT4.push(result[key].s4);
        objOfArrays.totalT5.push(result[key].s5);
        objOfArrays.totalT6.push(result[key].s6);
      }
      console.log(objOfArrays);

      return objOfArrays;
  };

  //Count data by period drivers performance utilization
  countDataUtilization(data: any, viewBy: string, key: string, title: string) {
    let sortedData: any = data.sort((a: any, b: any) => {
      return <any>new Date(a.date) - <any>new Date(b.date);
    })
    let group = sortedData.reduce((r: any, o: any) => {
    let periodName: any;
    if(viewBy === 'day') {
      periodName = `${moment(o.date).startOf('day').format('ddd MM.DD')}`;
    };

    if(viewBy === 'week') {
      periodName = `${moment(o.date).startOf('isoWeek').format('MMM DD')}-${moment(o.date).endOf('isoWeek').format('DD')}`;;
    };

    if(viewBy === 'month') {
      periodName = moment(o.date).startOf('month').format('MMM yy');
    };

    if(viewBy === 'quarter') {
      periodName = `${moment(o.date).startOf('quarter').format('MM.DD')}-${moment(o.date).endOf('quarter').format('MM.DD')}`;
    };

    if(viewBy === 'year') {
      periodName = `${moment(o.date).startOf('year').format('YYYY')}`;
    };

    if(r[periodName]) {
      r[periodName][key] += o[key];
    } else {
      r[periodName] = {categories: periodName, timestamp: o.date, [key]: o[key] ? o[key] : 0};
    };
      return r;
    }, {});

    let result = Object.keys(group).map((key) => group[key]);
    let titleArray: any[] = [];
    titleArray.push(data[0].driver + ' (' + title + ')');


  let objOfArrays: any = {name: titleArray, categories: [], timestampArray: [], total: [], data: data, key: key, title: title};

    for(let index in result) {
      let value: any =  result[index][key];
      objOfArrays.categories.push(result[index].categories);
      objOfArrays.timestampArray.push(result[index].timestamp)
      objOfArrays.total.push(value);
    }
    return objOfArrays;

  };

  countDataAccountingGraph(data: any, viewBy: string) {
    let group = data.reduce((r: any, o: any) => {
        let periodName: any;

        if(viewBy === 'week') {
          periodName = `${moment(o.start).format('MMM DD')}-${moment(o.end).format('DD')}`;
        };

        if(viewBy === 'month') {
          periodName = moment(o.start).startOf('month').format('MMM yy');
        };

        if(viewBy === 'quarter') {
          periodName = `${moment(o.start).startOf('quarter').format('MM.DD')}-${moment(o.start).endOf('quarter').format('MM.DD')}`;
        };

        if(viewBy === 'year') {
          periodName = `${moment(o.start).startOf('year').format('YYYY')}`;
        };

        if(r[periodName]) {
          r[periodName].maintenance_to_be_deducted += o.maintenance_to_be_deducted;
          r[periodName].ifta_to_be_deducted += o.ifta_to_be_deducted;
        } else {
          r[periodName] = {
            categories: periodName,
            maintenance: o.maintenance_to_be_deducted ? o.maintenance_to_be_deducted : 0,
            ifta: o.ifta_to_be_deducted ? o.ifta_to_be_deducted : 0,
          };
        };
      return r;
      }, {});

      let result = Object.keys(group).map((key) => group[key]);

      let objOfArrays: any = {categories: [], totalMaintenance: [], totalIfta: []};
      for(let key in result) {
        objOfArrays.categories.push(result[key].categories);
        objOfArrays.totalMaintenance.push(result[key].maintenance);
        objOfArrays.totalIfta.push(result[key].ifta);
      }
      return objOfArrays;
  };


  //Underperforming drivers
  getMondaysBetweenTwoDates(startDate: string, endDate: string) {
    let start: any = moment(startDate),
    end: any   = moment(endDate),
    day: number   = 1;

    let monday: any = start.clone();
    let result: any = [this.convertDateToTimestamp(monday.format('ddd, DD/MM YYYY'), '00:00:00')];
    let current: any = start.clone();

    while (current.day(7 + day).isBefore(end)) {
      let clone: any = current.clone();
      result.push(this.convertDateToTimestamp(clone.format('ddd, DD/MM YYYY'), '00:00:00'));
    }
    return result;
  };

  groupByWeek(array: any, mondaysArray: string[]) {
    const groups: any = array.reduce((acc: any, o: any) => {

    if (!acc[o.id]) {
      acc[o.id] = [];
    }

    acc[o.id].push(o);

    return acc;

    }, {});

    for(let key in groups) {

      let missingDates: any[] = [];
      let existDates: any[] = [];

      for(let key2 in groups[key]) {
        if(mondaysArray.includes(groups[key][key2].monday)) {
          existDates.push(groups[key][key2].monday);
        }
      }

      mondaysArray.forEach(date => {
        if(!existDates.includes(date)) {
          let obj: any = {days_at_home: null, dispatcher: groups[key][0].dispatcher, driver: groups[key][0].driver,
          fuel: 0, gross: 0, hire_date: groups[key][0].hire_date, id: groups[key][0].id, loss_loads: 0, mileage: 0,
          monday: date, number_of_loads: 0, paid: 0, profite: 0, rate: 0, recruiter: null, status: '',
          unit_no:  groups[key][0].unit_no, make: groups[key][0].make, year: groups[key][0].year, week: null,
          dispatcher_id: groups[key][0].dispatcher_id, truck_year: groups[key][0].truck_year };

          missingDates.push(obj)
        }
      });

      groups[key] = groups[key].concat(missingDates).sort((a: any, b: any) => {
        return <any>new Date(b.monday) - <any>new Date(a.monday);
      });
    }

    return groups;

  }

  groupByProperty(array: any[], propertyName: any) {
    const groups: any = array.reduce((acc: any, o: any) => {
      o[propertyName] = o.make + ' ' + o.truck_year;

      if (!acc[o[propertyName]]) {
        acc[o[propertyName]] = [];
      }

      acc[o[propertyName]].push(o);

      return acc;

    }, {});

    return groups;
  };

  quickAnalysisGraph(data: any, viewBy: string) {

    let group = data.reduce((r: any, o: any) => {
    let periodName: any;

    if(viewBy === 'day') {
      periodName = `${moment(o.date).startOf('day').format('ddd MM.DD')}`;
    };

    if(viewBy === 'week') {
      periodName = `${moment(o.date).startOf('isoWeek').format('MMM DD')}-${moment(o.date).endOf('isoWeek').format('DD')}`;
    };

    if(viewBy === 'month') {
      periodName = moment(o.date).startOf('month').format('MMM yy');
    };

    if(viewBy === 'quarter') {
      periodName = `${moment(o.date).startOf('quarter').format('MM.DD')}-${moment(o.date).endOf('quarter').format('MM.DD')}`;
    };

    if(viewBy === 'year') {
      periodName = `${moment(o.date).startOf('year').format('YYYY')}`;
    };

    if(r[periodName]) {
      r[periodName].empty += o.empty;
      r[periodName].empty_percent += o.empty_percent;
      r[periodName].total_loss += o.total_loss;
      r[periodName].yard += o.yard;
      r[periodName].dealership += o.dealership;
      r[periodName].recovery += o.recovery;
      r[periodName].daily_loss += o.daily_loss;

      if(periodName === 'day') {
        r[periodName].weekly_loss += 0
      }
      else {
        r[periodName].weekly_loss += o.weekly_loss;
      }

    } else {
      r[periodName] = {
        categories: periodName,
        s1: o.empty ? o.empty : 0,
        s2: o.empty_percent ? o.empty_percent : 0,
        s3: o.total_loss ? o.total_loss : 0,
        s4: o.yard ? o.yard : 0,
        s5: o.dealership ? o.dealership : 0,
        s6: o.recovery ? o.recovery : 0,
        s7: o.daily_loss ? o.daily_loss : 0,
        s8: o.weekly_loss ? o.weekly_loss : 0
      };
    };
  return r;
  }, {});

  let result = Object.keys(group).map((key) => group[key]);

  let objOfArrays: any = {categories: [], totalT1: [], totalT2: [], totalT3: [], totalT4: [], totalT5: [], totalT6: [], totalT7: [], totalT8: []};
  for(let key in result) {
    objOfArrays.categories.push(result[key].categories);
    objOfArrays.totalT1.push(result[key].s1);
    objOfArrays.totalT2.push(result[key].s2);
    objOfArrays.totalT3.push(result[key].s3);
    objOfArrays.totalT4.push(result[key].s4);
    objOfArrays.totalT5.push(result[key].s5);
    objOfArrays.totalT6.push(result[key].s6);
    objOfArrays.totalT7.push(result[key].s7);
    objOfArrays.totalT8.push(result[key].s8);
  }

  return objOfArrays;
};

//Count data by perid Active trucks
countDataByPeriodActiveTrucks(data: any, viewBy: string) {
  let sortedData: any = data.sort((a: any, b: any) => {
    return <any>new Date(a.date) - <any>new Date(b.date);
  })
  let group = sortedData.reduce((r: any, o: any) => {

      let periodName: any;

      if(viewBy === 'day') {
        periodName = `${moment(o.date).startOf('day').format('ddd MM.DD')}`;
      };

      if(viewBy === 'week') {
        periodName = `${moment(o.date).startOf('isoWeek').format('MMM DD')}-${moment(o.date).endOf('isoWeek').format('DD')}`;
      };

      if(viewBy === 'month') {
        periodName = moment(o.date).startOf('month').format('MMM yy');
      };

      if(viewBy === 'quarter') {
        periodName = `${moment(o.date).startOf('quarter').format('MM.DD')}-${moment(o.date).endOf('quarter').format('MM.DD')}`;
      };

      if(viewBy === 'year') {
        periodName = `${moment(o.date).startOf('year').format('YYYY')}`;
      };

      if(r[periodName]) {
        r[periodName].active += o.active;
        r[periodName].with_driver += o.with_driver;
        r[periodName].without_drivers += o.without_drivers;
        r[periodName].otr += o.otr;
        r[periodName].dealer_chicago += o.dealer_chicago;
        r[periodName].dealer_ready += o.dealer_ready;
        r[periodName].dealer_not_ready += o.dealer_not_ready;
        r[periodName].recovery += o.recovery;
        r[periodName].yard += o.yard;

        r[periodName].numDivider += 1;
      } else {
        r[periodName] = {categories: periodName, active: o.active ? o.active : 0,
        with_driver: o.with_driver ? o.with_driver : 0, without_drivers: o.without_drivers ? o.without_drivers : 0,  otr: o.otr ? o.otr : 0,
        dealer_chicago: o.dealer_chicago ? o.dealer_chicago : 0, dealer_ready: o.dealer_ready ? o.dealer_ready : 0, dealer_not_ready: o.dealer_not_ready ? o.dealer_not_ready : 0,
        recovery: o.recovery ? o.recovery : 0, yard: o.yard ? o.yard : 0, numDivider: o.numDivider ? o.numDivider: 1};
      };
      return r;
    }, {});

    let result = Object.keys(group).map((key) => group[key]);
    let objOfArrays: any = {
    categories: [], totalActive: [], totalOtr: [], totalDealerChicago: [], totalDealerReady: [], totalDealerNotReady: [], totalRecovery: [],
    totalYard: [], totalWithDrivers: [], totalWithoutDrivers: [], totalWithDriversPercent: [], totalWithoutDriversPercent: [],
    totalActivePercent: [], totalOtrPercent: [], totalDealerChicagoPercent: [], totalDealerReadyPercent: [], totalDealerNotReadyPercent: [],
    totalRecoveryPercent: [], totalYardPercent: []
    };

    for(let index in result) {
      objOfArrays.categories.push(result[index].categories);
      objOfArrays.totalActive.push(result[index].active / result[index].numDivider);
      objOfArrays.totalWithDrivers.push(result[index].with_driver / result[index].numDivider);
      objOfArrays.totalWithoutDrivers.push(result[index].without_drivers / result[index].numDivider);
      objOfArrays.totalOtr.push(result[index].otr / result[index].numDivider);
      objOfArrays.totalDealerChicago.push(result[index].dealer_chicago / result[index].numDivider);
      objOfArrays.totalDealerReady.push(result[index].dealer_ready / result[index].numDivider);
      objOfArrays.totalDealerNotReady.push(result[index].dealer_not_ready / result[index].numDivider);
      objOfArrays.totalRecovery.push(result[index].recovery / result[index].numDivider);
      objOfArrays.totalYard.push(result[index].yard / result[index].numDivider);

      objOfArrays.totalActivePercent.push(((result[index].with_driver * 100) / result[index].active) / result[index].numDivider);
      objOfArrays.totalWithDriversPercent.push(((result[index].with_driver * 100) / result[index].numDivider) / (result[index].active / result[index].numDivider));
      objOfArrays.totalWithoutDriversPercent.push(((result[index].without_drivers * 100) / result[index].numDivider) / (result[index].active / result[index].numDivider));
      objOfArrays.totalOtrPercent.push(((result[index].otr * 100) / result[index].numDivider) / (result[index].active / result[index].numDivider));
      objOfArrays.totalDealerChicagoPercent.push(((result[index].dealer_chicago * 100) / result[index].numDivider) / (result[index].active / result[index].numDivider));
      objOfArrays.totalDealerReadyPercent.push(((result[index].dealer_ready * 100) / result[index].numDivider) / (result[index].active / result[index].numDivider));
      objOfArrays.totalDealerNotReadyPercent.push(((result[index].dealer_not_ready * 100) / result[index].numDivider) / (result[index].active / result[index].numDivider));
      objOfArrays.totalRecoveryPercent.push(((result[index].recovery * 100) / result[index].numDivider) / (result[index].active / result[index].numDivider));
      objOfArrays.totalYardPercent.push(((result[index].yard * 100) / result[index].numDivider) / (result[index].active / result[index].numDivider));
    }
    console.log(objOfArrays);
    return objOfArrays;
};

//Count data by perid Active trailers
countDataByPeriodActiveTrailers(data: any, viewBy: string) {
  let sortedData: any = data.sort((a: any, b: any) => {
    return <any>new Date(a.date) - <any>new Date(b.date);
  })
  let group = sortedData.reduce((r: any, o: any) => {

      let periodName: any;

      if(viewBy === 'day') {
        periodName = `${moment(o.date).startOf('day').format('ddd MM.DD')}`;
      };

      if(viewBy === 'week') {
        periodName = `${moment(o.date).startOf('isoWeek').format('MMM DD')}-${moment(o.date).endOf('isoWeek').format('DD')}`;
      };

      if(viewBy === 'month') {
        periodName = moment(o.date).startOf('month').format('MMM yy');
      };

      if(viewBy === 'quarter') {
        periodName = `${moment(o.date).startOf('quarter').format('MM.DD')}-${moment(o.date).endOf('quarter').format('MM.DD')}`;
      };

      if(viewBy === 'year') {
        periodName = `${moment(o.date).startOf('year').format('YYYY')}`;
      };

      if(r[periodName]) {
        r[periodName].active += o.active;
        r[periodName].with_driver += o.with_driver;
        r[periodName].without_drivers += o.without_drivers;
        r[periodName].otr += o.otr;
        r[periodName].dealer_ready += o.dealer_ready;
        r[periodName].dealer_not_ready += o.dealer_not_ready;
        r[periodName].recovery += o.recovery;
        r[periodName].yard += o.yard;

        r[periodName].numDivider += 1;
      } else {
        r[periodName] = {categories: periodName, active: o.active ? o.active : 0,
        with_driver: o.with_driver ? o.with_driver : 0, without_drivers: o.without_drivers ? o.without_drivers : 0,  otr: o.otr ? o.otr : 0,
        dealer_ready: o.dealer_ready ? o.dealer_ready : 0, dealer_not_ready: o.dealer_not_ready ? o.dealer_not_ready : 0,
        recovery: o.recovery ? o.recovery : 0, yard: o.yard ? o.yard : 0, numDivider: o.numDivider ? o.numDivider: 1};
      };
      return r;
    }, {});

    let result = Object.keys(group).map((key) => group[key]);
    let objOfArrays: any = {
    categories: [], totalActive: [], totalOtr: [], totalDealerReady: [], totalDealerNotReady: [], totalRecovery: [],
    totalYard: [], totalWithDrivers: [], totalWithoutDrivers: [], totalWithDriversPercent: [], totalWithoutDriversPercent: [],
    totalActivePercent: [], totalOtrPercent: [], totalDealerReadyPercent: [], totalDealerNotReadyPercent: [],
    totalRecoveryPercent: [], totalYardPercent: []
    };

    for(let index in result) {
      objOfArrays.categories.push(result[index].categories);
      objOfArrays.totalActive.push(result[index].active / result[index].numDivider);
      objOfArrays.totalWithDrivers.push(result[index].with_driver / result[index].numDivider);
      objOfArrays.totalWithoutDrivers.push(result[index].without_drivers / result[index].numDivider);
      objOfArrays.totalOtr.push(result[index].otr / result[index].numDivider);
      objOfArrays.totalDealerReady.push(result[index].dealer_ready / result[index].numDivider);
      objOfArrays.totalDealerNotReady.push(result[index].dealer_not_ready / result[index].numDivider);
      objOfArrays.totalRecovery.push(result[index].recovery / result[index].numDivider);
      objOfArrays.totalYard.push(result[index].yard / result[index].numDivider);

      objOfArrays.totalActivePercent.push(((result[index].with_driver * 100) / result[index].active) / result[index].numDivider);
      objOfArrays.totalWithDriversPercent.push(((result[index].with_driver * 100) / result[index].numDivider) / (result[index].active / result[index].numDivider));
      objOfArrays.totalWithoutDriversPercent.push(((result[index].without_drivers * 100) / result[index].numDivider) / (result[index].active / result[index].numDivider));
      objOfArrays.totalOtrPercent.push(((result[index].otr * 100) / result[index].numDivider) / (result[index].active / result[index].numDivider));
      objOfArrays.totalDealerReadyPercent.push(((result[index].dealer_ready * 100) / result[index].numDivider) / (result[index].active / result[index].numDivider));
      objOfArrays.totalDealerNotReadyPercent.push(((result[index].dealer_not_ready * 100) / result[index].numDivider) / (result[index].active / result[index].numDivider));
      objOfArrays.totalRecoveryPercent.push(((result[index].recovery * 100) / result[index].numDivider) / (result[index].active / result[index].numDivider));
      objOfArrays.totalYardPercent.push(((result[index].yard * 100) / result[index].numDivider) / (result[index].active / result[index].numDivider));
    }
    console.log(objOfArrays);
    return objOfArrays;
};

  //Count all data by period and provided key
  countAllDataByPeriodAndKey(data: any, viewBy: string, key: string, keyTitle: string, countTitle?: string) {
    let sortedData: any = data.sort((a: any, b: any) => {
      return <any>new Date(a.date) - <any>new Date(b.date);
    });

    let group = sortedData.reduce((r: any, o: any) => {
      let periodName: any;
      if (viewBy === 'day') {
        periodName = `${moment(o.date).startOf('day').format('ddd MM.DD')}`;
      } else if (viewBy === 'week') {
        periodName = `${moment(o.date).startOf('isoWeek').format('MMM DD')}-${moment(o.date).endOf('isoWeek').format('DD')}`;
      } else if (viewBy === 'month') {
        periodName = moment(o.date).startOf('month').format('MMM yy');
      } else if (viewBy === 'quarter') {
        periodName = `${moment(o.date).startOf('quarter').format('MM.DD')}-${moment(o.date).endOf('quarter').format('MM.DD')}`;
      } else if (viewBy === 'year') {
        periodName = `${moment(o.date).startOf('year').format('YYYY')}`;
      }

      if (r[periodName]) {
        r[periodName][key] += o[key];
        r[periodName].COUNT += 1;
      } else {
        r[periodName] = {
          categories: periodName,
          timestamp: o.date,
          [key]: o[key] ? o[key] : 0,
          COUNT: 1,
        };
      }

      return r;
    }, {});

    let result: any[] = [];

    let objOfArraysCount: any = {
      name: countTitle ? countTitle : 'Count',
      categories: [],
      timestampArray: [],
      total: [],
      data: data,
      title: countTitle ? countTitle : 'Count',
    };

    let objOfArrays: any = {
      name: keyTitle,
      categories: [],
      timestampArray: [],
      total: [],
      data: data,
      title: keyTitle,
    };

    for (let index in group) {
      objOfArraysCount.categories.push(group[index].categories);
      objOfArraysCount.timestampArray.push(group[index].timestamp);
      objOfArraysCount.total.push(group[index].COUNT);

      objOfArrays.categories.push(group[index].categories);
      objOfArrays.timestampArray.push(group[index].timestamp);
      objOfArrays.total.push(group[index][key]);
    }

    result.push(objOfArraysCount);
    result.push(objOfArrays);

    return result;
  }

  //Hometime column chart
  countDataByPeriodColumnChart(data: any, viewBy: string, dateKey: string, propertyNameToCount: string) {
    let group = data.reduce((r: any, o: any) => {
    let periodName: any;
    if(viewBy === 'day') {
      periodName = `${moment(o[dateKey]).startOf('day').format('ddd MM.DD')}`;
    };

    if(viewBy === 'week') {
      periodName = `${moment(o[dateKey]).startOf('isoWeek').format('MMM DD')}-${moment(o[dateKey]).endOf('isoWeek').format('DD')}`;
    };

    if(viewBy === 'month') {
      periodName = moment(o[dateKey]).startOf('month').format('MMM yy');
    };

    if(viewBy === 'quarter') {
      periodName = `${moment(o[dateKey]).startOf('quarter').format('MM.DD')}-${moment(o[dateKey]).endOf('quarter').format('MM.DD')}`;
    };

    if(viewBy === 'year') {
      periodName = `${moment(o[dateKey]).startOf('year').format('YYYY')}`;
    };

    if(r[periodName]) {
      r[periodName][propertyNameToCount] += o[propertyNameToCount];
      r[periodName].driverList.push(o.drivers);
      r[periodName].percent = (o[propertyNameToCount] / o.active) * 100;
    } else {
      r[periodName] = {
        categories: periodName, 
        timestamp: o.date, 
        driverList: [o.drivers],
        [propertyNameToCount]: o[propertyNameToCount] ? o[propertyNameToCount] : 0, 
        percent: (o[propertyNameToCount] / o.active) * 100,
      };
    };
      return r;
    }, {});
    
    let result = Object.keys(group).map((key) => group[key]);
    let objOfArrays: any = {categories: [], timestampArray: [], total: [], percentArray: [], driversList: []};
    for(let index in result) {
      objOfArrays.categories.push(result[index].categories);
      objOfArrays.timestampArray.push(result[index].timestamp)
      objOfArrays.total.push(result[index][propertyNameToCount]);
      objOfArrays.percentArray.push(Math.round(result[index].percent));
      objOfArrays.driversList.push({drivers: result[index].driverList.flat()});
    }
    return objOfArrays;

  };

//Overview
countDataByPeriodOverview(data: any, viewBy: string, key: string, type: string, title: string, condition?: boolean) {
  console.log(data);
  let sortedData: any = data.sort((a: any, b: any) => {
    return <any>new Date(a.datecreated) - <any>new Date(b.datecreated);
  })
  let group = sortedData.reduce((r: any, o: any) => {
    let condition1: boolean = type === 'all';
    let condition2: any = type === o.driver_type;
    if (condition1 || condition2) {
      let periodName: any;

      if (viewBy === 'day') {
        periodName = `${moment(o.datecreated).startOf('day').format('ddd MM.DD')}`;
      };

      if (viewBy === 'week') {
        periodName = `${moment(o.datecreated).startOf('isoWeek').format('MMM DD')}-${moment(o.datecreated).endOf('isoWeek').format('DD')}`;
      };

      if (viewBy === 'month') {
        periodName = moment(o.datecreated).startOf('month').format('MMM yy');
      };

      if (viewBy === 'quarter') {
        periodName = `${moment(o.datecreated).startOf('quarter').format('MM.DD')}-${moment(o.datecreated).endOf('quarter').format('MM.DD')}`;
      };

      if (viewBy === 'year') {
        periodName = `${moment(o.datecreated).startOf('year').format('YYYY')}`;
      };
      if (r[periodName]) {
        r[periodName].grossRpm += o.gross;
        r[periodName].mileageRpm += o.mileage;
        r[periodName][key] += o[key];
        r[periodName].numDivider += 1;
      } else {
        r[periodName] = { categories: periodName, timestamp: o.datecreated, [key]: o[key] ? o[key] : 0, 
        grossRpm: o.gross ? o.gross : 0, mileageRpm: o.mileage ? o.mileage : 0, numDivider: o.numDivider ? o.numDivider : 1 };
      };
    }
    return r;
  }, {});

  let result = Object.keys(group).map((key) => group[key]);
  console.log(result)
  let titleArray: any[] = [];
  titleArray.push(title);
  let objOfArrays: any = { name: titleArray, categories: [], timestampArray: [], total: [], data: data, key: key, title: title, type: type };
  for (let index in result) {
    objOfArrays.categories.push(result[index].categories);
    objOfArrays.timestampArray.push(result[index].timestamp)
    if (condition) {
      if(key === 'rate_per_mile') {
        let rpm: number = result[index].grossRpm / result[index].mileageRpm ? (result[index].grossRpm / result[index].mileageRpm) : 0;
        objOfArrays.total.push(rpm.toFixed(2));
      }
      else {
        let value: number = result[index][key] === 0 ? result[index][key] : result[index][key] / result[index].numDivider;
        objOfArrays.total.push(Math.round(value));
      }
    } else {
      objOfArrays.total.push(Math.round(result[index][key]));
    }
  }
  return objOfArrays;
};

countDataByPeriodDispath(data: any, viewBy: string, key: string, title: string) {
  console.log(data);
  let sortedData: any = data.sort((a: any, b: any) => {
    return <any>new Date(a.date) - <any>new Date(b.date);
  })
  let group = sortedData.reduce((r: any, o: any) => {
    let periodName: any;

    if (viewBy === 'day') {
      periodName = `${moment(o.date).startOf('day').format('ddd MM.DD')}`;
    };

    if (viewBy === 'week') {
      periodName = `${moment(o.date).startOf('isoWeek').format('MMM DD')}-${moment(o.date).endOf('isoWeek').format('DD')}`;
    };

    if (viewBy === 'month') {
      periodName = moment(o.date).startOf('month').format('MMM yy');
    };

    if (viewBy === 'quarter') {
      periodName = `${moment(o.date).startOf('quarter').format('MM.DD')}-${moment(o.date).endOf('quarter').format('MM.DD')}`;
    };

    if (viewBy === 'year') {
      periodName = `${moment(o.date).startOf('year').format('YYYY')}`;
    };
    if (r[periodName]) {
      r[periodName].grossRpm += o.gross;
      r[periodName].mileageRpm += o.mileage;
      r[periodName][key] += o[key];
      r[periodName].numDivider += 1;
    } else {
      r[periodName] = { categories: periodName, timestamp: o.date, [key]: o[key] ? o[key] : 0, 
      grossRpm: o.gross ? o.gross : 0, mileageRpm: o.mileage ? o.mileage : 0, numDivider: o.numDivider ? o.numDivider : 1 };
    };
    return r;
  }, {});

  let result = Object.keys(group).map((key) => group[key]);
  console.log(result)
  let objOfArrays: any = { name: [data[0].name + ' (' + title + ')'], categories: [], timestampArray: [], total: [], data: data, key: key, title: title};
  for (let index in result) {
    objOfArrays.categories.push(result[index].categories);
    objOfArrays.timestampArray.push(result[index].timestamp)

    if(key === 'rate_per_mile') {
      let rpm: number = result[index].grossRpm / result[index].mileageRpm ? (result[index].grossRpm / result[index].mileageRpm) : 0;
      objOfArrays.total.push(rpm.toFixed(2));
    }      
    else if (key == 'avg_weight' || key == 'gross_per_load'
    || key == 'lengt_per_load' || key == 'drivers') {
      let value: number = result[index][key] === 0 ? result[index][key] : result[index][key] / result[index].numDivider;
      objOfArrays.total.push(Math.round(value));
    }
    else {;
      objOfArrays.total.push(Math.round(result[index][key]));
    }
  }
  console.log(objOfArrays);
  return objOfArrays;
};


//Count by period down cards
countDataByPeriodDownOverview(data: any, viewBy: string, key: string, title: string, maintenace?: any, type?: string) {
  let sortedData: any = data.sort((a: any, b: any) => {
    return <any>new Date(a.date) - <any>new Date(b.date);
  })
  let group = sortedData.reduce((r: any, o: any) => {
    let periodName: any;
    if (viewBy === 'day') {
      periodName = `${moment(o.date).startOf('day').format('ddd MM.DD')}`;
    };

    if (viewBy === 'week') {
      periodName = `${moment(o.date).startOf('isoWeek').format('MMM DD')}-${moment(o.date).endOf('isoWeek').format('DD')}`;;
    };

    if (viewBy === 'month') {
      periodName = moment(o.date).startOf('month').format('MMM yy');
    };

    if (viewBy === 'quarter') {
      periodName = `${moment(o.date).startOf('quarter').format('MM.DD')}-${moment(o.date).endOf('quarter').format('MM.DD')}`;
    };

    if (viewBy === 'year') {
      periodName = `${moment(o.date).startOf('year').format('YYYY')}`;
    };

    if (r[periodName]) {
      r[periodName][key] += o[key];
      r[periodName].numDivider += 1;
    } else {
      r[periodName] = { categories: periodName, timestamp: o.date, [key]: o[key] ? o[key] : 0, numDivider: o.numDivider ? o.numDivider : 1 };
    };
    return r;
  }, {});

  let result = Object.keys(group).map((key) => group[key]);
  let titleArray: any[] = [];
  if (key === 'trucks' || key === 'trailers' || maintenace) {
    titleArray.push(title);
  } else {
    titleArray.push(data[0].name + ' (' + title + ')');
  }

  let objOfArrays: any = { name: titleArray, categories: [], timestampArray: [], total: [], data: data, key: key, title: title, type: type };
  for (let index in result) {
    let value: any = result[index][key];
    if (key == 'avg_weight' || key == 'gross_per_load'
    || key == 'lengt_per_load' || key == 'drivers' || key == 'rate_per_mile') {
      value = value === 0 ? value : value / result[index].numDivider;
    }
    value = key === 'rate_per_mile' ? value.toFixed(2) : Math.round(value);
    objOfArrays.categories.push(result[index].categories);
    objOfArrays.timestampArray.push(result[index].timestamp)
    objOfArrays.total.push(value);
  }
  return objOfArrays;
};

//Get number of days

getNumberOfDaysOverview(start: any, end: any) {
  const date1 = new Date(start);
  const date2 = new Date(end);

  const oneDay = 1000 * 60 * 60 * 24;

  const diffInTime = date2.getTime() - date1.getTime();

  const diffInDays = Math.round(diffInTime / oneDay);

  return diffInDays;
}

//Count hired terminated

countHiredTerminatedOverview(array: any) {
  let sumHired: number = 0;
  let sumResigned: number = 0;
  let sumTermination: number = 0;
  for (let key in array) {
    sumHired += array[key].hired;
    sumResigned += array[key].resignation;
    sumTermination += array[key].termination;
  }

  return { sumHired, sumResigned, sumTermination };
};

//Group by weeks
groupByWeeksOverview(data: any, viewBy: string) {
  let group = data.reduce(function (r: any, o: any) {
    let periodName: any;

    if (viewBy === 'day') {
      periodName = `${moment(o.week).startOf('day').format('ddd MM.DD')}`;
    };

    if (viewBy === 'week') {
      periodName = `${moment(o.week).startOf('isoWeek').format('MMM DD')}-${moment(o.week).endOf('isoWeek').format('DD')}`;
    };

    if (viewBy === 'month') {
      periodName = moment(o.week).startOf('month').format('MMM yy');
    };

    if (viewBy === 'quarter') {
      periodName = `${moment(o.week).startOf('quarter').format('MM.DD')}-${moment(o.week).endOf('quarter').format('MM.DD')}`;
    };

    if (viewBy === 'year') {
      periodName = `${moment(o.week).startOf('year').format('YYYY')}`;
    };


    if (r[periodName]) {
      r[periodName].hired += o.hired;
      r[periodName].resigned += o.resignation;
      r[periodName].termination += o.termination;
      r[periodName].terminated += o.terminated;
      if (o.hired_drivers !== null) {
        r[periodName].hired_drivers.push(o.hired_drivers);
      }
      if (o.resignation_drivers !== null) {
        r[periodName].resignation_drivers.push(o.resignation_drivers);
      }
      if (o.termination_drivers !== null) {
        r[periodName].termination_drivers.push(o.termination_drivers);
      }
      if (o.terminated_drivers !== null) {
        r[periodName].terminated_drivers.push(o.terminated_drivers);
      }

    } else {
      r[periodName] = {
        categories: periodName, 
        hired: o.hired ? o.hired : 0, 
        resigned: o.resignation ? o.resignation : 0 , 
        termination: o.termination ? o.termination : 0,
        terminated: o.terminated ? o.terminated : 0,
        hired_drivers: [o.hired_drivers], 
        resignation_drivers: [o.resignation_drivers], 
        termination_drivers: [o.termination_drivers],
        terminated_drivers: [o.terminated_drivers],
      };
    };
    return r;
  }, {});

  let result = Object.keys(group).map((key) => group[key]);

  let objOfArrays: any = { 
    categories: [], 
    hiredPercent: [], 
    resignedPercent: [], 
    terminationPercent: [], 
    terminatedPercent: [],
    hired: [], 
    resigned: [], 
    termination: [],
    terminated: [],
    hiredData: [], 
    resignedData: [], 
    terminationData: [],
    terminatedData: [] 
  };

  for (let index in result) {
    let total: number = result[index].hired + result[index].resigned + result[index].termination;

    objOfArrays.categories.push(result[index].categories);
    objOfArrays.hiredPercent.push(this.addCommasDots((result[index].hired / total) * 100, 'round'));
    objOfArrays.resignedPercent.push(this.addCommasDots((result[index].resigned / total) * 100, 'round'));
    objOfArrays.terminationPercent.push(this.addCommasDots((result[index].termination / total) * 100, 'round'));
    objOfArrays.terminatedPercent.push(this.addCommasDots((result[index].terminated / total) * 100, 'round'));
    objOfArrays.hired.push(result[index].hired);
    objOfArrays.resigned.push(result[index].resigned );
    objOfArrays.termination.push(result[index].termination);
    objOfArrays.terminated.push(result[index].terminated);
    objOfArrays.hiredData.push(result[index].hired_drivers.flat(1));
    objOfArrays.resignedData.push(result[index].resignation_drivers.flat(1));
    objOfArrays.terminationData.push(result[index].termination_drivers.flat(1));
    objOfArrays.terminatedData.push(result[index].terminated_drivers.flat(1));
  }

  return objOfArrays;
}

//Missing date convert to specific period
convertToSpecificPeriodOverview(date: any, viewBy: string) {
  let periodName: string = '';
  if (viewBy === 'day') {
    periodName = `${moment(date).startOf('day').format('ddd MM.DD')}`;
  };

  if (viewBy === 'week') {
    periodName = `${moment(date).startOf('isoWeek').format('MMM DD')}-${moment(date).endOf('isoWeek').format('DD')}`;
  };

  if (viewBy === 'month') {
    periodName = moment(date).startOf('month').format('MMM yy');
  };

  if (viewBy === 'quarter') {
    periodName = `${moment(date).startOf('quarter').format('MM.DD')}-${moment(date).endOf('quarter').format('MM.DD')}`;
  };

  if (viewBy === 'year') {
    periodName = `${moment(date).startOf('year').format('YYYY')}`;
  };

  return periodName;
}

//Dispatcher profile
countDataByPeriodDownDispProfile(data: any, viewBy: string, key: string, title: string) {
  let sortedData: any = data.sort((a: any, b: any) => {
    return <any>new Date(a.date) - <any>new Date(b.date);
  })
  let group = sortedData.reduce((r: any, o: any) => {
    let periodName: any;
    if (viewBy === 'day') {
      periodName = `${moment(o.date).startOf('day').format('ddd MM.DD')}`;
    };

    if (viewBy === 'week') {
      periodName = `${moment(o.date).startOf('isoWeek').format('MMM DD')}-${moment(o.date).endOf('isoWeek').format('DD')}`;;
    };

    if (viewBy === 'month') {
      periodName = moment(o.date).startOf('month').format('MMM yy');
    };

    if (viewBy === 'quarter') {
      periodName = `${moment(o.date).startOf('quarter').format('MM.DD')}-${moment(o.date).endOf('quarter').format('MM.DD')}`;
    };

    if (viewBy === 'year') {
      periodName = `${moment(o.date).startOf('year').format('YYYY')}`;
    };

    if (r[periodName]) {
      r[periodName][key] += o[key];
      r[periodName].numDivider += 1;
    } else {
      r[periodName] = { categories: periodName, timestamp: o.date, [key]: o[key] ? o[key] : 0, numDivider: o.numDivider ? o.numDivider : 1 };
    };
    return r;
  }, {});

  let result = Object.keys(group).map((key) => group[key]);
  let titleArray: any[] = [];
  let name: string = data[0].name?.includes('/') ? data[0].name?.split('/')[0].trim() : data[0].name;
  titleArray.push(name + ' (' + title + ')');


  let objOfArrays: any = { name: titleArray, categories: [], timestampArray: [], total: [], data: data, key: key, title: title };

  for (let index in result) {
    let value: any = result[index][key];
    if (key == 'avg_weight' || key == 'gross_per_load'
      || key == 'lengt_per_load' || key == 'drivers' || key == 'rate_per_mile') {
      value = value === 0 ? value : value / result[index].numDivider;
    }
    value = key === 'rate_per_mile' ? value.toFixed(2) : Math.round(value);
    objOfArrays.categories.push(result[index].categories);
    objOfArrays.timestampArray.push(result[index].timestamp)
    objOfArrays.total.push(value);
  }
  return objOfArrays;
};

  //Group by date
  groupByDate(array: any) {
    const groups: any = array.reduce((acc: any, date: any) => {

      const yearWeek: any =
        `${moment(date.pay_date).startOf('isoWeek').format('MM.DD')}-${moment(date.pay_date).endOf('isoWeek').format('MM.DD')}`;


      if (!acc[yearWeek]) {
        acc[yearWeek] = [];
      }

      acc[yearWeek].push(date);

      return acc;

    }, {});
    return groups;
  }

  //Group by name
  groupByName(array: any, name: string, viewBy: string) {
    const group: any = array.reduce((acc: any, o: any) => {

      let periodName: any;

      if (viewBy === 'week') {
        periodName = `${moment(o.pay_date).startOf('isoWeek').format('MMM DD')} - ${moment(o.pay_date).endOf('isoWeek').format('MMM DD YYYY')}`;;
      };

      if (viewBy === 'month') {
        periodName = moment(o.pay_date).startOf('month').format('MMM yy');
      };

      if (viewBy === 'quarter') {
        periodName = `${moment(o.pay_date).startOf('quarter').format('MM.DD')}-${moment(o.pay_date).endOf('quarter').format('MM.DD')}`;
      };

      if (viewBy === 'year') {
        periodName = `${moment(o.pay_date).startOf('year').format('YYYY')}`;
      };

      if (name === o.driver_name) {
        let amount = Number(o.amount === 'X' || o.amount === 'T' || o.amount === 'N' ? 0 : o.amount);
        if (acc[periodName]) {
          acc[periodName].total += amount;
          acc[periodName].miles += o.miles;
          acc[periodName].rate += o.rate;
          acc[periodName].loads += o.loads;
          acc[periodName].tonu += o.tonu;
        }
        else {
          acc[periodName] = {
            categories: periodName, total: amount ? amount : 0, miles: o.miles ? o.miles : 0,
            rate: o.rate ? o.rate : 0, loads: o.loads ? o.loads : 0, tonu: o.tonu ? o.tonu : 0
          };
        }

      }

      return acc;

    }, {});
    let result = Object.keys(group).map((key) => group[key]);
    let titleArray: any[] = [];
    titleArray.push(name + ' (paycheck)');

    let objOfArrays: any = {
      categories: [], name: titleArray, total: [], data: array, realName: name,
      miles: [], rate: [], loads: [], tonu: []
    };
    for (let key in result) {
      objOfArrays.categories.push(result[key].categories)
      objOfArrays.total.push(result[key].total);
      objOfArrays.miles.push(result[key].miles);
      objOfArrays.rate.push(result[key].rate);
      objOfArrays.loads.push(result[key].loads);
      objOfArrays.tonu.push(result[key].tonu);
    }
    return objOfArrays;
  }


  //Dispatcher performance
  countDataByPeriodDownDispPerformance(data: any, viewBy: string, key: string, title: string) {
    let sortedData: any = data.sort((a: any, b: any) => {
      return <any>new Date(a.date) - <any>new Date(b.date);
    })
    let group = sortedData.reduce((r: any, o: any) => {
      let periodName: any;
      if (viewBy === 'day') {
        periodName = `${moment(o.date).startOf('day').format('ddd MM.DD')}`;
      };

      if (viewBy === 'week') {
        periodName = `${moment(o.date).startOf('isoWeek').format('MMM DD')}-${moment(o.date).endOf('isoWeek').format('DD')}`;
      };

      if (viewBy === 'month') {
        periodName = moment(o.date).startOf('month').format('MMM yy');
      };

      if (viewBy === 'quarter') {
        periodName = `${moment(o.date).startOf('quarter').format('MM.DD')}-${moment(o.date).endOf('quarter').format('MM.DD')}`;
      };

      if (viewBy === 'year') {
        periodName = `${moment(o.date).startOf('year').format('YYYY')}`;
      };

      if (r[periodName]) {
        r[periodName][key] += o[key];
        r[periodName].numDivider += 1;
      } else {
        r[periodName] = { categories: periodName, timestamp: o.date, [key]: o[key] ? o[key] : 0, numDivider: o.numDivider ? o.numDivider : 1 };
      };
      return r;
    }, {});

    let result = Object.keys(group).map((key) => group[key]);
    let titleArray: any[] = [];
    titleArray.push(data[0].name + ' (' + title + ')');


    let objOfArrays: any = { name: titleArray, categories: [], timestampArray: [], total: [], data: data, key: key, title: title };

    for (let index in result) {
      let value: any = result[index][key];
      if (key == 'avg_weight' || key == 'gross_per_load'
        || key == 'lengt_per_load' || key == 'drivers' || key == 'rate_per_mile') {
        value = value === 0 ? value : value / result[index].numDivider;
      }
      value = key === 'rate_per_mile' ? value.toFixed(2) : Math.round(value);
      objOfArrays.categories.push(result[index].categories);
      objOfArrays.timestampArray.push(result[index].timestamp)
      objOfArrays.total.push(value);
    }
    console.log(objOfArrays)
    return objOfArrays;
  };

  sortByType(data: any) {
    const groups: any = data.reduce((acc: any, obj: any) => {
      if (obj.type !== '') {
        if (!acc[obj.type]) {
          acc[obj.type] = [];
        }

        acc[obj.type].push(obj);
      }
      return acc;

    }, {});
    return groups;
  }

  //Not covered trucks

  countAvgLossEstimatedLoadsNotCoveredTrucks(data: any, key1: string, key2: string) {
    let obj: any = { avgKey1: 0, avgKey2: 0 };
    if (data?.length > 0) {
      let sumKey1: number = 0;
      let sumKey2: number = 0;
      let numDivider: number = data.length;

      for (let key in data) {
        sumKey1 += data[key][key1];
        sumKey2 += data[key][key2];
      }

      let avgKey1 = sumKey1 / numDivider;
      let avgKey2 = (sumKey1 / numDivider) * (sumKey2 / numDivider)

      obj = { avgKey1: avgKey1, avgKey2: avgKey2 };
    }
    return obj;
  }

  
  countDataByPeriodNotCoveredTrucks(data: any, viewBy: string, alreadySelected: any[]) {

    let group = data.reduce((r: any, o: any) => {

      let periodName: any;

      if (viewBy === 'day') {
        periodName = `${moment(o.date).startOf('day').format('ddd MM.DD')}`;
      };

      if (viewBy === 'week') {
        periodName = `${moment(o.date).startOf('isoWeek').format('MMM DD')}-${moment(o.date).endOf('isoWeek').format('DD')}`;
      };

      if (viewBy === 'month') {
        periodName = moment(o.date).startOf('month').format('MMM yy');
      };

      if (viewBy === 'quarter') {
        periodName = `${moment(o.date).startOf('quarter').format('MM.DD')}-${moment(o.date).endOf('quarter').format('MM.DD')}`;
      };

      if (viewBy === 'year') {
        periodName = `${moment(o.date).startOf('year').format('YYYY')}`;
      };

      if (r[periodName]) {
        r[periodName].number += o.number;
        r[periodName].percentage += o.percentage;
        r[periodName].loss += o.loss;
      } else {
        r[periodName] = {
          categories: periodName,
          number: o.number ? o.number : 0,
          percentage: o.percentage ? o.percentage : 0,
          loss: o.loss ? o.loss : 0
        };
      };
      return r;
    }, {});

    let result = Object.keys(group).map((key) => group[key]);

    let objOfArrays: any = {
      data: data,
      categories: [],
      graphArray: [
        { name: ['LOST LOADS'], data: [], selected: alreadySelected[0].selected },
        { name: ['LOST LOAD PERCENTAGE'], data: [], selected: alreadySelected[1].selected },
        { name: ['ESTIMATED LOSS'], data: [], selected: alreadySelected[2].selected },
      ]
    };

    for (let key in result) {
      objOfArrays.categories.push(result[key].categories);
      objOfArrays.graphArray[0].data.push(result[key].number);
      objOfArrays.graphArray[1].data.push(result[key].percentage);
      objOfArrays.graphArray[2].data.push(result[key].loss);
    }
    return objOfArrays;
  };

  //Line chart load table

  countDataByPeriodLoadTableNotCoveredTrucks(data: any, viewBy: string, key: string, title: string, name: string) {
    console.log(data);
    let group = data.reduce((r: any, o: any) => {
      let periodName: any;
      if (viewBy === 'day') {
        periodName = `${moment(o.date).startOf('day').format('ddd MM.DD')}`;
      };

      if (viewBy === 'week') {
        periodName = `${moment(o.date).startOf('isoWeek').format('MMM DD')}-${moment(o.date).endOf('isoWeek').format('DD')}`;;
      };

      if (viewBy === 'month') {
        periodName = moment(o.date).startOf('month').format('MMM yy');
      };

      if (viewBy === 'quarter') {
        periodName = `${moment(o.date).startOf('quarter').format('MM.DD')}-${moment(o.date).endOf('quarter').format('MM.DD')}`;
      };

      if (viewBy === 'year') {
        periodName = `${moment(o.date).startOf('year').format('YYYY')}`;
      };

      if (r[periodName]) {
        r[periodName][key] += o[key];
      } else {
        r[periodName] = { categories: periodName, [key]: o[key] ? o[key] : 0 };
      };
      return r;
    }, {});

    let result = Object.keys(group).map((key) => group[key]);
    let titleArray: any[] = [];
    titleArray.push(name + ' (' + title + ')');

    let objOfArrays: any = { name: titleArray, categories: [], total: [], data: data, key: key, title: title, dName: name };

    for (let index in result) {
      objOfArrays.categories.push(result[index].categories);
      objOfArrays.total.push(Math.round(result[index][key]));
    }
    return objOfArrays;
  };

  countDataByPeriodDisPerformance(data: any, viewBy: string, key: string, type: string, title: string, condition?: boolean) {
    let sortedData: any = data.sort((a: any, b: any) => {
      return <any>new Date(a.datecreated) - <any>new Date(b.datecreated);
    })
    let group = sortedData.reduce((r: any, o: any) => {
      let condition1: boolean = type === 'all';
      let condition2: any = type === o.driver_type;
      if (condition1 || condition2) {

        let periodName: any;

        if (viewBy === 'day') {
          periodName = `${moment(o.datecreated).startOf('day').format('ddd MM.DD')}`;
        };

        if (viewBy === 'week') {
          periodName = `${moment(o.datecreated).startOf('isoWeek').format('MMM DD')}-${moment(o.datecreated).endOf('isoWeek').format('DD')}`;
        };

        if (viewBy === 'month') {
          periodName = moment(o.datecreated).startOf('month').format('MMM yy');
        };

        if (viewBy === 'quarter') {
          periodName = `${moment(o.datecreated).startOf('quarter').format('MM.DD')}-${moment(o.datecreated).endOf('quarter').format('MM.DD')}`;
        };

        if (viewBy === 'year') {
          periodName = `${moment(o.datecreated).startOf('year').format('YYYY')}`;
        };

        if (r[periodName]) {
          r[periodName].grossRpm += o.gross;
          r[periodName].mileageRpm += o.mileage;
          r[periodName][key] += o[key];
          r[periodName].numDivider += 1;
        } else {
          r[periodName] = { categories: periodName, timestamp: o.datecreated, 
          grossRpm: o.gross ? o.gross : 0, mileageRpm: o.mileage ? o.mileage : 0,
          [key]: o[key] ? o[key] : 0, numDivider: o.numDivider ? o.numDivider : 1 };
        };
      }
      return r;
    }, {});

    let result = Object.keys(group).map((key) => group[key]);
    let titleArray: any[] = [];
    titleArray.push(title);
    let objOfArrays: any = { name: titleArray, categories: [], timestampArray: [], total: [], data: data, key: key, title: title, type: type };
    for (let index in result) {
      objOfArrays.categories.push(result[index].categories);
      objOfArrays.timestampArray.push(result[index].timestamp)
      if (condition) {
        if(key === 'rate_per_mile') {
          let rpm: number = result[index].grossRpm / result[index].mileageRpm ? (result[index].grossRpm / result[index].mileageRpm) : 0;
          objOfArrays.total.push(rpm.toFixed(2));
        }
        else {
          let value: number = result[index][key] === 0 ? result[index][key] : result[index][key] / result[index].numDivider;
          objOfArrays.total.push(Math.round(value));
        }
      } else {
        objOfArrays.total.push(Math.round(result[index][key]));
      }
    }
    return objOfArrays;
  };

  //Prebooked loads
  countDataByPeriodPrebookedLoads(dates: any[], viewBy: string) {

    let group = dates.reduce(function (r, o) {
      let periodName: any;

      if (viewBy === 'day') {
        periodName = `${moment(o.datum).startOf('day').format('ddd MM.DD')}`;
      };

      if (viewBy === 'week') {
        periodName = `${moment(o.datum).startOf('isoWeek').format('DD')}-${moment(o.datum).endOf('isoWeek').format('DD-MM')}`;
        console.log(periodName);
      };

      if (viewBy === 'month') {
        periodName = moment(o.datum).startOf('month').format('MMM yy');
      };

      if (viewBy === 'quarter') {
        periodName = `${moment(o.datum).startOf('quarter').format('DD.MM')}-${moment(o.datum).endOf('quarter').format('DD.MM')}`;
      };

      if (viewBy === 'year') {
        periodName = `${moment(o.datum).startOf('year').format('YYYY')}`;
      };

      if (r[periodName]) {
        r[periodName].prebooked += o.prebooked;
        r[periodName].spot += o.spot;
      } else {
        r[periodName] = { categories: periodName, prebooked: o.prebooked ? o.prebooked : 0, spot: o.spot ? o.spot : 0 };
      };
      return r;
    }, {});

    let result = Object.keys(group).map((key) => group[key]);

    let objOfArrays: any = { categories: [], prebooked: [], spot: [] };

    for (let key in result) {
      objOfArrays.categories.push(result[key].categories);
      objOfArrays.prebooked.push(result[key].prebooked);
      objOfArrays.spot.push(result[key].spot);
    }

    return objOfArrays;
  };

  //Recruiting page
  //Count pie statistics method
  countPieStatisticsRecruiting(data: any[]) {
    let arrayOfArrays: any = [];
    let arrayOfNames: string[] = [];
    let arrayOfTotal: number[] = [];
    let unique = Array.from(new Set(data.map((item: any) => item.type.trim().replace(/ {2,}/g, " "))));

    for (let key in unique) {
      let number = 0;
      for (let key2 in data) {
        if (data[key2].type.trim().replace(/ {2,}/g, " ") == unique[key]) {
          number++;
        };
      };
      arrayOfNames.push(unique[key]);
      arrayOfTotal.push(number);
    }
    arrayOfArrays = [arrayOfNames, arrayOfTotal];

    return arrayOfArrays;
  };
  
  //Count hired terminated
  countHiredTerminatedRecruiting(array: any) {
    let sumHired: number = 0;
    let sumTerminated: number = 0;
    for (let key in array) {
      sumHired += array[key].hired;
      sumTerminated += array[key].terminated;
    }

    return { sumHired, sumTerminated };
  };
  
  //Count data by certain period
  countDataByPeriodRecruiting(dates: any[], period: string) {
    let dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    let monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    let group = dates.reduce(function (r, o) {
      let date = new Date(o.week_dates);
      let periodName: any;

      if (period === 'today') {
        periodName = 'Today';
      };

      if (period === 'days') {
        periodName = 'Yesterday';
      };

      if (period === 'isoWeek') {
        let day = date.getDay();
        periodName = dayNames[day];
      };

      if (period === 'year' || period.includes('-') || period === 'month') {
        let month = date.getMonth();
        periodName = monthNames[month];
      };

      if (r[periodName]) {
        r[periodName].hired += o.hired;
        r[periodName].terminated += o.terminated;
      } else {
        r[periodName] = { labels: periodName, hired: o.hired ? o.hired : 0, terminated: o.terminated ? o.terminated : 0 };
      };
      return r;
    }, {});

    let result = Object.keys(group).map((key) => group[key]);

    let objOfArrays: any = { labels: [], hired: [], terminated: [] };

    for (let key in result) {
      objOfArrays.labels.push(result[key].labels);
      objOfArrays.hired.push(result[key].hired);
      objOfArrays.terminated.push(result[key].terminated);
    }

    return objOfArrays;
  }
  
  //Get data by weeks 
  calculateDriverPaychecksByWeeksRecruiting(dates: any) {
    const groups: any = dates.reduce((acc: any, date: any) => {

      const yearWeek =
        `${moment(date.pay_date).startOf('isoWeek').format('MM.DD')}-${moment(date.pay_date).endOf('isoWeek').format('MM.DD')}`;


      if (!acc[yearWeek]) {
        acc[yearWeek] = [];
      }

      acc[yearWeek].push(date);

      return acc;

    }, {});

    return groups;
  }
  
  calculateDriverByWeeksRecruiting(dates: any, key: string) {
    const groups: any = dates.reduce((acc: any, date: any) => {

      const yearWeek =
        `${moment(date[key]).startOf('isoWeek').format('MM.DD')}-${moment(date[key]).endOf('isoWeek').format('MM.DD')}`;


      if (!acc[yearWeek]) {
        acc[yearWeek] = [];
      }

      acc[yearWeek].push(date);

      return acc;

    }, {});

    return groups;
  }
  
  countDataForDialogRecruiting(dates: any[], period: string) {
    let monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    let group = dates.reduce(function (r, o) {
      let date = new Date(o.week_dates);
      let periodName: any;

      let periodArr: any[] = ['today', 'days', 'isoWeek', 'month']


      if (periodArr.includes(period)) {
        periodName = o.week_dates;
      };

      if (period === 'year' || period.includes('-')) {
        let month = date.getMonth();
        periodName = monthNames[month];
      };

      if (!r[periodName]) {
        r[periodName] = [];
      };

      r[periodName].push(o);

      return r;
    }, {});
    let group2 = dates.reduce(function (r, o) {

      if (!r[o.week_dates]) {
        r[o.week_dates] = [];
      }

      r[o.week_dates].push(o);

      return r;
    }, {});

    let obj: any = { group: group, group2: group2 };

    return obj;
  }

  //Trucks empty trucks stats
  
  countDataByPeriodTrucks(data: any, viewBy: string, alreadySelected: any[]) {
    let numberOfDays: any;

    let group = data.reduce((r: any, o: any) => {

      let periodName: any;

      if (viewBy === 'day') {
        periodName = `${moment(o.datecreated).startOf('day').format('ddd MM.DD')}`;
        numberOfDays = 1;
      };

      if (viewBy === 'week') {
        periodName = `${moment(o.datecreated).startOf('isoWeek').format('MMM DD')}-${moment(o.datecreated).endOf('isoWeek').format('DD')}`;
        numberOfDays = this.getNumberOfDaysTrucks(moment(o.datecreated).startOf('isoWeek'), moment(o.datecreated).endOf('isoWeek'));
      };

      if (viewBy === 'month') {
        periodName = moment(o.datecreated).startOf('month').format('MMM yy');
        numberOfDays = this.getNumberOfDaysTrucks(moment(o.datecreated).startOf('month'), moment(o.datecreated).endOf('month'));
      };

      if (viewBy === 'quarter') {
        periodName = `${moment(o.datecreated).startOf('quarter').format('MM.DD')}-${moment(o.datecreated).endOf('quarter').format('MM.DD')}`;
        numberOfDays = this.getNumberOfDaysTrucks(moment(o.datecreated).startOf('quarter'), moment(o.datecreated).endOf('quarter'));
      };

      if (viewBy === 'year') {
        periodName = `${moment(o.datecreated).startOf('year').format('YYYY')}`;
        numberOfDays = this.getNumberOfDaysTrucks(moment(o.datecreated).startOf('year'), moment(o.datecreated).endOf('year'));
      };

      if (r[periodName]) {
        r[periodName].empty_trucks += o.empty_trucks;
        r[periodName].yard += o.yard;
        r[periodName].recovery += o.recovery;
        r[periodName].dealership += o.dealership;
        r[periodName].empty_trucs_percent += o.empty_trucs_percent;
        r[periodName].loss_per_truck += o.loss;
        r[periodName].total_loss += o.total_loss;
      } else {
        r[periodName] = {
          categories: periodName,
          empty_trucks: o.empty_trucks ? o.empty_trucks : 0,
          yard: o.yard ? o.yard : 0,
          recovery: o.recovery ? o.recovery : 0,
          dealership: o.dealership ? o.dealership : 0,
          empty_trucs_percent: o.empty_trucs_percent ? o.empty_trucs_percent : 0,
          loss_per_truck: o.loss ? o.loss : 0,
          total_loss: o.total_loss ? o.total_loss : 0,
        };
      };
      return r;
    }, {});

    let result = Object.keys(group).map((key) => group[key]);

    let objOfArrays: any = {
      data: data,
      categories: [],
      graphArray: [
        { name: ['EMPTY TRUCKS'], data: [], selected: alreadySelected[0].selected },
        { name: ['YARD'], data: [], selected: alreadySelected[1].selected },
        { name: ['RECOVERY'], data: [], selected: alreadySelected[2].selected },
        { name: ['DEALERSHIP'], data: [], selected: alreadySelected[3].selected },
        { name: ['EMPTY TRUCKS %'], data: [], selected: alreadySelected[4].selected },
        { name: ['LOSS PER TRUCK'], data: [], selected: alreadySelected[5].selected },
        { name: ['TOTAL LOSS'], data: [], selected: alreadySelected[6].selected }
      ]
    };

    for (let key in result) {
      objOfArrays.categories.push(result[key].categories);
      objOfArrays.graphArray[0].data.push(result[key].empty_trucks / numberOfDays);
      objOfArrays.graphArray[1].data.push(result[key].yard / numberOfDays);
      objOfArrays.graphArray[2].data.push(result[key].recovery / numberOfDays);
      objOfArrays.graphArray[3].data.push(result[key].dealership / numberOfDays);
      objOfArrays.graphArray[4].data.push(result[key].empty_trucs_percent / numberOfDays);
      objOfArrays.graphArray[5].data.push(result[key].loss_per_truck / numberOfDays);
      objOfArrays.graphArray[6].data.push(result[key].total_loss);
    }
    return objOfArrays;
  };

  getNumberOfDaysTrucks(start: any, end: any) {
    const date1 = new Date(start);
    const date2 = new Date(end);

    const oneDay = 1000 * 60 * 60 * 24;

    const diffInTime = date2.getTime() - date1.getTime();

    const diffInDays = Math.round(diffInTime / oneDay);

    return diffInDays;
  }

  groupGraphDataByDate(obj: any) {
    let sortedData: any = obj.data.sort((a: any, b: any) => {
      return <any>new Date(a.date) - <any>new Date(b.date);
    });
    let dataArray: any[] = [];
    let startDate: any = new Date(obj.dateObj.startDate);
    let endDate: any = new Date(obj.dateObj.endDate);
    let index: number = 0;
    while (startDate <= endDate) {

      if(new Date(sortedData[index]?.date).toDateString() === startDate.toDateString()) {
        dataArray.push(sortedData[index]);
        index++;
      }
      else {
        let obj = {date: moment(startDate).format('YYYY-MM-DDT00:00:00'), gross: 0, loads: 0, mileage: 0, rate: 0};
        dataArray.push(obj);
      }
      startDate.setDate(startDate.getDate() + 1);
    }

    let group = dataArray.reduce((r: any, o: any) => {

      let periodName: any;

      if(obj.viewBy === 'day') {
        periodName = `${moment(o.date).startOf('day').format('ddd MM.DD')}`;
      };

      if(obj.viewBy === 'week') {
        periodName = `${moment(o.date).startOf('isoWeek').format('MMM DD')}-${moment(o.date).endOf('isoWeek').format('DD')}`;
      };

      if(obj.viewBy === 'month') {
        periodName = moment(o.date).startOf('month').format('MMM yy');
      };

      if(obj.viewBy === 'quarter') {
        periodName = `${moment(o.date).startOf('quarter').format('MM.DD')}-${moment(o.date).endOf('quarter').format('MM.DD')}`;
      };

      if(obj.viewBy === 'year') {
        periodName = `${moment(o.date).startOf('year').format('YYYY')}`;
      };

      if(r[periodName]) {
        r[periodName].gross += o.gross;
        r[periodName].mileage += o.mileage;
        r[periodName].rate += o.rate;
        r[periodName].loads += o.loads;
      } else {
        r[periodName] = {
          categories: periodName, 
          gross: o.gross ? o.gross : 0,
          mileage: o.mileage ? o.mileage : 0,
          rate: o.rate ? o.rate : 0,
          loads: o.loads ? o.loads : 0
        };
      };

      return r;

    }, {});
  
    let result = Object.keys(group).map((key) => group[key]);
    let objOfArrays: any = {
      categories: [], 
      series: [
        {name: 'Gross', data: []},
        {name: 'Miles', data: []},
        {name: 'Rate', data: []}
      ], 
      data: dataArray
    };

    for(let index in result) {
      objOfArrays.categories.push(result[index].categories);
      objOfArrays.series[0].data.push(result[index].gross);
      objOfArrays.series[1].data.push(result[index].mileage);
      objOfArrays.series[2].data.push(result[index].rate);
    }

    if(!obj.keys.grossSelected) {
      objOfArrays.series.shift();
    }
    if(!obj.keys.milesSelected) {
      objOfArrays.series.splice(objOfArrays.series.length === 3 ? 1 : 0, 1);
    }
    if(!obj.keys.rateSelected) {
      objOfArrays.series.pop();
    }
    console.log(objOfArrays);

    return objOfArrays;
  };

  groupGraphDataByDateDispatcherProfile(obj: any) {
    let sortedData: any = obj.data.sort((a: any, b: any) => {
      return <any>new Date(a.datecreated) - <any>new Date(b.datecreated);
    });
    let dataArray: any[] = [];
    let startDate: any = new Date(obj.dateObj.startDate);
    let endDate: any = new Date(obj.dateObj.endDate);
    let index: number = 0;
    while (startDate <= endDate) {

      if(new Date(sortedData[index]?.datecreated).toDateString() === startDate.toDateString()) {
        dataArray.push(sortedData[index]);
        index++;
      }
      else {
        let obj = {datecreated: moment(startDate).format('YYYY-MM-DDT00:00:00'), gross: 0, mileage: 0, rate_per_mile: 0};
        dataArray.push(obj);
      }
      startDate.setDate(startDate.getDate() + 1);
    }

    let group = dataArray.reduce((r: any, o: any) => {

      let periodName: any;

      if(obj.viewBy === 'day') {
        periodName = `${moment(o.datecreated).startOf('day').format('ddd MM.DD')}`;
      };

      if(obj.viewBy === 'week') {
        periodName = `${moment(o.datecreated).startOf('isoWeek').format('MMM DD')}-${moment(o.datecreated).endOf('isoWeek').format('DD')}`;
      };

      if(obj.viewBy === 'month') {
        periodName = moment(o.datecreated).startOf('month').format('MMM yy');
      };

      if(obj.viewBy === 'quarter') {
        periodName = `${moment(o.datecreated).startOf('quarter').format('MM.DD')}-${moment(o.datecreated).endOf('quarter').format('MM.DD')}`;
      };

      if(obj.viewBy === 'year') {
        periodName = `${moment(o.datecreated).startOf('year').format('YYYY')}`;
      };

      if(r[periodName]) {
        r[periodName].gross += o.gross;
        r[periodName].mileage += o.mileage;
        r[periodName].rate_per_mile += o.rate_per_mile;
        r[periodName].daily_load.push(o.daily_load ? o.daily_load : []);
      } else {
        r[periodName] = {
          categories: periodName, 
          gross: o.gross ? o.gross : 0,
          mileage: o.mileage ? o.mileage : 0,
          rate_per_mile: o.rate_per_mile ? o.rate_per_mile : 0,
          daily_load: o.daily_load ? (obj.viewBy === 'day'? o.daily_load : [o.daily_load]) : []
        };
      };

      return r;

    }, {});
  
    let result = Object.keys(group).map((key) => group[key]);
    let objOfArrays: any = {
      categories: [], 
      series: [
        {name: 'Gross', data: [], tableArray: []},
        {name: 'Miles', data: [], tableArray: []},
        {name: 'Rate', data: [], tableArray: []},
      ], 
      data: dataArray,
    };
    for(let index in result) {
      objOfArrays.categories.push(result[index].categories);
      objOfArrays.series[0].data.push(result[index].gross);
      objOfArrays.series[1].data.push(result[index].mileage);
      objOfArrays.series[2].data.push(this.addCommasDots(result[index].gross / result[index].mileage));

      if(obj.viewBy === 'day') {
        objOfArrays.series[0].tableArray.push(result[index].daily_load ? result[index].daily_load : []);
        objOfArrays.series[1].tableArray.push(result[index].daily_load ? result[index].daily_load : []);
        objOfArrays.series[2].tableArray.push(result[index].daily_load ? result[index].daily_load : []);
      }
      else {
        objOfArrays.series[0].tableArray.push(result[index].daily_load.flat()); 
        objOfArrays.series[1].tableArray.push(result[index].daily_load.flat()); 
        objOfArrays.series[2].tableArray.push(result[index].daily_load.flat()); 
      }
    }

    if(!obj.keys.grossSelected) {
      objOfArrays.series.shift();
    }
    if(!obj.keys.milesSelected) {
      objOfArrays.series.splice(objOfArrays.series.length === 3 ? 1 : 0, 1);
    }
    if(!obj.keys.rateSelected) {
      objOfArrays.series.pop();
    }
    return objOfArrays;
  };

  selectedColumn(obj: any, key: string, value: any, isSelected: boolean) {
    if(!isSelected) {
      return;
    }
    obj[key] = value;
  };

  transformDateFormat(date: string | null, format: string) {
    if(date) {
      return moment(date).format(format);
    }
    return '';
  };
  
}

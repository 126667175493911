import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { EmployeeService } from '@app/modules/employee/services/employee.service';
import { TransformService } from '@app/modules/shared/services/transform.service';
import moment = require('moment');
import { ApexAxisChartSeries, ApexChart, ApexDataLabels, ApexFill, ApexPlotOptions, ApexStates, ApexTooltip, ApexXAxis, ApexYAxis, ChartComponent } from 'ng-apexcharts';
import { Subscription } from 'rxjs';

export type ChartOptionsColumn = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  fill: ApexFill,
  colors: any;
  states: ApexStates,
  tooltip: ApexTooltip
};

@Component({
  selector: 'app-employee-activity-stats',
  templateUrl: './employee-activity-stats.component.html',
  styleUrls: ['./employee-activity-stats.component.scss']
})
export class EmployeeActivityStatsComponent implements OnInit, OnDestroy {
  @Input() data: any;
  @ViewChild("chart") chart: ChartComponent | any;
  public chartOptions: Partial<ChartOptionsColumn> | any;
  dataPointIndex: number = -1;

  //Status array
  statusArray: any[] = [];

  //Status by day
  statusByDayArray: any[] = [];
  activeIndex: number = 0;

  //Calendars
  isOpenFromCalendar: boolean = false;
  isOpenToCalendar: boolean = false;

  dateObj: any = {
    startDate: moment().startOf('month').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
  };

  //Overtime
  statisticsObj: any = {
    overtimeHours: 0,
    shortenedShift: 0,
    summary: 0
  };

  //Table statistics
  tableStatistics: any[] = [
    {status: 'Regular rest day', days: 0, percent: 0},
    {status: 'No status', days: 0, percent: 0},
  ]

  //Subscription
  subscription: Subscription | any;

  constructor(public transformService: TransformService,
              private employeeService: EmployeeService) { }

  ngOnInit(): void {
    this.dateObj.startDate = moment(this.data.dateObj.startDate).format('YYYY-MM-DD');
    this.dateObj.endDate = moment(this.data.dateObj.endDate).format('YYYY-MM-DD');
    this.getData();
  }

  getData() {
    console.log(this.data.obj.id)
    console.log(this.dateObj)
    this.subscription = this.employeeService.getActivityStats(this.data.obj.id, this.dateObj.startDate, this.dateObj.endDate)
    .subscribe((response: any) => {
      console.log(response)
      if(response[0]?.details) {
        this.statusArray = response;
        this.statusByDayArray = response[0].details;
        this.activeIndex = 0;
        
        //Statistics
        let sumOvertimeHours: number = response[0].overtime;
        let sumShortenedShift: number = response[0].shortned;
        let difference: number = sumOvertimeHours - sumShortenedShift;
        let mathAbs: any = Math.abs(difference);
        this.statisticsObj.overtimeHours = `${String(Math.floor(sumOvertimeHours / 3600000)).padStart(2, '0')}:${String(Math.floor((sumOvertimeHours % 3600000) / 60000)).padStart(2, '0')}`;
        this.statisticsObj.shortenedShift = `${String(Math.floor(sumShortenedShift / 3600000)).padStart(2, '0')}:${String(Math.floor((sumShortenedShift % 3600000) / 60000)).padStart(2, '0')}`;
        this.statisticsObj.summary = `${difference < 0 ? '-' : ''}${String(Math.floor(mathAbs / 3600000)).padStart(2, '0')}:${String(Math.floor((mathAbs % 3600000) / 60000)).padStart(2, '0')}`;
  
        this.showGraph(this.statusByDayArray, response[0].status);

        for(let key in response) {

          if(response[key].status === 'Regular rest day') {
            this.tableStatistics[0].status = response[key].status;
            this.tableStatistics[0].days = response[key].days;
            this.tableStatistics[0].percent = response[key].days_percent;
          }
  
          if(response[key].status === 'No status') {
            this.tableStatistics[1].status = response[key].status;
            this.tableStatistics[1].days = response[key].days;
            this.tableStatistics[1].percent = response[key].days_percent;
          }
  
        }

      }
    })
  };

  selectStatus(obj: any, index: number) {
    this.statusByDayArray = obj.details;
    this.activeIndex = index;
    this.showGraph(this.statusByDayArray, obj.status);
  }

  sortStatusArray(sort: Sort) {
    const data = this.statusArray.slice();
    if (!sort.active || sort.direction === '') {
      this.statusArray = data;
      return;
    }
    this.statusArray = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'status':
          return compare(a.status, b.status, isAsc);
        case 'days':
          return compare(a.days, b.days, isAsc);
        case 'days_percent':
          return compare(a.days_percent, b.days_percent, isAsc);
        default:
          return 0;
      }
    });
  };

  sortStatusByDayArray(sort: Sort) {
    const data = this.statusByDayArray.slice();
    if (!sort.active || sort.direction === '') {
      this.statusByDayArray = data;
      return;
    }
    this.statusByDayArray = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'status':
          return compare(a.status, b.status, isAsc);
        case 'date':
          return compare(a.date, b.date, isAsc);
        case 'day':
          return compare(a.day, b.day, isAsc);
        case 'updated_by':
          return compare(a.updated_by, b.updated_by, isAsc);
        default:
          return 0;
      }
    });
  };

  //Calendar method
  dateClicked(event: any, fromCalendar: boolean) {
    if(fromCalendar) {
      this.dateObj.startDate = moment(event._d).format('YYYY-MM-DD');
      this.isOpenFromCalendar = false;
    }
    else {
      this.dateObj.endDate = moment(event._d).format('YYYY-MM-DD');
      this.isOpenToCalendar = false;
    }
  };

  //Show graph
  showGraph(array: any[], status: string) {
    let categories: any[] = [];
    let total: any[] = [];

    const map = {
      'Monday': 1,'Tuesday': 2,'Wednesday': 3,'Thursday': 4,'Friday': 5,'Saturday': 6,
      'Sunday': 7
   };

    array.sort((a: any, b: any) => {
      return map[a.day] - map[b.day];
    });

    const groups: any = array.reduce((acc: any, obj: any) => {
      let key: string = obj.day;
    
      if (!acc[key]) {
        acc[key] = [];
      }
          
      acc[key].push(obj);
        
      return acc;
        
      }, {}
    );

    for(let key in groups) {
      categories.push(key);
      total.push(groups[key].length);
    }
    this.chartOptions = this.columnChart({categories: categories, total: total, title: status});
  }

  //Column chart
  columnChart(obj: any) {
    return {
      series: [
        {
          name: obj.title,
          data: obj.total
        }
      ],
      chart: {
        height: 225,
        type: "bar",
        fontFamily: 'Poppins',
        toolbar: {
          show: true,
          tools: {
            download: false
          }
        },
      },
      plotOptions: {
        bar: {
          dataLabels: {
            position: "top"
          }
        }
      },
      dataLabels: {
        enabled: true,
        offsetY: -30,
        style: {
          fontSize: "14px",
          colors: ["#304758"]
        }
      },
      tooltip: {
        y: {
          formatter: function (value: number) {
            let showValue: string = `${value} ${value === 1 ? 'day' : 'days'}`;
            return showValue;
          }
        }
      },
      xaxis: {
        categories: obj.categories,
        position: "bottom",
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        tooltip: {
          enabled: true
        }
      },
      yaxis: {
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        labels: {
          show: false
        }
      },
      colors: ['#b7b7b7'],
      fill: {
        opacity: 1
      },
      states: {
        normal: {
            filter: {
                type: 'none',
                value: 0,
            }
        },
        hover: {
            filter: {
                type: 'lighten',
                value: 0.15,
            }
        },
        active: {
            allowMultipleDataPointsSelection: false,
            filter: {
                type: 'none',
                value: 1
            }
        },
    }
    };
  }

  //Run
  run() {
    this.statusArray = [];
    this.statusByDayArray = [];
    this.chartOptions = undefined;
    this.statisticsObj.overtimeHours = '00';
    this.statisticsObj.shortenedShift = '00';
    this.statisticsObj.summary = '00';
    this.getData();
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-role-dialog',
  templateUrl: './delete-role-dialog.component.html',
  styleUrls: ['./delete-role-dialog.component.scss']
})
export class DeleteRoleDialogComponent {

  constructor(public dialogRef: MatDialogRef<DeleteRoleDialogComponent>) { }

  deleteUser() {
    this.dialogRef.close(true);
  };

}

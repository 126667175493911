import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { DateRange } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadsDocumentationDialogComponent } from '@app/modules/shared/components/loads-documentation-dialog/loads-documentation-dialog.component';
import { MsgForbbidenAccessComponent } from '@app/modules/shared/components/msg-forbbiden-access/msg-forbbiden-access.component';
import { SuccessDialogComponent } from '@app/modules/shared/components/success-dialog/success-dialog.component';
import { WarningMsgDialogComponent } from '@app/modules/shared/components/warning-msg-dialog/warning-msg-dialog.component';
import { RulesService } from '@app/modules/shared/services/rules.service';
import { TransformService } from '@app/modules/shared/services/transform.service';
import moment = require('moment');
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription, catchError, throwError } from 'rxjs';

import * as XLSX from 'xlsx'; 
import { DispatcherProfileService } from '../../services/dispatcher-profile.service';
import { SharedService } from '@app/modules/shared/services/shared.service';
import { LoadMapDialogComponent } from '@app/modules/shared/components/load-map-dialog/load-map-dialog.component';

@Component({
  selector: 'loads-table-dispatcher',
  templateUrl: './loads-table-dispatcher.component.html',
  styleUrls: ['./loads-table-dispatcher.component.scss']
})
export class LoadsTableDispatcherComponent implements OnInit, OnDestroy {
  permissions: any = this.rulesService.UserData[26].data;

  //Metadata
  filterMetaData: any = {
    countObj: {
      numOfAllLoads: 0,
      amount: 0,
      pickUpStopAmount: 0,
      rate: 0,
      marginPercent: 0,
      billableMiles: 0,
      paidMiles: 0,
      companyBenefitMilesPercent: 0,
      weight: 0,
      array: []
    }
  };

  //Filters
  loadSearch: string = '';
  brokerLoadNumSearch: string = '';
  truckSearch: string = '';
  driverSearch: string = '';
  dispatcherSearch: string = '';
  brokerSearch: string = '';
  typeSearch: string = '';
  puCitySearch: string = '';
  puStateSearch: string = '';
  delCitySearch: string = '';
  delStateSearch: string = '';
  statusSearch: string = '';

  //Num values
  radioBtns: any[] = [
    {name: 'More than'},
    {name: 'Less than'},
    {name: 'Equal'},
  ];

  //Pu amount
  radioPuAmountBtns: any[] = [
    {name: 'More than'},
    {name: 'Less than'},
    {name: 'Equal'},
    {name: 'MT Amount'}
  ];

  //Pickup
  radioLoadBtns: any[] = [
    {name: 'Checked'},
    {name: 'Unchecked'},
    {name: 'Both'},
  ];

  //Pu
  isOpenPuLoadMenu: boolean = false;
  radioPuLoadValue: string = 'Both';

  //Del
  isOpenDelLoadMenu: boolean = false;
  radioDelLoadValue: string = 'Both';

  //Amount
  isOpenAmountMenu: boolean = false;
  amountValue: string = '';
  amountSign: string = 'More than';

  //Pu amount
  isOpenPuAmountMenu: boolean = false;
  puAmountValue: string = '';
  puAmountSign: string = 'More than';

  //Rate
  isOpenRateMenu: boolean = false;
  rateValue: string = '';
  rateSign: string = 'More than';

  //Margin %
  isOpenMarginPercentMenu: boolean = false;
  marginPercentValue: string = '';
  marginPercentSign: string = 'More than';

  //Billable miles
  isOpenBillableMenu: boolean = false;
  billableValue: string = '';
  billableSign: string = 'More than';

  //Paid miles
  isOpenPaidMenu: boolean = false;
  paidValue: string = '';
  paidSign: string = 'More than';

  //Company benefit miles
  isOpenCompanyBenefitMenu: boolean = false;
  companyBenefitValue: string = '';
  companyBenefitSign: string = 'More than';

  //Weight
  isOpenWeightMenu: boolean = false;
  weightValue: string = '';
  weightSign: string = 'More than';

  //Pu calendar
  puRangeValue: DateRange<Date> | undefined;
  @Output() puRangeValueChange = new EventEmitter<DateRange<Date>>();
  isOpenPuCalendar: boolean = false;
  puObj: any;

  //Del calendar
  delRangeValue: DateRange<Date> | undefined;
  @Output() delRangeValueChange = new EventEmitter<DateRange<Date>>();
  isOpenDelCalendar: boolean = false;
  delObj: any;

  //Table
  sortedData: any[] = [];

  //Filters
  selectedFilter: number = 0;
  
  //Filter table and statistics
  filterArray: any[] = [
    {cardTitle: 'All loads', filter: 'all loads', array: [], value: 0},
    {cardTitle: 'Completed', filter: 'completed', array: [], value: 0},
    {cardTitle: 'Load booked', filter: 'load booked', array: [], value: 0},
    {cardTitle: 'Waiting for BOL', filter: 'waiting for bol', array: [], value: 0},
    {cardTitle: 'Waiting for payment', filter: 'waiting for payment', array: [], value: 0},
    {cardTitle: 'Critical loads', filter: 'transit delayed', array: [], value: 0},
    {cardTitle: 'Canceled loads', filter: 'canceled loads', array: [], value: 0},
    {cardTitle: 'Alerts', filter: 'alerts', array: [], value: 0},
  ];
  
  fileName: string = 'loads-table.xlsx';

  //Load statuses
  loadStatusArray: any[] = [
    {status: 'Load Booked'},
    {status: 'In Transit On Time'},
    {status: 'In Transit Delayed'},
    {status: 'Delivered Waiting For Bol'},
    {status: 'Waiting For Payment'},
    {status: 'Completed'}
  ];

  //Date
  dateObj: any = {
    startDate: moment().startOf('month').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD')
  };

  //Excel config
  xlsxConfig: any = [
    {columnName: 'No.', selected: true},
    {columnName: 'DISTANCE', selected: true},
    {columnName: 'LOAD NUMBER', selected: true},
    {columnName: 'BROKER LOAD NUMBER', selected: true},
    {columnName: 'STATUS', selected: true},
    {columnName: 'PICKED UP', selected: true},
    {columnName: 'DELIVERED', selected: true},
    {columnName: 'TRUCK NUMBER', selected: true},
    {columnName: 'DRIVER', selected: true},
    {columnName: 'DISPATCHER', selected: true},
    {columnName: 'BROKER', selected: true},
    {columnName: 'TYPE', selected: true},
    {columnName: 'PU DATE', selected: true},
    {columnName: 'DEL DATE', selected: true},
    {columnName: 'PU CITY', selected: true},
    {columnName: 'PU STATE', selected: true},
    {columnName: 'DEL CITY', selected: true},
    {columnName: 'DEL STATE', selected: true},
    {columnName: 'AMOUNT', selected: true},
    {columnName: 'PU AMOUNT', selected: true},
    {columnName: 'RATE', selected: true},
    {columnName: 'MARGIN %', selected: true},
    {columnName: 'BILLABLE MILES', selected: true},
    {columnName: 'PAID MILES', selected: true},
    {columnName: 'COMPANY BENEFIT MILES %', selected: true},
    {columnName: 'WEIGHT', selected: true}
  ];

  //Spinner
  loaded: boolean = false;
  error: boolean = false;

  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";

  spinnerBgColor: string = this.transformService.spinnerBgColor;
  spinnerType: string = this.transformService.spinnerType;

  subscription: Subscription | any;

  constructor(public transformService: TransformService,
              private dialog: MatDialog,
              private rulesService: RulesService,
              private sharedService: SharedService,
              private dispatchProfileService: DispatcherProfileService,
              private route: ActivatedRoute,
              private spinner: NgxSpinnerService,
              private router: Router) { }

  ngOnInit(): void {
    this.spinner.show('dispatcher-loads-table');
    this.getTableData();
  }

  getDate(result: any) {
    this.dateObj.startDate = result.startDate;
    this.dateObj.endDate = result.endDate;
    this.sortedData = [];
    this.selectedFilter = 0;
    this.error = false;
    this.loaded = false;
    this.spinner.show('dispatcher-loads-table');
    this.getTableData();
  };

  getTableData() {
    let id: number = +this.route.snapshot.paramMap.get('id');
    this.subscription = this.dispatchProfileService.getLoadsByDispatcher(this.dateObj.startDate, this.dateObj.endDate, id, this.transformService.filterParams)
    .pipe(catchError((err: any) => {
      this.spinner.hide('dispatcher-loads-table');
      this.loaded = true;
      this.error = true;
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      this.filterData(response);
      this.spinner.hide('dispatcher-loads-table');
      this.loaded = true;
      console.log(response)
    })
  }

  //Filter data
  filterData(data: any[]) {
    this.filterArray[0].array = data;

    let numOfAllLoads: number = data.length;
    let sumCompleted: number = 0;
    let sumLoadBooked: number = 0;
    let sumWaitingForBol: number = 0;
    let sumWaitingForPayment: number = 0;
    let sumCriticalLoads: number = 0;
    let sumCanceledLoads: number = 0;
    let sumAlerts: number = 0;


    data.forEach(element => {
      this.getDistance(element);
      
      if(element.status.toLowerCase() === 'completed') {
        sumCompleted++;
        this.filterArray[1].array.push(element);
      }

      if(element.status.toLowerCase() === 'load booked') {
        sumLoadBooked++;
        this.filterArray[2].array.push(element);
      }

      if(element.status.toLowerCase() === 'delivered waiting for bol') {
        sumWaitingForBol++;
        this.filterArray[3].array.push(element);
      }

      if(element.status.toLowerCase() === 'waiting for payment') {
        sumWaitingForPayment++;
        this.filterArray[4].array.push(element);
      }

      if(element.status.toLowerCase() === 'in transit delayed') {
        sumCriticalLoads++;
        this.filterArray[5].array.push(element);
      }

      if(element.is_cancelled) {
        sumCanceledLoads++;
        this.filterArray[6].array.push(element);
      }

      if(element.alert !== '') {
        sumAlerts++;
        this.filterArray[7].array.push(element);
      }

    });

    this.filterArray[0].value = numOfAllLoads;
    this.filterArray[1].value = sumCompleted;
    this.filterArray[2].value = sumLoadBooked;
    this.filterArray[3].value = sumWaitingForBol,
    this.filterArray[4].value = sumWaitingForPayment,
    this.filterArray[5].value = sumCriticalLoads;
    this.filterArray[6].value = sumCanceledLoads;
    this.filterArray[7].value = sumAlerts;

    this.sortedData = this.filterArray[0].array;
  };

  selectArray(i: number) {
    this.selectedFilter = i;
    this.sortedData = this.filterArray[i].array;
  };

  //Open documentation dialog
  openFilesForDownload(loadNo: any, loadId: number) {
    this.sharedService.getAllFilesLoads(loadNo).subscribe((response) => {
      console.log(response);
      this.dialog.open(LoadsDocumentationDialogComponent, {
        width: '500px',
        height: '500px',
        panelClass: 'download-files-dialog',
        autoFocus: false,
        data: {fileNames: response, loadNo: loadNo, loadId: loadId}
      });
    });
  };

  //Open in new tab
  openInNewTab(route: string) {
    const url = this.router.serializeUrl(this.router.createUrlTree([route]));
    window.open(url, '_blank');
  }

  //Filter date
  selectedChange(m: any, puDate: boolean) {
    if(puDate) {
      if (!this.puRangeValue?.start || this.puRangeValue?.end) {
        this.puRangeValue = new DateRange<Date>(m, null);
      } 
      else {
        const start = this.puRangeValue.start;
        const end = m;
        if (end < start) {
            this.puRangeValue = new DateRange<Date>(end, start);
        } else {
            this.puRangeValue = new DateRange<Date>(start, end);
        }
        this.puObj = this.puRangeValue;
      } 
      this.puRangeValueChange.emit(this.puRangeValue);
    }
    else {
      if (!this.delRangeValue?.start || this.delRangeValue?.end) {
        this.delRangeValue = new DateRange<Date>(m, null);
      } 
      else {
        const start = this.delRangeValue.start;
        const end = m;
        if (end < start) {
            this.delRangeValue = new DateRange<Date>(end, start);
        } else {
            this.delRangeValue = new DateRange<Date>(start, end);
        }
        this.delObj = this.delRangeValue;
      } 
      this.delRangeValueChange.emit(this.delRangeValue);
    }
  }

  //Change load status
  changeLoadStatus(status: string, element: any) {
    element.isOpenActivityMenu = false;
    if(this.permissions[0].allowedAll) {
      this.sharedService.changeLoadStatus(element.id, status)
      .subscribe((response: any) => {
        console.log(response);
        if(response) {
          element.status = status;
          this.dialog.open(SuccessDialogComponent, {
            autoFocus: false,
            panelClass: 'success-dialog-container'
          });  
        }
        else {
          this.dialog.open(WarningMsgDialogComponent, {
            autoFocus: false,
            panelClass: 'warning-msg-dialog-container'
          });
        }
      });
    }
    else {
      this.dialog.open(MsgForbbidenAccessComponent, {
        autoFocus: false,
        panelClass: 'forbidden-msg-dialog-container'
      })
    }
  };

  //Sort data
  sortData(sort: Sort) {
    const data = JSON.parse(JSON.stringify(this.sortedData));
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }
    this.sortedData = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'load_no':
          return compare(a.load_no, b.load_no, isAsc);
        case 'distance':
          return compare(a.distance, b.distance, isAsc);
        case 'broker_load_no':
          return compare(a.broker_load_no, b.broker_load_no, isAsc);
        case 'status':
          return compare(a.status, b.status, isAsc);
        case 'pick_up':
          return compare(a.pick_up, b.pick_up, isAsc);
        case 'delivery':
          return compare(a.delivery, b.delivery, isAsc);  
        case 'unit_no':
          return compare(a.unit_no, b.unit_no, isAsc);
        case 'driver':
          return compare(a.driver, b.driver, isAsc);
        case 'dispatcher':
          return compare(a.dispatcher, b.dispatcher, isAsc);
        case 'broker':
          return compare(a.broker, b.broker, isAsc);
        case 'driver_status':
          return compare(a.driver_status, b.driver_status, isAsc);
        case 'pickup_date':
          return compare(new Date(a.pickup_date), new Date(b.pickup_date), isAsc);
        case 'delivery_date':
          return compare(new Date(a.delivery_date), new Date(b.delivery_date), isAsc);
        case 'pickup_city':
          return compare(a.pickup_city, b.pickup_city, isAsc);
        case 'pickup_state':
          return compare(a.pickup_state, b.pickup_state, isAsc);
        case 'delivery_city':
          return compare(a.delivery_city, b.delivery_city, isAsc);
        case 'delivery_state':
          return compare(a.delivery_state, b.delivery_state, isAsc);
        case 'freight_amount':
          return compare(a.freight_amount, b.freight_amount, isAsc);
        case 'pickup_amount':
          return compare(a.pickup_amount, b.pickup_amount, isAsc);
        case 'rate':
          return compare(a.rate, b.rate, isAsc);
        case 'margin_percent':
          return compare(a.margin_percent, b.margin_percent, isAsc);
        case 'billable_miles':
          return compare(a.billable_miles, b.billable_miles, isAsc);
        case 'shortest_miles':
          return compare(a.shortest_miles, b.shortest_miles, isAsc);
        case 'benefint_mileage_percent':
          return compare(a.benefint_mileage_percent, b.benefint_mileage_percent, isAsc);
        case 'weight':
          return compare(a.weight, b.weight, isAsc);
        default:
          return 0;
      }
    });
  }

  clearFilters() {
    this.loadSearch = '';
    this.brokerLoadNumSearch = '';
    this.truckSearch = '';
    this.driverSearch = '';
    this.dispatcherSearch = '';
    this.brokerSearch = '';
    this.typeSearch = '';
    this.puCitySearch = '';
    this.puStateSearch = '';
    this.delCitySearch = '';
    this.delStateSearch = '';
    this.statusSearch = '';
    this.amountValue = '';
    this.amountSign = 'More than';
    this.puAmountValue = '';
    this.puAmountSign = 'More than'; 
    this.rateValue = '';
    this.rateSign = 'More than';
    this.marginPercentValue = '';
    this.marginPercentSign = 'More than';
    this.billableValue = '';
    this.billableSign = 'More than';
    this.paidValue = '';
    this.paidSign = 'More than';
    this.companyBenefitValue = '';
    this.companyBenefitSign = 'More than';
    this.weightValue = '';
    this.weightSign = 'More than';
    this.puRangeValue = undefined;
    this.puObj = undefined;
    this.radioPuLoadValue = 'Both';
    this.delRangeValue = undefined;
    this.delObj = undefined;
    this.radioDelLoadValue = 'Both';
  }

  //Map dialog
  openMapDialog(obj: any) {
    this.dialog.open(LoadMapDialogComponent, {
      panelClass: 'map-dialog',
      autoFocus: false,
      data: obj
    });
  };

  getDistance(obj: any) {
    let distance = this.transformService.addCommasDots(obj.distance * 0.000621371192) + ' mi' ;
    if(obj.pick_up && obj.delivery) {
      obj.distance = 'Delivered'
    }
    if(obj.tonu && (!obj.pick_up || !obj.delivery)) {
      obj.distance = 'TONU'
    }
    if(!obj.tonu && (!obj.pick_up || !obj.delivery)) {
      obj.distance = `${distance} from ${!obj.pick_up && !obj.delivery ? 'PU' : 'DEL'}`;
    }
    obj.duration = this.secondsToDhms(obj.duration);
  }

  secondsToDhms(seconds: number) {
    seconds = Number(seconds)
    let d = Math.floor(seconds / (3600 * 24))
    let h = Math.floor((seconds % (3600 * 24)) / 3600)
    let m = Math.floor((seconds % 3600) / 60)
    let s = Math.floor(seconds % 60)
    let eta: string = '';
    if(d > 0) {
      eta = `${d}d ${h}h ${m}m ${s}s`;
    }
    else if(h > 0) {
      eta = `${h}h ${m}m ${s}s`;
    }
    else if(m > 0) {
      eta = `${m}m ${s}s`;
    }
    else {
      eta = `${s}s`;
    };

    return eta;
  };

  //Export to excel
  exportToExcel(columnsConfig: any[]) {
    if(this.rulesService.UserData[56].data[0].sectionArray[46].allowed) {
      const excelTable: any[] = [];
      for(let i = 0; i < this.filterMetaData.countObj.array.length; i++) {
        let obj: any = {};
        this.transformService.selectedColumn(obj, columnsConfig[0].columnName, i + 1 , columnsConfig[0].selected);
        this.transformService.selectedColumn(obj, columnsConfig[1].columnName, this.filterMetaData.countObj.array[i].distance, columnsConfig[1].selected);
        this.transformService.selectedColumn(obj, columnsConfig[2].columnName, this.filterMetaData.countObj.array[i].load_no, columnsConfig[2].selected);
        this.transformService.selectedColumn(obj, columnsConfig[3].columnName, this.filterMetaData.countObj.array[i].broker_load_no, columnsConfig[3].selected);
        this.transformService.selectedColumn(obj, columnsConfig[4].columnName, this.filterMetaData.countObj.array[i].status, columnsConfig[4].selected);
        this.transformService.selectedColumn(obj, columnsConfig[5].columnName, this.filterMetaData.countObj.array[i].pick_up, columnsConfig[5].selected);
        this.transformService.selectedColumn(obj, columnsConfig[6].columnName, this.filterMetaData.countObj.array[i].delivery, columnsConfig[6].selected);
        this.transformService.selectedColumn(obj, columnsConfig[7].columnName, this.filterMetaData.countObj.array[i].unit_no, columnsConfig[7].selected);
        this.transformService.selectedColumn(obj, columnsConfig[8].columnName, this.filterMetaData.countObj.array[i].driver, columnsConfig[8].selected);
        this.transformService.selectedColumn(obj, columnsConfig[9].columnName, this.filterMetaData.countObj.array[i].dispatcher, columnsConfig[9].selected);
        this.transformService.selectedColumn(obj, columnsConfig[10].columnName, this.filterMetaData.countObj.array[i].broker, columnsConfig[10].selected);
        this.transformService.selectedColumn(obj, columnsConfig[11].columnName, this.filterMetaData.countObj.array[i].driver_status, columnsConfig[11].selected);
        this.transformService.selectedColumn(obj, columnsConfig[12].columnName, this.filterMetaData.countObj.array[i].pickup_date, columnsConfig[12].selected);
        this.transformService.selectedColumn(obj, columnsConfig[13].columnName, this.filterMetaData.countObj.array[i].delivery_date, columnsConfig[13].selected);
        this.transformService.selectedColumn(obj, columnsConfig[14].columnName, this.filterMetaData.countObj.array[i].pickup_city, columnsConfig[14].selected);
        this.transformService.selectedColumn(obj, columnsConfig[15].columnName, this.filterMetaData.countObj.array[i].pickup_state, columnsConfig[15].selected);
        this.transformService.selectedColumn(obj, columnsConfig[16].columnName, this.filterMetaData.countObj.array[i].delivery_city, columnsConfig[16].selected);
        this.transformService.selectedColumn(obj, columnsConfig[17].columnName, this.filterMetaData.countObj.array[i].delivery_state, columnsConfig[17].selected);
        this.transformService.selectedColumn(obj, columnsConfig[18].columnName, `$${this.transformService.addCommasDots(this.filterMetaData.countObj.array[i].freight_amount, 'round')}`, columnsConfig[18].selected);
        this.transformService.selectedColumn(obj, columnsConfig[19].columnName, `$${this.transformService.addCommasDots(this.filterMetaData.countObj.array[i].pickup_amount, 'round')}`, columnsConfig[19].selected);
        this.transformService.selectedColumn(obj, columnsConfig[20].columnName, `$${this.transformService.addCommasDots(this.filterMetaData.countObj.array[i].rate)}`, columnsConfig[20].selected);
        this.transformService.selectedColumn(obj, columnsConfig[21].columnName, `${this.transformService.addCommasDots(this.filterMetaData.countObj.array[i].margin_percent)}%`, columnsConfig[21].selected);
        this.transformService.selectedColumn(obj, columnsConfig[22].columnName, this.transformService.addCommasDots(this.filterMetaData.countObj.array[i].billable_miles), columnsConfig[22].selected);
        this.transformService.selectedColumn(obj, columnsConfig[23].columnName, this.transformService.addCommasDots(this.filterMetaData.countObj.array[i].shortest_miles, 'round'), columnsConfig[23].selected);
        this.transformService.selectedColumn(obj, columnsConfig[24].columnName, this.transformService.addCommasDots(this.filterMetaData.countObj.array[i].benefint_mileage_percent, 'round'), columnsConfig[24].selected);
        this.transformService.selectedColumn(obj, columnsConfig[25].columnName, this.transformService.addCommasDots(this.filterMetaData.countObj.array[i].weight, 'round'), columnsConfig[25].selected);
        excelTable.push(obj);
      };
      let footerObj: any = {};
      this.transformService.selectedColumn(footerObj, columnsConfig[0].columnName, this.filterMetaData.countObj.numOfAllLoads , columnsConfig[0].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[1].columnName, '', columnsConfig[1].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[2].columnName, '', columnsConfig[2].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[3].columnName, '', columnsConfig[3].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[4].columnName, '', columnsConfig[4].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[5].columnName, '', columnsConfig[5].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[6].columnName, '', columnsConfig[6].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[7].columnName, '', columnsConfig[7].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[8].columnName, '', columnsConfig[8].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[9].columnName, '', columnsConfig[9].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[10].columnName, '', columnsConfig[10].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[11].columnName, '', columnsConfig[11].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[12].columnName, '', columnsConfig[12].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[13].columnName, '', columnsConfig[13].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[14].columnName, '', columnsConfig[14].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[15].columnName, '', columnsConfig[15].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[16].columnName, '', columnsConfig[16].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[17].columnName, '', columnsConfig[17].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[18].columnName, `$${this.transformService.addCommasDots(this.filterMetaData.countObj.amount, 'round')}`, columnsConfig[18].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[19].columnName, `$${this.transformService.addCommasDots(this.filterMetaData.countObj.pickUpStopAmount, 'round')}`, columnsConfig[19].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[20].columnName, `$${this.transformService.addCommasDots(this.filterMetaData.countObj.rate)}`, columnsConfig[20].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[21].columnName, `${this.transformService.addCommasDots(this.filterMetaData.countObj.marginPercent)}%`, columnsConfig[21].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[22].columnName, this.transformService.addCommasDots(this.filterMetaData.countObj.billableMiles, 'round'), columnsConfig[22].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[23].columnName, this.transformService.addCommasDots(this.filterMetaData.countObj.paidMiles, 'round'), columnsConfig[23].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[24].columnName, `${this.transformService.addCommasDots(this.filterMetaData.countObj.companyBenefitMilesPercent, 'round')}%`, columnsConfig[24].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[25].columnName, this.transformService.addCommasDots(this.filterMetaData.countObj.weight, 'round'), columnsConfig[25].selected);
      excelTable.push(footerObj);
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelTable);
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, 'loads-table.xlsx');
    }
    else {
      this.msgForbbidenAccess();
    }
  };

  msgForbbidenAccess() {
    this.dialog.open(MsgForbbidenAccessComponent, {
      autoFocus: false,
      panelClass: 'forbidden-msg-dialog-container'
    })
  };

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

}

function compare(a: number | string | any, b: number | string | any, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}


import { Component, Input, OnChanges } from '@angular/core';
import * as XLSX from 'xlsx';
import moment = require('moment');
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Sort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { MapLoadDialogComponent } from './map-load-dialog/map-load-dialog.component';
import { PickupDeliveryConfirmationDialogComponent } from './pickup-delivery-confirmation-dialog/pickup-delivery-confirmation-dialog.component';
import { MAT_CHECKBOX_DEFAULT_OPTIONS, MatCheckboxDefaultOptions } from '@angular/material/checkbox';
import { LoadsDocumentationDialogComponent } from '@app/modules/shared/components/loads-documentation-dialog/loads-documentation-dialog.component';
import { TransformService } from '@app/modules/shared/services/transform.service';
import { DispatchersLiveBoardService } from '../../services/dispatchers-live-board.service';
import { SharedService } from '@app/modules/shared/services/shared.service';

//Map
declare const google: any;
declare global {
  interface Window {
    initMap: () => void;
  }
}

@Component({
  selector: 'app-dispatch-loads-table',
  templateUrl: './dispatch-loads-table.component.html',
  styleUrls: ['./dispatch-loads-table.component.scss'],
  providers: [
    {provide: MAT_CHECKBOX_DEFAULT_OPTIONS, useValue: { clickAction: 'noop' } as MatCheckboxDefaultOptions}
  ]
})

export class DispatchLoadsTableComponent implements OnChanges {
  @Input() dataSource: any;

  sortedData: any = new MatTableDataSource();

  fileName = 'loads-table.xlsx';

  displayedColumns: string[] = ['position', 'documentation', 'load_no', 'map', 'distance', 'pick_up', 'delivery',
  'broker', 'broker_load_nunmber', 'dispatcher', 'driver', 'unit_no', 'type', 'pu_date', 
  'del_date', 'from_state', 'to_state', 'rate_per_mile', 'mileage', 'paid_miles', 'loaded_miles', 'empty_miles',
  'company_benefit_miles_percent', 'freight_amount', 'stop_amont', 'margint_percent', 'weight', 'status'];

  isOpen1 = false;
  isOpen2 = false;
  calendarDate1: string = '';
  calendarDate2: string = '';

  //Statistics cards
  stCardsObj: any = {
    numOfLoads: 0,
    allGoodLoads: 0,
    loadsWithAlert: 0
  }

  //Loads table total, avg obj
  calculationObj: any = {
    totalLoads: 0,
    avgRpm: 0,
    totalMileage: 0,
    totalPaidMiles: 0,
    totalLoadedMiles: 0,
    totalEmptyMiles: 0,
    avgCompanyBenefitMilesPercent: 0,
    totalFreightAmount: 0,
    totalPuStopAmount: 0,
    avgMarginPercent: 0,
    avgWeight: 0
  }

  constructor(public transformService: TransformService,
              private dialog: MatDialog,
              private dispatchersLiveBoardService: DispatchersLiveBoardService,
              private sharedService: SharedService,
              private router: Router) { }

  ngOnChanges() {
    if(this.dataSource) {
      this.transformData(this.dataSource)
      this.countAvg(this.dataSource);
    }
  }

  transformData(array: any[]) {
    for(let i = 0; i < array.length; i++) {
      this.getDistance(array[i]);
    };
    this.stCardsObj.numOfLoads = array.length;
    this.sortedData.data = array;
  };

  //Get distance
  getDistance(obj: any) {
    let distance = this.transformService.addCommasDots(obj.distance * 0.000621371192) + ' mi' ;
    if(obj.pick_up && obj.delivery) {
      obj.distance = 'Delivered'
    }
    if(obj.tonu && (!obj.pick_up || !obj.delivery)) {
      obj.distance = 'TONU'
    }
    if(!obj.tonu && (!obj.pick_up || !obj.delivery)) {
      obj.distance = `${distance} from ${!obj.pick_up && !obj.delivery ? 'PU' : 'DEL'}`;
    }
    obj.duration = this.secondsToDhms(obj.duration);
  }

  secondsToDhms(seconds: number) {
    seconds = Number(seconds)
    let d = Math.floor(seconds / (3600 * 24))
    let h = Math.floor((seconds % (3600 * 24)) / 3600)
    let m = Math.floor((seconds % 3600) / 60)
    let s = Math.floor(seconds % 60)
    let eta: string = '';
    if(d > 0) {
      eta = `${d}d ${h}h ${m}m ${s}s`;
    }
    else if(h > 0) {
      eta = `${h}h ${m}m ${s}s`;
    }
    else if(m > 0) {
      eta = `${m}m ${s}s`;
    }
    else {
      eta = `${s}s`;
    };

    return eta;
  }

  dateClicked(event: any, calendarFirst: boolean) {
    if(calendarFirst) {
      this.calendarDate1 = moment(event._d).format('MMM DD, yyy');
      this.isOpen1 = false;
    } else {
      this.calendarDate2 = moment(event._d).format('MMM DD, yyy');
      this.isOpen2 = false;
    }
  }

  countAvg(data: any) {
    let totalLoads: number = data.length;
    let mileage: number = 0;
    let paidMiles: number = 0;
    let loadedMiles: number = 0;
    let emptyMiles: number = 0;
    let freightAmount: number = 0;
    let puStopAmount: number = 0;
    let weight: number = 0;

    let marginPercentConditions: string[] = ['SOLO COMPANY', 'TEAM COMPANY'];
    let sumFreightAmountOnlyRentAndOwner: number = 0;

    for(let key in data) {
      mileage += data[key].mileage;
      paidMiles += data[key].paid_miles;
      loadedMiles += data[key].loaded_miles;
      emptyMiles += data[key].empty_miles;
      freightAmount += data[key].freight_amount;
      puStopAmount += data[key].stop_amont;
      weight += data[key].weight;

      if(!marginPercentConditions.includes(data[key].type)) {
        sumFreightAmountOnlyRentAndOwner += data[key].freight_amount;
      }
    };

    this.calculationObj.totalLoads = totalLoads;
    this.calculationObj.avgRpm = freightAmount / mileage;
    this.calculationObj.totalMileage = mileage;
    this.calculationObj.totalPaidMiles = paidMiles;
    this.calculationObj.totalLoadedMiles = loadedMiles;
    this.calculationObj.totalEmptyMiles = emptyMiles;
    this.calculationObj.avgCompanyBenefitMilesPercent = (mileage - paidMiles) / mileage * 100;
    this.calculationObj.totalFreightAmount = freightAmount;
    this.calculationObj.totalPuStopAmount = puStopAmount;
    this.calculationObj.avgMarginPercent = ((freightAmount - puStopAmount) / (sumFreightAmountOnlyRentAndOwner) * 100);
    this.calculationObj.avgWeight = weight / totalLoads;
  };

  openFilesForDownload(loadNo: any) {
    this.sharedService.getAllFilesLoads(loadNo).subscribe((response) => {
      console.log(response);
      this.dialog.open(LoadsDocumentationDialogComponent, {
        width: '500px',
        height: '500px',
        panelClass: 'download-files-dialog',
        autoFocus: false,
        data: {fileNames: response, loadNo: loadNo}
      });
    });
  };

  exportToExcel() {
    let element = document.getElementById('loads-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    XLSX.writeFile(wb, this.fileName);
  };

  //Map dialog
  openMapDialog(obj: any) {
    this.dialog.open(MapLoadDialogComponent, {
      panelClass: 'map-dialog',
      autoFocus: false,
      data: obj
    });
  };

  //Search
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.sortedData.filter = filterValue.trim().toLowerCase();
  };

  //Open in new tab
  openInNewTab(route: string) {
    const url = this.router.serializeUrl(this.router.createUrlTree([route]));
    window.open(url, '_blank');
  };

  //Change load status
  changeLoadStatus(element: any, isPickUp: boolean) {
    let dialogRef: any = this.dialog.open(PickupDeliveryConfirmationDialogComponent, {
      autoFocus: false,
      panelClass: 'pickup-delivery-dialog-container',
      data: {obj: element, isPickUp: isPickUp}
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
        let loggedUser: any = JSON.parse(localStorage.getItem('currentUser'));
        let id: number | undefined;
        let fullName: any = loggedUser.first_name + ' ' + loggedUser.last_name;
        let date: string = this.transformService.convertDateToTimestamp(moment(new Date()).format('ddd, DD/MM YYYY'),  moment(new Date()).format('HH:mm:ss'));
        let value: boolean | undefined;
        let key: string = '';

        if(isPickUp) {
          id = element.pickup_id;
          value = !element.pick_up;
          key = 'pick_up';
        }
        else {
          id = element.delivery_id;
          value = !element.delivery;
          key = 'delivery';
        }
        this.dispatchersLiveBoardService.changeLoadStatus(id, fullName, date, value)
        .subscribe((response: any) => {
          if(response) {
            element[key] = !element[key];
          };

        });
      }
    });
  };

  //Sort data
  sortData(sort: Sort) {
    const data = JSON.parse(JSON.stringify(this.sortedData.data));
    if (!sort.active || sort.direction === '') {
      this.sortedData.data = data;
      return;
    }
    this.sortedData.data = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'load_no':
          return compare(a.load_no, b.load_no, isAsc);
        case 'map':
          return compare(a.map, b.map, isAsc);
        case 'distance':
          return compare(a.distance, b.distance, isAsc);
        case 'pick_up':
          return compare(a.pick_up, b.pick_up, isAsc);  
        case 'delivery':
          return compare(a.delivery, b.delivery, isAsc);
        case 'broker':
          return compare(a.broker, b.broker, isAsc);
        case 'broker_load_nunmber':
          return compare(a.broker_load_nunmber, b.broker_load_nunmber, isAsc);
        case 'driver':
          return compare(a.driver, b.driver, isAsc);
        case 'unit_no':
          return compare(a.unit_no, b.unit_no, isAsc);
        case 'type':
          return compare(a.type, b.type, isAsc);
        case 'pu_date':
          return compare(new Date(a.pu_date), new Date(b.pu_date), isAsc);
        case 'del_date':
          return compare(new Date(a.del_date), new Date(b.del_date), isAsc);
        case 'from_state':
          return compare(a.from_state, b.from_state, isAsc);
        case 'to_state':
          return compare(a.to_state, b.to_state, isAsc);
        case 'rate_per_mile':
          return compare(a.rate_per_mile, b.rate_per_mile, isAsc);
        case 'mileage':
          return compare(a.mileage, b.mileage, isAsc);
        case 'paid_miles':
          return compare(a.paid_miles, b.paid_miles, isAsc);
        case 'loaded_miles':
          return compare(a.loaded_miles, b.loaded_miles, isAsc);
        case 'empty_miles':
          return compare(a.empty_miles, b.empty_miles, isAsc);
        case 'company_benefit_miles_percent':
          return compare(a.company_benefit_miles_percent, b.company_benefit_miles_percent, isAsc);
        case 'freight_amount':
          return compare(a.freight_amount, b.freight_amount, isAsc);
        case 'margint_percent':
          return compare(a.margint_percent, b.margint_percent, isAsc);
        case 'weight':
          return compare(a.weight, b.weight, isAsc);
        case 'status':
          return compare(a.status, b.status, isAsc);
        default:
          return 0;
      }
    });
  }

}

function compare(a: number | string | any, b: number | string | any, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SafetyService } from '@app/modules/safety/services/safety.service';
import { WarningMsgDialogComponent } from '@app/modules/shared/components/warning-msg-dialog/warning-msg-dialog.component';
import { Subscription, catchError, throwError } from 'rxjs';

@Component({
  selector: 'app-policy-dialog',
  templateUrl: './policy-dialog.component.html',
  styleUrls: ['./policy-dialog.component.scss']
})
export class PolicyDialogComponent implements OnInit, OnDestroy {
  //Divisions
  isOpenDivisionMenu: boolean = false;
  divisionArray: any[] = [];

  divisionsValuesArray: string[] = [];
  divisionIdsArray: number[] = [];

  //Driver type
  isOpenDriverTypeMenu: boolean = false;
  typesArray: any[] = [
    {name: 'Company', selected: false, filter: 'SOLO COMPANY, TEAM COMPANY'},
    {name: 'Rent to run', selected: false, filter: 'SOLO RENT, TEAM RENT'},
    {name: 'Owner', selected: false, filter: 'OWNER, OWNER OPERATOR'}
  ];
  typesValuesArray: string[] = [];
  typesFiltersArray: string[] = [];

  //Drivers
  isOpenDriverMenu: boolean = false;
  driversArray: any[] = [];

  driversValuesArray: string[] = [];
  trucksDataArray: string[] = [];
  searchDriver: string = '';

  //Note
  policyText: string = '';

  subscription: Subscription | any = new Subscription();

  constructor(private safetyService: SafetyService, 
              private dialog: MatDialog,
              public dialogRef: MatDialogRef<any>) { }

  ngOnInit(): void {
    this.subscription = this.safetyService.getAllDrivers()
    .subscribe((responseObj: any) => {
      for(let i = 0; i < responseObj.response1.length; i++) {
        responseObj.response1[i].fullName = `${responseObj.response1[i].first_name} ${responseObj.response1[i].last_name}`;
      };
      this.driversArray = responseObj.response1;
      this.divisionArray = responseObj.response2.divisions;
    });
  };

  //Select divison
  selectDivision(obj: any) {
    obj.selected = !obj.selected;
    let index = this.divisionsValuesArray.indexOf(obj.name);
    if(index === -1) {
      this.divisionsValuesArray.push(obj.name);
      this.divisionIdsArray.push(obj.id);
    }
    else {
      this.divisionsValuesArray.splice(index, 1);
      this.divisionIdsArray.splice(index, 1);
    }
    this.resetSelectedDrivers();
  };

  //Select type
  selectType(obj: any) {
    obj.selected = !obj.selected;
    let index = this.typesValuesArray.indexOf(obj.name);
    if(index === -1) {
      this.typesValuesArray.push(obj.name);
      this.typesFiltersArray.push(obj.filter);
    }
    else {
      this.typesValuesArray.splice(index, 1);
      this.typesFiltersArray.splice(index, 1);
    }
    this.resetSelectedDrivers();
  };

  //Select driver
  selectDriver(obj: any) {
    obj.selected = !obj.selected;
    let index = this.driversValuesArray.indexOf(obj.fullName);
    if(index === -1) {
      this.driversValuesArray.push(obj.fullName);
      this.trucksDataArray.push(`${obj.truck_no}`);
    }
    else {
      this.driversValuesArray.splice(index, 1);
      this.trucksDataArray.splice(index, 1);
    }
  };

  sendPolicy() {
    const truckWrappedInQuotes = this.trucksDataArray.map((unitNo: string) => `'${unitNo}'`);
    const withCommasInBetween = truckWrappedInQuotes.join(',')
    this.safetyService.sendPolicy(withCommasInBetween, {policyBody: this.policyText})
    .pipe(catchError((err: any) => {
      this.warningDialog();
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
        if(response === 'Ok') {
          this.dialogRef.close(response);
        }
        else {
          this.warningDialog();
        }
    });
  };

  warningDialog() {
    this.dialog.open(WarningMsgDialogComponent, {
      autoFocus: false,
      panelClass: 'warning-msg-dialog-container'
    })
  };

  resetSelectedDrivers() {
    for(let i = 0; i < this.driversArray.length; i++) {
      this.driversArray[i].selected = false;
    }
    this.driversValuesArray = [];
    this.trucksDataArray = [];
    this.searchDriver = '';
  };

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  };

}

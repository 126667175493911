import { Component } from '@angular/core';

@Component({
  selector: 'app-fleet-health-page',
  templateUrl: './fleet-health-page.component.html',
  styleUrls: ['./fleet-health-page.component.scss']
})
export class FleetHealthPageComponent {
  activeSection: string = 'overview';

  //Search menu
  isOpenSearch: boolean = false;
  searchTruck: string = '';

  showOnlySchedule: boolean | undefined = history.state?.hidden;

  showSection(className: string) {
    this.activeSection = className;
  };

}

import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ApexAxisChartSeries, ApexPlotOptions, ApexChart, ApexLegend, ApexResponsive, ChartComponent } from 'ng-apexcharts';

export type ChartOptions = {
  series: ApexAxisChartSeries | any;
  colors: string | any;
  plotOptions: ApexPlotOptions;
  chart: ApexChart | any;
  labels: string | any;
  legend: ApexLegend | any;
  responsive: ApexResponsive | any;
};

@Component({
  selector: 'truck-activation-pie-chart',
  templateUrl: './truck-activation-pie-chart.component.html',
  styleUrls: ['./truck-activation-pie-chart.component.scss']
})
export class TruckActivationPieChartComponent implements OnInit {
  @ViewChild("chart") chart: ChartComponent | any;
  public chartOptions: Partial<ChartOptions> | any;
  @Input() data: any;

  constructor() { }

  ngOnInit(): void {
    this.chartOptions = this.pieChart(this.data);
  }

  pieChart(data: any) {
    return {
      colors: ['#ff1515', '#fffc15', '#00be62', '#1c6f46', '#0030FF', '#FF00FF', '#800000'],
      series: [data.total_trucks, data._1st_quarter, data._2st_quarter, data._3st_quarter, data._4st_quarter],
      plotOptions: {
        pie: {
          dataLabels: {
            offset: -33,
          }, 
        }
      },
      chart: {
        width: 400,
        type: "pie",
        fontFamily: 'Poppins'
      },
      labels: ['TOTAL TRUCKS', '1ST QUARTER', '2ND QUARTER', '3RD QUARTER', '4TH QUARTER'],
      legend: {
        position: 'bottom'
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: "bottom"
            }
          }
        }
      ]
    };
    
  };

}
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription, catchError, throwError } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { InspectionDetailsComponent } from './inspection-details/inspection-details.component';
import { RulesService } from '@app/modules/shared/services/rules.service';
import { TransformService } from '@app/modules/shared/services/transform.service';
import { Inspection, Outcome, Level, InspectionOverviewItem, InspectionOverview } from '../models/inspection.model';
import { InspectionsService } from '../services/inspections.service';
import moment = require('moment');
import * as XLSX from 'xlsx';
import { MsgForbbidenAccessComponent } from '@app/modules/shared/components/msg-forbbiden-access/msg-forbbiden-access.component';
import { TitleCasePipe } from '@angular/common';
import { InspectionListComponent } from './inspection-list/inspection-list.component';

@Component({
  selector: 'app-inspections',
  templateUrl: './inspections.component.html',
  styleUrls: ['./inspections.component.scss']
})
export class InspectionsComponent implements OnInit, OnDestroy {
  @ViewChild(InspectionListComponent) childComponent: any;
  dateObj: any = {
    startDate: moment().startOf('month').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD')
  };

  private subscription?: Subscription;
  private subscription2?: Subscription;

  inspectionOverview = this.initializeInspectionOverview();
  originalData?: Inspection[];
  filteredData?: Inspection[];

  selectedOutcome: Outcome | null = null;
  selectedLevel: Level | null = null;

  //Interval
  interval: any;

  //Excel config
  xlsxConfig: any[] = [
    {columnName: 'No.', selected: true},
    {columnName: 'Driver', selected: true},
    {columnName: 'Dispatcher', selected: true},
    {columnName: 'Truck', selected: true},
    {columnName: 'Outcome', selected: true},
    {columnName: 'Level', selected: true},
    {columnName: 'Charge', selected: true},
    {columnName: 'Safety reward', selected: true},
    {columnName: 'Points', selected: true},
    {columnName: 'Date', selected: true},
    {columnName: 'Out of Service', selected: true},
    {columnName: 'State', selected: true},
    {columnName: 'Original', selected: true},
    {columnName: 'Sent', selected: true},
    {columnName: 'City', selected: true},
    {columnName: 'Related', selected: true}
  ];
  excelData: any[] = [];

  loaded: boolean = false;
  error: boolean = false;
  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";
  spinnerBgColor: string = this.transformService.spinnerBgColor;
  spinnerType: string = this.transformService.spinnerType;

  constructor(public service: InspectionsService,
              private dialog: MatDialog,
              private transformService: TransformService,
              private spinner: NgxSpinnerService,
              private rulesService: RulesService,
              private titleCase: TitleCasePipe) { }

  ngOnInit() {
    this.spinner.show('inspections');
    this.getInspectionData();
    this.subscription2 = this.service.refresh$.subscribe((response: boolean) => {
      if(response) {
        this.loaded = false;
        this.error = false;
        this.service.data$.next([]);
        this.spinner.show('inspections');
        this.getInspectionData();
      }
    });
    if(this.rulesService.liveUpdate) {
      this.interval = setInterval(() => {
        this.getInspectionData();
      }, this.rulesService.miliseconds);
    }
  }

  get Outcome() {
    return Outcome;
  }

  get Level() {
    return Level;
  }

  getInspectionData() {
    this.subscription = this.service.getInspectionData(this.dateObj.startDate, this.dateObj.endDate)
    .pipe(catchError((err: any) => {
      this.spinner.hide('inspections');
      this.loaded = true;
      this.error = true;
      return throwError(() => err);
    }))
    .subscribe((response: Inspection[]) => {
      console.log(response);
      const sortedData = this.sortDataByDate(response);
      this.setFilteredAndOriginalData(sortedData);
      this.resetSelectedOutcomeAndLevel();
      this.generateInspectionOverview();
      this.service.data$.next(response);
      this.excelData = sortedData;
      this.spinner.hide('inspections');
      this.loaded = true;
    });
  }

  private sortDataByDate(data: any[]): any[] {
    return data.sort((a: any, b: any) => {
      return <any>new Date(b.date) - <any>new Date(a.date);
    })
  }

  private setFilteredAndOriginalData(sortedData: any[]): void {
    this.filteredData = this.originalData = sortedData;
  }

  private resetSelectedOutcomeAndLevel(): void {
    this.selectedOutcome = null;
    this.selectedLevel = null;
  }

  openDialog(data?: any, editMode?: boolean) {
    this.dialog.open(InspectionDetailsComponent, {
      autoFocus: false,
      panelClass: 'inspections',
      data: { inspectionData: data, editMode: editMode },
    });
  }

  getDate(result: any) {
    this.spinner.show('inspections');
    this.loaded = false;
    this.error = false;
    this.dateObj.startDate = result.startDate;
    this.dateObj.endDate = result.endDate;
    this.getInspectionData();
  };

  private generateInspectionOverview() {
    this.inspectionOverview = this.initializeInspectionOverview();

    if (this.originalData && this.originalData.length > 0) {
      this.originalData.forEach(item => {
        this.updateInspectionOverview(item, this.inspectionOverview.all);
        if (item.inspection_result?.toLowerCase() === Outcome.PASSED.toLowerCase()) {
          this.updateInspectionOverview(item, this.inspectionOverview.passed);
        } else if (item.inspection_result?.toLowerCase() === Outcome.FAILED.toLowerCase()) {
          this.updateInspectionOverview(item, this.inspectionOverview.failed);
        }
      });

      if (!this.inspectionOverview.all.value) return;

      this.inspectionOverview.passed.percentage = Math.round((this.inspectionOverview.passed.value / this.inspectionOverview.all.value) * 100);
      this.inspectionOverview.failed.percentage = Math.round((this.inspectionOverview.failed.value / this.inspectionOverview.all.value) * 100);
    }
  }

  private updateInspectionOverview(inspection: Inspection, inspectionOverviewItem: InspectionOverviewItem) {
    const predefinedLevels: Level[] = [
      Level.Level1,
      Level.Level2,
      Level.Level3,
    ];
    const inspectionLevel = inspection.inspection_level as Level;;

    if (predefinedLevels.includes(inspectionLevel)) {
      const existingLevel = inspectionOverviewItem.levels.find(e => e.level.toLowerCase() === inspectionLevel.toLowerCase());

      if (!existingLevel) {
        const newLevel = { level: inspectionLevel, value: 1, percentage: 0 };
        inspectionOverviewItem.levels.push(newLevel);
      } else {
        existingLevel.value++;
      }
    }

    inspectionOverviewItem.value++;

    predefinedLevels.forEach(level => {
      const existingLevel = inspectionOverviewItem.levels.find(e => e.level === level);
      if (!existingLevel) {
        const newLevel = { level, value: 0, percentage: 0 };
        inspectionOverviewItem.levels.push(newLevel);
      }
    });

    inspectionOverviewItem.levels.forEach(level => {
      level.percentage = Math.round((level.value / inspectionOverviewItem.value) * 100);
    });
  }

  filterByOutcomeAndLevel(outcome?: Outcome, level?: Level) {
    let filteredData: Inspection[];

    if (level) {
      if (outcome) {
        if (level.toLowerCase() === Level.Level1.toLowerCase()) {
          filteredData = this.originalData.filter(item => item.inspection_result.toLowerCase() === outcome.toLowerCase() && item.inspection_level.toLowerCase() === Level.Level1.toLowerCase());
        } else if (level.toLowerCase() === Level.Level2.toLowerCase()) {
          filteredData = this.originalData.filter(item => item.inspection_result.toLowerCase() === outcome.toLowerCase() && item.inspection_level.toLowerCase() === Level.Level2.toLowerCase());
        } else if (level.toLowerCase() === Level.Level3.toLowerCase()) {
          filteredData = this.originalData.filter(item => item.inspection_result.toLowerCase() === outcome.toLowerCase() && item.inspection_level.toLowerCase() === Level.Level3.toLowerCase());
        } else {
          filteredData = this.originalData.filter(item => item.inspection_result.toLowerCase() === outcome.toLowerCase());
        }
      } else {
        filteredData = this.originalData.filter(item => item.inspection_level.toLowerCase() === level.toLowerCase());
      }
    } else if (outcome) {
      filteredData = this.originalData.filter(item => item.inspection_result.toLowerCase() === outcome.toLowerCase());
    } else {
      filteredData = this.originalData;
    }

    this.selectedOutcome = outcome;
    this.selectedLevel = level;
    this.service.data$.next(filteredData);
  }

  private initializeInspectionOverview(): InspectionOverview {
    const predefinedLevels: Level[] = [
      Level.Level1,
      Level.Level2,
      Level.Level3,
    ];

    const initializeLevel = () => ({
      value: 0,
      percentage: 0,
    });

    const inspectionOverview: InspectionOverview = {
      all: {
        value: 0,
        percentage: 0,
        levels: predefinedLevels.map(level => ({
          ...initializeLevel(),
          level,
        })),
      },
      passed: {
        value: 0,
        percentage: 0,
        levels: predefinedLevels.map(level => ({
          ...initializeLevel(),
          level,
        })),
      },
      failed: {
        value: 0,
        percentage: 0,
        levels: predefinedLevels.map(level => ({
          ...initializeLevel(),
          level,
        })),
      },
    };

    return inspectionOverview;
  }

  exportToExcel(columnsConfig: any[]) {
    if(this.rulesService.UserData[56].data[0].sectionArray[22].allowed) {
      const excelTable: any[] = [];
      for(let i = 0; i < this.excelData.length; i++) {
        let obj: any = {};
        this.transformService.selectedColumn(obj, columnsConfig[0].columnName, i + 1, columnsConfig[0].selected);
        this.transformService.selectedColumn(obj, columnsConfig[1].columnName, this.titleCase.transform(this.excelData[i].driver), columnsConfig[1].selected);
        this.transformService.selectedColumn(obj, columnsConfig[2].columnName, this.titleCase.transform(this.excelData[i].dispatcher), columnsConfig[2].selected);
        this.transformService.selectedColumn(obj, columnsConfig[3].columnName, this.excelData[i].truck_no, columnsConfig[3].selected);
        this.transformService.selectedColumn(obj, columnsConfig[4].columnName, this.excelData[i].inspection_result, columnsConfig[4].selected);
        this.transformService.selectedColumn(obj, columnsConfig[5].columnName, this.excelData[i].inspection_level, columnsConfig[5].selected);
        this.transformService.selectedColumn(obj, columnsConfig[6].columnName, `$${this.transformService.addCommasDots(this.excelData[i].amount, 'round')}`, columnsConfig[6].selected);
        this.transformService.selectedColumn(obj, columnsConfig[7].columnName, `$${this.transformService.addCommasDots(this.excelData[i].rewards, 'round')}`, columnsConfig[7].selected);
        this.transformService.selectedColumn(obj, columnsConfig[8].columnName, this.excelData[i].points, columnsConfig[8].selected);
        this.transformService.selectedColumn(obj, columnsConfig[9].columnName, this.transformService.transformDateFormat(this.excelData[i].date, 'MMM DD, YYYY'), columnsConfig[9].selected);
        this.transformService.selectedColumn(obj, columnsConfig[10].columnName, this.excelData[i].out_of_service ? "OOS" : "Driving" , columnsConfig[10].selected);
        this.transformService.selectedColumn(obj, columnsConfig[11].columnName, this.excelData[i].state, columnsConfig[11].selected);
        this.transformService.selectedColumn(obj, columnsConfig[12].columnName, this.excelData[i].original ? 'Yes' : 'No', columnsConfig[12].selected);
        this.transformService.selectedColumn(obj, columnsConfig[13].columnName, this.excelData[i].sent ? 'Yes' : 'No', columnsConfig[13].selected);
        this.transformService.selectedColumn(obj, columnsConfig[14].columnName, this.excelData[i].city, columnsConfig[14].selected);
        this.transformService.selectedColumn(obj, columnsConfig[15].columnName, `${this.excelData[i].related[0].related}...`, columnsConfig[15].selected);
        excelTable.push(obj);
      };
      let footerTotalObj: any = {};
      this.transformService.selectedColumn(footerTotalObj, columnsConfig[0].columnName, this.childComponent.inspectionSummary.all.total, columnsConfig[0].selected);
      this.transformService.selectedColumn(footerTotalObj, columnsConfig[1].columnName, '', columnsConfig[1].selected);
      this.transformService.selectedColumn(footerTotalObj, columnsConfig[2].columnName, '', columnsConfig[2].selected);
      this.transformService.selectedColumn(footerTotalObj, columnsConfig[3].columnName, '', columnsConfig[3].selected);
      this.transformService.selectedColumn(footerTotalObj, columnsConfig[4].columnName, '', columnsConfig[4].selected);
      this.transformService.selectedColumn(footerTotalObj, columnsConfig[5].columnName, '', columnsConfig[5].selected);
      this.transformService.selectedColumn(footerTotalObj, columnsConfig[6].columnName, `Sum: ${this.transformService.addCommasDots(this.childComponent.inspectionSummary.amount.total, "round")}`, columnsConfig[6].selected);
      this.transformService.selectedColumn(footerTotalObj, columnsConfig[7].columnName, `Sum: ${this.transformService.addCommasDots(this.childComponent.inspectionSummary.rewards.total, "round")}`, columnsConfig[7].selected);
      this.transformService.selectedColumn(footerTotalObj, columnsConfig[8].columnName, `Sum: ${this.transformService.addCommasDots(this.childComponent.inspectionSummary.points.total)}`, columnsConfig[8].selected);
      this.transformService.selectedColumn(footerTotalObj, columnsConfig[9].columnName, '', columnsConfig[9].selected);
      this.transformService.selectedColumn(footerTotalObj, columnsConfig[10].columnName, '' , columnsConfig[10].selected);
      this.transformService.selectedColumn(footerTotalObj, columnsConfig[11].columnName, '', columnsConfig[11].selected);
      this.transformService.selectedColumn(footerTotalObj, columnsConfig[12].columnName, '', columnsConfig[12].selected);
      this.transformService.selectedColumn(footerTotalObj, columnsConfig[13].columnName, '', columnsConfig[13].selected);
      this.transformService.selectedColumn(footerTotalObj, columnsConfig[14].columnName, '', columnsConfig[14].selected);
      this.transformService.selectedColumn(footerTotalObj, columnsConfig[15].columnName, '', columnsConfig[15].selected);
      let footerAvgObj: any = {};
      this.transformService.selectedColumn(footerAvgObj, columnsConfig[0].columnName, '', columnsConfig[0].selected);
      this.transformService.selectedColumn(footerAvgObj, columnsConfig[1].columnName, '', columnsConfig[1].selected);
      this.transformService.selectedColumn(footerAvgObj, columnsConfig[2].columnName, '', columnsConfig[2].selected);
      this.transformService.selectedColumn(footerAvgObj, columnsConfig[3].columnName, '', columnsConfig[3].selected);
      this.transformService.selectedColumn(footerAvgObj, columnsConfig[4].columnName, '', columnsConfig[4].selected);
      this.transformService.selectedColumn(footerAvgObj, columnsConfig[5].columnName, '', columnsConfig[5].selected);
      this.transformService.selectedColumn(footerAvgObj, columnsConfig[6].columnName, `Avg: ${this.transformService.addCommasDots(this.childComponent.inspectionSummary.amount.avg, "round")}`, columnsConfig[6].selected);
      this.transformService.selectedColumn(footerAvgObj, columnsConfig[7].columnName, `Avg: ${this.transformService.addCommasDots(this.childComponent.inspectionSummary.rewards.avg, "round")}`, columnsConfig[7].selected);
      this.transformService.selectedColumn(footerAvgObj, columnsConfig[8].columnName, `Avg: ${this.transformService.addCommasDots(this.childComponent.inspectionSummary.points.avg)}`, columnsConfig[8].selected);
      this.transformService.selectedColumn(footerAvgObj, columnsConfig[9].columnName, '', columnsConfig[9].selected);
      this.transformService.selectedColumn(footerAvgObj, columnsConfig[10].columnName, '' , columnsConfig[10].selected);
      this.transformService.selectedColumn(footerAvgObj, columnsConfig[11].columnName, '', columnsConfig[11].selected);
      this.transformService.selectedColumn(footerAvgObj, columnsConfig[12].columnName, '', columnsConfig[12].selected);
      this.transformService.selectedColumn(footerAvgObj, columnsConfig[13].columnName, '', columnsConfig[13].selected);
      this.transformService.selectedColumn(footerAvgObj, columnsConfig[14].columnName, '', columnsConfig[14].selected);
      this.transformService.selectedColumn(footerAvgObj, columnsConfig[15].columnName, '', columnsConfig[15].selected);
      excelTable.push(footerTotalObj, footerAvgObj);
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelTable);
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, 'inspection-list.xlsx');
    }
    else {
      this.msgForbbidenAccess();
    }
  };

  msgForbbidenAccess() {
    this.dialog.open(MsgForbbidenAccessComponent, {
      autoFocus: false,
      panelClass: 'forbidden-msg-dialog-container'
    })
  };

  ngOnDestroy() {
    clearInterval(this.interval);
    this.subscription.unsubscribe();
    this.subscription2.unsubscribe();
  }

}

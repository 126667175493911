import { Pipe, PipeTransform } from '@angular/core';
import moment = require('moment');

@Pipe({
  name: 'dateDifference'
})
export class DateDifferencePipe implements PipeTransform {

  transform(date: string | null): string {
    
    if(date !== null) {
      let startDate: any = new Date(date);
      let endDate: any = new Date(moment().format('YYYY-MM-DDTHH:mm:ss'));
      let seconds = Math.floor((endDate - startDate) / 1000);
      let minutes = Math.floor((endDate - startDate) / 60000);
      let hours = Math.floor((endDate - startDate) / 3600000);
      let days = Math.floor((endDate - startDate) / 86400000);

      if(days >= 1) {
        return `${days} ${days === 1 ? 'day' : 'days'}`;
      }
      else if(hours >= 1) {
        return `${hours} ${hours === 1 ? 'hours' : 'hours'}`;
      }
      else if(minutes >= 1) {
        return `${minutes} ${minutes === 1 ? 'minute' : 'minutes'}`;
      }
      else {
        return `${seconds} ${seconds === 1 ? 'second' : 'seconds'}`;
      }
    }
    else {
      return '-';
    }

  }

}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ticket'
})
export class TicketPipe implements PipeTransform {

  transform(array: any[], value: string, ticketStatus: string): any[] {
    if (!array) {
      return [];
    }
 
    return array.filter((obj: any) => {
      let key1: boolean = true;
      let key2: boolean = false;

      if(value === 'Active drivers') {
        key1 = obj.is_active === true;
      }   
      
      if(value === 'Inactive drivers') {
        key1 = obj.is_active === false;
      }

      if(obj.status === ticketStatus || ticketStatus === 'All') {
        key2 = true;
      }

      if(key1 && key2) { 
        return obj;
      }

    });

  }

}

import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-template-dialog',
  templateUrl: './delete-template-dialog.component.html',
  styleUrls: ['./delete-template-dialog.component.scss']
})
export class DeleteTemplateDialogComponent {

  constructor(public dialogRef: MatDialogRef<any>) { }

  confirmAction() {
    this.dialogRef.close(true);
  };
  
}

import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AccountingService } from '@app/modules/accounting/services/accounting.service';
import { SharedService } from '@app/modules/shared/services/shared.service';
import { TransformService } from '@app/modules/shared/services/transform.service';
import moment = require('moment');
import { ApexAxisChartSeries, ApexChart, ApexDataLabels, ApexGrid, ApexStroke, ApexXAxis, ApexYAxis, ChartComponent } from 'ng-apexcharts';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription, catchError, throwError } from 'rxjs';

export type ChartOptions = {
  series: ApexAxisChartSeries | any;
  chart: ApexChart | any;
  xaxis: ApexXAxis | any;
  yaxis: ApexYAxis | any;
  dataLabels: ApexDataLabels | any;
  grid: ApexGrid | any;
  stroke: ApexStroke | any;
  markers: any;
  colors: any;
  tooltip: any;
};

@Component({
  selector: 'app-maintenance-ifta-graph',
  templateUrl: './maintenance-ifta-graph.component.html',
  styleUrls: ['./maintenance-ifta-graph.component.scss']
})
export class MaintenanceIftaGraphComponent implements OnInit, OnDestroy {
  @ViewChild("chart") chart: ChartComponent | any;
  public chartOptions: Partial<ChartOptions> | any;

  graphData: any[] = [];

  dateObj: any = {
    startDate: this.transformService.convertDateToTimestamp(moment().isoWeekday(2).subtract(4, 'week').format('ddd, DD/MM YYYY'), '00:00:00'),
    endDate: this.transformService.convertDateToTimestamp(moment().isoWeekday(1).format('ddd, DD/MM YYYY'), '23:59:59')
  };

  selectPeriod: FormControl | any;

  objCardsData: any = {
    maintenance: 0,
    maintenanceCpm: 0,
    ifta: 0,
    iftaCpm: 0
  }

  //Period
  isOpenPeriodMenu: boolean = false;
  menuValue: string = 'By week';
  selectPeriodArray: any[] = [
    {name: 'By year', value: 'year'},
    {name: 'By quarter', value: 'quarter'},
    {name: 'By month', value: 'month'},
    {name: 'By week', value: 'week'},
  ];

  loaded: boolean = false;

  error: boolean = false;

  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";

  spinnerBgColor: string = this.transformService.spinnerBgColor;
  spinnerType: string = this.transformService.spinnerType;

  subscription: Subscription | any;

  constructor(private transformService: TransformService,
              private accountingService: AccountingService,
              private spinner: NgxSpinnerService,
              private sharedService: SharedService) {
                this.selectPeriod = new FormControl(['week']);
              }

  ngOnInit(): void {
    this.spinner.show('accounting-graph');
    this.getGraphData();
  }

  getGraphData() {
    this.subscription = this.accountingService.getAccountingGraph(this.dateObj.startDate, this.dateObj.endDate)
    .pipe(catchError((err: any) => {
      this.spinner.hide('accounting-graph');
      this.loaded = true;
      this.error = true;
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
        console.log(response);
        this.initGraph(this.transformService.countDataAccountingGraph(response, this.selectPeriod.value[0]));
        response.forEach((obj: any) => {
          this.objCardsData.maintenance += obj.maintenance_to_be_deducted;  
          this.objCardsData.maintenanceCpm += obj.maintenance_cost_per_mile;

          this.objCardsData.ifta += obj.ifta_to_be_deducted;
          this.objCardsData.iftaCpm += obj.ifta_cost_per_mile;  
        });

        this.graphData = response;
        this.spinner.hide('accounting-graph');
        this.loaded = true;
    });
  }

  initGraph(obj: any) {
    this.chartOptions = {
      series: [
        {
          name: 'Maintenance Deducted',
          data: obj.totalMaintenance
        },
        {
          name: 'Ifta Deducted',
          data: obj.totalIfta
        }
      ],
      chart: {
        toolbar: {
          show: true,
          tools: {
            download: false
          },
          animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 800,
            animateGradually: {
                enabled: true,
                delay: 150
            },
            dynamicAnimation: {
                enabled: true,
                speed: 350
            }
          }
        },
        height: 350,
        type: "line",
        zoom: {
          enabled: false
        },
        fontFamily: 'Poppins'
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: "straight",
        width: 1,
      },
      grid: {
        row: {
          colors: ["transparent", "transparent"],
          opacity: 0.5
        }
      },
      xaxis: {
        categories: obj.categories
      },
      yaxis: {
        tooltip: {
          enabled: true
        },
        labels: {
          formatter: (num: number) => {
            let roundedNumber: any = Math.round(num);
            return roundedNumber.toLocaleString("en-US").toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          },
        },
      },
      markers: {
        size: 5
      },
      colors: ['#0030FF', '#FA9120', '#FA0000', '#008000', '#FF00FF', '#800000', '#FE7777', '#0303BB', '#008FA9', '#7550CB', '#6A6D6E'],
    };
  };

  onlyOneSelect(value: string, obj: any) {
    this.menuValue = obj.name;
    this.selectPeriod = new FormControl([value]);
    this.initGraph(this.transformService.countDataAccountingGraph(this.graphData, this.selectPeriod.value[0]));
    this.isOpenPeriodMenu = false;
  };

  getDate(result) {
    this.dateObj.startDate = result.startDate;
    this.dateObj.endDate = result.endDate;
    this.graphData = [];
    this.error = false;
    this.loaded = false;
    this.spinner.show('accounting-graph');
    this.getGraphData();
  };

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  };

}

import { TitleCasePipe } from '@angular/common';
import { Component, Input, OnChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { AccountingService } from '@app/modules/accounting/services/accounting.service';
import { MsgForbbidenAccessComponent } from '@app/modules/shared/components/msg-forbbiden-access/msg-forbbiden-access.component';
import { RulesService } from '@app/modules/shared/services/rules.service';
import { TransformService } from '@app/modules/shared/services/transform.service';
import moment = require('moment');
import { Subscription } from 'rxjs';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-accounting-table',
  templateUrl: './accounting-table.component.html',
  styleUrls: ['./accounting-table.component.scss']
})
export class AccountingTableComponent implements OnChanges {
  @Input() date: any;
  @Input() tableData: any;

  //Companies filter
  companyArray: any[] = [
    {viewValue: 'Jdm Expedite Inc'},
    {viewValue: 'Tempo Freight Systems Llc'},
    {viewValue: 'Five Star Transport'},
    {viewValue: 'All divisions'},
  ];
  companyMenuOpen: boolean = false;
  companySelected: string = 'All divisions';


  //Type filter
  typeArray: any[] = [
    {viewValue: 'Company', values: ['SOLO COMPANY', 'TEAM COMPANY']},
    {viewValue: 'Rent to run', values: ['SOLO RENT', 'TEAM RENT']},
    {viewValue: 'Owner', values: ['OWNER']},
    {viewValue: 'All types', values: ['SOLO COMPANY', 'TEAM COMPANY', 'SOLO RENT', 'TEAM RENT', 'OWNER', '']},
  ];
  typeMenuOpen: boolean = false;
  typeSelected: string = 'All types';

  //Maintenance cpm
  cpmMenuOpen: boolean = false;
  cpmValue: number = 0.15;
  enterMaintenanceValue: number = 0.15;
  maintenanceGlobalValue: boolean = false;

  //Ifta cpm
  iftaMenuOpen: boolean = false;
  iftaValue: number = 0.06;
  enterIftaValue: number = 0.06;
  iftaGlobalValue: boolean = false;

  //Accounting table

  dataSource: any[] = [];
  sortedData: any[] = [];
  dataTypingArray: any[] = [];

  displayedColumns: string[] = ['hidden-columns', 'maintenance', 'ifta'];
  displayedColumns2: string[] = ['position', 'division', 'driver', 'unit_no', 'type', 'tms_miles', 'gps_miles', 
  'maintenance_cost_per_mile', 'maintenance_to_be_deducted', 'maintenance_done', 
  'ifta_cost_per_mile', 'ifta_to_be_deducted', 'ifta_done'];

  className: string = '';
  columnName: string = '';

  //Excel config
  xlsxConfig: any = [
    {columnName: 'No.', selected: true},
    {columnName: 'DIVISION', selected: true},
    {columnName: 'DRIVER', selected: true},
    {columnName: 'TRUCK #', selected: true},
    {columnName: 'TYPE', selected: true},
    {columnName: 'TMS MILES', selected: true},
    {columnName: 'GPS MILES', selected: true},
    {columnName: 'Maint. COST PER MILE', selected: true},
    {columnName: 'Maint. TO BE DEDUCTED', selected: true},
    {columnName: 'Maint. DONE', selected: true},
    {columnName: 'IFTA COST PER MILE', selected: true},
    {columnName: 'IFTA TO BE DEDUCTED', selected: true},
    {columnName: 'IFTA DONE', selected: true},
  ];

  //Search
  searchText: string = '';
  selectedUsers: any[] = new Array<any>();
  isOpen: boolean = false;

  subscription: Subscription | any;

  constructor(public transformService: TransformService,
              private router: Router,
              private accountingService: AccountingService,
              private rulesService: RulesService,
              private dialog: MatDialog,
              private titleCase: TitleCasePipe) { }

  ngOnChanges(): void {
    if(this.tableData) {
      this.dataSource = JSON.parse(JSON.stringify(this.tableData));
      this.dataTypingArray = JSON.parse(JSON.stringify(this.tableData));
      this.sortedData = this.tableData;
    }
  }

  selectValue(obj: any, isCompany: boolean) {
    let arr: any[] = JSON.parse(JSON.stringify(this.dataSource));
    if(isCompany) {
      let companyToDisplay: any[] = [];
      this.companySelected = obj.viewValue;
      this.companyMenuOpen = false;

      if(obj.viewValue === 'All divisions') {
        this.sortedData = arr;
        return
      }

      for(let key in arr) {
        if(arr[key].division.toLowerCase() === obj.viewValue.toLowerCase()) {
          companyToDisplay.push(arr[key]);
        }
      }

      this.sortedData = companyToDisplay;
    } 
    else {
      let typeToDisplay: any[] = [];
      this.typeSelected = obj.viewValue;
      this.typeMenuOpen = false;

      if(obj.viewValue === 'All types') {
        this.sortedData = arr;
        return
      }

      for(let key in arr) {
        if(obj.values.includes(arr[key].type)) {
          typeToDisplay.push(arr[key]);
        }
      }
      this.sortedData = typeToDisplay;
    }
  };

  //Maintenance, Ifta menu method
  enterNumber(maintenance: boolean) {
    let key: any;
    let value: any;
    if(maintenance) {
      key = 'maintenance_cost_per_mile';
      value = this.enterMaintenanceValue;
      this.cpmValue = this.enterMaintenanceValue;
      this.cpmMenuOpen = false;
      this.maintenanceGlobalValue = true;
    } else {
      key = 'ifta_cost_per_mile';
      value = this.enterIftaValue;
      this.iftaValue = this.enterIftaValue;
      this.iftaMenuOpen = false;
      this.iftaGlobalValue = true;
    }

    this.sortedData.forEach(element => {
      element[key] = value;
    });
    
  }

  //Accounting table
  onMouseOver(columnName: string) {
    this.columnName = columnName;
    this.className = 'hovered-column';
  }

  onMouseOut(columnName: string) {
    this.columnName = columnName;
    this.className = '';
  }

  sortData(sort: Sort) {
    const data = this.dataSource.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }  
    this.sortedData = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'division':
          return compare(a.division, b.division, isAsc);
        case 'driver':
          return compare(a.driver, b.driver, isAsc);
        case 'driver':
          return compare(a.driver, b.driver, isAsc);
        case 'unit_no':
          return compare(a.unit_no, b.unit_no, isAsc);
        case 'type':
          return compare(a.type, b.type, isAsc);
        case 'tms_miles':
          return compare(a.tms_miles, b.tms_miles, isAsc);
        case 'gps_miles':
          return compare(a.gps_miles, b.gps_miles, isAsc);
        case 'maintenance_cost_per_mile':
          return compare(a.maintenance_cost_per_mile, b.maintenance_cost_per_mile, isAsc);
        case 'maintenance_to_be_deducted':
          return compare(a.maintenance_to_be_deducted, b.maintenance_to_be_deducted, isAsc);
        case 'maintenance_done':
          return compare(a.maintenance_done, b.maintenance_done, isAsc);
        case 'ifta_cost_per_mile':
          return compare(a.ifta_cost_per_mile, b.ifta_cost_per_mile, isAsc);
        case 'ifta_to_be_deducted':
          return compare(a.ifta_to_be_deducted, b.ifta_to_be_deducted, isAsc);
        case 'ifta_done':
          return compare(a.ifta_done, b.ifta_done, isAsc);
        default:
          return 0;
      }
    });
  }

  //Search
  toggleSelection(obj: any) {
    obj.selected = !obj.selected;
    if (obj.selected) {
      this.selectedUsers.push(obj);
      this.sortedData = JSON.parse(JSON.stringify(this.selectedUsers));
    } else {
      const i = this.selectedUsers.findIndex(value => value.driver === obj.driver);
      this.selectedUsers.splice(i, 1);
      this.sortedData = JSON.parse(JSON.stringify(this.selectedUsers));
    }
    this.sortedData.length === 0 ? this.sortedData = JSON.parse(JSON.stringify(this.dataSource)) : this.sortedData
  }

  resetCheckedValue() {
    let data: any = JSON.parse(JSON.stringify(this.dataSource));
    this.sortedData = data;
    this.dataTypingArray = data;
    this.searchText = '';
    this.selectedUsers = [];
  }

  addUpdateCpm(obj: any, maintenance: boolean) {
    if(maintenance) {
      this.maintenanceGlobalValue = false;
      obj.maintenance_done = !obj.maintenance_done;  
    } 
    else {
      this.iftaGlobalValue = false;
      obj.ifta_done = !obj.ifta_done;
    }

    let objDataForServer: any = {
      id: obj.id,
      driver_id: obj.driver_id,
      dispatcher_id: obj.dispatcher_id,
      division_id: obj.division_id,
      start: this.date.startDate,
      end: this.date.endDate,
      driver: obj.driver,
      dispatcher: obj.dispatcher,
      division: obj.division,
      unit_no: obj.unit_no,
      type: obj.type,
      tms_miles: obj.tms_miles,
      gps_miles: obj.gps_miles,
      maintenance_cost_per_mile: obj.maintenance_cost_per_mile,
      maintenance_to_be_deducted: obj.maintenance_to_be_deducted,
      maintenance_done: obj.maintenance_done,
      ifta_cost_per_mile: obj.ifta_cost_per_mile,
      ifta_to_be_deducted: obj.ifta_to_be_deducted,
      ifta_done: obj.ifta_done,
      created_by: JSON.parse(localStorage.getItem('currentUser')).first_name + ' ' + JSON.parse(localStorage.getItem('currentUser')).last_name,
      date_created: this.transformService.convertDateToTimestamp(moment(new Date()).format('ddd, DD/MM YYYY'),  moment(new Date()).format('HH:mm:ss'))
    };

    this.accountingService.setMaintenanceIftaCpm(objDataForServer).subscribe((response: any) => {
      console.log(response);
      if(response){
        obj.edit = false;
        obj.edit1 = false;
        obj.id = response.id;
        obj.maintenance_cost_per_mile = response.maintenance_cost_per_mile;
        obj.ifta_cost_per_mile = response.ifta_cost_per_mile;
      }
    })
  };
  
  //Open in new tab
  openInNewTab(route: string) {
    const url = this.router.serializeUrl(this.router.createUrlTree([route]));
    window.open(url, '_blank');
  }

  exportToExcel(columnsConfig: any[]) {
    if(this.rulesService.UserData[56].data[0].sectionArray[39].allowed) {
      const excelTable: any[] = [];
      for(let i = 0; i < this.sortedData.length; i++) {
        let obj: any = {};
        this.transformService.selectedColumn(obj, columnsConfig[0].columnName, i + 1, columnsConfig[0].selected);
        this.transformService.selectedColumn(obj, columnsConfig[1].columnName, this.titleCase.transform(this.sortedData[i].division), columnsConfig[1].selected);
        this.transformService.selectedColumn(obj, columnsConfig[2].columnName, this.titleCase.transform(this.sortedData[i].driver), columnsConfig[2].selected);
        this.transformService.selectedColumn(obj, columnsConfig[3].columnName, this.sortedData[i].unit_no, columnsConfig[3].selected);
        this.transformService.selectedColumn(obj, columnsConfig[4].columnName, this.titleCase.transform(this.sortedData[i].type), columnsConfig[4].selected);
        this.transformService.selectedColumn(obj, columnsConfig[5].columnName, this.sortedData[i].tms_miles, columnsConfig[5].selected);
        this.transformService.selectedColumn(obj, columnsConfig[6].columnName, this.transformService.addCommasDots(this.sortedData[i].gps_miles), columnsConfig[6].selected);
        this.transformService.selectedColumn(obj, columnsConfig[7].columnName, `$${this.sortedData[i].maintenance_cost_per_mile}`, columnsConfig[7].selected);
        this.transformService.selectedColumn(obj, columnsConfig[8].columnName, `$${this.transformService.addCommasDots(this.sortedData[i].maintenance_cost_per_mile * this.sortedData[i].gps_miles)}`, columnsConfig[8].selected);
        this.transformService.selectedColumn(obj, columnsConfig[9].columnName, this.sortedData[i].maintenance_done, columnsConfig[9].selected);
        this.transformService.selectedColumn(obj, columnsConfig[10].columnName, `$${this.sortedData[i].ifta_cost_per_mile}`, columnsConfig[10].selected);
        this.transformService.selectedColumn(obj, columnsConfig[11].columnName, `$${this.transformService.addCommasDots(this.sortedData[i].ifta_cost_per_mile * this.sortedData[i].gps_miles)}`, columnsConfig[11].selected);
        this.transformService.selectedColumn(obj, columnsConfig[12].columnName, this.sortedData[i].ifta_done, columnsConfig[12].selected);
        excelTable.push(obj);
      };
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelTable);
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, 'maintenance-ifta.xlsx');
    }
    else {
      this.msgForbbidenAccess();
    }
  };

  msgForbbidenAccess() {
    this.dialog.open(MsgForbbidenAccessComponent, {
      autoFocus: false,
      panelClass: 'forbidden-msg-dialog-container'
    })
  };

}


function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

import { CdkDragEnter, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NotCoveredTrucksService } from '../../services/not-covered-trucks.service';
import { TransformService } from '@app/modules/shared/services/transform.service';
import { RulesService } from '@app/modules/shared/services/rules.service';

@Component({
  selector: 'app-not-covered-trucks-cards',
  templateUrl: './not-covered-trucks-cards.component.html',
  styleUrls: ['./not-covered-trucks-cards.component.scss']
})
export class NotCoveredTrucksCardsComponent implements OnInit {
  permissions: any = this.rulesService.UserData[5].data[0].sectionArray;
  @ViewChild('dropListContainer') dropListContainer?: ElementRef;

  @Input() cards: any;
  @Input() prevPeriod: any;

  public items: Array<number> = [];

  activeCardsArray: any[] = [
    { selected: false },
    { selected: false },
    { selected: false },
  ];

  activeDownCardsArray: any[] = [
    { selected: false },
    { selected: false },
    { selected: false },
    { selected: false },
    { selected: false },
    { selected: false },
    { selected: false },
    { selected: false },
    { selected: false },
  ]

  selectedIndex: number = -1;

  titlesArray: string[] = ['LOST LOADS', 'LOST LOAD PERCENTAGE', 'ESTIMATED LOSS'];

  keyArray: string[] = ['number_of_trucks', 'percentage', 'estimated_lost'];

  keyArrayCardDown: string[] = ['number_of_trucks_company', 'number_of_trucks_rent', 'number_of_trucks_owner',
    'percentage_company', 'percentage_rent', 'percentage_owner', 'estimated_lost_company', 'estimated_lost_rent',
    'estimated_lost_owner'];

  downKey: string[] = ['number_of_trucks', 'number_of_trucks', 'number_of_trucks', 'percentage', 'percentage', 'percentage',
    'estimated_lost', 'estimated_lost', 'estimated_lost'];

  constructor(public transformService: TransformService, private dataService: NotCoveredTrucksService, private rulesService: RulesService) { }

  ngOnInit(): void {
    let cardShowInGraph: boolean = true;
    this.permissions.forEach((obj: any, index: number) => {
        if(obj.allowed) {

          if(cardShowInGraph) {
            this.activeCardsArray[index].selected = true;
            cardShowInGraph = false;
          }

          this.items.push(index+1);

        }
    });
  }

  showInGraph(obj: any, topCard: boolean, downCardIndex: number) {

    if (topCard) {
      obj.selected = !obj.selected;
      this.resetSelectedCards(false);
    }

    if (!topCard) {
      obj.selected = !obj.selected;
      this.resetSelectedCards(true);
    }

    this.dataService.cardsLineChartSubject.next(this.activeCardsArray);

  }

  resetSelectedCards(topCard: boolean) {
    if (topCard) {
      this.activeCardsArray = [
        { selected: false },
        { selected: false },
        { selected: false },
      ];
    } else {
      this.activeDownCardsArray = [
        { selected: false },
        { selected: false },
        { selected: false },
        { selected: false },
        { selected: false },
        { selected: false },
        { selected: false },
        { selected: false },
        { selected: false },
      ]
    }
  }

  //Drag and drop methods

  dropListReceiverElement?: HTMLElement;
  dragDropInfo?: {
    dragIndex: number;
    dropIndex: number;
  };

  dragEntered(event: CdkDragEnter<number>) {
    const drag = event.item;
    const dropList = event.container;
    const dragIndex = drag.data;
    const dropIndex = dropList.data;

    this.dragDropInfo = { dragIndex, dropIndex };

    const phContainer = dropList.element.nativeElement;
    const phElement = phContainer.querySelector('.cdk-drag-placeholder');

    if (phElement) {
      phContainer.removeChild(phElement);
      phContainer.parentElement?.insertBefore(phElement, phContainer);

      moveItemInArray(this.items, dragIndex, dropIndex);

    }
  };

  dragMoved(index: number) {
    let placeholderElement: any;
    if (!this.dropListContainer || !this.dragDropInfo) return;
    placeholderElement =
      this.dropListContainer.nativeElement.querySelector(
        '.cdk-drag-placeholder'
      );

    const receiverElement =
      this.dragDropInfo.dragIndex > this.dragDropInfo.dropIndex
        ? placeholderElement?.nextElementSibling
        : placeholderElement?.previousElementSibling;

    if (!receiverElement) {
      return;
    }

    receiverElement.style.display = 'none';
    this.dropListReceiverElement = receiverElement;
  }

  dragDropped() {
    if (!this.dropListReceiverElement) {
      return;
    }
    this.dropListReceiverElement.style.removeProperty('display');
    this.dropListReceiverElement = undefined;
    this.dragDropInfo = undefined;
  }

  setDifference(prev: number, current: number, avg?: any) {
    let checkGrowth: any;
    let className: any;
    if (current > prev) {
      checkGrowth = 'more';
      className = 'up';
      avg === 'avg_weight' ? className = 'down' : className;
    } else if (current < prev) {
      checkGrowth = 'less';
      className = 'down';
      avg === 'avg_weight' ? className = 'up' : className;
    } else {
      checkGrowth = '';
      className = 'none'
    }
    let percentage: any;
    if (prev == 0 && current == 0) {
      percentage = 0;
    }

    if (prev == 0 && current !== 0) {
      percentage = 100 - (prev * 100 / current);
    }

    if (prev !== 0 && current == 0) {
      percentage = 100 - (current * 100 / prev);
    }

    if (prev !== 0 && current !== 0) {
      percentage = (prev / current) * 100;
    }

    let oneRangeMsg: any;
    this.prevPeriod.days === 0 ? oneRangeMsg = `${this.prevPeriod.dayName}` : oneRangeMsg = `${this.prevPeriod.days} days`;
    let twoRangeMsg: string = 'chosen period';

    let alert: string = ` ${checkGrowth} than the previous ${this.prevPeriod.twoRange ? twoRangeMsg : oneRangeMsg}`;
    let alertObj: any = { percent: Math.round(percentage), message: alert, className: className };
    return alertObj;
  }

}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../core/services/auth/auth.guard';
import { DashboardDispatchPerformancePageComponent } from './components/dashboard-dispatch-performance-page.component';

const routes: Routes = [
	{
		path: 'time-series',
		canActivate: [AuthGuard],
		children: [
		  {
			path: 'time-series-disp',
			component: DashboardDispatchPerformancePageComponent,
		  }
		],
		data: { roles: 3 }
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class DispatcherPerformanceRoutingModule { }

import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import moment = require('moment');
import { MatDialog } from '@angular/material/dialog';
import { catchError, throwError } from 'rxjs';
import { TransformService } from '@app/modules/shared/services/transform.service';
import { LoginMessagesDialogComponent } from '@app/modules/shared/components/login-messages-dialog/login-messages-dialog.component';
import { SharedService } from '@app/modules/shared/services/shared.service';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
	providers: [AuthenticationService]
})

export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    loading = false;
    submitted = false;
	forgotPassword: boolean = false;

	//Email
	public email: string = '';
	public valid: boolean = false;
	public emailSended: boolean = false;

  constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private authenticationService: AuthenticationService,
		private dialog: MatDialog,
		private sharedService: SharedService,
		private transformService: TransformService) {}

    ngOnInit() {
        this.loginForm = this.formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required]
        });
    }

    // convenience getter for easy access to form fields
    get f() { return this.loginForm.controls; }

    onSubmit() {
        // stop here if form is invalid
        if (this.loginForm.invalid) {
            return;
        }

		this.loading = true;
        this.authenticationService.login(this.f.username.value, this.f.password.value)
		.pipe(catchError((err: any) => {
			this.loading = false;
			return throwError(() => err);
		  }))
		.subscribe((data: any) => {
			console.log('Login response: ', data.message);
			//Detect when loggin 
			if(data.message.toLowerCase() === 'ok') {
				let id: any = JSON.parse(localStorage.getItem('currentUser')).id;
				localStorage.setItem('status', 'online')
				this.sharedService.sendStatus(id, true, this.transformService.convertDateToTimestamp(moment(new Date()).format('ddd, DD/MM YYYY'),  moment(new Date()).format('HH:mm:ss')), true)
				this.router.navigate(['/admin/welcome']);
			}
			else {
				this.dialog.open(LoginMessagesDialogComponent, {
					width: '300px',
					autoFocus: false,
					panelClass: 'login-messages-dialog-container',
					data: data.message
				})
			}
			this.loading = false;
		})
	}
	
	sendResetLink() {
		this.loading = true;
		this.authenticationService.resetLink(this.email).subscribe((response: any) => {
			console.log(response);
			if(response) {
				this.emailSended = response;
				this.loading = false;
			}
		})
	}

	checkIsMailValid() {
		this.valid = this.isValidEmail();
	}

	public isValidEmail(): boolean {
		try {
		  let pattern = new RegExp("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$");
		  let valid = pattern.test(this.email);
		  return valid;
		} catch (TypeError) {
		  return false;
		}
	}
}

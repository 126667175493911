import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FleetHealthService } from '@app/modules/fleet-health/services/fleet-health.service';
import { DeleteConfirmationDialogComponent } from '@app/modules/shared/components/delete-confirmation-dialog/delete-confirmation-dialog.component';
import { SuccessDialogComponent } from '@app/modules/shared/components/success-dialog/success-dialog.component';
import { TransformService } from '@app/modules/shared/services/transform.service';
import moment = require('moment');
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-work-order',
  templateUrl: './work-order.component.html',
  styleUrls: ['./work-order.component.scss']
})
export class WorkOrderComponent implements OnInit, OnDestroy {
  @Input() workOrderObj: any;
  
  addMode: boolean = false;
  editMode: boolean = false;

  showNote: boolean = false;
  showImage: boolean = false;

  //Work order
  isOpenOrderMenu: boolean = false;
  orderCategoryValue: string = 'Category';
  orderSubategoryValue: string = 'Subcategory';
  orderName: string = 'Name';
  orderNameServer: string = '';

  orderCategoryValueServer: string = '';
  orderSubcategoryValueServer: string = '';

  ordersArray: any[] = [];
  ordersMenuArrayCopy: any[] = [];
  ordersMenuArray: any[] = [];

  searchOrdersArray: any[] = [];

  //Labor cost
  laborCost: string = '';
  partCost: string = '';
  files: any[] = [];
  imageName: string = '';
  byteArrayWorkOrder: any[] = [];
  selectedObjIndex: number | undefined = undefined;

  //Subscription
  subscription: Subscription | any;

  constructor(private dialog: MatDialog,
              private fleetService: FleetHealthService, 
              private transformService: TransformService) { }

  ngOnInit(): void {
    console.log(this.workOrderObj)
    this.subscription = this.fleetService.getAllParts().subscribe((response: any) => {
      this.transformWorkOrderData(response[0]);
      console.log(response);
    });
  }

  transformWorkOrderData(array: any) {
    this.ordersArray = array;
    this.searchOrdersArray = array;
    this.ordersMenuArray = this.groupCategories(array);
    this.ordersMenuArrayCopy = {...this.ordersMenuArray};
  }

  groupCategories(array: any[]) {
    const groups: any = array.reduce((acc: any, o: any) => {

      o['category'] = o['category'];
    
      if (!acc[o['category']]) {
        acc[o['category']] = [];
      }
          
      acc[o['category']].push(o);
  
      return acc;
  
    }, {});

    return groups;
  }

  //Filter data
  filterOrder(event: any) {
    console.log(event.target.value);
    let searchText: string = event.target.value;
    let array: any[] = [...this.searchOrdersArray];
    array = array.filter((obj: any) => {
      return obj.name?.toLocaleLowerCase().includes(searchText);
    });
    console.log(array);
    this.ordersMenuArray = this.groupCategories(array);
  }

  selectCategory(obj: any) {
    this.orderCategoryValue = obj.category;
    this.orderCategoryValueServer = obj.category;
    this.orderSubategoryValue = obj.subcategory; 
    this.orderSubcategoryValueServer = obj.subcategory;
    this.orderName = obj.name;
    this.orderNameServer = obj.name;
    this.isOpenOrderMenu = !this.isOpenOrderMenu;
  }

  selectOption(obj: any) {
    obj.isOpen = false;
  };

  //Add work order
  addWorkOrder() {
    this.addMode = true;
    this.resetAllFields();
    this.editMode = true;
  };

  editWorkorder(obj: any, i: number) {
    console.log(obj);
    this.orderCategoryValue = obj.category;
    this.orderCategoryValueServer = obj.category;
    this.orderSubategoryValue = obj.subcategory;
    this.orderSubcategoryValueServer = obj.subcategory;
    this.orderName = obj.name;
    this.orderNameServer = obj.name;
    this.laborCost = obj.labor_cost;
    this.partCost = obj.park_cost;
    this.files = [{image_name: obj.image_name, image_data: obj.image_data}]
    this.selectedObjIndex = i;
    this.editMode = true;
  }

  deleteWorkorder(obj: any, i: number) {
    let dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
      autoFocus: false,
      panelClass: 'delete-dialog-container',
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
        this.fleetService.deleteWorkOrder(obj.id).subscribe((response: any) => {
          console.log(response);
          if(response) {
            this.workOrderObj.workorders.splice(i, 1);
          }
        })
      }
    })
  }
  
  back() {
    this.resetAllFields();
    this.editMode = false;
    this.addMode = false;
  };

  //Upload file
  onFileSelected(event: any) {
    let fileName = event.target.files[0]?.name;
    let reader = new FileReader();
    reader.onload = (e: any) => {
      let uint8: any = new Uint8Array(e.target.result);
      let result: any[] = [];
      for (var i = 0; i < uint8.length; i++) {
        result.push(uint8[i]);
      }
      this.imageName = fileName;
      this.byteArrayWorkOrder  = result;
      this.files[0] = {image_name: fileName, image_data: result};
    }
    reader.readAsArrayBuffer(event.target.files[0]);
  };


  resetAllFields() {
    this.orderCategoryValue = 'Category';
    this.orderSubategoryValue = 'Subcategory';
    this.orderName = 'Name';
    this.orderNameServer = '';
    this.orderCategoryValueServer = '';
    this.orderSubcategoryValueServer = '';
    this.laborCost = '';
    this.partCost = '';
    this.files = [];
  };


  saveUpdate() {
    let loggedUser: any = JSON.parse(localStorage.getItem('currentUser'));
    let objUpdate: any = JSON.parse(JSON.stringify(this.workOrderObj));
    let obj: any = {
      id: 0, 
      type_id: 0, 
      type: 0,
      category: this.orderCategoryValueServer, 
      subcategory: this.orderSubcategoryValueServer, 
      name: this.orderNameServer, 
      labor_cost: this.laborCost, 
      park_cost: this.partCost, 
      image_name: this.imageName, 
      image_data: this.byteArrayWorkOrder
    };

    if(this.addMode) {  
      obj.created_by = loggedUser.first_name + ' ' + loggedUser.last_name; 
      obj.note = '';
      obj.created_date = this.transformService.convertDateToTimestamp(moment(new Date()).format('ddd, DD/MM YYYY'),  moment(new Date()).format('HH:mm:ss')),
      obj.edited_by = null; 
      obj.edited_date =  null;
      objUpdate.workorders.push(obj);
    }
    else {
      obj.id = this.workOrderObj.workorders[this.selectedObjIndex].id;
      objUpdate.workorders[this.selectedObjIndex].category = this.orderCategoryValueServer;
      objUpdate.workorders[this.selectedObjIndex].subcategory = this.orderSubcategoryValueServer;
      objUpdate.workorders[this.selectedObjIndex].name = this.orderNameServer;
      objUpdate.workorders[this.selectedObjIndex].labor_cost = this.laborCost;
      objUpdate.workorders[this.selectedObjIndex].park_cost = this.partCost;
      objUpdate.workorders[this.selectedObjIndex].image_name = this.imageName;
      objUpdate.workorders[this.selectedObjIndex].image_data = this.byteArrayWorkOrder;

      objUpdate.edited_by = loggedUser.first_name + ' ' + loggedUser.last_name;
      objUpdate.edited_date = this.transformService.convertDateToTimestamp(moment(new Date()).format('ddd, DD/MM YYYY'),  moment(new Date()).format('HH:mm:ss'));
    } 

    console.log(objUpdate);
    this.fleetService.saveWorkOrder(objUpdate).subscribe((response: any) => {
      console.log(response);
      if(response.result) {
        this.workOrderObj.workorders = objUpdate.workorders;
        this.dialog.open(SuccessDialogComponent, {
          autoFocus: false,
          panelClass: 'success-dialog-container'
        });   
      };
    })
  };

  previewFile(obj: any) {
    let extension: string = obj.image_name.substring(obj.image_name.length - 3);
    let base64: string = obj.image_data;
    if(typeof obj.image_data === 'object') {
      base64 = btoa(String.fromCharCode(...new Uint8Array(obj.image_data)));
    }

    if(extension === 'pdf') {
      const anchor = document.createElement('a');
      document.body.appendChild(anchor);
      anchor.style.display = 'none';
      const byteCharacters = atob(base64);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/pdf' });
      console.log(atob(base64));
      const url = window.URL.createObjectURL(blob);
      anchor.href = url; 
      anchor.target = '_blank';
      anchor.click();
      window.URL.revokeObjectURL(url);
    }
    else {
      let image = document.createElement('img');
      image.src = `data:image/${extension};base64,` + base64;
      image.setAttribute('style', 'width: 100%')
      let w = window.open("");
      w.document.write(image.outerHTML);
    }
  
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  };

}

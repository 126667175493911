import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IReasons } from '@app/modules/full-app/models/reason-model';

import { FullAppService } from '@app/modules/full-app/services/full-app.service';

@Component({
  selector: 'app-full-app-reason-dialog',
  templateUrl: './full-app-reason-dialog.component.html',
  styleUrls: ['./full-app-reason-dialog.component.scss']
})
export class FullAppReasonDialogComponent implements OnInit {
  reasonObj: IReasons = {
    id: 0,
    reason: ''
  }

  constructor(@Inject(MAT_DIALOG_DATA) public obj: IReasons,
  private fullAppService: FullAppService,
  public dialogRef: MatDialogRef<FullAppReasonDialogComponent>) { }

  ngOnInit(): void {
    this.reasonObj = this.obj;
  }

  saveReason() {
    this.fullAppService.createUpdateReason(this.reasonObj).subscribe((response: IReasons) => {
      console.log(response);
      if(response.id) {
        this.dialogRef.close(response);
      }
    })
  };
  
}


import { Component, Input } from '@angular/core';
import { IFields } from '@app/modules/full-app/models/template-fields.model';
import moment = require('moment');


@Component({
  selector: 'custom-input',
  templateUrl: './custom-input.component.html',
  styleUrls: ['./custom-input.component.scss']
})
export class CustomInputComponent {
  @Input() config: IFields = {
    id: 0,
    inputTitle: '',
    inputTitle2: '',
    inputValue: '',
    inputValue2: '',
    inputArray: '',
    inputPrefix: '',
    inputSuffix: '',
    inputPrefix2: '',
    inputSuffix2: '',
    option: 0,
    dropdownValue: '',
    dropdownValue2: '',
    dropdownArray: '',
    dropdownArray2: '',
    fixedValue: '',
    calendarTitle: '',
    date: '',
    users: 0
  }
  isOpenDropdownMenuFirst: boolean = false;
  isOpenDropdownMenuSecond: boolean = false;

  isOpenCalendar: boolean = false;

  dateClicked(event: any) {
    this.config.date = moment(event._d).format('YYYY-MM-DDT00:00:00');
    this.isOpenCalendar = false;
  }

  //Get mask
  getMask(value: number) {
    let letters: string =  'S';
    for(let i = 0;  i < value; i++) {
      letters += 'S';
    }
    return `00000||${letters}`;
  };

}

import { Injectable } from '@angular/core';
import * as _G from '../../../_config/globals';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';

@Injectable()

export class NotCoveredTrucksService {
  headers = new HttpHeaders({
		'Content-Type': 'application/json',
		'Authorization': 'Basic SVZTb2x1dGlvbjoxUzBsdXQxMG4yQDIy'
	});
	requestOptions = { headers: this.headers };


  constructor(private http: HttpClient) { }

  cardsLineChartSubject = new Subject<any>();
  driverLineChartSubject = new Subject<any>();
  dispatcherLineChartSubject = new Subject<any>();

  getNotCoveredTrucksCardsData(startDate: string, endDate: string) {
    return this.http.get(`${_G.apiUrl}Trucks/notcovered?startDate=${startDate}&endDate=${endDate}`);
  };

  getNotCoveredTrucksLineChart(startDate: string, endDate: string) {
    return this.http.get(`${_G.extendApiUrl}trucks/gettrucksnotcoveredbydate?startDate=${startDate}&enddate=${endDate}`, this.requestOptions);
  };

}

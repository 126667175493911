import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription, catchError, throwError } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { InspectionDetailsComponent } from './inspection-details/inspection-details.component';
import { SharedService } from '@app/modules/shared/services/shared.service';
import { RulesService } from '@app/modules/shared/services/rules.service';
import { TransformService } from '@app/modules/shared/services/transform.service';
import { Inspection, Outcome, Level, InspectionOverviewItem, InspectionOverview } from '../models/inspection.model';
import { InspectionsService } from '../services/inspections.service';
import moment = require('moment');

@Component({
  selector: 'app-inspections',
  templateUrl: './inspections.component.html',
  styleUrls: ['./inspections.component.scss']
})
export class InspectionsComponent implements OnInit, OnDestroy {
  dateObj: any = {
    startDate: moment().startOf('month').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD')
  };

  private subscription?: Subscription;
  private subscription2?: Subscription;

  inspectionOverview = this.initializeInspectionOverview();
  originalData?: Inspection[];
  filteredData?: Inspection[];

  selectedOutcome: Outcome | null = null;
  selectedLevel: Level | null = null;

  //Interval
  interval: any;

  loaded: boolean = false;
  error: boolean = false;
  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";
  spinnerBgColor: string = this.transformService.spinnerBgColor;
  spinnerType: string = this.transformService.spinnerType;

  constructor(
    public service: InspectionsService,
    private dialog: MatDialog,
    private transformService: TransformService,
    private spinner: NgxSpinnerService,
    private rulesService: RulesService) { }

  ngOnInit() {
    this.spinner.show('inspections');
    this.getInspectionData();
    this.subscription2 = this.service.refresh$.subscribe((response: boolean) => {
      if(response) {
        this.loaded = false;
        this.error = false;
        this.service.data$.next([]);
        this.spinner.show('inspections');
        this.getInspectionData();
      }
    });
    if(this.rulesService.liveUpdate) {
      this.interval = setInterval(() => {
        this.getInspectionData();
      }, this.rulesService.miliseconds);
    }
  }

  get Outcome() {
    return Outcome;
  }

  get Level() {
    return Level;
  }

  getInspectionData() {
    this.subscription = this.service.getInspectionData(this.dateObj.startDate, this.dateObj.endDate)
    .pipe(catchError((err: any) => {
      this.spinner.hide('inspections');
      this.loaded = true;
      this.error = true;
      return throwError(() => err);
    }))
    .subscribe((response: Inspection[]) => {
      console.log(response);
      const sortedData = this.sortDataByDate(response);
      this.setFilteredAndOriginalData(sortedData);
      this.resetSelectedOutcomeAndLevel();
      this.generateInspectionOverview();
      this.service.data$.next(response);
      this.spinner.hide('inspections');
      this.loaded = true;
    });
  }

  private sortDataByDate(data: any[]): any[] {
    return data.sort((a: any, b: any) => {
      return <any>new Date(b.date) - <any>new Date(a.date);
    })
  }

  private setFilteredAndOriginalData(sortedData: any[]): void {
    this.filteredData = this.originalData = sortedData;
  }

  private resetSelectedOutcomeAndLevel(): void {
    this.selectedOutcome = null;
    this.selectedLevel = null;
  }

  openDialog(data?: any, editMode?: boolean) {
    this.dialog.open(InspectionDetailsComponent, {
      autoFocus: false,
      panelClass: 'inspections',
      data: { inspectionData: data, editMode: editMode },
    });
  }

  getDate(result: any) {
    this.spinner.show('inspections');
    this.loaded = false;
    this.error = false;
    this.dateObj.startDate = result.startDate;
    this.dateObj.endDate = result.endDate;
    this.getInspectionData();
  };

  private generateInspectionOverview() {
    this.inspectionOverview = this.initializeInspectionOverview();

    if (this.originalData && this.originalData.length > 0) {
      this.originalData.forEach(item => {
        this.updateInspectionOverview(item, this.inspectionOverview.all);
        if (item.inspection_result?.toLowerCase() === Outcome.PASSED.toLowerCase()) {
          this.updateInspectionOverview(item, this.inspectionOverview.passed);
        } else if (item.inspection_result?.toLowerCase() === Outcome.FAILED.toLowerCase()) {
          this.updateInspectionOverview(item, this.inspectionOverview.failed);
        }
      });

      if (!this.inspectionOverview.all.value) return;

      this.inspectionOverview.passed.percentage = Math.round((this.inspectionOverview.passed.value / this.inspectionOverview.all.value) * 100);
      this.inspectionOverview.failed.percentage = Math.round((this.inspectionOverview.failed.value / this.inspectionOverview.all.value) * 100);
    }
  }

  private updateInspectionOverview(inspection: Inspection, inspectionOverviewItem: InspectionOverviewItem) {
    const predefinedLevels: Level[] = [
      Level.Level1,
      Level.Level2,
      Level.Level3,
    ];
    const inspectionLevel = inspection.inspection_level as Level;;

    if (predefinedLevels.includes(inspectionLevel)) {
      const existingLevel = inspectionOverviewItem.levels.find(e => e.level.toLowerCase() === inspectionLevel.toLowerCase());

      if (!existingLevel) {
        const newLevel = { level: inspectionLevel, value: 1, percentage: 0 };
        inspectionOverviewItem.levels.push(newLevel);
      } else {
        existingLevel.value++;
      }
    }

    inspectionOverviewItem.value++;

    predefinedLevels.forEach(level => {
      const existingLevel = inspectionOverviewItem.levels.find(e => e.level === level);
      if (!existingLevel) {
        const newLevel = { level, value: 0, percentage: 0 };
        inspectionOverviewItem.levels.push(newLevel);
      }
    });

    inspectionOverviewItem.levels.forEach(level => {
      level.percentage = Math.round((level.value / inspectionOverviewItem.value) * 100);
    });
  }

  filterByOutcomeAndLevel(outcome?: Outcome, level?: Level) {
    let filteredData: Inspection[];

    if (level) {
      if (outcome) {
        if (level.toLowerCase() === Level.Level1.toLowerCase()) {
          filteredData = this.originalData.filter(item => item.inspection_result.toLowerCase() === outcome.toLowerCase() && item.inspection_level.toLowerCase() === Level.Level1.toLowerCase());
        } else if (level.toLowerCase() === Level.Level2.toLowerCase()) {
          filteredData = this.originalData.filter(item => item.inspection_result.toLowerCase() === outcome.toLowerCase() && item.inspection_level.toLowerCase() === Level.Level2.toLowerCase());
        } else if (level.toLowerCase() === Level.Level3.toLowerCase()) {
          filteredData = this.originalData.filter(item => item.inspection_result.toLowerCase() === outcome.toLowerCase() && item.inspection_level.toLowerCase() === Level.Level3.toLowerCase());
        } else {
          filteredData = this.originalData.filter(item => item.inspection_result.toLowerCase() === outcome.toLowerCase());
        }
      } else {
        filteredData = this.originalData.filter(item => item.inspection_level.toLowerCase() === level.toLowerCase());
      }
    } else if (outcome) {
      filteredData = this.originalData.filter(item => item.inspection_result.toLowerCase() === outcome.toLowerCase());
    } else {
      filteredData = this.originalData;
    }

    this.selectedOutcome = outcome;
    this.selectedLevel = level;
    this.service.data$.next(filteredData);
  }

  private initializeInspectionOverview(): InspectionOverview {
    const predefinedLevels: Level[] = [
      Level.Level1,
      Level.Level2,
      Level.Level3,
    ];

    const initializeLevel = () => ({
      value: 0,
      percentage: 0,
    });

    const inspectionOverview: InspectionOverview = {
      all: {
        value: 0,
        percentage: 0,
        levels: predefinedLevels.map(level => ({
          ...initializeLevel(),
          level,
        })),
      },
      passed: {
        value: 0,
        percentage: 0,
        levels: predefinedLevels.map(level => ({
          ...initializeLevel(),
          level,
        })),
      },
      failed: {
        value: 0,
        percentage: 0,
        levels: predefinedLevels.map(level => ({
          ...initializeLevel(),
          level,
        })),
      },
    };

    return inspectionOverview;
  }

  ngOnDestroy() {
    clearInterval(this.interval);
    this.subscription.unsubscribe();
    this.subscription2.unsubscribe();
  }

}

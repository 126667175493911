import { Component, Input, NgZone, OnChanges, OnDestroy, OnInit } from '@angular/core';

import { ApexAxisChartSeries, ApexChart, ApexDataLabels, ApexLegend, ApexPlotOptions, ApexResponsive, ApexXAxis } from 'ng-apexcharts';
import { HiredTerminatedDialogOverComponent } from '../hired-terminated-dialog-over/hired-terminated-dialog-over.component';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { catchError, throwError } from 'rxjs';
import { RulesService } from '@app/modules/shared/services/rules.service';
import { OverviewService } from '../../services/overview.service';
import { TransformService } from '@app/modules/shared/services/transform.service';

export type ChartOptions = {
  series: ApexAxisChartSeries | any;
  chart: ApexChart | any;
  xaxis: ApexXAxis | any;
  responsive: ApexResponsive | any;
  labels: string | any;
  colors: string | any;
  legend: ApexLegend | any;
  tooltip: any;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
};

@Component({
  selector: 'app-fleet-status',
  templateUrl: './fleet-status.component.html',
  styleUrls: ['./fleet-status.component.scss']
})
export class FleetStatusComponent implements OnInit, OnChanges, OnDestroy {
  permissions: any = this.rulesService.UserData[1].data[1].sectionArray;
  @Input() date: any;
  driverTurnover: any;
  dataSource: any;
  displayedColumns: string[] = ['activity', 'drivers', 'percent'];

  subscription1: any;
  subscription2: any;
  subscription3: any;

  pieData: any;
  filteredDataForColumnChart: any[] = [];
  selectPeriod: FormControl | any;

  dataForDialog: any;

  elements: any;

  data: any;

  //Hired terminated resigned
  hiredResignedTerminatedArray: any[] = [
    {name: 'Company', filter: ['SOLO COMPANY', 'TEAM COMPANY']},
    {name: 'Rent to run', filter: ['SOLO RENT', 'TEAM RENT']},
    {name: 'All', filter: ['SOLO COMPANY', 'TEAM COMPANY', 'SOLO RENT', 'TEAM RENT']},
  ];
  isOpenHiredResignedTerminatedMenu: boolean = false;
  hiredResignedTerminatedValue: string = 'All';
  hiredResignedTerminatedIndex: number = 2;

  //Interval
  interval: any;

  //Error message
  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";

  loaded1: boolean = false;
  error1: boolean = false;

  loaded2: boolean = false;
  error2: boolean = false;

  spinnerBgColor: string = this.transformService.spinnerBgColor;
  spinnerType: string = this.transformService.spinnerType;

  public chartOptions: Partial<ChartOptions> | any;
  public chartOptions2: Partial<ChartOptions> | any;

  constructor(private overviewService: OverviewService,
              private transformService: TransformService, 
              private ngZone: NgZone, 
              private rulesService: RulesService,
              public dialog: MatDialog,
              private spinner: NgxSpinnerService) {
    this.selectPeriod = new FormControl(['day']);
  }

  ngOnInit(): void {
    this.spinner.show('sp2');
    this.spinner.show('sp3');
    this.dateChanged();
    if(this.rulesService.liveUpdate) {
      this.interval = setInterval(() => {
        this.getData();
      }, this.rulesService.miliseconds);
    }
  }

  ngOnChanges(): void {
    if (this.date) {
      this.getData();
    }
  }

  dateChanged() {
    this.subscription1 = this.overviewService.dateChanged.subscribe((response: any) => {
      this.date.startDate = response.startDate;
      this.date.endDate = response.endDate;
      this.dataSource = undefined;
      this.driverTurnover = undefined;
      this.chartOptions = undefined;

      this.error1 = false;
      this.loaded1 = false;
      this.spinner.show('sp2');

      this.error2 = false;
      this.loaded2 = false;
      this.spinner.show('sp3');

      this.getData();
    })
  }

  getData() {
    this.subscription2 = this.overviewService.getOrientationBreakdown(this.date.startDate, this.date.endDate)
    .pipe(catchError((err: any) => {
      this.spinner.hide('sp2');
      this.loaded1 = true;
      this.error1 = true;
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      this.dataSource = response;
      this.spinner.hide('sp2');
      this.loaded1 = true;
      console.log(response)
    })

    this.subscription3 = this.overviewService.getHiredTerminated(this.date.startDate, this.date.endDate, this.transformService.filterParams)
    .pipe(catchError((err: any) => {
      this.spinner.hide('sp3');
      this.loaded2 = true;
      this.error2 = true;
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      this.pieData = response;
      this.spinner.hide('sp3');
      this.loaded2 = true;
      console.log(response);
      this.filterDataByType(this.hiredResignedTerminatedArray[this.hiredResignedTerminatedIndex].filter, response);
    });
  }

  filterDrivers(obj: any, index: number) {
    this.hiredResignedTerminatedValue = obj.name; 
    this.hiredResignedTerminatedIndex = index;
    this.isOpenHiredResignedTerminatedMenu = false;
    this.filterDataByType(obj.filter, this.pieData);
  }

  filterDataByType(typeArray: string[], data: any[]) {
    let showColumnChart: boolean = false;
    if(this.chartOptions2) {
      showColumnChart = true;
    }
    this.chartOptions2 = false;
    let dataArray: any = JSON.parse(JSON.stringify(data));
    for(let i = 0; i < dataArray.length; i++) {
      let hiredDriversArray: any[] = [];
      let resignedDriversArray: any[] = [];
      let terminationDriversArray: any[] = [];
      let terminatedDriversArray: any[] = [];

      if(dataArray[i].hired_drivers) {
        for(let j = 0; j < dataArray[i].hired_drivers.length; j++) {
          if(typeArray.includes(dataArray[i].hired_drivers[j].type)) {
            hiredDriversArray.push(dataArray[i].hired_drivers[j])
          }
        };
      }

      if(dataArray[i].resignation_drivers) {
        for(let j = 0; j < dataArray[i].resignation_drivers.length; j++) {
          if(typeArray.includes(dataArray[i].resignation_drivers[j].type)) {
            resignedDriversArray.push(dataArray[i].resignation_drivers[j])
          }
        };
      }

      if(dataArray[i].termination_drivers) {
        for(let j = 0; j < dataArray[i].termination_drivers.length; j++) {
          if(typeArray.includes(dataArray[i].termination_drivers[j].type)) {
            terminationDriversArray.push(dataArray[i].termination_drivers[j])
          }
        };
      }

      if(dataArray[i].terminated_drivers) {
        for(let j = 0; j < dataArray[i].terminated_drivers.length; j++) {
          if(typeArray.includes(dataArray[i].terminated_drivers[j].type)) {
            terminatedDriversArray.push(dataArray[i].terminated_drivers[j])
          }
        };
      }

      dataArray[i].hired = hiredDriversArray.length;
      dataArray[i].resignation = resignedDriversArray.length;
      dataArray[i].termination = terminationDriversArray.length;
      dataArray[i].terminated = terminatedDriversArray.length;

      dataArray[i].hired_drivers = hiredDriversArray;
      dataArray[i].resignation_drivers = resignedDriversArray;
      dataArray[i].termination_drivers = terminationDriversArray;
      dataArray[i].terminated_drivers = terminatedDriversArray;
    };
    this.chartOptions = this.pieChart(this.transformService.countHiredTerminatedOverview(dataArray));
    if(showColumnChart) {
      this.columnChart(this.transformService.groupByWeeksOverview(dataArray, this.selectPeriod.value[0]));
    }
    this.filteredDataForColumnChart = dataArray;
  };

  pieChart(obj: any) {
    return {
      colors: ['#003fa2', '#ff900c', '#FA0000'],
      series: [obj.sumHired, obj.sumResigned, obj.sumTermination],
      plotOptions: {
        pie: {
          dataLabels: {
            offset: -33
          }, 
        }
      },
      chart: {
        width: 450,
        type: "pie",
        fontFamily: 'Poppins'
      },
      labels: ['HIRED', 'RESIGNED', 'TERMINATED'],
      legend: {
        position: 'bottom',
        formatter: function(seriesName, opts) {
          return [seriesName, " - ", opts.w.globals.series[opts.seriesIndex]]
      }
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: "bottom"
            }
          }
        }
      ]
    };
    
  };

  columnChart(obj: any) {
    this.chartOptions2 = {
      series: [
        {
          name: "HIRED",
          data: obj.hired
        },
        {
          name: "RESIGNED",
          data: obj.resigned
        },
        {
          name: "TERMINATED",
          data: obj.termination
        }
      ],
      chart: {
        toolbar: {
          show: true,
          tools: {
            download: false
          }
        },
        type: "bar",
        height: 500,
        fontFamily: 'Poppins',
        events: {
          click: (event: any, chartContext: any, config: any) => {
            this.ngZone.run(() => {
              if (config.dataPointIndex !== -1) {
                let dataArray: any[] = config.seriesIndex === 0 ? obj.hiredData : obj.terminatedData;
                this.dialog.open(HiredTerminatedDialogOverComponent, {
                  autoFocus: false,
                  data: {
                    elements: config,
                    data: {array: dataArray}
                  },
                  panelClass: 'hired-terminated-dialog'
                });
              }
            });
          }
        }
      },
      plotOptions: {
        bar: {
          dataLabels: {
            position: "top",
            columnWidth: "80%",
            endingShape: "rounded"
          }
        }
      },
      dataLabels: {
        enabled: true,
        offsetY: -30,
        style: {
          fontSize: "14px",
          colors: ["#304758"]
        }
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"]
      },
      xaxis: {
        categories: obj.categories
      },
      colors: ['#003fa2', '#ff900c', '#FA0000'],
      tooltip: {
        y: {
          formatter: (val: number, opts: any) => {
            let total: number = obj.hired[opts.dataPointIndex] + obj.terminated[opts.dataPointIndex];
            let value: number | any = (val / total) * 100; 
            let percent: number = value ? value : 0; 
            return `${this.transformService.addCommasDots(percent, 'round')}%`;
          }
        }
      }
    };    
  }

  onlyOneSelect(value: string) {
    this.selectPeriod = new FormControl([value]);
    this.columnChart(this.transformService.groupByWeeksOverview(this.filteredDataForColumnChart, this.selectPeriod.value[0]));
  };

  showColumnGraph() {
    this.columnChart(this.transformService.groupByWeeksOverview(this.filteredDataForColumnChart, this.selectPeriod.value[0]));
  };

  ngOnDestroy(): void {
    clearInterval(this.interval);
    this.subscription1?.unsubscribe();
    this.subscription2?.unsubscribe();
    this.subscription3?.unsubscribe();
  }

}

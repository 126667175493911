import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as _G from '../../../_config/globals';
import { IBrokersToAvoid } from '../models/brokers-to-avoid.model';
import { IBrokersReasons } from '../models/brokers-reasons.model';

@Injectable()
export class BrokersToAvoidService {
  headers = new HttpHeaders({
		'Content-Type': 'application/json',
		'Authorization': 'Basic SVZTb2x1dGlvbjoxUzBsdXQxMG4yQDIy'
	});
	requestOptions = { headers: this.headers };

  constructor(private http: HttpClient) { }

  getBrokersToAvoidTableData(history: boolean) {
    return this.http.get(`${_G.extendApiUrl}brokers/avoid?history=${history}`, this.requestOptions);
  };

  createUpdateBrokerToAvoid(obj: IBrokersToAvoid) {
    let json: string = JSON.stringify(obj);
    return this.http.post(`${_G.extendApiUrl}brokers/avoid`, json, this.requestOptions);
  };

  deleteBrokerToAvoid(id: number) {
    return this.http.delete(`${_G.extendApiUrl}brokers/avoid/${id}`, this.requestOptions);
  };

  //Reasons
  getAllReasons() {
    return this.http.get(`${_G.extendApiUrl}brokers/reasons`, this.requestOptions);
  };

  createUpdateReasons(obj: IBrokersReasons) {
    let json: string = JSON.stringify(obj);
    return this.http.post(`${_G.extendApiUrl}brokers/reasons`, json, this.requestOptions);
  };

  deleteReason(reasonId: number) {
    return this.http.delete(`${_G.extendApiUrl}brokers/reasons/${reasonId}`, this.requestOptions);
  };

}

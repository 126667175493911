import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IExtend } from '@app/modules/full-app/models/extend-model';
import { IFullAppFields } from '@app/modules/full-app/models/full-app-fields';
import { FullAppService } from '@app/modules/full-app/services/full-app.service';
import { TransformDataService } from '@app/modules/full-app/services/transform-data.service';
import { SharedService } from '@app/modules/shared/services/shared.service';
import { TransformService } from '@app/modules/shared/services/transform.service';
import moment = require('moment');
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription, catchError, throwError } from 'rxjs';
import { FullAppDocumentationDialogComponent } from '../../shared/components/full-app-documentation-dialog/full-app-documentation-dialog.component';
import { FullAppNoteDialogComponent } from '../../shared/components/full-app-note-dialog/full-app-note-dialog.component';
import { FullAppRejectionDialogComponent } from '../../shared/components/full-app-rejection-dialog/full-app-rejection-dialog.component';
import { ScheduleOrientationDateDialogComponent } from '../../shared/components/schedule-orientation-date-dialog/schedule-orientation-date-dialog.component';
import { TemplateDriverInputsComponent } from '../../shared/components/template-driver-inputs/template-driver-inputs.component';

@Component({
  selector: 'orientation-application-tab',
  templateUrl: './orientation-application-tab.component.html',
  styleUrls: ['./orientation-application-tab.component.scss']
})
export class OrientationApplicationTabComponent implements OnInit, OnDestroy {
  //Calendars
  isOpenFromCalendar: boolean = false;
  isOpenToCalendar: boolean = false;

  daysArray: any[] = [];
  dateObj: any = {
    startDate: moment().startOf('isoWeek').format('YYYY-MM-DDT00:00:00'),
    endDate: moment().endOf('isoWeek').format('YYYY-MM-DDT23:59:59')
  };
  selectedDay: number = 0;
  countsByDay: any = {};

  //Recruiter menu
  isOpenRecruiterMenu: boolean = false;
  recruiterValue: string = 'All';

  //Type menu
  isOpenTypeMenu: boolean = false;
  typeValue: string = 'All';
  typeKey: string = 'All';
  typeArray: any[] = [
    {name: 'Company', key: 'secondCheckbox'},
    {name: 'Rent to run', key: 'firstCheckbox'},
    {name: 'Owner', key: 'thirdCheckbox'},
    {name: 'All', key: 'All'}
  ];

  //Filled applications
  filledApplicationObj: any = {
    filledApplicationArray: [],
    recruiterArray: [],
    statusArray: ['In the office', 'Not hired', 'Hired', 'In transit', 'Left', 'In a hotel', 'No answer']
  }
  selectedFilledApplicationObj: any;
  selectedDriverIndex: number = 0;

  //Metadata
  filterMetaData: any = {
    countObj: {
      allNum: 0,
      inTheOfficeNum: 0,
      notHiredNum: 0,
      hiredNum: 0,
      inTransitNum: 0,
      leftNum: 0,
      inAHotelNum: 0,
      noAnswerNum: 0,
      array: [],
      selectedDriverObj: null
    }
  };

  driversSearch: string = '';

  searchFields: string = '';

  //Filters
  filtersArray: any[] = [
    {title: 'All', keyNum: 'allNum'},
    {title: 'In the office', keyNum: 'inTheOfficeNum'},
    {title: 'Not hired', keyNum: 'notHiredNum'},
    {title: 'Hired', keyNum: 'hiredNum'},
    {title: 'In transit', keyNum: 'inTransitNum'},
    {title: 'Left', keyNum: 'leftNum'},
    {title: 'In a hotel', keyNum: 'inAHotelNum'},
    {title: 'No answer', keyNum: 'noAnswerNum'}
  ];
  activeFilter: string = 'All';

  //Tabs
  tabsArray: any[] = [
    {name: 'Deal'},
    {name: 'Expenses'},
    {name: 'Special requirements'},
    {name: 'Trip planner'},
    {name: 'Paperwork'}
  ];
  activeTab: string = 'Deal';
  
  //Status menu
  isOpenStatusMenu: boolean = false;
  
  //Search drivers
  searchDrivers: string = '';
  
  //Inputs array
  inputsArray: IFullAppFields[] = [];

  loaded: boolean = false;
  error: boolean = false;
  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";
  spinnerBgColor: string = this.transformService.spinnerBgColor;
  spinnerType: string = this.transformService.spinnerType;

  //Subscription
  subscription1: Subscription = new Subscription();
  subscription2: Subscription = new Subscription();

  constructor(private transformData: TransformDataService,
              private fullAppService: FullAppService,
              private dialog: MatDialog, 
              public transformService: TransformService,
              private sharedService: SharedService,
              private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.getDaysBetweenRange();
    this.getFilledApplications();
    this.getAllRecruiters();
  };

  //Tabs
  selectStatus(value: string) {
    let obj: IExtend = {
      extend_id: this.selectedFilledApplicationObj.Id,
      recruiter: this.selectedFilledApplicationObj.recruiter,
      truck_no: this.selectedFilledApplicationObj.truck_no,
      status: value,
      orientation_date: this.selectedFilledApplicationObj.orientation_date
    };

    if(value === 'Schedule for Orientation') {
      this.scheduleOrientationDate(obj);
    }
    else if(obj.status === 'Not approved') {
      this.changeStatus(obj, value);
    }
    else {
      this.changeStatus(obj, value);
    }
  }

  changeStatus(obj: IExtend, value: string) {
    this.fullAppService.changeStatusOrRecruiter(obj).subscribe((response: any) => {
      if(response) {
        this.selectedFilledApplicationObj.status = value;
        this.isOpenStatusMenu = !this.isOpenStatusMenu;
        if(value === 'Not approved') {
          this.openDialogBasedOnStatus();
        }
      } 
    });
  };

  openDialogBasedOnStatus() {
    if(this.selectedFilledApplicationObj.status === 'Not approved') {
      this.openRejectionDialog();
    }
    else {
      this.openDocumentationDialog();
    }
  };

  scheduleOrientationDate(obj: IExtend) {
    let dialogRef = this.dialog.open(ScheduleOrientationDateDialogComponent, {
      autoFocus: false,
      panelClass: 'component-dialog-container',
      data: obj
    });
    dialogRef.afterClosed().subscribe((result: IExtend) => {
      if(result) {
        this.selectedFilledApplicationObj.status = result.status;
      }
    });
  };

  openRejectionDialog() {
    this.dialog.open(FullAppRejectionDialogComponent, {
      autoFocus: false,
      panelClass: 'rejection-dialog-container',
      disableClose: true,
      data: this.selectedFilledApplicationObj
    });
  };

  openDocumentationDialog() {
    this.dialog.open(FullAppDocumentationDialogComponent, {
      autoFocus: false,
      panelClass: 'documentation-dialog-container',
      disableClose: true,
      data: 0
    });
  };

  showNoteDialog() {
    this.dialog.open(FullAppNoteDialogComponent, {
      autoFocus: false,
      panelClass: 'note-dialog-container',
      position: {top: 'calc(50vh - 402.5px)', right: '0px'}, 
      data: this.selectedFilledApplicationObj.Id
    });
  };

  createInputs() {
    let dialogRef: any = this.dialog.open(TemplateDriverInputsComponent, {
      autoFocus: false,
      panelClass: 'template-drivers-dialog-container',
      disableClose: true,
      data:this.selectedFilledApplicationObj
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
        this.getFilledApplications();
      }
    });
  };

  dateClicked(event: any, fromCalendar: boolean) {
    if(fromCalendar) {
      this.dateObj.startDate = moment(event._d).format('YYYY-MM-DDT00:00:00');
      this.isOpenFromCalendar = false;
    }
    else {
      this.dateObj.endDate = moment(event._d).format('YYYY-MM-DDT23:59:59');
      this.isOpenToCalendar = false;
    }
  };

  run() {
    this.getDaysBetweenRange();
    this.getFilledApplications();
  };

  getFilledApplications() {
    this.filledApplicationObj.filledApplicationArray = [];
    this.error = false;
    this.loaded = false;
    this.spinner.show('full-app-section');
    this.subscription1 = this.fullAppService.getOrientationApplications(this.dateObj.startDate, this.dateObj.endDate)
    .pipe(catchError((err: any) => {
      this.spinner.hide('full-app-section');
      this.loaded = true;
      this.error = true;
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      console.log(response);
      this.filledApplicationObj.filledApplicationArray = response;
      if(response.length > 0) {
        this.fillInputsArrayWithData(response[0]);
        this.countsByDay = {};
        response.forEach((el: any) => {
          this.countsByDay[el.orientation_date] = (this.countsByDay[el.orientation_date] || 0) + 1;
        });
      }
      this.spinner.hide('full-app-section');
      this.loaded = true;
    });
  };

  getAllRecruiters() {
    this.subscription2 = this.sharedService.getAllEmployees().subscribe((response: any) => {
      for(let i = 0; i < response.length; i++) {
        if(response[i].department === 'Recruiting') {
          this.filledApplicationObj.recruiterArray.push(response[i].nickname)
        }
      }
    });
  };

  driverChanged(dataObj: any) {
    this.selectedDriverIndex = dataObj.index;
    this.fillInputsArrayWithData(dataObj.obj);
  }

  getInputsArray(obj: any) {
    if(this.selectedFilledApplicationObj?.Id !== obj.Id) {
      this.inputsArray = this.transformData.getInputs(obj);
      this.selectedFilledApplicationObj = obj;
    }
    return this.inputsArray;
  };

  fillInputsArrayWithData(obj: any) {
    let selectedobj: any = {...obj};
    selectedobj.DataJson = JSON.parse(selectedobj.DataJson);
    this.inputsArray = this.transformData.getInputs(selectedobj);
  };

  changeWeek(prev: boolean) {
    let startRes = moment(this.dateObj.startDate, "YYYY-MM-DD");
    let endRes = moment(this.dateObj.endDate, "YYYY-MM-DD")
    let days = endRes.diff(startRes, 'days') + 1;
    if(prev) {
      this.dateObj.startDate = moment(this.dateObj.startDate).subtract(days, "days").format('YYYY-MM-DDT00:00:00');
      this.dateObj.endDate = moment(this.dateObj.endDate).subtract(days, "days").format('YYYY-MM-DDT23:59:59');
    }
    else {
      this.dateObj.startDate = moment(this.dateObj.startDate).add(days, "days").format('YYYY-MM-DDT00:00:00');
      this.dateObj.endDate = moment(this.dateObj.endDate).add(days, "days").format('YYYY-MM-DDT23:59:59');
    }
    this.getDaysBetweenRange();
    this.run();
  };

  getDaysBetweenRange() {
    this.daysArray = [];
    let startDate = new Date(this.dateObj.startDate);
    let endDate = new Date(this.dateObj.endDate);
    const date = new Date(startDate.getTime());
    while (date <= endDate) {
      this.daysArray.push(moment(date).format('YYYY-MM-DDT00:00:00'));
      date.setDate(date.getDate() + 1);
    }
  };

  getStatisticsByDay(day: string): number {
    if(this.countsByDay.hasOwnProperty(day)) {
      return this.countsByDay[day];
    } 
    else {
      return 0;
    }
  };

  ngOnDestroy(): void {
    this.subscription1?.unsubscribe();   
    this.subscription2?.unsubscribe();    
  };

}

import { Component } from '@angular/core';

@Component({
  selector: 'app-full-app',
  templateUrl: './full-app.component.html',
  styleUrls: ['./full-app.component.scss']
})
export class FullAppComponent {
  //New design
  tabsArray: any[] = [
    {name: 'Full app'},
    {name: 'Contact form'},
    {name: 'Orientation'}
  ];
  activeIndex: number = 0;

  /*Old design
  tabsArray: ITabs[] = [{name: 'Orientation', badge: false}, {name: 'Full Applications', badge: false}, 
  {name: 'Search drivers', badge: false}, {name: 'Analysis', badge: false}];
  activeTab: string = 'Orientation';
  */

  constructor() { }
  
}

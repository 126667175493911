import { Component, Input } from '@angular/core';

@Component({
  selector: 'drivers-statements-activity-table',
  templateUrl: './drivers-statements-activity-table.component.html',
  styleUrls: ['./drivers-statements-activity-table.component.scss']
})
export class DriversStatementsActivityTableComponent {
  @Input() profileData: any;
  monthlyView: boolean = false;

  constructor() { }

  changePreview(event: any) {
    this.monthlyView = !this.monthlyView;
    event.preventDefault();
  };

}
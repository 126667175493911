import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import moment = require('moment');
import { DriversFilterService } from '../../services/drivers-filter.service';
import { TransformService } from '@app/modules/shared/services/transform.service';

@Component({
  selector: 'app-filters-dialog',
  templateUrl: './filters-dialog.component.html',
  styleUrls: ['./filters-dialog.component.scss']
})
export class FiltersDialogComponent {
  isOpenFromCalendar: boolean = false;
  isOpenToCalendar: boolean = false;

  constructor(public filterService: DriversFilterService,
              public dialogRef: MatDialogRef<any>,
              private transformService: TransformService) { }

  dateClicked(event: any, calendarFirst: boolean) {
    if(calendarFirst) {
      this.filterService.fromDate = moment(event._d).format('MMM DD, yyy');
      this.filterService.filterFromDate = this.transformService.convertDateToTimestamp(moment(event._d).format('ddd, DD/MM YYYY'), '00:00:00');
      this.isOpenFromCalendar = false;
    } else {
      this.filterService.toDate = moment(event._d).format('MMM DD, yyy');
      this.filterService.filterToDate = this.transformService.convertDateToTimestamp(moment(event._d).format('ddd, DD/MM YYYY'),  moment().format('HH:mm:ss'));
      this.isOpenToCalendar = false;
    }
  }

  search() {
    this.dialogRef.close(this.filterService.filterConditions);
  };
  
  resetFilters() {
    this.filterService.resetFilters();
  }
}

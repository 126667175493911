import { Component, Input } from '@angular/core';
import { TransformService } from '@app/modules/shared/services/transform.service';

@Component({
  selector: 'app-docs-history',
  templateUrl: './docs-history.component.html',
  styleUrls: ['./docs-history.component.scss']
})
export class DocsHistoryComponent {
  @Input() obj: any;

  constructor(public transformService: TransformService) { }

  selectOption(obj: any) {
    obj.isOpen = false;
  }

}

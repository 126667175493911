import { Injectable } from '@angular/core';
import * as _G from '../../../_config/globals';
import { HttpHeaders, HttpClient } from '@angular/common/http';

@Injectable()

export class HometimeService {
  headers = new HttpHeaders({
		'Content-Type': 'application/json',
		'Authorization': 'Basic SVZTb2x1dGlvbjoxUzBsdXQxMG4yQDIy'
	});
	requestOptions = { headers: this.headers };

  constructor(private http: HttpClient) { }

  getHometimeData(startDate: string, endDate: string) {
    return this.http.get(`${_G.extendApiUrl}drivers/hometime?start=${startDate}&end=${endDate}`, this.requestOptions);
  };
}

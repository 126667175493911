import { Component, OnInit } from '@angular/core';

import * as moment from 'moment';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PrebookedUpcomingService } from '../services/prebooked-upcoming.service';
import { TransformService } from '@app/modules/shared/services/transform.service';

@Component({
  selector: 'app-prebooked-upcoming',
  templateUrl: './prebooked-upcoming.component.html',
  styleUrls: ['./prebooked-upcoming.component.scss']
})
export class PrebookedUpcomingComponent implements OnInit {
  dateFormGroup: FormGroup;
  loaded: boolean = false;

  //Data for cards upcoming
  objOfCards: any;

  //Data for table
  tableData: any;

  dateObj: any = {
    startDate: this.transformService.convertDateToTimestamp(moment().startOf('month').format('ddd, MM/DD/YYYY'),
      '00:00:00'),
    endDate: this.transformService.convertDateToTimestamp(moment().format('ddd, MM/DD/YYYY'), moment().format('HH:mm:ss')),
  };


  constructor(private prebookedUpcomingService: PrebookedUpcomingService,
    private transformService: TransformService,
    private router: Router,
    private activatedRoute: ActivatedRoute) {
    this.dateFormGroup = new FormGroup({
      'start': new FormControl(moment().startOf('month')),
      'end': new FormControl(moment())
    });
  }

  ngOnInit(): void {
    let calendarDate: any;
    let date: any = sessionStorage.getItem('date');
    if (date) {
      let js = JSON.parse(date);
      calendarDate = js;
      this.dateFormGroup = new FormGroup({
        'start': new FormControl(moment(calendarDate.startDate)),
        'end': new FormControl(moment(calendarDate.endDate))
      })
      this.dateObj.startDate = this.transformService.convertDateToTimestamp(moment(calendarDate.startDate).format('ddd, MM/DD/YYYY'), '00:00:00');
      this.dateObj.endDate = this.transformService.convertDateToTimestamp(moment(calendarDate.endDate).format('ddd, MM/DD/YYYY'), moment().format('HH:mm:ss'));
    } else {
      calendarDate = this.dateObj;
    }
    console.log(calendarDate)
    this.getCardsData(this.dateObj);
  }

  getCardsData(dateObj: any) {
    this.prebookedUpcomingService.getUpcomingCards(dateObj.startDate, dateObj.endDate).subscribe((response: any) => {
      console.log(response);
      this.loaded = true;
      let obj: any = {};
      obj.potential = '$' + this.transformService.addCommasDots(response.data.potential);
      obj.potential_miles = this.transformService.addCommasDots(response.data.potential_miles);
      obj.reta_per_mile = '$' + this.transformService.addCommasDots(response.data.reta_per_mile);
      obj.potential_weight = this.transformService.addCommasDots(response.data.potential_weight);
      obj.potential_num_of_loads = this.transformService.addCommasDots(response.data.potential_num_of_loads);
      obj.potential_margin = '$' + this.transformService.addCommasDots(response.data.potential_margin);
      obj.potentional_margin_percent = this.transformService.addCommasDots(response.data.potentional_margin_percent) + '%';

      //Calculation
      obj.mileStatistics = { below: 20 > 10, difference: '0.21' };

      obj.weightStatistics = { below: 10 > 20, difference: 2.356 };

      obj.marginStatistics = { below: 20 > 10, difference: '3.4' };

      this.objOfCards = obj;
      this.tableData = response.data;
    })
  }


  //Calendar methods
  dateRangeChange(dateObj: any) {
    console.log(dateObj);
    this.dateObj.startDate = this.transformService.convertDateToTimestamp(moment(dateObj.startDate).format('ddd, MM/DD/YYYY'), '00:00:00');
    this.dateObj.endDate = this.transformService.convertDateToTimestamp(moment(dateObj.endDate).format('ddd, MM/DD/YYYY'), moment().format('HH:mm:ss'));
    this.getCardsData(this.dateObj);
  };

  //Back btn

  backPage() {
    this.router.navigate(['/admin/dispatchers/prebooked-loads'], { relativeTo: this.activatedRoute })
  }

  //Btns 

  nextWeek() {
    this.dateObj.startDate = this.transformService.convertDateToTimestamp(moment().add(1, 'weeks').startOf('isoWeek').format('ddd, MM/DD/YYYY'),
      '00:00:00')
    this.dateObj.endDate = this.transformService.convertDateToTimestamp(moment().add(1, 'weeks').endOf('isoWeek').format('ddd, MM/DD/YYYY'),
      '23:59:59');
    console.log(this.dateObj);
    this.getCardsData(this.dateObj);
  };

  next7Days() {
    this.dateObj.startDate = this.transformService.convertDateToTimestamp(moment().format('ddd, MM/DD/YYYY'),
      '00:00:00')
    this.dateObj.endDate = this.transformService.convertDateToTimestamp(moment().add(7, 'days').format('ddd, MM/DD/YYYY'),
      '23:59:59');
    console.log(this.dateObj);
    this.getCardsData(this.dateObj);
  };

  next14Days() {
    this.dateObj.startDate = this.transformService.convertDateToTimestamp(moment().format('ddd, MM/DD/YYYY'),
      '00:00:00')
    this.dateObj.endDate = this.transformService.convertDateToTimestamp(moment().add(14, 'days').format('ddd, MM/DD/YYYY'),
      '23:59:59');
    console.log(this.dateObj);
    this.getCardsData(this.dateObj)
  };

  next30Days() {
    this.dateObj.startDate = this.transformService.convertDateToTimestamp(moment().format('ddd, MM/DD/YYYY'),
      '00:00:00')
    this.dateObj.endDate = this.transformService.convertDateToTimestamp(moment().add(30, 'days').format('ddd, MM/DD/YYYY'),
      '23:59:59');
    console.log(this.dateObj);
    this.getCardsData(this.dateObj)
  };

  all() {
    this.dateObj.startDate = this.transformService.convertDateToTimestamp(moment().format('ddd, MM/DD/YYYY'),
      '00:00:00')
    this.dateObj.endDate = this.transformService.convertDateToTimestamp(moment().add(1, 'year').endOf('year')
      .format('ddd, MM/DD/YYYY'), '23:59:59');
    console.log(this.dateObj);
    this.getCardsData(this.dateObj);
  };

}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import moment = require('moment');

@Component({
  selector: 'calendar-one-date-select',
  templateUrl: './calendar-one-date-select.component.html',
  styleUrls: ['./calendar-one-date-select.component.scss']
})
export class CalendarOneDateSelectComponent {
  @Input() config: any = {
    date: '',
    defaultValue: '',
    dateFormatToShow: 'MMM dd, yyyy.',
    dateFormatToReturn: 'YYYY-MM-DD'
  };
  @Input() styleConfig: any = {
    'width': '240px',
    'height': '45px',
    'background-color': '#f4f8ff',
    'color': '#1F1F1F',
    'box-shadow': 'none',
    'border-radius': '25px',
    'border-top': 'thin solid #0000001f'
  };
  @Output() triggerEvent = new EventEmitter<string | number>();

  //Date
  isOpenCalendar: boolean = false;

  dateClicked(event: any) {
    this.config.date = moment(event._d).format(this.config.dateFormatToReturn);
    this.triggerEvent.emit(this.config.date);
    this.isOpenCalendar = false;
  };

}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { AccountingService } from '../../services/accounting.service';
import { Subscription, catchError, throwError } from 'rxjs';
import moment = require('moment');
import { TransformService } from '@app/modules/shared/services/transform.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { IAccountingBoard } from '../../models/accounting-board.model';
import * as XLSX from 'xlsx';
import { TitleCasePipe } from '@angular/common';
import { RulesService } from '@app/modules/shared/services/rules.service';
import { MatDialog } from '@angular/material/dialog';
import { MsgForbbidenAccessComponent } from '@app/modules/shared/components/msg-forbbiden-access/msg-forbbiden-access.component';

@Component({
  selector: 'app-dashboard-accounting-board-page',
  templateUrl: './dashboard-accounting-board-page.component.html',
  styleUrls: ['./dashboard-accounting-board-page.component.scss']
})
export class DashboardAccountingBoardPageComponent implements OnInit, OnDestroy {
  //Date
  dateObj: any = {
    startDate: moment().subtract(6, 'month').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD')
  };

  dataArray: IAccountingBoard[] | any = [];

  //Excel config
  xlsxConfig: any[] = [
    {columnName: 'No.', selected: true},
    {columnName: 'Confirmation', selected: true},
    {columnName: '#Truck', selected: true},
    {columnName: 'Driver', selected: true},
    {columnName: 'Load Number', selected: true},
    {columnName: 'Brooker', selected: true},
    {columnName: 'Load Status', selected: true},
    {columnName: 'Customer Load Number', selected: true},
    {columnName: 'Pu Date', selected: true},
    {columnName: 'Del Date', selected: true},
    {columnName: 'Invoice Date', selected: true},
    {columnName: 'Pu City and State', selected: true},
    {columnName: 'Del City and State', selected: true},
    {columnName: 'Driver Status', selected: true},
    {columnName: 'Dispatcher', selected: true},
    {columnName: 'Tonu', selected: true},
    {columnName: 'Paid Miles', selected: true},
    {columnName: 'Freight Amount', selected: true},
    {columnName: 'Note', selected: true}
  ];

  //Subscription
  subscription: Subscription = new Subscription();

  loaded: boolean = false;
  error: boolean = false;
  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";

  spinnerBgColor: string = this.transformService.spinnerBgColor;
  spinnerType: string = this.transformService.spinnerType;

  constructor(private transformService: TransformService,
              private accountingService: AccountingService,
              private spinner: NgxSpinnerService,
              private rulesService: RulesService,
              private dialog: MatDialog,
              private titleCase: TitleCasePipe) { }

  ngOnInit() {
    this.getTableData();
  };

  getTableData() {
    this.dataArray = [];
    this.error = false;
    this.loaded = false;
    this.spinner.show('accounting-board');
    this.subscription = this.accountingService.getAccountingLoads(this.dateObj.startDate, this.dateObj.endDate)
    .pipe(catchError((err: any) => {
      this.spinner.hide('accounting-board');
      this.loaded = true;
      this.error = true;
      return throwError(() => err);
    }))
    .subscribe((response: IAccountingBoard[]) => {
      this.dataArray = response;
      this.spinner.hide('accounting-board');
      this.loaded = true;
      console.log(response);
    });
  };

  getDate(result: any) {
    this.dateObj.startDate = result.startDate;
    this.dateObj.endDate = result.endDate;
    this.getTableData();
  };

  exportToExcel(columnsConfig: any[]) {
    if(this.rulesService.UserData[56].data[0].sectionArray[3].allowed) {
      const excelTable: any[] = [];
      for(let i = 0; i < this.dataArray.length; i++) {
        let obj: any = {};
        this.transformService.selectedColumn(obj, columnsConfig[0].columnName, i + 1, columnsConfig[0].selected);
        this.transformService.selectedColumn(obj, columnsConfig[1].columnName, this.dataArray[i].info._checked, columnsConfig[1].selected);
        this.transformService.selectedColumn(obj, columnsConfig[2].columnName, this.dataArray[i].unit_no, columnsConfig[2].selected);
        this.transformService.selectedColumn(obj, columnsConfig[3].columnName, this.titleCase.transform(this.dataArray[i].driver), columnsConfig[3].selected);
        this.transformService.selectedColumn(obj, columnsConfig[4].columnName, this.dataArray[i].load_no, columnsConfig[4].selected);
        this.transformService.selectedColumn(obj, columnsConfig[5].columnName, this.titleCase.transform(this.dataArray[i].company_name), columnsConfig[5].selected);
        this.transformService.selectedColumn(obj, columnsConfig[6].columnName, this.dataArray[i].load_status, columnsConfig[6].selected);
        this.transformService.selectedColumn(obj, columnsConfig[7].columnName, this.dataArray[i].customer_load_no, columnsConfig[7].selected);
        this.transformService.selectedColumn(obj, columnsConfig[8].columnName, this.transformService.transformDateFormat(this.dataArray[i].pickup_time, 'MM.DD.YYYY'), columnsConfig[8].selected);
        this.transformService.selectedColumn(obj, columnsConfig[9].columnName, this.transformService.transformDateFormat(this.dataArray[i].delivery_time, 'MM.DD.YYYY'), columnsConfig[9].selected);
        this.transformService.selectedColumn(obj, columnsConfig[10].columnName, this.transformService.transformDateFormat(this.dataArray[i].invoice_date, 'MM.DD.YYYY'), columnsConfig[10].selected);
        this.transformService.selectedColumn(obj, columnsConfig[11].columnName, this.dataArray[i].pickup_location, columnsConfig[11].selected);
        this.transformService.selectedColumn(obj, columnsConfig[12].columnName, this.dataArray[i].delivery_location, columnsConfig[12].selected);
        this.transformService.selectedColumn(obj, columnsConfig[13].columnName, this.titleCase.transform(this.dataArray[i].driver_status), columnsConfig[13].selected);
        this.transformService.selectedColumn(obj, columnsConfig[14].columnName, this.titleCase.transform(this.dataArray[i].dispatcher), columnsConfig[14].selected);
        this.transformService.selectedColumn(obj, columnsConfig[15].columnName, this.dataArray[i].tonu, columnsConfig[15].selected);
        this.transformService.selectedColumn(obj, columnsConfig[16].columnName, this.dataArray[i].paid_miles, columnsConfig[16].selected);
        this.transformService.selectedColumn(obj, columnsConfig[17].columnName, this.dataArray[i].freight_amount, columnsConfig[17].selected);
        this.transformService.selectedColumn(obj, columnsConfig[18].columnName, this.dataArray[i].note, columnsConfig[18].selected);
        excelTable.push(obj);
      };
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelTable);
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, 'accounting-board.xlsx');
    }
    else {
      this.msgForbbidenAccess();
    }
  };

  msgForbbidenAccess() {
    this.dialog.open(MsgForbbidenAccessComponent, {
      autoFocus: false,
      panelClass: 'forbidden-msg-dialog-container'
    })
  };

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  };

}

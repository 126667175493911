import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Sort } from '@angular/material/sort';
import moment = require('moment');
import { CreateWorkOrderDialogComponent } from '../create-work-order-dialog/create-work-order-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Subscription, catchError, throwError } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { TruckNoteDialogComponent } from './truck-note-dialog/truck-note-dialog.component';
import { CreateUpdateStatusComponent } from '../create-work-order-dialog/create-update-status/create-update-status.component';
import { SuccessDialogComponent } from '@app/modules/shared/components/success-dialog/success-dialog.component';
import { SharedService } from '@app/modules/shared/services/shared.service';
import { RulesService } from '@app/modules/shared/services/rules.service';
import { FleetHealthService } from '../../services/fleet-health.service';
import { TransformService } from '@app/modules/shared/services/transform.service';

//Table

export interface DealerShopTable {
  unit_no: string,
  fleet_status: string, 
  driver: string, 
  dispatcher: string, 
  trailer_status: string,
  arrived_by: string,
  waiting_time: string,
  end_type: string,
  type_state: string,
  status: string,
  repair_cost: string,
  last_check: string,
}

//Map
declare const google: any;
declare global {
  interface Window {
    initMap: () => void;
  }
}

@Component({
  selector: 'app-dealer-shop-page',
  templateUrl: './dealer-shop-page.component.html',
  styleUrls: ['./dealer-shop-page.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})

export class DealerShopPageComponent implements OnInit, OnDestroy {
  permissionsData: any = this.rulesService.UserData[22].data;

  //Dealer or Shop filter
  isOpenFilterMenu: boolean = false;
  value: string = 'Dealer';
  filterArray: any[] = [
    {filterName: 'Dealer', type: 0},
    {filterName: 'Shop', type: 1},
  ];
  type: number = 0;

  //Status filter
  allStatusArray: any[] = [];
  statusArray: any[] = [];
  statusActive: number = 0;

  dataSource: MatTableDataSource<DealerShopTable[]> = new MatTableDataSource();
  columnsToDisplay = ['unit_no', 'fleet_status', 'driver', 'dispatcher', 'load_status', 'arrived_by', 'waiting_time',
  'end_type', 'type_state', 'status', 'repair_cost', 'last_checked'];
  columnsToDisplayWithExpand = [...this.columnsToDisplay];
  expandedElement: DealerShopTable | null;

  arrayLength: number = 0;

  //Menu items
  menuArray: any[] = [
    {item: 'Dealership details'}, 
    {item: 'Work order'}, 
    {item: 'Payment detailes'}, 
    {item: 'Current Load'}, 
    {item: 'Repair history'}
  ];

  selectedMenuItem: number = 0;

  //Quick update
  lastCheckDate: string = '';
  timeStart: string = '';
  hourStart: any;
  minutesStart: any;

  endDate: string = ''; 
  timeEnd: string = '';
  hourEnd: any;
  minutesEnd: any;

  status: string = '';

  //Notes
  @ViewChild('scrollNotesContainer', { read: ElementRef }) public scrollNotesContainer: any;
  isBottom: boolean = false;

  //Map
  map: any = null;

  //Note array
  noteArray: any[] = [];

  //Total cost
  totalCost: number = 0;

  //Loader
  loaded: boolean = false;
  error: boolean = false;
  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";

  spinnerType: string = this.transformService.spinnerType;

  //Subscription
  subscription1: Subscription | any;
  subscription2: Subscription | any;
  subscription3: Subscription | any;
  subscription4: Subscription | any;
  subscription5: Subscription | any;

  constructor(private rulesService: RulesService, 
              private dialog: MatDialog,
              public transformService: TransformService,
              private fleetService: FleetHealthService,
              private spinner: NgxSpinnerService,
              private sharedService: SharedService) { }

  ngOnInit(): void {
    this.getDataByType();
    this.refreshNoteArray();
    this.updateListOfStatuses();
  }

  getDataByType() {
    this.loaded = false;
    this.spinner.show('dealer-shop-spinner');
    this.subscription1 = this.fleetService.getDataBasedOnType(this.type)
    .pipe(catchError((err: any) => {
      this.spinner.hide('dealer-shop-spinner');
      this.loaded = true;
      this.error = true;
      let errorCode: string = err.status + ' ' + err.statusText;
      let service: string = err.url;
      this.sharedService.sendErrorNotification(errorCode, service);
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      let transformedArray: any[] = response;
      transformedArray.forEach((obj: any) => {
        let startDate: any = moment();
        let endDate: any = moment(obj.start_type);
        obj.waiting_time = this.secondsToDhms(startDate.diff(endDate) / 1000);

        let repairCost: number = 0;
        for(let key in obj.payments) {
          repairCost += obj.payments[key].amount;
        }
        obj.repair_cost = repairCost;
      });

      this.getAllStatuses(transformedArray);
      console.log(transformedArray);
      //Map init
      this.initMap();
      this.arrayLength = transformedArray.length;
      this.dataSource.data = transformedArray;
      this.spinner.hide('dealer-shop-spinner');
      this.loaded = true;
    })
  }

  //Get statuses
  getAllStatuses(tableArray: any[]) {
    this.subscription2 = this.fleetService.getAllStatus().subscribe((response: any) => {
      this.allStatusArray = [...response];
      let allStatusesArray: any[] = response;

      tableArray.forEach((obj: any) => {

        allStatusesArray.forEach((element: any) => {

          if(!element.numOfStatus && !element.filteredData) {
            element.numOfStatus = 0;
            element.filteredData = [];
          }
          
          if(element.status === obj.checkedstatus?.[obj.checkedstatus.length - 1]?.status) {
            element.numOfStatus++;
            element.filteredData.push(obj);
          }

        });

      });
     
      allStatusesArray.unshift({id: null, status: 'All trucks', created_by: null, created_date: null, 
      edited_by: null, edited_date: null, numOfStatus: tableArray.length, filteredData: tableArray });
      console.log(allStatusesArray);
      this.statusArray = allStatusesArray;

    });
  }

  //Maps methods
  async initMap() {
    const { Map } = await google.maps.importLibrary("maps");
    this.map = new Map(document.getElementById("map"), {
      center: { lat: -34.397, lng: 150.644 },
      zoom: 8,
      scrollwheel: true,
      mapTypeControl: true,
      draggable: true,
      scaleControl: true,
      navigationControl: true,
      disableDefaultUI: true,
      streetViewControl: false,
    });
  }

  //Sort data
  sortData(sort: Sort) {
    const data = this.dataSource.data.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSource.data = data;
      return;
    }
    this.dataSource.data = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'unit_no':
          return compare(a.unit_no, b.unit_no, isAsc);
        case 'fleet_status':
          return compare(a.fleet_status, b.fleet_status, isAsc);
        case 'driver':
          return compare(a.driver, b.driver, isAsc);
        case 'dispatcher':
          return compare(a.dispatcher, b.dispatcher, isAsc);
        case 'load_status':
          return compare(a.load_status, b.load_status, isAsc);
        case 'arrived_by':
          return compare(a.arrived_by, b.arrived_by, isAsc);
        case 'waiting_time':
          return compare(a.waiting_time, b.waiting_time, isAsc);
        case 'end_type':
          return compare(a.end_type, b.end_type, isAsc);
        case 'type_state':
          return compare(a.type_state, b.type_state, isAsc);
        case 'status':
          return compare(a.status, b.status, isAsc);
        case 'repair_cost':
          return compare(a.repair_cost, b.repair_cost, isAsc);
        case 'last_checked':
          return compare(a.last_checked, b.last_checked, isAsc);
        default:
          return 0;
      }
    });
  }

  //Select filter
  selectFilter(obj: any, activeIndex: number) {
    this.statusActive = activeIndex;
    this.dataSource.data = obj.filteredData;
  };

  selectOption(obj: any) {
    this.value = obj.filterName;
    this.type = obj.type;
    this.getDataByType();
    this.isOpenFilterMenu = false;
  }

  //Quick update methods
  dateClicked(event: any, element: any, topCalendar: boolean) {
    if(topCalendar) {
      this.lastCheckDate = moment(event._d).format(`YYYY-MM-DDT00:00:00`);
      element.isOpenCalendar = false;
    }
    else {
      this.endDate = moment(event._d).format(`YYYY-MM-DDT00:00:00`);
      element.isOpenCalendar2 = false;
    }
  };

  //Time methods
  onKeyUp(event: any, hour: boolean, start: number) {
    let enteredNum: any = +event.target.value;

    if(hour && (enteredNum > 23 || enteredNum < 0 || event.target.value.length > 2)) {

      if(start === 1) {
        this.timeStart = '00';
      }
      else {
        this.timeEnd = '00';
      }
    }

    if(!hour && (enteredNum > 59 || enteredNum < 0 || event.target.value.length > 2)) {
      if(start === 1) {
        this.minutesStart = '00';
      }
      else {
        this.minutesEnd = '00';
      }
    }

  }

  leadingZero(hour: boolean, start: number) {
    if(start === 1) {

      if(hour && this.hourStart) {
        let hourString: string = this.hourStart.toString();
        this.hourStart =  (hourString.length === 1) ? ("0" + this.hourStart) : this.hourStart;
      }

      if(!hour && this.minutesStart) {
        let minutesString: string = this.minutesStart.toString();
        this.minutesStart = (minutesString.length === 1) ? ("0" + this.minutesStart) : this.minutesStart; 
      }
    }
    else {
      if(hour && this.hourEnd) {
        let hourString: string = this.hourEnd.toString();
        this.hourEnd =  (hourString.length === 1) ? ("0" + this.hourEnd) : this.hourEnd;
      }
  
      if(!hour && this.minutesEnd) {
        let minutesString: string = this.minutesEnd.toString();
        this.minutesEnd = (minutesString.length === 1) ? ("0" + this.minutesEnd) : this.minutesEnd; 
      }
    }
  }

  applyTime(element: any, start: number) {
    if(start === 1) {
      this.timeStart = `${this.hourStart}:${this.minutesStart}`;
      element.isOpenStartTimeMenu = false;
    }
    else {
      this.timeEnd = `${this.hourEnd}:${this.minutesEnd}`;
      element.isOpenEndTimeMenu = false;
    }
  };

  //Notes methods
  public scrollBottom(): void {
    this.scrollNotesContainer.nativeElement.scrollTo(
    { top: (this.scrollNotesContainer.nativeElement.scrollTop + 95 ), behavior: 'smooth' });
  }

  public scrollTop(): void {
    this.scrollNotesContainer.nativeElement.scrollTo(
      { top: (this.scrollNotesContainer.nativeElement.scrollTop - 95), behavior: 'smooth' });
  }

  isBottomCheck(e: any) {
    if (e.target.scrollHeight < e.target.scrollTop + e.target.offsetHeight + 1) {
      this.isBottom = true;
    }

    if(e.target.scrollTop === 0) {
      this.isBottom = false;
    }
  }

  openCreateWorkOrder() {
    let dialogRef: any = this.dialog.open(CreateWorkOrderDialogComponent, {
      autoFocus: false,
      panelClass: 'create-work-order-dialog-container',
      disableClose: true 
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
        this.getDataByType();
      }
    });
  }

  secondsToDhms(seconds: number) {
    seconds = Number(seconds)
    let d = Math.floor(seconds / (3600 * 24));
    let h = Math.floor((seconds % (3600 * 24)) / 3600);
    let m = Math.floor((seconds % 3600) / 60);

    let time: string = '';

    if(d > 0 && h > 0 && m > 0) {
      time = d+'d ' + h + ':' + m + 'h';
    } 
    else if(h > 0 && m > 0) {
      time = h + ':' + m + 'h';
    }
    else {
      time = m + 'h';
    }
    return time;
  }

  //Search table
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  //Quick update
  quickUpdate(element: any) {
    let obj: any = JSON.parse(JSON.stringify(element));
    let loggedUser: any = JSON.parse(localStorage.getItem('currentUser'));
    let startDate: string = this.transformService.convertDateToTimestamp(moment(this.lastCheckDate).format('ddd, DD/MM YYYY'), this.timeStart + ':00');
    let endDate: string = this.transformService.convertDateToTimestamp(moment(this.endDate).format('ddd, DD/MM YYYY'), this.timeEnd + ':00');
    let data: any = {
      id: 0,
      type_id: 0,
      last_check: startDate,
      status: this.status,
      expected_end: endDate,
      created_by: loggedUser.first_name + ' ' + loggedUser.last_name,
      created_date: this.transformService.convertDateToTimestamp(moment(new Date()).format('ddd, DD/MM YYYY'),  moment(new Date()).format('HH:mm:ss'))
    }
    obj.edited_by = loggedUser.first_name + ' ' + loggedUser.last_name;
    obj.edited_date = this.transformService.convertDateToTimestamp(moment(new Date()).format('ddd, DD/MM YYYY'),  moment(new Date()).format('HH:mm:ss'));
    obj.checkedstatus = [data];

    console.log(element);
    console.log(obj);
    
    this.fleetService.saveWorkOrder(obj).subscribe((response: any) => {
      console.log(response);
      if(response.result) {
        element.checkedstatus.push(obj.checkedstatus[0]);
        this.dialog.open(SuccessDialogComponent, {
          autoFocus: false,
          panelClass: 'success-dialog-container'
        });   
      }
    });
  }

  //Expand row
  expandRow(event: any, element: any) {
    this.expandedElement = this.expandedElement === element ? null : element; 
    event.stopPropagation(); 
    this.getNotes(element.id);
    let objData: any = JSON.parse(JSON.stringify(element.checkedstatus[element.checkedstatus.length - 1]));
    this.lastCheckDate = objData.last_check;
    this.timeStart = moment(objData.last_check).format('H:mm');

    this.endDate = objData.expected_end;
    this.timeEnd = moment(objData.expected_end).format('H:mm');
    this.status = objData.status;

    this.totalCost = this.countTotalCost(element)

    this.selectedMenuItem = 0;
  }

  //Truck note dialog
  openTruckNoteDialog(obj: any) {
    this.dialog.open(TruckNoteDialogComponent, {
      autoFocus: false,
      panelClass: 'truck-note-dialog-container',
      data: obj
    });
  }

  refreshNoteArray() {
    this.subscription3 = this.fleetService.truckNoteAddedSubject.subscribe((id: number) => {
      this.getNotes(id);
    });
  };

  getNotes(id: number) {
    this.subscription4 = this.fleetService.getAllTruckNotes(id).subscribe((response: any) => {
      console.log(response);
      this.noteArray = response;     
    })
  };

  countTotalCost(obj: any) {
    let total: number = 0;
    for(let key in obj.payments) {
      total += obj.payments[key].amount
    }
    return total;
  }

  //Open status settings
  openStatusSettings() {
    this.dialog.open(CreateUpdateStatusComponent, {
      autoFocus: false,
      panelClass: 'create-update-statuses-dialog-container',
      data: this.allStatusArray
    })
  };

  updateListOfStatuses() {
    this.subscription5 = this.fleetService.statusesSubject.subscribe((response: any) => {
      let tableArray: any[] = [...this.dataSource.data];

      this.allStatusArray = [...response];
      let allStatusesArray: any[] = response;

      tableArray.forEach((obj: any) => {

        allStatusesArray.forEach((element: any) => {

          if(!element.numOfStatus && !element.filteredData) {
            element.numOfStatus = 0;
            element.filteredData = [];
          }
          
          if(element.status === obj.status) {
            element.numOfStatus++;
            element.filteredData.push(obj);
          }

        });

      });
     
      allStatusesArray.unshift({id: null, status: 'All trucks', created_by: null, created_date: null, 
      edited_by: null, edited_date: null, numOfStatus: tableArray.length, filteredData: tableArray });
      console.log(allStatusesArray);
      this.statusArray = allStatusesArray;
    });
  };


  ngOnDestroy(): void {
    this.subscription1?.unsubscribe();
    this.subscription2?.unsubscribe();
    this.subscription3?.unsubscribe();
    this.subscription4?.unsubscribe();
    this.subscription5?.unsubscribe();
  };

}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
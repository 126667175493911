import { Component, OnDestroy, OnInit } from '@angular/core';
import { RulesService } from '@app/modules/shared/services/rules.service';
import { FleetHealthService } from '../../services/fleet-health.service';

@Component({
  selector: 'app-schedule-page',
  templateUrl: './schedule-page.component.html',
  styleUrls: ['./schedule-page.component.scss']
})
export class SchedulePageComponent implements OnInit, OnDestroy {
permissionsData: any = this.rulesService.UserData[22].data;

//Interval
interval: any;

constructor(private rulesService: RulesService, private fleetHealthService: FleetHealthService) {  }

ngOnInit(): void {
  this.interval = setInterval(() => {
    this.fleetHealthService.refreshDataSubject.next(false);
  }, 60000);
}

ngOnDestroy(): void {
  clearInterval(this.interval);
}

}

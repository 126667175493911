import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DateRange } from '@angular/material/datepicker';

@Component({
  selector: 'filter-calendar',
  templateUrl: './filter-calendar.component.html',
  styleUrls: ['./filter-calendar.component.scss']
})
export class FilterCalendarComponent {
  //Calendar
  @Input() selectedRangeDate: any;
  @Input() styleConfig: any = {
    'width': '200px',
    'background-color': '#fff',
    'color': '#1F1F1F',
    'box-shadow': '0px 1px 6.65px 0.35px rgba(13, 39, 80, 0.16)',
    'border-radius': '25px'
  };
  @Output() rangeValueChange = new EventEmitter<DateRange<Date>>();
  isOpenCalendar: boolean = false;

  //Filter date
  selectedChange(m: any) {
    if (!this.selectedRangeDate?.start || this.selectedRangeDate?.end) {
      this.selectedRangeDate = new DateRange<Date>(m, null);
    } 
    else {
      const start = this.selectedRangeDate.start;
      const end = m;
      if (end < start) {
        this.selectedRangeDate = new DateRange<Date>(end, start);
      } else {
        this.selectedRangeDate = new DateRange<Date>(start, end);
      }
      this.rangeValueChange.emit(this.selectedRangeDate);
    } 
  }
}

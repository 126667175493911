import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'warning'
})
export class WarningPipe implements PipeTransform {

  transform(array: any[], value: string, numOfWarnings: number | undefined, moreThanThree: boolean): any[] {
    if (!array) {
      return [];
    }

    return array.filter((obj: any) => {
      let key1: boolean = true;
      if(value === 'Active drivers') {
        key1 = obj.is_active === true;
      }   
      
      if(value === 'Inactive drivers') {
        key1 = obj.is_active === false;
      }

      if(typeof numOfWarnings=== 'number') {
        if(moreThanThree) {
          key1 = obj.warningsArray.length >= numOfWarnings;
        }
        else {
          key1 = obj.warningsArray.length === numOfWarnings;
        }
      }

      if(key1) { 
        return obj;
      }

    });
  }

}

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { DriversProfileService } from '@app/modules/drivers-profile-page/services/drivers-profile.service';
import { StatusDialogComponent } from '@app/modules/shared/components/status-dialog/status-dialog.component';
import { RulesService } from '@app/modules/shared/services/rules.service';
import { SharedService } from '@app/modules/shared/services/shared.service';
import { TransformService } from '@app/modules/shared/services/transform.service';
import moment = require('moment');
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription, catchError, throwError } from 'rxjs';

@Component({
  selector: 'drivers-statements-table',
  templateUrl: './drivers-statements-table.component.html',
  styleUrls: ['./drivers-statements-table.component.scss']
})
export class DriversStatementsTableComponent implements OnInit, OnDestroy {
  permissions: any = this.rulesService.UserData[14].data;
  @Input() data: any;

  //Statements data
  tableData: any[] = [];
  statusArray: any[] = [];
  avgFleet: any;

  //Driver id
  driverId: any;

  //Period
  isOpenPeriodMenu: boolean = false;
  periodArray: any[] = [
    {date: 'Show 4 weeks', 
    startDate: moment().subtract(5, "week").startOf('isoWeek').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
    numOfWeeks: 5,
    },
    {date: 'Show 5 weeks',
    startDate: moment().subtract(6, "week").startOf('isoWeek').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
    numOfWeeks: 6,
    },
    {date: 'Show 6 weeks',
    startDate: moment().subtract(7, "week").startOf('isoWeek').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
    numOfWeeks: 7,
    },
    {date: 'Show 7 weeks', 
    startDate: moment().subtract(8, "week").startOf('isoWeek').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
    numOfWeeks: 8,
    },
    {date: 'Show 8 weeks',
    startDate: moment().subtract(9, "week").startOf('isoWeek').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
    numOfWeeks: 9,
    },
    {date: 'Show 9 weeks',
    startDate: moment().subtract(10, "week").startOf('isoWeek').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
    numOfWeeks: 10,
    },
    {date: 'Show 10 weeks',
    startDate: moment().subtract(11, "week").startOf('isoWeek').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
    numOfWeeks: 11,
    },
    {date: 'Show all',
    startDate: '',
    endDate: moment().format('YYYY-MM-DD'),
    numOfWeeks: 0
    }
  ];
  periodValue: string = 'Show 4 weeks';
  showNumOfWeeks: number = 5;

  paySign: string[] = ['SOLO COMPANY', 'TEAM COMPANY'];

  dateObj: any = {
    startDate: moment().subtract(5, "week").startOf('isoWeek').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD')
  };

  //Statistics
  metaObj: any = {
    countObj: { 
      grossAvg: 0,
      driverGrossAvg:0,
      mileageAvg: 0,
      paidMilesAvg: 0,
      rateAvg: 0,
      weightAvg: 0,
      loadsAvg: 0,
      utilizationAvg: 0,
      hometimeAvg: 0,
      dealAvg: 0,
      paycheckAvg: 0
    }
  }

  isOpened: boolean = false;

  loaded: boolean = false;
  error: boolean = false;
  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";

  spinnerBgColor: string = this.transformService.spinnerBgColor;
  spinnerType: string = this.transformService.spinnerType;

  //Subscription
  subscription1: Subscription = new Subscription();
  subscription2: Subscription = new Subscription();

  constructor(public transformService: TransformService, 
    private dialog: MatDialog,
    private rulesService: RulesService,
    private driversProfileService: DriversProfileService,
    private sharedService: SharedService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.getTableData();
    this.dataChanged();
  }

  dataChanged() {
    this.subscription1 = this.sharedService.statusNoteChangedSubject.subscribe((response: boolean) => {
      if(response) {
        this.getTableData();
      }
    });
  }

  getTableData() {
    this.tableData = [];
    this.error = false;
    this.loaded = false;
    this.spinner.show('driver-statements');
    this.driverId =  this.route.snapshot.paramMap.get('id');
    this.subscription2 = this.driversProfileService.getActivityStatementsTableData(+this.driverId)
    .pipe(catchError((err: any) => {
      this.spinner.hide('driver-statements');
      this.loaded = true;
      this.error = true;
      return throwError(() => err);
    }))
    .subscribe((responseArrays: any[]) => {
      console.log(responseArrays);
      this.statusArray = responseArrays[1];
      let response = responseArrays[0];
      this.avgFleet = response;
      let array: any[] = JSON.parse(JSON.stringify(response.lista));
      if(array.length > 0) {
        this.addStatusesInArray(array, responseArrays[1]);
      }
      this.spinner.hide('driver-statements');
      this.loaded = true;
      console.log(this.tableData);
    })
  }

  addStatusesInArray(array: any[], driverStatusesArray: any[]) {
    let startDate = new Date(array[array.length - 1].mondey);
    let endDate = new Date(moment(array[0].mondey).endOf('isoWeek').format('YYYY-MM-DDT00:00:00'));
    const dates = [];
    while (startDate <= endDate) {
      let obj: any;
      let result = driverStatusesArray.some((currObj, arrIndex) => { 
        obj = currObj
      return currObj.status_date == moment(startDate).format('YYYY-MM-DDT00:00:00');
      })
      if(result) {
        obj.date = new Date(startDate);
        dates.unshift(obj);
      } else {
        dates.unshift({date: new Date(startDate), icon_name: null, status: null, notes: []});
      }
      startDate.setDate(startDate.getDate() + 1);
    }
  
    for(let i = 0; i < array.length; i++) {
      let initialObj: any = {date: '', icon_name: null, status: null, notes: [], week: ''};
      let obj: any = {mon: initialObj, tue: initialObj, wed: initialObj, thu: initialObj, fri: initialObj, sat: initialObj, sun: initialObj};
      for(let j = 0; j < dates.length; j++) {
        let isSameWeek: boolean = moment(array[i].mondey).isSame(dates[j].date, 'isoWeek');
        if(isSameWeek) {
          obj[`${moment(dates[j].date).format('ddd')}`.toLowerCase()] = dates[j];
        }
        else {
          continue;
        }
      }
      array[i].statusObj = obj;
    };
    this.tableData = array;
    console.log(this.tableData);
  };

  getStartEndWeek(date: string) {
    let value: string = '';
    let d1: any = moment().startOf('isoWeek').format('YYYY-MM-DD')
    let d2: any = moment(date).format('YYYY-MM-DD');
    if(d1 === d2) {
      value = 'Week in progress'
    }
    else {
      value = `${moment(date).startOf('isoWeek').format('MMM DD')}-${moment(date).endOf('isoWeek').format('MMM DD, yyyy')}`;
    }
    return value;
  }

  downloadStatement(obj: any) {
    obj.pdfLoading = true;
    this.sharedService.downloadStatementFile(this.driverId, obj.statement_no)
    .pipe(catchError((err: any) => {
      obj.pdfLoading = false;
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      obj.pdfLoading = false;
      let fileURL = window.URL.createObjectURL(response);
      let tab = window.open();
      if(this.permissions[5].sectionArray[0].allowed) {
        tab.location.href = fileURL;
      }
      else {
        tab.location.href = fileURL + '#toolbar=0';
      }
    }) 
  }

  //Select week
  selectWeek(obj: any) {
    if(obj.numOfWeeks === 0) {
      this.showNumOfWeeks = this.tableData.length;
      this.dateObj.startDate = this.tableData[this.tableData.length - 1].mondey;
    }
    else {
      this.showNumOfWeeks = obj.numOfWeeks;
      this.dateObj.startDate = obj.startDate;
      this.dateObj.endDate = obj.endDate;
    }
    this.periodValue = obj.date;
    this.isOpenPeriodMenu = false;
  }

  //Num of weeks
  numOfWeeks() {
    let value: string = '0 weeks';

    if(this.tableData.length === 1) {
      value = this.tableData.length - 1 + ' week';
    };

    if(this.tableData.length > 1) {
      value = this.tableData.length - 1 + ' weeks';
    };

    return value;
  }

  addStatus(element: any) {
    let startDate: string = moment(element.date).startOf('isoWeek').format('YYYY-MM-DDT00:00:00');
    let endDate: string = moment(element.date).endOf('isoWeek').format('YYYY-MM-DDT00:00:00');
    let statusesForSpecificPeriod: any[] = [];
    for(let i = 0; i < this.statusArray.length; i++) {
      if( this.statusArray[i].status_date >= startDate
      && this.statusArray[i].status_date <= endDate) {
        statusesForSpecificPeriod.push(this.statusArray[i]);
      }
    }; 
    let obj: any = {
      driverInfo: this.data,
      allStatusArray: statusesForSpecificPeriod,
      date: new Date(element.date),
      startDate: startDate,
      endDate: statusesForSpecificPeriod.length > 0 && new Date(statusesForSpecificPeriod[statusesForSpecificPeriod.length-1].status_date).getTime() > new Date(element.date).getTime() ? 
      statusesForSpecificPeriod[statusesForSpecificPeriod.length-1].status_date.split('T')[0] : moment(element.date).format('YYYY-MM-DD'),
    }
    this.dialog.open(StatusDialogComponent, {
      autoFocus: false,
      panelClass: 'status-dialog-container',
      data: obj
    });
  };

  ngOnDestroy(): void {
    this.subscription1?.unsubscribe();
    this.subscription2?.unsubscribe();
  };
  
}

import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import moment = require('moment');
import { Subscription, catchError, throwError } from 'rxjs';
import { CalendarDialogComponent } from '../calendar-dialog/calendar-dialog.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApexAxisChartSeries, ApexChart, ApexDataLabels, ApexGrid, ApexStroke, ApexXAxis, ApexYAxis, ChartComponent } from 'ng-apexcharts';
import { FormControl } from '@angular/forms';
import { TransformService } from '../../services/transform.service';
import { SharedService } from '../../services/shared.service';

export type ChartOptions = {
  series: ApexAxisChartSeries | any;
  chart: ApexChart | any;
  xaxis: ApexXAxis | any;
  yaxis: ApexYAxis | any;
  dataLabels: ApexDataLabels | any;
  grid: ApexGrid | any;
  stroke: ApexStroke | any;
  markers: any;
  colors: any;
  tooltip: any;
};

@Component({
  selector: 'app-empty-trucks-analysis-dialog',
  templateUrl: './empty-trucks-analysis-dialog.component.html',
  styleUrls: ['./empty-trucks-analysis-dialog.component.scss']
})

export class EmptyTrucksAnalysisDialogComponent implements OnInit, OnDestroy {
  //Graph 
  @ViewChild("chart") chart: ChartComponent | any;
  public chartOptions: Partial<ChartOptions> | any;
  selectedCards: any[] = [
    {selected: true}, {selected: false}, {selected: false}, {selected: false}, 
    {selected: false}, {selected: false}, {selected: false}, {selected: false}
  ];

  selectPeriod: FormControl | any;

  graphData: any;

  //Date
  dateObj: any = {
    startDate: this.transformService.convertDateToTimestamp(moment().startOf('month').format('ddd, DD/MM YYYY'), 
    '00:00:00'),
    endDate: this.transformService.convertDateToTimestamp(moment().format('ddd, DD/MM YYYY'), moment().format('HH:mm:ss')),
  };

  //Data
  cardsData: any;
  dataSource: any = [];
  sortedData: any[] = [];

  displayedColumns: string[] = ['position', 'unit_no', 'brand', 'year', 'mileage', 'days_empty'];

  //Filter data
  btnsArray: any[] = [
    {name: 'YARD', selected: true},
    {name: 'DEALERSHIP', selected: true},
    {name: 'RECOVERY', selected: true}
  ];

  //Spinner
  loaded: boolean = false;

  error: boolean = false;

  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";

  spinnerBgColor: string = this.transformService.spinnerBgColor;
  spinnerType: string = this.transformService.spinnerType;

  //Subscription
  subscription: Subscription | any;

  constructor(private dialog: MatDialog,
              public transformService: TransformService,
              private sharedService: SharedService,
              private spinner: NgxSpinnerService) {
                this.selectPeriod = new FormControl(['day']);
              }

  ngOnInit(): void {
    this.spinner.show('quick-analysis');
    this.getTableData();
  }

  getTableData() {
    let filterParams: string = '';
    let idsArray: any = JSON.parse(localStorage.getItem('selectedCompanies'));
    for(let key in idsArray) {
      filterParams +=	'&filters=' + idsArray[key];
    }

    this.subscription = this.sharedService.getQuickAnalysisTableData(this.dateObj.startDate, this.dateObj.endDate, filterParams)
    .pipe(catchError((err: any) => {
      this.spinner.hide('quick-analysis');
      this.loaded = true;
      this.error = true;
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      console.log(response);
      this.cardsData = response;
      this.graphData = this.transformService.quickAnalysisGraph(response.graph, this.selectPeriod.value[0])
      this.initGraph(this.graphData);
      this.dataSource = JSON.parse(JSON.stringify(response.list));
      this.filterData();
      this.spinner.hide('quick-analysis');
      this.loaded = true;
    });
  }


  filterData(obj?: any) {
    if(obj) {
      obj.selected = !obj.selected;
    }

    let data: any[] = JSON.parse(JSON.stringify(this.dataSource));
    let filteredArray: any[] = [];

    for(let key in data) {
      let pushData: boolean = false;
      let daysEmpty: number = 0;

      if(this.btnsArray[0].selected && data[key].yard !== 0) {
        pushData = true;
        daysEmpty += data[key].yard;
      };

      if(this.btnsArray[1].selected && data[key].dealership !== 0) {
        pushData = true;
        daysEmpty += data[key].dealership;
      };

      if(this.btnsArray[2].selected && data[key].recovery !== 0) {
        pushData = true;
        daysEmpty += data[key].recovery;
      };

      if(pushData) {
        data[key].days_empty = daysEmpty;
        filteredArray.push(data[key]);
      }

    };
    this.sortedData = filteredArray;
  };

  sortData(sort: Sort) {
    const data = this.sortedData.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }
    this.sortedData = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'unit_no':
          return compare(a.unit_no, b.unit_no, isAsc);
        case 'brand':
          return compare(a.brand, b.brand, isAsc);
        case 'year':
          return compare(a.year, b.year, isAsc);
        case 'mileage':
          return compare(a.mileage, b.mileage, isAsc);
        case 'days_empty':
          return compare(a.days_empty, b.days_empty, isAsc);
        default:
          return 0;
      }
    });
  }

  //Open calendar
  openDialog() {
    let dialogRef: any = this.dialog.open(CalendarDialogComponent, {
      width: '769px',
      height: '476px',
      autoFocus: false,
      data: {oneRange: true},
      panelClass: 'calendar-dialog-container'
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.dateObj.startDate = result.startDate;
        this.dateObj.endDate = result.endDate;
        this.cardsData = undefined;
        this.dataSource = [];
        this.sortedData = [];

        this.error = false;
        this.loaded = false;
  
        this.spinner.show('quick-analysis');
        this.getTableData();
      }
    });
  }

  //Graph methods
  initGraph(data: any) {
    let array: any[] = [
      {name: 'EMPTY TRUCKS', data: data.totalT1, selected: this.selectedCards[0].selected},
      {name: 'EMPTY TRUCKS %', data: data.totalT2, selected: this.selectedCards[1].selected},
      {name: 'TOTAL LOSS', data: data.totalT3, selected: this.selectedCards[2].selected},
      {name: 'NO DRIVER', data: data.totalT4, selected: this.selectedCards[3].selected},
      {name: 'DEALERSHIP', data: data.totalT5, selected: this.selectedCards[4].selected},
      {name: 'RECOVERY', data: data.totalT6, selected: this.selectedCards[5].selected},
      {name: 'AVG DAILY LOSS', data: data.totalT7, selected: this.selectedCards[6].selected},
      {name: 'WEEKLY LOSS', data: data.totalT8, selected: this.selectedCards[7].selected},
    ];
    let seriesArray: any[] = [];
    for(let key in array) {
      if(array[key].selected) {
        seriesArray.push(array[key]);
      }
    }
    this.chartOptions = {
      series: seriesArray,
      chart: {
        toolbar: {
          show: true,
          tools: {
            download: false
          }
        },
        height: 300,
        type: "line",
        zoom: {
          enabled: false
        },
        fontFamily: 'Poppins'
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: "straight",
        width: 4,
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"], 
          opacity: 0.5
        }
      },
      xaxis: {
        categories: data.categories
      },
      yaxis: {
        tooltip: {
          enabled: true
        },
        labels: {
          formatter: (num: number) => {
            let roundedNumber: any = Math.round(num);
            return roundedNumber.toLocaleString("en-US").toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          },
        },
      },
      markers: {
        size: 5
      },
      colors: ['#0030FF', '#FA9120', '#FA0000', '#008000', '#FF00FF', '#800000', '#FE7777', '#0303BB', '#008FA9', '#7550CB', '#6A6D6E'],
    };
  };

  onlyOneSelect(value: string) {
    this.selectPeriod = new FormControl([value]);
    this.initGraph(this.transformService.quickAnalysisGraph(this.cardsData.graph, this.selectPeriod.value[0]));
  };

  showInGraph(obj: any) {
    obj.selected = !obj.selected;
    this.initGraph(this.graphData);
  };

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  };

}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

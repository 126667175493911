import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Clipboard } from '@angular/cdk/clipboard';
import { TrucksMapService } from '@app/modules/trucks-map/services/trucks-map.service';
import { catchError, Subscription, throwError } from 'rxjs';
import { TransformService } from '@app/modules/shared/services/transform.service';
import { Router } from '@angular/router';
import { RulesService } from '@app/modules/shared/services/rules.service';
import { AssignTrailerComponent } from './assign-trailer/assign-trailer.component';
import { MsgForbbidenAccessComponent } from '@app/modules/shared/components/msg-forbbiden-access/msg-forbbiden-access.component';
import { SuccessDialogComponent } from '@app/modules/shared/components/success-dialog/success-dialog.component';
import { WarningMsgDialogComponent } from '@app/modules/shared/components/warning-msg-dialog/warning-msg-dialog.component';
import { SharedService } from '@app/modules/shared/services/shared.service';
import { AssignedConfirmationDialogComponent } from '@app/modules/shared/components/assigned-confirmation-dialog/assigned-confirmation-dialog.component';
import moment = require('moment');
import * as L from 'leaflet';
import { TitleCasePipe } from '@angular/common';
import { ConnectionPositionPair } from '@angular/cdk/overlay';

@Component({
  selector: 'app-truck-info-dialog',
  templateUrl: './truck-info-dialog.component.html',
  styleUrls: ['./truck-info-dialog.component.scss']
})
export class TruckInfoDialogComponent implements OnInit, OnDestroy {
  permissions: any = this.rulesService.UserData[14].data;
  infoData: any = {
    address: '',
    addressLink: '',
    driverID: 0,
    card_no: '',
    distances: [],
    driverName: '',
    eta: 0,
    fuel: 0,
    loads: [],
    minTillBreak: 0,
    minTillCycle: 0,
    minTillDrive: 0,
    minTillShift: 0,
    odometer: 0,
    plateNumber: '',
    speed: 0,
    trailerID: '', 
    truckID: '', 
    vin: '',
    puLat: 0,
    puLng: 0,
    deLat: 0,
    deLng: 0,
    currentLat: 0,
    currentLng: 0
  };
  currentLoad: any = {
    load_id: 0, 
    load_no: '', 
    pickup_city: '', 
    pickup_country: '', 
    picked_up: false, 
    delivery_city: '', 
    delivery_country: '', 
    delivered: false,
    delivered_at: null, 
    amount: 0, 
    mileage: 0, 
    rate_per_mile: 0, 
    pickup_date: null, 
    delivery_date: null
  };
  nextLoad: any = {
    load_id: 0, 
    load_no: '', 
    pickup_city: '', 
    pickup_country: '', 
    picked_up: false, 
    delivery_city: '', 
    delivery_country: '', 
    delivered: false,
    delivered_at: null, 
    amount: 0, 
    mileage: 0, 
    rate_per_mile: 0, 
    pickup_date: null, 
    delivery_date: null
  };

  currentDistance: number = 0;
  nextDistance: number = 0;

  currentETA: string =  '0s';

  currentAddress: string = '';

  //Map
  showMap: boolean = false;

  //Fuel Card
  isFuelCardActive: boolean = false;
  allowedStatusChange: boolean = true;

  //Driver info
  isOpened: boolean = false;

  positions = [
    new ConnectionPositionPair(
      {originX: 'end', originY: 'bottom'},
      {overlayX: 'start', overlayY: 'top'})
  ];

  //Subscription
  subscription1: Subscription = new Subscription();
  subscription2: Subscription = new Subscription();
  subscription3: Subscription = new Subscription();
  subscription4: Subscription = new Subscription();

  constructor(@Inject(MAT_DIALOG_DATA) public obj: any, 
              private rulesService: RulesService,
              private dialog: MatDialog,
              private trucksMapService: TrucksMapService, 
              private transformService: TransformService,
              private sharedService: SharedService,
              private clipboard: Clipboard,
              private titleCasePipe: TitleCasePipe,
              private router: Router) { }

  ngOnInit(): void {
    this.subscription1 = this.trucksMapService.getTruckTrailerInfo(this.obj.isTruck, this.obj.assetName)
    .subscribe((response: any) => {
      console.log(response);
      this.infoData = response;
      if(response.loads.length > 0) {
        this.currentLoad = response.loads[0].load;
      };
      if(response.loads.length > 1) {
        this.nextLoad = response.loads[1].load;
      };
      if(response.distances.length > 0) {
        this.currentDistance = response.distances[0];
      };
      if(response.distances.length > 1) {
        this.nextDistance = response.distances[1];
      };
      this.getCurrentAddress();
      this.getETA();
     // this.getFuelCardStatus(response.card_no);
    }); 
  };

  showHideMap() {
    this.showMap = !this.showMap;
    if(this.showMap) {
      this.initMap();
    }
    else {
      const divToRemove = document.getElementById('route-map-container');
      divToRemove.remove();
    }
  };

  private initMap(): void {
    const mainContainer = document.querySelector('.route-section');
    const mapContainer = document.createElement('div');
    mapContainer.id = 'route-map-container';
    mapContainer.style.width = '100%';
    mapContainer.style.height = 'calc(100% - 32.4px)'
    mapContainer.style.paddingTop = '15px';
    const map = document.createElement('div');
    map.id = 'route-map';
    map.style.width = '100%';
    map.style.height = '100%';
    map.style.borderRadius = '15px';
    mapContainer.appendChild(map);
    const driverInfoContainer = document.querySelector('.driver-info-container');
    mainContainer.insertBefore(mapContainer, driverInfoContainer.nextSibling);

    const routeMap = L.map('route-map').setView([37.920746, -91.020687], 5);
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 18,
      minZoom: 3,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    }).addTo(routeMap);

    let origin: any = {lat: this.infoData.puLat, lng: this.infoData.puLng};
    let destination: any = {lat: this.infoData.deLat, lng: this.infoData.deLng};
    let currentPosition: any = {lat: this.infoData.currentLat, lng: this.infoData.currentLng};

    const control: any = L.Routing.control({
      containerClassName: "leaflet-instructions",
      plan: L.Routing.plan([
        L.latLng(origin.lat, origin.lng),
        L.latLng(destination.lat, destination.lng),
        L.latLng(currentPosition.lat, currentPosition.lng)
      ], {
        createMarker: (i, wp, nWps) => {
          if (i === 0) {
            let myStartIcon = L.icon({
              iconUrl: './assets/img/location_start.png',
              iconSize: [34, 34],
              iconAnchor: [17, 34]
            });
            return L.marker(wp.latLng, {icon: myStartIcon });
          }
          if (i === 1) {                    
            let myEndIcon = L.icon({
              iconUrl: './assets/img/location_end.png',
              iconSize: [34, 34],
              iconAnchor: [17, 34]
            });
            return L.marker(wp.latLng, {icon: myEndIcon });
          }
          if (i === 2) {                    
            let currentTruckPosition = new L.DivIcon({
							className: 'my-div-icon',
							html: `<div style="width: 55px; background-color: #2196F3; color: #fff; text-align: center; padding: 3px; font-size: 14px; font-weight: bolder; border-radius: 10px; font-family: 'Poppins';">${ this.infoData.truckID }</div>
							<img style="width: 34px; height: 34px;" src="./assets/img/location-pin-truck.png"/>`,
              iconSize: [34, 34],
              iconAnchor: [17, 34]
						})
            return L.marker(wp.latLng, {icon: currentTruckPosition });
          }
        }
      }),
      routeWhileDragging: false,
      showAlternatives: false,
      lineOptions: {
        styles: [{color: '#022bb1', opacity: 1, weight: 5}], extendToWaypoints: false, missingRouteTolerance: 0
     }
    }).addTo(routeMap);
    routeMap.fitBounds([[origin.lat, origin.lng], [destination.lat, destination.lng]]);
    /*
      control.on('routesfound', function(e) {
        const routes = e.routes;
        const route = routes[0]; // Get the first route

        // Get the coordinates of the route
        const coordinates = route.coordinates.map(coord => [coord.lat, coord.lng]);

        console.log(coordinates)
      });
    */
  };

  timeConvert(n: number) {
    let num = n;
    let hours = (num / 60);
    let rhours = Math.floor(hours);
    let minutes = (hours - rhours) * 60;
    let rminutes = Math.round(minutes);
    return `${rhours < 10 ? '0' + rhours : rhours}:${rminutes < 10 ? '0' + rminutes : rminutes}`;
  }

  convertMetersToMilesPickup(element: any, distance: number) {
    if(!element.picked_up && !element.delivered && element.load_no) {
      return `${this.transformService.addCommasDots(distance * 0.000621371192)} mi\nfrom PU`;
    }
    return '';
  };

  convertMetersToMilesDelivery(element: any, distance: number) {
    if(element.picked_up && !element.delivered && element.load_no) {
      return `${this.transformService.addCommasDots(distance * 0.000621371192)} mi\nfrom DEL`;
    }
    return '';
  };

  getCurrentAddress() {
    this.subscription2 = this.sharedService.getCurrentAddress(this.infoData.currentLat, this.infoData.currentLng)
    .subscribe((response: any) => {
      this.currentAddress = response.display_name;
    });
  };

  getETA() {
    this.subscription3 = this.trucksMapService.getEta(this.infoData.truckID, this.currentLoad.load_no)
    .subscribe((seconds: number) => {
      this.currentETA = this.secondsToDhms(seconds);
    });
  };

  getFuelCardStatus(cardNo: string) {
    const fuelCardStatuses: any = {'ACTIVE': true, 'INACTIVE': false};
    this.subscription4 = this.trucksMapService.getFuelCardStatus(cardNo).subscribe((status: string) => {
      if(status === 'HOLD' || status === 'DELETED') {
        this.allowedStatusChange = false;
        return;
      }
      this.isFuelCardActive = fuelCardStatuses[status];
    });
  };

  secondsToDhms(seconds: number) {
    seconds = Number(seconds)
    let d = Math.floor(seconds / (3600 * 24))
    let h = Math.floor((seconds % (3600 * 24)) / 3600)
    let m = Math.floor((seconds % 3600) / 60)
    let s = Math.floor(seconds % 60)
    let eta: string = '';
    if(d > 0) {
      eta = `${d}d ${h}h ${m}m ${s}s`;
    }
    else if(h > 0) {
      eta = `${h}h ${m}m ${s}s`;
    }
    else if(m > 0) {
      eta = `${m}m ${s}s`;
    }
    else {
      eta = `${s}s`;
    };

    return eta;
  }

  predictedTime(date: string, element: any) {
    if(date && element.load_no) {
      let d1 = new Date().getTime();
      let d2 = new Date(date).getTime();

      let date1 = moment().format('YYYY-MM-DDTHH:mm:ss');
      let date2 = moment(date);
      let diffInMinutes = date2.diff(date1, 'minutes');
      if(d1 <= d2) {
        return this.timeConvert(diffInMinutes);
      }
      return moment(date).format('HH:mm');
    }
    return '';
  };
  
  checkDate(date: string) {
    let d1 = new Date().getTime();
    let d2 = new Date(date).getTime();
    if(d1 <= d2 || !date) {
      return false;
    }
    return true
  }

  copyAddress() {
    this.clipboard.copy(this.infoData.address);
  };

  openNewTab(url: string) {
    window.open(url, '_blank');
  };

  //Open in new tab
  openInNewTab(route: string) {
    const url = this.router.serializeUrl(this.router.createUrlTree([route]));
    window.open(url, '_blank');
  }  

  changeTrailer() {
    if(this.permissions[3].allowedAll) {
      let dialogRef = this.dialog.open(AssignTrailerComponent, {
        autoFocus: false,
        panelClass: 'component-dialog-container',
        data: {selectedValue: this.infoData.trailerID}
      });
      dialogRef.afterClosed().subscribe((obj: any) => {
        if(obj) {
          this.alreadyAssigned(obj);
        }
      });
    }
    else {
      this.showForbidenMessage();
    }
  };

  //Copy driver info
  copyDriverInfo(obj: any) {
    let content: string = `Driver:
${this.titleCasePipe.transform(`${obj.driverName}`)}
Truck: 
${obj.truckID}
VIN#:
${obj.vin}
Trailer:
${obj.trailerID}
Phone:
${obj.phone}`;
    this.clipboard.copy(content);
  };  

  alreadyAssigned(data: any) {
    this.sharedService.isTruckTrailerAssigned(data.trailerId, false)
    .pipe(catchError((err: any) => {
      this.showErrorMessage();
      return throwError(() => err);
    }))
    .subscribe((assigned: boolean) => {
      if(assigned) {
        let dialogRef: any = this.dialog.open(AssignedConfirmationDialogComponent, {
          autoFocus: false,
          panelClass: 'assigned-msg-dialog-container',
          data: false
        });
        dialogRef.afterClosed().subscribe((response: boolean) => {
          if(response) {
            this.assignTrailer(data.trailerId, this.infoData.truckID, data.trailerValue);
          } 
        });
      }
      else {
        this.assignTrailer(data.trailerId, this.infoData.truckID, data.trailerValue);
      }
    });
  };

  assignTrailer(trailerId: number, truckId: number, truckTrailerValue: string) {
    this.sharedService.assignTrailer(trailerId, truckId)
    .pipe(catchError((err: any) => {
      this.showErrorMessage();
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      if(response === 'OK') {
        this.infoData.trailerID = truckTrailerValue;
        this.showSuccessMessage(); 
      }
      else {
        this.showErrorMessage();
      }
    });
  };

  activateDeactivateFuelCard(status: boolean) {
    this.trucksMapService.changeFuelCardStatus(this.infoData.card_no, status)
    .pipe(catchError((err: any) => {
      this.showErrorMessage();
      return throwError(() => err);
    }))
    .subscribe((response: boolean) => {
      if(response) {
        this.isFuelCardActive = status;
      }
      else {
        this.showErrorMessage();
      }
    });
  };

  get convertKmToMiles(): string {
    return this.transformService.addCommasDots(this.infoData.odometer * 0.6213711922, 'round');
  };

  showSuccessMessage() {
    this.dialog.open(SuccessDialogComponent, {
      autoFocus: false,
      panelClass: 'success-dialog-container'
    });  
  };

  showErrorMessage() {
    this.dialog.open(WarningMsgDialogComponent, {
      autoFocus: false,
      panelClass: 'warning-msg-dialog-container'
    });
  };

  showForbidenMessage() {
    this.dialog.open(MsgForbbidenAccessComponent, {
      autoFocus: false,
      panelClass: 'forbidden-msg-dialog-container'
    })
  };

  ngOnDestroy(): void {
    this.subscription1?.unsubscribe(); 
    this.subscription2?.unsubscribe(); 
    this.subscription3?.unsubscribe(); 
    this.subscription4?.unsubscribe();
  };

}

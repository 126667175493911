import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import moment = require('moment');
import { CreateTruckThreadDialogComponent } from './create-truck-thread-dialog/create-truck-thread-dialog.component';

import { Subscription } from 'rxjs';
import { DeleteConfirmationDialogComponent } from '@app/modules/shared/components/delete-confirmation-dialog/delete-confirmation-dialog.component';
import { FleetHealthService } from '@app/modules/fleet-health/services/fleet-health.service';
import { TransformService } from '@app/modules/shared/services/transform.service';

@Component({
  selector: 'app-truck-note-dialog',
  templateUrl: './truck-note-dialog.component.html',
  styleUrls: ['./truck-note-dialog.component.scss']
})
export class TruckNoteDialogComponent implements OnInit, OnDestroy {
  photo: string | null = null;

  note: string = '';
  fileNames: any[] = [];
  files: any[] = [];

  noteArray: any[] = [];

  noteArrayCopy: any[] = [];

  selectedIndex: number = 0;
  isEdit: boolean = false;
  editedObj: any;

  filterValue: string = 'All';

  closeThreadValue: string = 'Open';

  subsctiption: Subscription | any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private dialog: MatDialog, 
              private fleetService: FleetHealthService,
              private transformService: TransformService) {}

  ngOnInit(): void {
    this.subsctiption = this.fleetService.getAllTruckNotes(this.data.id).subscribe((response: any) => {
      console.log(response);
      this.noteArray = response;
      this.noteArrayCopy = JSON.parse(JSON.stringify(response));        
    })
  }

  //Add note
  addEditNote() {
    let loggedUser: any = JSON.parse(localStorage.getItem('currentUser'));
    let obj: any = {  
      id: 0,
      note_id: this.noteArray[this.selectedIndex].id,
      mitf_id: this.data.id,
      note: this.note,
      note_date: this.transformService.convertDateToTimestamp(moment(new Date()).format('ddd, DD/MM YYYY'),  moment(new Date()).format('HH:mm:ss')),
      creator: loggedUser.first_name + ' ' + loggedUser.last_name,
      creator_department: loggedUser.department,
      editor: null,
      editor_department: null,
      edit_date: null,
      files: this.files
    }
    if(this.isEdit) {
      obj.id = this.editedObj.id;
      obj.note_date = this.editedObj.note_date;
      obj.editor = loggedUser.first_name + ' ' + loggedUser.last_name;
      obj.editor_department = loggedUser.department;
      obj.edit_date = this.transformService.convertDateToTimestamp(moment(new Date()).format('ddd, DD/MM YYYY'),  moment(new Date()).format('HH:mm:ss'));
    }
    console.log(obj)
    this.fleetService.addEditNote(obj).subscribe((response: any) => {
      console.log(response);
      if(this.isEdit) {
        this.note = '';
        this.fileNames = [];
        this.files = [];
        this.editedObj.editor = response.editor
        this.editedObj.mitf_id = this.data.id;
        this.editedObj.edit_date = response.edit_date;
        this.editedObj.note = response.note
        this.editedObj.files = response.files;
        this.editedObj.isEdit = false;
        this.isEdit = false;
      } 
      else {
        this.noteArray[this.selectedIndex].notes.push(response);
        this.note = '';
        this.fileNames = [];
        this.files = [];
      }
       this.fleetService.truckNoteAddedSubject.next(this.data.id);
    });
  };

  createNewThreadDialog() { 
    let dialogRef: any = this.dialog.open(CreateTruckThreadDialogComponent, {
      autoFocus: false,
      panelClass: 'truck-thread-dialog-container',
    })
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
        let loggedUser: any = JSON.parse(localStorage.getItem('currentUser'));
        let obj: any = {
          id: 0,
          mitf_id: this.data.id,
          subject: result.subject,
          create_date: this.transformService.convertDateToTimestamp(moment(new Date()).format('ddd, DD/MM YYYY'),  moment(new Date()).format('HH:mm:ss')),
          creator: loggedUser.first_name + ' ' + loggedUser.last_name,
          department: loggedUser.department,
          editor: null,
          editor_department: null,
          edit_date: null,
          closed: null,
          closed_by: null,
          closed_by_department: null,
          close_date: null,
          notes: null,
          files: null,
        }
        this.fleetService.createTruckNoteThread(obj).subscribe((response: any) => {
          console.log(response);
          this.noteArray.unshift(response);
          this.noteArrayCopy.unshift(response);
        })
      }
    });
  }

  //Edit note
  editNote(obj: any) {
    this.note = obj.note;
    this.editedObj = obj;
    this.isEdit = true;
    obj.isEdit = true;
    let files: any = JSON.parse(JSON.stringify(obj.files));
    this.fileNames = files;
    for(let key in files) {
      let data = {
        mitf_id: this.data.id,
        note_id: obj.note_id,
        filename: files[key].filename,
        ext: null,
        data: this.convertBase64ToByteArray(files[key].data)
      };
      this.files.push(data);
    }
  }

  convertBase64ToByteArray(base64: string) {
    var raw = window.atob(base64);
    var rawLength = raw.length;
    var array = new Uint8Array(new ArrayBuffer(rawLength));
    let result: number[] = [];
    for(let i = 0; i < rawLength; i++) {
      result.push(array[i]);
    }
    return result;
  }

  closeEditing(obj: any) {
    obj.isEdit = false;
    this.isEdit = false;
    this.note = '';
    this.fileNames = [];
    this.files = [];
  };

  //Delete all note

  discard(i : number) {
    this.fileNames.splice(i, 1);
    this.files.splice(i, 1);
  };


  deleteNote(obj: any, i: number) {
    this.fleetService.deleteTruckNote(obj.id).subscribe((response: any) => {
      if(response) {
        this.noteArray[this.selectedIndex].notes.splice(i, 1);
        this.noteArrayCopy[this.selectedIndex].notes.splice(i, 1);
        this.fleetService.truckNoteAddedSubject.next(this.data.id);
      }
    })
}

  deleteAllNote(id: number) {
    console.log(id)
    this.fleetService.deleteAllTruckNote(id).subscribe((response: any) => {
      console.log(response)
      if(response) {
        this.noteArray[this.selectedIndex].notes = [];
        this.noteArrayCopy[this.selectedIndex].notes = [];
        this.fleetService.truckNoteAddedSubject.next(this.data.id);
      }
    })
  };

  deleteThread(id: number) {
    let dialogRef: any = this.dialog.open(DeleteConfirmationDialogComponent, {
      autoFocus: false,
      panelClass: 'delete-dialog-container'
    })
    console.log(id);
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
        this.fleetService.deleteThread(id).subscribe((response: any) => {
          console.log(response);
          if(response) {
            this.noteArray.splice(this.selectedIndex, 1);
            this.noteArrayCopy.splice(this.selectedIndex, 1);
            this.fleetService.truckNoteAddedSubject.next(this.data.id);
          }
        })
      }
    });
  };

  onFileSelected(event: any) {  
      let length: any = Object.keys(event.target.files).length;
    for(let i = 0; i < length; i++) {
      this.addMultipleFiles(event, i);
    }
  }

  addMultipleFiles(event: any, index: number) {
    let fileName = event.target.files[index].name;
    this.fileNames.push({filename: fileName});
    let reader = new FileReader();
    reader.onload = (e: any) => {
    var uint8 = new Uint8Array(e.target.result);
    var result = [];
    for (var i = 0; i < uint8.length; i++) {
      result.push(uint8[i]);
    }
    let obj: any = {
      dispatcher_id: this.data.id,
      note_id: 0,
      filename: fileName,
      ext: null,
      data: result
    };
      this.files.push(obj);
    }
    reader.readAsArrayBuffer(event.target.files[index]);
  };


  filterMessages(value: string) {
    let data: any = JSON.parse(JSON.stringify(this.noteArrayCopy));
    this.filterValue = value;
    let array: any = [];
    if(value === 'Active') {
      for(let key in data) {
        if(!data[key].closed) {
          array.push(data[key])
        }
      }
    } else if (value === 'Closed') {
      for(let key in data) {
        if(data[key].closed) {
          array.push(data[key])
        }
      }
    } else {
      array = data;
    } 
    this.noteArray = array;
  }

  changeActiveThread(index: number) {
    this.selectedIndex = index;
  };

  //Close thread
  closeThread(value: string, data: any) {
    let loggedUser: any = JSON.parse(localStorage.getItem('currentUser'));
    console.log(data);
    this.closeThreadValue = value;
  
    let obj: any = {
      id: data.id,
      mitf_id: data.mitf_id,
      subject: data.subject,
      create_date: data.create_date,
      creator: data.creator,
      department: data.department,
      editor: null,
      editor_department: null,
      edit_date: null,
      closed: true,
      closed_by: loggedUser.first_name + ' ' + loggedUser.last_name,
      closed_by_department: loggedUser.department,
      close_date: this.transformService.convertDateToTimestamp(moment(new Date()).format('ddd, DD/MM YYYY'),  moment(new Date()).format('HH:mm:ss')),
      notes: data.notes,
      files: data.files,
    }

    this.fleetService.closeTruckThread(obj).subscribe((response: any) => {
      if(response) {
        data.closed = true;
        this.noteArrayCopy[this.selectedIndex].closed = true;
        this.fleetService.truckNoteAddedSubject.next(this.data.id);
      }
    })
  
  };

  ngOnDestroy(): void {
    this.subsctiption?.unsubscribe();
  };

}


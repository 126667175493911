import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoadAnalysisPageComponent } from './components/load-analysis-page.component';
import { AuthGuard } from '../core/services/auth/auth.guard';


const routes: Routes = [
	{
        path: 'dispatchers',
        children: [
            {
                path: 'load-analysis-page',
                canActivate: [AuthGuard],
                component: LoadAnalysisPageComponent,
                data: { roles: 46 }
            }
        ]
    
    }
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class LoadAnalysisRoutingModule { }

import { AfterContentChecked, ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import moment = require('moment');
import { SharedService } from '../../services/shared.service';
import { ConnectionPositionPair } from '@angular/cdk/overlay';
import { DeleteConfirmationDialogComponent } from '../delete-confirmation-dialog/delete-confirmation-dialog.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MsgForbbidenAccessComponent } from '../msg-forbbiden-access/msg-forbbiden-access.component';
import { DateRange } from '@angular/material/datepicker';
import { NoteHistoryDialogComponent } from './note-history-dialog/note-history-dialog.component';
import { MessageInfoDialogComponent } from '../message-info-dialog/message-info-dialog.component';

@Component({
  selector: 'app-status-dialog',
  templateUrl: './status-dialog.component.html',
  styleUrls: ['./status-dialog.component.scss']
})
export class StatusDialogComponent implements OnInit, AfterContentChecked {  
  loggedUser: any = JSON.parse(localStorage.getItem('currentUser'));
  fullName: string = `${this.loggedUser.first_name} ${this.loggedUser.last_name}`;
  nickname: string = `${this.loggedUser.nickname}`;
  creator: string = this.nickname.length === 0 ? this.fullName : this.nickname?.trim().split(' ')[0];

  formGroup: FormGroup;

  //Date
  dateObj: any = {
    startDate: moment().startOf('isoWeek').format('YYYY-MM-DD'),
    endDate: moment().endOf('isoWeek').format('YYYY-MM-DD'),
    currentDate:  moment().format('YYYY-MM-DD'),
  };

  //Date picker
  @Input() selectedRangeValue: DateRange<Date> | any;
  @Output() selectedRangeValueChange = new EventEmitter<DateRange<Date>>();
  isOpenCalendar: boolean = false;

  //Positions
  public positions = [
    new ConnectionPositionPair(
      {originX: 'end', originY: 'bottom'},
      {overlayX: 'end', overlayY: 'top'},
      0, 5
    )
  ];

  //Status positions
  public statusPositions = [
    new ConnectionPositionPair(
      {originX: 'end', originY: 'top'},
      {overlayX: 'start', overlayY: 'top'},
      5, 0
    )
  ];

  //Status list
  statusListArray: any[] = [
    {statusGroup: 'Hometime', statusList: [
      {id: 5, statusGroup: 'Hometime', status: 'Regular', icon: 'hometime-green.svg', bgColor: 'green-gradient'},
      {id: 4, statusGroup: 'Hometime', status: 'Bad practice', icon: 'hometime-red.svg', bgColor:'red'}
    ]}, 
    {statusGroup: 'OTR', statusList: [
      {id: 2, statusGroup: 'OTR', status: 'Pick Up', icon: 'truck.svg', bgColor: 'green-gradient'},
      {id: 22, statusGroup: 'OTR', status: 'Driving - In transit', icon: 'in-transit.png', bgColor: 'green-gradient'}, 
      {id: 21, statusGroup: 'OTR', status: 'Driving - Dispatch Swap', icon: 'dispatch-swap.svg', bgColor: 'green-gradient'},
      {id: 18, statusGroup: 'OTR', status: 'Doing recovery', icon: 'truck-yellow.svg', bgColor: 'orange'} 
    ]}, 
    {statusGroup: 'Repair', statusList: [
      {id: 6, statusGroup: 'Repair', status: 'Repair', icon: 'repair.svg', bgColor: 'orange'}, 
    ]},
    {statusGroup: 'Personal issues', statusList: [
      {id: 9, statusGroup: 'Personal issues', status: 'Health issue', icon: 'health-issue.svg', bgColor: 'orange'},
    ]},
    {statusGroup: 'Not covered', statusList: [
      {id: 3, statusGroup: 'Not covered', status: 'Dispatch fault', icon: 'dispatch-fault.svg', bgColor: 'red'}, 
      {id: 10, statusGroup: 'Not covered', status: "Driver can't be reached", icon: 'phone-red.svg', bgColor: 'red'},
      {id: 26, statusGroup: 'Not covered', status: 'Driver Rejected Loads', icon: 'steering-wheel-status.png', bgColor: 'red'},
      {id: 23, statusGroup: 'Not covered', status: 'Searching', icon: 'search.svg', bgColor: 'blue'}
    ]},
    {statusGroup: 'Hours of Service', statusList: [
      {id: 7, statusGroup: 'Hours of Service', status: 'Regular', icon: 'hos-yellow.svg', bgColor: 'orange'}, 
      {id: 8, statusGroup: 'Hours of Service', status: 'Bad practice', icon: 'hos-red.svg', bgColor: 'red'} 
    ]},
    {statusGroup: 'DOT / Police', statusList: [
      {id: 15, statusGroup: 'DOT / Police', status: 'DOT-Police', icon: 'dot-police.svg', bgColor: 'orange'}
    ]},
    {statusGroup: 'Accident', statusList: [
      {id: 12, statusGroup: 'Accident', status: 'Accident', icon: 'accident.svg', bgColor: 'red'}
    ]},
    {statusGroup: 'Hired', statusList: [
      {id: 0, statusGroup: 'Hired', status: 'Hired', icon: 'hired.svg', bgColor: 'green-gradient'}
    ]},
    {statusGroup: 'Terminated', statusList: [
      {id: 1, statusGroup: 'Terminated', status: 'Terminated', icon: 'terminated.svg', bgColor: 'black'}
    ]},
    {statusGroup: 'Load issue', statusList: [
      {id: 24, statusGroup: 'Load issue', status: 'Load Canceled', icon: 'box-yellow.svg', bgColor: 'orange'},
      {id: 25, statusGroup: 'Load issue', status: 'TONU', icon: 'box-red.svg', bgColor: 'red'},
      {id: 16, statusGroup: 'Load issue', status: 'PU Delayed', icon: 'box-yellow.svg', bgColor: 'orange'}, 
      {id: 17, statusGroup: 'Load issue', status: 'DEL Delayed', icon: 'box-yellow.svg', bgColor: 'orange'}, 
      {id: 19, statusGroup: 'Load issue', status: 'Load Damaged', icon: 'box-red.svg', bgColor: 'red'}, 
      {id: 20, statusGroup: 'Load issue', status: 'Dispatch fault', icon: 'dispatch-fault.svg', bgColor: 'red'}
    ]},
    {statusGroup: 'Vacation', statusList: [
      {id: 14, statusGroup: 'Vacation', status: 'Regular', icon: 'vacation.svg', bgColor: 'orange'}
    ]},
    {statusGroup: 'Court', statusList: [
      {id: 11, statusGroup: 'Court', status: 'Legal issue', icon: 'legal-issue.svg', bgColor: 'orange'}
    ]},
    {statusGroup: 'At yard', statusList: [
      {id: 13, statusGroup: 'At yard', status: 'At yard', icon: 'at-yard.svg', bgColor: 'orange'}
    ]}
  ];
  
  //Data
  searchValue: string = '';
  statusArray: any[] = [];
  metaObj: any = {
    selectedStatusObj: {
      id: 0,
      driver_id: null,
      driver_name: '',
      status_group_name: '',
      dispatcher_id: null,
      status: '',
      icon_id: null,
      icon_name: '',
      color: '',
      craetor: '',
      creator_id: null,
      creator_department: '',
      status_date: null,
      datecreated: null,
      editor: '',
      edit_date: null,
      terminated: false,
      className: '',
      no_className: '',
      driver_className: '',
      total_className: '',
      unit_no: '',
      files: [],
      notes: [],
      dispatcher: '',
      trailer: ''
    },
    transformedArray: []
  };

  editedNoteIndex: number | undefined = undefined;

  //Refresh data
  refreshData: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
              private sharedService: SharedService,
              private dialog: MatDialog,
              private cdref: ChangeDetectorRef,
              private formBuilder: FormBuilder, 
              public matDialogRef: MatDialogRef<StatusDialogComponent>) {
                this.formGroup = this.formBuilder.nonNullable.group({
                  'id': [0],
                  'status_id': [null],
                  'driver_id': [this.data.driverInfo.info.id],
                  'note': [''],
                  'note_date': [null],
                  'craetor': [this.creator],
                  'creator_id': [this.loggedUser.id],
                  'department': [this.loggedUser.department],
                  'editor': [''],
                  'edit_date': [null],
                  'is_deleted': [false],
                  'deleted_by': [false],
                  'deleted_by_id': [null],
                  'delete_date': [null],
                  'files': [[]]
                })
              }

  ngOnInit(): void {
    console.log(this.data);
    this.dateObj.startDate = this.data.startDate;
    this.dateObj.endDate = this.data.endDate;
    this.dateObj.curentDate = this.data.date;
    this.statusArray = this.data.allStatusArray;
    this.matDialogRef.backdropClick().subscribe(result => {
      if(this.refreshData) {
        this.sharedService.statusNoteChangedSubject.next(true);
        this.matDialogRef.close(true);
      }
    });
  }

  //Add files
  onFileSelected(event: any) {
    let length: any = Object.keys(event.target.files).length;
    for (let i = 0; i < length; i++) {
      this.addMultipleFiles(event, i);
    }
  }
  
  addMultipleFiles(event: any, index: number) {
    let fileName = event.target.files[index].name;
    let reader = new FileReader();
    reader.onload = (e: any) => {
      let base64 = e.target.result.split(',')[1];
      let obj: any = {
        driver_id: this.data.driverInfo.info.id,
        filename: fileName,
        data: base64
      };
      this.files.push(obj);
      this.formGroup.patchValue({'files': this.files});
    };
    reader.readAsDataURL(event.target.files[index]);
  };

  //Create update status
  createUpdateStatus(element: any, iconObj: any, index: number) {
    element.isOpenStatusMenu = !element.isOpenStatusMenu;
    let obj: any = JSON.parse(JSON.stringify(element));
    obj.status_group_name = iconObj.statusGroup;
    obj.status = iconObj.status;
    obj.icon_id = iconObj.id;
    obj.icon_name = iconObj.icon;
    obj.color = iconObj.bgColor;

    if(obj.id) {
      obj.editor = this.creator;
      obj.edit_date = moment().format('YYYY-MM-DDTHH:mm:ss');
    }
    else {
      obj.creator_id = this.loggedUser.id;
      obj.craetor = this.creator;
      obj.creator_department = this.loggedUser.department;
      obj.datecreated = moment().format('YYYY-MM-DDTHH:mm:ss');

      obj.pt_id = this.loggedUser.pt_id;
      obj.driver_id = this.data.driverInfo.info.id;
      obj.driver_name = this.data.driverInfo.info.name;
      obj.unit_no = this.data.driverInfo.info.truck_no;
      obj.trailer = this.data.driverInfo.info.trailer_no;
    }

    if(obj.driver_id === 0) {
      this.dialog.open(MessageInfoDialogComponent, {
        autoFocus: false,
        panelClass: 'msg-info-dialog-container'
      });
    }
    else {
      this.sharedService.setStatus(obj).subscribe((response: any) => {
        if(response?.id) {
          this.refreshData = true;
          this.metaObj.selectedStatusObj = response;
          this.metaObj.transformedArray[index] = response;
        };
      })
    }
  }

  //Delete status
  deleteStatus(obj: any, index: number) {
    obj.isOpenActionMenu = !obj.isOpenActionMenu;
    let dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
      autoFocus: false,
      panelClass: 'delete-dialog-container',
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
        this.sharedService.deleteStatus(obj.id, this.creator, this.loggedUser.id, moment().format('YYYY-MM-DDTHH:mm:ss'))
        .subscribe((response: any) => {
          if(response) {
            let objReplace ={
              craetor: null,
              creator_department: null,
              creator_id: 0,
              driver_id: null,
              driver_name: null,
              edit_date: null,
              editor: "",
              files: [],
              icon_id: null,
              icon_name: null,
              id: null,
              notes: [],
              status: 'No status',
              status_date: obj.status_date,
              status_group_name: 'No status',
              color: 'grey'
            }
            this.metaObj.selectedStatusObj.id = 0;
            this.metaObj.selectedStatusObj.driver_id = null;
            this.metaObj.selectedStatusObj.driver_name = '';
            this.metaObj.selectedStatusObj.status_group_name = '';
            this.metaObj.selectedStatusObj.dispatcher_id = null;
            this.metaObj.selectedStatusObj.status = '';
            this.metaObj.selectedStatusObj.icon_id = null;
            this.metaObj.selectedStatusObj.icon_name = '';
            this.metaObj.selectedStatusObj.color = '';
            this.metaObj.selectedStatusObj.craetor = '';
            this.metaObj.selectedStatusObj.creator_id = null;
            this.metaObj.selectedStatusObj.creator_department = '';
            this.metaObj.selectedStatusObj.status_date = null;
            this.metaObj.selectedStatusObj.datecreated = null;
            this.metaObj.selectedStatusObj.editor = '';
            this.metaObj.selectedStatusObj.edit_date = null;
            this.metaObj.selectedStatusObj.terminated = false;
            this.metaObj.selectedStatusObj.className = '';
            this.metaObj.selectedStatusObj.no_className = '';
            this.metaObj.selectedStatusObj.driver_className = '';
            this.metaObj.selectedStatusObj.total_className = '';
            this.metaObj.selectedStatusObj.unit_no = '';
            this.metaObj.selectedStatusObj.files = [];
            this.metaObj.selectedStatusObj.notes = [];
            this.metaObj.selectedStatusObj.dispatcher = '';
            this.metaObj.selectedStatusObj.trailer = '';
            this.metaObj.transformedArray[index] = objReplace;
            this.refreshData = true;
          }
        })
      }
    });
  };

  //Create update note
  createUpdateNote() {
    if(this.formGroup.value.id) {
      this.formGroup.patchValue({'editor': this.creator, 'edit_date': moment().format('YYYY-MM-DDTHH:mm:ss')});
    }
    else {
      this.formGroup.patchValue({'status_id': this.metaObj.selectedStatusObj.id, 'note_date': moment().format('YYYY-MM-DDTHH:mm:ss')});
    }
    console.log(this.formGroup.value)
    this.sharedService.createEditNote(this.formGroup.value).subscribe((response: any) => {
      console.log(response);
      if(response?.id && this.formGroup.value.id) {
        this.metaObj.selectedStatusObj.notes[this.editedNoteIndex] = response;
      }
      if(response?.id && !this.formGroup.value.id) {
        this.metaObj.selectedStatusObj.notes.unshift(response);
      }
      if(response?.id) {
        this.refreshData = true;
        this.formGroup.reset();
      }
    })
  };

  editNote(obj: any, index: number) {
    obj.isOpenNoteMenu = !obj.isOpenNoteMenu;
    this.editedNoteIndex = index;
    this.formGroup.setValue({
      'id':  obj.id,
      'status_id': obj,
      'driver_id': obj.driver_id,
      'note': obj.note,
      'note_date': obj.note_date,
      'craetor': obj.craetor,
      'creator_id': obj.creator_id,
      'department': obj.department,
      'editor': obj.editor,
      'edit_date': obj.edit_date,
      'is_deleted': obj.is_deleted,
      'deleted_by': obj.deleted_by,
      'deleted_by_id': obj.deleted_by_id,
      'delete_date': obj.delete_date,
      'files': obj.files
    });
  };

  //Delete note
  deleteNote(obj: any, index: number) {
    obj.isOpenNoteMenu = !obj.isOpenNoteMenu;
    if(this.loggedUser.id === obj.creator_id || this.loggedUser.userGroup === 'Admin') {
      let dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
        autoFocus: false,
        panelClass: 'delete-dialog-container',
      });
      dialogRef.afterClosed().subscribe((result: any) => {
        if(result) {
          this.sharedService.deleteNote(obj.id, this.creator, this.loggedUser.id, moment().format('YYYY-MM-DDTHH:mm:ss'))
          .subscribe((response: any) => {
            if(response) {
              this.metaObj.selectedStatusObj.notes.splice(index, 1);
              this.refreshData = true;
            }
          })
        }
      });
    }
    else {
      this.dialog.open(MsgForbbidenAccessComponent, {
        autoFocus: false,
        panelClass: 'forbidden-msg-dialog-container'
      })
    }

  };

  getFile(noteObj: any, fileObj: any, type: number, file: any, index: number) {
    file.isOpenFileMenu = !file.isOpenFileMenu;
    this.sharedService.getNoteFile(fileObj.driver_id, fileObj.note_id, fileObj.filename)
    .subscribe((response: any) => {
      if(type === 0) {
        this.openFileInNewTab(fileObj.filename, response.data)
      };
      if(type === 1) {
        this.downloadFile(fileObj.filename, response.data)
      };
      if(type === 2) {
        this.deleteFile(noteObj, index)
      };
    });
  };

  openFileInNewTab(name: string, data: any) {
    this.sharedService.downloadPreviewFile(name, data);
  };

  downloadFile(name: string, data: any) {
    let source: string = `data:application/octet-stream;base64,${data}`;
    const downloadLink = document.createElement('a');
    const fileName = name;
    downloadLink.href = source;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  deleteFile(noteObj: any, index: number) {
    let dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
      autoFocus: false,
      panelClass: 'delete-dialog-container',
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
        let obj: any = JSON.parse(JSON.stringify(noteObj));
        obj.editor = this.creator;
        obj.edit_date = moment().format('YYYY-MM-DDTHH:mm:ss');
        obj.files.splice(index, 1);
        this.sharedService.createEditNote(obj).subscribe((response: any) => {
          console.log(response);
          if(response) {
            noteObj.files = response.files;
          };
        });
      }
    })
  };

  selectedChange(m: any) {
    if (!this.selectedRangeValue?.start || this.selectedRangeValue?.end) {
      this.selectedRangeValue = new DateRange<Date>(m, null);
    } 
    else {
      const start = this.selectedRangeValue.start;
      const end = m;
      if (end < start) {
          this.selectedRangeValue = new DateRange<Date>(end, start);
      } else {
          this.selectedRangeValue = new DateRange<Date>(start, end);
      }
    }
    this.selectedRangeValueChange.emit(this.selectedRangeValue);
    if(this.selectedRangeValue.start && this.selectedRangeValue.end) {
      this.resetData();
      this.dateObj.startDate = moment(this.selectedRangeValue.start._d).format('YYYY-MM-DD');
      this.dateObj.endDate = moment(this.selectedRangeValue.end._d).format('YYYY-MM-DD');
      this.isOpenCalendar = !this.isOpenCalendar;
      this.sharedService.getDriverStatusesByDriver(this.data.driverInfo.info.id, this.dateObj.startDate, this.dateObj.endDate)
      .subscribe((response: any) => {
        this.statusArray = response;
      });
    }
  }

  resetData() {
    this.statusArray = [];
    this.metaObj = {
      selectedStatusObj: {
        id: 0,
        driver_id: null,
        driver_name: '',
        status_group_name: '',
        dispatcher_id: null,
        status: '',
        icon_id: null,
        icon_name: '',
        color: '',
        craetor: '',
        creator_id: null,
        creator_department: '',
        status_date: null,
        datecreated: null,
        editor: '',
        edit_date: null,
        terminated: false,
        className: '',
        no_className: '',
        driver_className: '',
        total_className: '',
        unit_no: '',
        files: [],
        notes: [],
        dispatcher: '',
        trailer: ''
      },
      transformedArray: []
    };
  }

  //Note history
  noteHistory() {
    if(this.metaObj.selectedStatusObj.id) {
      this.dialog.open(NoteHistoryDialogComponent, {
        autoFocus: false,
        panelClass: 'note-history-dialog-container',
        data: {statusId: this.metaObj.selectedStatusObj?.id, driverId: this.metaObj.selectedStatusObj?.driver_id}
      })
    }
  }

  //Form values
  get files(): any[] {
    return this.formGroup.controls['files'].value;
  };

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  };

}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IExtend } from '@app/modules/full-app/models/extend-model';
import { IFullAppFields } from '@app/modules/full-app/models/full-app-fields';
import { FullAppService } from '@app/modules/full-app/services/full-app.service';
import { TransformDataService } from '@app/modules/full-app/services/transform-data.service';
import { SharedService } from '@app/modules/shared/services/shared.service';
import { TransformService } from '@app/modules/shared/services/transform.service';
import moment = require('moment');
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription, catchError, throwError } from 'rxjs';
import { FullAppDocumentationDialogComponent } from '../../shared/components/full-app-documentation-dialog/full-app-documentation-dialog.component';
import { FullAppNoteDialogComponent } from '../../shared/components/full-app-note-dialog/full-app-note-dialog.component';
import { FullAppRejectionDialogComponent } from '../../shared/components/full-app-rejection-dialog/full-app-rejection-dialog.component';
import { ScheduleOrientationDateDialogComponent } from '../../shared/components/schedule-orientation-date-dialog/schedule-orientation-date-dialog.component';
import { TemplateDriverInputsComponent } from '../../shared/components/template-driver-inputs/template-driver-inputs.component';

@Component({
  selector: 'full-application-tab',
  templateUrl: './full-application-tab.component.html',
  styleUrls: ['./full-application-tab.component.scss']
})
export class FullApplicationTabComponent implements OnInit, OnDestroy {
  //Calendars
  isOpenFromCalendar: boolean = false;
  isOpenToCalendar: boolean = false;

  dateObj: any = {
    startDate: moment().startOf('month').format('YYYY-MM-DDT00:00:00'),
    endDate: moment().format('YYYY-MM-DDT23:59:59')
  };

  //Recruiter menu
  isOpenRecruiterMenu: boolean = false;
  recruiterValue: string = 'All';

  //Type menu
  isOpenTypeMenu: boolean = false;
  typeValue: string = 'All';
  typeKey: string = 'All';
  typeArray: any[] = [
    {name: 'Company', key: 'secondCheckbox'},
    {name: 'Rent to run', key: 'firstCheckbox'},
    {name: 'Owner', key: 'thirdCheckbox'},
    {name: 'All', key: 'All'}
  ];

  //Filled applications
  filledApplicationObj: any = {
    filledApplicationArray: [],
    recruiterArray: [],
    statusArray: ['No status', 'Prospecting', 'Proposal sent', 'Safety check', 'Approved', 'Scheduled', 'Not approved', 'Proposal declined', 'Schedule for Orientation']
  }
  selectedFilledApplicationObj: any;
  selectedDriverIndex: number = 0;

  searchFields: string = '';

  //Metadata
  filterMetaData: any = {
    countObj: {
      allAplicationsNum: 0,
      noStatusNum: 0,
      prospectingNum: 0,
      proposalSentNum: 0,
      safetyCheckNum: 0,
      approvedNum: 0,
      scheduledNum: 0,
      notApprovedNum: 0,
      proposalDeclinedNum: 0,
      allAplicationsPercent: 0,
      noStatusPercent: 0,
      prospectingPercent: 0,
      proposalSentPercent: 0,
      safetyCheckPercent: 0,
      approvedPercent: 0,
      scheduledPercent: 0,
      notApprovedPercent: 0,
      proposalDeclinedPercent: 0,
      array: [],
      selectedDriverObj: {}
    }
  };

  //Filter cards
  filtersArray: any[] = [
    {title: 'All applications', keyNum: 'allAplicationsNum', keyPercent: 'allAplicationsPercent'},
    {title: 'Not assigned', keyNum: 'notAssignedNum', keyPercent: 'notAssignedPercent'},
    {title: 'No status', keyNum: 'noStatusNum', keyPercent: 'noStatusPercent'},
    {title: 'Prospecting', keyNum: 'prospectingNum', keyPercent: 'prospectingPercent'},
    {title: 'Proposal sent', keyNum: 'proposalSentNum', keyPercent: 'proposalSentPercent'},
    {title: 'Safety check', keyNum: 'safetyCheckNum', keyPercent: 'safetyCheckPercent'},
    {title: 'Approved', keyNum: 'approvedNum', keyPercent: 'approvedPercent'},
    {title: 'Scheduled', keyNum: 'scheduledNum', keyPercent: 'scheduledPercent'},
    {title: 'Not approved', keyNum: 'notApprovedNum', keyPercent: 'notApprovedPercent'},
    {title: 'Proposal declined', keyNum: 'proposalDeclinedNum', keyPercent: 'proposalDeclinedPercent'}
  ];
  activeFilter: string = 'All applications';

  //Tabs
  tabsArray: any[] = [
    {name: 'Deal'},
    {name: 'Expenses'},
    {name: 'Special requirements'},
    {name: 'Paperwork'}
  ];
  activeTab: string = 'Deal';
  
  //Status menu
  isOpenStatusMenu: boolean = false;
  
  //Search drivers
  searchDrivers: string = '';
  
  //Inputs array
  inputsArray: IFullAppFields[] = [];

  loaded: boolean = false;
  error: boolean = false;
  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";
  spinnerBgColor: string = this.transformService.spinnerBgColor;
  spinnerType: string = this.transformService.spinnerType;

  //Subscription
  subscription1: Subscription = new Subscription();
  subscription2: Subscription = new Subscription();

  constructor(private transformData: TransformDataService,
              private fullAppService: FullAppService,
              private dialog: MatDialog, 
              public transformService: TransformService,
              private sharedService: SharedService,
              private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.getFilledApplications();
    this.getAllRecruiters();
  };

  //Tabs
  selectStatus(value: string) {
    let obj: IExtend = {
      extend_id: this.selectedFilledApplicationObj.Id,
      recruiter: this.selectedFilledApplicationObj.recruiter,
      truck_no: this.selectedFilledApplicationObj.truck_no,
      status: value,
      orientation_date: this.selectedFilledApplicationObj.orientation_date
    };

    if(value === 'Schedule for Orientation') {
      this.scheduleOrientationDate(obj);
    }
    else if(obj.status === 'Not approved') {
      this.changeStatus(obj, value);
    }
    else {
      this.changeStatus(obj, value);
    }
  }

  changeStatus(obj: IExtend, value: string) {
    this.fullAppService.changeStatusOrRecruiter(obj).subscribe((response: any) => {
      if(response) {
        this.selectedFilledApplicationObj.status = value;
        this.isOpenStatusMenu = !this.isOpenStatusMenu;
        if(value === 'Not approved') {
          this.openDialogBasedOnStatus();
        }
      } 
    });
  };

  openDialogBasedOnStatus() {
    if(this.selectedFilledApplicationObj.status === 'Not approved') {
      this.openRejectionDialog();
    }
    else {
      this.openDocumentationDialog();
    }
  };

  scheduleOrientationDate(obj: IExtend) {
    let dialogRef = this.dialog.open(ScheduleOrientationDateDialogComponent, {
      autoFocus: false,
      panelClass: 'component-dialog-container',
      data: {title: 'Schedule for Orientation', obj: obj}
    });
    dialogRef.afterClosed().subscribe((result: IExtend) => {
      if(result) {
        this.selectedFilledApplicationObj.status = result.status;
      }
    });
  };

  openRejectionDialog() {
    this.dialog.open(FullAppRejectionDialogComponent, {
      autoFocus: false,
      panelClass: 'rejection-dialog-container',
      disableClose: true,
      data: this.selectedFilledApplicationObj
    });
  };

  openDocumentationDialog() {
    this.dialog.open(FullAppDocumentationDialogComponent, {
      autoFocus: false,
      panelClass: 'documentation-dialog-container',
      disableClose: true,
      data: 0
    });
  };

  showNoteDialog() {
    this.dialog.open(FullAppNoteDialogComponent, {
      autoFocus: false,
      panelClass: 'note-dialog-container',
      position: {top: 'calc(50vh - 402.5px)', right: '0px'}, 
      data: this.selectedFilledApplicationObj.Id
    });
  };

  createInputs() {
    let dialogRef: any = this.dialog.open(TemplateDriverInputsComponent, {
      autoFocus: false,
      panelClass: 'template-drivers-dialog-container',
      disableClose: true,
      data:this.selectedFilledApplicationObj
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
        this.getFilledApplications();
      }
    });
  };

  dateClicked(event: any, fromCalendar: boolean) {
    if(fromCalendar) {
      this.dateObj.startDate = moment(event._d).format('YYYY-MM-DDT00:00:00');
      this.isOpenFromCalendar = false;
    }
    else {
      this.dateObj.endDate = moment(event._d).format('YYYY-MM-DDT23:59:59');
      this.isOpenToCalendar = false;
    }
  };

  run() {
    this.getFilledApplications();
  };

  getFilledApplications() {
    this.filledApplicationObj.filledApplicationArray = [];
    this.error = false;
    this.loaded = false;
    this.spinner.show('full-app-section');
    this.subscription1 = this.fullAppService.getFilledApplications(this.dateObj.startDate, this.dateObj.endDate)
    .pipe(catchError((err: any) => {
      this.spinner.hide('full-app-section');
      this.loaded = true;
      this.error = true;
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      console.log(response);
      this.filledApplicationObj.filledApplicationArray = response;
      if(response.length > 0) {
        this.fillInputsArrayWithData(response[0]);
      }
      this.spinner.hide('full-app-section');
      this.loaded = true;
    });
  };

  getAllRecruiters() {
    this.subscription2 = this.sharedService.getAllEmployees().subscribe((response: any) => {
      for(let i = 0; i < response.length; i++) {
        if(response[i].department === 'Recruiting') {
          this.filledApplicationObj.recruiterArray.push(response[i].nickname)
        }
      }
    });
  };

  driverChanged(dataObj: any) {
    this.selectedDriverIndex = dataObj.index;
    this.fillInputsArrayWithData(dataObj.obj);
  }

  getInputsArray(obj: any) {
    if(this.selectedFilledApplicationObj?.Id !== obj.Id) {
      this.inputsArray = this.transformData.getInputs(obj);
      this.selectedFilledApplicationObj = obj;
      console.log(obj);
    }
    return this.inputsArray;
  };

  fillInputsArrayWithData(obj: any) {
    let selectedobj: any = {...obj};
    selectedobj.DataJson = JSON.parse(selectedobj.DataJson);
    this.inputsArray = this.transformData.getInputs(selectedobj);
  };

  ngOnDestroy(): void {
    this.subscription1?.unsubscribe();   
    this.subscription2?.unsubscribe();    
  };

}



import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { InputsSettingsComponent } from './inputs-settings/inputs-settings.component';
import { Subscription } from 'rxjs';
import { FullAppService } from '@app/modules/full-app/services/full-app.service';
import { IFields } from '@app/modules/full-app/models/template-fields.model';
import { ITemplate } from '@app/modules/full-app/models/template-model';
import { DeleteConfirmationDialogComponent } from '@app/modules/shared/components/delete-confirmation-dialog/delete-confirmation-dialog.component';

@Component({
  selector: 'app-template',
  templateUrl: './template.component.html',
  styleUrls: ['./template.component.scss']
})
export class TemplateComponent implements OnInit, OnDestroy {
  //Template
  templateArray: ITemplate[] = [];
  templateObj: ITemplate = {
    id: 0,
    template_name: '',
    fileds: []
  };
  isOpenTemplateMenu: boolean = false;

  //Input Type menu
  isOpenTypeMenu: boolean = false;
  inputTypeValue: string = '';
  inputTypeArray: IFields[] | any = [];

  //Subscription 
  subscription1: Subscription = new Subscription();
  subscription2: Subscription = new Subscription();

  constructor(@Inject(MAT_DIALOG_DATA) public index: number,
              private fullAppService: FullAppService, 
              public dialogRef: MatDialogRef<TemplateComponent>,
              private dialog: MatDialog) {  }

  ngOnInit(): void {
    this.subscription1 = this.fullAppService.getAllTemplates().subscribe((response: ITemplate[]) => {
      console.log(response);
      this.templateArray = response;
      if(this.index !== -1) {
        this.templateObj = response[this.index];
      }
    });
    this.subscription2 = this.fullAppService.getAllInputs().subscribe((response: IFields[]) => {
      this.inputTypeArray = response;
      if(this.index !== -1) {
        this.selectCheckedFields();
      }
    })
  }

  //Settings
  inputsSettings() {
    this.isOpenTypeMenu = false;
    let dialogRef: any = this.dialog.open(InputsSettingsComponent, {
      autoFocus: false,
      panelClass: 'inputs-settings-dialog-container',
      disableClose: true
    });
    dialogRef.afterClosed().subscribe((array: IFields[]) => {
      this.inputTypeArray = array;
    });
  };

  //Select field
  selectField(element: IFields | any) {
    element.selected = !element.selected;
    let index: number = this.templateObj.fileds.findIndex(obj => obj.id === element.id);
    if(index === -1) {
      this.templateObj.fileds.push(element);
    }
    else {
      this.templateObj.fileds.splice(index, 1);
    }
  };

  //Delete field
  deleteField(element: any, i: number) {
    let index: number = this.templateObj.fileds.findIndex(obj => obj.id === element.id)
    this.inputTypeArray[index].selected = false;
    this.templateObj.fileds.splice(i, 1);
  }

  //Save template
  saveTemplate() {
    this.fullAppService.createUpdateTemplate(this.templateObj).subscribe((response: any) => {
      this.templateObj.id = response.id;
      if(response.id) {
        this.dialogRef.close(true);
      }
      console.log(response);
    })
  }

  //Duplicate template
  duplicateTemplate(obj: ITemplate) {
    this.resetTemplateObj();
    let fields: IFields[] = [...obj.fileds];
    this.isOpenTemplateMenu = false;
    this.templateObj.fileds = fields;
    this.selectCheckedFields();
  };

  //Edit template
  editTemplate(obj: ITemplate) {
    this.templateObj = {...obj};
  };

  selectCheckedFields() {
    for(let i = 0; i < this.templateObj.fileds.length; i++) {
      for(let j = 0; j < this.inputTypeArray.length; j++) {
        if(this.templateObj.fileds[i].id === this.inputTypeArray[j].id) {
          this.inputTypeArray[j].selected = true;
        }
       }
    }
  };
 
  //Delete template
  deleteTemplate(id: number, index: number) {
    let dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
      autoFocus: false,
      panelClass: 'delete-dialog-container',
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
        this.fullAppService.deleteTemplate(id).subscribe((response: boolean) => {
          if(response) {
            this.templateArray.splice(index, 1);
          };
          console.log(response);
        });
      }
    });
  };

  //New template
  newTemplate() {
    this.resetTemplateObj();
    for(let i = 0; i < this.inputTypeArray.length; i++) {
      this.inputTypeArray[i].selected = false;
    }
  };

  resetTemplateObj() {
    this.templateObj.id = 0;
    this.templateObj.template_name = '';
    this.templateObj.fileds = [];
  };

  ngOnDestroy(): void {
    this.subscription1?.unsubscribe();
    this.subscription2?.unsubscribe();
  };

}

import { Pipe, PipeTransform } from '@angular/core';
import moment = require('moment');

@Pipe({
  name: 'driverStatus'
})
export class DriverStatusPipe implements PipeTransform {

  transform(array: any[], dateObj: any, metaData: any): any[] {
    let date1 = new Date(dateObj.startDate);
    let date2 = new Date(dateObj.endDate);
    let Difference_In_Time = date2.getTime() - date1.getTime();
    const numOfIteration: number = Math.round(Difference_In_Time / (1000 * 3600 * 24)) + 1;
    let transformedArray: any[] = [];
    let statusIndex: number = 0;
    let selectedStatusObj: any = {};

    for(let i = 0; i < numOfIteration; i++) {
      let date: string = moment(dateObj.startDate).add(i, 'd').format('YYYY-MM-DDT00:00:00');
      let obj: any = {id: 0, driver_id: null, driver_name: '', status_group_name: 'No status', dispatcher_id: null, status: 'No status',
      icon_id: null, icon_name: '', color: 'grey', craetor: '', creator_id: 0, creator_department: '', status_date: date, datecreated: null,
      editor: '', edit_date: null, terminated: false, className: '', no_className: '', driver_className: '', total_className: '',
      unit_no: '', files: [], notes: [], dispatcher: '', trailer: ''};
      if(array[statusIndex]?.status_date === date) {
        obj = array[statusIndex];
        statusIndex++;
      }

      if(moment(dateObj.curentDate).format('YYYY-MM-DDT00:00:00') === date) {
        selectedStatusObj = obj;
      };

      transformedArray.unshift(obj);
    };
    metaData.selectedStatusObj = selectedStatusObj;
    metaData.transformedArray = transformedArray;
    return transformedArray;
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DispatchRewardSystemPageComponent } from './components/dispatch-reward-system-page/dispatch-reward-system-page.component';
import { DispatchRewardSystemRoutingModule } from './dispatch-reward-system.routing';
import { SharedModule } from '../shared/shared.module';
import { DispatchRewardSystemService } from './services/dispatch-reward-system.service';
import { DispatchRewardSystemCardsComponent } from './components/dispatch-reward-system-page/dispatch-reward-system-cards/dispatch-reward-system-cards.component';
import { FilterDropdownMultipleSelectComponent } from './components/dispatch-reward-system-page/dispatch-reward-system-cards/filter-dropdown-multiple-select/filter-dropdown-multiple-select.component';
import { FilterDropdownOneSelectComponent } from './components/dispatch-reward-system-page/dispatch-reward-system-cards/filter-dropdown-one-select/filter-dropdown-one-select.component';
import { SetTargetDialogComponent } from './components/dispatch-reward-system-page/dispatch-reward-system-cards/set-target-dialog/set-target-dialog.component';
import { TargetDotsMenuComponent } from './components/dispatch-reward-system-page/dispatch-reward-system-cards/set-target-dialog/target-dots-menu/target-dots-menu.component';
import { CurrentDatePipe } from './pipes/current-date.pipe';
import { DriversSearchPipe } from './pipes/drivers-search.pipe';
import { DriversResultPipe } from './pipes/drivers-result.pipe';
import { QuickStatsLinechartComponent } from './components/dispatch-reward-system-page/dispatch-reward-system-cards/quick-stats-linechart/quick-stats-linechart.component';

@NgModule({
  declarations: [
    DispatchRewardSystemPageComponent,
    DispatchRewardSystemCardsComponent,
    FilterDropdownMultipleSelectComponent,
    FilterDropdownOneSelectComponent,
    SetTargetDialogComponent,
    TargetDotsMenuComponent,
    CurrentDatePipe,
    DriversSearchPipe,
    DriversResultPipe,
    QuickStatsLinechartComponent
  ],
  imports: [
    CommonModule,
    DispatchRewardSystemRoutingModule,
    SharedModule
  ],
  providers: [DispatchRewardSystemService, DriversResultPipe]
})
export class DispatchRewardSystemModule { }

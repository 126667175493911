import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchMultiple'
})

export class SearchMultiplePipe implements PipeTransform {

  transform(array: any[], searchValuesArray: any[], keyArray: any[], objNumValue: any[], dateObj: any, metaData: any): any[] {
    if (!array) {
      return [];
    }

    //Statistics data
    let numOfAllLoads: number = 0;
    let sumAmount: number = 0;
    let sumPickUpAmount: number = 0;
    let sumMarginPercent: number = 0;
    let sumBillableMiles: number = 0;
    let sumPaidMiles: number = 0;
    let sumWeight: number = 0;

    //Obj to return
    let countObj: any = {
      numOfAllLoads: 0,
      amount: 0,
      pickUpStopAmount: 0,
      rate: 0,
      marginPercent: 0,
      billableMiles: 0,
      paidMiles: 0,
      companyBenefitMilesPercent: 0,
      weight: 0,
    }
 
    let transformedArray: any[] = array.filter((obj: any) => {
      let key1: boolean =  obj[keyArray[0]].toLocaleLowerCase().startsWith(searchValuesArray[0].toLocaleLowerCase());
      let key2: boolean =  obj[keyArray[1]].toLocaleLowerCase().startsWith(searchValuesArray[1].toLocaleLowerCase());
      let key3: boolean =  true;
      let key4: boolean =  true;
      let key5: boolean =  obj[keyArray[4]].toLocaleLowerCase().startsWith(searchValuesArray[4].toLocaleLowerCase());
      let key6: boolean =  obj[keyArray[5]].toLocaleLowerCase().startsWith(searchValuesArray[5].toLocaleLowerCase());
      let key7: boolean =  obj[keyArray[6]].toLocaleLowerCase().includes(searchValuesArray[6].toLocaleLowerCase());
      let key8: boolean =  obj[keyArray[7]].toLocaleLowerCase().startsWith(searchValuesArray[7].toLocaleLowerCase());
      let key9: boolean =  obj[keyArray[8]].toLocaleLowerCase().startsWith(searchValuesArray[8].toLocaleLowerCase());
      let key10: boolean = obj[keyArray[9]].toLocaleLowerCase().startsWith(searchValuesArray[9].toLocaleLowerCase());
      let key11: boolean = obj[keyArray[10]].toLocaleLowerCase().startsWith(searchValuesArray[10].toLocaleLowerCase());
      let key12: boolean = obj[keyArray[11]].toLocaleLowerCase().startsWith(searchValuesArray[11].toLocaleLowerCase());
      let key13: boolean = obj[keyArray[12]].toLocaleLowerCase().startsWith(searchValuesArray[12].toLocaleLowerCase());
      let key14: boolean = obj[keyArray[13]].toLocaleLowerCase().startsWith(searchValuesArray[13].toLocaleLowerCase());
      let key15: boolean = true;
      let key16: boolean = true;
      let key17: boolean = true;
      let key18: boolean = true;
      let key19: boolean = true;
      let key20: boolean = true;
      let key21: boolean = true;
      let key22: boolean = true;
      let key23: boolean = true;
      let key24: boolean = true;

      if(searchValuesArray[2] === 'Both') {
        key3  = true;
      }
      else if(searchValuesArray[2] === 'Checked') {
        key3 = obj[keyArray[2]] === true;
      }
      else {
        key3 = obj[keyArray[2]] === false;
      }

      
      if(searchValuesArray[3] === 'Both') {
        key4  = true;
      }
      else if(searchValuesArray[3] === 'Checked') {
        key4 = obj[keyArray[3]] === true;
      }
      else {
        key4 = obj[keyArray[3]] === false;
      }


      key15 = checkRadioBtns(0);
      key16 = checkRadioBtns(1);
      key17 = checkRadioBtns(2);
      key18 = checkRadioBtns(3);
      key19 = checkRadioBtns(4);
      key20 = checkRadioBtns(5);
      key21 = checkRadioBtns(6);
      key22 = checkRadioBtns(7);

      function checkRadioBtns(index: number) {
          if(objNumValue[index].operator === 'MT Amount') {
            let key: boolean | undefined = undefined;
            key = Number(obj.pickup_amount) > Number(obj.freight_amount);
            return key;
          }
          else if(objNumValue[index].searchValue) {
            let key: boolean | undefined = undefined;
            if(objNumValue[index].operator === 'More than') {
              key = Number(obj[objNumValue[index].key]) > Number(objNumValue[index].searchValue);
            }
            if(objNumValue[index].operator === 'Less than') {
              key = Number(obj[objNumValue[index].key]) < Number(objNumValue[index].searchValue);
            }
            if(objNumValue[index].operator === 'Equal') {
              key = Number(obj[objNumValue[index].key]) == Number(objNumValue[index].searchValue);
            }
            return key;
          }
          else {
            return true;
          }
      };

      if(dateObj.puObj) {
        const start = Date.parse(dateObj.puObj.start._d);
        const end = Date.parse(dateObj.puObj.end._d);
        const d = Date.parse(obj.pickup_date);

        key23 = d.valueOf() >= start.valueOf() && d.valueOf() <= end.valueOf();
      }

      if(dateObj.delObj) {

        const start = Date.parse(dateObj.delObj.start._d);
        const end = Date.parse(dateObj.delObj.end._d);
        const d = Date.parse(obj.delivery_date);

        key24 = d.valueOf() >= start.valueOf() && d.valueOf() <= end.valueOf();
      }

      if(key1 && key2 && key3 && key4 && key5 && key6 && key7 && key8 && key9 && key10 && key11 && key12 
        && key13 && key14 && key15 && key16 && key17 && key18 && key19 && key20 && key21 && key22 && key23 && key24) { 
        //Count statistics 
        sumAmount += obj.freight_amount;
        sumPickUpAmount += obj.pickup_amount;
        sumMarginPercent += obj.margin_percent;
        sumBillableMiles += obj.billable_miles;
        sumPaidMiles += obj.shortest_miles;
        sumWeight += obj.weight;
      
        return obj;
      }

    });

    //Set statistics data
    numOfAllLoads = transformedArray.length;

    countObj.numOfAllLoads = numOfAllLoads;
    countObj.amount = sumAmount;
    countObj.pickUpStopAmount = sumPickUpAmount;
    countObj.rate = sumAmount / sumBillableMiles; 
    countObj.marginPercent = sumMarginPercent / numOfAllLoads;
    countObj.billableMiles = sumBillableMiles;
    countObj.paidMiles = sumPaidMiles;
    countObj.companyBenefitMilesPercent = (sumBillableMiles - sumPaidMiles) * 100 / sumBillableMiles;
    countObj.weight = sumWeight / numOfAllLoads;
    countObj.array = transformedArray;

    metaData.countObj = countObj;
    return transformedArray;

  }

}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterStatuses'
})
export class FilterStatusesPipe implements PipeTransform {

  transform(arrayOfArrays: any[], filterArray: string[]): any[] {
    if (!arrayOfArrays) {
      return [];
    }

    let transformedArray: any[] = [];

    for(let i = 0; i < arrayOfArrays.length; i++) {
      let condition: boolean = false;
      let arrayToReturn: any[] = [];

      for(let j = 0; j < arrayOfArrays[i].length; j++) {
        if(filterArray.length === 0 ? true : (filterArray.includes(arrayOfArrays[i][j].status_group_name) || filterArray.includes(arrayOfArrays[i][j].icon_name ))) {
          condition = true;
          arrayToReturn.push(arrayOfArrays[i][j]);
        }
      };

      if(condition) {
        transformedArray.push(arrayToReturn);
      }

    }

    return transformedArray;
  }

}

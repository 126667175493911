import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { DateRange } from '@angular/material/datepicker';
import { Sort } from '@angular/material/sort';
import { IAccident } from '@app/modules/safety/models/accident.model';
import { AddEditAccidentsDialogComponent } from '../add-edit-accidents-dialog/add-edit-accidents-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import moment = require('moment');
import { Subscription, catchError, throwError } from 'rxjs';
import { TransformService } from '@app/modules/shared/services/transform.service';
import { AccidentsService } from '@app/modules/safety/services/accidents.service';
import { SharedService } from '@app/modules/shared/services/shared.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { RulesService } from '@app/modules/shared/services/rules.service';
import { MsgForbbidenAccessComponent } from '@app/modules/shared/components/msg-forbbiden-access/msg-forbbiden-access.component';

@Component({
  selector: 'accidents-table',
  templateUrl: './accidents-table.component.html',
  styleUrls: ['./accidents-table.component.scss']
})
export class AccidentsTableComponent implements OnInit, OnDestroy {
  permissions: any = this.rulesService.UserData[43].data;

  dateObj: any = {
    startDate: moment().startOf('month').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
  };
  
  loaded: boolean = false;
  error: boolean = false;
  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";

  spinnerBgColor: string = this.transformService.spinnerBgColor;
  spinnerType: string = this.transformService.spinnerType;

  //Subscription
  subscription: Subscription = new Subscription();
  
  dataSource: IAccident[] = [];

  //Filters
  driverSearch: string = '';
  loadNumberSearch: string = '';
  truckSearch: string = '';
  equipmentSearch: string = '';
  claimSearch: string = '';
  citySearch: string = '';
  stateSearch: string = '';
  zipSearch: string = '';
  caseSearch: string = '';
  citationSearch: string = '';

  //Checked
  radioBtns: any[] = [
    {name: 'Checked'},
    {name: 'Unchecked'},
    {name: 'Both'},
  ];

  radioFaultBtns: any[] = [
    {name: 'Our driver'},
    {name: '3rd Party'},
    {name: 'Both'},
  ];

  //Driver fault
  isOpenFaultMenu: boolean = false;
  radioFaultValue: string = 'Both';

  //Dot
  isOpenDotMenu: boolean = false;
  radioDotValue: string = 'Both';

  //Hazmat
  isOpenHazmatMenu: boolean = false;
  radioHazmatValue: string = 'Both';

  //Closed
  isOpenClosedMenu: boolean = false;
  radioClosedValue: string = 'Both';

  //Date occured
  dateOccuredRangeValue: DateRange<Date> | undefined;
  @Output() dateOccuredRangeValueChange = new EventEmitter<DateRange<Date>>();
  isOpenDateOccuredCalendar: boolean = false;
  dateOccuredObj: any;

  //Drug test date
  dateDrugRangeValue: DateRange<Date> | undefined;
  @Output() dateDrugRangeValueChange = new EventEmitter<DateRange<Date>>();
  isOpenDateDrugCalendar: boolean = false;
  dateDrugObj: any; 

  //Alcohol test date
  dateAlcoholRangeValue: DateRange<Date> | undefined;
  @Output() dateAlcoholRangeValueChange = new EventEmitter<DateRange<Date>>();
  isOpenDateAlcoholCalendar: boolean = false;
  dateAlcoholObj: any; 

  constructor(private dialog: MatDialog,
    private accidentsService: AccidentsService,
    public transformService: TransformService, 
    private spinner: NgxSpinnerService,
    private rulesService: RulesService
  ) { }

  ngOnInit(): void {
    this.getTableData();
  }

  getTableData() {
    this.dataSource = [];
    this.error = false;
    this.loaded = false;
    this.spinner.show('accidents');
    this.subscription = this.accidentsService.getAccidentsByDate(this.dateObj.startDate, this.dateObj.endDate)
    .pipe(catchError((err: any) => {
      this.spinner.hide('accidents');
      this.loaded = true;
      this.error = true;
      return throwError(() => err);
    }))
    .subscribe((response: IAccident[]) => {
      this.dataSource = response;
      this.spinner.hide('accidents');
      this.loaded = true;
      console.log(response);
    });
  };

  getDate(result: any) {
    this.dateObj.startDate = result.startDate;
    this.dateObj.endDate = result.endDate;
    this.getTableData();
  };

  sortData(sort: Sort) {
    const data = JSON.parse(JSON.stringify(this.dataSource));
    if (!sort.active || sort.direction === '') {
      this.dataSource = data;
      return;
    }
    this.dataSource = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'driver':
          return compare(a.driver, b.driver, isAsc);
        case 'load_no':
          return compare(a.load_no, b.load_no, isAsc);
        case 'unit_no':
          return compare(a.unit_no, b.unit_no, isAsc);
        case 'equipment_no':
          return compare(a.equipment_no, b.equipment_no, isAsc);
        case 'date_occured':
          return compare(new Date(a.date_occured), new Date(b.date_occured), isAsc);
        case 'fault_for_incident':
          return compare(a.fault_for_incident, b.fault_for_incident, isAsc);
        case 'claim_number':
          return compare(a.claim_number, b.claim_number, isAsc);
        case 'location_city':
          return compare(a.location_city, b.location_city, isAsc);
        case 'location_state':
          return compare(a.location_state, b.location_state, isAsc);
        case 'location_zip':
          return compare(a.location_zip, b.location_zip, isAsc);
        case 'case_number':
          return compare(a.case_number, b.case_number, isAsc);
        case 'citation_number':
          return compare(a.citation_number, b.citation_number, isAsc);
        case 'drug_test_date':
          return compare(new Date(a.drug_test_date), new Date(b.drug_test_date), isAsc);
        case 'alcohol_test_date':
          return compare(new Date(a.alcohol_test_date), new Date(b.alcohol_test_date), isAsc);
        case 'is_dot_recordable':
          return compare(a.is_dot_recordable, b.is_dot_recordable, isAsc);
        case 'is_hazmat':
          return compare(a.is_hazmat, b.is_hazmat, isAsc);
        case 'is_closed':
          return compare(a.is_closed, b.is_closed, isAsc);
        default:
          return 0;
      }
    });
  }

  selectedChange(m: any, type: number) {
    if(type === 1) {
      if (!this.dateOccuredRangeValue?.start || this.dateOccuredRangeValue?.end) {
        this.dateOccuredRangeValue = new DateRange<Date>(m, null);
      } 
      else {
        const start = this.dateOccuredRangeValue.start;
        const end = m;
        if (end < start) {
            this.dateOccuredRangeValue = new DateRange<Date>(end, start);
        } else {
            this.dateOccuredRangeValue = new DateRange<Date>(start, end);
        }
        this.dateOccuredObj = this.dateOccuredRangeValue;
      } 
      this.dateOccuredRangeValueChange.emit(this.dateOccuredRangeValue);
    }
    if(type === 2) {
      if (!this.dateDrugRangeValue?.start || this.dateDrugRangeValue?.end) {
        this.dateDrugRangeValue = new DateRange<Date>(m, null);
      } 
      else {
        const start = this.dateDrugRangeValue.start;
        const end = m;
        if (end < start) {
            this.dateDrugRangeValue = new DateRange<Date>(end, start);
        } else {
            this.dateDrugRangeValue = new DateRange<Date>(start, end);
        }
        this.dateDrugObj = this.dateDrugRangeValue;
      } 
      this.dateDrugRangeValueChange.emit(this.dateDrugRangeValue);
    }
    if(type === 3) {
      if (!this.dateAlcoholRangeValue?.start || this.dateAlcoholRangeValue?.end) {
        this.dateAlcoholRangeValue = new DateRange<Date>(m, null);
      } 
      else {
        const start = this.dateAlcoholRangeValue.start;
        const end = m;
        if (end < start) {
            this.dateAlcoholRangeValue = new DateRange<Date>(end, start);
        } else {
            this.dateAlcoholRangeValue = new DateRange<Date>(start, end);
        }
        this.dateAlcoholObj = this.dateAlcoholRangeValue;
      } 
      this.dateAlcoholRangeValueChange.emit(this.dateAlcoholRangeValue);
    }
  }

  addEditAccident(isEdit: boolean, id: number | null) {
    if(this.permissions[0].sectionArray[isEdit ? 1 : 0].allowed) {
      let dialogRef = this.dialog.open(AddEditAccidentsDialogComponent, {
        autoFocus: false,
        disableClose: true,
        panelClass: 'add-edit-dialog-container',
        data: {editMode: isEdit, id: id}
      });
      dialogRef.afterClosed().subscribe((result: any) => {
        if(result) {
        this.getTableData();
        }
      });
    }
    else {
      this.dialog.open(MsgForbbidenAccessComponent, {
        autoFocus: false,
        panelClass: 'forbidden-msg-dialog-container'
      })
    }
  }

  clearFilters() {
    this.driverSearch = '';
    this.loadNumberSearch = '';
    this.truckSearch = '';
    this.equipmentSearch = '';
    this.claimSearch = '';
    this.citySearch = '';
    this.stateSearch = '';
    this.zipSearch = '';
    this.caseSearch = '';
    this.citationSearch = '';
    this.radioFaultValue = 'Both'
    this.radioDotValue = 'Both';
    this.radioHazmatValue = 'Both';
    this.radioClosedValue = 'Both';
    this.dateOccuredRangeValue = undefined;
    this.dateOccuredObj = undefined;
    this.dateDrugRangeValue = undefined;
    this.dateDrugObj = undefined;
    this.dateAlcoholRangeValue = undefined;
    this.dateAlcoholObj = undefined;
  };

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();   
  };

}

function compare(a: number | any, b: number | any, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'documentStatus'
})
export class DocumentStatusPipe implements PipeTransform {

  transform(array: any[], metaObj: any): any {
    if (!array) {
      return [];
    }

    let approvedStatus: number = 0;
    let rejectedStatus: number = 0;
    let noStatus: number = 0;
    let statusName: string = '';
    let numOfPages: number = array.length;
    for(let i = 0 ; i < array.length; i++) {

      if(array[i].status === 1) {
        approvedStatus++;
      };
      if(array[i].status === 2) {
        rejectedStatus++;
      }
      if(array[i].status === 3) {
        noStatus++;
      }
    }

    if(approvedStatus === numOfPages) {
      statusName = 'Approved';
      metaObj.badgeColor.color = 'green-color';
    };
    
    if(approvedStatus === numOfPages) {
      statusName = 'Rejected';
      metaObj.badgeColor.color = 'red-color';
    };

    if(approvedStatus === numOfPages) {
      statusName = 'No status';
      metaObj.badgeColor.color = 'gray-color';
    };

    return statusName;
  }

}

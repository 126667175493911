import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';

import { ApexAxisChartSeries, ApexChart, ApexDataLabels, ApexFill, ApexGrid, ApexLegend, ApexResponsive, ApexStroke, ApexXAxis, ChartComponent } from 'ng-apexcharts';
import { Subscription } from 'rxjs';
import { RecruitingService } from '../../services/recruiting.service';

export type ChartOptions = {
  series: ApexAxisChartSeries | any;
  chart: ApexChart | any;
  xaxis: ApexXAxis | any;
  dataLabels: ApexDataLabels | any;
  grid: ApexGrid | any;
  stroke: ApexStroke | any;
};

export type ChartOptions2 = {
  series: ApexAxisChartSeries | any;
  chart: ApexChart | any;
  responsive: ApexResponsive | any;
  labels: string | any;
  colors: string | any;
  legend: ApexLegend | any;
  fill: ApexFill | any;
};
@Component({
  selector: 'app-average-paycheck',
  templateUrl: './average-paycheck.component.html',
  styleUrls: ['./average-paycheck.component.scss']
})
export class AveragePaycheckComponent implements OnInit {
  @ViewChild("chart") chart: ChartComponent | any;
  public chartOptions: Partial<ChartOptions> | any;
  public chartOptions2: Partial<ChartOptions> | any;
  selectPeriod: FormControl | any;
  subscription1: Subscription | any;
  show: string = 'line';
  @Input() date: any;

  objOfArrays: any;

  graphSelected: FormControl;

  barChartData: any;

  barChartOptions: any;

  dataForGraph: any;


  constructor(private dataService: RecruitingService) {
    this.selectPeriod = new FormControl(['month']);
    this.graphSelected = new FormControl(['line']);
    this.barChartOptions = {
      responsive: true,
      scales: {
        x: {},
        y: {}
      },
      plugins: {
        legend: {
          display: true,
        },
        datalabels: {
          anchor: 'end',
          align: 'end'
        }
      }
    }
}

  ngOnInit(): void {
    this.getData();
  }

  getData() {
    this.subscription1 = this.dataService.averagePaychecksData.subscribe(
      (response: any) => {
        console.log(response)
        let data = response.data;
        this.dataForGraph = data;
        let lengthNo: number = 0;
        let series: any[] = [];
        let labels: any[] = [];
        for (let key in data) {
          let num = 0;
          labels.push(key);
          for (let key2 in data[key]) {
            if (response.data[key][key2].total !== 'X') {
              num += +data[key][key2].total;
              lengthNo++;
            }
          }
          series.push((num / lengthNo).toFixed(2));
        }
        this.objOfArrays = { labels: labels, series: series };
        this.initCertainGraph(this.show);
        console.log(this.objOfArrays)
        console.log(response.data)
      });
  };

  //Init certain graph

  initCertainGraph(type: string) {
    if (type === 'bar') {
      this.initBarChartData(this.objOfArrays);
    };

    if (type === 'area' || type === 'line') {
      this.initGraphAreaLine(this.objOfArrays, type);
    };

    if (type === 'pie') {
      this.initPieChart(this.objOfArrays);
    };
  }

  //Graph bar

  initBarChartData(objOfArrays: any) {
    this.barChartData = {
      labels: objOfArrays.labels,
      datasets: [
        { data: objOfArrays.series, label: 'AVERAGE PAYCHECK', backgroundColor: '#100ca1', borderColor: '#0250cc' },
      ]
    };
  };

  //Graph line area

  initGraphAreaLine(objOfArrays: any, type: string) {
    this.chartOptions = {
      series: [
        {
          name: 'AVERAGE PAYCHECK',
          data: objOfArrays.series
        }
      ],
      chart: {
        height: 350,
        type: type,
        zoom: {
          enabled: false
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: "smooth"
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5
        }
      },
      xaxis: {
        categories: objOfArrays.labels
      }
    }
  };

  initPieChart(objOfArrays: any) {
    let sumAverage = 0;

    objOfArrays.series.forEach((hired: number) => {
      sumAverage += hired;
    });

    this.chartOptions2 = {
      fill: {
        type: 'gradient'
      },
      colors: ['#100ca1', '#868686', '#f4661c'],
      series: [sumAverage],
      chart: {
        width: 360,
        type: "pie",
      },
      labels: ['AVERAGE PAYCHECK'],
      legend: {
        position: 'bottom'
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: "bottom"
            }
          }
        }
      ]
    };
  }
  /*
  //Period methods

  onlyOneSelectPeriod(value: string) {
      this.selectPeriod = new FormControl([value]);
  };

  onSubmitPeriod(matSelect: MatSelect) {
    matSelect.close();
    //let data = this.countDataByPeriod(this.objOfArrays, this.selectPeriod.value[0]);
    console.log(this.dataForGraph);
    //this.initGraph(data);
  };
  */

  //Graph methods

  onlyOneSelect(value: string) {
    this.graphSelected = new FormControl([value]);
  };

  onSubmit(matSelect: MatSelect) {
    matSelect.close();
    this.show = matSelect.value[0];
    this.initCertainGraph(matSelect.value[0])
  };

  ngOnDestroy(): void {
    this.subscription1?.unsubscribe();
  }
}

import { Component, Input, OnChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { StatusDialogComponent } from '@app/modules/shared/components/status-dialog/status-dialog.component';
import { TransformService } from '@app/modules/shared/services/transform.service';
import moment = require('moment');
import { DispatchersLiveBoardService } from '../../services/dispatchers-live-board.service';
import * as XLSX from 'xlsx';
import { TitleCasePipe } from '@angular/common';
import { FilterStatusesPipe } from '../../pipes/filter-statuses.pipe';
import { RulesService } from '@app/modules/shared/services/rules.service';
import { MsgForbbidenAccessComponent } from '@app/modules/shared/components/msg-forbbiden-access/msg-forbbiden-access.component';

@Component({
  selector: 'app-dispatch-activity-table',
  templateUrl: './dispatch-activity-table.component.html',
  styleUrls: ['./dispatch-activity-table.component.scss']
})
export class DispatchActivityTableComponent implements OnChanges {
  @Input() tableData: any;
  @Input() date: string = '';
  @ViewChild(MatSort) sort: MatSort;

  cardsStObj: any = {
    searching: 0,
    covered: 0,
    hometime: 0,
    notCovered: 0,
    repair: 0,
    loadIssue: 0,
    driverIssue: 0,
    noStatus: 0,
    utilization: 0
  };
  filterArray: string[] = [];

  mergedArrays: any;

  dateLastTwoWeeks: any = {
    startDate: moment().subtract(2, 'week').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD')
  }

  isOpened: boolean = false;

  //Excel config
  xlsxConfig: any = [
    {columnName: 'Dispatcher', selected: true},
    {columnName: 'Truck - 1', selected: true},
    {columnName: 'Truck - 2', selected: true},
    {columnName: 'Truck - 3', selected: true},
    {columnName: 'Truck - 4', selected: true},
    {columnName: 'Truck - 5', selected: true},
    {columnName: 'Truck - 6', selected: true},
    {columnName: 'Truck - 7', selected: true},
    {columnName: 'Truck - 8', selected: true},
    {columnName: 'Truck - 9', selected: true},
    {columnName: 'Truck - 10', selected: true},
    {columnName: 'Truck - 11', selected: true},
    {columnName: 'Truck - 12', selected: true},
    {columnName: 'Truck - 13', selected: true},
    {columnName: 'Truck - 14', selected: true},
    {columnName: 'Truck - 15', selected: true},
    {columnName: 'Utilization', selected: true}
  ];

  constructor(public transformService: TransformService,
              private dispatchersLiveBoardService: DispatchersLiveBoardService,
              private dialog: MatDialog,
              private router: Router,
              private titleCase: TitleCasePipe,
              private filterStatuses: FilterStatusesPipe,
              private rulesService: RulesService) { }

  ngOnChanges() {
    if(this.tableData) {
      this.countStatistics(this.tableData);
      this.mergedArrays = new MatTableDataSource(this.tableData.flat(1));
      console.log(this.tableData)
    }
  };

  countStatistics(arrayOfArrays: any) {
    let searching: number = 0;
    let covered: number = 0;
    let hometime: number = 0;
    let notCovered: number = 0;
    let repair: number = 0;
    let loadIssue: number = 0;
    let driverIssue: number = 0;
    let noStatus: number = 0;
    let utilization: number = 0;

    //Utilization calculation
    let utilizationConditions: string[] = ['truck.svg', 'dispatch-swap.png', 'in-transit.png', 'truck-yellow.svg'];
    let numOfDispatchers: number = arrayOfArrays.length;
  
    for(let key in arrayOfArrays) {
      let numOfTrucks: number = arrayOfArrays[key].length;
      let num: number = 0;
      for(let key2 in arrayOfArrays[key]) {

        if(arrayOfArrays[key][key2].icon_name === 'search.svg') {
          searching++;
        }

        if(arrayOfArrays[key][key2].status_group_name === 'OTR') {
          covered++;
        }

        if(arrayOfArrays[key][key2].status_group_name === 'Hometime') {
          hometime++;
        }
        
        if(arrayOfArrays[key][key2].status_group_name === 'Not covered') {
          notCovered++;
        }

        if(arrayOfArrays[key][key2].status_group_name === 'Repair') {
          repair++;
        }

        if(arrayOfArrays[key][key2].status_group_name === 'Load issue') {
          loadIssue++;
        }
        
        if(arrayOfArrays[key][key2].status_group_name === '') {
          noStatus++;
        }

        if(utilizationConditions.includes(arrayOfArrays[key][key2].icon_name)) {
          num++;
        }

      }
      
      utilization += num * 100 / numOfTrucks;
    }

    this.cardsStObj.searching = searching;
    this.cardsStObj.covered = covered;
    this.cardsStObj.hometime = hometime;
    this.cardsStObj.notCovered = notCovered;
    this.cardsStObj.repair = repair;
    this.cardsStObj.loadIssue = loadIssue;
    this.cardsStObj.driverIssue = driverIssue;
    this.cardsStObj.noStatus = noStatus;
    this.cardsStObj.utilization = this.transformService.addCommasDots(utilization / numOfDispatchers, 'round');
  };

  countUtilization(data: any[]) {
    let numOfTrucks: number = data.length;
    let num: number = 0;
    let utilizationConditions: string[] = ['truck.svg', 'dispatch-swap.png', 'in-transit.png', 'truck-yellow.svg'];
    for(let key in data) {
      if(utilizationConditions.includes(data[key].icon_name)) {
        num++;
      }
    };
    return this.transformService.addCommasDots(num * 100 / numOfTrucks, 'round');
  }

  addStatus(obj: any) {
    this.dispatchersLiveBoardService.getStatusesByTruck(obj.unit_no, this.dateLastTwoWeeks.startDate, this.dateLastTwoWeeks.endDate)
    .subscribe((response: any) => {
      if(!obj.terminated) {
        let data: any = {
          driverInfo: {info: {id: obj.driver_id, name: obj.driver_name, truck_no: obj.unit_no, trailer_no: obj.trailer_no }},
          date: new Date(), 
          allStatusArray: response,
          startDate: this.dateLastTwoWeeks.startDate,
          endDate: this.dateLastTwoWeeks.endDate,
        };

      this.dialog.open(StatusDialogComponent, {
        autoFocus: false,
        panelClass: 'status-dialog-container',
        data: data
      });
    }
    })
  };

  //Open in new tab
  openInNewTab(route: string) {
    const url = this.router.serializeUrl(this.router.createUrlTree([route]));
    window.open(url, '_blank');
  }

  //Search
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.mergedArrays.filter = filterValue.trim().toLowerCase();
    this.tableData = this.groupByProperty(this.mergedArrays.filteredData);
  }

  groupByProperty(array: any[]) {
    const groups: any = array.reduce((acc: any, o: any) => {
      if (!acc[o.dispatcher_name]) {
        acc[o.dispatcher_name] = [];
      }
          
      acc[o.dispatcher_name].push(o);

      return acc;

    }, {});

    return Object.values(groups);
  };
  
  //Check uncheck filter
  checkUncheckValue(status: string) {
    let index = this.filterArray.indexOf(status);
    if (index === -1) {
      this.filterArray.push(status);
      this.filterArray = [...this.filterArray];
    } 
    else {
      this.filterArray.splice(index, 1);
      this.filterArray = [...this.filterArray];
    }
  };

  exportToExcel(columnsConfig: any[]) {
    if(this.rulesService.UserData[56].data[0].sectionArray[6].allowed) {
      let tableData: any[] = this.filterStatuses.transform(this.tableData, this.filterArray);
      const excelTable: any[] = [];
      for(let i = 0; i < tableData.length; i++) {
        let obj: any = {};
        this.transformService.selectedColumn(obj, columnsConfig[0].columnName, this.titleCase.transform(tableData[i][0].dispatcher_name), columnsConfig[0].selected);
        this.transformService.selectedColumn(obj, columnsConfig[1].columnName, tableData[i][0]?.unit_no, columnsConfig[1].selected);
        this.transformService.selectedColumn(obj, columnsConfig[2].columnName, tableData[i][1]?.unit_no, columnsConfig[2].selected);
        this.transformService.selectedColumn(obj, columnsConfig[3].columnName, tableData[i][2]?.unit_no, columnsConfig[3].selected);
        this.transformService.selectedColumn(obj, columnsConfig[4].columnName, tableData[i][3]?.unit_no, columnsConfig[4].selected);
        this.transformService.selectedColumn(obj, columnsConfig[5].columnName, tableData[i][4]?.unit_no, columnsConfig[5].selected);
        this.transformService.selectedColumn(obj, columnsConfig[6].columnName, tableData[i][5]?.unit_no, columnsConfig[6].selected);
        this.transformService.selectedColumn(obj, columnsConfig[7].columnName, tableData[i][6]?.unit_no, columnsConfig[7].selected);
        this.transformService.selectedColumn(obj, columnsConfig[8].columnName, tableData[i][7]?.unit_no, columnsConfig[8].selected);
        this.transformService.selectedColumn(obj, columnsConfig[9].columnName, tableData[i][8]?.unit_no, columnsConfig[9].selected);
        this.transformService.selectedColumn(obj, columnsConfig[10].columnName, tableData[i][9]?.unit_no, columnsConfig[10].selected);
        this.transformService.selectedColumn(obj, columnsConfig[11].columnName, tableData[i][10]?.unit_no, columnsConfig[11].selected);
        this.transformService.selectedColumn(obj, columnsConfig[12].columnName, tableData[i][11]?.unit_no, columnsConfig[12].selected);
        this.transformService.selectedColumn(obj, columnsConfig[13].columnName, tableData[i][12]?.unit_no, columnsConfig[13].selected);
        this.transformService.selectedColumn(obj, columnsConfig[14].columnName, tableData[i][13]?.unit_no, columnsConfig[14].selected);
        this.transformService.selectedColumn(obj, columnsConfig[15].columnName, tableData[i][14]?.unit_no, columnsConfig[15].selected);
        this.transformService.selectedColumn(obj, columnsConfig[16].columnName, `${this.countUtilization(tableData[i])}%`, columnsConfig[16].selected);
        excelTable.push(obj);
      };
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelTable);
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, 'activity-table.xlsx');
    }
    else {
      this.msgForbbidenAccess();
    }
  };

  msgForbbidenAccess() {
    this.dialog.open(MsgForbbidenAccessComponent, {
      autoFocus: false,
      panelClass: 'forbidden-msg-dialog-container'
    })
  };

}

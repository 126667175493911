import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { TitleCasePipe } from '@angular/common';
import { TransformService } from '../../services/transform.service';
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'app-status-popup',
  templateUrl: './status-popup.component.html',
  styleUrls: ['./status-popup.component.scss'],
  providers: [TitleCasePipe]
})
export class StatusPopupComponent implements OnInit, OnDestroy {
  @Input() statusData: any;
  popUpShow: number = 0;
  obj: any;

  sent: boolean = false;

  subscription: Subscription | any;

  constructor(public transformService: TransformService,
              private sharedService: SharedService,
              public titleCasePipe: TitleCasePipe) { }

  ngOnInit(): void {
    if(this.statusData.statusObj.status_group_name === 'OTR') {
      this.getDataForTruckWithload();
      this.popUpShow = 1;
    } 
    else if(this.statusData.statusObj.status === 'Searching') {
      this.getDataForTruckWithoutload();
      this.popUpShow = 2;
    }
    else {
      this.popUpShow = 3;
    }
  }

  getDataForTruckWithload() {
    this.subscription = this.sharedService.getLoadInfo(this.statusData.statusObj.driver_id, this.statusData.date, this.statusData.statusObj.status === 'Driving - In transit').subscribe((response: any) => {
      this.obj = response;
    })
  }

  getDataForTruckWithoutload() {
    this.subscription = this.sharedService.withoutLoadInfo(this.statusData.statusObj.driver_id).subscribe((response: any) => {
      this.obj = response;
    })
  }

  getNote(noteArray: any[]) {
    if(noteArray.length === 0) {
      return 'Note missing'
    } 
    else if(noteArray[noteArray.length - 1].note.length <= 153) {
      return noteArray[noteArray.length - 1].note;
    }
    else {
      return noteArray[noteArray.length - 1].note.substring(0, 153) + '...'; 
    }
  }

  sendNotification() {
    let dispName: any = this.titleCasePipe.transform(this.statusData.dispatcherName.split('/')[0].trim());
    let driverName: any = this.titleCasePipe.transform(this.statusData.driverName);
    let subjectMail: string = `${dispName} calls for help in covering ${driverName} ${this.statusData.truck}.`;
    let bodyMail: string = `<!DOCTYPE html>
    <html lang="en">
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Mail</title>
    </head>
    <body>
        
        <div>Please assist ${dispName} in covering:</div>
    
        <ul style="list-style-type: none; padding: 0px;">
            <li>Driver: ${driverName}</li>
            <li>Truck: ${this.statusData.truck}</li>
            <li>Trailer: ${this.statusData.trailer}</li>
            <li>Current location: ${this.obj.location}</li>
        </ul>
    
        <strong>Please take immediate action to assist in covering this truck.</strong>
    
    </body>
    </html>`;
    
    this.sharedService.sendMail(subjectMail, bodyMail).subscribe((response: any) => {
      this.sent = response;
    })
  };

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

}

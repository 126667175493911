import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TasksPageComponent } from './components/tasks-page/tasks-page.component';
import { TasksRoutingModule } from './tasks.routing';
import { SharedModule } from '../shared/shared.module';
import { TasksService } from './services/tasks.service';
import { TaskStatusDropdownComponent } from './components/tasks-page/task-status-dropdown/task-status-dropdown.component';
import { TriggerDialogComponent } from './components/tasks-page/trigger-dialog/trigger-dialog.component';
import { CompletedTaskDialogComponent } from './components/tasks-page/completed-task-dialog/completed-task-dialog.component';
import { AssignTaskDialogComponent } from './components/tasks-page/assign-task-dialog/assign-task-dialog.component';
import { TriggerDoubleClickDialogComponent } from './components/tasks-page/trigger-double-click-dialog/trigger-double-click-dialog.component';
import { TriggerTripleClickDialogComponent } from './components/tasks-page/trigger-triple-click-dialog/trigger-triple-click-dialog.component';
import { TasksPipe } from './pipes/tasks.pipe';
import { RelatedToDialogComponent } from './components/tasks-page/related-to-dialog/related-to-dialog.component';
import { FilterByGroupPipe } from './pipes/filter-by-group.pipe';

@NgModule({
  declarations: [
    TasksPageComponent,
    TaskStatusDropdownComponent,
    TriggerDialogComponent,
    CompletedTaskDialogComponent,
    AssignTaskDialogComponent,
    TriggerDoubleClickDialogComponent,
    TriggerTripleClickDialogComponent,
    TasksPipe,
    RelatedToDialogComponent,
    FilterByGroupPipe
  ],
  imports: [
    CommonModule,
    TasksRoutingModule,
    SharedModule
  ],
  providers: [TasksService, TasksPipe]
})
export class TasksModule { }

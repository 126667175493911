import { Component, OnDestroy, OnInit } from '@angular/core';
import { RulesService } from '@app/modules/shared/services/rules.service';
import { Subscription } from 'rxjs';
import { SafetyService } from '../../services/safety.service';


@Component({
  selector: 'app-safety-page',
  templateUrl: './safety-page.component.html',
  styleUrls: ['./safety-page.component.scss']
})
export class SafetyPageComponent implements OnInit, OnDestroy {
  permissions: any = this.rulesService.UserData[10].data;

  btnsArray: any[] = [
    {name: 'Truck file'},
    {name: 'Truck Devices'},
    {name: 'Contract'},
    {name: 'Document Editor'},
  ];

  initEditor: any = {header: '', template: ``, footer: '', show_page_number: false, newTemplate: true};

  selectedBtnIndex: number = 0;

  subscription: Subscription | any;

  constructor(private rulesService: RulesService, private safetyService: SafetyService) { }

  ngOnInit(): void {
    this.getTemplate();
  };

  getTemplate() {
    this.subscription = this.safetyService.templateSubject.subscribe((response: any) => {
      this.initEditor = response;
      this.selectedBtnIndex = 3;
      console.log(response)
    });
  };

  changeSection(i: number) {
    this.selectedBtnIndex = i; 
    this.initEditor = {header: '', template: ``, footer: '', show_page_number: false, newTemplate: true};
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  };

}

import { ConnectionPositionPair } from '@angular/cdk/overlay';
import { KeyValue } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'export-menu',
  templateUrl: './export-menu.component.html',
  styleUrls: ['./export-menu.component.scss']
})
export class ExportMenuComponent {
  @Input() xlsxConfig: any[] = [];
  @Output() triggerEvent = new EventEmitter<any>();
  isOpenExportMenu: boolean = false;
  //Positions
  public positions = [
    new ConnectionPositionPair(
      {originX: 'end', originY: 'bottom'},
      {overlayX: 'end', overlayY: 'top'},
      -10, 5
    )
  ];

  exportToExcel() {
    this.triggerEvent.emit(this.xlsxConfig);
    this.isOpenExportMenu = !this.isOpenExportMenu;
  };

}

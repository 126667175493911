import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DocumentExplanationDialogComponent } from './document-explanation-dialog/document-explanation-dialog.component';
import { AddEditDocumentDialogComponent } from './add-edit-document-dialog/add-edit-document-dialog.component';
import { CompanyInfoDialogComponent } from './company-info-dialog/company-info-dialog.component';
import { Subscription, catchError, throwError } from 'rxjs';
import { DocumentsListDialogComponent } from './documents-list-dialog/documents-list-dialog.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { TransformService } from '@app/modules/shared/services/transform.service';
import { DocumentsService } from '../../services/documents.service';
import { SharedService } from '@app/modules/shared/services/shared.service';

@Component({
  selector: 'app-documents-card',
  templateUrl: './documents-card.component.html',
  styleUrls: ['./documents-card.component.scss']
})
export class DocumentsCardComponent implements OnInit, OnDestroy {
  //Companies
  companiesArray: any[] = [];
  isOpenCompanyMenu: boolean = false;
  companyValue: string = 'Tempo Freight Systems LLC';
  companyInfoData: any = {data: []};

  //Search 
  searchValue: string = '';

  //Documents
  documentsArray: any[] = [];
  searchDocument: string = '';

  loaded: boolean = false;

  error: boolean = false;

  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";

  spinnerBgColor: string = this.transformService.spinnerBgColor;
  spinnerType: string = this.transformService.spinnerType;

  subscription1: Subscription | any; 
  subscription2: Subscription | any; 

  constructor(private dialog: MatDialog,
              private transformService: TransformService,
              private documentsService: DocumentsService,
              private sharedService: SharedService,
              private spinner: NgxSpinnerService) { } 

  ngOnInit(): void {
    this.getCompanyData();
  };

  //Get company data
  getCompanyData() {
    this.subscription1 = this.documentsService.getAllCompanies()
    .subscribe((response: any) => {
      console.log(response)
      let companyId: number = 0;
      for(let i = 0; i < response.length; i++) {
        response[i].data = JSON.parse(response[i].data);
        if(response[i].company_name.includes('Tempo Freight Systems')) {
          companyId = i;
        }
      }
      this.companiesArray = response;
      this.companyInfoData = response[companyId];
      this.getDocumentsByCompany(response[companyId].id);
      console.log(response);
    });
  };

  getDocumentsByCompany(id: number) {
    this.spinner.show('documents-section');
    this.subscription2 = this.documentsService.getDocumentsByCompany(id)
    .pipe(catchError((err: any) => {
      this.spinner.hide('documents-section');
      this.loaded = true;
      this.error = true;
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      this.documentsArray = response;
      this.spinner.hide('documents-section');
      this.loaded = true;
      console.log(response);
    });
  };

  //Change company
  changeCompany(obj: any) {
    this.companyValue = obj.company_name;
    this.companyInfoData = obj; 

    this.documentsArray = [];
    this.error = false;
    this.loaded = false;
    this.getDocumentsByCompany(obj.id)
    this.isOpenCompanyMenu = false;
  };

  //Company info
  openCompanyInfoDialog() {
    let dialogRef = this.dialog.open(CompanyInfoDialogComponent, {
      autoFocus: false,
      panelClass: 'company-info-dialog-container',
      data: JSON.parse(JSON.stringify(this.companyInfoData))
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
        this.companyInfoData.data = result;
      }
    });
  };

  //Add/Edit Document
  openAddEditDocumentDialog(obj: any, editMode: boolean, index: number) {
    let dialogRef = this.dialog.open(AddEditDocumentDialogComponent, {
      autoFocus: false,
      panelClass: 'add-edit-document-dialog-container',
      data: {obj: obj, editMode: editMode}
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
        if(editMode) {
          this.documentsArray[index] = result;
        }
        else {
          this.documentsArray.push(result);
        }
      }

      if(result === 0) {
        this.documentsArray.splice(index, 1);
      }
      console.log(result)
    });
  };

  //Open documentation dialog
  openFilesForDownload(obj: any) {
    this.dialog.open(DocumentsListDialogComponent, {
      panelClass: 'download-files-dialog',
      autoFocus: false,
      data: obj
    });
  };

  //Document explanation
  openDocumentExplanationDialog(obj: any) {
    this.dialog.open(DocumentExplanationDialogComponent, {
      autoFocus: false,
      panelClass: 'document-explanation-dialog-container',
      data: obj
    });
  };

  isAllowed(data: any, permissionType: string) {
    let userDepartment: any = JSON.parse(localStorage.getItem('currentUser')).department;
    let jsObj: any = JSON.parse(data);
    let allowed: boolean = false;
    if(permissionType === 'View' && jsObj.selectedDepartmentsViewArray.includes(userDepartment.trim())) {
      allowed = true;
    };
    if(permissionType === 'Download' && jsObj.selectedDepartmentsDownloadArray.includes(userDepartment.trim())) {
      allowed = true;
    };
    return allowed 
  };

  ngOnDestroy(): void {
    this.subscription1?.unsubscribe();
    this.subscription2?.unsubscribe();
  };

}

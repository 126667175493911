import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FleetHealthRoutingModule } from './fleet-health.routing';
import { SharedModule } from '../shared/shared.module';
import { CreateUpdateFormDialogComponent } from './components/create-work-order-dialog/create-update-parts/create-update-form-dialog/create-update-form-dialog.component';
import { CreateUpdatePartsComponent } from './components/create-work-order-dialog/create-update-parts/create-update-parts.component';
import { CreateUpdateStatusFormComponent } from './components/create-work-order-dialog/create-update-status/create-update-status-form/create-update-status-form.component';
import { CreateUpdateStatusComponent } from './components/create-work-order-dialog/create-update-status/create-update-status.component';
import { CreateWorkOrderDialogComponent } from './components/create-work-order-dialog/create-work-order-dialog.component';
import { CurrentLoadComponent } from './components/dealer-shop-page/current-load/current-load.component';
import { DealerShopPageComponent } from './components/dealer-shop-page/dealer-shop-page.component';
import { DealershipDetailsComponent } from './components/dealer-shop-page/dealership-details/dealership-details.component';
import { DocsHistoryComponent } from './components/dealer-shop-page/docs-history/docs-history.component';
import { PaymentDetailsComponent } from './components/dealer-shop-page/payment-details/payment-details.component';
import { CreateTruckThreadDialogComponent } from './components/dealer-shop-page/truck-note-dialog/create-truck-thread-dialog/create-truck-thread-dialog.component';
import { TruckNoteDialogComponent } from './components/dealer-shop-page/truck-note-dialog/truck-note-dialog.component';
import { WorkOrderComponent } from './components/dealer-shop-page/work-order/work-order.component';
import { FleetHealthOverviewComponent } from './components/fleet-health-overview/fleet-health-overview.component';
import { TrucksCardsComponent } from './components/fleet-health-overview/trucks-cards/trucks-cards.component';
import { UtilizationCardsGraphsComponent } from './components/fleet-health-overview/utilization-cards-graphs/utilization-cards-graphs.component';
import { FleetHealthPageComponent } from './components/fleet-health-page.component';
import { AskForApprovalDialogComponent } from './components/schedule-page/ask-for-approval-dialog/ask-for-approval-dialog.component';
import { AskForTruckDialogComponent } from './components/schedule-page/ask-for-truck-dialog/ask-for-truck-dialog.component';
import { DemandBySafetyTableComponent } from './components/schedule-page/demand-by-safety-table/demand-by-safety-table.component';
import { DemandReserveSpotDialogComponent } from './components/schedule-page/demand-reserve-spot-dialog/demand-reserve-spot-dialog.component';
import { MessageSpotsDialogComponent } from './components/schedule-page/message-spots-dialog/message-spots-dialog.component';
import { ReasonsDialogComponent } from './components/schedule-page/reasons-dialog/reasons-dialog.component';
import { ReserveSpotDialogComponent } from './components/schedule-page/reserve-spot-dialog/reserve-spot-dialog.component';
import { SchedulePageComponent } from './components/schedule-page/schedule-page.component';
import { ScheduleCalendarComponent } from './components/schedule-page/scheduled-trucks-calendar/schedule-calendar/schedule-calendar.component';
import { ScheduledTrucksCalendarComponent } from './components/schedule-page/scheduled-trucks-calendar/scheduled-trucks-calendar.component';
import { SettingsCalendarComponent } from './components/schedule-page/scheduled-trucks-calendar/settings-calendar/settings-calendar.component';
import { EditReasonsDialogComponent } from './components/schedule-page/spots/edit-reasons-dialog/edit-reasons-dialog.component';
import { SpotsComponent } from './components/schedule-page/spots/spots.component';
import { TruckArriveDialogComponent } from './components/schedule-page/spots/truck-arrive-dialog/truck-arrive-dialog.component';
import { WarningReserveSpotComponent } from './components/schedule-page/warning-reserve-spot/warning-reserve-spot.component';
import { TruckProfileFilesComponent } from './components/truck-profile-page-maintenance/truck-profile-files/truck-profile-files.component';
import { TruckProfileFuelComponent } from './components/truck-profile-page-maintenance/truck-profile-fuel/truck-profile-fuel.component';
import { TruckProfileCardsComponent } from './components/truck-profile-page-maintenance/truck-profile-overview/truck-profile-cards/truck-profile-cards.component';
import { TruckProfileDriversDispatchersTableComponent } from './components/truck-profile-page-maintenance/truck-profile-overview/truck-profile-drivers-dispatchers-table/truck-profile-drivers-dispatchers-table.component';
import { TruckProfileOverviewComponent } from './components/truck-profile-page-maintenance/truck-profile-overview/truck-profile-overview.component';
import { TruckProfilePageMaintenanceComponent } from './components/truck-profile-page-maintenance/truck-profile-page-maintenance.component';
import { TruckProfileRepairsComponent } from './components/truck-profile-page-maintenance/truck-profile-repairs/truck-profile-repairs.component';
import { TruckProfileScheduleComponent } from './components/truck-profile-page-maintenance/truck-profile-schedule/truck-profile-schedule.component';
import { TruckProfileTiresComponent } from './components/truck-profile-page-maintenance/truck-profile-tires/truck-profile-tires.component';
import { FleetHealthService } from './services/fleet-health.service';
import { ScheduledTrucksTableComponent } from './components/schedule-page/scheduled-trucks-table/scheduled-trucks-table.component';


@NgModule({
  declarations: [
    FleetHealthPageComponent,
    CreateWorkOrderDialogComponent,
    CreateUpdatePartsComponent,
    CreateUpdateFormDialogComponent,
    CreateUpdateStatusComponent,
    CreateUpdateStatusFormComponent,
    DealerShopPageComponent,
    CurrentLoadComponent,
    DealershipDetailsComponent,
    DocsHistoryComponent,
    PaymentDetailsComponent,
    TruckNoteDialogComponent,
    CreateTruckThreadDialogComponent,
    WorkOrderComponent,
    FleetHealthOverviewComponent,
    UtilizationCardsGraphsComponent,
    TrucksCardsComponent,
    SchedulePageComponent,
    AskForApprovalDialogComponent,
    AskForTruckDialogComponent,
    DemandBySafetyTableComponent,
    DemandReserveSpotDialogComponent,
    MessageSpotsDialogComponent,
    ReasonsDialogComponent,
    ReserveSpotDialogComponent,
    ScheduledTrucksCalendarComponent,
    ScheduleCalendarComponent,
    SettingsCalendarComponent,
    SpotsComponent,
    EditReasonsDialogComponent,
    TruckArriveDialogComponent,
    WarningReserveSpotComponent,
    TruckProfilePageMaintenanceComponent,
    TruckProfileFilesComponent,
    TruckProfileFuelComponent,
    TruckProfileOverviewComponent,
    TruckProfileCardsComponent,
    TruckProfileDriversDispatchersTableComponent,
    TruckProfileRepairsComponent,
    TruckProfileScheduleComponent,
    TruckProfileTiresComponent,
    ScheduledTrucksTableComponent
  ],
  imports: [
    CommonModule,
    FleetHealthRoutingModule,
    SharedModule
  ],
  providers: [FleetHealthService]
})
export class FleetHealthModule { }

import { GoogleChartsBaseService } from './google-charts.base.service';
import { Injectable } from '@angular/core';
import { AnnotationChartConfig } from './../models/AnnotationChartConfig';

declare var google: any;

@Injectable({  
  providedIn: 'root'
})
export class GoogleAnnotationChartService{

  constructor() { 
	google.charts?.load('current', {'packages':['annotationchart']});
   }

  public BuildAnnotationChart(elementId: string, data: any, config: any) : void {  
	  
    var chartFunc = () => { return new google.visualization.AnnotationChart(document.getElementById(elementId)); };
    // var options = {
    //         title: config.title,
    //         displayAnnotations: config.displayAnnotations,
	//   };
	  
	  var options = config;

    this.buildChart(data, chartFunc, options);
  }

  protected buildChart(data: any, chartFunc: any, options: any) : void {

    var func = (chartFunc, options) => {
		var datatable = new google.visualization.DataTable();

		data['columns'].forEach(
			el => {	
				datatable.addColumn(el[0], el[1])
			}
		);

		datatable.addRows(data['rows']);

      chartFunc().draw(datatable, options);
    };   
    var callback = () => func(chartFunc, options);
    google.charts.setOnLoadCallback(callback);
  }
}
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PaymentsService } from '@app/modules/accounting/services/payments.service';
import { WarningMsgDialogComponent } from '@app/modules/shared/components/warning-msg-dialog/warning-msg-dialog.component';
import { SharedService } from '@app/modules/shared/services/shared.service';
import { catchError, Subscription, throwError } from 'rxjs';
import { InvalidValidationDialogComponent } from './invalid-validation-dialog/invalid-validation-dialog.component';
import { ConnectionPositionPair } from '@angular/cdk/overlay';
import { DeleteConfirmationDialogComponent } from '@app/modules/shared/components/delete-confirmation-dialog/delete-confirmation-dialog.component';
import { RulesService } from '@app/modules/shared/services/rules.service';

@Component({
  selector: 'app-create-update-payment-dialog',
  templateUrl: './create-update-payment-dialog.component.html',
  styleUrls: ['./create-update-payment-dialog.component.scss']
})
export class CreateUpdatePaymentDialogComponent implements OnInit, OnDestroy {
  permissions: any = this.rulesService.UserData[37].data;
  loggedUser: any = JSON.parse(localStorage.getItem('currentUser'));
  fullName: string = `${this.loggedUser.first_name} ${this.loggedUser.last_name}`;
  nickname: string = `${this.loggedUser.nickname}`;

  //Payment group
  paymentFormGroup: any = this._formBuilder.nonNullable.group({
    id: [0],
    type: [0],
    IssuedBy: [`${this.nickname.length === 0 ? this.fullName : this.nickname?.trim().split(' ')[0]}`],
    DriverName: ['', [Validators.required, Validators.minLength(2)]],
    unit_no: ['', [Validators.required, Validators.minLength(2)]],
    DateOfPayment: [null, Validators.required],
    PaymentMethod: ['', [Validators.required, Validators.minLength(2)]],
    PaymantAmount: [0],
    PaymentIssuedTo: [''],
    TypeOfRepair: [''],
    InvoiceReceived: [false],
    InvoiceUploadedToPT: [false],
    MoneyCode: [''],
    IssuedAmount: [0],
    AmountUsed: [0],
    Stete: [''],
    Quantity: [0],
    FuelReceiptReceived: [false],
    CardUsed: [''],
    RELAYCode: [''],
    ReasonForPeyment: [''],
    ReasonForMoneyCode: [''],
    note: [''],
    fileId: [0],
    fileName: [''],
    fileData: [null],
    division: ['', [Validators.required, Validators.minLength(2)]],
    reviewed: [false],
    chargeTheDriver: [false],
    driverCharged: [false],
    amountCharged: [0],
    requestTrigger: [null],
    driverId: [0],
    driverMailSend: [null],
    issuedID: [this.loggedUser.id]
  })

  //Drivers
  driversArray: any[] = [];

  //Divisions
  divisionsArray: any[] = [];

  //Units
  unitsArray: any[] = [];

  //Reason for payment
  reasonForPayment: any[] = [
    {name: 'Alcohol 70%'}, {name: 'Anti GEL'}, {name: 'Bag of Salt'}, {name: 'Baggage Fee'}, {name: 'Boot Removal'},
    {name: 'Broom'}, {name: 'Bus Ticket'}, {name: 'Cargo Restacking'}, {name: 'Cargo Transloading'}, {name: 'Cash Advance'},
    {name: 'Coolant Fluid'}, {name: 'Crash Report'}, {name: 'Decals'}, {name: 'DEF Fluid'}, {name: 'Driver DOT Physical'},
    {name: 'Fire Extinguisher'}, {name: 'Flight'}, {name: 'FMCSA Clearing House Queries'}, {name: 'Follow up Alcohol Test'},
    {name: 'Follow up Drug Test'}, {name: 'Fridge'}, {name: 'Gate Pass'}, {name: 'Hard Hat'}, {name: 'High Visibility Vest'},
    {name: 'Hotel Accomodation'}, {name: 'ILSOS Certificate of Good Standing'}, {name: 'Inverter'}, {name: 'KY Permits'},
    {name: 'Late Fee'}, {name: 'Load Bars'}, {name: 'Lumper'}, {name: 'Misc. Traffic Ticket'}, {name: 'NJ Alcohol Permit'},
    {name: 'NM Permits'}, {name: 'NY permits'}, {name: 'Oil'}, {name: 'Overweight Ticket/Citation'}, {name: 'Padlock'},
    {name: 'Parking Ticket'}, {name: 'Port Escort'}, {name: 'Post-Accident Alcohol Test'}, {name: 'Post-Accident Drug Test'}, 
    {name: 'Power Steering Fluid'}, {name: 'Pre-Employment Drug Alcohol Test'}, {name: 'Random Breath Alcohol Test'}, 
    {name: 'Random Drug Test'}, {name: 'Safety Goggles'}, {name: 'Scaling'}, {name: 'Seals'}, {name: 'Straps'}, {name: 'Taxi'}, 
    {name: 'Three Way Hoses'}, {name: 'Toll Receipt'}, {name: 'Toolkit'}, {name: 'Trailer Storage'}, {name: 'Trailer Wash Out'}, 
    {name: 'Train Ticket'}, {name: 'TripPak Envelope'}, {name: 'Truck Detailing'}, {name: 'Truck Storage'}, {name: 'Truck Wash'}, 
    {name: 'Truck/Trailer Storage'}, {name: 'Uber Ride'}, {name: 'Windshield Washer Fluid'}, {name: 'Windshield Wipers'}
  ];

  //States
  statesArray: any[] = [];

  //File
  fileData: string = '';
  isOpenFileMenu: boolean = false;
  
  //Positions
  public positions = [
    new ConnectionPositionPair(
      {originX: 'end', originY: 'bottom'},
      {overlayX: 'end', overlayY: 'top'},
      0, 5
    )
  ];

  //Subscription
  subscription1: Subscription = new Subscription();
  subscription2: Subscription = new Subscription();
  subscription3: Subscription = new Subscription();
  subscription4: Subscription = new Subscription();
  subscription5: Subscription = new Subscription();

  constructor(@Inject(MAT_DIALOG_DATA) public obj: any, 
              private dialog: MatDialog,
              public dialogRef: MatDialogRef<CreateUpdatePaymentDialogComponent>,
              private paymentsService: PaymentsService,
              private _formBuilder: FormBuilder,
              private sharedService: SharedService,
              private rulesService: RulesService) { }
  
  ngOnInit(): void {
    if(this.obj.editMode) {
      this.paymentFormGroup.setValue(this.obj.data);
      if(this.obj.data.fileName !== '') {
        this.getPaymentFile();
      }
    }
    this.getDrivers();
    this.getDivisions();
    this.getTrucksAndTrailers();
    this.getStates();
  };

  getDrivers() {
    this.subscription1 = this.sharedService.getDriversData(true).subscribe((response: any) => {
      for(let i = 0; i < response.length; i++) {
        response[i].full_name = `${response[i].first_name} ${response[i].last_name}`;
      }
      this.driversArray = response;
      console.log(response)
    });
  };

  getDivisions() {
    this.subscription2 = this.sharedService.getCompanyData().subscribe((response: any) => {
      this.divisionsArray = response.divisions;
    });
  };

  getTrucksAndTrailers() {  
    this.subscription3 = this.sharedService.getTrucksAndTrailersData(true).subscribe((response: any) => {
      this.unitsArray = response[0].concat(response[1]);
    });
  };

  getStates() {
    this.subscription4 = this.sharedService.getAllLocationInfo().subscribe((response: any) => {
      this.statesArray = response;
    });
  };

  //Get file
  getPaymentFile() {
    this.subscription5 = this.paymentsService.getPaymentFile(this.obj.data.id, this.obj.data.fileName).subscribe((response: any) => {
      this.fileData = response;
    });
  };  

  //Files methods
  onFileSelected(event: any) {  
    let length: any = Object.keys(event.target.files).length;
    for(let i = 0; i < length; i++) {
      this.addMultipleFiles(event, i);
    }
  }

  addMultipleFiles(event: any, index: number) {
    let fileName = event.target.files[index].name;
    let reader = new FileReader();
    reader.onload = (e: any) => {
      let base64 = e.target.result.split(',')[1];
      this.paymentFormGroup.patchValue({'fileName': fileName, 'fileData': base64});
      this.fileData = base64;
    };
    reader.readAsDataURL(event.target.files[index]);
  };

  openFileInNewTab() {
    this.isOpenFileMenu = !this.isOpenFileMenu;
    this.sharedService.downloadPreviewFile(this.formData('fileName'), this.fileData);
  };

  downloadFile() {
    this.isOpenFileMenu = !this.isOpenFileMenu;
    let source: string = `data:application/octet-stream;base64,${this.fileData}`;
    const downloadLink = document.createElement('a');
    const fileName: string = this.formData('fileName');
    downloadLink.href = source;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  deleteFile() {
    this.isOpenFileMenu = !this.isOpenFileMenu;
    let dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
      autoFocus: false,
      panelClass: 'delete-dialog-container',
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
        if(this.obj.data?.id && this.obj.data?.fileName) {
          this.paymentsService.deleteFileFromPayment(this.obj.data.id, this.obj.data.fileName)
          .pipe(catchError((err: any) => {
            this.showErrorMessage();
            return throwError(() => err);
          }))
          .subscribe((response: boolean) => {
            if(response) {
              this.paymentFormGroup.patchValue({'fileId': 0, 'fileName': '', 'fileData': null});
              this.fileData = '';
            }
            else {
              this.showErrorMessage();
            }
            console.log(response)
          });
        }
        else {
          this.paymentFormGroup.patchValue({'fileId': 0, 'fileName': '', 'fileData': null});
          this.fileData = '';
        }
      }
    })
  };

  saveData() {
    this.paymentsService.createUpdatePayment(this.paymentFormGroup.value)
    .pipe(catchError((err: any) => {
      this.showErrorMessage();
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      if(response?.id) {
        this.dialogRef.close(response);
      }
      else {
        this.showErrorMessage();
      }
    });
  };

  //Show error message
  showErrorMessage() {
    this.dialog.open(WarningMsgDialogComponent, {
      autoFocus: false,
      panelClass: 'warning-msg-dialog-container'
    });
  };

  //Get payment type
  getPaymentType(): string {
    const obj: any = {0: 'Repair Payments', 1: 'Fuel Payments', 2: 'Miscellaneous Payments'};
    return obj[this.formData('type')];
  };

  //Get form value
  formData(formKey: string) {
    return this.paymentFormGroup.controls[formKey].value;
  };

  //Autocomplete division
  selectDivisionById(divisionId: number) {
    let division: string = '';
    for(let i = 0; i < this.divisionsArray.length; i++) {
      if(this.divisionsArray[i].id === divisionId) {
        division = this.divisionsArray[i].name;
        break;
      };
    };
    return division;
  };

  //Set or clear validators
  setOrClearValidators(paymentMethod: string) {
    this.paymentFormGroup.patchValue({'PaymantAmount': 0, 'CardUsed': '', 'PaymentIssuedTo': '', 'TypeOfRepair': '',
      'MoneyCode': '', 'ReasonForMoneyCode': '', 'IssuedAmount': 0, 'AmountUsed': 0, 'RELAYCode': ''
    });
    if(paymentMethod === 'EFS Money Code') {
      this.setValidators();
    }
    else {
      this.clearValidators();
    }
  };

  setValidators() {
    this.paymentFormGroup.controls['MoneyCode'].setValidators([Validators.required,  this.minLengthNumberValidator(10)]);
    this.paymentFormGroup.controls['MoneyCode'].updateValueAndValidity();
  };

  clearValidators() {
    this.paymentFormGroup.controls['MoneyCode'].clearValidators();
    this.paymentFormGroup.controls['MoneyCode'].updateValueAndValidity();
  };

  //Validator
  minLengthNumberValidator(digitsLength: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value == null) {
        return null;
      }
  
      const valueAsString = control.value.toString();
      return valueAsString.length === digitsLength ? null : { 'minLengthNumber': { value: control.value }};
    };
  };

  checkValidaty() {
    let moneyCode: string = `${this.paymentFormGroup.value.MoneyCode}`;
    if(moneyCode.length < 10) {
      this.showInvalidDataMessage('You are missing digits, a money code has 10 digits.');
    };
    if(moneyCode.length > 10) {
      this.showInvalidDataMessage('You have entered too many digits; a money code has 10 digits.');
    };
  };

  showInvalidDataMessage(message: string) {
    this.dialog.open(InvalidValidationDialogComponent, {
      autoFocus: false,
      panelClass: 'component-dialog-container',
      data: message
    });
  };

  setDriverData(event: any) {
    if(typeof event === 'object') {
      this.paymentFormGroup.patchValue({'DriverName': event.full_name, 'division': this.selectDivisionById(event.division_id),
      'driverId': event.id
      })
    }
    else {
      this.paymentFormGroup.patchValue({'DriverName': event, 'driverId': 0});
    }
  };

  resetForm() {
    this.paymentFormGroup.reset();
  };

  get getSelectedUnits(): any[] {
    const selectedUnits: string = this.formData('unit_no');
    if(selectedUnits?.length > 0) {
      return selectedUnits.split(', ');
    }
    return [];
  };

  ngOnDestroy(): void {
    this.subscription1?.unsubscribe();  
    this.subscription2?.unsubscribe();
    this.subscription3?.unsubscribe();
    this.subscription4?.unsubscribe();
    this.subscription5?.unsubscribe();    
  };

}

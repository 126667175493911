import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../core/services/auth/auth.guard';
import { LoadBoardComponent } from './components/load-board/load-board.component';


const routes: Routes = [
    {
		path: '',
		canActivate: [AuthGuard],
		component: LoadBoardComponent,
		data: { roles: 0 }
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class LoadBoardRoutingModule { }
import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-action-menu',
  templateUrl: './action-menu.component.html',
  styleUrls: ['./action-menu.component.scss']
})
export class ActionMenuComponent {
  //Action select
  isOpenAction: boolean = false;
  actionArray: any[] = [
    {action: 'To be terminated'},
    {action: 'Follow up'},
    {action: 'Change dispatcher'},
    {action: 'Ask for explanation'},
  ];
  selectedAction: string = 'Action';

  @Output() triggerEvent = new EventEmitter<any>();

  //Select action
  selectAction(obj: any) {
    this.selectedAction = obj.action;
    this.triggerEvent.emit(obj.action);
    this.isOpenAction = false;
  };

}

import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import moment = require('moment');
import { Subscription, catchError, throwError } from 'rxjs';
import { FiltersDialogComponent } from './filters-dialog/filters-dialog.component';
import { NgxSpinnerService } from 'ngx-spinner';
import * as XLSX from 'xlsx';
import { DriversFilterService } from '../services/drivers-filter.service';
import { TransformService } from '@app/modules/shared/services/transform.service';
import { RulesService } from '@app/modules/shared/services/rules.service';
import { MsgForbbidenAccessComponent } from '@app/modules/shared/components/msg-forbbiden-access/msg-forbbiden-access.component';
import { TitleCasePipe } from '@angular/common';
import { FilterDriversTableComponent } from './filter-drivers-table/filter-drivers-table.component';

@Component({
  selector: 'app-dashboard-filter-drivers-page',
  templateUrl: './dashboard-filter-drivers-page.component.html',
  styleUrls: ['./dashboard-filter-drivers-page.component.scss']
})
export class DashboardFilterDriversPageComponent implements OnInit, OnDestroy {
  @ViewChild(FilterDriversTableComponent) childComponent: any;
  tableData: any;

  copyTableData: any[] = [];

  dateObj: any = {
    startDate: this.transformService.convertDateToTimestamp(moment().startOf('month').format('ddd, DD/MM YYYY'), 
    '00:00:00'),
    endDate: this.transformService.convertDateToTimestamp(moment().format('ddd, DD/MM YYYY'),  moment().format('HH:mm:ss')),
  };

  //Deal value
  paySign: string[] = ['solo company', 'team company'];

  //Excel config
  xlsxConfig: any = [
    {columnName: 'No.', selected: true},
    {columnName: 'DRIVER', selected: true},
    {columnName: 'STATUS', selected: true},
    {columnName: 'TYPE', selected: true},
    {columnName: 'HIRING DATE', selected: true},
    {columnName: 'TERMINATION DATE', selected: true},
    {columnName: 'DEAL', selected: true},
    {columnName: 'GROSS', selected: true},
    {columnName: 'MILEAGE', selected: true},
    {columnName: 'AVG MILEAGE', selected: true},
    {columnName: 'STATE', selected: true},
    {columnName: 'PAYCHECK', selected: true},
    {columnName: 'AVG PAYCHECK', selected: true},
    {columnName: 'PHONE', selected: true}
  ];

  loaded: boolean = false;

  error: boolean = false;

  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";

  spinnerBgColor: string = this.transformService.spinnerBgColor;
  spinnerType: string = this.transformService.spinnerType;

  subscription: Subscription | any;

  constructor(private transformService: TransformService,
              private dialog: MatDialog,
              private filterService: DriversFilterService,
              private spinner: NgxSpinnerService,
              private rulesService: RulesService,
              private titleCase: TitleCasePipe) { }

  ngOnInit(): void {
    this.spinner.show('filter-drivers');
    this.getTableData();
  } 

  getTableData() {
    this.subscription = this.filterService.getFilterDriversTableData(this.dateObj.startDate, this.dateObj.endDate, this.transformService.filterParams)
    .pipe(catchError((err: any) => {
      this.spinner.hide('filter-drivers');
      this.loaded = true;
      this.error = true;
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      this.copyTableData = [...response];
      this.filterData(this.filterService.filterConditions);
      this.spinner.hide('filter-drivers');
      this.loaded = true;
      console.log(response);
    })
  }

  getDate(result: any) {
    this.dateObj.startDate = result.startDate;
    this.dateObj.endDate = result.endDate;
    this.error = false;
    this.loaded = false;
    this.tableData = undefined;
    this.spinner.show('filter-drivers');

    this.getTableData();
  };

  openFiltersDialog() {
    let dialogRef: any = this.dialog.open(FiltersDialogComponent, {
      width: '500px',
      autoFocus: false,
      position: {
        top: '78px',
        right: '0px'
      },
      panelClass: 'filters-dialog-container'
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.filterData(result);    
      }
    });
  };

  filterData(criteryArray: any[]) {
    console.log(criteryArray);
    console.log(this.copyTableData)
    let filterArray: any[] = [...this.copyTableData];
    this.tableData = filterArray.filter((item) => {
      item.status = item.is_active ? 'Active' : 'Inactive';

      if(item.weeks) {
        item.avgMileage = item.mileage / item.weeks;
        item.avgPaycheck = item.paycheck / item.weeks;
      }
      else {
        item.avgMileage = 0;
        item.avgPaycheck = 0;
      }

      if(this.paySign.includes(item.type.toLowerCase())) {
        item.dealValue = item.deal;
        item.value = '$'+ this.transformService.addCommasDots(item.deal)
      } 
      else {
        item.dealValue = Math.round(item.deal * 100);
        item.value = this.transformService.addCommasDots(item.deal * 100, 'round') + '%';
      }

      for (var obj in criteryArray) {
        let min: number = criteryArray[obj].min ? Number(criteryArray[obj].min) : -100000000;
        let max: number = criteryArray[obj].max ? Number(criteryArray[obj].max) : 100000000;

        if(criteryArray[obj].filterData.length > 0 && criteryArray[obj].condition === 'Status Type State') {

          if (item[criteryArray[obj].key] === undefined || !criteryArray[obj].filterData.includes(item[criteryArray[obj].key].toLowerCase()))
            return false;
        }

        
        if(criteryArray[obj].filterData.length > 0  && criteryArray[obj].condition === 'Deal') {
          if (item[criteryArray[obj].key] < min || item[criteryArray[obj].key] > max
              || !criteryArray[obj].filterData.includes(item.type.toLowerCase()))
            return false;
        }
        
        if(criteryArray[obj].condition === 'Mileage Paycheck') {
          let num: number = 0;

          if(criteryArray[obj].period === 'Weekly') {
            num = 1
          }
          if(criteryArray[obj].period === 'Monthly') {
            num = 4;
          }
          if(criteryArray[obj].period === 'Yearly') {
            num = 52;
          }

          let value: number = 100000000;

          if((min !== -100000000 || max !== 100000000) && item.weeks) {
            value = item[criteryArray[obj].key] / Math.round(item.weeks / num) ? item[criteryArray[obj].key] / Math.round(item.weeks / num) : 0;
          }
          
          if(!item.weeks) {
            value = item.mileage;
          }
      
          if (value < min || value > max)
            return false;
        }

        if(criteryArray[obj].condition === 'Period in the company' && criteryArray[obj].range === 'Calendar') { 

            if(item.termination_date && new Date(item.termination_date).getTime() <= new Date(criteryArray[obj].fromDate).getTime() 
            || item.termination_date && new Date(item.termination_date).getTime() < new Date(criteryArray[obj].toDate).getTime())
            return false;
        }

        if(criteryArray[obj].condition === 'Period in the company' && criteryArray[obj].range === 'Min Max') {
          let dividerNum: number = 0; 
          if(criteryArray[obj].period === 'Weeks') {
            dividerNum = 7;
          }
          if(criteryArray[obj].period === 'Months') {
            dividerNum = 30;
          }
          if(criteryArray[obj].period === 'Years') {
            dividerNum = 365;
          }

          if((item.in_company / dividerNum) < min || (item.in_company / dividerNum) > max)
          return false;

        }


      }
      return true;

    });
  };

  exportToExcel(columnsConfig: any[]) {
    if(this.rulesService.UserData[56].data[0].sectionArray[32].allowed) {
      const excelTable: any[] = [];
      for(let i = 0; i < this.tableData.length; i++) {
        let obj: any = {};
        this.transformService.selectedColumn(obj, columnsConfig[0].columnName, i + 1, columnsConfig[0].selected);
        this.transformService.selectedColumn(obj, columnsConfig[1].columnName, this.titleCase.transform(this.tableData[i].driver), columnsConfig[1].selected);
        this.transformService.selectedColumn(obj, columnsConfig[2].columnName, this.tableData[i].status, columnsConfig[2].selected);
        this.transformService.selectedColumn(obj, columnsConfig[3].columnName, this.titleCase.transform(this.tableData[i].type), columnsConfig[3].selected);
        this.transformService.selectedColumn(obj, columnsConfig[4].columnName, this.transformService.transformDateFormat(this.tableData[i].hire_date, 'MM.DD.YYYY'), columnsConfig[4].selected);
        this.transformService.selectedColumn(obj, columnsConfig[5].columnName,  this.transformService.transformDateFormat(this.tableData[i].termination_date, 'MM.DD.YYYY'), columnsConfig[5].selected);
        this.transformService.selectedColumn(obj, columnsConfig[6].columnName, this.tableData[i].value, columnsConfig[6].selected);
        this.transformService.selectedColumn(obj, columnsConfig[7].columnName, `$${this.transformService.addCommasDots(this.tableData[i].gross, 'round')}`, columnsConfig[7].selected);
        this.transformService.selectedColumn(obj, columnsConfig[8].columnName, this.transformService.addCommasDots(this.tableData[i].mileage, 'round'), columnsConfig[8].selected);
        this.transformService.selectedColumn(obj, columnsConfig[9].columnName, this.transformService.addCommasDots(this.tableData[i].avgMileage, 'round'), columnsConfig[9].selected);
        this.transformService.selectedColumn(obj, columnsConfig[10].columnName, this.tableData[i].state, columnsConfig[10].selected);
        this.transformService.selectedColumn(obj, columnsConfig[11].columnName, `$${this.transformService.addCommasDots(this.tableData[i].paycheck, 'round')}`, columnsConfig[11].selected);
        this.transformService.selectedColumn(obj, columnsConfig[12].columnName, `$${this.transformService.addCommasDots(this.tableData[i].avgPaycheck, 'round')}`, columnsConfig[12].selected);
        this.transformService.selectedColumn(obj, columnsConfig[13].columnName, this.tableData[i].phone_cell, columnsConfig[13].selected);
        excelTable.push(obj);
      };
      let footerObj: any = {};
      this.transformService.selectedColumn(footerObj, columnsConfig[0].columnName, '', columnsConfig[0].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[1].columnName, '', columnsConfig[1].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[2].columnName, '', columnsConfig[2].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[3].columnName, '', columnsConfig[3].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[4].columnName, '', columnsConfig[4].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[5].columnName, '', columnsConfig[5].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[6].columnName, '', columnsConfig[6].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[7].columnName, `$${this.childComponent.stObj.avgGross}`, columnsConfig[7].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[8].columnName, this.childComponent.stObj.avgTotalMileage, columnsConfig[8].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[9].columnName, this.childComponent.stObj.avgMileage, columnsConfig[9].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[10].columnName, '', columnsConfig[10].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[11].columnName, `$${this.childComponent.stObj.avgTotalPaycheck}`, columnsConfig[11].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[12].columnName, `$${this.childComponent.stObj.avgPaycheck}`, columnsConfig[12].selected);
      this.transformService.selectedColumn(footerObj, columnsConfig[13].columnName, '', columnsConfig[13].selected);
      excelTable.push(footerObj);
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelTable);
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, 'drivers-table.xlsx');
    }
    else {
      this.msgForbbidenAccess();
    }
  };
  
  msgForbbidenAccess() {
    this.dialog.open(MsgForbbidenAccessComponent, {
      autoFocus: false,
      panelClass: 'forbidden-msg-dialog-container'
    })
  };

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  };

}

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { LoadService } from '@app/modules/safety/services/load.service';
import { SuccessDialogComponent } from '@app/modules/shared/components/success-dialog/success-dialog.component';
import { WarningMsgDialogComponent } from '@app/modules/shared/components/warning-msg-dialog/warning-msg-dialog.component';
import { Subscription, catchError, throwError } from 'rxjs';

@Component({
  selector: 'request',
  templateUrl: './request.component.html',
  styleUrls: ['./request.component.scss']
})
export class RequestComponent implements OnInit, OnDestroy {
  @Input() loadNo: number = 0;
  loggedUser: any = JSON.parse(localStorage.getItem('currentUser'));
  fullName: string = `${this.loggedUser.first_name} ${this.loggedUser.last_name}`;
  nickname: string = `${this.loggedUser.nickname}`;
  creator: string = this.nickname.length === 0 ? this.fullName : this.nickname?.trim().split(' ')[0];

  formData: any = this._formBuilder.group({
    ID: [0],
    load_no: [''],
    driverID: [''],
    serviceType: [null, Validators.required],
    status: [null, Validators.required],
    driverName: [''],
    truckNo: [''],
    amount: [null, Validators.required],
    facilityAddress: [''],
    lumperCompanyName: [''],
    respondRequestStatus: [null, Validators.required],
    respondPaymentMethod: [null, Validators.required],
    respondAmount: [null, Validators.required],
    respondMoneyCode: [''],
    respondTransactionStatus: [null, Validators.required],
    respondReceipt: [null, Validators.required],
    respondRevisedRateCan: [null, Validators.required],
    respondDate: [null, Validators.required],
    respondNote: [''],
    requestDate: [null, Validators.required],
    respondUser: [this.creator]
  });

  requestArray: any[] = [];
  activeRow: number = 0;

  //Service type
  serviceTypeObj: any = {
    1: 'Lumper Service',
    2: 'Escort Service',
    3: 'Straps Service'
  };

  //Request status
  requestStatusArray: any[] = [
    {name: 'Completed'},
    {name: 'Waiting'}
  ];
  requestObj: any = {
    1: 'Completed',
    2: 'Waiting'
  };
  isOpenRequestStatusMenu: boolean = false;

  //Payment
  paymentArray: any[] = [
    {name: 'Money Code'},
    {name: 'EFS'},
    {name: 'Credit Card'}
  ];
  paymentObj: any = {
    1: 'Money Code',
    2: 'EFS',
    3: 'Credit Card'
  };
  isOpenPaymentMenu: boolean = false;

  //Transaction
  transactionArray: any[] = [
    {name: 'Deposited'}
  ];
  transactionObj: any = {
    1: 'Deposited'
  };
  isOpenTransactionMenu: boolean = false;

  //Receipt
  receiptArray: any[] = [
    {name: 'Needed'}
  ];
  receiptObj: any = {
    1: 'Needed'
  };
  isOpenReceiptMenu: boolean = false;

  //Revised rate
  revisedRateArray: any[] = [
    {name: 'Needed'}
  ];
  revisedRateObj: any = {
    1: 'Needed'
  };
  isOpenRevisedMenu: boolean = false;

  subscription: Subscription = new Subscription();

  loaded: boolean = false;
	error: boolean = false;

  constructor(private loadService: LoadService, 
              private _formBuilder: FormBuilder,
              private dialog: MatDialog) { }

  ngOnInit(): void {
    this.getRequestData();
  };

  getRequestData() {
    this.subscription = this.loadService.getRequestData(this.loadNo)
    .pipe(catchError((err: any) => {
      this.loaded = true;
      this.error = true;
      return throwError(() => err);
      }))
    .subscribe((response: any) => {
      console.log(response);
      this.requestArray = response;
      if(response.length > 0) {
        this.formData.patchValue(response[this.activeRow]);
      }
      this.loaded = true;
    });
  };

  completeRequest() {
    this.loadService.completeRequest(this.formData.value.ID, this.formData.value)
    .pipe(catchError((err: any) => {
      this.showErrorMessage();
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      if(response === 'OK') {
        this.showSuccessMessage();
      }
      else {
        this.showErrorMessage();
      }
    });
  };

  getFormDataValue(formKey: string) {
    return this.formData.controls[formKey].value;
  };

  showSuccessMessage() {
    this.dialog.open(SuccessDialogComponent, {
      autoFocus: false,
      panelClass: 'success-dialog-container'
    });  
  };

  showErrorMessage() {
    this.dialog.open(WarningMsgDialogComponent, {
      autoFocus: false,
      panelClass: 'warning-msg-dialog-container'
    });
  };


  ngOnDestroy(): void {
    this.subscription?.unsubscribe();  
  };

}

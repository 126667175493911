import { Component, Input, OnChanges, ViewChild } from '@angular/core';
import { ApexAxisChartSeries, ApexChart, ApexFill, ApexLegend, ApexResponsive, ChartComponent } from 'ng-apexcharts';

export type ChartOptions = {
  series: ApexAxisChartSeries | any;
  chart: ApexChart | any;
  responsive: ApexResponsive | any;
  labels: string | any;
  colors: string | any;
  legend: ApexLegend | any;
  fill: ApexFill | any;
};

@Component({
  selector: 'app-not-covered-pie-chart',
  templateUrl: './not-covered-pie-chart.component.html',
  styleUrls: ['./not-covered-pie-chart.component.scss']
})
export class NotCoveredPieChartComponent implements OnChanges {
  @ViewChild("chart") chart: ChartComponent | any;
  public chartOptions: Partial<ChartOptions> | any;
  @Input() pieData: any;

  constructor() { }

  ngOnChanges(): void {
    if (this.pieData) {
      this.chartOptions = this.pieChart(this.pieData.number_of_trucks, this.pieData.covered)
    }
  }

  pieChart(lost_loads: number, booked_loads: number) {
    return {
      fill: {
        type: "gradient",
        inverseColors: false
      },
      colors: ['#f66c17', '#003fa2'],
      series: [lost_loads, booked_loads],
      chart: {
        width: 360,
        type: "pie",
        fontFamily: 'Poppins'
      },
      labels: ['LOST LOADS', 'BOOKED LOADS'],
      legend: {
        position: 'bottom'
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: "bottom"
            }
          }
        }
      ]
    };
  };

}

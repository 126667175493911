import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

import moment = require('moment');
import { Subscription } from 'rxjs';
import { FullAppReasonDialogComponent } from './full-app-reason-dialog/full-app-reason-dialog.component';

import { ConnectionPositionPair } from '@angular/cdk/overlay';
import { FullAppService } from '@app/modules/full-app/services/full-app.service';
import { IDriverStatus } from '@app/modules/full-app/models/driver-status.model';
import { IReasons } from '@app/modules/full-app/models/reason-model';
import { DeleteConfirmationDialogComponent } from '@app/modules/shared/components/delete-confirmation-dialog/delete-confirmation-dialog.component';

@Component({
  selector: 'app-full-app-rejection-dialog',
  templateUrl: './full-app-rejection-dialog.component.html',
  styleUrls: ['./full-app-rejection-dialog.component.scss']
})
export class FullAppRejectionDialogComponent implements OnInit, OnDestroy {
  userData: any = JSON.parse(localStorage.getItem('currentUser'));
  driverStatusObj: any = {
    id: 0,
    driver_id: this.obj.Id,
    reasons: [],
    department: '',
    recruiter_id: 0,
    recruiter: '',
    note: '',
    reminder: null,
    status: 'Temporary rejection',
    status_date: ''
  }

  //Status menu
  statusMenuArray: any[] = [
    {status: 'Temporary rejection'},
    {status: 'Permanent rejection'}
  ];
  isOpenStatusMenu: boolean = false;

  //Reasons menu
  reasonsArray: IReasons[] = [];
  isOpenReasonMenu: boolean = false;
  public menuPositions = [
    new ConnectionPositionPair(
      {originX: 'start', originY: 'bottom'},
      {overlayX: 'start', overlayY: 'top'},
      0, 5
    )
  ];

  //Reminder
  isOpenCalendar: boolean = false;

  //Time
  isOpenTimeMenu: boolean = false;
  time: string = '';
  hour: any;
  minutes: any;

  //Subscription
  subscription1: Subscription = new Subscription();
  subscription2: Subscription = new Subscription();

  constructor(@Inject(MAT_DIALOG_DATA) public obj: any,
  private dialog: MatDialog,
  private fullAppService: FullAppService,
  public dialogRef: MatDialogRef<FullAppRejectionDialogComponent>) { }

  ngOnInit(): void {
    this.getDriverStatusById();
    this.getAllReasons();
  };

  //Driver status by id
  getDriverStatusById() {
    this.subscription1 = this.fullAppService.getDriverStatusById(this.obj.Id).subscribe((response: IDriverStatus[]) => {
      console.log(response);
      if(response.length > 0) {
        let lastElement: IDriverStatus = response[response.length - 1];
        if(lastElement.status === 'Temporary rejection') {
          let fullTime: string = lastElement.reminder.split('T')[1];
          this.hour = fullTime.split(':')[0];
          this.minutes = fullTime.split(':')[1];
          this.time = `${this.hour}:${this.minutes}`;
        }
        this.driverStatusObj = lastElement;
      }
    });
  };

  //Reasons
  getAllReasons() {
    this.subscription2 = this.fullAppService.getAllReasons().subscribe((response: any) => {
      console.log(response);
      this.reasonsArray = response;
    });
  };

  dateClicked(event: any) {
    this.driverStatusObj.reminder = moment(event._d).format('YYYY-MM-DDT00:00:00');
    this.isOpenCalendar = false;
  };

  //Time methods
  leadingZero(hour: boolean) {
    if(hour) {
      this.hour = String(this.hour).padStart(2, '0');
    }
    else {
      this.minutes = String(this.minutes).padStart(2, '0');
    }
  }

  applyTime() {
    if(isNaN(this.hour) || this.hour === '') {
      this.hour = '00';
    }
    
    if(isNaN(this.minutes) || this.minutes === '') {
      this.minutes = '00';
    }
    this.time = `${this.hour}:${this.minutes}`;
    this.isOpenTimeMenu = false;
  };

  changeStatus(value: string) {
    this.driverStatusObj.status = value; 
    this.isOpenStatusMenu = !this.isOpenStatusMenu
  };

  selectReason(obj: IReasons) {
    this.driverStatusObj.reasons.push(obj.reason);
  };

  createUpdateReason(obj: IReasons) {
    let dialogRef = this.dialog.open(FullAppReasonDialogComponent, {
      autoFocus: false,
      panelClass: 'create-reason-dialog-container',
      data: obj
    });
    dialogRef.afterClosed().subscribe((reason: IReasons) => {
      if(reason) {
        if(obj.id) {
          obj.reason = reason.reason;
        }
        else {
          this.reasonsArray.push(reason);
        }
        this.isOpenReasonMenu = !this.isOpenReasonMenu;
      }
    });
  };

  deleteReason(obj: IReasons, index: number) {
    let dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
      autoFocus: false,
      panelClass: 'delete-dialog-container',
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
        this.fullAppService.deleteReason(obj.id).subscribe((response: boolean) => {
          if(response) {
            this.reasonsArray.splice(index, 1);
          }
        })
      }
    });
  }

  discardReason(i: number) {
    this.driverStatusObj.reasons.splice(i, 1);
  };

  deleteStatus() {
    let dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
      autoFocus: false,
      panelClass: 'delete-dialog-container',
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
        this.fullAppService.deleteDriverStatus(this.driverStatusObj.id).subscribe((response: any) => {
          if(response) {
            this.dialogRef.close(true);
          }
        })
      }
    });
  };

  saveData() {
    let time: string = this.time ? this.time : '00:00';
    let objToSave: IDriverStatus = {
      id: this.driverStatusObj.id,
      driver_id: this.driverStatusObj.driver_id,
      reasons: this.driverStatusObj.reasons,
      department: this.userData.department,
      recruiter_id: this.userData.id,
      recruiter: this.userData.first_name + ' ' + this.userData.last_name,
      note: this.driverStatusObj.note,
      reminder: this.driverStatusObj.status === 'Temporary rejection' ? moment(this.driverStatusObj.reminder).format(`YYYY-MM-DDT${time}:00`) : null,
      status: this.driverStatusObj.status,
      status_date: moment().format('YYYY-MM-DDTHH:mm:ss')
    }
    console.log(objToSave);
    this.fullAppService.createUpdateStatus(objToSave).subscribe((response: any) => {
      if(response) {
        this.dialogRef.close(true);
      }
      console.log(response);
    })
  };

  ngOnDestroy(): void {
    this.subscription1?.unsubscribe();
    this.subscription2?.unsubscribe();
  };

}

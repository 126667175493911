import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-pickup-delivery-confirmation-dialog',
  templateUrl: './pickup-delivery-confirmation-dialog.component.html',
  styleUrls: ['./pickup-delivery-confirmation-dialog.component.scss']
})
export class PickupDeliveryConfirmationDialogComponent {


  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<any>) { }


  confirmAction() {
    this.dialogRef.close(true);
  };

}

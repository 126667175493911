import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EmployeeNoteCreateUpdateDialogComponent } from './employee-note-create-update-dialog/employee-note-create-update-dialog.component';
import { EmployeeService } from '@app/modules/employee/services/employee.service';


@Component({
  selector: 'app-employee-notes',
  templateUrl: './employee-notes.component.html',
  styleUrls: ['./employee-notes.component.scss']
})
export class EmployeeNotesComponent implements OnInit {
  @Input() data: any;

  notesArray: any[] = [];

  constructor(private dialog: MatDialog, private employeeService: EmployeeService) { }

  ngOnInit(): void {
    this.notesArray = this.data.obj.notes;
  }

  openAddUpdateNote(editMode: boolean, noteObj: any, index: number) {
    let dialogRef: any = this.dialog.open(EmployeeNoteCreateUpdateDialogComponent, {
      autoFocus: false,
      panelClass: 'employee-note-main-container',
      data: {editMode: editMode, obj: this.data.obj, noteObj: noteObj, index: index}
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {  
        this.data.obj = result;
        this.notesArray = result.notes;
        this.employeeService.refreshDataSubject.next(true);
      }
    });
  }

}

import { Component, OnDestroy, OnInit } from '@angular/core';

import * as moment from 'moment';
import { catchError, Subscription, throwError } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { DispatchersPerformancesService } from '../services/dispatchers-performances.service';
import { RulesService } from '@app/modules/shared/services/rules.service';
import { TransformService } from '@app/modules/shared/services/transform.service';
import { SharedService } from '@app/modules/shared/services/shared.service';
import { CalendarDialogComponent } from '@app/modules/shared/components/calendar-dialog/calendar-dialog.component';

@Component({
  selector: 'app-dashboard-dispatch-performance-page',
  templateUrl: './dashboard-dispatch-performance-page.component.html',
  styleUrls: ['./dashboard-dispatch-performance-page.component.scss']
})
export class DashboardDispatchPerformancePageComponent implements OnInit, OnDestroy {
  permissions: any = this.rulesService.UserData[3].data;
  data: any;
  dateObj: any = {
    startDate: this.transformService.convertDateToTimestamp(moment().startOf('month').format('ddd, DD/MM YYYY'),
      '00:00:00'),
    endDate: this.transformService.convertDateToTimestamp(moment().format('ddd, DD/MM YYYY'), moment().format('HH:mm:ss')),
  };

  subscription: Subscription | any;

  days: any;
  previousPeriod: any = {};

  secondRange: any;

  //Interval
  interval: any;

  loaded: boolean = false;

  error: boolean = false;

  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";

  spinnerBgColor: string = this.transformService.spinnerBgColor;
  spinnerType: string = this.transformService.spinnerType;

  constructor(private dispatchersPerformancesService: DispatchersPerformancesService, 
              private dialog: MatDialog, 
              private rulesService: RulesService,
              private transformService: TransformService,
              private sharedService: SharedService,
              private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.spinner.show('disp-perf');
    this.getCards();
    if(this.rulesService.liveUpdate) {
      this.interval = setInterval(() => {
        this.getCards();
      }, this.rulesService.miliseconds);
    }
  }

  getCards() {
    let start: any;
    let end: any;
    if (this.secondRange?.twoRange) {
      start = this.secondRange.startDate2;
      end = this.secondRange.endDate2;

    } else {
      start = this.dateObj.startDate;
      end = this.dateObj.endDate;
    }

    var startRes = moment(start, "YYYY-MM-DD");
    var endRes = moment(end, "YYYY-MM-DD")
    let difference = Math.abs(moment.duration(startRes.diff(endRes)).asDays());
    this.days = Math.round(difference);
    let obj: any;
    if (this.days === 0) {
      obj = {
        startDate: this.transformService.convertDateToTimestamp(moment().subtract(1, 'week').format('ddd, DD/MM YYYY'),
          '00:00:00'), endDate: this.transformService.convertDateToTimestamp(moment().subtract(1, 'week').format('ddd, DD/MM YYYY'),
            '23:59:59')
      };
    } else {

      obj = {
        startDate: this.transformService.convertDateToTimestamp(moment().subtract(difference * 2, 'days').format('ddd, DD/MM YYYY'),
          '00:00:00'), endDate: this.transformService.convertDateToTimestamp(moment().subtract(difference, 'days').format('ddd, DD/MM YYYY'),
            '23:59:59')
      };
    }

    this.subscription = this.dispatchersPerformancesService.getDispatchPerformanceCards(this.dateObj.startDate, this.dateObj.endDate, obj.startDate, obj.endDate, this.transformService.filterParams)
    .pipe(catchError((err: any) => {
      this.spinner.hide();
      this.loaded = true;
      this.error = true;
      return throwError(() => err);
    }))
    .subscribe((res: any) => {
      this.data = res[0];
      this.previousPeriod.data = res[1];
      this.previousPeriod.days = this.days;
      this.previousPeriod.twoRange = this.secondRange?.twoRange;
      this.previousPeriod.dayName = moment(this.dateObj.startDate).format('ddd');
      this.spinner.hide('disp-perf');
      this.loaded = true;
    })
  }

  getDate(result) {
    this.changeDate(result);
  };
  
  openFloatingDialog() {
    let dialogRef: any = this.dialog.open(CalendarDialogComponent, {
      minWidth: '769px',
      height: '476px',
      autoFocus: false,
      data: {oneRange: false}
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.changeDate(result);
      }
    });
  }

  changeDate(result: any) {
    this.secondRange = result;
    this.dateObj.startDate = result.startDate;
    this.dateObj.endDate = result.endDate;
    this.dispatchersPerformancesService.dateChanged.next(this.dateObj);
    this.data = undefined;
    this.previousPeriod = {};
    this.error = false;
    this.loaded = false;
    this.spinner.show('disp-perf');
    let data = {
      cards: undefined, key: undefined, title: undefined,
      type: undefined, downCard: undefined, reset: true
    };
    this.dispatchersPerformancesService.dispatchCardsLineChartSubject.next(data);
    this.getCards();
  }

  
  refresh() {
    this.changeDate(this.dateObj);
  };

  ngOnDestroy(): void {
    clearInterval(this.interval);
    this.subscription?.unsubscribe();
  }

}

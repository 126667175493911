import { ConnectionPositionPair } from '@angular/cdk/overlay';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

import moment = require('moment');
import { Subject, interval, startWith, takeUntil, switchMap } from 'rxjs';
import { MsgForbbidenAccessComponent } from '@app/modules/shared/components/msg-forbbiden-access/msg-forbbiden-access.component';
import { NotificationsSnackbarComponent } from '@app/modules/shared/components/notifications-snackbar/notifications-snackbar.component';
import { TransformService } from '@app/modules/shared/services/transform.service';
import { TitleService } from '@app/modules/shared/services/title.service';
import { RulesService } from '@app/modules/shared/services/rules.service';
import { SharedService } from '@app/modules/shared/services/shared.service';
import { InspectionDetailsComponent } from '@app/modules/inspections/components/inspection-details/inspection-details.component';
import { CreateUpdateTicketDialogComponent } from '@app/modules/tickets/components/create-update-ticket-dialog/create-update-ticket-dialog.component';
import { ChatInboxComponent } from '@app/modules/shared/components/chat-inbox/chat-inbox.component';

@Component({
  selector: 'navbar-page',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent  implements OnInit, OnChanges {
  permissions: any = this.rulesService.UserData[23].data;

  private listTitles: any[];
  location: Location;
  public title = "";

  public isLoggedIn: boolean = false;

  isOpen: boolean = false;

  showWelcomemenu: any;
  @Input() welcomeMenu: any;

  activeBtn: number = -1;

  // Notifications
  private destroy$ = new Subject<void>();
  private destroy$2 = new Subject<void>();
  loggedUser: any = JSON.parse(localStorage.getItem('currentUser'));
  allNotifications: any[] = [];
  newNotificationsArray: any[] = [];
  notificationsIds: number[] = [];
  numOfNotificationsToDisplay: number = 10;
  notificationIdDisplayed: number | undefined = undefined;

  //Notification filter
  notificationsIconsArray: any[] = [
    {icon : 'all_inbox', type: 0, tooltipText: 'All notifications'},
    {icon : 'unarchive', type: 2, tooltipText: 'Unarchived notifications'},
    {icon : 'archive', type: 1, tooltipText: 'Archived notifications'}
  ];
  openSettings: boolean = false;
  notificationsType: number = 2;

  //Show btn list
  showBtns: boolean = false;
  public positions = [
    new ConnectionPositionPair(
      {originX: 'end', originY: 'bottom'},
      {overlayX: 'end', overlayY: 'top'},
      -10, 5
    )
  ];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.welcomeMenu) {
      this.showWelcomemenu = this.welcomeMenu;
    }
  }

  constructor(location: Location,
    private router: Router,
    private transformService: TransformService,
    private titleService: TitleService,
    private dialog: MatDialog,
    private rulesService: RulesService,
    private sharedService: SharedService,
    private _snackBar: MatSnackBar
  ) {
    this.location = location;
  }

  logout() {
    let id: any = JSON.parse(localStorage.getItem('currentUser')).id;
    this.sharedService.sendStatus(id, false, this.transformService.convertDateToTimestamp(moment(new Date()).format('ddd, DD/MM YYYY'), moment(new Date()).format('HH:mm:ss')), true)
    localStorage.clear();
    this.router.navigate(['login']);
  }

  ngOnInit() {
    this.titleService.getTitle().subscribe(
      title => this.title = title
    );
    this.getTaskNotifications();
    this.getNotifications();
  }


  getTitle() {
    var titlee = this.location.prepareExternalUrl(this.location.path());
    titlee = titlee.split('/').pop();
    for (var item = 0; item < this.listTitles.length; item++) {
      if (this.listTitles[item].path === titlee) {
        return this.listTitles[item].title;
      }
    }
    return 'Dashboard';
  }

  openInspectionDialog(data?: any, editMode?: boolean) {
    if (this.permissions[0].allowedAll) {
      this.dialog.open(InspectionDetailsComponent, {
        autoFocus: false,
        disableClose: true,
        panelClass: 'inspections',
        data: { inspectionData: data, editMode: editMode },
      });
    }
  }

  openTicketDialog() {
    if(this.rulesService.UserData[28].data[0].allowedAll) {
      this.dialog.open(CreateUpdateTicketDialogComponent, {
        autoFocus: false,
        panelClass: 'create-update-ticket-main-container',
        disableClose: true,
        data: {editMode: false, obj: {}}
      });
    }
    else {
      this.dialog.open(MsgForbbidenAccessComponent, {
        autoFocus: false,
        panelClass: 'forbidden-msg-dialog-container'
      })
    }
  }

  // Get Notifications
  getNotifications() {
    interval(20000)
      .pipe(
        startWith(0),
        takeUntil(this.destroy$),
        switchMap(() => this.sharedService.getAllNotifications(this.loggedUser.id, this.numOfNotificationsToDisplay, this.notificationsType))
      )
      .subscribe((data: any) => {
        console.log(data);
        let parsedData = data.map(item => ({
          ...item,
          data: JSON.parse(item.data)
        }));
        console.log(parsedData)
        this.allNotifications = parsedData;
        this.newNotificationsArray = parsedData.filter(notification => !notification.opened);
        if (this.allNotifications.length > 0 && !this.isOpen) {
          this.notificationsIds = [];
          for(let i = 0; i < this.allNotifications.length; i++) {
            if(!this.allNotifications[i].opened) {
              this.notificationsIds.push(this.allNotifications[i].id);
            }
          }
        }
        if (this.newNotificationsArray.length > 0) {
          
          let time = (Date.now() - Date.parse(this.calculateTimeDifference(this.newNotificationsArray[0].date))) / 1000;
          if (time < 50 && this.notificationIdDisplayed !== this.newNotificationsArray[0].id) {
            if(!this.newNotificationsArray[0].type.includes('TASK_ASSIGNED')) {
              this.showRegularSnackbar();
            }
            this.notificationIdDisplayed = this.newNotificationsArray[0].id;
          } 

        }
      });
  }

  //Task notifications
  getTaskNotifications() {
    interval(20000)
      .pipe(
        startWith(0),
        takeUntil(this.destroy$2),
        switchMap(() => this.sharedService.getTaskNotificationsByDispatcher(this.loggedUser.id))
      )
      .subscribe((response: any) => {
        let parsedData = response.map(item => ({
          ...item,
          data: JSON.parse(item.data)
        }));
        if(parsedData.length === 1) {
          let date: any = new Date(this.getCurrentDate).getTime();
          let time = (date - Date.parse(parsedData[0].date)) / 1000;
          if(time < 50 && this.sharedService.taskNotifications.length === 0) {
            this.sharedService.showTaskSnackbar(parsedData[0]);
          };
        }
        this.sharedService.taskNotifications = parsedData; 
      });
  };

  showMoreNotifications(numOfNotifications: number) {
    this.numOfNotificationsToDisplay = numOfNotifications;
    this.getNotifications()
  };

  get getCurrentDate(): string {
    let date = new Date();
    let europeDateTime: any = date.toLocaleString("de-DE", {hour12: false, timeZone: "Europe/Berlin"}).split(',');
    let format: string = europeDateTime[0].split('.')
    let day: string = +format[0] < 10 ? `0${format[0]}` : format[0];
    let month: string = +format[1] < 10 ? `0${format[1]}` : format[1];
    let year: string = format[2];
    return `${year}-${month}-${day}T${europeDateTime[1].trim()}`;
  }

  //Time difference
  calculateTimeDifference(date: string) {
    let dateToConvert: string = date.split('.')[0];

    let timeZone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let dateToDisplay: any = '';
    if(timeZone.startsWith('America')) {
      let date: any = new Date(dateToConvert);
      date.setHours(date.getHours() - 7)
      dateToDisplay = moment(date).format('YYYY-MM-DDTHH:mm:ss');
    }
    else if(timeZone.startsWith('Europe')) {
      let date: any = new Date(dateToConvert);
      date.setHours(date.getHours() - 1)
      dateToDisplay = moment(date).format('YYYY-MM-DDTHH:mm:ss');
    }
    else {
      dateToDisplay = dateToConvert;
    }
    return dateToDisplay;
  };

  //Open new notification mark as opened
  openNotification() {
    this.isOpen = !this.isOpen;
    let id: any = JSON.parse(localStorage.getItem('currentUser')).id;
    if(this.notificationsIds.length > 0) {
      this.sharedService.notificationOpened(id).subscribe((response: any) => {
        if(response) {
          this.getNotifications();
        }

      });
    }
  };

  showRegularSnackbar() {
    this._snackBar.openFromComponent(NotificationsSnackbarComponent, {
      data: this.newNotificationsArray,
      horizontalPosition: 'end',
      verticalPosition: 'top',
      duration: 4000
    });
  };

  openInbox() {
    this.dialog.open(ChatInboxComponent, {
      autoFocus: false,
      panelClass: 'chat-inbox-dialog-container'
    });
  };

  //Open in new tab
  openInNewTab(route: string) {
    const url = this.router.serializeUrl(this.router.createUrlTree([route]));
    window.open(url, '_blank');
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();

    this.destroy$2.next();
    this.destroy$2.complete();
  };

}


import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { DriversProfileService } from '@app/modules/drivers-profile-page/services/drivers-profile.service';
import { SharedService } from '@app/modules/shared/services/shared.service';
import { TransformService } from '@app/modules/shared/services/transform.service';
import { CreateUpdateWarningDialogComponent } from '@app/modules/warnings/components/create-update-warning-dialog/create-update-warning-dialog.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription, catchError, throwError } from 'rxjs';

@Component({
  selector: 'app-warning-letters-dialog',
  templateUrl: './warning-letters-dialog.component.html',
  styleUrls: ['./warning-letters-dialog.component.scss']
})
export class WarningLettersDialogComponent implements OnInit, OnDestroy {

  warningLettersArray: any[] = [];

  loaded: boolean = false;
  error: boolean = false;
  errorMsg: string = "Sorry, we're having some temporary server issues. Please contact support";
  spinnerBgColor: string = this.transformService.spinnerBgColor;
  spinnerType: string = this.transformService.spinnerType;

  //Subscription
  subscription: Subscription = new Subscription();

  constructor(@Inject(MAT_DIALOG_DATA) public driverId: number, 
  private dialog: MatDialog,
  private transformService: TransformService,
  private driversProfileService: DriversProfileService,
  private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.getTableData();
  };

  getTableData() {
    this.warningLettersArray = [];
    this.error = false;
    this.loaded = false;
    this.spinner.show('driver-warning-letters');
    this.subscription = this.driversProfileService.getWarningLettersByDriver(this.driverId)
    .pipe(catchError((err: any) => {
      this.spinner.hide('driver-warning-letters');
      this.loaded = true;
      this.error = true;
      return throwError(() => err);
    }))
    .subscribe((response: any) => {
      console.log(response);
      this.warningLettersArray = response;
      this.spinner.hide('driver-warning-letters');
      this.loaded = true;
    });

  };

  // Open Warning Dialog
  openWarningDialog(editMode: boolean, obj: any) {
    let dialogRef = this.dialog.open(CreateUpdateWarningDialogComponent, {
      autoFocus: false,
      panelClass: 'create-update-warning-main-container',
      disableClose: true,
      data: {editMode: editMode, obj: obj}
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
        this.getTableData();
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();  
  };

}

import { Component, Input } from '@angular/core';

@Component({
  selector: 'checkbox-select',
  templateUrl: './checkbox-select.component.html',
  styleUrls: ['./checkbox-select.component.scss']
})
export class CheckboxSelectComponent {
   //Tags menu
   @Input() config: any = {
    title: '',
    array: [],
    search: false,
    icon: ''
  }
  isOpenCheckboxMenu: boolean = false;

  checkUncheck(event: any, obj: any) {
    obj.checked = !obj.checked;
    event.stopPropagation();
   }
}

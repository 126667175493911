import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'assignedTrucksFilter'
})
export class AssignedTrucksFilterPipe implements PipeTransform {

  transform(array: any[], unitNo: string, trailerNo: string, accountingEmployee: string, accountingObj: any): any[] {
    if (!array) {
      return [];
    }

    return array.filter((obj: any) => {
      let key1: boolean =  obj.unit_no.toLocaleLowerCase().startsWith(unitNo.toLocaleLowerCase());
      let key2: boolean =  obj.trailer_no.toLocaleLowerCase().startsWith(trailerNo.toLocaleLowerCase());
      let key3: boolean =  accountingObj[obj.employee]?.toLocaleLowerCase().startsWith(accountingEmployee.toLocaleLowerCase()) || accountingEmployee === '';

      if(key1 && key2 && key3) {
        return obj;
      }
    })

  }

}

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FullAppService } from '@app/modules/full-app/services/full-app.service';
import { IFile } from '@app/modules/full-app/models/file-group.model';

@Component({
  selector: 'app-create-update-group',
  templateUrl: './create-update-group.component.html',
  styleUrls: ['./create-update-group.component.scss']
})
export class CreateUpdateGroupComponent implements OnInit {
  document_group: string = '';

  constructor(@Inject(MAT_DIALOG_DATA) public obj: IFile,
              private fullAppService: FullAppService,
              public dialogRef: MatDialogRef<CreateUpdateGroupComponent>) { }

  ngOnInit(): void {
    this.document_group = this.obj.document_group;
  }

  save() {
    let obj: IFile = {
      id: this.obj.id,
      document_group: this.document_group
    }
    this.fullAppService.createUpdateFileGroup(obj).subscribe((response: any) => {
      if(response) {
        this.dialogRef.close(response);
      }
    });
  };

}

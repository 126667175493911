import { ConnectionPositionPair } from '@angular/cdk/overlay';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, 
OnInit, OnDestroy, Output, ViewChild } from '@angular/core';
import {Subject, debounceTime, filter, fromEvent, share, startWith, switchMap, takeUntil } from 'rxjs';

@Component({
  selector: 'popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PopupComponent implements OnInit, OnDestroy {
  @Input() overlayOrigin: any;
  //Positions
  @Input() public positions = [
    new ConnectionPositionPair(
      {originX: 'start', originY: 'top'},
      {overlayX: 'start', overlayY: 'bottom'})
  ];
  @Output() close = new EventEmitter<any>();
  @Output() open = new EventEmitter<any>();

  @ViewChild('dialog') dialog: ElementRef;
  isOpened = false;
  destroy$: any = new Subject<any>();
    
  constructor(private changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    const overlayOriginEl = this.overlayOrigin.elementRef.nativeElement;
    const open$: any = fromEvent(overlayOriginEl, 'mouseenter').pipe(
      filter(() => !this.isOpened),
      switchMap(enterEvent =>
        fromEvent(document, 'mousemove').pipe(
          startWith(enterEvent),
          debounceTime(200),
          filter(event => overlayOriginEl === event['target'])
        )
      ),
      share(),
      );

    open$.pipe(takeUntil(this.destroy$)).subscribe(() => this.changeState(true));

    const close$: any = fromEvent(document, 'mousemove').pipe(
      debounceTime(100),
      filter(() => this.isOpened),
      filter(event => this.isMovedOutside(overlayOriginEl, this.dialog, event))
      );

    open$.pipe(takeUntil(this.destroy$), switchMap(() => close$)).subscribe(() => {this.changeState(false);});
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  connectedOverlayDetach() {
    this.changeState(false);
  }

  private changeState(isOpened: boolean) {
    this.isOpened = isOpened;
    isOpened ? this.open.emit() : this.close.emit();
    this.changeDetectorRef.markForCheck();
  }

  private isMovedOutside(overlayOriginEl, dialog, event): boolean {
    return !(overlayOriginEl.contains(event['target']) ||     dialog.nativeElement.contains(event['target']));
  }
}

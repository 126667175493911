import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { DriversSearchRoutingModule } from './drivers-search.routing';
import { DashboardDriversSearchPageComponent } from './components/dashboard-drivers-search-page.component';
import { DriversSearchService } from './services/drivers-search.service';

@NgModule({
  declarations: [
    DashboardDriversSearchPageComponent
  ],
  imports: [
    CommonModule,
    DriversSearchRoutingModule,
    SharedModule
  ],
  providers: [DriversSearchService]
})
export class DriversSearchPageModule { }

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BusinessPartnersRoutingModule } from './business-partners.routing';
import { SharedModule } from '../shared/shared.module';
import { BusinessPartnersPageComponent } from './components/business-partners-page.component';
import { BusinessPartnersTableComponent } from './components/business-partners-table/business-partners-table.component';
import { BusinessPartnersService } from './services/business-partners.service';


@NgModule({
  declarations: [
    BusinessPartnersPageComponent,
    BusinessPartnersTableComponent
  ],
  imports: [
    CommonModule,
    BusinessPartnersRoutingModule,
    SharedModule
  ],
  providers: [BusinessPartnersService]
})
export class BusinessPartnersModule { }

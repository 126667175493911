import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { FooterComponent } from './components/footer/footer.component';
import { ForgotPasswordPageComponent } from './components/forgot-password-page/forgot-password-page.component';
import { LoginComponent } from './components/login/login.component';
import { GlobalSearchComponent } from './components/navbar/global-search/global-search.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { RegisterComponent } from './components/register/register.component';
import { CoreRoutingModule } from './core.routing';
import { AuthenticationService } from './services/authentication.service';

@NgModule({
  declarations: [
    NavbarComponent,
    FooterComponent,
    GlobalSearchComponent,
    ForgotPasswordPageComponent,
    LoginComponent,
    RegisterComponent
  ],
  imports: [
    CommonModule,
    CoreRoutingModule,
    SharedModule
  ],
  exports: [
    NavbarComponent,
    FooterComponent
  ],
  providers: [AuthenticationService]
})
export class CoreModule { }

import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DriversProfileService } from '@app/modules/drivers-profile-page/services/drivers-profile.service';
import { TransformService } from '@app/modules/shared/services/transform.service';
import { ApexAxisChartSeries, ApexChart, ApexXAxis, ApexYAxis, ApexDataLabels, ApexGrid, ApexStroke, ChartComponent } from 'ng-apexcharts';
import { Subscription } from 'rxjs';

export type ChartOptions = {
  series: ApexAxisChartSeries | any;
  chart: ApexChart | any;
  xaxis: ApexXAxis | any;
  yaxis: ApexYAxis | any;
  dataLabels: ApexDataLabels | any;
  grid: ApexGrid | any;
  stroke: ApexStroke | any;
  markers: any;
  colors: any;
  tooltip: any;
};

@Component({
  selector: 'driver-statistics-linechart',
  templateUrl: './driver-statistics-linechart.component.html',
  styleUrls: ['./driver-statistics-linechart.component.scss']
})
export class DriverStatisticsLinechartComponent implements OnInit, OnDestroy {
  @ViewChild("chart") chart: ChartComponent | any;
  public chartOptions: Partial<ChartOptions> | any;
  @Input() objData: any;

  selectPeriod: FormControl | any;
  graphDataLength: number = 0;

  //Period
  isOpenPeriodMenu: boolean = false;
  menuValue: string = 'By day';
  selectPeriodArray: any[] = [
    {name: 'By year', value: 'year'},
    {name: 'By quarter', value: 'quarter'},
    {name: 'By month', value: 'month'},
    {name: 'By week', value: 'week'},
    {name: 'By day', value: 'day'},
  ];

  subscription: Subscription | any;

  constructor(private transformService: TransformService, private driversProfileService: DriversProfileService) {
    this.selectPeriod = new FormControl(['day']);
  }

  ngOnInit(): void {
    this.selectionChange();
  };

  selectionChange() {
    this.subscription = this.driversProfileService.linechartSelectionChange.subscribe((response: boolean) => {
      if(response) {
        this.initGraph();
      };
    });
  };

  initGraph() {
    let obj: any = this.transformService.groupGraphDataByDate({data: this.objData.data.daily, viewBy: this.selectPeriod.value[0], keys: this.objData.keys, dateObj: this.objData.dateObj});
    this.graphDataLength = obj.series.length;
    this.chartOptions = {
      series: obj.series,
      chart: {
        toolbar: {
          show: true,
          tools: {
            download: false
          },
          animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 800,
            animateGradually: {
                enabled: true,
                delay: 150
            },
            dynamicAnimation: {
                enabled: true,
                speed: 350
            }
          }
        },
        height: 350,
        type: "line",
        zoom: {
          enabled: false
        },
        fontFamily: 'Poppins'
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: "straight",
        width: 1,
      },
      grid: {
        row: {
          colors: ["transparent", "transparent"],
          opacity: 0.5
        }
      },
      xaxis: {
        categories: obj.categories
      },
      yaxis: {
        tooltip: {
          enabled: true,
          shared: false
        },
        labels: {
          formatter: (num: number) => {
            return num.toLocaleString("en-US").toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          },
        },
      },
      markers: {
        size: 5
      },
      colors: ['#0030FF', '#FA9120', '#FA0000', '#008000', '#FF00FF', '#800000', '#FE7777', '#0303BB', '#008FA9', '#7550CB', '#6A6D6E'],
      tooltip: {
        enabled: true,
        inverseOrder: false,
        style: {
          fontSize: '12px'
        },
      }
    }
  };

  onlyOneSelect(value: string, obj: any) {
    this.menuValue = obj.name;
    this.selectPeriod = new FormControl([value]);
    this.initGraph();
    this.isOpenPeriodMenu = false;
  };

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();    
  };

}


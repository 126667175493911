import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EstimationsRoutingModule } from './estimations.routing';
import { SharedModule } from '../shared/shared.module';
import { EstimationAnalysisComponent } from './components/estimation-analysis/estimation-analysis.component';
import { EstimationBoardComponent } from './components/estimation-board/estimation-board.component';
import { AnnotationChartComponent } from './components/estimation-time-series/components/annotation-chart/annotation-chart.component';
import { PieChartComponent } from './components/estimation-time-series/components/pie-chart.component';
import { EstimationTimeSeriesComponent } from './components/estimation-time-series/estimation-time-series.component';
import { EstimationsComponent } from './components/estimations.component';
import { Misc } from '@app/_config/misc';
import { EstimationsService } from './services/estimations.service';



@NgModule({
  declarations: [
    EstimationAnalysisComponent,
    EstimationBoardComponent,
    EstimationTimeSeriesComponent,
    AnnotationChartComponent,
    PieChartComponent,
    EstimationsComponent
  ],
  imports: [
    CommonModule,
    EstimationsRoutingModule,
    SharedModule
  ],
  providers: [
    EstimationsService, Misc
  ]
})
export class EstimationsModule { }

import { Injectable } from '@angular/core';
import * as _G from '../../../_config/globals';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { forkJoin } from 'rxjs';
import { IDeal } from '../models/deal-model';
import { IDriverNote } from '../models/driver-note.model';
import { IDriverStatus } from '../models/driver-status.model';
import { IExpenses, PredefiniedExpense } from '../models/expenses-model';
import { IExtend } from '../models/extend-model';
import { IFullAppApplyFields, IFullAppFields } from '../models/full-app-fields';
import { IReasons } from '../models/reason-model';
import { ISpecialRequirements } from '../models/special-requirements.model';
import { IFields } from '../models/template-fields.model';
import { ITemplate } from '../models/template-model';
import { IFile } from '../models/file-group.model';
import { IPaperwork } from '../models/paperwork.model';
import { ITripPlanner } from '../models/trip-planner.model';


@Injectable()
export class FullAppService {
  headers = new HttpHeaders({
		'Content-Type': 'application/json',
		'Authorization': 'Basic SVZTb2x1dGlvbjoxUzBsdXQxMG4yQDIy'
	});
	requestOptions = { headers: this.headers };

  constructor(private http: HttpClient) { }

  //All templates
  getAllTemplates() {
    return this.http.get(`${_G.extendApiUrl}fullapp/templates`, this.requestOptions);
  };

  getFilledApplications(startDate: string, endDate: string) {
    return this.http.get(`${_G.extendApiUrl}fullapp?start=${startDate}&end=${endDate}`, this.requestOptions);
  };

  getOrientationApplications(startDate: string, endDate: string) {
    return this.http.get(`${_G.extendApiUrl}fullapp/orientation?start=${startDate}&end=${endDate}`, this.requestOptions);
  };

  //Create update template
  createUpdateTemplate(template: ITemplate) {
    let json: string = JSON.stringify(template);
    return this.http.post(`${_G.extendApiUrl}fullapp/templates`, json, this.requestOptions);
  };

  //Delete template
  deleteTemplate(id: number) {
    return this.http.delete(`${_G.extendApiUrl}fullapp/templates/${id}`, this.requestOptions);
  };

  //All inputs
  getAllInputs() {
    return this.http.get(`${_G.extendApiUrl}fullapp/fields`, this.requestOptions);
  };

  //Save input
  saveInput(obj: IFields) {
    let json: string = JSON.stringify(obj);
    return this.http.post(`${_G.extendApiUrl}fullapp/fields`, json, this.requestOptions);
  };

  //Delete input
  deleteInput(id: number) {
    return this.http.delete(`${_G.extendApiUrl}fullapp/fields/${id}`, this.requestOptions);
  };

  //Get deal by driver id
  getDeal(id: number) {
    return this.http.get(`${_G.extendApiUrl}fullapp/deal?id=${id}`, this.requestOptions);
  };

  getDealAndTemplates(id: number) {
    let request1 = this.http.get(`${_G.extendApiUrl}fullapp/deal?id=${id}`, this.requestOptions);
    let request2 = this.http.get(`${_G.extendApiUrl}fullapp/templates`, this.requestOptions);
    return forkJoin([request1, request2]);
  };

  //Create update deal
  createUpdateDeal(obj: IDeal) {
    let json: string = JSON.stringify(obj);
    return this.http.post(`${_G.extendApiUrl}fullapp/deal`, json ,this.requestOptions);
  };

  //Full app extra fields
  getFullAppExtraFields() {
    return this.http.get(`${_G.extendApiUrl}fullapp/extrafields`, this.requestOptions);
  };

  //Apply field to drivers
  applyFieldsToDrivers(obj: IFullAppApplyFields) {
    let json: string = JSON.stringify(obj);
    return this.http.post(`${_G.extendApiUrl}fullapp/employeeextra`, json, this.requestOptions);
  };

  deleteAppliedFieldsToDrivers(driverId: number, fieldId: number) {
    return this.http.delete(`${_G.extendApiUrl}fullapp/employeeextra?driver_id=${driverId}&extra_id=${fieldId}`, this.requestOptions);
  };

  //Save extra field
  saveUpdateeFullAppExtraField(obj: IFullAppFields) {
    let json: string = JSON.stringify(obj);
    return this.http.post(`${_G.extendApiUrl}fullapp/extrafields`, json, this.requestOptions);
  };

  //Delete extra field
  deleteFullAppExtraField(id: number) {
    return this.http.delete(`${_G.extendApiUrl}fullapp/extrafields/${id}`, this.requestOptions);
  };

  //Change recruiter, status
  changeStatusOrRecruiter(obj: IExtend) {
    let json: string = JSON.stringify(obj);
    return this.http.post(`${_G.extendApiUrl}fullapp/extend`, json, this.requestOptions);
  };

  //Get all expenses
  getAllExpenses(driverId: number) {
    return this.http.get(`${_G.extendApiUrl}fullapp/expenses?id=${driverId}`, this.requestOptions);
  };

  //Create update expense
  createUpdateExpense(array: IExpenses[]) {
    let json: string = JSON.stringify(array);
    return this.http.post(`${_G.extendApiUrl}fullapp/expenses`, json, this.requestOptions);
  };

  //Delete expense
  deleteExpense(driverId: number, expenseId: number) {
    return this.http.delete(`${_G.extendApiUrl}fullapp/expenses?driver_id=${driverId}&id=${expenseId}`, this.requestOptions);
  };

  //Delete file from expense
  deleteFileFromExpense(driverId: number, expenseId: number, filename: string) {
    return this.http.get(`${_G.extendApiUrl}fullapp/delexpfile?driver_id=${driverId}&expens_id=${expenseId}&filename=${filename}`, this.requestOptions);
  };

  //Create update predefinied expens
  createUpdatePredefiniedExpense(obj: PredefiniedExpense) {
    let json: string = JSON.stringify(obj);
    return this.http.post(`${_G.extendApiUrl}fullapp/expenses/eypense`, json, this.requestOptions);
  };

  //Get predefinied expense
  getAllPredefiniedExpense() {
    return this.http.get(`${_G.extendApiUrl}fullapp/expenses/eypense`, this.requestOptions);
  };

  //Delete predefinied expense
  deletePredefiniedExpense(id: number) {
    return this.http.delete(`${_G.extendApiUrl}fullapp/expenses/eypense/${id}`, this.requestOptions);
  };

  //Driver status
  getDriverStatusById(driverId: number) {
    return this.http.get(`${_G.extendApiUrl}fullapp/driverstatus?id=${driverId}`, this.requestOptions);
  };  

  markBadDocumentation(obj: any) {
    let json: string = JSON.stringify(obj);
    return this.http.post(`${_G.extendApiUrl}fullapp/expenseerror`, json, this.requestOptions);
  };

  //Create update status
  createUpdateStatus(obj: IDriverStatus) {
    let json: string = JSON.stringify(obj);
    return this.http.post(`${_G.extendApiUrl}fullapp/driverstatus`, json, this.requestOptions);
  };

  //Delete status
  deleteDriverStatus(statusId: number) {
    return this.http.delete(`${_G.extendApiUrl}fullapp/driverstatus/${statusId}`, this.requestOptions);
  };

  //Reasons get all
  getAllReasons() {
    return this.http.get(`${_G.extendApiUrl}fullapp/reasons`, this.requestOptions);
  };

  //Create update reason
  createUpdateReason(obj: IReasons) {
    let json: string = JSON.stringify(obj);
    return this.http.post(`${_G.extendApiUrl}fullapp/reasons`, json, this.requestOptions);
  };

  //Delete reason
  deleteReason(reasonId: number) {
    return this.http.delete(`${_G.extendApiUrl}fullapp/reasons/${reasonId}`, this.requestOptions);
  };

  //All special requirements
  getAllSpecialRequirements(driverId: number) {
    return this.http.get(`${_G.extendApiUrl}fullapp/specials?id=${driverId}`, this.requestOptions);
  };

  //Create update requirements
  createUpdateSpecialRequirements(obj: ISpecialRequirements) {
    let json: string = JSON.stringify(obj);
    return this.http.post(`${_G.extendApiUrl}fullapp/specials`, json, this.requestOptions);
  };

  //Delete requirement
  deleteSpecialRequirements(id: number) {
    return this.http.delete(`${_G.extendApiUrl}fullapp/specials/${id}`, this.requestOptions);
  };

  //Get notes by driver id
  getNotesByDriverId(driverId: number) {
    return this.http.get(`${_G.extendApiUrl}fullapp/note?id=${driverId}`, this.requestOptions);
  };

  //Create update note
  createUpdateDriverNote(obj: IDriverNote) {
    let json: string = JSON.stringify(obj);
    return this.http.post(`${_G.extendApiUrl}fullapp/note`, json, this.requestOptions);
  };

  //Delete driver note
  deleteDriverNote(noteId: number) {
    return this.http.delete(`${_G.extendApiUrl}fullapp/note/${noteId}`, this.requestOptions);
  };

  //Paperwork

  //Get file group
  getAllFilesGroup() {
    return this.http.get(`${_G.extendApiUrl}fullapp/docgroup`, this.requestOptions);
  };

  //Create update file group
  createUpdateFileGroup(obj: IFile) {
    let json: string = JSON.stringify(obj);
    return this.http.post(`${_G.extendApiUrl}fullapp/docgroup`, json, this.requestOptions);
  };

  //Delete file group
  deleteFileGroup(groupId: number) {
    return this.http.delete(`${_G.extendApiUrl}fullapp/docgroup/${groupId}`, this.requestOptions);
  };

  //Get all paperworks 
  getAllPaperworks(driverId: number) {
    return this.http.get(`${_G.extendApiUrl}fullapp/documents?id=${driverId}`, this.requestOptions);
  };

  //Create update paperwork
  createUpdatePaperwork(obj: IPaperwork) {
    let json: string = JSON.stringify(obj);
    return this.http.post(`${_G.extendApiUrl}fullapp/documents`, json, this.requestOptions);
  };

  //Delete paperwork
  deleteFile(fileId: number) {
    return this.http.delete(`${_G.extendApiUrl}fullapp/documents/${fileId}`, this.requestOptions);
  };

  //Delete full application
  deleteFullApplication(driverId: number) {
    return this.http.delete(`${_G.extendApiUrl}fullapp/delete/${driverId}`, this.requestOptions);
  };

  //Trip planner

  //Get trip planner by id
  getTripPlannerByDriverId(driverId: number) {
    return this.http.get(`${_G.extendApiUrl}fullapp/tripplanner?id=${driverId}`, this.requestOptions);
  };

  //Create update trip planner
  createUpdateTripPlanner(obj: ITripPlanner) {
    let json: string = JSON.stringify(obj);
    return this.http.post(`${_G.extendApiUrl}fullapp/tripplanner`, json, this.requestOptions);
  };

  //Delete trip planner
  deleteTripPlanner(tripPlannerId: number) {
    return this.http.delete(`${_G.extendApiUrl}fullapp/tripplanner/${tripPlannerId}`, this.requestOptions);
  };

}

import { Component } from '@angular/core';

@Component({
  selector: 'app-dashboard-underperforming-drivers-page',
  templateUrl: './dashboard-underperforming-drivers-page.component.html',
  styleUrls: ['./dashboard-underperforming-drivers-page.component.scss']
})
export class DashboardUnderperformingDriversPageComponent {

}

import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FleetHealthService } from '@app/modules/fleet-health/services/fleet-health.service';
import { TransformService } from '@app/modules/shared/services/transform.service';
import moment = require('moment');

@Component({
  selector: 'app-create-update-status-form',
  templateUrl: './create-update-status-form.component.html',
  styleUrls: ['./create-update-status-form.component.scss']
})
export class CreateUpdateStatusFormComponent {
  status: string = '';

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
              public dialogRef: MatDialogRef<any>,
              private transformService: TransformService,
              private fleetService: FleetHealthService) {
              if(!this.data.isNew) {
                this.status = data.obj.status;
              }
  }

  createUpdate() {
    let loggedUser: any = JSON.parse(localStorage.getItem('currentUser'));
    let obj: any = {
      id: 0,
      status: this.status,
      created_by: loggedUser.first_name + ' ' + loggedUser.last_name,
      created_date:  this.transformService.convertDateToTimestamp(moment(new Date()).format('ddd, DD/MM YYYY'),  moment(new Date()).format('HH:mm:ss')),
      edited_by: null,
      edited_date: null
    }

    if(!this.data.isNew) {
      obj.id = this.data.obj.id;
      obj.created_by = this.data.obj.created_by;
      obj.created_date = this.data.obj.created_date;
      obj.edited_by = loggedUser.first_name + ' ' + loggedUser.last_name;
      obj.edited_date = this.transformService.convertDateToTimestamp(moment(new Date()).format('ddd, DD/MM YYYY'),  moment(new Date()).format('HH:mm:ss'));
    }

    this.fleetService.createUpdateStatus(obj).subscribe((response: any) => {
      console.log(response);
      if(response) {
        this.dialogRef.close(response);
      }
    });
  }
}

import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import moment = require('moment');
import { CreateWorkOrderDialogComponent } from '../create-work-order-dialog/create-work-order-dialog.component';
import { TransformService } from '@app/modules/shared/services/transform.service';
import { RulesService } from '@app/modules/shared/services/rules.service';

@Component({
  selector: 'app-fleet-health-overview',
  templateUrl: './fleet-health-overview.component.html',
  styleUrls: ['./fleet-health-overview.component.scss'],
})
export class FleetHealthOverviewComponent {
  permissionsData: any = this.rulesService.UserData[22].data;

  dateObj: any = {
    startDate: this.transformService.convertDateToTimestamp(moment().startOf('month').format('ddd, DD/MM YYYY'), 
    '00:00:00'),
    endDate: this.transformService.convertDateToTimestamp(moment().format('ddd, DD/MM YYYY'),  moment().format('HH:mm:ss')),
  };

  constructor(public transformService: TransformService, private dialog: MatDialog, private rulesService: RulesService) {}

  //New design
  getDate(result: any) {

  };
  
  openCreateWorkOrder() {
    this.dialog.open(CreateWorkOrderDialogComponent, {
      autoFocus: false,
      panelClass: 'create-work-order-dialog-container',
      disableClose: true
    });
  }
  
}

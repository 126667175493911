import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '@app/modules/shared/shared.module';
import { ChatRoutingModule } from './chat.routing';
import { ChatComponent } from './components/chat/chat.component';
import { ChatService } from './services/chat.service';
import { ChatContactListDialogComponent } from './components/chat/chat-contact-list-dialog/chat-contact-list-dialog.component';

@NgModule({
  declarations: [
    ChatComponent,
    ChatContactListDialogComponent
  ],
  imports: [
    CommonModule,
    ChatRoutingModule,
    SharedModule
  ],
  providers: [ChatService]
})

export class ChatModule { }
